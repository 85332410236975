<template>
  <el-row>
    <el-col :span="11" class="menu-left">
      <el-menu :default-active="cardNames.EYE" @select="menuClicked">
        <el-menu-item :index="cardNames.EYE">
          <i
            :class="symptomList[0].isNormal === 'normal'? '' : 'el-icon-check'" style="color: red;"
          ></i>
          <span :class="symptomList[0].isNormal === 'normal'? '' : 'red-label'">ตา</span>
        </el-menu-item>
        <el-menu-item :index="cardNames.EAR">
          <i
            :class="symptomList[1].isNormal === 'normal'? '' : 'el-icon-check'" style="color: red;"
          ></i>
          <span :class="symptomList[1].isNormal === 'normal'? '' : 'red-label'">หู</span>
        </el-menu-item>
        <el-menu-item :index="cardNames.MOUTH">
          <i
            :class="symptomList[6].isNormal === 'normal'? '' : 'el-icon-check'" style="color: red;"
          ></i>
          <span :class="symptomList[6].isNormal === 'normal'? '' : 'red-label'">ช่องปาก ฟัน เหงือก</span>
        </el-menu-item>
        <el-menu-item :index="cardNames.SKIN">
          <i
            :class="symptomList[2].isNormal === 'normal'? '' : 'el-icon-check'" style="color: red;"
          ></i>
          <span :class="symptomList[2].isNormal === 'normal'? '' : 'red-label'">ผิวหนังและขน</span>
        </el-menu-item>
        <el-menu-item :index="cardNames.REPRODUCE">
          <i
            :class="symptomList[7].isNormal === 'normal'? '' : 'el-icon-check'" style="color: red;"
          ></i>
          <span :class="symptomList[7].isNormal === 'normal'? '' : 'red-label'">ระบบปัสสาวะและสืบพันธุ์</span>
        </el-menu-item>
        <el-menu-item :index="cardNames.DIGEST">
          <i
            :class="symptomList[3].isNormal === 'normal'? '' : 'el-icon-check'" style="color: red;"
          ></i>
          <span :class="symptomList[3].isNormal === 'normal'? '' : 'red-label'">ระบบทางเดินอาหาร</span>
        </el-menu-item>
        <el-menu-item :index="cardNames.BREATH">
          <i
            :class="symptomList[5].isNormal === 'normal'? '' : 'el-icon-check'" style="color: red;"
          ></i>
          <span :class="symptomList[5].isNormal === 'normal'? '' : 'red-label'">ระบบทางเดินหายใจ</span>
        </el-menu-item>
        <el-menu-item :index="cardNames.BONE">
          <i
            :class="symptomList[8].isNormal === 'normal'? '' : 'el-icon-check'" style="color: red;"
          ></i>
          <span :class="symptomList[8].isNormal === 'normal'? '' : 'red-label'">ระบบกระดูกและกล้ามเนื้อ</span>
        </el-menu-item>
        <el-menu-item :index="cardNames.HEART">
          <i
            :class="symptomList[4].isNormal === 'normal'? '' : 'el-icon-check'" style="color: red;"
          ></i>
          <span :class="symptomList[4].isNormal === 'normal'? '' : 'red-label'">หัวใจและระบบไหลเวียนโลหิต</span>
        </el-menu-item>
        <el-menu-item :index="cardNames.LYMPH">
          <i
            :class="symptomList[9].isNormal === 'normal'? '' : 'el-icon-check'" style="color: red;"
          ></i>
          <span :class="symptomList[9].isNormal === 'normal'? '' : 'red-label'">ต่อมน้ำเหลือง</span>
        </el-menu-item>
        <el-menu-item :index="cardNames.BRAIN">
          <i
            :class="symptomList[10].isNormal === 'normal'? '' : 'el-icon-check'" style="color: red;"
          ></i>
          <span :class="symptomList[10].isNormal === 'normal'? '' : 'red-label'">ระบบประสาท</span>
        </el-menu-item>
      </el-menu>
    </el-col>

    <el-col :span="13" class="menu-right">
      <div class="card" v-if="cardNames.EYE===selectedCategory">
        <h3 align="center">ตา</h3>
        <div>
          <Label class="row">ผลการสังเกต</Label>
          <div class="row">
            <el-radio-group
              v-model="symptomList[0].isNormal"
              size="normal"
              @change="onToggleIsNormal($event, 0)"
            >
              <el-radio-button style="margin-left: 10px;" :disabled="disabled" label="normal">ปกติ</el-radio-button>
              <el-radio-button :disabled="disabled" label="abnormal">ผิดปกติ</el-radio-button>
            </el-radio-group>
          </div>
        </div>

        <div class="table-row row">
          <div class="row-left"></div>

          <div class="row-right">ซ้าย</div>

          <div class="row-right">ขวา</div>
        </div>

        <div class="table-row row">
          <div class="row-left">
            <label>ตาอักเสบ</label>
          </div>

          <div class="row-right">
            <input
              :disabled="disabled"
              type="checkbox"
              v-model="symptomList[0].symptomDetail[0].status.left"
              @click="onCoupleSymptomChange($event,symptomList[0].symptomDetail[0].symptomName, couple.LEFT, 0)"
            />
          </div>

          <div class="row-right">
            <input
              :disabled="disabled"
              type="checkbox"
              v-model="symptomList[0].symptomDetail[0].status.right"
              @click="onCoupleSymptomChange($event,symptomList[0].symptomDetail[0].symptomName, couple.RIGHT, 0)"
            />
          </div>
        </div>

        <div class="table-row row">
          <div class="row-left">
            <label>กระจกตาขุ่น</label>
          </div>

          <div class="row-right">
            <input
              :disabled="disabled"
              type="checkbox"
              v-model="symptomList[0].symptomDetail[1].status.left"
              @click="onCoupleSymptomChange($event,symptomList[0].symptomDetail[1].symptomName, couple.LEFT, 0)"
            />
          </div>

          <div class="row-right">
            <input
              :disabled="disabled"
              type="checkbox"
              v-model="symptomList[0].symptomDetail[1].status.right"
              @click="onCoupleSymptomChange($event,symptomList[0].symptomDetail[1].symptomName, couple.RIGHT, 0)"
            />
          </div>
        </div>

        <div class="table-row row">
          <div class="row-left">
            <label>มีแผลหลุม</label>
          </div>

          <div class="row-right">
            <input
              :disabled="disabled"
              type="checkbox"
              v-model="symptomList[0].symptomDetail[2].status.left"
              @click="onCoupleSymptomChange($event,symptomList[0].symptomDetail[2].symptomName, couple.LEFT, 0)"
            />
          </div>

          <div class="row-right">
            <input
              :disabled="disabled"
              type="checkbox"
              v-model="symptomList[0].symptomDetail[2].status.right"
              @click="onCoupleSymptomChange($event,symptomList[0].symptomDetail[2].symptomName, couple.RIGHT, 0)"
            />
          </div>
        </div>

        <div class="table-row row">
          <div class="row-left">
            <label>ความดันตา</label>
          </div>

          <div class="row-right">
            <input
              :disabled="disabled"
              type="checkbox"
              v-model="symptomList[0].symptomDetail[3].status.left"
              @click="onCoupleSymptomChange($event,symptomList[0].symptomDetail[3].symptomName, couple.LEFT, 0)"
            />
          </div>

          <div class="row-right">
            <input
              :disabled="disabled"
              type="checkbox"
              v-model="symptomList[0].symptomDetail[3].status.right"
              @click="onCoupleSymptomChange($event,symptomList[0].symptomDetail[3].symptomName, couple.RIGHT, 0)"
            />
          </div>
        </div>

        <div class="table-row">
          <el-input
            :disabled="disabled"
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 3}"
            placeholder="บันทึกเพิ่มเติม ..."
            v-model="symptomList[0].note"
          ></el-input>
        </div>
      </div>

      <div class="card" v-if="cardNames.EAR===selectedCategory">
        <h3 align="center">หู</h3>
        <div>
          <div>
            <Label class="row">ผลการสังเกต</Label>
            <div class="row">
              <el-radio-group
                v-model="symptomList[1].isNormal"
                size="normal"
                @change="onToggleIsNormal($event, 1)"
              >
                <el-radio-button style="margin-left: 10px;" :disabled="disabled" label="normal">ปกติ</el-radio-button>
                <el-radio-button :disabled="disabled" label="abnormal">ผิดปกติ</el-radio-button>
              </el-radio-group>
            </div>
          </div>

          <div class="table-row row">
            <div class="row-left"></div>

            <div class="row-right">ซ้าย</div>

            <div class="row-right">ขวา</div>
          </div>

          <div class="table-row row">
            <div class="row-left">
              <label>ไม่ได้ยิน</label>
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[1].symptomDetail[0].status.left"
                @click="onCoupleSymptomChange($event,symptomList[1].symptomDetail[0].symptomName, couple.LEFT, 1)"
              />
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[1].symptomDetail[0].status.right"
                @click="onCoupleSymptomChange($event,symptomList[1].symptomDetail[0].symptomName, couple.RIGHT, 1)"
              />
            </div>
          </div>

          <div class="table-row row">
            <div class="row-left">
              <label>ขี้หูมาก</label>
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[1].symptomDetail[1].status.left"
                @click="onCoupleSymptomChange($event,symptomList[1].symptomDetail[1].symptomName, couple.LEFT, 1)"
              />
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[1].symptomDetail[1].status.right"
                @click="onCoupleSymptomChange($event,symptomList[1].symptomDetail[1].symptomName, couple.RIGHT, 1)"
              />
            </div>
          </div>

          <div class="table-row row">
            <div class="row-left">
              <label>มีกลิ่น</label>
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[1].symptomDetail[2].status.left"
                @click="onCoupleSymptomChange($event,symptomList[1].symptomDetail[2].symptomName, couple.LEFT, 1)"
              />
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[1].symptomDetail[2].status.right"
                @click="onCoupleSymptomChange($event,symptomList[1].symptomDetail[2].symptomName, couple.RIGHT, 1)"
              />
            </div>
          </div>

          <div class="table-row row">
            <div class="row-left">
              <label>หูอักเสบ</label>
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[1].symptomDetail[3].status.left"
                @click="onCoupleSymptomChange($event,symptomList[1].symptomDetail[3].symptomName, couple.LEFT, 1)"
              />
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[1].symptomDetail[3].status.right"
                @click="onCoupleSymptomChange($event,symptomList[1].symptomDetail[3].symptomName, couple.RIGHT, 1)"
              />
            </div>
          </div>

          <div class="table-row row">
            <div class="row-left">
              <label>ไรในหู</label>
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[1].symptomDetail[4].status.left"
                @click="onCoupleSymptomChange($event,symptomList[1].symptomDetail[4].symptomName, couple.LEFT, 1)"
              />
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[1].symptomDetail[4].status.right"
                @click="onCoupleSymptomChange($event,symptomList[1].symptomDetail[4].symptomName, couple.RIGHT, 1)"
              />
            </div>
          </div>

          <div class="table-row">
            <el-input
              :disabled="disabled"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 3}"
              placeholder="บันทึกเพิ่มเติม ..."
              v-model="symptomList[1].note"
            ></el-input>
          </div>
        </div>
      </div>

      <div class="card" v-if="cardNames.MOUTH===selectedCategory">
        <h3 align="center">ช่องปาก ฟัน เหงือก</h3>
        <div>
          <div>
            <Label class="row">ผลการสังเกต</Label>
            <div class="row">
              <el-radio-group
                v-model="symptomList[6].isNormal"
                size="normal"
                @change="onToggleIsNormal($event, 6)"
              >
                <el-radio-button style="margin-left: 10px;" :disabled="disabled" label="normal">ปกติ</el-radio-button>
                <el-radio-button :disabled="disabled" label="abnormal">ผิดปกติ</el-radio-button>
              </el-radio-group>
            </div>
          </div>

          <div class="table-row row">
            <div class="row-left">
              <label>มีกลิ่นปาก</label>
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[6].symptomDetail[0].status.single"
                @click="onSingleSymptomChange($event, symptomList[6].symptomDetail[0].symptomName, 6)"
              />
            </div>
          </div>

          <div class="table-row row">
            <div class="row-left">
              <label>เหงือกอักเสบ</label>
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[6].symptomDetail[1].status.single"
                @click="onSingleSymptomChange($event, symptomList[6].symptomDetail[1].symptomName, 6)"
              />
            </div>
          </div>

          <div class="table-row row">
            <div class="row-left">
              <label>มีหินปูน</label>
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[6].symptomDetail[2].status.single"
                @click="onSingleSymptomChange($event, symptomList[6].symptomDetail[2].symptomName, 6)"
              />
            </div>
          </div>

          <div class="table-row row">
            <div class="row-left">
              <label>ฟันผุ</label>
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[6].symptomDetail[3].status.single"
                @click="onSingleSymptomChange($event, symptomList[6].symptomDetail[3].symptomName, 6)"
              />
            </div>
          </div>

          <div class="table-row">
            <el-input
              :disabled="disabled"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 3}"
              placeholder="บันทึกเพิ่มเติม ..."
              v-model="symptomList[6].note"
            ></el-input>
          </div>
        </div>
      </div>

      <div class="card" v-if="cardNames.SKIN===selectedCategory">
        <h3 align="center">ผิวหนังและขน</h3>
        <div>
          <div>
            <Label class="row">ผลการสังเกต</Label>
            <div class="row">
              <el-radio-group
                v-model="symptomList[2].isNormal"
                size="normal"
                @change="onToggleIsNormal($event, 2)"
              >
                <el-radio-button style="margin-left: 10px;" :disabled="disabled" label="normal">ปกติ</el-radio-button>
                <el-radio-button :disabled="disabled" label="abnormal">ผิดปกติ</el-radio-button>
              </el-radio-group>
            </div>
          </div>

          <div class="table-row row">
            <div class="row-left">
              <label>ผิวหนังแห้ง มีรังแค</label>
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[2].symptomDetail[0].status.single"
                @click="onSingleSymptomChange($event, symptomList[2].symptomDetail[0].symptomName, 2)"
              />
            </div>
          </div>

          <div class="table-row row">
            <div class="row-left">
              <label>ผิวหนังมัน</label>
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[2].symptomDetail[1].status.single"
                @click="onSingleSymptomChange($event, symptomList[2].symptomDetail[1].symptomName, 2)"
              />
            </div>
          </div>

          <div class="table-row row">
            <div class="row-left">
              <label>ขนร่วง</label>
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[2].symptomDetail[2].status.single"
                @click="onSingleSymptomChange($event, symptomList[2].symptomDetail[2].symptomName, 2)"
              />
            </div>
          </div>

          <div class="table-row row">
            <div class="row-left">
              <label>มีผื่นคัน</label>
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[2].symptomDetail[3].status.single"
                @click="onSingleSymptomChange($event, symptomList[2].symptomDetail[3].symptomName, 2)"
              />
            </div>
          </div>

          <div class="table-row row">
            <div class="row-left">
              <label>มีปรสิต เห็บ หมัด ไร</label>
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[2].symptomDetail[4].status.single"
                @click="onSingleSymptomChange($event, symptomList[2].symptomDetail[4].symptomName, 2)"
              />
            </div>
          </div>

          <div class="table-row">
            <el-input
              :disabled="disabled"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 3}"
              placeholder="บันทึกเพิ่มเติม ..."
              v-model="symptomList[2].note"
            ></el-input>
          </div>
        </div>
      </div>

      <div class="card" v-if="cardNames.REPRODUCE===selectedCategory">
        <h3 align="center">ระบบปัสสาวะและสืบพันธุ์</h3>
        <div>
          <div>
            <Label class="row">ผลการสังเกต</Label>
            <div class="row">
              <el-radio-group
                v-model="symptomList[7].isNormal"
                size="normal"
                @change="onToggleIsNormal($event, 7)"
              >
                <el-radio-button style="margin-left: 10px;" :disabled="disabled" label="normal">ปกติ</el-radio-button>
                <el-radio-button :disabled="disabled" label="abnormal">ผิดปกติ</el-radio-button>
              </el-radio-group>
            </div>
          </div>

          <div class="table-row row">
            <div class="row-left">
              <label>ปัสสาวะผิดปกติ</label>
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[7].symptomDetail[0].status.single"
                @click="onSingleSymptomChange($event, symptomList[7].symptomDetail[0].symptomName, 7)"
              />
            </div>
          </div>

          <div class="table-row row">
            <div class="row-left">
              <label>มีเนื้องอก</label>
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[7].symptomDetail[1].status.single"
                @click="onSingleSymptomChange($event, symptomList[7].symptomDetail[1].symptomName, 7)"
              />
            </div>
          </div>

          <div class="table-row row">
            <div class="row-left">
              <label>นิ่ว</label>
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[7].symptomDetail[2].status.single"
                @click="onSingleSymptomChange($event, symptomList[7].symptomDetail[2].symptomName, 7)"
              />
            </div>
          </div>

          <div class="table-row">
            <el-input
              :disabled="disabled"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 3}"
              placeholder="บันทึกเพิ่มเติม ..."
              v-model="symptomList[7].note"
            ></el-input>
          </div>
        </div>
      </div>

      <div class="card" v-if="cardNames.DIGEST===selectedCategory">
        <h3 align="center">ระบบทางเดินอาหาร</h3>
        <div>
          <div>
            <Label class="row">ผลการสังเกต</Label>
            <div class="row">
              <el-radio-group
                v-model="symptomList[3].isNormal"
                size="normal"
                @change="onToggleIsNormal($event, 3)"
              >
                <el-radio-button style="margin-left: 10px;" :disabled="disabled" label="normal">ปกติ</el-radio-button>
                <el-radio-button :disabled="disabled" label="abnormal">ผิดปกติ</el-radio-button>
              </el-radio-group>
            </div>
          </div>

          <div class="table-row row">
            <div class="row-left">
              <label>ท้องเสีย</label>
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[3].symptomDetail[0].status.single"
                @click="onSingleSymptomChange($event, symptomList[3].symptomDetail[0].symptomName, 3)"
              />
            </div>
          </div>

          <div class="table-row row">
            <div class="row-left">
              <label>อาเจียน</label>
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[3].symptomDetail[1].status.single"
                @click="onSingleSymptomChange($event, symptomList[3].symptomDetail[1].symptomName, 3)"
              />
            </div>
          </div>

          <div class="table-row row">
            <div class="row-left">
              <label>ท้องผูก</label>
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[3].symptomDetail[2].status.single"
                @click="onSingleSymptomChange($event, symptomList[3].symptomDetail[2].symptomName, 3)"
              />
            </div>
          </div>

          <div class="table-row">
            <el-input
              :disabled="disabled"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 3}"
              placeholder="บันทึกเพิ่มเติม ..."
              v-model="symptomList[3].note"
            ></el-input>
          </div>
        </div>
      </div>

      <div class="card" v-if="cardNames.BREATH===selectedCategory">
        <h3 align="center">ระบบทางเดินหายใจ</h3>
        <div>
          <div>
            <Label class="row">ผลการสังเกต</Label>
            <div class="row">
              <el-radio-group
                v-model="symptomList[5].isNormal"
                size="normal"
                @change="onToggleIsNormal($event, 5)"
              >
                <el-radio-button style="margin-left: 10px;" :disabled="disabled" label="normal">ปกติ</el-radio-button>
                <el-radio-button :disabled="disabled" label="abnormal">ผิดปกติ</el-radio-button>
              </el-radio-group>
            </div>
          </div>

          <div class="table-row row">
            <div class="row-left">
              <label>หายใจลำบาก</label>
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[5].symptomDetail[0].status.single"
                @click="onSingleSymptomChange($event, symptomList[5].symptomDetail[0].symptomName, 5)"
              />
            </div>
          </div>

          <div class="table-row row">
            <div class="row-left">
              <label>ไอ</label>
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[5].symptomDetail[1].status.single"
                @click="onSingleSymptomChange($event, symptomList[5].symptomDetail[1].symptomName, 5)"
              />
            </div>
          </div>

          <div class="table-row row">
            <div class="row-left">
              <label>มีน้ำมูก</label>
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[5].symptomDetail[2].status.single"
                @click="onSingleSymptomChange($event, symptomList[5].symptomDetail[2].symptomName, 5)"
              />
            </div>
          </div>

          <div class="table-row">
            <el-input
              :disabled="disabled"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 3}"
              placeholder="บันทึกเพิ่มเติม ..."
              v-model="symptomList[5].note"
            ></el-input>
          </div>
        </div>
      </div>

      <div class="card" v-if="cardNames.BONE===selectedCategory">
        <h3 align="center">ระบบกระดูกและกล้ามเนื้อ</h3>
        <div>
          <div>
            <Label class="row">ผลการสังเกต</Label>
            <div class="row">
              <el-radio-group
                v-model="symptomList[8].isNormal"
                size="normal"
                @change="onToggleIsNormal($event, 8)"
              >
                <el-radio-button style="margin-left: 10px;" :disabled="disabled" label="normal">ปกติ</el-radio-button>
                <el-radio-button :disabled="disabled" label="abnormal">ผิดปกติ</el-radio-button>
              </el-radio-group>
            </div>
          </div>

          <div class="table-row row">
            <div class="row-left">
              <label>กระเพลก</label>
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[8].symptomDetail[0].status.single"
                @click="onSingleSymptomChange($event, symptomList[8].symptomDetail[0].symptomName, 8)"
              />
            </div>
          </div>

          <div class="table-row row">
            <div class="row-left">
              <label>หัก/ผิดรูป</label>
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[8].symptomDetail[1].status.single"
                @click="onSingleSymptomChange($event, symptomList[8].symptomDetail[1].symptomName, 8)"
              />
            </div>
          </div>

          <div class="table-row row">
            <div class="row-left">
              <label>อักเสบ/เลื่อน</label>
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[8].symptomDetail[2].status.single"
                @click="onSingleSymptomChange($event, symptomList[8].symptomDetail[2].symptomName, 8)"
              />
            </div>
          </div>

          <div class="table-row">
            <el-input
              :disabled="disabled"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 3}"
              placeholder="บันทึกเพิ่มเติม ..."
              v-model="symptomList[8].note"
            ></el-input>
          </div>
        </div>
      </div>

      <div class="card" v-if="cardNames.HEART===selectedCategory">
        <h3 align="center">หัวใจและระบบไหลเวียนโลหิต</h3>
        <div>
          <div>
            <Label class="row">ผลการสังเกต</Label>
            <div class="row">
              <el-radio-group
                v-model="symptomList[4].isNormal"
                size="normal"
                @change="onToggleIsNormal($event, 4)"
              >
                <el-radio-button style="margin-left: 10px;" :disabled="disabled" label="normal">ปกติ</el-radio-button>
                <el-radio-button :disabled="disabled" label="abnormal">ผิดปกติ</el-radio-button>
              </el-radio-group>
            </div>
          </div>

          <div class="table-row row">
            <div class="row-left">
              <label>มีเสียงแทรก</label>
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[4].symptomDetail[0].status.single"
                @click="onSingleSymptomChange($event, symptomList[4].symptomDetail[0].symptomName, 4)"
              />
            </div>
          </div>

          <div class="table-row row">
            <div class="row-left">
              <label>เต้นไม่เป็นจังหวะ</label>
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[4].symptomDetail[1].status.single"
                @click="onSingleSymptomChange($event, symptomList[4].symptomDetail[1].symptomName, 4)"
              />
            </div>
          </div>

          <div class="table-row row">
            <div class="row-left">
              <label>หัวใจโต</label>
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[4].symptomDetail[2].status.single"
                @click="onSingleSymptomChange($event, symptomList[4].symptomDetail[2].symptomName, 4)"
              />
            </div>
          </div>

          <div class="table-row">
            <el-input
              :disabled="disabled"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 3}"
              placeholder="บันทึกเพิ่มเติม ..."
              v-model="symptomList[4].note"
            ></el-input>
          </div>
        </div>
      </div>

      <div class="card" v-if="cardNames.LYMPH===selectedCategory">
        <h3 align="center">ต่อมน้ำเหลือง</h3>
        <div>
          <div>
            <Label class="row">ผลการสังเกต</Label>
            <div class="row">
              <el-radio-group
                v-model="symptomList[9].isNormal"
                size="normal"
                @change="onToggleIsNormal($event, 9)"
              >
                <el-radio-button style="margin-left: 10px;" :disabled="disabled" label="normal">ปกติ</el-radio-button>
                <el-radio-button :disabled="disabled" label="abnormal">ผิดปกติ</el-radio-button>
              </el-radio-group>
            </div>
          </div>

          <div class="table-row row">
            <div class="row-left">
              <label>บวมโต</label>
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[9].symptomDetail[0].status.single"
                @click="onSingleSymptomChange($event, symptomList[9].symptomDetail[0].symptomName, 9)"
              />
            </div>
          </div>

          <div class="table-row row">
            <div class="row-left">
              <label>เจ็บ</label>
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[9].symptomDetail[1].status.single"
                @click="onSingleSymptomChange($event, symptomList[9].symptomDetail[1].symptomName, 9)"
              />
            </div>
          </div>

          <div class="table-row row">
            <div class="row-left">
              <label>อักเสบ</label>
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[9].symptomDetail[2].status.single"
                @click="onSingleSymptomChange($event, symptomList[9].symptomDetail[2].symptomName, 9)"
              />
            </div>
          </div>

          <div class="table-row">
            <el-input
              :disabled="disabled"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 3}"
              placeholder="บันทึกเพิ่มเติม ..."
              v-model="symptomList[9].note"
            ></el-input>
          </div>
        </div>
      </div>

      <div class="card" v-if="cardNames.BRAIN===selectedCategory">
        <h3 align="center">ระบบประสาท</h3>
        <div>
          <div>
            <Label class="row">ผลการสังเกต</Label>
            <div class="row">
              <el-radio-group
                v-model="symptomList[10].isNormal"
                size="normal"
                @change="onToggleIsNormal($event, 10)"
              >
                <el-radio-button style="margin-left: 10px;" :disabled="disabled" label="normal">ปกติ</el-radio-button>
                <el-radio-button :disabled="disabled" label="abnormal">ผิดปกติ</el-radio-button>
              </el-radio-group>
            </div>
          </div>

          <div class="table-row row">
            <div class="row-left">
              <label>ชัก/กระตุก</label>
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[10].symptomDetail[0].status.single"
                @click="onSingleSymptomChange($event, symptomList[10].symptomDetail[0].symptomName, 10)"
              />
            </div>
          </div>

          <div class="table-row row">
            <div class="row-left">
              <label>อ่อนแรง</label>
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[10].symptomDetail[1].status.single"
                @click="onSingleSymptomChange($event, symptomList[10].symptomDetail[1].symptomName, 10)"
              />
            </div>
          </div>

          <div class="table-row row">
            <div class="row-left">
              <label>อัมพฤกษ์/อัมพาต</label>
            </div>

            <div class="row-right">
              <input
                :disabled="disabled"
                type="checkbox"
                v-model="symptomList[10].symptomDetail[2].status.single"
                @click="onSingleSymptomChange($event, symptomList[10].symptomDetail[2].symptomName, 10)"
              />
            </div>
          </div>

          <div class="table-row">
            <el-input
              :disabled="disabled"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 3}"
              placeholder="บันทึกเพิ่มเติม ..."
              v-model="symptomList[10].note"
            ></el-input>
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
let CardNames = {
  EYE: "Eye",
  EAR: "Ear",
  MOUTH: "Mouth",
  SKIN: "Skin",
  BREATH: "Breath",
  REPRODUCE: "Reproduce",
  DIGEST: "Digest",
  BONE: "Bone",
  HEART: "Heart",
  LYMPH: "Lymph",
  BRAIN: "Brain",
  WEIGHT: "Weight"
}

let Couple = {
  LEFT: "left",
  RIGHT: "right",
  COUPLE: 'couple',
  SINGLE: 'single'
}

export default {
  data() {
    return {
      symptomList: this.symptoms.symptomCheckingList,
      cardNames: CardNames,
      couple: Couple,
      selectedCategory: CardNames.EYE,
      testTrue: true,
      testFalse: false
    }
  },
  props: ["symptoms", "disabled"],
  mounted: function() {
    this.selectedCategory = CardNames.EYE
  },
  methods: {
    menuClicked: function(categoryName) {
      this.selectedCategory = categoryName
    },
    toggleSelectAllSymptom: function() {},
    onCoupleSymptomChange: function(e, symptomName, organSide, organIndex) {
      var isNormal = this.symptomList[organIndex].isNormal
      var status = e.srcElement.checked

      if (!status) {
        isNormal = "normal"
      }
      // Update symptom status
      for (var key in this.symptomList[organIndex].symptomDetail) {
        var detail = this.symptomList[organIndex].symptomDetail
        if (detail[key].symptomName === symptomName) {
          if (organSide === this.couple.LEFT) {
            detail[key].status.left = status
          } else {
            detail[key].status.right = status
          }
        }
      }

      for (var symptomKey in this.symptomList[organIndex].symptomDetail) {
        var symptomDetail = this.symptomList[organIndex].symptomDetail
        if (
          symptomDetail[symptomKey].status.left ||
          symptomDetail[symptomKey].status.right
        ) {
          isNormal = "abnormal"
        }
      }
      this.symptomList[organIndex].isNormal = isNormal
    },
    onSingleSymptomChange: function(e, symptomName, organIndex) {
      var isNormal = this.symptomList[organIndex].isNormal
      var status = e.srcElement.checked

      if (!status) {
        isNormal = "normal"
      }

      // Update symptom status
      for (var key in this.symptomList[organIndex].symptomDetail) {
        var detail = this.symptomList[organIndex].symptomDetail
        if (detail[key].symptomName === symptomName) {
          detail[key].status.single = status
        }
      }

      for (var symptomKey in this.symptomList[organIndex].symptomDetail) {
        var symptomDetail = this.symptomList[organIndex].symptomDetail
        if (symptomDetail[symptomKey].status.single) {
          isNormal = "abnormal"
        }
      }
      this.symptomList[organIndex].isNormal = isNormal
    },
    onToggleIsNormal: function(status, symptomIndex) {
      var organType = this.symptomList[symptomIndex].organType
      for (var key in this.symptomList[symptomIndex].symptomDetail) {
        var symptomDetail = this.symptomList[symptomIndex].symptomDetail
        if (status === "normal" && organType === Couple.COUPLE) {
          symptomDetail[key].status.left = false
          symptomDetail[key].status.right = false
        }
        if (status === "normal" && organType === Couple.SINGLE) {
          symptomDetail[key].status.single = false
        }
      }
    }
  }
}
</script>

<style>
.table-row {
  margin-top: 10px;
}
.red-label {
  color: red;
  font-weight: bold;
}
.card {
  margin: 0 0 0 10px;
  border-radius: 4px;
  border: solid 1px #e6eaee;
  display: flex;
  flex-direction: column;
}
.menu-left {
  font-size: 12px;
}
.menu-right {
  align-self: flex-start;
  font-size: 14px;
}
.row {
  display: flex;
  margin-left: 10px;
}
.row-left {
  margin-left: 10px;
  width: 60%;
}
.row-right {
  width: 15%
}
input[type="checkbox"] {
  width: 20px;
  height: 20px;
}
</style>
