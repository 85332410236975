<template>
  <div class="form-block">
    <div class="header-block">
      <div class="title">
        Please Enter<br>Your New Password
      </div>
    </div>
    <el-form ref="form" :model="form">
      <el-form-item>
        <el-input
          class="pet-owner-register"
          prefix-icon="far fa-user"
          type="tel"
          :disabled="true"
          v-validate="'required'"
          name="telephone"
          placeholder="Telephone"
          v-model="form.telephone"></el-input>
        <span class="error">{{ errors.first('telephone') }}</span>
      </el-form-item>

      <el-form-item>
        <el-input class="pet-owner-register"
          prefix-icon="fas fa-unlock-alt"
          type="password"
          v-validate="'required'"
          name="password"
          ref="password"
          placeholder="Your New Password"
          v-model="form.password"></el-input>
        <span class="error">{{ errors.first('password') }}</span>
      </el-form-item>

      <el-form-item>
        <el-input class="pet-owner-register"
          prefix-icon="fas fa-unlock-alt"
          type="password"
          v-validate="'required|confirmed:password'"
          name="passwordConfirm"
          placeholder="Re-Password"
          v-model="form.passwordConfirm"></el-input>
        <span class="error">{{ errors.first('passwordConfirm') }}</span>
      </el-form-item>

      <el-form-item class="text-center">
        <el-button
          :loading="submitButtonLoading"
          type="submit"
          class="btn btn-primary form-submit-btn btn-custom"
          @click="onSubmit">done</el-button>
        <span class="error">{{ errMessage }}</span>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        telephone: '',
        token: '',
        password: '',
        verify_code: null,
      },
      errMessage: '',
      submitButtonLoading: false,
    }
  },
  mounted() {
    if (this.$store.state.owner.profile !=  null) {
      this.form.telephone = this.$store.state.owner.profile.phone
      this.form.token = this.$store.state.owner.profile.token
    }
  
    if (this.$route.params.phone != null) 
      this.form.telephone = this.$route.params.phone;
    if (this.$route.params.token != null) 
      this.form.token = this.$route.params.token;
    if (this.$route.query.verify_code != null) 
      this.form.verify_code = this.$route.query.verify_code;
  },
  methods: {
    async onGetOTP() {
      await this.$store.dispatch('owner/createPasswordOTP', {
        data: {
          phone: this.form.telephone,
        }
      })
      this.$message('VRemind verification code sent. รหัสจะหมดอายุใน 5 นาที)')
      this.$router.push({ name: 'petOwnerRegisterOTP', 
        params: {
          phone: this.form.telephone
        },
        query: {
          verify_code: 'owner_create_password'
        }
      })
    },
    async onSubmit() {
      this.submitButtonLoading = true
      const valid = await this.$validator.validateAll()
      if (valid) {
        if (this.form.verify_code != null) {
          try {
            if (this.form.verify_code == 'owner_create_password') {
              try {
                await this.$store.dispatch('owner/createPassword', {
                data: {
                  phone: this.form.telephone,
                  password: this.form.password,
                  token: this.form.token
                }
              })
                await this.$store.dispatch('auth/ownerLogin', {
                  data: {
                    telephone: this.form.telephone,
                    password: this.form.password
                  }
                })
                this.$router.push({ name: 'petOwnerHome' })
              } catch (e) {
                this.$message("เกิดข้อผิดพลาดที่ไม่รู้จัก กรุณาติดต่อผู้ดูแลระบบ")
              } 
            } else {
              await this.$store.dispatch('owner/ownerVerify', {
                data: {
                  phone: this.form.telephone,
                  password: this.form.password,
                  verify_code: this.form.verify_code
                }
              })
              await this.$store.dispatch('auth/ownerLogin', {
                data: {
                  telephone: this.form.telephone,
                  password: this.form.password
                }
              })
              this.$router.push({ name: 'petOwnerHome' })
            }
          } catch (e) {
            this.onGetOTP();
          }
        } else {
          try {
            await this.$store.dispatch('owner/ownerUpdatePassword', {
              data: {
                phone: this.form.telephone,
                token: this.form.token,
                password: this.form.password,
              }
            })
            const result = this.$store.state.owner.profile; 
            if (result.phone != null) {
              this.$message('เปลี่ยนรหัสผ่านสำเร็จ! กรุณาเข้าสู่ระบบ')
              this.$router.push({ name: 'petOwnerLogin' })
            } else {
              switch (result.status_code) {
                case 500: this.$message('ใช้เวลาดำเนินการนานเกินไป กรุณาทำรายการใหม่อีกครั้ง'); break;
              }
              this.$router.push({ name: 'petOwnerForgetPassword' })
            }
          } catch (e) {
            this.$message('เกิดข้อผิดพลาดที่ไม่รู้จัก กรุณาทำรายการใหม่อีกครั้ง')
            this.$router.push({ name: 'petOwnerForgetPassword' })
          }
        }
      }
      this.submitButtonLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.form-block {
  @extend %block-container;
  @extend %form-style;
  height: 470px;

  .title {
    // margin-bottom: 30px;
    // height: 20px;
    font-size: 18px !important;
    font-weight: bold;
    text-align: center;
    color: #4a4a4a;
  }

  .el-button {
    margin-top: 10px;
  }
}
.footer-block {
  text-align: center;
  span {
    color: $gray;
  }
  a {
    text-decoration: none;
    color: #000;
  }
}
</style>

<style lang="scss" scoped>
button.btn-custom {
  width: 213px !important;
  font-size: 17px !important;
}
</style>
