<template>
  <div>Connecting...</div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      hospitalConnection: state => state.owner.hospitalConnection
    }),
  },
  async mounted() {
    try {
      if (this.$route.query.secret_code != null) 
      {    
        await this.$store.dispatch("owner/getOwnerHospitalConnection", {
          params: { secret_code: this.$route.query.secret_code },
        })

        localStorage.setItem("secret_code", this.$route.query.secret_code);

        this.$router.push({name: 'petOwnerHome'})
        this.$notify({
          message: `เชื่อมต่อ ${this.hospitalConnection.hospital.name} สำเร็จ`,
          type: "success"
        })
        return;
      }
    } catch (error) {
      this.$router.push({name: 'petOwnerHome'})
      this.$notify({
        message: `เกิดข้อผิดพลาดของระบบ ไม่สามารถเชื่อมต่อโรงพยาบาล`,
        type: "error"
      })
      return;
    }
    this.$router.push({name: 'petOwnerHome'})
    this.$notify({
      message: `เชื่อมต่อโรงพยาบาลไม่สำเร็จ เนื่องจาก QR Code ไม่ถูกต้อง`,
      type: "error"
    })
  }
}
</script>