<template>
  <div :id="`detail-pdf-${drug.id}`" class="detail-pdf" style="position: relative; height: 340px; width: 494px;">
    <img v-if="nameFlag" crossOrigin="Anonymous" :id="`hospital-logo-${drug.id}`" style="position: absolute; top: 13px; left: 8px; object-fit: contain;" width="40px" height="40px"/>
    <div v-if="nameFlag" class="hospital-name" style="position: absolute; top: 18px; left: 59px;">{{ hospital.name }}</div>
    <div class="medical-date" style="position: absolute; top: 78px; left: 8px;">{{ drug.medicalrecord.arrived | toThaiDate }}</div>
    <div v-if="hn" class="hn" style="position: absolute; top: 78px; left: 140px;">HN :</div>
    <div class="hn-value" style="position: absolute; top: 78px; left: 175px;">{{ hn }}</div>
    <div class="pet-type" style="position: absolute; top: 78px; left: 330px;">{{ pet.type ? pet.type.name : '' }}</div>
    <div class="pet-name" style="position: absolute; top: 100px; left: 8px;">{{ pet.name }}</div>
    <div class="age" style="position: absolute; top: 97px; left: 300px;">Age :</div>
    <div class="owner" style="position: absolute; top: 115px; left: 300px;">Owner :</div>
    <div class="age-value" style="position: absolute; top: 97px; left: 350px;">{{ getPetAge(new Date(pet.birthday)) }}</div>
    <div class="owner-value" style="position: absolute; top: 115px; left: 350px;">{{ getPetOwner() }}</div>
    <div 
      class="product-value" 
      :class="[
        !productFlag ? 'transparent-text' : '',
      ]"
      style="position: absolute; top: 135px; left: 8px;"
    >{{ getProductName() }}</div>
    <div class="detail" style="position: absolute; top: 155px; left: 8px;">
      <div>{{ getMedicalUse() + (drug.dose ? ' ครั้งละ ' + drug.dose + ' ' + getMedicationUnit(drug.unit) : '') }}</div>
      <div>{{ getCondition(drug.taken_condition) }}</div>
    </div>
    <div class="taken-time" style="position: absolute; top: 215px; left: 8px;">
      <div v-for="taken in drug.taken_frequency" :key="taken">
        {{ frequency[taken] }}
      </div>
    </div>
    <div v-if="drug.taken_hour_frequency" class="detail-other" style="position: absolute; top: 250px; left: 8px;">
      {{ `ทุกๆ ${drug.taken_hour_frequency} ชั่วโมง` }}
    </div>
    <div class="note" style="position: absolute; top: 266px; left: 8px;">{{ drug.note }}</div>
    <div class="properties" style="position: absolute; top: 283px; left: 8px;">{{ drug.medication_properties + ' ' + (drug.medication_caution ? drug.medication_caution : '') }}</div>
    <div class="other-checkbox" style="position: absolute; top: 302px; left: 8px;">
      <div v-html="getWarningText()"></div>
    </div>
    <div v-if="drug.amount" class="amount">
      {{ drug.amount }}
      <span class="amount-unit" v-if="drug.product && drug.product.unit">
        {{ drug.product.unit.name }}</span>
    </div>
    <!-- <div class="medical-id">{{ drug.medicalrecord.id }}</div> -->
    <div class="vet-name">{{ getVetName() }}</div>
  </div>
</template>

<script>

function convertImgToBase64URL(url, callback, outputFormat){
  var img = new Image();
  img.crossOrigin = 'Anonymous';
  img.onload = function(){
      var canvas = document.createElement('CANVAS'),
        ctx = canvas.getContext('2d'), dataURL;
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0);
      dataURL = canvas.toDataURL(outputFormat);
      callback(dataURL);
      canvas = null;
  };
  img.src = url;
}

export default {
  props: [
    'drug',
    'pet',
    'hospital',
    'hn',
    'nameFlag',
    'productFlag',
    'medicationUse',
    'medicationUnits'
  ],
  components: {
  },
  data() {
    return {
      condition: {
        1: "ก่อนอาหาร",
        2: "หลังอาหาร",
        3: "พร้อมอาหาร"
      },
      frequency: {
        1: "เช้า",
        2: "กลางวัน",
        3: "เย็น",
        4: "ก่อนนอน",
        5: "เมื่อมีอาการ"
      }
    }
  },
  mounted() {
    this.nameFlag && this.logo()
  },
  watch: {
    nameFlag(newValue) {
      if(newValue) {
        this.logo()
      }
    }
  },
  methods: {
    getVetName() {
      if (this.drug.medicalrecord.vet) {
        return this.drug.medicalrecord.vet.full_name
      }
      return ''
    },
    getPetOwner() {
      if (this.pet.owners.length > 0) {
        return this.pet.owners[0].firstname + ' ' + this.pet.owners[0].lastname
      } else {
        return '-'
      }
    },
    getProductName() {
      let result = ''
      if (this.drug.product) result = this.drug.product.trade_name
      if (this.drug.product && this.drug.product.generic_name) result += ' (' + this.drug.product.generic_name + ')'
      return result
    },
    getPetAge(date) {
      if (date == null) {
        return '-'
      }
      var currentDate = new Date()
      var timeDiff = Math.abs(currentDate.getTime() - date.getTime())
      var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
      var diffMonth = parseInt(diffDays / 30 % 12)
      var diffYear = parseInt(diffDays / 30 / 12)
      return (diffYear > 0 ? diffYear + ' ปี ' : '') + (diffMonth > 0 || diffYear <= 0 ? diffMonth + ' เดือน' : '')
    },
    getMedicalUse() {
      const use = this.medicationUse.filter(m => m.id === this.drug.medication_use)
      const location = this.drug.location ? this.drug.location : "";
      return use.length > 0 ? (use[0].name + ' ' + location) : '';
    },
    getMedicationUnit(unitId) {
      const unit = this.medicationUnits.filter(m => m.id === unitId)
      return unit.length > 0 ? unit[0].name : ''
    },
    getCondition(condition) {
      if (this.condition[condition]) {
        // let times = ''
        // if (this.drug.taken_times_per_day) times = this.drug.taken_times_per_day
        // else times = this.drug.taken_frequency.length
        let times = this.drug.taken_frequency.length
        return  this.condition[condition] + ' ' + this.drug.taken_condition_suffix + ' วันละ ' + times + ' ครั้ง'
      } else {
        return ''
      }
    },
    logo() {
      if (this.drug && this.hospital.logo.small) {
        const logoId = `hospital-logo-${this.drug.id}`
        convertImgToBase64URL(this.hospital.logo.small, function(base64Img){
            document.getElementById(logoId).src = base64Img
        })
      }
    },
    getWarningText() {
      let warings = []

      if (this.drug.is_external_use) warings.push("*ห้ามรับประทาน&nbsp;&nbsp;")
      if (this.drug.is_keep_cool) warings.push("*เก็บในตู้เย็น&nbsp;&nbsp;")
      if (this.drug.is_danger) warings.push("*ยาอันตราย&nbsp;&nbsp;")
      if (this.drug.is_need_shake) warings.push("*เขย่าก่อนใช้&nbsp;&nbsp;")
      if (warings.length > 2) warings.splice(2, 0, '<br>')

      return warings.join('')
    }
  }
}
</script>

<style lang="scss" scoped>
.transparent-text {
  color: transparent;
}

.hospital-name {
  font-family: Arial;
  font-size: 24px;
  font-weight: bold;
}
.medical-date {
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
}
.medical-time {
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
}
.hn {
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
}
.hn-value {
  font-family: Arial;
  font-size: 14px;
  font-weight: bold;
}
.pet-type {
  font-family: Arial;
  font-size: 13px;
  font-weight: normal;
  white-space: nowrap;
}
.pet-name {
  font-family: Arial;
  font-size: 24px;
  font-weight: bold;
}
.age {
  font-family: Arial;
  font-size: 12px;
  font-weight: normal;
}
.owner {
  font-family: Arial;
  font-size: 12px;
  font-weight: normal;
}
.age-value {
  font-family: Arial;
  font-size: 12px;
  font-weight: bold;
}
.owner-value {
  font-family: Arial;
  font-size: 12px;
  font-weight: bold;
}
.product-value {
  font-family: Arial;
  font-size: 16px;
  font-weight: normal;
}
.product {
  font-family: Arial;
  font-size: 12px;
  font-weight: normal;
}
.product-name {
  font-family: Arial;
  font-size: 12px;
  font-weight: normal;
}
.detail {
  font-family: Arial;
  font-size: 16px;
  font-weight: bold;
}
.taken-time {
  width: 80%;
  display: flex;
  align-items: center;
  div {
    font-family: Arial;
    font-size: 14px;
    font-weight: bold;
    color: #030202;
    text-align: center;
    padding: 5px 10px;
    margin-right: 20px;
    width: auto;
    height: 20px;
    border: solid 1px #cfcfcf;
    border-radius: 5px;
    background-color: #cfcfcf;
  }
}
.detail-other {
  font-family: Arial;
  font-size: 16px;
  font-weight: normal;
}
.note {
  font-family: Arial;
  font-size: 15px;
  font-weight: normal;
}
.properties {
  font-family: Arial;
  font-size: 15px;
  font-weight: normal;
}
.other-checkbox {
  width: 45%;
  display: flex;
  align-items: center;
  div {
    margin-right: 10px;
    width: auto;
    font-family: Arial;
    font-size: 13px;
    font-weight: bold;
  }
}
.amount {
  font-family: Arial;
  font-size: 20px;
  font-weight: normal;
  position: relative;
  top: 290px;
  width: 55%;
  text-align: right;
  float: right;
  margin-right: 30px;

  .amount-unit {
    font-family: Arial;
    font-size: 17px;
    font-weight: normal;
    margin-left: 10px;
  }
}

.vet-name {
  font-family: Arial;
  font-size: 12px;
  font-weight: normal;
  white-space: nowrap;
  position: relative;
  top: 295px;
  width: 55%;
  text-align: right;
  float: right;
  margin-right: 30px;
}
</style>

