const Ipd = () => import("@/pages/ipd/Ipd");
const IpdDetail = () => import("@/pages/ipd/IpdDetail");
const IpdMedicalRecord = () => import("@/pages/ipd/IpdMedicalRecord");
const IpdPayment = () => import("@/pages/ipd/IpdPayment");
const IpdDeposit = () => import("@/pages/ipd/IpdDeposit");
const IpdOverdue = () => import("@/pages/ipd/IpdOverdue");
const IpdPaid = () => import("@/pages/ipd/IpdPaid");
const IpdDeleted = () => import("@/pages/ipd/IpdDeleted");
const MedicalAddDetailPage = () =>
  import("@/pages/medical/MedicalAddDetailPage");
const IpdDepositCreate = () => import("@/pages/ipd/IpdDepositCreate");
const IpdCheckout = () => import("@/pages/ipd/IpdCheckout");
const MedicalDrugLabel = () => import("@/pages/medical/MedicalDrugLabel");
const MedicalDrugLabelDetail = () =>
  import("@/pages/medical/MedicalDrugLabelDetail");
const MedicalDrugLabelPDF = () => import("@/pages/medical/MedicalDrugLabelPDF");

const routes = [
  {
    path: "/ipd",
    name: "ipd",
    component: Ipd,
    meta: {
      requiresAuth: true,
      permissions: ["hospital_admin"],
      plan_permissions: ['ipd_section.can_use_ipd'],
    },
  },
  {
    path: "/ipd/deleted",
    name: "ipd-deleted",
    component: IpdDeleted,
    meta: {
      requiresAuth: true,
      permissions: ["hospital_admin"],
      plan_permissions: ['ipd_section.can_view_ipd_delete_list'],
    },
  },
  {
    path: "/ipd/:id",
    name: "ipd-detail",
    component: IpdDetail,
    meta: {
      requiresAuth: true,
      permissions: ["hospital_admin"],
      plan_permissions: ['ipd_section.can_use_ipd'],
    },
    redirect: { name: "ipd-medical-record" },
    children: [
      {
        path: "medical-record",
        name: "ipd-medical-record",
        component: IpdMedicalRecord,
        meta: {
          requiresAuth: true,
          permissions: ["hospital_admin"],
        },
      },
      {
        path: "payment",
        name: "ipd-payment",
        component: IpdPayment,
        meta: {
          requiresAuth: true,
          permissions: ["hospital_admin"],
        },
        redirect: { name: "ipd-deposit" },
        children: [
          {
            path: "deposit",
            name: "ipd-deposit",
            component: IpdDeposit,
            meta: {
              requiresAuth: true,
              permissions: ["hospital_admin"],
            },
          },
          {
            path: "overdue",
            name: "ipd-overdue",
            component: IpdOverdue,
            meta: {
              requiresAuth: true,
              permissions: ["hospital_admin"],
            },
          },
          {
            path: "paid",
            name: "ipd-paid",
            component: IpdPaid,
            meta: {
              requiresAuth: true,
              permissions: ["hospital_admin"],
            },
          },
        ],
      },
    ],
  },
  {
    path: "/ipd/:id/medical-record/create",
    name: "ipd-medical-record-create",
    component: MedicalAddDetailPage,
    meta: {
      requiresAuth: true,
      permissions: ["hospital_admin"],
      plan_permissions: ['ipd_section.can_use_ipd'],
      ipd: true,
    },
  },
  {
    path: "/ipd/:id/medical-record/:opdId",
    name: "ipd-medical-record-detail",
    component: MedicalAddDetailPage,
    meta: {
      requiresAuth: true,
      permissions: ["hospital_admin"],
      plan_permissions: ['ipd_section.can_use_ipd'],
      ipd: true,
    },
  },
  {
    path: "/ipd/:id/medical-record/:opdId/drug-label",
    name: "ipd-medical-record-detail-drug-label",
    component: MedicalDrugLabel,
    meta: {
      requiresAuth: true,
      permissions: ["hospital_admin"],
      ipd: true,
    },
  },
  {
    path: "/ipd/:id/medical-record/:opdId/drug-label/edit",
    name: "ipd-medical-record-detail-drug-label-edit",
    component: MedicalDrugLabelDetail,
    meta: {
      requiresAuth: true,
      permissions: ["hospital_admin"],
      ipd: true,
    },
  },
  {
    path: "/ipd/:id/medical-record/:opdId/drug-label/print",
    name: "ipd-medical-record-detail-drug-label-print",
    component: MedicalDrugLabelPDF,
    meta: {
      requiresAuth: true,
      permissions: ["hospital_admin"],
      ipd: true,
    },
  },
  {
    path: "/ipd/:id/payment/deposit/create",
    name: "ipd-deposit-create",
    component: IpdDepositCreate,
    meta: {
      requiresAuth: true,
      permissions: ["hospital_admin"],
    },
  },
  {
    path: "/ipd/:id/payment/overdue/checkout",
    name: "ipd-overdue-checkout",
    component: IpdCheckout,
    meta: {
      requiresAuth: true,
      permissions: ["hospital_admin"],
      partial: true,
    },
  },
  {
    path: "/ipd/:id/payment/checkout",
    name: "ipd-checkout",
    component: IpdCheckout,
    meta: {
      requiresAuth: true,
      permissions: ["hospital_admin"],
    },
  },
];

export default routes;
