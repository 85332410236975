<template>
  <div class="request-hospital-page">

    <!-- Sub Navbar -->
    <Subnavbar>
      <template slot="breadcrumb">
      </template>
      <template slot="title">BLOOD REQUEST BETWEEN HOSPITAL</template>
      <template
        slot="actions"
        class="action-wrapper"
      >
        <button
          class="btn btn-large btn-new-request"
          @click="newRequest"
        >
          <img src="@/assets/bloodbank/new-public-request.png" />
          + New Request
        </button>
      </template>
    </Subnavbar>

    <!-- Navigation -->
    <el-row :gutter="10">
      <el-col>
        <ContentBox class="blood-navigation">
          <h2 class="title">Navigation</h2>
          <el-row>
            <el-col :md="8">
              <el-radio-group
                v-model="stock"
                size="small"
              >
                <el-radio-button :label="myRequestBloodBag"></el-radio-button>
                <el-radio-button :label="myInbox"></el-radio-button>
              </el-radio-group>
            </el-col>

            <el-col :md="6">
              <el-select
                clearable
                placeholder="Pet Type"
                v-model="petType"
                size="small"
              >
                <el-option
                  :value="petTypeEnum.CAT"
                  label="CAT"
                ></el-option>
                <el-option
                  :value="petTypeEnum.DOG"
                  label="DOG"
                ></el-option>
              </el-select>
            </el-col>

            <el-col :md="8">
              <!--              <el-input placeholder="Blood Bag ID" size="small" v-model="bloodBagSearchText" clearable>-->
              <!--              </el-input>-->
              <el-input
                placeholder="Request ID"
                v-model="requestBeforeSearchText"
                class="search-input"
                size="small"
                clearable
                @keyup.enter.native="search"
                @clear="search"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="search"
                ></el-button>
              </el-input>
            </el-col>
          </el-row>
        </ContentBox>
      </el-col>
    </el-row>

    <!-- Tabs -->
    <el-row :gutter="10">
      <el-col>
        <ContentBox style="height: 100%; margin-top: 10px">
          <div class="table-container">
            <div class="table-container-header">
              <span class="total">{{ count | number }}</span> RESULT
            </div>

            <div class="table-detail">
              <blood-request-hospital-table
                :request-list="paginatedRequestList"
                @detail="onDetailClicked"
              >
              </blood-request-hospital-table>
            </div>

            <div
              class="not-found"
              v-if="count === 0"
            >ไม่พบข้อมูลที่ต้องการค้นหา</div>

            <el-pagination
              class="pagination"
              background
              layout="prev, pager, next"
              :current-page="currentPage"
              :page-size="pageSize"
              :total="count"
              @current-change="changePage"
            ></el-pagination>
          </div>
        </ContentBox>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Subnavbar from "../../../components/navigation/Subnavbar";
import ContentBox from "../../../components/container/ContentBox";
import petTypeEnum from "../../../constants/bloodbank/pet-type-enum";
import { mapState } from "vuex";
import statusEnum from "../../../constants/bloodbank/blood-request-hospital-status-enum";
import BloodRequestHospitalTable from "../../../components/bloodbank/blood-request-hospital/BloodRequestHospitalTable";

const myRequestBloodBag = "My Request Blood Bag";
const myInbox = "My Inbox";

export default {
  name: "BloodRequestHospitalPage",
  components: { BloodRequestHospitalTable, ContentBox, Subnavbar },
  data() {
    return {
      petTypeEnum,
      statusEnum,
      myRequestBloodBag,
      myInbox,

      currentPage: 1,
      pageSize: 10,

      petType: petTypeEnum.DOG,
      stock: myRequestBloodBag,
      requestBeforeSearchText: null,
      requestSearchText: null
    };
  },
  methods: {
    search() {
      this.requestSearchText = this.requestBeforeSearchText;
      this.changePage(1);
    },
    changePage(page) {
      this.currentPage = page;
      this.updateQueryString();
    },
    updateQueryString() {
      const query = {
        stock: this.stock,
        pet_type: this.petType
      };
      if (this.currentPage && this.currentPage > 1) {
        query.page = this.currentPage;
      }
      if (this.requestSearchText && this.requestSearchText !== "") {
        query.search = this.requestSearchText;
      }

      this.$router.replace({ query });
    },
    loadQueryString() {
      if (this.$route.query.page) {
        this.currentPage = Number(this.$route.query.page);
      }
      if (this.$route.query.search) {
        this.requestBeforeSearchText = this.$route.query.search;
        this.requestSearchText = this.$route.query.search;
      }
      if (this.$route.query.stock) {
        this.stock = this.$route.query.stock;
      }
      if (this.$route.query.pet_type) {
        this.petType = this.$route.query.pet_type;
      }
    },
    newRequest() {
      this.$router.push({ name: "requestHospitalForm" });
    },

    getBloodGroup: function(id) {
      const bloodBag = this.$store.getters["bloodbank/getBloodBagById"](id);
      let bloodGroup = "-";

      if (bloodBag && bloodBag.petInfo && bloodBag.petInfo.bloodGroup) {
        bloodGroup = bloodBag.petInfo.bloodGroup;
      } else if (bloodBag && bloodBag.bloodGroup) {
        bloodGroup = bloodBag.bloodGroup;
      }
      return { bloodGroup: bloodGroup };
    },
    getTagProps: function(status) {
      switch (status) {
        case true:
          return { text: statusEnum.ACCEPT, color: "#8fbe00" };
        case false:
          return { text: statusEnum.REJECT, color: "#ff4f4a" };
      }
      return { text: statusEnum.NO_RESPOND, color: "#a8aab7" };
    },
    transformRequest: function(item) {
      let hospitalName = this.$store.getters["hospital/getHospitalById"](
        this.stock === myRequestBloodBag
          ? item.targetHospitalId
          : item.hospitalId
      ).name;
      let bloodApproval = this.$store.getters[
        "bloodbank/getBloodApprovalByBloodRequestId"
      ](item.id);
      let status = this.getTagProps(bloodApproval && bloodApproval.isApproved);
      return Object.assign(item, {
        hospitalName: hospitalName,
        requestDate: item.createdDate,
        status: status
      });
    },
    compareRequestId: function(item) {
      if (this.requestSearchText === "" || this.requestSearchText == null) {
        return true;
      } else {
        let idStr = item.id.toString(10);
        return idStr.indexOf(this.requestSearchText) >= 0;
      }
    },

    onDetailClicked(requestId) {
      this.$router.push({
        name: "requestHospitalDetail",
        params: { id: requestId }
      });
    }
  },
  computed: {
    ...mapState({
      requestList: state => state.bloodbank.bloodHospitalRequests,
      requestApprovalList: state =>
        state.bloodbank.bloodHospitalRequestApprovals,
      hospitalInfo: state => state.hospital.profile,
      hospitals: state => state.hospital.hospitals,
      bloodBags: state => state.bloodbank.bloodBags
    }),
    computedRequestList() {
      if (this.requestList.length < 1) {
        return [];
      }

      return this.stock === myRequestBloodBag
        ? this.requestList
            .filter(item => item.hospitalId === this.hospitalInfo.id)
            .filter(item => item.petType === this.petType)
            .filter(item => this.compareRequestId(item))
            .filter(item => item.targetHospitalId)
            .sort((a, b) => new Date(b.requestDate) - new Date(a.requestDate))
            .map(item => this.transformRequest(item))
            .map(item =>
              Object.assign(item, this.getBloodGroup(item.bloodBagId))
            )
        : this.requestList
            .filter(item => item.targetHospitalId === this.hospitalInfo.id)
            .filter(item => item.petType === this.petType)
            .filter(item => this.compareRequestId(item))
            .sort((a, b) => new Date(b.requestDate) - new Date(a.requestDate))
            .map(item => this.transformRequest(item))
            .map(item =>
              Object.assign(item, this.getBloodGroup(item.bloodBagId))
            );
    },
    paginatedRequestList() {
      return this.computedRequestList.slice(
        (this.currentPage - 1) * 10,
        this.currentPage * 10
      );
    },
    count() {
      return this.computedRequestList.length;
    }
  },
  async mounted() {
    await this.$store.dispatch("ui/setIsFullScreenLoading", true);
    this.loadQueryString();

    try {
      // List&Fill Donations
      if (this.$store.state.bloodbank.donations.length < 1) {
        await this.$store.dispatch("bloodbank/listAndFillBloodDonations");
      }
      // List&Fill Requests
      if (this.$store.state.bloodbank.requests.length < 1) {
        await this.$store.dispatch("bloodbank/listAndFillBloodRequests");
      }
      // List Vets
      if (this.$store.state.hospital.vets.length < 1) {
        await this.$store.dispatch("hospital/listVets");
      }
      // List Hospitals
      if (this.hospitals.length < 1) {
        await this.$store.dispatch("hospital/listHospitals");
      }
      // List Blood Bags
      if (this.bloodBags.length < 1) {
        await this.$store.dispatch("bloodbank/listAndFillBloodCollectInfo");
      }

      // List Requests
      if (this.requestApprovalList.length < 1) {
        await this.$store.dispatch(
          "bloodbank/listBloodHospitalRequestApprovals"
        );
      }
      if (this.requestList.length < 1) {
        await this.$store.dispatch("bloodbank/listBloodHospitalRequests");
      }
    } catch (e) {
      await this.$store.dispatch("ui/showFetchErrorMsg");
    }
    await this.$store.dispatch("ui/setIsFullScreenLoading", false);

    this.updateQueryString();
  },
  watch: {
    petType: function() {
      this.currentPage = 1;
      this.updateQueryString();
    },
    stock: function() {
      this.currentPage = 1;
      this.updateQueryString();
    }
  }
};
</script>

<style lang="scss">
.request-hospital-page {
  .right-col {
    display: flex;
  }
}
</style>

<style lang="scss" scoped>
.btn-new-request {
  img {
    height: 24px;
    vertical-align: middle;
  }
}

.blood-navigation {
  .title {
    margin: 0;
    padding: 0;
    font-size: 16px;
    margin-bottom: 5px;
  }

  .row {
    margin-bottom: 10px;
  }

  .date-group {
    > span {
      padding: 10px;
    }
  }
}

.table-container {
  .table-container-header {
    margin-bottom: 5px;
  }

  .table-detail {
    overflow-x: auto;
  }
}

.not-found {
  display: flex;
  flex: 1;
  padding: 20px 0 0 0;
  text-align: center;
  justify-content: center;
}

.pagination {
  margin-top: 20px;
  text-align: right;
}
</style>
