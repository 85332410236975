<template>
  <table class="summary-table">
    <thead>
    <tr>
      <th colspan="2">ถุงเลือด</th>
      <th>FWB</th>
      <th>SWB</th>
      <th>pRBCs</th>
      <th>PRP</th>
      <th>FFP</th>
      <th>FP</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th>ทั้งหมด</th>
      <td>(unit)</td>
      <td>{{commaNumber(summaryTableData.FWB.all)}}</td>
      <td>{{commaNumber(summaryTableData.SWB.all)}}</td>
      <td>{{commaNumber(summaryTableData.PRBCS.all)}}</td>
      <td>{{commaNumber(summaryTableData.PRP.all)}}</td>
      <td>{{commaNumber(summaryTableData.FFP.all)}}</td>
      <td>{{commaNumber(summaryTableData.FP.all)}}</td>
    </tr>
    <tr>
      <td></td>
      <td>(ml.)</td>
      <td>{{commaNumber(summaryTableData.FWB.allQuantity)}}</td>
      <td>{{commaNumber(summaryTableData.SWB.allQuantity)}}</td>
      <td>{{commaNumber(summaryTableData.PRBCS.allQuantity)}}</td>
      <td>{{commaNumber(summaryTableData.PRP.allQuantity)}}</td>
      <td>{{commaNumber(summaryTableData.FFP.allQuantity)}}</td>
      <td>{{commaNumber(summaryTableData.FP.allQuantity)}}</td>
    </tr>
    <tr>
      <th>จองใช้แล้ว</th>
      <td>(unit)</td>
      <td>{{commaNumber(summaryTableData.FWB.reserve)}}</td>
      <td>{{commaNumber(summaryTableData.SWB.reserve)}}</td>
      <td>{{commaNumber(summaryTableData.PRBCS.reserve)}}</td>
      <td>{{commaNumber(summaryTableData.PRP.reserve)}}</td>
      <td>{{commaNumber(summaryTableData.FFP.reserve)}}</td>
      <td>{{commaNumber(summaryTableData.FP.reserve)}}</td>
    </tr>
    <tr>
      <td></td>
      <td>(ml.)</td>
      <td>{{commaNumber(summaryTableData.FWB.reserveQuantity)}}</td>
      <td>{{commaNumber(summaryTableData.SWB.reserveQuantity)}}</td>
      <td>{{commaNumber(summaryTableData.PRBCS.reserveQuantity)}}</td>
      <td>{{commaNumber(summaryTableData.PRP.reserveQuantity)}}</td>
      <td>{{commaNumber(summaryTableData.FFP.reserveQuantity)}}</td>
      <td>{{commaNumber(summaryTableData.FP.reserveQuantity)}}</td>
    </tr>
    <tr>
      <th>ที่ใช้ได้</th>
      <td>(unit)</td>
      <td>{{commaNumber(summaryTableData.FWB.available)}}</td>
      <td>{{commaNumber(summaryTableData.SWB.available)}}</td>
      <td>{{commaNumber(summaryTableData.PRBCS.available)}}</td>
      <td>{{commaNumber(summaryTableData.PRP.available)}}</td>
      <td>{{commaNumber(summaryTableData.FFP.available)}}</td>
      <td>{{commaNumber(summaryTableData.FP.available)}}</td>
    </tr>
    <tr>
      <td></td>
      <td>(ml.)</td>
      <td>{{commaNumber(summaryTableData.FWB.availableQuantity)}}</td>
      <td>{{commaNumber(summaryTableData.SWB.availableQuantity)}}</td>
      <td>{{commaNumber(summaryTableData.PRBCS.availableQuantity)}}</td>
      <td>{{commaNumber(summaryTableData.PRP.availableQuantity)}}</td>
      <td>{{commaNumber(summaryTableData.FFP.availableQuantity)}}</td>
      <td>{{commaNumber(summaryTableData.FP.availableQuantity)}}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
  import * as commaNumber from 'comma-number'

  export default {
    name: "BloodInventorySummaryTable",
    props: ['summaryTableData'],
    data () {
      return {
        commaNumber: commaNumber,
      }
    }
  }
</script>

<style lang="scss" scoped>
  .summary-table {
    width: 100%;
    border-collapse: collapse;
    font-family: "Kanit", sans-serif;

    thead {
      tr {
        height: 25px;
        border-radius: 4px;
        border: #e6eaee 1px solid;

        th {
          padding: 5px;
          font-weight: normal;
          color: rgba(0, 0, 0, 0.48);
          background-color: #f5f8fa;
          text-align: center;
          font-size: 14px;
          font-family: "Kanit", sans-serif;
        }
      }
    }

    tbody {
      tr {
        height: 30px;
        border-bottom: 1px #eee solid;
        font-family: "Kanit", sans-serif;
        // -webkit-box-shadow:0px 1px 1px #eee;
        // -moz-box-shadow:0px 1px 1px #eee;
        // box-shadow:0px 1px 1px #eee;

        td {
          position: relative;
          padding: 5px;
          text-align: center;
          font-size: 14px;
          font-family: "Kanit", sans-serif;

          .subtitle {
            color: #666;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          &.name-surname {
            color: #2198f1;
          }
        }
      }
    }
  }
</style>
