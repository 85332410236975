<template>
  <div>
    <table class="reward-table">
      <thead>
        <tr>
          <th class="no">No.</th>
          <th class="name">Product Name</th>
          <th class="type">Type</th>
          <th class="unit">Unit</th>
          <th class="is-in-stock">Is in Stock</th>
          <th class="action">Edit</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="item.id">
          <td class="no">{{  (pageSize * (currentPage - 1)) + index + 1 }}</td>
          <td class="name">
            <div class="ellipsis">{{ item.trade_name }}</div>
          </td>
          <td class="type">
            <div class="ellipsis">{{ item.type }}</div>
          </td>
          <td class="type">
            <div v-if="item.unit">{{ item.unit.name }}</div>
          </td>
          <td class="is-in-stock">
            <el-button v-if="item.is_in_stock" type="success" icon="el-icon-check" size="mini" circle plain></el-button>
          </td>
          <td class="action">
            <el-button
              size="mini"
              @click="$emit('selectToEdit', item)"
            >
              Edit
              <i class="el-icon-arrow-right el-icon-right"></i>
            </el-button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="not-found" v-if="items.length === 0">ไม่พบข้อมูลที่ต้องการค้นหา</div>
    <el-row class="pagination" type="flex" justify="end">
      <el-col :md="4" class="text-left">
        <button v-if="canExport" class="btn btn-small btn-status-cancel"
          @click="() => $emit('export-data')">
          Export Data
        </button>
      </el-col>
      <el-col :md="20" class="text-right">
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page="currentPage"
          :page-size="pageSize"
          :total="total"
          @current-change="(page) => $emit('current-change', page)"
        ></el-pagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    pageSize: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 1
    },
    canExport: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.action .el-button {
  width: 91px;
  height: 35px;
  border-radius: 5px;
  background-color: #02b0e1;
  color: white;

  font-family: "Kanit";
}

.reward-table {
  width: 100%;
  border-collapse: collapse;
  font-family: "Kanit", sans-serif;

  thead {
    tr {
      height: 54px;
      border-radius: 4px;
      border: #e6eaee 1px solid;

      th {
        padding: 5px;
        font-weight: normal;
        color: rgba(0, 0, 0, 0.48);
        background-color: #f5f8fa;
        text-align: center;
        font-size: 14px;
        font-family: "Kanit", sans-serif;

        &.no {
          width: 64px;
        }

        &.name {
          text-align: left;
          width: 200px;
        }

        &.type {
          width: 200px;
        }

        &.unit {
          width: 15px;
        }

        &.is-in-stock {
          width: 50px;
        }

        &.action {
          width: 100px;
        }
      }
    }
  }

  tbody {
    tr {
      height: 56px;
      border-bottom: 1px #eee solid;
      font-family: "Kanit", sans-serif;

      td {
        position: relative;
        padding: 5px;
        text-align: center;
        font-size: 14px;
        font-family: "Kanit", sans-serif;

        // .ellipsis {
        //   white-space: nowrap;
        //   overflow: hidden;
        //   text-overflow: ellipsis;
        // }

        &.name > .ellipsis {
          text-align: left;
          width: 200px;
        }

        &.detail {
          > .ellipsis {
            text-align: left;
            width: 400px;
          }
        }
      }
    }
  }
}

.not-found {
  text-align: center;
  padding: 20px 0;
}

.pagination {
  margin-top: 20px;
  text-align: right;
}
</style>


