export const medicalStatus = value => {
  switch (value) {
    case "in_progress":
      return "In Progress"
    case "done":
      return "Finish"
    default:
      return ""
  }
};
