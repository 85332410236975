export const googleApiKey = "AIzaSyDBEO0KjvkCDRIrfAy5bTChwUFQR7btUG0";

export const appointmentStatus = {
  NO_SHOW: 'no_show',
  CONFIRM: 'confirm',
  NO_RESPOND: 'no_respond',
  REQUEST_POSTPONE: 'request_postpone',
  POSTPONE: 'postpone',
  DONE: 'done',
  EDIT_MARKDONE: 'edit_markdone'
}

export const medicalStatus = {
  IN_TREATMENT: 'exam',
  PAYMENT: 'for_payment',
  DONE: 'finished'
}

export const MMcolor = {
  pale: 1,
  normal: 2,
  pink: 3,
  red: 4,
  purple: 5,
  brown: 6,
  black: 7
}

export const MMcolorRestore = {
  1: "pale",
  2: "normal",
  3: "pink",
  4: "red",
  5: "purple",
  6: "brown",
  7: "black"
}
