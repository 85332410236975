<script>
export default {
  props: {
    price: {
      type: Number,
      default: 0,
    },

    type: {
      type: String,
      default: "",
      validator: (value) => !value || ["opd", "ipd"].includes(value),
    },

    showSendToCashierButton: {
      type: Boolean,
      default: false,
    },

    showSendToCashierAndCutStockButton: {
      type: Boolean,
      default: false,
    },

    showPaymentButton: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      visible: false,
    };
  },

  mounted() {
    this.visible = true;
  },

  methods: {
    handleClose() {
      this.$emit("close");
      this.visible = false;
    },

    handleClosed() {
      this.$destroy();
    },

    emit(value) {
      this.$emit("action", value);

      this.visible = false;
    },

    handleCloseButtonClick() {
      this.visible = false;
    },
  },

  render() {
    const buttons = (() => {
      const confirmButton = (
        <el-button
          type="primary"
          class={[
            this.$style.button,
          ]}
          onClick={() => {
            this.emit("confirm");
          }}
        >
          ส่งเข้าห้องตรวจ 
        </el-button>
      );

      const sendToCashierButton = this.showSendToCashierButton
        ?(
          <el-button
            class={[
              this.$style.button,
              this.$style.sendToCashierButton
            ]}
            onClick={() => {
              this.emit("sendToCashier");
            }}
          >
            ส่งไปชำระเงิน 
          </el-button>
        )
        : undefined;

      const sendToCashierAndCutStockButton = this.showSendToCashierAndCutStockButton
        ? (
          <el-button
            class={[
              this.$style.button,
              this.$style.sendToCashierAndCutStockButton
            ]}
            onClick={() => {
              this.emit("sendToCashierAndCutStock");
            }}
          >
            ส่งไปชำระเงิน/ตัด<br />Stock
          </el-button>
        )
        : undefined;

      const paymentButton = this.showPaymentButton
        ? (
          <el-button
            class={[
              this.$style.button,
              this.$style.paymentButton
            ]}
            onClick={() => {
              this.emit("payment");
            }}
          >
            ชำระเงิน  
          </el-button>
        )
        : undefined;

      switch (this.type) {
        case "opd":
          return [
            confirmButton,
            sendToCashierButton,
            sendToCashierAndCutStockButton,
            paymentButton
          ];

        case "ipd":
          return [
            confirmButton,
            sendToCashierButton
          ];
      }
    })();

    const renderHeader = (() => {
      const typeText = (() => {
        switch (this.type) {
          case "ipd":
            return "IPD";

          case "opd":
            return "OPD";
        }
      })();

      return <div 
        style={{ 
          margin: "0.5rem 0",
          fontWeight: "bold",
          color: "#020202",
          fontSize: "20px"
        }}>บันทึก {typeText}</div>
    });

    const renderPrice = () => (
      <div style={{ textAlign: "center", padding: "0 16px" }}>
        <div
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            color: "#030202",
            marginBottom: "8px",
          }}
        >
          {`
            ยอดเงินที่ต้องชำระ 
            ${this.price.toLocaleString("th-TH", {
              style: "currency",
              currency: "THB",
            })}
          `}
        </div>
        {this.type == "ipd" && (
          <div
            style={{
            fontSize: "16px",
            color: "#5a5a5a",
            textAlign: "left"
          }}>*เมื่อยืนยันการชำระเงินแล้ว จะเปลี่ยนสถานะเป็นจบเคส ไม่สามารถส่งเข้า Admit เป็นสัตว์ป่วยในได้</div>
        )}
      </div>
    );

    return (
      <el-dialog
        center
        visible={this.visible}
        onClose={this.handleClose}
        onClosed={this.handleClosed}
        class={this.$style.root}
      >
        <template slot="title">{renderHeader()}</template>

        {renderPrice()}

        <el-row
          slot="footer"
          type="flex"
          gutter={16}
          justify="center"
          style={{ flexWrap: "wrap" }}
        >
          {buttons.filter(vnode => vnode != undefined).map((vnode) => (
            <el-col span={6}>{vnode}</el-col>
          ))}
        </el-row>
      </el-dialog>

    );
  },
};
</script>

<style lang="scss" module>
.root {
  font-family: Sarabun;
}

.button {
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 1.2rem;
  padding: 9px;
}

.sendToCashierButton {
  background: #5b9f11;
  color: #fff;

  &:hover {
    background-color: lighten(#5b9f11, 10%) !important;
  }
}

.sendToCashierAndCutStockButton {
  background: #ff8800;
  color: #fff;

  &:hover {
    background-color: lighten(#ff8800, 10%) !important;
  }
}

.paymentButton {
  background: #ff6b6b;
  color: #fff;

  &:hover {
    background-color: lighten(#ff6b6b, 10%) !important;
  }
}
</style>
