<template>
  <div class="owner-results">
    <div class="header">
      <span class="total">{{ total | number }}</span> RESULT
    </div>
    <div class="content">
      <table class="pet-table">
        <thead>
          <tr>
            <th class="owner">Owner</th>
            <th class="tel-email">Tel./Email</th>
            <th class="pet-number">Number of Pets</th>
            <th class="frequency">Frequency</th>
            <th class="expence">Expense (฿)</th>
            <th class="action"></th>
          </tr>
        </thead>
        <tbody v-if="results && results.length > 0">
          <tr v-for="result in results" :key="result.owner.id">
            <td class="result-owner text-left">
              <router-link :to="{ name:'hospitalPetOwnerProfile', params: { id: result.owner.id }}">
                <div>{{ `${result.owner.firstname} ${result.owner.lastname}` }}</div>
              </router-link>
            </td>
            <td class="text-left">
              <div v-if="result.owner.phone">Tel. {{ result.owner.phone}}</div>
              <div class="subtitle" v-if="result.owner.email">{{ result.owner.email}}</div>
            </td>
            <td>
              {{ result.total_pet | number }}
            </td>
            <td>
              {{ result.frequency | number }}
            </td>
            <td>
              {{ result.expence | currency }}
            </td>
            <td>
              <button
                class="btn btn-tiny btn-primary"
                @click="$router.push({ name: 'hospitalPetOwnerProfile', params: { id: result.owner.id }})"
              >
                SEE DETAIL
                <i class="el-icon-arrow-right el-icon-right"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="not-found" v-if="results == null || results.length === 0">ไม่พบข้อมูลที่ต้องการค้นหา</div>
    </div>
    <div class="footer" v-if="results">
      <el-pagination
        background
        @current-change="(page) => $emit('current-change', page)"
        :current-page="currentPage"
        :page-size="10"
        layout="prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>

export default {
  components: {

  },
  props: ["results", "currentPage", "total"],
  data() {
    return {}
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
.owner-results {
  .header {
    margin-bottom: 5px;
  }
  .content {
    display: flex;
    flex-wrap: wrap;
  }

  .footer {
    padding: 20px;
    text-align: center;
  }
}

.pet-table {
  width: 100%;
  border-collapse: collapse;

  a {
    color: #333;
    text-decoration: none;
    &:hover {
      color: #02b0e1;
    }
  }

  thead {
    tr {
      border: #e6eaee 1px solid;

      th {
        padding: 10px 5px;
        font-weight: normal;
        color: #7f8fa4;
        background-color: #f5f8fa;
        font-size: 0.9em;

        &.frequency,
        &.expence {
          width: 120px;
        }
      }
    }
  }

  tbody {
    tr {
        text-align: center;
      td {
        position: relative;
        padding: 10px 5px;
        font-size: 0.9em;
        border-bottom: 1px solid #eee;

        &.result-owner a {
          color: #4a90e2;
        }

        .subtitle {
          color: #666;
        }

        .thumbnail {
          display: inline-block;
          width: 50px;
          height: 50px;
          overflow: hidden;
          background: #eee;
          border-radius: 5px;
          margin-right: 10px;
          vertical-align: middle;

          > img {
            width: 50px;
            height: 50px;
            border-radius: 5px;
          }
        }
      }
    }
  }
}

.total {
  color: $light-blue;
  font-weight: bold;
}

.not-found {
  display: flex;
  flex: 1;
  padding: 20px 0 0 0;
  text-align: center;
  justify-content: center;
}

.pagination {
  margin-top: 20px;
  text-align: right;
}
</style>
