<template>
  <el-card style="padding: 5px;">
    <div class="row" style="font-size: 14px; font-weight: bold;">
      <label v-if="isRequired" class="error-field-label"> {{label}} <span>*</span></label>
      <label v-if="!isRequired">{{label}}</label>
    </div>

    <div id="top-label-input" class="row" style="height: 45px;">
      <el-input :disabled="disabled" v-model="vModel" size="large" style="width: 100%;" :status="!isValid ? 'error' : ''"></el-input>
    </div>
  </el-card>
</template>

<script>
export default {
  name: 'top-label-input',
  props: [
    'label',
    'model',
    'propName',
    'isValid',
    'isRequired',
    'disabled'
  ],
  data () {
    return {
      vModel: this.model
    }
  },
  watch: {
    vModel: function (val) {
      this.$emit('updated', { prop: this.propName, value: val })
    }
  }
}
</script>