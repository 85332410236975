import { render, staticRenderFns } from "./BloodDonationStep4Collect.vue?vue&type=template&id=0d125887&"
import script from "./BloodDonationStep4Collect.vue?vue&type=script&lang=js&"
export * from "./BloodDonationStep4Collect.vue?vue&type=script&lang=js&"
import style0 from "./BloodDonationStep4Collect.vue?vue&type=style&index=0&id=0d125887&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports