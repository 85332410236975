<template>
  <div class="form-block">
    <div class="header-block">
      <div class="title">
        Create Hospital Profile
      </div>
      <div class="lead">
        Map<br/>
        (2/4)
      </div>
    </div>
    <div>
      <div class="row">
        <span class="form-label">Google Map</span>
        <el-input
          v-model="findAddress"
          @keyup.enter.native="OnFindAddressChange"
          placeholder="กรอกสถานที่"
          style="width: 100%"
        ></el-input>
        <span class="error">{{ errors.first('username') }}</span>
      </div>

      <div class="row">
        <LocationPicker v-if="loaded"
          v-on:locationChange="locationChange"
          :apiKey="googleApiKey"
          :initialLat="location.lat"
          :initialLng="location.lng"
          ref="map"
        />
      </div>  
    
      <div class="row">
        <el-button
          :loading="submitButtonLoading"
          type="button"
          class="btn btn-primary form-submit-btn location-save-btn"
          @click="saveLocation">save this location</el-button>
      </div>

      <div class="row text-center">
        <router-link
          :to="{ name: 'profileGeneral' }"
          class="btn btn-plain form-submit-btn">
          &lt; Back
        </router-link>
        <router-link
          :to="{ name: 'profileAddress' }"
          class="btn btn-primary form-submit-btn">
          Next &gt;
        </router-link>
        <span v-for="(error, key) of errMessage"
          v-bind:key="key"
          class="error">{{ key }}: {{ error }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import LocationPicker from '@/components/LocationPicker'
import { googleApiKey } from '@/constants'

export default {
  data() {
    return {
      findAddress: "",
      submitButtonLoading: false,
      errMessage: [],
      location: {},
      loaded: false,
      googleApiKey: googleApiKey
    }
  },
  async mounted() {
    await this.$store.dispatch('ui/setIsFullScreenLoading', true)
    await this.$store.dispatch('hospital/getProfile')
    this.location = {
      lat: this.$store.state.hospital.profile.latitude || 0,
      lng: this.$store.state.hospital.profile.longitude || 0
    }
    this.loaded = true
    await this.$store.dispatch('ui/setIsFullScreenLoading', false)
  },
  methods: {
    locationChange(location) {
      this.location = location
    },
    async saveLocation() {
      this.submitButtonLoading = true
      try {
        await this.$store.dispatch('hospital/updateProfile', {
          data: {
            latitude: this.location.lat.toFixed(12),
            longitude: this.location.lng.toFixed(12)
          }
        })
        this.$message('Hospital Location Saved')
      } catch (err) {
        this.errMessage = err
      }
      this.submitButtonLoading = false
    },
    OnFindAddressChange () {
      this.$refs.map.findLocation(this.findAddress);
    },
  },
  components: {
    LocationPicker
  }
}
</script>

<style lang="scss" scoped>
.form-block {
  @extend %block-container;
  @extend %form-style;
  .location-save-btn {
    display: block;
    width: 100%;
    background-color: #536c8d;
    margin: 0;
  }
}
.row {
  margin-bottom: 15px;
}
</style>
