import moment from 'moment'
import _ from 'lodash'
import store from '@/store/index'
export default class {
  constructor (obj) {
    if (obj != null) {
      this.id = obj.id == null ? null : obj.id
      this.isPhysicalExamPassed = obj.isPhysicalExamPassed == null ? true : obj.isPhysicalExamPassed
      this.physicalExamNote = obj.physical_exam_note == null ? '' : obj.physical_exam_note
      this.isLabExamPassed = obj.isLabExamPassed == null ? true : obj.isLabExamPassed
      this.labExamNote = obj.lab_exam_note == null ? '' : obj.lab_exam_note
      this.nextVaccineDate = obj.next_vaccine == null ? '' : new Date(obj.next_vaccine)
      this.nextBugVaccineDate = obj.next_bug_resistance == null ? '' : new Date(obj.next_bug_resistance)
      this.nextDonationDate = obj.next_donation == null ? '' : new Date(obj.next_donation)
      this.canDonate = obj.able_to_donate == null ? '' : obj.able_to_donate
      this.bloodDonationId = obj.blood_donation == null ? '' : obj.blood_donation
      this.approver = obj.approver == null ? '' : obj.approver
    } else {
      this.id = null
      this.isPhysicalExamPassed = true
      this.physicalExamNote = ''
      this.isLabExamPassed = true
      this.labExamNote = ''
      this.nextVaccineDate = ''
      this.nextBugVaccineDate = ''
      this.nextDonationDate = ''
      this.canDonate = ''
      this.bloodDonationId = null
      this.approver = ''
    }
  }

  toRequestJson () {
    return {
      id: this.id,
      physical_exam_note: this.physicalExamNote,
      lab_exam_note: this.labExamNote,
      next_vaccine: _.isDate(this.nextVaccineDate) ? moment(this.nextVaccineDate).format('YYYY-MM-DD') : null,
      next_bug_resistance: _.isDate(this.nextBugVaccineDate) ? moment(this.nextBugVaccineDate).format('YYYY-MM-DD') : null,
      next_donation: _.isDate(this.nextDonationDate) ? moment(this.nextDonationDate).format('YYYY-MM-DD') : null,
      able_to_donate: this.canDonate,
      approver: this.approver,
      blood_donation: this.bloodDonationId,
      hospital: store.state.hospital.profile.id
    }
  }
}
