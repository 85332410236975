<script>
export default {
  provide() {
    return {
      command: this.command,
    };
  },

  methods: {
    command(value) {
      this.$emit("command", value);
    },
  },

  render() {
    return <div>{this.$slots.default}</div>;
  },
};
</script>
