<template>
  <table class="inventory-table">
    <thead>
    <tr>
      <th class="status"></th>
      <th>Volumn (ml.)</th>
      <th>Type</th>
      <th>Pet Name</th>
      <th>Blood Bag ID</th>
      <th>PCV</th>
      <th>Blood Group</th>
      <th>Collect Date</th>
      <th>Expire Date</th>
      <th></th>
    </tr>
    </thead>
    <tbody v-if="bloodBags && bloodBags.length > 0">
    <tr :key="index" v-for="(bloodBag, index) in bloodBags">
      <td>
        <div
          class="tag tag-vertical"
          :style="`color: #fff; background-color: ${getInventoryStatusTagColor(bloodBag.status)}`"
        >{{ getInventoryStatusTagLabel(bloodBag.status) }}</div>
      </td>
      <td>{{(Number(bloodBag.quantity) * 10) / 10}} ml.</td>
      <td>{{ bloodBag.bloodType }}</td>
      <td>{{ bloodBag.petInfo && bloodBag.petInfo.name ? bloodBag.petInfo.name : '-'}}</td>
      <td>{{ bloodBag.id }}</td>
      <td>{{ bloodBag.pcv }}</td>
      <td>{{ bloodBag.bloodGroup == null ? 'ไม่ระบุ' : bloodBag.bloodGroup }}</td>
      <td>{{bloodBag.dateCollection | toThaiDate}}</td>
      <td>{{bloodBag.expiryDate | toThaiDate}}</td>
      <td>
        <el-button
          size="mini"
          type="primary"
          @click="onDetailClicked(bloodBag.id)"
        >
          See Details
        </el-button>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
  import bloodBagStatus from '../../../constants/bloodbank/blood-storage-bag-status'
  import utils from '../../../utils/bloodbank'

  export default {
    name: "BloodInventoryHistoryTable",
    props: ['bloodBags'],
    data () {
      return {
        bloodBagStatus
      }
    },
    methods: {
      onDetailClicked(value) {
        this.$emit('detail', value)
      },
      getInventoryStatusTagLabel: utils.getInventoryStatusTagLabel,
      getInventoryStatusTagColor: utils.getInventoryStatusTagColor,
    },
  }
</script>

<style lang="scss" scoped>
  .table-container {
    .header {
      margin-bottom: 5px;
    }
  }

  .inventory-table {
    width: 100%;
    border-collapse: collapse;

    thead {
      tr {
        border: #e6eaee 1px solid;

        th {
          padding: 5px;
          font-weight: normal;
          color: #7f8fa4;
          background-color: #f5f8fa;
          font-size: 0.9em;

          &.status {
            width: 30px;
          }
        }
      }
    }

    tbody {
      tr {
        height: 82px;

        td {
          position: relative;
          padding: 5px;
          font-size: 0.9em;
          text-align: center;
        }
      }
    }
  }
</style>
