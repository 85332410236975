<template>
    <div class="pet-healthbook-timeline">
        <el-timeline v-if="timeline_items.length > 0">
        <el-timeline-item></el-timeline-item>
        <div v-for="(item, index) in timeline_items" :key="index">
            <div class="year-timeline">{{ item.year }}</div>
            <el-timeline-item :class="`pet-healthbook-${group_item.label}`" 
                v-for="(group_item, index) in item.groups"
                :key="index">
                <PetHealthBookGroupItem
                    :title="group_item.label"
                    :items="group_item.items" />
            </el-timeline-item>
        </div>

        </el-timeline>
        <div class="no-item" v-else>No timeline</div>
        <div class="load-more-container" v-if="timeline_items.length < yearList.length">
            <button class="btn btn-small btn-primary" @click="loadMore">Load more...</button>
        </div>
    </div>
</template>

<script>
import PetHealthBookGroupItem from "./PetHealthBookGroupItem";

export default {
  components: {
    PetHealthBookGroupItem
  },
  props: ["petId", "viewer"],
  data() {
    return {
      id: this.petId,
      yearList: [],
      timeline_items: []
    };
  },
  methods: {
    loadMore() {
      this.fetchHealthBooks();
    },
    async fetchHealthBooks() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        var response;

        let query = {};
        if (this.yearList && this.timeline_items) {
            query["year"] = this.yearList[this.timeline_items.length];
        }

        if (this.viewer == "hospital") {
            response = await this.$store.dispatch("pet/listTimelineHospitalHealthBook", {
                query, params: { petId: this.id }
            });
        } else if (this.viewer == "owner") {
            response = await this.$store.dispatch("owner/listPetTimelineHealthBooks", {
                query, params: { petId: this.id }
            });
        } else if (this.viewer == "public") {
            response = await this.$store.dispatch("pet/listPublicTimelineHospitalHealthBook", {
                query, params: { code: this.id }
            });
        }
        
        this.yearList = response.year_list;
        this.timeline_items = [...this.timeline_items, ...response.details];
        
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorMsg", error.detail);
      }
    }
  },

  async mounted() {
    this.fetchHealthBooks();
  }
};
</script>

<style lang="scss" scoped>
.no-item {
  font-family: default;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.7px;
  text-align: center;
  color: #8e8e93;
  margin: 72px 0px;
}

</style>

<style lang="scss">

.pet-healthbook-timeline {
    .year-timeline {
        font-size: 24px;
        font-weight: bold;
        line-height: 0;
        margin-left: -20px;
        
        &:first-child {
            margin-top: 24px;
        }
    }

    .el-timeline {
        margin-left: -20px;
    }
    .el-timeline-item {
        padding-left: 20px;

        // &:first-child {
        //     margin-top: 18px;
        // }

        // &:last-child {
        //   margin-bottom: 18px;
        // }

        &:first-child {
          .el-timeline-item__node--normal {
            display: none;
          }
          .el-timeline-item__tail {
            display: inline;
          }
        }

        &:last-child {
          .el-timeline-item__tail {
            display: inline;
          }
        }

        .el-timeline-item__node--normal {
            left: -12px;
            width: 34px;
            height: 34px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
        }
        .el-timeline-item__tail {
            border-width: 4px;
        }

        &.pet-healthbook-Vaccine {
            .el-timeline-item__node--normal {
                background: url("../../../assets/icons/icon-timeline-vaccine.png");
            }
        }

        &.pet-healthbook-Prevention {
            .el-timeline-item__node--normal {
                background: url("../../../assets/icons/icon-timeline-prevention.png");
            }
        }
    }

    .load-more-container {
        text-align: center;
        padding: 0 0 20px 0;
    }
}
</style>