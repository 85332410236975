<template>
  <div class="pet-reward-timeline">
    <el-timeline v-if="items.length > 0">
      <el-timeline-item class="pet-reward-timeline" v-for="(item, index) in items" :key="index">
        <PetRewardTimelineItem :item="item" />
      </el-timeline-item>
    </el-timeline>
    <div class="no-item" v-else>No Redeem</div>
    <div class="load-more-container" v-if="currentPage < totalPages">
      <button class="btn btn-small btn-primary" @click="loadMore">Load more...</button>
    </div>
  </div>
</template>

<script>
import PetRewardTimelineItem from "./PetRewardTimelineItem";

export default {
  components: {
    PetRewardTimelineItem
  },
  props: ["petID", "viewer"],
  data() {
    return {
      id: this.petID,
      items: [],
      currentPage: 1,
      totalPages: 1
    };
  },
  methods: {
    loadMore() {
      this.fetchItems();
    },
    async fetchItems() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        var response;

        let query = {};
        if (this.currentPage < this.totalPages) {
          this.currentPage += 1;
        }
        query["page"] = this.currentPage;
       
        if (this.viewer === 'owner') {
            response = await this.$store.dispatch("pet/listTimelineHospitalReward", {
                query, params: { petId: this.id }
            });
        } else if (this.viewer === 'public') {
            response = await this.$store.dispatch("pet/listPublicTimelineHospitalReward", {
                query, params: { code: this.id }
            });
        }

        if (this.currentPage > 1) {
          this.items = [...this.items, ...response.results];
        } else {
          this.items = response.results;
        }
        this.totalPages = Math.ceil(response.count / 1);
        
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorMsg", error.detail);
      }
    }
  },

  async mounted() {
    this.fetchItems();
  }
};
</script>

<style lang="scss" scoped>
.no-item {
  font-family: default;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.7px;
  text-align: center;
  color: #8e8e93;
  margin: 72px 0px;
}
</style>

<style lang="scss">
.pet-reward-timeline {
  background-color: #fff;
  padding: 8px;

  .el-timeline-item__wrapper {
    padding-left: 0;
  }
  .el-timeline {
    margin-left: -40px;
  }
  .el-timeline-item {
    .el-timeline-item__node--normal {
      display: none;
    }
  }

  .el-timeline-item__tail {
    display: none;
  }

  .el-collapse-item__content {
    padding-bottom: 0;
  }

  .load-more-container {
    text-align: center;
    padding: 0 0 20px 0;
  }
}
</style>