import { render, staticRenderFns } from "./BloodRequestCrossmatchRow.vue?vue&type=template&id=99a9dee8&"
import script from "./BloodRequestCrossmatchRow.vue?vue&type=script&lang=js&"
export * from "./BloodRequestCrossmatchRow.vue?vue&type=script&lang=js&"
import style0 from "./BloodRequestCrossmatchRow.vue?vue&type=style&index=0&id=99a9dee8&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports