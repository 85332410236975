import store from '@/store/index'

export default class {
  constructor (obj) {
    if (obj != null) {
      /**
       * -- bloodBagId1 --
       * Recipient id or competitor number one
       *
       * In case of single crossmatch; value = Recipient id (null)
       * In case of multi crossmatch; value = bloodbag id
       *
       */
      this.id = obj.id == null ? null : obj.id
      this.isSelected = obj.isSelected == null ? false : obj.isSelected
      this.bloodBagId1 = obj.blood_collection_1 == null ? null : obj.blood_collection_1
      // competitor number two
      this.bloodBagId2 = obj.blood_collection_2 == null ? null : obj.blood_collection_2
      this.isMajor = obj.is_major_passed == null ? null : obj.is_major_passed
      this.isMinor = obj.is_minor_passed == null ? null : obj.is_minor_passed
      this.isMajorAg = obj.Major_AgOrHe == null ? null : obj.Major_AgOrHe
      this.isMinorAg = obj.Minor_AgOrHe == null ? null : obj.Minor_AgOrHe
      this.bloodRequestId = obj.blood_request == null ? null : obj.blood_request
    } else {
      this.id = null
      this.isSelected = false
      this.bloodBagId1 = null
      this.bloodBagId2 = null
      this.quantity1 = ''
      this.quantity2 = ''
      this.isMajor = null
      this.isMinor = null
      this.isMajorAg = null
      this.isMajorAg = null
    }
  }

  toRequestJson () {
    return {
      id: this.id,
      isSelected: this.isSelected,
      blood_collection_1: this.bloodBagId1,
      blood_collection_2: this.bloodBagId2,
      is_major_passed: this.isMajor,
      is_minor_passed: this.isMinor,
      Major_AgOrHe: this.isMajorAg,
      Minor_AgOrHe: this.isMajorAg,
      blood_request: store.state.bloodbank.selectedRequest.id
    }
  }
}
