<template>
  <div class="activity-groups">
    <!-- <div class="prev" @click="prev">
      <i class="el-icon-arrow-left"></i>
    </div> -->
    <div class="content">
      <ul class="tabs" ref="tabs">
        <li class="tab" :class="{ active: isFavorite}">
          <el-button 
            class="dropdown" 
            style="font-size: 11px; border-radius: 4px;"
            @click="selectFavorite"
          >Favorite</el-button>
        </li>
        <li
          class="tab"
          v-for="group in groups"
          :key="group.id"
          :class="{ active: group.id === selectedGroupId}"
        >
          <template v-if="group.sub_group && group.sub_group.length > 0">
            <el-dropdown
              class="dropdown"
              split-button
              trigger="click"
              @click="selectGroup(group.id)"
              @command="selectSubgroup"
            >
              {{ group.name }}
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="subgroup in group.sub_group"
                  :key="subgroup.id"
                  :command="{ groupId: group.id, subGroupId: subgroup.id}"
                  :class="{ active: subgroup.id === selectedSubGroupId}"
                >
                  <span>{{ subgroup.name }}</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
          <template v-else>
            <el-button @click="selectGroup(group.id)">{{ group.name }}</el-button>
          </template>
        </li>
      </ul>
    </div>
    <!-- <div class="next" @click="next">
      <i class="el-icon-arrow-right"></i>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    groups: {
      types: Array,
      required: true
    },
    selectedGroupId: {
      types: Number
    },
    selectedSubGroupId: {
      types: Number
    },
    isFavorite: {
      types: Boolean
    }
  },
  methods: {
    selectFavorite() {
      this.$emit("click", { isFavorite: true, groupId: -1, subGroupId: -1 })
    },
    selectGroup(groupId) {
      this.$emit("click", {
        isFavorite: false,
        selectedGroupId: groupId,
        selectedSubGroupId: -1
      })
    },
    selectSubgroup(data) {
      this.$emit("click", {
        isFavorite: false,
        selectedGroupId: data.groupId,
        selectedSubGroupId: data.subGroupId
      })
    },
    prev() {
      /* eslint-disable */
      const tabs = $(this.$refs.tabs)
      $(tabs).animate({ scrollLeft: tabs.scrollLeft() - 200 }, 300)
    },
    next() {
      /* eslint-disable */
      const tabs = $(this.$refs.tabs)
      $(tabs).animate({ scrollLeft: tabs.scrollLeft() + 200 }, 300)
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-groups {

  .el-button {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
  }

  .active {
    .el-button {
      color: #fff;
      background-color: $light-blue;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .prev,
  .next {
    margin: 6px 2px;
    width: 16px;
    padding: 4px 5px;
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    line-height: 1em;
    background: #ccc;
    text-align: center;
    vertical-align: top;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.1s ease;

    &:hover {
      color: #fff;
      background-color: $light-blue;
    }
  }

  .content {
    width: 100%;
    display: inline-block;

    .tabs {
      padding: 0;
      margin: 0;
      list-style-type: none;
      display: grid;
      width: 100%;
      border-bottom: 0;
      &::-webkit-scrollbar {
        display: none;
      }

      .tab {
        width: 100%;
        display: inline-flex;
        white-space: nowrap;
      }
    }
  }
}
</style>

<style lang="scss">
.dropdown {
  width: 100%;
  margin-bottom: 5px;

  .el-button-group {
    display: flex;
  }

  .el-button-group > .el-button {
    font-size: 11px;
  }

  .el-button-group > .el-button:first-child {
    width: 75%;
    height: auto;
    padding: 12px 8px;
  }

  .el-button {
    line-height: 1.2;
    white-space: normal;
  }
}
</style>
