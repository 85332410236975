<template>
  <el-dialog title="บันทึกน้ำหนัก" :visible.sync="petWeightDialog" center @close="$emit('cancel')">
    <el-form :model="form" :rules="rules" ref="petWeightDialogForm" label-position="top">
      <el-form-item label="วันที่" :label-width="formLabelWidth" prop="date">
        <el-date-picker
          v-model="form.date"
          type="date"
          placeholder="Select Date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          style="width: 100%;">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="น้ำหนักตัว(kg)" :label-width="formLabelWidth" prop="weight">
        <el-input-number 
        v-model="form.weight" 
        :precision="2" 
        :step="1"
        :min="0.1"
        style="width: 100%;">
        </el-input-number>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('cancel')">Cancel</el-button>
      <el-button type="primary" @click="$emit('confirm')">Confirm</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
    props: {
      visible: Boolean,
      form: Object
    },
    data() {
      return {
        petWeightDialog: this.visible,
        formLabelWidth: '100%',
        rules: {
          date: [
            { required: true, },
          ],
          weight: [
            { required: true, },
          ],
        }
      };
    },
  };
</script>

<style lang="scss">
  .el-dialog__header .el-dialog__title {
    font-size: 20px;
    font-weight: bold;
    color: $light-blue;
  }
  .el-dialog--center .el-dialog__body {
    font-size: 16px;
    padding: 20px 80px;

    .el-form--label-top .el-form-item__label {
      padding-bottom: 0;
      font-weight: bold;
      font-size: 16px;
    }
  }
  
</style>
