<template>
  <div>
    <div class="report_container">
      <div class="table_panel content">
        <div v-for="(item, index) in bars" v-bind:key="index">
          <div class="col name">{{item.name}}</div>
          <div class="col c number" v-bind:style="{color: item.number === maxNum ? '#02b0e1' : '#9b9b9b'}">{{item.number}} ตัว</div>
          <div style="flex: 2;">
            <LineSideBarChart :bar="item.bar"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineSideBarChart from './LineSideBarChart';

export default {
  name: "BloodbankRequestRankChart",
  props: ["bloodbank"],
  components: {
    LineSideBarChart,
  },
  computed: {
    maxNum() {
      let maxNum = 0;
      if(this.bloodbank.bloodrequest && this.bloodbank.bloodrequest.rank
        && this.bloodbank.bloodrequest.rank[0])
      {
        maxNum = this.bloodbank.bloodrequest.rank[0].number;
      }
      return maxNum;
    },
    bars() {
      let bars = [];
      if(this.bloodbank.bloodrequest && this.bloodbank.bloodrequest.rank
        && this.bloodbank.bloodrequest.rank[0])
      {
        this.bloodbank.bloodrequest.rank.map((item, index) => {
          bars.push(
            {
              name: item.name,
              number: item.number,
              bar: [
                {
                  value: item.number/this.maxNum,
                  color: item.number === this.maxNum ? '#02b0e1' : '#d8d8d8',
                },
              ],
            },
          );
        });
      }
      return bars;
    }
  },
};
</script>

<style lang="scss" scoped>
.report_container {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 20px 20px;
}

.table_panel {
  flex: 2;
  display: flex;
  flex-direction: column;
  padding: 0px 15px 0px 15px;
  div {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
}
.name {
  flex: 1;
  color: #4a4a4a;
}
.number {
  flex: 0.3;
}

.col {
  padding: 10px 5px 10px 0px;
}
.col.c {
    display: flex;
    justify-content: flex-end;
}

.chart_panel {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 15px 10px 1px;
}
</style>
