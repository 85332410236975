import CalendarPage from '@/pages/calendar/CalendarPage.vue'

const routes = [
  {
    path: '/calendar',
    name: 'calendar',
    component: CalendarPage,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['hospital_feature.can_use_calendar']
    }
  },
]

export default routes