<template>
<div class="container no-gutter" style="width:100%;">
  <div class="row">
    <div class="col-md-6 flex">
      <div class="container row flex-middle flex-center">
          <h3>เลขที่ถุงเลือด</h3>
          <el-select clearable v-model="info.bloodBagId1" style="width: 50%; margin-left: 10px;">
            <el-option v-for="(bag, idx) in clonedBags" :key="idx" v-bind:value="bag.bloodBagId1" v-if="info.bloodBagId2 != bag.bloodBagId1">{{bag.bloodBagId1}}</el-option>
          </el-select>
        </div>
    </div>

    <div class="col-md-6 row flex">
      <div class="container row flex-middle flex-center">
        <h3>เลขที่ถุงเลือด</h3>
        <el-select clearable v-model="info.bloodBagId2" style="width: 50%; margin-left: 10px;">
          <el-option v-for="(bag, idx) in clonedBags" :key="idx" v-bind:value="bag.bloodBagId1" v-if="info.bloodBagId1 != bag.bloodBagId1">{{bag.bloodBagId1}}</el-option>
        </el-select>
      </div>
    </div>

    <div class="col-md-6 flex">
      <div class="container row flex-middle flex-center">
        <el-radio-group v-model="info.isMajor" size="large" :disabled="disabled">
          <el-radio-button :label="true" :disabled="disabled"><i class="icon icon-check" style="font-size:25px"></i><span style="font-size:18px">ผ่าน</span></el-radio-button>
          <el-radio-button :label="false" :disabled="disabled"><i class="icon icon-x" style="font-size:25px"></i><span style="font-size:18px">ไม่ผ่าน</span></el-radio-button>
        </el-radio-group>
      </div>
    </div>

    <div class="col-md-6 flex">
      <div class="container row flex-middle flex-center">
        <el-radio-group v-model="info.isMinor" size="large" :disabled="disabled">
          <el-radio-button :label="true" :disabled="disabled"><i class="icon icon-check" style="font-size:25px"></i><span style="font-size:18px">ผ่าน</span></el-radio-button>
          <el-radio-button :label="false" :disabled="disabled"><i class="icon icon-x" style="font-size:25px"></i><span style="font-size:18px">ไม่ผ่าน</span></el-radio-button>
        </el-radio-group>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4 flex">
    </div>

    <div class="col-md-2 flex">
    </div>

    <div class="col-md-6 row flex">
    </div>

    <div class="col-md-6 flex">
      <div class="container row flex-middle flex-center" style="margin-left: 100px;" v-if="!info.isMajor">
        <el-radio v-model="info.isMajorAg" :label="true" :disabled="disabled">Ag</el-radio>
        <el-radio v-model="info.isMajorAg" :label="false" style="padding-left: 10px;" :disabled="disabled">He</el-radio>
      </div>
    </div>

    <div class="col-md-6 flex">
      <div class="container row flex-middle flex-center" style="margin-left: 100px;" v-if="!info.isMinor">
        <el-radio v-model="info.isMinorAg" :label="true" :disabled="disabled">Ag</el-radio>
        <el-radio v-model="info.isMinorAg" :label="false" style="padding-left: 10px;" :disabled="disabled">He</el-radio>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'blood-request-multi-crossmatch-row',
  data () {
    return {
      clonedBags: []
    }
  },
  props: [
    'bags',
    'info',
    'disabled'
  ],
  created: function () {
    this.bags.map((item) => {
      this.clonedBags.push(item)
    })

    if (this.info.bloodBagId2 != null && this.info.bloodBagId2 !== '') {
      const isExist = this.bags.some((item) => {
        return item.bloodBagId1 === this.info.bloodBagId2
      })

      if (!isExist) {
        this.clonedBags.push({
          bloodBagId1: this.info.bloodBagId2
        })
      }
    }

    if (this.info.bloodBagId1 != null && this.info.bloodBagId1 !== '') {
      const isExist = this.bags.some((item) => {
        return item.bloodBagId1 === this.info.bloodBagId1
      })

      if (!isExist) {
        this.clonedBags.push({
          bloodBagId1: this.info.bloodBagId1
        })
      }
    }
  }
}
</script>

<style>

</style>
