<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },

    name: {
      type: String,
      default: "",
    },
  },

  render() {
    return <div>{this.$slots.default}</div>;
  },
};
</script>
