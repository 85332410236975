<template>
  <div class="container flex-start label-template" style="width:10.1cm;">
    <div class="row flex-bottom">
      <!-- Barcode -->
      <div class="col-md-12">
        <svg id="barcode"></svg>
      </div>
      <!-- BloodType -->
      <p style="font-size:40px; margin-top: -30px; font-weight: bold;">{{bloodProduct}}</p>

      <!-- Pet Icon -->
      <img v-if="petType==petTypeEnums.DOG" style="padding-top: 5px;" src="@/assets/bloodbank/shapeDog.png" class="petPicture "/>
      <img v-if="petType==petTypeEnums.CAT" src="@/assets/bloodbank/shapeCat.png" class="petPicture"/>
    </div>

    <div class="row">
      <!-- BloodBagId -->
      <div class="col">
        <span class="col-md-8 twentySevenPixelFont" style="font-weight: bold;">Blood Bag Id : </span>
        <span class="col-md-4 flex-start" style="font-weight: bold;">{{bloodBagId}}</span>
      </div>
    </div>

    <div class="row" style="margin-left: 0px;">
      <div class="col-md-12">
        <!-- Pet Name Label -->
        <span style="margin-left: -4px; font-weight: bold;">Pet Name:</span><br>
        <!-- Pet Name -->
        <span style="font-weight: bold; font-size: 18px; line-height: 18px;">{{petName}}</span>
      </div>
      <!-- Blood state -->
      <div class="col-md-12" style="padding-top: 5px;">
<!--        <at-timeline  v-if="bloodProduct != bloodProductEnum.SWB && bloodProduct != bloodProductEnum.FWB" style="margin-top: -15px;">-->
<!--          <at-timeline-item>-->
<!--            &lt;!&ndash; <h2 style="height:50px;">&#9899; {{bloodProduct}} {{ moment(dateCollect).format('DD/MM/YYYY hh:mm A') }}</h2> &ndash;&gt;-->
<!--            <p style="font-size: 11px; font-weight: bold;">{{bloodProduct}} {{ moment(dateCollect).format('DD/MM/YYYY hh:mm A') }}</p>-->
<!--          </at-timeline-item>-->
<!--        </at-timeline>-->

        <at-timeline v-if="bloodProduct == bloodProductEnum.FWB" style="margin-top: -15px;">
          <at-timeline-item>
            <p style="font-size: 11px; font-weight: bold;">{{bloodProduct}} {{ moment(dateCollect).format('DD/MM/YYYY hh:mm A') }}</p>
          </at-timeline-item>
          <at-timeline-item>
            <p style="font-size: 11px; font-weight: bold;">{{bloodProductEnum.SWB}} {{ moment(dateCollect).add(6, 'hours').format('DD/MM/YYYY hh:mm A') }}</p>
          </at-timeline-item>
        </at-timeline>

<!--         <at-timeline v-if="bloodProduct == bloodProductEnum.SWB" style="margin-top: -15px;">-->
<!--          <at-timeline-item>-->
<!--            <p style="font-size: 11px; font-weight: bold;">{{bloodProduct}} {{ moment(dateCollect).subtract(6, 'hours').format('DD/MM/YYYY hh:mm A') }}</p>-->
<!--          </at-timeline-item>-->
<!--          <at-timeline-item>-->
<!--            <p style="font-size: 11px; font-weight: bold;">{{bloodProductEnum.SWB}} {{ moment(dateCollect).format('DD/MM/YYYY hh:mm A') }}</p>-->
<!--          </at-timeline-item>-->
<!--        </at-timeline>-->
      </div>
    </div>

    <div class="row" :class="{'margin-minus-43': bloodProduct == bloodProductEnum.SWB || bloodProduct == bloodProductEnum.FWB}">
      <!-- PetId Label-->
      <!-- PetId -->
      <div class="col">
        <span style="font-weight: bold;">Pet ID : </span>

        <span style="font-weight: bold;">{{petID}}</span><br>

        <!-- DonationId Label -->
        <span style="font-weight: bold;">Donation ID : </span>
        <!-- Donation Id -->
        <span style="font-weight: bold;">{{donationID}}</span><br>

        <!-- PCV Label -->
        <span style="font-weight: bold;">PCV : </span>
        <!-- PCV Value -->
        <span style="font-weight: bold;">{{pcv}} %</span><br>

        <span style="font-weight: bold;">Blood Type : </span>
        <!-- BloodType -->
        <span style="font-weight: bold; font-size: 16px;">{{bloodGroup}}</span><br>

        <span style="font-weight: bold; margin-bottom: 0;">Volume :</span><br>

        <span style="font-size: 26px; font-weight: bold; line-height: 26px;">{{(volumn * 100) / 100}}</span>
        <span style="font-weight: bold; margin-left: 20px;">ml.</span>
      </div>

            <!-- Date Collect Label -->
      <div class="col">
        <span style="font-weight: bold;">Date Collect: </span><br>
        <span style="font-size: 24px; font-weight: bold; margin-top: -10px;">{{moment(dateCollect).format('DD/MM/YYYY')}}</span><br><br>

        <span style="font-weight: bold;" class="col-md-12">Date Expire :</span><br>
        <span style="font-size: 24px; font-weight: bold; margin-top: -10px;">{{moment(dateExpire).format('DD/MM/YYYY')}}</span>
      </div>
    </div>

<!--      <div class="row" style="margin-bottom: 0;">-->
<!--        <div class="col">-->
<!--          <span style="font-weight: bold;" class="col-md-12">Volume :</span>-->
<!--        </div>-->
<!--        <div class="col">-->
<!--          <span style="font-weight: bold;" class="col-md-12">Date Expire :</span>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="row flex-middle" style="margin-top: 0;">-->
<!--          <div class="col-md-12 flex">-->
<!--            <span style="font-size: 30px; font-weight: bold; margin-top: -10px;">{{(volumn * 100) / 100}}</span>-->
<!--            <span style="margin-top: 5px; font-weight: bold; margin-left: 20px;">ml.</span>-->
<!--          </div>-->

<!--        <div class="col-md-12 flex">-->
<!--          <span style="font-size: 25px; font-weight: bold;">{{moment(dateExpire).format('DD/MM/YYYY')}}</span>-->
<!--          &lt;!&ndash; <div class="row">-->
<!--            <p style="margin-top: 5px;">Reserve by _____________</p>-->
<!--          </div> &ndash;&gt;-->
<!--        </div>-->
<!--      </div>-->
  </div>
</template>

<script>
import PetTypeEnums from '@/constants/bloodbank/pet-type-enum'
import jsBarcode from 'jsbarcode'
import bloodProductEnum from '@/constants/bloodbank/blood-product-type-enum.js'
import moment from 'moment'

export default {
  name: 'bloodBagLabelTemplate',
  props: ['bloodBagId', 'petName', 'petType', 'petID', 'bloodGroup', 'volumn', 'bloodProduct', 'donationID', 'dateCollect', 'dateExpire', 'pcv'],
  data () {
    return {
      petTypeEnums: PetTypeEnums,
      bloodProductEnum: bloodProductEnum,
      moment: moment
    }
  },
  mounted: function () {
    jsBarcode('#barcode', this.bloodBagId, {
      displayValue: false,
      height: 51,
      marginBottom: -25
    })
  }
}

</script>

<style lang="scss">
  .label-template {
    font-size: 13px !important;

    p {
      color: #3F536E;
      margin: 0;
    }

    .twentySevenPixelFont {
      /* font-family: 'SukhumvitSet-Light'; */
      /* font-size: 27px */
    }

    .twentyEightPixelFont {
      /* font-family: 'SukhumvitSet-Light'; */
      /* font-size: 28px */
    }

    .sixtyPixelFont {
      /* font-family: 'SukhumvitSet-Light'; */
      /* font-size:60px */
    }

    .seventyPixelFont {
      /* font-size: 70px */
    }

    .boldFont {
      font-weight: bold;
      /* font-family: 'SukhumvitSet-Bold'; */
    }

    .petPicture {
      width: 45px;
      height: 35px;
      margin-left: 30px;
    }

    .circle:before {
      content: '\25CF';
      font-size: 20px;
    }

    .margin-minus-43 {
      margin-top: -43px;
    }
  }
</style>
