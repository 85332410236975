import * as vuexHelper from "../helpers/vuexHelper";

import { coreAPI } from "../../services/apis";
import * as endpoints from "../../services/endpoints";
// import { medicalStatus } from "../../constants"

const config = {
  default: {
    api: coreAPI
  },
  state: {
    all: null,
    selected: null,
    pet: null,
    overview: null,
    today: null,
    calendar: null,
    problem: [],
    foodtype: [],
    diagnosis: [],
    tag: null,
    note: null,
    backupActivities: null,
    medicalDrugLabelPDF: []
  },
  mutations: {
    setPet (state, newPet) {
      state.pet = {...state.pet, ...newPet}
    },
    addFoodtype (state, newFoodtype) {
      if (newFoodtype) {
        const found = state.foodtype.some(el => el.id === newFoodtype.id);
        if (!found) state.foodtype.push(newFoodtype);
      }
    },
    addDiagnosis (state, newDiagnosis) {
      if (newDiagnosis) {
        const found = state.diagnosis.some(el => el.id === newDiagnosis.id);
        if (!found) state.diagnosis.push(newDiagnosis);
      }
    },
    addProblem (state, newProblem) {
      if (newProblem) {
        const found = state.problem.some(el => el.id === newProblem.id);
        if (!found) state.problem.push(newProblem);
      }
    },
    addAttachment(state, newAttachment) {
      state.selected.labexam[newAttachment.prop].unshift(newAttachment.data)
    },
    addPOAttachment(state, newAttachment) {
      state.selected.problem_oriented.attachments.unshift(newAttachment)
    },
    deleteAttachment(state, attachment) {
      state.selected.labexam[attachment.section].splice(attachment.index, 1)
    },
    deletePOAttachment(state, attachment) {
      state.selected.problem_oriented.attachments.splice(attachment.index, 1)
    },
    setMedicalDrugLabelPDF(state, data) {
      let i = state.medicalDrugLabelPDF.findIndex(m => m.id === data.id)
      if (i === -1) {
        state.medicalDrugLabelPDF.push(data)
      } else {
        state.medicalDrugLabelPDF[i] = data
      }
    }
  },
  getters: {
    getMedicalDrugLabel: state => id => state.medicalDrugLabelPDF.find(m => m.id === id)
  },
  asyncActions: [
    {
      name: "getCalendar",
      method: "get",
      url: `${endpoints.medicals}calendar/`,
      setState: "calendar"
    },
    {
      name: "listMedicals",
      method: "get",
      url: `${endpoints.medicals}`,
      setState: "all"
    },
    {
      name: "getMedical",
      method: "get",
      url: ({ id }) => `${endpoints.medicals}${id}/`,
      setState: "selected",
      before(payload, { commit }) {
        commit('SET_SELECTED', null)
      }
    },
    {
      name: "getMedicalByRevision",
      method: "get",
      url: ({ id, revision }) => `${endpoints.medicals}${id}/revision/${revision}/`,
      setState: "selected",
      before(payload, { commit }) {
        commit('SET_SELECTED', null)
      }
    },
    {
      name: "getMedicalNote",
      method: "get",
      url: ({ id }) => `${endpoints.medicals}${id}/afternote/`,
      setState: "note",
      before(payload, { commit }) {
        commit('SET_NOTE', null)
      }
    },
    {
      name: "createMedicalNote",
      method: "post",
      url: ({ id }) => `${endpoints.medicals}${id}/afternote/`,
    },
    {
      name: "createMedical",
      method: "post",
      url: `${endpoints.medicals}`,
    },
    {
      name: "createAppointmentMarkdoneMedical",
      method: "patch",
      url: ({ id }) => `${endpoints.appointments}appointments/${id}/`,
    },
    {
      name: "deleteMedical",
      method: "post",
      url: ({ id }) => `${endpoints.medicals}${id}/discard/`,
    },
    {
      name: "updateMedical",
      method: "patch",
      url: ({ id }) => `${endpoints.medicals}${id}/`,
    },
    {
      name: 'upload',
      method: 'post',
      url: `${endpoints.medicals}attachments/`
    },
    {
      name: "getOverview",
      method: "get",
      url:  `${endpoints.medicals}overview/`,
      setState: "overview"
    },
    {
      name: "getTodayMedicals",
      method: "get",
      url:  `${endpoints.medicals}today/`,
      setState: "today"
    },
    {
      name: "getReminderTemplate",
      method: "get",
      url:  `${endpoints.medicals}reminder/template/`,
    },
    {
      name: "getProblem",
      method: "get",
      url: `${endpoints.medicals}problem/`,
      onSuccess: (result, payload, { commit }) => {
        result.results.forEach(obj => {
          commit('addProblem', obj);
        });
      }
    },
    {
      name: "createProblem",
      method: "post",
      url:  `${endpoints.medicals}problem/`,
      onSuccess: (result) => {
        return result
      }
    },
    {
      name: "getFoodtype",
      method: "get",
      url: `${endpoints.medicals}foodtype/`,
      onSuccess: (result, payload, { commit }) => {
        result.results.forEach(obj => {
          commit('addFoodtype', obj);
        });
      }
    },
    {
      name: "createFoodtype",
      method: "post",
      url:  `${endpoints.medicals}foodtype/`,
      onSuccess: (result) => {
        return result
      }
    },
    {
      name: "getDiagnosis",
      method: "get",
      url: `${endpoints.medicals}diagnosis/`,
      onSuccess: (result, payload, { commit }) => {
        result.results.forEach(obj => {
          commit('addDiagnosis', obj);
        });
      }
    },
    {
      name: "getDiagnosisGroupList",
      method: "get",
      url: `${endpoints.medicals}diagnosis/group/`,
    },
    {
      name: "createDiagnosis",
      method: "post",
      url:  `${endpoints.medicals}diagnosis/`,
      onSuccess: (result) => {
        return result
      }
    },
    {
      name: "getTag",
      method: "get",
      url:  ({ search }) => `${endpoints.medicals}tag/?search=${search}`,
      onSuccess: (result, payload, { commit }) => {
        commit('SET_TAG', result.results)
      }
    },
    {
      name: "createTag",
      method: "post",
      url:  `${endpoints.medicals}tag/`,
      onSuccess: (result) => {
        return result
      }
    },
    {
      name: "attachment",
      method: "post",
      url:  `${endpoints.medicals}attachment/`,
      onSuccess: (result) => {
        return result
      }
    },
    {
      name: "getAttachment",
      method: "get",
      url: `${endpoints.medicals}attachment/`
    },
    {
      name: "getMedicalLabelList",
      method: "get",
      url: ({ id }) => `${endpoints.medicals}${id}/medicationlabel/print/`
    },
    {
      name: "updateMedicalLabelList",
      method: "put",
      url: ({ id, drug_id }) => `${endpoints.medicals}${id}/medicationlabel/${drug_id}/`
    },
    {
      name: "listDiagnosisSummary",
      method: "get",
      url: `${endpoints.medicals}diagnosis/summary/`,
    },
    {
      name: "detailDiagnosisSummary",
      method: "get",
      url: ({ id }) => `${endpoints.medicals}diagnosis/summary/${id}/`
    },
    {
      name: "transactionDiagnosisSummary",
      method: "get",
      url: ({ id }) => `${endpoints.medicals}diagnosis/summary/${id}/transactions/`
    },
    {
      name: "chartDiagnosisSummary",
      method: "get",
      url: ({ id, year }) => `${endpoints.medicals}diagnosis/summary/${id}/yearly/${year}/`
    },
    {
      name: "setBadDebt",
      method: "post",
      url: ({ id }) => `${endpoints.medicals}${id}/baddebt/`,
    },
  ]
};

export default vuexHelper.createStoreModule(config);
