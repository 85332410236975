<template>
  <canvas id="doner_age_chart" height="100px"></canvas>
</template>

<script>
import { barChartGenerator, barChartDecorationDataGenerator } from '../../utils/report'

export default {
  name: "BloodbankDonerAgeChart",
  props: ['bloodbank', 'petType'],
  data() {
    return {
      chartObject: null,
    }
  },
  methods: {
    generateDataSet: function () {
      let dataGenerated = {};
      dataGenerated.data = this.bloodbank.others[this.petType].data.donerAge
        .map(item => item.number);
      dataGenerated = barChartDecorationDataGenerator(dataGenerated);
      dataGenerated.label = this.bloodbank.others[this.petType].data.donerAge
        .map(item => item.name);
      return dataGenerated;
    },
    generateChart: function () {
      if(this.bloodbank.others)
        this.chartObject = barChartGenerator('doner_age_chart', this.generateDataSet(), this.chartObject)
    },
  },
  watch: {
    'bloodbank.others': function () {
      this.generateChart();
    },
    'petType': function () {
      this.generateChart();
    },
  },
  mounted() {
    this.generateChart();
  },
}
</script>