const BaseIcon = ({ data }) => (
  <div
    {...data}
    style={{
      display: "inline-block",
      border: "2px solid white",
      verticalAlign: "middle",
    }}
  />
);

export default BaseIcon;
