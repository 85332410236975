<template>
  <div class="pet-owner-login">
    <HospitalConnectionTag style="margin-top: -30px"/> 
    
    <div class="form-block">
      <div class="header-block">
        <div class="title">
          Pet Owner
          <div class="lead">
            Log in
          </div>
        </div>
      </div>
      <el-form ref="form" :model="form">
        <el-form-item>
          <el-input class="pet-owner-register"
            prefix-icon="far fa-user"
            type="tel"
            v-validate="'required'"
            name="telephone"
            placeholder="Telephone"
            v-model="form.telephone"></el-input>
          <span class="error">{{ errors.first('telephone') }}</span>
        </el-form-item>

        <el-form-item>
          <el-input class="pet-owner-register"
            prefix-icon="fas fa-unlock-alt"
            type="password"
            v-validate="'required'"
            name="password"
            ref="password"
            placeholder="Password"
            v-model="form.password"></el-input>
          <span class="error">{{ errors.first('password') }}</span>
        </el-form-item>

        <el-form-item class="login-optional">
          <div class="term-and-condition">
            <el-checkbox v-model="termChecked">ยอมรับ 
              <span>
                <el-link href="https://drive.google.com/file/u/3/d/1CB2gkIKR-74QXjfs4HaMZn6zbIX3Tl5l/view?usp=sharing" :underline="false" target="_blank" rel="norefferer">Term and Condition</el-link>
                </span>
            </el-checkbox>
        </div>
          <div>
            <router-link :to="{ name: 'petOwnerForgetPassword' }"><span>forget password?</span></router-link>
          </div>
        </el-form-item>

        <el-form-item class="text-center">
          <el-button
            :loading="submitButtonLoading"
            type="submit"
            class="btn btn-primary form-submit-btn btn-pet-login"
            :disabled='toggleSignin'
            @click="onSubmit">sign in</el-button>
          <span class="error">{{ errMessage }}</span>
        </el-form-item>
      </el-form>
      <div class="footer-block">
        <span>ไม่ได้เป็นสมาชิก? </span>
        <router-link :to="{ name: 'petOwnerRegister' }">สมัครสมาชิกที่นี่</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import HospitalConnectionTag from '@/components/pet/HospitalConnectionTag'

export default {
  components: {
    HospitalConnectionTag
  },
  data() {
    return {
      form: {
        telephone: '',
        password: '',
      },
      errMessage: '',
      submitButtonLoading: false,
      termChecked: true,
    }
  },
  mounted() {
    if (this.$store.state.owner.profile != null)
    if (this.$store.state.owner.profile.phone != null) {
      this.form.telephone = this.$store.state.owner.profile.phone;
      this.$refs.password.focus();
    }
  },
  computed: {
    toggleSignin: function(){
      return !this.termChecked;
    }
  },
  methods: {
    async onSubmit() {
      this.submitButtonLoading = true
      const valid = await this.$validator.validateAll()
      if (valid) {
        try {
          await this.$store.dispatch('auth/ownerLogin', {
            data: {
              telephone: this.form.telephone,
              password: this.form.password
            }
          })
          var redirectTo = this.$route.query.redirect;
          if (redirectTo) {
            this.$router.push({ path: redirectTo });
          } else {
            this.$router.push({ name: 'petOwnerHome' })
          }
        } catch (e) {
          this.errMessage = 'Incorrect Password'
        }
      }
      this.submitButtonLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.form-block {
  @extend %block-container;
  @extend %form-style;

  padding-bottom: 48px;
}
.footer-block {
  font-size: 14px;
  text-align: center;
  span {
    color: $gray;
  }
  a {
    text-decoration: none;
    color: #000;
  }
}
</style>

<style lang="scss" scoped>
.login-optional {
  .el-form-item__content {
    div {
      width: auto;
      display: inline-block;
      vertical-align: middle;

      .el-checkbox__label, a {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: 0.1px;
        text-align: center;
        color: #8e8e93;
        text-decoration: none;
      }
    }
    div:first-child {
      float: left;
    }
    div:last-child {
      float: right;
    }
  }
  .el-checkbox__inner {
    border-radius: 8px;
  }
}
button.btn-pet-login {
  width: 213px !important;
  font-size: 17px !important;
}
</style>
