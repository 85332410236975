<template>
  <div>
    <table class="doctors-fee-table">
      <thead>
        <tr>
          <th class="date">Date.</th>
          <th class="activity">Activity</th>
          <th class="price">Doctor’s  fee (DF)</th>
          <th class="action"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td class="date">{{ item.date | toThaiDate }}</td>
          <td class="activity">
            <div class="thumbnail">
              <img v-if="item.activity.attachment" 
                :src="item.activity.attachment" />
            </div>
            <div class="info">
              <div class="subtitle">{{ item.activity.name }}</div>
            </div>
          </td>
          <td class="price">
            {{ item.total_df | currency }}
          </td>
          <td class="action">
            <el-button
              size="medium"
              @click="$emit('seeDetail', item)"
            >
              See Detail
              <i class="el-icon-arrow-right el-icon-right"></i>
            </el-button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="not-found" v-if="items.length === 0">ไม่พบข้อมูลที่ต้องการค้นหา</div>
    <el-pagination
      class="pagination"
      background
      layout="prev, pager, next"
      :current-page="currentPage"
      :page-size="pageSize"
      :total="total"
      @current-change="(page) => $emit('current-change', page)"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    pageSize: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>

.doctors-fee-table {
  width: 100%;
  border-collapse: collapse;
  font-family: "Kanit", sans-serif;

  thead {
    tr {
      height: 54px;
      border-radius: 4px;
      border: #e6eaee 1px solid;

      th {
        padding: 5px;
        font-weight: normal;
        color: rgba(0, 0, 0, 0.48);
        background-color: #f5f8fa;
        text-align: center;
        font-size: 14px;
        font-family: "Kanit", sans-serif;

        &.date {
          width: 120px;
        }

        &.activity {
          width: 180px;
        }

        &.price {
          width: 200px;
        }

        &.action {
          width: 100px;
        }
      }
    }
  }

  tbody {
    tr {
      height: 56px;
      border-bottom: 1px #eee solid;
      font-family: "Kanit", sans-serif;

      td {
        position: relative;
        padding: 5px;
        text-align: center;
        font-size: 14px;
        font-family: "Kanit", sans-serif;

        .thumbnail {
          display: inline-block;
          width: 50px;
          height: 50px;
          overflow: hidden;
          background: #eee;
          border-radius: 5px;
          margin-right: 10px;
          vertical-align: middle;

          > img {
            width: 50px;
            height: 50px;
            border-radius: 5px;
          }
        }

        .info {
          display: inline-block;
          width: calc(100% - 60px);
          vertical-align: middle;
        }

        .ellipsis {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &.activity {
           text-align: left; 
        }

      }
    }
  }
}

.not-found {
  text-align: center;
  padding: 20px 0;
}

.pagination {
  margin-top: 20px;
  text-align: right;
}
</style>


