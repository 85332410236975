<template>
  <div>
    <Subnavbar>
      <template slot="title">Home</template>
      <template slot="actions">
        <button
          class="btn btn-large btn-new-pet"
          v-if="canNewPet"
          @click="openNewPet()">
          <i class="fa fa-plus-circle"></i>New Pet
        </button>

        <button class="btn btn-large btn-calendar"  @click="openCalendar()">
          <i class="fa fa-calendar-alt"></i>Calendar
        </button>

        <button class="btn btn-large btn-pet-profile" @click="openPetProfile()">
          <i class="fa fa-list-alt"></i>Pet Profile
        </button>
      </template>
    </Subnavbar>

    <ContentBox v-if="todayOverview">
      <TodayOverview
        :income="+todayOverview.total_income"
        :expense="+todayOverview.total_expense"
        :deposit="+todayOverview.total_deposit"
        :overdue="+todayOverview.total_overdue"
      />
    </ContentBox>

    <h3>Appointment List</h3>
    <ApptTodayWithOverview />

    <div :style="{ marginBottom: '4em' }">
      <el-row type="flex" :gutter="16" :style="{ flexWrap: 'wrap' }">
        <el-col :md="12">
          <OpdToday :style="{ height: '100%' }" />
        </el-col>
        <el-col :md="12">
          <IpdToday :style="{ height: '100%' }" />
        </el-col>
      </el-row>
    </div>

    <ContentBox
      v-if="canUsePos">
      <el-row class="content-middle">
        <el-col :span="16">
          <POSOverview
            :gainRevenue="pos.overview.gainRevenue"
            :case="pos.overview.case"
            :inAction="pos.overview.inAction"
            :loading="pos.overview.isLoading"
          />
        </el-col>
        <el-col :span="8" class="text-right">
          <button
            class="btn btn-large btn-medical"
            @click="openNewPos()" >
            <i class="fa fa-plus-circle"></i>POS Record
          </button>
        </el-col>
      </el-row>
    </ContentBox>

    <POSTable
      v-if="canUsePos"
      style="margin-bottom: 60px"
      :items="pos.items"
      :page="pos.page"
      :total="pos.total"
      :page-size="pos.pageSize"
      @page-change="handlePOSResultPageChange"
      @view="handlePOSResultView" />

    <el-divider><h2>More Action</h2></el-divider>

    <div class="more-action">
      <button class="btn btn-large btn-redeem"
        @click="openNewRedeem()">
        <img src="@/assets/images/reward/hand-card.png"
          style="width: 56px; height: 32px;"/>
          <span style="width: auto;">+ New Redeem</span>
      </button>

      <button class="btn btn-large btn-broadcast"
        @click="openNewBroadCast()">
        <img src="@/assets/images/broadcast/microphone.png"
          style="width: 26px; height: 47px;"/>
          <span>+ New Broadcast</span>
      </button>
    </div>

  </div>
</template>

<script>
import { mapState } from "vuex"
import moment from "moment"
import Subnavbar from "@/components/navigation/Subnavbar"
import ApptTodayWithOverview from "@/components/appointment/ApptTodayWithOverview"
import MedicalRecordTodayWithOverview from "@/components/medical/MedicalRecordTodayWithOverview"

import {
  //TodayFinance,
  //MedicalCard,
  OpdToday,
  IpdToday,
  // IpdTable,
  // OpdTable,
  POSOverview,
  TodayOverview
} from "./HomePage/components"


//import Tracker from "@/components/common/Tracker"
import POSTable from "@/pages/pos/Pos/DataTable"
import { startToday, endToday } from '@/utils/datetime'
import ContentBox from "@/components/container/ContentBox";

export default {
  components: {
    Subnavbar,
    TodayOverview,
    ApptTodayWithOverview,

    MedicalRecordTodayWithOverview,

    //TodayFinance,
    //Tracker,
    OpdToday,
    IpdToday,
    // IpdTable,
    // OpdTable,
    POSTable,
    POSOverview,
    ContentBox,
  },

  data() {
    const { query } = this.$route;

    return {
      pos: {
        items: [],
        page: Number(query.posPage) || undefined,
        total: 0,
        pageSize: 10,
        overview: {
          case: 0,
          inAction: 0,
          gainRevenue: 0,
          isLoading: false
        }
      },
    }
  },

  computed: {
    ...mapState({
      currentPlan: state => state.plan.currentPlan,
      todayOverview: state => state.hospital.todayoverview
    }),
    getCurrentDate: function() {
      return moment().format("DD MMM YYYY");
    },
    canNewPet() {
       return this.currentPlan && this.currentPlan.hospital_feature.can_create_owner_and_pet;
    },
    canUsePos() {
        return this.currentPlan.pos_section.can_use_pos
    }
  },

  methods: {
    openNewPet() {
        this.$router.push({ name: 'hospitalPetsSearch' })
    },
    openCalendar() {
        this.$router.push({ name: 'calendar' })
    },
    openPetProfile() {
        this.$router.push({ name: 'hospitalPets' })
    },
    openNewPos() {
        this.$router.push({ name: 'pos-create' })
    },
    openNewRedeem() {
        this.$router.push({ name: 'redeem-select-pet' })
    },
    openNewBroadCast() {
        this.$router.push({ name: 'hospitalBroadcastCreate' })
    },
    async fetchTodayOverview() {
      await this.$store.dispatch("hospital/getTodayOverview");
    },
    async fetchPOSList() {
      //--- Get pos list ---

      this.$store.dispatch("ui/setIsFullScreenLoading", true);

      try {
        const { results, count } = await this.$store.dispatch("pos/list", {
          params: {
            start: startToday() || undefined,
            end: endToday() || undefined,
            page: this.pos.page,
          },
        });

        this.pos.items = results.map((item) => ({
          id: item.id,
          status: item.status,
          date: item.datetime ? new Date(item.datetime) : null,
          consumer: item.owner ? item.owner.get_fullname : "",
          revenue: +item.revenue,
        }));

        this.pos.total = count;

        this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (e) {
        this.$store.dispatch("ui/showFetchErrorWithReloadMsg");
      }
    },

    async fetchPOSOverview() {
      this.pos.overview.isLoading = true;
      //--- get pos overview ---
      try {
        const { gain_revenue, count, in_action } = await this.$store.dispatch("pos/overview", {
          params: {
            start: startToday() || undefined,
            end: endToday() || undefined,
          },
        });

        this.pos.overview.gainRevenue = +gain_revenue.replace(/,/g, "");
        this.pos.overview.case = +count;
        this.pos.overview.inAction = +in_action;

        this.pos.overview.isLoading = false;
      } catch (e) {
        this.$store.dispatch("ui/showFetchErrorWithReloadMsg");
      }
    },

    handlePOSResultPageChange(posPage) {
      this.pos.page = posPage;

      this.$router.replace({
        query: { ...this.$route.query, posPage },
      });

      this.fetchPOSList();
    },

    handlePOSResultView(value) {
      this.$router.push({ name: "pos-detail", params: { id: value } });
    },
  },

  created() {
    //--- Fetch Data ---
    if (this.currentPlan.report_section.can_view_home_overview) {
      this.fetchTodayOverview();
    }

    if (this.currentPlan.pos_section.can_use_pos) {
      this.fetchPOSList();
      this.fetchPOSOverview();
    }
  },
}
</script>

<style lang="scss" scoped>
.content-middle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.today-date {
  background-color: $white;
  display: inline-block;
  padding: 0px 118px;
}
.el-divider__text {
    background-color: #f0f2f5;
}
.more-action {
  margin-top: 40px;
  margin-bottom: 120px;
  display: inline-block;

  button {
    width: 234px;
    height: 104px;
    font-size: 17px;
    margin-left: 21px;
    text-align: right;
    line-height: 1.2em;
    vertical-align: middle;

    &:first-child {
      margin-left: 0;
    }

    span {
      display: inline-block;
      vertical-align: middle;
      width: 75%;
      margin-left: 4px;
      text-align: center;
    }

    img {
      object-fit: contain;
      margin-right: 6px;
      vertical-align: middle;
      display: inline-block;
    }
  }
}
</style>