/* eslint-disable no-unused-vars, no-console */
import * as vuexHelper from '../helpers/vuexHelper'

import { coreAPI } from '../../services/apis'
import * as endpoints from '../../services/endpoints'
import utils from '../../utils/bloodbank'

import BloodDonationInfo from '@/models/data/blood-donate/blood-donate-info/blood-donate-info'
import MedicalRecordDonation from '@/models/data/common/medical-record/medical-record-blood-donation'
import DonorCareInfo from '@/models/data/blood-donate/donor-care-info/donor-care-info'
import BloodCollectInfo from '@/models/data/blood-donate/blood-collect-info/blood-collect-info'
import LabExam from '@/models/data/common/lab-exam/lab-exam'
import PetInfo from '@/models/data/common/pets-info/pet-info/pet-info'
import petTypeEnum from '@/constants/bloodbank/pet-type-enum.js'
import BloodBag from '@/models/data/blood-storage/blood-bag/blood-bag'
import BloodBagHistoryInfo from '@/models/data/blood-storage/blood-bag-history-info/blood-bag-history-info'
import BloodRequestHospitalDetail from '../../models/data/blood-request-hospital/blood-request-hospital-detail/blood-request-hospital-detail'
import BloodRequestHospitalApproval from '../../models/data/blood-request-hospital/blood-request-hospital-approval/blood-request-hospital-approval'

import BloodRequest from '@/models/data/blood-request/blood-request'
import BloodRequestInfo from '@/models/data/blood-request/blood-request-info/blood-request-info'
import BloodRecipientProfile from '@/models/data/blood-request/blood-request-profile-info/blood-request-profile-info'
import MedicalRecordRequest from '@/models/data/common/medical-record/medical-record-blood-request'
import Crossmatch from '@/models/data/blood-request/blood-request-crossmatch-info/blood-request-crossmatch-info'
import ProductRequest from '../../models/data/blood-request/product-request-info/product-request-info'
import ReserveBlood from '@/models/data/blood-request/acquire-blood-bag-info/acquire-blood-bag-info'
import ReceiveBlood from '@/models/data/blood-request/blood-bag-acquire-confirm-info/blood-bag-acquire-confirm-info'
import Feedback from '@/models/data/blood-request/acquire-blood-bag-summary-info/acquire-blood-bag-summary-info'
import CrossmatchItemInfo from '@/models/data/blood-request/blood-request-crossmatch-info/crossmatch-info'

import _ from 'lodash'
import clone from 'clone'
import bagStatus from '../../constants/bloodbank/blood-storage-bag-status'

const config = {
	default: {
		api: coreAPI
	},
	state: {
		donations: [],
		medicalRecords: [],
		labExams: [],
		selectedDonation: null,
		tooltips: null,

		// Blood Inventory
		bloodBags: [],
		bloodBagHistories: [],

		// Blood Request Between Hospital
		bloodHospitalRequests: [],
		bloodHospitalRequestApprovals: [],

		// Blood Request
		requests: [],
		selectedRequest: null,
		diagnosis: []
	},
	getters: {
		getBloodBagById: state => id => state.bloodBags.find(x => x.id === id),
		getClonedBloodBagById: state => id => {
			const bag = state.bloodBags.find(x => x.id === id)
			return clone(bag)
		},
		getOwnedBloodBagList: state => id =>
			state.bloodBags.slice().filter(bag => bag.hospitalId === id),
		getPublicBloodBagList: state => id =>
			state.bloodBags.slice().filter(bag => bag.hospitalId !== id),
		getDonationById: state => id => state.donations.find(x => x.bloodDonationId === id),
		getBloodBagHistoriesByBloodBagId: state => id =>
			state.bloodBagHistories.filter(x => x.blood_collection === id),
		getBloodRequestById: state => id => state.requests.find(x => x.id === id),

		// Blood Request Between Hospital
		getBloodHospitalRequestById: state => id => state.bloodHospitalRequests.find(x => x.id === id),
		getBloodApprovalByBloodRequestId: state => id => state.bloodHospitalRequestApprovals.find(x => x.bloodRequestId === id),
		getClonedBloodApprovalByBloodRequestId: state => id => {
			let approval = state.bloodHospitalRequestApprovals.find(x => x.bloodRequestId === id)
			if (approval) {
				return clone(approval)
			}
			approval = new BloodRequestHospitalApproval()
			approval.bloodRequestId = id
			return approval
		},
	},
	mutations: {
		ADD_DONATION(state, payload) {
			state.donations.push(payload)
			//  console.log('add D', payload)
		},
		ADD_REQUEST(state, payload) {
			state.requests.push(payload)
			//  console.log('add R', payload)
		},
		UPDATE_DONATION(state, payload) {
			let i = state.donations.findIndex(
				d => d.bloodDonationId === payload.bloodDonationId
			)
			state.donations[i] = payload
		},
		UPDATE_REQUEST(state, payload) {
			let i = state.requests.findIndex(d => d.id === payload.id)
			state.requests[i] = payload
		},
		UPDATE_BLOOD_BAG(state, payload) {
			let i = state.bloodBags.findIndex(d => d.id === payload.id)
			state.bloodBags[i] = payload
		},
		NEW_BLOOD_HOSPITAL_REQUEST(state, payload) {
			state.bloodHospitalRequests.push(payload)
		},
		NEW_BLOOD_HOSPITAL_REQUEST_APPROVAL(state, payload) {
			state.bloodHospitalRequestApprovals.splice(0, 0, payload)
		},
		FILL_MEDICAL_RECORD(state, payload) {
			let bloodDonationIdToObj = _.keyBy(
				state.donations,
				o => o.bloodDonationId
			)
			payload.forEach(item => {
				if (bloodDonationIdToObj[item.blood_donation]) {
					bloodDonationIdToObj[
						item.blood_donation
					].medicalRecord = new MedicalRecordDonation(item)
				}
			})
		},
		FILL_LAB_EXAM(state, payload) {
			let bloodDonationIdToObj = _.keyBy(
				state.donations,
				o => o.bloodDonationId
			)
			let medicalRecordIdToInfo = _.keyBy(state.medicalRecords, o => {
				return o.id
			})
			payload.forEach(item => {
				if (medicalRecordIdToInfo[item.object_id]) {
					medicalRecordIdToInfo[item.object_id].labExam = item.id
					bloodDonationIdToObj[
						medicalRecordIdToInfo[item.object_id].blood_donation
					].labExam = new LabExam(item)
				}
			})
		},
		FILL_DONOR_CARE(state, payload) {
			let bloodDonationIdToObj = _.keyBy(
				state.donations,
				o => o.bloodDonationId
			)
			payload.forEach(item => {
				if (bloodDonationIdToObj[item.blood_donation]) {
					bloodDonationIdToObj[
						item.blood_donation
					].donorCareInfo = new DonorCareInfo(item)
				}
			})
		},
		FILL_BLOOD_COLLECT(state, payload) {
			let bloodDonationIdToObj = _.keyBy(
				state.donations,
				o => o.bloodDonationId
			)
			payload.forEach(item => {
				if (bloodDonationIdToObj[item.blood_donation]) {
					bloodDonationIdToObj[
						item.blood_donation
					].bloodCollectInfo = new BloodCollectInfo(item)
				}
			})
		},
		FILL_PET(state, payload) {
			let petIdToInfoDictionary = _.keyBy(payload, o => o.pet.id)
			state.donations.forEach(item => {
				if (petIdToInfoDictionary[item.itaampetId]) {
					let pet = petIdToInfoDictionary[item.itaampetId]
					item.pet = new PetInfo()
					item.pet.setPropsFromPetObject(pet)
				}
			})
		},
	},
	asyncActions: [
		{
			name: 'listBloodDonations',
			method: 'get',
			url: endpoints.bloodDonation
		},
		{
			name: 'listMedicalRecordDonation',
			method: 'get',
			url: endpoints.medicalRecord,
			setState: 'MEDICAL_RECORDS'
		},
		{
			name: 'listLabExam',
			method: 'get',
			url: endpoints.labExam,
			setState: 'LAB_EXAMS'
		},
		{
			name: 'listDonorCareInfo',
			method: 'get',
			url: endpoints.donorCare
		},
		{
			name: 'listBloodCollectInfo',
			method: 'get',
			url: endpoints.bloodCollect
		},
		{
			name: 'listPet',
			method: 'get',
			url: endpoints.petProfiles + '?page_size=10000000&page=1'
		},
		{
			name: 'listBloodCollectionHistory',
			method: 'get',
			url: endpoints.BLOOD_COLLECTION_HISTORY,
			onSuccess: (result, payload, { commit }) => {
				const histories = result.map(item => new BloodBagHistoryInfo(item))
				commit('SET_BLOOD_BAG_HISTORIES', histories)
			}
		},
		{
			name: 'createDonation',
			method: 'post',
			url: endpoints.bloodDonation
		},
		{
			name: 'createMedicalRecordDonation',
			method: 'post',
			url: endpoints.medicalRecord
		},
		{
			name: 'createLabExam',
			method: 'post',
			url: endpoints.labExam
		},
		{
			name: 'createDonorCareInfo',
			method: 'post',
			url: endpoints.donorCare
		},
		{
			name: 'createBloodCollectInfo',
			method: 'post',
			url: endpoints.bloodCollect
		},
		{
			name: 'createVetVerify',
			method: 'post',
			url: endpoints.vetVerification
		},
		{
			name: 'createBloodCollectionHistory',
			method: 'post',
			url: endpoints.BLOOD_COLLECTION_HISTORY
		},
		{
			name: 'updateBloodCollectionHistory',
			method: 'put',
			url: params => endpoints.BLOOD_COLLECTION_HISTORY_ITEM.replace(':id', params.id)
		},
		{
			name: 'updateDonation',
			method: 'put',
			url: params => endpoints.bloodDonationItem.replace(':id', params.id)
		},
		{
			name: 'updateMedicalRecordDonation',
			method: 'put',
			url: params => endpoints.medicalRecordItem.replace(':id', params.id)
		},
		{
			name: 'updateLabExam',
			method: 'put',
			url: params => endpoints.labExamItem.replace(':id', params.id)
		},
		{
			name: 'updateDonorCareInfo',
			method: 'put',
			url: params => endpoints.donorCareItem.replace(':id', params.id)
		},
		{
			name: 'updateBloodCollectInfo',
			method: 'put',
			url: params => endpoints.bloodCollectItem.replace(':id', params.id)
		},
		{
			name: 'listLabExamMeta',
			method: 'get',
			url: params => endpoints.LAB_EXAM_META + '?pet_type__key=' + params.pet_type,
			onSuccess: (result, payload, { commit }) => {
				let tooltips = {
					labExamMeta: {
						cat: {},
						dog: {}
					}
				}
				result.forEach(item => {
					let itemKey = item.pet_type === 1 ? petTypeEnum.DOG : petTypeEnum.CAT
					if (itemKey === petTypeEnum.DOG) {
						tooltips.labExamMeta.dog[item.key.toUpperCase()] = {
							fullname: item.fullname,
							tooltip: item.tooltip,
							tooltipContent: item.tooltipcontent.replace(
								/..\/..\/..\/..\/../g,
								'http://reminder.itaam.co'
							),
							minTooltip: item.min_tooltip,
							minTooltipContent: item.min_tooltipcontent.replace(
								/..\/..\/..\/..\/../g,
								'http://reminder.itaam.co'
							),
							maxTooltip: item.max_tooltip,
							maxTooltipContent: item.max_tooltipcontent.replace(
								/..\/..\/..\/..\/../g,
								'http://reminder.itaam.co'
							)
						}
					} else if (itemKey === petTypeEnum.CAT) {
						tooltips.labExamMeta.cat[item.key.toUpperCase()] = {
							fullname: item.fullname,
							tooltip: item.tooltip,
							tooltipContent: item.tooltipcontent.replace(
								/..\/..\/..\/..\/../g,
								'http://reminder.itaam.co'
							),
							minTooltip: item.min_tooltip,
							minTooltipContent: item.min_tooltipcontent.replace(
								/..\/..\/..\/..\/../g,
								'http://reminder.itaam.co'
							),
							maxTooltip: item.max_tooltip,
							maxTooltipContent: item.max_tooltipcontent.replace(
								/..\/..\/..\/..\/../g,
								'http://reminder.itaam.co'
							)
						}
					}
				})
				commit('SET_TOOLTIPS', tooltips)
			}
		},
		{
			name: 'listBloodRequests',
			method: 'get',
			url: endpoints.BLOOD_REQUEST
		},
		{
			name: 'listBloodRequestProfile',
			method: 'get',
			url: endpoints.BLOOD_REQUEST_PROFILE
		},
		{
			name: 'listBloodRequestMedicalRecord',
			method: 'get',
			url: endpoints.BLOOD_REQUEST_MEDICAL_RECORD
		},
		{
			name: 'listProductRequest',
			method: 'get',
			url: endpoints.PRODUCT_REQUEST
		},
		{
			name: 'listBloodSuggest',
			method: 'get',
			url: endpoints.BLOOD_SUGGEST
		},
		{
			name: 'listCrossmatch',
			method: 'get',
			url: endpoints.CROSSMATCH
		},
		{
			name: 'listReserveBloodBag',
			method: 'get',
			url: endpoints.RESERVE_BLOOD_BAG
		},
		{
			name: 'listReserveBloodBagItem',
			method: 'get',
			url: endpoints.RESERVE_BLOOD_BAG_ITEM
		},
		{
			name: 'listRecieveBloodBag',
			method: 'get',
			url: endpoints.RECEIVE_BLOOD_BAG
		},
		{
			name: 'listFeedback',
			method: 'get',
			url: endpoints.FEEDBACK
		},
		{
			name: 'listDiagnosis',
			method: 'get',
			url: endpoints.DIAGNOSIS,
			setState: 'diagnosis'
		},
		{
			name: 'listBloodHospitalRequests',
			method: 'get',
			url: endpoints.BLOOD_REQUEST_HOSPITAL,
			onSuccess: (result, payload, { commit }) => {
				const requests = result
					.map(item => new BloodRequestHospitalDetail(item))
					.sort(function(a, b) {
						return new Date(b.createdDate) - new Date(a.createdDate)
					})
				commit('SET_BLOOD_HOSPITAL_REQUESTS', requests)
			}
		},
		{
			name: 'listBloodHospitalRequestApprovals',
			method: 'get',
			url: endpoints.BLOOD_REQUEST_APPROVAL,
			onSuccess: (result, payload, { commit }) => {
				const approvals = result
					.map(item => new BloodRequestHospitalApproval(item))
					.sort(function(a, b) {
						return new Date(b.createdDate) - new Date(a.createdDate)
					})
				commit('SET_BLOOD_HOSPITAL_REQUEST_APPROVALS', approvals)
			}
		},
		{
			name: 'createBloodHospitalRequest',
			method: 'post',
			url: endpoints.BLOOD_REQUEST_HOSPITAL
		},
		{
			name: 'createBloodHospitalRequestApproval',
			method: 'post',
			url: endpoints.BLOOD_REQUEST_APPROVAL
		},
		{
			name: 'postQuantityPredict',
			method: 'post',
			url: endpoints.AI_QUANTITY_PREDICTION
		},
		{
			name: 'postPossibilityPredict',
			method: 'post',
			url: endpoints.AI_POSSIBILITY_CALCULATION
		},

		{
			name: 'updateRequest',
			method: 'put',
			url: params => endpoints.BLOOD_REQUEST_ITEM.replace(':id', params.id)
		},
		{
			name: 'updateRequestProfile',
			method: 'put',
			url: params =>
				endpoints.BLOOD_REQUEST_PROFILE_ID.replace(':id', params.id)
		},
		{
			name: 'updateRequestMedicalRecord',
			method: 'put',
			url: params =>
				endpoints.BLOOD_REQUEST_MEDICAL_RECORD_ITEM.replace(':id', params.id)
		},
		{
			name: 'updateProductRequest',
			method: 'put',
			url: params => endpoints.PRODUCT_REQUEST_ITEM.replace(':id', params.id)
		},
		{
			name: 'updateCrossmatch',
			method: 'put',
			url: params => endpoints.CROSSMATCH_ITEM.replace(':id', params.id)
		},
		{
			name: 'updateReserveBloodBag',
			method: 'put',
			url: params => endpoints.RESERVE_BLOOD_BAG + params.id + '/'
		},
		{
			name: 'updateReserveBloodBagItem',
			method: 'put',
			url: params =>
				endpoints.RESERVE_BLOOD_BAG_ITEM_ID.replace(':id', params.id)
		},
		{
			name: 'updateRecieveBloodBagItem',
			method: 'put',
			url: params => endpoints.RECEIVE_BLOOD_BAG_ITEM.replace(':id', params.id)
		},
		{
			name: 'updateFeedback',
			method: 'put',
			url: params => endpoints.FEEDBACK_ITEM.replace(':id', params.id)
		},
		{
			name: 'createRequest',
			method: 'post',
			url: endpoints.BLOOD_REQUEST
		},
		{
			name: 'createRequestProfile',
			method: 'post',
			url: endpoints.BLOOD_REQUEST_PROFILE
		},
		{
			name: 'createRequestMedicalRecord',
			method: 'post',
			url: endpoints.BLOOD_REQUEST_MEDICAL_RECORD
		},
		{
			name: 'createProductRequest',
			method: 'post',
			url: endpoints.PRODUCT_REQUEST
		},
		{
			name: 'createCrossmatch',
			method: 'post',
			url: endpoints.CROSSMATCH
		},
		{
			name: 'createReserveBloodBag',
			method: 'post',
			url: endpoints.RESERVE_BLOOD_BAG
		},
		{
			name: 'createReserveBloodBagItem',
			method: 'post',
			url: endpoints.RESERVE_BLOOD_BAG_ITEM
		},
		{
			name: 'createReceiveBloodBag',
			method: 'post',
			url: endpoints.RECEIVE_BLOOD_BAG
		},
		{
			name: 'createFeedback',
			method: 'post',
			url: endpoints.FEEDBACK
		},
		{
			name: 'deleteCrossmatch',
			method: 'delete',
			url: params => endpoints.CROSSMATCH_ITEM.replace(':id', params.id)
		},
		{
			name: 'deleteReserveBloodBagItem',
			method: 'delete',
			url: params =>
				endpoints.RESERVE_BLOOD_BAG_ITEM_ID.replace(':id', params.id)
		},
		{
			name: 'listFeedbackImage',
			method: 'get',
			url: endpoints.FEEDBACK_IMAGE
		},
		{
			name: 'createFeedbackImage',
			method: 'post',
			url: endpoints.FEEDBACK_IMAGE
		},
		{
			name: 'updateFeedbackImage',
			method: 'patch',
			url: params => endpoints.FEEDBACK_IMAGE_ID.replace(':id', params.id)
		},
		{
			name: 'deleteFeedbackImage',
			method: 'delete',
			url: params => endpoints.FEEDBACK_IMAGE_ID.replace(':id', params.id)
		}
	],

	actions: {
		// DONATION
		async listAndFillBloodDonations({ commit, dispatch }, data) {
			let donations = await dispatch('listBloodDonations')
			donations = donations.map(d => new BloodDonationInfo(d))
			let bloodDonationIdToObj = _.keyBy(donations, o => o.bloodDonationId)
			await Promise.all([
				dispatch('listMedicalRecordDonation').then(medicalRecordResult => {
					medicalRecordResult.forEach(item => {
						if (bloodDonationIdToObj[item.blood_donation]) {
							bloodDonationIdToObj[
								item.blood_donation
							].medicalRecord = new MedicalRecordDonation(item)
						}
					})
					return dispatch('listLabExam').then(labExamResult => {
						let medicalRecordIdToInfo = _.keyBy(medicalRecordResult, o => o.id)

						labExamResult.forEach(item => {
							if (medicalRecordIdToInfo[item.object_id]) {
								medicalRecordIdToInfo[item.object_id].labExam = item.id
								bloodDonationIdToObj[
									medicalRecordIdToInfo[item.object_id].blood_donation
								].labExam = new LabExam(item)
							}
						})
					})
				}),
				dispatch('listDonorCareInfo').then(result => {
					result.forEach(item => {
						if (bloodDonationIdToObj[item.blood_donation]) {
							bloodDonationIdToObj[
								item.blood_donation
							].donorCareInfo = new DonorCareInfo(item)
						}
					})
				}),
				dispatch('listBloodCollectInfo').then(result => {
					result.forEach(item => {
						if (bloodDonationIdToObj[item.blood_donation]) {
							bloodDonationIdToObj[
								item.blood_donation
							].bloodCollectInfo = new BloodCollectInfo(item)
						}
					})
				}),
				dispatch('listPet').then(result => {
					let petIdToInfoDictionary = _.keyBy(result.results, o => o.pet.id)
					donations.forEach(item => {
						if (petIdToInfoDictionary[item.itaampetId]) {
							let pet = petIdToInfoDictionary[item.itaampetId]
							item.pet = new PetInfo()
							item.pet.setPropsFromPetObject(pet)
						}
					})
				})
			])
			commit('SET_DONATIONS', donations)
		},
		async createFullDonation({ commit, dispatch }, data) {
			data.isDonorProfileSubmitted = true
			data.isPhysicalExamSubmitted = true
			let donationRequest = data.toRequestJson()
			let donationResult = await dispatch('createDonation', {
				data: donationRequest
			})
			data.bloodDonationId = donationResult.id
			data.medicalRecord.bloodDonationId = donationResult.id
			data.donorCareInfo.bloodDonationId = donationResult.id
			data.bloodCollectInfo.bloodDonationId = donationResult.id
			donationRequest.blood_donation = donationResult.id
			donationRequest.medicalRecord.blood_donation = donationResult.id
			donationRequest.donorCareInfo.blood_donation = donationResult.id
			donationRequest.bloodCollectInfo.blood_donation = donationResult.id
			await Promise.all([
				dispatch('createMedicalRecordDonation', {
					data: donationRequest.medicalRecord
				})
					.then(medicalRecordResult => {
						data.medicalRecord = new MedicalRecordDonation(medicalRecordResult)
						donationRequest.medicalRecord = medicalRecordResult
						donationRequest.labExam.object_id = medicalRecordResult.id
						donationRequest.labExam.content_type = 46

						return dispatch('createLabExam', { data: donationRequest.labExam })
					})
					.then(labExamResult => {
						data.medicalRecord.labExamId = labExamResult.id
						data.labExam = new LabExam(labExamResult)
						donationRequest.medicalRecord.lab_exam = labExamResult.id
						donationRequest.labexam = labExamResult
					}),
				dispatch('createDonorCareInfo', {
					data: donationRequest.donorCareInfo
				}).then(donorCareResult => {
					data.donorCareInfo = new DonorCareInfo(donorCareResult)
					donationRequest.donorCareInfo = donorCareResult
				}),
				dispatch('createBloodCollectInfo', {
					data: donationRequest.bloodCollectInfo
				}).then(bloodCollectResult => {
					data.bloodCollectInfo = new BloodCollectInfo(bloodCollectResult)
					donationRequest.bloodCollectInfo = bloodCollectResult
				})
			])
			data.created = new Date()
			data.modifiedDate = new Date()
			commit('ADD_DONATION', data)
			return data
		},

		async updateFullDonation({ commit, dispatch }, { id, data }) {
			let donationRequest = data.toRequestJson()
			donationRequest.labExam.content_type = 46
			let donationResult = await dispatch('updateDonation', {
				params: { id: id },
				data: donationRequest
			})
			let medicalRecordResult = await dispatch('updateMedicalRecordDonation', {
				params: { id: donationRequest.medicalRecord.id },
				data: donationRequest.medicalRecord
			})
			let labExamResult = await dispatch('updateLabExam', {
				params: { id: donationRequest.labExam.id },
				data: donationRequest.labExam
			})

			await Promise.all([
				dispatch('updateDonorCareInfo', {
					params: { id: donationRequest.donorCareInfo.id },
					data: donationRequest.donorCareInfo
				}).then(donorCareResult => {
					return dispatch('updateBloodCollectInfo', {
						params: { id: donationRequest.bloodCollectInfo.id },
						data: donationRequest.bloodCollectInfo
					})
				})
			])
			data.modifiedDate = new Date()
			commit('UPDATE_DONATION', data)
			return data
		},

		cancelDonation({ commit, dispatch }, { id, data }) {
			data.isTicketRejected = true
			data.modifiedDate = new Date()
			commit('UPDATE_DONATION', data)

			return dispatch('updateDonation', {
				params: { id: id },
				data: data.toRequestJson()
			})
		},

		// REQUEST
		async listAndFillBloodRequests({ commit, dispatch }, data) {
			let requests = await dispatch('listBloodRequests')
			////  console.log('listBloodRequests', requests)
			let bloodRequestList = []
			requests.forEach(item => {
				bloodRequestList.push(new BloodRequestInfo(item))
			})
			bloodRequestList.sort(function(a, b) {
				return new Date(b.modifiedDate) - new Date(a.modifiedDate)
			})
			let bloodRequestIdToObj = _.keyBy(bloodRequestList, o => o.id)
			let recipientProfileInfos = []
			let medicalRecordIdToInfo
			let crossmatchInfos
			let receiveIdToInfo
			await Promise.all([
				// listBloodRequestProfile
				new Promise(async (resolve, reject) => {
					let brpResult = await dispatch('listBloodRequestProfile')
					// console.log('listBloodRequestProfile', brpResult)
					brpResult.forEach(item => {
						if (bloodRequestIdToObj[item.blood_request]) {
							bloodRequestIdToObj[
								item.blood_request
							].recipientProfileInfo = new BloodRecipientProfile(item)
							recipientProfileInfos.push(
								bloodRequestIdToObj[item.blood_request].recipientProfileInfo
							)
						}
					})

					let bloodRecipientProfileIdToObj = _.keyBy(
						recipientProfileInfos,
						o => o.id
					)
					// listBloodRequestProfile -> listBloodRequestMedicalRecord
					let medicalRecordResult = await dispatch(
						'listBloodRequestMedicalRecord'
					)
				// 	console.log('listBloodRequestMedicalRecord', medicalRecordResult)
					medicalRecordResult.forEach(item => {
						if (bloodRecipientProfileIdToObj[item.blood_request_profile]) {
							bloodRecipientProfileIdToObj[
								item.blood_request_profile
							].medicalRecord = new MedicalRecordRequest(item)
						}
					})
					medicalRecordIdToInfo = _.keyBy(medicalRecordResult, o => o.id)

					await Promise.all([
						// listBloodRequestProfile -> listBloodRequestMedicalRecord -> listLabExam
						dispatch('listLabExam').then(labExamResult => {
						// 	console.log('listLabExam', labExamResult)
							labExamResult.forEach(item => {
								if (medicalRecordIdToInfo[item.object_id]) {
									medicalRecordIdToInfo[item.object_id].labExam = item.id
									bloodRecipientProfileIdToObj[
										medicalRecordIdToInfo[item.object_id].blood_request_profile
									].labExam = new LabExam(item)
								}
							})
						}),
						// listBloodRequestProfile -> listBloodRequestMedicalRecord -> listProductRequest
						dispatch('listProductRequest').then(productRequestResult => {
						// 	console.log('listProductRequest', productRequestResult)
							productRequestResult.forEach(item => {
								if (bloodRecipientProfileIdToObj[item.blood_request_profile]) {
									bloodRecipientProfileIdToObj[
										item.blood_request_profile
									].productRequest = new ProductRequest(item)
								}
							})
						})
					])
					resolve()
				}),
				// listCrossmatch
				new Promise(async (resolve, reject) => {
					let croosmatchResult = await dispatch('listCrossmatch')
				// 	console.log('listCrossmatch', croosmatchResult)
					croosmatchResult.forEach(item => {
						if (bloodRequestIdToObj[item.blood_request]) {
							if (!bloodRequestIdToObj[item.blood_request].crossmatch) {
								bloodRequestIdToObj[
									item.blood_request
								].crossmatch = new Crossmatch()
							}

							if (
								item.blood_collection_1 != null &&
								item.blood_collection_2 != null
							) {
								if (
									!_.some(
										bloodRequestIdToObj[item.blood_request].crossmatch
											.multiCrossmatchList,
										o => {
											return (
												o.bloodBagId1 === item.blood_collection_1 &&
												o.bloodBagId2 === item.blood_collection_2
											)
										}
									)
								) {
									bloodRequestIdToObj[
										item.blood_request
									].crossmatch.addMultiCrossmatchToList(item)
								}
							} else {
								if (
									!_.some(
										bloodRequestIdToObj[item.blood_request].crossmatch
											.singleCrossmatchList,
										o => {
											return o.bloodBagId1 === item.blood_collection_1
										}
									)
								) {
									bloodRequestIdToObj[
										item.blood_request
									].crossmatch.addSingleCrossmatchToList(item)
								}
							}
						}
					})
					crossmatchInfos = _.keyBy(croosmatchResult, o => {
						return o.id
					})
					resolve()
				}),
				// listReserveBloodBag
				new Promise(async (resolve, reject) => {
					let reserveBloodBag = await dispatch('listReserveBloodBag')
				// 	console.log('listReserveBloodBag', reserveBloodBag)
					reserveBloodBag.forEach(item => {
						if (bloodRequestIdToObj[item.blood_request]) {
							bloodRequestIdToObj[
								item.blood_request
							].reserveBlood = new ReserveBlood(item)
						}
					})
					let bloodReserveIdToInfo = _.keyBy(reserveBloodBag, o => o.id)
					
					// listReserveBloodBag -> listReserveBloodBagItem
					let reserveBloodBagItem = await dispatch('listReserveBloodBagItem')
					
					reserveBloodBagItem.forEach(item => {
						const reserveBlood = bloodReserveIdToInfo[item.reserve_blood];
						if (reserveBlood) {
							item.crossmatchInfo = new CrossmatchItemInfo(
								crossmatchInfos[item.crossmatch]
							)
							bloodRequestIdToObj[reserveBlood.blood_request].reserveBlood.addItem(item)
						}
					})
				// 	console.log('listReserveBloodBagItem', reserveBloodBagItem)
					resolve()
				}),
				// listRecieveBloodBag
				new Promise(async (resolve, reject) => {
					let recieveBloodBagItem = await dispatch('listRecieveBloodBag')
				// 	console.log('listRecieveBloodBag', recieveBloodBagItem)
					recieveBloodBagItem.forEach(item => {
						if (bloodRequestIdToObj[item.blood_request]) {
							bloodRequestIdToObj[
								item.blood_request
							].receiveBlood = new ReceiveBlood(item)
						}
					})
					receiveIdToInfo = _.keyBy(recieveBloodBagItem, o => {
						return o.id
					})
					let feedBackResult = await dispatch('listFeedback')
				// 	console.log('listFeedback', feedBackResult)
					feedBackResult.forEach(item => {
						if (
							bloodRequestIdToObj[
								receiveIdToInfo[item.receive_blood_bag].blood_request
							]
						) {
							bloodRequestIdToObj[
								receiveIdToInfo[item.receive_blood_bag].blood_request
							].feedback = new Feedback(item)
						}
					})
					resolve()
				}),
				// listPet
				dispatch('listPet').then(result => {
				// 	console.log('listPet', result)
					let petIdToInfoDictionary = _.keyBy(result.results, o => o.pet.id)
					bloodRequestList.forEach(item => {
						if (petIdToInfoDictionary[item.petId]) {
							let pet = petIdToInfoDictionary[item.petId]
							item.petInfo = new PetInfo()
							item.petInfo.setPropsFromPetObject(pet)
						}
					})
				})
			])

			commit('SET_REQUESTS', bloodRequestList)
		},

		async createFullRequest({ commit, dispatch }, data) {
			// BLOOD_REQUEST
			// 	BLOOD_REQUEST_PROFILE
			// 		BLOOD_REQUEST_MEDICAL_RECORD
			// 			LAB_EXAM
			// 		PRODUCT_REQUEST
			// 	CROSSMATCH
			// 	RESERVE_BLOOD_BAG
			// 	RECEIVE_BLOOD_BAG
			// 		FEEDBACK
			data.isRecipientProfileSubmitted = true
			data.recipientProfileInfo.isRecipientProfileSubmitted = true
			data.recipientProfileInfo.isLabExamSubmitted = true
			data.recipientProfileInfo.isProductRequestSubmitted = true
			let requestRequest = data.toRequestJson()
			let requestResult = await dispatch('createRequest', {
				data: requestRequest
			})
		// 	console.log('createFullRequest', requestResult)

			data.id = requestResult.id
			data.recipientProfileInfo.bloodRequestId = requestResult.id
			data.crossmatch.bloodRequestId = requestResult.id
			data.reserveBlood.bloodRequestId = requestResult.id
			data.receiveBlood.bloodRequestId = requestResult.id
			data.feedback.bloodRequestId = requestResult.id

			requestRequest.recipientProfileInfo.blood_request = requestResult.id
			requestRequest.blood_request = requestResult.id
			requestRequest.crossmatch.blood_request = requestResult.id
			requestRequest.reserveBlood.blood_request = requestResult.id
			requestRequest.receiveBlood.blood_request = requestResult.id
			requestRequest.feedback.blood_request = requestResult.id

			await Promise.all([
				// BloodRequestProfile
				new Promise(async (resolve, reject) => {
					let brpResult = await dispatch('createRequestProfile', {
						data: requestRequest.recipientProfileInfo
					})
				// 	console.log('createRequestProfile', brpResult)
					data.recipientProfileInfo.id = brpResult.id
					data.recipientProfileInfo.medicalRecord.bloodRequestId = brpResult.id
					requestRequest.recipientProfileInfo.id = brpResult.id
					requestRequest.recipientProfileInfo.medicalRecord.blood_request_profile =
						brpResult.id
					requestRequest.recipientProfileInfo.productRequest.blood_request_profile =
						brpResult.id

					await Promise.all([
						new Promise(async (resolve, reject) => {
							// Medical Record
							let mrResult = await dispatch('createRequestMedicalRecord', {
								data: requestRequest.recipientProfileInfo.medicalRecord
							})
						// 	console.log('Medical Record', mrResult)
							data.recipientProfileInfo.medicalRecord = new MedicalRecordRequest(
								mrResult
							)
							requestRequest.recipientProfileInfo.medicalRecord = mrResult
							requestRequest.recipientProfileInfo.labExam.object_id =
								mrResult.id
							requestRequest.recipientProfileInfo.labExam.content_type = 37
							// LabExam
							let leResult = await dispatch('createLabExam', {
								data: requestRequest.recipientProfileInfo.labExam
							})
						// 	console.log('LabExam', leResult)
							data.recipientProfileInfo.medicalRecord.labExamId = leResult.id
							data.recipientProfileInfo.labExam.id = leResult.id
							data.recipientProfileInfo.labExam = new LabExam(leResult)
							requestRequest.recipientProfileInfo.labExam = leResult
							resolve()
						}),
						new Promise(async (resolve, reject) => {
							// Product Request
							let prResult = await dispatch('createProductRequest', {
								data: requestRequest.recipientProfileInfo.productRequest
							})
						// 	console.log('Product Request', prResult)
							data.recipientProfileInfo.productRequest = new ProductRequest(
								prResult
							)
							requestRequest.recipientProfileInfo.productRequest = prResult
							resolve()
						})
					])
					resolve()
				}),
				// Crossmatch
				new Promise(async (resolve, reject) => {
					data.crossmatch = new Crossmatch()
					let crossMatchPromises = []
				// 	console.log('cm array', requestRequest)
					requestRequest.crossmatch.forEach(item => {
						crossMatchPromises.push(
							dispatch('createCrossmatch', {
								data: item
							}).then(result => {
							// 	console.log('Crossmatch Create', result)
								if (!item.bloodBagId2) {
									data.crossmatch.addSingleCrossmatchToList(result)
								} else {
									data.crossmatch.addMultiCrossmatchToList(result)
								}
							})
						)
					})
					if (crossMatchPromises && crossMatchPromises.length > 0)
						await Promise.all(crossMatchPromises)
				// 	console.log('Crossmatch Create Done', crossMatchPromises.length)
					resolve()
				}),
				// Reserve
				new Promise(async (resolve, reject) => {
					let reserveResult = await dispatch('createReserveBloodBag', {
						data: requestRequest.reserveBlood
					})
				// 	console.log('Reserve Create', reserveResult)
					data.reserveBlood = new ReserveBlood(reserveResult)
					requestRequest.reserveBlood = reserveResult
					resolve()
				}),
				// Has Receive
				new Promise(async (resolve, reject) => {
					let receiveResult = await dispatch('createReceiveBloodBag', {
						data: requestRequest.reserveBlood
					})
				// 	console.log('Receive Create', receiveResult)
					data.receiveBlood = new ReceiveBlood(receiveResult)
					requestRequest.reserveBlood = receiveResult
					requestRequest.feedback.receive_blood_bag = receiveResult.id

					let feedbackResult = await dispatch('createFeedback', {
						data: requestRequest.feedback
					})
				// 	console.log('Feedback Create', feedbackResult)
					data.feedback = new Feedback(feedbackResult)
					requestRequest.reserveBlood = feedbackResult
					resolve()
				})
			])
			data.modifiedDate = new Date()
			commit('ADD_REQUEST', data)
		},

		async updateFullRequest({ commit, dispatch }, { id, data }) {
			// BLOOD_REQUEST_ITEM
			// 	BLOOD_REQUEST_PROFILE_ID
			// 		BLOOD_REQUEST_MEDICAL_RECORD_ITEM
			// 			LAB_EXAM_ITEM
			// 		PRODUCT_REQUEST_ITEM
			// 	CROSSMATCH_ITEM
			// 	(else) CROSSMATCH
			// 	RESERVE_BLOOD_BAG
			// 		RESERVE_BLOOD_BAG_ITEM_ID
			// 	RECEIVE_BLOOD_BAG_ITEM
			// 	FEEDBACK_ITEM
			let requestRequest = data.toRequestJson()
			requestRequest.recipientProfileInfo.labExam.content_type = 37
			let requestResult = await dispatch('updateRequest', {
				params: { id: requestRequest.id },
				data: requestRequest
			})
		// 	console.log('updateFullRequest', requestResult)

			data.id = requestResult.id
			data.recipientProfileInfo.bloodRequestId = requestResult.id
			data.crossmatch.bloodRequestId = requestResult.id
			data.reserveBlood.bloodRequestId = requestResult.id
			data.receiveBlood.bloodRequestId = requestResult.id
			data.feedback.bloodRequestId = requestResult.id

			requestRequest.recipientProfileInfo.blood_request = requestResult.id
			requestRequest.blood_request = requestResult.id
			requestRequest.crossmatch.blood_request = requestResult.id
			requestRequest.reserveBlood.blood_request = requestResult.id
			requestRequest.receiveBlood.blood_request = requestResult.id
			requestRequest.feedback.blood_request = requestResult.id

			await Promise.all([
				// BloodRequestProfile
				new Promise(async (resolve, reject) => {
					let brpResult = await dispatch('updateRequestProfile', {
						params: { id: requestRequest.recipientProfileInfo.id },
						data: requestRequest.recipientProfileInfo
					})
				// 	console.log('updateRequestProfile', brpResult)

					await Promise.all([
						new Promise(async (resolve, reject) => {
							// Medical Record
							let mrResult = await dispatch('updateRequestMedicalRecord', {
								params: {
									id: requestRequest.recipientProfileInfo.medicalRecord.id
								},
								data: requestRequest.recipientProfileInfo.medicalRecord
							})
						// 	console.log('Medical Record', mrResult)
							// LabExam
							let leResult = await dispatch('updateLabExam', {
								params: { id: requestRequest.recipientProfileInfo.labExam.id },
								data: requestRequest.recipientProfileInfo.labExam
							})
						// 	console.log('LabExam', leResult)
							resolve()
						}),
						new Promise(async (resolve, reject) => {
							// Product Request
							let prResult = await dispatch('updateProductRequest', {
								params: {
									id: requestRequest.recipientProfileInfo.productRequest.id
								},
								data: requestRequest.recipientProfileInfo.productRequest
							})
						// 	console.log('Product Request', prResult)
							requestRequest.recipientProfileInfo.productRequest = prResult
							resolve()
						})
					])
					resolve()
				}),
				// Crossmatch
				new Promise(async (resolve, reject) => {
					let allCrossmatch = data.crossmatch.getAllCrossmatchInfo()
					let crossMatchPromises = []
				// 	console.log('cm array', requestRequest)
					requestRequest.crossmatch.forEach(item => {
						if (item.id) {
							crossMatchPromises.push(
								dispatch('updateCrossmatch', {
									params: { id: item.id },
									data: item
								}).then(result =>  console.log('Crossmatch Update', result))
							)
						} else {
							crossMatchPromises.push(
								dispatch('createCrossmatch', {
									data: item
								}).then(result => {
								// 	console.log('Crossmatch Create', result)
									_.forEach(allCrossmatch, crossmatch => {
										if (
											crossmatch.bloodBagId1 === item.blood_collection_1 &&
											crossmatch.bloodBagId2 === item.blood_collection_2
										) {
											crossmatch.id = result.id
										}
									})
								})
							)
						}
					})

					data.crossmatch.removeList.map(removeItem => {
						crossMatchPromises.push(
							dispatch('deleteCrossmatch', {
								params: { id: removeItem.id }
							})
						)
					})

					if (crossMatchPromises && crossMatchPromises.length > 0)
						await Promise.all(crossMatchPromises)
				// 	console.log('Crossmatch Update Done', crossMatchPromises.length)
					resolve()
				}),
				// Reserve
				new Promise(async (resolve, reject) => {
					let reserveResult = await dispatch('updateReserveBloodBag', {
						params: { id: requestRequest.reserveBlood.id },
						data: requestRequest.reserveBlood
					})
				// 	console.log('Reserve Create', reserveResult)
					// Reserve Item
					let bloodBagReservePromises = []
					requestRequest.reserveBlood.bloodBagList.forEach(item => {
						item.reserve_blood = reserveResult.id
						const checkCrossmatchRemoved = data.crossmatch.removeList.some(
							elem => elem.id === item.crossmatch
						)

						if (!checkCrossmatchRemoved) {
							if (item.id) {
								bloodBagReservePromises.push(
									dispatch('updateReserveBloodBagItem', {
										params: { id: item.id },
										data: item
									}).then(response =>
										console.log('Reserve BloodBag Item Update', response)
									)
								)
							} else {
								bloodBagReservePromises.push(
									dispatch('createReserveBloodBagItem', {
										data: item
									}).then(response =>
										console.log('Reserve BloodBag Item Create', response)
									)
								)
							}
						} else {
							bloodBagReservePromises.push(
								dispatch('deleteReserveBloodBagItem', {
									params: { id :item.id }
								}).then(response =>
									console.log('Reserve BloodBag Item Deleted', response)
								)
							)
						}
					})

					if (bloodBagReservePromises && bloodBagReservePromises.length > 0)
						await Promise.all(bloodBagReservePromises)
					resolve()
				}),
				// Has Receive
				new Promise(async (resolve, reject) => {
					let receiveResult = await dispatch('updateRecieveBloodBagItem', {
						params: { id: requestRequest.receiveBlood.id },
						data: requestRequest.receiveBlood
					})
				// 	console.log('Receive Update', receiveResult)
					resolve()
				}),
				// Feedback
				new Promise(async (resolve, reject) => {
					let receiveResult = await dispatch('updateFeedback', {
						params: { id: requestRequest.feedback.id },
						data: requestRequest.feedback
					})
				// 	console.log('Feedback Update', receiveResult)
					resolve()
				})
			])
			data.modifiedDate = new Date()
			commit('UPDATE_REQUEST', data)
		},
		cancelRequest({ commit, dispatch }, { id, data }) {
			data.isTicketRejected = true
			data.modifiedDate = new Date()
			commit('UPDATE_REQUEST', data)

			return dispatch('updateRequest', {
				params: { id: id },
				data: data.toRequestJson()
			})
		},

		validateBloodBagQuantity({ commit, dispatch }, bloodBagQuantityList) {
			return dispatch('listBloodCollectInfo').then(result => {
				let invalidQuantity = []
				bloodBagQuantityList.map(item => {
					const existingBag = result.find(bag => {
						return bag.id === item.id
					})

					if (existingBag) {
						const isRemainingQuantityValid =
							Number(existingBag.collected_quantity) - Number(item.quantity) >=	0
						if (!isRemainingQuantityValid) {
							invalidQuantity.push({
								id: existingBag.id,
								remaining: Number(existingBag.collected_quantity),
								request: Number(item.quantity)
							})
						}
					}
				})

				if (invalidQuantity.length === 0) {
					// resolve()
				} else {
					throw invalidQuantity
				}
			})
		},

		// BLOOD BAGS
		async listAndFillBloodCollectInfo({ state, commit, dispatch }) {
			let bloodBags = await dispatch('listBloodCollectInfo')
			bloodBags = bloodBags.map(item => new BloodBag(item))
			bloodBags.sort(function(a, b) {
				return new Date(a.dateCollection) - new Date(b.dateCollection)
			})

			// Blood Donate Id List
			let bloodRequestList = state.requests
			let bloodDonationList = state.donations

			let bloodDonationIdToBloodDonation = _.keyBy(bloodDonationList, o => {
				return o.bloodDonationId
			})

			//Check reserve status
			// any reserve with Reserved and !Recieved
			let reservedBloodBagsId = []
			bloodRequestList.forEach(bloodRequest => {
				if (
					bloodRequest.isWithdrawBloodBagReceived &&
					!bloodRequest.isBloodBagReceived
				) {
					bloodRequest.reserveBlood.bloodBagList.forEach(reservedBloodBag => {
						if (reservedBloodBag.isSelected) {
							reservedBloodBagsId.push(
								reservedBloodBag.crossmatchInfo.bloodBagId1
							)
							if (reservedBloodBag.crossmatchInfo.bloodBagId2) {
								reservedBloodBagsId.push(
									reservedBloodBag.crossmatchInfo.bloodBagId2
								)
							}
						}
					})
				}
			})

			// Pet List from Blood Donate
			bloodBags.forEach(bloodBag => {
				// Blood Donate Id for Split/Transform Bag
				if (bloodBag.transformedFromBag) {
					bloodBag.donationId = utils.getParentBloodBagDonationId(
						bloodBags,
						bloodBag.transformedFromBag
					)
				}

				// Pet List from Blood Donate
				if (bloodDonationIdToBloodDonation[bloodBag.donationId]) {
					bloodBag.petInfo =
						bloodDonationIdToBloodDonation[bloodBag.donationId].pet
				}

				// TODO: recheck this
				if (bloodBag.transformedFromBag) {
					if (bloodDonationIdToBloodDonation[bloodBag.transformedFromBag]) {
						bloodBag.petInfo =
							bloodDonationIdToBloodDonation[bloodBag.transformedFromBag].pet
					}
				}

				//Check is bag reserved
				if (reservedBloodBagsId.indexOf(bloodBag.id) >= 0) {
					bloodBag.status = bagStatus.RESERVE
				}
			})

			commit('SET_BLOOD_BAGS', bloodBags)
		},
		async updateFullBloodCollectInfo({state, commit, dispatch}, {id, data, isDataReady}) {
			if (data.reasonOfRemoval != null) {
				if (data.reasonOfRemoval === 'UNKNOWN') {
					data.reasonOfRemoval = 0
				} else if (data.reasonOfRemoval === 'EXPIRE') {
					data.reasonOfRemoval = 1
				} else {
					data.reasonOfRemoval = 2
				}
			}

			// Transformed, splitted case
			if (data.transformedFromBag) {
				var validTransformFromBag = _.some(state.bloodBags, (i) => {
					return i.id === data.transformedFromBag
				})

				if (!validTransformFromBag) {
					data.transformedFromBag = null
				}

				// Remove donationId
				data.donationId = null
			}

			// BloodCollectInfo (Blood collect Id)
			return dispatch('updateBloodCollectInfo', {
				params: {id},
				data: isDataReady ? data : data.toRequestJson()
			})
		},
	}
}

export default vuexHelper.createStoreModule(config)
