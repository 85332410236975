<template>
  <el-card class="box-card">
    <el-row>
      <el-col :span="5">
        <span class="title">
          [ID: {{donation.bloodDonationId}}]
        </span><br>
        <span>
          {{formattedTime}}
        </span><br>
        <el-button class='statusTag' size="mini" type="primary"
          :style="`background-color: ${statusColor}; border-color: ${statusColor}`">
          {{statusText}}
        </el-button>
      </el-col>
      <el-col :span="5">
        <span class="header">Donor</span>
        <div>
          <span class="title">{{donation.pet.name}}</span><br>
          <span>{{donation.pet.type}} Weight:{{formattedWeight}}</span>
        </div>
      </el-col>
      <el-col :span="5">
        <span class="header">Owner</span>
        <div>
          <span class="title">{{donation.pet.owners && donation.pet.owners.length > 0 ? donation.pet.owners[0].firstname : '-'}}</span><br>
          <span>{{donation.pet.owners && donation.pet.owners.length > 0 ? 'Tel :' + donation.pet.owners[0].phone : 'Tel : -'}}</span>
        </div>
      </el-col>
      <el-col :span="3">
        <span class="header">PCV</span>
        <div>
          <span class="title">{{donation.labExam.HCT === '' ? `-` : Number(donation.labExam.HCT)}}</span>
        </div>
      </el-col>
      <el-col :span="4">
        <el-button size="medium"
          @click="$router.push({ name: 'donationTimeline', params: { id: donation.bloodDonationId }})"
        >
          ดูรายละเอียด
          <i class="el-icon-arrow-right el-icon-right"></i>
        </el-button>
      </el-col>
      <el-col :span="2">
        <el-button
          v-on:click="toggle"
          :icon="isCollapseFlag?'el-icon-arrow-up':'el-icon-arrow-down'" circle>
        </el-button>
      </el-col>
    </el-row>
    <br>
    <el-row>
      <el-collapse-transition>
        <div v-show="isCollapseFlag">
          <el-steps :active="donation.getCurrentProgress()" finish-status="success">
            <el-step v-for='p in donation.getProgressList()' :key='p.title' :title="p.title"></el-step>
          </el-steps>
        </div>
      </el-collapse-transition>
    </el-row>
  </el-card>
    
</template>


<script>
import moment from 'moment'
import _ from "lodash"
import ContentBox from "@/components/container/ContentBox"

export default {
  components: {
    ContentBox
  },
  props: ["donation"],
  data() {
    return {
      isCollapseFlag: false
    }
  },
  computed: {
    formattedTime() {
      return moment(this.donation.created).format('DD MMMM YYYY')
    },
    formattedWeight() {
      return _.ceil(this.donation.medicalRecord.weight, 1)
    },
    statusText() {
      if (this.donation.donorCareInfo.canDonate === false) {
        return 'Failed Case'
      } else if (this.donation.isTicketRejected) {
        return 'Cancelled'
      } else if (this.donation.isBloodCollectSubmitted) {
        return 'Done'
      } else if (this.donation.isDonorCareSubmitted) {
        return 'Donor care'
      } else if (this.donation.isLabExamSubmitted) {
        return 'Lab'
      } else if (this.donation.isPhysicalExamSubmitted) {
        return 'Donor Submit'
      } else if (this.donation.isDonorProfileSubmitted) {
        return 'Donor Submit'
      }
    },
    statusColor() {
      if (this.donation.donorCareInfo.canDonate === false) {
        return '#7f8fa4'
      } else if (this.donation.isTicketRejected) {
        return '#dd0000'
      } else if (this.donation.isBloodCollectSubmitted) {
        return '#324a5e'
      } else if (this.donation.isDonorCareSubmitted) {
        return '#dba743'
      } else if (this.donation.isLabExamSubmitted) {
        return '#61ab60'
      } else if (this.donation.isPhysicalExamSubmitted) {
        return '#41abbe'
      } else if (this.donation.isDonorProfileSubmitted) {
        return '#6571b9'
      }
    }
  },
  methods: {
    toggle: function () {
      this.isCollapseFlag = !this.isCollapseFlag
    }
  },
  mounted() {

    // this.stepperList = [
    //   {
    //     type: 'common',
    //     title: 'Donor',
    //     detail: this.donation.pet.name,
    //     detailedInformation: `${this.donation.pet.type} Weight:${_.ceil(this.donation.medicalRecord.weight, 1)}`
    //   },
    //   {
    //     type: 'common',
    //     title: 'Owner',
    //     detail: this.donation.pet.owners != null && this.donation.pet.owners.length > 0 ? this.donation.pet.owners[0].firstname : '-',
    //     detailedInformation: this.donation.pet.owners != null && this.donation.pet.owners.length > 0 ? 'Tel :' + this.donation.pet.owners[0].phone : 'Tel : -'
    //   },
    //   {
    //     type: 'common',
    //     title: 'PCV',
    //     detail: '',
    //     detailedInformation: this.donation.labExam.HCT === '' ? `-` : Number(this.donation.labExam.HCT)
    //   }
    // ]

    // stepperProgressBarList:  [
    //           {
    //             title: 'Donor Submit',
    //             state: item.isDonorProfileSubmitted ? 'finish' : 'process',
    //             status: item.isDonorProfileSubmitted ? 'finish' : 'process'
    //           },
    //           {
    //             title: 'Physical Exam',
    //             state: item.isPhysicalExamSubmitted ? 'finish' : 'process',
    //             status: item.isPhysicalExamSubmitted ? 'finish' : 'process'
    //           },
    //           {
    //             title: 'Lab',
    //             state: item.isLabExamSubmitted ? 'finish' : 'process',
    //             status: item.isLabExamSubmitted ? 'finish' : 'process'
    //           },
    //           {
    //             title: 'Donor Care',
    //             state: item.isDonorCareSubmitted ? 'finish' : 'process',
    //             status: item.isDonorCareSubmitted ? 'finish' : 'process'
    //           },
    //           {
    //             title: 'Blood Collect',
    //             state: item.isBloodCollectSubmitted ? 'finish' : 'process',
    //             status: item.isBloodCollectSubmitted ? 'finish' : 'process'
    //           }
    //         ]
  }
}
</script>

<style lang="scss" scoped>
.statusTag {
  width: 80%;
}

.title {
  font-weight: bold;
}

.header {
  font-weight: 100;
  font-style: italic;
  border-bottom: 1px solid #ccc;
}

.el-col {
  text-align: center;
  padding: 0px 10px;
  border-left: 1px solid #ccc;
  &:first-child {
    border-left: none;
  }
  height: 75px;
  span {
    vertical-align: middle;
  }
}
  

</style>
