<template>
  <el-dialog :visible="visible" width="600px" center @close="$emit('cancel')">
    <div class="header">
      <h1 class="title">Add Reward</h1>
    </div>
    <div class="content">
      <div class="form-row">
        <div><label>Reward Name*</label></div>
        <el-input size="small"  v-model="form.name" type="textarea" :autosize="{ minRows: 2 }"/>
      </div>
      <div class="form-row">
        <div><label>Reward point</label></div>
        <el-input-number size="small" v-model="form.use_point" />
      </div>
      <div class="form-row">
        <div><label>Detail</label></div>
        <el-input size="small"  v-model="form.detail" type="textarea" rows="5" :autosize="{ minRows: 3 }"/>
      </div>
    </div>
    <span class="footer">
      <el-button @click="$emit('cancel')">Cancel</el-button>
      <el-button type="primary" @click="handelSave">Save</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: ["visible"],
  data() {
    return {
      form: {
        name: '',
        use_point: 0,
        detail: ''
      }
    }
  },
  methods: {
    handelSave() {
      if (this.form.name !== '' && !isNaN(this.form.use_point)) {
        this.$emit("save", this.form)
      } else {
        this.$notify({
          message: `กรุณากรอกข้อมูลให้ครบ`,
          type: "warning"
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  margin-top: -40px;
  margin-bottom: 20px;
  text-align: center;

  .title {
    font-size: 18px;
    font-weight: bold;
  }
}
.content {
  font-size: 16px;
  text-align: center;
}
.footer {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.form-row {
  margin-top: 10px;
  text-align: left;

  label {
      display: block;
     text-align: left;
     margin: 5px 0;
  }
  
  textarea {
    width: 100%;
  }
}
</style>

