import Vue from 'vue'
import Router from 'vue-router'

Router.prototype.open = function (routeObject) {
  const {href} = this.resolve(routeObject)
  window.open(href, '_blank')
}

// Layout
import HospitalRegisterLayout from '@/layouts/HospitalRegisterLayout.vue'
import PetOwnerLayout from '@/layouts/PetOwnerLayout.vue'
import MainHospitalLayout from '@/layouts/MainHospitalLayout.vue'

// Pages
import LoginPage from '@/pages/auth/LoginPage.vue'
// import HomePage from '@/pages/home/HomePage.vue'
import LandingPage from '@/pages/home/LandingPage.vue'
import HospitalHome from '@/pages/hospital/HomePage.vue'
import ApptDetailPublicPage from '@/pages/appointment/ApptDetailPublicPage.vue'
import ApptDetailPDF from "@/pages/appointment/ApptDetailPDF"
import PlanAndCreditTopUp from '@/pages/plan/PlanAndCreditTopUp'
import PetProfilePublicPage from '@/pages/pet/PetProfilePublicPage.vue'
import MedicalDetailPDF from '@/pages/medical/MedicalDetailPDF'
import BroadcastPublicPage from '@/pages/broadcast/BroadcastPublicPage.vue'

const MedicalDetailPdf = () => import('@/pages/medical/MedicalDetailPdfV2')
const MedicalDetailPdfCompact = () => import('@/pages/medical/MedicalDetailPdfCompact')
const MedicalPrint = () => import('@/pages/medical/MedicalPrint')
const MedicalPrintTaxless = () => import('@/pages/medical/MedicalPrintTaxless')
const MedicalPrintSlip = () => import('@/pages/medical/MedicalPrintSlip')
const MedicalPrintSlipTaxless = () => import('@/pages/medical/MedicalPrintSlipTaxless')
const MedicalExpensePrint = () => import('@/pages/medical/MedicalExpensePrint')
const MedicalExpensePrintSlip = () => import('@/pages/medical/MedicalExpensePrintSlip')
const MedicalExpenseGroupPrint = () => import('@/pages/medical/MedicalExpenseGroupPrint')
const MedicalExpenseGroupPrintSlip = () => import('@/pages/medical/MedicalExpenseGroupPrintSlip')

const IpdDepositPrint = () => import('@/pages/ipd/IpdDepositPrint')
const IpdDepositPrintSlip = () => import('@/pages/ipd/IpdDepositPrintSlip')

const IpdOverduePrint = () => import('@/pages/ipd/IpdOverduePrint')
const IpdOverduePrintSlip = () => import('@/pages/ipd/IpdOverduePrintSlip')
const IpdOverdueGroupPrint = () => import('@/pages/ipd/IpdOverdueGroupPrint')
const IpdOverdueGroupPrintSlip = () => import('@/pages/ipd/IpdOverdueGroupPrintSlip')

const IpdPaidPrint = () => import('@/pages/ipd/IpdPaidPrint')
const IpdPaidPrintTaxless = () => import('@/pages/ipd/IpdPaidPrintTaxless')
const IpdPaidPrintSlip = () => import('@/pages/ipd/IpdPaidPrintSlip')
const IpdPaidPrintSlipTaxless = () => import('@/pages/ipd/IpdPaidPrintSlipTaxless')

const PosPrint = () => import('@/pages/pos/PosPrint')
const PosPrintTaxless = () => import('@/pages/pos/PosPrintTaxless')
const PosPrintSlip = () => import('@/pages/pos/PosPrintSlip')
const PosPrintSlipTaxless = () => import('@/pages/pos/PosPrintSlipTaxless')

const AppointmentPrintSlip = () => import('@/pages/appointment/AppointmentPrintSlip')

const IncomeExpenseReceiptPrint = () => import('@/pages/incomeExpense/ReceiptPrint')
const IncomeExpenseReceiptPrintTaxless = () => import('@/pages/incomeExpense/ReceiptPrintTaxless')
const IncomeExpenseReceiptPrintSlip = () => import('@/pages/incomeExpense/ReceiptPrintSlip')
const IncomeExpenseReceiptPrintSlipTaxless = () => import('@/pages/incomeExpense/ReceiptPrintSlipTaxless')
const IncomeExpenseReceiptGroupPrint = () => import('@/pages/incomeExpense/ReceiptGroupPrint')
const IncomeExpenseReceiptGroupPrintTaxless = () => import('@/pages/incomeExpense/ReceiptGroupPrintTaxless')
const IncomeExpenseReceiptGroupPrintSlip = () => import('@/pages/incomeExpense/ReceiptGroupPrintSlip')
const IncomeExpenseReceiptGroupPrintSlipTaxless = () => import('@/pages/incomeExpense/ReceiptGroupPrintSlipTaxless')
const IncomeExpenseSummaryPrint = () => import('@/pages/incomeExpense/SummaryDetailPrint')
const ShiftHistoryDetailPrint = () => import('@/pages/incomeExpense/ShiftHistoryDetailPrint')

const CheckStockPrint = () => import('@/pages/inventory/CheckStockPrint')
const InventoryTransactionPrint = () => import('@/pages/inventory/InventoryTransactionPrint')
const AddRemoveInventoryPrint = () => import('@/pages/inventory/AddRemoveInventoryPrint')
const AddIncomeExpensePrint = () => import('@/pages/incomeExpense/AddIncomeExpensePrint')

// Routes
import hospitalRegister from './hospitalRegisterRoutes'
import appointmentRoutes from './appointmentRoutes'
import medicalRoutes from './medicalRoutes'
import inventoryRoutes from './inventoryRoutes'
import petOwnerRoutes from './petOwnerRoutes'
import activityRoutes from './activityRoutes'
import veterinarianRoutes from './veterinarianRoutes'
import staffRoutes from './staffRoutes'
import hospitalRoutes from './hospitalRoutes'
import bloodBankRoutes from './bloodBankRoutes'
import calendarRoutes from './calendarRoutes'
import rewardRoutes from './rewardRoutes'
import redeemRoutes from './redeemRoutes'
import productRoutes from './productRoutes'
import receiptRoutes from './receiptRoutes'
import incomeExpenseRoutes from './incomeExpenseRoutes'
import reportRoutes from './reportRoutes'
import ipdRoutes from './ipdRoutes'
import posRoutes from './posRoutes'
import contactRoutes from './contactRoutes'

Vue.use(Router)

const router = new Router({
  mode: "history",
  linkActiveClass: "active",
  routes: [
    {
      path: "/",
      name: "home",
      // component: HomePage,
      component: LandingPage,
      meta: {
        name: "Home",
        requiresAuth: false
      },
      beforeEnter: (to, from, next) => {
        const user_type = localStorage.getItem("user_type");
        const token = localStorage.getItem("token");
        if (token != null && user_type != null) {
          if (user_type == "pet_owner") next({ name: "petOwnerHome" });
          else if (user_type == "hospital_admin") next({ name: "hospitalHome" });
        } else {
          next();
        }
      }
    },
    {
      path: "/login",
      name: "login",
      component: LoginPage,
      meta: {
        name: "Login",
        requiresAuth: false
      },
      beforeEnter: (to, from, next) => {
        const user_type = localStorage.getItem("user_type");
        const token = localStorage.getItem("token");
        if (token != null) {
          if (user_type == "hospital_admin") {
            next({
              name: "hospitalHome"
            });
          } else if (user_type == "pet_owner") {
            next({
              name: "petOwnerHome"
            });
          }
        } else {
          next();
        }
      }
    },
    {
      path: "/hospital/register",
      component: HospitalRegisterLayout,
      children: [
        ...hospitalRegister
      ],
      beforeEnter: (to, from, next) => {
        const user_type = localStorage.getItem("user_type");
        const token = localStorage.getItem("token");
        if (token != null) {
          if (user_type == "hospital_admin") {
            next({
              name: "hospitalHome"
            });
          } else if (user_type == "pet_owner") {
            next({
              name: "petOwnerHome"
            });
          }
        } else {
          next();
        }
      }
    },
    {
      path: '/appointment/:id/pdf',
      name: 'appointment-detail-pdf',
      component: ApptDetailPDF
    },
    {
      path: '/appointment/:id/print/slip',
      name: 'appointment-print-slip',
      component: AppointmentPrintSlip
    },
    {
      path: "/hospital",
      component: MainHospitalLayout,
      children: [
        {
          path: '/',
          name: 'hospitalHome',
          component: HospitalHome,
          meta: {
            name: "Home",
            requiresAuth: true,
            permissions: ['hospital_admin']
          }
        },
        // {
        //   path: '/plan-and-price',
        //   name: 'plan-and-price',
        //   component: PlanAndCreditTopUp,
        //   meta: {
        //     requiresAuth: false
        //   }
        // },
        ...appointmentRoutes,
        ...medicalRoutes,
        ...inventoryRoutes,
        ...veterinarianRoutes,
        ...staffRoutes,
        ...hospitalRoutes,
        ...activityRoutes,
        ...calendarRoutes,
        ...bloodBankRoutes,
        ...rewardRoutes,
        ...redeemRoutes,
        ...productRoutes,
        ...receiptRoutes,
        ...incomeExpenseRoutes,
        ...reportRoutes,
        ...ipdRoutes,
        ...posRoutes,
        ...contactRoutes,
      ]
    },
    {
      path: '/medicals/:id/pdf/v2',
      name: 'medicals-detail-pdf',
      component: MedicalDetailPdf
    },
    {
      path: '/medicals/:id/pdf/compact',
      name: 'medicals-detail-pdf-compact',
      component: MedicalDetailPdfCompact
    },
    {
      path: '/medicals/:id/pdf',
      name: 'medicals-detail-pdf-v1',
      component: MedicalDetailPDF
    },
    {
      path: '/medicals/:id/print/a4',
      name: 'medicals-print-a4',
      component: MedicalPrint,
      meta: {
        size: 'a4',
        requiresAuth: true,
        permissions: ['hospital_admin'],
        plan_permissions: ['print_section.can_use_have_tax_print']
      }
    },
    {
      path: '/medicals/:id/print/a4/taxless',
      name: 'medicals-print-a4-taxless',
      component: MedicalPrintTaxless,
      meta: {
        size: 'a4',
        requiresAuth: true,
        permissions: ['hospital_admin'],
        plan_permissions: ['print_section.can_use_no_tax_print']
      }
    },
    {
      path: '/medicals/:id/print/a5',
      name: 'medicals-print-a5',
      component: MedicalPrint,
      meta: {
        size: 'a5',
        requiresAuth: true,
        permissions: ['hospital_admin'],
        plan_permissions: ['print_section.can_use_have_tax_print']
      }
    },
    {
      path: '/medicals/:id/print/a5/taxless',
      name: 'medicals-print-a5-taxless',
      component: MedicalPrintTaxless,
      meta: {
        size: 'a5',
        requiresAuth: true,
        permissions: ['hospital_admin'],
        plan_permissions: ['print_section.can_use_no_tax_print']
      }
    },
    {
      path: '/medicals/:id/print/slip',
      name: 'medicals-print-slip',
      component: MedicalPrintSlip,
      meta: {
        requiresAuth: true,
        permissions: ['hospital_admin'],
        plan_permissions: ['print_section.can_use_have_tax_print']
      }
    },
    {
      path: '/medicals/:id/print/slip/taxless',
      name: 'medicals-print-slip-taxless',
      component: MedicalPrintSlipTaxless,
      meta: {
        requiresAuth: true,
        permissions: ['hospital_admin'],
        plan_permissions: ['print_section.can_use_no_tax_print']
      }
    },
    {
      path: '/medicals/:id/invoices/:invoiceId/print/slip',
      name: 'medicals-expense-print-slip',
      component: MedicalExpensePrintSlip,
      meta: {
        requiresAuth: true,
        permissions: ['hospital_admin'],
      }
    },
    {
      path: '/medicals/:id/invoices/:invoiceId/print/:size',
      name: 'medicals-expense-print',
      component: MedicalExpensePrint,
      meta: {
        requiresAuth: true,
        permissions: ['hospital_admin'],
      }
    },
    {
      path: '/medicals/:id/invoices/:invoiceId/group/print/slip',
      name: 'medicals-expense-group-print-slip',
      component: MedicalExpenseGroupPrintSlip,
      meta: {
        requiresAuth: true,
        permissions: ['hospital_admin'],
      }
    },
    {
      path: '/medicals/:id/invoices/:invoiceId/group/print/:size',
      name: 'medicals-expense-group-print',
      component: MedicalExpenseGroupPrint,
      meta: {
        requiresAuth: true,
        permissions: ['hospital_admin'],
      }
    },
    {
      path: '/ipd/:id/payment/deposit/refund/print/slip',
      name: 'ipd-deposit-refund-print-slip',
      component: IpdDepositPrintSlip,
      meta: {
        requiresAuth: true,
        permissions: ['hospital_admin'],
        isRefund: true
      }
    },
    {
      path: '/ipd/:id/payment/deposit/refund/print/:size',
      name: 'ipd-deposit-refund-print',
      component: IpdDepositPrint,
      meta: {
        requiresAuth: true,
        permissions: ['hospital_admin'],
        isRefund: true
      }
    },
    {
      path: '/ipd/:id/payment/deposit/:receiptId/print/slip',
      name: 'ipd-deposit-print-slip',
      component: IpdDepositPrintSlip,
      meta: {
        requiresAuth: true,
        permissions: ['hospital_admin'],
      }
    },
    {
      path: '/ipd/:id/payment/deposit/:receiptId/print/:size',
      name: 'ipd-deposit-print',
      component: IpdDepositPrint,
      meta: {
        requiresAuth: true,
        permissions: ['hospital_admin'],
      }
    },
    {
      path: '/ipd/:id/payment/overdue/invoices/:invoiceId/print/slip',
      name: 'ipd-overdue-print-slip',
      component: IpdOverduePrintSlip,
      meta: {
        requiresAuth: true,
        permissions: ['hospital_admin'],
      }
    },
    {
      path: '/ipd/:id/payment/overdue/invoices/:invoiceId/print/:size',
      name: 'ipd-overdue-print',
      component: IpdOverduePrint,
      meta: {
        requiresAuth: true,
        permissions: ['hospital_admin'],
      }
    },
    {
      path: '/ipd/:id/payment/overdue/invoices/:invoiceId/group/print/slip',
      name: 'ipd-overdue-group-print-slip',
      component: IpdOverdueGroupPrintSlip,
      meta: {
        requiresAuth: true,
        permissions: ['hospital_admin'],
      }
    },
    {
      path: '/ipd/:id/payment/overdue/invoices/:invoiceId/group/print/:size',
      name: 'ipd-overdue-group-print',
      component: IpdOverdueGroupPrint,
      meta: {
        requiresAuth: true,
        permissions: ['hospital_admin'],
      }
    },
    {
      path: '/ipd/:id/payment/paid/:receiptId/print/slip',
      name: 'ipd-paid-print-slip',
      component: IpdPaidPrintSlip,
      meta: {
        requiresAuth: true,
        permissions: ['hospital_admin'],
      }
    },
    {
      path: '/ipd/:id/payment/paid/:receiptId/print/slip/taxless',
      name: 'ipd-paid-print-slip-taxless',
      component: IpdPaidPrintSlipTaxless,
      meta: {
        requiresAuth: true,
        permissions: ['hospital_admin'],
      }
    },
    {
      path: '/ipd/:id/payment/paid/:receiptId/print/:size',
      name: 'ipd-paid-print',
      component: IpdPaidPrint,
      meta: {
        requiresAuth: true,
        permissions: ['hospital_admin'],
      }
    },
    {
      path: '/ipd/:id/payment/paid/:receiptId/print/:size/taxless',
      name: 'ipd-paid-print-taxless',
      component: IpdPaidPrintTaxless,
      meta: {
        requiresAuth: true,
        permissions: ['hospital_admin'],
      }
    },
    {
      path: '/pos/:id/print/a4',
      name: 'pos-print-a4',
      component: PosPrint,
      meta: {
        size: 'a4',
        requiresAuth: true,
        permissions: ['hospital_admin'],
        plan_permissions: ['print_section.can_use_have_tax_print']
      }
    },
    {
      path: '/pos/:id/print/a4/taxless',
      name: 'pos-print-a4-taxless',
      component: PosPrintTaxless,
      meta: {
        size: 'a4',
        requiresAuth: true,
        permissions: ['hospital_admin'],
        plan_permissions: ['print_section.can_use_no_tax_print']
      }
    },
    {
      path: '/pos/:id/print/a5',
      name: 'pos-print-a5',
      component: PosPrint,
      meta: {
        size: 'a5',
        requiresAuth: true,
        permissions: ['hospital_admin'],
        plan_permissions: ['print_section.can_use_have_tax_print']
      }
    },
    {
      path: '/pos/:id/print/a5/taxless',
      name: 'pos-print-a5-taxless',
      component: PosPrintTaxless,
      meta: {
        size: 'a5',
        requiresAuth: true,
        permissions: ['hospital_admin'],
        plan_permissions: ['print_section.can_use_no_tax_print']
      }
    },
    {
      path: '/pos/:id/print/slip',
      name: 'pos-print-slip',
      component: PosPrintSlip,
      meta: {
        requiresAuth: true,
        permissions: ['hospital_admin'],
        plan_permissions: ['print_section.can_use_have_tax_print']
      }
    },
    {
      path: '/pos/:id/print/slip/taxless',
      name: 'pos-print-slip-taxless',
      component: PosPrintSlipTaxless,
      meta: {
        requiresAuth: true,
        permissions: ['hospital_admin'],
        plan_permissions: ['print_section.can_use_no_tax_print']
      }
    },
    {
      path: "/pet-owner",
      component: PetOwnerLayout,
      children: [
        ...petOwnerRoutes
      ]
    },
    {
      path: '/income-expense/receipt/:id/print/taxless/slip',
      name: 'income-expense-receipt-print-taxless-slip',
      component: IncomeExpenseReceiptPrintSlipTaxless,
      meta: {
        requiresAuth: true,
        permissions: ['hospital_admin'],
        plan_permissions: ['print_section.can_use_no_tax_print']
      }
    },
    {
      path: '/income-expense/receipt/:id/print/taxless/:size',
      name: 'income-expense-receipt-print-taxless',
      component: IncomeExpenseReceiptPrintTaxless,
      meta: {
        requiresAuth: true,
        permissions: ['hospital_admin'],
        plan_permissions: ['print_section.can_use_no_tax_print']
      }
    },
    {
      path: '/income-expense/receipt/:id/print/slip',
      name: 'income-expense-receipt-print-slip',
      component: IncomeExpenseReceiptPrintSlip,
      meta: {
        requiresAuth: true,
        permissions: ['hospital_admin'],
        plan_permissions: ['print_section.can_use_have_tax_print']
      }
    },
    {
      path: '/income-expense/receipt/:id/print/:size',
      name: 'income-expense-receipt-print',
      component: IncomeExpenseReceiptPrint,
      meta: {
        requiresAuth: true,
        permissions: ['hospital_admin'],
        plan_permissions: ['print_section.can_use_have_tax_print']
      }
    },
    {
      path: '/income-expense/receipt/:id/group/print/taxless/slip',
      name: 'income-expense-receipt-group-print-taxless-slip',
      component: IncomeExpenseReceiptGroupPrintSlipTaxless,
      meta: {
        requiresAuth: true,
        permissions: ['hospital_admin'],
        plan_permissions: ['print_section.can_use_group_no_tax_print']
      }
    },
    {
      path: '/income-expense/receipt/:id/group/print/taxless/:size',
      name: 'income-expense-receipt-group-print-taxless',
      component: IncomeExpenseReceiptGroupPrintTaxless,
      meta: {
        requiresAuth: true,
        permissions: ['hospital_admin'],
        plan_permissions: ['print_section.can_use_group_no_tax_print']
      }
    },
    {
      path: '/income-expense/receipt/:id/group/print/slip',
      name: 'income-expense-receipt-group-print-slip',
      component: IncomeExpenseReceiptGroupPrintSlip,
      meta: {
        requiresAuth: true,
        permissions: ['hospital_admin'],
        plan_permissions: ['print_section.can_use_group_have_tax_print']
      }
    },
    {
      path: '/income-expense/receipt/:id/group/print/:size',
      name: 'income-expense-receipt-group-print',
      component: IncomeExpenseReceiptGroupPrint,
      meta: {
        requiresAuth: true,
        permissions: ['hospital_admin'],
        plan_permissions: ['print_section.can_use_group_have_tax_print']
      }
    },
    {
      path: '/income-expense/summary/print/:size',
      name: 'income-expense-summary-print',
      component: IncomeExpenseSummaryPrint,
      meta: {
        requiresAuth: true,
        permissions: ['hospital_admin'],
        // plan_permissions: ['print_section.can_use_have_tax_print']
      }
    },
    {
      path: '/shift/history/:id/detail/print/:size',
      name: 'shift-history-detail-print',
      component: ShiftHistoryDetailPrint,
      meta: {
        requiresAuth: true,
        permissions: ['hospital_admin'],
        // plan_permissions: ['print_section.can_use_have_tax_print']
      }
    },
    {
      path: '/inventory/check/stock/:datetime/print/:size',
      name: 'check-stock-print',
      component: CheckStockPrint,
      meta: {
        requiresAuth: true,
        permissions: ['hospital_admin'],
        // plan_permissions: ['print_section.can_use_have_tax_print']
      }
    },
    {
      path: '/inventory/import/havepayment/inventory/:id/print/:size',
      name: 'import-inventory-havepayment-print',
      component: AddRemoveInventoryPrint,
      meta: {
        requiresAuth: true,
        isAdd: true,
        isPayment: true,
        permissions: ['hospital_admin'],
      }
    },
    {
      path: '/inventory/export/havepayment/inventory/:id/print/:size',
      name: 'export-inventory-havepayment-print',
      component: AddRemoveInventoryPrint,
      meta: {
        requiresAuth: true,
        isAdd: false,
        isPayment: true,
        permissions: ['hospital_admin'],
      }
    },
    {
      path: '/inventory/import/nopayment/inventory/:id/print/:size',
      name: 'import-inventory-nopayment-print',
      component: AddRemoveInventoryPrint,
      meta: {
        requiresAuth: true,
        isAdd: true,
        isPayment: false,
        permissions: ['hospital_admin'],
      }
    },
    {
      path: '/inventory/export/nopayment/inventory/:id/print/:size',
      name: 'export-inventory-nopayment-print',
      component: AddRemoveInventoryPrint,
      meta: {
        requiresAuth: true,
        isAdd: false,
        isPayment: false,
        permissions: ['hospital_admin'],
      }
    },
    {
      path: '/incomeexpense/income/:id/print/:size',
      name: 'incomeexpense-income-print',
      component: AddIncomeExpensePrint,
      meta: {
        requiresAuth: true,
        isIncome: true,
        isExpense: false,
        permissions: ['hospital_admin'],
      }
    },
    {
      path: '/incomeexpense/expense/:id/print/:size',
      name: 'incomeexpense-expense-print',
      component: AddIncomeExpensePrint,
      meta: {
        requiresAuth: true,
        isIncome: false,
        isExpense: true,
        permissions: ['hospital_admin'],
      }
    },
    {
      path: '/inventory/transaction/print/:size',
      name: 'inventory-transaction-print',
      component: InventoryTransactionPrint,
      meta: {
        requiresAuth: true,
        permissions: ['hospital_admin'],
        // plan_permissions: ['print_section.can_use_have_tax_print']
      }
    },
		{
			path: '/public/appointment/:id',
			name: 'public-appointment-detail',
			component: ApptDetailPublicPage,
			meta: {
				requiresAuth: false
			}
    },
    {
			path: '/public/pet/code/:code',
			name: 'public-pet-profile-detail',
			component: PetProfilePublicPage,
			meta: {
				requiresAuth: false
			}
    },
    {
			path: '/public/broadcast/:type/:id',
			name: 'public-broadcast-detail',
			component: BroadcastPublicPage,
			meta: {
				requiresAuth: false
			}
    },
		{
			path: '*',
			redirect: '/'
		}
	]
})

function fetchFieldValue(obj, prop) {

  if(typeof obj === 'undefined') {
      return false;
  }

  var _index = prop.indexOf('.')
  if(_index > -1) {
      return fetchFieldValue(obj[prop.substring(0, _index)], prop.substr(_index + 1));
  }

  return obj[prop];
}

router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		// this route requires auth, check if logged in
		// if not, redirect to login page.
		if (to.meta.permissions != null) {
			const token = localStorage.getItem('token')
			const user_type = localStorage.getItem('user_type')
			if (to.meta.permissions.indexOf(user_type) > -1 && token != null) {

        if (to.meta.plan_permissions != null) {
          const plan = JSON.parse(localStorage.getItem('currentPlan'))
          to.meta.plan_permissions.forEach(permission => {
            if (plan && !fetchFieldValue(plan, permission))
              next({name: 'hospitalError403'})
          });
        }

        next()
        
			} else {
				let routeName = 'home'

				to.meta.permissions.forEach(permission => {
					switch (permission) {
						case 'pet_owner':
							routeName = 'petOwnerLogin'
							break
						case 'hospital_admin':
							routeName = 'login'
							break
						default:
							break
					}
        })
        
				next({
					name: routeName,
					query: { redirect: to.fullPath }
				})
			}
		} else {
			alert(
				`Error, route ${
					to.name
				} require a permissions meta when requiresAuth is true.`
			)
			next({
				path: from.fullPath
			})
    }
	} else {
		next() // make sure to always call next()!
	}
})

router.afterEach((to, from) => {
  if (!from.name) return

  window.history.replaceState(
    {
      ...window.history.state,
      previousPath: from.fullPath
    },
    ''
  )
})

export default router

export const back = (fallbackLocation = '/') => {
  const {previousPath} = window.history.state

  if (!previousPath) return router.replace(fallbackLocation)

  return router.back()
}
