<template>
  <div>
    <div class="filter-section">
      <ContentBox>
        <h2 class="title">Search By</h2>
        <div>
          <el-select placeholder="Group Actitivies" size="small"
            clearable
            v-model="filter.group"
            @change="fetchAppointments"
            @clear="filter.group = ''"
          >
            <el-option
              v-for="group in activityGroups"
              :key="group.id"
              :value="group.id"
              :label="`${group.name}`"
            >
            </el-option>
          </el-select>
          <el-select placeholder="Status"
            size="small"
            clearable
            v-model="filter.status" 
            @change="fetchAppointments" 
            @clear="filter.status = ''" >
            <el-option :value="appointmentStatus.NO_SHOW" label="No show"></el-option>
            <el-option :value="appointmentStatus.CONFIRM" label="Confirm"></el-option>
            <el-option :value="appointmentStatus.NO_RESPOND" label="No respond"></el-option>
            <el-option :value="appointmentStatus.REQUEST_POSTPONE" label="Request postpone"></el-option>
            <el-option :value="appointmentStatus.POSTPONE" label="Postpone"></el-option>
            <el-option :value="appointmentStatus.DONE" label="Done"></el-option>
          </el-select>
        </div>
      </ContentBox>
    </div>
    <div class="content-section">
      <div class="left-col">
        <ContentBox>
          <div style="padding: 0 0 15px 0; margin-bottom: 15px; border-bottom: 1px solid #ccc;">
            <input type="checkbox" :checked="calendar.viewAll" @change="toggleViewAll" > View all
          </div>
          <div
              style="cursor: pointer; margin-bottom: 8px;"
              v-for="item in calendar.calendarList" 
              :key="item.id"
              @click="selectCalendarId(item.id)"
            >
            <span class="mark" :style="{ borderColor: item.borderColor, backgroundColor: item.checked ? item.backgroudColor: 'transparent'}"></span>
            <input type="checkbox" class="calendar-checkbox-round" :checked="item.checked" >
            <span>{{ item.name }}</span>
          </div>
        </ContentBox>
      </div>
      <div class="right-col">
        <ContentBox>
          <div class="calendar-info">
            <el-select v-model="calendar.view" size="small" placeholder="please select your zone">
              <el-option label="month" value="month"></el-option>
              <el-option label="week" value="week"></el-option>
              <el-option label="day" value="day"></el-option>
            </el-select>
            <el-button size="small" @click="onCalendarNav('today')">Today</el-button>
            <el-button size="small" @click="onCalendarNav('prev')"><i class="fa fa-angle-left"></i></el-button>
            <el-button size="small" @click="onCalendarNav('next')"><i class="fa fa-angle-right"></i></el-button>
            <span class="render-range">{{calendar.dateRange}}</span>
          </div>
          <calendar
            ref="calendar" 
            style="height: 800px;"
            :view="calendar.view"
            :calendars="calendar.calendarList"
            :schedules="calendar.scheduleList"
            :isReadOnly="true"
            :template="calendar.template"
          />
        </ContentBox>
      </div>
    </div> 
  </div>
</template>

<script>
import { mapState } from "vuex"
import randomColor from 'randomcolor'
import moment from 'moment'
import ContentBox from "@/components/container/ContentBox"
import Calendar from '@/components/calendar/Calendar'
import { appointmentStatus } from '../../constants'

export default {
  components: {
    ContentBox,
    Calendar
  },
  data () {
    return {
      appointmentStatus: appointmentStatus,
      filter: {
        group: '',
        status: ''
      },
      calendar: {
        dateRange: '',
        viewAll: true,
        view: 'month',
        scheduleList: [],
        calendarList: [],
        template: {
          popupDetailBody: function(schedule) {
            return schedule.body
          },
        }
      }
    }
  },
  computed: {
    ...mapState({
      activityGroups: state => state.activity.groups,
    })
  },
  methods: {
    init() {
      this.setRenderRangeText();
    },
    onCalendarNav(action) {
      const { invoke } = this.$refs.calendar
      invoke(action)
      this.setRenderRangeText()
      this.fetchAppointments()
    },
    selectCalendarId (id) {
      const {invoke} = this.$refs.calendar;

      this.calendar.calendarList = this.calendar.calendarList.map(item => {
        if(item.id === id) {
          invoke('toggleSchedules', item.id, item.checked, true)
          return {...item, checked: !item.checked}
        }
        return item
      })

      const countChecked = this.calendar.calendarList.reduce((total, item) => {
        if(item.checked) {
          return total + 1
        }
        return total
      }, 0)

      if (countChecked < this.calendar.calendarList.length && this.calendar.viewAll === true) {
        this.calendar.viewAll = false
      } else if (countChecked === this.calendar.calendarList.length && this.calendar.viewAll === false){
        this.calendar.viewAll = true
      }
    },
    toggleViewAll () {
      const {invoke} = this.$refs.calendar
      const value = !this.calendar.viewAll
      this.calendar.calendarList.forEach(item => {
        item.checked = value
        invoke('toggleSchedules', item.id, !value, false)
      })
      this.calendar.viewAll =  value
      invoke('render',true)
    },
    setRenderRangeText() {
      const {invoke} = this.$refs.calendar;
      const view = invoke('getViewName');
      const calDate = invoke('getDate');
      const rangeStart = invoke('getDateRangeStart');
      const rangeEnd = invoke('getDateRangeEnd');
      let year = calDate.getFullYear();
      let month = calDate.getMonth() + 1;
      let date = calDate.getDate();
      let dateRangeText = '';
      let endMonth, endDate, start, end;
      switch (view) {
        case 'month':
          dateRangeText = `${year}-${month}`;
          break;
        case 'week':
          year = rangeStart.getFullYear();
          month = rangeStart.getMonth() + 1;
          date = rangeStart.getDate();
          endMonth = rangeEnd.getMonth() + 1;
          endDate = rangeEnd.getDate();
          start = `${year}-${month}-${date}`;
          end = `${endMonth}-${endDate}`;
          dateRangeText = `${start} ~ ${end}`;
          break;
        default:
          dateRangeText = `${year}-${month}-${date}`;
      }
      this.calendar.dateRange = dateRangeText;
    },
    async fetchAppointments () {
      let query = {}
      const {invoke} = this.$refs.calendar;
      const calDate = invoke('getDate');
      let year = calDate.getFullYear();
      let month = calDate.getMonth();
      
      query["start"] = new Date(year, month, 1)
      query["end"] = new Date(year, month + 1, 0,23,59,59,999)
      query["status"] = this.filter.status
      query["activities__activity__sub_group__group__id"] = this.filter.group
    
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        await this.$store.dispatch("activity/listActivityGroups")
        const appts = await this.$store.dispatch("appointment/getCalendar", {
          query
        })
        if(appts) {
          let vets = []
          let schedules = []
          appts.forEach(appt => {
            let color = randomColor({ luminosity: 'light' })

            let vet = {}
            if (appt.id === -1) {
              color = '#ddd'
              vet = {
                id: appt.id,
                name: `${appt.first_name || appt.last_name}`,
                checked: true,
                borderColor: color,
                backgroudColor: color
              }
            } else {
              vet = {
                id: appt.id,
                name: `${appt.first_name} ${appt.last_name}`,
                checked: true,
                borderColor: color,
                backgroudColor: color
              }
            }
            
            vets.push(vet)

            appt.appointments.forEach(item => {
              const pet = (item.pet && item.pet.length > 0) ? item.pet[0] : null
              const schedule = {
                id: item.id,
                title: `${!item.is_no_time 
                          ? moment(item.date_time).format("HH:mm")
                          : ''}
                        ${pet ? pet.name : ''}`,
                category: 'time',
                start: item.date_time,
                end: item.date_time,
                calendarId: vet.id,
                color: '#333',
                bgColor: color,
                dragBgColor: color,
                borderColor: color,
                isVisible: vet.checked,
                isAllDay: item.is_no_time,
                body: item.activities.join(', ')
              }
              schedules.push(schedule)
            })
           
          })

          this.calendar.viewAll = true
          this.calendar.calendarList = vets
          this.calendar.scheduleList = schedules
        }
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg")
      }
    }
  },
  async mounted() {
    await this.init();
    // await this.fetchVets()
    await this.fetchAppointments()
  },
  watch: {
    'calendar.view': function (newValue) {
      this.$refs.calendar.invoke('changeView', newValue, true);
      this.setRenderRangeText();
    }
  },
}
</script>

<style lang="scss" scoped>
.filter-section {
  margin-bottom: 20px;

  .el-select {
    margin-right: 10px;
  }
}
.content-section {
  display: flex;
  width: 100%;
}
.left-col {
  display: flex;
  width: 200px;
  margin-right: 10px;
  flex-direction: column;
}
.right-col {
  display: flex;
  flex: 1;

  .content-box {
    width: 100%;
  }

  .el-select {
    margin-right: 10px;
  }
}
.calendar-info {
  margin-bottom: 15px;

  .render-range {
    font-size: 18px;
    margin-left: 20px;
    vertical-align: middle;
  }
}
input[type='checkbox'].calendar-checkbox-round {
  display: none; 
}

.mark {
    display: inline-block;
    cursor: pointer;
    width: 10px;
    height: 10px;
    line-height: 10px;
    vertical-align: middle;
    margin-right: 6px;
    border-radius: 10px;
    border: solid 2px;
    background: transparent;
  }

</style>

