<template>
  <at-modal class="row at-row" v-model="isShowLocal" @on-cancel="onCancelWrapper">
    <div class="row flex-center">
      <h4>กรุณาใส่ Password 4 ตัว เพื่อยืนยันตัวตน</h4>
    </div>
    <div class="row flex-center" style="padding-top: 5px">
      <h4>{{vetFullName}}</h4>
    </div>
    <div class="row flex-center" style="padding-top: 10px">
      <at-input v-validate="'required'" :maxlength='4' v-model="pin" placeholder="โปรดกรอกรหัสผ่าน" type="password" size="small"></at-input>
    </div>
    <div slot="footer" class="flex-end">
      <at-button @click="onCancelWrapper"> Cancel </at-button>
      <at-button type="error" @click="validateVet"> Confirm </at-button>
    </div>
  </at-modal>
</template>

<script>
export default {
  props: ['vet', 'isShow', 'onSubmit', 'formData', 'onCancel'],
  data () {
    return {
      pin: ''
    }
  },
  methods: {
    validateVet: function () {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.$notify({
            message: "ข้อมูลผิดพลาด กรุณากรอกข้อมูลให้ถูกต้อง",
            type: "error"
          })
          return
        }

        this.$store.dispatch("bloodbank/createVetVerify", { data : {
          vet_id: this.vet.id,
          pin: this.pin
        }}).then(() => {
          this.onSubmit()
          this.onCancelWrapper()
        }).catch((thrown) => {
          this.$notify({
            message: "การยืนยันตัวตนผิดพลาด กรุณากรอกรหัสผ่านให้ถูกต้อง",
            type: "error"
          })
          alert('ERROR FAIL ' + JSON.stringify(thrown))
          this.onCancelWrapper()
        })
      })
    },
    resetTypedPin: function () {
      this.pin = ''
    },
    onCancelWrapper: function () {
      this.resetTypedPin()
      this.onCancel()
    }
  },
  computed:{
    vetFullName: function() {
      return this.vet ? this.vet.title + ' ' + this.vet.first_name + ' ' + this.vet.last_name : ''
    },
    isShowLocal: {
      get: function() {
        return this.isShow
      },
      set: function() {
        
      }
    }
  }
}
</script>

<style>
</style>
