<template>
  <div>
    <Subnavbar>
      <template slot="title">New Appointment 2/2: Select activities</template>
      <template slot="actions">
        <button class="btn btn-large btn-calendar" @click="$router.push({ name: 'calendar' })">
          <i class="fa fa-calendar-alt"></i>Calendar
        </button>
        <button
          class="btn btn-large btn-default"
          @click="$route.query.cancelToRouteName ? $router.push({ name: $route.query.cancelToRouteName.toString(), params: { id: $route.params.id }}) : $router.push({ name: 'appointments' })"
        >Cancel</button>
        <button class="btn btn-large btn-primary" @click="confirmCreateAppointment">
          <i class="fa fa-save"></i>Create Appointment
        </button>
      </template>
    </Subnavbar>

    <el-row :gutter="20">
      <el-col :md="3">
        <div class="activity-groups-section" v-show="!$route.query.search">
          <ActivityGroups
            v-if="!searchText"
            :groups="activityGroups"
            :selectedGroupId="selectedGroupId"
            :selectedSubGroupId="selectedSubGroupId"
            :isFavorite="isFavorite"
            @click="selectGroup"
          />
        </div>
      </el-col>
      <el-col :md="14">
        <div class="search-section">
          <el-input
            placeholder="ค้นหาชื่อ กิจกรรม, ผลิตภัณฑ์, บริษัท"
            v-model="searchText"
            class="search-input"
            clearable
            @keyup.enter.native="search"
            @clear="clearSearch"
          >
            <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
          </el-input>
        </div>
        <div class="activity-breadcrumb" v-show="!$route.query.search">
          <template v-if="showActivities">
            <template v-if="$route.query.is_favorite">Favorite</template>
            <template v-else>{{ groupName }}</template>
            ({{ activities.count }}
            <template v-if=" activities.count > 1">Activities</template>
            <template v-else>Activity</template>)
          </template>
          <template v-else>
            <i class="el-icon-loading" />
          </template>
        </div>
        <div :style="{ backgroundColor: '#fff' }">
          <div class="activity-list" v-if="activities">
            <div v-for="activity in showActivities" :key="activity.id" class="activity-item-wrapper">
              <ActivitySelectableItem
                :activity="activity"
                :isSelected="isActivitySelected(activity.id)"
                @click="selectActivity"
              />
            </div>
          </div>
          <div
            class="not-found"
            v-if="showActivities && showActivities.length === 0"
          >ไม่พบข้อมูลที่ต้องการค้นหา</div>
          <el-pagination
            v-if="activities"
            background
            class="text-center"
            :page-size="50"
            @current-change="handleActivityPageChanged"
            :current-page.sync="activityPage"
            layout="prev, pager, next"
            :total="activities.count">
          </el-pagination>
        </div>
      </el-col>
      <el-col :md="7">
        <ContentBox>
          <template slot="header">Pet Profile</template>
          <PetProfile
          v-if="pet && pet.pet" 
          :pet="pet.pet" 
          :hospitalId="$route.params.id"
          :owner="(pet && pet.pet.owners && pet.pet.owners.length > 0) ? pet.pet.owners[0] : null" >

          <div slot="point" class="rectangle" v-if="pet && pet.pet">
            <span class="health-point">Health Point: <span class="pet-point">{{pet.pet.point}}</span> <img src="@/assets/images/common/favorite-true.png" style="width: 15px; height: 15px;"></span>
          </div>
          </PetProfile>
        </ContentBox>
        <ContentBox>
          <template slot="header">Detail</template>
          <ActivityRecordBox
            :activities="selectedActivities"
            :owners="pet && pet.pet.owners || []"
            :selectedOwnerId="(pet && pet.pet.owners && pet.pet.owners.length > 0) ? pet.pet.owners[0].id : null"
            :vets="vets"
            :date="apptDate"
            :selectedVetId="selectedVetId"
            :note="note"
            @delete="deleteActivity"
            @change="changeActivityRecord"
          />
        </ContentBox>
      </el-col>
    </el-row>

    <CreateApptConfirmDialog
      :visible="showConfirmDialog"
      :pet="pet"
      :owner="getOwnerByOwnerId(selectedOwnerId)"
      :date="apptDate"
      :isNoTime="isNoTime"
      :activities="selectedActivities"
      :vet="selectedVet"
      :note="note"
      :credit="creditCalulate"
      @confirm="createAppointment"
      @cancel="showConfirmDialog = false"
    />
  </div>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import ContentBox from "@/components/container/ContentBox";
import Subnavbar from "@/components/navigation/Subnavbar";
import PetProfile from "@/components/pet/PetProfile";
import ActivityGroups from "@/components/activity/ActivityGroups";
import ActivitySelectableItem from "@/components/activity/ActivitySelectableItem";
import ActivityRecordBox from "@/components/activity/ActivityRecordBox";
import CreateApptConfirmDialog from "@/components/appointment/CreateApptConfirmDialog";

export default {
  components: {
    ContentBox,
    Subnavbar,
    PetProfile,
    ActivityGroups,
    ActivitySelectableItem,
    ActivityRecordBox,
    CreateApptConfirmDialog
  },
  data() {
    return {
      query: {},
      searchText: "",
      isFavorite: true,
      selectedGroupId: -1,
      selectedSubGroupId: -1,
      selectedActivities: [],
      showActivities: [],
      activityPage: 1,
      apptDate: new Date(),
      isNoTime: false,
      selectedVetId: -1,
      selectedOwnerId: null,
      attachments: [],
      note: "",
      showConfirmDialog: false,
      reminderBeforeTemplate: null,
      savedData: null,
      creditCalulate: null
    };
  },
  watch: {
    activities: {
      handler() {
        const result = this.activities ? this.activities.results : [];
        this.showActivities = JSON.parse(JSON.stringify(result));
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      pet: state => state.pet.selected,
      activityGroups: state => state.activity.groups,
      activities: state => state.activity.activities,
      vets: state => state.hospital.vets
    }),
    groupName() {
      const group = this.activityGroups.find(
        group => group.id === this.selectedGroupId
      );
      let groupName = group ? group.name : "";
      if (group && group.sub_group) {
        const supGroup = group.sub_group.find(
          group => group.id === this.selectedSubGroupId
        );
        groupName += supGroup ? ` > ${supGroup.name}` : "";
      }
      return groupName;
    },
    selectedVet() {
      if (this.selectedVetId !== -1) {
        return this.vets.find(vet => vet.id === this.selectedVetId);
      }
    }
  },
  methods: {
    getOwnerByOwnerId(ownerId) {
      if (this.pet && this.pet.pet.owners && this.pet.pet.owners.length > 0) {
        return this.pet.pet.owners.find(owner => owner.id === ownerId);
      }
      return null;
    },
    search() {
      if (this.searchText.trim() !== "") {
        this.query = {
          ...this.getDefaultQuery(),
          search: this.searchText.trim(),
        };
        delete this.query.is_favorite;
        this.$router.replace({ query: this.query });
        this.fetchActivity(this.query);
      }

      if (
        this.searchText.trim() === "" &&
        this.$route.query.search !== undefined
      ) {
        this.clearSearch();
      }
    },
    clearSearch() {
      this.query = this.getDefaultQuery();
      this.activityPage = 1;
      this.searchText = "";
      this.isFavorite = true;
      this.selectedGroupId = -1;
      this.selectedSubGroupId = -1;
      this.$router.replace({ query: this.query });
      this.fetchActivity(this.query);
    },
    async handleActivityPageChanged(page) {
      const query = {
        ...this.query,
        page: this.activityPage
      };
      await this.fetchActivity(query);
    },
    async fetchActivity(query) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        await this.$store.dispatch("activity/listActivities", { 
          query: {
            ...query,
            page_size: 50
          }
        });
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorMsg");
      }
    },
    async selectGroup(data) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        this.activityPage = 1;
        this.query = this.setQueryString(data);
        this.$router.replace({ query: this.query });
        await this.fetchActivity(this.query);
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorMsg");
      }
    },
    async createAppointment() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        const appt = await this.$store.dispatch(
          "appointment/createAppointment",
          { data: this.savedData }
        );
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        this.savedData = null;
        this.$notify({
          message: "สร้างนัดหมายเรียบร้อยแล้ว",
          type: "success"
        });
        this.$router.push({
          name: "appointment-detail",
          params: { id: appt.id }
        });
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorMsg", error.detail);
      }
    },
    selectActivity(activity) {
      if (this.isActivitySelected(activity.id)) {
        this.deleteActivity(activity);
      } else {
        this.selectedActivities.push(activity);
      }
    },
    deleteActivity(activity) {
      this.selectedActivities = this.selectedActivities.filter(
        item => item.id !== activity.id
      );
    },
    changeActivityRecord(data) {
      this.apptDate = data.date;
      this.isNoTime = data.isNoTime;
      this.selectedVetId = data.vetId;
      this.attachments = data.attachments;
      (this.note = data.note),
        (this.reminderBeforeTemplate = data.reminderBeforeTemplate);
      this.selectedOwnerId = data.selectedOwnerId;
    },
    isActivitySelected(id) {
      return !!this.selectedActivities.find(item => item.id === id);
    },
    async calculateBeforeConfirm() {
      let data = {};
      data["pet"] = this.pet.pet.id;
      data["date_time"] = this.apptDate;
      data["is_no_time"] = this.isNoTime;
      data["activities"] = this.selectedActivities.map(activity => {
        return {
          activity: activity.id
        };
      });
      data["attachments"] = this.attachments;
      data["note"] = this.note;

      data["reminder_before_template"] = this.reminderBeforeTemplate.map(
        item => {
          return {
            line_item: item.line_item,
            remind_via_sms: item.remind_via_sms,
            remind_via_social: item.remind_via_social
          };
        }
      );
      data["remind_to"] = this.selectedOwnerId;

      if (this.selectedVetId !== -1) {
        data["vet"] = this.selectedVetId;
      }

      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        this.creditCalulate = await this.$store.dispatch(
          "appointment/getCreditCalulate",
          { data }
        );
        this.savedData = data;
        this.showConfirmDialog = true;
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorMsg", error.detail);
      }
    },
    confirmCreateAppointment() {
      if (!this.apptDate) {
        this.$notify({
          message: "กรุณาเลือกวันเวลานัด",
          type: "warning"
        });
      } else if (this.selectedActivities.length == 0) {
        this.$notify({
          message: "กรุณาเลือกกิจกรรมอย่างน้อย 1 อย่าง",
          type: "warning"
        });
      } else if (!this.selectedOwnerId) {
        this.$notify({
          message: "กรุณาเลือกผู้รับ (Select Receive Owner)",
          type: "warning"
        });
      } else if (this.apptDate && this.selectedActivities.length > 0) {
        this.calculateBeforeConfirm();
      }
    },
    getDefaultQuery() {
      return {
        // all: true,
        page: 1,
        is_favorite: true,
        pet_type__key: this.pet.pet.type.key,
        pet_gender: this.pet.pet.gender
      };
    },
    getQueryString() {
      let query = { 
        page: this.activityPage,
      };

      if (this.$route.query.search) {
        this.searchText = this.$route.query.search;
        query["search"] = this.$route.query.search;
      } else {
        this.searchText = "";
      }

      if (this.$route.query.is_favorite) {
        this.isFavorite = !!this.$route.query.is_favorite;
        query["is_favorite"] = this.$route.query.is_favorite;
      } else if (!this.$route.query.group) {
        query["is_favorite"] = true;
        this.isFavorite = true;
      } else {
        this.isFavorite = false;
        query["is_favorite"] = undefined;
      }

      if (this.$route.query.group) {
        this.selectedGroupId = parseInt(this.$route.query.group);
        query["group"] = this.$route.query.group;
      } else {
        this.selectedGroupId = -1;
        query["group"] = undefined;
      }

      if (this.$route.query.sub_group) {
        this.selectedSubGroupId = parseInt(this.$route.query.sub_group);
        query["sub_group"] = this.$route.query.sub_group;
      } else {
        this.selectedSubGroupId = -1;
        query["sub_group"] = undefined;
      }

      if (this.pet.pet.type.key) {
        query["pet_type__key"] = this.pet.pet.type.key;
      }

      if (this.pet.pet.gender !== null) {
        query["pet_gender"] = this.pet.pet.gender;
      }

      if (this.$route.query.cancelToRouteName) {
        query["cancelToRouteName"] = this.$route.query.cancelToRouteName;
      }

      return query;
    },
    setQueryString(data) {
      let query = { page: 1 };

      if (this.searchText !== "") {
        query["search"] = this.searchText;
      }

      if (data.isFavorite) {
        this.isFavorite = data.isFavorite;
        query["is_favorite"] = data.isFavorite;
      } else {
        this.isFavorite = false;
      }

      if (data.selectedGroupId !== -1) {
        this.selectedGroupId = data.selectedGroupId;
        query["group"] = data.selectedGroupId;
      } else {
        this.selectedGroupId = -1;
      }

      if (data.selectedSubGroupId !== -1) {
        this.selectedSubGroupId = data.selectedSubGroupId;
        query["sub_group"] = data.selectedSubGroupId;
      } else {
        this.selectedSubGroupId = -1;
      }

      if (this.pet.pet.type.key) {
        query["pet_type__key"] = this.pet.pet.type.key;
      }

      if (this.pet.pet.gender !== null) {
        query["pet_gender"] = this.pet.pet.gender;
      }

      if (this.$route.query.cancelToRouteName) {
        query["cancelToRouteName"] = this.$route.query.cancelToRouteName;
      }

      return query;
    },
    normalizeAPIQuery(query) {
      return _.omit(query, ["cancelToRouteName"]);
    }
  },
  async created() {
    try {
      await this.$store.dispatch("ui/setIsFullScreenLoading", true);
      await this.$store.dispatch("pet/getPetProfile", {
        params: { petHospitalId: this.$route.params.id }
      });

      this.query = this.getDefaultQuery();

      if (!_.isEmpty(this.$route.query)) {
        this.query = this.getQueryString(); // if querystring is not empty
      }
      this.$router.replace({ query: this.query });
      await this.$store.dispatch("activity/listActivityGroups", {
        query: {
          is_only_in_activity: true,
          feature: "appointment"
        }
      });
      await this.fetchActivity(this.normalizeAPIQuery(this.query));
      await this.$store.dispatch("hospital/listVets");
      await this.$store.dispatch("ui/setIsFullScreenLoading", false);
    } catch (error) {
      await this.$store.dispatch("ui/showFetchErrorWithReloadMsg");
    }
  }
};
</script>

<style lang="scss" scoped>
.search-section {
  margin-bottom: 8px;
}
.activity-breadcrumb {
  padding: 2px 0;
  margin: 4px 0;
  height: 20px;
  font-size: 16px;
  line-height: 1em;
  font-weight: normal;
  color: #333;
}
.not-found {
  text-align: center;
}
.activity-item-wrapper {
  display: inline-block;
  // width: calc(20% - 10px);
  padding: 4px;
  vertical-align: top;
}

.rectangle {
  display: flex;
  align-items: center;
  align-self: center;
  height: 32px;
  border-radius: 5px;
  background-color: #f5f8fa;
}
.health-point {
  display: block;
  text-align: center;
  width: 100%;
  font-family: Arial;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  color: #7f8fa4;

  .pet-point {
    margin: 0 4px;
    font-family: Arial;
    font-size: 14px;
    font-weight: Bold;
    color: #d0025b;
  }
}
</style>

