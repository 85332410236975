<template>
  <div>
    <h2 class="title">Filter</h2>
    <el-row class="custom-row">
      <el-col :md="12">
        <div class="date-group" :gutter="8">
          <el-date-picker type="date" placeholder="Start Date" size="small" v-model="form.start" @change="startDateChange"></el-date-picker>
          <span style="display: inline-block; padding: 0 8px;"> to </span>
          <el-date-picker type="date" placeholder="End Date" size="small" v-model="form.end" @change="endDateChange"></el-date-picker>
        </div>
      </el-col>
      <el-col :md="12">
        <el-row :gutter="16">
          <el-col :md="8">
            <el-select placeholder="Select Type" size="small"
              clearable
              v-model="form.typeId"
              @change="onSearch"
              @clear="onClearTypeId"
            >
              <el-option
                v-for="type in types"
                :key="type.id"
                :value="type.id"
                :label="`${type.name}`"
              ></el-option>
            </el-select> 
          </el-col>
          <el-col :md="8">
            <el-select placeholder="ผู้ติดต่อ" size="small"
              clearable
              v-model="form.contact"
              @change="onSearch"
              @clear="onClearContact"
            >
              <el-option
                v-for="item in contacts"
                :key="item.id"
                :value="item.id"
                :label="`${item.name}`"
              ></el-option>
            </el-select> 
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: ['startDate', 'endDate', 'types', 'selectedTypeId', 'contact', 'contacts'],
  components: {
    
  },
  data() {
    return {
      form: {
        start: this.startDate ? moment(this.startDate).format("YYYY-MM-DD") : null,
        end:  this.endDate ? moment(this.endDate).format("YYYY-MM-DD"): null,
        typeId: this.selectedTypeId,
        contact: this.contact,
      }
    }
  },
  methods: {
    startDateChange () {
      this.$emit('search', this.form)
    },
    endDateChange (d) {
      if(d) {
        d.setHours(23,59,59,999)
        this.form.end = new Date(d)
      }
      this.$emit('search', this.form)
    },
    onSearch () {
      this.$emit('search', this.form)
    },
    onClearTypeId () {
      this.form.typeId = ''
      this.$emit('search', this.form)
    },
    onClearContact () {
      this.form.contact = ''
      this.$emit('search', this.form)
    },
  },
  watch: {
    selectedTypeId(newValue, oldValue) {
      if(newValue !== oldValue) {
        this.form.typeId = parseInt(newValue)
      }
    },
    contact(newValue, oldValue) {
      if(newValue !== oldValue) {
        this.form.contact = parseInt(newValue)
      }
    },
    startDate(newValue, oldValue) {
      if(newValue !== oldValue) {
        this.form.start = newValue ? moment(newValue).format("YYYY-MM-DD") : null
      }
    },
    endDate(newValue, oldValue) {
      if(newValue !== oldValue) {
        this.form.end = newValue ? moment(newValue).format("YYYY-MM-DD"): null
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.appointment-search {
  .title {
    margin: 0;
    padding: 0;
    font-size: 16px;
    margin-bottom: 5px;
  }

  .custom-row {
    margin-bottom: 10px;
  }

  .date-group {
    > span {
      padding: 10px;
    }
  }
}
</style>


