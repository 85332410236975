<template>
  <div>
    <Subnavbar>
      <template slot="title">
        Drug Label
      </template>
      <template slot="actions">
        <div style="display: flex;">
          <button :disabled="medicalDrugList.length === 0 || medicalDrugCheck.length === 0" class="btn btn-large btn-info" style="border: solid 1px;" @click="printPDF()">
            <i class="fa fa-print"></i>Print
          </button>
          <button :disabled="medicalDrugList.length === 0" class="btn btn-large btn-primary" @click="handleEditButtonClick">
            <i class="fa fa-edit"></i>Edit
          </button>
          <div class="message-box">
            <i class="el-icon-close" style="align-self: center;" @click="$router.go(-1)"></i>
          </div>
        </div>
      </template>
    </Subnavbar>
    <el-row :gutter="20">
      <el-col :md="24">
        {{ med && med.id ? `Medical Record ID : ` + med.id : ''}}
        <ContentBox>
          <template slot="header" class="pet-profile-header">Pet Profile</template>
          <div class="pet-profile-content">
            <PetProfile v-if="pet" class="medical-pet-profile" :pet="pet.pet" :owner="pet.pet.owners[0]"/>
            <div class="rectangle">
              <span class="health-point">Health Point: <span class="pet-point">{{pet && pet.pet.point}}</span> <img src="@/assets/images/common/favorite-true.png" style="width: 15px; height: 15px;"></span>
            </div>
          </div>
        </ContentBox>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :md="24">
        <ContentBox>
          <div class="content-header">
            <div>
              <div class="title">All {{ medicalDrugList.length }} Drug Label</div>
              <el-checkbox v-model="checkAll" @change="handleCheckAllChange" border>Print All Drug Label</el-checkbox>
              <el-select
                placeholder="Tx / Rx"
                class="margin-right"
                v-model="filterX"
              >
                <el-option
                  value="0"
                  :label="`ทั้งหมด`"
                ></el-option>
                <el-option
                  value="1"
                  :label="`Tx`"
                ></el-option>
                <el-option
                  value="2"
                  :label="`Rx`"
                ></el-option>
              </el-select>
              <el-checkbox v-model="hospitalName" border>ไม่เอาชื่อโรงพยาบาล</el-checkbox>
              <el-checkbox v-model="productFlag" border style="margin-left: 0px">ไม่เอาชื่อยา</el-checkbox>
            </div>
            <div class="selection-header">
              <div class="sub-title">Selection {{ medicalDrugCheck.length }} label</div>
            </div>
          </div>
        </ContentBox>
      </el-col>
    </el-row>
    <el-checkbox-group v-model="medicalDrugCheck" @change="handleCheckedChange">
      <el-row class="drug-row" v-if="pet">
        <div class="not-found" v-if="medicalDrugList.length === 0">
          <img src="@/assets/images/no-result/no-result-04.png" class="responsive-img" />
        </div>
        <el-col class="drug-card" v-for="list in medicalDrugList" :key="list.id">
          <div class="drug-list">
            <el-checkbox class="checkbox-select" @change="handleChecked(list.id)" :label="list">Selection</el-checkbox>
            <div class="drug-detail" :id="`drug-${list.id}`">
              <DrugLabelPrint
                :medicationUse="medicationUse"
                :medicationUnits="medicationUnits"
                :drug="list"
                :pet="pet.pet"
                :hospital="hospitalInfo"
                :hn="hn"
                :nameFlag="!hospitalName"
                :productFlag="!productFlag"
              ></DrugLabelPrint>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-checkbox-group>
  </div>
</template>

<script>
// import _ from "lodash"
import { mapState } from "vuex"
import ContentBox from "@/components/container/ContentBox"
import Subnavbar from "@/components/navigation/Subnavbar"
import PetProfile from "@/components/pet/PetProfile"
import DrugLabelPrint from "@/components/medical/DrugLabelShow"

export default {
  components: {
    ContentBox,
    Subnavbar,
    PetProfile,
    DrugLabelPrint
  },
  data() {
    return {
      medicalDrugList: [],
      medicalDrugCheck: [],
      medicationUse: [],
      medicationUnits: [],
      isIndeterminate: true,
      checkAll: false,
      hn: '',
      hospitalName: false,
      productFlag: false,
      filterX: "0"
    }
  },
  watch: {
    async filterX(newVal) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        if (newVal === '1') {
          this.medicalDrugList = await this.$store.dispatch("medical/getMedicalLabelList", {
            params: { id: this.med.id },
            query: { is_tx: true }
          })
        } else if (newVal === '2') {
          this.medicalDrugList = await this.$store.dispatch("medical/getMedicalLabelList", {
            params: { id: this.med.id },
            query: { is_rx: true }
          })
        } else {
          this.medicalDrugList = await this.$store.dispatch("medical/getMedicalLabelList", {
            params: { id: this.med.id }
          })
        }
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg")
      }
    }
  },
  computed: {
    ...mapState({
      pet: state => state.pet.selected,
      med: state => state.medical.selected,
      hospitalInfo: state => state.hospital.profile
    }),

    isIpd() {
      return this.$route.meta.ipd;
    },

    id() {
      if (this.isIpd) return this.$route.params.opdId
      return this.$route.params.id
    }
  },
  methods: {
    handleCheckAllChange(val) {
      const medicalAll = this.medicalDrugList
      medicalAll.forEach(ma => {
        let element = document.getElementById(`drug-${ma.id}`)
        if (val) {
          element.classList.add('is-checked')
        } else {
          element.classList.remove('is-checked')
        }
      })
      this.medicalDrugCheck = val ? medicalAll : [];
      this.isIndeterminate = false;
    },
    handleCheckedChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.medicalDrugList.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.medicalDrugList.length;
    },
    handleChecked(id) {
      let element = document.getElementById(`drug-${id}`)
      if (element.classList.value.indexOf('is-checked') !== -1) {
        element.classList.remove('is-checked')
      } else {
        element.classList.add('is-checked')
      }
    },
    async printPDF() {
      const data = {
        id: this.med.id,
        medicalDrugLabel: this.medicalDrugCheck,
        medicationUse: this.medicationUse,
        medicationUnits: this.medicationUnits,
        nameFlag: !this.hospitalName,
        productFlag: this.productFlag,
        pet: this.pet,
        hospitalInfo: this.hospitalInfo,
        hn: this.hn
      }
      await this.$store.commit("medical/setMedicalDrugLabelPDF", data)

      if (this.isIpd) {
        this.$router.push({name: 'ipd-medical-record-detail-drug-label-print', params: this.$route.params})
      } else {
        this.$router.push({name: 'medical-drug-label-pdf', params: { id: this.med.id }})
      }
    },
    handleEditButtonClick() {
      if (this.isIpd) {
        this.$router.push({ name: "ipd-medical-record-detail-drug-label-edit", params: this.$route.params})
        return
      }

      this.$router.push({ name: "medicals-drug-label-detail", params: { id: this.med.id }})
    }
  },
  async mounted() {

  },
  async created() {
    try {
      await this.$store.dispatch("ui/setIsFullScreenLoading", true)
      this.medicationUse = await this.$store.dispatch(
        "product/listMedicationUse",
        {
          query: { all: true }
        }
      )
      this.medicationUnits = await this.$store.dispatch("product/listUnits", {
        query: { show_in: "medication", all: true }
      })
      await this.$store.dispatch("medical/getMedical", {
        params: { id: this.id }
      })
      this.medicalDrugList = await this.$store.dispatch("medical/getMedicalLabelList", {
        params: { id: this.med.id }
      })
      let pet = await this.$store.dispatch("pet/getPetProfile", {
        params: { petHospitalId: this.med.pethospital }
      })
      this.hn = pet.hn;
      await this.$store.dispatch("ui/setIsFullScreenLoading", false)
    } catch (error) {
      await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      await this.$store.dispatch("ui/showFetchErrorWithReloadMsg")
    }
  }
}
</script>

<style lang="scss" scoped>
.not-found {
  padding: 10%;
}
.medical-pet-profile {
  height: 100%;
  display: flex;
  justify-content: center;
}
.pet-profile-header {
  border-radius: 4px;
  border: solid 1px #e6eaee;
}
.pet-profile-content {
  display: flex;
  flex-direction: column;
  margin-left: 5%;
}
.el-row {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  .el-col-8 {
    width: 10%;
  }
  .el-col-16 {
    width: 35%;
  }
}
.margin-row {
  margin: 10px 0;
}
.rectangle {
  margin-left: -10%;
  display: flex;
  align-items: center;
  align-self: center;
  width: 30%;
  height: 45px;
  border-radius: 5px;
  background-color: #f5f8fa;
}
.health-point {
  margin-left: 15%;
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  color: #7f8fa4;
}
.pet-point {
  margin: 0 20px;
  font-family: Arial;
  font-size: 18px;
  font-weight: Bold;
  color: #d0025b;
}
.message-box {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.content-header {
  display: flex;
  justify-content: space-between;
}
.selection-header {
  display: flex;
  align-self: flex-end;
}
.title {
  font-family: "Kanit", sans-serif;
  font-size: 18px;
  font-weight: bold;
}
.sub-title {
  font-family: "Kanit", sans-serif;
  font-size: 16px;
}
.margin-right {
  margin-right: 30px;
}
.drug-row {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.drug-card {
  display: flex;
  width: 50%;
  margin: 2.5% 0;
}
.drug-list {
  width: 98%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.drug-detail {
  width: 100%;
  height: 340px;
  background-color: #ffffff;
}
.checkbox-select {
  padding: 1% 2%;
  font-family: Thonburi;
  font-size: 14px;
  background-color: #ffffff;
}
.is-checked {
  border: solid 3px #02b0e1;
}

@media only screen and (max-width: 768px) {
  .content-header {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .drug-row {
    justify-content: center;
  }
  .drug-card {
    width: 70%;
  }
}

</style>
