<template>
    <div id="backtotop">
        <button class="goTop" v-bind:class="{ isVisible: isVisible }" v-if="isVisible" @click="backToTop">
            <i class="fa fa-angle-up" aria-hidden="true"></i>
        </button>
    </div>
</template>

<script>
    import $ from 'jquery'
    
    export default {
        name: 'VRemindBackTop',
        componentName: 'VRemindBackTop',

        props: {
            height: {
                type: Number,
                default: 800
            }
        },
        data() {
            return {
                isVisible: false,
            }
        },
        methods: {
            handleScroll () {
                this.isVisible = $('#page').scrollTop() >= this.height;
                
                if (this.isVisible) {
                    $('.goTop').addClass('isVisible');
                } else {
                    $('.goTop').removeClass('isVisible');
                }
            },
            backToTop () {
                $('#page').stop().animate({
                    scrollTop: 0
                }, 'slow', 'swing');
                this.$emit('click');
            }
        }
    }
</script>

<style lang="scss" scoped>
.goTop {
    border-radius: 5px;
    background-color: rgb(1,14,27);
    background-color: rgba(1, 14, 27, .7);
    position: fixed;
    width: 45px;
    height: 45px;
    display: block;
    right: 15px;
    bottom: 15px;
    border: none;
    opacity: 0;
    z-index: -1;
    .fa {
        color: white;
        font-size: 22px;
    }
    &:hover {
        opacity: 1;
        background-color: rgb(1,14,27);
        background-color: rgba(1, 14, 27, .9);
    }
}

.isVisible {
    opacity: .8;
    z-index: 999999;
    transition: all .4s ease-in;
}
</style>