<template>
  <el-dialog title="บันทึก Blood Group" :visible.sync="petBloodGroupDialog" center @close="$emit('cancel')">
    <el-form :model="form" :rules="rules" ref="petBloodGroupDialogForm" label-position="top">
      <el-form-item label="วันที่" :label-width="formLabelWidth" prop="date">
        <el-date-picker
          v-model="form.date"
          type="date"
          placeholder="Select Date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          style="width: 100%;">
        </el-date-picker>
      </el-form-item>
      
      <el-form-item label="Blood Group" :label-width="formLabelWidth" prop="blood_group">
        <el-select v-model="form.blood_group" placeholder="Select Blood Group">
          <el-option
            v-for="item in form.blood_group_items"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="บันทึกเพิ่มเติม" :label-width="formLabelWidth" prop="note">
        <el-input
          type="textarea"
          :autosize="{ minRows: 4 }"
          placeholder="Note Here.."
          v-model="form.note">
        </el-input>
      </el-form-item>

      <el-form-item label="แนบไฟล์รูป(ถ้ามี)" :label-width="formLabelWidth" prop="file">
        <FileUploader
          v-if="!fileUrl"
          style="width: 256px; height: 144px; margin: 4px;"
          :multiple="false"
          accept="image/*"
          @onSelect="handleSelectFile" />
        <ImageFilePreviewer
          v-if="fileUrl"
          style="width: 256px; height: 144px; margin: 4px;"
          :url="fileUrl"
          :showDeleteButton="true"
          @delete="handleDeleteSelectFile" />
      </el-form-item>


    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('cancel')">Cancel</el-button>
      <el-button type="primary" @click="$emit('confirm')">Confirm</el-button>
    </span>
  </el-dialog>
</template>

<script>
import FileUploader from "@/components/upload/FileUploader"
import ImageFilePreviewer from "@/components/upload/ImageFilePreviewer"

export default {
    components: {
      FileUploader,
      ImageFilePreviewer
    },

    props: {
      visible: Boolean,
      form: Object
    },
    data() {
      return {
        petBloodGroupDialog: this.visible,
        fileUrl: null,
        formLabelWidth: '100%',
        rules: {
          date: [
            { required: true, },
          ],
          // blood_group: [
          //   { required: true, },
          // ],
        }
      };
    },
    methods: {
      handleSelectFile(files) {
        if (files.length > 0) {
          const self = this;
          const reader = new FileReader();
          this.form.file = files[0]

          reader.onload = function (e) {
            self.fileUrl = e.target.result
          }
          reader.readAsDataURL(files[0])
        } else {
          this.form.file = null
          this.fileUrl = null
        }
      },
      handleDeleteSelectFile() {
        this.fileUrl = null
      },
    }
  };
</script>

<style lang="scss">
  .el-dialog__header .el-dialog__title {
    font-size: 20px;
    font-weight: bold;
    color: $light-blue;
  }
  .el-dialog--center .el-dialog__body {
    font-size: 16px;
    padding: 20px 80px;

    .el-form--label-top .el-form-item__label {
      padding-bottom: 0;
      font-weight: bold;
      font-size: 16px;
    }
  }
  
</style>
