var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"plan-table"},[_vm._m(0),_c('div',{staticClass:"row no-top-line"},[_vm._m(1),_c('div',{staticClass:"column"},[_c('el-row',{staticClass:"reminder-credit"},[_c('el-col',{attrs:{"sm":24,"md":5}},[_c('img',{attrs:{"src":require("@/assets/images/plan/coin.png")}})]),_c('el-col',{staticClass:"credits",attrs:{"sm":24,"md":19}},[_c('strong',[_vm._v("1,000")]),_vm._v(" credits ")])],1)],1),_c('div',{staticClass:"column"},[_c('el-row',{staticClass:"reminder-credit"},[_c('el-col',{attrs:{"sm":24,"md":5}},[_c('img',{attrs:{"src":require("@/assets/images/plan/coin.png")}})]),_c('el-col',{staticClass:"credits",attrs:{"sm":24,"md":19}},[_c('strong',[_vm._v("5,000")]),_vm._v(" credits ")])],1)],1),_c('div',{staticClass:"column"},[_c('el-row',{staticClass:"reminder-credit"},[_c('el-col',{attrs:{"sm":24,"md":5}},[_c('img',{attrs:{"src":require("@/assets/images/plan/coin.png")}})]),_c('el-col',{staticClass:"credits",attrs:{"sm":24,"md":19}},[_c('strong',[_vm._v("10,000")]),_vm._v(" credits ")])],1)],1),_c('div',{staticClass:"column"},[_c('el-row',{staticClass:"reminder-credit"},[_c('el-col',{attrs:{"sm":24,"md":5}},[_c('img',{attrs:{"src":require("@/assets/images/plan/coin.png")}})]),_c('el-col',{staticClass:"credits",attrs:{"sm":24,"md":19}},[_c('strong',[_vm._v("20,000")]),_vm._v(" credits ")])],1)],1)]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17),_vm._m(18),_vm._m(19),_vm._m(20),_vm._m(21),_vm._m(22),_vm._m(23),_vm._m(24)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row no-top-line"},[_c('div',{staticClass:"column topic-column"},[_c('div',{staticClass:"title"},[_vm._v(" ")])]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"plan-header plan-free"},[_c('div',{staticClass:"plan-name"},[_vm._v("TRIAL")]),_c('div',{staticClass:"plan-desc"},[_vm._v("ทดลองใช้")]),_c('div',{staticClass:"pricing"},[_vm._v("FREE")])])]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"plan-header plan-free"},[_c('div',{staticClass:"plan-name"},[_vm._v("BASIC")]),_c('div',{staticClass:"plan-desc"},[_vm._v("เน้นเฉพาะทำนัดหมาย")]),_c('div',{staticClass:"pricing"},[_c('span',{staticClass:"currency"},[_vm._v("THB")]),_vm._v("5,000 "),_c('span',{staticClass:"per"},[_vm._v("/Year")])])])]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"popular-label"},[_vm._v("Popular")]),_c('div',{staticClass:"plan-header plan-free"},[_c('div',{staticClass:"plan-name"},[_vm._v("STANDARD")]),_c('div',{staticClass:"plan-desc"},[_vm._v("ทำนัดหมาย และ สื่อสารการตลาด")]),_c('div',{staticClass:"pricing"},[_c('span',{staticClass:"currency"},[_vm._v("THB")]),_vm._v("8,000 "),_c('span',{staticClass:"per"},[_vm._v("/Year")])])])]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"plan-header plan-free"},[_c('div',{staticClass:"plan-name"},[_vm._v("PREMIUM")]),_c('div',{staticClass:"plan-desc"},[_vm._v("เน้นการพัฒนาศักยภาพให้สูงสุด")]),_c('div',{staticClass:"pricing"},[_c('span',{staticClass:"currency"},[_vm._v("THB")]),_vm._v("12,000 "),_c('span',{staticClass:"per"},[_vm._v("/Year")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column topic-column"},[_c('div',{staticClass:"title"},[_vm._v("Reminder Credit")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row no-top-line"},[_c('div',{staticClass:"column topic-column"},[_c('div',{staticClass:"title"},[_vm._v("Appointments")]),_c('div',{staticClass:"subtitle"},[_vm._v("จำนวนนัดหมาย/เดือน")])]),_c('div',{staticClass:"column"},[_vm._v("50")]),_c('div',{staticClass:"column"},[_vm._v("Unlimited")]),_c('div',{staticClass:"column"},[_vm._v("Unlimited")]),_c('div',{staticClass:"column"},[_vm._v("Unlimited")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row has-icon"},[_c('div',{staticClass:"column topic-column"},[_c('div',{staticClass:"title"},[_c('img',{attrs:{"src":require("@/assets/images/plan/profile-plus.png")}}),_vm._v("Member (Pets) ")]),_c('div',{staticClass:"subtitle"},[_vm._v("จำนวนสัตว์เลี้ยง")])]),_c('div',{staticClass:"column"},[_vm._v("Unlimited")]),_c('div',{staticClass:"column"},[_vm._v("Unlimited")]),_c('div',{staticClass:"column"},[_vm._v("Unlimited")]),_c('div',{staticClass:"column"},[_vm._v("Unlimited")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row has-icon"},[_c('div',{staticClass:"column topic-column"},[_c('div',{staticClass:"title"},[_c('img',{attrs:{"src":require("@/assets/images/plan/printer.png")}}),_vm._v("Printing ")]),_c('div',{staticClass:"subtitle"},[_vm._v("ระบบพิมพ์ใบนัด")])]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row has-icon"},[_c('div',{staticClass:"column topic-column"},[_c('div',{staticClass:"title"},[_c('img',{attrs:{"src":require("@/assets/images/plan/tap-click.png")}}),_vm._v("Self Confirmation ")]),_c('div',{staticClass:"subtitle"},[_vm._v("ระบบยืนยันนัด เลื่อนนัด โดยเจ้าของสัตว์")])]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row has-icon"},[_c('div',{staticClass:"column topic-column"},[_c('div',{staticClass:"title"},[_c('img',{attrs:{"src":require("@/assets/images/plan/tag.png")}}),_vm._v("Appointment Status ")]),_c('div',{staticClass:"subtitle"},[_vm._v("แสดงสถานะการนัด (ยืนยัน, เลื่อน, ยกเลิก,..)")])]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"column topic-column"},[_c('div',{staticClass:"title"},[_vm._v("Pet Owner Support")]),_c('div',{staticClass:"subtitle"},[_vm._v("อำนวยความสะดวกแก่เจ้าของสัตว์")])]),_c('div',{staticClass:"column"},[_vm._v(" ")]),_c('div',{staticClass:"column"},[_vm._v(" ")]),_c('div',{staticClass:"column"},[_vm._v(" ")]),_c('div',{staticClass:"column"},[_vm._v(" ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-row"},[_c('div',{staticClass:"column topic-column"},[_c('div',{staticClass:"title"},[_vm._v("Owner Self Register")]),_c('div',{staticClass:"subtitle"},[_vm._v("ลงทะเบียนได้ด้วยตัวเอง")])]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-row"},[_c('div',{staticClass:"column topic-column"},[_c('div',{staticClass:"title"},[_vm._v("Health Book Online")]),_c('div',{staticClass:"subtitle"},[_vm._v("สามารถดูประวัติสัตว์เลี้ยงของตัวเองได้")])]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"column topic-column"},[_c('div',{staticClass:"title"},[_vm._v("Reminder Channel")]),_c('div',{staticClass:"subtitle"},[_vm._v("ช่องทางการแจ้งเตือนนัดหมาย")])]),_c('div',{staticClass:"column"},[_vm._v(" ")]),_c('div',{staticClass:"column"},[_vm._v(" ")]),_c('div',{staticClass:"column"},[_vm._v(" ")]),_c('div',{staticClass:"column"},[_vm._v(" ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-row"},[_c('div',{staticClass:"column topic-column"},[_c('div',{staticClass:"title"},[_vm._v("SMS")]),_c('div',{staticClass:"subtitle"},[_vm._v("แจ้งเมื่อทำนัด และ 2 วันก่อนถึงวันนัดหมาย")])]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-row"},[_c('div',{staticClass:"column topic-column"},[_c('div',{staticClass:"title"},[_vm._v("Social Network")]),_c('div',{staticClass:"subtitle"},[_vm._v("LINE, Messenger, Webpush")])]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon icon-close",attrs:{"src":require("@/assets/images/plan/close.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"column topic-column"},[_c('div',{staticClass:"title"},[_vm._v("Hospital Support")]),_c('div',{staticClass:"subtitle"},[_vm._v("อำนวยความสะดวกแก่สถานพยาบาล")])]),_c('div',{staticClass:"column"},[_vm._v(" ")]),_c('div',{staticClass:"column"},[_vm._v(" ")]),_c('div',{staticClass:"column"},[_vm._v(" ")]),_c('div',{staticClass:"column"},[_vm._v(" ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-row"},[_c('div',{staticClass:"column topic-column"},[_c('div',{staticClass:"title"},[_vm._v("Calendar")]),_c('div',{staticClass:"subtitle"},[_vm._v("ปฏิทินแสดงนัดหมาย")])]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon icon-close",attrs:{"src":require("@/assets/images/plan/close.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-row"},[_c('div',{staticClass:"column topic-column"},[_c('div',{staticClass:"title"},[_vm._v("Smart Pet Bloodbank")]),_c('div',{staticClass:"subtitle"},[_vm._v("ระบบจัดการธนาคารเลือด")])]),_c('div',{staticClass:"column"},[_vm._v("ติดต่อทีมงาน")]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-row"},[_c('div',{staticClass:"column topic-column"},[_c('div',{staticClass:"title"},[_vm._v("Follow up / Feedback")]),_c('div',{staticClass:"subtitle"},[_vm._v("ติดตามการให้บริการ/สอบถามความพึงพอใจ")])]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon icon-close",attrs:{"src":require("@/assets/images/plan/close.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon icon-close",attrs:{"src":require("@/assets/images/plan/close.png")}})]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"icon-wrapper"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle-2.png")}}),_c('div',{staticClass:"coming-soon"},[_vm._v("coming soon")])])]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"icon-wrapper"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle-2.png")}}),_c('div',{staticClass:"coming-soon"},[_vm._v("coming soon")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-row"},[_c('div',{staticClass:"column topic-column"},[_c('div',{staticClass:"title"},[_vm._v("Broadcast")]),_c('div',{staticClass:"subtitle"},[_vm._v("ระบบส่งข้อความแบบกลุ่ม โดยเลือกกลุ่มผู้รับได้")])]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon icon-close",attrs:{"src":require("@/assets/images/plan/close.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon icon-close",attrs:{"src":require("@/assets/images/plan/close.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-row"},[_c('div',{staticClass:"column topic-column"},[_c('div',{staticClass:"title"},[_vm._v("Direct Messages")]),_c('div',{staticClass:"subtitle"},[_vm._v("ส่งข้อความโดยตรงถึงเจ้าของสัตว์เลี้ยง")])]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon icon-close",attrs:{"src":require("@/assets/images/plan/close.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon icon-close",attrs:{"src":require("@/assets/images/plan/close.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"column topic-column"},[_c('div',{staticClass:"title"},[_vm._v("Special Feature/Service")]),_c('div',{staticClass:"subtitle"},[_vm._v("บริการเสริมพิเศษ")])]),_c('div',{staticClass:"column"},[_vm._v(" ")]),_c('div',{staticClass:"column"},[_vm._v(" ")]),_c('div',{staticClass:"column"},[_vm._v(" ")]),_c('div',{staticClass:"column"},[_vm._v(" ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-row"},[_c('div',{staticClass:"column topic-column"},[_c('div',{staticClass:"title"},[_vm._v("Email Alert")]),_c('div',{staticClass:"subtitle"},[_vm._v("ระบบแจ้งเตือนความผิดปกติหลังรับบริการ")])]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon icon-close",attrs:{"src":require("@/assets/images/plan/close.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon icon-close",attrs:{"src":require("@/assets/images/plan/close.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon icon-close",attrs:{"src":require("@/assets/images/plan/close.png")}})]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"icon-wrapper"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle-2.png")}}),_c('div',{staticClass:"coming-soon"},[_vm._v("coming soon")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-row"},[_c('div',{staticClass:"column topic-column"},[_c('div',{staticClass:"title"},[_vm._v("Report")]),_c('div',{staticClass:"subtitle"},[_vm._v("สรุปข้อมูล เพื่อการตลาดต่อยอด")])]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon icon-close",attrs:{"src":require("@/assets/images/plan/close.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon icon-close",attrs:{"src":require("@/assets/images/plan/close.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon icon-close",attrs:{"src":require("@/assets/images/plan/close.png")}})]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"icon-wrapper"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle-2.png")}}),_c('div',{staticClass:"coming-soon"},[_vm._v("coming soon")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-row"},[_c('div',{staticClass:"column topic-column"},[_c('div',{staticClass:"title"},[_vm._v("Weekly Report Email")]),_c('div',{staticClass:"subtitle"},[_vm._v("สรุปกิจกรรม/นัดหมาย ฯลฯ ประจำอาทิตย์")])]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon icon-close",attrs:{"src":require("@/assets/images/plan/close.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon icon-close",attrs:{"src":require("@/assets/images/plan/close.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon icon-close",attrs:{"src":require("@/assets/images/plan/close.png")}})]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"icon-wrapper"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle-2.png")}}),_c('div',{staticClass:"coming-soon"},[_vm._v("coming soon")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-row"},[_c('div',{staticClass:"column topic-column"},[_c('div',{staticClass:"title"},[_vm._v("Export Data CSV.")])]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon icon-close",attrs:{"src":require("@/assets/images/plan/close.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon icon-close",attrs:{"src":require("@/assets/images/plan/close.png")}})]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon icon-close",attrs:{"src":require("@/assets/images/plan/close.png")}})]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"icon-wrapper"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle-2.png")}}),_c('div',{staticClass:"coming-soon"},[_vm._v("coming soon")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-row"},[_c('div',{staticClass:"column topic-column"},[_c('div',{staticClass:"title"},[_vm._v("Import Member Data")]),_c('div',{staticClass:"subtitle"},[_vm._v("บริการเพิ่มฐานข้อมูลสมาชิกจากโปรแกรมอื่นๆก่อนเริ่มใช้งาน")])]),_c('div',{staticClass:"column"},[_vm._v("3,000")]),_c('div',{staticClass:"column"},[_vm._v("3,000")]),_c('div',{staticClass:"column closed-line"},[_vm._v("3,000")]),_c('div',{staticClass:"column"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/plan/checked-circle.png")}})])])
}]

export { render, staticRenderFns }