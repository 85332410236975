import bloodProductTypeEnum from '@/constants/bloodbank/blood-product-type-enum'
import targetType from '@/constants/bloodbank/blood-request-target-type'
import utils from '@/utils/bloodbank'
import store from '@/store/index'
export default class {
  constructor (obj) {
    if (obj != null) {
      this.id = obj.id == null ? null : obj.id
      this.bloodRequestProfileId = obj.blood_request_profile == null ? null : obj.blood_request_profile
      this.selectedTargets = obj.selectedTargets == null ? [] : obj.selectedTargets
      this.targetPcv = obj.target_PCV == null ? null : Number(obj.target_PCV)
      this.targetPlasma = obj.target_Plasma == null ? null : Number(obj.target_Plasma)
      this.targetPlatelet = obj.target_Platelet == null ? null : Number(obj.target_Platelet)
      this.selectedBloodProduct = obj.blood_type == null ? bloodProductTypeEnum.FWB : utils.convertBloodTypeServiceValueToEnum(obj.blood_type)
      this.quantity = obj.blood_quantity == null ? '' : Number(obj.blood_quantity)
      this.requester = obj.requester == null ? '' : obj.requester

      if (obj.target_PCV && obj.target_PCV !== 0) {
        this.selectedTargets.push(targetType.PCV)
      }
      if (obj.target_Plasma && obj.target_Plasma !== 0) {
        this.selectedTargets.push(targetType.PLASMA)
      }
      if (obj.target_Platelet && obj.target_Platelet !== 0) {
        this.selectedTargets.push(targetType.PLATELET)
      }
    } else {
      this.id = null
      this.targetPcv = null
      this.targetPlasma = null
      this.targetPlatelet = null
      this.selectedBloodProduct = bloodProductTypeEnum.FWB
      this.quantity = null
      this.selectedTargets = []
      this.requester = ''
    }
  }

  nullOrNumber (val) {
    return isNaN(parseFloat(val)) || isNaN(val) ? null : Number(val)
  }

  toRequestJson () {
    let requestObj = {
      id: this.id,
      target_PCV: this.selectedTargets.indexOf(targetType.PCV) >= 0 ? this.nullOrNumber(this.targetPcv) : null,
      target_Plasma: this.selectedTargets.indexOf(targetType.PLASMA) >= 0 ? this.nullOrNumber(this.targetPlasma) : null,
      target_Platelet: this.selectedTargets.indexOf(targetType.PLATELET) >= 0 ? this.nullOrNumber(this.targetPlatelet) : null,
      blood_quantity: this.nullOrNumber(this.quantity),
      blood_type: utils.convertBloodTypeEnumToServiceValue(this.selectedBloodProduct),
      requester: this.requester,
      hospital: store.state.hospital.profile.id
    }

    return requestObj
  }
}
