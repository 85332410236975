<template>
  <div class="location-picker-container">
    <div class="gg-map" ref="ggMap"></div>
    <img src="@/assets/icons/google-pin.png" alt="pin" class="map-pin">
  </div>
</template>

<script>
export default {
  props: ['initialLat', 'initialLng', 'apiKey', 'lock'],
  mounted() {
    let google = document.createElement('script')
    google.onload = () => {
      this.initMap()
    }
    google.async = true
    google.src = 'https://maps.googleapis.com/maps/api/js?key=' + this.apiKey + '&libraries=places&language=TH'
    this.$el.appendChild(google)
  },
  data() {
    return {
      mapRef: null,
    }
  },
  methods: {
    initMapLock(location=null) {
      this.initMap(null, true, location);
    },
    findLocation(address) {
      this.initMap(address, null);
    },
    initMap(address, isLock=null, location=null) {
      const element = this.$refs.ggMap
      var initialLat = this.initialLat || 13.734
      var initialLng = this.initialLng || 100.538
      initialLat = parseFloat(initialLat)
      initialLng = parseFloat(initialLng)
      if (location) {
        initialLat = location.lat;
        initialLng = location.lng;
      }
      const options = {
        // eslint-disable-next-line no-undef
        center: new google.maps.LatLng(initialLat, initialLng),
        zoom: 14,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false
      }
      // eslint-disable-next-line no-undef
      const map = new google.maps.Map(element, options)
      let _this = this

      if (isLock || this.lock) {
        map.setOptions({draggable: false, zoomControl: false, scrollwheel: false, disableDoubleClickZoom: true});
      }

      map.addListener('zoom_changed', function() {
        _this.$emit('locationChange', {
          lat: map.getCenter().lat(),
          lng: map.getCenter().lng()
        })
      })

      map.addListener('center_changed', function() {
        _this.$emit('locationChange', {
          lat: map.getCenter().lat(),
          lng: map.getCenter().lng()
        })
      })

      // eslint-disable-next-line no-undef
      const geocoder = new google.maps.Geocoder(); 
      let vm = this
      this.initRef = vm.geocoder;
      map.addListener('mouseup', function(){
        getAddress();
      });

      if (address) {
        geocoder.geocode( {'address': address }, function(results, status) {
          // eslint-disable-next-line no-undef
          if (status == google.maps.GeocoderStatus.OK) {
            map.setCenter(results[0].geometry.location);
            getAddress();
          }
        });
      }

      function getAddress() {
        geocoder.geocode({ 'latLng': {lat: map.getCenter().lat(), lng: map.getCenter().lng() } }, function (results, status) {

          var address = "";
          var sub_district = "";
          var district = "";
          var province = "";
          var zip_code = "";

          results[0].address_components.forEach(function(addr) {
            if (addr.types.indexOf("postal_code") > -1) {
              zip_code = addr.long_name;
            } else if (addr.types.indexOf("administrative_area_level_1") > -1) {
              province = addr.long_name;
            } else if (addr.types.indexOf("sublocality_level_1") > -1 || addr.types.indexOf("administrative_area_level_2") > -1) {
              district = addr.long_name;
            } else if (addr.types.indexOf("sublocality_level_2") > -1 || addr.types.indexOf("locality") > -1) {
              sub_district = addr.long_name;
            } else if (!(addr.types.indexOf("country") > -1)) {
              address = `${address} ${addr.long_name}`;
            }
          });

          _this.$emit('placeChange', {
            place: results[0],
            status: status,
            formatted_address: results[0].formatted_address,
            address: address,
            sub_district: sub_district,
            district: district,
            province: province,
            zip_code: zip_code,
          })
        });
      }

      // autocomplete
      var input = document.getElementById('autocomplete-input')
      if (input) {
        // eslint-disable-next-line no-undef
        var autocomplete = new google.maps.places.Autocomplete(input)
        autocomplete.bindTo('bounds', map)
        autocomplete.setFields(['address_components', 'geometry', 'icon', 'name'])


        autocomplete.addListener('place_changed', function() {
          var place = autocomplete.getPlace()
          if (!place.geometry) {
            window.alert("No details available for input: '" + place.name + "'")
            return
          }

          if (place.geometry.viewport) {
            map.fitBounds(place.geometry.viewport)
          } else {
            map.setCenter(place.geometry.location)
            map.setZoom(17)
          }

          /*
          var address = ''
          if (place.address_components) {
            address = [
              (place.address_components[0] && place.address_components[0].short_name || ''),
              (place.address_components[1] && place.address_components[1].short_name || ''),
              (place.address_components[2] && place.address_components[2].short_name || '')
            ].join(' ')
          }
          */
        })



      }
    }
  }
}
</script>

<style lang="scss" scoped>
.location-picker-container {
  width: 100%;
  height: 320px;
  position: relative;
  .gg-map {
    height: 100%;
    width: 100%;
  }
  .map-pin {
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 40px;
  }
}
</style>
