<template>
  <ContentBox class="search-section">
    <h2 class="title">Search</h2>
    <el-row :gutter="20">
      <el-col :md="12">
        <el-input placeholder="Activity name , Product name" size="small"
          v-model="form.search"
          clearable
          @keyup.enter.native="onSearch"
          @clear="onClearSearch"
        >
          <el-button slot="append" icon="el-icon-search" @click="onSearch"></el-button>
        </el-input>
      </el-col>
      <el-col :md="4">
        <el-select
          size="small"
          placeholder="All"
          clearable
          v-model="form.isProduct"
          :style="{ width: '100%' }"
          @change="onSearch"
        >
          <el-option
            v-for="(item, index) in activityTypes"
            :key="index"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-col>
      <el-col :md="8" class="text-right">
        <button class="btn btn-small btn-status-cancel"
          @click="gotoSort">
          เรียงลำดับ
        </button>
        <button class="btn btn-small btn-delete"
          @click="gotoDelete">
          ลบ Activity
        </button>
      </el-col>
    </el-row>
  </ContentBox>
</template>

<script>
import ContentBox from "@/components/container/ContentBox"

export default {
  props: ['search', 'types', 'selectedTypeId', 'isProduct'],
  components: {
    ContentBox
  },
  data() {
    return {
      form: {
        search: this.search,
        typeId: this.selectedTypeId,
        isProduct: this.isProduct,
      },
      activityTypes: [
        { value: true, label: "Activity-Product" },
        { value: false, label: "Activity-Service" },
      ],
    }
  },
  methods: {
    onSearch () {
      this.$emit('search', this.form)
    },
    onClearSearch () {
      this.form.search = ''
      this.$emit('search', this.form)
    },
    gotoDelete () {
      this.$emit('gotoDelete');
    },
    gotoSort () {
      this.$emit('gotoSort');
    },
  }
}
</script>

<style lang="scss" scoped>
.search-section {
  .title {
    margin: 0;
    padding: 0;
    font-size: 16px;
    margin-bottom: 5px;
  }
}

.btn-small {
  padding: 6px 15px;
  font-size: 14px;
  font-weight: normal;
  margin-left: 10px;
}
</style>


