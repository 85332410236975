export default {
  Collect: 0,
  Crossmatch: 1,
  Splitting: 2,
  Transform: 3,
  Reserve: 4,
  Disburse: 5,
  Remove_Move: 6,
  Remove_Expire: 7,
  Received: 8
}
