<template>
  <canvas id="pet_age_chart" height="100px"></canvas>
</template>

<script>
import { barChartGenerator, barChartDecorationDataGenerator } from '../../utils/report'

export default {
  name: "PetAgeChart",
  props: ['pets', 'petType'],
  data() {
    return {
      chartObject: null,
    }
  },
  methods: {
    generateDataSet: function () {
      let dataGenerated = {};
      dataGenerated.data = [
        this.pets.others[this.petType].age['0-2'],
        this.pets.others[this.petType].age['3-5'],
        this.pets.others[this.petType].age['6-9'],
        this.pets.others[this.petType].age['>9'],
        this.pets.others[this.petType].age['unknown'],
      ];
      dataGenerated = barChartDecorationDataGenerator(dataGenerated);
      dataGenerated.label = ['0 - 2', '3 - 5', '6 - 9', '> 9', 'unknown'];
      return dataGenerated;
    },
    generateChart: function () {
      if(this.pets.others)
        this.chartObject = barChartGenerator('pet_age_chart', this.generateDataSet(), this.chartObject)
    },
  },
  watch: {
    'pets.others': function () {
      this.generateChart();
    },
    'petType': function () {
      this.generateChart();
    },
  },
  mounted() {
    this.generateChart();
  },
}
</script>