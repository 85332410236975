<template>
  <div class="pet-timeline" :v-if="timeline">
    <el-timeline v-if="timelineFiltered.length > 0">
        <el-timeline-item></el-timeline-item>
        <el-timeline-item v-for="(t, index) in timelineFiltered" :key="index">
          <el-card>
            <div class="contain">
              <div class="detail">
                <div class="key" style="text-transform: capitalize;">{{t.type}}</div>
                <div class="time">
                  <span>{{ t.detail.appointment.date_time | toThaiDate }}</span>
                  <span>
                    <template v-if="!t.detail.appointment.is_no_time">{{t.detail.appointment.date_time.date_time | toHHMM}}  น.</template>
                    <template v-else>ไม่ระบุเวลา</template>  
                  </span>
                </div>
              </div>
              <div class="detail">
                <div class="pet-info" v-if="showPetInfo">
                  <img class="pet-profile" v-if="t.detail.appointment.pet.image && t.detail.appointment.pet.image.small" :src="t.detail.appointment.pet.image.small">
                  <img class="pet-profile" v-else src="@/assets/icons/icon-camera-plus.png">
                  <div class="detail" style="margin-left: 50px">
                    <div class="value">{{ t.detail.appointment.pet.name }}</div>
                    <div class="key">{{ t.detail.appointment.pet.type.name }}</div>
                  </div>
                </div>
                <div class="key" v-if="t.detail.appointment.hospital">{{ t.detail.appointment.hospital.name }}</div>
                <div class="key" v-if="t.detail.appointment.vet">Veterinary : {{ `${t.detail.appointment.vet.title}${t.detail.appointment.vet.first_name} ${t.detail.appointment.vet.last_name}` }}</div>
                <div class="key" v-else>Veterinary : ไม่ระบุ</div>
                <div class="value">Activities : 
                  <span v-for="(activity,index) in t.detail.appointment.activities" :key="index">
                    {{ activity.name }}<span v-if="index != t.detail.appointment.activities.length-1">, </span>
                  </span>
                </div>
              </div>
              <div :class="`status ${t.detail.appointment.status}`">
                <span>&#9900;</span><div class="detail" v-if="t.detail.appointment">{{ t.detail.appointment.status.replace("_", " ").toUpperCase() }}</div>
              </div>
            </div>
            <el-button class="action-btn b1"
              @click="goApptDetail(t.detail.appointment.id)"
            >ดูรายละเอียด</el-button>
          </el-card>
        </el-timeline-item>
        <el-timeline-item></el-timeline-item>
    </el-timeline>
    <div class="no-item" v-else>No timeline</div>
  </div>
</template>

<script>
export default {
  props: {
    timeline: {
      default: null,
      type: Array
    },
    showPetInfo: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    timelineFiltered: function() {
      return this.timeline.filter(function(t) {
        return t.type == "appointment"
      });
    }
  },
  methods: {
    goApptDetail(apptId) {
      const route = localStorage.getItem('user_type') == 'hospital_admin' ? 'appointment-detail' : 'public-appointment-detail';
      this.$router.push({ name: route, params: { id: apptId }})
    }
  }
}
</script>

<style lang="scss" scoped>
.pet-info {
  vertical-align: middle;
  height: 42px;

  div {
    top: 0;
    width: 200px;

    .detail {
      width: 200px;
      .key, .value {
        display: block;
      }
    }
  }

  img.pet-profile {
    float: left;
    display: inline-block;
    vertical-align: middle;
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 4px;
    border: solid 1px #979797;
  }
}

.no-item {
  font-family: default;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.7px;
  text-align: center;
  color: #8e8e93;
  margin: 72px 0px;
}

.detail {
  text-align: left;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0px;
  }

  .time {
    width: 100%;
    max-width: 269px;
    height: 30px;
    border-radius: 4px;
    color: white;
    background-color: #156f88;

    text-align: center;
    vertical-align: middle;
    line-height: 30px;

    span {
      &:first-child {
        float: left;
        margin-left: 8px;
      }
      &:last-child {
        float: right;
        margin-right: 8px;
      }
    }
  }

  .key {
    font-family: default;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    color: #8e8e93;
  }
  .value {
    font-family: default;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #030303;

    &.small {
      font-family: default;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.19;
      letter-spacing: normal;
      color: #354052;
    }

    &.big {
      font-family: default;
      font-size: 18px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
    }

    &.line {
      border-bottom: 1px solid #e6eaee;
    }

    .sub {
      font-size: 16px;
      font-weight: 0;
    }
  }
}
</style>

<style lang="scss" scoped>
.status {
  line-height: 18px;
  position: absolute;
  top: 12px;
  right: 12px;
  @include mobile {
    top: 0;
    right: 0;
    position: relative;
    float: none;
  }
  display: inline-block;
  // width: 50px;
  span {
    display: inline;
    vertical-align: middle;
    margin-right: 6px;

    font-weight: bold;
    color: #02b0e1;
  }

  .detail {
    display: inline;
    vertical-align: middle;

    padding: 0px 8px 0px 8px;
    height: 18px;
    border-radius: 3px;
    background-color: #02b0e1;

    text-align: center;
    vertical-align: middle;
    line-height: 18px;
    
    font-family: default;
    font-size: 10px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #ffffff;
  }

  &.request_postpone {
    div {
      background: #ff4f4a;
    }
    span {
      color: #ff4f4a;
    }
  }

  &.no_show {
    div {
      background: #ff8800;
    }
    span {
      color: #ff8800;
    }
  }

  &.no_respond {
    div {
      background: #ffc300;
    }
    span {
      color: #ffc300;
    }
  }

  &.confirm {
    div {
      background: #7ed321;
    }
    span {
      color: #7ed321;
    }
  }

  &.done {
    div {
      background: #90d5e8;
    }
    span {
      color: #90d5e8;
    }
  }

  &.postpone {
    div {
      background: #a8aab7;
    }
    span {
      color: #a8aab7;
    }
  }

  &.terminate {
    div {
      background: #d8d8d8;
    }
    span {
      color: #d8d8d8;
    }
  }
}
</style>


<style lang="scss">
.pet-timeline {
  .el-timeline {
    padding: 0px 0px 0px 12px;
    margin: auto;
    overflow: hidden;
  }

  .el-timeline-item__wrapper {
    padding-left: 50px;
  }

  .el-timeline-item__tail {
    left: 3px;
    border-left: 4px solid #E4E7ED;
  }

  .el-timeline-item {
    .el-timeline-item__node--normal {
      left: -12px;
      width: 34px;
      height: 34px;
      background-image: url('../../assets/icons/icon-calendar2.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }

    &:first-child {
      .el-timeline-item__node--normal {
        display: none;
      }
    }

    &:last-child {
      .el-timeline-item__node--normal {
        display: none;
      }
      padding: 0px;
    }

    &:nth-last-child(2) {
      height: 300px;
    }
  }
}
</style>

<style lang="scss">
.pet-timeline {
  .el-card__body {
    padding: 0px;
  }
}
</style>


<style lang="scss" scoped>
.el-button {
  img {
    width: 20px;
    height: 20px;
    object-fit: contain;

    display: inline;
    vertical-align: middle;
  }

  &.side-button {
    width: 100%;
    // margin-top: 5px;
    margin-bottom: 15px;
  }
}

.el-button {
  &.action-btn {
    color: #156f88;
    border-radius: 5px;
    border: none;
    &:not(:hover) {
      background-color: #c2e5f0;
    }

    &.b1 {
      border-radius: 0px;
      width: 100%;
    }
  }
}

.contain {
  padding: 12px;
}
</style>