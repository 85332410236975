let api;

if (!api) {
  import("./apis").then((apis) => {
    api = apis.coreAPI;
  });
}

/**
 * Get pet profile
 * @param {number|string} id
 */
const getAll = () => api.get("/hospital/vet/");

export default { getAll };
