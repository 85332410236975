// doc: https://nhnent.github.io/tui.editor/api/latest/ToastUIEditor.html#ToastUIEditor
export default {
  minHeight: '200px',
  previewStyle: 'vertical',
  useCommandShortcut: true,
  useDefaultHTMLSanitizer: true,
  usageStatistics: false,
  hideModeSwitch: true,
  placeholder: 'type here...',
  initialValue: '',
  initialEditType: 'wysiwyg',
  toolbarItems: [
    'heading',
    'bold',
    'italic',
    'ul',
    'ol',
    'task'
  ]
}
