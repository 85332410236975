import * as vuexHelper from "../helpers/vuexHelper";
import { coreAPI } from "../../services/apis";
import * as endpoints from "../../services/endpoints";
import router from '../../router'

const config = {
  default: {
    api: coreAPI
  },
  state: {
    user: null,
    token: null,
    errorMessage: null,
  },
  getters: {
    user: state => state.user,
    isAuthenticated: state => state.token != null,
  },
  asyncActions: [
    {
      name: "login",
      method: "post",
      url: endpoints.login,
      onSuccess: (result, payload, { commit }) => {
        if (result && result.key) {
          const token = result.key;
          const user = {
            username: payload.data.username,
          };
          const user_type = result.user_type;
          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("token", token);
          localStorage.setItem("user_type", user_type);
          commit('SET_USER', user);
          commit('SET_TOKEN', token);
        }
      },
      onError: (result, payload, { commit }) => {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        commit('SET_USER', null);
        commit('SET_TOKEN', null);
      }
    },
    {
      name: "ownerLogin",
      method: "post",
      url: endpoints.ownerLogin,
      onSuccess: (result, payload, { commit }) => {
        if (result && result.key) {
          const token = result.key;
          const user = {
            username: payload.data.telephone,
            // type: result.user_type,
          };
          const user_type = result.user_type;
          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("token", token);
          localStorage.setItem("user_type", user_type);
          commit('SET_USER', user);
          commit('SET_TOKEN', token);
          // commit('SET_USER_TYPE', user_type);
        }
      },
      onError: (result, payload, { commit }) => {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        commit('SET_USER', null);
        commit('SET_TOKEN', null);
      }
    }
  ],
  actions: {
    logout({ commit }) {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("user_type");
      localStorage.removeItem("currentPlan");
      // commit('owner/SET_PROFILE', null);
      commit('SET_USER', null);
      commit('SET_TOKEN', null);
      commit('plan/UPDATE_CURRENT_PLANT', null, { root: true });
    },
    forbidden({ commit }, data) {
      commit('SET_ERROR_MESSAGE', data.detail);
      router.replace({ name: 'hospitalError403' })
    },
    autoLogin({ commit }) {
      const user = localStorage.getItem("user");
      const token = localStorage.getItem("token");
      const currentPlan = localStorage.getItem("currentPlan");
      if (!token || !user) {
        return;
      }
      commit('SET_USER', JSON.parse(user));
      commit('SET_TOKEN', token);
      commit('plan/UPDATE_CURRENT_PLANT', JSON.parse(currentPlan), { root: true });
    }
  }
};

export default vuexHelper.createStoreModule(config);
