<template>
  <el-timeline>
    <el-timeline-item v-for='(p,i) in donation.getProgressList()' :key='p.title' size='large'
                      :timestamp='p.status.toUpperCase()' placement="top"
                      :icon="p.status === 'finish' ? 'el-icon-check': 'el-icon-time'"
                      :color="p.status === 'finish' ? '#0bbd87': '#ff9e24'">
      <el-card class="timeline">
        <el-col :span='3'>
          <img :src="imgLoader(p.img)" />
        </el-col>
        <el-col :span='19'>
          <h1 style="margin-top: 0; margin-bottom: 0;">{{p.title}}</h1>
        </el-col>
        <el-col :span='2'>
          <el-button type="primary" icon="el-icon-edit" circle @click='btnAction(i)' :disabled="isStepDisabled(i)"></el-button>
        </el-col>
      </el-card>
    </el-timeline-item>
  </el-timeline>
</template>

<script>
  export default {
    name: "BloodDonationTimeline",
    props: ['donation'],
    computed: {

    },

    methods: {
      isStepDisabled(i) {
        return (i==0 || this.donation.getProgressList()[i-1].status !== 'finish') ||
        (this.donation.isCaseClosed() && this.donation.getProgressList()[i].status !== 'finish')
      },
      imgLoader(imgPath) {
        // eslint-disable-next-line no-undef
        return require('@/assets/bloodbank/'+imgPath)
      },
      btnAction(index) {
        this.$emit('action', index)
      }
    }
  }
</script>

<style scoped>
  img {
    transform: translateY(-10%);
  }
</style>
