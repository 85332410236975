import * as vuexHelper from "../helpers/vuexHelper";

import { coreAPI } from "../../services/apis";
import * as endpoints from "../../services/endpoints";

const config = {
  default: {
    api: coreAPI
  },
  state: {
    overview: null,
    all: null
  },
  asyncActions: [
    {
      name: "getOverview",
      method: "get",
      url: endpoints.petRewardOverview,
      setState: "overview"
    },
    {
      name: "listRedeems",
      method: "get",
      url: endpoints.petRewardRedeem,
      setState: "all"
    },
    {
      name: "createRedeem",
      method: "post",
      url: endpoints.petRewardRedeem,
    },
  ]
};

export default vuexHelper.createStoreModule(config);
