<template>
  <div class="pet-health-detail">
    <Subnavbar back="petGeneralInfo" :mini="true">
      <template slot="title">PET HEALTH DETAIL</template>
    </Subnavbar>
    <PetHealthDetailForm :form="form" :standalone="true">
      <template slot="actions">
        <el-form-item class="text-center">
          <el-button
            :loading="submitButtonLoading"
            type="button"
            class="btn btn-primary form-submit-btn"
            @click="onBack">&lt; back</el-button>
          <el-button
            :loading="submitButtonLoading"
            type="button"
            class="btn btn-primary form-submit-btn"
            @click="onSubmit">done</el-button>
        </el-form-item>
      </template>
    </PetHealthDetailForm>
  </div>
</template>

<script>
import { mapState } from "vuex"
import Subnavbar from '@/components/navigation/Subnavbar'
import PetHealthDetailForm from '@/components/pet/PetHealthDetailForm'

export default {
  components: {
    Subnavbar,
    PetHealthDetailForm,
  },
  data() {
    return {
      form: {
        sterilize: null,
        sterilize_date: null,
        microchip: "",
        // microchip_date: "",
        microchips: [""],
        blood_group: null,
        insurance_number: "",
        insurance_numbers: [""],
        drug_allergy_detail: "",
        type_key: null,
        type_key_default: null,
      },
      submitButtonLoading: false,
    }
  },
  computed: {
    ...mapState({
      createForm: state => state.owner.createForm,
      hospitalConnection: state => state.owner.hospitalConnection,
      pet: state => state.owner.pet,
    })
  },
  async mounted() {
    await this.$store.dispatch('ui/setIsFullScreenLoading', true)
    this.form = {
      ...this.form,
      ...this.$store.state.owner.createForm
    };

    if (this.form.type_key != this.form.type_key_default) {
      this.form.blood_group = null;
    }

    if (this.$route.params.id) {
      await this.$store.dispatch('owner/getPet', {
        params: { id: this.$route.params.id },
      }); 

      this.form = {
        ...this.form,
        sterilize: this.$store.state.owner.pet.sterilize,
        sterilize_date: this.$store.state.owner.pet.sterilize_date,
        microchip: this.$store.state.owner.pet.microchip,
        microchips: [""],
        blood_group: this.$store.state.owner.pet.blood_group,
        insurance_number: this.$store.state.owner.pet.insurance_number,
        insurance_numbers: [""],
        drug_allergy_detail: this.$store.state.owner.pet.drug_allergy_detail,
        type_key: this.$store.state.owner.pet.type.key,
        type_key_default: null,
      };
    }
    await this.$store.dispatch('ui/setIsFullScreenLoading', false)
  },
  methods: {
    onBack() {
      this.form.type_key_default = this.form.type_key;
      this.$store.commit('owner/SET_CREATE_FORM', this.form);

      if (this.$route.params.id == null)
      this.$router.push({ name: 'petGeneralInfo' });
      else
      this.$router.push({ 
        name: 'petGeneralInfoEdit', 
        params: { id: this.$route.params.id },
      });
    },
    validate() {
      let message = "";
      switch(true) {
        case this.form.name == "": message = "กรุณากรอกชื่อสัตว์เลี้ยง"; break;
        case this.form.type == null: message = "กรุณาระบุชนิดสัตว์เลี้ยง"; break;
        case this.form.breed == null: message = "กรุณาระบุพันธุ์สัตว์เลี้ยง"; break;
        case this.form.gender == null: message = "กรุณาระบุเพศสัตว์เลี้ยง"; break;
        // case this.form.birthday == null: message = "กรุณาระบุวันเกิดสัตว์เลี้ยง"; break;
        // case this.form.age_year == null || this.form.age_month == null: message = "กรุณาระบุอายุสัตว์เลี้ยง"; break;
        case this.form.age_year == null: this.form.age_year = 0; break;
        case this.form.age_month == null: this.form.age_month = 0; break;
      }

      if (message != "") {
        this.$notify({
          message: message,
          type: "warning"
        })
        return false;
      }
      return true;
    },
    async onSubmit() {
      if (this.validate()) {
        await this.$store.dispatch('ui/setIsFullScreenLoading', true)
        var form = new FormData();
        form.append('microchip', this.form.microchip);
        if (this.form.microchip_date != null)
        form.append('microchip_date', this.form.microchip_date);
        form.append('microchip_note', '');
        form.append('insurance_number', this.form.insurance_number);
        form.append('blood_group', this.form.blood_group);
        form.append('sterilize', this.form.sterilize);
        if (this.form.sterilize_date != null)
        form.append('sterilize_date', this.form.sterilize_date);
        form.append('sterilize_note', '');
        form.append('dead', false);
        if (this.form.dead_date != null)
        form.append('dead_date', this.form.dead_date);
        form.append('dead_detail', '');
        form.append('drug_allergy_detail', this.form.drug_allergy_detail);
        form.append('mark', '');
        form.append('dead_reason', null);
        if (this.$route.params.id == null) {
          if (this.form.image != null)
          form.append('image', this.form.image)
          form.append('breed', this.form.breed);
          form.append('type', this.form.type);
          form.append('name', this.form.name);
          form.append('gender', this.form.gender);
          if (this.form.birthday != null)
          form.append('birthday', this.form.birthday);

          await this.$store.dispatch('owner/createPet', {
            formData: form
          });
          
          // Auto add pet to hospital if user has hospital secret code.
          const secret_code = localStorage.getItem("secret_code");
          if (secret_code !== null) {
            await this.$store.dispatch("owner/getOwnerHospitalConnection", {
              params: { secret_code: secret_code },
            })
            await this.$store.dispatch('owner/addPetToHospital', {
              data: {
                hospital_id: this.hospitalConnection.hospital.id,
                pets_id: [this.pet.id],
              }
            });
          }
        } else {
          await this.$store.dispatch('owner/updatePet', {
            params: { id: this.$route.params.id },
            formData: form
          });
        }
        await this.$store.dispatch('ui/setIsFullScreenLoading', false)
        this.$store.commit('owner/SET_CREATE_FORM', {});
        this.$router.push({ 
          name: 'petProfile', 
          params: { id: this.$store.state.owner.pet.id },
        });
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.pet-health-detail {
  margin-top: 0px;
  padding: 0px 10px 40px 10px;
}
</style>
