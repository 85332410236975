import * as vuexHelper from "../helpers/vuexHelper";

import { coreAPI } from "../../services/apis";
import * as endpoints from "../../services/endpoints";

const config = {
  default: {
    api: coreAPI
  },
  state: {
    all: null
  },
  asyncActions: [
    {
      name: "listRewards",
      method: "get",
      url: endpoints.petReward,
      setState: "all"
    },
    {
      name: "createReward",
      method: "post",
      url: endpoints.petReward,
    },
    {
      name: "updateReward",
      method: "patch",
      url: ({ id }) => `${endpoints.petReward}${id}/`,
    },
    {
      name: "deleteReward",
      method: "delete",
      url: ({ id }) => `${endpoints.petReward}${id}/`,
    },
    {
      name: "addPetPoint",
      method: "post",
      url: `${endpoints.petReward}points/add/`,
    }
  ]
};

export default vuexHelper.createStoreModule(config);
