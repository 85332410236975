import Vue from "vue";
import BeforeCloseCaseDialog from "./BeforeCloseCaseDialog";
import PetBackHomeDialog from "./PetBackHomeDialog";
import BadDebtDialog from "./BadDebtDialog";


export const showBeforeCloseCaseDialog = (propsData = {}) =>
  new Promise((resolve, reject) => {
    const Component = Vue.extend(BeforeCloseCaseDialog);

    const instance = new Component({
      propsData,

      destroyed() {
        document.body.removeChild(this.$el);
      },
    }).$mount();

    document.body.appendChild(instance.$el);

    instance.$on("action", (value) => {
      resolve(value);
    });

    instance.$on("close", () => {
      reject();
    });
  });


export const showPetBackHomeDialog = (propsData = {}) =>
  new Promise((resolve, reject) => {
    const Component = Vue.extend(PetBackHomeDialog);

    const instance = new Component({
      propsData,

      destroyed() {
        document.body.removeChild(this.$el);
      },
    }).$mount();

    document.body.appendChild(instance.$el);

    instance.$on("action", (value) => {
      resolve(value);
    });

    instance.$on("close", () => {
      reject();
    });
  });


export const showBadDebtDialog = (propsData = {}) =>
  new Promise((resolve, reject) => {
    const Component = Vue.extend(BadDebtDialog);

    const instance = new Component({
      propsData,

      destroyed() {
        document.body.removeChild(this.$el);
      },
    }).$mount();

    document.body.appendChild(instance.$el);

    instance.$on("action", (value) => {
      resolve(value);
    });

    instance.$on("close", () => {
      reject();
    });
  });
