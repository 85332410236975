<template>
    <el-card>
        <div class="contain">
            <el-row>
                <el-col class="text-left title" :span="12">Blood group</el-col>
                <el-col class="text-right date" :span="12"><strong>{{ date | toThaiDate }}</strong></el-col>
            </el-row>
            <el-row>
                <el-col class="blood-group" :span="24">{{ item.blood_group }}</el-col>
            </el-row>
            <el-row v-if="item.note">
                <el-col class="note" :span="24">{{ item.note }}</el-col>
            </el-row>
            <el-row v-if="item.file">
                <el-col :span="24">
                    <el-image
                        style="width: 100%; height: auto;"
                        :src="item.file"
                        fit="contain"></el-image>
                </el-col>
            </el-row>
        </div>
    </el-card>
</template>

<script>
export default {
  props: ["date", "item"]
};
</script>

<style lang="scss" scoped>
.contain {
  padding: 12px;
}
.title {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
}
.date {
    color: #156f88;
    font-size: 16px;
}
.blood-group {
    font-size: 18px;
    border-radius: 4px;
    border: 1px solid #d7dae2;
    margin: 14px 0;
    padding: 4px;
}
.note {
    font-size: 14px;
    margin: 14px 0;
}
</style>