<template>
  <div>

    <el-row  v-if="progress_status === 'in_progress' && !after_finish">
      <el-col :span="2">
        <div class="grid" >
            <span class="text-header">Arrive : </span>
        </div>
      </el-col>

      <el-col :span="6">
        <div class="grid" >
        <el-date-picker
          class="date-picker-medical"
          type="date"
          placeholder="Pick a day"
          size="large"
          v-model="startDate"
          :clearable="false"
          @change="handleChange"
        ></el-date-picker>
      </div>
      </el-col>

      <el-col :span="4">
        <div class="grid" >
        <el-time-select
          class="date-picker-medical-time"
          placeholder="Select time"
          size="large"
          format="HH:mm"
          :picker-options="{
            start: '00:00',
            end: '23:59',
            step: '00:01',
          }"
          v-model="startTime"
          :clearable="false"
          @change="handleChange"
        ></el-time-select>
      </div>
      </el-col>

    </el-row>

    <el-row v-if="progress_status === 'done' || after_finish">
      <el-col :span="2">
        <div class="grid" ><span class="text-header">Arrive : </span></div>
      </el-col>
      <el-col :span="6">
        <div class="grid" >
            {{ getStartDate | toThaiDate }} &nbsp;&nbsp; {{ getStartDate | toHHMM }} น.
        </div>
      </el-col>

      <el-col :span="2">
        <div class="grid" ><span class="text-header">Finish : </span></div>
      </el-col>
      <el-col :span="6">
            <div class="grid" >{{ endDate | toThaiDate }}
                &nbsp;&nbsp;
            <span v-if="endDate">{{ endDate | toHHMM }} น.</span>
        </div>
      </el-col>

      <el-col :span="8">
        <div class="grid" >
        <v-select
          v-model="revision"
          label="name"
          :options="revisions"
          :clearSearchOnSelect="true"
          :pushTags="false"
          :filterable="true" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ContentBox from "@/components/container/ContentBox"
import moment from "moment"
import { toThaiDate, toHHMM } from "@/filters/datetime"
import { combineDateTime } from "@/utils/datetime"

export default {
  props: ['datedata',
          'revision_set',
          'watching_revision',
          'progress_status',
          'after_finish'],
  components: {
    ContentBox
  },
  data() {
    return {
      /* startDate: this.datedata.arrived,
      startTime: toHHMM(this.datedata.arrived),
      endDate: this.datedata.finished,
      endTime: (this.datedata.finished && moment.isDate(this.datedata.finished)) ? toHHMM(this.datedata.finished) : null,
      finish: this.datedata.isFinish, */

      startDate: null,
      startTime: null,
      endDate: null,
      endTime:  null,
      finish: false,

      revisions: this.revision_set,
      revision: {}
    }
  },
  computed: {
      getStartDate() {
          console.log("Display : ")

          this.startDate = this.datedata.arrived?  this.datedata.arrived : null

          console.log(this.datedata.arrived)
          return this.startDate
      },



  },
  methods: {
    handleChange() {
      const timeToDateStart = moment(this.startTime, "HH:mm").toDate()
      const newStartDate = combineDateTime(this.startDate, timeToDateStart)
      if (moment.isDate(newStartDate)) {
        this.datedata["arrived"] = newStartDate
      } else {
        this.datedata["arrived"] = null
      }

      const timeToDateEnd = moment(this.endTime, "HH:mm").toDate()
      const newEndDate = combineDateTime(this.endDate, timeToDateEnd)
      if (moment.isDate(newEndDate)) {
        this.datedata["finished"] = newEndDate
      } else {
        this.datedata["finished"] = null
      }

      this.datedata["isFinish"] = this.finish
      if (this.finish && !moment.isDate(this.endDate)) {
        this.datedata["finished"] = new Date()
        this.endDate = new Date()
        this.endTime = toHHMM(new Date())
      } else if (!this.finish) {
        this.datedata["finished"] = null
        this.endDate = null
        this.endTime = null
      }
    },
    setNewRevision() {
      if (this.revisions && this.revisions.length > 0) {
        this.revisions.forEach(item => {
          item.name = (item.revision === 1 && item.revision + "st" || item.revision === 2 && item.revision + "nd" || item.revision === 3 && item.revision + "rd" || item.revision + "th") + " Version : " + (toThaiDate(item.last_updated))
        })
        if (this.watching_revision) {
          this.revision = this.revisions.find(r => r.id === this.watching_revision.id)
        }
      }
    }
  },
  watch: {
    async revision() {
      if (this.revision.revision) {
        this.$emit('change', this.revision)
      }
    },
    progress_status() {
        if (this.revisions) {
          this.revision = this.revisions[this.revisions.length - 1]
        }
    },
    revision_set: {
      handler() {
        this.revisions = this.revision_set
        this.setNewRevision()
      },
      deep: true
    }
  },
  mounted() {
    this.setNewRevision()
  }
}
</script>

<style lang="scss" scoped>
.grid {
   /* grid of display cell */
   height:35px;
   display: table-cell;
   vertical-align: middle;
}

.date-picker-medical {
  width: 150px;
}

.date-picker-medical-time {
  width: 100px;
}
.text-header {
  width: 60px;
  height: 27px;
  white-space: nowrap;
  font-family: Kanit;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.display-end {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 10px 0 -10px;
}
.display-date-end {
  display: flex;
  justify-content: flex-end;
}
.checkbox-double-scale {
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
}
</style>


