<template>
  <canvas id="petOwner_age_chart" height="100px"></canvas>
</template>

<script>
import { barChartGenerator, barChartDecorationDataGenerator } from '../../utils/report'

export default {
  name: "PetOwnerAgeChart",
  props: ['petOwners'],
  methods: {
    generateDataSet: function () {
      let dataGenerated = {};
      dataGenerated.data = [
        this.petOwners.age['0-15'],
        this.petOwners.age['16-20'],
        this.petOwners.age['21-40'],
        this.petOwners.age['41-60'],
        this.petOwners.age['>60'],
        this.petOwners.age['unknown'],
      ];
      dataGenerated = barChartDecorationDataGenerator(dataGenerated);
      dataGenerated.label = ['0 - 15', '16 - 20', '21 - 40', '41 - 60', '> 60', 'unknown'];
      return dataGenerated;
    },
  },
  watch: {
    'petOwners.age': function () {
      if(this.petOwners.age)
        barChartGenerator('petOwner_age_chart', this.generateDataSet())
    },
  },
  mounted() {
    if(this.petOwners.age)
      barChartGenerator('petOwner_age_chart', this.generateDataSet())
  },
}
</script>