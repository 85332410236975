<template>
  <div>
    <Subnavbar>
      <template slot="title">Add to Inventory</template>
      <template slot="actions">
        <button class="btn btn-large btn-default" @click="cancelClick()">Cancel</button>
        <button class="btn btn-large btn-primary" @click="handleSave">
          <i class="fa fa-save"></i>Add to Inventory
        </button>
      </template>
    </Subnavbar>
    <ContentBox style="padding: 20px;">
      <div class="head-title">Add Product to Inventory</div>
      <div class="form">
        <el-row class="row">
          <el-col :span="4">
            <label>Cover Picture</label>
          </el-col>
          <el-col :span="16">
            <div class="remark">แนบรูป เป็น cover card</div>
            <FileUploader
              v-if="!coverImageUrl"
              style="width: 256px; height: 144px; margin: 4px;"
              :multiple="false"
              accept="image/*"
              @onSelect="handleSelectCoverImage"
            />
            <ImageFilePreviewer
              v-if="coverImageUrl"
              style="width: 256px; height: 144px; margin: 4px;"
              :url="coverImageUrl"
              :showDeleteButton="true"
              @delete="handleDeleteCoverImage"
            />
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="4">
            <label class="multi-line">
              Product Name
              <span class="remark">(Trade Name)</span>
            </label>
          </el-col>
          <el-col :span="16">
            <el-input type="text" v-model="form.tradeName" />
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="4">
            <label class="multi-line">
              Generic Name,
              <span>Concentration</span>
            </label>
          </el-col>
          <el-col :span="16">
            <el-input type="text" placeholder="Paracetamal 500 mg" v-model="form.genericName" />
            <small class="remark">*กรณีที่เป็น เวชภัณฑ์</small>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="4">
            <label>Type</label>
          </el-col>
          <el-col :span="16">
            <el-select
              placeholder="Select Product Type"
              clearable
              v-model="form.typeId"
              @clear="handleClearTypeId"
            >
              <el-option
                v-for="type in types"
                :key="type.id"
                :value="type.id"
                :label="`${type.name}`"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="4">
            <label>Unit</label>
          </el-col>
          <el-col :span="16">
            <el-select placeholder="Select Unit" v-model="form.unitId" disabled>
              <el-option
                v-for="unit in units"
                :key="unit.id"
                :value="unit.id"
                :label="`${unit.name}`"
              ></el-option>
            </el-select>
            <small
              class="remark"
              style="display: block; color: #e02020;"
            >เมื่อสร้างแล้วไม่สามารถเปลี่ยน Unit นี้ได้ เนื่องจากเป็นการนับการตัดต่อ 1 กิจกรรม</small>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="4">
            <label>Package size</label>
          </el-col>
          <el-col :span="16">
            <el-input type="text" placeholder="เช่น 100 ml" v-model="form.productContent" />
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="4">
            <label>Barcode</label>
          </el-col>
          <el-col :span="16">
            <el-input type="text" v-model="form.barcode" />
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="4">
            <label>Note</label>
          </el-col>
          <el-col :span="16">
            <el-input type="textarea" :rows="4" v-model="form.note" />
          </el-col>
        </el-row>
        <hr class="seperator" />
        <h1 class="title">Inventory</h1>
        <el-row class="row" :gutter="4">
          <el-col :span="5">
            <label>ยอดยกมา จำนวน</label>
          </el-col>
          <el-col :span="6">
            <el-input-number v-model="form.stock.initAmount" @blur="checkMinus('initAmount')" />
          </el-col>
          <el-col :span="7">
            <label>จำนวนขั้นต่ำสำหรับแจ้งเตือน</label>
          </el-col>
          <el-col :span="6">
            <el-input-number v-model="form.stock.minAmount" @blur="checkMinus('minAmount')" />
          </el-col>
        </el-row>
        <el-row class="row" :gutter="4">
          <el-col :span="5">
            <label>ต้นทุนต่อหน่วย (บาท)</label>
          </el-col>
          <el-col :span="6">
            <el-input-number v-model="form.stock.unitPrice" @blur="checkMinus('unitPrice')" />
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="4">
            <label>อัตราภาษีซื้อ</label>
          </el-col>
          <el-col :span="20">
            <input type="radio" v-model="form.stock.buyVat" :value="-1" @click="cancelRadio('buyVat', -1)" /><label class="radio-text">ไม่มี VAT</label>
            <input type="radio" v-model="form.stock.buyVat" :value="0" @click="cancelRadio('buyVat', 0)" /><label class="radio-text">VAT 0%</label>
            <input type="radio" v-model="form.stock.buyVat" :value="7" @click="cancelRadio('buyVat', 7)" /><label class="radio-text">VAT 7%</label>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="4">
            <label>อัตราภาษีขาย</label>
          </el-col>
          <el-col :span="20">
            <input type="radio" v-model="form.stock.sellVat" :value="-1" @click="cancelRadio('sellVat', -1)" /><label class="radio-text">ไม่มี VAT</label>
            <input type="radio" v-model="form.stock.sellVat" :value="0" @click="cancelRadio('sellVat', 0)" /><label class="radio-text">VAT 0%</label>
            <input type="radio" v-model="form.stock.sellVat" :value="7" @click="cancelRadio('sellVat', 7)" /><label class="radio-text">VAT 7%</label>
          </el-col>
        </el-row>

        <hr class="seperator" />
        <h1 class="title">
          <el-checkbox v-model="form.is_medication_label_use" style="display: inline-block;" />Drug Label Setting
        </h1>
        <div v-show="form.is_medication_label_use">
          <el-row class="row">
            <el-col :span="4">
              <label>วิธีการใช้</label>
            </el-col>
            <el-col :span="16">
              <el-select
                placeholder="Select Medical Use"
                clearable
                v-model="form.medication.medicationUseId"
                @clear="handleClearMedicationUseId"
              >
                <el-option
                  v-for="item in medicationUse"
                  :key="item.id"
                  :value="item.id"
                  :label="`${item.name}`"
                ></el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="4">
              <label>ตำแหน่งที่ใช้</label>
            </el-col>
            <el-col :span="11">
              <el-input type="text" placeholder="รอบๆ แผล" v-model="form.medication.location" />
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="4">
              <label class="multi-line">
                ปริมาณการใช้
                <span>(ต่อครั้ง)</span>
              </label>
            </el-col>
            <el-col :span="12">
              <el-input-number
                type="text"
                v-model="form.medication.dose"
                style="width: 150px; margin-right: 10px;"
              />
              <el-select
                placeholder="Select Unit"
                clearable
                v-model="form.medication.medicationUnitId"
                @clear="handleClearMedicationUnitId"
              >
                <el-option
                  v-for="item in medicationUnits"
                  :key="item.id"
                  :value="item.id"
                  :label="`${item.name}`"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :span="2">
              <label>วันละ</label>
            </el-col>
            <el-col :span="3">
              <el-input v-model="form.medication.taken_times_per_day" type="text" />
            </el-col>
            <el-col :span="2">
              <label style="margin-left: 5px;">ครั้ง</label>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="4">
              <label>เวลา</label>
            </el-col>
            <el-col :span="4">
              <input type="radio" v-model="form.medication.taken_condition" :value="1" @click="cancelRadio('taken_condition', 1)" /><label class="radio-text">ก่อนอาหาร</label>
            </el-col>
            <el-col :span="7">
              <el-input
                type="text"
                :disabled="form.medication.taken_condition !== 1"
                placeholder="อธิบายเพิ่มเติม เช่น 30 นาที"
                v-model="form.medication.taken_condition_suffix_before"
              />
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="4">
              <label>&nbsp;</label>
            </el-col>
            <el-col :span="4">
              <input type="radio" v-model="form.medication.taken_condition" :value="2" @click="cancelRadio('taken_condition', 2)" /><label class="radio-text">หลังอาหาร</label>
            </el-col>
            <el-col :span="7">
              <el-input
                type="text"
                :disabled="form.medication.taken_condition !== 2"
                placeholder="อธิบายเพิ่มเติม เช่น 30 นาที"
                v-model="form.medication.taken_condition_suffix_after"
              />
            </el-col>
          </el-row>

          <el-row class="row">
            <el-col :span="4">
              <label>&nbsp;</label>
            </el-col>
            <el-col :span="4">
              <input type="radio" v-model="form.medication.taken_condition" :value="3" @click="cancelRadio('taken_condition', 3)" /><label class="radio-text">พร้อมอาหาร</label>
            </el-col>
            <el-col :span="7">
              <el-input
                type="text"
                :disabled="form.medication.taken_condition !== 3"
                placeholder="อธิบายเพิ่มเติม เช่น 30 นาที"
                v-model="form.medication.taken_condition_suffix_current"
              />
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="4">
              <label>&nbsp;</label>
            </el-col>
            <el-col :span="16">
              <el-checkbox-group v-model="form.medication.takenfrequency">
                <el-checkbox :label="1" border>เช้า</el-checkbox>
                <el-checkbox :label="2" border>กลางวัน</el-checkbox>
                <el-checkbox :label="3" border>เย็น</el-checkbox>
                <el-checkbox :label="4" border>ก่อนนอน</el-checkbox>
              </el-checkbox-group>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="4">&nbsp;</el-col>
            <el-col :span="3">
              <el-checkbox
                v-model="form.medication.taken_hour_frequency_checked"
                style="line-height: 2.2em;"
              >ทุกๆ</el-checkbox>
            </el-col>
            <el-col :span="3">
              <el-input
                :disabled="!form.medication.taken_hour_frequency_checked"
                type="text"
                size="small"
                v-model="form.medication.taken_hour_frequency"
              />
            </el-col>
            <el-col :span="7">
              <div style="line-height: 2.3em; margin-left: 10px;">ชั่วโมง</div>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="4">&nbsp;</el-col>
            <el-col :span="3">
              <el-checkbox
                :label="5"
                v-model="form.medication.takenfrequency_5_checked"
              >เมื่อมีอาการ</el-checkbox>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="4">&nbsp;</el-col>
            <el-col :span="4">
              <el-checkbox v-model="form.medication.is_external_use">ห้ามรับประทาน</el-checkbox>
            </el-col>
            <el-col :span="3">
              <el-checkbox v-model="form.medication.is_keep_cool">เก็บในตู้เย็น</el-checkbox>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="4">&nbsp;</el-col>
            <el-col :span="4">
              <el-checkbox v-model="form.medication.is_danger">ยาอันตราย</el-checkbox>
            </el-col>
            <el-col :span="3">
              <el-checkbox v-model="form.medication.is_need_shake">เขย่าก่อนใช้</el-checkbox>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="4">
              <label>วัตถุประสงค์</label>
            </el-col>
            <el-col :span="11">
              <el-input
                type="text"
                placeholder="ยาต้านการแข็งตัวของเลือด"
                v-model="form.medication.medication_properties"
              />
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="4">
              <label>เพิ่มเติม</label>
            </el-col>
            <el-col :span="11">
              <el-input type="text" v-model="form.medication.note" />
            </el-col>
          </el-row>
        </div>
      </div>
    </ContentBox>
  </div>
</template>

<script>
import ContentBox from "@/components/container/ContentBox";
import Subnavbar from "@/components/navigation/Subnavbar";
import FileUploader from "@/components/upload/FileUploader";
import ImageFilePreviewer from "@/components/upload/ImageFilePreviewer";

export default {
  components: {
    ContentBox,
    Subnavbar,
    FileUploader,
    ImageFilePreviewer
  },
  data() {
    return {
      isLoading: false,
      coverImageFile: null,
      coverImageUrl: null,
      attachmentFiles: [],
      types: [],
      units: [],
      medicationUse: [],
      medicationUnits: [],
      form: {
        id: null,
        tradeName: "",
        genericName: "",
        typeId: null,
        unitId: null,
        productContent: "",
        barcode: "",
        note: "",
        is_medication_label_use: false,
        stock: {
          amount: 0,
          minAmount: 0,
          unitPrice: 0,
          buyVat: 7,
          sellVat: 7
        },
        medication: {
          medicationUnitId: null,
          medicationUseId: null,
          takenfrequency: [],
          takenfrequency_5_checked: false,
          location: "",
          dose: 0,
          taken_times_per_day: null,
          taken_condition: null,
          taken_condition_suffix_before: "",
          taken_condition_suffix_after: "",
          taken_condition_suffix_current: "",
          taken_hour_frequency_checked: false,
          taken_hour_frequency: "",
          is_external_use: false,
          is_danger: false,
          is_keep_cool: false,
          is_need_shake: false,
          medication_properties: "",
          note: ""
        }
      }
    };
  },
  computed: {},
  methods: {
    checkMinus(state) {
      if (this.form.stock[state] < 0) {
        this.$notify.info({
          title: 'Info',
          message: `${state === 'initAmount' && 'ยอดยกมา' || state === 'minAmount' && 'จำนวนขั้นต่ำสำหรับแจ้งเตือน' || 'ต้นทุนต่อหน่วย'}ไม่สามารถใส่จำนวนติดลบได้`
        });
        return false
      }
    },
    cancelRadio(type, value) {
      if (type === 'taken_condition' && this.form.medication.taken_condition === value) {
        this.form.medication.taken_condition = null
      } else if (type === 'buyVat' && this.form.stock.buyVat === value) {
        this.form.stock.buyVat = null
      } else if (type === 'sellVat' && this.form.stock.sellVat === value) {
        this.form.stock.sellVat = null
      }
    },
    handleSelectCoverImage(files) {
      if (files.length > 0) {
        const self = this;
        const reader = new FileReader();
        this.coverImageFile = files[0];

        reader.onload = function(e) {
          self.coverImageUrl = e.target.result;
        };
        reader.readAsDataURL(files[0]);
      } else {
        this.coverImageFile = null;
        this.coverImageUrl = null;
      }
    },
    handleDeleteCoverImage() {
      this.coverImageUrl = null;
    },
    handleSelectAttachment(files) {
      this.attachmentFiles = [...this.attachmentFiles, ...files];
    },
    handleDeleteAttachment(index) {
      this.attachmentFiles.splice(index, 1);
    },
    handleClearTypeId() {
      this.form.typeId = null;
    },
    handleClearMedicationUseId() {
      this.form.medication.medicationUseId = null;
    },
    handleClearMedicationUnitId() {
      this.form.medication.medicationUnitId = null;
    },
    async handleSave() {
      if (this.form.stock.initAmount >= 0 && this.form.stock.minAmount >= 0 && this.form.stock.unitPrice >= 0) {
        let data = {
          stock: {
            init_amount: this.form.stock.initAmount,
            min_amount: this.form.stock.minAmount,
            unit_price: this.form.stock.unitPrice,
            buy_vat:
              this.form.stock.buyVat === -1 ? null : this.form.stock.buyVat,
            sell_vat:
              this.form.stock.sellVat === -1 ? null : this.form.stock.sellVat
          },
          trade_name: this.form.tradeName,
          product_content: this.form.productContent,
          generic_name: this.form.genericName,
          barcode: this.form.barcode,
          note: this.form.note,
          unit: this.form.unitId,
          type: this.form.typeId,
          is_medication_label_use: this.form.is_medication_label_use,
          medication: {
            unit: this.form.medication.medicationUnitId,
            taken_frequency: this.form.medication.takenfrequency_5_checked
              ? this.form.medication.takenfrequency.concat(5)
              : this.form.medication.takenfrequency,
            medication_use: this.form.medication.medicationUseId,
            dose: this.form.medication.dose,
            taken_times_per_day: this.form.medication.taken_times_per_day,
            taken_condition: this.form.medication.taken_condition,
            location: this.form.medication.location,
            taken_condition_suffix: this.form.medication.taken_condition === 1 ? this.form.medication.taken_condition_suffix_before : (this.form.medication.taken_condition === 2 ? this.form.medication.taken_condition_suffix_after : (this.form.medication.taken_condition === 3 ? this.form.medication.taken_condition_suffix_current : "")),
            medication_caution: this.form.medication.medication_caution,
            medication_properties: this.form.medication.medication_properties,
            taken_hour_frequency: this.form.medication
              .taken_hour_frequency_checked
              ? this.form.medication.taken_hour_frequency
              : "",
            is_external_use: this.form.medication.is_external_use ? this.form.medication.is_external_use : false,
            is_danger: this.form.medication.is_danger ? this.form.medication.is_danger : false,
            is_keep_cool: this.form.medication.is_keep_cool ? this.form.medication.is_keep_cool : false,
            is_need_shake: this.form.medication.is_need_shake ? this.form.medication.is_need_shake : false,
            note: this.form.medication.note
          }
        };
        try {
          await this.$store.dispatch("ui/setIsFullScreenLoading", true);

          if (!this.coverImageFile) {
            data.image = null;
          }
          this.product = await this.$store.dispatch("product/updateProduct", {
            params: { id: this.form.id },
            data
          });

          if (this.coverImageFile) {
            let formData = new FormData();
            formData.append("image", this.coverImageFile);
            await this.$store.dispatch("product/updateProduct", {
              params: { id: this.product.id },
              formData
            });
          }

          this.$router.push({ name: "inventory-list" });
          this.$notify({
            type: "success",
            title: "บันทึกข้อมูลเรียบร้อยแล้ว"
          });
          await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        } catch (error) {
          await this.$store.dispatch("ui/setIsFullScreenLoading", false);
          await this.$store.dispatch("ui/showFetchErrorMsg", error);
        }
      }
    },
    async fetchData() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        this.types = await this.$store.dispatch("product/listTypes", {
          query: { all: true }
        });
        this.units = await this.$store.dispatch("product/listUnits", {
          query: { all: true }
        });
        this.medicationUnits = await this.$store.dispatch("product/listUnits", {
          query: { show_in: "medication", all: true }
        });
        this.medicationUse = await this.$store.dispatch(
          "product/listMedicationUse",
          {
            query: { all: true }
          }
        );
        const id = this.$route.params.id;
        this.product = await this.$store.dispatch("product/getProduct", {
          params: { id }
        });

        if (this.product.image) {
          this.coverImageUrl = this.product.image;
        }

        let taken_frequency = [];
        if (
          this.product.medication &&
          this.product.medication.taken_frequency
        ) {
          taken_frequency = this.product.medication.taken_frequency.map(
            item => item.id
          );
        }

        let taken_condition = null;
        if (
          this.product.medication &&
          this.product.medication.taken_condition
        ) {
          taken_condition = this.product.medication.taken_condition;
        }

        this.product.medication && this.product.medication.taken_condition
          ? this.product.medication.taken_condition
          : "";
        this.form = {
          id: this.product.id,
          tradeName: this.product.trade_name,
          genericName: this.product.generic_name,
          typeId:
            this.product.type && this.product.type.id
              ? this.product.type.id
              : null,
          unitId:
            this.product.unit && this.product.unit.id
              ? this.product.unit.id
              : null,
          productContent: this.product.product_content,
          barcode: this.product.barcode,
          note: this.product.note,
          is_medication_label_use: this.product.is_medication_label_use,
          stock: {
            initAmount:
              this.product.stock && this.product.stock.init_amount
                ? this.product.stock.init_amount
                : null,
            minAmount:
              this.product.stock && this.product.stock.min_amount
                ? this.product.stock.min_amount
                : 0,
            unitPrice:
              this.product.stock && this.product.stock.unit_price
                ? this.product.stock.unit_price
                : 0,
            buyVat:
              this.product.stock && this.product.stock.buy_vat !== null
                ? this.product.stock.buy_vat
                : -1,
            sellVat:
              this.product.stock && this.product.stock.sell_vat !== null
                ? this.product.stock.sell_vat
                : -1
          },
          medication: {
            medicationUnitId:
              this.product.medication &&
              this.product.medication.unit &&
              this.product.medication.unit.id
                ? this.product.medication.unit.id
                : null,
            medicationUseId:
              this.product.medication &&
              this.product.medication.medication_use &&
              this.product.medication.medication_use.id
                ? this.product.medication.medication_use.id
                : null,
            takenfrequency: taken_frequency.filter(item => item !== 5),
            takenfrequency_5_checked: taken_frequency.includes(5),
            location:
              this.product.medication && this.product.medication.location
                ? this.product.medication.location
                : "",
            dose:
              this.product.medication && this.product.medication.dose
                ? this.product.medication.dose
                : "",
            taken_times_per_day:
            this.product.medication && this.product.medication.taken_times_per_day
                ? this.product.medication.taken_times_per_day
                : "",
            taken_condition: taken_condition,
            taken_condition_suffix_before:
              taken_condition == 1
                ? this.product.medication.taken_condition_suffix
                : "",
            taken_condition_suffix_after:
              taken_condition == 2
                ? this.product.medication.taken_condition_suffix
                : "",
            taken_condition_suffix_current:
              taken_condition == 3
                ? this.product.medication.taken_condition_suffix
                : "",
            taken_hour_frequency_checked:
              this.product.medication &&
              this.product.medication.taken_hour_frequency &&
              this.product.medication.taken_hour_frequency !== ""
                ? true
                : false,
            taken_hour_frequency:
              this.product.medication &&
              this.product.medication.taken_hour_frequency
                ? this.product.medication.taken_hour_frequency
                : "",
            is_external_use: this.product.medication && this.product.medication.is_external_use,
            is_danger: this.product.medication && this.product.medication.is_danger,
            is_keep_cool: this.product.medication && this.product.medication.is_keep_cool,
            is_need_shake: this.product.medication && this.product.medication.is_need_shake,
            medication_properties:
              this.product.medication &&
              this.product.medication.medication_properties
                ? this.product.medication.medication_properties
                : "",
            note:
              this.product.medication && this.product.medication.note
                ? this.product.medication.note
                : ""
          }
        };

        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg");
      }
    },
    cancelClick() {
      this.$router.go(-1);
    }
  },
  async created() {
    this.fetchData();
  }
};
</script>

<style lang="scss" scoped>
.page {
  .seperator {
    margin-top: 30px;
    margin-bottom: 20px;
    border-top: none;
    border-bottom: 1px solid #ccc;
  }
}

.form {
  padding: 0 50px;
  .row {
    margin-bottom: 10px;
  }

  .title {
    color: $light-blue;
    font-size: 1.2em;
  }

  label {
    display: inline-block;
    margin-right: 3px;
    line-height: 2.4em;

    &.multi-line {
      line-height: 1.2em;
      span {
        display: block;
      }
    }

    small {
      color: #999;
    }
  }

  .remark {
    font-size: 12px;
    color: #999;
  }

  .attachment-list {
    list-style-type: none;
    padding: 0;
    margin: 10px 0 0 0;

    > li {
      margin: 5px 0;
      padding: 0 10px;
      font-size: 14px;

      .name {
        display: inline-block;
        min-width: 150px;
        padding: 0 10px;
      }

      i {
        color: $light-blue;

        &.fa-times-circle {
          color: #ccc;
          cursor: pointer;

          &:hover {
            color: $red;
          }
        }
      }
    }
  }

  .el-checkbox {
    line-height: 1em;
  }
}
.head-title {
  margin: 30px 0;
  display: flex;
  justify-content: center;
  font-family: Arial;
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
}
.radio-text {
    margin-left: 5px;
    font-size: 14px;
    color: #606266;
    font-weight: 500;
  }
</style>
