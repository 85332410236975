<template>
  <div>
    <table class="appointment-table">
      <thead>
        <tr>
          <th class="status">&nbsp;</th>
          <th class="time">
            Time
            <i class="el-icon-arrow-down el-icon-right"></i>
          </th>
          <th class="pet">
            Pet
            <i class="el-icon-arrow-down el-icon-right"></i>
          </th>
          <th class="owner">
            Owner
            <i class="el-icon-arrow-down el-icon-right"></i>
          </th>
          <th class="activities">
            Activities
            <i class="el-icon-arrow-down el-icon-right"></i>
          </th>
          <th class="action">Mark Done</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="appt in appts" :key="appt.id">
          <td>
            <div
              :class="{ tag: true, 'tag-vertical': true, [appt.status]: true}"
            >{{ appt.status | apptStatus }}</div>
          </td>
          <td>
            <div class="subtitle">
              <template v-if="!appt.is_no_time">{{appt.date_time | toHHMM}}</template>
              <template v-else>ไม่ระบุเวลา</template>
            </div>
          </td>
          <td>
            <template v-if="appt.pet">
              <div class="thumbnail">
                <img v-if="appt.pet.image && appt.pet.image.medium" :src="appt.pet.image.medium" />
              </div>
              <div class="info">
                <div class="name">
                  <router-link
                    :to="{ name:'hospitalPetProfile', params: { id: appt.pet.pet_hospital.id }}"
                  >{{ appt.pet.name }}</router-link>
                </div>
                <div class="subtitle">{{ appt.pet.type.name }}</div>
              </div>
            </template>
          </td>
          <td>
            <div class="thumbnail">
              <img
                v-if="appt.remind_to.image && appt.remind_to.image.medium"
                :src="appt.remind_to.image.medium"
              />
            </div>
            <div class="info">
              <div class="name">
                <router-link
                  :to="{ name:'hospitalPetOwnerProfile', params: { id: appt.remind_to.id }}"
                >{{ `${appt.remind_to.firstname} ${appt.remind_to.lastname}` }}</router-link>
              </div>
              <div
                class="subtitle"
                v-if="appt.remind_to.reminder_phone"
              >Tel. {{ appt.remind_to.reminder_phone}}</div>
            </div>
          </td>
          <td v-if="appt.activities" class="activities-col">
            {{ appt.activities.length }}
            <template v-if="appt.activities.length > 1">Activities</template>
            <template v-else>Activity</template>
            <div class="inline-activities">{{ appt.activities.map(item => item.name).join(', ' )}}</div>
          </td>
          <th>
            <button
              v-if="appt.status !== 'done'"
              class="btn btn-primary btn-tiny"
              @click="$router.push({
                name: 'medicals-markdone-appointment',
                params: { type: medicalType.TYPE_APPOINTMENT, id: appt.id }
              })"
            >
              Mark Done
              <i class="el-icon-arrow-right el-icon-right"></i>
            </button>
            <button v-if="appt.status == 'done'" class="btn btn-tiny btn-done">
              <i class="fa fa-check"></i>
              Done
            </button>
          </th>
        </tr>
      </tbody>
    </table>
    <div class="not-found" v-if="appts.length === 0">
      <img src="@/assets/images/no-result/no-result-01.png" class="responsive-img" />
    </div>
    <el-pagination
      v-if="total > 0"
      class="pagination"
      background
      layout="prev, pager, next"
      :current-page="currentPage"
      :page-size="pageSize"
      :total="total"
      @current-change="(page) => $emit('current-change', page)"
    ></el-pagination>
  </div>
</template>

<script>
import medicalType from '@/constants/medical/medical-type.js'

export default {
  props: {
    appts: {
      type: Array,
      required: true
    },
    pageSize: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      medicalType: medicalType
    }
  }
};
</script>

<style lang="scss" scoped>
.appointment-table {
  width: 100%;
  border-collapse: collapse;

  a {
    color: #333;
    text-decoration: none;
    &:hover {
      color: #02b0e1;
    }
  }

  thead {
    tr {
      border: #e6eaee 1px solid;

      th {
        padding: 5px;
        font-weight: normal;
        color: #7f8fa4;
        background-color: #f5f8fa;
        text-align: left;
        font-size: 0.9em;

        &.status {
          width: 30px;
        }

        &.time {
          width: 80px;
        }

        &.activities {
          width: 250px;
          text-align: center;
        }

        &.action {
          width: 100px;
          text-align: center;
        }
      }
    }
  }

  tbody {
    tr {
      /* cursor: pointer; */
      height: 82px;

      /* &:hover {
        background-color: #efefef;
      } */

      td {
        position: relative;
        padding: 5px;
        font-size: 0.9em;

        .subtitle {
          color: #666;
        }

        .thumbnail {
          display: inline-block;
          width: 50px;
          height: 50px;
          overflow: hidden;
          background: #eee;
          border-radius: 5px;
          margin-right: 10px;

          > img {
            width: 50px;
            height: 50px;
            border-radius: 5px;
          }
        }

        .info {
          display: inline-block;
          width: calc(100% - 65px);
          vertical-align: top;
        }

        .name,
        .subtitle {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &.activities-col {
          text-align: left;

          .inline-activities {
            width: 250px;
            font-size: 0.85em;
            color: #999;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }
}

.btn {
  width: 90px;

  &.btn-done {
    cursor: default;
    color: #666;
    background: #ccc;
    box-shadow: inset 3px 3px 3px -3px rgba(0, 0, 0, 0.6);
    &:hover {
      background: #ccc;
    }
  }
}
.not-found {
  margin: 0 auto;
  max-width: 300px;
  padding: 50px 0;
  text-align: center;
}

.pagination {
  margin-top: 20px;
  text-align: right;
}
</style>


