<template>
  <div>
    <Subnavbar>
      <template slot="title">Activities Setting</template>
      <template slot="actions">
        <el-select placeholder="Activity Group" size="medium"
          clearable
          v-model="searchForm.selectedGroupId"
          @change="handleSelectGroupId"
          @clear="handleClearGroupId"
        >
          <el-option
            v-for="group in groups"
            :key="group.id"
            :value="group.id"
            :label="`${group.name}`"
          ></el-option>
        </el-select>
        <button class="btn btn-large btn-primary"
          @click="$router.push({ name: 'activity-setting' })">
          <i class="fa fa-plus-circle"></i>Create New Activity
        </button>
      </template>
    </Subnavbar>

    <ActivitySearch 
      :search="searchForm.search"
      :isProduct="searchForm.isProduct"
      @search="handleSearch"
      @gotoDelete="gotoDeletePage"
      @gotoSort="gotoSortPage" />

    <ContentBox>
      <template v-if="items && items.results">
        <ActivityTable
          :items="items.results"
          :total="items.count"
          :current-page="currentPage"
          :page-size="pageSize"
          :can-export="currentPlan.export_section.can_export_activity"
          @current-change="pageChange"
          @selectToEdit="handleSelectToEdit"
          @export-data="exportData"
        />
      </template>
    </ContentBox>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ExportService } from "@/services";
import { download } from "@/utils";
import ContentBox from "@/components/container/ContentBox";
import Subnavbar from "@/components/navigation/Subnavbar";
import ActivityTable from "@/components/activity/ActivityTable";
import ActivitySearch from "@/components/activity/ActivitySearch";


export default {
  components: {
    ContentBox,
    Subnavbar,
    ActivityTable,
    ActivitySearch
  },
  data() {
    return {
      groups: [],
      currentPage: 1,
      showAddRewardDialog: false,
      showEditRewardDialog: false,
      editRewardData: null,
      pageSize: 20,
      searchForm: {
        search: '',
        selectedGroupId: '',
        isProduct: undefined,
      }
    };
  },
  computed: {
    ...mapState({
      items: state => state.activity.activities,
      currentPlan: state => state.plan.currentPlan,
    })
  },
  methods: {
    handleSearch(form) {
      let query = { page_size: this.pageSize };
      query.all = false
      this.currentPage = 1
      query['page'] = 1
      if (form.search !== '') {
        query['search'] = form.search
        this.searchForm.search = form.search
      } else {
        this.searchForm.search = ""
      }
      if (this.searchForm.selectedGroupId !== '') {
        query['group'] = this.searchForm.selectedGroupId;
      }
      query['is_product'] = (form.isProduct === false || form.isProduct === true)
                             ? form.isProduct : undefined;

      this.fetchData(query)
    },
    handleSelectGroupId () {
      let query = {}
      query.all = false
      this.currentPage = 1
      query['page'] = 1
      query['search'] = this.searchForm.search
      query['group'] = this.searchForm.selectedGroupId
      query['is_product'] = this.searchForm.isProduct;
      this.fetchData(query)
    },
    handleClearGroupId () {
      this.searchForm.selectedGroupId = ""
      // this.searchForm({})
    },
    handleSelectToEdit(data) {
      if (data.is_product) {
        this.$router.push({
          name: 'activity-setting-product-update',
          params: { id: data.id }
        })
      } else {
        this.$router.push({
          name: 'activity-setting-service-update',
          params: { id: data.id }
        })
      }
    },
    gotoDeletePage() {
      this.$router.push({
        name: 'activity-setting-delete-select',
        query: {
          q: this.searchForm.search ? this.searchForm.search : undefined,
          group: this.searchForm.selectedGroupId ? this.searchForm.selectedGroupId : undefined,
          isProduct: this.searchForm.isProduct ? this.searchForm.isProduct : undefined,
        }
      });
    },
    gotoSortPage() {
      this.$router.push({
        name: 'activity-setting-sort-select',
        query: {
          group: this.searchForm.selectedGroupId ? this.searchForm.selectedGroupId : undefined,
        }
      });
    },
    getQueryString() {
      const urlQuery = this.$route.query;
      let query = { page_size: this.pageSize };
      query.all = false

      if (urlQuery.page) {
        query["page"] = parseInt(urlQuery.page);
        this.currentPage = parseInt(urlQuery.page);
      } else {
        this.currentPage = 1;
      }

      if (urlQuery.search) {
        this.searchForm.search = urlQuery.search
        query["search"] = urlQuery.search
      } 

      if (urlQuery.group) {
        this.searchForm.selectedGroupId = parseInt(urlQuery.group)
        query["group"] = urlQuery.group
      } 

      this.searchForm.isProduct = urlQuery.is_product ? Boolean(urlQuery.is_product) : undefined;
      query['is_product'] = this.searchForm.isProduct;

      return query;
    },
    pageChange(page) {
      let query = this.getQueryString();
      query = { ...query, page };
      this.currentPage = page;

      this.fetchData(query);
    },
    async fetchGroups() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        this.groups = await this.$store.dispatch("activity/listActivityGroups", {
          query: { all: true }
        });
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg");
      }
    },
    async fetchData(query) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        await this.$store.dispatch("activity/listActivitySettingAll", {
          query,
        });
        this.$router.replace({ query: query });
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg");
      }
    },

    async exportData() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)

        const { data, headers } = await ExportService.exportActivities(this.getQueryString());
        download(data, "activities.xlsx", headers)

        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        await this.$store.dispatch("ui/showFetchErrorMsg")
      }
    },
  },
  async created() {
    this.fetchGroups();
    this.fetchData(this.getQueryString());
  }
};
</script>