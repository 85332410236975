<template>
  <div class="page">
    <Subnavbar>
      <template slot="title">{{ title }}</template>
      <template slot="actions">
       
          <!-- <button
            class="btn btn-large btn-default"
            @click="$router.push({ name: 'income-expense-list' })"
          >Back</button> -->
          <button
            v-if="detail && !isOtherTypeDetail()"
            class="btn btn-large btn-status-cancel"
            @click="$router.push({ name: 'income-expense-edit', params: { id: detail.id } })"
          >Edit</button>
          <button
            v-if="detail && !isOtherTypeDetail() && !isRevertDetail()"
            v-show="currentPlan.income_expense_section.can_remove_income_expense"
            class="btn btn-large btn-status-cancel"
            @click="showConfirmDeleteDialog = true"
          >Delete</button>
          <button
            class="btn btn-transparent btn-close"
            @click="$router.go(-1)"
          ><i class="fa fa-times"/></button>
   
      </template>
    </Subnavbar>

    <!-- general -->
    <ContentBox v-if="detail && isGeneralDetail()">
      <div class="form">
        <h1 class="title">{{ title }}</h1>
        <el-row class="row">
          <el-col :span="4">
            <label>Date/time : </label>
          </el-col>
          <el-col :span="5">
            {{ detail.datetime  | toThaiDate }} 
          </el-col>
          <el-col :span="6">
            {{ detail.datetime  | toHHMM }} น.
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="4">
            <label>Type :</label>
          </el-col>
          <el-col :span="20">
            {{ detail.transaction_type.name}}
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="4">
            <label>Detail :</label>
          </el-col>
          <el-col :span="20">
            {{ detail.description || '-' }}
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="4">
            <label>Price :</label>
          </el-col>
          <el-col :span="5">
            {{ detail.unit_price | currency }}
          </el-col>
          <el-col :span="5">
            บาท
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="4">
            <label>Discount :</label>
          </el-col>
          <el-col :span="5">
             {{ detail.discount | currency }}
          </el-col>
          <el-col :span="5">
            บาท
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="4">
            <label>Note :</label>
          </el-col>
          <el-col :span="20">
            {{ detail.note || '-' }}
          </el-col>
        </el-row>
        <el-row class="row" v-if="detail.attachments.length > 0">
          <el-col :span="4">
            <label>Attachment :</label>
          </el-col>
          <el-col :span="20">
           <AttachmentPreviewer
                v-for="(url, index) in detail.attachments"
                :key="index"
                style="float: left; width: 110px; height: 110px; margin: 4px;"
                :url="url.attachment"
            />
          </el-col>
        </el-row>
        <el-row class="text-right">
          <button
            v-if="detail && isOtherTypeDetail()"
            class="btn btn-small btn-status-cancel"
            @click="handleSeeDetailClick()"
          >See Detail</button>
        </el-row>
      </div>
    </ContentBox>

    <!-- product -->
    <ContentBox  v-if="detail && isProductDetail()">
      <div class="form">
        <h1 class="title">{{ title }}</h1>
        <el-row class="row">
          <el-col :span="4">
            <label>Date/time : </label>
          </el-col>
          <el-col :span="5">
            {{ detail.created  | toThaiDate }} 
          </el-col>
          <el-col :span="6">
            {{ detail.created  | toHHMM }} น.
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="4">
            <label>Product : </label>
          </el-col>
          <el-col :span="20">
            {{ detail.product.trade_name }} 
          </el-col>
        </el-row>
        <el-row class="row" >
          <el-col :span="4">
            <label>Amount :</label>
          </el-col>
           <el-col :span="5">
            {{ detail.amount | currency }}
          </el-col>
          <el-col :span="5">
            <template v-if="detail.product.unit">{{ detail.product.unit.name}}</template>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="4">
            <label>Price/unit :</label>
          </el-col>
          <el-col :span="5">
            {{ detail.unit_price | currency }}
          </el-col>
          <el-col :span="5">
            บาท
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="4">
            <label>Discount :</label>
          </el-col>
          <el-col :span="5">
             {{ detail.discount | currency }}
          </el-col>
          <el-col :span="5">
            บาท
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="4">
            <label>Total :</label>
          </el-col>
          <el-col :span="5">
             {{ detail.total | currency }}
          </el-col>
          <el-col :span="5">
            บาท
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="4">
            <label>Note :</label>
          </el-col>
          <el-col :span="20">
            {{ detail.note || '-' }}
          </el-col>
        </el-row>
        <el-row class="row" v-if="detail.attachments.length > 0">
          <el-col :span="4">
            <label>Attachment :</label>
          </el-col>
          <el-col :span="20">
           <AttachmentPreviewer
                v-for="(url, index) in detail.attachments"
                :key="index"
                style="float: left; width: 110px; height: 110px; margin: 4px;"
                :url="url.attachment"
            />
          </el-col>
        </el-row>
      </div>
    </ContentBox>
    <ConfirmDialog
            :visible="showConfirmDeleteDialog"
            @cancel="showConfirmDeleteDialog = false"
            @confirm="handleDelete"
    >
      <template slot="header">
        <h1 class="title">ยืนยันการข้อมูลนี้</h1>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Subnavbar from "@/components/navigation/Subnavbar";
import ContentBox from "@/components/container/ContentBox";
import AttachmentPreviewer from "@/components/upload/AttachmentPreviewer"
import ConfirmDialog from "@/components/dialog/ConfirmDialog";
import { IpdService } from "@/services";

export default {
  components: {
    Subnavbar,
    ContentBox,
    AttachmentPreviewer,
    ConfirmDialog
  },
  data() {
    return {
      showConfirmDeleteDialog: false,
      detail: null
    }
  },
  computed: {
    ...mapState({
      currentPlan: state => state.plan.currentPlan,
    }),

    title() {
      if (this.detail) {
        let title = ''
        if (this.detail.product !== null && this.detail.product !== undefined) {
          title = 'Product'
        } else {
          title = 'General'
        }
        if (this.detail.is_expense) {
          title += ' Expense'
        } else {
          title += ' Income'
        }
        return title
      }
      return ''
    }
  },
  methods: {
    isProductDetail() {
      return this.detail && this.detail.product !== null && this.detail.product !== undefined;
    },
    isGeneralDetail() {
      return this.detail && this.detail.product === undefined;
    },
    isOtherTypeDetail() {
      if (this.detail.ref_id && this.detail.ref_content_type && this.detail.ref_content_type.model) {
        if (this.detail.ref_content_type.model == "medicalrecord" 
            || this.detail.ref_content_type.model == "ipd" 
            || this.detail.ref_content_type.model == "deposit" 
            || this.detail.ref_content_type.model == "standalonepos"
            || this.detail.ref_content_type.model == "receipt"
            || this.detail.ref_content_type.model == "importexportdeclaration"
            || this.detail.ref_content_type.model == "importexportincomeexpense")

            return true
      }

      return false
    },
    isRevertDetail() {
      return this.detail.transaction_type 
              && this.detail.transaction_type.name.toLowerCase() == "revert"
    },
    async getIpdIdFromDepositID(depositID) {
      const {
        data: { result },
      } = await IpdService.getDeposit(depositID);
      return result.ipd.id;
    },
    async handleSeeDetailClick() {
      if (this.detail.ref_id && this.detail.ref_content_type && this.detail.ref_content_type.model) {
        const isExpense = this.detail.is_expense;

        switch (this.detail.ref_content_type.model) {
          case "medicalrecord":
            return this.$router.push({
              name: "medicals-edit-detail",
              params: { id: this.detail.ref_id },
              query: { ref: "incomeExpense" }
            });
          
          case "ipd":
            return this.$router.push({
              name: "ipd-paid",
              params: { id: this.detail.ref_id },
              query: { ref: "incomeExpense" }
            });

          case "deposit":
            const ipdID = await this.getIpdIdFromDepositID(this.detail.ref_id);
            return this.$router.push({
              name: "ipd-deposit",
              params: { id: ipdID },
              query: { ref: "incomeExpense" }
            });

          case "standalonepos":
            return this.$router.push({
              name: "pos-detail",
              params: { id: this.detail.ref_id },
            });

          case "receipt":
            return this.$router.push({
              name: "income-expense-receipt-detail",
              params: { id: this.detail.ref_id },
            });
          
          case "importexportdeclaration":
            if (isExpense) 
              return this.$router.push({
                name: "inventory-add-detail",
                params: { id: this.detail.ref_id },
              });
            else
              return this.$router.push({
                name: "inventory-remove-detail",
                params: { id: this.detail.ref_id },
              });
          
          case "importexportincomeexpense":
            if (isExpense) 
              return this.$router.push({
                name: "income-expense-expense-detail",
                params: { id: this.detail.ref_id },
              });
            else
              return this.$router.push({
                name: "income-expense-income-detail",
                params: { id: this.detail.ref_id },
              });

          default:
            return this.$router.push({
              name: "income-expense-detail",
              params: { id: this.detail.id },
            });
        }
      }
    },
    async handleDelete () {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        this.detail = await this.$store.dispatch("incomeExpense/deleteIncomeExpense", {
          params: { id: this.detail.id }
        });
        this.showConfirmDeleteDialog = false;
        this.$router.push({name: 'income-expense-list' })
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorMsg", error)
      }
    }
  },
  async created() {
    if (this.$route.params.id != undefined) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        this.detail = await this.$store.dispatch("incomeExpense/getIncomeExpense", {
          params: { id: this.$route.params.id }
        });
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
  .seperator {
    margin-top: 30px;
    margin-bottom: 20px;
    border-top: none;
    border-bottom: 1px solid #ccc;
  }
}

.form {
  padding: 0 50px;
  .row {
    margin-bottom: 20px;
  }

  .title {
    color: $light-blue;
    font-size: 1.2em;
  }

  label {
    display: inline-block;
    margin-right: 3px;
    margin-left: 5px;
    small {
      color: #999;
    }
  }

  .remark {
    font-size: 12px;
    color: #999;
  }

  .attachment-list {
    list-style-type: none;
    padding: 0;
    margin: 10px 0 0 0;

    > li {
      margin: 5px 0;
      padding: 0 10px;
      font-size: 14px;

      .name {
        display: inline-block;
        min-width: 150px;
        padding: 0 10px;
      }

      i {
        color: $light-blue;

        &.fa-times-circle {
          color: #ccc;
          cursor: pointer;

          &:hover {
            color: $red;
          }
        }
      }
    }
  }

 
  .el-radio {
    display: inline-block;
    margin-right: 20px;
  }

  .el-checkbox {
    line-height: 1em;
  }
}
</style>
