<script>
export default {
  render() {
    return (
      <div>
        <el-row
          type="flex"
          gutter={16}
          align="middle"
          style={{ flexWrap: "wrap" }}
        >
          {this.$slots.default && (
            <el-col>
              <el-row type="flex" justify="end" gutter={16} style={{ flexWrap: "wrap" }}>
                {this.$slots.default.map((vnode, index) => (
                  <el-col key={index} md={8} class={this.$style.trackerCol}>
                    {vnode}
                  </el-col>
                ))}
              </el-row>
            </el-col>
          )}
        </el-row>
      </div>
    );
  },
};
</script>

<style lang="scss" module>
.labelCol {
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
}

.label {
  font-size: 1.125rem;
  font-weight: bold;
}

.trackerCol {
  position: relative;

  & + & {
    margin-top: 0.5rem;

    @media (min-width: 768px) {
      margin-top: 0;
    }
  }

  &:not(:first-child)::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    transform: translateX(-50%);
    width: 1px;
    background-color: #979797;
  }
}
</style>
