<script>
export default {
  props: {
    dataId: {
      type: String,
      default: "",
    },

    type: {
      type: String,
      default: "",
      validator: (value) => !value || ["pos", "opd", "ipd"].includes(value),
    },
  },

  data() {
    return {
      visible: false,
    };
  },

  mounted() {
    this.visible = true;
  },

  methods: {
    emit(value) {
      this.$emit("action", value);

      this.visible = false;
    },

    handleClose() {
      this.$emit("close");

      this.visible = false;
    },

    handleClosed() {
      this.$destroy();
    },
  },

  render() {
    const buttons = (() => {
      const cancelButton = (
        <el-button onClick={this.handleClose}>Cancel</el-button>
      );

      const voidButton = (
        <el-button
          type="primary"
          onClick={() => {
            this.emit("void");
          }}
        >
          Void
        </el-button>
      );

      const voidAndEditButton = (
        <el-button
          type="primary"
          onClick={() => {
            this.emit("void_and_edit");
          }}
        >
          Void &amp; Edit
        </el-button>
      );

      const confirmButton = (
        <el-button
          type="primary"
          onClick={() => {
            this.emit("confirm");
          }}
        >
          Confirm
        </el-button>
      );

      switch (this.type) {
        case "pos":
        case "opd":
          return [cancelButton, voidButton, voidAndEditButton];

        default:
          return [cancelButton, confirmButton];
      }
    })();

    const bottomText = (() => {
      const typeText = (() => {
        switch (this.type) {
          case "pos":
            return "POS";

          case "opd":
            return "OPD";
        }
      })();

      return `แล้วสามารถแก้ไข ${typeText} และสร้าง Receipt ใหม่ได้`;
    })();

    return (
      <el-dialog
        title="ยืนยันการยกเลิกใบเสร็จ (Void Receipt)"
        center
        visible={this.visible}
        onClose={this.handleClose}
        onClosed={this.handleClosed}
      >
        <p style={{ margin: "0", textAlign: "center" }}>
          ยกเลิก (void)
          <br />
          Receipt ID {this.dataId}
          {["pos", "opd"].includes(this.type) && [<br />, bottomText]}
        </p>

        <el-row
          slot="footer"
          type="flex"
          gutter={16}
          justify="center"
          style={{ flexWrap: "wrap" }}
        >
          {buttons.map((vnode) => (
            <el-col span={null}>{vnode}</el-col>
          ))}
        </el-row>
      </el-dialog>
    );
  },
};
</script>
