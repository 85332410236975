<script>
export default {
  props: {
    case: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      visible: false,
    };
  },

  mounted() {
    this.visible = true;
  },

  methods: {
    handleClose() {
      this.$emit("close");
      this.visible = false;
    },

    handleClosed() {
      this.$destroy();
    },

    emit(value) {
      this.$emit("action", value);

      this.visible = false;
    },

    handleCloseButtonClick() {
      this.visible = false;
    },
  },

  render() {
    const buttons = (() => {
      const cancelButton = (
        <el-button
          class={[
            this.$style.button,
          ]}
          onClick={() => {
            this.visible = false;
          }}
        >
          ยกเลิก 
        </el-button>
      );

      const confirmButton = (
        <el-button
          type="primary"
          class={[
            this.$style.button,
          ]}
          onClick={() => {
            this.emit("confirm");
          }}
        >
          ยืนยันปิดเคสชำระเงิน 
        </el-button>
      );

      return [
        cancelButton,
        confirmButton,
      ]
    })();

    const renderHeader = (() => {

      return <div 
        style={{ 
          margin: "0.5rem 0",
          fontWeight: "bold",
          color: "#020202",
          fontSize: "20px"
        }}>มีเคสที่จะไม่ถูกส่งไปชำระเงิน</div>
    });

    const renderTotal = () => (
      <div style={{ marginTop: "1rem" }}>
        <div style={{ marginBottom: "0.5rem" }}>
          <div style={{ textAlign: "center", padding: "0 16px" }}>
            มี {this.case} เคสที่ไม่อยู่ในสถานะ For payment <br />ซึ่งจะไม่ถูกส่งไปชำระเงินในครั้งนี้ ยอดรวมทั้งสิ้น
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              padding: "1rem",
              border: "1px solid #979797",
              fontSize: "1.5rem",
              fontWeight: "bold",
            }}
          >
            {this.total.toLocaleString("th-TH", {
              style: "currency",
              currency: "THB",
            })}
          </div>
        </div>
      </div>
    );

    return (
      <el-dialog
        center
        visible={this.visible}
        onClose={this.handleClose}
        onClosed={this.handleClosed}
        class={this.$style.root}
      >
        <template slot="title">{renderHeader()}</template>

        {renderTotal()}

        <el-row
          slot="footer"
          type="flex"
          gutter={16}
          justify="center"
          style={{ flexWrap: "wrap" }}
        >
          {buttons.filter(vnode => vnode != undefined).map((vnode) => (
            <el-col span={8}>{vnode}</el-col>
          ))}
        </el-row>
      </el-dialog>

    );
  },
};
</script>

<style lang="scss" module>
.root {
  font-family: Sarabun;
}

.button {
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 1.2rem;
  padding: 9px;
}
</style>