import PetTypeEnum from '@/constants/bloodbank/pet-type-enum'
import PetSexEnum from '@/constants/bloodbank/pet-sex-enum'
import PetStatusEnum from '@/constants/bloodbank/pet-status-enum'
export default class {
	constructor(
		id = 0,
		imageUrl = null,
		name = null,
		type = null,
		hospitalNumber = null,
		sex = null,
		age = null,
		owner = null,
		latestDonation = null,
		status = null,
		bloodGroup = null,
		petId = null,
		breed = null,
		address = null,
		microchip = null,
		sterilize = false
	) {
		this.id = id
		this.imageUrl = imageUrl
		this.name = name
		this.type = this.getTypeEnum(type)
		this.hospitalNumber = hospitalNumber
		this.sex = this.getSex(sex)
		this.age = age
		this.owners = owner
		this.latestDonation = latestDonation
		this.status = status
		this.bloodGroup = bloodGroup
		this.systemId = petId
		this.breed = breed
		this.address = address
		this.microchip = microchip
		this.sterilize = sterilize
	}

	setPropsFromPetObject(obj) {
		this.id = obj.pet.id
		this.imageUrl = obj.pet.image != null ? obj.pet.image.medium : null
		this.name = obj.pet.name
		this.type = obj.pet.type.id === 1 ? 'DOG' : 'CAT'
		this.hospitalNumber = obj.hn
		this.sex = this.getSex(obj.pet.gender)
		this.age = new Date(obj.pet.birthday)
		this.owners = obj.pet.owners
		this.latestDonation = new Date()
		this.status = PetStatusEnum.AVAILABLE
		this.bloodGroup = obj.pet.blood_group
		this.systemId = obj.id
		this.breed = obj.pet.breed
		this.address = obj.pet.addresses.length > 0 ? obj.pet.addresses[0] : null
		this.microchip = obj.pet.microchip
		this.sterilize = obj.pet.sterilize
		return this
	}

	getTypeEnum(type) {
		if (type === 'Dog') {
			return PetTypeEnum.DOG
		} else if (type === 'Cat') {
			return PetTypeEnum.CAT
		} else {
			return 'unknown'
		}
	}

	getSex(sex) {
		if (sex === 0) {
			return PetSexEnum.FEMALE
		} else {
			return PetSexEnum.MALE
		}
	}

	cloneFrom(obj) {
		// alert('Clone pet')
		this.id = obj.id 
		this.imageUrl = obj.imageUrl 
		this.name = obj.name
		this.type = obj.type
		this.hospitalNumber 
		this.sex = obj.sex 
		this.age = obj.age 
		this.owners = obj.owners 
		this.latestDonation = obj.latestDonation 
		this.status = obj.status 
		this.bloodGroup = obj.bloodGroup 
		this.systemId = obj.systemId 
		this.breed = obj.breed 
		this.address = obj.address 
		this.microchip = obj.microchip 
		this.sterilize = obj.sterilize
	}
}
