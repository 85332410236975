<template>
  <div class="pet-profile">
    <PlainNavbar/>
    <el-row v-if="currentUserType == null">
      <el-col class="text-center" :span="24" style="margin-bottom: 8px;">
        <el-button class="btn btn-outline" type="primary" plain @click="$router.push({name: 'petOwnerLogin'})">Owner Log In</el-button>
      </el-col>
    </el-row>

    <div class="el-row" v-if="profile" style="background-color: #f0f2f5;min-height: 100vh;padding-top: 16px;">
      <div class="el-col el-col-24 el-col-md-8">
        <div class="form-block">
          <PetDetailPublic :pet="profile" :showProfile="isShowProfile" />
          <PetDetailOwnerPublic :pet="profile" :showProfile="isShowProfile"/>
          <div class="panel-block">
            <el-button class="action-btn" type="default" @click="isShowProfile = !isShowProfile" style="width: 100%; margin-top: 10px; margin-bottom: 15px;">
              {{ isShowProfile ? 'Hide' : 'Show' }} Profile
            </el-button>
          </div>
        </div>
      </div>
      <div class="el-col el-col-24 el-col-md-16 content-padding" style="height: 100%">
        <div class="panel-block">
          <el-tabs v-model="activeName" :stretch="isMobile()" :class="isHospitalConnected ? 'hide-tab' : ''">
            <el-tab-pane 
              label="Appointment"
              name="appointment"
              style="margin-bottom: -40px;">
              <PetAppointmentTimeline
                viewer="public"
                viewIn="petPage"
                type="active"
                :petHospitalId="profile.code" 
                v-if="isAppointmentLoaded && profile"
              />
              <el-collapse class="appointment-history-collapse" style="margin-bottom: 60px">
                <el-collapse-item name="1">
                  <template slot="title">
                    <div class="appointment-history-collapse-title">ประวัติการนัดหมาย</div>
                  </template>
                  <PetAppointmentTimeline
                    viewer="public"
                    viewIn="petPage"
                    :petHospitalId="profile.code" 
                    v-if="isAppointmentLoaded && profile"
                  />
                </el-collapse-item>
              </el-collapse>
            </el-tab-pane>
            <el-tab-pane
              label="HealthBook"
              name="healthBook"
            >
              <h2 class="hidden-sm-and-down">Health History</h2>
              <PetFinalDiagnosisTimeline
                v-if="profile && ishealthBookLoaded"
                viewer="public"
                title="Health History"
                :petId="profile.code"
              />
              <h2 class="hidden-sm-and-down">Blood</h2>
              <PetBloodTimeline
                v-if="profile && ishealthBookLoaded"
                viewer="public"
                title="Blood"
                :petId="profile.code"
              />
              <h2>Vaccine Book</h2>
              <PetHealthBookTimeline
                v-if="profile && ishealthBookLoaded"
                viewer="public"
                :petId="profile.code" />
            </el-tab-pane>
            <el-tab-pane
              label="Redeem"
              name="reward"
              style="border-radius: 4px;border: solid 1px #e6eaee;">
              <PetRewardTimeline
                viewer="public"
                :petID="profile.code" 
                v-if="isRewardLoaded && profile" />
            </el-tab-pane>
            <el-tab-pane
              label="Insurance"
              name="insurance">
              <PetInsuranceTimeline
                viewer="public"
                :petID="profile.code" 
                v-if="isInsuranceLoaded && profile" />
            </el-tab-pane>
            <el-tab-pane
              label="Message"
              name="message">
              <PetMessageTimeline
                :isPublic="true"
                :petHospitalId="profile.code" 
                v-if="isMessageLoaded && profile" />
          </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <el-row class="no-item" v-if="!profile">
        {{ message }}
    </el-row>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { currentUserType } from '@/utils'
import PlainNavbar from "@/components/navigation/PlainNavbar"
import PetDetailPublic from '@/components/pet/PetDetailPublic'
import PetDetailOwnerPublic from '@/components/pet/PetDetailOwnerPublic'
import PetMessageTimeline from "@/components/message/PetMessageTimeline"
import PetAppointmentTimeline from "@/components/appointment/PetAppointmentTimeline"
import PetHealthBookTimeline from "@/components/healthbook/timeline/PetHealthBookTimeline"
import PetFinalDiagnosisTimeline from "@/components/medical/timeline/PetFinalDiagnosisTimeline"
import PetBloodTimeline from "@/components/healthbook/timeline/PetBloodTimeline"
import PetRewardTimeline from "@/components/reward/PetRewardTimeline"
import PetInsuranceTimeline from "@/components/insurance/PetInsuranceTimeline";

export default {
  components: {
    PlainNavbar,
    PetDetailPublic,
    PetDetailOwnerPublic,
    PetMessageTimeline,
    PetAppointmentTimeline,
    PetHealthBookTimeline,
    PetFinalDiagnosisTimeline,
    PetBloodTimeline,
    PetRewardTimeline,
    PetInsuranceTimeline
  },
  data() {
    return {
        message: 'โปรดรอสักครู่..',
        profile: null,
        isShowProfile: false,
        isHospitalConnected: false,
        activeName: "",
        isMessageLoaded: false,
        isAppointmentLoaded: false,
        ishealthBookLoaded: false,
        isRewardLoaded: false,
        isInsuranceLoaded: false,
        currentUserType: currentUserType()
    }
  },
  computed: {
    ...mapState({
      pet: state => state.pet.petpublic,
      timeline: state => state.owner.timeline,
    }),
    sterilizeDate() {
      if (this.profile.sterilize_date == null) return "";
      const arr = this.profile.sterilize_date.split("-");
      const year = parseInt(arr[0]) + 543;
      const month = [ "ม.ค.", "ก.พ.", "มี.ค.", "เม.ษ.", "พ.ค.", "มิ.ย.",
        "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค." ][parseInt(arr[1]-1)];
      return `${parseInt(arr[2])} ${month} ${year.toString().slice(-2)}`;
    },
    birthday() {
      if (this.profile.birthdate == null) return "";
      const arr = this.profile.birthdate.split("-");
      const year = parseInt(arr[0]) + 543;
      const month = [ "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน",
        "กรกฏาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม" ][parseInt(arr[1]-1)];
      return `${parseInt(arr[2])} ${month} ${year}`;
    },
    age() {
      this.calculateAge();
      return `${this.profile.age_year} ปี ${this.profile.age_month} เดือน`;
    }
  },
  async mounted() {
    this.activeName = 'appointment';
    const { tab } = this.$route.query;
    if (tab) this.activeName = tab;
    this.$store.commit('owner/SET_TIMELINE', {});
    await this.$store.dispatch('ui/setIsFullScreenLoading', true)

    try {
        await this.$store.dispatch('pet/getPetProfilePublic', {
        params: { code: this.$route.params.code },
        });

        if (currentUserType() === 'hospital_admin') {
          this.$router.push({ 
            name: 'hospitalPetsSearch',
            query: { 
              isExisting: false,
              search: this.pet.id,
              ownerCurrentPage: 1,
              petCurrentPage: 1
            }
          })

        } else if (currentUserType() === 'pet_owner' && this.pet.is_owner) {
          this.$router.push({ name: 'petProfile', params: { id: this.pet.id }})

        } else {
          this.profile = {
              image: this.pet.image.large,
              name: this.pet.name,
              id: this.pet.id,
              point: this.pet.point,
              code: this.$route.params.code,
              birthdate: this.pet.birthday,
              gender: this.pet.gender,
              sterilize: this.pet.sterilize,
              sterilize_date: this.pet.sterilize_date,
              blood_group: this.pet.blood_group,
              microchip: this.pet.microchip,
              weight: this.pet.weight,
              insurance_number: this.pet.insurance_number,
              drug_allergy_detail: this.pet.drug_allergy_detail,
              owners: this.pet.owners,
              type: this.pet.type, 
              breed: this.pet.breed,
          }
        }
    } catch (error) {
        this.message = 'ไม่พบสัตว์เลี้ยงที่คุณต้องการ'
    }
    await this.$store.dispatch('ui/setIsFullScreenLoading', false)
  },
  methods: {
    OnRequestAppointment() {
      alert("Coming Soon");
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    calculateAge() {
      if (this.profile.birthdate == null) return;
      var diff = new Date(Date.now()-new Date(this.profile.birthdate));
      if (diff >= 0) {
        this.profile.age_year = diff.getYear()-70;
        this.profile.age_month = diff.getMonth();
      }
    }
  },
  watch: {
    activeName (newValue) {
      this.$router.replace({ query: {...this.$route.query, tab: newValue }})
      if (newValue === "message") {
        this.isMessageLoaded = true
      } else if (newValue === "appointment") {
        this.isAppointmentLoaded = true
      } else if (newValue === "healthBook") {
        this.ishealthBookLoaded = true
      } else if (newValue === "reward") {
        this.isRewardLoaded = true
      } else if (newValue === "insurance") {
        this.isInsuranceLoaded = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// .contain {
//   max-width: 320px;
//   margin: 0 auto;
//   padding: 12px;
// }
.form-block {
  @extend %block-container;
  @extend %form-style;

  padding-top: 9px;
  padding-bottom: 20px;
  text-align: center;

  background: none;
  box-shadow: none;
  padding: 0;
  margin: 0 auto;

  max-width: 400px;
  // max-width: 300px;
  // border-radius: 4px;
  // border: solid 1px #e6eaee;
  // background-color: #ffffff;
}
.el-col {
  padding-left: 12px;
  padding-right: 12px;
}
.panel-block {
  //@extend %block-container;
  // @extend %form-style;
  padding: 0px;
  margin-bottom: 10px;

  border: none;
  background: none;
  box-shadow: none;
  
  // padding-bottom: 48px;
  .el-card {
    box-shadow: none;

  }
  @include non-desktop {
    margin: 0 auto;
    max-width: 400px;
  }
}
.footer-block {
  font-size: 14px;
  text-align: center;
  span {
    color: $gray;
  }
  a {
    text-decoration: none;
    color: #000;
  }
}
</style>

<style lang="scss" scoped>
.login-optional {
  .el-form-item__content {
    div {
      width: auto;
      display: inline-block;
      vertical-align: middle;

      .el-checkbox__label, a {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: 0.1px;
        text-align: center;
        color: #8e8e93;
        text-decoration: none;
      }
    }
    div:first-child {
      float: left;
    }
    div:last-child {
      float: right;
    }
  }
  .el-checkbox__inner {
    border-radius: 8px;
  }
}
button.btn-pet-login {
  width: 213px !important;
  font-size: 17px !important;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #e6eaee;
  margin-bottom: 6px;
}

.split {
  // height: 100%;
  // float: left;
  vertical-align: top;
  display: inline-block;
  // position: fixed;
  // z-index: 1;
  top: 0;
  // overflow-x: hidden;
  // padding-top: 20px;

  &.left {
    left: 0;
    width: 120px;
  }

  &.right {
    right: 0;
  }
}

img.cover {
  position: relative;
  width: 100%;
  height: 100px;
  margin-bottom: -70px;
  margin-top: -14px;
}

img.profile {
  width: 128px;
  height: 128px;
  object-fit: cover;
  border-radius: 60px;

  position: relative;
  z-index: 1;
  
  &.large {
    width: 83px;
    height: 83px;
  }
}

img.pet-profile {
  display: inline;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 4px;
  border: solid 1px #979797;
}

img.gender {
  width: 18px;
  height: 18px;
  object-fit: contain;
}


img.pet-type {
  width: 27px;
  height: 27px;
  object-fit: contain;
}

.el-table {
  img {
    display: inline;
    vertical-align: middle;
  }

  .el-button {
    padding: 12px;
    width: 60px;
    height: 40px;
    border-radius: 4px;
    // background-color: #e04e1d;

    // font-family: default;
    // font-size: 14px;
    // font-weight: 900;
    // font-style: normal;
    // font-stretch: normal;
    // line-height: normal;
    // letter-spacing: normal;
    // color: #ffffff;

    &.light-blue-button {
      padding: 0px;
      width: 40px;
      height: 40px;
      border-radius: 4px;
      &:not(:hover) {
        background-color: #c2e5f0;
        border: none;
      }
    }
  }

  .pet-name {
    font-family: default;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #1890ff;
  }
}

.title {
  font-family: default;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #354052;

  margin-bottom: 0px;
}

.subtitle {
  margin-top: 0px;
  margin-bottom: 40px;

  font-family: default;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #7f8fa4;
  .c1 {
    font-weight: normal;
  }
}

.social-info {
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;

  margin-bottom: 7px
}

.el-button {
  &.action-btn {
    color: #156f88;
    border-radius: 5px;
    border: none;
    &:not(:hover) {
      background-color: #c2e5f0;
    }
  }

  &.edit-btn {
    position: relative;
    right: 0;
    margin-top: -24px;
    margin-right: -24px;
    float: right;

    z-index: 1;
  }

  &.connect-btn {
    width: 100%;
    display: block;
    clear: both;

    height: 50px;
    border-radius: 5px;

    font-family: Arial;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: center;
    color: #ffffff;

    margin-left: 0px;
    margin-bottom: 14px;
    // border: solid 2px #e7e7e7;
    // background-color: #0384ff;
  }
}

.el-tabs__nav-wrap::after {
  background-color: none;
}

.el-card__header {
  background-image: linear-gradient(to top, #f4f7fa, #ffffff);
}

.location-picker-container {
  // margin: -20px;
  // margin-top: 0px;
  width: 100%;
  height: 200px;
}

.detail {
  text-align: left;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0px;
  }

  .key {
    font-family: default;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    color: #8e8e93;
  }
  .value {
    font-family: default;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #030303;

    &.small {
      font-family: default;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.19;
      letter-spacing: normal;
      color: #354052;
    }

    &.big {
      font-family: default;
      font-size: 18px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
    }

    &.line {
      border-bottom: 1px solid #e6eaee;
    }

    .sub {
      font-size: 16px;
      font-weight: 0;
    }
  }
}

.card-block {
  .header {
    padding: 12px;
    border-radius: 4px 4px 0px 0px;
    border: solid 1px #e6eaee;
    background-image: linear-gradient(to top, #f4f7fa, #ffffff);

    font-family: default;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #354052;
  }
  .contain {
    padding: 0px;
    border-radius: 0px 0px 4px 4px;
    border: solid 1px #e6eaee;
    background-color: #ffffff;
    .row {
      padding: 12px;
    }
  }
}

.no-item {
  font-family: default;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.7px;
  text-align: center;
  color: #8e8e93;
  margin: 72px 0px;
}

.profile-btn{
  font-family: default;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7f8fa4;

  text-decoration: none;
}
</style>

<style lang="scss">
.pet-profile {
  .content-padding {
    padding: 16px 12px 0px 24px;

    @include mobile {
      padding: 12px 12px 0px 12px;
    }
  }

  .el-tabs__item {
    font-family: default;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8e8e93;
    
    &.is-active {
      color: #000000;
    }
  }

  .el-tabs__active-bar {
    height: 3px;
  }

  .el-tabs__nav-wrap::after {
    background: none;
  }

  .el-card__body {
    padding: 0px;
  }

  .el-card {
    border: none;
    box-shadow: none;
  }
}
</style>

<style lang="scss">
.pet-profile {
  @include mobile {
    .el-tabs__item {
      padding: 10px;

      font-family: default;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #8e8e93;
      
      &.is-active {
        color: #000000;
      }
    }

    .el-tabs__active-bar {
      // margin-left: -5%;
      // width: 55% !important;
      height: 3px;
    }

    .el-tabs__nav-wrap::after {
      background: none;
    }

    .el-table {
      th {
        background-color: #f5f8fa;
      }
    }

    .hide-mobile {
      display: none;
    }
  }

  .hide-tab {
    .el-tabs__header {
      display: none;
    }
  }

  th {
    background: #f5f8fa;
  }

  .el-button--danger.is-disabled {
    background: #cfcfcf;
    border-color: #cfcfcf;
  }

  .btn-request-appointment {
    color: #fff;
    background-color: #02b0e1;

    font-family: default;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    // line-height: 10px;
    letter-spacing: -0.4px;
    text-align: right;
    color: #ffffff;

    padding-top: 8px;
    margin-left: 0px;

    img {
      width: 29px;
      height: 31px;
      object-fit: contain;
      float: left;
      display: inline;
    }
  }
}
</style>

<style lang="scss">
  .appointment-history-collapse {
    border-radius: 5px;
    background-color: rgba(2, 176, 225, 0.19);
    text-align: center;

    .el-collapse-item__header {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #156f88;
    }

    .appointment-history-collapse-title {
      width: 100%;
      text-align: center;
    }

    .el-collapse-item__wrap {
      background-color: #f0f2f5;
    }
  }
</style>

<style lang="scss">
  .el-tabs__nav-wrap.is-scrollable {
    padding: 0 32px;

    .el-tabs__nav-next, .el-tabs__nav-prev {
      font-size: 24px;
      line-height: 56px;
    }
  }
</style>
