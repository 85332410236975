import { render, staticRenderFns } from "./ApptPetTable.vue?vue&type=template&id=5b02d796&scoped=true&"
import script from "./ApptPetTable.vue?vue&type=script&lang=js&"
export * from "./ApptPetTable.vue?vue&type=script&lang=js&"
import style0 from "./ApptPetTable.vue?vue&type=style&index=0&id=5b02d796&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b02d796",
  null
  
)

export default component.exports