import * as vuexHelper from "../helpers/vuexHelper";

import { coreAPI } from "../../services/apis";
import * as endpoints from "../../services/endpoints";

const config = {
  default: {
    api: coreAPI
  },
  state: {
    all: null,
    minimallist: null,
  },
  mutations: {

  },
  getters: {

  },
  asyncActions: [
    {
      name: "listInventories",
      method: "get",
      url: `${endpoints.inventory}`,
      setState: "all"
    },
    {
      name: "inventoryOverview",
      method: "get",
      url: `${endpoints.inventory}overview/`,
    },
    {
      name: "listMinimal",
      method: "get",
      url: `${endpoints.inventory}list-by-datetime/`,
      setState: "minimallist"
    },
    {
      name: "listSummary",
      method: "get",
      url: `${endpoints.inventory}summary/`,
    },
    {
      name: "transactionList",
      method: "get",
      url: ({ id }) => `${endpoints.inventory}${id}/transactions/`
    },
    {
      name: "transactionOverview",
      method: "get",
      url: ({ id }) => `${endpoints.inventory}${id}/transactions/overview/`
    },
    {
      name: 'transactionDetail',
      method: 'get',
      url: ({ id }) => `${endpoints.inventory}transaction/${id}/`
    },
    {
      name: 'transactionAdd',
      method: 'post',
      url: ({ id }) => `${endpoints.inventory}${id}/add/`
    },
    {
      name: 'transactionRemove',
      method: 'post',
      url: ({ id }) => `${endpoints.inventory}${id}/remove/`
    },
    {
      name: 'stockImportExportDetail',
      method: 'get',
      url: ({ id }) => `${endpoints.inventory}importexport/${id}/`
    },
    {
      name: "updateImportExportDetail",
      method: "patch",
      url: ({ id }) => `${endpoints.inventory}importexport/${id}/`,
    },
    {
      name: "voidImportExport",
      method: "post",
      url: ({ id }) => `${endpoints.inventory}importexport/${id}/void/`,
    },
    {
      name: 'stockImport',
      method: 'post',
      url: `${endpoints.inventory}importexport/add/`
    },
    {
      name: 'stockExport',
      method: 'post',
      url: `${endpoints.inventory}importexport/remove/`
    },
    {
      name: "stockAliveImportItem",
      method: "get",
      url: ({ productId }) => `${endpoints.inventory}${productId}/aliveimport/items/`,
    },
    {
      name: "listStockYearly",
      method: "get",
      url: ({ productId }) => `${endpoints.inventory}${productId}/yearly/`,
    },
  ]
};

export default vuexHelper.createStoreModule(config);
