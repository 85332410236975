import bloodAcquireResultEnum from '@/constants/bloodbank/blood-acquire-result'
import store from '@/store/index'
export default class {
	constructor(obj) {
		if (obj != null) {
			this.id = obj.id == null ? '' : obj.id
			this.hospitalId = obj.hospital == null ? null : obj.hospital
			this.sideEffect =
				obj.blood_receive_result_note == null
					? ''
					: obj.blood_receive_result_note
			switch (obj.blood_receive_result) {
				case 0:
					this.bloodAcquireResult = bloodAcquireResultEnum.SUCCESS
					break
				case 1:
					this.bloodAcquireResult = bloodAcquireResultEnum.FAILED
					break
				case 2:
					this.bloodAcquireResult = bloodAcquireResultEnum.UNUSED
					break
				case 3:
					this.bloodAcquireResult = bloodAcquireResultEnum.OTHER
					break
				default:
					this.bloodAcquireResult = bloodAcquireResultEnum.SUCCESS
			}
			this.bloodAcquireResultNote =
				obj.blood_receive_other == null ? '' : obj.blood_receive_other
			this.note = obj.note == null ? '' : obj.note
			this.opdImgSrc = obj.OPD_imageURL == null ? [] : obj.OPD_imageURL
			this.otherImg = obj.etc_imageUrl == null ? [] : obj.etc_imageUrl
			this.approver = obj.recorder == null ? '' : obj.recorder
			this.receiveBloodBagId =
				obj.receive_blood_bag == null ? '' : obj.receive_blood_bag
			this.actualUsedQuantity =
				obj.actual_used_quantity == null ? '' : obj.actual_used_quantity
		} else {
			this.id = null
			this.hospitalId = store.state.hospital.profile.id
			this.sideEffect = null
			this.bloodAcquireResult = bloodAcquireResultEnum.SUCCESS
			this.bloodAcquireResultNote = ''
			this.note = ''
			this.opdImgSrc = []
			this.otherImg = []
			this.approver = ''
			this.receiveBloodBagId = null
			this.actualUsedQuantity = ''
		}
	}

	toRequestJson() {
		let convertedAcquireResult
		switch (this.bloodAcquireResult) {
			case bloodAcquireResultEnum.SUCCESS:
				convertedAcquireResult = 0
				break
			case bloodAcquireResultEnum.FAILED:
				convertedAcquireResult = 1
				break
			case bloodAcquireResultEnum.UNUSED:
				convertedAcquireResult = 2
				break
			case bloodAcquireResultEnum.OTHER:
				convertedAcquireResult = 3
				break
		}
		return {
			id: this.id,
			blood_receive_result_note: this.sideEffect,
			blood_receive_result: convertedAcquireResult,
			blood_receive_other: this.bloodAcquireResultNote,
			note: this.note,
			recorder: this.approver,
			hospital: this.hospitalId,
			receive_blood_bag: this.receiveBloodBagId,
			actual_used_quantity:
				this.actualUsedQuantity === '' ? null : this.actualUsedQuantity
		}
	}

	cloneFrom(obj) {
		this.id = obj.id
		this.hospitalId = obj.hospitalId
		this.sideEffect = obj.sideEffect
		this.bloodAcquireResult = obj.bloodAcquireResult
		this.bloodAcquireResultNote = obj.bloodAcquireResultNote
		this.note = obj.note
		this.opdImgSrc = [...obj.opdImgSrc]
		this.otherImg = [...obj.otherImg]
		this.approver = obj.approver
		this.receiveBloodBagId = obj.receiveBloodBagId
		this.actualUsedQuantity = obj.actualUsedQuantity
	}
}
