<template>
  <div>
    <Subnavbar>
      <template slot="title">Product Setting</template>
      <template slot="actions">
        <button class="btn btn-large btn-primary" 
          @click="$router.push({ name: 'proudct-setting-product-create' })">
          <i class="fa fa-plus-circle"></i>Create New Product
        </button>
      </template>
    </Subnavbar>
    <ProductSearch :types="types" :selectedTypeId="searchForm.selectedTypeId" @search="handleSearch" />
    <ContentBox>
      <template v-if="items && items.results">
        <ProductTable
          :items="items.results"
          :total="items.count"
          :current-page="currentPage"
          :page-size="pageSize"
          :can-export="currentPlan.export_section.can_export_product"
          @current-change="pageChange"
          @selectToEdit="handleSelectToEdit"
          @export-data="exportData"
        />
      </template>
    </ContentBox>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ExportService } from "@/services";
import { download } from "@/utils";
import ContentBox from "@/components/container/ContentBox";
import Subnavbar from "@/components/navigation/Subnavbar";
import ProductTable from "@/components/product/ProductTable";
import ProductSearch from "@/components/product/ProductSearch";


export default {
  components: {
    ContentBox,
    Subnavbar,
    ProductTable,
    ProductSearch
  },
  data() {
    return {
      types: [],
      currentPage: 1,
      showAddRewardDialog: false,
      showEditRewardDialog: false,
      editRewardData: null,
      pageSize: 20,
      searchForm: {
        search: '',
        selectedTypeId: '',
      }
    };
  },
  computed: {
    ...mapState({
      items: state => state.product.all,
      currentPlan: state => state.plan.currentPlan,
    })
  },
  methods: {
    handleSearch(form) {
      let query = { page_size: this.pageSize };
      this.currentPage = 1
      query['page'] = 1
      if (form.search !== '') query['search'] = form.search
      if (form.typeId !== '') query['type__id'] = form.typeId

      this.fetchData(query)
    },
    handleSelectToEdit(data) {
      this.$router.push({
        name: 'proudct-setting-product-update',
        params: { id: data.id }
      });
    },
    getQueryString() {
      let query = { page_size: this.pageSize };

      if (this.$route.query.page) {
        query["page"] = parseInt(this.$route.query.page);
        this.currentPage = parseInt(this.$route.query.page);
      } else {
        this.currentPage = 1;
      }

      if (this.$route.query.search) {
        this.searchForm.search = this.$route.query.search
        query["search"] = this.$route.query.search
      } 

       if (this.$route.query.type__id) {
        this.searchForm.selectedTypeId = parseInt(this.$route.query.type__id)
        query["type__id"] = this.$route.query.type__id
      } 

      return query;
    },
    pageChange(page) {
      let query = this.getQueryString();
      query = { ...query, page };
      query.available = true
      this.currentPage = page;
      this.fetchData(query);
    },
    async fetchTypes() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        this.types = await this.$store.dispatch("product/listTypes", {
          query: { all: true }
        });
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg");
      }
    },
    async fetchData(query) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        await this.$store.dispatch("product/listRealImageProducts", {
          query,
        });

        let urlQuery = { ...query }
        if (query["search"]) urlQuery['search'] = query["search"]
        if (query["type__id"]) urlQuery['type__id'] = query["type__id"]
        if (query["page"]) urlQuery['page'] = query["page"]
        this.$router.replace({ query: urlQuery })

        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg");
      }
    },

    async exportData() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)

        const { data, headers } = await ExportService.exportProducts(this.getQueryString());
        download(data, "products.xlsx", headers)

        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        await this.$store.dispatch("ui/showFetchErrorMsg")
      }
    },
  },
  async created() {
    this.fetchTypes();
    this.fetchData(this.getQueryString());
  }
};
</script>