<template>
  <ContentBox class="appointment-overview" v-if="overview">
    <h2 class="title">Overview</h2>
    <DataLoading v-show="isLoading" class="overview-loading" />
    <el-row>
      <el-col :md="6">
        <div class="date-section">
          <div class="arrow" @click="$emit('changePrevMonth')">
            <i class="el-icon-arrow-left"/>
          </div>
          <div class="date">{{ monthText }}</div>
          <div 
          class="arrow" 
          @click="$emit('changeNextMonth')">
            <i class="el-icon-arrow-right"/>
          </div>
        </div>
      </el-col>
      <el-col :md="6" class="total">
        <div class="data-section">
          <label>Income</label>
          <div class="values">
            <div class="top">{{ overview.income | currency }} THB</div>
          </div>
        </div>
      </el-col>
       <el-col :md="6" class="total">
        <div class="data-section">
          <label>Expense</label>
          <div style="color: #d0021b;" class="values">
            <div class="top">{{ overview.expense | currency }} THB</div>
          </div>
        </div>
      </el-col>
       <el-col :md="6" class="total">
        <div class="data-section">
          <label>Total</label>
          <div class="values">
            <div class="top">{{ overview.total | currency }} THB</div>
          </div>
        </div>
      </el-col>
    </el-row>
  </ContentBox>
</template>

<script>
import ContentBox from "@/components/container/ContentBox"
import DataLoading from '@/components/loading/DataLoading'
import { getThaiMonth } from '@/utils/datetime'

export default {
  props: ['overview', 'currentDate', 'isLoading'],
  components: {
    ContentBox,
    DataLoading
  },
  computed: {
    monthText () {
      if (this.currentDate) {
        const y = this.currentDate.getFullYear()
        const m = this.currentDate.getMonth()
        return getThaiMonth(m) + ' ' + y
      }
      return '-'
    }
  }
}
</script>

<style lang="scss" scoped>
.overview-loading {
  position: absolute;
  margin: 2px;
  z-index: 99;
  background: rgba(0,0,0,.05);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding-top: 46px;
}
.appointment-overview {
  position: relative;

  .title {
    margin: 0;
    padding: 0;
    font-size: 16px;
    margin-bottom: 5px;
  }

  .date-section {
    padding: 10px 10px 10px 0;
    font-size: 20px;

    > div {
      display: inline-block;

      &.date {
        line-height: 1.1em;
        width: calc(100% - 40px);
        text-align: center;
      }

      &.arrow {
        cursor: pointer;
        vertical-align: top;
        cursor: pointer;

        &:hover {
          color: $light-blue
        }

        i {
          width: 20px;
          font-weight: bold;
        }

        &.disabled {
          cursor: default;
          color: #ccc;
          :hover {
            color: #ccc;
          }
        }
      }
    }
  }

  .data-section {
    border-left: 1px solid #ccc;
    padding: 10px 10px 10px;

    label {
      display: inline-block;
      width: 40%;
      font-size: 18px;
      line-height: 1.2em;
      vertical-align: top;
    }

    .values {
      display: inline-block;
      width: 60%;
      text-align: right;

      .minus {
        color: #cc333f;
      }

      .top {
        font-size: 18px;
        line-height: 1em;
        font-weight: bold;
      }
    }
  }
}
</style>

