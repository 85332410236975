//--- Get Data from State ---

const toCurrency = function(num) {
    return (Math.round(num * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

const toNumber = function(num) {
    return (Math.round(num * 100) / 100).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export default {
    GetIpd(state) {
        return state.Ipd
    },
    GetIpdRecords(state) {
        return state.MedicalRecords
    },
    GetPaymentStatus(state) {
        return state.PaymentStatus
    },
    GetOverdueRecords(state) {
        //--- overdue is status of for_payment
        let records = state.MedicalRecords.filter( (el) => {
            return el.receiptStatus == 'for_payment'
        })
        //--- reverse for date to start from old->new ---
        records.reverse()
        return records
    },

    Branches(state) {
        return state.Branches
    },
    BrancheNameList(state) {
        let results = []
        if (state.Branches) {
            results = state.Branches.map(
                (branch) => {
                    let label = ""

                    if (branch.display_name) {
                        label = branch.display_name
                    } else {
                        label = branch.branch_name
                    }

                    return { label: label,
                            value: branch.branch_id }
                })
        }

        return results
    },
    GraphAmountData(state) {
        let datas = Array.from({length: 12}, () => 0 )

        if (state.Graphs) {
            state.Graphs.forEach((obj)=> {
                    const inx =  parseInt(obj.ym.substr(4,2))-1
                    datas[inx] = parseFloat(obj.amount)});
        }
        return datas
    },
    GraphWeightData(state) {
        let datas = Array.from({length: 12}, () => 0 )

        if (state.Graphs) {
            state.Graphs.forEach((obj)=> {
                    const inx =  parseInt(obj.ym.substr(4,2))-1
                    datas[inx] = parseFloat(obj.real_weight)
            });
        }
        return datas
    },
    GraphReceiptData(state) {
        let datas = Array.from({length: 12}, () => 0 )

        if (state.Graphs) {
            state.Graphs.forEach((obj)=> {
                    const inx =  parseInt(obj.ym.substr(4,2))-1
                    datas[inx] = parseFloat(obj.cnt)
            });
        }
        return datas
    },
    BpdTable(state) {
        let results = []
        let i = 0;

        if (state.Tables) {
            results = state.Tables.map(
                (item) => {
                     i++;
                     let productname = ""
                     if (item.usedproduct_id == "1") productname ="ทอง 90%"
                     if (item.usedproduct_id == "2") productname ="ทอง 94.5%"
                     if (item.usedproduct_id == "3") productname ="ทอง 96.5%"
                     if (item.usedproduct_id == "4") productname ="ทอง 99.99%"

                     let label = ""
                     if (item.display_name) {
                        label = item.display_name
                     } else {
                        label = item.branch_name
                     }

                     return { id: i,
                              branch_id:item.branch_id,
                              display_name:label,
                              product_id:productname,
                              receipt_count:toNumber(item.receipt_count),
                              baht_weight:toCurrency(item.baht_weight),
                              real_weight:toCurrency(item.real_weight),
                              amount:toCurrency(item.amount),
                              avg:toCurrency(item.amount/item.receipt_count),
                            }
                })
        }

        return results
    },
    BpdTableSum(state) {
        let results = {
            amount:0,
            weight:0,
            receipt:0,}

            state.Tables.forEach((obj)=> {
                results.amount += parseFloat(obj.amount)
                results.weight += parseFloat(obj.real_weight)
                results.receipt += parseInt(obj.receipt_count)
            })
        return results
    },
    BpdDetailTable(state) {
        let results = []
        let i = 0;

        if (state.DetailTables) {
            results = state.DetailTables.map(
                (item) => {
                     i++;
                     let productname = ""
                     if (item.usedproduct_id == "1") productname ="ทอง 90%"
                     if (item.usedproduct_id == "2") productname ="ทอง 94.5%"
                     if (item.usedproduct_id == "3") productname ="ทอง 96.5%"
                     if (item.usedproduct_id == "4") productname ="ทอง 99.99%"

/*                      let label = ""
                     if (item.display_name) {
                        label = item.display_name
                     } else {
                        label = item.branch_name
                     } */

                     return { id: i,
                              pos_datetime:item.pos_datetime,
                              display_id:item.display_id,
                              transtype:item.transtype,
                              product_id:productname,
                              amount:toCurrency(item.amount),
                              baht_weight:toCurrency(item.baht_weight),
                              real_weight:toCurrency(item.real_weight),

                            }
                })
        }

        return results
    },
}