<template>
  <div class="pet-detail">
    <div class="form-block" v-if="bloodBag">

      <div class="pet-image">
        <img v-if="bloodBag.bloodType === 'FWB' || bloodBag.bloodType === 'SWB'" src="../../../assets/bloodbank/fwb.png">
        <img v-if="bloodBag.bloodType === 'FP' || bloodBag.bloodType === 'FFP'" src="../../../assets/bloodbank/ffp.png">
        <img v-if="bloodBag.bloodType === 'PRBCS' || bloodBag.bloodType === 'PRP'" src="../../../assets/bloodbank/p-rb-cs.png">
      </div>

      <div class="title">
        <img src="../../../assets/bloodbank/shapeDog.png" style="width: 75px; height: 75px;" v-if="petType === 'DOG'">
        <img src="../../../assets/bloodbank/shapeCat.png" style="width: 75px; height: 75px;" v-if="petType === 'CAT'">
        <span>{{ bloodBag.bloodType }}</span>
      </div>

      <div class="subtitle">{{ (bloodBag.quantity * 100 ) / 100 }} ml.</div>

      <at-tag color="success" v-if="bloodBag.status == bloodStatusEnum.AVAILABLE">Available</at-tag>
      <at-tag color="error" v-if="bloodBag.status == bloodStatusEnum.RESERVE">Reserved</at-tag>

      <div v-show="bloodBag && hospital && bloodBag.hospitalId === hospital.id">
        <button class="btn btn-small btn-default" style="margin-left: 0; margin-top: 10px;" @click="onPrintClicked">
          Print
        </button>
      </div>

      <div class="inner-block">
        <div class="detail">
          <div class="key">Pet Name</div>
          <div class="value big">{{ bloodBag.petInfo == null || bloodBag.petInfo.name == null ? '-' : bloodBag.petInfo.name }}</div>
        </div>

        <div class="detail">
          <div class="key">Pet ID</div>
          <div class="value">{{ bloodBag.petInfo == null || bloodBag.petInfo.id === 0 ? '-' : bloodBag.petInfo.id }}</div>
        </div>

        <div class="detail">
          <div class="key">Blood Group</div>
          <div class="value">{{ bloodBag.petInfo == null || bloodBag.petInfo.bloodGroup == null ? bloodBag.bloodGroup : bloodBag.petInfo.bloodGroup }}</div>
        </div>

        <div class="detail">
          <div class="key">PCV</div>
          <div class="value">{{ bloodBag.pcv ? bloodBag.pcv : '-' }}</div>
        </div>

        <div class="divider"></div>

        <div class="detail">
          <div class="key">Blood Bag ID</div>
          <div class="value">{{ bloodBag.id }}</div>
        </div>

        <div class="detail">
          <div class="key">Donation ID</div>
          <div class="value">{{ bloodBag.donationId === 0 || bloodBag.donationId == null ? '-' : bloodBag.donationId }}</div>
        </div>

        <div class="detail">
          <div class="key">Date Collect</div>
          <div class="value">{{ bloodBag.dateCollection | toThaiDate }}</div>
        </div>

        <div class="detail">
          <div class="key">Date Expire</div>
          <div class="value">{{ bloodBag.expiryDate | toThaiDate }}</div>
        </div>
      </div>
    </div>

    <!-- Print Template -->
    <blood-bag-label-template v-if="bloodBag" :bloodBagId="bloodBag.id" :petName="bloodBag.petInfo == null ? '-' : bloodBag.petInfo.name" :petID="bloodBag.petInfo == null ? '-' : bloodBag.petInfo.id" :pcv="bloodBag.pcv" :bloodGroup="bloodBag.bloodGroup" :volumn="bloodBag.quantity" :petType="bloodBag.petType" :bloodProduct="bloodBag.bloodType" :donationID="bloodBag.donationId == null ? '-' : bloodBag.donationId" :dateCollect="bloodBag.dateCollection" :dateExpire="bloodBag.expiryDate" id="printTemplate" style="display:none;">
    </blood-bag-label-template>
  </div>
</template>

<script>
  import bloodStatus from '../../../constants/bloodbank/blood-storage-bag-status'
  import BloodBagLabelTemplate from '../BloodBagLabelTemplate'
  import printJS from 'print-js'
  import moment from 'moment'
  import {mapState} from 'vuex'
  export default {
    name: "BloodInventoryInfo",
    components: {BloodBagLabelTemplate},
    props: ['bloodBag'],
    data () {
      return {
        bloodStatusEnum: bloodStatus,
      }
    },
    methods: {
      onPrintClicked: function () {
        window.moment = moment
        let elem = document.getElementById('printTemplate')
        elem.style.display = 'inline-block'
        printJS({printable: 'printTemplate',
          type: 'html',
          targetStyles: ['*']
        })
        elem.style.display = 'none'
      },
    },
    computed: {
      ...mapState({
        hospital: state => state.hospital.profile,
      }),
      petType: function () {
        return this.bloodBag.petInfo != null && this.bloodBag.petInfo.type !== 'unknown' ? this.bloodBag.petInfo.type.toUpperCase() : this.bloodBag.petType.toUpperCase()
      },
    }
  }
</script>

<style lang="scss" scoped>
  .contain {
    max-width: 320px;
    margin: 0 auto;
    padding: 12px;
  }
  .form-block {
    @extend %block-container;
    @extend %form-style;

    padding: 3px 6px;
    text-align: center;

    .inner-block {
      padding: 10px 6px;
    }
  }
  .panel-block {
    @extend %block-container;
    padding: 0px;
    margin-bottom: 10px;

    border: none;
    background: none;
    box-shadow: none;

    .el-card {
      box-shadow: none;

    }
  }
  .footer-block {
    font-size: 14px;
    text-align: center;
    span {
      color: $gray;
    }
    a {
      text-decoration: none;
      color: #000;
    }
  }

  .detail-image {
    text-align: left;
    vertical-align: middle;
    .image {
      display: inline-block;
    }
    .detail {
      margin-left: 10px;
      display: inline-block;
    }
  }
</style>

<style lang="scss" scoped>
  .divider {
    width: 100%;
    height: 1px;
    background-color: #e6eaee;
    margin-bottom: 6px;
  }

  .pet-image {
    margin-top: 10px;
  }

  .title {
    font-family: default;
    font-size: 60px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #354052;

    margin-bottom: 0;
    margin-top: 0;
  }

  .subtitle {
    margin-top: 0px;
    // margin-bottom: 40px;

    font-family: default;
    font-size: 40px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #7f8fa4;
    .c1 {
      font-weight: normal;
    }
  }

  .el-button {
    &.action-btn {
      color: #156f88;
      border-radius: 5px;
      border: none;
      &:not(:hover) {
        background-color: #c2e5f0;
      }
    }

    &.connect-btn {
      width: 100%;
      display: block;
      clear: both;

      height: 50px;
      border-radius: 5px;

      font-family: Arial;
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      text-align: center;
      color: #ffffff;

      margin-left: 0px;
      margin-bottom: 14px;
      // border: solid 2px #e7e7e7;
      // background-color: #0384ff;
    }
  }

  .el-card__header {
    background-image: linear-gradient(to top, #f4f7fa, #ffffff);
  }

  .detail {
    text-align: left;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0px;
    }

    .key {
      font-family: default;
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.1px;
      color: #8e8e93;
    }
    .value {
      font-family: default;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #030303;

      &.small {
        font-family: default;
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.19;
        letter-spacing: normal;
        color: #354052;
      }

      &.big {
        font-family: default;
        font-size: 18px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000000;
      }

      &.line {
        border-bottom: 1px solid #e6eaee;
      }

      .sub {
        font-size: 16px;
        font-weight: 0;
      }
    }
  }
</style>
