<template>
  <div class="row" style="margin-top:20px; margin-left:20px">
      <div class="col-md-10">
          <at-button type="primary" disabled><i class="icon icon-download"></i> Download .PDF </at-button>
      </div>
      <div class="col-md-10 col-md-offset-4">
          <at-button type="primary" disabled><i class="icon icon-printer"></i> Print this form </at-button>
      </div>
  </div>
</template>

<script>
export default {
  name: 'export-document'
}
</script>

<style>

</style>
