<template>
  <div class="main_report_container">
    <div style="width: 700px;">
      <div class="report_container header">
        <div class="table_panel header">
          <div style="flex: 1;">
            <div class="col h header"></div>
            <div class="col c header">Unit</div>
            <div class="col c header">ml</div>
          </div>
          <div style="flex: 1.5;"></div>
        </div>
      </div>
      <div class="report_container chart">
        <div class="table_panel content">
          <div v-for="(item, index) in bars" v-bind:key="index">
            <div style="flex: 1; display: flex;">
              <div class="col h">{{item.name}}</div>
              <div class="col c">{{item.unit}}</div>
              <div class="col c">{{item.ml}}</div>
            </div>
            <div style="flex: 1.5; flex-direction: column;">
              <LineSideBarTitle :bar="item.bar"/>
              <LineSideBarChart :bar="item.bar"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineSideBarChart from './LineSideBarChart';
import LineSideBarTitle from './LineSideBarTitle';

export default {
  name: "BloodbankOverviewBloodGroupChart",
  props: ["bloodbank", "petType"],
  components: {
    LineSideBarChart,
    LineSideBarTitle,
  },
  computed: {
    bars() {
      let maxNum = 0;
      let bars = [];
      if(this.bloodbank.others &&
        this.bloodbank.others[this.petType] &&
        this.bloodbank.others[this.petType].data.bloodGroup)
      {
        maxNum = this.bloodbank.others[this.petType].data.bloodGroup
            .reduce((max, item) => item.status.total > max ? item.status.total : max, 0);

        this.bloodbank.others[this.petType].data.bloodGroup.map((item) => {
          bars.push({
            name: item.name,
            unit: item.unit,
            ml: item.ml,
            bar: [
              {value: item.status.used/maxNum, unit: item.status.used, color: '#d0025b'},
              {value: item.status.transfer/maxNum, unit: item.status.transfer, color: '#02b0e1'},
              {value: item.status.expired/maxNum, unit: item.status.expired, color: '#D8D8D8'},
            ],
          });
        });
      }
      return bars;
    }
  },
};
</script>

<style lang="scss" scoped>
.main_report_container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.report_container {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px 20px 20px;
}
.report_container.header {
  padding: 10px 20px 5px 20px;
  flex: 2;
}
.report_container.chart {
  flex: 1;
}

.table_panel {
  flex: 2.1;
  display: flex;
  flex-direction: column;
  padding: 0px 15px 0px 15px;
  div {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
  }
}
.table_panel.header {
  display: flex;
  flex-direction: row;
}

.col {
  color: #000000;
  padding: 3px 2px 3px 0px;
  font-size: 14px;
}
.col.h {
  flex: 2;
  font-weight: bold;
}
.col.c {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 5px;
}
.col.header {
  display: flex;
  justify-content: flex-end;
  color: #9b9b9b;
}

.chart_panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 15px 10px 1px;
}
</style>
