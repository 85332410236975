<template>
  <div class="pet-search-result">
    <div class="header">
      <span class="total">{{ total | number }}</span> RESULT
    </div>
    <div class="content">
      <table class="pet-table">
        <thead>
          <tr>
            <th class="name">Pet Name</th>
            <th class="type">Type</th>
            <th class="breed">Breed</th>
            <th class="owner">Owner</th>
            <th class="frequency">Frequency</th>
            <th class="expence">Expense (฿)</th>
          </tr>
        </thead>
        <tbody v-if="results && results.length > 0">
          <tr v-for="result in results" :key="result.pet.id">
            <td class="text-left">
              <div class="thumbnail">
                <img v-if="result.pet.image && result.pet.image.medium" :src="result.pet.image.medium">
              </div>
              <div class="pet-info">
                <router-link :to="{ name:'hospitalPetProfile', params: { id: result.pet.pet_hospital.id }}">
                {{ result.pet.name }}
                </router-link>
              </div>
            </td>
            <td>
              <PetTypeIcon :type="result.pet.type.key"/>
              <PetGenderIcon :gender="result.pet.gender"/>
            </td>
            <td class="text-left">
              <template v-if="result.pet && result.pet.breed">
                <div>{{ result.pet.breed.name }}</div>
              </template>
            </td>
            <td class="text-left">
              <template v-if="result.pet && result.pet.owners && result.pet.owners.length > 0">
                <router-link :to="{ name:'hospitalPetOwnerProfile', params: { id: result.pet.owners[0].id }}">
                <div>{{ `${result.pet.owners[0].firstname} ${result.pet.owners[0].lastname}` }}</div>
                </router-link>
              </template>
              <template v-else>
                <div class="text-center">-</div>
              </template>
            </td>
            <td>
              {{ result.frequency | number }}
            </td>
            <td>
              {{ result.expence | currency }}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="not-found" v-if="results == null || results.length === 0">ไม่พบข้อมูลที่ต้องการค้นหา</div>
    </div>
    <div class="footer" v-if="results">
      <el-pagination
        background
        @current-change="(page) => $emit('current-change', page)"
        :current-page="currentPage"
        :page-size="10"
        layout="prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import PetGenderIcon from "@/components/pet/PetGenderIcon"
import PetTypeIcon from "@/components/pet/PetTypeIcon"

export default {
  components: {
    PetGenderIcon,
    PetTypeIcon
  },
  props: ["results", "currentPage", "total"],
  data() {
    return {}
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
.pet-search-result {
  .header {
    margin-bottom: 5px;
  }
  .content {
    display: flex;
    flex-wrap: wrap;
  }

  .footer {
    padding: 20px;
    text-align: center;
  }
}

.pet-table {
  width: 100%;
  border-collapse: collapse;

  a {
    color: #333;
    text-decoration: none;
    &:hover {
      color: #02b0e1;
    }
  }

  thead {
    tr {
      border: #e6eaee 1px solid;

      th {
        padding: 10px 5px;
        font-weight: normal;
        color: #7f8fa4;
        background-color: #f5f8fa;
        font-size: 0.9em;

        &.name {
          width: 150px;
        }

        &.type {
          width: 100px;
        }

        &.frequency,
        &.expence {
          width: 120px;
        }
      }
    }
  }

  tbody {
    tr {
        text-align: center;
      td {
        position: relative;
        padding: 10px 5px;
        font-size: 0.9em;
        border-bottom: 1px solid #eee;

        .subtitle {
          color: #666;
        }

        .thumbnail {
          display: inline-block;
          width: 50px;
          height: 50px;
          overflow: hidden;
          background: #eee;
          border-radius: 5px;
          margin-right: 10px;
          vertical-align: middle;

          > img {
            width: 50px;
            height: 50px;
            border-radius: 5px;
          }
        }

        .pet-info {
          display: inline-block;
          width: calc(100% - 60px);
          vertical-align: middle;
        }

        .gender-icon {
          margin: 0 5px;
          height: 20px;
          vertical-align: middle;
          opacity: 0.5;
        }
      }
    }
  }
}

.total {
  color: $light-blue;
  font-weight: bold;
}

.not-found {
  display: flex;
  flex: 1;
  padding: 20px 0 0 0;
  text-align: center;
  justify-content: center;
}

.pagination {
  margin-top: 20px;
  text-align: right;
}
</style>
