<script>
import { toThaiDate } from '@/filters/datetime';

export default {
  props: {
    reminderStores: {
      type: Array,
      default: () => [],
    },
  },

  render() {
    const remindItem = (name, isSent, datetime) => (
      <el-row
        type="flex"
        gutter={16}
        align="middle"
        style={{ flexWrap: "wrap" }}
      >
        <el-col span={null}>
          {isSent 
            ? <i class="el-icon-circle-check"
                style={{ color: "#26dc19", fontSize: "18px" }}
              ></i>
            : <i class="el-icon-circle-check"
                style={{ color: "transparent", fontSize: "18px" }}
              ></i>
          }
        </el-col>
        
        <el-col span={null} class={this.$style.text}>
          {name}
        </el-col>
        {isSent && datetime && (
          <el-col span={null} class={this.$style.date}>
            {toThaiDate(datetime)}
          </el-col>
        )}
      </el-row>
    )

    return (
      <div>
        {this.reminderStores.map(item => [
          <label class={this.$style.title}>{item.template_name}</label>,
          <div style={{ marginBottom: "8px" }}>
            {item.is_send_via_sms && 
              remindItem("SMS", item.is_sent, item.reminder_time)
            }
            {item.is_send_via_social && 
              remindItem("Social", item.is_sent, item.reminder_time)
            }
          </div>
        ])}
      </div>
    );
  },
};
</script>

<style lang="scss" module>
.title {
  font-size: 14px;
  font-weight: bold;
  color: black;
}

.text {
  font-size: 14px;
  color: #4a4a4a;
  font-weight: narmal;
}

.date {
  font-size: 12px;
  color: #8e8e93;
  font-weight: narmal;
}
</style>
