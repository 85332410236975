<template>
  <div class="request-step-4-exam" style="width: 100%">
    <el-row>
      <slot
        class="headSlot"
        name="header"
        :currentProgress="myProgress"
        :currentText="myText"
        :currentVetType="myVetType"
      ></slot>
    </el-row>

    <el-row>
      <!-- Content -->
      <el-row>
        <el-row class="medical-record-card">
          <el-col>
            <img src="@/assets/bloodbank/file.png" height="20" />
            <span>Blood selection (Our stock)</span>
          </el-col>
        </el-row>
        <el-row style="margin-top:20px;">
          <el-card>
            <blood-row-selectable
              v-for="(item, index) in bloodBagListForDisplay"
              :key="index"
              :bloodBagId="item.id"
              :bloodType="item.bloodType"
              :pcvValue="item.pcv"
              :expiry="item.expiryDate"
              :possibility="item.possibility"
              :suggestedQuantity="item.suggestedQuantity"
              :remainingQuantity="Number(item.quantity)"
              :isInStock="item.isInStock"
              :isSelected="item.isSelected"
              :status="item.status"
              @bloodbagSelected="onBloodBagSelected"
              @bloodbagCancel="onBloodBagCancelSelected"
              style="margin-top:20px"
              :disabled="request.isCaseClosed()"
            ></blood-row-selectable>
            <hr />
            <el-pagination
              :total="totalSizeOfBloodBagSelectableList"
              :page-size="pageSizeForBloodSelectionSection"
              :current-page="currentPageForBloodSelectionSection"
              @current-change="onChangePageForBloodSelectionSection"
              background
            ></el-pagination>
            <br />
            <el-button
              :disabled="request.isCaseClosed()"
              type="primary"
              @click="addBloodBagSelectionList"
            >Submit</el-button>
          </el-card>
        </el-row>

        <el-row class="medical-record-card">
          <el-col>
            <img src="@/assets/bloodbank/file.png" height="20" />
            <span>Selected blood bag</span>
          </el-col>
        </el-row>
        <el-row style="margin-top:20px;">
          <el-card>
            <blood-suggest-row
              :key="index"
              v-for="(item, index) in crossmatchList"
              :bloodBagId="getBloodBagById(item.bloodBagId1).id"
              :bloodType="getBloodBagById(item.bloodBagId1).bloodType"
              :pcvValue="getBloodBagById(item.bloodBagId1).pcv"
              :expiry="getBloodBagById(item.bloodBagId1).expiryDate"
              :possibility="getBloodBagById(item.bloodBagId1).possibility"
              :suggestedQuantity="getBloodBagById(item.bloodBagId1).suggestedQuantity"
              :remainingQuantity="Number(getBloodBagById(item.bloodBagId1).quantity)"
              :isInStock="getBloodBagById(item.bloodBagId1).isInStock"
              @remove="onRemoved"
              :disabled="request.isCaseClosed()"
              v-if="getBloodBagById(item.bloodBagId1) != null && item.bloodBagId1 != 0 && item.bloodBagId1 != null && getBloodBagById(item.bloodBagId1).id != null"
              style="margin-top:20px"
            ></blood-suggest-row>
            <!-- <hr>
            <el-button :disabled="request.isCaseClosed()" type="primary" @click="addBloodBagModalShow=true"> + Bloodbag </el-button>-->
          </el-card>
        </el-row>

        <el-row class="medical-record-card">
          <el-col>
            <img src="@/assets/bloodbank/file.png" height="20" />
            <span>Blood Request Data</span>
          </el-col>
        </el-row>
        <el-card>
          <el-row wrap>
            <el-col :span="12">
              <h2>การ Crossmatch กับ Recipient</h2>
            </el-col>
            <el-col :span="6">
              <h3 style="margin-left:10px">Major</h3>
            </el-col>
            <el-col :span="6">
              <h3 style="margin-left:26px">Minor</h3>
            </el-col>
            <el-col id="crossmatchList" :span="24">
              <blood-request-crossmatch-row
                :key="index"
                v-for="(item, index) in crossmatchList"
                v-if="getBloodBagById(item.bloodBagId1) != null"
                :bloodBagQuantity="getBloodBagById(item.bloodBagId1).quantity"
                :pcvValue="getBloodBagById(item.bloodBagId1).pcv"
                :info="item"
                :disabled="request.isCaseClosed()"
                style="text-align:left; padding: 20px 0px 0px 0px"
              ></blood-request-crossmatch-row>
            </el-col>
            <el-col :span="24" style="margin-top:20px">
              <hr style="border:solid 1px" />
            </el-col>
            <el-col :span="24">
              <h1>+ Crossmatch 3 สาย</h1>
              <span>* การ Crossmatch ระหว่างถุงเลือด (เมื่อต้องการใช้มากกว่า 1 ถุง)</span>
              <blood-multi-crossmatch-row
                :bags="crossmatchList"
                :key="index"
                v-for="(item, index) in mutliCrossmatchList"
                :info="item"
                style="margin-top:20px"
                :disabled="request.isCaseClosed()"
              ></blood-multi-crossmatch-row>
            </el-col>
            <el-col :span="24" style="margin-top:20px">
              <hr style="border:solid 1px" />
            </el-col>

            <el-col :span="24" style="margin-top:20px">
              <el-button
                :disabled="request.isCaseClosed() || request.crossmatch.singleCrossmatchList.length < 2"
                @click="addMultiBloodBagToList"
                type="error"
              >+ เพิ่ม Crossmatch 3 สาย</el-button>
            </el-col>
          </el-row>
        </el-card>
      </el-row>
    </el-row>
  </div>
</template>

<script>
/* eslint-disable no-console,no-unused-vars */
import { mapState, mapGetters } from "vuex";
import moment from "moment";
import BloodRequesPageEnum from "@/constants/bloodbank/page-name-enum";
import BloodCrossmatchRow from "@/components/bloodbank/blood-request/BloodRequestCrossmatchRow";
import BloodMultiCrossmatchRow from "@/components/bloodbank/blood-request/BloodRequestMultiCrossmatchRow";
import BloodSuggestRow from "@/components/bloodbank/blood-request/BloodSuggestRow";
import BloodRowSelectable from "@/components/bloodbank/blood-request/BloodRowSelectable";
import ExportDocument from "@/components/bloodbank/controls/export-document/ExportDocument";
import _ from "lodash";
import pageComponentEnum from "@/constants/bloodbank/page-name-component-map";

export default {
  components: {
    "blood-request-crossmatch-row": BloodCrossmatchRow,
    "blood-multi-crossmatch-row": BloodMultiCrossmatchRow,
    "export-document": ExportDocument,
    "blood-suggest-row": BloodSuggestRow,
    "blood-row-selectable": BloodRowSelectable
  },
  props: {
    request: Object,
    mode: {
      default: "view"
    }
  },
  data() {
    return {
      myProgress: 2,
      myText: "Physical Exam",
      myVetType: "ผู้ตรวจ",
      step: 3,
      crossmatchList: this.request.crossmatch.singleCrossmatchList,
      mutliCrossmatchList: this.request.crossmatch.multiCrossmatchList,
      bloodBagSelected: "",
      bloodBagSelectedListFromSelectSection: [],
      pageComponentEnum: pageComponentEnum,
      bloodBagPreview: {
        type: "",
        pcv: "",
        expiryDate: "",
        quantity: ""
      },
      addBloodBagModalShow: false,
      currentPageForBloodSelectionSection: 1,
      pageSizeForBloodSelectionSection: 5,
      bloodBagSelectableList: [],
      bloodBagListForDisplay: [],
      totalSizeOfBloodBagSelectableList: 0,
      isConfirmModalShow: false,
      calculatedBloodBags: []
    };
  },
  computed: {
    ...mapState({
      diagnosis: state => state.bloodbank.diagnosis,
      profile: state => state.hospital.profile
    }),
    ...mapGetters({
      getBloodBagById: "bloodbank/getBloodBagById",
      getOwnedBloodBagList: "bloodbank/getOwnedBloodBagList"
    }),
    bloodbagList() {
      return this.getOwnedBloodBagList(this.profile.id).sort(
        (a, b) => a.id < b.id
      );
    },
    bloodBagSelectedList() {
      let result = [];
      this.crossmatchList.forEach(elem => {
        if (elem != null && elem.bloodBagId2 != null) {
          let bloodBagInfo = this.getBloodBagById(elem.bloodBagId2);
          if (bloodBagInfo != null) {
            result.push(bloodBagInfo);
          }
        }
      });

      return result;
    },

    filteredBloodBagByPetTypeList() {
      return this.bloodbagList
        .filter(bag => {
          let exists = this.crossmatchList.some(item => {
            return item.bloodBagId1 === bag.id;
          });

          if (bag.petInfo != null) {
            return (
              bag.petInfo.type === this.request.petInfo.type &&
              bag.quantity > 0 &&
              !exists &&
              bag.reasonOfRemoval == null
            );
          } else {
            return (
              bag.petType === this.request.petInfo.type &&
              bag.quantity > 0 &&
              !exists &&
              bag.reasonOfRemoval == null
            );
          }
        })
        .sort((a, b) => {
          return a.id - b.id;
        });
    }
  },
  async created() {
    this.updateBloodSelectableList();
    this.initBloodBagListForDisplay();
    this.updateBloodBagSelectedListFromSelectSection();
    this.updateCalculatedProdiction();
  },
  methods: {
    async updateCalculatedProdiction() {
      this.calculatedBloodBags.length = 0;
      const selectedBloodRequest = this.request;
      if (selectedBloodRequest) {
        const targetPCV = Number(
          selectedBloodRequest.recipientProfileInfo.productRequest.targetPcv
        );
        const primaryPCV = Number(
          selectedBloodRequest.recipientProfileInfo.labExam.HCT
        );
        const weight = Number(
          selectedBloodRequest.recipientProfileInfo.medicalRecord.weight
        );
        const payload = {
          data: {
            pcv_patient: primaryPCV,
            pcv_target: targetPCV,
            patient_weight: weight,
            algorithm_ml: "LR",
            bloodbag_list: this.bloodBagListForDisplay.map(bag => {
              return {
                bagID: `${bag.id}`,
                volume: `${bag.quantity}`,
                pcv: `${bag.pcv}`,
                algorithm_ml: "LR"
              };
            })
          }
        };

        this.$store
          .dispatch("bloodbank/postPossibilityPredict", { data: payload })
          .then(result => {
            console.log(`data crossmatch`, result);
            result.map(item => {
              const bag = this.bloodBagListForDisplay.find(
                bag => bag.id === Number(item.bagID)
              );
              bag.suggestedQuantity = item.suggest_volume;
              bag.possibility = item.confident;
              // this.calculatedBloodBags.push(item)
            });
          })
          .catch(err => "");

        console.log(`data crossmatch`, this.calculatedBloodBags);
      }
    },
    updateBloodBagSelectedListFromSelectSection() {
      let bloodBagList = this.bloodbagList;
      let crossmatchList = this.crossmatchList;
      bloodBagList.forEach(elem => {
        if (elem != null) {
          crossmatchList.some(item => {
            if (item.bloodBagId1 === elem.id) {
              this.bloodBagSelectedListFromSelectSection.push(elem.id);
            }
          });
        }
      });
    },
    initBloodBagListForDisplay() {
      for (let idx = 0; idx < this.pageSizeForBloodSelectionSection; idx++) {
        if (this.bloodBagSelectableList[idx] != null) {
          this.bloodBagListForDisplay.push(this.bloodBagSelectableList[idx]);
        }
      }
    },
    updateBloodSelectableList() {
      let bloodBagList = this.bloodbagList;
      // console.log(bloodBagList)
      // let arrToAddSelecedBloodBag = []
      let crossmatchList = this.crossmatchList;
      bloodBagList.forEach(elem => {
        let isSelected = false;
        if (elem != null) {
          crossmatchList.some(item => {
            if (item.bloodBagId1 === elem.id) {
              isSelected = true;
              if (elem.petInfo !== null) {
                if (
                  elem.petInfo.type === this.request.petInfo.type &&
                  elem.quantity > 0 &&
                  elem.reasonOfRemoval == null &&
                  elem.status !== 4 &&
                  elem.bloodType ===
                    this.request.recipientProfileInfo.productRequest
                      .selectedBloodProduct
                ) {
                  this.bloodBagSelectableList.push({
                    id: elem.id,
                    bloodType: elem.bloodType,
                    pcv: elem.pcv,
                    expiryDate: elem.expiryDate,
                    possibility: elem.possibility,
                    suggestedQuantity: elem.suggestedQuantity,
                    quantity: Number(elem.quantity),
                    isInStock: elem.isInStock,
                    petInfo: elem.petInfo,
                    isSelected: true,
                    status: elem.status
                  });
                }
              } else if (
                elem.petType === this.request.petInfo.type &&
                elem.quantity > 0 &&
                elem.reasonOfRemoval == null &&
                elem.status !== 4 &&
                elem.bloodType ===
                  this.request.recipientProfileInfo.productRequest
                    .selectedBloodProduct
              ) {
                this.bloodBagSelectableList.push({
                  id: elem.id,
                  bloodType: elem.bloodType,
                  pcv: elem.pcv,
                  expiryDate: elem.expiryDate,
                  possibility: elem.possibility,
                  suggestedQuantity: elem.suggestedQuantity,
                  quantity: Number(elem.quantity),
                  isInStock: elem.isInStock,
                  petInfo: null,
                  isSelected: true,
                  status: elem.status
                });
              }
            }
          });
          if (!isSelected) {
            // arrToAddSelecedBloodBag.push(elem.id)
            if (elem.petInfo !== null) {
              if (
                elem.petInfo.type === this.request.petInfo.type &&
                elem.quantity > 0 &&
                elem.reasonOfRemoval == null &&
                elem.status !== 4 &&
                elem.bloodType ===
                  this.request.recipientProfileInfo.productRequest
                    .selectedBloodProduct
              ) {
                this.bloodBagSelectableList.push({
                  id: elem.id,
                  bloodType: elem.bloodType,
                  pcv: elem.pcv,
                  expiryDate: elem.expiryDate,
                  possibility: elem.possibility,
                  suggestedQuantity: elem.suggestedQuantity,
                  quantity: Number(elem.quantity),
                  isInStock: elem.isInStock,
                  petInfo: elem.petInfo,
                  isSelected: false,
                  status: elem.status
                });
              }
            } else if (
              elem.petType === this.request.petInfo.type &&
              elem.quantity > 0 &&
              elem.reasonOfRemoval == null &&
              elem.status !== 4 &&
              elem.bloodType ===
                this.request.recipientProfileInfo.productRequest
                  .selectedBloodProduct
            ) {
              this.bloodBagSelectableList.push({
                id: elem.id,
                bloodType: elem.bloodType,
                pcv: elem.pcv,
                expiryDate: elem.expiryDate,
                possibility: elem.possibility,
                suggestedQuantity: elem.suggestedQuantity,
                quantity: Number(elem.quantity),
                isInStock: elem.isInStock,
                petInfo: null,
                isSelected: false,
                status: elem.status
              });
            }
          }
        }
      });
      this.totalSizeOfBloodBagSelectableList = this.bloodBagSelectableList.length;
    },
    addBloodBagSelectionList() {
      console.log(
        "addBloodBagSelectionList, ",
        this.bloodBagSelectedListFromSelectSection
      );
      let bloodBagToRemove = [];
      this.crossmatchList.forEach(crossmatch => {
        let isRemove = true;
        this.bloodBagSelectedListFromSelectSection.forEach(bloodBagId => {
          if (crossmatch.bloodBagId1 === bloodBagId) {
            isRemove = false;
          }
        });
        if (isRemove) {
          bloodBagToRemove.push(crossmatch.bloodBagId1);
        }
      });
      bloodBagToRemove.forEach(item => {
        this.request.crossmatch.removeSingleCrossmatchFromList(item);
        console.log("removed, ", item);
      });

      this.bloodBagSelectedListFromSelectSection.forEach(bloodBag => {
        let isExist = false;
        var bloodCrossmatchInfo = {
          blood_collection_1: this.getBloodBagById(bloodBag).id,
          blood_collection_2: null,
          isMajor: null,
          isMinor: null,
          isMajorAg: null,
          isMinorAg: null
        };
        this.crossmatchList.forEach(crossmatch => {
          if (
            crossmatch.bloodBagId1 === bloodCrossmatchInfo.blood_collection_1
          ) {
            isExist = true;
          }
        });
        if (!isExist) {
          this.request.crossmatch.addSingleCrossmatchToList(
            bloodCrossmatchInfo
          );
        }
      });
    },
    onBloodBagSelected(e) {
      let bloodBagId = e.selectedBloodBagId;
      this.bloodBagSelectedListFromSelectSection.push(bloodBagId);
      this.setIsSelectedForBloodBagSelection(bloodBagId, true);
      this.updateBloodBagListForDisplay(bloodBagId, true);
    },
    onBloodBagCancelSelected(e) {
      let bloodBagId = e.selectedBloodBagId;
      console.log(this.bloodBagSelectedListFromSelectSection);
      this.bloodBagSelectedListFromSelectSection.forEach(bloodBagSelected => {
        var index = this.bloodBagSelectedListFromSelectSection.indexOf(
          bloodBagId
        );
        if (index > -1) {
          this.bloodBagSelectedListFromSelectSection.splice(index, 1);
        }
      });
      console.log(this.bloodBagSelectedListFromSelectSection);
      this.setIsSelectedForBloodBagSelection(bloodBagId, false);
      this.updateBloodBagListForDisplay(bloodBagId, false);
    },
    setIsSelectedForBloodBagSelection(bloodBagId, isSelected) {
      this.bloodBagSelectableList.forEach(bloodBag => {
        if (bloodBagId === bloodBag.id) {
          bloodBag.isSelected = isSelected;
        }
      });
    },
    updateBloodBagListForDisplay(bloodBagId, isSelected) {
      this.bloodBagListForDisplay.forEach(bloodBag => {
        if (bloodBagId === bloodBag.id) {
          bloodBag.isSelected = isSelected;
        }
      });
    },
    onChangePageForBloodSelectionSection(pageNumber) {
      console.log(this.bloodBagSelectableList);
      this.currentPageForBloodSelectionSection = pageNumber;
      var lastIdx =
        this.currentPageForBloodSelectionSection *
        this.pageSizeForBloodSelectionSection;
      var firstIdx = lastIdx - this.pageSizeForBloodSelectionSection;

      this.bloodBagListForDisplay.length = 0;
      console.log("Current Page : ", this.currentPageForBloodSelectionSection);

      for (let idx = firstIdx; idx <= lastIdx - 1; idx++) {
        if (this.bloodBagSelectableList[idx] != null) {
          this.bloodBagListForDisplay.push(this.bloodBagSelectableList[idx]);
        }
      }
      this.updateCalculatedProdiction();
    },
    addNewCrossmatchRow() {
      new BloodCrossmatchRow().$mount("#crossmatchList");
    },

    createCrossmatchRow() {
      return this.components("blood-crossmathc-row", {
        render(createElement) {
          return createElement;
        },
        props: {}
      });
    },

    addSingleBloodBagToList() {
      console.log("addBloodBagToSelectedList", this.bloodBagSelected);
      // competitor bloodbag id
      var bloodCrossmatchInfo = {
        blood_collection_1: this.getBloodBagById(this.bloodBagSelected).id,
        blood_collection_2: null,
        isMajor: null,
        isMinor: null,
        isMajorAg: null,
        isMinorAg: null
      };
      console.log("BagInfo", bloodCrossmatchInfo);
      this.request.crossmatch.addSingleCrossmatchToList(bloodCrossmatchInfo);
    },

    addMultiBloodBagToList() {
      this.request.crossmatch.addMultiCrossmatchToList({
        blood_collection_1: "",
        blood_collection_2: "",
        isMajor: null,
        isMinor: null,
        isMajorAg: null,
        isMinorAg: null
      });
    },

    onRemoved(args) {
      this.request.crossmatch.removeSingleCrossmatchFromList(
        args.selectedBloodBagId
      );
      this.request.crossmatch.removeMultiCrossmatchToList(
        args.selectedBloodBagId
      );
      this.bloodBagSelectedListFromSelectSection.forEach(bloodBagSelected => {
        var index = this.bloodBagSelectedListFromSelectSection.indexOf(
          args.selectedBloodBagId
        );
        if (index > -1) {
          this.bloodBagSelectedListFromSelectSection.splice(index, 1);
        }
      });

      if (this.bloodBagSelectedListFromSelectSection.length < 2) {
        this.request.crossmatch.multiCrossmatchList.length = 0;
      }

      this.setIsSelectedForBloodBagSelection(args.selectedBloodBagId, false);
      this.updateBloodBagListForDisplay(args.selectedBloodBagId, false);
      setTimeout(() => {
        this.$forceUpdate();
      });
    },

    getPreviewData(bloodBagId) {
      if (bloodBagId) {
        var bloodBagInfo = this.getBloodBagById(bloodBagId);
        console.log(bloodBagInfo);
        this.bloodBagPreview.type = bloodBagInfo.bloodType;
        this.bloodBagPreview.pcv = bloodBagInfo.pcv;
        this.bloodBagPreview.quantity = bloodBagInfo.quantity;
        this.bloodBagPreview.expiryDate = bloodBagInfo.expiryDate;
      }
    },

    async beforeChangeStep() {
      const availableMultiCrossmatch = _.filter(
        this.request.crossmatch.multiCrossmatchList,
        multiCrossmatchRow => {
          return (
            !multiCrossmatchRow.bloodBagId1 !== "" &&
            multiCrossmatchRow.bloodBagId2 !== ""
          );
        }
      );
      this.request.crossmatch.multiCrossmatchList.length = 0;
      availableMultiCrossmatch.map(elem => {
        this.request.crossmatch.multiCrossmatchList.push(elem);
      });
      let crossmatches = _.union(
        this.request.crossmatch.singleCrossmatchList,
        this.request.crossmatch.multiCrossmatchList
      );
      if (crossmatches.length === 0) {
        throw "กรุณาเลือกถุงเลือด";
      } else {
        let isValid = true;
        _.forEach(crossmatches, crossmatchRow => {
          if (
            crossmatchRow.isMajor === null ||
            crossmatchRow.isMinor === null ||
            (!crossmatchRow.isMajor && crossmatchRow.isMajorAg === null) ||
            (!crossmatchRow.isMinor && crossmatchRow.isMinorAg === null)
          ) {
            isValid = false;
            return;
          }
        });
        if (!isValid) {
          throw "กรุณากรอกข้อมูลให้ถูกต้อง";
        }
      }
    }
  }
};
</script>

<style lang="scss">
  .request-step-4-exam {
    .header-container {
      width: calc(100% + 5px);
      height: 50px;
      border-radius: 6px;
      background-color: #5c9ba3;
      text-align: center;
    }

    .profile-row-container {
      height: 55px;
    }

    .at-input-group__append {
      padding: 0px !important;
    }

    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100% !important;
    }
  }
</style>
