<template>
  <ContentBox class="medical-overview" v-if="overview">
    <h2 class="title">Overview</h2>
    <DataLoading v-show="isLoading" class="overview-loading" />
    <el-row>
      <el-col :md="6">
        <div class="date-section">
          <div class="arrow" @click="$emit('changePrevMonth')">
            <i class="el-icon-arrow-left"/>
          </div>
          <div class="date">{{ monthText }}</div>
          <div
          class="arrow"
          @click="$emit('changeNextMonth')">
            <i class="el-icon-arrow-right"/>
          </div>
        </div>
      </el-col>
      <el-col :md="6">
        <div class="data-section" style="border-left: 0;">
        </div>
      </el-col>
      <el-col :md="5">
        <div class="data-section">
          <label>Gain Revenue</label>
          <div class="values">
            <div class="top">{{ overview.total_revenue | currency }}</div>
            <div class="bottom">THB</div>
          </div>
        </div>
      </el-col>
      <el-col :md="5">
        <div class="data-section">
          <label>Done</label>
          <div class="values">
            <div class="top">{{ overview.finished | number }}</div>
            <div class="bottom">case</div>
          </div>
        </div>
      </el-col>
    </el-row>
  </ContentBox>
</template>

<script>
import ContentBox from "@/components/container/ContentBox"
import DataLoading from '@/components/loading/DataLoading'
import { getThaiMonth } from '@/utils/datetime'
import numeral from 'numeral'

export default {
  props: ['overview', 'currentDate', 'isLoading'],
  components: {
    ContentBox,
    DataLoading
  },
  computed: {
    monthText () {
      if (this.currentDate) {
        const y = this.currentDate.getFullYear()
        const m = this.currentDate.getMonth()
        return getThaiMonth(m) + ' ' + (y + 543)
      }
      return '-'
    },
    percent () {
      if(this.overview && (this.overview.finished !== 0 && this.overview.in_progress !== 0)) {
        const total = this.overview.total_cases

        const finished = Math.round(this.overview.finished/total * 100)
        const in_progress = Math.round(this.overview.in_progress/total * 100)
        return {
          finished: numeral(finished, '0,0.00').value(),
          in_progress: numeral(in_progress, '0,0.00').value()
        }
      }
      return {
        finished: 0,
        in_progress: 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.overview-loading {
  position: absolute;
  margin: 2px;
  z-index: 99;
  background: rgba(0,0,0,.05);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding-top: 46px;
}
.medical-overview {
  position: relative;

  .title {
    margin: 0;
    padding: 0;
    font-size: 16px;
    margin-bottom: 5px;
  }

  .date-section {
    padding: 10px 10px 10px 0;
    font-size: 20px;

    > div {
      display: inline-block;

      &.date {
        line-height: 1.1em;
        width: calc(100% - 40px);
        text-align: center;
      }

      &.arrow {
        cursor: pointer;
        vertical-align: top;
        cursor: pointer;

        &:hover {
          color: $light-blue
        }

        i {
          width: 20px;
          font-weight: bold;
        }

        &.disabled {
          cursor: default;
          color: #ccc;
          :hover {
            color: #ccc;
          }
        }
      }
    }
  }

  .data-section {
    border-left: 1px solid #ccc;
    padding: 0 10px 10px;

    label {
      display: inline-block;
      width: 20%;
      font-size: 16px;
      line-height: 1.2em;
      vertical-align: top;
    }

    .values {
      display: inline-block;
      width: 80%;
      text-align: right;

      .minus {
        color: #cc333f;
      }

      .top {
        font-size: 20px;
        line-height: 1em;
        font-weight: bold;
      }
    }
  }
}
</style>

