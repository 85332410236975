import axios from "axios";
import _ from "lodash";
import store from "../store";

// Global API Endpoints
// export VUE_APP_API_ENDPOINT=//dev-reminder.itaam.co/api/v1
export const coreAPI = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.VUE_APP_API_ENDPOINT
});

// Request
coreAPI.interceptors.request.use(
  config => {
    let token = store.state.auth.token;
    if (token) {
      // add token into Authorization headers every requests
      config.headers["Authorization"] = `token ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Response
coreAPI.interceptors.response.use(
  response => {
    const status_code = _.result(response.data, "status_code", 200);
    switch (status_code) {
      case 400:
        if (response.data.message && response.data.message.detail) {
          return Promise.reject(
            _.result(response.data, "message", "Error with status code 400")
          );
        } else if (response.data.message.length > 0) {
          return Promise.reject({ detail: response.data.message.join('<br>') })
        } else {
          return Promise.reject(
            _.result(response.data, "message", "Error with status code 400")
          );
        }
        // if (typeof response.data.message === "Array") {
        //
        // }

      case 401:
        store.dispatch("auth/logout")
        return Promise.reject(
          _.result(response.data, "message", "Error with status code 401")
        );
      case 403:
        store.dispatch("auth/forbidden", _.result(response.data, "message", "Error with status code 403"))
        return Promise.reject(
          _.result(response.data, "message", "Error with status code 403")
        );
      default:
        return response;
    }
  },
  error => {
    return Promise.reject(error);
  }
);
