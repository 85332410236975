<template>
  <div class="form-block">
    <div class="header-block">
      <div class="title">
        Forget Your Password?
      </div>
    </div>
    <el-form ref="form" :model="form">
      <el-form-item>
        <el-input
          class="pet-owner-register"
          prefix-icon="far fa-user"
          type="tel"
          :disabled="true"
          name="telephone"
          placeholder="Telephone"
          v-model="form.telephone"></el-input>
        <span class="error">{{ errors.first('telephone') }}</span>
      </el-form-item>

      <el-form-item>
        <div class="info">* กรุณาระบุรหัส OTP ที่ได้รับทาง SMS เพื่อเข้าสู่ระบบ<br>รหัส OTP ของคุณมีอายุการใช้งาน 5 นาที</div>
        <el-input
          class="pet-owner-forgetpassword"
          type="tel"
          v-validate="'required'"
          ref="otp"
          name="otp"
          placeholder="รหัส OTP"
          v-model="form.otp"></el-input>
        <span class="error">{{ errors.first('otp') }}</span>
      </el-form-item>

      <el-form-item class="text-center">
        <router-link :to="{ name: 'petOwnerForgetPassword' }"
          class="btn btn-plain form-submit-btn btn-custom">
          cancel
        </router-link>
        <el-button
          :loading="submitButtonLoading"
          type="submit"
          class="btn btn-primary form-submit-btn btn-custom"
          @click="onSubmit">done</el-button>
        <span class="error">{{ errMessage }}</span>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        telephone: '',
        otp: '',
      },
      errMessage: '',
      submitButtonLoading: false,
    }
  },
  mounted() {
    if (this.$store.state.owner.profile != null)
      this.form.telephone = this.$store.state.owner.profile.phone
    if (this.$route.params.phone != null)
      this.form.telephone = this.$route.params.phone;
  },
  methods: {
    async onSubmit() {
      this.submitButtonLoading = true
      const valid = await this.$validator.validateAll()
      if (valid) {
        if (this.$route.query.verify_code == 'owner_create_password') {
          try {
            const result = await this.$store.dispatch('owner/ownerOTPVerify', {
              data: {
                phone: this.form.telephone,
                pin: this.form.otp,
                operation_name: 'owner_create_password'
              }
            })
            if (result.token != null) {
              this.$router.push({ name: 'invitePage', 
                params: {
                  phone: this.form.telephone,
                  token: result.token,
                },
                query: {
                  verify_code: 'owner_create_password',
                }
              })
            } else {
              if (["Code is invalid.", "Parameter Invalid."].indexOf(result.error) > -1) {
                this.errMessage = "Incorrect OTP pin";
              } else {
                this.$router.push({ name: 'petOwnerRegister' })
                this.$message('VRemind verification code expired. (รหัสหมดอายุแล้ว กรุณาลองใหม่อีกครั้ง)')
              }
            }
          } catch (e) {
            this.errMessage = e.pin[0]
          }
        } else {
          try {
            await this.$store.dispatch('owner/ownerForgetPasswordOTP', {
              data: {
                phone: this.form.telephone,
                pin: this.form.otp,
                operation_name: 'owner_forgetpassword'
              }
            })
            const result = this.$store.state.owner.profile; 
            if (result.token != null) {
              this.$router.push({ name: 'petOwnerForgetPasswordRecovery' })
            } else {
              if (["Code is invalid.", "Parameter Invalid."].indexOf(result.error) > -1) {
                this.errMessage = "Incorrect OTP pin";
              } else {
                this.$router.push({ name: 'petOwnerForgetPassword' })
                this.$message('VRemind verification code expired. (รหัสหมดอายุแล้ว กรุณาลองใหม่อีกครั้ง)')
              }
            }
          } catch (e) {
            this.errMessage = e.pin[0]
          }
        }
      }
      this.submitButtonLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.form-block {
  @extend %block-container;
  @extend %form-style;
  height: 470px;

  .title {
    // height: 20px;
    font-size: 18px !important;
    font-weight: bold;
    text-align: center;
    color: #4a4a4a;
  }

  .lead {
    margin-top: 24px;
    height: 36px;
    font-size: 14px !important;
    font-weight: bold;
    letter-spacing: -0.6px;
    text-align: center;
    color: #4a4a4a;
  }

  .el-button {
    margin-top: 10px;
  }

  .info {
    margin-bottom: 7px;
    display: block;
    font-size: 10px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    color: #4a90e2;
  }
}
.footer-block {
  text-align: center;
  span {
    color: $gray;
  }
  a {
    text-decoration: none;
    color: #000;
  }
}
</style>

<style lang="scss" scoped>
button.btn-custom, a.btn-custom {
  box-sizing: border-box !important;
  margin-top: 0px !important;
  width: 130px !important;
  font-size: 17px !important;
}
</style>

<style lang="scss">
.pet-owner-forgetpassword {
  input {
    color: black;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    // line-height: 1.29;
    // line-height: 0;
    letter-spacing: -0.4px;
  }
}
</style>