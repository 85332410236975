<template>
  <div class="layout-container">
    <div class="form-block">
      <div class="header-block">
        <div class="title">
          Forgot Your Password?
        </div>
        <div class="lead">
          กรุณากรอกอีเมล์ของคุณ
        </div>
      </div>
      <el-form ref="form" :model="form">
        <el-form-item>
          <el-input
            type="email"
            v-validate="'required|email'"
            name="email"
            placeholder="Email"
            v-model="form.username"></el-input>
          <span v-show="formFields.email && formFields.email.dirty" class="error">{{ errors.first('email') }}</span>
        </el-form-item>

        <el-form-item class="btn-container">
          <router-link
            class="btn btn-plain form-submit-btn"
            :to="{ name: 'login' }">Back</router-link>
          <el-button
            :loading="submitButtonLoading"
            type="submit"
            class="btn btn-primary form-submit-btn"
            @click="submit">Submit</el-button>
          <span class="error error-message">{{ errMessage }}</span>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import PlainNavbar from '@/components/navigation/PlainNavbar'

export default {
  data() {
    return {
      form: {
        username: ""
      },
      errMessage: "",
      submitButtonLoading: false
    }
  },
  methods: {
    async submit(e) {
      e.preventDefault()
      this.errMessage = ''
      this.submitButtonLoading = true
      try {
        const valid = await this.$validator.validateAll()
        if (valid) {
          await this.$store.dispatch("ui/setIsFullScreenLoading", true)
          const res = await this.$store.dispatch("hospital/forgetPassword", {
            data: {
              email: this.form.username
            }
          })
          await this.$store.dispatch("ui/setIsFullScreenLoading", false)

          if (res && res.message && res.message.detail) {
            this.$message({
              message: res.message.detail,
              type: 'success'
            })
          }

        }
      } catch (error) {
        if (error.constructor === Array && error.length > 0)
        this.errMessage = error[0]

        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      }
      this.submitButtonLoading = false
    }
  },
  components: {
    PlainNavbar
  }
}
</script>

<style lang="scss" scoped>
.layout-container {
  background-color: $gray-background;
  min-height: 500px;
  padding-bottom: 40px;
}
.is-danger {
  border: 1px solid red;
}
.form-block {
  @extend %block-container;
  @extend %form-style;
  .btn-container {
    text-align: center;
    .form-submit-btn {
      display: inline-block;
      width: 100px;
      line-height: 1;
      margin: 4px;
      text-align: center;
      text-decoration: none;
    }
  }
}
.error-message {
  margin-top: 10px;
}
</style>
