<template>
  <el-card>
    <el-row>
      <slot name="header" :currentProgress="this.myProgress" :currentText="this.myText"></slot>
    </el-row>
    <el-divider></el-divider>
    <div class="block">
      <BloodDonationTimeline v-if="donation" :donation="donation" @action="btnAction"></BloodDonationTimeline>
    </div>
  </el-card>
</template>

<script>
import BloodDonationTimeline from "../../../components/bloodbank/blood-donate/BloodDonationTimeline";
export default {
  components: { BloodDonationTimeline },
  props: ["donation"],
  data() {
    return {
      myProgress: 0,
      myText: "Donation Timeline"
    };
  },
  computed: {},
  methods: {
    btnAction(i) {
      switch (i) {
        case 1:
          this.$router.push({ name: "donationExam" });
          break;
        case 2:
          this.$router.push({ name: "donationLab" });
          break;
        case 3:
          this.$router.push({ name: "donationCare" });
          break;
        case 4:
          this.$router.push({ name: "donationCollect" });
          break;
      }
    }
  }
};
</script>

<style scoped>
.el-card {
  width: 100%;
}
</style>
