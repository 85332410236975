<template>
  <el-dialog :visible="visible" width="600px" center @close="$emit('cancel')">
    <div class="header">
      <h1 class="title">Edit Reward</h1>
    </div>
    <div class="content">
      <div class="form-row">
        <div>
          <label>Reward Name*</label>
        </div>
        <el-input size="small" v-model="form.name" type="textarea" :autosize="{ minRows: 2 }" />
      </div>
      <div class="form-row">
        <div>
          <label>Reward point</label>
        </div>
        <el-input-number size="small" v-model="form.use_point" />
      </div>
      <div class="form-row">
        <div>
          <label>Detail</label>
        </div>
        <el-input
          size="small"
          v-model="form.detail"
          type="textarea"
          rows="5"
          :autosize="{ minRows: 3, maxRows: 5 }"
        />
      </div>
      <div class="form-row">
        <div>
          <label>Status</label>
        </div>
        <div>
          <el-radio v-model="form.available" :label="true" size="small" border>Active</el-radio>
          <el-radio v-model="form.available" :label="false" size="small" border>Inactive</el-radio>
        </div>
      </div>
    </div>
    <span class="footer">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-button type="outline" style="width: 100%;" @click="$emit('cancel')">Cancel</el-button>
        </el-col>
        <el-col :span="12">
          <el-button type="primary" @click="handelSave" style="width: 100%;">Save</el-button>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top: 20px;">
          <el-col :span="24">
              <el-button type="outline" class="btn-delete" style="width: 100%;" @click="confirmDeleteVisible = true">Delete reward</el-button>
          </el-col>
      </el-row>
    </span>

    <el-dialog title="Delete reward" :visible="confirmDeleteVisible" width="500px" center append-to-body @close="confirmDeleteVisible = false">
        <div class="text-center">ยืนยันการลบ {{ form.name }}</div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="confirmDeleteVisible = false">cancel</el-button>
            <el-button type="primary" @click="$emit('delete', editData)">confirm</el-button>
        </span>
    </el-dialog>
  </el-dialog>
</template>

<script>
export default {
  props: ["visible", "editData"],
  data() {
    return {
      confirmDeleteVisible: false,
      form: {
        id: null,
        name: "",
        use_point: 0,
        detail: "",
        available: false
      }
    };
  },
  methods: {
    handelSave() {
      if (this.form.name !== "" && !isNaN(this.form.use_point)) {
        this.$emit("save", this.form);
      } else {
        this.$notify({
          message: `กรุณากรอกข้อมูลให้ครบ`,
          type: "warning"
        });
      }
    }
  },
  watch: {
    editData(newValue) {
      if (newValue) {
        this.form = {
          id: newValue.id,
          name: newValue.name,
          use_point: newValue.use_point,
          detail: newValue.detail,
          available: newValue.available
        };
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  margin-top: -40px;
  margin-bottom: 20px;
  text-align: center;

  .title {
    font-size: 18px;
    font-weight: bold;
  }
}
.content {
  font-size: 16px;
  text-align: center;
}
.footer {
  margin-top: 30px;
  display: block;
  justify-content: center;
}
.form-row {
  margin-top: 10px;
  text-align: left;

  label {
    display: inline-block;
    text-align: left;
    margin: 5px 0;
  }

  textarea {
    width: 100%;
  }
}
</style>

