<template>
  <div class="donation-step-3-exam" style='width: 100%'>

    <el-row>
      <slot class='headSlot' name="header" :currentProgress='this.myProgress' :currentText='this.myText'></slot>
    </el-row>

    <el-row>
      <el-row class="medical-record-card">
        <el-col>
          <img src="@/assets/bloodbank/file.png" height="20"/>
          <span>  Decision Suggestion</span>
        </el-col>
      </el-row>
      <el-row>
        <el-card>
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <at-card style="width: 100%;">
                  <h4 slot="title">สรุปผลการตรวจสุขภาพ</h4>

                  <div class="row flex-middle">
                    <H1>• Physical Exam</H1>
                  </div>

                  <div v-if="donation.medicalRecord.isPassed" class="row flex-middle">
                      <i class="icon icon-check" style="color: green; font-size:50px; margin-right:5;"></i>
                      <H1>ผ่าน</H1>
                  </div>

                  <div v-if="!donation.medicalRecord.isPassed" class="row flex-middle">
                      <i class="icon icon-x" style="color: red; font-size:50px; margin-right:5;"></i>
                      <H1>ไม่ผ่าน</H1>
                  </div>

                  <div class="row flex-middle">
                          หมายเหตุ
                  </div>

                  <div class="row flex-middle">
                    <label> {{donation.medicalRecord.note}} </label>
                  </div>

                  <div class="row flex-middle">
                      <H1>• Lab</H1>
                  </div>

                  <div v-if="donation.labExam.isPassed" class="row flex-middle">
                      <i class="icon icon-check" style="color: green; font-size:50px; margin-right:5;"></i>
                      <H1>ผ่าน</H1>
                  </div>

                  <div v-if="!donation.labExam.isPassed" class="row flex-middle">
                      <i class="icon icon-x" style="color: red; font-size:50px; margin-right:5;"></i>
                      <H1>ไม่ผ่าน</H1>
                  </div>

                  <div class="row flex-middle">
                        หมายเหตุ
                  </div>

                  <div class="row flex-middle">
                      <label> {{donation.labExam.note}} </label>
                  </div>

                </at-card>
              </div>
            </div>

            <div class="col-md-1"></div>

            <div class="col-md-11">
              <div class="row">
                <at-card style="width: 100%;">
                  <h4 slot="title">Donor Care</h4>

                  <div class="row flex-start flex-middle">
                      <div class="col-md-12">
                        ทำวัคซีนครั้งต่อไป <i class="icon icon-alert-triangle"></i>
                      </div>

                      <div class="col-md-12">
                        <el-date-picker :disabled="donation.isCaseClosed()" v-model="donation.donorCareInfo.nextVaccineDate" type="date" placeholder="Select date"></el-date-picker>
                      </div>
                  </div>

                  <div class="row flex-start flex-middle" style="margin-top: 10px;">
                      <div class="col-md-12">
                        ป้องกันเห็บหมัดครั้งต่อไป <i class="icon icon-alert-triangle"></i>
                      </div>

                      <div class="col-md-12">
                        <el-date-picker :disabled="donation.isCaseClosed()" v-model="donation.donorCareInfo.nextBugVaccineDate" type="date" placeholder="Select date"></el-date-picker>
                      </div>
                  </div>

                  <div class="row flex-start flex-middle" style="margin-top: 10px;">
                      <div class="col-md-12">
                        นัดบริจาคครั้งต่อไป <i class="icon icon-alert-triangle"></i>
                      </div>

                      <div class="col-md-12">
                        <el-date-picker :disabled="donation.isCaseClosed()" v-model="donation.donorCareInfo.nextDonationDate" type="date" placeholder="Select date"></el-date-picker>
                      </div>
                  </div>

                  <div class="row flex-start flex-middle" style="margin-top: 20px;">
                      <div class="col-md-24">
                        <label style="color: red;"><i class="icon icon-alert-triangle"></i>ข้อมูลนี้ช่วยในการแจ้งเตือนแก่เจ้าของสัตว์เลี้ยง</label>
                      </div>
                  </div>

                </at-card>
              </div>

              <div class="row" style="margin-top: 15px;">
                <at-card style="width: 100%;">
                  <h4 slot="title" class="error-field-label">บริจาคเลือด <span>*</span></h4>
                  <div clas="row">
                    <div class="col-md-24 col-xs-24">
                      <confirmation-button :disabled="donation.isCaseClosed()" v-validate="'required'" :isActive="donation.donorCareInfo.canDonate" v-model="donation.donorCareInfo.canDonate"></confirmation-button>
                    </div>
                  </div>
                </at-card>
              </div>
            </div>
          </div>
        </el-card>
      </el-row>
    </el-row>

  </div>
</template>

<script>
import TopLabelInputCtrl from '@/components/bloodbank/controls/top-label-input/TopLabelInput'
import SymptomTable from '@/components/bloodbank/controls/symptom-table/SymptomTable'
import VaccineRegular from '@/constants/bloodbank/vaccine-regular'
import ConfirmationButton from '@/components/bloodbank/controls/confirmation-button/ConfirmMationButton'

export default {
  components: {
    'top-label-input': TopLabelInputCtrl,
    'symptom-table': SymptomTable,
    'confirmation-button': ConfirmationButton
  },
  props: {
    donation: Object,
    mode : {
      default: 'view'
    }
  },
  data() {
    return {
      myProgress: 3,
      myText: 'Donor Care',
      vaccineRegular: VaccineRegular
    }
  },
  watch: {
    canDonate() {
      this.donation.isDonorCareSubmitted = false
    }
  },
  computed: {
    canDonate() {
      return this.donation.donorCareInfo.canDonate
    }
  },
  methods: {
    getNextVaccineDate: function () {
      var dateObj = new Date()
      var year = dateObj.getFullYear()
      var month = dateObj.getMonth()
      var date = dateObj.getDate()
      var todayInNextYear = new Date(year + 1, month, date)
      this.donation.donorCareInfo.nextVaccineDate = todayInNextYear
    },
    getNextBugVaccineDate: function () {
      var dateObj = new Date()
      var year = dateObj.getFullYear()
      var month = dateObj.getMonth()
      var date = dateObj.getDate()
      var todayInNextYear = new Date(year, month + 1, date)
      this.donation.donorCareInfo.nextBugVaccineDate = todayInNextYear
    },
    getNextDonationDate: function () {
      var dateObj = new Date()
      var year = dateObj.getFullYear()
      var month = dateObj.getMonth()
      var date = dateObj.getDate()
      var todayInNextYear = new Date(year, month + 3, date)
      this.donation.donorCareInfo.nextDonationDate = todayInNextYear
    }
  },
  mounted() {
    if (this.donation.isDonorCareSubmitted) {
      return
    }

    this.getNextVaccineDate()
    this.getNextBugVaccineDate()
    this.getNextDonationDate()
  }
}
</script>

<style lang="scss">
  .donation-step-3-exam {
    h4 {
      margin: 0;
    }

    .medical-record-card {
      border-radius: 4px;
      min-height: 40px;
      text-align: center;
      color: white;
      background: #33495d;
    }

    .medical-record-card > .el-col {
      transform: translateY(+20%);
    }

    .button-hover:hover {
      border-color: black;
      border-width: 1px;
    }

    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100% !important;
    }
  }
</style>
