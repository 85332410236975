<template>
  <div class="pet-timeline" :v-if="histories">
    <el-timeline v-if="histories && histories.length > 0">
      <el-timeline-item></el-timeline-item>
      <el-timeline-item v-for="(history, index) in histories" :key="index">
        <el-card>
          <div class="contain">
            <div class="detail">
              <div class="key" style="text-transform: capitalize;">{{ history.action_timestamp | toThaiDate }}</div>
              <div class="time">

                <span v-if="history.action_enum == bloodCollectionHistoryActionEnums.Collect && bloodBag.hospitalName == null">
                  Collect Blood
                </span>

                <span v-if="history.action_enum == bloodCollectionHistoryActionEnums.Collect && bloodBag.hospitalName != null">
                  Add Blood Bag
                </span>

                <span v-if="history.action_enum == bloodCollectionHistoryActionEnums.Crossmatch">
                  Crossmatch
                </span>

                <span v-if="history.action_enum ==  bloodCollectionHistoryActionEnums.Reserve">
                  Reserve
                </span>

                <span v-if="history.action_enum == bloodCollectionHistoryActionEnums.Transform">
                  ปั่นแยก
                </span>

                <span v-if="history.action_enum == bloodCollectionHistoryActionEnums.Splitting">
                  แบ่งเลือด
                </span>

                <span v-if="history.action_enum == bloodCollectionHistoryActionEnums.Remove_Move || history.action_enum == bloodCollectionHistoryActionEnums.Remove_Expire">
                  Remove Blood Bag
                </span>

                <span v-if="history.action_enum == bloodCollectionHistoryActionEnums.Received">
                  Received
                </span>

              </div>
            </div>
            <div class="detail">

              <div class="value" v-if="history.action_enum === bloodCollectionHistoryActionEnums.Collect && bloodBag.hospitalName == null">
                <p>Donation ID: <a @click="onBloodDonationIdClicked(history.bloodDonationId)">{{ history.bloodDonationId }}</a></p>
              </div>

              <div class="value" v-if="history.action_enum == bloodCollectionHistoryActionEnums.Collect && bloodBag.hospitalName != null">
                <p><b>From Hospital Name:</b> {{ bloodBag.hospitalName }}</p>
                <p><b>Donor:</b> {{ bloodBag.petType }}</p>
                <p><b>Blood Group:</b> {{ bloodBag.bloodGroup }}</p>
                <p><b>Type:</b> {{ bloodBag.bloodType }}</p>
                <p><b>PCV:</b> {{ bloodBag.pcv }} %</p>
                <p><b>Amount:</b> {{ bloodBag.originalQuantity }} ml.</p>
                <p><b>Collect Date/Time:</b> {{ bloodBag.dateCollection | toThaiDate }}</p>
                <p><b>Expiry Date:</b> {{ bloodBag.expiryDate | toThaiDate }}</p>
                <p><b>Blood Bag ID:</b> {{ bloodBag.id }}</p>
                <p v-if="bloodBag.existingBloodBagId != null"><b>Previous SPBB ID:</b> {{ bloodBag.spbb  }}</p>
                <p v-if="bloodBag.existingBloodBagId != null"><b>Previous Other Blood Bag ID:</b> {{ bloodBag.existingBloodBagId  }}</p>
              </div>

              <div class="value" v-if="history.action_enum == bloodCollectionHistoryActionEnums.Crossmatch">
                <p><b>Request ID: </b><a @click="onBloodRequestIdClicked(history.bloodRequestId)">{{ history.bloodRequestId }}</a>, {{history.bloodBagId}}, <b>Major:</b> {{history.Major}}, <b>Minor:</b> {{history.Minor}}</p>
              </div>

              <div class="value" v-if="history.action_enum ==  bloodCollectionHistoryActionEnums.Reserve">
                <p><b>By:</b> {{history.approverName}} <b>Request ID: </b><a @click="onBloodRequestIdClicked(history.bloodRequestId)">{{ history.bloodRequestId }}</a></p>
              </div>

              <div class="value" v-if="history.action_enum == bloodCollectionHistoryActionEnums.Transform">
                <p>
                  <b>ถุงเลือดตั้งต้น</b><br>
                  - <span v-html="getBloodBagInfo(history.transformedFromBag)"></span>&nbsp;<b>ID:</b>&nbsp;<a @click="onBloodCollectIdClicked(history.transformedFromBag)" v-if="history.transformedFromBag">{{ history.transformedFromBag }}</a><span v-else>-</span><br>
                  <b>ผลิตภัณฑ์ที่ได้</b><br>
                  <template v-bind="id" v-for="bloodCollect in history.bloodBagList">
                    <span v-bind:key="bloodCollect.id">- {{`${bloodCollect.productType} ${bloodCollect.quantity}`}} <b>ID: </b><a @click="onBloodCollectIdClicked(bloodCollect.id)">{{bloodCollect.id}}</a></span><br>
                  </template>
                </p>
              </div>

              <div class="value" v-if="history.action_enum == bloodCollectionHistoryActionEnums.Splitting">
                <p>
                  <b>ถุงเลือดตั้งต้น</b><br>
                  - <span v-html="getBloodBagInfo(history.transformedFromBag)"></span>&nbsp;<b>ID:</b>&nbsp;<a @click="onBloodCollectIdClicked(history.transformedFromBag)" v-if="history.transformedFromBag">{{ history.transformedFromBag }}</a><span v-else>-</span><br>
                  <b>ผลิตภัณฑ์ที่ได้</b><br>
                  <template v-bind="id" v-for="bloodCollect in history.bloodBagList">
                    <span v-bind:key="bloodCollect.id">- {{`${bloodCollect.productType} ${bloodCollect.quantity}`}} <b>ID: </b><a @click="onBloodCollectIdClicked(bloodCollect.id)">{{bloodCollect.id}}</a></span><br>
                  </template>
                </p>
              </div>

              <div class="value" v-if="history.action_enum == bloodCollectionHistoryActionEnums.Remove_Move">
                <p><b>Reason of Removal:</b> ให้โรงพยาบาล</p>
                <p><b>ชื่อสถานพยาบาล:</b> {{ bloodBag && bloodBag.givenToHospitalName }}</p>
                <p><b>หมายเหตุ:</b> {{ bloodBag && bloodBag.note ? bloodBag.note : '-' }}</p>
              </div>

              <div class="value" v-if="history.action_enum == bloodCollectionHistoryActionEnums.Remove_Expire">
                <p><b>Reason of Removal:</b> หมดอายุ</p>
                <p><b>หมายเหตุ:</b> {{ bloodBag && bloodBag.note ? bloodBag.note : '-' }}</p>
              </div>

              <div class="value" v-if="history.action_enum == bloodCollectionHistoryActionEnums.Received">
                <p><b>By:</b> {{history.approverName}} <b>Request ID: </b><a @click="onBloodRequestIdClicked(history.bloodRequestId)">{{ history.bloodRequestId }}</a></p>
              </div>

            </div>
          </div>
          <!--<el-button class="action-btn b1"-->
          <!--&gt;ดูรายละเอียด</el-button>-->
        </el-card>
      </el-timeline-item>
      <el-timeline-item></el-timeline-item>
    </el-timeline>
    <div class="no-item" v-else>No timeline</div>
  </div>
</template>

<script>
  import bloodCollectionHistoryActionEnums from '../../../constants/bloodbank/blood-collect-history-action'
  import utils from '../../../utils/bloodbank'
  export default {
    name: "BloodInventoryDetailTimeline",
    props: ['bloodBag', 'histories'],
    data () {
      return {
        bloodCollectionHistoryActionEnums
      }
    },
    methods: {
      onBloodDonationIdClicked: function (id) {
        this.$router.push({ name: 'donationTimeline', params: { id: id }})
      },
      onBloodRequestIdClicked: function (id) {
        this.$router.push({ name: 'requestTimeline', params: { id: id }})
      },
      onBloodCollectIdClicked: function (id) {
        this.$router.push({ name: 'inventoryDetail', params: { id: id }})
      },

      getBloodBagInfo(bloodBagId) {
        const bloodBag = this.$store.getters['bloodbank/getBloodBagById'](bloodBagId)
        if (bloodBagId == null || bloodBag == null) {
          return ''
        }

        const productType = utils.convertBloodTypeEnumToFullName(bloodBag.bloodType)
        const quantity = parseInt(bloodBag.originalQuantity) + ' ml.'
        return productType + ' ' + quantity
      }
    }
  }
</script>

<style lang="scss" scoped>
  .el-button {
    img {
      width: 20px;
      height: 20px;
      object-fit: contain;

      display: inline;
      vertical-align: middle;
    }

    &.side-button {
      width: 100%;
      // margin-top: 5px;
      margin-bottom: 15px;
    }
  }

  .el-button {
    &.action-btn {
      color: #156f88;
      border-radius: 5px;
      border: none;
      &:not(:hover) {
        background-color: #c2e5f0;
      }

      &.b1 {
        border-radius: 0px;
        width: 100%;
      }
    }
  }

  .contain {
    padding: 12px;
  }

  .no-item {
    font-family: default;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.7px;
    text-align: center;
    color: #8e8e93;
    margin: 72px 0px;
  }

  .detail {
    text-align: left;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0px;
    }

    .time {
      width: 100%;
      max-width: 269px;
      height: 30px;
      border-radius: 4px;
      color: white;
      background-color: #156f88;

      text-align: center;
      vertical-align: middle;
      line-height: 30px;

      span {
        float: left;
        margin-left: 8px;
      }
    }

    .key {
      font-family: default;
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.1px;
      color: #8e8e93;
    }
    .value {
      font-family: default;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #030303;

      &.small {
        font-family: default;
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.19;
        letter-spacing: normal;
        color: #354052;
      }

      &.big {
        font-family: default;
        font-size: 18px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000000;
      }

      &.line {
        border-bottom: 1px solid #e6eaee;
      }

      .sub {
        font-size: 16px;
        font-weight: 0;
      }
    }
  }
</style>

<style lang="scss">
  .pet-timeline {
    .el-card__body {
      padding: 0px;
    }

    .el-timeline {
      padding: 0px 0px 0px 12px;
      margin: auto;
      /*overflow: hidden;*/
    }

    .el-timeline-item__wrapper {
      padding-left: 50px;
    }

    .el-timeline-item__tail {
      left: 3px;
      border-left: 4px solid #E4E7ED;
    }

    .el-timeline-item {
      .el-timeline-item__node--normal {
        left: -12px;
        width: 34px;
        height: 34px;
        background-image: url('../../../assets/icons/icon-calendar2.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }

      &:first-child {
        .el-timeline-item__node--normal {
          display: none;
        }
      }

      &:last-child {
        .el-timeline-item__node--normal {
          display: none;
        }
        padding: 0px;
      }

      &:nth-last-child(2) {
        height: 300px;
      }
    }
  }
</style>
