<script>
import getStatus from "./getStatus";

export default {
  props: {
    type: {
      type: String,
      default: "",
    },
  },

  computed: {
    statusData() {
      return getStatus(this.type);
    },
  },

  render() {
    return (
      this.statusData && (
        <div
          style={{
            display: "inline-block",
            padding: "0.25em 0.5em",
            borderRadius: "0.25em",
            lineHeight: "1.5",
            backgroundColor: this.statusData.color,
            color: "white",
          }}
        >
          {this.statusData.label}
        </div>
      )
    );
  },
};
</script>
