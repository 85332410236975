<template>
    <el-collapse accordion class="pet-reward-timeline-item">
        <el-collapse-item name="1">
            <template slot="title">
                <div class="title">{{ item.name }}</div>
            </template>
            <div class="reward" v-for="(reward, index) in item.rewards" :key="index">
                <div class="point-text"><span class="point">{{ reward.use_point }}</span> point</div>
                <div class="normal-text">{{ reward.name }}</div>
                <div v-if="reward.detail">
                    <div class="point-text">รายละเอียด</div>
                    <div class="normal-text">{{ reward.detail }}</div>
                </div>
            </div>
        </el-collapse-item>
    </el-collapse>
</template>

<script>
export default {
  props: ["item"]
};
</script>

<style lang='scss'>
.pet-reward-timeline-item{
    &.el-collapse {
        border: none;
    }
    .el-collapse-item__header {
        border-radius: 5px;
        background-color: rgba(2, 176, 225, 0.19);
    }
    .title {
        width: 100%;
        padding: 0 16px;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #156f88;
    }
    .reward {
        padding: 16px 4px;
        border-bottom: solid 1px #d8d8d8;
        background-color: #fff;

        .point-text {
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #030202;

            .point {
                color: #ff4f4a;
            }
        }

        .normal-text {
            font-size: 14px;
            color: #5a5a5a;
            margin-top: 4px;
        }
    }
}

</style>