<template>
  <div class="pet-owner-register">
    <HospitalConnectionTag style="margin-top: -30px"/> 

    <div class="form-block">
      <div class="header-block">
        <div class="title">
          Pet Owner
          <div class="lead">
          Register
          </div>
        </div>
      </div>
    
      <el-form ref="form" :model="form">
        <el-form-item>
          <el-input class="pet-owner-register"
            prefix-icon="far fa-user"
            type="tel"
            v-validate="'required'"
            name="telephone"
            placeholder="Telephone"
            v-model="form.telephone"></el-input>
          <span class="error">{{ errors.first('telephone') }}</span>
          <span class="info">* เบอร์โทรศัพท์มือถือนี้จะใช้เป็น username ของคุณ</span>
        </el-form-item>

        <el-form-item v-if="!isNotVerify">
          <el-input class="pet-owner-register"
            prefix-icon="fas fa-unlock-alt"
            type="password"
            v-validate="'required'"
            name="password"
            ref="password"
            placeholder="Password"
            v-model="form.password"></el-input>
          <span class="error">{{ errors.first('password') }}</span>
        </el-form-item>

        <el-form-item v-if="!isNotVerify">
          <el-input class="pet-owner-register"
            prefix-icon="fas fa-unlock-alt"
            type="password"
            v-validate="'required|confirmed:password'"
            name="passwordConfirm"
            placeholder="Re-Password"
            v-model="form.passwordConfirm"></el-input>
          <span class="error">{{ errors.first('passwordConfirm') }}</span>
        </el-form-item>

        <el-form-item v-if="!isNotVerify" class="login-optional">
          <div class="term-and-condition">
            <el-checkbox v-model="termChecked">ยอมรับ 
              <span>
                <el-link href="https://drive.google.com/file/u/3/d/1CB2gkIKR-74QXjfs4HaMZn6zbIX3Tl5l/view?usp=sharing" :underline="false" target="_blank" rel="norefferer">Term and Condition</el-link>
                </span>
            </el-checkbox>
          </div>
          <div></div>
        </el-form-item>

        <!-- <div class="info center" v-if="!isNotVerify">คุณได้ยอมรับใน Terms and Condition และ<br>Privacy Policy แล้ว</div> -->

        <el-form-item class="text-center" style="margin-top: 4px;">
          <el-button v-if="!isNotVerify"
            :loading="submitButtonLoading"
            type="submit"
            class="btn btn-primary form-submit-btn btn-custom"
            :disabled='toggleSignup'
            @click="onSubmit">sign up</el-button>
          <span class="error">{{ errMessage }}</span>
          <span v-if="isNotVerify" style="font-size: 16px">Verify Your account via OTP SMS</span>
          <el-button v-if="isNotVerify"
          :loading="submitButtonLoading"
          type="submit"
          class="btn btn-primary form-submit-btn btn-custom"
          @click="onGetOTP">Get OTP</el-button>
        </el-form-item>
      </el-form>
      <div class="footer-block" v-if="!isNotVerify">
        <div style="margin-top: 16px">
          <span>เป็นสมาชิกแล้ว? </span>
          <router-link :to="{ name: 'petOwnerLogin' }">เข้าสู่ระบบ</router-link>
        </div>
        <div style="margin-top: 12px">
          <span>ลืมรหัสผ่าน? </span>
          <router-link :to="{ name: 'petOwnerForgetPassword' }">Forget Password</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HospitalConnectionTag from '@/components/pet/HospitalConnectionTag'

export default {
  components: {
    HospitalConnectionTag
  },
  data() {
    return {
      form: {
        telephone: '',
        password: '',
        passwordConfirm: '',
      },
      isNotVerify: false,
      termChecked: false,
      errMessage: '',
      submitButtonLoading: false,
    }
  },
  computed: {
    toggleSignup: function(){
      return !this.termChecked;
    }
  },
  methods: {
    async onGetOTP() {
      await this.$store.dispatch('owner/createPasswordOTP', {
        data: {
          phone: this.form.telephone,
        }
      })
      this.$message('VRemind verification code sent. รหัสจะหมดอายุใน 5 นาที)')
      this.$router.push({ name: 'petOwnerRegisterOTP', 
        params: {
          phone: this.form.telephone
        },
        query: {
          verify_code: 'owner_create_password'
        }
      })
    },
    async getHospitalData() {
      try {
        await this.$store.dispatch('owner/ownerGetHospital', {
          data: {
            phone: this.form.telephone,
            password: this.form.password
          }
        })
        this.$message('VRemind verification code sent. รหัสจะหมดอายุใน 5 นาที)')
        this.$router.push({ name: 'petOwnerRegisterOTP' })
      } catch (e) {
        this.errMessage = 'Duplicate phone number'
      }
    },
    async onSubmit() {
      this.submitButtonLoading = true
      const valid = await this.$validator.validateAll()
      if (valid) {
        try {
          await this.$store.dispatch('owner/ownerRegister', {
            data: {
              phone: this.form.telephone,
              password: this.form.password
            }
          })
          this.$message('VRemind verification code sent. รหัสจะหมดอายุใน 5 นาที)')
          this.$router.push({ name: 'petOwnerRegisterOTP', params: { password: this.form.password } })
        } catch (e) {
          this.errMessage = 'Duplicate phone number'
          if (e.phone.constructor === Array) {
            if (e.phone[0] == "owner with this phone already exists, but not verified yet.") {
              this.errMessage = 'Owner with this phone not verify yet.'
              this.isNotVerify = true
            }
          }
        }
      }
      this.submitButtonLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.form-block {
  @extend %block-container;
  @extend %form-style;

  padding-bottom: 57px;
}
.footer-block {
  text-align: center;
  span {
    color: $gray;
  }
  a {
    text-decoration: none;
    color: #000;
  }
}
button.btn-custom {
  width: 213px !important;
  font-size: 17px !important;
}
.el-input__icon {
  width: 200px;
  padding-left: 80px !important;
  padding-right: 80px !important;
}
.el-form-item__content {
    line-height: 0px;

}
.info {
  display: block;
  font-size: 11px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  // letter-spacing: -0.4px;
  color: #4a90e2;

  &.center {
    line-height: 1.5;
    margin-top: 32px;
    text-align: center;
  }
}
.el-input {
  font-size: 17px !important;
}

</style>
<style lang="scss">
.pet-owner-register {
  .el-input__inner {
    padding-left: 44px !important;
    line-height: 1.36 !important;
  }
  .el-input__icon {
    width: 42px;
  }
  input::placeholder {
    // font-family: "kanit";
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: -0.4px;
    // color: #8e8e93;
  }
}
</style>

<style lang="scss" scoped>
.login-optional {
  .el-form-item__content {
    div {
      width: auto;
      display: inline-block;
      vertical-align: middle;

      .el-checkbox__label, a {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: 0.1px;
        text-align: center;
        color: #8e8e93;
        text-decoration: none;
      }
    }
    div:first-child {
      float: left;
    }
    div:last-child {
      float: right;
    }
  }
  .el-checkbox__inner {
    border-radius: 8px;
  }
}
</style>
