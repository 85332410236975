<template>
  <div>
    <table class="reward-table">
      <thead>
        <tr>
          <th class="no"></th>
          <th class="name">Reward Name</th>
          <th class="point">Health Point</th>
          <th class="detail">Detail</th>
          <th class="status">Status</th>
          <th class="action">Edit</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in rewards" :key="index">
          <td class="no">{{ (10 * (currentPage - 1)) + index + 1 }}</td>
          <td class="name">
            <div class="ellipsis">{{ item.name }}</div>
          </td>
          <td class="point">{{ item.use_point | number }}</td>
          <td class="detail">
            <div class="ellipsis">{{ item.detail }}</div>
          </td>
          <td class="status">
            <el-switch
              class="default-style"
              v-model="item.available"
              :active-value="true"
              :inactive-value="false"
              disabled
            ></el-switch>
          </td>
          <td class="action">
            <el-button
              size="mini"
              @click="$emit('selectToEdit', item)"
            >
              Edit
              <i class="el-icon-arrow-right el-icon-right"></i>
            </el-button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="not-found" v-if="rewards.length === 0">ไม่พบข้อมูลที่ต้องการค้นหา</div>
    <el-pagination
      class="pagination"
      background
      layout="prev, pager, next"
      :current-page="currentPage"
      :page-size="pageSize"
      :total="total"
      @current-change="(page) => $emit('current-change', page)"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    rewards: {
      type: Array,
      required: true
    },
    pageSize: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.el-button {
  width: 91px;
  height: 35px;
  border-radius: 5px;
  background-color: #02b0e1;
  color: white;

  font-family: "Kanit";
}

.reward-table {
  width: 100%;
  border-collapse: collapse;
  font-family: "Kanit", sans-serif;

  thead {
    tr {
      height: 54px;
      border-radius: 4px;
      border: #e6eaee 1px solid;

      th {
        padding: 5px;
        font-weight: normal;
        color: rgba(0, 0, 0, 0.48);
        background-color: #f5f8fa;
        text-align: center;
        font-size: 14px;
        font-family: "Kanit", sans-serif;

        &.no,
        &.status {
          width: 64px;
        }

        &.point {
          width: 80px;
        }

        &.name {
          width: 200px;
        }

        &.detail {
          width: 400px;
        }

        &.action {
          width: 100px;
        }
      }
    }
  }

  tbody {
    tr {
      height: 56px;
      border-bottom: 1px #eee solid;
      font-family: "Kanit", sans-serif;

      td {
        position: relative;
        padding: 5px;
        text-align: center;
        font-size: 14px;
        font-family: "Kanit", sans-serif;

        .ellipsis {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &.name > .ellipsis {
          text-align: left;
          width: 200px;
        }

        &.detail {
          > .ellipsis {
            text-align: left;
            width: 400px;
          }
        }
      }
    }
  }
}

.not-found {
  text-align: center;
  padding: 20px 0;
}

.pagination {
  margin-top: 20px;
  text-align: right;
}
</style>


