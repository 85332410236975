import { render, staticRenderFns } from "./ApptDetailPage.vue?vue&type=template&id=58485a1d&scoped=true&"
import script from "./ApptDetailPage.vue?vue&type=script&lang=js&"
export * from "./ApptDetailPage.vue?vue&type=script&lang=js&"
import style0 from "./ApptDetailPage.vue?vue&type=style&index=0&id=58485a1d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58485a1d",
  null
  
)

export default component.exports