/* eslint-disable no-case-declarations */
import _ from 'lodash'
import moment from 'moment'
import bloodProductTypeEnum from '@/constants/bloodbank/blood-product-type-enum.js'
import bloodCollectionHistoryActionEnum from '@/constants/bloodbank/blood-collect-history-action'
import bloodBagStatus from '../constants/bloodbank/blood-storage-bag-status'
import BloodBagHistoryActionEnums from '../constants/bloodbank/blood-collect-history-action'
import ContentTypeEnums from '../constants/bloodbank/content-type'
import store from '@/store/index'

export default class {
	static removeItemInArray(array, element) {
		const index = array.indexOf(element)
		if (index !== -1) {
			array.splice(index, 1)
		}
	}

	static hasClassInArray(classArray, className) {
		for (var i = 0; i < classArray.length; i++) {
			if (classArray[i] === className) {
				return true
			}
		}
		return false
	}

	static convertDateTimeToUtc(dateTime) {
		return new Date(dateTime).toISOString()
	}

	static convertBloodTypeEnumToServiceValue(type) {
		const types = {
			FWB: 0,
			SWB: 1,
			PRP: 2,
			FFP: 3,
			FP: 4,
			PRBCS: 5
		}

		return types[type]
	}

	static convertBloodTypeEnumToFullName(type) {
		const types = {
			FWB: 'Fresh Whole Blood (FWB)',
			SWB: 'Store Whole Blood (SWB)',
			PRP: 'Platelet Rich Plasma (PRP)',
			FFP: 'Fresh Flozen Plasma (FFP)',
			FP: 'Fresh Plasma (FP)',
			PRBCS: 'Packed RBCs (pRBCs)'
		}

		return types[type]
	}

	static convertBloodTypeServiceValueToEnum(type) {
		const enums = {
			0: bloodProductTypeEnum.FWB,
			1: bloodProductTypeEnum.SWB,
			2: bloodProductTypeEnum.PRP,
			3: bloodProductTypeEnum.FFP,
			4: bloodProductTypeEnum.FP,
			5: bloodProductTypeEnum.PRBCS
		}
		return enums[type]
	}

	static isToday(date) {
		var inputDate = date
		var todaysDate = new Date()

		return inputDate.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)
	}

	static convertBloodCollectionHistoryActionValueToEnum(value) {
		const enums = {
			0: bloodCollectionHistoryActionEnum.Collect,
			1: bloodCollectionHistoryActionEnum.Crossmatch,
			2: bloodCollectionHistoryActionEnum.Splitting,
			3: bloodCollectionHistoryActionEnum.Transform,
			4: bloodCollectionHistoryActionEnum.Reserve,
			5: bloodCollectionHistoryActionEnum.Disburse,
			6: bloodCollectionHistoryActionEnum.Remove_Move,
			7: bloodCollectionHistoryActionEnum.Remove_Expire,
			8: bloodCollectionHistoryActionEnum.Received
		}

		return enums[value]
	}

	static convertBloodCollectionHistoryActionEnumToValue(type) {
		const values = {
			Collect: 0,
			Crossmatch: 1,
			Splitting: 2,
			Transform: 3,
			Reserve: 4,
			Disburse: 5,
			Remove_Move: 6,
			Remove_Expire: 7
		}
		return values[type]
	}

  static getInventoryStatusTagLabel(status) {
		switch (status) {
			case bloodBagStatus.EXPIRE:
				return 'Expire'
      case bloodBagStatus.USED:
        return 'Used'
      case bloodBagStatus.AVAILABLE:
        return 'Available'
      case bloodBagStatus.RESERVE:
        return 'Reserve'
      case bloodBagStatus.TRANSFER:
        return 'Transfer'
			default:
				return '-'
		}
  }

  static getInventoryStatusTagColor(status) {
    switch (status) {
      case bloodBagStatus.EXPIRE:
      case bloodBagStatus.USED:
        return '#ff4f4a'
      case bloodBagStatus.AVAILABLE:
        return '#8fbe00'
      case bloodBagStatus.RESERVE:
      case bloodBagStatus.TRANSFER:
        return '#f5a623'
      default:
        return '#fff'
    }
  }

  static convertBloodBagHistoryInfoToTimelineInfo (bloodBagHistoryInfoes) {
    let result = []

    _.forEach(bloodBagHistoryInfoes, (item) => {
      result.push(this.makeTimelineInfo(item))
    })

    // Sort time
    return result
  }

  static makeTimelineInfo (bloodBagHistoryInfo) {
    // let bloodDonationInfo = BloodDonateFactory.getInstance().getDonationById()
    let result = {}
    result.action_enum = bloodBagHistoryInfo.action_enum
    result.action_timestamp = moment(bloodBagHistoryInfo.action_timestamp).format('YYYY-MM-DD')
    switch (bloodBagHistoryInfo.action_enum) {
      case BloodBagHistoryActionEnums.Collect:
        if (bloodBagHistoryInfo.content_type === ContentTypeEnums.BloodDonation) {
          // Donation Id
          result.bloodDonationId = bloodBagHistoryInfo.object_id
        } else {
          result.bloodDonationId = '-'
        }
        break
      case BloodBagHistoryActionEnums.Crossmatch:
        let crossmatchInfo = null
        _.forEach(store.state.bloodbank.requests, (bloodRequest) => {
          let allCrossmatch = _.union(bloodRequest.crossmatch.singleCrossmatchList, bloodRequest.crossmatch.multiCrossmatchList)
          _.forEach(allCrossmatch, (crossmatch) => {
            if (crossmatch.id === bloodBagHistoryInfo.object_id) {
              crossmatchInfo = crossmatch
            }
          })
        })

        if (crossmatchInfo) {
          // 3 ways case
          // สามสาย
          // Request Id: 1, vs Blood Bag Id: 3, Major: Passed, Minor: Not Passed

          // 2 ways case
          // สองสาย
          // Request Id: 1, vs Receipt, Major: Passed, Minor: Not Passed
          result.bloodRequestId = crossmatchInfo.bloodRequestId
          result.bloodBagId = crossmatchInfo.bloodBagId2 ? ` Blood Bag Id: ${crossmatchInfo.bloodBagId2}` : 'Receipt'
          result.Major = crossmatchInfo.isMajor ? 'Passed' : 'Not Passed'
          result.Minor = crossmatchInfo.isMinor ? 'Passed' : 'Not Passed'
        } else {
          result.bloodRequestId = '-'
          result.bloodBagId = '-'
          result.Major = '-'
          result.Minor = '-'
        }

        break
      case BloodBagHistoryActionEnums.Disburse:
        break
      case BloodBagHistoryActionEnums.Remove_Expire:
        break
      case BloodBagHistoryActionEnums.Remove_Move:
        // Share Public Stock
        // - Result
        // - - ProductType
        // - - Quantity
        // - - ID
        let bloodCollectInfo = _.find(store.state.bloodbank.bloodBags, (item) => {
          return item.id === bloodBagHistoryInfo.blood_collection
        })
        if (bloodCollectInfo) {
          result.bloodBagList = []

          let formattedQuantity = parseInt(bloodCollectInfo.quantity)
          result.bloodBagList.push(
            {
              productType: this.convertBloodTypeEnumToFullName(bloodCollectInfo.bloodType),
              quantity: `${formattedQuantity} ml.`,
              id: bloodCollectInfo.id
            }
          )
        }
        break
      case BloodBagHistoryActionEnums.Reserve:
        //   Reserve
        //   - Approver
        //   - Request ID
        let requestInfo = _.find(store.state.bloodbank.requests, (bloodRequest) => {
          return bloodRequest.reserveBlood.id === bloodBagHistoryInfo.object_id
        })
        if (requestInfo) {
          let reserveInfo = requestInfo.reserveBlood
          let approverInfo = _.find(store.state.hospital.vets, (employee) => {
            return employee.id === reserveInfo.approverId
          })
          result.bloodRequestId = reserveInfo.bloodRequestId
          result.approverName = approverInfo ? `${approverInfo.title} ${approverInfo.first_name} ${approverInfo.last_name}` : '-'
        }
        break
      case BloodBagHistoryActionEnums.Splitting:
      case BloodBagHistoryActionEnums.Transform:
        // Split
        // - List (Result)
        // - - Result
        // - - - ProductType
        // - - - Quantity
        // - - - ID

        let peerBloodBagIds = _.filter(store.state.bloodbank.bloodBagHistories, (item) => {
          return item.object_id === bloodBagHistoryInfo.object_id &&
            item.action === bloodBagHistoryInfo.action &&
            item.action_timestamp === bloodBagHistoryInfo.action_timestamp
        }).map(item => item.blood_collection)

        let bloodCollectInfoes = _.filter(store.state.bloodbank.bloodBags, (item) => {
          return _.indexOf(peerBloodBagIds, item.id) !== -1
        })

        // let bloodCollectInfoes = _.filter(bloodStorageFactory.getInstance().bloodBagList, (item) => {
        //   return item.transformedFromBag === bloodBagHistoryInfo.object_id || item.id === bloodBagHistoryInfo.object_id
        // })
        if (bloodCollectInfoes) {
          result.bloodBagList = []

          _.forEach(bloodCollectInfoes, (info) => {
            let formattedQuantity = parseInt(info.quantity)
            result.bloodBagList.push(
              {
                productType: this.convertBloodTypeEnumToFullName(info.bloodType),
                quantity: `${formattedQuantity} ml.`,
                id: info.id
              }
            )

            result.transformedFromBag = info.transformedFromBag
          })
        }
        break
      case BloodBagHistoryActionEnums.Received:
        const bloodRequest = store.getters['bloodbank/getBloodRequestById'](bloodBagHistoryInfo.object_id)

        if (bloodRequest) {
          result.bloodRequestId = bloodBagHistoryInfo.object_id
          const bloodBag = bloodRequest.reserveBlood.bloodBagList.find((bag) => {
            return bag.crossmatchInfo.bloodBagId1 === bloodBagHistoryInfo.blood_collection && bag.crossmatchInfo.bloodBagId2 == null
          })

          if (bloodBag) {
            result.quantity = bloodBag.quantity

            let approverInfo = _.find(store.state.hospital.vets, (employee) => {
              return employee.id === bloodRequest.receiveBlood.approver
            })
            result.approverName = `${approverInfo.title} ${approverInfo.first_name} ${approverInfo.last_name}`
          }
        }

        break;
      default:
        break
    }
    return result
  }

  static getParentBloodBagDonationId (bagList, parentBagId) {
    if (bagList == null) {
      bagList = store.state.bloodbank.bloodBags
    }

    if (bagList && bagList.length > 0) {
      const existingBag = bagList.find((bloodBag) => {
        return bloodBag.id === parentBagId
      })

      if (existingBag) {
        if (existingBag.transformedFromBag) {
          return this.getParentBloodBagDonationId(bagList, existingBag.transformedFromBag)
        } else {
          return existingBag.donationId == null || existingBag.donationId === 0 ? null : existingBag.donationId
        }
      }
    }
  }
}
