<template>
  <div>
    <div class="custom-row">
      <el-row :gutter="5">
        <el-col :span="isSmall? 8: 4">
          <label class="label">
            <img class="icon" src="@/assets/icons/icon-clock.png">วันที่ทำ
            <span class="required-mark">*</span>
          </label>
        </el-col>
        <el-col :span="isSmall? 16: 20">
          <div class="el-row">
            <el-col :span="isSmall? 24: 12">
              <el-date-picker
                type="date"
                placeholder="Pick a day"
                size="small"
                v-model="form.apptDate"
                @change="handleChange"
                style="margin-bottom: 5px"
              ></el-date-picker>
            </el-col>
            <el-col :span="isSmall? 24: 12">
              <el-time-select
                placeholder="Select time"
                size="small"
                v-model="form.apptTime"
                :picker-options="{
                  start: '00:00',
                  step: '00:15',
                  end: '24:45'
                }"
                format="HH:mm"
                @change="handleChange"
              ></el-time-select>
            </el-col>
          </div>
        </el-col>
      </el-row>
      <div class="section">
        <h3 class="label">
          <img class="icon" src="@/assets/icons/icon-vaccine.png">
          กิจกรรม ({{ form.activities.length }})
          <span class="required-mark">*</span>
        </h3>
        <div class="activitie-list">
          <transition-group name="fade" tag="div">
            <div v-for="activity in form.activities" :key="activity.id">
              <ActivityEditItem
                :showThumbnail="!isSmall"
                :activity="activity"
                @change="handleActivityChange"
                @delete="handleActivityDelete"
              />
            </div>
          </transition-group>
        </div>
        <div class="total">
          <div class="label">Total</div>
          <div class="value">{{ total | currency }}฿</div>
        </div>
      </div>
      <div class="section">
        <div class="sub-section">
          <label class="label">
            <img src="@/assets/icons/icon-doctor.png" class="icon">สัตวแพทย์
          </label>
          <div>
            <el-select
              v-model="form.selectedVetId"
              placeholder="Select"
              size="small"
              @change="handleChange"
            >
              <el-option :key="-1" :value="-1" label="ไม่ระบุ"></el-option>
              <el-option
                v-for="vet in vets"
                :key="vet.id"
                :value="vet.id"
                :label="`${vet.first_name} ${vet.last_name}`"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="sub-section">
          <label class="label">
            <img src="@/assets/icons/icon-notepad.png" class="icon">บันทึกเพิ่มเติม
          </label>
          <div>
            <el-input
              v-model="form.note"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="Please input"
              @change="handleChange"
              size="small"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="section clearfix">
        <FileUploader
          style="float: left; width: 110px; height: 110px; margin: 4px;"
          :isUploading="isUploading"
          @onSelect="handleSelectFiles"
        />
        <FilePreviewer
          v-for="file in this.form.attachments"
          :key="file.id"
          style="float: left; width: 110px; height: 110px; margin: 4px;"
          :file="file"
          :showDeleteButton="true"
          @delete="handleDeleteUploadFile"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment"
import { combineDateTime, roundToQuarterHour } from "@/utils/datetime"
import ActivityEditItem from "@/components/activity/ActivityEditItem"
import FileUploader from "@/components/upload/FileUploader"
import FilePreviewer from "@/components/upload/FilePreviewer"

export default {
  props: ["appt", "activities", "vets", "isSmall"],
  components: {
    ActivityEditItem,
    FileUploader,
    FilePreviewer
  },
  data() {
    return {
      form: {
        apptDate: this.appt.date,
        apptTime: roundToQuarterHour(this.appt.date).format("HH:mm"),
        attachments: this.appt.attachments,
        activities: this.activities || [],
        selectedVetId: this.appt.vet ? this.appt.vet.id : -1,
        note: ""
      },
      isUploading: false
    }
  },
  computed: {
    total() {
      return this.form.activities.reduce((total, item) => {
        if (item.price) {
          return total + parseFloat(item.price)
        }
        return total
      }, 0)
    },
    apptDateTime() {
      const timeToDate = moment(this.apptTime, "HH:mm").toDate();
      return combineDateTime(this.apptDate, timeToDate)
    }
  },
  methods: {
    handleActivityDelete(data) {
      this.form.activities = this.form.activities.filter(
        item => item.id !== data.id
      )
      this.handleChange()
    },
    handleActivityChange(data) {
      this.form.activities = this.form.activities.map(item => {
        if (item.id === data.id) {
          return data
        }
        return item
      })
      this.handleChange()
    },
    handleChange() {
      const data = {
        activities: this.form.activities,
        attachments: this.form.attachments,
        vetId: this.form.selectedVetId,
        note: this.form.note
      }
      const timeToDate = moment(this.form.apptTime, "HH:mm").toDate();
      const dt = combineDateTime(this.form.apptDate, timeToDate)
      if (moment.isDate(dt)) {
        data["date"] = dt
      } else {
        data["date"] = null
      }
      this.$emit("change", data)
    },
    async handleSelectFiles(files) {
      try {
        this.isUploading = true
        for (let index = 0; index < files.length; index++) {
          await this.uploadFile(files[index])
        }
        this.isUploading = false
        this.$notify({
          message: `อัพโหลดไฟล์เรียบร้อยแล้ว`,
          type: "success"
        })
        this.handleChange()
      } catch (error) {
        this.isUploading = false
      }
    },
    async uploadFile(file) {
      try {
        let formData = new FormData()
        formData.append("attachment", file)
        const response = await this.$store.dispatch("appointment/upload", {
          formData
        })
        this.form.attachments = [response, ...this.form.attachments]
      } catch (error) {
        this.$notify({
          message: "ไม่สามารถอัพโหลดไฟล์ " + file.name,
          type: "error"
        })
      }
    },
    handleDeleteUploadFile(file) {
      this.form.attachments = this.form.attachments.filter(
        item => item.id !== file.id
      )
      this.handleChange()
    }
  },
  watch: {
    activities(newValue) {
      if (newValue) {
        this.form.activities = newValue
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-select,
.el-input {
  width: 100%;
}

.label {
  color: $light-blue;
  display: inline-block;
  margin-bottom: 10px;
}

.section {
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
  width: 100%;

  &:last-child {
    border-bottom: none;
  }
}
.sub-section {
  margin: 10px 0;
}
.total {
  display: flex;
  padding-top: 15px;

  .label {
    display: flex;
    flex: 3;
    font-weight: bold;
    color: #333;
  }
  .value {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    line-height: 1em;
    font-size: 15px;
    font-weight: bold;
  }
}
</style>

