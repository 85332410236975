import StaffListPage from '@/pages/staff/StaffListPage.vue'
import StaffDetail from '@/pages/staff/StaffDetail.vue'

const routes = [
  {
    path: '/staff',
    name: 'staff',
    component: StaffListPage,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['setting_section.can_use_staff_setting']
    }
  },
  {
    path: '/staff/add',
    name: 'staff-add',
    component: StaffDetail,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['setting_section.can_use_staff_setting']
    }
  },
  {
    path: '/staff/:id',
    name: 'staff-detail',
    component: StaffDetail,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['setting_section.can_use_staff_setting']
    }
  }
]

export default routes