import * as vuexHelper from "../helpers/vuexHelper";
import { Message } from 'element-ui';

const config = {
  state: {
    isSidebarCollapse: false,
    isFullScreenLoading: false,
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    showFetchErrorMsg({ commit }, msg) {
      Message({
        message:
          msg || "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบข้อมูลอีกครั้ง",
        type: "error",
        showClose: true,
        duration: 0
      });
    },
    showFetchErrorWithReloadMsg() {
      Message({
        dangerouslyUseHTMLString: true,
        message:
          "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบข้อมูลอีกครั้ง <a class='reload-link' href='javascript:location.reload()'>คลิกเพื่อ Reload</a>",
        type: "error",
        duration: 0
      });
    },
  }
};

export default vuexHelper.createStoreModule(config);
