import * as vuexHelper from "../helpers/vuexHelper";

import { coreAPI } from "../../services/apis";
import * as endpoints from "../../services/endpoints";

const config = {
  default: {
    api: coreAPI
  },
  state: {
    petowners: {},
    pets: {},
    bloodbank: {},
    visitoverview: {},
    petvisitfrequency: null,
    petexpencerate: null,
    ownervisitfrequency: null,
    ownerexpencerate: null,
  },
  asyncActions: [
    {
      name: "getPetowners",
      method: "get",
      url: endpoints.reportPetOwners,
      setState: "petowners"
    },
    {
      name: "getPets",
      method: "get",
      url: endpoints.reportPets,
      setState: "pets"
    },
    {
      name: "getBloodbank",
      method: "get",
      url: ({ start, end }) => endpoints.reportBloodbank+start+"/"+end+"/",
      setState: "bloodbank"
    },
    {
      name: "getExpenceVisitOverview",
      method: "get",
      url: endpoints.reportPetVisitExpenceOverview,
      setState: "visitoverview"
    },
    {
      name: "getPetExpenceVisitSummary",
      method: "get",
      url: endpoints.reportPetVisitExpenceRateSummary
    },
    {
      name: "getPetExpenceVisitAge",
      method: "get",
      url: endpoints.reportPetVisitExpenceRateAge,
    },
    {
      name: "getPetExpenceVisitBreed",
      method: "get",
      url: endpoints.reportPetVisitExpenceRateBreed
    },
    {
      name: "getPetExpenceVisitPets",
      method: "get",
      url: endpoints.reportPetVisitExpenceRatePets
    },
    {
      name: "getPetVisitFrequency",
      method: "get",
      url: endpoints.reportPetVisitFrequency,
      setState: "petvisitfrequency"
    },
    {
      name: "getPetExpenceRate",
      method: "get",
      url: endpoints.reportPetExpenceRate,
      setState: "petexpencerate"
    },
    {
      name: "getOwnerVisitFrequency",
      method: "get",
      url: endpoints.reportOwnerVisitFrequency,
      setState: "ownervisitfrequency"
    },
    {
      name: "getOwnerExpenceRate",
      method: "get",
      url: endpoints.reportOwnerExpenceRate,
      setState: "ownerexpencerate"
    },
    {
      name: "getOwnerExpenceVisitSummary",
      method: "get",
      url: endpoints.reportOwnerVisitExpenceRateSummary
    },
    {
      name: "getOwnerExpenceVisitAge",
      method: "get",
      url: endpoints.reportOwnerVisitExpenceRateAge,
    },
    {
      name: "getOwnerExpenceVisitOwners",
      method: "get",
      url: endpoints.reportOwnerVisitExpenceRateOwners
    },
    {
      name: "getFinanceAndCaseCompare",
      method: "get",
      url: endpoints.reportFinanceAndCaseCompare
    },
    {
      name: "getAndOwnerCompare",
      method: "get",
      url: endpoints.reportPetAndOwernCompare
    },
    {
      name: "getAppointmentCompare",
      method: "get",
      url: endpoints.reportAppointmentCompare
    },
  ]
};

export default vuexHelper.createStoreModule(config);
