import DonorCareInfo from '../donor-care-info/donor-care-info'
import BloodCollectInfo from '../blood-collect-info/blood-collect-info'
import MedicalRecordDonation from '../../common/medical-record/medical-record-blood-donation'
import LabExam from '../../common/lab-exam/lab-exam'
import PetInfo from '../../common/pets-info/pet-info/pet-info'
import store from '@/store/index'
export default class {
	constructor(obj) {
		if (obj != null) {
			this.bloodDonationId = obj.id == null ? null : obj.id
			this.hospitalId = obj.hospital == null ? null : obj.hospital
			this.isDonorProfileSubmitted =
				obj.is_Profile_Submitted == null ? false : obj.is_Profile_Submitted
			this.isPhysicalExamSubmitted =
				obj.is_physical_exam_submitted == null
					? false
					: obj.is_physical_exam_submitted
			this.isLabExamSubmitted =
				obj.is_lab_exam_submitted == null ? false : obj.is_lab_exam_submitted
			this.isDonorCareSubmitted =
				obj.is_donor_care_submitted == null
					? false
					: obj.is_donor_care_submitted
			this.isBloodCollectSubmitted =
				obj.is_blood_collect_submitted == null
					? false
					: obj.is_blood_collect_submitted
			this.medicalRecord =
				obj.medicalRecord == null
					? new MedicalRecordDonation()
					: obj.medicalRecord
			this.labExam = obj.labExam == null ? new LabExam() : obj.labExam
			this.donorCareInfo =
				obj.donorCareInfo == null ? new DonorCareInfo() : obj.donorCareInfo
			this.bloodCollectInfo =
				obj.bloodCollectInfo == null
					? new BloodCollectInfo()
					: obj.bloodCollectInfo
			this.pet = obj.pet == null ? new PetInfo() : obj.pet
			this.itaampetId = obj.itaampet == null ? null : obj.itaampet
			this.isTicketRejected =
				obj.is_ticket_rejected == null ? null : obj.is_ticket_rejected
			this.created = obj.created == null ? null : obj.created
			this.modifiedDate = obj.last_updated == null ? null : obj.last_updated
		} else {
			this.bloodDonationId = null
			this.isDonorProfileSubmitted = false
			this.isPhysicalExamSubmitted = false
			this.isLabExamSubmitted = false
			this.isDonorCareSubmitted = false
			this.isBloodCollectSubmitted = false
			this.medicalRecord = new MedicalRecordDonation()
			this.labExam = new LabExam()
			this.donorCareInfo = new DonorCareInfo()
			this.bloodCollectInfo = new BloodCollectInfo()
			this.pet = new PetInfo()
			this.itaampetId = null
			this.isTicketRejected = false
			this.created = null
			this.modifiedDate = null
			this.hospitalId = store.state.hospital.profile.id
		}
	}

	isCaseClosed() {
		return (
			this.isTicketRejected ||
			this.isBloodCollectSubmitted ||
			(this.isDonorCareSubmitted && !this.donorCareInfo.canDonate)
		)
	}

	toRequestJson() {
		this.bloodCollectInfo.pcv = Number(this.labExam.HCT)
		return {
			id: this.bloodDonationId,
			is_Profile_Submitted: this.isDonorProfileSubmitted,
			is_physical_exam_submitted: this.isPhysicalExamSubmitted,
			is_lab_exam_submitted: this.isLabExamSubmitted,
			is_donor_care_submitted: this.isDonorCareSubmitted,
			is_blood_collect_submitted: this.isBloodCollectSubmitted,
			medicalRecord: this.medicalRecord.toRequestJson(),
			labExam: this.labExam.toRequestJson(),
			donorCareInfo: this.donorCareInfo.toRequestJson(),
			bloodCollectInfo: this.bloodCollectInfo.toRequestJson(),
			pet: this.pet,
			itaampet: this.pet.id,
			is_ticket_rejected: !(
				this.isTicketRejected == null || !this.isTicketRejected
			),
			hospital: this.hospitalId,
			created: this.created
		}
	}

	getProgressList() {
		return [
			{
				title: 'Donor Submit',
				state: this.isDonorProfileSubmitted ? 'finish' : 'process',
				status: this.isDonorProfileSubmitted ? 'finish' : 'process',
				img: 'notepad.png'
			},
			{
				title: 'Physical Exam',
				state: this.isPhysicalExamSubmitted ? 'finish' : 'process',
				status: this.isPhysicalExamSubmitted ? 'finish' : 'process',
				img: 'file.png'
			},
			{
				title: 'Lab',
				state: this.isLabExamSubmitted ? 'finish' : 'process',
				status: this.isLabExamSubmitted ? 'finish' : 'process',
				img: 'calendar.png'
			},
			{
				title: 'Donor Care',
				state: this.isDonorCareSubmitted ? 'finish' : 'process',
				status: this.isDonorCareSubmitted ? 'finish' : 'process',
				img: 'syringe.png'
			},
			{
				title: 'Blood Collect',
				state: this.isBloodCollectSubmitted ? 'finish' : 'process',
				status: this.isBloodCollectSubmitted ? 'finish' : 'process',
				img: 'blood-transfusion.png'
			}
		]
	}

	getCurrentProgress() {
		let i = this.getProgressList().findIndex(p => p.status === 'process')
		return i >= 0 ? i : this.getProgressList().length // If no process found = finish all
	}

	cloneFrom(obj) {
		this.bloodDonationId = obj.bloodDonationId
		this.hospitalId = obj.hospitalId
		this.isDonorProfileSubmitted = obj.isDonorProfileSubmitted
		this.isPhysicalExamSubmitted = obj.isPhysicalExamSubmitted
		this.isLabExamSubmitted = obj.isLabExamSubmitted
		this.isDonorCareSubmitted = obj.isDonorCareSubmitted
		this.isBloodCollectSubmitted = obj.isBloodCollectSubmitted
		this.medicalRecord = new MedicalRecordDonation(obj.medicalRecord.toRequestJson())
		this.labExam = new LabExam(obj.labExam.toRequestJson())
		this.donorCareInfo = new DonorCareInfo(obj.donorCareInfo.toRequestJson())
		this.bloodCollectInfo = new BloodCollectInfo(obj.bloodCollectInfo.toRequestJson())
		this.pet.cloneFrom(obj.pet)
		this.itaampetId = obj.itaampetId
		this.isTicketRejected = obj.isTicketRejected
		this.created = obj.created
		this.modifiedDate = obj.modifiedDate
	}
}
