<template>
  <div class="inventory-detail">

    <!-- Sub Navbar -->
    <Subnavbar>
      <template slot="breadcrumb">
      </template>
      <template slot="title">BLOOD INVENTORY DETAIL</template>
      <template slot="actions" class="action-wrapper">
        <div v-show="selectedBloodBag && hospital && selectedBloodBag.hospitalId === hospital.id && selectedBloodBag.quantity > 0 && selectedBloodBag.reasonOfRemoval == null && selectedBloodBag.status !== bloodBagStatus.EXPIRE && selectedBloodBag.status !== bloodBagStatus.USED">
          <button class="btn btn-large btn-primary" @click="onTransformClicked">
            <img src="@/assets/bloodbank/btn-spin.png" />
            ปั่นแยก
          </button>
          <button class="btn btn-large btn-primary" @click="onSplitClicked">
            <img src="@/assets/bloodbank/btn-separate.png" />
            แบ่งเลือด
          </button>
          <button class="btn btn-large btn-primary" @click="isRemoveBloodBagDialogShown = true">
            <i class="fa fa-minus"></i>Remove
          </button>
        </div>
      </template>
    </Subnavbar>

    <el-row :gutter="10">
      <!-- Inventory Info -->
      <el-col :md="8">
        <BloodInventoryInfo :blood-bag="selectedBloodBag"></BloodInventoryInfo>
      </el-col>

      <!-- Timeline & Donation -->
      <el-col :md="16" class="content-padding">
        <el-tabs v-model="activeTab">
          <el-tab-pane label="Timeline" name="timeline">
            <BloodInventoryDetailTimeline :blood-bag="selectedBloodBag" :histories="selectedBloodBagHistories">
            </BloodInventoryDetailTimeline>
          </el-tab-pane>
          <el-tab-pane label="Donation" name="donation" v-if="donationId !== 0 && donationId != null && bloodBagDonation != null">
            <BloodDonationTimeline v-if="bloodBagDonation" :donation="bloodBagDonation" @action="btnAction">
            </BloodDonationTimeline>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>

    <!-- Modals -->
    <blood-inventory-transform-modal-content v-if="selectedBloodBag" :blood-bag="selectedBloodBag" @save="saveTransformBag" @close="closeTransformDialog" :is-shown="isTransformDialogShown" mode="transform"></blood-inventory-transform-modal-content>

    <blood-inventory-transform-modal-content v-if="selectedBloodBag" :blood-bag="selectedBloodBag" @save="saveTransformBag" @close="closeSplitBloodBagDialog" :is-shown="isSplitDialogShown" mode="split"></blood-inventory-transform-modal-content>

    <at-modal v-model="isRemoveBloodBagDialogShown" :showClose="false" :maskClosable="false" :show-footer="false">
      <div slot="header">
        <el-row type="flex" align="middle">
          <el-col :span="12">
            Remove Blood Bag
          </el-col>

          <el-col :span="12" class="text-right">
            <el-button class="btn btn-large btn-plain hoverable" @click="isRemoveBloodBagDialogShown = false">Cancel</el-button>
            <el-button class="btn-large" icon="fa fa-save" @click="removeBloodBag" type="primary">Save</el-button>
          </el-col>
        </el-row>
      </div>

      <div class="at-container">
        <div class="row">
          <div class="col-md-10 flex-end flex-middle" style="padding: 5px; padding-top: 7px;">
            <label>Blood Bag ID:</label>
          </div>

          <div class="col-md-14 flex-start flex-middle" style="padding: 5px; padding-top: 7px;">
            <p style="margin: 0;">{{ selectedBloodBag && selectedBloodBag.id }}</p>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class="col-md-10 flex-end flex-middle" style="padding: 5px;">
            <label>Reason of Removal:</label>
          </div>

          <div class="col-md-14 flex-start flex-middle">
            <div class="row no-glutter" style="padding: 5px;">
              <at-radio v-model="removeBloodBagObj.reasonOfRemoval" label="EXPIRE">หมดอายุ</at-radio>
            </div>
            <div class="row no-glutter" style="padding: 5px;">
              <at-radio v-model="removeBloodBagObj.reasonOfRemoval" label="GIVE_AWAY" style="margin-top: 3px;">ให้โรงพยาบาล</at-radio>

              <div v-if="hospital && removeBloodBagObj.reasonOfRemoval === removalBloodBagReasonEnum.GIVE_AWAY" class="row no-glutter" style="padding: 5px;">
                <at-select v-model="removeBloodBagObj.hospital" style="width:220px">
                  <at-option v-for="(hospital, idx) in otherHospitals" :key="idx" v-bind:value="hospital.id"  >{{hospital.name}}</at-option>
                  <at-option value="OTHER">อื่น ๆ</at-option>
                </at-select>
              </div>

              <at-input v-if="removeBloodBagObj.reasonOfRemoval === removalBloodBagReasonEnum.GIVE_AWAY && removeBloodBagObj.hospital === 'OTHER'" placeholder="ชื่อโรงพยาบาล" v-model="removeBloodBagObj.givenHospitalName" size="small"></at-input>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-10 flex-end flex-middle" style="padding: 5px; padding-top: 7px;">
            <label>หมายเหตุ:</label>
          </div>

          <div class="col-md-14 flex-start flex-middle" style="padding: 5px;">
            <!--<at-textarea v-if="isRemoveBloodBagDialogShown" v-model="removeBloodBagObj.note" max-rows="1"></at-textarea>-->
            <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 3}" placeholder="บันทึกเพิ่มเติม ..." v-model="removeBloodBagObj.note"></el-input>
          </div>
        </div>
      </div>
    </at-modal>

  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import Subnavbar from '../../../components/navigation/Subnavbar'
  import BloodInventoryInfo from '../../../components/bloodbank/blood-storage/BloodInventoryInfo'
  import utils from '../../../utils/bloodbank'
  import BloodInventoryDetailTimeline from '../../../components/bloodbank/blood-storage/BloodInventoryDetailTimeline'
  import BloodDonationTimeline from '../../../components/bloodbank/blood-donate/BloodDonationTimeline'
  import BloodInventoryTransformModalContent
    from '../../../components/bloodbank/blood-storage/BloodInventoryTransformModalContent'
  import removalBloodBagReasonEnum from '../../../constants/bloodbank/removal-blood-bag-reason'
  import moment from 'moment'
  import BloodBagHistoryInfo from '../../../models/data/blood-storage/blood-bag-history-info/blood-bag-history-info'
  import bloodCollectionHistoryActionEnums from '../../../constants/bloodbank/blood-collect-history-action.js'
  import contentTypeEnums from '../../../constants/bloodbank/content-type'
  import clone from 'clone'
  import bloodBagStatus from '../../../constants/bloodbank/blood-storage-bag-status'
  import printJS from 'print-js'

  export default {
    name: "BloodInventoryDetailPage",
    components: {
      BloodInventoryTransformModalContent,
      BloodDonationTimeline, BloodInventoryDetailTimeline, BloodInventoryInfo, Subnavbar},
    data () {
      return {
        removalBloodBagReasonEnum,
        bloodCollectionHistoryActionEnums,
        contentTypeEnums,
        bloodBagStatus,

        activeTab: 'timeline',

        bloodBagId: null,
        selectedBloodBag: null,
        selectedBloodBagHistories: null,
        bloodBagDonation: null,
        donationId: 0,

        isTransformDialogShown: false,
        isSplitDialogShown: false,
        isRemoveBloodBagDialogShown: false,
        removeBloodBagObj: {
          bloodBagId: null,
          reasonOfRemoval: removalBloodBagReasonEnum.EXPIRE,
          hospital: 'OTHER',
          givenHospitalName: '',
          note: ''
        },
      }
    },
    methods: {
      reactivate: function () {
        this.donationId = null
        if(this.selectedBloodBag.donationId && this.selectedBloodBag.donationId !== 0){
          this.donationId = this.selectedBloodBag.donationId
        } else if (this.selectedBloodBag.transformedFromBag) {
          this.donationId = utils.getParentBloodBagDonationId(null, this.selectedBloodBag.transformedFromBag)
        } else if (this.selectedBloodBag.aliasDonation) {
          this.donationId = this.selectedBloodBag.aliasDonation
        }

        if (this.donationId && this.donationId !== 0) {
          this.bloodBagDonation = this.$store.getters['bloodbank/getDonationById'](this.donationId)
        }

        setTimeout(() => this.$forceUpdate())
      },
      loadBloodBagHistoriesTimelineInfoes() {
        const histories = this.$store.getters['bloodbank/getBloodBagHistoriesByBloodBagId'](this.bloodBagId)
        this.selectedBloodBagHistories = utils.convertBloodBagHistoryInfoToTimelineInfo(histories)
      },
      btnAction(i) {
        switch (i) {
          case 1: this.$router.push({ name: 'donationExam', params: {id: this.donationId}}); break;
          case 2: this.$router.push({ name: 'donationLab', params: {id: this.donationId}}); break;
          case 3: this.$router.push({ name: 'donationCare', params: {id: this.donationId}}); break;
          case 4: this.$router.push({ name: 'donationCollect', params: {id: this.donationId}}); break;
        }
      },

      onTransformClicked: function () {
        this.isTransformDialogShown = true
      },

      onSplitClicked: function () {
        this.isSplitDialogShown = true
      },
      closeSplitBloodBagDialog: function () {
        this.isSplitDialogShown = false
      },
      closeTransformDialog: function () {
        this.isTransformDialogShown = false
      },
      saveTransformBag: async function (e, transformDate, isSplit) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);

        let transformedBag = this.selectedBloodBag
        let rawTransformBags = e
        let transformBags = []
        let sumOftransformedQuantity = 0
        transformedBag.petType = transformedBag.getPetType()
        rawTransformBags.forEach(rawTransformBag => {
          if (rawTransformBag.type &&
            rawTransformBag.expireDate &&
            rawTransformBag.amount) {
            let newBloodBag = clone(transformedBag)
            newBloodBag.transformedFromBag = transformedBag.id
            newBloodBag.id = null
            newBloodBag.quantity = parseFloat(rawTransformBag.amount)
            newBloodBag.bloodType = rawTransformBag.type
            newBloodBag.dateCollection = transformDate
            newBloodBag.expiryDate = rawTransformBag.expireDate
            sumOftransformedQuantity += parseFloat(rawTransformBag.amount)
            transformBags.push(newBloodBag)
          }
        })

        if (isSplit && (sumOftransformedQuantity > transformedBag.quantity)) {
          await this.$store.dispatch("ui/setIsFullScreenLoading", false);
          return
        } else {
          if (sumOftransformedQuantity) {
            if (isSplit) {
              transformedBag.quantity -= parseFloat(sumOftransformedQuantity)
            } else {
              transformedBag.quantity = 0
            }
            this.$store.dispatch('bloodbank/updateFullBloodCollectInfo', {
              id: transformedBag.id,
              data: transformedBag
            })
            // update store
            this.$store.commit('bloodbank/UPDATE_BLOOD_BAG', transformedBag)

            let newBloodCollectionHistoryInfo = new BloodBagHistoryInfo()
            // Set Info Here

            newBloodCollectionHistoryInfo.blood_collection = transformedBag.id
            newBloodCollectionHistoryInfo.action = isSplit ? this.bloodCollectionHistoryActionEnums.Splitting : this.bloodCollectionHistoryActionEnums.Transform
            newBloodCollectionHistoryInfo.content_type = this.contentTypeEnums.BloodCollection
            newBloodCollectionHistoryInfo.object_id = transformedBag.id

            // bloodCollectionHistoryInstance.addBloodBagHistoryInfo(newBloodCollectionHistoryInfo)
            this.$store.dispatch('bloodbank/createBloodCollectionHistory', {data: newBloodCollectionHistoryInfo.toRequestJson()})

            if (transformBags.length > 0) {
              transformBags.forEach(transformBag => {
                this.$store.dispatch('bloodbank/createBloodCollectInfo', {data: transformBag.toRequestJson()}).then((result) => {
                  if (result) {
                    let newBloodCollectionHistoryInfo = new BloodBagHistoryInfo()
                    // Set Info Here

                    newBloodCollectionHistoryInfo.blood_collection = result.id
                    newBloodCollectionHistoryInfo.action = isSplit ? this.bloodCollectionHistoryActionEnums.Splitting : this.bloodCollectionHistoryActionEnums.Transform
                    newBloodCollectionHistoryInfo.content_type = this.contentTypeEnums.BloodCollection
                    newBloodCollectionHistoryInfo.object_id = transformedBag.id

                    // bloodCollectionHistoryInstance.addBloodBagHistoryInfo(newBloodCollectionHistoryInfo)
                    this.$store.dispatch('bloodbank/createBloodCollectionHistory', {data: newBloodCollectionHistoryInfo.toRequestJson()})
                      .then(async () => {
                        await this.$store.dispatch("bloodbank/listAndFillBloodCollectInfo")
                        await this.$store.dispatch('bloodbank/listBloodCollectionHistory')
                      })
                  }
                })
              })
            }
          }
        }

        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        this.isTransformDialogShown = false
        this.isSplitDialogShown = false
      },
      removeBloodBag: async function () {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        var removeBloodBag = this.$store.getters['bloodbank/getClonedBloodBagById'](this.removeBloodBagObj.bloodBagId)
        if (this.removeBloodBagObj.reasonOfRemoval) {
          removeBloodBag.reasonOfRemoval = this.removeBloodBagObj.reasonOfRemoval
          if (this.removeBloodBagObj.reasonOfRemoval === removalBloodBagReasonEnum.GIVE_AWAY) {
            if (this.removeBloodBagObj.hospital !== 'OTHER') {
              removeBloodBag.givenByHospital = this.hospital.id
              removeBloodBag.givenToHospitalName = this.$store.getters['hospital/getHospitalById'](this.removeBloodBagObj.hospital).name
            } else {
              removeBloodBag.givenToHospitalName = this.removeBloodBagObj.givenHospitalName
            }
          }

          removeBloodBag.note = this.removeBloodBagObj.note

          let newBloodCollectionHistoryInfo = new BloodBagHistoryInfo()
          // Set Info Here
          newBloodCollectionHistoryInfo.blood_collection = this.removeBloodBagObj.bloodBagId
          newBloodCollectionHistoryInfo.action = this.removeBloodBagObj.reasonOfRemoval === removalBloodBagReasonEnum.GIVE_AWAY
            ? this.bloodCollectionHistoryActionEnums.Remove_Move
            : this.bloodCollectionHistoryActionEnums.Remove_Expire
          newBloodCollectionHistoryInfo.content_type = this.contentTypeEnums.BloodCollection
          newBloodCollectionHistoryInfo.object_id = this.removeBloodBagObj.bloodBagId

          // bloodCollectionHistoryInstance.addBloodBagHistoryInfo(newBloodCollectionHistoryInfo)
          this.$store.dispatch('bloodbank/createBloodCollectionHistory', {data: newBloodCollectionHistoryInfo.toRequestJson()})

          this.$store.dispatch('bloodbank/updateFullBloodCollectInfo', {
            id: removeBloodBag.id,
            data: removeBloodBag
          }).then(async () => {

            await this.$store.dispatch("bloodbank/listAndFillBloodCollectInfo")
            await this.$store.dispatch('bloodbank/listBloodCollectionHistory')
            this.$router.push({name: 'inventory'})
          }).catch(() => {
          })
        }

        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        this.isRemoveBloodBagDialogShown = false
      },
      onPrintClicked: function () {
        window.moment = moment
        let elem = document.getElementById('printTemplate')
        elem.style.display = 'inline-block'
        printJS({printable: 'printTemplate',
          type: 'html',
          targetStyles: ['*']
        })
        elem.style.display = 'none'
      },

      setBloodBag() {
        this.removeBloodBagObj.bloodBagId = this.bloodBagId

        // clone blood bag from store
        this.selectedBloodBag = this.$store.getters['bloodbank/getClonedBloodBagById'](this.bloodBagId)

        this.loadBloodBagHistoriesTimelineInfoes()
      },
    },
    computed: {
      ...mapState({
        donations: state => state.bloodbank.donations,
        requests: state => state.bloodbank.requests,
        bloodBags: state => state.bloodbank.bloodBags,
        bloodBagHistories: state => state.bloodbank.bloodBagHistories,
        vets: state => state.hospital.vets,
        hospital: state => state.hospital.profile,
        hospitals: state => state.hospital.hospitals,
      }),
      otherHospitals() {
        if (!this.hospitals || !this.hospital) {
          return []
        }
        return this.hospitals.filter(x => x.id !== this.hospital.id)
      }
    },
    async created() {
      await this.$store.dispatch("ui/setIsFullScreenLoading", true);

      try {
        // List&Fill Donations
        if (this.donations.length < 1) {
          await this.$store.dispatch("bloodbank/listAndFillBloodDonations")
        }
        // List&Fill Requests
        if (this.requests.length < 1) {
          await this.$store.dispatch("bloodbank/listAndFillBloodRequests")
        }
        // List Vets
        if (this.vets.length < 1) {
          await this.$store.dispatch("hospital/listVets")
        }
        // List Hospitals
        if (this.hospitals.length < 1) {
          await this.$store.dispatch('hospital/listHospitals')
        }

        // List Blood Bags
        if (this.bloodBags.length < 1) {
          await this.$store.dispatch("bloodbank/listAndFillBloodCollectInfo")
        }
        if (this.bloodBagHistories.length < 1) {
          await this.$store.dispatch("bloodbank/listBloodCollectionHistory")
        }
      } catch (e) {
        await this.$store.dispatch("ui/showFetchErrorMsg");
      }

      this.bloodBagId = Number(this.$route.params['id'])
      this.setBloodBag()
      this.reactivate()

      await this.$store.dispatch("ui/setIsFullScreenLoading", false);
    },
    watch: {
      '$route': 'reactivate'
    },
    beforeRouteUpdate(to, from, next) {
      // eslint-disable-next-line no-console
      console.log(`navigate ${to.params['id']}`)
      this.bloodBagId = Number(to.params['id'])
      this.setBloodBag()
      this.reactivate()
      next()
    }
  }
</script>

<style lang="scss" scoped>
  .el-button {
    img {
      width: 20px;
      height: 20px;
      object-fit: contain;

      display: inline;
      vertical-align: middle;
    }

    &.side-button {
      width: 100%;
      margin-top: 5px;
      margin-bottom: 15px;
    }

    &.action-btn {
      color: #156f88;
      border-radius: 5px;
      border: none;
      &:not(:hover) {
        background-color: #c2e5f0;
      }
    }
  }
</style>

<style lang="scss">
  .inventory-detail {

    .right-col > div {
      display: flex;
    }

    .btn-primary {
      img {
        height: 24px;
        vertical-align: middle;
      }
    }

    h1 {
      margin-left: 25px;
    }


    // .el-dropdown-menu {
    //   border: none !important;
    //   background-color: #324a5e !important;
    //   li {
    //     color: white !important;
    //     &:hover {
    //       background-color: #436480 !important;
    //     }
    //   }
    //   ul > div {
    //     display: none !important;
    //   }
    // }

    .content-padding {
      padding: 16px 12px 0px 46px !important;
    }
    // html {min-height:100%;}
    // body {height:100%}

    .el-tabs__item {
      font-family: default;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #8e8e93;

      &.is-active {
        color: #000000;
      }
    }

    .el-tabs__active-bar {
      height: 3px;
    }

    .el-tabs__nav-wrap::after {
      background: none;
    }

    .el-tabs__content {
      overflow: visible;
    }

    .btn1 {
      border: solid 1px #979797 !important;
      background-color: #ffffff !important;
      vertical-align: middle;
      line-height: 24px;
      img {
        width: 24px;
        height: 24px;
        display: inline;
        vertical-align: middle;
      }
    }
    .btn2 {
      border: none;
      background-color: #c1e1e7 !important;
      vertical-align: middle;
      line-height: 24px;
      img {
        width: 24px;
        height: 24px;
        display: inline;
        vertical-align: middle;
      }
    }
    .btn3 {
      vertical-align: middle;

      img {
        width: 26px;
        height: 26px;
        object-fit: contain;
      }

      .el-button {

        // width: 160px;
        height: 50px;
        border: none;
        border-radius: 6px;
        background-color: #324a5e;

        margin-left: 10px;
        // padding-left: 10px;
        // padding-right: 10px;
        span {
          line-height: 25px;
        }

        div{
          display: inline;
          vertical-align: middle;

          &:first-child {
            float: left;
          }

          &:last-child {
            margin-left: 10px;
            float: right;
          }
        }
      }
    }

    .el-card:not(.timeline) {
      border: none;
      box-shadow: none;

      .el-card__body {
        padding: 0px;
      }
    }
  }
</style>
