let api;

if (!api) {
  import("./apis").then((apis) => {
    api = apis.coreAPI;
  });
}

/**
 * Get export Pets data
 * @param {Object} params
 */
const exportPets = (params) =>
  api.get("/pet/profiles/export/", { responseType: 'blob', params: params });

/**
 * Get export Activities data
 * @param {Object} params
 */
const exportActivities = (params) =>
 api.get("/activity/activities/export/", { responseType: 'blob', params: params });

/**
 * Get export Products data
 * @param {Object} params
 */
const exportProducts = (params) =>
  api.get("/product/products/export/", { responseType: 'blob', params: params });

/**
 * Get export OPDs data
 * @param {Object} params
 */
const exportOPDs = (params) =>
 api.get("/medicalrecord/export/", { responseType: 'blob', params: params });


export default {
  exportPets,
  exportActivities,
  exportProducts,
  exportOPDs
};
