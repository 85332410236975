<script>
export default {
  props: {
    activeName: {
      type: String,
      default: undefined,
    },
  },

  render() {
    return (
      this.$slots.default && (
        <el-tabs stretch value={this.activeName} class={this.$style.tabs}>
          {this.$slots.default.map((vnode) => (
            <el-tab-pane
              label={vnode.componentOptions.propsData.label}
              name={vnode.componentOptions.propsData.name}
              lazy
            >
              {vnode}
            </el-tab-pane>
          ))}
        </el-tabs>
      )
    );
  },
};
</script>

<style lang="scss" module>
.tabs {
  :global(.el-tabs__active-bar) {
    display: none;
  }

  :global(.el-tabs__item) {
    background-color: #cbcdd4;
    color: #324a5e !important;
    padding: 0 1em !important;
    display: flex;
    justify-content: center;
    align-items: center;

    &:global(.is-active) {
      background-color: #324a5e;
      color: white !important;
    }
  }
}
</style>
