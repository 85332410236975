<template>
  <div>
    <el-table
      :data="items"
      :header-cell-class-name="$style.cell"
      :cell-class-name="$style.cell"
      :class="$style.table"
      :header-row-style="{ backgroundColor: '#f5f8fa' }"
      :row-style="{ height: '4rem' }"
      :style="{ marginBottom: '0.5rem' }"
    >
      <el-table-column prop="status" width="23" :class-name="$style.tagCol">
        <template v-slot="{ row }">
          <div
            v-if="getStatus(row.status)"
            :style="{ backgroundColor: getStatus(row.status).color }"
          >
            {{ getStatus(row.status).label }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Date Admit" prop="date" min-width="100">
        <template v-slot="{ row }">
          <div :style="{ fontSize: '0.8em' }">
            {{ row.date | toThaiDate }}
          </div>
          <div :style="{ color: '#666' }">
            {{ row.date | toHHMM }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Pet" prop="pet" min-width="130">
        <template v-slot="{ row }">
          <el-row type="flex" :gutter="8" align="middle">
            <el-col :span="null">
              <img
                :src="row.petImage"
                alt="Pet image"
                :style="{
                  display: 'block',
                  width: '40px',
                  height: '40px',
                  objectFit: 'cover',
                  borderRadius: '0.25rem',
                }"
              />
            </el-col>
            <el-col :span="null" :style="{ flex: '1' }">
              <router-link
                :style="{ color: '#000', textDecoration: 'none' }"
                :to="{ name:'hospitalPetProfile', params: { id: row.petID }}"
              >{{ row.petName }}</router-link>
              <div :style="{ color: '#666' }">{{ row.petType }}</div>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column label="Overdue" prop="amount" min-width="80">
        <template v-slot="{ row }">
          {{ row.amount | currency }}
        </template>
      </el-table-column>
      <el-table-column prop="id" width="115">
        <template v-slot="{ row }">
          <el-button
            size="mini"
            :style="{ width: '100%' }"
            @click="view(row.id)"
          >
            See Detail <i class="el-icon-right"></i>
          </el-button>
        </template>
      </el-table-column>
      <img
        slot="empty"
        src="@/assets/images/no-result/medical-record-no-result.png"
        alt="Not found"
        :style="{ display: 'block', margin: '0 auto', maxWidth: '300px' }"
      />
    </el-table>
  </div>
</template>

<script>
import { getStatus } from "@/pages/ipd/components/status";

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    view(id) {
      this.$emit("view", id);
    },

    getStatus,
  },
};
</script>

<style lang="scss" module>
.table {
  color: black;
  font-size: 0.75rem;

  :global(.el-table__empty-text) {
    margin: 1.5rem 0;
    width: 100%;
  }

  th {
    padding: 0.25rem 0;
    background-color: #f5f8fa;
  }
}

.cell {
  :global(.cell) {
    word-break: normal;
    padding: 0 0.25rem !important;
    line-height: 1.5;
  }
}

.tagCol {
  overflow: hidden;
  font-size: 0.8em;
  position: relative;

  :global(.cell) {
    padding: 0 !important;
    width: 8rem;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
    line-height: 23px;
    text-align: center;
    font-weight: bold;
    color: white;
  }
}
</style>
