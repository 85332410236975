export default {
  EYE: 'eye',
  SKIN_AND_HAIR: 'skin and hair', // ผิวหนังและขน
  ALIMETARY_SYSTEM: 'alimentary system', // ระบบย่อยอาหาร
  EAR: 'ear',
  HEART: 'heart',
  RESPIRATORY_SYSTEM: 'respiratory system', // ระบบทางเดินหายใจ
  MOUTH_TEETH_GILL: 'mouth and teeth and gill', // ปาก ฟัน เหงือก
  URINATE_SYSTEM_AND_REPRODUCE_SYSTEM: 'urinate system and reproduce system', // ระบบปัสวะ และสืบพันธ์ุ
  BONE_AND_MUSCLE: 'bone and muscle',
  LYMPH_NODE: 'lymph node', // ต่อมน้ำเหลือง
  NERVOUS_SYSTEM: 'nervous system', // ระบบประสาท
  WEIGHT: 'weight'
}
