let api;

if (!api) {
  import("./apis").then((apis) => {
    api = apis.coreAPI;
  });
}

/**
 * Create a receipt
 * @param {Object} data
 * @param {number[]} data.pos_set
 * @param {Date} data.datetime
 * @param {string} data.owner
 * @param {number[]} data.attachments
 * @param {Object[]} data.payments
 * @param {number} data.payments[].payment_method
 * @param {string} data.payments[].ref
 * @param {number} data.payments[].amount
 * @param {number} data.payments[].fee_percentage
 */
const create = (data) => api.post("/receipt/receipt/", data);

/**
 * Create an invoice
 * @param {Object} data
 * @param {number[]} data.pos_set
 * @param {Date} data.datetime
 * @param {string} data.owner
 */
const createInvoice = (data) => api.post("/receipt/invoice/", data);

/**
 * Void a receipt
 * @param {string} id
 */
const doVoid = (id) => api.post(`/receipt/receipt/${id}/qvoid/`);

/**
 * Get a receipt
 * @param {string} id
 */
const get = (id) => api.get(`/receipt/receipt/${id}/`);

/**
 * Get all receipts
 * @param {Object} params
 */
const getAll = (params) => api.get("/receipt/receipt/", { params });

/**
 * Get an invoice detail
 * @param {string} id
 */
const getInvoice = (id) => api.get(`/receipt/invoice/${id}/`);

export default { create, createInvoice, doVoid, get, getAll, getInvoice };
