<template>
  <div id="page">
    <Navbar v-if="profile && profile.id != null" :profile="profile" @logout="logout"/>
    <PlainNavbar v-else />
    <!-- <HospitalConnectionTag v-if="showHospitalTag" /> -->
    <div id="main" :class="profile?'':'login'" :style="`${ showHospitalTag ? 'margin-bottom: 80px' : '' }`">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Navbar from '@/components/navigation/Navbar'
import PlainNavbar from '@/components/navigation/PlainNavbar'
// import HospitalConnectionTag from '@/components/pet/HospitalConnectionTag'

export default {
  components: {
    Navbar,
    PlainNavbar,
    // HospitalConnectionTag,
  },  
  computed: {
    ...mapGetters({
      profile: "owner/profile"
    }),
    showHospitalTag() {
      return ['/pet-owner/login', '/pet-owner/register', '/pet-owner/register/verify', '/pet-owner/forget-password', '/pet-owner/forget-password/verify', '/pet-owner/forget-password/recovery'].indexOf(this.$route.path) > -1;
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
      this.$store.dispatch("owner/logout");
      this.$router.push({ name: 'petOwnerLogin' });
    }
  },
  async mounted() {
    if (localStorage.getItem("user_type") == "pet_owner")
      await this.$store.dispatch("owner/getOwnerProfile");
  }
}
</script>

<style lang="scss" scoped>
.layout-container {
  background-color: $gray-background;
  min-height: 500px;
  // padding-top: 55px;
  padding-bottom: 40px;
}
#page {
  position: relative;
  width: 100%;
  height: 100%;
  background: #f0f2f5;
  overflow: auto;
}
#main {
  padding-top: 150px;
  margin: 0 auto;
  max-width: 1000px;
}

.login {
  padding-top: 55px !important;
}
</style>
