<template>
  <div class="broadcast-search">
    <h1 class="title">Search</h1>
      <el-row class="row">
        <el-col :md="12">
          <div class="date-group">
            <el-date-picker
              type="date"
              placeholder="Start Date"
              value-format="timestamp"
              v-model="form.startDate"
              @change="handleStartDateChange"
            ></el-date-picker>
            <span>to</span>
            <el-date-picker
              type="date"
              placeholder="End Date"
              value-format="timestamp"
              v-model="form.endDate"
              @change="handleEndDateChange"
            ></el-date-picker>
          </div>
        </el-col>
        <el-col :md="12">
          <el-input
            placeholder="Topic Name, Keyword"
            v-model="form.search"
            clearable
            @keyup.enter.native="handleSearch"
            @clear="handleClearSearch"
          >
            <el-button slot="append" icon="el-icon-search" @click="handleSearch"></el-button>
          </el-input>
        </el-col>
      </el-row>
  </div>
</template>

<script>
export default {
  props: ['startDate', 'endDate', 'search'],
  data() {
    return {
      form: {
        startDate: this.startDate,
        endDate: this.endDate,
        search: this.search
      }
    };
  },
  methods: {
    handleStartDateChange() {
      this.$emit('search', this.form)
    },
    handleEndDateChange() {
      this.$emit('search', this.form)
    },
    handleSearch() {
      this.$emit('search', this.form)
    },
    handleClearSearch() {
      this.form.search = ''
      this.$emit('search', this.form)
    }
  },
  watch: {
    "startDate" (newValue) {
      this.form.startDate = newValue
    },
    "endDate" (newValue) {
      this.form.endDate = newValue
    },
    "search" (newValue) {
      this.form.search = newValue
    }
  }
};
</script>

<style lang="scss" scoped>
.broadcast-search {
  .row {
    margin-bottom: 10px;
  }
  .date-group > span {
    padding: 10px;
  }
}
</style>
