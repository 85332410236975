import Vue from "vue";
import { MessageBox } from "element-ui";
import PrintDialog from "./PrintDialog";
import VoidDialog from "./VoidDialog";

export const showPrintDialog = (propsData = {}) =>
  new Promise((resolve, reject) => {
    const Component = Vue.extend(PrintDialog);

    const instance = new Component({
      propsData,

      destroyed() {
        document.body.removeChild(this.$el);
      },
    }).$mount();

    document.body.appendChild(instance.$el);

    instance.$on("action", (value) => {
      resolve(value);
    });

    instance.$on("close", () => {
      resolve("close");
      reject();
    });
  });

export const showVoidDialog = (options = {}) =>
  new Promise((resolve, reject) => {
    const Component = Vue.extend(VoidDialog);

    const instance = new Component({
      propsData: {
        dataId: options.id,
        type: options.type,
      },

      destroyed() {
        document.body.removeChild(this.$el);
      },
    }).$mount();

    document.body.appendChild(instance.$el);

    instance.$on("action", (value) => {
      resolve(value);
    });

    instance.$on("close", () => {
      reject();
    });
  });
