<template>
  <div class="transform-modal">
    <at-modal v-model="shownState" :showClose="false" @on-cancel="closeDialog" :show-footer="false">
      <div slot="header">
        <el-row type="flex" align="middle">
          <el-col :span="12">
            {{mode === 'transform' ? 'ปั่นแยก' : 'แบ่งเลือด'}}
          </el-col>

          <el-col :span="12" class="text-right">
            <el-button class="btn btn-large btn-plain hoverable" @click="closeDialog">Cancel</el-button>
            <el-button class="btn-large" type="primary" @click="onTransformSaveClicked">
              <i class="fa fa-save"></i>
              Save
            </el-button>
          </el-col>
        </el-row>
      </div>

      <div style="text-align:center; overflow:auto; height:400px; padding: 10px;">
        <div class="row at-row">
          <div class="col-md-12">
            <div class="row" style="height: 29px;">
              <div class="col-md-10 flex-start">
                <label>Blood Bag ID</label>
              </div>

              <div class="col-md-14 flex-start">
                <label style="font-weight: 500;">{{this.bloodBag.id}}</label>
              </div>
            </div>

            <div class="row" style="height: 29px;">
              <div class="col-md-10 flex-start">
                <label>Type</label>
              </div>

              <div class="col-md-14 flex-start">
                <label style="font-weight: 500;">{{this.bloodBag.bloodType}}</label>
              </div>
            </div>

            <div class="row" style="height: 29px;">
              <div class="col-md-10 flex-start">
                <label>Collect Date/Time</label>
              </div>

              <div class="col-md-14 flex-start">
                <label style="font-weight: 500;">{{this.bloodBag.dateCollection | toThaiDate }}</label>
              </div>
            </div>

            <div class="row" style="height: 29px;">
              <div class="col-md-10 flex-start">
                <label>Expire Date</label>
              </div>

              <div class="col-md-14 flex-start">
                <label style="font-weight: 500;">{{this.bloodBag.expiryDate | toThaiDate }}</label>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="row" style="height: 29px;">
              <div class="col-md-10 flex-start">
                <label>Amount</label>
              </div>

              <div class="col-md-14 flex-start">
                <label style="font-weight: 500;">{{ Number(this.bloodBag.quantity) }} ml.</label>
              </div>
            </div>

            <div class="row" style="height: 29px;">
              <div class="col-md-10 flex-start">
                <label>Pet Name</label>
              </div>

              <div class="col-md-14 flex-start">
                <label style="font-weight: 500;">{{ this.bloodBag.petInfo == null ? '-' : this.bloodBag.petInfo.name }}</label>
              </div>
            </div>

            <div class="row" style="height: 29px;">
              <div class="col-md-10 flex-start">
                <label>Pet ID</label>
              </div>

              <div class="col-md-14 flex-start">
                <label style="font-weight: 500;">{{ this.bloodBag.petInfo == null ? '-' : this.bloodBag.petInfo.id }}</label>
              </div>
            </div>

            <div class="row" style="height: 29px;">
              <div class="col-md-10 flex-start">
                <label>Donation ID</label>
              </div>

              <div class="col-md-14 flex-start">
                <label style="font-weight: 500;">{{ this.bloodBag.donationId == 0 ? '-' : this.bloodBag.donationId }}</label>
              </div>
            </div>
          </div>
        </div>

        <div class="row at-row" style="margin-top: 10px;">
          <div class="col-md-12 flex flex-start flex-middle">
            <div class="row">
              <label>Date/Time</label>
            </div>
          </div>

          <div class="col-md-12 flex flex-end flex-middle" style="padding-right: 8px;">
            <div class="row flex-middle">
              <label style="margin-right: 10px; font-weight: bold;">วันที่ / เวลา <span style="color: red;">*</span></label>
              <el-date-picker v-validate="'required'" v-model="transformDate" type="datetime" placeholder="Select date" format="dd-MM-yyyy hh:mm:ss"></el-date-picker>
            </div>
          </div>
        </div>

        <div class="row at-row" style="margin-top: 10px;">
          <div class="row col-md-24" style="background: #f4f7f9;">
            <div class="col-md-2 flex flex-center flex-middle" style="height: 50px;">
              <label class="header-table">No.</label>
            </div>

            <div class="col-md-10 flex flex-center flex-middle" style="height: 50px;">
              <label class="header-table">Type <span style="color: red;" v-if="mode === 'transform'">*</span></label>
            </div>

            <div class="col-md-6 flex flex-center flex-middle" style="height: 50px;">
              <label class="header-table">Amount (ml.) <span style="color: red;">*</span></label>
            </div>

            <div class="col-md-6 flex flex-center flex-middle" style="height: 50px;">
              <label class="header-table">Expire Date <span style="color: red;">*</span></label>
            </div>
          </div>

          <div class="row col-md-24"  v-if="mode === 'split'">
            <div class="col-md-2 flex flex-center flex-middle" style="height: 50px;">
              <label class="header-table">Original</label>
            </div>

            <div class="col-md-10 flex flex-center flex-middle" style="height: 50px;">
              <label>{{bloodBag.bloodType}}</label>
            </div>

            <div class="col-md-6 flex flex-center flex-middle" style="height: 50px;">
              <label v-if="remainingQuantity >= 0">{{ remainingQuantity }}</label>
              <label v-if="remainingQuantity < 0" style="color: red;">{{ remainingQuantity }}</label>
            </div>

            <div class="col-md-6 flex flex-center flex-middle" style="height: 50px;">
              <label>{{ bloodBag.expiryDate | toThaiDate }}</label>
            </div>
          </div>

          <div class="row col-md-24" :key="idx" v-for="(item, idx) in transformedBags">
            <div class="col-md-2 flex flex-center flex-middle" style="height: 50px;">
              <label class="header-table">{{idx + 1}}</label>
            </div>

            <div class="col-md-10 flex flex-center flex-middle" style="height: 50px;">
              <at-select class="flex flex-start" v-model="item.type" v-if="mode === 'transform'">
                <at-option v-validate="'required'" :value="type" :key="idx" v-for="(type, idx) in bloodProductTypeEnum">{{type}}</at-option>
              </at-select>
              <label v-if="mode === 'split'">{{bloodBag.bloodType}}</label>
            </div>

            <div class="col-md-6 flex flex-center flex-middle" style="height: 50px;">
              <at-input v-validate="'required'" v-model="item.amount" placeholder="Please input" style="width: 80%;" size="small" format="dd-MM-yyyy"></at-input>
            </div>

            <div class="col-md-6 flex flex-center flex-middle" style="height: 50px;">
              <el-date-picker v-validate="'required'" v-model="item.expireDate" type="date" placeholder="Select date" size="mini" format="dd-MM-yyyy" ></el-date-picker>
            </div>
          </div>

          <div class="row col-md-24">
            <div class="col-md-24 flex flex-end flex-middle" style="height: 50px;">
              <at-button @click="addNewBloodBag" icon="icon-plus">Add More Bag</at-button>
            </div>
          </div>
        </div>
      </div>
    </at-modal>
  </div>
</template>

<script>
  import bloodProductType from '../../../constants/bloodbank/blood-product-type-enum'

  export default {
    name: 'BloodInventoryTransformModalContent',
    data () {
      return {
        transformDate: new Date(),
        bloodProductTypeEnum: bloodProductType,
        transformedBags: [
          {
            type: this.mode === 'split' ? this.bloodBag.bloodType : '',
            amount: '',
            expireDate: this.mode === 'split' ? this.bloodBag.expiryDate : ''
          },
          {
            type: this.mode === 'split' ? this.bloodBag.bloodType : '',
            amount: '',
            expireDate: this.mode === 'split' ? this.bloodBag.expiryDate : ''
          }
        ]
      }
    },
    computed: {
      shownState: function () {
        return this.isShown
      },

      remainingQuantity: function () {
        let usedQuantity = 0
        this.transformedBags.forEach((bag) => {
          if (bag.amount != null && bag.amount !== '') {
            usedQuantity += Number(bag.amount)
          }
        })

        return Number(this.bloodBag.quantity) - usedQuantity
      }
    },
    methods: {
      addNewBloodBag: function () {
        this.transformedBags.push({
          type: this.mode === 'split' ? this.bloodBag.bloodType : '',
          amount: '',
          expireDate: this.mode === 'split' ? this.bloodBag.expiryDate : ''
        })
      },

      closeDialog: function () {
        this.$emit('close', this.transformedBags)
      },

      onTransformSaveClicked: function () {
        this.$validator.validateAll().then((result) => {
          if (!result) {
            this.$Notify.error({ title: 'ข้อมูลผิดพลาด', message: 'กรุณากรอกข้อมูลให้ถูกต้อง' })
          } else {
            let isValid = true

            if (this.remainingQuantity < 0) {
              this.$Notify.error({ title: 'ข้อมูลผิดพลาด', message: 'กรุณาใส่จำนวนให้ถูกต้อง' })
              isValid = false
              return false
            }

            let countableBags = this.transformedBags.filter((bag) => {
              return bag.amount != null && bag.amount !== ''
            })

            countableBags.forEach((bag) => {
              if (this.mode === 'split') {
                if (!((!bag.amount && !bag.expireDate) ||
                  (bag.amount && bag.expireDate))) {
                  this.$Notify.error({ title: 'ข้อมูลผิดพลาด', message: 'กรุณากรอกข้อมูลให้ถูกต้อง' })
                  isValid = false
                  return false
                }
              } else {
                if (!((!bag.type && !bag.amount && !bag.expireDate) ||
                  (bag.type && bag.amount && bag.expireDate))) {
                  this.$Notify.error({ title: 'ข้อมูลผิดพลาด', message: 'กรุณากรอกข้อมูลให้ถูกต้อง' })
                  isValid = false
                  return false
                }
              }
            })

            if (isValid) {
              if (this.mode === 'transform') {
                this.bloodBag.quantity = 0
              }

              this.transformedBags = [
                {
                  type: this.mode === 'split' ? this.bloodBag.bloodType : '',
                  amount: '',
                  expireDate: this.mode === 'split' ? this.bloodBag.expiryDate : ''
                },
                {
                  type: this.mode === 'split' ? this.bloodBag.bloodType : '',
                  amount: '',
                  expireDate: this.mode === 'split' ? this.bloodBag.expiryDate : ''
                }
              ]

              this.$emit('save', countableBags, this.transformDate, this.mode === 'split')
            }
          }
        })
      }
    },
    props: {
      isShown: {
        default: false,
        type: Boolean
      },
      bloodBag: {
        default: null,
        type: Object
      },
      mode: {
        default: 'transform',
        type: String
      }
    }
  }
</script>

<style scoped>
  .header-table {
    font-weight: bold;
    font-size: 15px
  }
</style>

<style lang="scss">
  .transform-modal .at-select__selection {
    width: 100%;
  }
</style>
