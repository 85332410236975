<script>
export default {
  provide() {
    return {
      command: this.command,
    };
  },

  props: {
    header: {
      type: String,
      default: "",
    },

    icon: {
      type: String,
      default: "el-icon-printer",
    },
  },

  methods: {
    command(value) {
      this.$emit("command", value);
    },
  },

  render() {
    return (
      <section class={this.$style.root}>
        <h1 style={{ fontSize: "1.125em", margin: "0 0 0.5em" }}>
          <i class={this.icon} style={{ marginRight: "0.5em" }} />
          {this.header}
        </h1>
        {this.$slots.default && (
          <div>
            <el-row type="flex" gutter={8} style={{ flexWrap: "wrap" }}>
              {this.$slots.default.map((vnode) => (
                <el-col span={null} style={{ flex: "1" }}>
                  {vnode}
                </el-col>
              ))}
            </el-row>
          </div>
        )}
      </section>
    );
  },
};
</script>

<style lang="scss" module>
.root {
  & ~ & {
    margin-top: 1em;
  }
}
</style>
