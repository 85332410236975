<template>
  <div class="report_container">
    <div class="chart_pane">
      <div class="text_desc">
        <div class="text_desc_l1">
          <div class="gender">
            Male
          </div>
          <div class="percent">
            {{ Math.round(male / all * 100) }}%
          </div>
        </div>
        <div class="text_desc_l2" v-if="(all / 2) - female <= others / 2">
          <div class="gender">
            Others
          </div>
          <div class="percent">
            {{ Math.round(others / all * 100) }}%
          </div>
        </div>
      </div>
      <div>
        <div id="report_pet_owner_gender_chart"/>
        <div class="inner_circle">
          <div class="inner_circle_top">
            Total Owner
          </div>
          <div class="inner_circle_bottom">
            {{all}}
          </div>
        </div>
      </div>
      <div class="text_desc">
        <div class="text_desc_r1">
          <div class="gender">
            Female
          </div>
          <div class="percent">
            {{ Math.round(female / all * 100) }}%
          </div>
        </div>
        <div class="text_desc_l2" v-if="(all / 2) - female > others / 2">
          <div class="gender">
            Others
          </div>
          <div class="percent">
            {{ Math.round(others / all * 100) }}%
          </div>
        </div>
      </div>
    </div>
    <div class="table_pane">
      <table>
        <tr>
          <th class="table_header">Male</th>
          <td>{{ male }}</td>
        </tr>
        <tr>
          <th class="table_header">Female</th>
          <td>{{ female }}</td>
        </tr>
        <tr>
          <th class="table_header">Others</th>
          <td>{{ others }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { donutChartGenerator } from '../../utils/report';

export default {
  name: "PetOwnerGenderChart",
  props: ['petOwners'],
  computed: {
    male: function () { return this.petOwners.gender ? this.petOwners.gender.male : 0 },
    female: function () { return this.petOwners.gender ? this.petOwners.gender.female : 0 },
    others: function () { return this.petOwners.gender ? this.petOwners.gender.others : 0 },
    all: function () { return this.petOwners.gender ? this.petOwners.gender.all : 0 },
  },
  methods: {
    updateChart: function () {
      const data = [
        { value: this.female, color: "#d0025b" },
        { value: this.others, color: "#9b9b9b" },
        { value: this.male, color: "#02b0e1" },
      ]
      donutChartGenerator('report_pet_owner_gender_chart', 190, 10, data, true);
    }
  },
  watch: {
    'petOwners.gender': function () {
      if(this.petOwners.gender)
        this.updateChart()
    },
  },
  mounted() {
    if(this.petOwners.gender)
      this.updateChart()
  },
}
</script>

<style lang="scss" scoped>
.report_container {
  display: flex;
  flex-direction: row;
  width: auto;
  justify-content: space-around;
  padding-top: 20px;
}

.chart_pane {
  flex: 1.5;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 220px;
}

.table_pane {
  padding-top: 20px;
  flex: 1;
}

.text_desc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  div {
    text-align: center;
    .gender {
      font-size: 16px;
    }
    .percent {
      font-size: 24px;
    }
  }
  .text_desc_l1 {
    color: #02b0e1;
  }
  .text_desc_l2 {
    color: #9b9b9b;
    position: relative;
    top: -30px;
  }
  .text_desc_r1 {
    color: #d0025b;
  }
}

.inner_circle {
  position: relative;
  text-align: center;
  top: -130px;
  .inner_circle_top {
    font-size: 14px;
  }
  .inner_circle_bottom {
    font-size: 34px;
    color: #02b0e1;
    position: relative;
    top: -10px;
  }
}

.table_header {
  width: 100px;
  text-align: left;
}
</style>
