<script>
import numeral from "numeral";

export default {
  props: {
    label: {
      type: String,
      default: "",
    },

    value: {
      type: Number,
      default: 0,
    },

    unit: {
      type: String,
      default: "",
    },

    format: {
      type: String,
      default: "0,0",
    },

    layout: {
      type: String,
      default: "horizontal",
      validator: (value) => ["horizontal", "vertical", "row"].indexOf(value) !== -1,
    },

    useExtraStyle: {
      type: Boolean,
      default: false
    }
  },

  render() {
    const label = (this.label || this.$slots.default) && (
      <label>{this.label || this.$slots.default}</label>
    );

    const value = (() => {
      const text = numeral(this.value).format(this.format);

      return this.$scopedSlots.value ? this.$scopedSlots.value(text) : text;
    })();

    return (
      <div>
        {this.layout === "vertical" && (
          [
            <div>{label}</div>,
            <el-row type="flex" gutter={8} style={{ alignItems: "baseline" }}>
              <el-col
                span={null}
                style={{ flex: "1", fontWeight: "bold", fontSize: "1.25em" }}
              >
                {value}
              </el-col>
              <el-col span={null}>{this.unit}</el-col>
            </el-row>,
          ]
        )}
        
        {this.layout === "horizontal" && (
          <el-row type="flex" gutter={8} style={{ alignItems: "baseline" }}>
            <el-col span={null} style={{ flex: "1" }}>
              {label}
            </el-col>
            <el-col span={null} style={{ textAlign: "right" }}>
              <div class={(this.useExtraStyle) ? '' : this.$style.value}>
                {value}
              </div>
              <div>{this.unit}</div>
            </el-col>
          </el-row>
        )}

        {this.layout === "row" && (
          <el-row type="flex" gutter={8} style={{ alignItems: "baseline" }}>
            <el-col span={null} style={{ flex: "1" }}>
              {label}
            </el-col>
            <el-col span={null} style={{ textAlign: "right" }}>
              <div class={(this.useExtraStyle) ? '' : this.$style.value}>
                {value}
              </div>
            </el-col>
            <el-col span={null}>
              {this.unit}
            </el-col>
          </el-row>
        )}
      </div>
    );
  },
};
</script>

<style lang="scss" module>
  .value {
    font-weight: bold;
    font-size: 1.25em;
  }
</style>
