<template>
  <div class="container" style="width:100%;">
    <div class="row" style="width:100%;">
      <div class="col-md-4 flex">
        <div class="container row flex-middle flex-center">
          <h3>Recipient Sample</h3>
        </div>
      </div>

      <div class="col-md-1 flex">
        <div class="container row flex-middle flex-center">
          <h3>VS</h3>
        </div>
      </div>

      <div class="col-md-7 row flex">
        <div class="container row flex-middle flex-center">
          <div class="col-md-10">
            <h3>เลขที่ถุงเลือด</h3>
          </div>
          <div class="col-md-14">
            <el-input v-model="info.bloodBagId1" disabled style="margin-left: 10px;"></el-input>
          </div>
        </div>
      </div>

      <div class="col-md-6 flex">
        <div class="container row flex-middle flex-center">
          <div class="custom-disable" v-if="disabled"></div>
          <el-radio-group v-validate="'required'" v-model="info.isMajor" size="normal">
            <el-radio-button :label="true">
              <i class="icon icon-check" style="font-size:25px"></i>
              <span style="font-size:18px">ผ่าน</span>
            </el-radio-button>
            <el-radio-button :label="false">
              <i class="icon icon-x" style="font-size:25px"></i>
              <span style="font-size:18px">ไม่ผ่าน</span>
            </el-radio-button>
          </el-radio-group>
        </div>
      </div>

      <div class="col-md-6 flex">
        <div class="container row flex-middle flex-center">
          <div class="custom-disable" v-if="disabled"></div>
          <el-radio-group v-validate="'required'" v-model="info.isMinor" size="normal">
            <el-radio-button :label="true">
              <i class="icon icon-check" style="font-size:25px"></i>
              <span style="font-size:18px">ผ่าน</span>
            </el-radio-button>
            <el-radio-button :label="false">
              <i class="icon icon-x" style="font-size:25px"></i>
              <span style="font-size:18px">ไม่ผ่าน</span>
            </el-radio-button>
          </el-radio-group>
        </div>
      </div>
    </div>

    <div class="row" style="width:100%;">
      <div class="col-md-4 flex"></div>

      <div class="col-md-1 flex"></div>

      <div class="col-md-7 row flex">
        <div class="col-md-13 flex">
          <div class="container row flex-middle flex-center">
            <label>PCV = {{pcvValue}}</label>
          </div>
        </div>

        <div class="col-md-11 flex">
          <div class="container row flex-middle flex-end">
            <label>ml. = {{Number(bloodBagQuantity)}}</label>
          </div>
        </div>
      </div>

      <div class="col-md-6 flex">
        <div class="container row flex-middle flex-center" style="margin-left: 80px;">
          <el-radio
            v-if="!info.isMajor"
            v-model="info.isMajorAg"
            :label="true"
            style="padding-right: 10px;"
          >Ag</el-radio>
          <el-radio v-if="!info.isMajor" v-model="info.isMajorAg" :label="false">He</el-radio>
        </div>
      </div>

      <div class="col-md-6 flex">
        <div class="container row flex-middle flex-center" style="margin-left: 70px;">
          <el-radio
            v-if="!info.isMinor"
            v-model="info.isMinorAg"
            :label="true"
            style="padding-right: 10px;"
          >Ag</el-radio>
          <el-radio v-if="!info.isMinor" v-model="info.isMinorAg" :label="false">He</el-radio>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "blood-request-crossmatch-row",
  props: ["bloodBagQuantity", "pcvValue", "info", "disabled"]
};
</script>

<style>
.custom-disable {
  position: absolute;
  display: block;
  z-index: 999;
  cursor: not-allowed;
  width: 200px;
  height: 43px;
}
</style>
