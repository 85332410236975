<script>
import ConfirmDialog from "@/components/dialog/ConfirmDialog";

import PrintOptionSelector from "./PrintOptionSelector";

export default {
  props: {
    change: {
      type: Number,
      default: 0,
    },
    canNoTaxPrint: {
      type: Boolean,
      default: false,
    },
    canTaxAndVatPrint: {
      type: Boolean,
      default: false,
    },
    canGroupNoTaxPrint: {
      type: Boolean,
      default: false,
    },
    canGroupTaxAndVatPrint: {
      type: Boolean,
      default: false,
    },
    taxHeader: {
      type: String,
      default: "with Tax and Vat / พิมพ์แบบมีใบกำกับภาษี",
    },
    taxlessHeader: {
      type: String,
      default: "No Tax / พิมพ์แบบไม่มีใบกำกับภาษี",
    },
    groupTaxHeader: {
      type: String,
      default: "พิมพ์ใบเสร็จแบบย่อ มีใบกำกับภาษี",
    },
    groupTaxlessHeader: {
      type: String,
      default: "พิมพ์ใบเสร็จแบบย่อ ไม่มีใบกำกับภาษี",
    },
    showReceiptPrint: {
      type: Boolean,
      default: true,
    },
    showAppointment: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      visible: false,
    };
  },

  mounted() {
    this.visible = true;
  },

  methods: {
    handleClose() {
      this.$emit("close");
      this.visible = false;
    },

    handleClosed() {
      this.$destroy();
    },

    handleTaxlessPrintSelect(value) {
      this.$emit("action", `${value}/taxless`);
      this.visible = false;
    },

    handleTaxPrintSelect(value) {
      this.$emit("action", value);
      this.visible = false;
    },

    handleGroupTaxlessPrintSelect(value) {
      this.$emit("action", `${value}/group/taxless`);
      this.visible = false;
    },

    handleGroupTaxPrintSelect(value) {
      this.$emit("action",  `${value}/group`);
      this.visible = false;
    },

    handleMakeAppointmentClick() {
      this.$emit("action", "appointment");
      this.visible = false;
    },

    handleCloseButtonClick() {
      this.visible = false;
    },
  },

  render() {
    const renderHeader = () => (
      <div style={{ margin: "0.5rem 0", fontWeight: "bold" }}>รับชำระเงิน</div>
    );

    const renderAlertBox = () => (
      <div
        style={{
          border: "1px solid #979797",
          padding: "1rem",
          fontSize: "1.5rem",
          fontWeight: "bold",
        }}
      >
        <i
          class="fa fa-check-circle"
          style={{
            fontSize: "2em",
            marginBottom: "0.5rem",
            color: "#46e26f",
          }}
        />
        <div>การชำระเงินเสร็จสมบูรณ์</div>
      </div>
    );

    const renderChange = () => (
      <div style={{ marginTop: "1rem" }}>
        <div style={{ marginBottom: "0.5rem" }}>เงินทอน</div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              padding: "1rem",
              border: "1px solid #979797",
              fontSize: "1.5rem",
              fontWeight: "bold",
            }}
          >
            {this.change.toLocaleString("th-TH", {
              style: "currency",
              currency: "THB",
            })}
          </div>
        </div>
      </div>
    );

    const renderFooterHeader = () => (
      <div
        v-show={this.canNoTaxPrint || this.canTaxAndVatPrint}
        style={{
          textAlign: "center",
          fontSize: "1.25rem",
          fontWeight: "bold",
          borderBottom: "1px solid #979797",
        }}
      >
        Done and Print
      </div>
    );

    const renderFooterBody = () => (
      <div style={{ padding: "1rem 0" }}>
        {this.canNoTaxPrint && (
          <PrintOptionSelector
            label={this.taxlessHeader}
            color="#7ed321"
            onSelect={this.handleTaxlessPrintSelect}
          />
        )}
        {this.canTaxAndVatPrint && (
          <PrintOptionSelector
            label={this.taxHeader}
            color="#02b0e1"
            style={{ marginTop: "1em" }}
            onSelect={this.handleTaxPrintSelect}
          />
        )}
        {this.canGroupTaxAndVatPrint && (
          <PrintOptionSelector
            label={this.groupTaxlessHeader}
            color="#b3f765"
            titleColor="#02b0e1"
            style={{ marginTop: "1em" }}
            onSelect={this.handleGroupTaxlessPrintSelect}
          />
        )}
        {this.canGroupNoTaxPrint && (
          <PrintOptionSelector
            label={this.groupTaxHeader}
            color="#e1f3ff"
            titleColor="#5b9f11"
            style={{ marginTop: "1em" }}
            onSelect={this.handleGroupTaxPrintSelect}
          />
        )}
      </div>
    );

    const renderFooterFooter = () => (
      <div style={{ borderTop: "1px solid #979797", paddingTop: "0.5rem" }}>
        <div
          v-show={this.canNoTaxPrint && this.canTaxAndVatPrint}
          style={{ textAlign: "center" }}
        >
          or
        </div>

        {this.showAppointment && (
          <el-row style={{ margin: "4px 0 10px" }}>
            <el-button
              style={{ width: "100%" }}
              onClick={this.handleMakeAppointmentClick}
            >
              สร้างนัดหมาย
            </el-button>
          </el-row>
        )}

        <el-row>
          <el-button
            style={{ width: "100%" }}
            onClick={this.handleCloseButtonClick}
          >
            Done and Close
          </el-button>
        </el-row>
      </div>
    );

    return (
      <ConfirmDialog
        visible={this.visible}
        onClose={this.handleClose}
        onClosed={this.handleClosed}
      >
        <template slot="header">{renderHeader()}</template>

        <template slot="content">
          {renderAlertBox()}
          {renderChange()}
        </template>

        <template slot="footer">
          <div style={{ width: "100%" }}>
            {renderFooterHeader()}
            {this.showReceiptPrint && renderFooterBody()}
            {renderFooterFooter()}
          </div>
        </template>
      </ConfirmDialog>
    );
  },
};
</script>
