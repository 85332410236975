<template>
  <div class="content-box">
    <div class="header" v-if="hasHeaderSlot">
      <slot name="header"></slot>
    </div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    hasHeaderSlot() {
      return !!this.$slots.header
    }
  }
}
</script>

<style lang="scss" scoped>
.content-box {
  display: block;
  margin-bottom: 15px;
  background: #fff;
  .header {
    padding: 8px;
    border-bottom: #ddd 1px solid;
    background-color: #fff;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1) 0%,
      rgba(237, 237, 237, 0.8) 100%
    );
    text-align: center;
  }
  .content {
    padding: 15px;
  }
}
</style>
