<template>
  <div class="layout-container">
    <div class="form-block">
      <div class="header-block">
        <div class="title">
          Please Enter <br/>
          Your New Password
        </div>
      </div>
      <el-form ref="form" :model="form">
        <el-form-item>
          <el-input
            type="email"
            disabled
            name="email"
            placeholder="Email"
            v-model="form.username"></el-input>
          <span class="error">{{ errors.first('email') }}</span>
        </el-form-item>

        <el-form-item>
          <el-input
            type="password"
            v-validate="'required'"
            name="password"
            ref="password"
            placeholder="Password"
            v-model="form.password"></el-input>
          <span class="error">{{ errors.first('password') }}</span>
        </el-form-item>

        <el-form-item>
          <el-input
            type="password"
            v-validate="'required|confirmed:password'"
            name="passwordConfirm"
            placeholder="Re-Password"
            v-model="form.passwordConfirm"></el-input>
          <span class="error">{{ errors.first('passwordConfirm') }}</span>
        </el-form-item>

        <el-form-item class="btn-container">
          <router-link
            class="btn btn-plain form-submit-btn"
            :to="{ name: 'login' }">Cancel</router-link>
          <el-button
            :loading="submitButtonLoading"
            type="submit"
            class="btn btn-primary form-submit-btn"
            @click="recover">Submit</el-button>
          <span class="error">{{ errMessage }}</span>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import PlainNavbar from '@/components/navigation/PlainNavbar'

export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
        passwordConfirm: ""
      },
      token: "",
      errMessage: "",
      submitButtonLoading: false
    }
  },
  async mounted() {
    let token = this.$route.query.token
    let uidb64 = this.$route.query.uidb64
    try {
      var result = await this.$store.dispatch("hospital/passwordRecoveryVerify", {
        query: {
          token: token,
          uidb64: uidb64
        }
      })
      this.form.username = result.email
      this.token = result.token
    } catch (error) {
      this.$message("Invalid Token")
    }
  },
  methods: {
    async recover() {
      this.submitButtonLoading = true
      try {
        const valid = await this.$validator.validateAll()
        if (valid) {
          await this.$store.dispatch("ui/setIsFullScreenLoading", true)
          await this.$store.dispatch("hospital/passwordRecovery", {
            data: {
              email: this.form.username,
              password: this.form.password,
              token: this.token
            }
          })
          await this.$store.dispatch("ui/setIsFullScreenLoading", false)
          this.$message("Password has been reset")
          this.$router.push({ name: 'hospitalHome' })
        }
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        this.errMessage = ""
      }
      this.submitButtonLoading = false
    }
  },
  components: {
    PlainNavbar
  }
}
</script>

<style lang="scss" scoped>
.layout-container {
  background-color: $gray-background;
  min-height: 500px;
  padding-bottom: 40px;
}
.is-danger {
  border: 1px solid red;
}
.form-block {
  @extend %block-container;
  @extend %form-style;
  .btn-container {
    text-align: center;
    .form-submit-btn {
      display: inline-block;
      width: 100px;
      line-height: 1;
      margin: 4px;
      text-align: center;
      text-decoration: none;
    }
  }
}
</style>
