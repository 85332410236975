<template>
    <el-card>
        <div class="contain">
            <el-row>
                <el-col class="text-left title" :span="4">Weight</el-col>
                <el-col class="text-left title" :span="8">{{ item.weight }}&nbsp;&nbsp;Kg</el-col>
                <el-col class="text-right date" :span="12"><strong>{{ date | toThaiDate }}</strong></el-col>
            </el-row>
        </div>
    </el-card>
</template>

<script>
export default {
  props: ["date", "item"]
};
</script>

<style lang="scss" scoped>
.contain {
  padding: 12px;
}
.title {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
}
.date {
    color: #156f88;
    font-size: 16px;
}
</style>