import * as vuexHelper from "../helpers/vuexHelper";

import { coreAPI } from "../../services/apis";
import * as endpoints from "../../services/endpoints";

const config = {
  default: {
    api: coreAPI
  },
  state: {
    paymentbanks: [],
    paymenttypes: [],
    paymentmethods: []
  },
  asyncActions: [
    {
      name: "createReceipt",
      method: "post",
      url: `${endpoints.receipt}receipt/`,
    },
    {
      name: "createPaymentMethod",
      method: "post",
      url: `${endpoints.receipt}paymentmethod/`,
    },
    {
      name: "updatePaymentMethod",
      method: "patch",
      url: ({ id }) => `${endpoints.receipt}paymentmethod/${id}/`,
    },
    {
      name: "deletePaymentMethod",
      method: "delete",
      url: ({ id }) => `${endpoints.receipt}paymentmethod/${id}/`,
    },
    {
      name: "getPaymentMethodList",
      method: "get",
      url: `${endpoints.receipt}paymentmethod/`,
      setState: "paymentmethods"
    },
    {
      name: "getPaymentMethodBankList",
      method: "get",
      url: `${endpoints.receipt}paymentmethod/bank/`,
      setState: "paymentbanks"
    },
    {
      name: "getPaymentMethodTypeList",
      method: "get",
      url: `${endpoints.receipt}paymentmethod/type/`,
      setState: "paymenttypes"
    },
    {
      name: 'upload',
      method: 'post',
      url: `${endpoints.receipt}attachment/`
    },
  ]
};

export default vuexHelper.createStoreModule(config);