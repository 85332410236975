let api;

if (!api) {
  import("./apis").then((apis) => {
    api = apis.coreAPI;
  });
}

/**
 * Get owner by POS ids
 * @param {Object} data
 * @param {number[]} data.pos_set
 * @param {Object} params
 */
const getByPos = (data, params) =>
  api.post("/owner/system/search/bypos/", data, { params });

export default { getByPos };
