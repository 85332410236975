<template>
  <div>
    <table class="inventory-product-table">
      <thead>
        <tr>
          <th class="date">Date</th>
          <th class="type">Type</th>
          <th class="detail">Detail</th>
          <th class="amount-add">Add</th>
          <th class="amount-remove">Remove</th>
          <th class="current-stock">Current Stock</th>
          <th class="action">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item.id">
          <td><div class="date-text">{{ item.datetime | toThaiDate }}</div><div class="time-text">{{ item.datetime | toHHMM }}</div></td>
          <td class="type">
            <div class="ellipsis">{{ getItemTypeName(item) }}</div>
          </td>
          <td>
            <div :style="{ fontSize: '0.857em' }">
              <template v-if="['OPD', 'IPD', 'POS'].includes(getType(item))">
                <div>{{ item.ref_id }}</div>
                <div v-if="item.receipt " :style="{ fontWeight: 'bold' }">
                  {{ item.receipt.receipt_number  }}
                </div>
              </template>
              <template
                v-else-if="
                  [
                    'Add',
                    'Remove',
                    'Income',
                    'Expense',
                    'Revert',
                    'Initial Stock',
                  ].includes(getType(item))
                "
                >Product</template
              >
            </div>
          </td>
          <td class="amount-add">
            <div class="ellipsis" v-if="item.amount > 0">{{ item.amount }}</div>
          </td>
          <td class="amount-remove">
            <div class="ellipsis" v-if="item.amount < 0">{{ item.amount }}</div>
          </td>
          <td class="current-stock">
            <div v-if="item.remaining_amount < 0" class="text-red">{{ item.remaining_amount }}</div>
            <div v-else>{{ item.remaining_amount }}</div>
          </td>
          <td class="action">
            <div class="text-red" v-if="item.is_ref_404">Deleted</div>
            <el-button
              v-else
              size="mini"
              @click="$emit('select-product', item)"
            >
              See Detail
              <i class="el-icon-arrow-right el-icon-right"></i>
            </el-button>
          </td>
        </tr>
      </tbody>
    </table>
    
    <div class="footer" v-if="items">
      <el-pagination
        class="pagination"
        background
        layout="prev, pager, next"
        :current-page="currentPage"
        :page-size="pageSize"
        :total="total"
        @current-change="(page) => $emit('current-change', page)"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    product_name: {
      type: String
    },
    pageSize: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {};
  },
  methods: {
    getType(item) {
      const type = item.ref_content_type ? item.ref_content_type.model : null
      const action = item.action_type.toLowerCase()
      const amount = item.amount

      switch (type) {
        case "medicalrecord":
          return "OPD";

        case "ipd":
          return "IPD";

        case "standalonepos":
          return "POS";

        case "incomeexpense":
          if (amount < 0) return "Expense";

          return "Income";

        default:
          switch (action) {
            case "rvrt":
              return "Revert";

            case "remv":
              return "Remove";

            case "add":
              return "Add";

            case "init":
              return "Initial Stock";

            default:
              return type;
          }
      }
    },
    getItemTypeName(item) {
      let type_name = item.action_type_name;
      let action_type = item.action_type;
      let ref_content_type = (item.ref_content_type !== null) ? item.ref_content_type.model.toLowerCase() : '';

      if (action_type.toLowerCase() === 'sell') {

        if (ref_content_type == 'medicalrecord') {
          type_name = 'OPD';
        } else if (ref_content_type == 'ipd') {
          type_name = 'IPD';
        } else if (ref_content_type == 'incomeexpense') {
          type_name = 'Income';
        } else if (ref_content_type == 'standalonepos') {
          type_name = 'POS';
        }

      } else if (action_type.toLowerCase() === 'add') {
        if (ref_content_type == 'incomeexpense') {
          type_name = 'Expense';
        } else if (ref_content_type == '') {
          type_name = 'Inventory';
        }
      } else if (action_type.toLowerCase() === 'rvrt') {
        if (ref_content_type == 'medicalrecord') {
          type_name += ' (OPD)';
        } else if (ref_content_type == 'ipd') {
          type_name += ' (IPD)';
        } else if (ref_content_type == 'standalonepos') {
          type_name += ' (POS)';
        }
      }

      return type_name;
    }
  }
};
</script>

<style lang="scss" scoped>
.detail {
  cursor: pointer;
  margin: 0 2px;
  text-align: center;
  color: #2198f1;
}

.inventory-product-table {
  margin: 0 5px;
  width: 99%;
  border-collapse: collapse;
  font-family: "Kanit", sans-serif;

  thead {
    tr {
      height: 54px;
      border-radius: 4px;
      border: #e6eaee 1px solid;

      th {
        padding: 5px;
        font-weight: normal;
        color: rgba(0, 0, 0, 0.48);
        background-color: #f5f8fa;
        text-align: center;
        font-size: 14px;
        font-family: "Kanit", sans-serif;

        &.date {
          width: 12%;
        }

        &.type {
          width: 10%;
        }

        &.detail {
          width: 15%;
        }

        &.amount-add {
          width: 15%;
        }

        &.amount-remove {
          width: 10%;
        }

        &.current-stock {
          width: 10%;
        }

        &.action {
          width: 10%;
        }
      }
    }
  }

  tbody {
    tr {
      height: 56px;
      border-bottom: 1px #eee solid;
      font-family: "Kanit", sans-serif;

      td {
        position: relative;
        padding: 5px;
        text-align: center;
        font-size: 14px;
        font-family: "Kanit", sans-serif;

        .ellipsis {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &.date {
          text-align: left;
        }

        &.type {
          text-align: left;
        }
        
        &.amount-add {
          color: #47b400;
        }

        &.amount-remove,
        .text-red {
          color: #d0021b;
        }
      }
    }
  }
  .date-text {
    padding-left: 10px;
    text-align: left;
    font-size: 13px;
  }
  .time-text {
    padding-left: 10px;
    text-align: left;
    font-size: 12px;
    color: #666;
  }
}
.footer {
  text-align: center;
  margin: 16px;
}
</style>


