<template>
  <div>
    <Subnavbar>
      <template slot="title">Payment / ชำระเงิน</template>
      <template slot="actions">
        <button class="btn btn-large btn-coral-outline" @click="$router.back()">Cancel</button>
        <button class="btn btn-large btn-coral" style="width: 100px" @click="handleConfirm">Confirm</button>
      </template>
    </Subnavbar>
      <el-row :gutter="20">
        <el-col :span="12">
           <ContentBox v-if="pet && med" >
            <template slot="header">Pet Profile</template>
            <div>
              <PetProfile :pet="pet.pet" :owner="pet.pet.owners[0]" :avatarSize="120" class="pet-profile"/>
              <div style="margin-top: 30px; font-size: .9em;">
                <h1 class="title" style="padding-bottom: 10px;">OPD ID: {{ med.id }}</h1>
                <el-row class="row">
                  <el-col :span="24">
                    <el-date-picker
                      type="date"
                      placeholder="Pick a day"
                      v-model="form.d"
                      style="margin-bottom: 5px"
                    ></el-date-picker>
                    <el-time-select
                      style="margin-left: 10px;"
                      placeholder="Select time"
                      v-model="form.t"
                      :picker-options="{
                        start: '00:00',
                        step: '00:01',
                        end: '23:59'
                      }"
                      format="HH:mm"
                    ></el-time-select>
                  </el-col>
                </el-row>
                <!-- activity list start -->
                <el-row 
                  v-for="activity in med.pos.posactivity_set" :key="activity.id"
                  style="margin: 10px 0;"
                >
                  <el-col :span="14">
                    {{ activity.activity.name }}
                    <!-- <div style="margin-top: -5px; color: #02b0e1;">
                      <small>{{  activity.activity.products.length > 0 ? activity.activity.products[0].trade_name: '' }}</small>
                    </div> -->
                  </el-col>
                  <el-col :span="5">{{ activity.amount }} x</el-col>
                  <el-col :span="5" style="text-align: right;">
                    {{ activity.amount * activity.unit_price - activity.discount | currency }}
                  </el-col>
                  <el-col :span="24">
                    <ActivityInfo
                      :amount="activity.amount"
                      :price-per-unit="+activity.unit_price"
                      :discount="+activity.discount || 0"
                      :vat="activity.activity.is_has_vat"
                      :products="
                        activity.posactivityproduct_set
                          ? activity.posactivityproduct_set.map(
                            item => ({
                              name: item.product.trade_name,
                              amount: item.amount
                            })
                          )
                          : []
                      "
                      :style="{ fontSize: '0.75rem', color: '#02b0e1' }"
                    />
                  </el-col>
                </el-row>
                <hr style="margin: 20px 0 0;">
                <el-row style="padding: 20px 0;">
                  <el-col :span="19">
                    Total
                  </el-col>
                  <el-col :span="5" style="text-align: right; font-weight: bold;">
                    {{ total | currency}}
                  </el-col>
                </el-row>
                <!-- activity list end -->
              </div>
            </div>
          </ContentBox>
        </el-col>
        <el-col :span="12" v-if="med">
          <ContentBox >
            <template slot="header">Payment</template>
            <div class="form">
              <el-row :style="{ margin: '0' }">
                <el-col :span="12" class="text-gray"><label>รวมรายการที่ยกเว้นภาษี</label></el-col>
                <el-col :span="12"><div class="text-right text-gray">{{ afterDiscount | currency }}</div></el-col>
              </el-row>
              <el-row :style="{ margin: '0' }">
                <el-col :span="12" class="text-gray"><label>ราคาสุทธิสินค้าบริการที่เสียภาษี</label></el-col>
                <el-col :span="12"><div class="text-right text-gray">{{ med.pos.grand_total_price_exclude_vat | currency }}</div></el-col>
              </el-row>
              <el-row :style="{ margin: '0' }">
                <el-col :span="12" class="text-gray"><label>ภาษีมูลค่าเพิ่ม 7%</label></el-col>
                <el-col :span="12"><div class="text-right text-gray">{{ med.pos.total_vat | currency }}</div></el-col>
              </el-row>
              <!-- <el-row>
                <el-col :span="8" class="text-gray"><label>ยอดเงิน</label></el-col>
                <el-col :span="16"><div class="text-right text-gray">{{ afterDiscount | currency }}</div></el-col>
              </el-row> -->
              <!-- <el-row>
                <el-col :span="8"><label class="text-gray">ส่วนลด <small>(จำนวนเงิน)</small></label></el-col>
                <el-col :span="1" style="padding-top: 10px;"><el-radio v-model="discountChoice" :label="true">&nbsp;</el-radio></el-col>
                <el-col :span="15">
                  <el-input class="number-input text-gray" :disabled="!discountChoice" @input="handleChangeDiscount"  v-model="form.discount" />
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8"><label class="text-gray">ส่วนลด (%)</label></el-col>
                <el-col :span="1" style="padding-top: 10px;"><el-radio v-model="discountChoice" :label="false">&nbsp;</el-radio></el-col>
                <el-col :span="15"><el-input class="number-input text-gray" :disabled="discountChoice"  @input="handleChangeDiscountPercent"  v-model="form.discountPercent" /></el-col>
              </el-row> -->
              <el-row>
                <el-col :span="8"><h3>รวม(บาท)</h3></el-col>
                <el-col :span="16"><h3 class="text-right total">{{ total | currency }}</h3></el-col>
              </el-row>
              <el-row>
                <el-col :span="10" class="text-left"><label class="text-gray">ค่าธรรมเนียม</label></el-col>
                <el-col :span="14" class="text-right"><label class="text-gray">{{ feeTotal | currency }}</label></el-col>
              </el-row>
              <el-row>
                <el-col :span="10" class="text-left"><label>ยอดชำระรวมค่าธรรมเนียม</label></el-col>
                <el-col :span="14" class="text-right"><label>{{ (total + feeTotal) | currency }}</label></el-col>
              </el-row>
            </div>
            <div style="padding: 20px">
              <el-row>
                <el-col :span="12">
                  <div class="total-box">
                    <div class="title">ได้รับแล้ว</div>
                    <div class="total">฿ {{ paymentTotal | currency }}</div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="total-box last">
                    <div class="title">เงินทอน / เงินคืน</div>
                    <div class="total" style="color: #d0021b">฿ {{ paymentTotal > (total + feeTotal) ? Math.abs((total + feeTotal) - paymentTotal) : 0 | currency }}</div>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div style="padding: 20px" class="payment-channels">
              <el-row>
                <el-col :span="14">
                  <h1 class="title">ช่องทางที่รับชำระ</h1>
                  <div class="subtitle">* เพิ่มช่องทางชำระเงินที่ Setting</div>
                </el-col>
                <el-col :span="10" style="text-align: right; margin-top: 10px;">
                   <button class="btn btn-outline" @click="handleAddPayment">+ Add</button>
                </el-col>
              </el-row>
              <div style="padding: 20px 0">
                <ol class="payment-list">
                  <li v-for="(p, index) in this.form.payments" :key="index">
                    <el-row :gutter="10">
                      <el-col :span="22" style="margin-top: -15px;">
                        <el-select placeholder="Select payment"
                          style="width: 100%"
                          filterable 
                          clearable
                          v-model="form.payments[index].id"
                          @change="initFeePercentage(form.payments[index].id, index)"
                          @clear="() => form.payments[index].id = null"
                        >
                          <el-option
                            v-for="item in payments"
                            :key="item.id"
                            :value="item.id"
                            :label="getPaymentDetail(item)"
                          ></el-option>
                        </el-select>
                      </el-col>
                      <el-col :span="2" style="margin-top: -15px; padding-top: 10px; text-align: right;">
                        <i class="fa fa-times-circle" style="cursor: pointer;" @click="() => handleDelectPayment(index)"></i>
                      </el-col>
                    </el-row>
                    <el-row :gutter="10">
                      <el-col :span="11">
                        <label style="font-size: .8em; color: #666">รหัสอ้างอิง</label>
                        <el-input v-model="form.payments[index].ref" :disabled="isDisabledRef(form.payments[index].id)" />
                      </el-col>
                      <el-col :span="11">
                        <label style="font-size: .8em; color: #666">จำนวน</label>
                        <el-input v-model="form.payments[index].amount" />
                      </el-col>
                    </el-row>
                    <el-row v-if="isShowFeeInput(form.payments[index].id)" style="margin-top:8px;">
                     <el-row :gutter="10">
                        <el-col :span="11">
                          <el-row>
                            <el-col :span="11" style="padding-top: 8px"><label style="font-size: .8em; color: #666;">ค่าธรรมเนียม</label></el-col>
                            <el-col :span="11" style="width: 54%;">
                              <el-input-number
                              v-model="form.payments[index].fee_percentage"
                              :min="0" :max="5" :controls="false" :precision="2"
                              style="width: 100%"/>
                            </el-col>
                          </el-row>
                        </el-col>
                        <el-col :span="11" style="padding-top: 10px">
                          <el-row :gutter="10" style="margin-right: -20px;">
                            <el-col class="text-left" :span="11" style="font-size: .8em; color: #666">คิดเป็นเงิน</el-col>
                            <el-col 
                              class="text-right" 
                              :span="11" 
                              style="font-size: .8em; color: #666">
                                {{ `${form.payments[index].amount * form.payments[index].fee_percentage / 100}` | currency}}
                            </el-col>
                          </el-row>
                        </el-col>
                     </el-row>
                     <el-row style="padding-top: 5px;">
                       <el-col class="text-left" :span="11" style="font-size: .8em; color: #666">รวม</el-col>
                       <el-col 
                        class="text-right" 
                        :span="11" 
                        style="font-size: .8em; color: #666;padding-right: 2px;">
                          {{ totalOfPaymentMethod(form.payments[index]) | currency}}
                      </el-col>
                     </el-row>
                    </el-row>
                  </li>
                </ol>
                <div style="padding: 10px 0;" class="clearfix">
                  <h1 class="title">แนบหลักฐาน / สลิป </h1>
                  <FileUploader
                    style="float: left; width: 110px; height: 110px; margin: 4px;"
                    :isUploading="isUploading"
                    @onSelect="handleSelectFiles"
                  />
                  <FilePreviewer
                    v-for="file in this.form.attachments"
                    :key="file.id"
                    style="float: left; width: 110px; height: 110px; margin: 4px;"
                    :file="file"
                    :showDeleteButton="true"
                    @delete="handleDeleteUploadFile"
                  />
                </div>
              </div>
            </div>
          </ContentBox>
        </el-col>
      </el-row>

    <ConfirmDialog
              :visible="showPaymentSuccessDialog"
              :footerWider="true"
              @cancel="showPaymentSuccessDialog = false; $router.push({name: 'medicals-edit-detail', params: { id: med.id } })"
      >
        <template slot="header">
          <h1 class="title">รับชำระเงิน</h1>
        </template>
        <template slot="content" >
         <div class="dialog-form">
            <div style="border: 1px solid #ccc; padding: 20px;">
              <div><i class="fa fa-check-circle" style="color: #46e26f; font-size: 3em;"></i></div>
              <h1 style="margin: 10px 0 0 0; color: #999;">การชำระเงินเสร็จสมบูรณ์</h1>
            </div>
          </div>
          <h1 class="title">เงินทอน</h1>
           <div style="border: 1px solid #ccc; padding: 10px; width: 50%; margin: 0 auto;">
              <div style="font-size: 2em;">฿ {{ paymentTotal > (total + feeTotal) ? Math.abs((total + feeTotal) - paymentTotal) : 0 | currency }}</div>
          </div>
        </template>
        <template slot="footer">
          <!-- <el-button type="primary" style="width: 200px"  @click="$router.push({name: 'medicals-edit-detail', params: { id: med.id } })">Done</el-button> -->
          <el-row class="footer-button">
            <el-col :span="6">
              <button
                class="btn btn-large btn-primary"
                @click="$router.go(-1)">
                Done</button>
            </el-col>
            <el-col :span="6">
              <button
                class="btn btn-large btn-primary"
                @click="doPrint('receipt_no_tax')">
                Done & Print No tax A5</button>
            </el-col>
            <el-col :span="6">
              <button
                class="btn btn-large btn-primary"
                @click="doPrint('receipt_a4')">
                Done & Print A4</button>
            </el-col>
            <el-col :span="6">
              <button
                class="btn btn-large btn-primary"
                @click="doPrint('receipt_slip')">
                Done & Print Slip</button>
            </el-col>
          </el-row>
        </template>
      </ConfirmDialog>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import ContentBox from "@/components/container/ContentBox";
import Subnavbar from "@/components/navigation/Subnavbar";
import PetProfile from "@/components/pet/PetProfile"
import FileUploader from "@/components/upload/FileUploader"
import FilePreviewer from "@/components/upload/FilePreviewer"
import { combineDateTime } from "@/utils/datetime"
import ConfirmDialog from "@/components/dialog/ConfirmDialog"
import ActivityInfo from "@/components/receipt/ActivityInfo"
import { showPrintDialog } from '@/components/dialog/receipt'
import { showStockNotEnoughDialog } from '@/components/dialog/stock-not-enough'

export default {
  components: {
    ContentBox,
    Subnavbar,
    PetProfile,
    FileUploader,
    FilePreviewer,
    ConfirmDialog,
    ActivityInfo
  },
  data () {
    return {
      showPaymentSuccessDialog: false,
      med: null,
      pet: null,
      payments: [],
      isUploading: false,
      discountChoice: true,
      form: {
        d: new Date(),
        t: moment().format("HH:mm"),
        discount: 0,
        discountPercent: 0,
        attachments: [],
        payments: [{
          id: 1,
          ref: '',
          amount: 0,
          fee_percentage: 0,
          note: '',
        }]
      }
    }
  },
  computed: {
    ...mapState({
      currentPlan: state => state.plan.currentPlan,
    }),
    afterDiscount () {
      if (this.med && this.med.pos) {
        return +this.med.pos.grand_total_price_no_vat_only
      }
      return 0
    },
    feeTotal() {
      return this.form.payments.reduce((acc, p) => acc + parseFloat(p.amount * p.fee_percentage / 100), 0)
    },
    total() {
      if (this.med && this.med.pos) {
        return (
            +this.med.pos.grand_total_price_no_vat_only + 
            +this.med.pos.grand_total_price_exclude_vat + 
            +this.med.pos.total_vat
        )
      }

      return 0
    },
    paymentTotal () {
      return this.form.payments.reduce((acc, p) => acc + parseFloat(p.amount), 0) + this.feeTotal
    }
  },
  methods: {
    printMedical() {
      this.$router.push({name: 'medicals-edit-detail', params: { id: this.med.id } })
      let routeData = this.$router.resolve({
        name: 'medicals-detail-pdf',
        params: { id: this.med.id }
      })
      window.open(routeData.href, '_blank')
    },
    doPrint(printType) {
      this.$router.push({name: 'medicals-edit-detail', params: { id: this.med.id } })
      let routeData = null;

      if (printType === 'receipt_no_tax') {
        let routeData = this.$router.resolve({
          name: "medicals-detail-pdf-v1",
          params: { id: this.med.id },
        });
        window.open(routeData.href, "_blank");

      } else if (printType === 'receipt_a4') {
        let routeData = this.$router.resolve({
          name: "medicals-detail-pdf",
          params: { id: this.med.id },
        });
        window.open(routeData.href, "_blank");

      } else if (printType === 'receipt_slip') {
        const route = this.$router.resolve({
          name: "medicals-detail-pdf-compact",
          params: { id: this.med.id },
        });
        window.open(route.href, "_blank");

      } 
    },
    async handleConfirm () {
      const timeToDate = moment(this.form.t, "HH:mm").toDate()
      const datetime = combineDateTime(this.form.d, timeToDate)
      if (!moment(datetime).isValid()) {
        this.$notify({
          type: "warning",
          title: "กรุณาเลือกวันและเวลา"
        })
        return
      }

      let paymentInvalidErrorMessage = 'กรุณาตรวจสอบข้อมูลชำระเงิน'
      const paymentInvalid = this.form.payments.find(item => {
        const payment = this.payments.find(p => p.id === item.id)
        if (payment) {
          if (item.amount < 0) {
            paymentInvalidErrorMessage = 'จำนวนเงินต้องมากกว่า 0'
            return true
          }
          return false
        } else {
          return true
        }
       })
      
      if (!this.med.petowner.owner) {
        this.$notify({
          type: "warning",
          title: "ไม่สามารถชำระเงินให้สัตว์เลี้ยงที่ไม่มีเจ้าของได้"
        })
        return
      }

      if (this.form.payments.length === 0) {
        this.$notify({
          type: "warning",
          title: "กรุณาตรวจสอบข้อมูลชำระเงิน"
        })
        return
      }

      if (paymentInvalid) {
        this.$notify({
          type: "warning",
          title: paymentInvalidErrorMessage
        })
        return
      }

      const roundValue = (num, decimals=2) => {
          return parseFloat(num).toFixed(decimals) * 1
      }

      if (roundValue(this.paymentTotal, 2) < (roundValue(this.total, 2) + roundValue(this.feeTotal, 2))) {
        this.$notify({
          type: "warning",
          title: "กรุณาตรวจสอบยอดเงินที่ชำระ"
        })
        return
      }

      let pos_set = []
      pos_set.push(this.med.pos.id)
      const data = {
        pos_set,
        datetime,
        // vet: this.med.revision_set.length > 0 && this.med.revision_set[0].vet ?this.med.revision_set[0].vet.id : null,
        staff: this.med.revision_set.length > 0 && this.med.revision_set[0].vet ?this.med.revision_set[0].vet.id : null,
        owner: this.med.petowner.owner.id,
        discount: this.form.discount,
        payments: this.form.payments.map(item => ({
          payment_method: item.id,
          ref: item.ref,
          amount: parseFloat(item.amount),
          fee_percentage: item.fee_percentage,
          note: '',
        })),
        attachments: this.form.attachments.map(item => item.id),
        note: '',
      }

      const goToPrintPage = (name, params) => {
        const route = this.$router.resolve({
          name,
          params: { ...this.$route.params, ...params },
        });

        window.open(route.href);
        showDialog(params.id);
      };

      const showDialog = async (receiptId) => {
        try {
          const action = await showPrintDialog({ 
            change: this.paymentTotal > (this.total + this.feeTotal) ? Math.abs((this.total + this.feeTotal) - this.paymentTotal) : 0,
            canNoTaxPrint: this.currentPlan.print_section
              .can_use_no_tax_print,
            canTaxAndVatPrint: this.currentPlan.print_section
              .can_use_have_tax_print,
            canGroupNoTaxPrint: this.currentPlan.print_section
              .can_use_group_no_tax_print,
            canGroupTaxAndVatPrint: this.currentPlan.print_section
              .can_use_group_have_tax_print,
            showAppointment: true,
          });

          switch (action) {
            case "a4":
                return goToPrintPage("income-expense-receipt-print", {
                  id: receiptId,
                  size: "a4",
                });

              case "a5":
                return goToPrintPage("income-expense-receipt-print", {
                  id: receiptId,
                  size: "a5",
                });

              case "slip":
                return goToPrintPage("income-expense-receipt-print-slip", {
                  id: receiptId,
                });

              case "a4/taxless":
                return goToPrintPage("income-expense-receipt-print-taxless", {
                  id: receiptId,
                  size: "a4",
                });

              case "a5/taxless":
                return goToPrintPage("income-expense-receipt-print-taxless", {
                  id: receiptId,
                  size: "a5",
                });

              case "slip/taxless":
                return goToPrintPage("income-expense-receipt-print-taxless-slip", {
                  id: receiptId,
                });

              case "a4/group":
                return goToPrintPage("income-expense-receipt-group-print", {
                  id: receiptId,
                  size: "a4",
                });

              case "a5/group":
                return goToPrintPage("income-expense-receipt-group-print", {
                  id: receiptId,
                  size: "a5",
                });

              case "slip/group":
                return goToPrintPage("income-expense-receipt-group-print-slip", {
                  id: receiptId,
                });

              case "a4/group/taxless":
                return goToPrintPage("income-expense-receipt-group-print-taxless", {
                  id: receiptId,
                  size: "a4",
                });

              case "a5/group/taxless":
                return goToPrintPage("income-expense-receipt-group-print-taxless", {
                  id: receiptId,
                  size: "a5",
                });

              case "slip/group/taxless":
                return goToPrintPage("income-expense-receipt-group-print-taxless-slip", {
                  id: receiptId,
                });

              case "appointment":
                return this.$router.replace({
                  name: 'appointment-select-activities',
                  params: { id: this.pet.id }
                });

              case "close":
                return this.$router.replace({name: 'medicals-edit-detail' });
          }

        } catch (e) {
          //
        }
      };

      const showNotEnoughDialog = async (products) => {
        await showStockNotEnoughDialog({
          items: products
        });
      };

      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        const result = await this.$store.dispatch("receipt/createReceipt", {data});
        
        // this.showPaymentSuccessDialog = true;
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);

        if (result.status_code == 460) {
          showNotEnoughDialog(result.message);
        } else {
          showDialog(result.id);
        }
        
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorMsg", error)
      }
      
      
    },
    handleChangeDiscount () {
      const newValue = this.form.discount;
      if (this.discountChoice && this.med && this.med.pos && 
      this.med.pos.grand_total_price && this.med.pos.grand_total_price.after_discount &&
      this.med.pos.grand_total_price.after_discount > 0 &&
      newValue !== '' &&  !isNaN(newValue)) {
        this.form.discountPercent = (newValue / this.med.pos.grand_total_price.after_discount ) * 100
      } else {
        this.form.discountPercent = 0;
      }
    },
    handleChangeDiscountPercent () {
      const newValue = this.form.discountPercent;
      if (!this.discountChoice && this.med && this.med.pos && 
      this.med.pos.grand_total_price && this.med.pos.grand_total_price.after_discount &&
      this.med.pos.grand_total_price.after_discount > 0 &&
      newValue !== '' &&  !isNaN(newValue)) {
        this.form.discount = this.med.pos.grand_total_price.after_discount * (newValue / 100)
      } else {
        this.form.discount = 0;
      }
    },
    getPaymentDetail (item) {
      if (item.payment_method_type) {
        let in_bracket = '';
        
        if (item.bank) in_bracket += item.bank.name
        if (item.account_name) in_bracket += ' ' + item.account_name
        if (item.bank_account_id) in_bracket += ' ' + item.bank_account_id
        if (in_bracket.trim()) in_bracket = `(${in_bracket.trim()})`

        return `${item.payment_method_type.name} ${in_bracket}`.trim()
      }
      return item.description
    },
    initFeePercentage (paymentID, form_payment_index) {
      this.payments.forEach(payment => {
        if (payment.id === paymentID) {
          this.form.payments[form_payment_index].fee_percentage = payment.fee_percentage
        }
      });
    },
    totalOfPaymentMethod (payment) {
      return (payment.amount * payment.fee_percentage / 100) + parseFloat(payment.amount);
    },
    isShowFeeInput (paymentID) {
      return this.payments.find(item => item.id === paymentID && item.has_fee)
    },
    isDisabledRef (id) {
      return !this.payments.find(item => item.id === id && item.bank_account_id)
    },
    handleAddPayment () {
      this.form.payments.push({
        id: null,
        ref: '',
        amount: 0,
        fee_percentage: 0,
        note: '',
      })
    },
    handleDelectPayment (index) {
      this.form.payments.splice(index, 1)
    },
    async handleSelectFiles(files) {
      try {
        this.isUploading = true
        for (let index = 0; index < files.length; index++) {
          await this.uploadFile(files[index])
        }
        this.isUploading = false
        this.$notify({
          message: `อัพโหลดไฟล์เรียบร้อยแล้ว`,
          type: "success"
        })
        this.handleChange()
      } catch (error) {
        this.isUploading = false
      }
    },
    async uploadFile(file) {
      try {
        let formData = new FormData()
        formData.append("attachment", file)
        const response = await this.$store.dispatch("receipt/upload", {
          formData
        })
        this.form.attachments = [...this.form.attachments, response]
      } catch (error) {
        this.$notify({
          message: "ไม่สามารถอัพโหลดไฟล์ " + file.name,
          type: "error"
        })
      }
    },
    handleDeleteUploadFile(file) {
      this.form.attachments = this.form.attachments.filter(
        item => item.id !== file.id
      )
    },
    async fetchData () {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        this.med = await this.$store.dispatch("medical/getMedical", {
          params: { id: this.$route.params.id }
        })
        this.pet = await this.$store.dispatch("pet/getPetProfile", {
          params: { petHospitalId: this.med.pethospital }
        })
        this.payments = await this.$store.dispatch("receipt/getPaymentMethodList", {
          query: { all: true }
        })
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg")
      }
    },
  },
  async created() {
    this.fetchData()
  }
}
</script>

<style lang="scss" scoped>
.form {
  .el-row {
    margin: 10px 0;
  }
  label {
    display: inline-block;
    text-align: left;
    margin: 5px 0;
  }
}
.rounded-box {
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 5px;
  text-align: right;

  &.total {
    border-color: $primary;
    font-size: 20px;
    font-weight: bold;
  }
}
.total-box {
  padding: 5px 20px 20px;
  border: 1px solid #eee;

  &.last {
    border-left: none;
  }

  .title {
    color: #666;
    font-size: .8em;
    line-height: 1em;
  }

  .total {
    font-weight: bold;
    text-align: center;
    font-size: 1.2em;
  }
}
.payment-channels {
  .title {
    font-size: 1.2em;
    line-height: 1em;
  }
  .subtitle {
    font-size: .8em;
    margin-top: -5px;
  }
}
.payment-list {
  margin: 0 0 0 5px;
  padding: 5px;

  > li {
    margin: 0 0 20px 0;
    padding: 0 0 0 10px;
  }
}

.text-gray {
  color: #666;
}
</style>

<style lang="scss">
.number-input {
  .el-input__inner {
    text-align: right !important;
  }
}
.footer-button {
  .el-col {
    padding: 0 4px;

    .btn {
      font-weight: normal;
      width: 100%;
      word-break: initial;
      margin-left: 0;
    }
  }
}
</style>
