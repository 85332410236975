<template>
    <div class="pet-medical-record-timeline">
        <el-timeline v-if="timeline_items.length > 0">
        <el-timeline-item></el-timeline-item>
        <el-timeline-item :class="`${item.type}`" v-for="(item, index) in timeline_items" :key="index">
            <MedicalRecordItem
                v-if="item.type === 'medicalrecord_medicalrecord'"
                :date="item.date"
                :item="item.detail"
                @click="navigateToDetail" />

            <IPDItem
                v-else-if="item.type === 'medicalrecord_ipd'"
                :id="item.detail.id"
                :arrived="item.detail.arrived
                          ? new Date(item.detail.arrived)
                          : undefined"
                :finished="item.detail.finished
                          ? new Date(item.detail.finished)
                          : undefined"
                :status="item.detail.overall_status === 'finished' 
                        || item.detail.overall_status === 'overdue'
                          ? item.detail.overall_status === 'finished'
                                ? 'finish' : 'overdue'
                          : 'active'"
                :deposit="item.detail.deposit"
                :paid="item.detail.paid"
                :overdue="item.detail.unpaid"
                :vets="item.detail.vets"
                @view="navigateToIPDDetail" />
            
            <PetBloodGroupItem
                v-else-if="item.type === 'pet_petbloodgroup'"
                :date="item.date"
                :item="item.detail" />

            <PetMicrochipItem
                v-else-if="item.type === 'pet_petmicrochip'"
                :date="item.date"
                :item="item.detail" />

            <PetWeightItem
                v-else-if="item.type === 'pet_petweight'"
                :date="item.date"
                :item="item.detail" />

            <PetBloodRequestItem
                v-else-if="item.type === 'bloodbank_bloodrequest'"
                :date="item.date"
                :item="item.detail" />

            <PetBloodDonationItem
                v-else-if="item.type === 'bloodbank_blooddonation'"
                :date="item.date"
                :item="item.detail" />
                
        </el-timeline-item>
        <el-timeline-item></el-timeline-item>
        </el-timeline>
        <div class="no-item" v-else>No timeline</div>
        <div class="load-more-container" v-if="currentPage < totalPages">
        <button class="btn btn-small btn-primary" @click="loadMore">Load more...</button>
        </div>
    </div>
</template>

<script>
import MedicalRecordItem from "./MedicalRecordItem";
import IPDItem from "./IPDItem";
import PetBloodGroupItem from "./PetBloodGroupItem";
import PetMicrochipItem from "./PetMicrochipItem";
import PetWeightItem from "./PetWeightItem";
import PetBloodRequestItem from "./PetBloodRequestItem";
import PetBloodDonationItem from "./PetBloodDonationItem";

export default {
  components: {
    MedicalRecordItem,
    IPDItem,
    PetBloodGroupItem,
    PetMicrochipItem,
    PetWeightItem,
    PetBloodRequestItem,
    PetBloodDonationItem
  },
  props: ["petId"],
  data() {
    return {
      id: this.petId,
      timeline_items: [],
      currentPage: 1,
      totalPages: 1
    };
  },
  methods: {
    navigateToDetail(id) {
        this.$router.push({
          name: 'medicals-edit-detail',
          params: { id: id }
        })
    },

    navigateToIPDDetail(id) {
      this.$router.push({
        name: "ipd-detail",
        params: { id: id } 
      });
    },

    loadMore() {
      this.fetchMedicalRecords();
    },
    async fetchMedicalRecords() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        var response;

        let query = {};
        if (this.currentPage < this.totalPages) {
          this.currentPage += 1;
        }
        query["page"] = this.currentPage;

        response = await this.$store.dispatch("pet/listTimelineMedicalRecord", {
            query, params: { petId: this.id }
        });

        if (this.currentPage > 1) {
          this.timeline_items = [...this.timeline_items, ...response.results];
        } else {
          this.timeline_items = response.results;
        }
        this.totalPages = Math.ceil(response.count / 10);
        
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorMsg", error.detail);
      }
    }
  },

  async mounted() {
    this.fetchMedicalRecords();
  }
};
</script>

<style lang="scss" scoped>
.no-item {
  font-family: default;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.7px;
  text-align: center;
  color: #8e8e93;
  margin: 72px 0px;
}
</style>

<style lang="scss">
.pet-medical-record-timeline {
  .el-timeline {
    margin-left: -20px;
  }
  .el-timeline-item {
    padding-left: 20px;

    &:first-child {
      .el-timeline-item__node--normal {
        display: none;
      }
    }

    &:last-child {
      .el-timeline-item__node--normal {
        display: none;
      }
      padding: 0px;
    }

    .el-timeline-item__node--normal {
      left: -12px;
      width: 34px;
      height: 34px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
    .el-timeline-item__tail {
      border-width: 4px;
    }

    &.medicalrecord_medicalrecord,
    &.medicalrecord_ipd,
    &.pet_petbloodgroup,
    &.pet_petmicrochip,
    &.pet_petweight {
      .el-timeline-item__node--normal {
        background-image: url("../../../assets/icons/icon-calendar2.png");
      }
    }

    &.bloodbank_bloodrequest {
      .el-timeline-item__node--normal {
        background-image: url("../../../assets/icons/icon-timeline-bloodbag.png");
      }
    }

    &.bloodbank_blooddonation {
      .el-timeline-item__node--normal {
        background-image: url("../../../assets/icons/icon-timeline-blooddrop.png");
      }
    }
  }

  .load-more-container {
    text-align: center;
    padding: 0 0 20px 0;
  }
}
</style>