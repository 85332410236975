<template>
  <div class="page">
    <Subnavbar>
      <template slot="title">
        {{ title }}
      </template>
      <template slot="actions">
        <button
          class="btn btn-large btn-default"
          @click="$router.push({ name: 'income-expense-list' })"
        >Cancel</button>
        <button v-if="incomeType !== ''" class="btn btn-large btn-primary" @click="handleSave">
            <i class="fa fa-save"></i>
              Save
        </button>
      </template>
    </Subnavbar>
    <ContentBox style="padding: 5px;" v-if="detail">
      <div class="form form-income-types">
        <el-row>
          <el-col :span="24"><h1 class="title">{{ detail.is_expense ? "ชนิดรายจ่าย" : "ชนิดรายรับ" }}</h1></el-col>
        </el-row>
        <el-row>
          <el-col :span="10"  v-if="incomeType === 'product'">
            <el-radio class="type-radio" v-model="incomeType" label="product">Product</el-radio>
          </el-col>
          <el-col :span="10" v-if="incomeType === 'general'">
            <el-radio class="type-radio" :disabled="incomeType !== 'general'" v-model="incomeType" label="general">General</el-radio>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10" v-if="incomeType === 'product'">
            <div style="margin-left: 27px; margin-top: -5px; color: #666;">{{  detail.is_expense ? "(ซื้อเข้า เพิ่มในคลังสินค้า)" : "(ขายออกตัดจากคลังสินค้า)" }}</div>
          </el-col>
          <el-col :span="10" v-if="incomeType === 'general'">
             <div style="margin-left: 27px; margin-top: -5px; color: #666;">{{  detail.is_expense ? "(รายจ่าย)" : "(รายรับอื่นๆ)" }}</div>
          </el-col>
        </el-row>
      </div>
    </ContentBox>
    <template v-if="detail && incomeType=='product'">
      <AddIncomeExpenseProduct :isExpense="detail.is_expense" :isEdit="true" :form-data="this.formProduct" :products="products" @change="handleAddIncomeProductChange" />
    </template>
    <template v-if="detail && incomeType=='general'">
      <AddIncomeExpenseGeneral :isExpense="detail.is_expense" :isEdit="true" :form-data="this.form" :types="types" :isRevertType="isRevertDetail()" @change="handleAddIncomeGeneralChange" />
    </template>
  </div>
</template>

<script>
import moment from 'moment';
import Subnavbar from "@/components/navigation/Subnavbar";
import ContentBox from "@/components/container/ContentBox";
import FileUploader from "@/components/upload/FileUploader"
import ImageFilePreviewer from "@/components/upload/ImageFilePreviewer"
import AddIncomeExpenseGeneral from "@/components/income-expense/AddIncomeExpenseGeneral"
import AddIncomeExpenseProduct from "@/components/income-expense/AddIncomeExpenseProduct"
import { combineDateTime } from "@/utils/datetime"

export default {
  components: {
    ContentBox,
    Subnavbar,
    FileUploader,
    ImageFilePreviewer,
    AddIncomeExpenseGeneral,
    AddIncomeExpenseProduct
  },
  data() {
    return {
      detail: null,
      incomeType: '',
      types: [],
      products: [],
      form: {
        id: null,
        datetime: new Date(),
        transaction_type: null,
        description:'',
        unit_price: 0,
        discount: 0,
        note: '',
        attachments: []
      },
       formProduct: {
        id: null,
        datetime: new Date(),
        product: null,
        description:'',
        amount: 0,
        unit_price: 0,
        discount: 0,
        note: '',
        attachments: []
      }
    };
  },
  computed: {
    title() {
      if (this.detail) {
        if (this.detail.is_expense) {
          return 'Edit Expense'
        } else {
          return 'Edit Income'
        }
      }
      return ''
    }
  },
  methods: {
    handleAddIncomeGeneralChange (data) {
      const timeToDate = moment(data.t, "HH:mm").toDate()
      this.form = {
        datetime: combineDateTime(data.d, timeToDate),
        transaction_type: data.transaction_type,
        description: data.description,
        unit_price: data.unit_price,
        discount: data.discount,
        note: data.note,
        attachments: data.attachments
      }
    },
    handleAddIncomeProductChange (data) {
      const timeToDate = moment(data.t, "HH:mm").toDate()
      this.formProduct = {
        datetime: combineDateTime(data.d, timeToDate),
        product: data.product,
        description: data.description,
        amount: data.amount,
        unit_price: data.unit_price,
        discount: data.discount,
        note: data.note,
        attachments: data.attachments
      }
    },
    isRevertDetail() {
      return this.detail.transaction_type.name.toLowerCase() == "revert"
    },
    async handleSave () {
      if (this.incomeType == '') {
        this.$notify({
          type: "warning",
          title: "Please Select Income Type"
        })
        return
      }

      if (this.incomeType === 'general') {
        this.saveIncomeGerneral()
      } else if (this.incomeType === 'product') {
        this.saveIncomeProduct()
      }
    },
    async saveIncomeGerneral() { 
      if (!moment(this.form.datetime).isValid()) {
        this.$notify({
          type: "warning",
          title: "กรุณาเลือกวันและเวลา"
        })
        return
      } else if (!this.form.transaction_type) {
        this.$notify({
          type: "warning",
          title: "กรุณาเลือก type"
        })
        return
      }

      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        const data = {
          description: this.form.description,
          note: this.form.note,
          attachments: this.form.attachments.map(item => item.id)
        }
        await this.$store.dispatch("incomeExpense/updateIncomeExpense", {
          params: { id: this.detail.id },
          data
        });
        
        this.$router.push({name: 'income-expense-list' })
        this.$notify({
          type: "success",
          title: "บันทึกข้อมูลเรียบร้อยแล้ว"
        })
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorMsg", error)
      }
    },
    async saveIncomeProduct() { 
      if (!moment(this.formProduct.datetime).isValid()) {
        this.$notify({
          type: "warning",
          title: "กรุณาเลือกวันและเวลา"
        })
        return
      } else if (!this.formProduct.product) {
        this.$notify({
          type: "warning",
          title: "กรุณาเลือก product"
        })
        return
      }
  
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        const data = {
          note: this.formProduct.note,
          attachments: this.formProduct.attachments.map(item => item.id)
        }

        await this.$store.dispatch("incomeExpense/updateIncomeExpense", {
          params: { id: this.detail.id },
          data
        });
        
        this.$router.push({name: 'income-expense-list' })
        this.$notify({
          type: "success",
          title: "บันทึกข้อมูลเรียบร้อยแล้ว"
        })
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorMsg", error)
      }
    },
    async initData() {
      if (this.detail.transaction_type === null && this.detail.product) {
        this.incomeType = "product"
        this.formProduct = {
          id: this.detail.id,
          datetime: new Date(this.detail.datetime),
          product: this.detail.product ? this.detail.product.id : null,
          description:this.detail.description,
          amount: this.detail.amount,
          unit_price: this.detail.unit_price,
          discount: this.detail.discount,
          note: this.detail.note,
          attachments: this.detail.attachments
        }
      } else if (this.detail.transaction_type !== null) {
        this.incomeType = "general"
        this.form = {
          id: this.detail.id,
          datetime: new Date(this.detail.datetime),
          transaction_type: this.detail.transaction_type ? this.detail.transaction_type.id : null,
          description: this.detail.description,
          unit_price: this.detail.unit_price,
          discount: this.detail.discount,
          note: this.detail.note,
          attachments: this.detail.attachments
        }
      }

    },
    async fetchData() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        this.detail = await this.$store.dispatch("incomeExpense/getIncomeExpense", {
          params: { id: this.$route.params.id }
        });

        await this.initData()
        this.types = await this.$store.dispatch("incomeExpense/listIncomeExpenseTypes", {
          query: { all: true }
        });
        this.products = await this.$store.dispatch("product/listProducts", {
          query: { all: true }
        });
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg");
      }
    },
  },
  async created() {
    this.fetchData();
  }
};
</script>

<style lang="scss" scoped>
.page {
  .seperator {
    margin-top: 30px;
    margin-bottom: 20px;
    border-top: none;
    border-bottom: 1px solid #ccc;
  }
}

.form {
  padding: 0 50px;
  .row {
    margin-bottom: 10px;
  }

  .title {
    color: $light-blue;
    font-size: 1.2em;
  }

  label {
    display: inline-block;
    margin-right: 3px;
    line-height: 2.4em;
    
    &.multi-line {
      line-height: 1.2em;
      span {
        display: block;
      }
    }

    small {
      color: #999;
    }
  }

  .remark {
    font-size: 12px;
    color: #999;
  }

  .attachment-list {
    list-style-type: none;
    padding: 0;
    margin: 10px 0 0 0;

    > li {
      margin: 5px 0;
      padding: 0 10px;
      font-size: 14px;

      .name {
        display: inline-block;
        min-width: 150px;
        padding: 0 10px;
      }

      i {
        color: $light-blue;

        &.fa-times-circle {
          color: #ccc;
          cursor: pointer;

          &:hover {
            color: $red;
          }
        }
      }
    }
  }

 
  .el-radio {
    display: inline-block;
    margin-right: 20px;
  }

  .el-checkbox {
    line-height: 1em;
  }
}
</style>

<style lang="scss">
  .form-income-types {
    .type-radio {
      .el-radio__inner {
        width: 20px !important;
        height: 20px !important;
      }

      .el-radio__inner::after {
        width: 5px;
        height: 5px;
      }

      .el-radio__label {
        font-size: 20px;
        font-weight: bold;
        color: #333;
      }
    }
  }
 
</style>
