<template>
  <div  style="width: 100%;  padding: 5px;">
  <at-card style="width: 100%;">
    <div class="row">
      <div class="col-md-9 col-xs-9 col-sm-9">
        <div class="row">
          <div class="col-md-12 col-xs-12 col-sm-12">
            <label>เลขที่ถุงเลือด</label>
          </div>

          <div class="col-md-12 col-xs-12 col-sm-12">
            <div class="container row flex-bottom flex-start">
              <h2>{{bloodBagId}}</h2>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 col-xs-12 col-sm-12"></div>

          <div class="col-md-12 col-xs-12 col-sm-12">
            <div class="container row flex-bottom flex-start">
              <label>
                    <i class="icon icon-check" style="color: #68b70b;" v-if="isMajorPassed"></i>
                    <i class="icon icon-x" style="color: #d0021b;" v-if="!isMajorPassed"></i>
                    <span> Major </span>
                    <i class="icon icon-check" style="color: #68b70b;" v-if="isMinorPassed"></i>
                    <i class="icon icon-x" style="color: #d0021b;" v-if="!isMinorPassed"></i>
                    <span> Minor </span>
                  </label>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-5 col-xs-5 col-sm-5">
        <div class="row" style="height: 27px;">
          <div class="container row flex-middle flex-start">
            <label>PCV = {{pcvValue}} %</label>
          </div>
        </div>

        <div class="row">
          <div class="container row flex-bottom flex-start">
            <label>Type = {{bloodType}}</label>
          </div>
        </div>
      </div>

      <div class="col-md-5 col-xs-5 col-sm-5">
        <div class="row" style="height: 27px;">
          <div class="container row flex-middle flex-center">
            <label>ปริมาตรที่เบิก</label>
          </div>
        </div>

        <div class="row">
          <div class="container row flex-bottom flex-center">
            <h3>{{quantity}} ml.</h3>
          </div>
        </div>
      </div>

      <div class="col-md-5 col-xs-5 col-sm-5">
        <div class="row" style="height: 27px;">
          <div class="container row flex-middle flex-center">
            <label>ปริมาตรในถุง</label>
          </div>
        </div>

        <div class="row">
          <div class="container row flex-bottom flex-center">
            <h3>{{remainingQuantity}} ml.</h3>
          </div>
        </div>
      </div>
    </div>
  </at-card>
  </div>
</template>

<script>
export default {
  name: 'blood-request-confirm-blood-bag',
  props: [
    'bloodBagId',
    'isMajorPassed',
    'isMinorPassed',
    'pcvValue',
    'bloodType',
    'quantity',
    'remainingQuantity'
  ]
}
</script>

<style>
</style>
