<template>
  <div class="google-map-form">
    <label>Google Map</label>
    <div class="form-row">
      <el-input
        v-model="findAddress"
        @keyup.enter.native="OnFindAddressChange" 
        placeholder="กรอกสถานที่" 
        style="width: 100%"
      ></el-input>
    </div>
    <LocationPicker v-if="loaded"
      v-on:locationChange="locationChange"
      v-on:placeChange="placeChange"
      :findPlace="findAddress" 
      :apiKey="googleApiKey"
      :initialLat="form.lat"
      :initialLng="form.lng"
      ref="map"
      />
  </div>
</template>

<script>
import LocationPicker from '@/components/LocationPicker'
import { googleApiKey } from '@/constants'

export default {
  props: ['lat', 'lng'],
  components: {
    LocationPicker
  },
  data() {
    return {
      form: {
        address: '',
        subDistrict: '',
        district: '',
        province: '',
        zipcode: '',
        lat: this.lat,
        lng: this.lng,
      },
      findAddress: "",
      googleApiKey: googleApiKey,
      loaded: false
    }
  },
  methods: {
    locationChange(place) {
      this.form.lat = place.lat.toFixed(14);
      this.form.lng = place.lng.toFixed(14);
      this.$emit('change', this.form)
    },
    placeChange(place) {
      this.findAddress = place.formatted_address;
      this.form.address = place.address;
      this.form.subDistrict = place.sub_district;
      this.form.district = place.district;
      this.form.province = place.province;
      this.form.zipcode = place.zip_code;
      this.$emit('change', this.form)
    },
    OnFindAddressChange () {
      this.$refs.map.findLocation(this.findAddress);
    },
  },
  mounted () {
    this.loaded = true
  },
  watch: {
    lat (val) {
      this.form.lat = val
    },
    lng (val) {
      this.form.lng = val
    }
  }
}
</script>

<style lang="scss" scoped>
.google-map-form {
  .form-row {
   margin-bottom: 10px;
 }
}
</style>
