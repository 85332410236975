<template>
  <div>
    <Subnavbar>
      <template slot="title" >
        <div style="float:left;">{{ title }}</div>
        <div style="float:left;" v-if="isSelectMedicalRecord">

<!--           <div v-if="isTreatment" class="tag opd-in-treatment" style="font-size: 12px;">
              In treatment
          </div>
          <div v-if="isPayment" class="tag opd-payment" style="font-size: 12px;">
              Payment
          </div>
          <div v-if="isDone" class="tag opd-done" style="font-size: 12px;">
              Done
          </div> -->

          <el-tag size="small" v-if="isTreatment" class="opd-in-treatment">
              In treatment
          </el-tag>

          <el-tag size="small" v-if="isPayment" class="opd-payment">
              Payment
          </el-tag>

          <el-tag size="small" v-if="isDone" class="opd-done">
              Done
          </el-tag>

          <el-tag
            size="small"
            effect="plain"
            v-if="isReceipt"
            :style="{width: '100px', textAlign: 'center'}"
            :class="`${ getReceiptStatusClass }`">
            {{ getReceiptStatus }}
          </el-tag>

          <el-tag size="small" effect="plain"
            v-if="isStockout"
            :style="{width: '100px', textAlign: 'center'}" class="pos-stock-out">
            Stock out
          </el-tag>
        </div>
        <!-- If is discarded Medical Record -->
        <div style="float:left;" v-else>
          <el-tag
            type="danger"
            size="small"
            effect="plain">
            Deleted
          </el-tag>
        </div>
        <div style="clear:both;"></div>
      </template>

      <template slot="actions">
        <div v-if="progress_status === 'done'" style="display: flex;">
          <div
            v-if="medSelect && medSelect.id && !medSelect.discarded"
            :class="showNote ? 'message-box-open' : 'message-box'"
            @click="showNote=!showNote"
          >
            <div class="message-icon">
              <div class="message-amount">{{ noteAmount }}</div>
            </div>
          </div>

          <button
            v-if="!medSelect.discarded && canEditMR"
            class="btn btn-large btn-primary"
            @click="editMedical">
            <i class="fa fa-edit"></i>Edit
          </button>
          <div class="message-box">
            <i
              class="el-icon-close"
              style="align-self: center;"
              @click="doneCloseClick()"></i>
          </div>
        </div>
        <div v-else style="display: flex;">
          <div
            v-if="medSelect && medSelect.id"
            :class="showNote ? 'message-box-open' : 'message-box'"
            @click="showNote=!showNote"
          >
            <div class="message-icon">
              <div class="message-amount">{{ noteAmount }}</div>
            </div>
          </div>
          <button class="btn btn-large btn-default" @click="cancelClick()">Cancel</button>

          <button class="btn btn-large btn-primary" @click="showCreateActionDialog">
            <i class="fa fa-save"></i>Save
          </button>

        </div>
      </template>
    </Subnavbar>

    <el-row >
      <el-col >
        <div v-if=" medSelect && medSelect.id && vets.length > 0 && showNote">
            <MedicalNote :medId="medSelect.id" :vets="vets"></MedicalNote>
        </div>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :md="24">
        <el-row style="margin: 10px 0;">
          <el-col :span="10" v-if="medSelect && medSelect.id">
            {{ medSelect && medSelect.id ? 'Medical Record ID : ' + medSelect.id : ''}}
            <span
              v-if="showDeleteButton"
              @click="confirmDeleteMedical"
              class="tag delete"
              style="border: solid 0.5px #7b7b7d; background-color: #e6eaee; font-size: 12px; cursor: pointer;"
            >Delete</span>
          </el-col>
          <el-col :span="14">
            <el-steps
              v-if="!isIpd"
              class="med-steps"
              :active="currentStep"
              finish-status="success"
              align-center>
              <el-step title="รอคิว"></el-step>
              <el-step title="พบแพทย์"></el-step>
              <el-step title="รอชำระเงิน/รับยา"></el-step>
              <el-step title="เสร็จสิ้น"></el-step>
            </el-steps>
          </el-col>
        </el-row>

        <ContentBox>
          <template slot="header" class="pet-profile-header">Pet Profile</template>
          <div class="pet-profile-content"
              v-loading="this.petLoading"
          element-loading-text="Loading..."
                      element-loading-spinner="el-icon-loading"
                      element-loading-background="rgba(0, 0, 0, 0.8)" >

            <PetProfile class="medical-pet-profile"
              v-if="pet"
              :pet="pet"
              :owner="pet.owners[0]"
              :hospitalId="pet.hospitalId" />

            <div class="rectangle">
              <span class="health-point">
                Health Point:
                <span class="pet-point">{{pet && pet.point}}</span>
                <img
                  src="@/assets/images/common/favorite-true.png"
                  style="width: 15px; height: 15px;"
                />
              </span>
            </div>
          </div>
        </ContentBox>
      </el-col>
    </el-row>

    <el-row type="flex" :gutter="20"
        class="margin-row" :style="{ flexWrap: 'wrap' }"
        v-loading="this.dateLoading"
        element-loading-text="Loading..."
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(0, 0, 0, 0.8)"
        >
      <el-col :span="null" :xs="24" :style="{ flex: '1' }">

          <MedicalAddDetailDate
            :datedata="getRevisionDateData"
            :revision_set="medSelect && medSelect.revision_set"
            :watching_revision="medSelect && medSelect.watching_revision"
            :progress_status="progress_status"
            :after_finish="afterFinish"
            @change="changeRevision" />

      </el-col>

      <el-col
        v-if="showOPDBadDebtButton"
        :span="null" >
        <el-button
          plain
          v-if="!isBadDebt"
          @click="handleSetBadDebtClick"
        >ตัดหนี้สูญ</el-button>
        <el-button
          v-else
          plain
          @click="handleSetBadDebtClick"
        >ยกเลิกตัดหนี้สูญ</el-button>
      </el-col>

      <el-col v-if="showAdmitButton" :span="null" :style="{ marginLeft: 'auto' }">
        <el-button type="primary" @click="handleAdmitButtonClick">Admit</el-button>
      </el-col>

    </el-row>

    <el-row :gutter="20" class="margin-row">
      <el-col :md="24">
          <MedicalAddDetail
            v-if="pet"
            :pet="pet"
            :POData="POData"
            :labexam="labexam"
            :pos="pos"
            :progress_status="progress_status"
            :force_restore="forceRestoreModel"
            :currentStep="currentStep"
            :ipd="$route.meta.ipd"
            :ipdStatus="ipdStatus"
            :actors="medSelect.actors"
            :discardable="!!medSelect.discarded"
            :can-edit-data="canEditMR"
            :can-edit-price-and-stock-amount="canEditPriceAndStockAmount"
          />
      </el-col>
    </el-row>

    <!-- <div v-if="pet"> -->
      <CreateMedicalConfirmDialog
        :visible="showConfirmDialog"
        :finishData="finishData"
        v-on:update:finishData="finishData = $event"
        :type="typeConfirm"
        @confirm="createMedicalRecord"
        @delete="deleteMedical"
        @cancel="showConfirmDialog = false"
      />
    <!-- </div> -->

    <div>
      <VRemindBackTop ref="backtop"></VRemindBackTop>
    </div>
  </div>
</template>

<script>
import _ from "lodash"
import { mapState } from "vuex";
import { PosService } from "@/services";
import medicalType from "@/constants/medical/medical-type.js";
import ContentBox from "@/components/container/ContentBox";
import Subnavbar from "@/components/navigation/Subnavbar";
import PetProfile from "@/components/pet/PetProfile";
import MedicalAddDetailDate from "@/components/medical/MedicalAddDetailDate";
import MedicalAddDetail from "@/components/medical/MedicalAddDetail";
import MedicalNote from "@/components/medical/MedicalNote";
import CreateMedicalConfirmDialog from "@/components/medical/CreateMedicalConfirmDialog";
import $ from "jquery";
import VRemindBackTop from "@/components/ScrollToTop";
import { MMcolor, MMcolorRestore } from "../../constants";
import { showVoidDialog } from "@/components/dialog/receipt";
import { showMedicalSaveDialog } from "@/components/dialog/medical";
import { showStockNotEnoughDialog } from '@/components/dialog/stock-not-enough';
import { showBadDebtDialog } from "@/components/dialog/ipd";
import { IpdService } from "@/services"
import { back } from "@/router"

//--- use for new api call ---
import axios from 'axios'

const getCoreAPI = () => {
  const { coreAPI } = require("@/services/apis");
  return coreAPI;
};

export default {
  provide() {
    return {
      voidReceipt: this.voidReceipt
    }
  },
  components: {
    ContentBox,
    Subnavbar,
    PetProfile,
    MedicalAddDetailDate,
    MedicalAddDetail,
    MedicalNote,
    CreateMedicalConfirmDialog,
    VRemindBackTop
  },
  data() {
    return {
      dateLoading: false,
      petLoading: false,

      ipdStatus: undefined,
      isBadDebt: false,
      medicalType: medicalType,
      medType: "",
      afterFinish: false,
      showNote: false,
      forceRestoreModel: false,
      medSelect: {},
      progress_status: "in_progress",
      showProgressStatus: true,
      MMcolor: MMcolor,
      MMcolorRestore: MMcolorRestore,
      showConfirmDialog: false,
      typeConfirm: "",
/*       datedata: {
        arrived: new Date(),
        finished: null,
        isFinish: false
      }, */
      datedata: null,
      finishData: {
        vet_id: {},
        vet_pin: null
      },
      POData: {
        physicalexam: {
          weight: undefined,
          temp: null,
          HR: null,
          RR: null,
          CRT: null,
          BP: null,
          membrane: null,
          BSCLevel: null,
          note: null,

          is_eye_normal: true,
          is_ophthalmitis: 0,
          is_corneal_opacity: 0,
          is_ulcerate: 0,
          is_hypertension: 0,
          eye_note: null,
          is_ear_normal: true,
          is_inaudible: 0,
          is_earwax: 0,
          is_ear_smelly: 0,
          is_otitis: 0,
          is_mite: 0,
          ear_note: null,
          is_mouth_teeth_gill_normal: true,
          is_mouth_teeth_gill_smelly: false,
          is_gingivitis: false,
          is_dental_calculus: false,
          is_decayed_tooth: false,
          mouth_teeth_gill_note: null,
          is_skin_and_hair_normal: true,
          is_excoriation: false,
          is_oily_skin: false,
          is_hair_lose: false,
          is_rash: false,
          is_tick: false,
          skin_and_hair_note: null,
          is_urinate_and_reproduce_system_normal: true,
          is_guarded: false,
          is_tumor: false,
          is_frown: false,
          urinate_and_reproduce_system_note: null,
          is_alimetary_system_normal: true,
          is_diarrhea: false,
          is_vomit: false,
          is_constipation: false,
          alimetary_system_note: null,
          is_respiratory_system_normal: true,
          is_gasping: false,
          is_cough: false,
          is_snivel: false,
          respiratory_system_note: null,
          is_bone_and_muscle_normal: true,
          is_limp: false,
          is_out_of_shape: false,
          is_deteriorate: false,
          bone_and_muscle_note: null,
          is_heart_normal: true,
          is_disturbance_sound: false,
          is_arrhythmia: false,
          is_enlarged_heart: false,
          heart_note: null,
          is_lymph_node_normal: true,
          is_intumesce: false,
          is_pain: false,
          is_inflame: false,
          lymph_node_note: null,
          is_nervous_system_normal: true,
          is_convulse: false,
          is_weak: false,
          is_paralysis: false,
          nervous_system_note: null
        },
        problem_oriented: {
          cc: "",
          ht: "",
          diagnosis_detail: "",
          tx: "",
          rx: "",
          treatment_plan: "",
          client_edu: "",
          notes: "",
          response_score: null,
          response: "",

          problems: [],
          food_types: [],
          attachments: [],
          diff_diagnosises: [],
          final_diagnosis: null
        }
      },
      labexam: {
        HCT: null,
        HgB: null,
        RBC: null,
        WBC: null,
        NEU: null,
        Lym: null,
        Mono: null,
        Eos: null,
        Baso: null,
        PLT: null,
        MPV: null,
        PCT: null,
        MCHC: null,
        RDW: null,
        RETIC: null,
        MCV: null,
        MCH: null,
        Morphology: null,
        ALP: null,
        ALT: null,
        AST: null,
        CREATINE_KINASE: null,
        GGT: null,
        Albumin: null,
        total_Protein: null,
        Globulin: null,
        total_Bilirubin: null,
        Bilirubin_Conjugated: null,
        BUN: null,
        CREATININE: null,
        Cholesterol: null,
        Glucose: null,
        Calcium: null,
        Phosphorus: null,
        TCO2_Bicarbonate: null,
        Chloride: null,
        Potassium: null,
        Sodium: null,
        Alb_Glob_Ratio: null,
        Bilirubin_Unconjugated: null,
        NA_K_Ratio: null,
        ECanisEewingiiAb: 0,
        LymeDisease: 0,
        AphagocytophilumAplatyAb: 0,
        HeartwormAg: 0,
        GiardiaAg: 0,
        LeptospiraAb: 0,
        FIVAb: 0,
        FeLVAg: 0,
        FelineHeartwormAg: 0,
        ParvovirusAg: 0,
        PancreasSpecificLipase: 0,
        NTproBNP: 0,
        is_pass: null,
        note: "",
        xrayattachment_set: [],
        opdattachment_set: [],
        cbcattachment_set: [],
        ultrasoundattachment_set: [],
        freshsmearattachment_set: [],
        otherlabattachment_set: [],
        otherattachment_set: []
      },
      pos: {
        posactivity_set: [],
        discount: null
      },
      //--- For new api data IPD
      pethospitalid: null,
      discardedDate:null,
      hn: null,
      overall_status: null,
      //progress_status: null,
    };
  },
  watch: {
    //--- Watch for change in hospital ID to display Pet on IPD-MED
    async pethospitalid() {
        //console.log("watch pethospitalid")
        //console.log(this.pethospitalid)
        this.petLoading = true
        const { pet } = await this.$store.dispatch("pet/getPetProfile", {
          params: { petHospitalId: this.pethospitalid }
        });
        pet.hospitalId = this.pethospitalid;
        //--- save pet in store ---
        this.$store.commit("medical/setPet", pet);
        //--- load pet type ---
        await this.$store.dispatch("bloodbank/listLabExamMeta", {
          params: { pet_type: this.pet.type.key }
        });
        this.petLoading = false
    }
  },
  computed: {
    ...mapState({
      appt: state => state.appointment.selected,
      pet: state => state.medical.pet,
      med: state => state.medical.selected,
      vets: state => state.hospital.vets,
      noteAmount: state => state.medical.note && state.medical.note.count,
      currentPlan: state => state.plan.currentPlan,
    }),
    getRevisionDateData() {
      console.log("get Date ")
      return this.datedata
    },
    //--- tag condition
    isSelectMedicalRecord() {
      return (this.medSelect && !this.medSelect.discarded)
    },
    isDone() {
      return (this.currentStep==4 && this.progress_status === 'done')
        || (this.currentStep==4 && this.medSelect.overall_status === 'finished')
    },
    isTreatment() {
      return (this.currentStep==1)
    },
    isPayment() {
      return (this.currentStep==2)
    },
    isReceipt() {
      return (this.medSelect.status_tags != null &&
              this.medSelect.status_tags.receipt_status != null &&
              !(this.medSelect.status_tags.receipt_status === 'for_payment'
                && this.currentStep === 2))
    },
    isStockout() {
      return (this.medSelect.status_tags
              && this.medSelect.status_tags.stock_status === 'stock_out')
    },
    getReceiptStatus() {
      return this.getReceiptStatusTag(this.medSelect.status_tags.receipt_status)['name']
    },
    getReceiptStatusClass() {
      return this.getReceiptStatusTag(this.medSelect.status_tags.receipt_status)['class']
    },
    showAdmitButton() {
      //console.log("is ipd " + this.isIpd)
      //console.log("currentStep " + this.currentStep)
      //console.log("progress_status " + this.progress_status)
      return !this.isIpd && this.currentStep <= 2
          && this.progress_status === 'in_progress'
          && this.currentPlan.ipd_section.can_use_ipd
          && this.currentPlan.ipd_section.can_create_ipd;
    },
    showDeleteButton() {
      if (this.isIpd)
        return !this.medSelect.discarded && this.currentPlan.ipd_section.can_delete_ipd

        return !this.medSelect.discarded && this.currentPlan.opd_section.can_delete_opd
    },
    //--- get Step of OPD
    currentStep() {
      let currentStatus = "exam";
      if (this.med && this.med.overall_status) {
        currentStatus = this.med.overall_status;
      }
      const steps = ["new", "exam", "cashier", "done", "finished"];
      return steps.indexOf(currentStatus);
    },
    canEditPriceAndStockAmount() {
      if (this.isIpd)
        return this.currentPlan.ipd_section.can_edit_ipd_activityrecord_price_and_amount;
      return this.currentPlan.opd_section.can_edit_opd_activityrecord_price_and_amount;
    },
    canEditMR() {
      if (this.currentStep == 2) {
        if (this.isIpd)
          return this.currentPlan.ipd_section.can_edit_mr_after_ipd_send_to_cashier;
        return this.currentPlan.opd_section.can_edit_mr_after_opd_send_to_cashier;

      } else if ((this.currentStep==4 && this.progress_status === 'done')
        || (this.currentStep==4 && this.medSelect.overall_status === 'finished')) {
        if (this.isIpd)
          return this.currentPlan.ipd_section.can_edit_mr_after_ipd_paid;
        return this.currentPlan.opd_section.can_edit_mr_after_opd_paid;
      }

      return true;
    },
    isIpd() {
      return this.$route.meta.ipd
    },
    isMarkDone() {
      return this.$route.path.indexOf("/markdone") !== -1;
    },
    id() {
      if (this.isIpd) return this.$route.params.opdId
      return this.$route.params.id
    },
    title() {
      if (this.isIpd) return "IPD"

      return "OPD Record"
    },

    isCompleteCase() {
      const isDone = (this.currentStep == 4 && this.progress_status === 'done')
                    || (this. currentStep == 4 && this.medSelect && this.medSelect.overall_status === 'finished')
      return this.medSelect.discarded == "deleted" || isDone;
    },

    showOPDBadDebtButton() {
      return !this.isIpd
              && this.id
              && !this.isCompleteCase
              && this.medSelect.last_updated;
    }
  },
  methods: {
    backTop() {
      $("#page").on(
        "scroll",
        function() {
          this.$refs.backtop.handleScroll();
        }.bind(this)
      );
    },

    doneCloseClick() {
      if (this.isIpd || this.medSelect.discarded) {
        return back();
      }

      if (
        this.medType === this.medicalType.TYPE_APPOINTMENT ||
        this.medType === this.medicalType.TYPE_DONE_APPOINTMENT
      ) {
        back();
      } else {
        switch (this.$route.query.ref) {
          case "incomeExpense":
            this.$router.push({ name: "income-expense-list" });
            break

          case "receipt":
          case "overdue":
          case "report":
          case "inventory":
            back();
            break;

          default:
            this.$router.push({ name: "medicals" });
            break;
        }
      }
    },
    cancelClick() {
      if (this.isIpd) {
        back();
        return
      }

      if (
        this.medType === this.medicalType.TYPE_APPOINTMENT ||
        this.medType === this.medicalType.TYPE_DONE_APPOINTMENT
      ) {
        back();
      } else {
        this.afterFinish
          ? this.$router.go()
          : back();
      }
    },

    getReceiptStatusTag(status) {
      const statusList = {
        'for_payment': {
          'class': 'receipt-for-payment',
          'name': 'For payment'
        },
        'paid': {
          'class': 'pos-paid',
          'name': 'Paid'
        },
        'voided': {
          'class': 'receipt-void',
          'name': 'Void'
        }
      };
      return statusList[status];
    },

    getConfirmDialogNoticeText() {
      if (this.medSelect.progress_status === "done") {
        return 'การกด "save" จะเป็นการสร้าง version ใหม่ของ medical record รายการนี้ ถ้าไม่มีการแก้ไขใดๆ กด "cancel" เพื่อออก';
      }
      return "";
    },
    async changeRevision(revision) {
      console.log("chg revision")

      if (
        this.pet &&
        this.medSelect &&
        this.medSelect.watching_revision &&
        this.medSelect.watching_revision.revision !== revision.revision
      ) {
        try {
          await this.$store.dispatch("ui/setIsFullScreenLoading", true);
          await this.$store.dispatch("medical/getMedicalByRevision", {
            params: { id: this.medSelect.id, revision: revision.revision }
          });
          this.medSelect = Object.assign({}, this.med);
          this.POData["physicalexam"] = Object.assign(
            {},
            this.medSelect.physicalexam
          );
          this.POData["problem_oriented"] = Object.assign(
            {},
            this.medSelect.problem_oriented
          );
          this.labexam = Object.assign({}, this.medSelect.labexam);



          if (this.POData.physicalexam.membrane) {
            this.POData.physicalexam.membrane = this.MMcolorRestore[
              this.POData.physicalexam.membrane]
          }

          //--- set DateData ---
          const dateArrive = new Date(this.medSelect.arrived)
          const dateisFinish = (this.progress_status === "done")? true : false
          const dateFinished = (!!this.medSelect.finished) ? new Date(this.medSelect.finished) : null

/*           if (this.progress_status === "done") {
            this.datedata.isFinish = true;
            this.datedata.finished = (!!this.medSelect.finished) ? new Date(this.medSelect.finished) : null;
          } */

          //--- set Date Date object ---
          this.datedata = {
                arrived: dateArrive,
                finished: dateFinished,
                isFinish: dateisFinish,
          }

          //--- End set DateData

          if (this.medSelect.watching_revision) {
            this.finishData.vet_id =
              this.medSelect.watching_revision.vet || null;
          }

          this.forceRestoreModel = !this.forceRestoreModel;
          await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        } catch (error) {
          await this.$store.dispatch("ui/setIsFullScreenLoading", false);
          await this.$store.dispatch("ui/showFetchErrorMsg");
        }
      }
    },
    async deleteMedical() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        let resMed = true;
        await this.$store
          .dispatch("medical/deleteMedical", {
            params: { id: this.id },
          })
          .catch(async err => {
            resMed = false;
            this.$notify({
              message: err,
              type: "error"
            });
          });
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);

        if (resMed) {
          this.$notify({
            message: "ลบข้อมูลเรียบร้อยแล้ว",
            type: "success"
          });
        }

        setTimeout(() => {
          if (this.isIpd) {
            this.$router.go(-1);
          } else {
            this.$router.push({
              name: "medicals"
            });
          }
        }, 1000);
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorMsg");
      }
    },
    async editMedical() {
      this.afterFinish = true;
      this.progress_status = "in_progress";

      if (this.medSelect.progress_status == "done") {
        this.showProgressStatus = false;
      }
    },
    async handleSendToCashier() {
      try {
          await this.$store.dispatch("ui/setIsFullScreenLoading", true);
          const pos = (() => {
            let clonePOS = JSON.parse(JSON.stringify(this.pos));
            clonePOS.posactivity_set = clonePOS.posactivity_set.map((a) => {
              return {
                activity: a.id,
                lot_no: a.lot_no,
                unit_price: a.price,
                amount: a.amount,
                discount: a.discount,
                is_tx: a.is_tx || false,
                is_rx: a.is_rx || false,
                posactivityproduct_set: a.activityProducts
              };
            });
            if (!clonePOS.discount) {
              clonePOS.discount = 0;
            }
            return clonePOS;
          })();
          await this.$store.dispatch("medical/updateMedical", {
            params: { id: this.medSelect.id },
            data: {
              pethospital: this.medSelect.pethospital,
              is_send_to_cashier: true,
              pos: pos,
            },
          });

          this.$notify({
            type: "success",
            title: "บันทึกข้อมูลเรียบร้อยแล้ว",
          });
          await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
          await this.$store.dispatch("ui/setIsFullScreenLoading", false);
          await this.$store.dispatch("ui/showFetchErrorMsg", error);
      }
    },
    async showCreateActionDialog() {
      //---Show Dialog Here ---
      console.log("1. Show Create Action Dialog")

      if (!this.medSelect.id) {
        const showSendToCashierButton = (() => {
            return this.isIpd
                ? this.currentPlan.ipd_section.can_use_ipd_cashier_step
                : this.currentPlan.opd_section.can_use_opd_cashier_step;
        })();
        const showSendToCashierAndCutStockButton = (() => {
            return this.currentPlan.stock_section.can_stock_out_with_unpaid
                  && showSendToCashierButton;
        })();
        const showPaymentButton = (() => {
            return this.currentPlan.opd_section.can_use_opd_payment_step
                  && showSendToCashierButton;
        })();

        const total = (() => {
          let total = this.pos.posactivity_set.reduce((total, item) => {
            if (item.price && item.amount) {
              return (
                total + parseFloat(item.price) * item.amount - (item.discount || 0)
              );
            }
            return total;
          }, 0);
          return total;
        })();

        const action = await showMedicalSaveDialog({
          type: this.isIpd ? "ipd" : "opd",
          price: total,
          showSendToCashierButton: showSendToCashierButton,
          showSendToCashierAndCutStockButton: showSendToCashierAndCutStockButton,
          showPaymentButton: showPaymentButton
        });

        const save = async () => {
          const id = await this.confirmCreateMedical({ reload: false });
          await this.$store.dispatch("medical/getMedical", {
            params: { id: id }
          });

          this.setNewMedical(this.med);
        };

        switch (action) {
          case "confirm":
            console.log("2. Confirm Action")
            await save();
            break;

          case "sendToCashier":
            await save();
            await this.handleSendToCashier();
            break;

          case "sendToCashierAndCutStock":
            await save();
            await this.handleSendToCashier();
            const {
              data: { status_code, message },
            } = await PosService.cutStock({
              pos_set: [this.pos.id],
            });

            if (status_code == 460) {
              try {
                await showStockNotEnoughDialog({
                  items: message
                });
              } finally {
                this.$router.replace({
                  name: "medicals-edit-detail",
                  params: { id: this.medSelect.id }
                });
                this.$router.go();
              }
            }
            break;

          case "payment":
            await save();
            await this.handleSendToCashier();
            this.$router.replace({
              name: "medicals-edit-detail",
              params: { id: this.medSelect.id }
            });
            this.$router.push({
              name: "payment",
              params: { id: this.medSelect.id }
            });
            return
            break;
        }

        if (this.isIpd) {
          this.$router.replace({
            name: "ipd-detail",
            params: { id: this.$route.params.id }
          });
          return
        } else {
          this.$router.replace({
            name: "medicals-edit-detail",
            params: { id: this.medSelect.id }
          });
          this.$router.go();
        }
      } else {
        this.confirmCreateMedical();
      }
    },

    async confirmCreateMedical(options) {
      console.log("Confirm Create Medical")

      options = { reload: true, ...options }

      console.log(this.datedata)
      //--- if this.datedata is null then set default ---
      if (!this.datedata) {
        console.log("init here")
        this.datedata= {
            arrived: new Date(),
            finished: null,
            isFinish: false
        }
      }

      if (this.datedata.isFinish) {
        this.typeConfirm = "create";
        this.showConfirmDialog = true;

      } else {
        if (this.isIpd) {
          const id = await this.createMedicalRecord({ reload: false });

          if (this.id) {
            this.$router.replace({ name: "ipd-detail", params: { id: this.$route.params.id } });
            return
          }

          if (id) {
            try {
              this.$store.dispatch("ui/setIsFullScreenLoading", true);

              const {
                data: { result }
              } = await IpdService.createMedicalRecord(
                this.$route.params.id,
                { medical_record: id }
              );

              if (options.reload) {
                this.$router.replace({
                  name: "ipd-detail",
                  params: { id: result.id }
                });
              }

            } catch (e) {
              this.$notify.error("เกิดข้อผิดพลาดในการเพิ่ม medical record");
            } finally {
              // this.$store.dispatch("ui/setIsFullScreenLoading", false);
            }
          }

          return id;
        }

        if (!options.reload) {
          const id = await this.createMedicalRecord({ reload: false });
          return id;
        }

        return this.createMedicalRecord();
      }
    },
    confirmDeleteMedical() {
      this.typeConfirm = "delete";
      this.showConfirmDialog = true;
    },
    mapLabAttachment(attch) {
      delete attch.attachmentPreview;
      delete attch.created;
      delete attch.deleted;
      delete attch.id;
      delete attch.last_updated;
      if (isNaN(attch.attachment)) {
        attch.attachment = attch.attachment.id;
      }
      this.mapSelectbox(attch, "tags");
      if (!Array.isArray(attch.tags)) {
        attch.tags = [];
      }
      return attch;
    },
    mapSelectbox(selectData, fieldName) {
      if (selectData[fieldName]) {
        selectData[fieldName] = selectData[fieldName].map(x => x.id);
      }
    },
    cloneLabExam() {
      let labData = Object.assign({}, this.labexam);
      for (var prop in labData) {
        if (labData[prop] === null || labData[prop] === undefined) {
          delete labData[prop];
        }
      }
      let cloneLabExam = JSON.parse(JSON.stringify(labData));
      // eslint-disable-next-line
      cloneLabExam.opdattachment_set.forEach(attch => {
        attch = this.mapLabAttachment(attch);
      });
      // eslint-disable-next-line
      cloneLabExam.otherattachment_set.forEach(attch => {
        attch = this.mapLabAttachment(attch);
      });
      // eslint-disable-next-line
      cloneLabExam.otherlabattachment_set.forEach(attch => {
        attch = this.mapLabAttachment(attch);
      });
      // eslint-disable-next-line
      cloneLabExam.ultrasoundattachment_set.forEach(attch => {
        attch = this.mapLabAttachment(attch);
      });
      // eslint-disable-next-line
      cloneLabExam.xrayattachment_set.forEach(attch => {
        attch = this.mapLabAttachment(attch);
      });
      // eslint-disable-next-line
      cloneLabExam.freshsmearattachment_set.forEach(attch => {
        attch = this.mapLabAttachment(attch);
      });
      // eslint-disable-next-line
      cloneLabExam.cbcattachment_set.forEach(attch => {
        attch = this.mapLabAttachment(attch);
      });
      return cloneLabExam;
    },
    clonePOData() {
      let poData = Object.assign({}, this.POData);
      for (var prop in poData.physicalexam) {
        if (poData.physicalexam[prop] === null || poData.physicalexam[prop] === undefined) {
          delete poData.physicalexam[prop];
        }
      }
      let clonePOData = JSON.parse(JSON.stringify(poData));
      if (clonePOData.physicalexam.membrane) {
        clonePOData.physicalexam.membrane = this.MMcolor[
          clonePOData.physicalexam.membrane
        ];
      }
      clonePOData.problem_oriented.attachments = clonePOData.problem_oriented.attachments.map(
        attch => attch.id
      );
      this.mapSelectbox(clonePOData.problem_oriented, "diff_diagnosises");
      this.mapSelectbox(clonePOData.problem_oriented, "problems");
      this.mapSelectbox(clonePOData.problem_oriented, "food_types");
      if (clonePOData.problem_oriented.final_diagnosis) {
        clonePOData.problem_oriented.final_diagnosis =
          clonePOData.problem_oriented.final_diagnosis.id;
      }
      return clonePOData;
    },
    clonePOS() {
      let clonePOS = JSON.parse(JSON.stringify(this.pos));
      clonePOS.posactivity_set = clonePOS.posactivity_set.map(a => {
        return {
          activity: a.id,
          lot_no: a.lot_no,
          unit_price: a.price,
          amount: a.amount,
          discount: a.discount,
          is_tx: a.is_tx || false,
          is_rx: a.is_rx || false,
          posactivityproduct_set: a.activityProducts
        };
      });
      if (!clonePOS.discount) {
        clonePOS.discount = 0;
      }
      return clonePOS;
    },
    restorePOSFromAppointment() {
      let res = [];
      this.appt.activities.forEach(activity => {
        res.push({
          id: activity.id,
          lot_no: "",
          amount: 1,
          price: activity.price ? activity.price : 0,
          name: activity.name,
          products: (activity.products)
                          ? activity.products.map((item) => ({
                              id: item.id,
                              trade_name: item.trade_name,
                              amount: item.amount,
                              unit: item.unit,
                            }))
                          : [],
          activityProducts: (activity.products)
                          ? activity.products.map((item) => ({
                              product: item.id,
                              amount: item.amount,
                            }))
                          : []
        });
      });
      this.pos.id = null;
      this.pos.posactivity_set = res;
      this.pos.grand_total_price = {
        discount: "0",
        before_discount: "0",
        after_discount: "0"
      };
      this.pos.note = "";
      this.pos.discount = "0";
      this.pos.last_updated = new Date();
    },
    restorePOS() {
      let restorePos = JSON.parse(JSON.stringify(this.pos.posactivity_set));
      restorePos.forEach(activity => {
        activity.id = activity.activity.id;
        activity.name = activity.activity.name;
        activity.products = (activity.posactivityproduct_set)
                          ? activity.posactivityproduct_set.map((item) => ({
                              id: item.product.id,
                              trade_name: item.product.trade_name,
                              amount: item.amount,
                              unit: item.product.unit,
                            }))
                          : []
        activity.activityProducts = (activity.posactivityproduct_set)
                          ? activity.posactivityproduct_set.map((item) => ({
                              product: item.product.id,
                              amount: item.amount,
                            }))
                          : [];
        activity.price = activity.unit_price;
      });
      this.pos.posactivity_set = JSON.parse(JSON.stringify(restorePos));
    },
    setNewMedical(medicalRecord) {
      console.log("setNewMedical")

      this.medSelect = Object.assign({}, medicalRecord);
      this.POData["physicalexam"] = Object.assign(
        {},
        this.medSelect.physicalexam
      );
      this.POData["problem_oriented"] = Object.assign(
        {},
        this.medSelect.problem_oriented
      );
      this.labexam = Object.assign({}, this.medSelect.labexam);
      this.pos = Object.assign({}, this.medSelect.pos);
      this.restorePOS();

      //--- set datedata ---
 /*      const dateArrive =
        new Date(this.medSelect.arrived) || this.datedata.arrived; */

      if (this.POData.physicalexam.membrane) {
        this.POData.physicalexam.membrane = this.MMcolorRestore[
          this.POData.physicalexam.membrane
        ];
      }

      this.isBadDebt = this.medSelect.is_bad_debt;

      if (this.medSelect.discarded) {
        this.progress_status = "done";

      } else if (this.medSelect && (this.medSelect.pos && this.medSelect.pos.receipt)) {
        this.progress_status = this.medSelect.progress_status;
      }

      //--- set datedata ---
/*       if (this.progress_status === "done") {
        this.datedata.isFinish = true;
        this.datedata.finished = (!!this.medSelect.finished) ? new Date(this.medSelect.finished) : null;
      } */

      const dateArrive = new Date(this.medSelect.arrived) || new Date()
      const dateisFinish = (this.progress_status === "done") ? true : false
      const dateFinished = (!!this.medSelect.finished) ? new Date(this.medSelect.finished) : null

      this.datedata = {
          arrived: dateArrive,
          finished: dateFinished,
          isFinish: dateisFinish,
      }

      console.log(this.datedata)

      if (this.medSelect.watching_revision) {
        this.finishData.vet_id = this.medSelect.watching_revision.vet || null;
      }
    },
    async createMedicalRecord(options) {
      console.log("Create Medical Record")
      console.log()
      options = { reload: true, ...options }

      this.showConfirmDialog = false;
      let data = {};

      console.log(this.datedata)
      data["arrived"] = this.datedata.arrived;
      data["finished"] = this.datedata.finished;
      if (this.datedata.isFinish) {
        data["progress_status"] = "done";
      }
      data["vet_id"] = this.finishData.vet_id
        ? this.finishData.vet_id.id
        : null;
      data["vet_pin"] = this.finishData.vet_pin;
      data["pet"] = this.pet.id;
      let newPOData = this.clonePOData();
      data["physicalexam"] = newPOData.physicalexam;
      data["problem_oriented"] = newPOData.problem_oriented;
      data["labexam"] = this.cloneLabExam();
      data["pos"] = this.clonePOS();

      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        let resMed = true;
        let params = {};
        let resultMed = {};
        let dispatchUrl = "medical/createMedical";
        if (
          this.$route.path.indexOf("/pet") !== -1 ||
          this.$route.path.indexOf("/markdone") !== -1 ||
          this.isIpd && this.$route.path.indexOf("/create") !== -1
        ) {

          if (this.medType === this.medicalType.TYPE_APPOINTMENT) {
            dispatchUrl = "medical/createAppointmentMarkdoneMedical";
            params = { id: this.appt.id };
            data["action"] = "markdone";
          }

          resultMed = await this.$store
            .dispatch(dispatchUrl, {
              params: params,
              data: data
            })
            .catch(async err => {
              resMed = false;
              this.$notify({
                message: JSON.stringify(err),
                type: "error"
              });
            });
        } else {
          resultMed = await this.$store
            .dispatch("medical/updateMedical", {
              params: { id: this.id },
              data: {
                ...data,
                pos: _.omit(data.pos, ['vet'])
              }
            })
            .catch(async err => {
              resMed = false;
              this.$notify({
                message: JSON.stringify(err),
                type: "error"
              });
            });
        }

        if (resMed) {
          this.$notify({
            message: "บันทึกเรียบร้อยแล้ว",
            type: "success"
          });
          if (
            this.$route.path.indexOf("/pet") !== -1 ||
            this.$route.path.indexOf("/markdone") !== -1 ||
            this.isIpd && this.$route.path.indexOf("/create") !== -1
          ) {
            if (options.reload) {
              this.$router.replace({
                name: "medicals-edit-detail",
                params: { id: resultMed.id }
              });
              this.$router.go();
            }
          } else {
            await this.$store.dispatch("medical/getMedical", {
              params: { id: this.id }
            });
            this.setNewMedical(this.med);
            this.forceRestoreModel = !this.forceRestoreModel;
            await this.$store.dispatch("ui/setIsFullScreenLoading", false);
          }
        }

        if (Object.keys(resultMed).length) {
          return resultMed.id
        }
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorMsg");
      }
    },
    async handleAdmitButtonClick() {
      await this.$confirm("ส่งไป Admit เป็นสัตว์ป่วยใน?", "ยืนยันส่งไป Admit", {
        type: "warning"
      });

      const id = await this.createMedicalRecord({ reload: false });

      if (id) {
        try {
          this.$store.dispatch("ui/setIsFullScreenLoading", true);

          const {
            data: { result }
          } = await IpdService.create({ medical_record: id });

          this.$router.replace({ name: "ipd-detail", params: { id: result.id } });
        } catch (e) {
          this.$notify.error(e.detail);
        } finally {
          this.$store.dispatch("ui/setIsFullScreenLoading", false);
        }
      }
    },
    async voidReceipt() {
      const doVoid = async () => {
        this.$store.dispatch("ui/setIsFullScreenLoading", true);
        try {
          await getCoreAPI().post(
            `/receipt/receipt/${this.pos.receipt.id}/qvoid/`
          );
          this.$notify.success("ยกเลิกใบเสร็จเรียบร้อยแล้ว");
        } catch (e) {
          this.$notify.error("เกิดปัญหาในการยกเลิกใบเสร็จ");
          throw e;
        } finally {
          this.$store.dispatch("ui/setIsFullScreenLoading", false);
        }
      };

      const action = await showVoidDialog({
        id: this.pos.receipt ? this.pos.receipt.receipt_number : undefined,
        type: "opd",
      });

      switch (action) {
        case "void":
          await doVoid();

          this.doneCloseClick();

          break;

        case "void_and_edit":
          const oldPOSActivitySet = this.pos.posactivity_set;
          await doVoid();

          this.$store.dispatch("ui/setIsFullScreenLoading", true);

          await this.$store.dispatch("medical/getMedical", {
            params: { id: this.id }
          });

          const oldActivityMed = Object.assign({}, this.med);
          oldActivityMed.pos.posactivity_set = oldPOSActivitySet;

          this.setNewMedical(oldActivityMed);

          this.editMedical();

          this.$store.dispatch("ui/setIsFullScreenLoading", false);

          break;
      }
    },

    async handleSetBadDebtClick() {
      const total = this.pos && this.pos.grand_total_price
        ? +this.pos.grand_total_price.after_discount
        : 0;

      const setBadDebt = async id => {
        try {
          this.$store.dispatch("ui/setIsFullScreenLoading", true);

          await this.$store.dispatch("medical/setBadDebt", {
            params: { id: this.id },
            data: { is_bad_debt: !this.isBadDebt }
          });

          this.$notify.success("บันทึกข้อมูลเรียบร้อยแล้ว");

          this.$router.go();
        } catch (e) {
          this.$notify.error(e.detail);
        } finally {
          this.$store.dispatch("ui/setIsFullScreenLoading", false);
        }
      }

      const action = await showBadDebtDialog({
        type: 'opd',
        actionType: this.isBadDebt ? 'cancel' : 'confirm',
        id: this.id,
        total: total
      });

      switch (action) {
        case "confirm":
          await setBadDebt(this.id);
      }
    },
    //--- New api Call Function ---
    async fetchPetHospital(ipd) {
      //--- Call api from monitor server ---
      const api = axios.create({
          baseURL: process.env.VUE_APP_API_ENDPOINT2
      })
      //--- params for api call ---
      const params = `ipd=${ipd}`
      //this.isLoading = true;
      api.get('/ipddetail/' + params).then(response => {
          console.log(response.data)
          const cases = response.data.ipddetail;

          cases.forEach(el  => {
              this.pethospitalid = el.pethospital_id
              this.finishedDate = el.finished
              this.discardedDate = el.discarded
              this.hn = el.hn
              this.overall_status = el.overall_status
          });

          this.ipdStatus = (() => {
            if (this.discardedDate) return "deleted"
            if (this.overall_status === "overdue") return "overdue"
            if (this.finishedDate) return "finish"
            return "active"
          })()

      }).catch(err => {
          console.log(err.response);
      });
    },
  },
  //--- Compoent System Function ---
  async mounted() {
    this.backTop();

    try {
      if (this.medSelect && this.medSelect.id) {
        await this.$store.dispatch("medical/getMedicalNote", {
          params: { id: this.medSelect.id }
        });
      }
    } catch (e) {
      // eslint-disable-next-line
    }
  },
  async created() {
    console.log("this is Created.")
    //console.log(this.id)

    this.$store.commit("medical/SET_BACKUP_ACTIVITIES", null);

    try {
      await this.$store.dispatch("ui/setIsFullScreenLoading", true);
      this.medType = this.$route.params.type || this.medicalType.TYPE_WALKIN;

      //--- Check For Loading Medical Record ---
      if (this.id && this.$route.path.indexOf("/pet") === -1 && !this.isMarkDone) {
        console.log("MedicalRecord= " + this.id)
        await this.$store.dispatch("medical/getMedical", {
          params: { id: this.id }
        });

        this.setNewMedical(this.med);

        await this.$store.dispatch("medical/getMedicalNote", {
          params: { id: this.medSelect.id }
        })
      }

      //--- Check for ipd records ---
      if (this.isIpd) {
        //console.log("IPD= " + this.$route.params.id)

        this.fetchPetHospital(this.$route.params.id)

      } else {
        this.dateLoading = true

        //--- this is not IPD ---
        console.log("This is OPD ")

        if (this.$route.path.indexOf("/pet") !== -1) {
          //--- get Petid from Link path ---
          console.log("1. OPD- HN" + this.id )

          this.petLoading = true
          let newPet = await this.$store.dispatch("pet/getPetProfile", {
                                  params: { petHospitalId: this.id } })

          newPet.pet.hospitalId = this.id
          this.$store.commit("medical/setPet", newPet.pet)
          //--- load pet type ---
          await this.$store.dispatch("bloodbank/listLabExamMeta", {
            params: { pet_type: this.pet.type.key }
          })

          this.petLoading = false
        } else if (this.isMarkDone) {

          console.log("2. Done OPD-HN" + this.id )

          this.petLoading = true
          await this.$store.dispatch("appointment/getAppointment", {
            params: { id: this.id }
          })

          const pet = this.appt.pet;
          pet.hospitalId = this.appt.pet.pet_hospital.id;
          this.$store.commit("medical/setPet", pet);

          //--- load pet type ---
          await this.$store.dispatch("bloodbank/listLabExamMeta", {
            params: { pet_type: this.pet.type.key }
          });
          this.petLoading = false

          this.restorePOSFromAppointment();
        } else {
          console.log("3. N-Done OPD-HN" + this.medSelect.pethospital)

          this.petLoading = true
          let newPet = await this.$store.dispatch("pet/getPetProfile", {
            params: { petHospitalId: this.medSelect.pethospital }
          });

          newPet.pet.hospitalId = this.medSelect.pethospital;
          this.$store.commit("medical/setPet", newPet.pet);

          //--- load pet type ---
          await this.$store.dispatch("bloodbank/listLabExamMeta", {
            params: { pet_type: this.pet.type.key }
          })

          this.petLoading = false
        }
      }

      await this.$store.dispatch("hospital/listVets");
      await this.$store.dispatch("ui/setIsFullScreenLoading", false);
    } catch (error) {
      console.log(error)
      await this.$store.dispatch("ui/setIsFullScreenLoading", false);
    }

    this.dateLoading = false
  },
  beforeDestroy() {
    this.$store.commit("medical/SET_PET", null);
    this.$store.commit("medical/SET_BACKUP_ACTIVITIES", null);
    this.$store.commit("medical/SET_SELECTED", null);
    $("#page").off("scroll");
  }
};
</script>

<style lang="scss" scoped>
.medical-pet-profile {
  margin:0;
}
.pet-profile-header {
  border-radius: 4px;
  border: solid 1px #e6eaee;
}
.pet-profile-content {
  display: flex;
  flex-direction: column;
  margin-left: 5%;
}
 .el-row {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  .el-col-8 {
    width: 10%;
  }
  .el-col-16 {
    width: 35%;
  }
}
.margin-row {
  margin: 10px 0;
}
.rectangle {
  margin-top: 10px;
  display: flex;
  align-items: center;
  align-self: center;
  width: 280px;
  height: 45px;
  border-radius: 5px;
  background-color: #f5f8fa;

}
.health-point {
  margin-left:30px;
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  color: #7f8fa4;
}
.pet-point {
  margin: 0 20px;
  font-family: Arial;
  font-size: 18px;
  font-weight: Bold;
  color: #d0025b;
}
.message-box {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.message-box-open {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  background-color: #eef5f9;
}
.message-icon {
  display: flex;
  align-self: center;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background-image: url("../../assets/icons/message-three-points.png");
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.message-amount {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15px;
  width: 15px;
  margin-top: 15px;
  margin-left: 15px;
  border-radius: 50%;
  background-color: #5b9f11;
  color: white;
}
.vet-fullname {
  font-family: Arial;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  color: #7f8fa4;
  margin-right: 10px;
  display: flex;
  justify-content: flex-end;
}
</style>

<style lang="scss">
.med-steps {
  .el-step__head.is-success {
    color: #02b0e1;
    border-color: #02b0e1;
  }
  .el-step__title.is-success {
    color: #02b0e1;
  }
  .el-step__head.is-process {
    color: #02b0e1;
    border-color: #02b0e1;
  }
  .el-step__title.is-process {
    color: #02b0e1;
  }
}
</style>
