<template>
  <div style="width: 100%">
    <el-row>
      <slot
        class="headSlot"
        name="header"
        :currentProgress="this.myProgress"
        :currentText="this.myText"
        :currentVetType="myVetType"
      ></slot>
    </el-row>

    <el-row>
      <!-- Content -->
      <el-row>
        <el-row class="medical-record-card">
          <el-col>
            <img
              src="@/assets/bloodbank/file.png"
              height="20"
            >
            <span>เป้าหมายการให้เลือด</span>
          </el-col>
        </el-row>

        <el-card style="padding-top: 15px; padding-left: 10px; padding-right: 10px;">
          <div
            class="row"
            :key="idx"
            v-for="(item, idx) in request.recipientProfileInfo.productRequest.selectedTargets"
          >
            <div class="col-md-12 col-xs-12 col-sm-12">
              <div
                class="flex-end flex-middle"
                style="padding-right: 15px; padding-top: 5px;"
              >
                <div>
                  <label>Target:</label>
                  <el-select
                    :disabled="request.isCaseClosed()"
                    class="flex-start"
                    style="width: 30%"
                    v-model="request.recipientProfileInfo.productRequest.selectedTargets[idx]"
                  >
                    <el-option :value="targetType.PCV">PCV</el-option>
                    <el-option :value="targetType.PLASMA">Plasma</el-option>
                    <el-option :value="targetType.PLATELET">Platelet</el-option>
                  </el-select>
                </div>
              </div>
            </div>

            <div
              class="col-md-12 col-xs-12 col-sm-12 flex-start"
              style="padding-top: 5px;"
            >
              <div class="row">
                <el-input
                  :disabled="request.isCaseClosed()"
                  v-validate="'required|between:1,100'"
                  v-if="request.recipientProfileInfo.productRequest.selectedTargets[idx] === ''"
                  placeholder="Please input"
                  style="width: 30%;"
                ></el-input>
                <el-input
                  :disabled="request.isCaseClosed()"
                  v-validate="'required|between:1,100'"
                  data-vv-name="PCV"
                  :status="errors.has('PCV') ? 'error' : ''"
                  v-if="request.recipientProfileInfo.productRequest.selectedTargets[idx] === targetType.PCV"
                  v-model="request.recipientProfileInfo.productRequest.targetPcv"
                  placeholder="Please input"
                  style="width: 30%;"
                ></el-input>
                <el-input
                  :disabled="request.isCaseClosed()"
                  v-validate="'required|between:1,100'"
                  data-vv-name="PLASMA"
                  :status="errors.has('PLASMA') ? 'error' : ''"
                  v-if="request.recipientProfileInfo.productRequest.selectedTargets[idx] === targetType.PLASMA"
                  v-model="request.recipientProfileInfo.productRequest.targetPlasma"
                  placeholder="Please input"
                  style="width: 30%;"
                ></el-input>
                <el-input
                  :disabled="request.isCaseClosed()"
                  v-validate="'required|between:1,100'"
                  data-vv-name="PLATELET"
                  :status="errors.has('PLATELET') ? 'error' : ''"
                  v-if="request.recipientProfileInfo.productRequest.selectedTargets[idx] === targetType.PLATELET"
                  v-model="request.recipientProfileInfo.productRequest.targetPlatelet"
                  placeholder="Please input"
                  style="width: 30%;"
                ></el-input>
                <p style="margin-left: 10px;">%&nbsp;</p>
                <span
                  style="color: red;"
                  v-if="request.recipientProfileInfo.productRequest.targetPcv && (request.recipientProfileInfo.labExam.HCT) > Number(request.recipientProfileInfo.productRequest.targetPcv)"
                >เป้าหมาย PCV น้อยกว่า PCV ตั้งต้น</span>
                <at-button
                  :disabled="request.isCaseClosed()"
                  icon="icon-x"
                  style="margin-left: 5px;"
                  hollow
                  @click="request.recipientProfileInfo.productRequest.selectedTargets.splice(idx, 1)"
                  v-if="request.recipientProfileInfo.productRequest.selectedTargets.length > 1"
                ></at-button>
              </div>
            </div>
          </div>

          <div
            class="row flex-center"
            style="margin-top: 10px;"
          >
            <at-button
              @click="request.recipientProfileInfo.productRequest.selectedTargets.push('')"
              :disabled="request.recipientProfileInfo.productRequest.selectedTargets.length >= 3 || request.isCaseClosed()"
              type="primary"
            >
              <i class="icon icon-plus"></i>เพิ่ม Target
            </at-button>
          </div>

          <div
            class="row flex-center"
            style="margin-top: 15px;"
          >
            <at-button
              :disabled="request.isCaseClosed()"
              size="large"
              type="success"
              v-on:click="calculateQuantity"
            >กดเพื่อคำนวนปริมาณ</at-button>
          </div>
        </el-card>

        <el-row class="medical-record-card">
          <el-col>
            <img
              src="@/assets/bloodbank/file.png"
              height="20"
            >
            <span>ผลิตภัณฑ์เลือดที่ต้องการ</span>
          </el-col>
        </el-row>
        <el-card
          style="padding-top: 15px; padding-left: 10px; padding-right: 10px;"
          v-if="calculatedBloodProduct != null"
        >
          <el-card>
            <h4 slot="title">เลือกผลิตภัณฑ์เลือด</h4>

            <div class="row">
              <div class="col-md-9 col-xs-9 col-sm-9"></div>

              <div class="col-md-5 col-xs-5 col-sm-5 flex-center">
                <label class="error-field-label">
                  ปริมาณที่ต้องการ (ml.)
                  <span>*</span>
                </label>
              </div>

              <div class="col-md-5 col-xs-5 col-sm-5 flex-center">
                <label>ปริมาณจากสูตร (ml.)</label>
              </div>

              <div class="col-md-5 col-xs-5 col-sm-5 flex-center">
                <label>ปริมาณที่แนะนำจาก AI (ml.)</label>
              </div>
            </div>

            <div
              class="row"
              style="height: 35px; margin-top: 10px;"
            >
              <div class="col-md-9 col-xs-9 col-sm-9 flex-start">
                <el-radio
                  :disabled="request.isCaseClosed()"
                  size="large"
                  v-model="request.recipientProfileInfo.productRequest.selectedBloodProduct"
                  v-bind:label="bloodProductEnum.FWB"
                >
                  <span style="font-size: 16px; font-weight: bold;">Fresh Whole Blood (FWB)</span>
                </el-radio>
              </div>

              <div class="col-md-5 col-xs-5 col-sm-5 flex-center">
                <el-input
                  :disabled="request.isCaseClosed()"
                  v-validate="{ rules: { required: request.recipientProfileInfo.productRequest.selectedBloodProduct === bloodProductEnum.FWB, min_value :1} }"
                  v-model="request.recipientProfileInfo.productRequest.quantity"
                  v-if="request.recipientProfileInfo.productRequest.selectedBloodProduct === bloodProductEnum.FWB"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                ></el-input>
              </div>

              <div class="col-md-5 col-xs-5 col-sm-5 flex-center">
                <label v-if="request.recipientProfileInfo.productRequest.selectedBloodProduct === bloodProductEnum.FWB">{{ this.calculatedBloodProduct.FWB.quantityFromEquation }}</label>
              </div>

              <div class="col-md-5 col-xs-5 col-sm-5 flex-center">
                <label v-if="request.recipientProfileInfo.productRequest.selectedBloodProduct === bloodProductEnum.FWB">{{ this.calculatedBloodProduct.FWB.quantityFromAI }}</label>
              </div>
            </div>

            <div
              class="row"
              style="height: 35px; margin-top: 10px;"
            >
              <div class="col-md-9 flex-start">
                <el-radio
                  :disabled="request.isCaseClosed()"
                  size="large"
                  v-model="request.recipientProfileInfo.productRequest.selectedBloodProduct"
                  v-bind:label="bloodProductEnum.SWB"
                >
                  <span style="font-size: 16px; font-weight: bold;">Stored Whole Blood (SWB)</span>
                </el-radio>
              </div>

              <div class="col-md-5 flex-center">
                <el-input
                  :disabled="request.isCaseClosed()"
                  v-validate="{ rules: { required: request.recipientProfileInfo.productRequest.selectedBloodProduct === bloodProductEnum.SWB, min_value :1} }"
                  v-model="request.recipientProfileInfo.productRequest.quantity"
                  v-if="request.recipientProfileInfo.productRequest.selectedBloodProduct === bloodProductEnum.SWB"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                ></el-input>
              </div>

              <div class="col-md-5 flex-center">
                <label v-if="request.recipientProfileInfo.productRequest.selectedBloodProduct === bloodProductEnum.SWB">{{ this.calculatedBloodProduct.SWB.quantityFromEquation }}</label>
              </div>

              <div class="col-md-5 flex-center">
                <label v-if="request.recipientProfileInfo.productRequest.selectedBloodProduct === bloodProductEnum.SWB">{{ this.calculatedBloodProduct.SWB.quantityFromAI }}</label>
              </div>
            </div>

            <div
              class="row"
              style="height: 35px; margin-top: 10px;"
            >
              <div class="col-md-9 flex-start">
                <el-radio
                  :disabled="request.isCaseClosed()"
                  size="large"
                  v-model="request.recipientProfileInfo.productRequest.selectedBloodProduct"
                  v-bind:label="bloodProductEnum.PRBCS"
                >
                  <span style="font-size: 16px; font-weight: bold;">Packed RBCs (pRBCs)</span>
                </el-radio>
              </div>

              <div class="col-md-5 flex-center">
                <el-input
                  :disabled="request.isCaseClosed()"
                  v-validate="{ rules: { required: request.recipientProfileInfo.productRequest.selectedBloodProduct === bloodProductEnum.PRBCS, min_value :1} }"
                  v-model="request.recipientProfileInfo.productRequest.quantity"
                  v-if="request.recipientProfileInfo.productRequest.selectedBloodProduct === bloodProductEnum.PRBCS"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                ></el-input>
              </div>

              <div class="col-md-5 flex-center">
                <label v-if="request.recipientProfileInfo.productRequest.selectedBloodProduct === bloodProductEnum.PRBCS">{{ this.calculatedBloodProduct.PRBCS.quantityFromEquation }}</label>
              </div>

              <div class="col-md-5 flex-center">
                <label v-if="request.recipientProfileInfo.productRequest.selectedBloodProduct === bloodProductEnum.PRBCS">{{ this.calculatedBloodProduct.PRBCS.quantityFromAI }}</label>
              </div>
            </div>

            <div
              class="row"
              style="height: 35px; margin-top: 10px;"
            >
              <div class="col-md-9 flex-start">
                <el-radio
                  :disabled="request.isCaseClosed()"
                  size="large"
                  v-model="request.recipientProfileInfo.productRequest.selectedBloodProduct"
                  v-bind:label="bloodProductEnum.PRP"
                >
                  <span style="font-size: 16px; font-weight: bold;">Platelet Rich Plasma (PRP)</span>
                </el-radio>
              </div>

              <div class="col-md-5 flex-center">
                <el-input
                  :disabled="request.isCaseClosed()"
                  v-validate="{ rules: { required: request.recipientProfileInfo.productRequest.selectedBloodProduct === bloodProductEnum.PRP, min_value :1} }"
                  v-model="request.recipientProfileInfo.productRequest.quantity"
                  v-if="request.recipientProfileInfo.productRequest.selectedBloodProduct === bloodProductEnum.PRP"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                ></el-input>
              </div>

              <div class="col-md-5 flex-center">
                <label v-if="request.recipientProfileInfo.productRequest.selectedBloodProduct === bloodProductEnum.PRP">{{ this.calculatedBloodProduct.PRP.quantityFromEquation }}</label>
              </div>

              <div class="col-md-5 flex-center">
                <label v-if="request.recipientProfileInfo.productRequest.selectedBloodProduct === bloodProductEnum.PRP">{{ this.calculatedBloodProduct.PRP.quantityFromAI }}</label>
              </div>
            </div>

            <div
              class="row"
              style="height: 35px; margin-top: 10px;"
            >
              <div class="col-md-9 flex-start">
                <el-radio
                  :disabled="request.isCaseClosed()"
                  size="large"
                  v-model="request.recipientProfileInfo.productRequest.selectedBloodProduct"
                  v-bind:label="bloodProductEnum.FFP"
                >
                  <span style="font-size: 16px; font-weight: bold;">Fresh Frozen Plasma (FFP)</span>
                </el-radio>
              </div>

              <div class="col-md-5 flex-center">
                <el-input
                  :disabled="request.isCaseClosed()"
                  v-validate="{ rules: { required: request.recipientProfileInfo.productRequest.selectedBloodProduct === bloodProductEnum.FFP, min_value :1} }"
                  v-model="request.recipientProfileInfo.productRequest.quantity"
                  v-if="request.recipientProfileInfo.productRequest.selectedBloodProduct === bloodProductEnum.FFP"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                ></el-input>
              </div>

              <div class="col-md-5 flex-center">
                <label v-if="request.recipientProfileInfo.productRequest.selectedBloodProduct === bloodProductEnum.FFP">{{ this.calculatedBloodProduct.FFP.quantityFromEquation }}</label>
              </div>

              <div class="col-md-5 flex-center">
                <label v-if="request.recipientProfileInfo.productRequest.selectedBloodProduct === bloodProductEnum.FFP">{{ this.calculatedBloodProduct.FFP.quantityFromAI }}</label>
              </div>
            </div>

            <div
              class="row"
              style="height: 35px; margin-top: 10px;"
            >
              <div class="col-md-9 flex-start">
                <el-radio
                  :disabled="request.isCaseClosed()"
                  size="large"
                  v-model="request.recipientProfileInfo.productRequest.selectedBloodProduct"
                  v-bind:label="bloodProductEnum.FP"
                >
                  <span style="font-size: 16px; font-weight: bold;">Fresh Plasma (FP)</span>
                </el-radio>
              </div>

              <div class="col-md-5 flex-center">
                <el-input
                  :disabled="request.isCaseClosed()"
                  v-validate="{ rules: { required: request.recipientProfileInfo.productRequest.selectedBloodProduct === bloodProductEnum.FP, min_value :1} }"
                  v-model="request.recipientProfileInfo.productRequest.quantity"
                  v-if="request.recipientProfileInfo.productRequest.selectedBloodProduct === bloodProductEnum.FP"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                ></el-input>
              </div>

              <div class="col-md-5 flex-center">
                <label v-if="request.recipientProfileInfo.productRequest.selectedBloodProduct === bloodProductEnum.FP">{{ this.calculatedBloodProduct.FP.quantityFromEquation }}</label>
              </div>

              <div class="col-md-5 flex-center">
                <label v-if="request.recipientProfileInfo.productRequest.selectedBloodProduct === bloodProductEnum.FP">{{ this.calculatedBloodProduct.FP.quantityFromAI }}</label>
              </div>
            </div>
          </el-card>

          <el-card
            style="margin-top: 15px;"
            v-if="request.recipientProfileInfo.productRequest.selectedBloodProduct != null"
          >
            <h4 slot="title">คำแนะนำสำหรับการเลือกใช้ผลิตภัณฑ์เลือด</h4>

            <div class="row">
              <label v-if="request.recipientProfileInfo.productRequest.selectedBloodProduct === bloodProductEnum.FWB">{{ this.calculatedBloodProduct.FWB.recommend }}</label>
              <label v-if="request.recipientProfileInfo.productRequest.selectedBloodProduct === bloodProductEnum.SWB">{{ this.calculatedBloodProduct.SWB.recommend }}</label>
              <label v-if="request.recipientProfileInfo.productRequest.selectedBloodProduct === bloodProductEnum.PRP">{{ this.calculatedBloodProduct.PRP.recommend }}</label>
              <label v-if="request.recipientProfileInfo.productRequest.selectedBloodProduct === bloodProductEnum.FFP">{{ this.calculatedBloodProduct.FFP.recommend }}</label>
              <label v-if="request.recipientProfileInfo.productRequest.selectedBloodProduct === bloodProductEnum.FP">{{ this.calculatedBloodProduct.FP.recommend }}</label>
              <label v-if="request.recipientProfileInfo.productRequest.selectedBloodProduct === bloodProductEnum.PRBCS">{{ this.calculatedBloodProduct.PRBCS.recommend }}</label>
            </div>
          </el-card>
        </el-card>
      </el-row>
    </el-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BloodProductEnum from "@/constants/bloodbank/blood-product-type-enum";
import targetType from "@/constants/bloodbank/blood-request-target-type.js";
import _ from "lodash";

export default {
  props: {
    request: Object,
    mode: {
      default: "view"
    }
  },
  data() {
    return {
      myProgress: 3,
      myText: " Product Request",
      myVetType: "ผู้ขอ",
      targetType: targetType,
      bloodProductEnum: BloodProductEnum,
      calculatedBloodProduct: {
        FWB: {
          requestQuantity: null,
          quantityFromEquation: "-",
          quantityFromAI: "-",
          recommend:
            "การชดเชยด้วยการให้ red cells ใช้สำหรับสัตว์ป่วยที่มีภาวะโลหิตจาง และจำเป็นต้องได้รับการชดเชยแก้ไขเพื่อป้องกันอันตรายที่อาจเกิดขึ้นต่ออวัยวะต่างๆ ในร่างกาย อันเป็นผลจากการเกิดภาวะ anemic hypoxia เนื่องจากการขาดเม็ดเลือดแดงซึ่งส่วนประกอบหลักในการขนส่งออกซิเจนไปเลี้ยงร่างกาย Whole blood เป็นผลิตภัณฑ์เลือดที่มีองค์ประกอบเพียงพอที่จะทำให้ภาวะ tissue reoxygenation และ blood volume reestablishment เกิดขึ้นได้จึงมีความเหมาะสมที่จะเลือกใช้สำหรับรักษาสัตว์ป่วยที่มีภาวะ hypovolemic anemia เช่น acute massive hemorrhage secondary to trauma เนื่องจาก Fresh Whole blood มี labile coagulation factors และเกล็ดเลือดที่ยังสามารถ ทำงานได้อยู่มากพอ ดังนั้น FWB จึงมีประสิทธิภาพเหนือกว่า SWB สำหรับการเลือกใช้ในกรณีที่สัตว์ป่วยมีภาวะ hemostatic dysfunction ร่วมด้วย"
        },
        SWB: {
          requestQuantity: null,
          quantityFromEquation: "-",
          quantityFromAI: "-",
          recommend:
            "การชดเชยด้วยการให้ red cells ใช้สำหรับสัตว์ป่วยที่มีภาวะโลหิตจาง และจำเป็นต้องได้รับการชดเชยแก้ไขเพื่อป้องกันอันตรายที่อาจเกิดขึ้นต่ออวัยวะต่างๆ ในร่างกาย อันเป็นผลจากการเกิดภาวะ anemic hypoxia เนื่องจากการขาดเม็ดเลือดแดงซึ่งส่วนประกอบหลักในการขนส่งออกซิเจนไปเลี้ยงร่างกาย Whole blood เป็นผลิตภัณฑ์เลือดที่มีองค์ประกอบเพียงพอที่จะทำให้ภาวะ tissue reoxygenation และ blood volume reestablishment เกิดขึ้นได้จึงมีความเหมาะสมที่จะเลือกใช้สำหรับรักษาสัตว์ป่วยที่มีภาวะ hypovolemic anemia เช่น acute massive hemorrhage secondary to trauma"
        },
        PRP: {
          requestQuantity: null,
          quantityFromEquation: "-",
          quantityFromAI: "-",
          recommend:
            "ภาวะ thrombocytopenia ในสุนัขและแมวเกิดจากสาเหตุหลักๆ คือ 1. decreased production 2. increased consumption or destruction 3. increased sequestration  โดยทั่วไปการให้เกล็ดเลือดนั้น แนะนำให้ใช้ในกรณีที่เกล็ดเลือดต่ำ จากภาวะ decreased production เท่านั้น เนื่องจากเกล้ดเลือดที่ให้เข้าไปในสัตว์ที่มีภาวะ increased sequestration จะถูกทำลายอย่างรวดเร็ว อย่างไรก็ดี หากพบว่าสัตว์ป่วยนั้นมีภาวะ lift-threatening hemorrhage อาจต้องพิจารณาให้เป็นกรณีพิเศษ ทั้งนี้ต้องให้การรักษาที่จำเพาะร่วมด้วย  วัตถุประสงค์ในการให้เกล็ดเลือดจะมีอยู่ 2 อย่างคือ 1. เพื่อรักษาสัตวืป่วยที่มีภาวะเลือดออกจากเกล็ดเลือดทำงานผิดปกติ 2. การให้เพื่อป้องกันภาวะเลือดออกจากเกล็ดเลือดต่ำ"
        },
        FFP: {
          requestQuantity: null,
          quantityFromEquation: "-",
          quantityFromAI: "-",
          recommend:
            "Fresh Frozen Plasma  เป็นผลิตภัณฑ์เลือดที่มีพลาสมาโปรตีนครบทุกชนิด ในปริมาณที่ใกล้เคียงกับพลาสมาปกติ สามารถใช้สำหรับทดแทน coagulation factors ในสัตว์ป่วยที่มีปัญหา coagulation factors deficiency เช่น liver failure, DIC, warfarin toxicity, vWFD, hemophilia A, dilutional coagulopathy after massive transfusion, acute pancreatitis, parvovirosis, hypoalbuminemia"
        },
        FP: {
          requestQuantity: null,
          quantityFromEquation: "-",
          quantityFromAI: "-",
          recommend:
            "Fresh Plasma เป็นผลิตภัณฑ์เลือดที่มีพลาสมาโปรตีนครบทุกชนิด ในปริมาณที่ใกล้เคียงกับพลาสมาปกติ สามารถใช้สำหรับทดแทน coagulation factors ในสัตว์ป่วยที่มีปัญหา coagulation factors deficiency เช่น liver failure, DIC, warfarin toxicity, vWFD, hemophilia A, dilutional coagulopathy after massive transfusion, acute pancreatitis, parvovirosis, hypoalbuminemia"
        },
        PRBCS: {
          requestQuantity: null,
          quantityFromEquation: "-",
          quantityFromAI: "-",
          recommend:
            "PRCs เป็น erythrocyte containing product ที่มีความเข้มข้นของเม็ดเลือดแดงสูงกว่า ใน Whole Blood แต่มีปริมาตรโดยรวมน้อยกว่า จึงทำให้ PRCs มีแต่เพียงคุณสมบัติในด้านของ tissue reoxygenation โดยไม่มีคุณสมบัติสำหรับสำหรับ blood volume reestablishment อีกทั้งยังไม่มีเกล็ดเลือดและปัจจัยการแข็งตัวของเลือดอยู่ ดังนั้น PRCs จึงเหมาะกับการใช้ในสัตว์ป่วยที่มีภาวะ normovolemic anemia ที่ไม่มีปัญหาของ hemostatic dysfunction ร่วมด้วย เช่น chronic blood loss ที่เป็นมามากกว่า 72 ชั่วโมง หรือ กรณี non-regenerative anemia หรือนำมาใช้ในการเพิ่มเม็ดเลือดให้กับสัตว์ป่วยที่มีภาวะ โรคหัวใจ หรือ โรคไตที่มีปัญหาเกี่ยวกับการสร้างปัสสาวะ ซึ่งมีความเสี่ยงที่จะเกิดภาวะ  hypervolemia"
        }
      }
    };
  },
  computed: {
    ...mapState({
      diagnosis: state => state.bloodbank.diagnosis
    })
  },
  created: function() {
    if (
      !this.request.recipientProfileInfo.isProductRequestSubmitted &&
      this.request.recipientProfileInfo.productRequest.selectedTargets
        .length === 0
    ) {
      this.request.recipientProfileInfo.productRequest.selectedTargets.push(
        this.targetType.PCV
      );
    } else {
      this.calculateQuantity(false);
    }
  },
  methods: {
    calculateQuantity: function(displayConfident = true) {
      if (
        this.request.recipientProfileInfo.productRequest.selectedTargets.indexOf(
          "PCV"
        ) < 0 ||
        this.request.recipientProfileInfo.productRequest.targetPcv == null ||
        this.request.recipientProfileInfo.productRequest.targetPcv === ""
      ) {
        return;
      }

      this.$store
        .dispatch("bloodbank/postQuantityPredict", {
          data: {
            Weight: Number(
              this.request.recipientProfileInfo.medicalRecord.weight
            ),
            PCV_patient: Number(this.request.recipientProfileInfo.labExam.HCT),
            PCV_target: Number(
              this.request.recipientProfileInfo.productRequest.targetPcv
            )
          }
        })
        .then(data => {
          if (data.errorMessage) {
            this.$Notify.error({
              title: "AI Calculation",
              message: `พบข้อผิดพลาด ไม่สามารถคำนวนได้`
            });
            return;
          }
          for (let product in this.calculatedBloodProduct) {
            if (product === "PRP" || product === "FFP" || product === "FP") {
              continue;
            }

            this.calculatedBloodProduct[product].quantityFromEquation = _.round(
              data.Formula
            );
            this.calculatedBloodProduct[product].quantityFromAI = _.round(
              data.ML_Vol
            );
          }

          if (displayConfident) {
            this.$Notify.info({
              title: "AI Calculation",
              message: `Confidential of volumn calculation is ${_.round(
                data.Confident,
                2
              ) * 100} %`
            });
          }
        });
    }
  }
};
</script>

<style scoped>
.header-container {
  width: calc(100% + 5px);
  height: 50px;
  border-radius: 6px;
  background-color: #5c9ba3;
  text-align: center;
}

.el-radio__label {
  font-size: 16px;
}
</style>
