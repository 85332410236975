<template>
  <div>
    <div class="header">
      <label class="label">
        <img src="@/assets/icons/icon-vaccine.png" class="icon">กิจกรรม
      </label>
    </div>
    <div class="content">
      <ul class="activity-list">
        <li v-for="activity in activities" :key="activity.id">
          <div class="thumbnail">
            <img
              :src="activity.attachment"
              v-if="activity.attachment && activity.attachment !== ''"
            >
          </div>
          <div class="info">
            <h3 class="name">{{ activity.name }}</h3>
            <template v-if="activity.products && activity.products.length > 0">
              <div class="product">{{ activity.products[0].trade_name || '-' }}</div>
            </template>
            <template v-else>
              <div class="product">-</div>
            </template>
            <template v-if="activity.lot_number">
              <div class="lot-no">Lot No. {{ activity.lot_number || '-' }}</div>
            </template>
          </div>
          <div class="price">{{ activity.price | currency }}฿</div>
        </li>
      </ul>
      <div class="total">
        <div class="label">Total</div>
        <div class="value">{{ total | currency }}฿</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["activities"],
  computed: {
    total() {
      return this.activities.reduce((total, item) => {
        if (item.price) {
          return total + parseFloat(item.price)
        }
        return total
      }, 0)
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-list {
  margin: 15px 0 0 0;
  padding: 0;
  list-style-type: none;

  > li {
    margin: 10px 0;
    display: flex;
  }
}

.thumbnail {
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  margin-right: 15px;
  text-align: center;
  width: 80px;
  height: 60px;

  > img {
    max-width: 80px; // ratio 4:3
    max-height: 60px;
    width: auto;
    height: auto;
  }
}
.info {
  display: flex;
  flex-direction: column;
  flex: 1;
  vertical-align: top;

  .name {
    margin: 0 0 3px 0;
    padding: 0;
    font-size: 14px;
    line-height: 1.2em;
  }

  .product {
    margin: 0;
    padding: 0;
    font-size: 13px;
    color: #666;
  }

  .lot-no {
    margin: 0;
    padding: 0;
    font-size: 13px;
    color: #666;
  }
}
.price {
  display: flex;
  font-size: 15px;
}
.total {
  display: flex;
  padding-top: 10px;
  border-top: 1px solid #ccc;

  .label {
    display: flex;
    flex: 3;
    font-weight: bold;
  }
  .value {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    line-height: 1em;
    font-size: 15px;
    font-weight: bold;
  }
}
</style>

