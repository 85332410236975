<template>
  <div :class="{ 'activity-item': true, selected: isSelected }" @click="$emit('click', activity)">
    <div class="thumbnail">
      <img :src="activity.attachment" v-if="activity.attachment && activity.attachment !== ''">
      <div class="checked">
        <i class="el-icon-check"/>
      </div>
    </div>
    <div class="info">
      <h3 class="name">{{ activity.name }}</h3>
      <template v-if="activity.products && activity.products.length > 0">
        <div class="product">{{ activity.products[0].trade_name || '-' }}</div>
      </template>
      <template v-else>
        <div class="product">-</div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activity: {
      types: Object,
      required: true
    },
    isSelected: {
      types: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-item {
  position: relative;
  cursor: pointer;
  border: 1px solid #ccc;
  width: 105px;
  overflow: hidden;

  .thumbnail {
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    width: 105px;
    height: 79px;

    > img {
      display: block;
      max-width: 120px; // 4:3
      max-height: 90px;
      width: auto;
      height: auto;
    }

    .checked {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: rgba(2, 176, 225, 0.6);
      z-index: 9;

      > i {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -25px;
        margin-left: -25px;
        font-size: 50px;
        font-weight: bold;
        color: #fff;
      }
    }
  }

  .info {
    padding: 10px;

    .name {
      padding: 0;
      margin: 0;
      font-size: 13px;
      font-weight: normal;
      line-height: 15px;
      height: 44px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }

    .product,
    .brand {
      margin-top: 5px;
      color: #666;
      font-size: 11px;
      font-weight: normal;
      line-height: 1em;
      display: -webkit-box;
      height: 20px;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  &.selected {
    border-color: $light-blue;
    box-shadow: 0 0 8px rgba(2, 176, 225, 0.5);

    .thumbnail {
      .checked {
        display: block;
      }
    }
  }

  .gender {
    position: absolute;
    right: 3px;
    top: 3px;

    .gender-icon {
      width: 15px;
      height: 15px;
    }
  }
}
</style>

