<template>
  <div class="form-block">
    <div class="header-block">
      <div class="title">
        {{ title }}
      </div>
      <router-link v-if="error" :to="{ name: 'register' }">สมัครสมาชิก</router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: 'Verifying...',
      error: false
    }
  },
  mounted() {
    this.validate()
  },
  methods: {
    async validate() {
      let token = this.$route.query.token
      let uidb64 = this.$route.query.uidb64
      if (!token || !uidb64) {
        this.title = 'Varification Error'
        this.error = true
      } else {
        try {
          await this.$store.dispatch('hospital/verify', {
            query: {
              uidb64: uidb64,
              token: token
            }
          })
          this.$router.push({ name: 'profileGeneral' });
        } catch (err) {
          this.title = 'Varification Error'
          this.error = true
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-block {
  @extend %block-container;
  @extend %form-style;
}
</style>
