<template>
  <div>
    <Subnavbar>
      <template slot="title">Veterinarian</template>
      <template slot="actions">
        <button
          class="btn btn-large btn-delete"
          v-if="isEdit"
          @click="confirmDeleteVisible = true"
        >Delete</button>
        <button
          class="btn btn-large btn-default"
          @click="$router.push({ name: 'veterinarians' })"
        >Cancel</button>
        <button
          class="btn btn-large btn-primary"
          @click="verifySaveDetail"
        >
        {{ isEdit? "Save" : "Create" }}
        </button>
      </template>
    </Subnavbar>

    <ContentBox class="content-mini" v-if="form">
      <div class="title">{{ isEdit ? "Edit" : "Create" }} Veterinarian Staff</div>
      <el-form ref="form" :model="form">
        <div class="subtitle">คำนำหน้า</div>
        <el-input class="input" v-model="form.title" placeholder="กรอกคำนำหน้า"></el-input>
        <div class="subtitle">ชื่อ</div>
        <el-input class="input" v-model="form.first_name" placeholder="กรอกชื่อ"></el-input>
        <div class="subtitle">นามสกุล</div>
        <el-input class="input" v-model="form.last_name" placeholder="กรอกนามสกุล"></el-input>
        <div class="subtitle">เลขที่ใบประกอบวิชาชีพ</div>
        <el-input class="input" 
          v-model="form.license_number" 
          placeholder="กรอกเลขที่ใบประกอบวิชาชีพ"
        ></el-input>
        <div class="subtitle">ที่อยู่</div>
        <el-input
            class="input textarea"
            v-model="form.address"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="กรอกที่อยู่"
            size="small"
        ></el-input>
        <div class="subtitle">เบอร์โทร</div>
        <el-input class="input" v-model="form.phone" placeholder="กรอกเบอร์โทรศัพท์"></el-input>
        <div class="subtitle">Email</div>
        <el-input class="input" v-model="form.email" autocomplete="new-password" placeholder="กรอกอีเมลล์"></el-input>
        <div class="subtitle">Pin <span style="font-size: 12px;">(สำหรับใช้เพื่อยืนยันตัวตนตอนบันทึกข้อมูล)</span></div>
        <el-input class="input" 
          :value="form.pin"
          @input="handlePinChange"
          placeholder="รหัสตัวเลข 4 หลัก" 
          type="password"
          autocomplete="new-password"
          maxlength="4"
          show-password
        ></el-input>
        <div class="required-mark">*ต้องเป็นรหัสที่ห้ามซำ้กันภายในโรงพยาบาล</div>
        <div class="subtitle">Status</div>
        <div>
            <el-radio 
            v-model="form.is_hidden" 
            :label="false"
            border 
            >
            Active
            </el-radio>
            <el-radio 
            v-model="form.is_hidden" 
            :label="true"
            border
            >
            Inactive
            </el-radio>
        </div>
      </el-form>
    </ContentBox>

    <ConfirmDialog
      :visible="showConfirmDialog"
      @cancel="showConfirmDialog = false"
      @confirm="confirmSaveDetail"
    >
      <template slot="header">
        <h1 class="title">ยืนยันการบันทึก?</h1>
      </template>
    </ConfirmDialog>

    <el-dialog title="Delete veterinarian" :visible="confirmDeleteVisible" width="500px" center append-to-body @close="confirmDeleteVisible = false">
        <div class="text-center">ยืนยันการลบหมอ</div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="confirmDeleteVisible = false">cancel</el-button>
            <el-button type="primary" @click="deleteVet">confirm</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Subnavbar from "@/components/navigation/Subnavbar";
import ContentBox from "@/components/container/ContentBox";
import ConfirmDialog from "@/components/dialog/ConfirmDialog"

export default {
  data() {
    return {
      form: {
        title: "",
        first_name: "",
        last_name: "",
        license_number: "",
        address: "",
        phone: "",
        email: "",
        pin: "",
        is_hidden: false
      },
      isEdit: false,
      showConfirmDialog: false,
      confirmDeleteVisible: false
    }
  },
  components: {
    Subnavbar,
    ContentBox,
    ConfirmDialog,
  },
  computed: {
    ...mapState({
      vet: state => state.hospital.vet
    }),
  },
  methods: {
    handlePinChange (value) {
      if (value.match(/^[0-9]+$/) || value === '') {
        this.form.pin = value
      }
    },
    validEmail: function (email) {
      // eslint-disable-next-line
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    verifySaveDetail() {
      if (this.form.title == "") {
        this.$notify({
          message: "กรุณากรอกคำนำหน้าชื่อ",
          type: "warning"
        })
        return;
      }
      if (this.form.first_name == "") {
        this.$notify({
          message: "กรุณากรอกชื่อ",
          type: "warning"
        })
        return;
      }
      if (this.form.last_name == "") {
        this.$notify({
          message: "กรุณากรอกนามสกุล",
          type: "warning"
        })
        return;
      }
      if (this.form.license_number == "") {
        this.$notify({
          message: "กรุณากรอกเลขที่ใบประกอบวิชาชีพ",
          type: "warning"
        })
        return;
      }
      // if (this.form.address == "") {
      //   this.$notify({
      //     message: "กรุณากรอกที่อยู่",
      //     type: "warning"
      //   })
      //   return;
      // }
      if (this.form.phone == "") {
        this.$notify({
          message: "กรุณากรอกเบอร์โทรศัพท์",
          type: "warning"
        })
        return;
      }
      if (this.form.email == "") {
        this.$notify({
          message: "กรุณากรอกอีเมลล์",
          type: "warning"
        })
        return;
      }
      if (!this.validEmail(this.form.email)) {
        this.$notify({
          message: "รูปแบบอีเมลล์ไม่ถูกต้อง",
          type: "warning"
        })
        return;
      }
      // if (this.form.pin == "" && !this.isEdit) {
      //   this.$notify({
      //     message: "กรุณากรอก PIN สำหรับใช้ใน Bloodbank",
      //     type: "warning"
      //   })
      //   return;
      // }

      this.showConfirmDialog = true;
    },
    confirmSaveDetail() {
      this.isEdit? this.updateVet() : this.createVet();
    },
    async updateVet() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)

        let data =  {
          title: this.form.title,
          first_name: this.form.first_name,
          last_name: this.form.last_name,
          license_number: this.form.license_number,
          address: this.form.address,
          phone: this.form.phone,
          email: this.form.email,
          is_hidden: this.form.is_hidden,
          pin: this.form.pin
        };

        await this.$store.dispatch("hospital/updateVet", {
          params: { id: this.$route.params.id },
          data: data,
        })
        this.$router.push({
          name: "veterinarians"
        })
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        await this.$store.dispatch("ui/showFetchErrorMsg")
      }
    },
    async createVet() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        await this.$store.dispatch("hospital/createVet", {
          data: {
            title: this.form.title,
            first_name: this.form.first_name,
            last_name: this.form.last_name,
            license_number: this.form.license_number,
            address: this.form.address,
            phone: this.form.phone,
            email: this.form.email,
            pin: this.form.pin,
            is_hidden: this.form.is_hidden,
          }
        })
        this.$router.push({
          name: "veterinarians"
        })
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        let message = ''
        if (error.pin) {
          message = error.pin
        }
        this.showConfirmDialog = false
        await this.$store.dispatch("ui/showFetchErrorMsg", message)
      }
    },
    async deleteVet() {
      this.confirmDeleteVisible = false;
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        await this.$store.dispatch("hospital/deleteVet", {
          params: { id: this.vet.id },
        });
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        this.$notify({
          type: "success",
          title: "ลบหมอเรียบร้อยแล้ว"
        })
        this.$router.go(-1);
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorMsg");
      }
    },
  },
  async created() {
    if (this.$route.params.id != undefined) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        await this.$store.dispatch("hospital/getAllVet", {
          params: { id: this.$route.params.id }
        });
        this.isEdit = true;
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg");
      }
    }
  },
  watch: {
    vet(newValue) {
      this.form.title = newValue.title;
      this.form.first_name = newValue.first_name;
      this.form.last_name = newValue.last_name;
      this.form.license_number = newValue.license_number;
      this.form.address = newValue.address;
      this.form.phone = newValue.phone;
      this.form.email = newValue.email;
      this.form.pin = newValue.pin;
      this.form.is_hidden = newValue.is_hidden;
    }
  }
};
</script>

<style lang="scss" scoped>
.content-mini {
  font-family: "Kanit", sans-serif;
  font-size: 14px;
  display: block;
  width: 290px;
  margin: 0 auto;
  margin-bottom: 15px;
  background: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);

  padding: 20px;
  padding-top: 0px;
}

.title {
  width: 100%;
  height: 22px;
  font-family: "Kanit", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
  margin-top: 9px;
  margin-bottom: 17px;
}

.subtitle {
  width: 100%;
  height: 18px;
  font-family: "Kanit", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
  margin-top: 0px;
  margin-bottom: 7px;
}

.input {
  display: block;
  width: 260px;
  height: 40px;
  border-radius: 5px;
  // border: solid 1px #e5e5e5;
  margin-bottom: 20px;

  &.textarea { 
    height: auto;
    clear: both;
    // resize: none;
  }
}

.button {
  width: 113px;
  height: 40px;
  border-radius: 5px;
  border: solid 1px #e5e5e5;
  display: inline-block;

  font-family: "Kanit", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: -0.3px;

  vertical-align: middle;

  img {
    width: 19px;
    height: 19px;
    object-fit: contain;
    margin-left: -8px;
    margin-right: 20px;

    display: inline;
  }

  &:last-child{
    float: right;
  }
}

.el-radio:last-child{
  float: right;
}

.required-mark {
  margin-top: -15px;
  margin-bottom: 10px;
  font-size: .9em;
}
</style>
