<template>
  <editor
    ref="toastuiEditor" 
    :initialEditType="mode"
    :height="height"
    :options="editorOptions"
    @change="onEditorChange"
  />
</template>

<script>
// deps for editor
import 'codemirror/lib/codemirror.css';
import '@toast-ui/editor/dist/toastui-editor.css';

import { Editor } from '@toast-ui/vue-editor';
import defaultOptions from './default-options'

export default {
  name: 'MarkdownEditor',
  components: {
    editor: Editor
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default() {
        return defaultOptions
      }
    },
    mode: {
      type: String,
      default: 'wysiwyg'
    },
    height: {
      type: String,
      required: false,
      default: '300px'
    },
  },
  data() {
    return {}
  },
  computed: {
    editorOptions() {
      return this.options
    }
  },
  watch: {
    value(newValue, preValue) {
      if (newValue !== preValue && newValue !== this.invoke('getValue')) {
        this.invoke('setValue', newValue)
      }
    },
  },
  mounted() {
    this.initEditor()
  },
  destroyed() {
    this.destroyEditor()
  },
  methods: {
    initEditor() {
      this.invoke('setMarkdown', this.value)
    },
    onEditorChange() {
      const markdownText = this.invoke('getMarkdown')
      this.$emit(
        'input',
        (typeof this.value === 'undefined' || this.value === null) &&
          markdownText === ''
          ? this.value
          : markdownText,
      )
    },
    invoke(tuiMethodName, arg) {
      return this.$refs.toastuiEditor.invoke(tuiMethodName, arg)
    },
    destroyEditor() {
      if (!this.$refs.toastuiEditor) return
      this.$refs.toastuiEditor.remove()
    }
  }
}
</script>
