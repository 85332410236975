import RewardSettingList from '@/pages/redeem/RedeemList.vue'
import RedeemSelectPet from '@/pages/redeem/RedeemSelectPet.vue'
import RedeemPet from '@/pages/redeem/RedeemPet.vue'

const routes = [
  {
    path: '/redeems',
    name: 'redeem-list',
    component: RewardSettingList,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['hospital_feature.can_use_point_redeem']
    }
  },
  {
    path: '/redeems/select-pet',
    name: 'redeem-select-pet',
    component: RedeemSelectPet,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['hospital_feature.can_use_point_redeem']
    }
  },
  {
    path: '/redeems/pet/:id',
    name: 'redeem-pet',
    component: RedeemPet,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['hospital_feature.can_use_point_redeem']
    }
  }
]

export default routes