import ReservationInfo from '../acquire-blood-bag-item-info/acquire-blood-bag-item-info'
import moment from 'moment'
import _ from 'lodash'

export default class {
  constructor (obj) {
    if (obj != null) {
      this.id = obj.id == null ? null : obj.id
      this.bloodBagList = obj.bagList == null ? [] : obj.bagList
      this.usedDate = obj.reservation_datetime == null ? '' : new Date(obj.reservation_datetime)
      this.approverId = obj.approver == null ? '' : obj.approver
      this.isImmediatelyUse = obj.is_immediately_use == null ? true : obj.is_immediately_use
      this.bloodRequestId = obj.blood_request == null ? null : obj.blood_request
    } else {
      this.id = null
      this.bloodBagList = []
      this.usedDate = ''
      this.approverId = ''
      this.isImmediatelyUse = true
      this.bloodRequestId = null
    }
  }

  importFromCrossmatch (items) {
    this.bloodBagList.length = 0
    items.forEach((elem) => {
      this.bloodBagList.push(new ReservationInfo(elem))
    })
  }

  addItem (item) {
    // If bloodbag is not already in the list, or already in but not selected (bug ?)
    if(!this.bloodBagList.find(x => x.crossmatchId === item.crossmatch && x.isSelected)) {
      this.bloodBagList.push(new ReservationInfo(item))  
    }
    
  }

  toRequestJson () {
    let reserveBloodBagArrayJson = []
    _.forEach(this.bloodBagList, o => {
      reserveBloodBagArrayJson.push(o.toRequestJson())
    })
    return {
      id: this.id,
      reservation_datetime: _.isDate(this.usedDate) ? moment(this.usedDate).format() : null, // "2018-06-05T02:20:08+07:00"
      approver: this.approverId,
      is_immediately_use: this.isImmediatelyUse,
      bloodBagList: reserveBloodBagArrayJson
    }
  }

  cloneFrom(obj) {
    this.id = obj.id
    this.bloodBagList = [...obj.bloodBagList.map(x => new ReservationInfo(x.toRequestJson()))]
    this.bloodBagList = [...this.bloodBagList.map((x,i) => x.cloneFrom(obj.bloodBagList[i]))]
    this.usedDate = obj.usedDate
    this.approverId = obj.approverId
    this.isImmediatelyUse = obj.isImmediatelyUse
    this.bloodRequestId = obj.bloodRequestId
  }
}
