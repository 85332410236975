<template>
  <div>
      <div class="medicalrecord-overview bg-white" v-if="overview">
        <el-row v-if="overview">
          <el-col :md="6">
            <div class="data-section" style="border-left: none;">
              <label>Total Today Case</label>
              <div class="values">
                <div class="top">{{ overview.today_cases | number }}</div>
                <div class="bottom">case</div>
              </div>
            </div>
          </el-col>
          <el-col :md="4">
            <div class="data-section">
              <label>Finish</label>
              <div class="values">
                <div class="top">{{ overview.finished | number }}</div>
                <div class="bottom">case</div>
              </div>
            </div>
          </el-col>
          <el-col :md="4">
            <div class="data-section">
              <label>In Action</label>
              <div class="values">
                <div class="top">{{ overview.in_progress | number }}</div>
                <div class="bottom">case</div>
              </div>
            </div>
          </el-col>
          <el-col :md="5">
            <div class="data-section">
              <label>Gain Revenue</label>
              <div class="values">
                <div class="top">{{ overview.total_revenue | currency }}</div>
                <div class="bottom">THB</div>
              </div>
            </div>
          </el-col>
          <!-- <el-col :md="5">
            <div class="data-section">
              <label>Lost Revenue</label>
              <div class="values">
                <div class="top">{{ overview.lost_revenue | currency }}</div>
                <div class="bottom">THB</div>
              </div>
            </div>
          </el-col> -->
        </el-row>
        
        <el-row class="form-section">
          <el-col :span="15">
            <el-input
                placeholder="ค้นหาจาก ID, HN, ชื่อสัตว์เลี้ยง, เบอร์โทร ,etc."
                v-model="searchMedicalText"
                class="search-input"
                size="large"
                clearable
                @keyup.enter.native="handleSearchClick"
                @clear="clearSearch" >
                <el-button type="primary" slot="append" icon="el-icon-search" @click="handleSearchClick"></el-button>
            </el-input>
          </el-col>
          <el-col :span="9" class="text-right">
            <button
              class="btn btn-large btn-medical"
              v-show="currentPlan.opd_section.can_create_opd"
              @click="$router.push({ name: 'medicals-select-pet' })" >
              <i class="fa fa-plus-circle"></i>OPD Record
            </button>
          </el-col>
        </el-row>
      </div>

    <ContentBox class="result-container ">
      <template v-if="todayMedicals && todayMedicals.results">
        <MedicalTable
          :medicals="todayMedicals"
          :total="todayMedicals.count"
          :current-page="currentMedicalPage"
          :resultTitle="'Today List ' + todayMedicals.count"
          @current-change="pageChange"
        />
      </template>
    </ContentBox>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { startToday, endToday } from '@/utils/datetime'
import ContentBox from "@/components/container/ContentBox"
import MedicalTable from "@/components/medical/MedicalTable"

export default {
    components: {
        ContentBox,
        MedicalTable
    },
    data() {
        return {
            currentMedicalPage: 1,
            searchMedicalText: '',
        }
    },
    computed: {
        ...mapState({
            todayMedicals: state => state.medical.all,
            overview: state => state.medical.overview,
            currentPlan: state => state.plan.currentPlan,
        })
    },
    methods: {
        pageChange (page) {
            this.currentMedicalPage = page
            this.search(page)
        },
        handleSearchClick() {
            this.search(1)
        },
        clearSearch() {
            this.searchMedicalText = ""
            this.currentMedicalPage = 1
            this.search(1)
        },
        async search(currentMedicalPage) {
            try {
                await this.$store.dispatch("ui/setIsFullScreenLoading", true)
                await this.$store.dispatch("medical/getOverview", { query: { 
                    start: startToday(),
                    end: endToday()
                }})

                let query = { page: currentMedicalPage }
                query.start = startToday()
                query.end = endToday()
                if (this.searchMedicalText !== "") {
                    query.search = this.searchMedicalText
                }
                await this.$store.dispatch("medical/listMedicals", { query })
                await this.$store.dispatch("ui/setIsFullScreenLoading", false)
            } catch (error) {
                await this.$store.dispatch("ui/showFetchErrorWithReloadMsg")
            }
        },
    },
    async created() {
        this.search(1)
    }
}
</script>

<style lang="scss" scoped>
.bg-white {
  background-color: $white;
}

.medicalrecord-overview {
  position: relative;
  padding: 16px 8px;
  margin-bottom: 18px;

  .title {
    margin: 0;
    padding: 0;
    font-size: 16px;
    margin-bottom: 5px;
  }

  .data-section {
    border-left: 1px solid #ccc;
    padding: 0 10px 10px;

    label {
      display: inline-block;
      width: 40%;
      font-size: 16px;
      line-height: 1.2em;
      vertical-align: top;
    }

    .values {
      display: inline-block;
      width: 60%;
      text-align: right;

      .top {
        font-size: 20px;
        line-height: 1em;
        font-weight: bold;
      }
    }
  }

  .form-section {
    margin: 8px;

    .search-input {
      margin-top: 8px;
      
      input[type=text] {
        background-color: #f7f7f7;
      }

      .el-button--primary {
        color: $white;
        background-color: $primary;
        border-color: $primary;
        background-color: $primary;
      }
    }
  }
}

.result-container {
  margin-bottom: 64px;
}
</style>


