<script>
import Tracker from "@/components/common/Tracker";

export default {
  props: {
    revenue: {
      type: Number,
      default: 0,
    },

    deposit: {
      type: Number,
      default: 0,
    },

    overdue: {
      type: Number,
      default: 0,
    },
  },

  render() {
    return (
      <el-card bodyStyle={{ padding: "0.5em" }}>
        <el-row type="flex" gutter={16} style={{ flexWrap: "wrap" }}>
          <el-col sm={8} class={this.$style.col}>
            <Tracker value={this.revenue} unit="THB" format="0,0.00">
              <div>รายรับรวม</div>
              <div style={{ fontSize: "0.875em" }}>Total Revenue</div>
            </Tracker>
          </el-col>
          <el-col sm={8} class={this.$style.col}>
            <Tracker value={this.deposit} unit="THB" format="0,0.00">
              <div>มัดจำ</div>
              <div style={{ fontSize: "0.875em" }}>Deposit</div>
            </Tracker>
          </el-col>
          <el-col sm={8} class={this.$style.col}>
            <Tracker
              value={this.overdue}
              unit="THB"
              format="0,0.00"
              style={{ color: "#d0021b" }}
            >
              <div>ค้างชำระ</div>
              <div style={{ fontSize: "0.875em" }}>Overdue</div>
            </Tracker>
          </el-col>
        </el-row>
      </el-card>
    );
  },
};
</script>

<style lang="scss" module>
.col {
  position: relative;

  &:not(:first-child) {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 1px;
      background-color: #979797;
    }
  }
}
</style>
