<template>
  <div>
    <Subnavbar>
      <template slot="title">Reward Setting</template>
      <template slot="actions">
        <button class="btn btn-large btn-primary" @click="showAddRewardDialog=true">
          <i class="fa fa-plus-circle"></i>Add Reward
        </button>
      </template>
    </Subnavbar>
    <ContentBox>
      <template v-if="rewards && rewards.results">
        <RewardTable
          :rewards="rewards.results"
          :total="rewards.count"
          :current-page="currentPage"
          @current-change="pageChange"
          @selectToEdit="selectToEdit"
        />
      </template>
    </ContentBox>
    <AddRewardDialog
      :visible="showAddRewardDialog"
      @save="addReward"
      @cancel="showAddRewardDialog = false"
    />
    <EditRewardDialog
      :visible="showEditRewardDialog"
      :editData="editRewardData"
      @save="editReward"
      @delete="deleteReward"
      @cancel="showEditRewardDialog = false"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ContentBox from "@/components/container/ContentBox";
import Subnavbar from "@/components/navigation/Subnavbar";
import RewardTable from "@/components/reward/RewardTable";
import AddRewardDialog from "@/components/reward/AddRewardDialog";
import EditRewardDialog from "@/components/reward/EditRewardDialog";

export default {
  components: {
    ContentBox,
    Subnavbar,
    RewardTable,
    AddRewardDialog,
    EditRewardDialog
  },
  data() {
    return {
      currentPage: 1,
      showAddRewardDialog: false,
      showEditRewardDialog: false,
      editRewardData: null
    };
  },
  computed: {
    ...mapState({
      rewards: state => state.reward.all
    })
  },
  methods: {
    selectToEdit(data) {
      this.editRewardData = data;
      this.showEditRewardDialog = true;
    },
    async addReward(data) {
      this.showAddRewardDialog = false;
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        await this.$store.dispatch("reward/createReward", {
          data
        });
        this.fetchData(this.getQueryString());
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg");
      }
    },
    async editReward(data) {
      this.showEditRewardDialog = false;
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        await this.$store.dispatch("reward/updateReward", {
          params: { id: data.id },
          data,
        });
        this.fetchData(this.getQueryString());
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg");
      }
    },
    async deleteReward(reward) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        await this.$store.dispatch("reward/deleteReward", {
          params: { id: reward.id },
        });
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        this.$notify({
          type: "success",
          title: "ลบ reward เรียบร้อยแล้ว"
        })
        this.showEditRewardDialog = false;
        this.$router.go();
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorMsg");
      }
    },
    getQueryString() {
      let query = {};

      if (this.$route.query.page) {
        query["page"] = parseInt(this.$route.query.page);
        this.currentPage = parseInt(this.$route.query.page);
      } else {
        this.currentPage = 1;
      }

      if (this.$route.query.search) {
        this.searchForm.search = this.$route.query.search
        query["search"] = this.$route.query.search
      } 

      return query;
    },
    pageChange(page) {
      let query = this.getQueryString();
      query = { ...query, page };
      this.currentPage = page;
      this.fetchData(query);
    },
    async fetchData(query) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        await this.$store.dispatch("reward/listRewards", {
          query,
        });
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg");
      }
    }
  },
  async created() {
    this.fetchData(this.getQueryString());
  }
};
</script>