<template>
  <div>
    <Subnavbar>
      <template slot="breadcrumb">
        <ul>
          <li>New {{forPage}}</li>
        </ul>
      </template>
      <template slot="title">New {{forPage}}: Select pet</template>
      <template slot="actions">
        <button
          class="btn btn-large btn-new-pet"
          v-show="currentPlan.hospital_feature.can_create_owner_and_pet"
          @click="$router.push({ name: 'hospitalPetsSearch' })"
        >
          <i class="fa fa-plus-circle"></i>New Pet
        </button>
      </template>
    </Subnavbar>

    <ContentBox>
      <div class="search-section">
        <h2 class="title" v-if="hospital">ค้นหาเจ้าของ หรือ สัตว์เลี้ยงจากใน {{ hospital.name }}</h2>
        <div class="search-input-wrapper">
          <el-input
            placeholder="ค้นหาด้วยชื่อสัตว์ เบอร์โทร, ชื่อเจ้าของ, อีเมล์, HN, Microchips, Pet ID"
            v-model="searchText"
            class="search-input"
            clearable
            @keyup.enter.native="search"
            @clear="clearSearch"
          >
            <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
          </el-input>
        </div>
      </div>
      <div class="results-section">
        <div v-if="pets && pets.count">{{ pets.count }} RESULT</div>
        <template v-if="pets && pets.results">
          <BloodBankPetTable
            :forPage="forPage"
            :pets="pets.results"
            :total="pets.count"
            :current-page="currentPage"
            @current-change="pageChange"
          />
        </template>
      </div>
    </ContentBox>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ContentBox from "@/components/container/ContentBox";
import Subnavbar from "@/components/navigation/Subnavbar";
import BloodBankPetTable from "@/components/bloodbank/BloodBankPetTable";

export default {
  props: {
    forPage: {
      type: String,
      default: "donation"
    }
  },
  components: {
    ContentBox,
    Subnavbar,
    BloodBankPetTable
  },
  data() {
    return {
      searchText: "",
      currentPage: 1
    };
  },
  computed: {
    ...mapState({
      pets: state => state.pet.all,
      hospital: state => state.hospital.profile,
      currentPlan: state => state.plan.currentPlan
    })
  },
  methods: {
    search() {
      const query = { search: this.searchText };
      this.currentPage = 1;
      this.fetchPetProfiles(query);
      this.$router.replace({ query });
    },
    clearSearch() {
      const query = {};
      this.searchText = "";
      this.currentPage = 1;
      this.fetchPetProfiles(query);
      this.$router.replace({ query });
    },
    async fetchPetProfiles(query) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        await this.$store.dispatch("pet/listPetProfiles", { query });
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorMsg");
      }
    },
    pageChange(page) {
      const query = { ...this.$route.query, page: page };
      this.currentPage = page;
      this.$router.replace({ query });
      this.fetchPetProfiles(query);
    },
    getQueryString() {
      let query = {};

      if (this.$route.query.search) {
        this.searchText = this.$route.query.search;
        query["search"] = this.$route.query.search;
      } else {
        this.searchText = "";
      }

      if (this.$route.query.page) {
        query["page"] = parseInt(this.$route.query.page);
        this.currentPage = parseInt(this.$route.query.page);
      } else {
        this.currentPage = 1;
      }

      return query;
    }
  },
  async created() {
    try {
      await this.$store.dispatch("ui/setIsFullScreenLoading", true);
      await this.$store.dispatch("pet/listPetProfiles", {
        query: this.getQueryString()
      });
      await this.$store.dispatch("ui/setIsFullScreenLoading", false);
    } catch (error) {
      await this.$store.dispatch("ui/showFetchErrorWithReloadMsg");
    }
  }
};
</script>

<style lang="scss" scoped>
.box {
  margin-bottom: 15px;
}

.search-section {
  .title {
    margin: 5px 0;
    padding: 0;
    font-size: 18px;
    text-align: center;
  }

  .search-input-wrapper {
    margin: 0 auto;
    width: 60%;
  }
}

.results-section {
  margin-top: 10px;
}
</style>

