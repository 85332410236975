import { render, staticRenderFns } from "./PetHealthBookGroupItem.vue?vue&type=template&id=5fdec3fc&scoped=true&"
import script from "./PetHealthBookGroupItem.vue?vue&type=script&lang=js&"
export * from "./PetHealthBookGroupItem.vue?vue&type=script&lang=js&"
import style0 from "./PetHealthBookGroupItem.vue?vue&type=style&index=0&id=5fdec3fc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fdec3fc",
  null
  
)

export default component.exports