import * as vuexHelper from "../helpers/vuexHelper";
import { coreAPI } from "../../services/apis";
import * as endpoints from "../../services/endpoints";

const config = {
  default: {
    api: coreAPI
  },
  state: {
    list: {
      total: 0,
      limit: 10,
      results: []
    },
    detail: null,
    audiences: null
  },
  asyncActions: [
    {
      name: "listBroadcast",
      method: "get",
      url: `${endpoints.broadcast}/`,
      onSuccess: (result, payload, { commit }) => {
        if (result) {
          commit('SET_LIST', {
            total: result.count || 0,
            results: result.results || []
          });
        }
      }
    },
    {
      name: "getBroadcast",
      method: "get",
      url: ({ id }) => `${endpoints.broadcast}/${id}/`,
      setState: "detail",
      before(payload, { commit }) {
        commit('SET_DETAIL', null)
      }
    },
    {
      name: "deleteBroadcast",
      method: "delete",
      url: ({ id }) => `${endpoints.broadcast}/${id}/`,
      onSuccess: (result, payload, { commit }) => {
        commit('SET_DETAIL', null)
      }
    },
    {
      name: "sendBroadcast",
      method: "post",
      url: `${endpoints.broadcast}/`,
    },
    {
      name: "getAudiences",
      method: "get",
      url: `${endpoints.broadcast}/utils/calculation/`,
      onSuccess: (result, payload, { commit }) => {
        if (result) {
          commit('SET_AUDIENCES', result);
        }
      }
    },
    {
      name: "getPublicBroadcast",
      method: "get",
      url: ({ id }) => `${endpoints.broadcast}/public/${id}/`,
    },
    {
      name: "getPublicSystem",
      method: "get",
      url: ({ id }) => `${endpoints.broadcast}/public/system/${id}/`,
    },
    {
      name: "getPublicSupplier",
      method: "get",
      url: ({ id }) => `${endpoints.broadcast}/public/supplier/${id}/`,
    },
    {
      name: "getPublicSupplier",
      method: "get",
      url: ({ id }) => `${endpoints.broadcast}/public/supplier/${id}/`,
    },
    {
      name: "getInboxMessages",
      method: "get",
      url: () => `${endpoints.broadcast}/me/inbox/`,
    },
    {
      name: "getInboxCount",
      method: "get",
      url: () => `${endpoints.broadcast}/me/inbox/count/`,
    },
  ]
};

export default vuexHelper.createStoreModule(config);
