<template>
  <div class="pet-general-info">
    <Subnavbar back="petOwnerHome" :mini="true">
      <template slot="title">PET GENERAL INFO</template>
    </Subnavbar>
    <PetGeneralInfoForm ref="form" :form="form" :standalone="true">
      <template slot="actions">
        <el-form-item class="text-center">
          <el-button
          :loading="submitButtonLoading"
          type="button"
          class="btn btn-primary form-submit-btn"
          @click="onSubmit">next ></el-button>
        </el-form-item>
      </template>
    </PetGeneralInfoForm>
  </div>
</template>

<script>
import { mapState } from "vuex"
import Subnavbar from '@/components/navigation/Subnavbar'
import PetGeneralInfoForm from '@/components/pet/PetGeneralInfoForm'

export default {
  components: {
    Subnavbar,
    PetGeneralInfoForm,
  },
  data() {
    return {
      form: {
        image: null,
        image_url: null,
        name: null,
        type: null,
        type_key: null,
        type_select: null,
        breed: null,
        gender: null,
        birthday: null,
        age_year: null,
        age_month: null,
      },
      submitButtonLoading: false,
    }
  },
  computed: {
    ...mapState({
      createForm: state => state.owner.createForm,
    })
  },
  async created() {
    await this.$store.dispatch('ui/setIsFullScreenLoading', true)
    this.form = {
      ...this.form,
      ...this.$store.state.owner.createForm
    };
    if (this.$route.params.id != null) {
      await this.$store.dispatch('owner/getPet', {
        params: { id: this.$route.params.id },
      }); 
    // }
    // if (this.$store.state.owner.pet != null){console.log(this.$store.state.owner.pet);
      await this.$refs.form.setType(this.$store.state.owner.pet.type.key);
      // await this.setType(this.$store.state.owner.pet.type.key);
      this.form = {
        ...this.form,
        image: null,
        image_url: this.$store.state.owner.pet.image.large,
        name: this.$store.state.owner.pet.name,
        // breed: this.$store.state.owner.pet.breed.id,
        type: this.$store.state.owner.pet.type.id,
        gender: this.$store.state.owner.pet.gender,
        birthday: this.$store.state.owner.pet.birthday,
        age_year: null,
        age_month: null,
        // type: this.$store.state.owner.pet.type.id,
        // type_key: this.$store.state.owner.pet.type.key,
        // type_select: this.$store.state.owner.pet.type.id,
        // breed: this.$store.state.owner.pet.breed.id,
      };
      if (this.$store.state.owner.pet.breed)
      this.form.breed = this.$store.state.owner.pet.breed.id;
      // await this.onSetBirthday();
      // this.$refs.form.form = this.form;
      // this.$refs.form.onSetBirthday(this.form.birthday);
    }
    // console.log(this.form);
    await this.$store.dispatch('pet/listTypes');
    await this.$store.dispatch('ui/setIsFullScreenLoading', false)
  },
  methods: {
    validate() {
      let message = "";
      switch(true) {
        case this.form.name == "": message = "กรุณากรอกชื่อสัตว์เลี้ยง"; break;
        case this.form.type == null: message = "กรุณาระบุชนิดสัตว์เลี้ยง"; break;
        case this.form.breed == null: message = "กรุณาระบุพันธุ์สัตว์เลี้ยง"; break;
        case this.form.gender == null: message = "กรุณาระบุเพศสัตว์เลี้ยง"; break;
        // case this.form.birthday == null: message = "กรุณาระบุวันเกิดสัตว์เลี้ยง"; break;
        // case this.form.age_year == null || this.form.age_month == null: message = "กรุณาระบุอายุสัตว์เลี้ยง"; break;
        case this.form.age_year == null: this.form.age_year = 0; break;
        case this.form.age_month == null: this.form.age_month = 0; break;
      }

      if (message != "") {
        this.$notify({
          message: message,
          type: "warning"
        })
        return false;
      }
      return true;
    },
    async onSubmit() {
      if (this.validate()) {
        this.$store.commit('owner/SET_CREATE_FORM', this.form);
        if (this.$route.params.id == null)
          this.$router.push({ name: 'petHealthDetail' });
        else {
          await this.$store.dispatch('ui/setIsFullScreenLoading', true)
          var form = new FormData();
          if (this.form.image != null)
          form.append('image', this.form.image)
          form.append('breed', this.form.breed);
          form.append('type', this.form.type);
          form.append('name', this.form.name);
          form.append('gender', this.form.gender);
          if (this.form.birthday != null)
          form.append('birthday', this.form.birthday);
          await this.$store.dispatch('owner/updatePet', {
            params: { id: this.$route.params.id },
            formData: form
          });
          await this.$store.dispatch('ui/setIsFullScreenLoading', false)
          this.$router.push({ 
            name: 'petHealthDetailEdit', 
            params: { id: this.$route.params.id },
          });
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pet-general-info {
  margin-top: 0px;
  padding: 0px 10px 40px 10px;
}
.form-block {
  @extend %block-container;
  @extend %form-style;

  .form-submit-btn {
    width: 160px;
  }
  .row {
    width: 100%;
  }
  .age-select {
    display: inline-block;
    width: 48%;

    &:first-child {
      float: left;
    }

    &:last-child {
      float: right;
    }
  }
  .gender-select, .type-select {
    display: block;
    // text-align: center;cc
    .el-radio {
      padding: 12px 25px 0 20px;
      width: 140px;
      
    }
    .el-button, .el-input {
      // padding: 12px 25px 0 20px;
      width: 48%;
      // box-sizing: border-box;

      i {
        float: left;
      }

      &:first-child {
        float: left;
        clear: left;
      }

      &:last-child {
        float: right;
        clear: right;
      }
    }

    &:last-child {
      margin-top: 8px;
    }
  }
  .upload-image {
    text-align: center;
    .image-container {
      position: relative;
      height: 120px;
      width: 120px;
      border-radius: 60px;
      background-color: #d8d8d8;
      display: block;
      margin: 8px auto;             
      img {
        display: block;
        width: 70px;
        height: 70px;
        padding: 25px;
        object-fit: contain;
        &.full-logo {
          width: 120px;
          height: 120px;
          padding: 0px;
          object-fit: cover;
        }
      }
      input {
        height: 120px;
        width: 120px;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
      }
    }
  }
}
</style>
