<template>
  <div class="form-block">
    <div class="header-block">
      <div class="title">
        Create Hospital Profile
      </div>
      <div class="lead">
        Social Network<br/>
        (4/4)
      </div>
    </div>

    <el-form ref="form" :model="form">

      <el-form-item>
        <span class="form-label">8. Line ID</span>
        <el-input
          type="text"
          name="lineId"
          placeholder="เช่น @hospital"
          v-model="form.lineId"></el-input>
        <span class="error">{{ errors.first('lineId') }}</span>
      </el-form-item>

      <el-form-item>
        <span class="form-label">9. Facebook</span>
        <el-input
          type="text"
          name="facebookId"
          placeholder="เช่น @hospital"
          v-model="form.facebookId"></el-input>
        <span class="error">{{ errors.first('facebookId') }}</span>
      </el-form-item>

      <el-form-item>
        <span class="form-label">10. Instagram</span>
        <el-input
          type="text"
          name="instagramId"
          placeholder="เช่น @hospital"
          v-model="form.instagramId"></el-input>
        <span class="error">{{ errors.first('instagramId') }}</span>
      </el-form-item>

      <el-form-item class="text-center">
        <router-link
          :to="{ name: 'profileAddress' }"
          class="btn btn-plain form-submit-btn">
          &lt; Back
        </router-link>
        <el-button
          :loading="submitButtonLoading"
          type="button"
          class="btn btn-primary form-submit-btn"
          @click="onSubmit">Save</el-button>
        <span v-for="(error, key) of errMessage"
          v-bind:key="key"
          class="error">{{ key }}: {{ error }}</span>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      submitButtonLoading: false,
      form: {},
      errMessage: [],
    }
  },
  async mounted() {
    await this.$store.dispatch('ui/setIsFullScreenLoading', true)
    await this.$store.dispatch('hospital/getProfile')
    this.form = {
      lineId: this.$store.state.hospital.profile.line_id,
      facebookId: this.$store.state.hospital.profile.facebook_id,
      instagramId: this.$store.state.hospital.profile.instragram_id,
    }
    await this.$store.dispatch('ui/setIsFullScreenLoading', false)
  },
  methods: {
    async onSubmit() {
      this.submitButtonLoading = true
      try {
        await this.$store.dispatch('hospital/updateProfile', {
          data: {
            line_id: this.form.lineId,
            facebook_id: this.form.facebookId,
            instragram_id: this.form.instagramId,
          }
        })
        this.$message('Hospital Profile Saved')
        this.$router.push({ name: 'hospitalHome' })
      } catch (err) {
        this.errMessage = err
      }
      this.submitButtonLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.form-block {
  @extend %block-container;
  @extend %form-style;
  .form-submit-btn {
    &.btn-primary {
      width: 142px;
    }
  }
}
</style>
