<template>
  <div class="appointment-detail-pdf" id="appointment-detail-pdf">
    <img crossOrigin="Anonymous" id="hospital-logo" style="position: absolute; top: 12px; left: 20px; object-fit: contain;" width="50px" height="50px"/>
    <div class="title-content-upper" style="position: absolute; top: 17px; left: 86px;">บัตรนัดหมายสำหรับสัตว์เลี้ยง</div>
    <div class="title-content-lower" style="position: absolute; top: 41px; left: 86px;">{{ hospital.name }}</div>
    <div class="qr-code" style="position: absolute; top: 19px; left: 408px;">
      <div>* แสกน QR Code</div>
      <div>เพื่อกดยืนยัน / เลื่อนนัด</div>
      <div>หรือดูรายละเอียด</div>
    </div>
    <qrcode style="position: absolute; top: 7px; left: 510px; object-fit: contain;" :value="qrcode" :options="{ width: 80, height: 80 }"></qrcode>

    <div style="position: absolute; top: 69px; left: 22px;" class="upper-line"/>
    <div class="qr-code-lower" style="position: absolute; top: 90px; left: 500px;">{{ appt && appt.id }}</div>
    <div class="topic-one" style="position: absolute; top: 77px; left: 21px;">วันที่นัด</div>
    <div class="value-one" style="position: absolute; top: 77px; left: 107px;">{{ appt && appt.date_time | toThaiDate }} 
        <template v-if="appt && !appt.is_no_time">{{appt.date_time | toHHMM}} น.</template>
        <template v-else>(ไม่ระบุเวลา)</template>
    </div>
    <div class="topic-two" style="position: absolute; top: 96px; left: 21px;">ชื่อสัตว์</div>
    <div class="value-two" style="position: absolute; top: 96px; left: 107px;">{{ appt && appt.pet.name }} <span style="font-weight: normal;">Type</span> {{ appt && appt.pet.type.name }} <span style="font-weight: normal;">อายุ</span> {{ appt && appt.pet.birthday | petAge }} <span style="font-weight: normal;">เพศ</span> {{ appt && appt.pet.gender | petGender }} <span style="font-weight: normal;" v-if="appt && appt.pet.pet_hospital.hn">HN.</span>{{ appt.pet.pet_hospital.hn }}</div>
    <div class="topic-three" style="position: absolute; top: 114px; left: 21px;">เจ้าของ</div>
    <div class="value-three-a" style="position: absolute; top: 114px; left: 107px;">{{ owner | fullname }}</div>
    <div class="value-three-b" style="position: absolute; top: 114px; left: 250px;">Tel. {{ owner && owner.phone | hyphen}}</div>
    <div class="topic-four" style="position: absolute; top: 130px; left: 21px;">สัตวแพทย์</div>
    <div class="value-four" style="position: absolute; top: 130px; left: 107px;">{{ appt && appt.vet | fullname }}</div>
    <div class="topic-five" style="position: absolute; top: 152px; left: 21px;">กิจกรรม</div>
    <div class="value-five" style="position: absolute; top: 152px; left: 107px;">
      <div v-if="appt && appt.activities">
        <div v-for="(activity, index) in appt && appt.activities" :key="activity && activity.id">
          {{ index + 1 }}. {{ activity.name }}
        </div>
      </div>
      <div v-else>-</div>
    </div>
    <div class="topic-six" style="position: absolute; top: 285px; left: 21px;">บันทึกเพิ่มเติม</div>
    <div class="value-six" style="position: absolute; top: 285px; left: 107px;">{{ appt && appt.note | hyphen }}</div>
    <div class="topic-seven" style="position: absolute; top: 310px; left: 21px;">หมายเหตุ</div>
    <div class="value-seven" style="position: absolute; top: 314px; left: 107px;">
      <div v-if="remarks.length === 0">-</div>
      <div v-else>
        <div v-for="(remark, index) in remarks" :key="index">
          {{ remark.note }}
        </div>
      </div>
    </div>
    <div style="position: absolute; top: 370px; left: 22px;" class="lower-line"/>
    <div class="footer-one" style="position: absolute; top: 374px; left: 23px;">
      <div>วันที่ออกบัตรนัด</div>
      <div>{{ appt && appt.created | toThaiDate }} {{ appt && appt.created | toHHMM }}</div>
    </div>
    <div class="footer-two" style="position: absolute; top: 374px; left: 110px;">ที่อยู่: {{ hospital.fullAddress | hyphen }}</div>
    <div class="footer-three" style="position: absolute; top: 387px; left: 110px;">เวลาทำการ {{ hospital.open_time | hyphen }}</div>
    <div class="footer-five" style="position: absolute; top: 401px; left: 110px;">tel: <span style="font-weight: 300;">{{ hospital.phone | hyphen }}</span></div>
    <div v-if="hospital.line_id" class="footer-six" style="position: absolute; top: 401px; left: 190px;">@Line: <span style="font-weight: 300;">{{ hospital.line_id | hyphen }}</span></div>
    <div class="footer-seven" style="position: absolute; top: 378px; left: 520px;">Power by : </div>
    <img src="@/assets/brand/v-remind-logo-text.png" class="footer-eight" style="position: absolute; top: 395px; left: 508px; object-fit: contain;" width="63px" height="15px"/>
    <div style="position: absolute; top: 420px; left: 0;" class="footer-line"/>
<!--
    <div class="title-content-lower">โรงพยาบาลสัตว์</div>
    <div class="header-upper">
      <img class="hos-pic"/>
      <div class="title-upper">
        <div class="title-content-upper">บัตรนัดหมายสำหรับสัตว์เลี้ยง</div>
        <div class="title-content-lower">โรงพยาบาลสัตว์</div>
      </div>
      <div class="title">
        <div class="qr-code">
          <label>* แสกน QR Code</label>
          <label>เพื่อกดยืนยัน / เพื่อนัด</label>
          <label>เพื่อดูรายละเอียด</label>
        </div>
        <img width="100px" height="100px"/>
      </div>
    </div>
    <hr class="line"/>
    <div>
      <p style="margin-right: 10px;">วันที่นัด
        <span>{{ appt.date_time | toThaiDate }} {{ appt.date_time | toHHMM }} น.</span>
      </p>
      <p style="margin-right: 10px;">ชื่อสัตว์
        <span>{{ appt.pet.name }}</span>
        <span style="margin-left: 10px; margin-right: 10px;">อายุ</span>
        <span>{{ appt.pet.birthday | petAge }}</span>
        <span style="margin-left: 10px; margin-right: 10px;">เพศ</span>
        <span>{{ appt.pet.gender | petGender }}</span>
      </p>
      <p style="margin-right: 10px;">เจ้าของ
        <span>{{ owner | fullname }}</span>
      </p>
      <p style="margin-right: 10px;">สัตวแพทย์
        <span>{{ appt.vet | fullname }}</span>
      </p>
      <div class="title">
        <p style="margin-right: 20px;">กิจกรรม</p>
        <div>
          <p style="margin: 0;" v-for="(activity, index) in appt.activities" :key="activity.id">
            {{ index + 1 }}. {{ activity.name }}
          </p>
        </div>
      </div>
      <p style="margin-right: 10px;">บันทึกเพิ่มเติม
        <span>{{ appt.note | hyphen }}</span>
      </p>
      <div class="title">
        <p>หมายเหตุ</p>
        <ul>
          <li>การทำวัคซีนเป็นประจำ จะช่วยป้องกันโรคติดต่อ หรือ โรคร้ายแรงในสุนัขได้</li>
          <li>สัตว์เลี้ยงที่ทำวัคซีน จะต้องมีสุขภาพปกติ</li>
          <li>ภายหลังจากการทำวัคซีนแล้ว สัตว์จะมีอาการซึมเล็กน้อย และจะเป็นปกติภายในระยะเวลา 2 วัน</li>
        </ul>
      </div>
      <hr class="line"/>
      <div class="header">
        <div>
          <h4>วันที่ออกบัตรนัด</h4>
          <h5>{{ appt.created | toThaiDate }} {{appt.created | toHHMM}}</h5>
        </div>
        <div>
          <p>ที่อยู่ 3/23 ถ.เพชรบุรี แขวงพญาไท เขตราชเทวี 10400</p>
          <p>เวลาทำการ เปิดตลอด 24 ชม. ทุกวัน</p>
          <p>tel: 067-398-3891 <span> @Line: @lovedoglovecat </span></p>
        </div>
        <div class="title">
          <p style="margin-right: 10px">Power by : </p>
          <img width="100px" height="100px"/>
        </div>
      </div>
    </div>
    <hr style="margin: 10px -10px; border: 1px solid #EEE;" />
    <div class="header">
      <div class="title">
        <img width="100px" height="100px"/>
        <div class="title-content">
          <h2>บัตรนัดหมายสำหรับสัตว์เลี้ยง (สำเนา)</h2>
          <h3>โรงพยาบาลสัตว์</h3>
        </div>
      </div>
      <div class="title">
        <div class="title-content">
          <p>* แสกน QR Code</p>
          <p>เพื่อกดยืนยัน / เพื่อนัด</p>
          <p>เพื่อดูรายละเอียด</p>
        </div>
        <img width="100px" height="100px"/>
      </div>
    </div>
    <hr class="line"/> -->
    <!-- <div>
      <p style="margin-right: 10px;">วันที่นัด
        <span>{{ appt.date_time | toThaiDate }} {{ appt.date_time | toHHMM }} น.</span>
      </p>
      <p style="margin-right: 10px;">ชื่อสัตว์
        <span>{{ appt.pet.name }}</span>
        <span style="margin-left: 10px; margin-right: 10px;">อายุ</span>
        <span>{{ appt.pet.birthday | petAge }}</span>
        <span style="margin-left: 10px; margin-right: 10px;">เพศ</span>
        <span>{{ appt.pet.gender | petGender }}</span>
      </p>
      <p style="margin-right: 10px;">เจ้าของ
        <span>{{ owner | fullname }}</span>
      </p>
      <p style="margin-right: 10px;">สัตวแพทย์
        <span>{{ appt.vet | fullname }}</span>
      </p>
      <div class="title">
        <p style="margin-right: 20px;">กิจกรรม</p>
        <div>
          <p style="margin: 0;" v-for="(activity, index) in appt.activities" :key="activity.id">
            {{ index + 1 }}. {{ activity.name }}
          </p>
        </div>
      </div>
      <p style="margin-right: 10px;">บันทึกเพิ่มเติม
        <span>{{ appt.note | hyphen }}</span>
      </p>
      <div class="title">
        <p>หมายเหตุ</p>
        <ul>
          <li>การทำวัคซีนเป็นประจำ จะช่วยป้องกันโรคติดต่อ หรือ โรคร้ายแรงในสุนัขได้</li>
          <li>สัตว์เลี้ยงที่ทำวัคซีน จะต้องมีสุขภาพปกติ</li>
          <li>ภายหลังจากการทำวัคซีนแล้ว สัตว์จะมีอาการซึมเล็กน้อย และจะเป็นปกติภายในระยะเวลา 2 วัน</li>
        </ul>
      </div>
      <hr class="line"/>
      <div class="header">
        <div>
          <h4>วันที่ออกบัตรนัด</h4>
          <h5>{{ appt.created | toThaiDate }} {{appt.created | toHHMM}}</h5>
        </div>
        <div>
          <p>ที่อยู่ 3/23 ถ.เพชรบุรี แขวงพญาไท เขตราชเทวี 10400</p>
          <p>เวลาทำการ เปิดตลอด 24 ชม. ทุกวัน</p>
          <p>tel: 067-398-3891 <span> @Line: @lovedoglovecat </span></p>
        </div>
        <div class="title">
          <p style="margin-right: 10px">Power by : </p>
          <img width="100px" height="100px"/>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
// import jsPDF from "jspdf"
import _ from "lodash"
import printJS from 'print-js'
import html2canvas from "html2canvas"

function convertImgToBase64URL(url, callback, outputFormat){
  var img = new Image();
  img.crossOrigin = 'Anonymous';
  img.onload = function(){
      var canvas = document.createElement('CANVAS'),
      ctx = canvas.getContext('2d'), dataURL;
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0);
      dataURL = canvas.toDataURL(outputFormat);
      callback(dataURL);
      canvas = null;
  };
  img.src = url;
}

export default {
  props: ["appt"],
  components: {},
  mounted() { },
  updated() {
    this.logo()
    setTimeout(() => {
      // window.print()
      html2canvas(document.querySelector('.appointment-detail-pdf'), { scale: 4 }).then(canvasimg => {
        var imgData = canvasimg.toDataURL("image/png")
        printJS(imgData, 'image')
        // var pdf = new jsPDF("p", "mm", "a5")
        // var width = pdf.internal.pageSize.getWidth()
        // var height = pdf.internal.pageSize.getHeight()
        // pdf.addImage(imgData, 'JPEG', 0, 0, width, height)
        // pdf.save('pdf.pdf')
      })
    }, 2000)
  },
  computed: {
    owner() {
      if (this.appt.remind_to) return this.appt.remind_to
      else if (this.appt && this.appt.pet && this.appt.pet.owners.length > 0) {
        return this.appt.pet.owners[0]
      }
      return null
    },
    hospital() {
      if (this.appt && this.appt.hospital) {
        return {
          logo: this.appt.hospital.logo.medium,
          name: this.appt.hospital.name,
          fullAddress: `${this.appt.hospital.address || ""} ${this.appt.hospital
            .district || ""} ${this.appt.hospital.sub_district || ""} ${this
            .appt.hospital.province || ""} ${this.appt.hospital.zip_code ||
            ""}`,
          phone: this.appt.hospital.contact_phones.length > 0 ? this.appt.hospital.contact_phones[0] : '-',
          open_time: this.appt.hospital.open_time
        }
      }
      return {
        logo: "",
        name: ""
      }
    },
    remarks() {
      let remarks = []
      this.appt && this.appt.activities.forEach(activity => {
        if (activity.note.length > 0) {
          return activity.note.forEach(notes => {
            remarks.push(notes)
          })
        }
      })
      return _.uniqBy(remarks, "id")
    },
    qrcode() {
      if (this.appt) {
        const protocol = window.location.protocol
        const slashes = protocol.concat("//")
        let host = slashes.concat(window.location.hostname)
        const port = window.location.port
        if (port) {
          host = host.concat(":" + port)
        }
        const url = host.concat(`/public/appointment/${this.appt.id}`)
        return url
      }
      return null
    }
  },
  methods: {
    logo() {
      if (this.hospital.logo) {
        convertImgToBase64URL(this.hospital.logo, function(base64Img){
            document.getElementById('hospital-logo').src = base64Img
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

@page {
  size: 595px 842px;
  margin: 0;
  padding: 0;
}

@media print {
  .appointment-detail-pdf {
    size: auto;
  }
}

.appointment-detail-pdf {
  background-color: #ffffff;
  width: 595px;
  height: 842px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.title-content-upper {
  width: 290px;
  height: 21px;
  font-family: "Kanit", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.title-content-lower {
  width: 290px;
  height: 16px;
  font-family: "Kanit", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.qr-code {
  width: 99px;
  height: 39px;
  font-family: "Kanit", sans-serif;
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #4a4a4a;
}

.upper-line {
  width: 491px;
  border: solid 1px #8e8e93;
}

.lower-line {
  width: 550px;
  border: solid 1px #8e8e93;
}

.footer-line {
  width: 595px;
  border: solid 1px #979797;
}

.qr-code-lower {
  width: 74px;
  height: 12px;
  font-family: "Kanit", sans-serif;
  font-size: 9px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.22px;
  text-align: right;
  color: #000000;
}

.topic-one {
  width: 37px;
  height: 16px;
  font-family: "Kanit", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.topic-two {
  width: 35px;
  height: 16px;
  font-family: "Kanit", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.topic-three {
  width: 30px;
  height: 13px;
  font-family: "Kanit", sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.topic-four {
  width: 43px;
  height: 13px;
  font-family: "Kanit", sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.topic-five {
  width: 40px;
  height: 16px;
  font-family: "Kanit", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.topic-six {
  width: 57px;
  height: 13px;
  font-family: "Kanit", sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.topic-seven {
  width: 36px;
  height: 12px;
  font-family: "Kanit", sans-serif;
  font-size: 9px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.value-one {
  width: 232px;
  height: 16px;
  font-family: "Kanit", sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.value-two {
  width: 380px;
  height: 16px;
  font-family: "Kanit", sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.value-three-a {
  width: 150px;
  height: 13px;
  font-family: "Kanit", sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.value-three-b {
  width: 90px;
  height: 11px;
  font-family: "Kanit", sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.value-four {
  width: 300px;
  height: 13px;
  font-family: "Kanit", sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.value-five {
  width: 317px;
  height: 114px;
  font-family: "Kanit", sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.58;
  letter-spacing: normal;
  color: #4a4a4a;
}

.value-six {
  width: 300px;
  height: 13px;
  font-family: "Kanit", sans-serif;
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.value-seven {
  width: 391px;
  height: 33px;
  font-family: "Kanit", sans-serif;
  font-size: 8px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.footer-one {
  width: 84px;
  height: 24px;
  font-family: "Kanit", sans-serif;
  font-size: 9px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.footer-two {
  width: 400px;
  height: 13px;
  font-family: "Kanit", sans-serif;
  font-size: 9px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.footer-three {
  width: 400px;
  height: 13px;
  font-family: "Kanit", sans-serif;
  font-size: 9px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.footer-four {
  width: 80px;
  height: 12px;
  font-family: "Kanit", sans-serif;
  font-size: 9px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.footer-five {
  width: 87px;
  height: 12px;
  font-family: "Kanit", sans-serif;
  font-size: 9px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.footer-six {
  width: 109px;
  height: 12px;
  font-family: "Kanit", sans-serif;
  font-size: 9px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.footer-seven {
  width: 70px;
  height: 12px;
  font-family: "Kanit", sans-serif;
  font-size: 9px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.footer-eight {
  object-fit: contain;
}

</style>
