<template>
  <div class="request-step-7" style="width: 100%">
    <el-row>
      <slot
        class="headSlot"
        name="header"
        :currentProgress="this.myProgress"
        :currentText="this.myText"
        :currentVetType="myVetType"
      ></slot>
    </el-row>

    <el-row>
      <el-row class="medical-record-card">
        <el-col>
          <img src="@/assets/bloodbank/file.png" height="20" />
          <span>บันทึกผลการใช้เลือด</span>
        </el-col>
      </el-row>
    </el-row>

    <!-- Content -->
    <el-card>
      <div class="row">
        <div class="col-md-12 col-xs-24 col-sm-24" style="padding: 0px 10px 0px 10px">
          <el-card>
            <h4 slot="title">ประเมินความสำเร็จ</h4>
            <div class="row flex">
              <div class="col-md-8 flex-start flex-middle">
                <h5 class="error-field-label">ปริมาตรเลือดที่ใช้จริง*</h5>
              </div>
              <div class="col-md-16 flex-start flex-middle">
                <div class="row flex-start flex-middle">
                  <el-input v-model="request.feedback.actualUsedQuantity" style="width: 150px;"></el-input>&nbsp;ml.
                </div>
              </div>
              <div class="col-md-24 flex-start">
                <h5>ผลเลือดหลังจากให้เลือด</h5>
              </div>
              <div class="col-md-6">
                <h1>PCV.</h1>
              </div>
              <div class="col-md-6">
                <label>ก่อนให้</label>
                <br />
                <label>{{ Number(request.recipientProfileInfo.labExam.HCT) }} %</label>
              </div>
              <div class="col-md-6">
                <label>เป้าหมาย</label>
                <br />
                <label>{{ Number(request.recipientProfileInfo.productRequest.targetPcv) }} %</label>
              </div>
              <div class="col-md-6">
                <label class="error-field-label">ผลที่ได้ *</label>
                <br />
                <el-input v-validate="'required'" v-model="request.feedback.sideEffect"></el-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-24 flex-start">
                <h5>ประเมินผลการให้เลือด</h5>
              </div>
              <div class="col-md-24 flex-start">
                <el-radio-group v-model="request.feedback.bloodAcquireResult">
                  <el-radio :label="bloodAcquireResultEnum.SUCCESS" style="margin-top:10px;">สำเร็จ</el-radio>
                  <br />
                  <el-radio
                    :label="bloodAcquireResultEnum.FAILED"
                    style="margin-top:10px;"
                  >ไม่สำเร็จ</el-radio>
                  <br />
                  <el-radio
                    :label="bloodAcquireResultEnum.UNUSED"
                    style="margin-top:10px;"
                  >ไม่ได้ใช้</el-radio>
                  <br />
                  <el-radio :label="bloodAcquireResultEnum.OTHER" style="margin-top:10px;">
                    อื่นๆ
                    <at-textarea
                      v-if="request.feedback.bloodAcquireResult === bloodAcquireResultEnum.OTHER"
                      v-model="request.feedback.bloodAcquireResultNote"
                      style="margin-top: 10px"
                    ></at-textarea>
                  </el-radio>
                  <br />
                </el-radio-group>
              </div>
            </div>
          </el-card>
        </div>
        <div class="col-md-12 col-xs-24 col-sm-24" style="padding: 0px 10px 0px 10px">
          <el-card>
            <h4 slot="title">บันทึก</h4>
            <div class="flex-start">
              <span>บันทึกเพิ่มเติม</span>
              <at-textarea v-model="request.feedback.note"></at-textarea>
              <span>แนบผลการตรวจ / OPD</span>
              <div class="col-sm-24">
                <vueDropzone
                  id="opdDropzone"
                  ref="opdDropzone"
                  :options="dropzoneOptions"
                  @vdropzone-removed-file="opdRemoved"
                  @vdropzone-file-added-manually="fileAdded"
                ></vueDropzone>
              </div>
              <!-- <el-input type="file"></el-input><br> -->
              <span>แนบไฟล์เพิ่มเติม</span>
              <div class="col-sm-24">
                <vueDropzone
                  id="otherImageDropzone"
                  ref="otherImageDropzone"
                  :options="dropzoneOptions"
                  @vdropzone-removed-file="otherImageRemoved"
                  @vdropzone-file-added-manually="fileAdded"
                ></vueDropzone>
              </div>
            </div>
          </el-card>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
/* eslint-disable no-console */
import { mapState, mapGetters } from "vuex";

import BloodAcquireResultEnum from "@/constants/bloodbank/blood-acquire-result";
import axios from "axios";
import { FEEDBACK_IMAGE } from "@/services/endpoints";

import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  components: {
    vueDropzone: vue2Dropzone
  },
  props: {
    request: Object,
    mode: {
      default: "view"
    }
  },

  data() {
    return {
      myProgress: 5,
      myText: "Blood Request Feedback",
      myVetType: "ผู้บันทึก",
      bloodAcquireResultEnum: BloodAcquireResultEnum,
      opdCroppas: [{}],
      otherImageCroppas: [{}],
      existingImage: [],
      removedImages: [],
      dropzoneOptions: {
        url: FEEDBACK_IMAGE,
        method: "PATCH",
        thumbnailWidth: 150,
        addRemoveLinks: true,
        paramName: "imageURL",
        autoProcessQueue: false
      }
    };
  },
  computed: {
    ...mapState({
      profile: state => state.hospital.profile
    }),
    ...mapGetters({
      getBloodBagById: "bloodbank/getBloodBagById",
      getOwnedBloodBagList: "bloodbank/getOwnedBloodBagList"
    })
  },

  methods: {
    async getFile(fileUrl) {
      return new Promise(async resolve => {
        const fileResponse = await axios.get(fileUrl, {
          responseType: "arraybuffer"
        });
        resolve(fileResponse);
      });
    },
    addExistingFile(dropZone, file, imgUrl) {
      console.log("File Added", file);
      console.log("File Url", imgUrl);
      dropZone.manuallyAddFile(file, imgUrl);
      // dropZone.dropzone.emit("addedfile", file)
    },
    fileAdded: function(file) {
      const item = this.existingImage.find(item => item.id === file.id);
      let a = document.createElement("a");
      a.setAttribute("class", "dz-remove");
      a.setAttribute("style", "margin-bottom: 45px;");
      a.setAttribute("target", "_blank");
      a.setAttribute("href", item.imageURL);
      a.innerHTML = "download";
      file.previewElement.appendChild(a);
    },
    createFeedbackImage: function(imageType, fileFullname) {
      return this.$store.dispatch("bloodbank/createFeedbackImage", {
        data: {
          feedback: this.request.feedback.id,
          file_fullname: fileFullname,
          image_type: imageType
        }
      });
    },
    uploadImage: async function(file, imageType) {
      const feedbackImg = await this.createFeedbackImage(imageType, file.name);
      const data = new FormData();
      file.dataURL = file.dataURL
        ? file.dataURL
        : await this.getDocDataURL(file);
      data.append(
        "imageURL",
        this.getBlobObject(file.dataURL),
        `opd.${file.name.split(".")[1]}`
      );
      return this.$store.dispatch("bloodbank/updateFeedbackImage", {
        params: {
          id: feedbackImg.id
        },
        formData: data
      });
    },
    getDocDataURL: async function(file) {
      return new Promise(resolve => {
        const fileReader = new FileReader();
        fileReader.onload = fileLoadedEvent => {
          resolve(fileLoadedEvent.target.result);
        };
        fileReader.readAsDataURL(file);
      });
    },
    opdRemoved: function(file) {
      // let confirmation = false;

      // this.$confirm("คุณยืนยันที่จะลบไฟล์ดังกล่าวใช่หรือไม่ ?", "คำเตือน")
      //   .then(() => {
      //     confirmation = true;
          this.onImageRemoved(file);
        // })
        // .catch(() => {
        //   if (confirmation) {
        //     return;
        //   }
        //   const existingFile = this.existingImage.find(
        //     item => item.id === file.name
        //   );

        //   if (existingFile) {
        //     this.$refs.opdDropzone.manuallyAddFile(
        //       existingFile,
        //       existingFile.imageURL
        //     );
        //   } else {
        //     this.$refs.opdDropzone.addFile(file);
        //   }
        // });
    },
    otherImageRemoved: function(file) {
      // let confirmation = false;

      // this.$confirm("คุณยืนยันที่จะลบไฟล์ดังกล่าวใช่หรือไม่ ?", "คำเตือน")
        // .then(() => {
          // confirmation = true;
          this.onImageRemoved(file);
        // })
        // .catch(() => {
        //   if (confirmation) {
        //     return;
        //   }
        //   const existingFile = this.existingImage.find(
        //     item => item.id === file.name
        //   );

        //   if (existingFile) {
        //     this.$refs.otherImageDropzone.manuallyAddFile(
        //       existingFile,
        //       existingFile.imageURL
        //     );
        //   } else {
        //     this.$refs.otherImageDropzone.addFile(file);
        //   }
        // });
    },
    onImageRemoved: function(file) {
      if (file.manuallyAdded) {
        this.removedImages.push(file.id);
      }
    },
    getBlobObject: function dataURItoBlob(dataURI) {
      var byteString = atob(dataURI.split(",")[1]);
      var mimeString = dataURI
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      var blob = new Blob([ab], { type: mimeString });
      return blob;
    },
    beforeChangeStep: async function() {
      let result = await this.$validator.validateAll();
      if (!result || !this.request.feedback.approver) {
        throw "กรุณากรอกข้อมูลให้ถูกต้อง";
      }
      let updateImagePromises = [];
      this.removedImages.map(feedbackImgId => {
        updateImagePromises.push(
          this.$store.dispatch("bloodbank/deleteFeedbackImage", {
            params: {
              id: feedbackImgId
            }
          })
        );
      });

      this.$refs.otherImageDropzone.getAcceptedFiles().map(img => {
        updateImagePromises.push(this.uploadImage(img, 2));
      });

      this.$refs.opdDropzone.getAcceptedFiles().map(img => {
        updateImagePromises.push(this.uploadImage(img, 1));
      });

      await Promise.all(updateImagePromises).then(console.log("Image updated"));
    }
  },

  async mounted() {
    if (this.request.feedback !== null && this.request.feedback.id !== null) {
      const allFeedbackImages = await this.$store.dispatch(
        "bloodbank/listFeedbackImage"
      );
      const feedbackId = this.request.feedback.id;
      await allFeedbackImages.map(async item => {
        const f = await this.getFile(item.imageURL);
        const file = {
          size: f.data.byteLength,
          name: item.file_fullname ? item.file_fullname : item.id,
          id: item.id,
          type: f.headers["content-type"]
        };
        this.existingImage.push({ ...item });
        if (
          item.feedback === feedbackId &&
          item.image_type === 1 &&
          item.imageURL != null
        ) {
          this.addExistingFile(this.$refs.opdDropzone, file, item.imageURL);
        } else if (
          item.feedback === feedbackId &&
          item.image_type === 2 &&
          item.imageURL != null
        ) {
          this.addExistingFile(
            this.$refs.otherImageDropzone,
            file,
            item.imageURL
          );
        }
      });
      this.$forceUpdate();
    }
  }
};
</script>

<style lang="scss">
  .request-step-7 {
    .header-container {
      width: calc(100% + 5px);
      height: 50px;
      border-radius: 6px;
      background-color: #5c9ba3;
      text-align: center;
    }

    .profile-row-container {
      height: 55px;
    }

    .el-input-group__append {
      padding: 0px !important;
    }

    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100% !important;
    }
  }
</style>
