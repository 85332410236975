<template>
  <div class="plan-and-credit-page">
    <Subnavbar>
      <template slot="title">Plan and Credit Top-up</template>
      <template slot="actions">
        <div class="navbar-contact-link">
          <span class="label">สนใจสั่งซื้อ ทักแชทที่</span>
          <a href="http://nav.cx/f0rayna" target="_blank">
            <img src="@/assets/images/plan/line-add-friend-button.png" class="line-img-link" />
          </a>
          <a href="https://www.facebook.com/VRemindU/" target="_blank">
            <img src="@/assets/images/plan/message-us-button.png" class="fb-img-link" />
          </a>
        </div>
      </template>
    </Subnavbar>
    <ContentBox class="top-section">
      <h1 class="title">Plan and Price</h1>
      <div class="subtitle">“ ดูแลนัดหมายลูกค้า ให้กลับมาหาเราได้สบายกว่าวิธีเดิมๆ”</div>
      <PlanTable />
    </ContentBox>
    <ContentBox class="info-section">
      <div class="credit-top-up">
        <h1 class="title">Credit Top-up</h1>
        <div class="subtitle">“ เครดิตสำหรับใช้แจ้งเตือนหลากหลายช่องทาง สะดวกกว่า และ ถูกกว่า!”</div>
        <div class="credit-info">
          <el-row>
            <el-col :span="14">
              <span class="coin">
                <img src="@/assets/images/plan/coin-2.png" />
              </span>
              <span class="credits">20,000 Credits</span>
            </el-col>
            <el-col :span="10">
              THB
              <strong>2,000</strong>
              <span class="per-credit">(0.1/Credit)</span>
            </el-col>
          </el-row>
        </div>
        <div class="credit-info">
          <el-row>
            <el-col :span="14">
              <span class="coin">
                <img src="@/assets/images/plan/coin-3.png" />
              </span>
              <span class="credits">50,000 Credits</span>
            </el-col>
            <el-col :span="10">
              THB
              <strong>3,500</strong>
              <span class="per-credit">(0.07/Credit)</span>
            </el-col>
          </el-row>
        </div>
        <div class="credit-info popular">
          <div class="popular-label">Popular</div>
          <el-row>
            <el-col :span="14">
              <span class="coin">
                <img src="@/assets/images/plan/coin-4.png" />
              </span>
              <span class="credits">100,000 Credits</span>
            </el-col>
            <el-col :span="10">
              THB
              <strong>5,000</strong>
              <span class="per-credit">(0.05/Credit)</span>
            </el-col>
          </el-row>
        </div>
        <div class="note">
          <div>หมายเหตุ:</div>
          <ul style="margin: 0;">
            <li>
              การเตือนผ่าน SMS 1 ครั้ง
              <ul style="list-style-type: none; padding: 0 0 0 30px">
                <li>Plan Freeใช้ 20 Credits/ครั้ง</li>
                <li>Plan Basic, Standard, Premium ใช้ 18 Credits/ครั้ง</li>
              </ul>
            </li>
            <li>
              การเตือนผ่าน Social 1ครั้ง(ทุกช่องทาง)
              <ul style="list-style-type: none; padding: 0 0 0 30px">
                <li>Plan Freeใช้ 8 Credits/ครั้ง</li>
                <li>Plan Basic, Standard, Premium ใช้ 6 Credits/ครั้ง</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div class="how-to-buy">
        <h1 class="title">How to buy?</h1>
        <div class="subtitle">วิธีการสั่งซื้อง่ายๆ</div>
        <ol>
          <li>เข้าสู่ระบบ/สมัครสมาชิก</li>
          <li>เลือก Plan ที่ต้องการ หรือถ้าต้องการเติม Credit เตือนนัด ก็สามารถเลือกเฉพาะ Top up Credits ที่ต้องการได้</li>
          <li>
            พิมพ์รายละเอียดแล้วแจ้งเข้ามาที่ @Line หรือ Messenger
            <div class="social">
              <div class="text-center">
                <a href="http://nav.cx/f0rayna" target="_blank">
                  <img src="@/assets/images/plan/line-add-friend-button.png" />
                </a>
                <a href="https://www.facebook.com/VRemindU/" target="_blank">
                  <img src="@/assets/images/plan/message-us-button.png" />
                </a>
              </div>
              <ul style="margin: 10px auto; width: 350px;">
                <li>ชื่อโรงพยาบาล</li>
                <li>email</li>
                <li>เบอร์ติดต่อ</li>
                <li>ชื่อ Plan หรือ Credit ที่ต้องการ</li>
              </ul>
            </div>
          </li>
          <li>รับข้อความยืนยันการสั่งซื้อจาก VRemind โดยจะส่งเป็นลิงก์ ‘สรุปรายการสั่งซื้อ’ ให้ตรวจสอบรายละเอียดการสั่งซื้ออีกครั้ง</li>
          <li>
            ชำระเงิน เมื่อรายการสั่งซื้อถูกต้องแล้ว โดยการโอนยอดเงินผ่านช่องทางต่อไปนี้
            <div class="payment">
              <div class="clearfix">
                <img src="@/assets/images/plan/kbank.png" />บริษัท เพ็ทส์บ็อกซ์ ซัพพลาย จำกัด
                <br />ธนาคารกสิกรไทย สาขาสยามพารากอน
                <br />
                <div class="bank-account">738-2-02499-7</div>
              </div>
              <div class="remark">
                * มีปัญหาในการชำระเงิน โปรดติดต่อเราได้ที่
                <a
                  href="mailto:infovremind@gmail.com"
                >infovremind@gmail.com</a>
              </div>
            </div>
          </li>
          <li>ส่งรูปถ่ายหลักฐานการโอนเงิน มาได้ที่ลิงก์ ‘สรุปรายการสั่งซื้อ’ ของท่าน เพื่อยืนยันการชำระเงิน</li>
          <li>ระบบจะทำการอัพเดท Plan หรือ Credit เมื่อการสั่งซื้อเสร็จสิ้นแล้ว</li>
        </ol>
      </div>
    </ContentBox>
  </div>
</template>

<script>
import Subnavbar from "@/components/navigation/Subnavbar";
import ContentBox from "@/components/container/ContentBox";
import PlanTable from "@/pages/plan/PlanTable";
export default {
  components: {
    Subnavbar,
    ContentBox,
    PlanTable
  }
};
</script>

<style lang="scss" scoped>
.plan-and-credit-page {
  min-width: 760px;

  .navbar-contact-link {
    margin-top: 15px;
    
    .label {
      display: none;
      font-size: 1.1em;
      line-height: 2em;
    }

    .line-img-link {
      margin-left: 5px;
      width: 129px;
      vertical-align: top;
    }
    .fb-img-link {
      margin-left: 10px;
      width: 125px;
      vertical-align: top;
    }

    @media (min-width: 769px) {
      margin-top: 10px;
      .label {
        display: inline;
      }
      .line-img-link {
        width: 149px;
      }
      .fb-img-link {
        width: 145px;
      }
    }
  }

  .top-section {
    max-width: 1200px;
    margin: 0 auto 20px;
  }

  .info-section {
    margin: 0;
    padding: 0;
    background: #fff;
  }

  .title {
    text-align: center;
    margin: 10px 0;
    font-size: 2em;
  }
  .subtitle {
    margin: 15px 0 20px 0;
    text-align: center;
    font-size: 1.3rem;
  }

  .credit-top-up {
    margin: 0 auto;
    max-width: 700px;
    font-size: 1.2rem;

    .credit-info {
      position: relative;
      margin: 10px 0;
      background-color: #f5f8fa;
      padding: 15px 30px;
      font-size: 1.4rem;
      font-weight: normal;

      &.popular {
        border: 2px solid #d0025b;
      }

      .popular-label {
        position: absolute;
        left: 25px;
        top: -15px;
        font-size: 0.9rem;
        color: #fff;
        background-color: #d0025b;
        padding: 2px 15px;
        border-radius: 15px;
      }

      .coin {
        display: inline-block;
        width: 70px;
        img {
          vertical-align: middle;
        }
      }
      .credits {
        margin-left: 20px;
      }

      .per-credit {
        margin-left: 10px;
        color: #8e8e93;
      }
    }
  }

  .how-to-buy {
    margin: 30px auto 0;
    width: 760px;
    font-size: 1.2rem;

    .social {
      margin: 25px 0;

      img {
        margin: 0 10px;
      }
    }

    .payment {
      margin: 25px 0;
      img {
        float: left;
        vertical-align: top;
        margin: 0 20px 20px 50px;
      }
      .bank-account {
        font-size: 2rem;
        font-weight: bold;
      }
      .remark,
      .remark a {
        color: #d0021b;
      }
    }
  }
}
</style>
