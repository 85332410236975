<template>
  <ContentBox class="appointment-search">
    <h2 class="title">Search</h2>
    <el-row class="custom-row">
      <el-col :md="12">
        <div class="date-group" :gutter="20">
          <el-date-picker type="date" placeholder="Start Date" size="small" v-model="form.start" @change="startDateChange"></el-date-picker>
          <span>to</span>
          <el-date-picker type="date" placeholder="End Date" size="small" v-model="form.end" @change="endDateChange"></el-date-picker>
        </div>
      </el-col>
    </el-row>
    <el-row class="custom-row" :gutter="20">
      <el-col :md="12">
        <el-input placeholder="Pet Name, H.N., Owner, Appointment ID , Tel" size="small"
          v-model="form.search"
          clearable
          @keyup.enter.native="onSearch"
          @clear="onClearSearch"
        >
          <el-button slot="append" icon="el-icon-search" @click="onSearch"></el-button>
        </el-input>
      </el-col>
    </el-row>
  </ContentBox>
</template>

<script>
import ContentBox from "@/components/container/ContentBox"

export default {
  props: ['search', 'startDate', 'endDate', 'status'],
  components: {
    ContentBox
  },
  data() {
    return {
      form: {
        search: this.search,
        start: this.startDate,
        end: this.endDate,
        status: this.status,
        rewardId: this.selectedRewardId,
      }
    }
  },
  methods: {
    startDateChange () {
      this.$emit('search', this.form)
    },
    endDateChange (d) {
      if(d) {
        d.setHours(23,59,59,999)
        this.form.end = new Date(d)
      }
      this.$emit('search', this.form)
    },
    onSearch () {
      this.$emit('search', this.form)
    },
    onClearSearch () {
      this.form.search = ''
      this.$emit('search', this.form)
    },
    onClearStatus () {
      this.form.status = ''
      this.$emit('search', this.form)
    },
    onClearVetId () {
      this.form.vetId = ''
      this.$emit('search', this.form)
    },
    onClearActivityGroupId () {
      this.form.activityGroupId = ''
      this.$emit('search', this.form)
    }
  }
}
</script>

<style lang="scss" scoped>
.appointment-search {
  .title {
    margin: 0;
    padding: 0;
    font-size: 16px;
    margin-bottom: 5px;
  }

  .custom-row {
    margin-bottom: 10px;
  }

  .date-group {
    > span {
      padding: 10px;
    }
  }
}
</style>


