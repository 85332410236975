import MedicalRecord from './medical-record'
import moment from 'moment'
import _ from 'lodash'

export default class extends MedicalRecord {
  constructor (obj) {
    super(obj)

    if (obj != null) {
      this.usedToBloodTaken = obj.ever_receive_blood == null ? false : obj.ever_receive_blood
      this.bloodTakenDate = obj.last_receive_blood == null ? '' : new Date(obj.last_receive_blood)
      this.usedToSurgery = obj.ever_has_surgery == null ? false : obj.ever_has_surgery
      this.surgeryDate = obj.last_surgery == null ? '' : new Date(obj.last_surgery)
      this.diagnosis = obj.diagnosis == null ? [] : obj.diagnosis
      this.isImmediatelyRequired = obj.use_immediately == null ? true : obj.use_immediately
      this.reserveDate = obj.reserve_date == null ? '' : new Date(obj.reserve_date)
      this.bloodRequestProfileId = obj.blood_request_profile == null ? null : obj.blood_request_profile
    } else {
      this.usedToBloodTaken = false
      this.bloodTakenDate = ''
      this.usedToSurgery = false
      this.surgeryDate = ''
      this.diagnosis = []
      this.isImmediatelyRequired = true
      this.reserveDate = ''
      this.bloodRequestId = null
    }
  }

  toRequestJson () {
    let baseObj = super.toRequestJson()

    baseObj.blood_request_profile = this.bloodRequestProfileId
    baseObj.ever_receive_blood = this.usedToBloodTaken
    baseObj.last_receive_blood = _.isDate(this.bloodTakenDate) ? moment(this.bloodTakenDate).format('YYYY-MM-DD') : null
    baseObj.ever_has_surgery = this.usedToSurgery
    baseObj.last_surgery = _.isDate(this.surgeryDate) ? moment(this.surgeryDate).format('YYYY-MM-DD') : null
    baseObj.use_immediately = this.isImmediatelyRequired
    baseObj.reserve_date = _.isDate(this.reserveDate) ? moment(this.reserveDate).format() : null // "2018-06-05T02:20:08+07:00"
    baseObj.diagnosis = this.diagnosis

    return baseObj
  }
}
