<script>
export default {
  props: {
    header: {
      type: String,
      default: "",
    },
  },

  render() {
    const renderHeader = () => (
      <el-row
        type="flex"
        gutter={16}
        align="middle"
        style={{ marginBottom: "0.5rem" }}
      >
        <el-col span={null} style={{ flex: "1" }}>
          <h3>{this.header}</h3>
        </el-col>
        {this.$slots.action && (
          <el-col span={null}>{this.$slots.action}</el-col>
        )}
        {this.$slots.topTracker && (
          <el-col span={null} 
            style={{ flex: "1", border: "1px solid #d8d8d8", margin: "0 8px" }}>
              {this.$slots.topTracker}
          </el-col>
        )}
      </el-row>
    );

    const renderTrackers = () => (
      <div style={{ padding: "0 0.5rem", marginBottom: "0.5rem" }}>
        <el-row type="flex" gutter={16} style={{ flexWrap: "wrap" }}>
          {this.$slots.tracker &&
            this.$slots.tracker.map((vnode) => (
              <el-col md={12} style={{ border: "1px solid #d8d8d8" }}>
                {vnode}
              </el-col>
            ))}
        </el-row>
      </div>
    );

    return (
      <el-card
        bodyStyle={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          boxSizing: "border-box",
        }}
      >
        {renderHeader()}
        {renderTrackers()}
        {this.$slots.default}
      </el-card>
    );
  },
};
</script>
