import Crossmatch from '../blood-request-crossmatch-info/blood-request-crossmatch-info'
import BloodRecipientProfile from '../blood-request-profile-info/blood-request-profile-info'
import BloodSuggest from '../../blood-suggest/blood-suggest'
import ReserveBlood from '../../blood-request/acquire-blood-bag-info/acquire-blood-bag-info'
import Pet from '../../common/pets-info/pet-info/pet-info'
import AcquireBloodBagSummaryInfo from '../acquire-blood-bag-summary-info/acquire-blood-bag-summary-info'
import AcquireConfirm from '../blood-bag-acquire-confirm-info/blood-bag-acquire-confirm-info'
import store from '@/store/index'
export default class {
	constructor(obj) {
		if (obj != null) {
			this.id = obj.id == null ? null : obj.id
			this.petId = obj.itaampet == null ? null : obj.itaampet
			this.petInfo = obj.petInfo == null ? null : obj.petInfo
			this.pet = obj.pet == null ? null : obj.pet
			this.hospitalId = obj.hospital == null ? null : obj.hospital
			this.isRecipientProfileSubmitted =
				obj.is_request_submitted == null ? false : obj.is_request_submitted
			this.recipientProfileInfo =
				obj.recipientProfileInfo == null
					? new BloodRecipientProfile()
					: obj.recipientProfileInfo
			this.isBloodSuggestSubmitted =
				obj.is_blood_suggest_submitted == null
					? false
					: obj.is_blood_suggest_submitted
			this.bloodSuggestInfo =
				obj.bloodSuggestInfo == null ? new BloodSuggest() : obj.bloodSuggestInfo
			this.isCrossmatchSubmitted =
				obj.is_crossmatch_submitted == null
					? false
					: obj.is_crossmatch_submitted
			this.isWithdrawBloodBagReceived =
				obj.is_receive_blood_bag_submitted == null
					? false
					: obj.is_receive_blood_bag_submitted
			this.isBloodBagReceived =
				obj.has_receive == null ? false : obj.has_receive
			this.isFeedbackSubmitted =
				obj.is_feedback_submitted == null ? false : obj.is_feedback_submitted
			this.selectedBloodSuggestList =
				obj.selectedBloodSuggestList == null ||
				obj.selectedBloodSuggestList.lenght === 0
					? []
					: obj.obj.selectedBloodSuggestList
			this.crossmatch =
				obj.crossmatch == null ? new Crossmatch() : obj.crossmatch
			this.reserveBlood =
				obj.reserveBlood == null ? new ReserveBlood() : obj.reserveBlood // Change prop name here for the blood reservation info from BL
			this.receiveBlood =
				obj.acquireBloodBag == null ? new AcquireConfirm() : obj.acquireBloodBag
			this.feedback =
				obj.acquireBloodBagSummaryInfo == null
					? new AcquireBloodBagSummaryInfo()
					: obj.acquireBloodBagSummaryInfo
			this.createDate = obj.created == null ? null : obj.created
			this.modifiedDate = obj.last_updated == null ? null : obj.last_updated
			this.isTicketRejected =
				obj.is_ticket_rejected == null ? false : obj.is_ticket_rejected
			this.crossmatchExaminer =
				obj.crossmatch_examiner == null ? '' : obj.crossmatch_examiner // Examiner for all cross match blood bags of the request
		} else {
			this.id = null
			this.petId = null
			this.petInfo = new Pet()
			this.hospitalId = store.state.hospital.profile.id
			this.isRecipientProfileSubmitted = false
			this.recipientProfileInfo = new BloodRecipientProfile()
			this.isBloodSuggestSubmitted = false
			this.bloodSuggestInfo = new BloodSuggest()
			this.isCrossmatchSubmitted = false
			this.isWithdrawBloodBagReceived = false
			this.isBloodBagReceived = false
			this.isFeedbackSubmitted = false
			this.selectedBloodSuggestList = []
			this.crossmatch = new Crossmatch()
			this.reserveBlood = new ReserveBlood()
			this.receiveBlood = new AcquireConfirm()
			this.feedback = new AcquireBloodBagSummaryInfo()
			this.createDate = null
			this.modifiedDate = null
			this.isTicketRejected = false
			this.crossmatchExaminer = ''
		}
	}

	isCaseClosed() {
		return this.isBloodBagReceived || this.isTicketRejected
	}

	toRequestJson() {
		return {
			id: this.id,
			itaampet: this.petInfo.id,
			hospital: this.hospitalId,
			has_receive: this.isBloodBagReceived,
			is_blood_suggest_submitted: this.isBloodSuggestSubmitted,
			is_crossmatch_submitted: this.isCrossmatchSubmitted,
			is_feedback_submitted: this.isFeedbackSubmitted,
			is_receive_blood_bag_submitted: this.isWithdrawBloodBagReceived,
			is_request_submitted: this.isRecipientProfileSubmitted,
			is_ticket_rejected: this.isTicketRejected,
			crossmatch_examiner: this.crossmatchExaminer,
			recipientProfileInfo: this.recipientProfileInfo.toRequestJson(),
			// bloodSuggestInfo: this.bloodSuggestInfo.toRequestJson(),
			crossmatch: this.crossmatch.toRequestJson(),
			reserveBlood: this.reserveBlood.toRequestJson(),
			receiveBlood: this.receiveBlood.toRequestJson(),
			feedback: this.feedback.toRequestJson()
		}
	}

	getSubProgressList() {
		return [
			{
				title: 'Recipient Profile'
			},
			{
				title: 'Lab'
			},
			{
				title: 'Product Request'
			}
		]
	}

	getProgressList() {
		return [
			{
				title: 'ยื่นคำขอถุงเลือด',
				state: this.isRecipientProfileSubmitted ? 'finish' : 'process',
				status: this.isRecipientProfileSubmitted ? 'finish' : 'process',
				img: 'notepad.png'
			},
			{
				title: 'Crossmatch',
				state: this.isCrossmatchSubmitted ? 'finish' : 'process',
				status: this.isCrossmatchSubmitted ? 'finish' : 'process',
				img: 'file.png'
			},
			{
				title: 'เบิกถุงเลือด',
				state: this.isWithdrawBloodBagReceived ? 'finish' : 'process',
				status: this.isWithdrawBloodBagReceived ? 'finish' : 'process',
				img: 'syringe.png'
			},
			{
				title: 'รับเลือดแล้ว',
				state: this.isBloodBagReceived ? 'finish' : 'process',
				status: this.isBloodBagReceived ? 'finish' : 'process',
				img: 'blood-transfusion.png'
			},
			{
				title: 'Done',
				state: this.isFeedbackSubmitted ? 'finish' : 'process',
				status: this.isFeedbackSubmitted ? 'finish' : 'process',
				img: 'blood-transfusion.png'
			}
		]
	}

	getCurrentProgress() {
		let i = this.getProgressList().findIndex(p => p.status === 'process')
		return i >= 0 ? i : this.getProgressList().length // If no process found = finish all
	}

	cloneFrom(obj) {
		this.id = obj.id
		this.petId = obj.petId
		this.petInfo.cloneFrom(obj.petInfo)
		this.hospitalId = obj.hospitalId
		this.isRecipientProfileSubmitted = obj.isRecipientProfileSubmitted
		this.recipientProfileInfo.cloneFrom(obj.recipientProfileInfo)
		this.isBloodSuggestSubmitted = obj.isBloodSuggestSubmitted
		this.bloodSuggestInfo = obj.bloodSuggestInfo //= new BloodSuggest()
		this.isCrossmatchSubmitted = obj.isCrossmatchSubmitted
		this.isWithdrawBloodBagReceived = obj.isWithdrawBloodBagReceived
		this.isBloodBagReceived = obj.isBloodBagReceived
		this.isFeedbackSubmitted = obj.isFeedbackSubmitted
		this.selectedBloodSuggestList = obj.selectedBloodSuggestList
		this.crossmatch.cloneFrom(obj.crossmatch)
		this.reserveBlood.cloneFrom(obj.reserveBlood)
		this.receiveBlood = new AcquireConfirm(obj.receiveBlood.toRequestJson())
		this.feedback.cloneFrom(obj.feedback)
		this.createDate = obj.createDate
		this.modifiedDate = obj.modifiedDate
		this.isTicketRejected = obj.isTicketRejected
		this.crossmatchExaminer = obj.crossmatchExaminer
	}
}
