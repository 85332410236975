<template>
  <div style="display: inline-block;">
    <el-tooltip style="opacity: 0.6;">
      <el-button
        style="opacity: 1.6; padding: 2px;"
        v-if="mode === 'tooltip'"
        @click="showDialog = true"
        type="text"
        circle
        plain
        icon="el-icon-info"
        hollow
      ></el-button>
      <el-button
        style="opacity: 1.6; padding: 2px"
        v-if="mode === 'alert'"
        @click="showDialog = true"
        type="text"
        circle
        plain
        icon="el-icon-warning"
        hollow
      ></el-button>
      <template slot="content">
        <span>
          {{tooltip}}
          <a>...คลิกที่ปุ่มเพื่อแสดงเพิ่มเติม</a>
        </span>
      </template>
    </el-tooltip>
    <el-dialog :visible.sync="showDialog" :title="title" :styles="{top: '15%', width: '70%'}">
      <div v-html="tooltipContent" style="overflow: auto; maxHeight: 500px;"></div>
      <div slot="footer">
        <el-button type="primary" @click="showDialog = false">Close</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "tooltip-control",
  data() {
    return {
      showDialog: false
    };
  },
  props: ["tooltipContent", "title", "tooltip", "mode"],
  methods: {}
};
</script>

<style scoped>
</style>
