<template>
  <div class="no-gutter">
    <div class="row">
      <div class="col-md-1 flex">
        <div class="flex-center flex-middle">
          <i v-if="isSelected" class="icon icon-check" style="color: green;font-size:30px"></i>
        </div>
      </div>
      <div class="col-md-2">
        <el-tag type="success" v-if="status == bloodBagStatus.AVAILABLE">Available</el-tag>
        <el-tag type="warning" v-if="status == bloodBagStatus.RESERVE">Reserved</el-tag>
        <el-tag type="error" v-if="status == bloodBagStatus.EXPIRE">Expired</el-tag>
        <el-tag type="error" v-if="status == bloodBagStatus.USED">Used</el-tag>
        <el-tag type="warning" v-if="status == bloodBagStatus.TRANSFER">Transfered</el-tag>
      </div>
      <div class="col-md-3" style="padding-left:10px">
        <div class="row">
          <label>เลขที่ถุงเลือด</label>
        </div>
        <div class="row">
          <h3>{{bloodBagId}}</h3>
        </div>
      </div>

      <div class="col-md-1">
        <div class="row">
          <label>Type</label>
        </div>
        <div class="row">
          <h3>{{bloodType}}</h3>
        </div>
      </div>

      <div class="col-md-2" style="padding-left:10px">
        <div class="row">
          <label>PCV</label>
        </div>
        <div class="row">
          <h3>{{pcvValue}} %</h3>
        </div>
      </div>

      <div class="col-md-3">
        <div class="row">
          <label>วันหมดอายุ</label>
        </div>
        <div class="row">
          <h3>{{expiry}}</h3>
        </div>
      </div>

      <div class="col-md-4">
        <div class="row">
          <label>คะแนนความเป็นไปได้</label>
        </div>
        <div class="row">
          <h3>{{possibility ? Math.round(possibility * 100) : '-'}} %</h3>
        </div>
      </div>

      <div class="col-md-3">
        <div class="row">
          <label>ปริมาตรที่แนะนำ</label>
        </div>
        <div class="row">
          <h3>{{suggestedQuantity ? Math.round(suggestedQuantity) : '-'}} ml.</h3>
        </div>
      </div>

      <div class="col-md-3">
        <div class="row">
          <label>ปริมาตรคงเหลือ</label>
        </div>

        <div class="row">
          <h3>{{remainingQuantity}} ml.</h3>
        </div>
      </div>

      <div class="col-md-2 flex">
        <div class="row container flex-middle">
          <div class="flex-middle" style="height: 75%;">
            <el-button
              type="error"
              size="mini"
              :disabled="disabled"
              v-if="isSelected"
              v-on:click="onCancel"
            >ยกเลิก</el-button>
            <el-button
              type="primary"
              size="mini"
              :disabled="disabled"
              v-if="!isSelected"
              v-on:click="onSelect"
            >เลือกถุงเลือด</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bloodBagStatus from "@/constants/bloodbank/blood-storage-bag-status.js";
export default {
  name: "blood-row-selectable",
  data() {
    return {
      bloodBagStatus: bloodBagStatus
    };
  },
  props: [
    "bloodBagId",
    "bloodType",
    "pcvValue",
    "expiry",
    "possibility",
    "remainingQuantity",
    "isInStock",
    "disabled",
    "isSelected",
    "status",
    "suggestedQuantity"
  ],
  methods: {
    onSelect: function(e) {
      this.$emit("bloodbagSelected", {
        event: e,
        selectedBloodBagId: this.bloodBagId
      });
    },

    onCancel: function(e) {
      this.$emit("bloodbagCancel", {
        event: e,
        selectedBloodBagId: this.bloodBagId
      });
    }
  }
};
</script>

<style scoped>
</style>
