<template>
  <div class="pet-search-page">
    <Subnavbar>
      <template slot="breadcrumb">
        <ul>
          <li>New Pet</li>
        </ul>
      </template>
      <template slot="title">NEW PET</template>
      <template slot="actions">
        <button
          class="btn btn-large btn-register-new-pet"
          @click="$router.push({ name: 'hospitalPetOwnerRegister' })"
        >
          <i class="fa fa-plus"></i> Register New Owner
        </button>
      </template>
    </Subnavbar>

    <ContentBox>
      <div class="search-section">
        <h2 class="title" v-if="hospital">ค้นหาเจ้าของ หรือ สัตว์เลี้ยงจาก</h2>
        <div class="search-choices" v-if="hospital">
          <el-select
            style="width: 100%;text-align:center;"
            popper-class="search-select"
            v-model="searchChoices"
          >
            <el-option style="text-align:center;" :key="0" :value="true" :label="hospital.name"></el-option>
            <el-option style="text-align:center;" :key="1" :value="false" label="ระบบ VRemind"></el-option>
          </el-select>
        </div>
        <div class="search-input-wrapper">
          <el-input
            ref="searchInput"
            autofocus
            :placeholder="searchChoices? 'ค้นหาจากชื่อสัตว์, เบอร์โทร, ชื่อเจ้าของ, อีเมล์, HN, Microchips, Pet ID': 'ค้นหาจาก Pet ID, Microchips, เบอร์โทร'"
            v-model="searchText"
            class="search-input"
            clearable
            @keyup.enter.native="() => search(1,1)"
            @clear="clearSearch"
          >
            <el-button slot="append" icon="el-icon-search" @click="() => search(1,1)"></el-button>
          </el-input>

          <small
            v-show="searchChoices === false"
            class="search-remark"
          >* พิมพ์ให้ถูกต้องทุกตัวอักษร ถึงจะแสดงผลการค้นหา</small>
        </div>
      </div>
      <div class="section">
        <PetOwnerSearchResult
          :owners="ownerResults"
          :current-page="ownerCurrentPage"
          @current-change="ownerPageChange"
        />
      </div>
      <div class="section">
        <PetSearchResult
          :pets="petResults"
          :current-page="petCurrentPage"
          @current-change="petPageChange"
          @add-pet="handleAddPet"
        />
      </div>
      <div class="not-found not-found-hospital" v-if="hasResults && searchChoices">
        <img src="@/assets/images/no-result/no-result-02.png" class="responsive-img" />
      </div>
      <div class="not-found not-found-system" v-if="hasResults && !searchChoices">
        <img src="@/assets/images/no-result/no-result-03.png" class="responsive-img" />
      </div>
    </ContentBox>

    <ConfirmDialog
      :visible="showAddPetDialog"
      @cancel="showAddPetDialog = false"
      @confirm="handleConfirmAddPet"
    >
      <template slot="header">
        <h1 class="title">
          ยืนยันรับ
          <span class="petname-text" v-if="selectedAddPet">{{ selectedAddPet.name }}</span> เข้าโรงพยาบาล?
        </h1>
        <div v-if="selectedAddPet">
          <div>
            <PetTypeIcon :type="selectedAddPet.type.key" />
            <PetGenderIcon :gender="selectedAddPet.gender" />
          </div>
          <div v-if="selectedAddPet.breed">{{ selectedAddPet.breed.name }}</div>
          <div v-if="selectedAddPet.microchip">
            <strong>Microchip No</strong>
            : {{ selectedAddPet.microchip }}
          </div>
        </div>
        <div v-if="addPetOwner">
          <div>
            <strong>Owner:</strong>
            {{ `${addPetOwner.firstname} ${addPetOwner.lastname}` }}
          </div>
          <div>
            <strong>Tel:</strong>
            {{ addPetOwner.phone || '-' }}
          </div>
        </div>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ContentBox from "@/components/container/ContentBox";
import Subnavbar from "@/components/navigation/Subnavbar";
import PetOwnerSearchResult from "@/components/pet/PetOwnerSearchResult";
import PetSearchResult from "@/components/pet/PetSearchResult";
import ConfirmDialog from "@/components/dialog/ConfirmDialog";
import PetGenderIcon from "@/components/pet/PetGenderIcon";
import PetTypeIcon from "@/components/pet/PetTypeIcon";

export default {
  components: {
    ContentBox,
    Subnavbar,
    PetOwnerSearchResult,
    PetSearchResult,
    ConfirmDialog,
    PetGenderIcon,
    PetTypeIcon
  },
  data() {
    return {
      hasResults: false,
      isSearching: false,
      isExisting: true,
      searchText: "",
      ownerCurrentPage: 1,
      petCurrentPage: 1,
      selectedAddPet: null,
      showAddPetDialog: false
    };
  },
  computed: {
    searchChoices: {
      get() {
        return this.isExisting;
      },
      set(value) {
        this.ownerCurrentPage = 1;
        this.petCurrentPage = 1;
        this.isExisting = value;
        this.$nextTick(() => this.$refs.searchInput.focus());
        this.search(this.ownerCurrentPage, this.petCurrentPage);
      }
    },
    addPetOwner() {
      if (this.selectedAddPet && this.selectedAddPet.owners) {
        if (this.selectedAddPet.owners.length > 0) {
          return this.selectedAddPet.owners[0];
        }
        return null;
      }
      return null;
    },
    ...mapState({
      hospital: state => state.hospital.profile,
      ownerResults: state => state.owner.searchResults,
      petResults: state => state.pet.searchResults
    })
  },
  methods: {
    checkSearchResults() {
      this.hasResults =
        this.searchText !== "" &&
        this.ownerResults.results &&
        this.ownerResults.results.length === 0 &&
        this.petResults.results &&
        this.petResults.results.length === 0;
    },
    async search(ownerCurrentPage, petCurrentPage) {
      this.hasResults = false;

      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        if (this.isExisting) {
          await this.$store.dispatch("owner/existingSearch", {
            query: { search: this.searchText, page: ownerCurrentPage }
          });
          await this.$store.dispatch("pet/existingSearch", {
            query: { search: this.searchText, page: petCurrentPage }
          });
        } else {
          await this.$store.dispatch("owner/systemSearch", {
            query: { search: this.searchText, page: ownerCurrentPage }
          });
          await this.$store.dispatch("pet/systemSearch", {
            query: { search: this.searchText, page: petCurrentPage }
          });
        }

        this.$router.replace({
          query: {
            isExisting: this.isExisting,
            search: this.searchText,
            ownerCurrentPage: ownerCurrentPage,
            petCurrentPage: petCurrentPage
          }
        });
        this.checkSearchResults();
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        this.hasResults = false;
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorMsg");
      }
    },
    clearSearch() {
      this.searchText = "";
      this.ownerCurrentPage = 1;
      this.petCurrentPage = 1;
      this.$router.replace({
        search: "",
        ownerCurrentPage: 1,
        petCurrentPage: 1
      });
      this.$nextTick(() => this.$refs.searchInput.focus());
      this.$store.commit("owner/SET_SEARCH_RESULTS", {});
      this.$store.commit("pet/SET_SEARCH_RESULTS", {});
      this.hasResults = false;
    },
    ownerPageChange(page) {
      this.ownerCurrentPage = page;
      this.search(page, this.petCurrentPage);
    },
    petPageChange(page) {
      this.petCurrentPage = page;
      this.search(this.ownerCurrentPage, page);
    },
    handleAddPet(pet) {
      this.selectedAddPet = pet;
      this.showAddPetDialog = true;
    },
    async handleConfirmAddPet() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        await this.$store.dispatch("pet/addPetToHospital", {
          data: { pet: this.selectedAddPet.id }
        });
        this.selectedAddPet = null;
        this.showAddPetDialog = false;
        this.search(this.ownerCurrentPage, this.petCurrentPage);
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorMsg");
      }
    },
    getQueryStringFormUrl() {
      if (this.$route.query.isExisting === "true") {
        this.isExisting = true;
      }

      if (this.$route.query.isExisting === "false") {
        this.isExisting = false;
      }

      if (this.$route.query.search) {
        this.searchText = this.$route.query.search;
      }

      if (this.$route.query.ownerCurrentPage) {
        this.ownerCurrentPage = parseInt(this.$route.query.ownerCurrentPage);
      }

      if (this.$route.query.search) {
        this.searchText = this.$route.query.search;
      }

      if (this.$route.query.petCurrentPage) {
        this.petCurrentPage = parseInt(this.$route.query.petCurrentPage);
      }

      this.search(this.ownerCurrentPage, this.petCurrentPage);
    }
  },
  async created() {
    this.$nextTick(() => this.$refs.searchInput.focus());
    this.$store.commit("owner/SET_SEARCH_RESULTS", {});
    this.$store.commit("pet/SET_SEARCH_RESULTS", {});
    this.getQueryStringFormUrl();
  }
};
</script>

<style lang="scss">
.pet-search-page {
  .section {
    margin: 20px 0;
  }
  .tabs {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    border-bottom: 0;
    &::-webkit-scrollbar {
      display: none;
    }

    .tab {
      margin-right: 5px;
      width: auto;
      white-space: nowrap;
    }

    .el-button {
      border: none;
      background-color: #ccc;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: 0;
    }

    .active {
      .el-button {
        background-color: #fff;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .search-section {
    .title {
      margin: 5px 0;
      padding: 0;
      font-size: 18px;
      text-align: center;
    }

    .search-choices {
      margin: 5px auto;
      text-align: center;
      width: 60%;

      input[type="text"] {
        text-align: center !important;
        font-weight: bold !important;
      }
    }
    .search-input-wrapper {
      margin: 0 auto;
      width: 60%;
    }

    .search-remark {
      display: block;
      margin-top: 5px;
      font-size: 0.9em;
      text-align: center;
      color: $light-blue;
    }
  }

  .petname-text {
    color: $light-blue;
  }

  .not-found {
    margin: 0 auto;
    max-width: 400px;
    padding: 20px 0;
    text-align: center;
  }

  .not-found-system {
    padding-top: 50px;
    padding-left: 20px;
  }

  .not-found-hospital {
    margin-top: -102px;
    max-width: 560px;
    margin-right: 147px;
  }
}
</style>
