<template>
  <div class="activity-item">
    <div class="thumbnail" v-show="showThumbnail">
      <img :src="activity.attachment" v-if="activity.attachment && activity.attachment !== ''">
    </div>
    <div class="info">
      <h4 class="title">{{ activity.name }}</h4>
      <template v-if="activity.products && activity.products.length > 0">
        <div class="subtitle">{{ activity.products[0].trade_name || '-' }}</div>
      </template>
      <template v-else>
        <div class="subtitle">-</div>
      </template>
      <div class="custom-row" v-if="activity.products && activity.products.length > 0">
        <label class="label">
          Lot No
          <span class="required-mark">*</span>
        </label>
        <div>
          <el-input
            type="text"
            size="mini"
            v-model="form.lot_number"
            @change="handleChange"
            class="lot-input"
          />
        </div>
      </div>
      <div class="custom-row">
        <label class="label">ราคา</label>
        <div>
          <el-input-number size="mini" v-model="form.price" @change="handleChange"></el-input-number>&nbsp;฿
        </div>
      </div>
      <div class="delete-btn" @click="$emit('delete', activity)">
        <i class="fa fa-times-circle"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["activity", "showThumbnail"],
  data() {
    return {
      form: {
        price: this.activity.price,
        lot_number: this.activity.lot_number || ""
      }
    }
  },
  methods: {
    handleChange() {
      let data = {
        ...this.activity,
        price: this.form.price
      }

      if (this.activity.products && this.activity.products.length > 0) {
        data["lot_number"] = this.form.lot_number
      }
      this.$emit("change", data)
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-item {
  position: relative;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
  display: flex;

  .title {
    padding: 0;
    margin: 5px 0 0 0;
    font-size: 14px;
  }
  .subtitle {
    padding: 0;
    margin: 0 0 5px 0;
    color: $gray;
    font-weight: normal;
  }

  .custom-row {
    margin-top: 10px;
    .label {
      display: inline-block;
      padding-bottom: 5px;
    }
  }

  .lot-input {
    width: 50%;
  }

  .delete-btn {
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: 10px;
    color: $gray;

    &:hover {
      color: $red;
    }
  }
  .thumbnail {
    display: flex;
    position: relative;
    overflow: hidden;
    justify-content: center;
    width: 80px; // ratio 4:3
    height: 60px;
    margin-right: 15px;
    text-align: center;

    > img {
      max-width: 80px;
      max-height: 60px;
      width: auto;
      height: auto;
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    flex: 1;
    vertical-align: top;
  }

  .delete-btn {
    position: absolute;
    right: 0px;
    top: 15px;
  }
}
</style>
