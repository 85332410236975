<template>
  <div>
    <div class="container" style="margin-top: 10px; background: #ffffff;">
      <div class="flex">
        <div class="row header-container flex-middle flex-center">
          <h3 style="color: #ffffff;">Blood Bag Label</h3>
        </div>
      </div>
      <at-card class="container" style="padding-left: 5px; padding-right: 5px;">
        <div class="row col-md-24">
          <div class="row col-md-8 flex-start">
            <label class="col-md-24">พิมพ์ฉลาก</label>
            <at-radio class="col-md-24 no-margin .button" style="height: 25px;" :label="bloodProductTypeEnum.FWB" v-model="donation.bloodCollectInfo.bloodType">
              <label style="font-size: 16px; font-weight: bold;">Fresh Whole Blood (FWB)</label>
            </at-radio>
            <!--<at-radio class="col-md-24 .button" style="height: 25px;" :label="bloodProductTypeEnum.SWB" v-model="donation.bloodCollectInfo.bloodType">
              <label style="font-size: 16px; font-weight: bold;">Stored Whole Blood (SWB)</label>
            </at-radio>
            <at-radio class="col-md-24 .button" style="height: 25px;" :label="bloodProductTypeEnum.PRBCS" v-model="donation.bloodCollectInfo.bloodType">
              <label style="font-size: 16px; font-weight: bold;">Packed RBCs (pRBCs)</label>
            </at-radio>
            <at-radio class="col-md-24 .button" style="height: 25px;" :label="bloodProductTypeEnum.PRP" v-model="donation.bloodCollectInfo.bloodType">
              <label style="font-size: 16px; font-weight: bold;">Platelet Rich Plasma (PRP)</label>
            </at-radio>
            <at-radio class="col-md-24 .button" style="height: 25px;" :label="bloodProductTypeEnum.FFP" v-model="donation.bloodCollectInfo.bloodType">
              <label style="font-size: 16px; font-weight: bold;">Fresh Frozen Plasma (FFP)</label>
            </at-radio>
            <at-radio class="col-md-24 .button" style="height: 25px;" :label="bloodProductTypeEnum.FP" v-model="donation.bloodCollectInfo.bloodType">
              <label style="font-size: 16px; font-weight: bold;">Fresh Plasma (FP)</label>
            </at-radio>-->
          </div>

          <div class="row col-md-4 flex-center">
               <label class="col-md-24">ปริมาตร</label>
            <div class="col-md-24" style="height: 35px; margin-top: 10px;">
              <at-input v-if="donation.bloodCollectInfo.bloodType == bloodProductTypeEnum.FWB" v-model="donation.bloodCollectInfo.quantity"></at-input>
            </div>

            <!--<div class="col-md-24" style="height: 35px; margin-top: 10px;">
              <at-input v-if="donation.bloodCollectInfo.bloodType == bloodProductTypeEnum.SWB" v-model="donation.bloodCollectInfo.quantity"></at-input>
            </div>

            <div class="col-md-24" style="height: 35px; margin-top: 10px;">
              <at-input v-if="donation.bloodCollectInfo.bloodType == bloodProductTypeEnum.PRBCS" v-model="donation.bloodCollectInfo.quantity"></at-input>
            </div>

            <div class="col-md-24" style="height: 35px; margin-top: 10px;">
              <at-input v-if="donation.bloodCollectInfo.bloodType == bloodProductTypeEnum.PRP" v-model="donation.bloodCollectInfo.quantity"></at-input>
            </div>

            <div class="col-md-24" style="height: 35px; margin-top: 10px;">
              <at-input v-if="donation.bloodCollectInfo.bloodType == bloodProductTypeEnum.FFP" v-model="donation.bloodCollectInfo.quantity"></at-input>
            </div>

            <div class="col-md-24" style="height: 35px; margin-top: 10px;">
              <at-input v-if="donation.bloodCollectInfo.bloodType == bloodProductTypeEnum.FP" v-model="donation.bloodCollectInfo.quantity"></at-input>
            </div>-->
          </div>

          <div class="row col-md-6 flex-center">
            <label class="col-md-24">วันหมดอายุ</label>
            <div class="col-md-24" style="height: 35px; margin-top: 10px;">
              <el-date-picker style="margin-left: 5px;" v-if="donation.bloodCollectInfo.bloodType == bloodProductTypeEnum.FWB" v-model="donation.bloodCollectInfo.expiryDate" type="date" placeholder="Select date"></el-date-picker>
            </div>
            <!--<div class="col-md-24" style="height: 35px; margin-top: 10px;">
              <el-date-picker style="margin-left: 5px;" v-if="donation.bloodCollectInfo.bloodType == bloodProductTypeEnum.SWB" v-model="donation.bloodCollectInfo.expiryDate" type="date" placeholder="Select date"></el-date-picker>
            </div>
            <div class="col-md-24" style="height: 35px; margin-top: 10px;">
              <el-date-picker style="margin-left: 5px;" v-if="donation.bloodCollectInfo.bloodType == bloodProductTypeEnum.PRBCS" v-model="donation.bloodCollectInfo.expiryDate" type="date" placeholder="Select date"></el-date-picker>
            </div>
            <div class="col-md-24" style="height: 35px; margin-top: 10px;">
              <el-date-picker style="margin-left: 5px;" v-if="donation.bloodCollectInfo.bloodType == bloodProductTypeEnum.PRP" v-model="donation.bloodCollectInfo.expiryDate" type="date" placeholder="Select date"></el-date-picker>
            </div>
            <div class="col-md-24" style="height: 35px; margin-top: 10px;">
              <el-date-picker style="margin-left: 5px;" v-if="donation.bloodCollectInfo.bloodType == bloodProductTypeEnum.FFP" v-model="donation.bloodCollectInfo.expiryDate" type="date" placeholder="Select date"></el-date-picker>
            </div>
            <div class="col-md-24" style="height: 35px; margin-top: 10px;">
              <el-date-picker style="margin-left: 5px;" v-if="donation.bloodCollectInfo.bloodType == bloodProductTypeEnum.FP" v-model="donation.bloodCollectInfo.expiryDate" type="date" placeholder="Select date"></el-date-picker>
            </div>-->
          </div>
          <div class="row col-md-3 flex-center">
            <label class="col-md-24">Download .pdf</label>
            <div class="col-md-24" style="height: 35px; margin-top: 10px;">
              <at-button v-if="donation.bloodCollectInfo.bloodType == bloodProductTypeEnum.FWB" ><i class="icon icon-download"></i></at-button>
            </div>
            <!--<div class="col-md-24" style="height: 35px; margin-top: 10px;">
              <at-button v-if="donation.bloodCollectInfo.bloodType == bloodProductTypeEnum.SWB" ><i class="icon icon-download"></i></at-button>
            </div>
            <div class="col-md-24" style="height: 35px; margin-top: 10px;">
              <at-button v-if="donation.bloodCollectInfo.bloodType == bloodProductTypeEnum.PRBCS" ><i class="icon icon-download"></i></at-button>
            </div>
            <div class="col-md-24" style="height: 35px; margin-top: 10px;">
              <at-button v-if="donation.bloodCollectInfo.bloodType == bloodProductTypeEnum.PRP" ><i class="icon icon-download"></i></at-button>
            </div>
            <div class="col-md-24" style="height: 35px; margin-top: 10px;">
              <at-button v-if="donation.bloodCollectInfo.bloodType == bloodProductTypeEnum.FFP" ><i class="icon icon-download"></i></at-button>
            </div>
            <div class="col-md-24" style="height: 35px; margin-top: 10px;">
              <at-button v-if="donation.bloodCollectInfo.bloodType == bloodProductTypeEnum.FP" ><i class="icon icon-download"></i></at-button>
            </div>-->
          </div>
          <div class="row col-md-3 flex-center">
            <label class="col-md-24">Print Label</label>
            <div class="col-md-24" style="height: 35px; margin-top: 10px;">
              <at-button @click="onPrintButtonClick" v-if="donation.bloodCollectInfo.bloodType == bloodProductTypeEnum.FWB" ><i class="icon icon-printer "></i></at-button>
            </div>
            <!--<div class="col-md-24" style="height: 35px; margin-top: 10px;">
              <at-button v-if="donation.bloodCollectInfo.bloodType == bloodProductTypeEnum.SWB" ><i class="icon icon-printer "></i></at-button>
            </div>
            <div class="col-md-24" style="height: 35px; margin-top: 10px;">
              <at-button v-if="donation.bloodCollectInfo.bloodType == bloodProductTypeEnum.PRBCS" ><i class="icon icon-printer "></i></at-button>
            </div>
            <div class="col-md-24" style="height: 35px; margin-top: 10px;">
              <at-button v-if="donation.bloodCollectInfo.bloodType == bloodProductTypeEnum.PRP" ><i class="icon icon-printer "></i></at-button>
            </div>
            <div class="col-md-24" style="height: 35px; margin-top: 10px;">
              <at-button v-if="donation.bloodCollectInfo.bloodType == bloodProductTypeEnum.FFP" ><i class="icon icon-printer "></i></at-button>
            </div>
            <div class="col-md-24" style="height: 35px; margin-top: 10px;">
              <at-button v-if="donation.bloodCollectInfo.bloodType == bloodProductTypeEnum.FP" ><i class="icon icon-printer "></i></at-button>
            </div>-->
         </div>
        </div>
      </at-card>
    </div>
    <bloodBagLabelPrintTemplate :bloodBagId='donation.bloodCollectInfo.id' :petName='donation.pet.name' :petID="donation.pet.id" :bloodGroup="donation.bloodCollectInfo.bloodGroup" :volumn="donation.bloodCollectInfo.quantity" :petType="petTypeEnums.DOG" :bloodProduct="donation.bloodCollectInfo.bloodType" :donationID="donation.bloodDonationId" :dateCollect="donation.bloodCollectInfo.collectDate" :dateExpire="donation.bloodCollectInfo.expiryDate" id="printTemplate" style="display:none;"></bloodBagLabelPrintTemplate>
  </div>
</template>

<script>

import PetTypeEnums from '@/constants/bloodbank/pet-type-enum'
import bloodProductTypeEnum from '@/constants/bloodbank/blood-product-type-enum.js'
import bloodBagLabelPrintTemplate from '@/components/bloodbank/BloodBagLabelTemplate'
import printJS from 'print-js'
export default {
  props: ['donation'],
  components: {
    bloodBagLabelPrintTemplate
  },
  data () {
    return {
      petTypeEnums: PetTypeEnums,
      bloodProductTypeEnum: bloodProductTypeEnum
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    onPrintButtonClick: function (event) {
      // eslint-disable-next-line no-console
      console.log('print click')
      let a = document.getElementById('printTemplate')
      a.style.display = 'inline-block'
      printJS({printable: 'printTemplate',
        type: 'html',
        targetStyles: ['*']
      })
      a.style.display = 'none'
    }
  }
}

</script>

<style>
  .at-radio.no-margin + .at-radio.no-margin {
    margin-left: 0px!important;
  }

  @media print{
     body {
    width: 9cm;
    height: 6cm;
  }
  }
</style>
