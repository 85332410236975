<template>
  <div class="navbar">
    <img
      v-if="currentUserType == 'hospital_admin'" 
      class="logo"
      src="@/assets/brand/v-remind-logo.png"
      alt="VRemind Logo">
      
    <div class="header">
      <router-link to="/">
        <slot name="logo">
          <img 
            v-if="currentUserType == 'hospital_admin'"
            src="@/assets/brand/v-remind-logo-text.png"
            alt="VRemind Logo">
          <img 
            v-else
            src="@/assets/brand/itaam-logo.png"
            alt="iTaam Logo">
        </slot>
        
      </router-link>
    </div>
  </div>
</template>

<script>
import { currentUserType } from '@/utils'

export default {
  data() {
    return {
      currentUserType: currentUserType()
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  width: 100%;
  background: #fff;
  text-align: center;
  position: relative;
  .logo {
    height: 40px;
    position: absolute;
    top: 6px;
    left: 6px;
  }
  .header {
    display: inline-block;
    margin: 6px 0 4px;
  }
}
a {
  text-decoration: none;
  color: $primary;
  line-height: 0.8;
  img {
    margin: 4px 0 0;
    height: 40px;
  }
}
</style>
