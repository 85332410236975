import * as vuexHelper from "../helpers/vuexHelper";
import { coreAPI } from "../../services/apis";
import * as endpoints from "../../services/endpoints";

const config = {
  default: {
    api: coreAPI
  },
  state: {
    profile: null,
    hospital: null,
    vets: []
  },
  asyncActions: [
    {
      name: "getSubdistrict",
      method: "get",
      url: endpoints.locationSubdistrict,
      onSuccess: (result) => {
        return result
      }
    },
  ]
};

export default vuexHelper.createStoreModule(config);
