export default {
  HOME: 'home',
  LOGIN: 'login',
  BLOOD_REQUEST: 'request',
  BLOOD_REQUEST_DASHBOARD: 'request-dashboard',
  BLOOD_REQUEST_HOSPITAL_DASHBOARD: 'request-hospital-dashboard',
  BLOOD_REQUEST_PROFILE: 'request-profile',
  BLOOD_REQUEST_PROFILE_RECIPIENT_PROFILE: 'request-recipient-profile',
  BLOOD_REQUEST_PROFILE_LAB_EXAM: 'request-lab-exam',
  BLOOD_REQUEST_PROFILE_PRODUCT_REQUEST: 'request-product-request',
  BLOOD_REQUEST_SUGGEST: 'request-suggest',
  BLOOD_REQUEST_CROSSMATCH: 'request-crossmatch',
  BLOOD_REQUEST_RESERVE: 'request-reserve',
  BLOOD_REQUEST_CONFIRMATION: 'request-confirmation',
  BLOOD_REQUEST_TRANSFER_RESULT: 'request-transfer-result',
  BLOOD_REQUEST_TIMELINE: 'request-timeline',

  BLOOD_REQUEST_BLOOD_SUGGEST: 'request-suggest',
  BLOOD_DONATION: 'BLOOD_DONATION',

  BLOOD_DONATE: 'donation',
  BLOOD_DONATE_DASHBOARD: 'donation-dashboard',
  BLOOD_DONATE_PROFILE: 'donation-profile',
  BLOOD_DONATE_PROFILE_PHYSICAL_EXAM: 'donation-profile-physical-exam',
  BLOOD_DONATE_PROFILE_LAB_EXAM: 'donation-profile-lab-exam',
  BLOOD_DONATE_PROFILE_DONOR_CARE: 'donation-profile-donor-care',
  BLOOD_DONATE_PROFILE_BLOOD_COLLECT: 'donation-profile-blood-collect',
  BLOOD_DONATE_TIMELINE: 'donation-timeline',

  BLOOD_STORAGE: 'storage',
  BLOOD_STORAGE_DASHBOARD: 'storage-dashboard',
  BLOOD_BAG_DETAIL: 'storage-blood-bag-detail',

  APPOINTMENT: 'appointment',
  PET: 'petlist',
  PET_DETAIL: 'pet-detail',
  REQUEST_PET_DETAIL: 'request-pet-detail',
  DONATION_PET_DETAIL: 'donation-pet-detail'
}
