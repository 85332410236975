<script>
export default {
  props: {
    id: {
      type: String,
      default: "",
    },
    total: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      visible: false,
    };
  },

  mounted() {
    this.visible = true;
  },

  methods: {
    handleClose() {
      this.$emit("close");
      this.visible = false;
    },

    handleClosed() {
      this.$destroy();
    },

    emit(value) {
      this.$emit("action", value);

      this.visible = false;
    },

    handleCloseButtonClick() {
      this.visible = false;
    },
  },

  render() {
    const buttons = (() => {
      const cancelButton = (
        <el-button
          class={[
            this.$style.button,
          ]}
          onClick={() => {
            this.visible = false;
          }}
        >
          Cancel 
        </el-button>
      );

      const confirmButton = (
        <el-button
          type="primary"
          class={[
            this.$style.button,
          ]}
          onClick={() => {
            this.emit("confirm");
          }}
        >
          Confirm
        </el-button>
      );

      return [
        cancelButton,
        confirmButton,
      ]
    })();

    const renderHeader = (() => {

      return <div 
        style={{ 
          margin: "0.5rem 0",
          fontWeight: "bold",
          color: "#020202",
          fontSize: "20px"
        }}>ยืนยัน กลับบ้าน</div>
    });

    const renderTotal = () => (
      <div style={{ marginTop: "1rem" }}>
        <div style={{ marginBottom: "0.5rem" }}>
          <div style={{ textAlign: "center", padding: "0 16px", fontWeight: "bold", color: "black" }}>
            รายการ IPD : {this.id} <br />ยอดค้างชำระ : 
            {this.total.toLocaleString("th-TH", {
              style: "currency",
              currency: "THB",
            })}
          </div>
        </div>
        <p style={{ fontSize: "14px", padding: "0 12px" }}>
          *ใช้ตอนที่สัตว์เลี้ยง กลับบ้านและยังไม่ชำระเงิน
          เมื่อยืนยันกลับบ้านแล้ว จะไม่แสดงในหน้า IPD ที่หน้า HOME
          แต่ยังสามารถค้นหาจาก IPD list ได้อยู่ และจะไม่สามารถยกเลิกได้ 
          แต่ยังสามารถชำระเงินได้อยู่
        </p>
      </div>
    );

    return (
      <el-dialog
        center
        visible={this.visible}
        onClose={this.handleClose}
        onClosed={this.handleClosed}
        class={this.$style.root}
      >
        <template slot="title">{renderHeader()}</template>

        {renderTotal()}

        <el-row
          slot="footer"
          type="flex"
          gutter={16}
          justify="center"
          style={{ flexWrap: "wrap" }}
        >
          {buttons.filter(vnode => vnode != undefined).map((vnode) => (
            <el-col span={8}>{vnode}</el-col>
          ))}
        </el-row>
      </el-dialog>

    );
  },
};
</script>

<style lang="scss" module>
.root {
  font-family: Sarabun;
}

.button {
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 1.2rem;
  padding: 9px;
}
</style>