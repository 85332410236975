<template>
  <canvas id="chart-canvas"></canvas>
</template>

<script>
  import Chart from 'chart.js'

  export default {
    name: "BloodInventoryChart",
    props: ['summaryTableData'],
    data () {
      return {
        chart: null,
      }
    },
    methods: {
      updateChart: function () {
        var ctx = document.getElementById('chart-canvas')

        var availableBags = {
          label: 'เลือดที่ใช้ได้ (ถุง)',
          data: [
            this.summaryTableData.FWB.available,
            this.summaryTableData.SWB.available,
            this.summaryTableData.PRBCS.available,
            this.summaryTableData.PRP.available,
            this.summaryTableData.FFP.available,
            this.summaryTableData.FP.available
          ],
          backgroundColor: [
            'rgba(166, 166, 166, 1)',
            'rgba(166, 166, 166, 1)',
            'rgba(166, 166, 166, 1)',
            'rgba(166, 166, 166, 1)',
            'rgba(166, 166, 166, 1)',
            'rgba(166, 166, 166, 1)'
          ]
        }

        var reserveBags = {
          label: 'เลือดที่จองใช้ (ถุง)',
          data: [
            this.summaryTableData.FWB.reserve,
            this.summaryTableData.SWB.reserve,
            this.summaryTableData.PRBCS.reserve,
            this.summaryTableData.PRP.reserve,
            this.summaryTableData.FFP.reserve,
            this.summaryTableData.FP.reserve
          ],
          backgroundColor: [
            'rgba(255, 79, 74, 1)',
            'rgba(255, 79, 74, 1)',
            'rgba(255, 79, 74, 1)',
            'rgba(255, 79, 74, 1)',
            'rgba(255, 79, 74, 1)',
            'rgba(255, 79, 74, 1)'
          ]
        }

        if (ctx != null) {
          if (this.chart == null) {
            this.chart = new Chart(ctx, {
              type: 'bar',
              data: {
                labels: ['FWB', 'SWB', 'pRBCs', 'PRP', 'FFP', 'FP'],
                datasets: [ availableBags, reserveBags ]
              },
              options: {
                scales: {
                  yAxes: [{
                    ticks: {
                      beginAtZero: true,
                      precision: 0
                    },
                    stacked: true
                  }],
                  xAxes: [{
                    stacked: true,
                    barPercentage: 0.4
                  }]
                }
              }
            })
          } else {
            this.chart.data.datasets[0].data = availableBags.data
            this.chart.data.datasets[1].data = reserveBags.data
            this.chart.update()
          }
        }
      },
    },
    mounted() {
      // Update chart
      // this.updateChart()
    },
    watch: {
      summaryTableData: 'updateChart'
    }
  }
</script>

<style scoped>

</style>
