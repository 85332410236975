export const hyphen = (value) => {
  return value ? value : '-'
}

export const fullname = (value) => {
  if (value && value.first_name) {
    return `${value.first_name} ${value.last_name}`
  } else if (value && value.firstname) {
    return `${value.firstname} ${value.lastname}`
  }

  return 'ไม่ระบุ'
}

export const getLatestDataFromArray = (items, field) => {
  if (items && items.length > 0) {
    const lastItem = items[items.length - 1];
    return lastItem[field];
  }
  return "";
};