import { render, staticRenderFns } from "./MedicalSelectPetPage.vue?vue&type=template&id=44fcd34f&scoped=true&"
import script from "./MedicalSelectPetPage.vue?vue&type=script&lang=js&"
export * from "./MedicalSelectPetPage.vue?vue&type=script&lang=js&"
import style0 from "./MedicalSelectPetPage.vue?vue&type=style&index=0&id=44fcd34f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44fcd34f",
  null
  
)

export default component.exports