<template>
  <div>
    <table class="vet-table">
      <thead>
        <tr>
          <th class="no">
            No.
          </th>
          <th class="username">
            Username
          </th>
          <th class="role">
            Role
          </th>
          <th class="tel">
            Tel
          </th>
          <th class="email">
            Email
          </th>
          <th class="license">
            เลขที่ใบอนุญาติ
          </th>
          <th class="status">
            Status
          </th>
          <th class="action">
            Edit
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(staff, index) in staffs" :key="staff.id">
          <td class="no">
            <div>{{ index + 1 }}</div>
          </td>
          <td class="username">
            <!-- <div>{{ `${staff.title}${staff.first_name} ${staff.last_name}` }}</div> -->
            <div v-if="staff.is_first_user">{{ staff.username }}</div>
            <div v-else>{{ `${staff.staff_username_prefix}${staff.username}` }}</div>
            <div class="hint" v-if="!staff.is_from_plan_active">ไม่สามารถเข้าใช้งานได้</div>
          </td>
          <td class="role">
            <div v-if="staff.role">{{ staff.role.name }}</div>
            <div v-else>-</div>
          </td>
          <td class="tel">
            <div>{{ staff.phone }}</div>
          </td>
          <td class="email">
            <div>{{ staff.email }}</div>
          </td>
          <td class="license">
            <div>{{ staff.license_number }}</div>
          </td>
          <td class="status">
            <div>
              <el-switch 
                class="default-style"
                v-model="staff.is_active"
                disabled
              ></el-switch>
            </div>
          </td>
          <td class="action">
            <div>
              <el-button
              size="mini"
              @click="$router.push({ name: 'staff-detail', params: { id: staff.id } })"
              >
                Edit
                <i class="el-icon-arrow-right el-icon-right"></i>
              </el-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    staffs: {
      type: [Object, Array],
      required: true
    },
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.el-button {
  width: 91px;
  height: 35px;
  border-radius: 5px;
  background-color: #02b0e1;
  color: white;

  font-family: "Kanit";
}

.vet-table {
  width: 100%;
  border-collapse: collapse;
  font-family: "Kanit", sans-serif;

  thead {
    tr {
      height: 54px;
      border-radius: 4px;
      border: #e6eaee 1px solid;

      th {
        padding: 5px;
        font-weight: normal;
        color: rgba(0, 0, 0, 0.48);
        background-color: #f5f8fa;
        text-align: center;
        font-size: 14px;
        font-family: "Kanit", sans-serif;

        &.no, &.status {
          width: 64px;
        }

        &.username {
          width: 150px;
          text-align: left;
        }

        &.role {
          width: 50px;
          text-align: left;
        }

        &.license {
          width: 160px;
        }

        &.action {
          width: 100px;
        }
      }
    }
  }

  tbody {
    tr {
      height: 56px;
      border-bottom: 1px #eee solid;
      font-family: "Kanit", sans-serif;
      // -webkit-box-shadow:0px 1px 1px #eee;
      // -moz-box-shadow:0px 1px 1px #eee;
      // box-shadow:0px 1px 1px #eee;

      td {
        position: relative;
        padding: 5px;
        text-align: center;
        font-size: 14px;
        font-family: "Kanit", sans-serif;

        .subtitle {
          color: #666;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &.username {
          color: #2198f1;
          text-align: left;

          .hint {
            color: #d0021b;
            font-size: 11px;
          }
        }

        &.role {
          text-align: left;
        }
      }
    }
  }
}

.pagination {
  margin-top: 20px;
  text-align: right;
}
</style>


