<template>
  <div class="appt-detail-pdf">
    <ApptDetailPDF :appt="appt"/>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import ApptDetailPDF from "@/components/appointment/ApptDetailPDF"

export default {
  components: {
    ApptDetailPDF
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      appt: state => state.appointment.selected
    }),
    ...mapGetters({
      apptState: "appointment/apptState"
    })
  },
  methods: {
    async fetchData(id) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        await this.$store.dispatch("appointment/getAppointment", {
          params: { id: id }
        })
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg")
      }
    }
  },
  async created() {
    const id = this.$route.params.id
    this.fetchData(id)
  },
  watch: {
    "$route.params.id"(newId) {
      this.fetchData(newId) // if id changes, will refectch data
    }
  }
}
</script>

<style lang="scss" scoped>
.appt-detail-pdf {
  margin: 0 auto;
  width: 620px;
}
</style>
