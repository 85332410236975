<template>
  <div class="pet-general-info-form">
    <div :class="standalone?`form-block standalone`:`form-block`">
      <div class="header-block" v-if="standalone">
        <div class="title">
          {{ $route.params.id ? 'Edit' : 'Create' }} Pet Profile
        </div>
        <div class="lead">
          General Info<br/>
          (1/2)
        </div>
      </div>

      <div class="upload-image" v-if="standalone">
        <div class="image-container">
          <img v-if="form.image_url" class="full-logo" :src="form.image_url" alt="" style="border-radius:60px">
          <img v-else src="@/assets/icons/icon-camera-plus.png">
          <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
        </div>
        <span>Profile Picture *</span>
      </div>

      <el-form ref="form" :model="form">
        <el-form-item>
          <span class="form-label">ชื่อสัตว์เลี้ยง <span class="red">*</span></span>
          <el-input
            type="text"
            v-validate="'required'"
            name="name"
            v-model="form.name"></el-input>
          <span class="error">{{ errors.first('name') }}</span>
        </el-form-item>

        <el-form-item>
          <span class="form-label">ชนิด <span class="red">*</span></span>
          <div class="type-select">
            <el-button v-on:click="setType('DOG')" :type="(form.type_key == 'DOG') ? 'primary' : ''"><i class="fas fa-dog"></i> Dog</el-button>
            <el-button v-on:click="setType('CAT')" :type="(form.type_key == 'CAT') ? 'primary' : ''"><i class="fas fa-cat"></i> Cat</el-button>
          </div>
          <el-select
            v-on:change="onSetType"
            v-model="form.type_select" 
            placeholder="อื่นๆ กรุณาเลือก"
            style="width: 100%; margin-top: 8px">
            <el-option
              v-for="item in otherTypes"
              :key="item.id"
              :label="item.name"
              :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <span class="form-label">พันธุ์ <span class="red">*</span></span>
          <el-select 
            v-model="form.breed"
            :filterable="true"
            autocomplete="on"
            placeholder="กรุณาเลือก"
            style="width: 100%;" :disabled="form.type_key==null">
            <el-option
              v-for="item in breeds"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <span class="form-label">เพศ <span class="red">*</span></span>
          <div class="type-select">
            <el-button v-on:click="form.gender = 1" :type="(form.gender == 1) ? 'primary' : ''"><i class="fas fa-mars"></i> Male</el-button>
            <el-button v-on:click="form.gender = 0" :type="(form.gender == 0) ? 'primary' : ''"><i class="fas fa-venus"></i> Female</el-button>
          </div>
        </el-form-item>

        <el-form-item>
          <span class="form-label">วันเกิด <span class="red">*</span></span>
          <el-date-picker
            v-on:change="onSetBirthday"
            v-model="form.birthday"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="กรุณาเลือกวันเกิด"
            style="width: 100%">
          </el-date-picker>
        </el-form-item>

        <el-form-item>
          <span class="form-label">อายุปัจจุบัน <span class="red">*</span></span>
          <div class="age-select">
            <span class="form-label">ปี</span>
            <el-select v-model="form.age_year" @change="yearChange(form.age_year)" placeholder="ปี">
              <el-option
                v-for="i in 21"
                :key="i-1"
                :label="i-1"
                :value="i-1">
              </el-option>
            </el-select>
          </div>
          <div class="age-select">
            <span class="form-label">เดือน</span>
            <el-select v-model="form.age_month" @change="monthChange(form.age_month)" placeholder="เดือน">
              <el-option
                v-for="i in 13"
                :key="i-1"
                :label="i-1"
                :value="i-1">
              </el-option>
            </el-select>
          </div>
        </el-form-item>

        <slot name="actions"></slot>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  props: {
    form: {
      default: null,
      type: Object,
    },
    standalone: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapState({
      types: state => state.pet.types,
      otherTypes: state => state.pet.types.filter(function(type) {
        return ["DOG", "CAT"].indexOf(type.key) == -1
      }),
      breeds: state => state.pet.breeds,
    })
  },
  methods: {
    convertDate(d) {
      function pad(s) { return (s < 10) ? '0' + s : s; }
      return [d.getFullYear(), pad(d.getMonth()+1), pad(d.getDate())].join('-');
    },
    yearChange(year) {
      const now = new Date(Date.now());
      if (this.form.birthday == null) this.form.birthday = this.convertDate(now);
      let birth = new Date(this.form.birthday);
      birth.setYear(now.getFullYear());
      birth.setMonth(now.getMonth()-(year*12)-this.form.age_month);
      this.form.birthday = this.convertDate(birth);
    },
    monthChange(month) {
      const now = new Date(Date.now());
      if (this.form.birthday == null) this.form.birthday = this.convertDate(now);
      let birth = new Date(this.form.birthday);
      birth.setYear(now.getFullYear()-this.form.age_year);
      birth.setMonth(now.getMonth()-(month));
      this.form.birthday = this.convertDate(birth);
    },
    onSetBirthday() {
      if (this.form.birthday == null) return;
      var diff = new Date(Date.now()-new Date(this.form.birthday));
      if (diff >= 0) {
        this.form.age_year = diff.getYear()-70;
        this.form.age_month = diff.getMonth();
      } else {
        this.form.birthday = null;
        this.$notify({
          message: `วันเกิดไม่ถูกต้อง!`,
          type: "warning"
        })
      }
    },
    async setType (type) {
      if (['DOG', 'CAT'].indexOf(type) > -1)
        this.form.type_select = null;

      this.types.forEach(t => {
        if (t.key == type) this.form.type = t.id;
      });

      this.form.type_key = type;
      this.form.breed = null;

      await this.$store.dispatch('ui/setIsFullScreenLoading', true)
      await this.$store.dispatch('pet/listBreeds', {
        query: {
          pet_type__key: type,
        }
      });
      await this.$store.dispatch('ui/setIsFullScreenLoading', false)
    },
    onSetType () {
      if (this.form.type_select != null)
        this.setType(this.form.type_select);
    },
    async handleFileUpload() {
      this.form.image = this.$refs.file.files[0];
      if (!this.form.image) return

      this.form.image_url = URL.createObjectURL(this.form.image);
    }
  },
  watch: {
    form: function () {
      this.onSetBirthday();
    }
  },
  async mounted() {
    await this.$store.dispatch('ui/setIsFullScreenLoading', true);
    await this.$store.dispatch('pet/listTypes');
    await this.$store.dispatch('ui/setIsFullScreenLoading', false)
  }
}
</script>

<style lang="scss" scoped>
.form-block {
  &.standalone {
    @extend %block-container;
  }
  @extend %form-style;
  .form-submit-btn {
    width: 160px;
  }
  .row {
    width: 100%;
  }
  .age-select {
    display: inline-block;
    width: 48%;

    &:first-child {
      float: left;
    }

    &:last-child {
      float: right;
    }
  }
  .gender-select, .type-select {
    display: block;
    // text-align: center;cc
    .el-radio {
      padding: 12px 25px 0 20px;
      width: 140px;
      
    }
    .el-button, .el-input {
      // padding: 12px 25px 0 20px;
      width: 48%;
      // box-sizing: border-box;

      i {
        float: left;
      }

      &:first-child {
        float: left;
        clear: left;
      }

      &:last-child {
        float: right;
        clear: right;
      }
    }

    &:last-child {
      margin-top: 8px;
    }
  }
  .upload-image {
    text-align: center;
    .image-container {
      position: relative;
      height: 120px;
      width: 120px;
      border-radius: 60px;
      background-color: #d8d8d8;
      display: block;
      margin: 8px auto;             
      img {
        display: block;
        width: 70px;
        height: 70px;
        padding: 25px;
        object-fit: contain;
        &.full-logo {
          width: 120px;
          height: 120px;
          padding: 0px;
          object-fit: cover;
        }
      }
      input {
        height: 120px;
        width: 120px;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
      }
    }
  }
}
</style>

<style lang="scss">
.el-popper {
  @include mobile {
    width: 100%;
    left: 0px !important;
  }
}

.el-select-dropdown__item span {
  text-overflow: ellipsis;
}
</style>
