import * as vuexHelper from "../helpers/vuexHelper";

import { coreAPI } from "../../services/apis";
import * as endpoints from "../../services/endpoints";
import { appointmentStatus } from "../../constants"

const config = {
  default: {
    api: coreAPI
  },
  state: {
    all: null,
    selected: null,
    overview: null,
    today: null,
    calendar: null
  },
  getters: {
    apptState: (state) => {
      let statusState = {
        isCancel: false,
        isConfirm: false,
        isMarkDone: false,
        isPostpone: false
      }
      if (state.selected) {
        switch (state.selected.status) {
          case appointmentStatus.NO_RESPOND:
            return {
              ...status,
              isCancel: true,
              isConfirm: true,
              isMarkDone: true,
              isPostpone: true
            }
          case appointmentStatus.NO_SHOW:
            return {
              ...status,
              isCancel: true,
              isMarkDone: true,
            }
          case appointmentStatus.CONFIRM:
            return {
              ...status,
              isCancel: true,
              isMarkDone: true,
              isPostpone: true
            }
          case appointmentStatus.REQUEST_POSTPONE:
            return {
              ...status,
              isCancel: true,
              isConfirm: true,
              isMarkDone: true,
              isPostpone: true
            }
          default:
            return statusState
        }
      }
      return statusState
    }
  },
  asyncActions: [
    {
      name: "getCalendar",
      method: "get",
      url: `${endpoints.appointments}calendar/`,
      setState: "calendar"
    },
    {
      name: "listAppointments",
      method: "get",
      url: `${endpoints.appointments}appointments/`,
      setState: "all"
    },
    {
      name: "getAppointment",
      method: "get",
      url: ({ id }) => `${endpoints.appointments}appointments/${id}/`,
      setState: "selected",
      before(payload, { commit }) {
        commit('SET_SELECTED', null)
      }
    },
    {
      name: "createAppointment",
      method: "post",
      url: `${endpoints.appointments}appointments/`,
    },
    {
      name: "updateAppointment",
      method: "patch",
      url: ({ id }) => `${endpoints.appointments}appointments/${id}/`,
    },
    {
      name: 'upload',
      method: 'post',
      url: `${endpoints.appointments}attachments/`
    },
    {
      name: "getOverview",
      method: "get",
      url:  `${endpoints.appointments}overview/`,
      setState: "overview"
    },
    {
      name: "getTodayAppointments",
      method: "get",
      url:  `${endpoints.appointments}today/`,
      setState: "today"
    },
    {
      name: "getReminderTemplate",
      method: "get",
      url:  `${endpoints.appointments}reminder/template/`,
    },
    {
      name: "getCreditCalulate",
      method: "post",
      url:  `${endpoints.appointments}credit/calculate/`,
    },
    {
      name: "addFollowUpNote",
      method: "post",
      url: ({ id }) => `${endpoints.appointments}appointments/${id}/follow_up_note/`,
    }
  ]
};

export default vuexHelper.createStoreModule(config);
