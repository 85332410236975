<template>
    <el-card style="margin-bottom: 12px;">
        <div class="contain">
            <el-row>
                <el-col class="text-left title" :sm="24" :md="12">{{ title }}</el-col>
                <el-col class="text-right small-text date" :sm="24" :md="12">{{ date | toThaiDate }}</el-col>
            </el-row>
            <el-row>
                <el-col class="text-left small-text" :md="12">product : {{ product }}</el-col>
                <el-col v-if="lot" class="text-right small-text gray" :sm="24" :md="12">Lot : {{ lot }}</el-col>
            </el-row>
            <hr>
            <el-row>
                <el-col class="text-left small-text gray" :sm="24" :md="24">Veterinary : {{ vet }}</el-col>
            </el-row>
            <el-row>
                <el-col class="text-left small-text gray" :sm="24" :md="24">hospital : {{ hospital }}</el-col>
            </el-row>
        </div>
    </el-card>
</template>

<script>
export default {
  props: ["title", "date", "product", "lot", "vet", "hospital"]
};
</script>

<style lang="scss" scoped>
.contain {
  padding: 12px;
}
.title {
    font-size: 18px;
    font-weight: bold;
    color: #156f88;
    margin: 0;
}
.date {
    color: #156f88;
    font-size: 14px !important;
}
.small-text {
    font-size: 12px;
    font-family: "Roboto","Lucida Grande","DejaVu Sans","Bitstream Vera Sans",Verdana,Arial,sans-serif;
}
.gray {
    color: #8e8e93;
}
.text-right {
    @media(max-width: 768px) {
        text-align: left;
    }
}
</style>