<template>
  <canvas id="petOwner_socialConnect_chart" height="100px"></canvas>
</template>

<script>
import { barChartGenerator, barChartDecorationDataGenerator } from '../../utils/report'

export default {
  name: "PetOwnerSocialConnectChart",
  props: ['petOwners'],
  methods: {
    generateDataSet: function () {
      let dataGenerated = {};
      dataGenerated.data = [
        this.petOwners.social_connect.none,
        this.petOwners.social_connect.fb,
        this.petOwners.social_connect.line,
        this.petOwners.social_connect.web,
      ];
      dataGenerated = barChartDecorationDataGenerator(dataGenerated);
      dataGenerated.label = ['Not Connect', 'FB Messenger', 'Line', 'Web'];
      return dataGenerated;
    },
  },
  watch: {
    'petOwners.social_connect': function () {
      if(this.petOwners.social_connect)
        barChartGenerator('petOwner_socialConnect_chart', this.generateDataSet())
    },
  },
  mounted() {
    if(this.petOwners.social_connect)
      barChartGenerator('petOwner_socialConnect_chart', this.generateDataSet())
  },
}
</script>