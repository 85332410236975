var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"medical-table-container"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"total"},[_vm._v(_vm._s(_vm.resultTitle))])]),_c('table',{staticClass:"medical-table"},[_vm._m(0),_c('tbody',_vm._l((_vm.medicals.results),function(medical){return _c('tr',{key:medical.id},[_c('td',[_c('div',{class:{ tag: true, 'tag-vertical': true, [`${_vm.currentStatus(medical)['class']}`]: true}},[_vm._v(_vm._s(_vm.currentStatus(medical)['name']))])]),_c('td',[_c('div',[_vm._v(_vm._s(_vm._f("toThaiDate")(medical.arrived)))]),_c('div',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm._f("toHHMM")(medical.arrived)))])]),_c('td',[(medical.pet)?[(medical.pet.rank && medical.pet.rank <= 3)?_c('img',{style:({ 
                display: "inline-block",
                height: "2em",
                marginRight: "6px",
                verticalAlign: "super"
              }),attrs:{"src":_vm.getStatusIcon(medical.pet.rank),"alt":"Status icon"}}):_vm._e(),_c('div',{staticClass:"thumbnail",class:{ blankLeft: medical.pet.rank && medical.pet.rank > 3 }},[(medical.pet.image && medical.pet.image.medium)?_c('img',{attrs:{"src":medical.pet.image.medium}}):_vm._e()]),_c('div',{staticClass:"info"},[_c('router-link',{attrs:{"to":{ name:'hospitalPetProfile', params: { id: medical.pethospital.id }}}},[_vm._v(_vm._s(medical.pet.name))]),_c('div',{staticClass:"subtitle"},[_vm._v(_vm._s(medical.pet.type.name))])],1)]:_vm._e()],2),_c('td',[_c('el-row',{attrs:{"gutter":10}},[_c('el-col',{attrs:{"span":12}},[(medical.status_tags.receipt_status)?_c('el-tag',{class:`${_vm.getReceiptStattusTag(medical.status_tags.receipt_status)['class']}`,style:({width: '100%', textAlign: 'center'}),attrs:{"size":"small","effect":"plain"}},[_vm._v(" "+_vm._s(_vm.getReceiptStattusTag(medical.status_tags.receipt_status)['name'])+" ")]):_vm._e()],1),_c('el-col',{attrs:{"span":12}},[(medical.status_tags.stock_status 
                  && medical.status_tags.stock_status !== 'stock_not_update')?_c('el-tag',{class:`${_vm.getStockStattusTag(medical.status_tags.stock_status)['class']}`,style:({width: '100%', textAlign: 'center'}),attrs:{"size":"small","effect":"plain"}},[_vm._v(" "+_vm._s(_vm.getStockStattusTag(medical.status_tags.stock_status)['name'])+" ")]):_vm._e()],1)],1)],1),_c('td',[_c('div',[_vm._v(_vm._s(medical.final_diagnosis))])]),_c('td',[_c('div',[_vm._v(_vm._s(_vm._f("currency")(medical.revenue)))])]),_c('td',{staticClass:"action"},[_c('el-button',{staticStyle:{"width":"125px"},attrs:{"size":"medium","type":(medical.progress_status === 'in_progress' && 'primary') || ''},on:{"click":function($event){return _vm.$router.push({ name: 'medicals-edit-detail', params: { id: medical.id }})}}},[_vm._v(" "+_vm._s((medical.progress_status === 'in_progress' && '+ Record') || 'See Detail')+" "),_c('i',{staticClass:"el-icon-arrow-right el-icon-right"})])],1)])}),0)]),(_vm.total === 0)?_c('div',{staticClass:"not-found"},[_c('img',{staticClass:"responsive-img",attrs:{"src":require("@/assets/images/no-result/medical-record-no-result.png")}})]):_vm._e(),_c('el-row',{staticClass:"pagination",attrs:{"type":"flex","justify":"end"}},[_c('el-col',{staticClass:"text-left",attrs:{"md":4}},[(_vm.canExport)?_c('button',{staticClass:"btn btn-small btn-status-cancel",on:{"click":() => _vm.$emit('export-data')}},[_vm._v(" Export Data ")]):_vm._e()]),_c('el-col',{staticClass:"text-right",attrs:{"md":20}},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","current-page":_vm.currentPage,"page-size":_vm.pageSize,"total":_vm.total},on:{"current-change":(page) => _vm.$emit('current-change', page)}})],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"status"},[_vm._v(" ")]),_c('th',{staticClass:"date-visit"},[_vm._v(" Date Visit "),_c('i',{staticClass:"el-icon-arrow-down el-icon-right"})]),_c('th',{staticClass:"pet"},[_vm._v(" Pet "),_c('i',{staticClass:"el-icon-arrow-down el-icon-right"})]),_c('th',{staticClass:"status-tag"},[_vm._v(" Status ")]),_c('th',{staticClass:"final-diagnosis"},[_vm._v(" Final Diagnosis "),_c('i',{staticClass:"el-icon-arrow-down el-icon-right"})]),_c('th',{staticClass:"revenue"},[_vm._v(" Revenue "),_c('i',{staticClass:"el-icon-arrow-down el-icon-right"})]),_c('th',{staticClass:"action"},[_vm._v(" ")])])])
}]

export { render, staticRenderFns }