<template>
  <div class="insurance-item">
    <div class="header-title">ข้อมูลกรมธรรม์</div>
    <el-card>
        <div class="contain">
          <div class="font-black font-bold"
            style="font-size: 16px;">
            เลขที่กรมธรรม์  {{ item.insurance_number | 'กำลังรอเลขที่กรมธรรม์'}}
          </div>
          <div class="font-black font-bold font-medium mt-10">
            แผนความคุ้มครอง  {{ item.plan.name }}
          </div>
          <div class="font-black font-bold font-medium">
            วันที่เริ่ม  {{ item.start_date | toThaiDate }}
          </div>
          <div class="font-black font-bold font-medium">
            วันสิ้นสุด  {{ item.end_date | toThaiDate }}
          </div>
          <div class="font-gray font-small mt-14">
            บริษัท :  {{ item.plan.company.name }}
          </div>
          <hr class="mt-14" style="border: solid 1px #979797;"/>
          <div class="font-black font-bold font-large">
            ความคุ้มครอง  {{ item.plan.name }}
          </div>

          <div class="mt-10">
            <div class="mb-10" v-for="(rule, index) in item.plan.items" :key="index">
              <div class="font-gray font-small">{{ rule.name }}</div>
              <div class="font-gray font-small">{{ rule.rule }}</div>
            </div>
          </div>

          <div v-if="item.insurance_document">
            <div class="font-black font-bold font-large mt-10">กรมธรรม์</div>
            <el-link class="pdf-container"
              :href="item.insurance_document"
              :underline="false"
              target="_blank"
              rel="norefferer">
              PDF
            </el-link>
          </div>

          <div v-if="item.insurance_claim_document" class="mb-10">
            <div class="font-black font-bold font-large mt-10">เอกสารเคลม</div>
            <el-link class="pdf-container"
              :href="item.insurance_claim_document"
              :underline="false"
              target="_blank"
              rel="norefferer">
              PDF
            </el-link>
          </div>
        </div>
    </el-card>
  </div>
</template>

<script>
export default {
  props: ["item"]
};
</script>

<style lang="scss" scoped>
.insurance-item {
  
  .contain {
    padding: 14px 18px;
  }

  .pdf-container {
    width: 91px;
    height: 87px;
    border-radius: 5px;
    background-color: #02b0e1;
    font-size: 18px;
    color: #000;
    font-weight: bold;
  }

  .header-title {
    color: #000;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 20px;
  }

  .font-small {
    font-size: 12px;
  }

  .font-medium {
    font-size: 14px;
  }

  .font-large {
    font-size: 18px;
  }

  .font-bold {
    font-weight: bold;
  }

  .font-black {
    color: #030202;
  }

  .font-gray {
    color: #7f8fa4;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mt-14 {
    margin-top: 14px;
  }
}
</style>