import { render, staticRenderFns } from "./AttachmentPreviewer.vue?vue&type=template&id=4ccca51a&scoped=true&"
import script from "./AttachmentPreviewer.vue?vue&type=script&lang=js&"
export * from "./AttachmentPreviewer.vue?vue&type=script&lang=js&"
import style0 from "./AttachmentPreviewer.vue?vue&type=style&index=0&id=4ccca51a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ccca51a",
  null
  
)

export default component.exports