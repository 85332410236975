<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      visible: false,
    };
  },

  mounted() {
    this.visible = true;
  },

  methods: {
    handleClose() {
      this.$emit("close");
      this.visible = false;
    },

    handleClosed() {
      this.$destroy();
    },

    emit(value) {
      this.$emit("action", value);

      this.visible = false;
    },

    handleCloseButtonClick() {
      this.visible = false;
    },
  },

  render() {
    const buttons = (() => {
      const cancelButton = (
        <el-button
          class={[this.$style.button,]}
          type="primary"
          onClick={() => {
            this.visible = false;
          }}
        >
          ปิด 
        </el-button>
      );

      return [
        cancelButton,
      ]
    })();

    const renderHeader = (() => {

      return <div 
        style={{ 
          margin: "0.5rem 0",
          fontWeight: "bold",
          color: "#020202",
          fontSize: "20px"
        }}>สินค้าในคลังสินค้าไม่พอให้ตัดออก</div>
    });

    const renderItems = () => (
      <table style={{ width: "100%" }}>
        <thead style={{ fontWeight: "12px", color: "#030202" }}>
          <tr>
            <th></th>
            <th style={{ width: "15%" }}>คงเหลือ</th>
            <th style={{ width: "15%" }}></th>
          </tr>
        </thead>
        <tbody style={{ fontWeight: "12px", color: "#737d89" }}>
          {this.items.map((item, index) => (
            <tr key={index}>
              <td>{`${index+1}. ${item.name}`}</td>
              <td>{item.remaining_amount}</td>
              <td>{item.unit}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );

    const renderBottom = (() => {

      return <div 
        style={{ 
          margin: "1rem 0",
          color: "#d0021b",
          fontSize: "14px"
        }}>*ตรวจสอบและเพิ่มจำนวนสินค้าให้มีจำนวนมากกว่าที่จะตัดออกจากคลังสินค้าก่อน</div>
    });

    return (
      <el-dialog
        center
        width="60%"
        visible={this.visible}
        onClose={this.handleClose}
        onClosed={this.handleClosed}
        class={this.$style.root}
      >
        <template slot="title">{renderHeader()}</template>

        {renderItems()}
        {renderBottom()}

        <el-row
          slot="footer"
          type="flex"
          gutter={16}
          justify="center"
          style={{ flexWrap: "wrap" }}
        >
          {buttons.filter(vnode => vnode != undefined).map((vnode) => (
            <el-col span={8}>{vnode}</el-col>
          ))}
        </el-row>
      </el-dialog>

    );
  },
};
</script>

<style lang="scss" module>
.root {
  font-family: Sarabun;
}

.button {
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 1.2rem;
  padding: 9px;
}
</style>