<template>
  <div>
    <!-- Sub Navbar -->
    <Subnavbar>
      <template slot="breadcrumb"></template>
      <template slot="title">Donate Blood</template>
      <template slot="actions" class="action-wrapper">
        <button
          class="btn btn-large btn-new-donation"
          @click="$router.push({ name: 'donationCreateSelect' })"
        >
          <img src="@/assets/bloodbank/new-donation.png" /> + New Donation
        </button>
        <button
          class="btn btn-large btn-new-pet"
          v-show="currentPlan.hospital_feature.can_create_owner_and_pet"
          @click="$router.push({ name: 'hospitalPetsSearch' })"
        >
          <i class="fa fa-plus-circle"></i>New Pet
        </button>
        <button
          class="btn btn-large btn-pet-profile"
          @click="$router.push({ name: 'hospitalPets' })"
        >
          <i class="fa fa-list-alt"></i>Pet Profile
        </button>
      </template>
    </Subnavbar>

    <!-- Donation Board -->
    <ContentBox class="donation-board">
      <h2 class="title">Donation Board</h2>
      <div class="inner">
        <div class="column today-tickets">
          <Widget
            :isHighlighten="true"
            :isLabelAtTop="true"
            label="Success/ All Donor Today"
            :widgetText="getAllSuccessAndCreatedTodayTickets"
            type="text"
          ></Widget>
        </div>
        <div class="column failed-donation">
          <Widget
            :isLabelAtTop="true"
            label="Failed Donation"
            :widgetText="getFailedDonation"
            type="text"
          ></Widget>
        </div>
        <div class="column wait-result">
          <Widget
            :isLabelAtTop="true"
            label="Wait for Lab Result"
            :widgetText="getWaitLabExamAndCreatedTodayTickets"
            type="text"
          ></Widget>
        </div>
      </div>
    </ContentBox>

    <!-- Search Box -->
    <ContentBox>
      <el-row :gutter="10">
        <el-col :md="3">
          <el-select
            v-model="selectedDonationState"
            placeholder="Donation"
            size="small"
            @change="handleSearchClick"
          >
            <el-option
              v-for="state in Object.keys(donationStates)"
              :key="state"
              :value="donationStates[state]"
              :label="donationStates[state]"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :md="12">
          <el-input
            placeholder="ชื่อสัตว์, เบอร์โทร, ชื่อเจ้าของ, อีเมล์, HN, Microchips, Pet ID, Donation ID"
            v-model="searchText"
            class="search-input"
            size="small"
            clearable
            @keyup.enter.native="handleSearchClick"
            @clear="clearSearch"
          >
            <el-button slot="append" icon="el-icon-search" @click="handleSearchClick"></el-button>
          </el-input>
        </el-col>
        <el-col :md="3">
          <el-select
            clearable
            placeholder="Pet Type"
            v-model="selectedPetType"
            size="small"
            @change="handleSearchClick"
            @clear="selectedBreedId=''"
          >
            <el-option value="CAT" label="CAT"></el-option>
            <el-option value="DOG" label="DOG"></el-option>
          </el-select>
        </el-col>
        <el-col :md="3">
          <el-select
            clearable
            placeholder="Breed"
            :disabled="selectedPetType === ''"
            v-model="selectedBreedId"
            size="small"
            @change="handleSearchClick"
            @clear="selectedBreedId=''"
          >
            <el-option
              v-for="breed in breeds"
              :key="breed.id"
              :value="breed.id"
              :label="breed.name"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :md="3">
          <el-select
            clearable
            placeholder="Sex / Sterilize"
            v-model="selectedSterilize"
            size="small"
            @change="handleSearchClick"
            @clear="selectedSterilize=''"
          >
            <el-option value="male" label="ผู้ / ไม่หมัน"></el-option>
            <el-option value="female" label="เมีย / ไม่หมัน"></el-option>
            <el-option value="male_sterilize" label="ผู้ / หมัน"></el-option>
            <el-option value="female_sterilize" label="เมีย / หมัน"></el-option>
          </el-select>
        </el-col>
      </el-row>
    </ContentBox>

    <!-- Pet List -->
    <ContentBox v-if="donations">
      <BloodDonationSearchResult
        :donations="filteredDonations"
        :current-page="currentPage"
        @current-change="pageChange"
      />
    </ContentBox>
  </div>
</template>

<script>
import _ from "lodash";
import { mapState, mapGetters } from "vuex";
import ContentBox from "@/components/container/ContentBox";
import Subnavbar from "@/components/navigation/Subnavbar";
import Widget from "@/components/bloodbank/Widget";
import BloodDonationSearchResult from "@/components/bloodbank/blood-donate/BloodDonationSearchResult";
import util from "@/utils/bloodbank";

const DONATION_STATE = {
  ALL: "All Donations",
  MEDICAL_RECORD: "Donor Submit",
  LABEXAM: "Lab Exam",
  DONOR_CARE: "Donor Care",
  BLOOD_COLLECT: "Done",
  REJECTED: "Cancelled"
};

export default {
  components: {
    ContentBox,
    Subnavbar,
    Widget,
    BloodDonationSearchResult
  },
  data() {
    return {
      donationStates: DONATION_STATE,
      searchText: "",
      currentPage: 1,
      selectedPetType: "",
      selectedBreedId: "",
      selectedSterilize: "",
      selectedDonationState: DONATION_STATE.ALL,
      filteredDonations: []
    };
  },
  computed: {
    ...mapState({
      overview: state => state.pet.overview,
      petResults: state => state.pet.all,
      breeds: state => state.pet.breeds,
      donations: state => state.bloodbank.donations,
      currentPlan: state => state.plan.currentPlan
    }),
    ...mapGetters({
      profile: "hospital/profile"
    }),
    getAllSuccessAndCreatedTodayTickets() {
      let counter = 0;
      let complete = 0;
      if (this.donations) {
        this.donations
          .filter(item => {
            return item.hospitalId === this.profile.id;
          })
          .forEach(item => {
            let isTicketCreatedToday =
              item.created == null
                ? false
                : util.isToday(new Date(item.created));

            if (isTicketCreatedToday) {
              counter++;
            }

            if (
              item.isDonorProfileSubmitted &&
              item.isLabExamSubmitted &&
              item.isPhysicalExamSubmitted &&
              item.isDonorCareSubmitted &&
              item.isBloodCollectSubmitted &&
              !item.isTicketRejected &&
              isTicketCreatedToday
            ) {
              complete++;
            }
          });
      }

      return `${complete} / ${counter}`;
    },
    getFailedDonation: function() {
      let counter = 0;
      if (this.donations) {
        this.donations
          .filter(item => {
            return item.hospitalId === this.profile.id;
          })
          .forEach(item => {
            if (
              item.isDonorCareSubmitted &&
              !item.donorCareInfo.canDonate &&
              item.donorCareInfo.canDonate !== ""
            ) {
              counter++;
            }
          });
      }

      return `${counter}`;
    },
    getWaitLabExamAndCreatedTodayTickets: function() {
      let counter = 0;
      if (this.donations) {
        this.donations
          .filter(item => {
            return item.hospitalId === this.profile.id;
          })
          .forEach(item => {
            if (
              item.isDonorProfileSubmitted &&
              item.isPhysicalExamSubmitted &&
              !item.isLabExamSubmitted &&
              !item.isDonorCareSubmitted &&
              !item.isBloodCollectSubmitted
            ) {
              counter++;
            }
          });
      }

      return `${counter}`;
    }
  },
  methods: {
    handleSearchClick() {
      this.search(1);
    },
    async search(currentPage) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);

        let query = { page: currentPage };
        if (this.searchText !== "") {
          query.search = this.searchText;
        }

        if (this.selectedPetType !== "") {
          query["pet__type__key"] = this.selectedPetType;
        }

        if (this.selectedBreedId !== "") {
          query["pet__breed"] = this.selectedBreedId;
        }

        if (this.selectedSterilize !== "") {
          switch (this.selectedSterilize) {
            case "male":
              query["pet__gender"] = 1;
              break;
            case "female":
              query["pet__gender"] = 0;
              break;
            case "male_sterilize":
              query["pet__gender"] = 1;
              query["pet__sterilize"] = true;
              break;
            case "female_sterilize":
              query["pet__gender"] = 0;
              query["pet__sterilize"] = false;
              break;
          }
        }
        // Just Fill Pet Breed in search box
        if (this.selectedPetType !== "") {
          await this.$store.dispatch("pet/listBreeds", {
            query: {
              pet_type__key: this.selectedPetType
            }
          });
        } else {
          this.$store.commit("pet/SET_BREEDS", null);
        }

        // List&Fill Donations
        if (this.donations.length < 1) {
          await this.$store.dispatch("bloodbank/listAndFillBloodDonations");
        }

        // Basic Filter
        let list = this.donations;
        list = list
          .filter(d => d.hospitalId === this.profile.id)
          .sort(function(a, b) {
            return new Date(b.modifiedDate) - new Date(a.modifiedDate);
          });

        // Filter by state
        let filterPropertyMap = null;
        let requiredFalseProps = [];
        switch (this.selectedDonationState) {
          case DONATION_STATE.MEDICAL_RECORD:
            filterPropertyMap = "isPhysicalExamSubmitted";
            requiredFalseProps = [
              "isLabExamSubmitted",
              "isDonorCareSubmitted",
              "isBloodCollectSubmitted",
              "isTicketRejected"
            ];
            break;

          case DONATION_STATE.LABEXAM:
            filterPropertyMap = "isLabExamSubmitted";
            requiredFalseProps = [
              "isDonorCareSubmitted",
              "isBloodCollectSubmitted",
              "isTicketRejected"
            ];
            break;

          case DONATION_STATE.DONOR_CARE:
            filterPropertyMap = "isDonorCareSubmitted";
            requiredFalseProps = [
              "isBloodCollectSubmitted",
              "isTicketRejected"
            ];
            break;

          case DONATION_STATE.BLOOD_COLLECT:
            filterPropertyMap = "isBloodCollectSubmitted";
            requiredFalseProps = ["isTicketRejected"];
            break;

          case DONATION_STATE.REJECTED:
            filterPropertyMap = "isTicketRejected";
            break;
        }

        list = list.filter(donation => {
          if (filterPropertyMap != null) {
            const isConditionMatch = requiredFalseProps.every(prop => {
              return donation[prop] === false;
            });
            if (isConditionMatch && donation[filterPropertyMap]) {
              return true;
            }
          } else {
            return true;
          }
        });

        // Filter by searchText
        if (this.searchText !== "") {
          let pattern = new RegExp(this.searchText, "i");
          list = list.filter(
            donation =>
              (donation.bloodDonationId &&
                pattern.test(donation.bloodDonationId.toString())) ||
              (donation.pet.name && pattern.test(donation.pet.name)) ||
              (donation.pet.hospitalNumber &&
                pattern.test(donation.pet.hospitalNumber)) ||
              (donation.pet.id && pattern.test(donation.pet.id)) ||
              (donation.pet.microchip &&
                pattern.test(donation.pet.microchip)) ||
              (donation.pet.owners &&
                donation.pet.owners.some(
                  o =>
                    (o.firstname &&
                      o.lastname &&
                      pattern.test(o.firstname + " " + o.lastname)) ||
                    (o.phone && pattern.test(o.phone)) ||
                    (o.phone2 && pattern.test(o.phone2)) ||
                    (o.phone3 && pattern.test(o.phone3)) ||
                    (o.email && pattern.test(o.email))
                ))
          );
        }

        // Filter by type/breed/gender/sterilize
        if (this.selectedPetType) {
          list = list.filter(
            donation =>
              donation.pet.type.toLowerCase() ===
              this.selectedPetType.toLowerCase()
          );
        }
        if (this.selectedBreedId) {
          list = list.filter(
            donation => donation.pet.breed.id === this.selectedBreedId
          );
        }
        if (this.selectedSterilize) {
          let [gender, sterilize] = this.selectedSterilize.split("_");
          list = list.filter(
            donation =>
              gender === donation.pet.sex &&
              (sterilize ? donation.pet.sterilize : !donation.pet.sterilize)
          );
        }

        // Final list
        this.filteredDonations = list;

        this.currentPage = currentPage;

        let urlQuery = _.omit(query, "pet__gender", "pet__sterilize");
        if (this.selectedSterilize !== "") {
          urlQuery.sterilize = this.selectedSterilize;
        }
        this.$router.replace({ query: urlQuery });
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        alert(error);
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorMsg");
      }
    },
    clearSearch() {
      // this.$store.commit("pet/SET_ALL", null)
      // this.$store.commit("pet/SET_BREEDS", null)
      this.searchText = "";
      this.currentPage = 1;
      this.$router.replace({
        search: "",
        currentPage: 1
      });
      this.search(1);
    },
    pageChange(page) {
      this.currentPage = page;
      // this.search(page)
    },
    getQueryStringFormUrl() {
      if (this.$route.query.search) {
        this.searchText = this.$route.query.search;
      }

      if (this.$route.query.page) {
        this.currentPage = parseInt(this.$route.query.page);
      }

      if (
        this.$route.query.pet__type__key &&
        this.$route.query.pet__type__key !== ""
      ) {
        this.selectedPetType = this.$route.query.pet__type__key;
      }

      if (this.$route.query.pet__breed && this.$route.query.pet__breed !== "") {
        this.selectedBreedId = parseInt(this.$route.query.pet__breed);
      }

      if (this.$route.query.sterilize && this.$route.query.sterilize !== "") {
        this.selectedSterilize = this.$route.query.sterilize;
      }

      // Bloodbank filter
      if (
        this.$route.query.donation__state &&
        this.$route.query.donation__state !== ""
      ) {
        this.selectedDonationState = this.$route.query.donation__state;
      }

      this.search(this.currentPage);
    }
  },
  async mounted() {
    this.getQueryStringFormUrl();
  }
};
</script>

<style lang="scss" scoped>
.donation-board {
  .title {
    padding: 0;
    margin: 0 0 5px 10px;
  }

  .inner {
    display: flex;
    flex-direction: row;

    .column {
      display: flex;
      flex-direction: row;
      flex: 1;
      padding: 5px 10px;
    }
  }
}
</style>
