import bloodProductTypeEnum from '@/constants/bloodbank/blood-product-type-enum'
// import bagType from '@/constants/bloodbank/bag-type'
import bagStatus from '@/constants/bloodbank/blood-storage-bag-status'
import moment from 'moment'
import _ from 'lodash'
import petTypeEnum from '@/constants/bloodbank/pet-type-enum'
import store from '@/store/index'
export default class {
  constructor (obj) {
    if (obj != null) {
      this.id = obj.id == null ? null : obj.id
      this.bloodGroup = obj.blood_group == null ? "-" : obj.blood_group
      this.pcv = obj.PCV == null ? null : Number(obj.PCV)
      this.collectDate = obj.collection_date == null ? new Date() : new Date(obj.collection_date)
      this.expiryDate = obj.expiry_date == null ? '' : new Date(obj.expiry_date)
      this.originalQuantity = obj.original_quantity == null ? obj.collected_quantity == null ? '' : Number(obj.collected_quantity) : Number(obj.original_quantity)
      this.quantity = obj.collected_quantity == null ? '' : Number(obj.collected_quantity)
      this.bagType = obj.bag_type == null ? '' : obj.bag_type
      this.collectorId = obj.collectorId == null ? '' : obj.collectorId
      this.bagStatus = obj.bag_status == null ? bagStatus.AVAILABLE : obj.bag_status
      this.bloodType = obj.bloodType == null ? bloodProductTypeEnum.FWB : obj.bloodType
      this.bloodDonationId = obj.blood_donation == null ? null : obj.blood_donation
      this.collector = obj.collector == null ? '' : obj.collector
      this.petType = obj.pet_type == null ? null : this.convertPetTypeToEnum(obj.pet_type)
      this.reasonOfRemoval = obj.remove_reason != null ? obj.remove_reason : null
    } else {
      this.bloodGroup = '-'
      this.pcv = 0
      this.collectDate = ''
      this.expiryDate = null
      this.quantity = ''
      this.originalQuantity = ''
      this.bagType = null
      this.bagStatus = bagStatus.AVAILABLE
      this.bloodType = bloodProductTypeEnum.FWB
      this.collectorId = ''
      this.bloodDonationId = null
      this.collector = ''
      this.petType = null
      this.reasonOfRemoval = null
    }
  }

  getBloodTypeValue (val) {
    const bloodType = {
      FWB: 0,
      SWB: 1,
      PRP: 2,
      FFP: 3,
      FP: 4,
      PRBCS: 5
    }

    return bloodType[val]
  }

  convertPetTypeToEnum (type) {
    switch (type) {
      case 1:
        return petTypeEnum.DOG

      case 2:
        return petTypeEnum.CAT

      default:
        return type
    }
  }

  convertEnumToPetType (type) {
    switch (type) {
      case petTypeEnum.DOG:
        return 1

      case petTypeEnum.CAT:
        return 2

      default:
        return type
    }
  }

  toRequestJson () {
    return {
      id: this.id,
      blood_group: this.bloodGroup,
      collection_date: _.isDate(this.collectDate) ? this.collectDate : null,
      collected_quantity: isNaN(parseFloat(this.quantity)) || isNaN(this.quantity) ? null : Number(this.quantity),
      original_quantity: isNaN(parseFloat(this.originalQuantity)) || isNaN(this.originalQuantity) ? null : Number(this.originalQuantity),
      expiry_date: _.isDate(this.expiryDate) && this.expiryDate != null ? moment(this.expiryDate).format('YYYY-MM-DD') : null,
      bag_type: this.bagType === '' ? null : this.bagType,
      bag_status: this.bagStatus,
      existing_bloodbag_id: null,
      PCV: isNaN(parseFloat(this.pcv)) || isNaN(this.pcv) ? null : Number(this.pcv),
      blood_donation: this.bloodDoanationId,
      blood_type: this.getBloodTypeValue(this.bloodType),
      collector: this.collector,
      pet_type: this.convertEnumToPetType(this.petType),
      remove_reason: this.reasonOfRemoval,
      hospital: store.state.hospital.profile.id
    }
  }
}
