<template>
  <div class="file-uploader">
    <div :class="{ 'dropbox': type === 'dropbox' }">
      <input
        id="file"
        :multiple="multiple"
        name="uploader"
        :disabled="isUploading"
        type="file"
        :accept="accept"
        class="input-file"
        @change="filesChange($event.target.files)"
      >
      <div v-if="!isUploading">
        <template v-if="type === 'attachment'">
          <div v-if="type === 'attachment'" class="attachment-button"><i class="fa fa-paperclip"></i></div>
        </template>
        <template v-if="type === 'dropbox'"><i class="fa fa-cloud-upload-alt"></i></template>
      </div>
      <div v-if="isUploading" class="loader">Uploading...</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'dropbox'
    },
    multiple: {
      type: Boolean,
      default: true
    },
    isUploading: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: "image/*, application/pdf"
    }
  },
  methods: {
    filesChange(fileList) {
      if (!fileList.length) return
      const uploadedFiles = []
      for (let i = 0; i < fileList.length; i++) {
        uploadedFiles.push(fileList[i])
      }
      this.$emit("onSelect", uploadedFiles)
    }
  }
}
</script>

<style lang="scss" scoped>
.attachment-button {
  display: inline-block;
  margin: 5px 0;
  padding: 5px 15px;
  background: $light-blue;
  color: #fff;
  font-size: 1.3em;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
}
.file-uploader {
  position: relative;
  display: inline-block;
}
.dropbox {
  position: relative;
  cursor: pointer;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s ease;
  overflow: hidden;

  i {
    font-size: 2.4em;
    color: #ccc;
    transition: all 0.4s ease;
  }

  &:hover {
    i {
      color: $light-blue;
    }
  }
}

.input-file {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  border-color: $light-blue;
}

.dropbox p {
  font-size: 1em;
  text-align: center;
  padding: 50px 0;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: $light-blue;
  font-size: 5px;
  margin: -25px auto 0;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
</style>

