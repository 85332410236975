<script>
import numeral from "numeral";

export default {
  props: {
    first: {
        type: Number,
        default: 0,
    },

    second: {
        type: Number,
        default: 0,
    },

    third: {
        type: Number,
        default: 0,
    },

    isEdit: {
        type: Boolean,
        default: false
    }
  },

  methods: {
    toCurrency(value) {
      return numeral(value).format('0,0.00');
    },

    async handleFirstChange(value) {
      await this.$emit("update:first", value);
    //   this.$emit("submit");
    },

    async handleSecondChange(value) {
      await this.$emit("update:second", value);
    },

    async handleThirdChange(value) {
      await this.$emit("update:third", value);
    },

    handleDoNotThing(value) {

    },

    getRankIcon(rank) {
      switch (rank) {
        case "gold":
          return require("@/assets/icons/medal-gold.svg");

        case "silver":
          return require("@/assets/icons/medal-silver.svg");

        case "bronze":
          return require("@/assets/icons/medal-bronze.svg");
      }
    },

    getRankIconLayout(rank, text) {
        return (
            <el-row
                type="flex"
                justify="space-around"
                gutter={16}
                style={{ alignItems: "center", flexWrap: "wrap" }}>
                <el-col span={null}>
                    {rank && (
                        <img src={this.getRankIcon(rank)}
                            alt="Status icon"
                            style={{ display: "block", height: "2em" }}
                        />
                    )}
              </el-col>
              <el-col span={null} style={{ fontSize: "14px", textAlign: "left" }}>{text}</el-col>
            </el-row>
        );
    },

    getRankLayout(rank, text, start, end, listener) {
        const disableStart = start !== undefined;
        const min = start !== undefined ? start + 1 : 0;
        end = end < min ? min : end;

        return (
            <el-row
                type="flex"
                gutter={16}
                class={this.$style.row}
                style={{ alignItems: "center", flexWrap: "wrap" }}>
                <el-col class={this.$style.firstCol} span={null} style={{ width: "100px" }}>
                    {this.getRankIconLayout(rank, text)}
                </el-col>
                <el-col span={null} style={{  fontSize: "12px" }}>ช่วงการใช้จ่าย</el-col>
                {start && [
                    <el-col span={null} style={{  fontSize: "12px", width: "70px" }}>มากกว่า</el-col>,
                    <el-col span={null} style={{  fontSize: "12px" }}>
                        <el-input-number
                            step={1}
                            disabled={disableStart}
                            controls={false}
                            value={start}
                            style={{ width: "96px", display: "flex" }} />
                    </el-col>
                ]}
                {end && [
                    <el-col span={null} style={{  fontSize: "12px" }}>น้อยกว่าเท่ากับ</el-col>,
                    <el-col span={null} style={{  fontSize: "12px" }}>
                        <el-input-number
                            step={1}
                            controls={false}
                            min={min}
                            value={end}
                            disabled={!this.isEdit}
                            onInput={listener}
                            onChange={listener}
                            style={{ width: "96px", display: "flex" }} />
                    </el-col>
                ]}
                <el-col span={null} style={{ flex: 1, fontSize: "14px", fontWeight: "bold", textAlign: "right" }}>THB</el-col>
            </el-row>
        );
    },

  },

  render() {
    return (
      <div class={this.$style.root}>
        <div style={{ color: "#4a4a4a", fontSize: "14px", marginBottom: "8px" }}>
            ตั้งค่ากลุ่มลูกค้า  ตามค่าใช้จ่ายยอดรวมต่อปี ของเจ้าของสัตว์ และ สัตว์เลี้ยง
        </div>
        {this.getRankLayout("", "No Tag", undefined, this.third, this.handleThirdChange)}
        {this.getRankLayout("bronze", "Bronze", this.third, this.second, this.handleSecondChange)}
        {this.getRankLayout("silver", "Silver", this.second, this.first, this.handleFirstChange)}
        {this.getRankLayout("gold", "Gold", this.first, undefined, this.handleDoNotThing)}
      </div>
    );
  },
};

</script>

<style lang="scss" module>
.root {
    background-color: #fff;
    padding: 10px;
}

.row {
    border-top: 1px solid #979797;
    padding: 6px 0;
}

.firstCol {
    border-right: 1px solid #979797;
}

</style>
