<template>
  <div>
    <Subnavbar>
      <template slot="title">Reward Redeem</template>
      <template slot="actions">
        <button class="btn btn-large btn-primary" @click="$router.push({ name: 'redeem-select-pet' })">
          <i class="fa fa-plus-circle"></i>Redeem Point
        </button>
      </template>
    </Subnavbar>

    <RedeemOverview 
      :isLoading="isOverviewLoading"
      :overview="overview"
      :currentDate="overviewDate"
      @changePrevMonth="changePrevMonth"
      @changeNextMonth="changeNextMonth"
    />

    <RedeemSearch
      :search="searchForm.search"
      :startDate="searchForm.start"
      :endDate="searchForm.end"
      @search="handleSearch"
    />

    <ContentBox>
      <template v-if="redeems && redeems.results">
        <RedeemTable
          :items="redeems"
          :total="redeems.count"
          :current-page="currentPage"
          @current-change="pageChange"
        />
      </template>
    </ContentBox>

  </div>
</template>

<script>
import { mapState } from "vuex"
import ContentBox from "@/components/container/ContentBox"
import Subnavbar from "@/components/navigation/Subnavbar"
import RedeemOverview from "@/components/redeem/RedeemOverview"
import RedeemSearch from "@/components/redeem/RedeemSearch"
import RedeemTable from "@/components/redeem/RedeemTable"

export default {
  components: {
    ContentBox,
    Subnavbar,
    RedeemOverview,
    RedeemSearch,
    RedeemTable
  },
  data() {
    return {
      currentPage: 1,
      isOverviewLoading: false,
      overviewDate: new Date(),
      searchForm: {
        search: '',
        start: '',
        end: ''
      }
    }
  },
  computed: {
    ...mapState({
      redeems: state => state.redeem.all,
      overview: state => state.redeem.overview,
      vets: state => state.hospital.vets,
    })
  },
  methods: {
    handleSearch(form) {
      let query = {}
      this.currentPage = 1
      query['page'] = 1
      if (form.search !== '') query['search'] = form.search
      if (form.start && form.start !== '') query['start'] = form.start
      if (form.end && form.end !== '') query['end'] = form.end

      this.fetchData(query)
    },
    pageChange(page) {
      let query = this.getQueryString()
      query = { ...query, page }
      this.currentPage = page
      this.fetchData(query)
    },
    changePrevMonth() {
      const t = this.overviewDate.setMonth(this.overviewDate.getMonth() - 1)
      this.overviewDate = new Date(t)
      this.fetchOverview()
    },
    changeNextMonth() {
      const t = this.overviewDate.setMonth(this.overviewDate.getMonth() + 1)
      this.overviewDate = new Date(t)
      this.fetchOverview()
    },
    async fetchOverview () {
      const y = this.overviewDate.getFullYear()
      const m = this.overviewDate.getMonth()
      const firstDay = new Date(y, m, 1)
      const lastDay = new Date(y, m + 1, 0,23,59,59,999)      

      try {
        this.isOverviewLoading = true
        await this.$store.dispatch("redeem/getOverview", { query: { 
          start: firstDay,
          end: lastDay
        }})
        this.isOverviewLoading = false
      } catch (error) {
        this.isOverviewLoading = false
         this.$notify({
          message: `พบปัญหาการโหลดข้อมูล overview`,
          type: "error"
        })
      }
    },
    getQueryString() {
      let query = {}

      if (this.$route.query.page) {
        query["page"] = parseInt(this.$route.query.page)
        this.currentPage = parseInt(this.$route.query.page)
      } else {
        this.currentPage = 1
      }

      if (this.$route.query.search) {
        this.searchForm.search = this.$route.query.search
        query["search"] = this.$route.query.search
      } 

      if (this.$route.query.start) {
        const start = new Date(parseInt(this.$route.query.start))
        query["start"] = start
        this.searchForm.start = start
      }

      if (this.$route.query.end) {
        const end = new Date(parseInt(this.$route.query.end))
        query["end"] = end
        this.searchForm.end = end
      }

      return query
    },
    async fetchData (query) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        await this.$store.dispatch("redeem/listRedeems", {
          query
        })

        let urlQuery = { ...query }
        if (query.start) urlQuery['start'] = query.start.getTime()
        if (query.end) urlQuery['end'] = query.end.getTime()

        this.$router.replace({ query: urlQuery })
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg")
      }
    }
  },
  async created() {
    this.fetchOverview()
    this.fetchData(this.getQueryString())
  }
}
</script>

<style lang="scss" scoped>
.box {
  margin-bottom: 15px;
}
.box-data-table {
  padding: 20px;
}
</style>