import HospitalForgetPasswordPage from '@/pages/hospitalRegister/HospitalForgetPasswordPage.vue'
import HospitalPasswordRecoveryPage from '@/pages/hospitalRegister/HospitalPasswordRecoveryPage.vue'
import Register from '@/pages/hospitalRegister/Register.vue'
import VerifyHospital from '@/pages/hospitalRegister/VerifyHospital.vue'
import ProfileGeneral from '@/pages/hospitalRegister/ProfileGeneral.vue'
import ProfileMapLocation from '@/pages/hospitalRegister/ProfileMapLocation.vue'
import ProfileAddress from '@/pages/hospitalRegister/ProfileAddress.vue'
import ProfileSocial from '@/pages/hospitalRegister/ProfileSocial.vue'

const routes = [
  {
    path: 'forget-password',
    name: 'hospitalForgetPassword',
    component: HospitalForgetPasswordPage,
    meta: {
      name: "HospitalForgetPasswordPage",
      requiresAuth: false
    }
  },
  {
    path: 'password-recovery',
    name: 'hospitalPasswordRecovery',
    component: HospitalPasswordRecoveryPage,
    meta: {
      name: "HospitalPasswordRecoveryPage",
      requiresAuth: false
    }
  },
  {
    path: '',
    name: 'register',
    component: Register,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: 'verify',
    name: 'verifyHospital',
    component: VerifyHospital,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: 'profile-general',
    name: 'profileGeneral',
    component: ProfileGeneral,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin']
    }
  },
  {
    path: 'profile-location',
    name: 'profileMapLocation',
    component: ProfileMapLocation,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin']
    }
  },
  {
    path: 'profile-address',
    name: 'profileAddress',
    component: ProfileAddress,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin']
    }
  },
  {
    path: 'profile-social',
    name: 'profileSocial',
    component: ProfileSocial,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin']
    }
  }
]

export default routes
