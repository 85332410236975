<template>
  <div v-if="isConnect && hospitalConnection" class="hospital-connection-tag">
    <div>
      {{ text }}
      <!-- ยินดีต้อนรับสู่การสมัคร เพื่อเชื่อมต่อกับ  -->
      <div class="text-style-1">{{ hospitalConnection.hospital.name }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    text: {
      default: "ยินดีต้อนรับสู่การสมัคร เพื่อเชื่อมต่อกับ",
      type: String
    },
    isConnect: {
      default: true,
      type: Boolean
    }
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapState({
      hospitalConnection: state => state.owner.hospitalConnection
    }),
  },
  async mounted() {
    const secret_code = localStorage.getItem('secret_code');
    if (secret_code) {
      try {
        await this.$store.dispatch("owner/getOwnerHospitalConnection", {
          params: { secret_code: secret_code },
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.hospital-connection-tag {
  margin-top: 16px;

  & div {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.36;
    letter-spacing: -0.4px;
    text-align: center;
    color: #000000;

    .text-style-1 {
      font-size: 17px;
      color: #156f88;
    }
  }
}
</style>
