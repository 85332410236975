import moment from "moment";

export const gender = (value) => {
  switch (value) {
    case 0:
      return "หญิง/Female";
    case 1:
      return "ชาย/Male";
    default:
      return "ไม่ระบุ/Unknown";
  }
};

export const petGender = value => {
  switch (value) {
    case 0:
      return "เมีย/Female";
    case 1:
      return "ผู้/Male";
    default:
      return "ไม่ระบุ/Unknown";
  }
};

export const petGenderTh = value => {
  switch (value) {
    case 0:
      return "เมีย";
    case 1:
      return "ผู้";
    default:
      return "ไม่ระบุ";
  }
};

export const petAge = value => {
  if (value) {
    const diffMonth = moment().diff(moment(value), "months");
    const ageYear = Math.floor(diffMonth / 12);
    const ageMonth = diffMonth % 12;
    return (
      (ageYear > 0 ? ageYear + " ปี " : "") +
      (ageMonth > 0 || ageMonth <= 0 ? ageMonth + " เดือน" : "")
    );
  }
  return "";
};
