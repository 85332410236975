<template>
  <div class="panel-body">
    <div class="panel-title">
      <div class="title-group">
        <div>
          <span class="panel-en-title">{{enName}}</span>
          <span class="panel-th-title"> / {{thName}}</span>
        </div>
        <slot name="dropDown"></slot>
      </div>
      <span class="panel-fullview">
        <slot name="viewFullLink"></slot>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    enName: {
      type: String
    },
    thName: {
      type: String
    },
  },
}
</script>

<style lang="scss" scoped>
  .panel-body {
    padding: 5px 15px 0px 15px;
    border-bottom: solid 1px #979797;
  }
  .panel-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
  }
  .title-group {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .panel-en-title {
    color: #000000;
    font-size: 18px;
  }
  .panel-th-title {
    color: #4a4a4a;
    font-size: 14px;
  }
  .panel-fullview {
    color: #4a4a4a;
    font-size: 16px;
    cursor: pointer;
    display: none;
  }
</style>
