<script>
import numeral from "numeral";


export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    toCurrency(value) {
      return numeral(value).format('0,0.00');
    },
  },

  render() {
    return (
      <div>
        <el-table data={this.items}>
          <el-table-column label="Year" prop="year" />
          <el-table-column
            label="Revenue"
            prop="revenue"
            align="right"
            scopedSlots={{
              default: ({ row }) =>
                this.toCurrency(row.revenue),
            }}
          />
        </el-table>
      </div>
    );
  },
};
</script>
