<script>
import { toThaiDate } from '@/filters/datetime';
import { StatusTag } from "@/pages/ipd/components/status";
import Tracker from "@/components/common/Tracker";
import IPDCardStatusTrackers from "@/components/medical/timeline/IPDCardStatusTrackers";

export default {

  props: {
    id: {
        type: String,
        default: ""
    },

    arrived: {
        type: Date,
        default: undefined,
    },

    finished: {
        type: Date,
        default: undefined
    },

    status: {
        type: String,
        default: ""
    },

    deposit: {
        type: Number,
        default: 0
    },

    paid: {
        type: Number,
        default: 0
    },

    overdue: {
        type: Number,
        default: 0
    },

    vets: {
        type: Array,
        default: () => []
    }

  },

  methods: {
    showVets() {
        if (this.vets.length === 0) return "ไม่ระบุ";
        
        let result = new Array();
        for (var i=0; i<this.vets.length; i++) {
            result.push(this.vets[i].full_name);
        }
        return result.join(", ")
    },

    handelSeeDetailClick() {
      this.$emit("view", this.id);
    },
  },

  render() {
    return (
        <el-card>
            <div style={{ padding: "12px"}}>
                <el-row type="flex" gutter={8} style={{ alignItems: "baseline" }}>
                    <el-col
                        span={null}
                        style={{ flexBasis: "2em", fontWeight: "bold", fontSize: "21px", color: "#354052" }}
                    >
                      IPD  
                    </el-col>
                    <el-col span={null}>IPD ID : {this.id}</el-col>
                </el-row>
                <el-row type="flex" gutter={20} style={{ alignItems: "baseline" }}>
                    <el-col
                        span={null}
                        style={{ fontWeight: "bold", fontSize: "21px", color: "#070b10" }}
                    >
                      วันเข้า : {toThaiDate(this.arrived)}
                    </el-col>
                    {this.finished && (<el-col
                        span={null}
                        style={{ fontWeight: "bold", fontSize: "21px", color: "#070b10" }}
                    >
                      วันปิดเคส : {toThaiDate(this.finished)}
                    </el-col>)}
                </el-row>
                <el-row type="flex" gutter={8} style={{ alignItems: "baseline" }}>
                    <el-col
                        span={null}
                        style={{ flexBasis: "2em", fontWeight: "bold", fontSize: "21px", color: "#354052" }}
                    >
                      สถานะ  
                    </el-col>
                    <el-col span={null}>
                        <StatusTag 
                            type={this.status}
                            style={{ fontSize: '0.75rem' }} />
                    </el-col>
                </el-row>

                <IPDCardStatusTrackers style={{ margin: "10px 0"}}>
                    <Tracker 
                        value={this.deposit} 
                        unit="THB" 
                        format="0,0.00">
                        Deposit<br />มัดจำ
                    </Tracker>
                    <Tracker 
                        value={this.paid} 
                        unit="THB" 
                        format="0,0.00">
                        Paid<br />ชำระแล้ว
                    </Tracker>
                    <Tracker 
                        value={this.overdue} 
                        unit="THB" 
                        format="0,0.00">
                        Overdue<br />ค้างชำระ
                    </Tracker>
                </IPDCardStatusTrackers>

                <el-row style={{ color: "#9b9b9b" }}>
                    <img src={require("@/assets/icons/icon-vet.png")}
                        style={{width: "17px", height: "20px", marginRight: "8px"}} />
                        สัตวแพทย์
                </el-row>

                <el-row>{this.showVets()}</el-row>

            </div>
            <el-button class={this.$style.button}
                onClick={this.handelSeeDetailClick}
                >ดูรายละเอียด</el-button>
        </el-card>
    );
  },
};
</script>

<style lang="scss" module>
.button {
    color: #156f88;
    border-radius: 5px;
    border: none;
    background-color: #c2e5f0;
    border-radius: 0px;
    width: 100%;
}
</style>
