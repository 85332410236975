import MedicalRecord from './medical-record'
import moment from 'moment'
import _ from 'lodash'

export default class extends MedicalRecord {
	constructor(obj) {
		super(obj)

		if (obj != null) {
			this.numberOfDonation =
				obj.number_of_donation == null ? '' : obj.number_of_donation
			this.lastDonation =
				obj.last_donation == null ? '' : new Date(obj.last_donation)
			this.isFullVaccineApplied =
				obj.is_vaccine_complete == null ? true : obj.is_vaccine_complete
			this.lastVaccine =
				obj.last_vaccine == null ? '' : new Date(obj.last_vaccine)
			this.bugVaccineHistory =
				obj.bug_resistance_vaccine == null ? '' : obj.bug_resistance_vaccine
			this.lastBugResistance =
				obj.last_bug_resistance == null
					? null
					: new Date(obj.last_bug_resistance)
			this.hasSkinSymptom =
				obj.has_skin_problem == null ? '' : obj.has_skin_problem
			this.hasPillTakenInOneMonth =
				obj.has_medical_treatment_in_last_one_month == null
					? ''
					: obj.has_medical_treatment_in_last_one_month
			this.usedToBloodTaken =
				obj.has_receive_blood == null ? '' : obj.has_receive_blood
			this.usedToSurgery = obj.has_surgery == null ? '' : obj.has_surgery
			this.isPassed = obj.is_pass
			this.bloodDonationId =
				obj.blood_donation == null ? null : obj.blood_donation
		} else {
			this.numberOfDonation = ''
			this.lastDonation = ''
			this.isFullVaccineApplied = ''
			this.lastVaccine = ''
			this.bugVaccineHistory = ''
			this.lastBugResistance = ''
			this.hasSkinSymptom = ''
			this.hasPillTakenInOneMonth = ''
			this.usedToBloodTaken = ''
			this.usedToSurgery = ''
			this.isPassed = null
			this.bloodDonationId = null
		}
	}

	toRequestJson() {
		let baseObject = super.toRequestJson()

		baseObject.number_of_donation =
			isNaN(parseFloat(this.numberOfDonation)) || isNaN(this.numberOfDonation)
				? null
				: Number(this.numberOfDonation)
		baseObject.last_donation = _.isDate(this.lastDonation)
			? moment(this.lastDonation).format('YYYY-MM-DD')
			: null
		baseObject.is_vaccine_complete = this.isFullVaccineApplied
		baseObject.last_vaccine = _.isDate(this.lastVaccine)
			? moment(this.lastVaccine).format('YYYY-MM-DD')
			: null
		baseObject.bug_resistance_vaccine = this.bugVaccineHistory
		baseObject.last_bug_resistance = _.isDate(this.lastBugResistance)
			? moment(this.lastBugResistance).format('YYYY-MM-DD')
			: null
		baseObject.has_skin_problem = this.hasSkinSymptom
		baseObject.has_medical_treatment_in_last_one_month = this.hasPillTakenInOneMonth
		baseObject.has_receive_blood = this.usedToBloodTaken
		baseObject.has_surgery = this.usedToSurgery
		baseObject.is_pass = this.isPassed
		baseObject.blood_donation = Number(this.bloodDonationId),
		baseObject.hospital = this.hospital

		return baseObject
	}

	cloneFrom(obj) {
		super.cloneFrom(obj)
		this.numberOfDonation = obj.numberOfDonation
		this.lastDonation = obj.lastDonation
		this.isFullVaccineApplied = obj.isFullVaccineApplied
		this.lastVaccine = obj.lastVaccine
		this.bugVaccineHistory = obj.bugVaccineHistory
		this.lastBugResistance = obj.lastBugResistance
		this.hasSkinSymptom = obj.hasSkinSymptom
		this.hasPillTakenInOneMonth = obj.hasPillTakenInOneMonth
		this.usedToBloodTaken = obj.usedToBloodTaken
		this.usedToSurgery = obj.usedToSurgery
		this.isPassed = obj.isPassed
    this.bloodDonationId = obj.bloodDonationId
	}
}
