<template>
  <div>
    <Subnavbar>
      <template slot="breadcrumb">
        <ul>
          <li>REDEEM PONT</li>
        </ul>
      </template>
      <template slot="title">REDEEM PONT 2/2 : REDEEM</template>
      <template slot="actions">
      </template>
    </Subnavbar>

    <ContentBox v-if="pet" style="width: 800px; margin:0 auto;">
      <template slot="header">Pet Profile</template>
      <div style="width: 400px; margin: 0 auto;">
        <PetProfile :pet="pet.pet" :owner="pet.pet.owners[0]" :avatarSize="120" class="pet-profile"/>
        <div class="point-container">
          <el-row>
            <el-col :span="16"><span class="title">Health Point</span></el-col>
            <el-col :span="8" ><span class="points">{{ pet.pet.point | number}}<i class="icon icon-heart-on"></i></span></el-col>
          </el-row>
        </div>
      </div>
    </ContentBox>
    <ContentBox style="width: 800px; margin:15px auto;">
      <template slot="header">Detail</template>
      <h2 class="title">แลกแต้ม</h2>
      <el-row class="custom-row">
        <el-col :md="20">
          <el-input placeholder="พิมพ์ค้นหารายการแลกแต้ม"
            v-model="searchForm.search"
            clearable
            @keyup.enter.native="handleSearch"
            @clear="handleClearSearch"
          >
            <el-button slot="append" icon="el-icon-search" @click="handleSearch"></el-button>
          </el-input>
        </el-col>
      </el-row>
      <div style="padding:10px 0 20px; color: red; font-size: .8em;">
        *ถ้าต้องการเพิ่มรายการแลกแต้ม เพิ่มได้ที่ REWARD SETTING
      </div>
      <template v-if="rewards && rewards.results">
         <RedeemRewardTable
          :rewards="rewards.results"
          :total="rewards.count"
          :current-page="currentPage"
          @current-change="pageChange"
          @selected="handleSelected"
        />
      </template>
    </ContentBox>
    <template v-if="selectedReward !== null">
      <ConfirmDialog
              :visible="showConfirmDialog"
              @cancel="showConfirmDialog = false"
              @confirm="handelConfirm"
      >
        <template slot="header">
          <h1 class="title">ยืนยันการแลกแต้ม?</h1>
        </template>
        <template slot="content" >
          <div class="dialog-form">
            <div style="text-align: left;">
              <el-row>
                <el-col :span="8"><label>Pet</label></el-col>
                <el-col :span="16">{{ pet.pet.name }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="8"><label>Owner</label></el-col>
                <el-col :span="16">
                  <template v-if="pet.pet.owners.length > 0">
                  {{ `${ pet.pet.owners[0].firstname} ${ pet.pet.owners[0].lastname}` }}
                  </template>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8"><label>Date</label></el-col>
                <el-col :span="16">
                  {{ new Date() | toThaiDate }} {{ new Date() | toHHMM}}
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8"><label>รายการ</label></el-col>
                <el-col :span="16">
                  {{ selectedReward.name }}
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8"><label>Note</label></el-col>
                <el-col :span="16">
                  {{ selectedReward.detail || '-' }}
                </el-col>
              </el-row>
            </div>
            <div style="text-align: left; width: 200px; margin-top: 30px;">
              <h1 class="title">Health Point:</h1>
              <el-row>
                <el-col :span="8"><label>ใช้ไป</label></el-col>
                <el-col :span="16" style="text-align: right;">{{ selectedReward.use_point | number}}</el-col>
              </el-row>
              <el-row>
                <el-col :span="8"><label>คงเหลือ</label></el-col>
                <el-col :span="16" style="text-align: right;">{{ (pet.pet.point - selectedReward.use_point) | number }}</el-col>
              </el-row>
            </div>
            <div class="error-message" v-if="(pet.pet.point - selectedReward.use_point) < 0">
              คุณมีจำนวน Health point ไม่เพียงพอ
            </div>
          </div>
        </template>
        <template slot="footer">
          <el-button @click="showConfirmDialog = false">Cancel</el-button>
          <el-button type="primary" v-if="(pet.pet.point - selectedReward.use_point) >= 0" @click="handelConfirm">Confirm</el-button>
        </template>
      </ConfirmDialog>
    </template>

  </div>
</template>

<script>
import { mapState } from "vuex"
import ContentBox from "@/components/container/ContentBox"
import Subnavbar from "@/components/navigation/Subnavbar"
import PetProfile from "@/components/pet/PetProfile"
import RedeemRewardTable from "@/components/redeem/RedeemRewardTable";
import ConfirmDialog from "@/components/dialog/ConfirmDialog"

export default {
  components: {
    ContentBox,
    Subnavbar,
    PetProfile,
    RedeemRewardTable,
    ConfirmDialog
  },
  data() {
    return {
      showConfirmDialog: false,
      currentPage: 1,
      searchForm: {
        search: '',
      },
      selectedReward: null,
    }
  },
  computed: {
    ...mapState({
      pet: state => state.pet.selected,
      rewards: state => state.reward.all
    })
  },
  methods: {
    async handelConfirm () {
      this.showConfirmDialog = false
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)

        let data =  {
          pet: this.pet.pet.id,
          reward: this.selectedReward.id,
        };

        await this.$store.dispatch("redeem/createRedeem", {
          data: data,
        })
        this.$notify({
          message: "ได้ทำการแลกแต้มเรียบร้อยแล้ว",
          type: "success"
        });
        this.$router.push({ name: "redeem-list" })
       this.fetchAllData(this.getQueryString())
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        await this.$store.dispatch("ui/showFetchErrorMsg")
      }
    },
    handleSearch () {
      let query = {}
      this.currentPage = 1
      query['available'] = true;
      query['page'] = 1
      if (this.searchForm.search !== '') query['search'] = this.searchForm.search

      this.fetchRewardData(query)
    },
    handleClearSearch () {
      let query = {}
      this.currentPage = 1
      query['available'] = true;
      query['page'] = 1
      query['search'] = ''
      this.searchForm.search = ''
      this.fetchRewardData(query)
    },
    handleSelected(item) {
      this.selectedReward = item
       this.showConfirmDialog = true
    },
    pageChange(page) {
      let query = this.getQueryString()
      query = { ...query, page }
      this.currentPage = page
      this.fetchRewardData(query)
    },
    getQueryString() {
      let query = {}
      query['available'] = true;

      if (this.$route.query.page) {
        query["page"] = parseInt(this.$route.query.page)
        this.currentPage = parseInt(this.$route.query.page)
      } else {
        this.currentPage = 1
      }

      if (this.$route.query.search) {
        this.searchForm.search = this.$route.query.search
        query["search"] = this.$route.query.search
      } 

      return query
    },
    async fetchRewardData (query) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        await this.$store.dispatch("reward/listRewards", {
          query
        })

        let urlQuery = { ...query }

        this.$router.replace({ query: urlQuery })
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg")
      }
    },
    async fetchAllData (query) {
      try {
        const id = this.$route.params.id;

        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        await this.$store.dispatch("pet/getPetProfile", {
          params: { petHospitalId: id }
        });
         await this.$store.dispatch("reward/listRewards", {
          query
        })

        let urlQuery = { ...query }

        this.$router.replace({ query: urlQuery })
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg")
      }
    }
  },
  async created() {
    this.fetchAllData(this.getQueryString())
  }
}
</script>

<style lang="scss" scoped>
.box {
  margin-bottom: 15px;
}
.box-data-table {
  padding: 20px;
}
.point-container {
  margin-top: 10px;
  width: 400px;;
  background: #f5f8fa;
  padding: 10px 15px;
  border-radius: 10px;

  .title {
    font-size: .9em;
    line-height: 1.2em;
    color: #666;
  }

  .points {
    font-weight: bold;
    font-size: 1.2em;
    color: #d0025b;
  }

  .icon-heart-on {
    margin-left: 20px;
    vertical-align: middle;
    color: #d0025b;
  }
}

.dialog-form {
  label {
    color: #999;
  }
  .el-row {
    margin: 10px 0;
  }
}

.error-message {
  color: red;
  font-size: .9em;
  margin: 20px 0 0;
  padding: 10px;
  background: #efefef;
  border-radius: 5px;
}
</style>