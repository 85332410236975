<template>
  <el-button @click="widgetClicked" class='widget-style flex-center'>
    <label v-if="isLabelAtTop">
      <h2 :style="isHighlighten ? 'color: #FFD05B' : 'color: #FFFFFF'"> {{label}} </h2>
    </label>
    <span :style="isHighlighten ? 'color: #FFD05B' : 'color: #FFFFFF'" v-if="type=='text'" class="text-widget"> {{ widgetText }} </span>
    <i v-if="type=='icon'" class="icon-style" v-bind:class="[widgetIconClass]" :style="{ color: isHighlighten ? '#FFD05B' : '#FFFFFF', 'font-size': '100px' }"></i>
    <img v-if="type=='image'" :src="imgSrc" :alt="pic">
    <label v-if="!isLabelAtTop">
      <h2 :style="isHighlighten ? 'color: #FFD05B' : 'color: #FFFFFF'"> {{label}} </h2>
    </label>
  </el-button>
</template>

<script>
export default {
  name: 'widget',
  data () {
    return {}
  },
  props: ['type', 'label', 'widgetIconClass', 'widgetText', 'isLabelAtTop', 'isHighlighten', 'imgSrc'],

  methods: {
    widgetClicked: function () {
      this.$emit('widgetClicked')
    }
  }
}

</script>

<style scoped>
  .widget-style {
    width: 100%;
    height: 150px;
    word-break: inherit;
    white-space: normal;
    font-weight: bold;
    background: #324A5E
  }

  .text-widget {
    font-size: 5em;
  }

  .at-btn:hover {
    background: #324A5E!important;
  }
  h2 {
    font-size: 16px;
  }
</style>
