<template>
  <div class="request-page">
    <!-- Sub Navbar -->
    <Subnavbar>
      <template slot="breadcrumb"></template>
      <template slot="title">Request Blood</template>
      <template slot="actions" class="action-wrapper">
        <button
          class="btn btn-large btn-new-request"
          @click="$router.push({ name: 'requestCreateSelect' })"
        >
          <img src="@/assets/bloodbank/new-request.png" /> + New Request
        </button>
        <button
          class="btn btn-large btn-new-pet"
          v-show="currentPlan.hospital_feature.can_create_owner_and_pet"
          @click="$router.push({ name: 'hospitalPetsSearch' })"
        >
          <i class="fa fa-plus-circle"></i>New Pet
        </button>
        <button
          class="btn btn-large btn-pet-profile"
          @click="$router.push({ name: 'hospitalPets' })"
        >
          <i class="fa fa-list-alt"></i>Pet Profile
        </button>
      </template>
    </Subnavbar>

    <!-- Request Board -->
    <ContentBox class="request-board">
      <h2 class="title">Blood Request Board</h2>
      <div class="inner">
        <div class="column today-tickets">
          <Widget
            :isHighlighten="true"
            :isLabelAtTop="true"
            label="New request"
            :widgetText="newRequestWidgetData"
            type="text"
          ></Widget>
        </div>
        <div class="column failed-request">
          <Widget
            :isLabelAtTop="true"
            label="Crossmatch Stage"
            :widgetText="crossmatchWidgetData"
            type="text"
          ></Widget>
        </div>
        <div class="column wait-result">
          <Widget
            :isLabelAtTop="true"
            label="จ่ายถุงเลือดวันนี้"
            :widgetText="withdrawBloodBagTodayWidgetData"
            type="text"
          ></Widget>
        </div>
      </div>
    </ContentBox>

    <!-- Search Box -->
    <ContentBox>
      <el-row :gutter="10">
        <el-col :md="3">
          <el-select
            v-model="selectedRequestState"
            placeholder="Request"
            size="small"
            @change="handleSearchClick"
          >
            <el-option
              v-for="state in Object.keys(requestStates)"
              :key="state"
              :value="requestStates[state]"
              :label="requestStates[state]"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :md="12">
          <el-input
            placeholder="ชื่อสัตว์, เบอร์โทร, ชื่อเจ้าของ, อีเมล์, HN, Microchips, Pet ID, Request ID"
            v-model="searchText"
            class="search-input"
            size="small"
            clearable
            @keyup.enter.native="handleSearchClick"
            @clear="clearSearch"
          >
            <el-button slot="append" icon="el-icon-search" @click="handleSearchClick"></el-button>
          </el-input>
        </el-col>
        <el-col :md="3">
          <el-select
            clearable
            placeholder="Pet Type"
            v-model="selectedPetType"
            size="small"
            @change="handleSearchClick"
            @clear="selectedBreedId=''"
          >
            <el-option value="CAT" label="CAT"></el-option>
            <el-option value="DOG" label="DOG"></el-option>
          </el-select>
        </el-col>
        <el-col :md="3">
          <el-select
            clearable
            placeholder="Breed"
            :disabled="selectedPetType === ''"
            v-model="selectedBreedId"
            size="small"
            @change="handleSearchClick"
            @clear="selectedBreedId=''"
          >
            <el-option
              v-for="breed in breeds"
              :key="breed.id"
              :value="breed.id"
              :label="breed.name"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :md="3">
          <el-select
            clearable
            placeholder="Sex / Sterilize"
            v-model="selectedSterilize"
            size="small"
            @change="handleSearchClick"
            @clear="selectedSterilize=''"
          >
            <el-option value="male" label="ผู้ / ไม่หมัน"></el-option>
            <el-option value="female" label="เมีย / ไม่หมัน"></el-option>
            <el-option value="male_sterilize" label="ผู้ / หมัน"></el-option>
            <el-option value="female_sterilize" label="เมีย / หมัน"></el-option>
          </el-select>
        </el-col>
      </el-row>
    </ContentBox>

    <!-- Pet List -->
    <ContentBox v-if="requests">
      <BloodRequestSearchResult
        :requests="filteredRequests"
        :current-page="currentPage"
        @current-change="pageChange"
      />
    </ContentBox>
  </div>
</template>

<script>
import _ from "lodash";
import { mapState, mapGetters } from "vuex";
import ContentBox from "@/components/container/ContentBox";
import Subnavbar from "@/components/navigation/Subnavbar";
import Widget from "@/components/bloodbank/Widget";
import BloodRequestSearchResult from "@/components/bloodbank/blood-request/BloodRequestSearchResult";
import moment from "moment";

const REQUEST_STATE = {
  ALL: "All Requests",
  RECIPIENT_PROFILE: "ยื่นคำขอถุงเลือด",
  CROSSMATCH: "Crossmatch",
  RESERVE: "เบิกถุงเลือด",
  CONFIRMATION: "รับเลือดแล้ว",
  FEEDBACK: "Done",
  REJECTED: "Cancelled"
};

export default {
  components: {
    ContentBox,
    Subnavbar,
    Widget,
    BloodRequestSearchResult
  },
  data() {
    return {
      requestStates: REQUEST_STATE,
      searchText: "",
      currentPage: 1,
      selectedPetType: "",
      selectedBreedId: "",
      selectedSterilize: "",
      selectedRequestState: REQUEST_STATE.ALL,
      filteredRequests: []
    };
  },
  computed: {
    ...mapState({
      profile: state => state.hospital.profile,
      petResults: state => state.pet.all,
      breeds: state => state.pet.breeds,
      requests: state => state.bloodbank.requests,
      vets: state => state.hospital.vets,
      hospitals: state => state.hospital.hospitals,
      currentPlan: state => state.plan.currentPlan
    }),
    ...mapGetters({
      profile: "hospital/profile"
    }),
    newRequestWidgetData() {
      if (this.requests < 1) return 0;
      let bloodRequest = this.requests.filter(item => {
        return item.hospitalId === this.profile.id;
      });
      return bloodRequest
        ? _.filter(bloodRequest, o => {
            return Math.abs(moment(o.createDate).diff(moment(), "days")) < 1;
          }).length
        : 0;
    },
    crossmatchWidgetData: function() {
      if (this.requests < 1) return 0;
      let bloodRequest = this.requests.filter(item => {
        return item.hospitalId === this.profile.id;
      });
      return bloodRequest
        ? _.filter(bloodRequest, o => {
            return o.isRecipientProfileSubmitted && !o.isCrossmatchSubmitted;
          }).length
        : 0;
    },
    withdrawBloodBagTodayWidgetData: function() {
      if (this.requests < 1) return 0;
      let bloodRequest = this.requests.filter(item => {
        return item.hospitalId === this.profile.id;
      });
      return bloodRequest
        ? _.filter(bloodRequest, o => {
            return (
              o.isBloodBagReceived &&
              o.receiveBlood.modifiedDate &&
              moment(o.receiveBlood.modifiedDate).isSame(moment(), "day")
            );
          }).length
        : 0;
    }
  },
  methods: {
    handleSearchClick() {
      this.search(1);
    },
    async search(currentPage) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);

        let query = { page: currentPage };
        if (this.searchText !== "") {
          query.search = this.searchText;
        }

        if (this.selectedPetType !== "") {
          query["pet__type__key"] = this.selectedPetType;
        }

        if (this.selectedBreedId !== "") {
          query["pet__breed"] = this.selectedBreedId;
        }

        if (this.selectedSterilize !== "") {
          switch (this.selectedSterilize) {
            case "male":
              query["pet__gender"] = 1;
              break;
            case "female":
              query["pet__gender"] = 0;
              break;
            case "male_sterilize":
              query["pet__gender"] = 1;
              query["pet__sterilize"] = true;
              break;
            case "female_sterilize":
              query["pet__gender"] = 0;
              query["pet__sterilize"] = false;
              break;
          }
        }
        // Just Fill Pet Breed in search box
        if (this.selectedPetType !== "") {
          await this.$store.dispatch("pet/listBreeds", {
            query: {
              pet_type__key: this.selectedPetType
            }
          });
        } else {
          this.$store.commit("pet/SET_BREEDS", null);
        }

        // List&Fill Requests
        if (this.requests.length < 1) {
          await this.$store.dispatch("bloodbank/listAndFillBloodRequests");
        }

        // Basic Filter
        let list = this.requests;
        list = list
          .filter(d => d.hospitalId === this.profile.id)
          .sort(function(a, b) {
            return new Date(b.modifiedDate) - new Date(a.modifiedDate);
          });

        // Filter by state
        let filterPropertyMap = null;
        let requiredFalseProps = [];
        switch (this.selectedRequestState) {
          case REQUEST_STATE.RECIPIENT_PROFILE:
            filterPropertyMap = "";
            requiredFalseProps = [
              "isCrossmatchSubmitted",
              "isWithdrawBloodBagReceived",
              "isBloodBagReceived",
              "isFeedbackSubmitted",
              "isTicketRejected"
            ];
            break;

          case REQUEST_STATE.CROSSMATCH:
            filterPropertyMap = "isCrossmatchSubmitted";
            requiredFalseProps = [
              "isWithdrawBloodBagReceived",
              "isBloodBagReceived",
              "isFeedbackSubmitted",
              "isTicketRejected"
            ];
            break;

          case REQUEST_STATE.RESERVE:
            filterPropertyMap = "isWithdrawBloodBagReceived";
            requiredFalseProps = [
              "isBloodBagReceived",
              "isFeedbackSubmitted",
              "isTicketRejected"
            ];
            break;

          case REQUEST_STATE.CONFIRMATION:
            filterPropertyMap = "isBloodBagReceived";
            requiredFalseProps = ["isFeedbackSubmitted", "isTicketRejected"];
            break;

          case REQUEST_STATE.FEEDBACK:
            filterPropertyMap = "isFeedbackSubmitted";
            requiredFalseProps = ["isTicketRejected"];
            break;

          case REQUEST_STATE.REJECTED:
            filterPropertyMap = "isTicketRejected";
            break;
        }

        list = list.filter(request => {
          if (filterPropertyMap != null) {
            const isConditionMatch = requiredFalseProps.every(prop => {
              return request[prop] === false;
            });
            if (filterPropertyMap !== "") {
              if (isConditionMatch && request[filterPropertyMap]) {
                return true;
              }
            } else {
              if (isConditionMatch) {
                return true;
              }
            }
          } else {
            return true;
          }
        });

        // Filter by searchText
        if (this.searchText !== "") {
          let pattern = new RegExp(this.searchText, "i");
          list = list.filter(
            request =>
              (request.id && pattern.test(request.id.toString())) ||
              (request.petInfo.name && pattern.test(request.petInfo.name)) ||
              (request.petInfo.hospitalNumber &&
                pattern.test(request.petInfo.hospitalNumber)) ||
              (request.petInfo.id && pattern.test(request.petInfo.id)) ||
              (request.petInfo.microchip &&
                pattern.test(request.petInfo.microchip)) ||
              (request.petInfo.owners &&
                request.petInfo.owners.some(
                  o =>
                    (o.firstname &&
                      o.lastname &&
                      pattern.test(o.firstname + " " + o.lastname)) ||
                    (o.phone && pattern.test(o.phone)) ||
                    (o.phone2 && pattern.test(o.phone2)) ||
                    (o.phone3 && pattern.test(o.phone3)) ||
                    (o.email && pattern.test(o.email))
                ))
          );
        }

        // Filter by type/breed/gender/sterilize
        if (this.selectedPetType) {
          list = list.filter(
            request =>
              request.petInfo.type.toLowerCase() ===
              this.selectedPetType.toLowerCase()
          );
        }
        if (this.selectedBreedId) {
          list = list.filter(
            request => request.petInfo.breed.id === this.selectedBreedId
          );
        }
        if (this.selectedSterilize) {
          let [gender, sterilize] = this.selectedSterilize.split("_");
          list = list.filter(
            request =>
              gender === request.petInfo.sex &&
              (sterilize
                ? request.petInfo.sterilize
                : !request.petInfo.sterilize)
          );
        }

        // Final list
        this.filteredRequests = list;

        this.currentPage = currentPage;

        let urlQuery = _.omit(query, "pet__gender", "pet__sterilize");
        if (this.selectedSterilize !== "") {
          urlQuery.sterilize = this.selectedSterilize;
        }
        this.$router.replace({ query: urlQuery });
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorMsg");
      }
    },
    clearSearch() {
      // this.$store.commit("pet/SET_ALL", null)
      // this.$store.commit("pet/SET_BREEDS", null)
      this.searchText = "";
      this.currentPage = 1;
      this.$router.replace({
        search: "",
        currentPage: 1
      });
      this.search(1);
    },
    pageChange(page) {
      this.currentPage = page;
      // this.search(page)
    },
    getQueryStringFormUrl() {
      if (this.$route.query.search) {
        this.searchText = this.$route.query.search;
      }

      if (this.$route.query.page) {
        this.currentPage = parseInt(this.$route.query.page);
      }

      if (
        this.$route.query.pet__type__key &&
        this.$route.query.pet__type__key !== ""
      ) {
        this.selectedPetType = this.$route.query.pet__type__key;
      }

      if (this.$route.query.pet__breed && this.$route.query.pet__breed !== "") {
        this.selectedBreedId = parseInt(this.$route.query.pet__breed);
      }

      if (this.$route.query.sterilize && this.$route.query.sterilize !== "") {
        this.selectedSterilize = this.$route.query.sterilize;
      }

      // Bloodbank filter
      if (
        this.$route.query.request__state &&
        this.$route.query.request__state !== ""
      ) {
        this.selectedRequestState = this.$route.query.request__state;
      }

      this.search(this.currentPage);
    }
  },
  async mounted() {
    this.getQueryStringFormUrl();
    // List Vets
    if (this.vets.length < 1) {
      await this.$store.dispatch("hospital/listVets");
    }
    if (this.hospitals.length < 1) {
      await this.$store.dispatch("hospital/listHospitals");
    }
  }
};
</script>

<style lang="scss">
  .request-page {
    .right-col {
      display: flex;
    }

    .btn-new-request {
      img {
        height: 24px;
        vertical-align: middle;
      }
    }

    .request-board {
      .title {
        padding: 0;
        margin: 0 0 5px 10px;
      }

      .inner {
        display: flex;
        flex-direction: row;

        .column {
          display: flex;
          flex-direction: row;
          flex: 1;
          padding: 5px 10px;
        }
      }
    }
  }
</style>
