<template>
  <div class="donation-step-4-exam" style="width: 100%">
    <el-row>
      <slot
        class="headSlot"
        name="header"
        :currentProgress="this.myProgress"
        :currentText="this.myText"
      ></slot>
    </el-row>

    <el-row>
      <el-row class="medical-record-card">
        <el-col>
          <img src="@/assets/bloodbank/file.png" height="20" />
          <span>Blood Collect</span>
        </el-col>
      </el-row>
      <el-row>
        <el-card style="padding-left: 5px; padding-right: 5px;">
          <div class="row flex">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12 flex-end">
                  <h1 class="main-title">Blood Group</h1>
                </div>
                <div class="col-md-12 flex-start">
                  <label
                    class="main-detail"
                  >{{donation.pet.id !== 0 ? donation.pet.bloodGroup === '' ? 'ไม่ระบุ' : donation.pet.bloodGroup : donation.bloodCollectInfo.bloodGroup === '' ? 'ไม่ระบุ' : donation.bloodCollectInfo.bloodGroup }}</label>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 flex-end">
                  <h1 class="main-title">PCV</h1>
                </div>
                <div class="col-md-12 flex-start">
                  <label class="main-detail">{{donation.bloodCollectInfo.pcv}} %</label>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-24 margin-top-20">
                  <div class="row">
                    <div class="col-md-11 flex-end">
                      <label class="error-field-label">
                        วันที่เก็บเลือด
                        <span>*</span>
                      </label>
                    </div>
                    <div class="col-md-12 col-md-offset-1">
                      <el-date-picker
                        :disabled="donation.isCaseClosed()"
                        v-validate="{ required: true, date_format: 'YYYY-MM-DD' }"
                        v-model="donation.bloodCollectInfo.collectDate"
                        type="datetime"
                        placeholder="Select date"
                      ></el-date-picker>
                    </div>
                  </div>
                </div>
                <div class="col-md-24 margin-top-20">
                  <div class="row flex">
                    <div class="col-md-11 flex-end">
                      <label class="error-field-label">Expiry Date *</label>
                    </div>
                    <div class="col-md-12 col-md-offset-1 flex-start">
                      <el-date-picker
                        :disabled="donation.isCaseClosed()"
                        name="expireDate"
                        v-validate="{ required: true, date_format: 'YYYY-MM-DD' }"
                        v-if="donation.bloodCollectInfo.bloodType == bloodBagTypeEnum.FWB"
                        v-model="donation.bloodCollectInfo.expiryDate"
                        type="date"
                        placeholder="Select date"
                      ></el-date-picker>
                    </div>
                  </div>
                </div>
                <div class="col-md-24 margin-top-20">
                  <div class="row">
                    <div class="col-md-11 flex-end">
                      <label class="error-field-label">
                        ปริมาตรที่เก็บได้ (ml)
                        <span>*</span>
                      </label>
                      <br />
                      <span
                        class="font-suggestion"
                      >*ปริมาตรที่แนะนำ {{ suggestedQuantity }} ml. (น้ำหนักตัว x {{ suggestedQuantityMultiplier }})</span>
                    </div>
                    <div class="col-md-12 col-md-offset-1">
                      <el-input
                        :disabled="donation.isCaseClosed()"
                        v-validate="'required|decimal|between:1,100000'"
                        v-model="donation.bloodCollectInfo.originalQuantity"
                      ></el-input>
                    </div>
                  </div>
                </div>
                <div class="col-md-24 margin-top-20">
                  <div class="row flex">
                    <div class="col-md-11 flex-end">
                      <label>ชนิดถุงที่ใช้เก็บ</label>
                    </div>
                    <div class="col-md-12 col-md-offset-1 flex-start">
                      <el-select
                        v-model="donation.bloodCollectInfo.bagType"
                        size="large"
                        placeholder="Select type"
                        :disabled="donation.isCaseClosed()"
                      >
                        <el-option label="ถุงเดี่ยว" :value="bagTypeEnum.BAG_TYPE_A"/>
                        <el-option label="สองถุง"  :value="bagTypeEnum.BAG_TYPE_B"/>
                        <el-option label="สามถุง"  :value="bagTypeEnum.BAG_TYPE_C"/>
                        <el-option label="สี่ถุง"  :value="bagTypeEnum.BAG_TYPE_D"/>
                      </el-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <!-- <div class="col-md-4 col-md-offset-16">
              <at-button type="error" @click="isPrintPreviewShow=true"> Print </at-button>
            </div>
            <div class="col-md-4">
              <at-button class="btn-next-page" type="error" v-on:click="onSaveClicked()" style="margin-top:20px; width: 250px; padding: 10px; 0px;" size="large"> บันทึกผล </at-button>
            </div>-->
            <div class="col-md-24 flex-end">
              <el-button
                type="error"
                v-if="donation.isBloodCollectSubmitted && hospital.id === donation.hospitalId"
                @click="isPrintPreviewShow=true"
                class="flex-end btn-next-page"
                style="margin-top:20px; width: 220px; padding: 10px;"
              >Print</el-button>
            </div>
          </div>
        </el-card>

        <!-- <div class="row flex" style="margin-left: 4px;" v-if="donation.isBloodCollectSubmitted">
          <blood-bag-label></blood-bag-label>
        </div>-->

        <at-modal v-model="isPrintPreviewShow" :styles="{width:'800px'}" :showClose="false">
          <div class="at-container" style="height:300px">
            <div class="row">
              <div class="col-md-12 img-label-preview">
                <print-template
                  :pcv="donation.bloodCollectInfo.pcv"
                  :bloodBagId="donation.bloodCollectInfo.id"
                  :petName="donation.pet.name"
                  :petID="donation.pet.id"
                  :bloodGroup="donation.pet.bloodGroup"
                  :volumn="donation.bloodCollectInfo.quantity"
                  :petType="donation.pet.type"
                  :bloodProduct="donation.bloodCollectInfo.bloodType"
                  :donationID="donation.bloodDonationId"
                  :dateCollect="donation.bloodCollectInfo.collectDate"
                  :dateExpire="donation.bloodCollectInfo.expiryDate"
                  id="printTemplate"
                ></print-template>
              </div>
              <div class="col-md-12 text-center">
                <div class="row">
                  <div class="col-md-12">
                    <label>Blood Bag ID</label>
                  </div>
                  <div class="col-md-12">
                    <span>{{ donation.bloodCollectInfo.id }}</span>
                  </div>
                </div>
                <div class="row margin-top-20">
                  <div class="col-md-12">
                    <label>Collect Date/Time</label>
                  </div>
                  <div class="col-md-12">
                    <span>{{ getCollectDate(donation.bloodCollectInfo.collectDate) }}</span>
                  </div>
                </div>
                <div class="row margin-top-20">
                  <div class="col-md-12">
                    <label>
                      Expire Date
                      <span>*</span>
                    </label>
                  </div>
                  <div class="col-md-12">
                    <span>{{ moment(donation.bloodCollectInfo.expiryDate).format('DD MMM YYYY') }}</span>
                  </div>
                </div>
                <div class="row margin-top-20">
                  <div class="col-md-12">
                    <label>Type</label>
                  </div>
                  <div class="col-md-12">
                    <span>{{ donation.bloodCollectInfo.bloodType }}</span>
                  </div>
                </div>
                <div class="row margin-top-20">
                  <div class="col-md-12">
                    <label>Amount</label>
                  </div>
                  <div class="col-md-12">
                    <span>{{ donation.bloodCollectInfo.quantity }}</span>
                  </div>
                </div>
                <div class="row margin-top-20">
                  <div class="col-md-12">
                    <label>Pet ID</label>
                  </div>
                  <div class="col-md-12">
                    <span>{{ donation.pet.id }}</span>
                  </div>
                </div>
                <div class="row margin-top-20">
                  <div class="col-md-12">
                    <label>Donation ID</label>
                  </div>
                  <div class="col-md-12">
                    <span>{{ donation.bloodDonationId }}</span>
                  </div>
                </div>
                <div class="row flex">
                  <div class="col-md-24 flex-end">
                    <at-button
                      @click="onPrintButtonClick"
                      v-if="donation.bloodCollectInfo.bloodType == bloodBagTypeEnum.FWB"
                    >
                      <i class="icon icon-printer"></i>
                    </at-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div slot="footer">
            <at-button @click="isPrintPreviewShow = false" type="primary">ปิด</at-button>
          </div>
        </at-modal>
      </el-row>
    </el-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import PetTypeEnums from "@/constants/bloodbank/pet-type-enum";
import bloodBagLabel from "@/components/bloodbank/BloodBagLabel";
import bagTypeEnum from "@/constants/bloodbank/bag-type.js";
import bloodBagTypeEnum from "@/constants/bloodbank/blood-product-type-enum.js";
import printTemplate from "@/components/bloodbank/BloodBagLabelTemplate";
import printJS from "print-js";
import moment from "moment";
import VetConfirmModal from "@/components/bloodbank/controls/vet-confirm-modal/VetConfirmModal.vue";

export default {
  components: {
    "blood-bag-label": bloodBagLabel,
    "print-template": printTemplate,
    VetConfirmModal: VetConfirmModal
  },
  props: {
    donation: Object,
    mode: {
      default: "view"
    }
  },
  data() {
    return {
      myProgress: 4,
      myText: "Blood Collect",
      bagTypeEnum: bagTypeEnum,
      petTypeEnums: PetTypeEnums,
      bloodBagTypeEnum: bloodBagTypeEnum,
      moment: moment,
      isPrintPreviewShow: false
    };
  },
  computed: {
    ...mapState({
      hospital: state => state.hospital.profile,
      vets: state => state.hospital.vets
    }),
    suggestedQuantity: function() {
      let maximumQuantity =
        this.donation.pet.type.toUpperCase() === "DOG" ? 450 : 50;

      let suggestedValue =
        this.donation.medicalRecord.weight * this.suggestedQuantityMultiplier;
      return suggestedValue > maximumQuantity
        ? maximumQuantity
        : suggestedValue;
    },
    suggestedQuantityMultiplier: function() {
      return this.donation.pet.type.toUpperCase() === "DOG" ? 15 : 10;
    },
    selectedVet: function() {
      let vet = this.donation.bloodCollectInfo.collector;
      return this.vets.find(v => v.id === vet);
    }
  },
  mounted: function() {
    if (!this.donation.isBloodCollectSubmitted) {
      if (
        this.donation.bloodCollectInfo.expiryDate === null ||
        this.donation.bloodCollectInfo.expiryDate === ""
      ) {
        this.donation.bloodCollectInfo.expiryDate = moment(
          this.donation.bloodCollectInfo.collectDate
        )
          .add(28, "days")
          .toDate();
      }

      this.donation.bloodCollectInfo.originalQuantity = this.suggestedQuantity;
    }
  },
  methods: {
    setIsPrintPreview: function() {
      this.isPrintPreviewShow = !this.isPrintPreviewShow;
    },
    // eslint-disable-next-line no-unused-vars
    onPrintButtonClick: function(event) {
      this.$validator.validateAll().then(result => {
        if (!result) {
          this.$notify({
            message: "ข้อมูลผิดพลาด กรุณากรอกข้อมูลให้ถูกต้อง",
            type: "error"
          });
          return;
        }
        let a = document.getElementById("printTemplate");
        a.style.display = "inline-block";
        printJS({
          printable: "printTemplate",
          type: "html",
          targetStyles: ["*"]
        });
      });
    },
    getCollectDate(dateStr) {
      var collectDate = new Date(dateStr);
      var date = collectDate.getDate();
      var month = collectDate.getMonth();
      var year = collectDate.getFullYear();
      var hour = collectDate.getHours();
      var min = collectDate.getMinutes();

      var monthName = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ];
      return (
        date + " " + monthName[month] + " " + year + " " + hour + ":" + min
      );
    }
  },
  watch: {
    "donation.bloodCollectInfo.collectDate": function(val) {
      this.donation.bloodCollectInfo.expiryDate = moment(val).add(28, "days");
    }
  }
};
</script>

<style lang="scss">
  .at-modal {
    max-width: 800px !important;
    width: 100% !important;

    .at-modal__title {
      width: 100%;
    }
  }

  .donation-step-4-exam {
    .medical-record-card {
      border-radius: 4px;
      min-height: 40px;
      text-align: center;
      color: white;
      background: #33495d;
    }

    .medical-record-card > .el-col {
      transform: translateY(+20%);
    }

    .img-label-preview {
      border: solid;
      border-width: 2px;
      padding-top: 20px;
      padding-bottom: 40px;
    }

    .main-title {
      margin-top: 24px;
    }

    .main-detail {
      font-size: 45px;
      margin-left: 20px;
    }

    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100% !important;
    }
  }
</style>
