import ApptListPage from '@/pages/appointment/ApptListPage.vue'
import ApptDetailPage from '@/pages/appointment/ApptDetailPage.vue'
import ApptSelectPetPage from '@/pages/appointment/ApptSelectPetPage.vue'
import ApptSelectActivitiesPage from '@/pages/appointment/ApptSelectActivitiesPage.vue'
import ApptMarkdonePage from '@/pages/appointment/ApptMarkdonePage.vue'

const routes = [
  {
    path: '/appointments',
    name: 'appointments',
    component: ApptListPage,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin']
    }
  },
  {
    path: '/appointments/pets',
    name: 'appointment-select-pet',
    component: ApptSelectPetPage,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin']
    }
  },
  {
    path: '/appointments/pet/:id/activities',
    name: 'appointment-select-activities',
    component: ApptSelectActivitiesPage,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin']
    }
  },
  {
    path: '/appointment/:id',
    name: 'appointment-detail',
    component: ApptDetailPage,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin']
    }
  },
  {
    path: '/appointment/:id/markdone',
    name: 'appointment-markdone',
    component: ApptMarkdonePage,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin']
    }
  }
]

export default routes