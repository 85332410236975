<template>
  <ContentBox class="search-section">
    <h2 class="title">Search</h2>
    <el-row :gutter="20">
      <el-col :md="12">
        <el-input placeholder="Product Name, Barcode" size="small"
          v-model="form.search"
          clearable
          @keyup.enter.native="onSearch"
          @clear="onClearSearch"
        >
          <el-button slot="append" icon="el-icon-search" @click="onSearch"></el-button>
        </el-input>
      </el-col>
      <el-col :md="12">
        Product Type
        <el-select placeholder="Select Product Type" size="small"
          clearable
          v-model="form.typeId"
          @change="onSearch"
          @clear="onClearTypeId"
        >
          <el-option
            v-for="type in types"
            :key="type.id"
            :value="type.id"
            :label="`${type.name}`"
          ></el-option>
        </el-select> 
      </el-col>
    </el-row>
  </ContentBox>
</template>

<script>
import ContentBox from "@/components/container/ContentBox"

export default {
  props: ['search', 'types', 'selectedTypeId'],
  components: {
    ContentBox
  },
  data() {
    return {
      form: {
        search: this.search,
        typeId: this.selectedTypeId,
      }
    }
  },
  watch: {
    search(value) {
      this.form.search = this.search
    },
    type(value) {
      this.form.typeId = this.selectedTypeId
    }
  },
  methods: {
    onSearch () {
      this.$emit('search', this.form)
    },
    onClearSearch () {
      this.form.search = ''
      this.$emit('search', this.form)
    },
    onClearTypeId () {
      this.form.typeId = ''
      this.$emit('search', this.form)
    },
  }
}
</script>

<style lang="scss" scoped>
.search-section {
  .title {
    margin: 0;
    padding: 0;
    font-size: 16px;
    margin-bottom: 5px;
  }
}
</style>


