<template>
  <div class="data-table-container">
    <div class="header">
      <span class="total">{{ itemCount | number }}</span> RESULT
    </div>
    <table class="data-table">
      <thead>
        <tr>
          <th :style="{ padding: '0' }"></th>
          <th class="date-time">
            Date time
          </th>
          <th class="type text-center">
            Type
          </th>
          <th class="detail text-center">
            Detail
          </th>
          <th class="income text-center">
            Income
          </th>
          <th class="expense text-center">
            Expense
          </th>
          <th class="action">
            &nbsp;
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items.results" :key="item.id">
          <td
            :style="{
              padding: '0',
              width: '21px',
              position: 'relative',
              overflow: 'hidden',
            }"
          >
            <Badge
              v-if="item.is_reverted"
              background-color="#90d5e8"
              color="white"
              :style="{
                width: '8em',
                fontSize: '0.675rem',
                transform: 'translate(-50%, -50%) rotate(-90deg)',
                position: 'absolute',
                top: '50%',
                left: '50%',
              }"
              >Void</Badge
            >
          </td>
          <td>
            <div>{{ item.datetime | toThaiDate }}</div>
            <div class="subtitle">
              {{ item.datetime | toHHMM }}
            </div>
          </td>
          <td class="text-center">
            <div>
              {{ (item.transaction_type && item.transaction_type.name) || "-" }}
            </div>
          </td>
          <!-- <td
            class="detail text-center"
            v-html="
              item.ref_content_type &&
              ['medicalrecord', 'standalonepos'].includes(
                item.ref_content_type.model
              )
                ? `${item.ref_id}${
                    item.receipt
                      ? `, <b>${item.receipt.receipt_number}</b>`
                      : ''
                  }`.trim()
                : item.description || '-'
            "
          ></td> -->
          <td
            class="detail text-center"
            v-html="item.description"
          ></td>
          <td class="text-center">
            <div v-if="!item.is_expense">{{ item.total | currency }}</div>
          </td>
          <td class="text-center">
            <div v-if="item.is_expense" style="color: #d0021b;">
              {{ item.total | currency }}
            </div>
          </td>
          <td style="text-align: right;">
            <el-button
              size="mini"
              @click="$router.push({name: 'income-expense-detail', params: { id: item.id },})" >
              See Detail
              <i class="el-icon-arrow-right el-icon-right"></i>
            </el-button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="not-found" v-if="items.length === 0">
      ไม่พบข้อมูลที่ต้องการค้นหา
    </div>
    <el-pagination
      class="pagination"
      background
      layout="prev, pager, next"
      :current-page="currentPage"
      :page-size="pageSize"
      :total="total"
      @current-change="(page) => $emit('current-change', page)"
    ></el-pagination>
  </div>
</template>

<script>
import Badge from "@/components/common/Badge";

export default {
  components: { Badge },

  props: {
    items: {
      type: Object,
      required: true,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    itemCount() {
      if (this.items && this.items.count) {
        return this.items.count || 0;
      }
      return 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.data-table-container {
  .header {
    margin-bottom: 5px;
  }
}
.data-table {
  width: 100%;
  border-collapse: collapse;

  a {
    color: #333;
    text-decoration: none;
    &:hover {
      color: #02b0e1;
    }
  }

  thead {
    tr {
      border: #e6eaee 1px solid;

      th {
        padding: 5px;
        font-weight: normal;
        color: #7f8fa4;
        background-color: #f5f8fa;
        font-size: 0.9em;

        &.date-time {
          width: 120px;
        }

        &.detail {
          width: 280px;
        }

        &.action {
          width: 80px;
        }
      }
    }
  }

  tbody {
    tr {
      height: 82px;

      td {
        position: relative;
        padding: 5px;
        font-size: 0.9em;

        .subtitle {
          color: #666;
        }

        .thumbnail {
          display: inline-block;
          width: 50px;
          height: 50px;
          overflow: hidden;
          background: #eee;
          border-radius: 5px;
          margin-right: 10px;

          > img {
            width: 50px;
            height: 50px;
            border-radius: 5px;
          }
        }

        .info {
          display: inline-block;
          width: calc(100% - 65px);
          vertical-align: top;
        }

        .name,
        .subtitle {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &.detail > .ellipsis {
          width: 280px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
}

.not-found {
  padding: 20px 0;
  text-align: center;
}

.pagination {
  margin-top: 20px;
  text-align: right;
}
</style>
