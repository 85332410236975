<template>
  <div class="hospital-profile-page">
    <Subnavbar>
      <template slot="title">Hospital Profile</template>
      <template slot="actions">
        <button v-if="!isEdit"
          class="btn btn-large btn-primary"
          @click="onEdit"
        >
          <img src="@/assets/icons/icon-edit.svg">
          Edit
        </button>
        <button v-if="isEdit"
          class="btn btn-large btn-default"
          @click="onCancelEdit"
        >
          Cancel
        </button>
        <button v-if="isEdit"
          class="btn btn-large btn-primary"
          @click="onVerify"
        >
          Save
        </button>
      </template>
    </Subnavbar>
    
    <div class="el-row">
      <div class="el-col el-col-24 el-col-md-8 content-padding">
        <ContentBox>
          <div class="container-center">
            <input type="file" id="file" ref="file" @change="handleFileUpload" style="display: none"/>
            <img v-if="form.logo" :src="`${form.logo.large}`"
              alt="Hospital Profile Avatar"
              :class="`Default-Hospital-Logo ${ isEdit ? 'edit' : ''}`"
              style="border-radius: 64px"
              @click="openFileUpload"
            >
          </div>
          <div v-if="!isEdit" class="hospital-name">{{ form.name }}</div>
          <el-input v-if="isEdit" class="hospital-name" v-model="form.name" placeholder="กรอกชื่อสถานพยาบาล"></el-input>
          <el-button v-if="!isEdit" class="qrcode" @click="printQRCode">Print QR Code</el-button>
          <el-form class="top" ref="form" :model="form" label-position="top" label-width="120px">
            <el-form-item label="Hospital ID" class="hospital-id">
              <span class="bigger">{{ form.id }}</span>
            </el-form-item>
            <el-form-item label="Username">
              <!-- <el-input v-if="isEdit" :value="user.username" placeholder="กรอก Username"></el-input> -->
              <!-- <span class="bigger" v-if="!isEdit">{{ user.username }}</span> -->
              <span class="bigger">{{ user.username }}</span>
            </el-form-item>
            <el-form-item label="Website">
              <el-input v-if="isEdit" v-model="form.website" placeholder="กรอกเว็บไซต์"></el-input>
              <span v-if="!isEdit">{{ form.website }}</span>
            </el-form-item>
            <el-form-item label="Line ID">
              <el-input v-if="isEdit" v-model="form.line_id" placeholder="Line ID"></el-input>
              <span v-if="!isEdit">{{ form.line_id }}</span>
            </el-form-item>
            <el-form-item label="Facebook">
              <el-input v-if="isEdit" v-model="form.facebook_id" placeholder="Facebook URL"></el-input>
              <span v-if="!isEdit">{{ form.facebook_id }}</span>
            </el-form-item>
            <el-form-item label="Instagram">
              <el-input v-if="isEdit" v-model="form.instragram_id" placeholder="Instragram ID"></el-input>
              <span v-if="!isEdit">{{ form.instragram_id }}</span>
            </el-form-item>
          </el-form>
        </ContentBox>
        <ContentBox>
          <el-form class="top" ref="form" :model="form" label-position="top" label-width="120px">
            <el-form-item>
              <p>Prefix username</p>
              <el-input v-if="isEdit" v-model="form.staff_username_prefix" :minlength="1" :maxlength="3" placeholder="AER"></el-input>
              <template v-if="!isEdit">
                <p v-if="form.staff_username_prefix">{{ form.staff_username_prefix }}</p>
                <p v-else>-</p>
              </template>
              <p class="small-hint">ตั้งคำนำหน้า Username Staff ที่ใช้งานโรงพยาบาลนี้ คำนำหน้าจะไม่ซ้ำกันในระบบ  VRemind</p>
            </el-form-item>
          </el-form>
        </ContentBox>
        <ContentBox v-if="currentPlan">
          <el-form class="credit-form" label-position="top">
            <h2 class="title">Plan/Credit</h2>
            <el-form-item label="Plan">
              <span>{{ currentPlan.name }}</span>
            </el-form-item>
            <el-form-item label="Credit Available">
              <div>{{ currentPlan.credit_section.credit_remain | number }} Credits</div>
            </el-form-item>
          </el-form>
          <!-- <div style="text-align: center;">
            <router-link :to="{ name: 'plan-and-price'}"
            class="btn btn-outline btn-small" 
              style="width: 80%; margin: 0 auto 20px; display: inline-block; text-decoration: none;" >
              Change Plan/Add Credit
            </router-link>
          </div> -->
        </ContentBox>
        <button class="btn btn-primary btn-large" 
          @click="gotoiTaam('/hospital/me/')"
          style="width:100%; margin-left:0; text-decoration:none;" >
          Hospital Profile iTaam
        </button>
      </div>
      <div class="el-col el-col-24 el-col-md-16 content-padding">
        <ContentBox>
          <div class="topic">รายละเอียดสถานพยาบาล</div>
            <el-form class="top" ref="form" :model="form" label-position="top" label-width="120px">
              <el-form-item label="อีเมล์ของสถานพยาบาลสำหรับลูกค้า" style="width: 260px">
                <span v-for="(item, index) in form.contact_emails" :key="index">         
                  <el-input v-if="isEdit" v-model="form.contact_emails[index]" :placeholder="`กรอกอีเมลล์ที่ ${ index+1 }`" style="margin-bottom: 6px;"></el-input>
                  <div v-if="!isEdit">{{ form.contact_emails[index] }}</div>
                </span>
                <span v-if="isEdit" class="btn-add" @click="addEmail">
                  <span >เพิ่มอีเมลล์</span>
                  <img src="@/assets/icons/icon-add.png">
                </span>
              </el-form-item>
              <el-form-item label="เบอร์ติดต่อสถานพยาบาลสำหรับลูกค้า" style="width: 260px">
                <span v-for="(item, index) in form.contact_phones" :key="index">         
                  <el-input 
                    v-if="isEdit" 
                    v-model="form.contact_phones[index]" 
                    :placeholder="`กรอกเบอร์โทรศัพท์ที่ ${ index+1 }`" 
                    style="margin-bottom: 6px;"
                  ></el-input>
                  <div v-if="!isEdit">{{ form.contact_phones[index] }}</div>
                </span>
                <span v-if="isEdit" class="btn-add" @click="addPhone">
                  <span>เพิ่มเบอร์ติดต่อ</span>
                  <img src="@/assets/icons/icon-add.png">
                </span>
              </el-form-item>  
              <el-form-item label="เวลาทำการ">
                <!-- <el-input v-if="isEdit" v-model="form.open_time" style="width: 100%"></el-input> -->
                <el-input
                    v-if="isEdit"
                    v-model="form.open_time"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 3}"
                    placeholder="กรอกเวลาทำการ"
                    size="large"
                ></el-input>
                <span v-if="!isEdit">{{ form.open_time }}</span>
              </el-form-item>  
              <div class="subtopic">Map</div>
              <el-form-item label="Google Map" v-if="isEdit">
                <el-input 
                  v-model="findAddress" 
                  @keypress="findAddress = $event.target.value" 
                  @input="findAddress = $event.target.value" 
                  @keyup.enter.native="OnFindAddressChange" 
                  placeholder="กรอกสถานที่" 
                  style="width: 100%"
                ></el-input>
              </el-form-item>  
            </el-form>

            <LocationPicker v-if="loaded"
              v-on:locationChange="locationChange"
              v-on:placeChange="placeChange"
              :findPlace="findAddress" 
              :apiKey="googleApiKey"
              :initialLat="form.latitude"
              :initialLng="form.longitude"
              ref="map"
              />

            <div class="subtopic">Address</div>
            <el-form class="address" ref="form" :model="form" label-width="120px">
              <el-form-item label="บ้านเลขที่">
                <el-input v-if="isEdit" v-model="form.address" placeholder="กรอกบ้านเลขที่" style="width: 260px"></el-input>
                <span v-if="!isEdit">{{ form.address }}</span>
              </el-form-item>
              <el-form-item label="แขวง/ตำบล">
                <el-input v-if="isEdit" v-model="form.sub_district" placeholder="กรอกแขวง/ตำบล" style="width: 260px"></el-input>
                <span v-if="!isEdit">{{ form.sub_district }}</span>
              </el-form-item>
              <el-form-item label="เขต/อำเภอ">
                <el-input v-if="isEdit" v-model="form.district" placeholder="กรอกเขต/อำเภอ" style="width: 260px"></el-input>
                <span v-if="!isEdit">{{ form.district }}</span>
              </el-form-item>
              <el-form-item label="จังหวัด">
                <el-input v-if="isEdit" v-model="form.province" placeholder="กรอกจังหวัด" style="width: 260px"></el-input>
                <span v-if="!isEdit">{{ form.province }}</span>
              </el-form-item>
              <el-form-item label="รหัสไปรษณีย์">
                <el-input v-if="isEdit" v-model="form.zip_code" placeholder="กรอกรหัสไปรษณีย์" style="width: 260px"></el-input>
                <span v-if="!isEdit">{{ form.zip_code }}</span>
              </el-form-item>          
            </el-form>

        </ContentBox>

        <RankForm
          :first.sync="expenseRank.first"
          :second.sync="expenseRank.second"
          :third.sync="expenseRank.third"
          :is-edit="isEdit"
          style="margin-bottom: 64px;"
        />
      </div>
    </div>
    
    <ConfirmDialog
      :visible="showConfirmDialog"
      @cancel="showConfirmDialog = false"
      @confirm="onSave"
    >
      <template slot="header">
        <h1 class="title">ยืนยันการบันทึกโปรไฟล์?</h1>
      </template>
    </ConfirmDialog>

  </div>
</template>

<script>
import { mapState } from "vuex"
import { googleApiKey } from '@/constants'
import ContentBox from "@/components/container/ContentBox"
import Subnavbar from "@/components/navigation/Subnavbar"
import LocationPicker from '@/components/LocationPicker'
import ConfirmDialog from "@/components/dialog/ConfirmDialog"
import RankForm from "./components/expense/RankForm";

export default {
  data() {
    return {
      form: {
        "id": 18,
        "contact_emails": [],
        "contact_phones": [],
        "name": "",
        "staff_username_prefix": "",
        "address": "",
        "sub_district": "",
        "district": "",
        "province": "",
        "zip_code": "",
        "latitude": null,
        "longitude": null,
        "open_time": "",
        "phone": "",
        "line_id": "",
        "facebook_id": "",
        "instragram_id": "",
        "website": "",
        "has_company_detail": false,
        "company_name": "",
        "company_taxpayer_no": "",
        "company_phone": "",
        "company_address": "",
        "company_sub_district": "",
        "company_district": "",
        "company_province": "",
        "company_zip_code": "",
        "has_vat": false
      },
      undoForm: {},
      expenseRank: {
        first: 0,
        second: 0,
        third: 0
      },
      googleApiKey: googleApiKey,
      loaded: false,
      isEdit: false,
      findAddress: "",
      lastFindAddress: "",
      showConfirmDialog: false,
      qrcodePage: {
        image1: "",
        image2: "",
        // eslint-disable-next-line
        image3: require('@/assets/brand/v-remind-logo-text.png'),
      }
    }
  },
  components: {
    ContentBox,
    Subnavbar,
    LocationPicker,
    ConfirmDialog,
    RankForm,
  },
  computed: {
    ...mapState({
      profile: state => state.hospital.profile,
      user: state => state.auth.user,
      qrcode: state => state.hospital.qrcode,
      currentPlan: state => state.plan.currentPlan
    })
  },
  methods: {
    gotoiTaam(url) {
      window.open(process.env.VUE_APP_ITAAM_FRONTEND_ENDPOINT + url, '_blank');
    },
    openFileUpload() {
      if (this.isEdit)
        this.$refs.file.click();
    },
    handleFileUpload() {
      if (this.$refs.file.files[0]) {
        this.form.logo.large =  window.URL.createObjectURL(this.$refs.file.files[0])
      }
    },
    async imageURLToBase64(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
          callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    },
    printQRCode() {
      var qrcodeWindow = window.open("", "Hospital QRCode", "");
      qrcodeWindow.document.write(`
        <link href="https://fonts.googleapis.com/css?family=Kanit" rel="stylesheet">

        <div class="qrcodePage">
          <img class="image1" src="${ this.qrcodePage.image1 }"/>
          <div class="label1">${ this.profile.name }</div>
          <div class="label2">ลงทะเบียนสัตว์เลี้ยง<br>สแกนที่นี่</div>
          <img class="image2" src="${ this.qrcodePage.image2 }"/>
          <div class="label3">เพื่อรับการแจ้งเตือนนัดหมาย<br>และ รับสมุดวัคซีน ONLINE</div>
          <div class="footer">
            <img class="image3" src="${ this.qrcodePage.image3 }">
            <div class="label4">Power by : </div>
          </div>
        </div>

        <style>
          .qrcodePage {
            width: 100%;
            height: 100%;
            margin: 0;
            text-align: center;
          }

          .image1 {
            width: 135px;
            height: 135px;
          }

          .image2 {
            width: 460px;
            margin-top: -20px;
            margin-bottom: -20px;
          }

          .image3 {
            float: right;
            width: 163.5px;
            height: 40px;
            vertical-align: middle;
          }

          .label1 {
            width: 100%;
            font-family: Kanit;
            font-size: 32px;
            margin-top: 15px;
          }

          .label2 {
            width: 100%;
            font-family: Kanit;
            font-size: 54px;
            line-height: 1.29;
            letter-spacing: -1.1px;
            margin-top: 15px;
          }

          .label3 {
            width: 100%;
            font-family: Kanit;
            font-size: 40px;
            letter-spacing: -0.9px;
          }

          .label4 {
            float: right;
            width: 96px;
            height: 19px;
            vertical-align: middle;
            margin-right: 10px;
          }

          .footer {
            width: 100%;
            height: auto;
            margin-top: 40px;
            margin-bottom: 17px;
          }
        </style>
      `);
      qrcodeWindow.onfocus = function(){ 
        qrcodeWindow.print();
        qrcodeWindow.close();
      }
    },
    validEmail: function (email) {
      // eslint-disable-next-line
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    addEmail() {
      if (this.form.contact_emails.indexOf("") > -1) return;
      this.form.contact_emails.push("");
    },
    addPhone() {
      if (this.form.contact_phones.indexOf("") > -1) return;
      this.form.contact_phones.push("");
    },
    onEdit() {
      this.isEdit = true;
      this.formatted_address = "";
      this.$refs.map.initMap();
    },
    onCancelEdit() {
      this.isEdit = false;
      this.form = JSON.parse(JSON.stringify(this.undoForm));
      this.$refs.map.initMap();
    },
    onVerify() {
      this.showConfirmDialog = true;
      var i = this.form.contact_emails.length;
      while (i--) {
          if (this.form.contact_emails[i] == "") { 
              this.form.contact_emails.splice(i, 1);
          } 
      }

      i = this.form.contact_phones.length;
      while (i--) {
          if (this.form.contact_phones[i] == "") { 
              this.form.contact_phones.splice(i, 1);
          } 
      }

      this.form.contact_emails.forEach((email) => {
        if (!this.validEmail(email)) {
          this.$notify({
            message: `อีเมลล์ ${email} มีรูปแบบอีเมลล์ไม่ถูกต้อง!`,
            type: "warning"
          })
          this.showConfirmDialog = false;
        }
      });      
    },
    async onSave() {
      this.showConfirmDialog = false;
      await this.updateProfile();
      await this.updateExpenseRank();
      this.undoForm = JSON.parse(JSON.stringify(this.form));
      this.$refs.map.initMap();
    },
    locationChange(location) {
      if (!this.isEdit) { this.$refs.map.initMapLock(); return; }
      this.form.latitude = location.lat;
      this.form.longitude = location.lng;
    },
    placeChange(place) {
      if (!this.isEdit) { return; }
      this.findAddress = place.formatted_address;

      this.form.address = place.address;
      this.form.sub_district = place.sub_district;
      this.form.district = place.district;
      this.form.province = place.province;
      this.form.zip_code = place.zip_code;
    },
    OnFindAddressChange () {
      this.$refs.map.findLocation(this.findAddress);
    },
    async updateProfile() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        await this.$store.dispatch("hospital/updateProfile", {
          data: {
            contact_emails: this.form.contact_emails,
            contact_phones: this.form.contact_phones,
            name: this.form.name,
            staff_username_prefix: this.form.staff_username_prefix,
            address: this.form.address,
            sub_district: this.form.sub_district,
            district: this.form.district,
            province: this.form.province,
            zip_code: this.form.zip_code,
            latitude: this.form.latitude,
            longitude: this.form.longitude,
            open_time: this.form.open_time,
            phone: this.form.phone,
            line_id: this.form.line_id,
            facebook_id: this.form.facebook_id,
            instragram_id: this.form.instragram_id,
            website: this.form.website,
          }
        })

        if (this.$refs.file.files[0]) {
          var formData = new FormData()
          formData.append('logo', this.$refs.file.files[0])
          await this.$store.dispatch("hospital/updateProfile", {
            formData: formData,
          })
        }
        this.isEdit = false;
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)

        if (error.staff_username_prefix) {
          await this.$store.dispatch("ui/showFetchErrorMsg", error.staff_username_prefix[0])
        } else {
          await this.$store.dispatch("ui/showFetchErrorMsg")
        }
      }
    },

    async updateExpenseRank() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        await this.$store.dispatch("hospital/updateExpenseRankCondition", {
          data: this.expenseRank
        })
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        await this.$store.dispatch("ui/showFetchErrorMsg")
      }
    },
    
  },
  async mounted() {
    try {
      await this.$store.dispatch("ui/setIsFullScreenLoading", true)
      await this.$store.dispatch("hospital/getProfile")
      await this.$store.dispatch("hospital/getQRCode")
      const expenseRankResult = await this.$store.dispatch('hospital/getExpenseRankCondition');
      this.expenseRank = ((rank) => {
        return {
          first: +rank.first,
          second: +rank.second,
          third: +rank.third
        };
      })(expenseRankResult);
      this.loaded = true;
      await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      this.qrcodePage.image1 = this.profile.logo.large;
      this.qrcodePage.image2 = this.qrcode.qr_img;
      this.imageURLToBase64(this.qrcodePage.image1, (result) => {
        this.qrcodePage.image1 = result;
      });
      this.imageURLToBase64(this.qrcodePage.image2, (result) => {
        this.qrcodePage.image2 = result;
      });
      this.imageURLToBase64(this.qrcodePage.image3, (result) => {
        this.qrcodePage.image3 = result;
      });
      await this.$store.dispatch("plan/getCurrentPlan")
    } catch (error) {
      await this.$store.dispatch("ui/showFetchErrorWithReloadMsg")
    }
  },
  watch: {
    profile(newValue) {
      this.form = JSON.parse(JSON.stringify(newValue));
      this.undoForm = JSON.parse(JSON.stringify(this.form));
    }
  }
}
</script>

<style lang="scss">
.hospital-profile-page {
  .el-input {
    width: 260px;
  }

  #main {
    max-width: 1060px !important;
  }

  .btn-add {
    float: right;
    width: auto;
    height: 30px;
    // margin-top: 6px;

    span {
      font-family: Default;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.3px;
      text-align: right;
      color: #4a90e2;

      margin-right: 11px;
    }

    img {
      width: 15px;
      height: 15px;
      object-fit: contain;

      vertical-align: middle;
    }

    &:hover {
      color: #4180c7;
      cursor: pointer;
    }
  }

  .location-picker-container {
    margin-bottom: 20px;
  }

  .topic {
    font-family: Default;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #000000;

    margin-bottom: 20px;
  }

  .subtopic {
    font-family: Default;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #9b9b9b;

    margin-bottom: 12px;
  }

  .address {
    span {
      display: block;
      margin-top: 6px;
      // vertical-align: middle;
    }
  }

  .el-form-item {
    .el-input {
      line-height: auto;
    }

    .el-form-item__content {
      line-height: normal;
      font-size: 16px;

      .bigger {
        font-size: 18px;
      }
    }
  }

  .hospital-id {
    // width: 100%;
    border-bottom: 1px solid #e6eaee;
  }

  .container-center {
    width: 100%;
    text-align: center;
  }

  .hospital-name {
    width: 100%;
    text-align: center;
    font-family: Default;
    font-size: 17px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    // line-height: 0;
    letter-spacing: -0.4px;
    text-align: center;
    color: #030303;

    margin-top: 9.4px;
    margin-bottom: 20px;

    input {
      width: 260px;
      font-family: Default;
      font-size: 17px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      // line-height: normal;
      letter-spacing: -0.4px;
      text-align: center;
      color: #030303;
      margin-bottom: 20px;
    }
  }

  .el-form-item__label {
    font-family: Default;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    // line-height: normal;
    letter-spacing: normal;
    color: #9b9b9b;

    text-align: left;
  }

  .qrcode {
    display: block;
    margin: 0 auto;
    width: 259px;
    height: 40px;
    border-radius: 5px;
    background-color: #02b0e1;
    color: white;

    margin-bottom: 23px;

    font-family: Arial;
    font-size: 16px;
    // font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    // line-height: normal;
    letter-spacing: -0.4px;
    color: #ffffff;
  }

  .Default-Hospital-Logo {
    margin-top: 15px;
    width: 128px;
    height: 128px;
    object-fit: contain;

    &.edit {
      cursor: pointer;
    }
  }

  .content-padding {
    box-sizing: border-box;
    padding-left: 8px;
    padding-right: 8px;

    &:first-child {
      padding-right: 8px;

      .content {
        padding-left: 23px;
        padding-right: 28px;
      }
    }
    &:last-child {
      padding-left: 8px;

      .content {
        padding-left: 28px;
        padding-right: 87px;
      }
    }
  }

  .ContentBox {
    margin: 8px;
    padding-right: 8px;
  }

  .el-form.top .el-form-item__label {
    line-height: 0;
    padding-bottom: 8px;
  }

  .credit-form {
    .el-form-item__label {
      line-height: 0;
      padding-bottom: 8px;
    }
  }

  .small-hint {
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a;
  }
}
</style>
