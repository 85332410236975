<template>
  <ContentBox class="appointment-search">
    <h2 class="title">Search</h2>
    <el-row class="custom-row">
      <el-col :md="12">
        <div class="date-group" :gutter="20">
          <el-date-picker type="date" placeholder="Start Date" size="small" v-model="form.start" @change="startDateChange"></el-date-picker>
          <span>to</span>
          <el-date-picker type="date" placeholder="End Date" size="small" v-model="form.end" @change="endDateChange"></el-date-picker>
        </div>
      </el-col>
    </el-row>
    <el-row class="custom-row" :gutter="20">
      <el-col :md="12">
        <el-input placeholder="Pet Name, H.N., Owner, Appointment ID , Tel" size="small"
          v-model="form.search"
          clearable
          @keyup.enter.native="onSearch"
          @clear="onClearSearch"
        >
          <el-button slot="append" icon="el-icon-search" @click="onSearch"></el-button>
        </el-input>
      </el-col>
      <el-col :md="4">
        <el-select
          v-model="form.vetId"
          clearable
          placeholder="Veterinarian"
          size="small"
          @change="onSearch"
          @clear="onClearVetId"
        >
          <el-option
            v-for="vet in vets"
            :key="vet.id"
            :value="vet.id"
            :label="`${vet.first_name} ${vet.last_name}`"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :md="4">
        <el-select placeholder="Group Actitivies" size="small"
          clearable
          v-model="form.activityGroupId"
          @change="onSearch"
          @clear="onClearActivityGroupId"
        >
          <el-option
            v-for="group in activityGroups"
            :key="group.id"
            :value="group.id"
            :label="`${group.name}`"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :md="4">
        <el-select placeholder="Status"
          size="small"
          clearable
          v-model="form.status"
          @change="onSearch"
          @clear="onClearStatus" >
          <el-option :value="appointmentStatus.NO_SHOW" label="No show"></el-option>
          <el-option :value="appointmentStatus.CONFIRM" label="Confirm"></el-option>
          <el-option :value="appointmentStatus.NO_RESPOND" label="No respond"></el-option>
          <el-option :value="appointmentStatus.REQUEST_POSTPONE" label="Request postpone"></el-option>
          <el-option :value="appointmentStatus.POSTPONE" label="Postpone"></el-option>
          <el-option :value="appointmentStatus.DONE" label="Done"></el-option>
        </el-select>
      </el-col>
    </el-row>
  </ContentBox>
</template>

<script>
import ContentBox from "@/components/container/ContentBox"
import { appointmentStatus } from '../../constants'
import { getAppointmentIDFormUrl } from '../../utils'

export default {
  props: ['search', 'startDate', 'endDate', 'status',
  'vets', 'selectedVetId',
  'activityGroups', 'selectedActivityGroupId'],
  components: {
    ContentBox
  },
  data() {
    return {
      appointmentStatus: appointmentStatus,
      form: {
        search: this.search,
        start: this.startDate,
        end: this.endDate,
        status: this.status,
        vetId: this.selectedVetId,
        activityGroupId: this.selectedActivityGroupId
      }
    }
  },
  methods: {
    startDateChange () {
      this.$emit('search', this.form)
    },
    endDateChange (d) {
      if(d) {
        d.setHours(23,59,59,999)
        this.form.end = new Date(d)
      }
      this.$emit('search', this.form)
    },
    onSearch () {
      this.$emit('search', this.form)
    },
    onClearSearch () {
      this.form.search = ''
      this.$emit('search', this.form)
    },
    onClearStatus () {
      this.form.status = ''
      this.$emit('search', this.form)
    },
    onClearVetId () {
      this.form.vetId = ''
      this.$emit('search', this.form)
    },
    onClearActivityGroupId () {
      this.form.activityGroupId = ''
      this.$emit('search', this.form)
    }
  },
  watch: {
    'form.search': function(value) {
      const result = getAppointmentIDFormUrl(value)
      if(result !== '') {
        this.form.search = result
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.appointment-search {
  .title {
    margin: 0;
    padding: 0;
    font-size: 16px;
    margin-bottom: 5px;
  }

  .custom-row {
    margin-bottom: 10px;
  }

  .date-group {
    > span {
      padding: 10px;
    }
  }
}
</style>


