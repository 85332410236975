<template>
  <div class="pet-owner-general-info-form">
    <div class="form-row">
      <label class="form-label">
        ชื่อ
        <span class="required-mark">*</span>
      </label>
      <el-input type="text" name="firstname" v-model="form.firstname" @change="onChange" required></el-input>
    </div>

    <div class="form-row">
      <label class="form-label">
        นามสกุล
        <span class="required-mark">*</span>
      </label>
      <el-input
        type="text"
        v-validate="'required'"
        name="lastname"
        v-model="form.lastname"
        @change="onChange"
        required
      ></el-input>
    </div>

    <div class="form-row">
      <label class="form-label">
        เพศ
        <span class="required-mark">*</span>
      </label>
      <div class="gender-select">
        <el-radio v-model="form.gender" :label="1" border @change="onChange">
          <PetGenderIcon :gender="1" />ชาย
        </el-radio>
        <el-radio v-model="form.gender" :label="0" border @change="onChange">
          <PetGenderIcon :gender="0" />หญิง
        </el-radio>
      </div>
    </div>

    <div class="form-row">
      <label class="form-label">วันเกิด</label>
      <el-date-picker
        v-model="form.birthdate"
        type="date"
        format="yyyy/MM/dd"
        @change="onChange"
        :picker-options="pickerOptions"
        placeholder="กรุณาเลือกวันเกิด"
      ></el-date-picker>
    </div>

    <div class="form-row">
      <label class="form-label">
        เบอร์ติดต่อ
        <span class="required-mark">*</span>
      </label>
      <template v-if="!isEditing">
        <el-input type="tel" name="phone" v-model="form.phone" @change="onChange"></el-input>
        <div class="remark-note">
          <span class="required-mark">*</span> เบอร์โทรศัพท์นี้จะใช้เป็น username ของเจ้าของสัตว์
        </div>
        <div v-if="form.isPhoneExist" class="tel-error">
          <span class="required-mark">*</span> เบอร์โทรศัพท์นี้ลงทะเบียนแล้ว กรุณาพิมพ์เบอร์นี้ ที่ช่อง
          <router-link
            :to="{ name: 'hospitalPetsSearch', query: { search: form.phone? form.phone: '' }}"
          >หน้าค้นหาจาก VRemind</router-link>เพื่อ Add Pet สู่โรงพยาบาลหรือ เพิ่มสัตว์เลี้ยงตัวใหม่ภายใต้เจ้าของเดิม
        </div>
      </template>
      <template v-if="isEditing">
        <div
          style="font-size: .7em; margin: 5px 0; color: #737d89;"
        >สามารถมีได้สูงสุด 3 เบอร์ เลือกเบอร์เตือนได้เพียงเบอร์เดียว</div>
        <el-row>
          <el-col :span="2">
            <el-radio
              v-model="form.reminderPhoneField"
              :label="1"
              @change="onChange"
              style="font-size: .7em;"
            >&nbsp;</el-radio>
          </el-col>
          <el-col :span="6" style="font-size: .7em">ใช้เบอร์นี้ในการแจ้งเตือน</el-col>
          <el-col :span="16">
            <el-input
              type="tel"
              name="phone"
              :disabled="true"
              v-model="form.phone"
              @change="onChange"
            ></el-input>
          </el-col>
        </el-row>
        <div
          class="required-mark"
          style="font-size: .7em; margin: 5px 0;"
        >* เบอร์โทรศัพท์นี้จะใช้เป็น username ของเจ้าของสัตว์</div>
        <el-row>
          <el-col :span="2">
            <el-radio
              v-model="form.reminderPhoneField"
              :label="2"
              @change="onChange"
              style="font-size: .7em;"
            >&nbsp;</el-radio>
          </el-col>
          <el-col :span="6" style="font-size: .7em">ใช้เบอร์นี้ในการแจ้งเตือน</el-col>
          <el-col :span="16">
            <el-input
              type="tel"
              name="phone2"
              v-model="form.phone2"
              @change="onChange"
              style="margin-bottom: 5px;"
            ></el-input>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="2">
            <el-radio
              v-model="form.reminderPhoneField"
              :label="3"
              @change="onChange"
              style="font-size: .7em;"
            >&nbsp;</el-radio>
          </el-col>
          <el-col :span="6" style="font-size: .7em">ใช้เบอร์นี้ในการแจ้งเตือน</el-col>
          <el-col :span="16">
            <el-input type="tel" name="phone3" v-model="form.phone3" @change="onChange"></el-input>
          </el-col>
        </el-row>
      </template>
    </div>

    <div class="form-row">
      <label class="form-label">หมายเลขบัตรประชาชน</label>
      <el-input
        type="tel"
        name="id_card_number"
        placeholder="ใช้สำหรับการออกใบกำกับภาษี"
        v-model="form.id_card_number"
        @change="onChange"
      ></el-input>
    </div>

    <div class="form-row">
      <label class="form-label">อีเมล์</label>
      <el-input
        type="email"
        name="email"
        placeholder="Email"
        v-model="form.email"
        @change="onChange"
      ></el-input>
    </div>
  </div>
</template>


<script>
import PetGenderIcon from "@/components/pet/PetGenderIcon";

export default {
  props: [
    "isEditing",
    "firstname",
    "lastname",
    "gender",
    "birthdate",
    "phone",
    "phone2",
    "phone3",
    "id_card_number",
    "email",
    "isPhoneExist",
    "reminderPhoneField"
  ],
  components: {
    PetGenderIcon
  },
  data() {
    return {
      form: {
        firstname: this.firstname,
        lastname: this.lastname,
        gender: this.gender,
        birthdate: this.birthdate,
        phone: this.phone,
        phone2: this.phone2,
        phone3: this.phone3,
        id_card_number: this.id_card_number,
        email: this.email,
        isPhoneExist: this.isPhoneExist,
        reminderPhoneField: this.reminderPhoneField
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      }
    };
  },
  methods: {
    async onChange() {
      this.$emit("change", this.form);
    }
  },
  watch: {
    firstname(val) {
      this.form.firstname = val;
    },
    lastname(val) {
      this.form.lastname = val;
    },
    gender(val) {
      this.form.gender = val;
    },
    birthdate(val) {
      this.form.birthdate = val;
    },
    phone(val) {
      this.form.phone = val.replace(/[^0-9]/g, "");
    },
    phone2(val) {
      if (val && val !== "") {
        this.form.phone2 = val.replace(/[^0-9]/g, "");
      }
    },
    phone3(val) {
      if (val && val !== "") {
        this.form.phone3 = val.replace(/[^0-9]/g, "");
      }
    },
    reminderPhoneField(val) {
      this.form.reminderPhoneField = val;
    },
    id_card_number(val) {
      this.form.id_card_number = val;
    },
    email(val) {
      this.form.email = val;
    },
    isPhoneExist(val) {
      this.form.isPhoneExist = val;
    }
  }
};
</script>

<style lang="scss">
.pet-owner-general-info-form {
  .form-row {
    margin-bottom: 10px;
  }
  .form-label {
    display: block;
  }
  .gender-select {
    .el-radio {
      margin-right: 5px;
      min-width: 100px;
      text-align: center;
    }
    .el-radio__inner {
      display: none !important;
    }
    .el-radio__label {
      padding-left: 0px;
    }
  }
  .remark-note {
    font-size: 0.8em;
    color: #999;
  }
  .tel-error {
    font-size: 0.8em;
    color: #cd0b15;

    a {
      color: #02b0e1;
      text-decoration: none;
    }
  }
}
</style>
