<template>
  <div class="row">
    <div class="col-md-8 col-md-offset-3 col-xs-12 flex">
      <div
        id="confirmation-button-ok"
        class="confirmation-btn align-content-middle flex-middle"
        :class="{'confirmation-disabled': disabled }"
        @click="onclick(true)"
      >
        <i class="icon icon-check"></i> ผ่าน
      </div>
    </div>
    <div class="col-md-8 col-md-offset-1 col-xs-12 flex">
      <div
        id="confirmation-button-fail"
        class="confirmation-btn align-content-middle flex-middle"
        :class="{'confirmation-disabled': disabled }"
        @click="onclick(false)"
      >
        <i class="icon icon-x"></i> ไม่ผ่าน
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "export-document",
  props: ["isActive", "disabled"],
  mounted: function() {
    if (_.isBoolean(this.isActive)) {
      this.setActive(this.isActive);
    }
  },
  methods: {
    onclick: function(isActive) {
      if (this.disabled) {
        return;
      }
      this.setActive(isActive);
    },

    setActive: function(isActive) {
      var okBtn = document.getElementById("confirmation-button-ok");
      var failBtn = document.getElementById("confirmation-button-fail");
      if (isActive) {
        okBtn.classList.add("active");
        okBtn.classList.add("success");
        failBtn.classList.remove("active");
        failBtn.classList.remove("fail");
        this.$emit("input", true);
      } else {
        failBtn.classList.add("active");
        failBtn.classList.add("fail");
        okBtn.classList.remove("active");
        okBtn.classList.remove("success");
        this.$emit("input", false);
      }
    }
  }
};
</script>

<style scoped>
.confirmation-btn {
  padding: 0px 13px;
  background-color: white;
  border: solid;
  font-size: 20px;
  border-width: 3px;
  border-radius: 10px;
  border-color: rgb(92, 90, 90);
  white-space: nowrap;
}

.confirmation-btn:hover {
  cursor: pointer;
}

.confirmation-btn > .icon {
  font-size: 58px;
  font-weight: 1000;
  color: Black;
}

.confirmation-btn.active.success > .icon {
  color: rgb(31, 245, 31);
}

.confirmation-btn.active.fail > .icon {
  color: rgb(248, 91, 91);
}

.active {
  background-color: #055175;
  color: white;
}

.confirmation-disabled {
  cursor: not-allowed !important;
  opacity: 0.6;
}
</style>
