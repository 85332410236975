<template>
  <canvas id="petOwner_petPerPerson_chart" height="100px"></canvas>
</template>

<script>
import { barChartGenerator, barChartDecorationDataGenerator } from '../../utils/report'

export default {
  name: "PetOwnerPetPerPersonChart",
  props: ['petOwners'],
  methods: {
    generateDataSet: function () {
      let dataGenerated = {};
      dataGenerated.data = [
        this.petOwners.petPerPerson[1],
        this.petOwners.petPerPerson[2],
        this.petOwners.petPerPerson[3],
        this.petOwners.petPerPerson[4],
        this.petOwners.petPerPerson[5],
      ];
      dataGenerated = barChartDecorationDataGenerator(dataGenerated);
      dataGenerated.label = ['1ตัว', '2ตัว', '3ตัว', '4ตัว', '5ตัวขึ้นไป'];
      return dataGenerated;
    },
  },
  watch: {
    'petOwners.petPerPerson': function () {
      if(this.petOwners.petPerPerson)
        barChartGenerator('petOwner_petPerPerson_chart', this.generateDataSet())
    },
  },
  mounted() {
    if(this.petOwners.petPerPerson)
      barChartGenerator('petOwner_petPerPerson_chart', this.generateDataSet())
  },
}
</script>
