import * as vuexHelper from "../helpers/vuexHelper";
import { coreAPI } from "../../services/apis";
import * as endpoints from "../../services/endpoints";

const config = {
  default: {
    api: coreAPI
  },
  state: {
   currentPlan: null
  },
  getters: {
    getCurrentPlan(state) {
      return state.currentPlan
    }
  },
  mutations: {
		UPDATE_CURRENT_PLANT(state, data) {
			state.currentPlan = data
    },
  },
  asyncActions: [
    {
      name: "getCurrentPlan",
      method: "get",
      url: `${endpoints.plan}/currentplan/`,
      onSuccess: (result, payload, { commit }) => {
        if (result) {
          commit('SET_CURRENT_PLAN', result);
          localStorage.setItem("currentPlan", JSON.stringify(result));
        }
      },
      onError: (result, payload, { commit }) => {
        localStorage.removeItem("currentPlan");
        commit('SET_CURRENT_PLAN', null);
      }
    }
  ]
};

export default vuexHelper.createStoreModule(config);
