<template>
  <div class="layout-container">
    <PlainNavbar>
      <img 
        slot="logo"
        src="@/assets/brand/v-remind-logo-text.png"
        alt="VRemind Logo"
        style="height: 40px;margin: 4px 0 0;">
    </PlainNavbar>
    <router-view></router-view>
  </div>
</template>

<script>
import PlainNavbar from '@/components/navigation/PlainNavbar'

export default {
  components: {
    PlainNavbar
  }
}
</script>

<style lang="scss" scoped>
.layout-container {
  background-color: $gray-background;
  min-height: 500px;
  padding-bottom: 40px;
}
</style>
