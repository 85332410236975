<template>
    <div>
        <div class="title">Overview</div>
        <Doughnut 
            :chartData="chartData"
            :options="options"
            :responsive="true"
            :style="customStyles"/>
        <div class="bottom-text text-right">คิดในช่วงเวลา 18 เดือน นับย้อนจากวันปัจจุบัน</div>
    </div>
</template>

<script>
import Doughnut from './chart/Doughnut'

export default {
    components: {
        Doughnut
    },
    props: {
        chartData: {
            type: Object,
            default: null
        },
        options: {
            type: Object,
            default: null
        },
        customStyles: {
            type: String,
            default: ''
        }
    },
    mounted () {
    }
}
</script>

<style lang="scss" scoped>
.title {
    font-size: 18px;
    color: #354052;
    font-weight: normal;
    font-family: "Roboto","Lucida Grande","DejaVu Sans","Bitstream Vera Sans",Verdana,Arial,sans-serif;
}
.bottom-text {
    margin-top: 24px;
    font-size: 14px;
}
</style>