<template>
  <div class="request-step-1-exam" style="width: 100%">
    <el-row>
      <slot
        class="headSlot"
        name="header"
        :currentProgress="this.myProgress"
        :currentText="this.myText"
        :currentVetType="myVetType"
      ></slot>
    </el-row>

    <el-row>
      <el-row class="medical-record-card">
        <el-col>
          <img src="@/assets/bloodbank/file.png" height="20">
          <span>Medical Record</span>
        </el-col>
      </el-row>
      <!-- Content -->
      <el-row>
        <el-card style="padding-left: 5px; padding-right: 5px;">
          <el-row :gutter="5">
            <!-- Physical Exam -->
            <el-col :span="12" class="left pane">
              <el-card style="width: 100%;">
                <h4>Physical Exam</h4>

                <el-row :gutter="5">
                  <el-col :span="8">
                    <top-label-input
                      :disabled="request.isCaseClosed()"
                      :isRequired="true"
                      v-validate="'required|decimal'"
                      data-vv-value-path="vModel"
                      data-vv-name="weight"
                      :isValid="!errors.has('weight')"
                      @updated="onTopLabelInputUpdated"
                      prop-name="weight"
                      label="Weight (kg.)"
                      :model="request.recipientProfileInfo.medicalRecord.weight"
                    ></top-label-input>
                  </el-col>
                  <el-col :span="8">
                    <top-label-input
                      :disabled="request.isCaseClosed()"
                      v-validate="'decimal'"
                      data-vv-value-path="vModel"
                      data-vv-name="temp"
                      :isValid="!errors.has('temp')"
                      @updated="onTopLabelInputUpdated"
                      prop-name="temp"
                      label="Temp (°C)"
                      :model="request.recipientProfileInfo.medicalRecord.temp"
                    ></top-label-input>
                  </el-col>
                  <el-col :span="8">
                    <top-label-input
                      :disabled="request.isCaseClosed()"
                      v-validate="'decimal'"
                      data-vv-value-path="vModel"
                      data-vv-name="hr"
                      :isValid="!errors.has('hr')"
                      @updated="onTopLabelInputUpdated"
                      prop-name="hr"
                      label="HR (bmp)"
                      :model="request.recipientProfileInfo.medicalRecord.hr"
                    ></top-label-input>
                  </el-col>
                </el-row>

                <el-row :gutter="5">
                  <el-col :span="8">
                    <top-label-input
                      :disabled="request.isCaseClosed()"
                      v-validate="'decimal'"
                      data-vv-value-path="vModel"
                      data-vv-name="rr"
                      :isValid="!errors.has('rr')"
                      @updated="onTopLabelInputUpdated"
                      prop-name="rr"
                      label="RR (tpm)"
                      :model="request.recipientProfileInfo.medicalRecord.rr"
                    ></top-label-input>
                  </el-col>
                  <el-col :span="8">
                    <top-label-input
                      :disabled="request.isCaseClosed()"
                      v-validate="'decimal'"
                      data-vv-value-path="vModel"
                      data-vv-name="crt"
                      :isValid="!errors.has('crt')"
                      @updated="onTopLabelInputUpdated"
                      prop-name="crt"
                      label="CRT (Sec)"
                      :model="request.recipientProfileInfo.medicalRecord.crt"
                    ></top-label-input>
                  </el-col>
                  <el-col :span="8">
                    <top-label-input
                      :disabled="request.isCaseClosed()"
                      data-vv-value-path="vModel"
                      data-vv-name="bp"
                      :isValid="!errors.has('bp')"
                      @updated="onTopLabelInputUpdated"
                      prop-name="bp"
                      label="BP (mmHg)"
                      :model="request.recipientProfileInfo.medicalRecord.bp"
                    ></top-label-input>
                  </el-col>
                </el-row>

                <el-row style="padding: 5px;">
                  <el-col :span="8" style="padding-bottom: 5px;">
                    <label>Mucouse Membrane</label>
                  </el-col>

                  <el-col :span="16" style="padding: 5px;">
                    <el-row>
                      <el-radio-group
                        v-model="request.recipientProfileInfo.medicalRecord.mucouseMembrane"
                        :disabled="request.isCaseClosed()"
                        size="small"
                        circle
                        gap="5"
                      >
                        <el-radio-button
                          :label="1"
                          class="mucouse-btn"
                          style="background: #fde6d2;"
                        ></el-radio-button>
                        <el-radio-button
                          :label="2"
                          class="mucouse-btn"
                          style="background: #f8c4c4;"
                        ></el-radio-button>
                        <el-radio-button
                          :label="3"
                          class="mucouse-btn"
                          style="background: #f16d5f;"
                        ></el-radio-button>
                        <el-radio-button
                          :label="4"
                          class="mucouse-btn"
                          style="background: #c3423e;"
                        ></el-radio-button>
                        <el-radio-button
                          :label="5"
                          class="mucouse-btn"
                          style="background: #7a4f66;"
                        ></el-radio-button>
                        <el-radio-button
                          :label="6"
                          class="mucouse-btn"
                          style="background: #57302b;"
                        ></el-radio-button>
                        <el-radio-button
                          :label="7"
                          class="mucouse-btn"
                          style="background: #2a2726;"
                        ></el-radio-button>
                      </el-radio-group>
                    </el-row>

                    <el-row class="mucouse-mini-labels">
                      <el-col>Pale</el-col>
                      <el-col>Normal</el-col>
                      <el-col>Pink</el-col>
                      <el-col>Red</el-col>
                      <el-col>Purple</el-col>
                      <el-col>Brown</el-col>
                      <el-col>Black</el-col>
                    </el-row>
                  </el-col>
                </el-row>

                <el-row style="padding: 5px;">
                  <el-col :span="3" style="padding-top: 10px;">
                    <label>BSC Level</label>
                  </el-col>

                  <el-col :span="4" style="padding-top: 10px;">
                    <div v-if="request.petInfo.type.toLowerCase() === 'dog'">
                      <img
                        src="@/assets/bloodbank/dog-bsc-too-thin-1.png"
                        v-if="request.recipientProfileInfo.medicalRecord.bscLevel.toString() === '1' || request.recipientProfileInfo.medicalRecord.bscLevel.toString() === '2'"
                      >
                      <img
                        src="@/assets/bloodbank/dog-bsc-too-thin-2.png"
                        v-if="request.recipientProfileInfo.medicalRecord.bscLevel.toString() === '3' || request.recipientProfileInfo.medicalRecord.bscLevel.toString() === '4'"
                      >
                      <img
                        src="@/assets/bloodbank/dog-bsc-ideal.png"
                        v-if="request.recipientProfileInfo.medicalRecord.bscLevel.toString() === '5'"
                      >
                      <img
                        src="@/assets/bloodbank/dog-bsc-overweight.png"
                        v-if="request.recipientProfileInfo.medicalRecord.bscLevel.toString() === '6' || request.recipientProfileInfo.medicalRecord.bscLevel.toString() === '7'"
                      >
                      <img
                        src="@/assets/bloodbank/dog-bsc-obese.png"
                        v-if="request.recipientProfileInfo.medicalRecord.bscLevel.toString() === '8' || request.recipientProfileInfo.medicalRecord.bscLevel.toString() === '9'"
                      >
                    </div>

                    <div v-if="request.petInfo.type.toLowerCase() === 'cat'">
                      <img
                        src="@/assets/bloodbank/cat-bsc-too-thin-1.png"
                        v-if="request.recipientProfileInfo.medicalRecord.bscLevel.toString() === '1' || request.recipientProfileInfo.medicalRecord.bscLevel.toString() === '2'"
                      >
                      <img
                        src="@/assets/bloodbank/cat-bsc-too-thin-2.png"
                        v-if="request.recipientProfileInfo.medicalRecord.bscLevel.toString() === '3' || request.recipientProfileInfo.medicalRecord.bscLevel.toString() === '4'"
                      >
                      <img
                        src="@/assets/bloodbank/cat-bsc-ideal.png"
                        v-if="request.recipientProfileInfo.medicalRecord.bscLevel.toString() === '5'"
                      >
                      <img
                        src="@/assets/bloodbank/cat-bsc-overweight.png"
                        v-if="request.recipientProfileInfo.medicalRecord.bscLevel.toString() === '6' || request.recipientProfileInfo.medicalRecord.bscLevel.toString() === '7'"
                      >
                      <img
                        src="@/assets/bloodbank/cat-bsc-obese.png"
                        v-if="request.recipientProfileInfo.medicalRecord.bscLevel.toString() === '8' || request.recipientProfileInfo.medicalRecord.bscLevel.toString() === '9'"
                      >
                    </div>
                  </el-col>

                  <el-col :span="17" style="padding: 5px;">
                    <el-row style="height: 35px;">
                      <el-radio-group
                        v-model="request.recipientProfileInfo.medicalRecord.bscLevel"
                        :disabled="request.isCaseClosed()"
                        size="mini"
                      >
                        <el-radio-button :label="1" class="bsc-btn"></el-radio-button>
                        <el-radio-button :label="2" class="bsc-btn"></el-radio-button>
                        <el-radio-button :label="3" class="bsc-btn"></el-radio-button>
                        <el-radio-button :label="4" class="bsc-btn"></el-radio-button>
                        <el-radio-button :label="5" class="bsc-btn"></el-radio-button>
                        <el-radio-button :label="6" class="bsc-btn"></el-radio-button>
                        <el-radio-button :label="7" class="bsc-btn"></el-radio-button>
                        <el-radio-button :label="8" class="bsc-btn"></el-radio-button>
                        <el-radio-button :label="9" class="bsc-btn"></el-radio-button>
                      </el-radio-group>
                    </el-row>

                    <el-row style="padding-bottom: 30px;">
                      <!-- div width is from width of each button multiply by number of coverage block -->
                      <div style="display: inline-block; width: 110px;">
                        <label style="font-size: 10px;">Too Thin</label>
                      </div>
                      <div style="display: inline-block; width: 50px;">
                        <label style="font-size: 10px;">Ideal</label>
                      </div>
                      <div style="display: inline-block; width: 95px;">
                        <label style="font-size: 10px;">Overweight</label>
                      </div>
                      <div style="display: inline-block; width: 10px;">
                        <label style="font-size: 10px;">Obese</label>
                      </div>
                    </el-row>
                  </el-col>
                </el-row>

                <symptom-table
                  :disabled="request.isCaseClosed()"
                  :symptoms="request.recipientProfileInfo.medicalRecord.symptoms"
                ></symptom-table>
              </el-card>
            </el-col>
            <!-- History -->
            <el-col :span="12" class="right pane">
              <div class="row">
                <el-card style="width: 100%;">
                  <h4>History Taking</h4>

                  <div class="row" style="text-align:left;">
                    <div class="col-md-8 col-xs-12 col-sm-12">
                      <label>เคยได้รับเลือดมาก่อน</label>
                    </div>

                    <div class="col-md-16 row flex-left flex-middle" style="padding: 5px;">
                      <el-radio
                        :disabled="request.isCaseClosed()"
                        v-model="request.recipientProfileInfo.medicalRecord.usedToBloodTaken"
                        :label="false"
                      >ไม่เคย</el-radio>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-8" style="padding: 5px;"></div>

                    <div class="col-md-16 row flex-left flex-middle" style="padding: 5px;">
                      <el-radio
                        :disabled="request.isCaseClosed()"
                        v-model="request.recipientProfileInfo.medicalRecord.usedToBloodTaken"
                        :label="true"
                      >เคย</el-radio>
                      <el-date-picker
                        :disabled="request.isCaseClosed()"
                        style="margin-top: 10px;"
                        v-model="request.recipientProfileInfo.medicalRecord.bloodTakenDate"
                        type="date"
                        placeholder="Select date"
                      ></el-date-picker>
                    </div>
                  </div>
                  <div class="row" style="text-align:left; margin-top:20px;">
                    <div class="col-md-8 col-xs-12 col-sm-12">
                      <label>เคยผ่าตัดมาก่อน</label>
                    </div>

                    <div class="col-md-16 row flex-left flex-middle" style="padding: 5px;">
                      <el-radio
                        :disabled="request.isCaseClosed()"
                        v-model="request.recipientProfileInfo.medicalRecord.usedToSurgery"
                        :label="false"
                      >ไม่เคย</el-radio>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-8" style="padding: 5px;"></div>

                    <div class="col-md-16 row flex-left flex-middle" style="padding: 5px;">
                      <el-radio
                        :disabled="request.isCaseClosed()"
                        v-model="request.recipientProfileInfo.medicalRecord.usedToSurgery"
                        :label="true"
                      >เคย</el-radio>
                      <el-date-picker
                        :disabled="request.isCaseClosed()"
                        style="margin-top: 10px;"
                        v-model="request.recipientProfileInfo.medicalRecord.surgeryDate"
                        type="date"
                        placeholder="Select date"
                      ></el-date-picker>
                    </div>
                  </div>
                </el-card>
              </div>

              <div class="row" style="margin-top: 15px;">
                <el-card style="width: 100%;">
                  <h4>Dianogsis</h4>

                  <div class="row">
                    <label class="error-field-label">
                      อาการ / Techical Sign
                      <span>*</span>
                    </label>
                  </div>

                  <div class="row" style="padding: 10px;">
                    <el-select
                      :disabled="request.isCaseClosed()"
                      v-validate="'required'"
                      v-model="selectedTechnicalSign"
                      multiple
                      style="width: 100%;"
                    >
                      <el-option
                        v-for="(diagnosis, idx) in technicalSignList"
                        :key="idx"
                        v-bind:value="diagnosis.id"
                        :label="diagnosis.tag"
                      ></el-option>
                    </el-select>
                  </div>

                  <div class="row">
                    <label class="error-field-label">
                      สาเหตุของความต้องการเลือด / Diagnosis
                      <span>*</span>
                    </label>
                  </div>

                  <div class="row" style="padding: 10px;">
                    <el-select
                      :disabled="request.isCaseClosed()"
                      v-validate="'required'"
                      v-model="selectedDiagnosis"
                      multiple
                      style="width: 100%;"
                    >
                      <el-option
                        v-for="(diagnosis, idx) in diagnosisList"
                        :key="idx"
                        v-bind:value="diagnosis.id"
                        :label="diagnosis.tag"
                      ></el-option>
                    </el-select>
                  </div>

                  <div class="row">
                    <label>โรคประจำตัวอื่นๆ / Other Symptom</label>
                  </div>

                  <div class="row" style="padding: 10px;">
                    <el-select
                      :disabled="request.isCaseClosed()"
                      v-model="selectedOtherSymtom"
                      multiple
                      style="width: 100%;"
                    >
                      <el-option
                        v-for="(diagnosis, idx) in otherSymtomList"
                        :key="idx"
                        :value="diagnosis.id"
                        :label="diagnosis.tag"
                      ></el-option>
                    </el-select>
                  </div>
                </el-card>
              </div>

              <div class="row" style="margin-top: 15px;">
                <el-card style="width: 100%;">
                  <h4>Date Use</h4>

                  <div class="row" style="text-align:left;">
                    <div class="col-md-8 col-xs-12 col-sm-12">
                      <label>วันที่ต้องการใช้เลือด</label>
                    </div>

                    <div class="col-md-16">
                      <el-radio
                        :disabled="request.isCaseClosed()"
                        v-model="request.recipientProfileInfo.medicalRecord.isImmediatelyRequired"
                        :label="true"
                      >ทันที</el-radio>
                    </div>
                  </div>

                  <div class="row" style="text-align:left;">
                    <div class="col-md-8 col-xs-12 col-sm-12" style="padding: 5px;"></div>

                    <div class="col-md-16" style="padding: 5px;">
                      <el-radio
                        :disabled="request.isCaseClosed()"
                        v-model="request.recipientProfileInfo.medicalRecord.isImmediatelyRequired"
                        :label="false"
                      >จองใช้เมื่อ</el-radio>
                      <div
                        style="display: inline-flex;"
                        v-if="!request.recipientProfileInfo.medicalRecord.isImmediatelyRequired"
                      >
                        <el-date-picker
                          :disabled="request.isCaseClosed()"
                          v-validate="{ rules: { required: !request.recipientProfileInfo.medicalRecord.isImmediatelyRequired} }"
                          style="margin-top: 10px;"
                          v-model="request.recipientProfileInfo.medicalRecord.reserveDate"
                          type="date"
                          placeholder="Select date"
                          :picker-options="{disabledDate:disablePreviousDate}"
                        ></el-date-picker>
                        <span class="error-field-label" style="margin-left:5px; margin-top: 5px;">*</span>
                      </div>
                    </div>
                  </div>
                </el-card>
              </div>

              <div class="row" style="margin-top: 15px;">
                <el-card style="width: 100%;">
                  <h4>Note</h4>

                  <div class="row" style="margin-top: 10px;">
                    <div class="col-md-24 flex-start flex-middle">
                      <div class="col-md-2">
                        <label>หมายเหตุ</label>
                      </div>
                      <div class="col-md-22">
                        <el-input
                          :readonly="request.isCaseClosed()"
                          type="textarea"
                          :autosize="{ minRows: 3, maxRows: 3}"
                          placeholder="บันทึกเพิ่มเติม ..."
                          v-model="request.recipientProfileInfo.medicalRecord.note"
                        ></el-input>
                      </div>
                    </div>
                  </div>
                </el-card>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </el-row>
    </el-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TopLabelInputCtrl from "@/components/bloodbank/controls/top-label-input/TopLabelInput";
import SymptomTable from "@/components/bloodbank/controls/symptom-table/SymptomTable";
import ConfirmationButton from "@/components/bloodbank/controls/confirmation-button/ConfirmMationButton";
import _ from "lodash";

export default {
  components: {
    "top-label-input": TopLabelInputCtrl,
    "symptom-table": SymptomTable,
    "confirmation-button": ConfirmationButton
  },
  props: {
    request: Object,
    mode: {
      default: "view"
    }
  },
  data() {
    return {
      myProgress: 1,
      myText: "Physical Exam",
      myVetType: "ผู้ขอ"
    };
  },
  computed: {
    ...mapState({
      diagnosis: state => state.bloodbank.diagnosis
    }),
    technicalSignList: function() {
      return this.diagnosis.filter(o => o.diagnosis_type === 1);
    },

    otherSymtomList: function() {
      return this.diagnosis.filter(o => o.diagnosis_type === 2);
    },
    diagnosisList: function() {
      return this.diagnosis.filter(o => o.diagnosis_type === 3);
    },
    selectedTechnicalSign: {
      get: function() {
        return _.intersection(
          this.technicalSignList.map(o => o.id),
          this.request.recipientProfileInfo.medicalRecord.diagnosis
        );
      },
      set: function(newVal) {
        this.request.recipientProfileInfo.medicalRecord.diagnosis = [
          ...this.selectedOtherSymtom,
          ...this.selectedDiagnosis,
          ...newVal
        ];
      }
    },
    selectedOtherSymtom: {
      get: function() {
        return _.intersection(
          this.otherSymtomList.map(o => o.id),
          this.request.recipientProfileInfo.medicalRecord.diagnosis
        );
      },
      set: function(newVal) {
        this.request.recipientProfileInfo.medicalRecord.diagnosis = [
          ...this.selectedTechnicalSign,
          ...this.selectedDiagnosis,
          ...newVal
        ];
      }
    },
    selectedDiagnosis: {
      get: function() {
        return _.intersection(
          this.diagnosisList.map(o => o.id),
          this.request.recipientProfileInfo.medicalRecord.diagnosis
        );
      },
      set: function(newVal) {
        this.request.recipientProfileInfo.medicalRecord.diagnosis = [
          ...this.selectedTechnicalSign,
          ...this.selectedOtherSymtom,
          ...newVal
        ];
      }
    }
  },
  created() {},
  methods: {
    disablePreviousDate(time) {
      return time.getTime() < Date.now() - 8.64e7;
    },
    onTopLabelInputUpdated(e) {
      this.request.recipientProfileInfo.medicalRecord[e.prop] = e.value;
    }
  }
};
</script>

<style lang="scss">
  .request-step-1-exam {
    h4 {
      margin: 0;
    }
    
    .header-container {
      width: calc(100% + 5px);
      height: 50px;
      border-radius: 6px;
      background-color: #5c9ba3;
      text-align: center;
    }

    .mucouse-btn,
    .bsc-btn {
      height: 35px;
    }

    .mucouse-btn-active {
      border-color: #009688 !important;
      border-width: 3px !important;
    }

    .bsc-btn-active,
    .bsc-btn:hover {
      background-color: rgb(97, 144, 233) !important;
      color: white;
    }

    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100% !important;
    }
  }
</style>
