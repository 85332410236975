<template>
  <div>
    <Subnavbar>
      <template slot="title">Inventory</template>
      <template slot="actions">
        <div style="display: flex;">
          <el-button 
            v-if="currentPlan.stock_section.can_delete_stock"
            class="detail" 
            size="medium" 
            @click="showConfirmDeleteDialog()">
            Delete
          </el-button>
          <el-button
            v-if="currentPlan.setting_section.can_use_activity_setting"
            class="detail" 
            size="medium" 
            @click="handleEditProduct">
            Edit
          </el-button>
          <div
            v-if="currentPlan.stock_section.can_remove_stock && product">
            <el-button
              style="margin: 0 2px;"
              type="primary"
              size="large"
              @click="$router.push({ 
                name: 'inventory-remove-with-payment', 
                query: { 
                  product: $route.params.product_id,
                  unit: product.unit ? product.unit.id : undefined
                }
              })"
            >
              <span style="font-size: 16px; text-align: center;">- Remove</span>
            </el-button>
          </div>
          <div
            v-if="currentPlan.stock_section.can_add_stock && product">
            <el-button
              style="margin: 0 2px;"
              type="primary"
              size="large"
              @click="$router.push({ 
                name: 'inventory-add-with-payment', 
                query: { 
                  product: $route.params.product_id,
                  unit: product.unit ? product.unit.id : undefined
                }
              })"
            >
              <span style="font-size: 16px; text-align: center;">+ Add</span>
            </el-button>
          </div>
          <div class="message-box"><i class="el-icon-close" @click="$router.go(-1)"></i></div>
        </div>
      </template>
    </Subnavbar>
    <ContentBox>
      <div class="form">
        <el-row class="row">
          <el-col :span="14">
            <h1 class="title">{{ product.trade_name }}</h1>
          </el-col>
          <el-col :span="9" class="current-stock">
            <label style="font-size: 16px; color: rgba(0, 0, 0, 0.65);">Current Stock</label>
            <div style="font-size: 28px; color: #030202;">{{ transOverview.remaining_amount }}</div>
            <label style="font-size: 16px; color: rgba(0, 0, 0, 0.65)">{{ product.unit && product.unit.name }}</label>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="13" class="self-center">
            <div class="date-group">
              <el-date-picker type="date" v-model="startDate" placeholder="Start Date" size="large" @change="handleFilter"></el-date-picker>
              <span>to</span>
              <el-date-picker type="date" v-model="endDate" placeholder="End Date" size="large" @change="handleFilter"></el-date-picker>
            </div>
          </el-col>
          <el-col :span="1" class="self-center" style="justify-content: center;">
            <label style="font-size: 14px;">Result</label>
          </el-col>
          <span style="border-left: 1px solid #ccc; margin: 0 10px;"></span>
          <el-col :span="2" class="self-center">
            <label style="font-size: 16px;">Stock in</label>
          </el-col>
          <el-col :span="2" class="flex-column">
            <label style="text-align: right; font-size: 16px;">{{ transOverview.in_amount }}</label>
            <label style="text-align: right; font-size: 10px;">{{ product.unit && product.unit.name }}</label>
          </el-col>
          <span style="border-left: 1px solid #ccc; margin: 0 10px;"></span>
          <el-col :span="2" class="self-center">
            <label style="font-size: 16px;">Stock out</label>
          </el-col>
          <el-col :span="2" class="flex-column">
            <label style="text-align: right; font-size: 16px;">{{ transOverview.out_amount }}</label>
            <label style="text-align: right; font-size: 10px;">{{ product.unit && product.unit.name }}</label>
          </el-col>
        </el-row>
      </div>
      <template v-if="transList && transList.results">
        <InventoryProductTable
          :items="transList.results"
          :product_name="product.trade_name"
          :total="transList.count"
          :current-page="currentPage"
          @current-change="pageChange"
          @select-product="selectProduct"
        />
      </template>
      <InventoryModal
        :visible="showModal"
        :form="form"
        :stockId="stockId"
        :product="product"
        :remain="transOverview.remaining_amount"
        @save="saveModal"
        @cancel="showModal = false"
      />
      <InventoryDetailModal
        :visible="showDetailModal"
        :product="product"
        :item="modalItem"
        @cancel="showDetailModal = false"
      />
      <DeleteProductConfirmModal
        :visible="showDeleteProductModal"
        :product="product"
        :activities="activities"
        @delete="deleteProduct"
        @cancel="showDeleteProductModal = false"
      />
    </ContentBox>
  </div>
</template>

<script>
import { mapState } from "vuex"
import ContentBox from "@/components/container/ContentBox";
import Subnavbar from "@/components/navigation/Subnavbar";
import InventoryProductTable from "@/components/inventory/InventoryProductTable";
import InventoryModal from "@/components/inventory/InventoryModal";
import InventoryDetailModal from "@/components/inventory/InventoryDetailModal";
import DeleteProductConfirmModal from "@/components/product/DeleteProductConfirmModal";
// import DeleteProductConfirmModal from "@/components/inventory/DeleteProductConfirmModal";

export default {
  components: {
    ContentBox,
    Subnavbar,
    InventoryProductTable,
    InventoryModal,
    InventoryDetailModal,
    DeleteProductConfirmModal
  },
  data() {
    return {
      currentPage: 1,
      startDate: null,
      endDate: null,
      showModal: false,
      showDetailModal: false,
      showDeleteProductModal: false,
      isLoading: false,
      transList: {},
      transOverview: {},
      product: {
        unit: {
          name: null
        }
      },
      params: "",
      stockId: null,
      form: {
        title: "",
        value: null,
        reason: 1,
        note: ""
      },
      modalItem: null,
      reason: {
        1: "เพิ่มสต็อก",
        2: "ได้รับจากภายนอกมา",
        3: "โอนย้ายมาจากสาขาอื่น",
        4: "ปรับจำนวนคงเหลือ",
        5: "หมดอายุ",
        6: "สูญหาย",
        7: "ชำรุดเสียหาย ใช้งานไม่ได้",
        8: "ปรับจำนวนคงเหลือ"
      }
    };
  },
  computed: {
    ...mapState({
      activities: state => state.product.activities,
      currentPlan: state => state.plan.currentPlan,
    })
  },
  methods: {
    openModal(title) {
      this.form.title = title;
      this.form.value = null;
      this.form.reason = 1;
      this.showModal = true;
    },
    async saveModal() {
      if (this.form.value >= 0.01) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        let data = {};
        data["amount"] = this.form.value;
        if (this.form.reason === 5) {
          data["note"] = this.form.note;
        } else {
          data["note"] =
            this.form.title === "Add"
              ? this.reason[this.form.reason]
              : this.reason[this.form.reason + 4];
        }
        let resMed = true;

        if (this.form.title === "Add") {
          await this.$store
            .dispatch("inventory/transactionAdd", {
              params: { id: this.stockId },
              data
            })
            .catch(async err => {
              resMed = false;
              this.$notify({
                message: JSON.stringify(err),
                type: "error"
              });
            });
        } else if (this.form.title === "Remove") {
          await this.$store
            .dispatch("inventory/transactionRemove", {
              params: { id: this.stockId },
              data
            })
            .catch(async err => {
              resMed = false;
              this.$notify({
                message: JSON.stringify(err),
                type: "error"
              });
            });
        }
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);

        if (resMed) {
          this.$notify({
            message: "บันทึกเรียบร้อยแล้ว",
            type: "success"
          });
          this.transList = await this.$store.dispatch(
            "inventory/transactionList",
            {
              params: { id: this.stockId, params: this.params }
            }
          );
          this.transOverview = await this.$store.dispatch(
            "inventory/transactionOverview",
            {
              params: { id: this.stockId, params: this.params }
            }
          );
        }
        this.showModal = false;
      }
    },
    async showConfirmDeleteDialog() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        await this.$store.dispatch("product/getProductActivities", {
          params: { id: this.product.id },
        });
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        this.showDeleteProductModal = true;
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorMsg");
      }
    },
    async deleteProduct(product) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        await this.$store.dispatch("activity/deleteActivityOneProductFromProduct", {
          params: { id: product.id },
        });
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        this.$notify({
          type: "success",
          title: "ลบข้อมูลผลิตภัณฑ์เรียบร้อยแล้ว"
        })
        this.showDeleteProductModal = false;
        this.$router.go(-1);
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorMsg");
      }
    },
    async selectProduct(item) {
      this.modalItem = JSON.parse(JSON.stringify(item))
      if (item.ref_content_type && item.ref_content_type.model === "medicalrecord") {
        this.$router.push({ name: 'medicals-edit-detail', params: { id: item.ref_id }, query: { ref: "inventory" }})
      } else if (item.ref_content_type && item.ref_content_type.model === "ipd") {
        this.$router.push({ name: 'ipd-detail', params: { id: item.ref_id }})
      } else if (item.ref_content_type && item.ref_content_type.model === "incomeexpense") {
        this.$router.push({ name: 'income-expense-detail', params: { id: item.ref_id }})
      } else if (item.ref_content_type && item.ref_content_type.model === "standalonepos") {
        this.$router.push({ name: 'pos-detail', params: { id: item.ref_id }})
      } else if (item.ref_content_type && item.ref_content_type.model === "importexportdeclaration") {
        this.gotoInventoryAddRemove(item);
      } else if (item.ref_content_type && item.ref_content_type.model === "stocktransaction") {
        await this.checkRevertDetail(item.ref_id);
      } else {
        this.showDetailModal = true;
      }
    },
    handleEditProduct() {
      if (this.product && this.product.id) {
        this.$router.push({
          name: 'proudct-setting-product-update',
          params: { id: this.product.id }
        });
      }
    },
    handleFilter() {
      let query = {};
      this.currentPage = 1;
      query["page"] = 1;
      if (this.startDate) {
        query["start"] = this.startDate.toISOString()
      }
      if (this.endDate) {
        query["end"] = this.endDate.toISOString()
      }
      this.fetchData(query)
    },
    pageChange(page) {
      let query = this.getQueryString();
      query = { ...query, page };
      this.currentPage = page;
      this.fetchData(query);
    },
    getQueryString() {
      let query = {};

      if (this.startDate) {
        query["start"] = this.startDate.toISOString()
      }

      if (this.endDate) {
        query["end"] = this.endDate.toISOString()
      }

      return query;
    },

    gotoInventoryAddRemove(transaction) {
      const action = transaction.action_type.toLowerCase();
        if (action == "add") 
          this.$router.push({
            name: "inventory-add-detail",
            params: { id: transaction.ref_id },
          });
        else
          this.$router.push({
            name: "inventory-remove-detail",
            params: { id: transaction.ref_id },
          });
    },

    async checkRevertDetail(transactionId) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        const result = await this.$store.dispatch("inventory/transactionDetail", {
          params: { id: transactionId }
        });

        this.selectProduct(result);

        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg");
      }
    },

    async fetchData(query) {
      try {
        this.stockId = this.$route.params.stock_id;
        const product_id = this.$route.params.product_id;
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        this.transList = await this.$store.dispatch(
          "inventory/transactionList",
          {
            params: { id: this.stockId },
            query
          }
        );
        this.transOverview = await this.$store.dispatch(
          "inventory/transactionOverview",
          {
            params: { id: this.stockId },
            query
          }
        );
        this.product = await this.$store.dispatch("product/getProduct", {
          params: { id: product_id }
        });
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg");
      }
    }
  },
  async created() {
    this.fetchData(this.getQueryString());
  }
};
</script>

<style lang="scss" scoped>
.detail {
  cursor: pointer;
  margin: 0 2px;
  font-family: Arial;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: center;
  color: #2198f1;
}
.message-box {
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-self: center;
  cursor: pointer;
}
.current-stock {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 45px;
  border: solid 1px #c3c3c3;
  background-color: #e9e9e9;
}
.self-center {
  display: flex;
  align-self: center;
}
.flex-column {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.form {
  padding: 0 10px;
  .row {
    margin-bottom: 10px;
  }

  .title {
    font-size: 1.4em;
  }

  label {
    display: inline-block;
    font-family: Thonburi;
    font-size: 16px;
    font-weight: bold;
    margin-right: 3px;
    color: #666;
    line-height: 1.8;
  }
}
.head-title {
  margin: 30px 0;
  display: flex;
  justify-content: center;
  font-family: Arial;
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
}
.date-group {
  > span {
    color: #666;
    padding: 10px;
  }
}
</style>
