<template>
  <div>
    <Subnavbar>
      <template slot="title">Veterinarian</template>
      <div class="line"></div>
      <template slot="actions">
        <button
          class="btn btn-large btn-primary btn-user-add"
          @click="$router.push({ name: 'veterinarians-add' })"
        >
          <img src="@/assets/icons/icon-user-add.png">
          Add
        </button>
      </template>
    </Subnavbar>

    <ContentBox>
      <template v-if="vets">
        <VeterinarianTable
          :vets="vets"
        />
      </template>
    </ContentBox>
  </div>
</template>

<script>
import { mapState } from "vuex"
import ContentBox from "@/components/container/ContentBox"
import Subnavbar from "@/components/navigation/Subnavbar"
import VeterinarianTable from "@/components/veterinarian/VeterinarianTable"

export default {
  components: {
    ContentBox,
    Subnavbar,
    VeterinarianTable
  },
  data() {
    return {
      currentPage: 1
    }
  },
  computed: {
    ...mapState({
      vets: state => state.hospital.vets
    })
  },
  methods: {
  },
  async created() {
    try {
      await this.$store.dispatch("ui/setIsFullScreenLoading", true)
      await this.$store.dispatch("hospital/listAllVets")
      await this.$store.dispatch("ui/setIsFullScreenLoading", false)
    } catch (error) {
      await this.$store.dispatch("ui/showFetchErrorWithReloadMsg")
    }
  }
}
</script>

<style lang="scss" scoped>
.line {
  width: 300px;
  height: 600px;
  background: #979797;

  z-index: 99;
}
.box {
  margin-bottom: 15px;
}
.box-data-table {
  padding: 20px;
}
</style>