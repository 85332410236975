import MedicalListPage from '@/pages/medical/MedicalListPage.vue'
import MedicalSelectPetPage from '@/pages/medical/MedicalSelectPetPage.vue'
import MedicalAddDetailPage from '@/pages/medical/MedicalAddDetailPage.vue'
import MedicalDrugLabel from '@/pages/medical/MedicalDrugLabel.vue'
import MedicalDrugLabelDetail from '@/pages/medical/MedicalDrugLabelDetail.vue'
import MedicalDrugLabelPDF from '@/pages/medical/MedicalDrugLabelPDF.vue'
const MedicalDeleted = () => import('@/pages/medical/MedicalDeleted')


const routes = [
  {
    path: '/medicals',
    name: 'medicals',
    component: MedicalListPage,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin']
    }
  },
  {
    path: '/medicals/pets',
    name: 'medicals-select-pet',
    component: MedicalSelectPetPage,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin']
    }
  },
  {
    path: '/medicals/pet/:id/detail',
    name: 'medicals-add-detail',
    component: MedicalAddDetailPage,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin']
    }
  },
  {
    path: '/medicals/markdone/:id/detail',
    name: 'medicals-markdone-appointment',
    component: MedicalAddDetailPage,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin']
    }
  },
  {
    path: '/medicals/med/:id',
    name: 'medicals-edit-detail',
    component: MedicalAddDetailPage,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin']
    }
  },
  {
    path: '/medicals/drug/:id',
    name: 'medicals-drug-label',
    component: MedicalDrugLabel,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['print_section.can_use_drug_label_print']
    }
  },
  {
    path: '/medicals/drug/:id/detail',
    name: 'medicals-drug-label-detail',
    component: MedicalDrugLabelDetail,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['print_section.can_use_drug_label_print']
    }
  },
  {
    path: '/medicals/drug/:id/pdf',
    name: 'medical-drug-label-pdf',
    component: MedicalDrugLabelPDF
  },
  {
    path: '/medicals/deleted',
    name: 'medical-deleted',
    component: MedicalDeleted,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['opd_section.can_view_opd_delete_list']
    }
  }
]

export default routes
