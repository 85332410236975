<template>
    <div>

      <div class="appointment-overview bg-white" v-if="overview">
        <el-row v-if="overview">
          <el-col :md="6">
            <div class="data-section" style="border-left: none;">
              <label>Total Today Appointment</label>
              <div class="values">
                <div class="top">{{ overview.total_appointment | number }}</div>
                <div class="bottom">case</div>
              </div>
            </div>
          </el-col>
          <el-col :md="4">
            <div class="data-section">
              <label>Done</label>
              <div class="values">
                <div class="top">{{ overview.doned_appointment | number }}</div>
                <div class="bottom">case</div>
              </div>
            </div>
          </el-col>
          <el-col :md="4">
            <div class="data-section">
              <label>Pending</label>
              <div class="values">
                <div class="top">{{ overview.pending | number }}</div>
                <div class="bottom">case</div>
              </div>
            </div>
          </el-col>
          <el-col :md="5">
            <div class="data-section">
              <label>Gain Revenue</label>
              <div class="values">
                <div class="top">{{ overview.gain_revenue | currency }}</div>
                <div class="bottom">THB</div>
              </div>
            </div>
          </el-col>
          <el-col :md="5">
            <div class="data-section">
              <label>Lost Revenue</label>
              <div class="values">
                <div class="top">{{ overview.lost_revenue | currency }}</div>
                <div class="bottom">THB</div>
              </div>
            </div>
          </el-col>
        </el-row>
        
        <el-row class="form-section">
          <el-col :span="15">
            <el-input
                placeholder="ค้นหานัด จาก ID , ชื่อสัตว์เลี้ยง , เบอร์โทร ,etc."
                v-model="searchText"
                class="search-input"
                size="large"
                clearable
                @keyup.enter.native="handleSearchClick"
                @clear="clearSearch" >
                <el-button type="primary" slot="append" icon="el-icon-search" @click="handleSearchClick"></el-button>
            </el-input>
          </el-col>
          <el-col :span="9" class="text-right">
            <button
              class="btn btn-large btn-appointment"
              @click="$router.push({ name: 'appointment-select-pet' })" >
              <i class="fa fa-plus-circle"></i>Appointment
            </button>
          </el-col>
        </el-row>
      </div>
        
      <div class="result-container bg-white" v-if="todayAppointments && todayAppointments.results">
          <strong class="total-count">Today List {{ todayAppointments.count }}</strong>
          <ApptTableHome
            :appts="todayAppointments.results"
            :total="todayAppointments.count"
            :current-page="currentPage"
            @current-change="pageChange"
            />
      </div>

    </div>
</template>


<script>
import { mapState } from "vuex"
import { startToday, endToday } from '@/utils/datetime'
import ApptTableHome from '@/components/appointment/ApptTableHome'
import { getAppointmentIDFormUrl } from '../../utils'


export default {
  components: {
    ApptTableHome
  },
  data() {
    return {
      currentDate: new Date(),
      currentPage: 1,
      searchText: '',
    }
  },
  computed: {
    ...mapState({
      overview: state => state.appointment.overview,
      todayAppointments: state => state.appointment.today
    })
  },
  methods: {
    pageChange (page) {
      this.currentPage = page
      this.search(page)
    },
    handleSearchClick() {
      this.search(1)
    },
    clearSearch() {
      this.$store.commit("appointment/SET_TODAY", null)
      this.searchText = ""
      this.currentPage = 1
      // this.$router.replace({
      //   search: "",
      //   currentPage: 1
      // })
      this.search(1)
    },
    async search(currentPage) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        await this.$store.dispatch("appointment/getOverview", { query: { 
          start: startToday(),
          end: endToday()
        }})

        let query = { page: currentPage }
        if (this.searchText !== "") {
          query.search = this.searchText
        }
        await this.$store.dispatch("appointment/getTodayAppointments", { query })
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg")
      }
    },
  },
  watch: {
    'searchText': function(value) {
      const result = getAppointmentIDFormUrl(value)
      if(result !== '') {
        this.searchText = result
      }
    }
  },
  async created() {
    this.$store.commit("appointment/SET_TODAY", null)
    this.search(1)
  }
}
</script>

<style lang="scss" scoped>
.bg-white {
  background-color: $white;
}

.appointment-overview {
  position: relative;
  padding: 16px 8px;
  margin-bottom: 18px;

  .title {
    margin: 0;
    padding: 0;
    font-size: 16px;
    margin-bottom: 5px;
  }

  .data-section {
    border-left: 1px solid #ccc;
    padding: 0 10px 10px;

    label {
      display: inline-block;
      width: 40%;
      font-size: 16px;
      line-height: 1.2em;
      vertical-align: top;
    }

    .values {
      display: inline-block;
      width: 60%;
      text-align: right;

      .top {
        font-size: 20px;
        line-height: 1em;
        font-weight: bold;
      }
    }
  }

  .form-section {
    margin: 8px;

    .search-input {
      margin-top: 8px;
      
      input[type=text] {
        background-color: #f7f7f7;
      }

      .el-button--primary {
        color: $white;
        background-color: $primary;
        border-color: $primary;
        background-color: $primary;
      }
    }
  }
}

.result-container {
  padding: 20px;
  margin-bottom: 64px;

  .total-count {
    margin-bottom: 8px;
    display: inline-block;
  }
}
</style>