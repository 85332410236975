<template>
  <div :class="{ 'postpone-form': true, 'vertical': vertical }">
    <h3 class="title">ต้องการเลื่อนนัดเป็น</h3>
    <div class="content">
      <el-row>
        <el-col>
          <label class="label">
            <img class="icon" src="@/assets/icons/icon-clock.png">วันที่นัด
            <span class="required-mark">*</span>
          </label>
          <el-date-picker
            class="date-picker"
            type="date"
            placeholder="Pick a day"
            size="small"
            v-model="form.apptDate"
            :picker-options="pickerDateOptions"
          ></el-date-picker>
          <el-time-select
            class="date-picker"
            placeholder="Select time"
            :picker-options="{
              start: '00:00',
              step: '00:15',
              end: '24:45'
            }"
            size="small"
            v-model="form.apptTime"
            format="HH:mm"
          ></el-time-select>
        </el-col>
      </el-row>
    </div>
    <div class="actions">
      <button
        v-if="showCloseButton"
        class="btn btn-outline"
        @click="$emit('close')"
        type="primary"
      >Cancel</button>
      <button class="btn btn-primary" @click="handelConfirmClick" type="primary">Confirm</button>
    </div>
    <div v-if="showCloseIcon" class="close-btn" @click="$emit('close')">
      <i class="fa fa-times-circle"></i>
    </div>
  </div>
</template>

<script>
import moment from "moment"
import { combineDateTime, roundToQuarterHour } from "@/utils/datetime"
export default {
  props: ["requestDate", "showCloseIcon", "showCloseButton", "vertical"],
  data() {
    return {
      form: {
        apptDate: this.requestDate || new Date(),
        apptTime: roundToQuarterHour(this.requestDate).format("HH:mm"),
      },
      pickerDateOptions: {
        disabledDate(time) {
          const date = new Date()
          date.setTime(date.getTime() - 3600 * 1000 * 24)
          return time.getTime() < date
        }
      }
    }
  },
  methods: {
    handelConfirmClick() {
      let data = {}
      const timeToDate = moment(this.form.apptTime, "HH:mm").toDate();
      const dt = combineDateTime(this.form.apptDate, timeToDate)
      if (moment.isDate(dt)) {
        data["date"] = dt
      } else {
        data["date"] = null
      }
      this.$emit("confirm", data)
    }
  }
}
</script>

<style lang="scss" scoped>
.postpone-form {
  position: relative;
  padding: 0 10px;

  .label {
    display: inline-block;
    margin-bottom: 10px;
  }
}
.date-picker {
  margin-right: 10px;
  margin-bottom: 10px;
}
.actions {
  margin-top: 10px;
  text-align: right;
}
.close-btn {
  position: absolute;
  top: -15px;
  right: 0;
  cursor: pointer;
}
.vertical {
  .actions {
    margin-top: 5px;
  }
  .date-picker {
    width: 100%;
  }
  .btn {
    margin: 0 1%;
    width: 48%;
  }
}
</style>

