<template>
  <div class="hospital-profile-page">
    <Subnavbar>
      <template slot="title">ตั้งค่าการใช้งาน</template>
      <template slot="actions">
  
        <button
          class="btn btn-large btn-primary"
          @click="onVerify"
        >
          Save
        </button>
      </template>
    </Subnavbar>
    
    <div class="el-row">
      <div class="el-col el-col-24 el-col-md-16 col-offset-4 content-padding">
        <ContentBox style="min-height:1200px;">
          <div class="subtopic">1. รายละเอียดองค์กรเพื่อออกใบเสร็จรับเงิน</div>

          <template>
            <el-form class="address" ref="form" :model="form" label-width="120px">
              <el-form-item label="ชื่อบริษัท">
                <el-input v-model="form.company" placeholder="กรอกชื่อบริษัท" style="width: 260px"></el-input>
              </el-form-item>
              <el-form-item label="เลขที่ผู้เสียภาษี">
                <el-input v-model="form.tin" placeholder="กรอกเลขที่ผู้เสียภาษี" style="width: 260px"></el-input>
              </el-form-item>
              <el-form-item label="โทรศัพท์">
                <el-input v-model="form.phone" placeholder="กรอกเบอร์โทรศัพท์" style="width: 260px"></el-input>
              </el-form-item>        
            </el-form>

            <div class="subtopic">ที่อยู่</div>
            <el-form class="address" ref="form" :model="form" label-width="120px">
              <el-form-item label="ที่อยู่">
                <el-input v-model="form.address" placeholder="กรอกที่อยู่" style="width: 260px"></el-input>
              </el-form-item>
              <el-form-item label="แขวง/ตำบล">
                <el-input v-model="form.sub_district" placeholder="กรอกแขวง/ตำบล" style="width: 260px"></el-input>
              </el-form-item>
              <el-form-item label="เขต/อำเภอ">
                <el-input v-model="form.district" placeholder="กรอกเขต/อำเภอ" style="width: 260px"></el-input>
              </el-form-item>
              <el-form-item label="จังหวัด">
                <el-input v-model="form.province" placeholder="กรอกจังหวัด" style="width: 260px"></el-input>
              </el-form-item>
              <el-form-item label="รหัสไปรษณีย์">
                <el-input v-model="form.zip_code" placeholder="กรอกรหัสไปรษณีย์" style="width: 260px"></el-input>
              </el-form-item>          
            </el-form>

            <el-form label-width="120px">
              <el-form-item label="ภาษีมูลค่าเพิ่ม">
                <el-checkbox v-model="form.use_vat" class="vat-checkbox">จดทะเบียนภาษีมูลค่าเพิ่ม</el-checkbox>
              </el-form-item>
            </el-form>

          </template>

          <div class="subtopic">2. ตั้งค่าการนับ Stock</div>

          <template>
            <el-form class="address" ref="otherForm" :model="otherForm" label-width="335px">
              <el-form-item
                class="labelLineHieght"
                label="เปิดเพื่อใช้งานการนับ Stock โดยไม่สามารถสร้างรายการได้ถ้าจำนวนในคลังสินค้ามีจำนวนไม่เพียงพอ"
              >
                <el-switch
                  class="default-style"
                  v-model="otherForm.use_cut_available_stock">
                </el-switch>
              </el-form-item>
            </el-form>
          </template>
        </ContentBox>
      </div>
    </div>
    
    <ConfirmDialog
      :visible="showConfirmDialog"
      @cancel="showConfirmDialog = false"
      @confirm="onSave"
    >
      <template slot="header">
        <h1 class="title">ยืนยันการบันทึกใบเสร็จรับเงิน</h1>
      </template>
      <template slot="content">
        <p>{{ getConfirmDialogMessage() }}</p>
      </template>
    </ConfirmDialog>

  </div>
</template>

<script>
import { mapState } from "vuex"
import ContentBox from "@/components/container/ContentBox"
import Subnavbar from "@/components/navigation/Subnavbar"
import ConfirmDialog from "@/components/dialog/ConfirmDialog"
// eslint-disable-next-line

export default {
  data() {
    return {
      form: {
        company: '',
        tin: '',
        phone: '',
        address: '',
        sub_district: '',
        district: '',
        province: '',
        zip_code: '',
        use_vat: false
      },
      otherForm: {
        use_cut_available_stock: false
      },
      old_use_vat: false,
      showConfirmDialog: false,
    }
  },
  components: {
    ContentBox,
    Subnavbar,
    ConfirmDialog,
  },
  computed: {
    ...mapState({
      receiptsetting: state => state.hospital.receiptsetting,
      othersetting: state => state.hospital.othersetting,
    })
  },
  methods: {
    getConfirmDialogMessage() {
      let use_message = '';
      if (!this.form.use_vat) use_message = 'ไม่'

      return 'คุณได้เลือก' + use_message + 'จดทะเบียนภาษีมูลค่าเพิ่ม ดังนั้นทุก activity ของคุณ จะถูกเปลี่ยนเป็น' + use_message + 'มีภาษีมูลค่าเพิ่มทั้งหมด โดยคุณสามารถเข้าไป ตั้งค่าแบบเจาะจงได้ในแต่ละ activity ภายหลัง'
    },
    onVerify() {
      if (this.form.use_vat !== this.old_use_vat) {
        this.showConfirmDialog = true;
      } else {
        this.onSave()
      }
        
    },
    onSave() {
      this.showConfirmDialog = false;
      this.updateProfile();
    },
    async updateProfile() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        
        const data = {
          ...this.form
        }

        const otherData = {
          ...this.otherForm
        }

        await this.$store.dispatch("hospital/updateReceiptSetting", {
          data: data
        });

        await this.$store.dispatch("hospital/updateOtherSetting", {
          data: otherData
        });

        this.$notify({
          type: "success",
          title: "บันทึกข้อมูลเรียบร้อยแล้ว"
        })
        this.$router.go();
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        await this.$store.dispatch("ui/showFetchErrorMsg", error)
      }
    },
  },
  async mounted() {
    try {
      await this.$store.dispatch("ui/setIsFullScreenLoading", true)
      await this.$store.dispatch("hospital/getReceiptSetting")
      await this.$store.dispatch("hospital/getOtherSetting")

      this.form = {
        company: this.receiptsetting.company,
        tin: this.receiptsetting.tin,
        phone: this.receiptsetting.phone,
        address: this.receiptsetting.address,
        sub_district: this.receiptsetting.sub_district,
        district: this.receiptsetting.district,
        province: this.receiptsetting.province,
        zip_code: this.receiptsetting.zip_code,
        use_vat: this.receiptsetting.use_vat
      }
      this.old_use_vat = this.receiptsetting.use_vat

      this.otherForm = {
        use_cut_available_stock: this.othersetting.use_cut_available_stock,
      }

      await this.$store.dispatch("ui/setIsFullScreenLoading", false)
    } catch (error) {
      await this.$store.dispatch("ui/showFetchErrorWithReloadMsg")
    }
  },
}
</script>

<style lang="scss">
.hospital-profile-page {
  .el-input {
    width: 260px;
  }

  #main {
    max-width: 1060px !important;
  }

  .topic {
    font-family: Default;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #000000;

    margin-bottom: 20px;
  }

  .subtopic {
    font-family: Default;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;

    margin-bottom: 12px;
  }

  .el-form-item {
    .el-input {
      line-height: auto;
    }

    .el-form-item__content {
      line-height: normal;
      font-size: 16px;

      .bigger {
        font-size: 18px;
      }
    }
  }

  .container-center {
    width: 100%;
    text-align: center;
  }

  .el-form-item__label {
    font-family: Default;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    // line-height: normal;
    letter-spacing: normal;
    color: #9b9b9b;

    text-align: left;
  }

  .content-padding {
    box-sizing: border-box;
    padding-left: 8px;
    padding-right: 8px;

    &:first-child {
      padding-right: 8px;

      .content {
        padding-left: 23px;
        padding-right: 28px;
      }
    }
    &:last-child {
      padding-left: 8px;

      .content {
        padding-left: 28px;
        padding-right: 87px;
      }
    }
  }

  .vat-checkbox .el-checkbox__inner {
    width: 36px;
    height: 34px;
    border: solid 1px #02b0e1;
  }

  .vat-checkbox .el-checkbox__inner::after {
    height: 24px;
    left: 14px;
  }

  .ContentBox {
    margin: 8px;
    padding-right: 8px;
  }

  .el-form.top .el-form-item__label {
    line-height: 0;
    padding-bottom: 8px;
  }

  .labelLineHieght {
    label {
      line-height: 1.4rem;
    }
  }
}
</style>
