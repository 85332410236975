<template>
  <div :class="{ 'container': true, 'show-activities': showActivities }">
    <Subnavbar>
      <template slot="title">
        MARK DONE
        <span v-if="appt">/ {{ appt.id }}</span>
      </template>
      <template slot="actions">
        <button
          :class="{ 'btn btn-large btn-goto-activities': true, 'pressed': showActivities }"
          @click="handleShowActivity"
        >
          <img src="@/assets/icons/icon-goto-activity.png" class="icon icon-normal">
          <img src="@/assets/icons/icon-goto-activity-white.png" class="icon icon-active">+ Activities
        </button>
        <div class="total-topnav">
          <div class="label">Total</div>
          <div class="value">{{ total | currency }}฿</div>
        </div>
        <button
          class="btn btn-large btn-default"
          @click="$router.replace({ name: 'appointment-detail', param: { id: $route.params.id} })"
        >Cancel</button>
        <button class="btn btn-large btn-primary" @click="verifyBeforeSave">
          <i class="far fa-save"></i> Save Record
        </button>
      </template>
    </Subnavbar>
    <div v-if="apptData" class="clearfix">
      <div class="left-col">
        <ActivityDataGrid
          :activities="activities"
          :activityGroups="activityGroups"
          :isFavorite="isFavorite"
          :selectedGroupId="selectedGroupId"
          :selectedSubGroupId="selectedSubGroupId"
          :searchText="searchText"
          :selectedActivities="this.apptData.activities"
          @changeGroup="handleChangeActivityGroup"
          @search="handleSearch"
          @clearSearch="handleClearSearch"
          @updateSelected="handleUpdateSelectedActivities"
        />
      </div>
      <div class="right-col">
        <ContentBox>
          <template slot="header">Pet Profile</template>
          <PetProfile :pet="appt.pet" :owner="appt.remind_to" :avatarSize="showActivities? 80: 120" class="pet-profile"/>
        </ContentBox>
        <ContentBox>
          <template slot="header">Detail</template>
          <AppointmentRecord
            :appt="apptData"
            :activities="apptData.activities"
            :vets="vets"
            @change="handleDataChange"
            :isSmall="showActivities"
          />
        </ContentBox>
      </div>
    </div>
    <div v-else>
      <DataLoading/>
    </div>
    <ConfirmDialog
      :visible="showConfirmDialog"
      @cancel="showConfirmDialog = false"
      @confirm="handleSave"
    >
      <template slot="header">
        <h1 class="title">ยืนยันการบันทึกผลการทำนัด?</h1>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import _ from "lodash"
import { mapState, mapGetters } from "vuex"
import ActivityDataGrid from "@/components/activity/ActivityDataGrid"
import AppointmentRecord from "@/components/appointment/AppointmentRecord"
import DataLoading from "@/components/loading/DataLoading"
import ContentBox from "@/components/container/ContentBox"
import Subnavbar from "@/components/navigation/Subnavbar"
import PetProfile from "@/components/pet/PetProfile"
import ConfirmDialog from "@/components/dialog/ConfirmDialog"

export default {
  components: {
    ActivityDataGrid,
    AppointmentRecord,
    ContentBox,
    DataLoading,
    Subnavbar,
    PetProfile,
    ConfirmDialog
  },
  data() {
    return {
      showActivities: false,
      apptData: null,
      isFavorite: true,
      selectedGroupId: -1,
      selectedSubGroupId: -1,
      searchText: "",
      showConfirmDialog: false,
      childId: null
    }
  },
  computed: {
    ...mapState({
      appt: state => state.appointment.selected,
      activityGroups: state => state.activity.groups,
      activities: state => state.activity.activities,
      vets: state => state.hospital.vets
    }),
    ...mapGetters({
      apptState: "appointment/apptState"
    }),
    total() {
      if (this.appt) {
        let data = this.appt
        if (this.apptData) {
          data = this.apptData
        }
        return data.activities.reduce((total, item) => {
          if (item.price) {
            return total + parseFloat(item.price)
          }
          return total
        }, 0)
      }
      return 0
    }
  },
  methods: {
    handleSearch(value) {
      const query = {
        ...this.getDefaultQuery(this.appt),
        search: value
      }
      delete query.is_favorite
      this.$router.replace({ query })
      this.fetchActivity(query)
    },
    handleClearSearch() {
      const query = this.getDefaultQuery(this.appt)
      this.searchText = ""
      this.isFavorite = true
      this.selectedGroupId = -1
      this.selectedSubGroupId = -1
      this.$router.replace({ query })
      this.fetchActivity(query)
    },
    async fetchActivity(query) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        await this.$store.dispatch("activity/listActivities", { query })
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        await this.$store.dispatch("ui/showFetchErrorMsg")
      }
    },
    handleUpdateSelectedActivities(activities) {
      this.apptData = {
        ...this.apptData,
        activities: activities
      }
    },
    handleShowActivity() {
      const toggle = !this.showActivities
      this.showActivities = toggle
      let query = { ...this.$route.query }
      if (toggle === true) {
        query["show_activities"] = true
      } else {
        query = _.omit(query, "show_activities")
      }
      this.updateUrl(query)
    },
    handleDataChange(data) {
      this.apptData = data
    },
    validateActivities(activities) {
      const result = activities.find(
        item => item.products && item.products.length > 0 && !item.lot_number
      )

      if (result) {
        this.$notify({
          message: "กรุณาระบุ Lot No",
          type: "warning"
        })
        return false
      } else {
        return true
      }
    },
    verifyBeforeSave() {
      if (!this.apptData.date) {
        this.$notify({
          message: "กรุณาเลือกวันเวลานัด",
          type: "warning"
        })
      }
      if (this.apptData.activities.length == 0) {
        this.$notify({
          message: "กรุณาเลือกกิจกรรมอย่างน้อย 1 อย่าง",
          type: "warning"
        })
      }

      if (
        this.apptData.date &&
        this.apptData.activities.length > 0 &&
        this.validateActivities(this.apptData.activities)
      ) {
        this.showConfirmDialog = true
      }
    },
    async handleSave() {
      let data = {
        action: "markdone",
        done_date_time: this.apptData.date,
        note: this.apptData.note
      }

      data["activities"] = this.apptData.activities.map(item => {
        let activity = {
          activity: item.id,
          price: parseFloat(item.price)
        }
        if (item.lot_number) {
          activity["lot_number"] = item.lot_number
        }
        return activity
      })

      if (this.apptData.vetId !== -1) {
        data["vet"] = this.apptData.vetId
      }

      data["attachments"] =
        this.apptData.attachments.map(item => ({
          attachment: item.id
        })) || []

      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        await this.$store.dispatch("appointment/updateAppointment", {
          params: { id: this.appt.id },
          data: data
        })
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        this.$router.replace({
          name: "appointment-detail",
          params: { id: this.appt.id }
        })
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        await this.$store.dispatch("ui/showFetchErrorMsg")
      }
    },
    async handleChangeActivityGroup(data) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        const query = this.createQueryString(data)
        this.updateUrl(query)
        await this.$store.dispatch("activity/listActivities", {
          query: this.parseQueryToListActivities(query)
        })
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        await this.$store.dispatch("ui/showFetchErrorMsg")
      }
    },
    getDefaultQuery(appt) {
      return {
        all: true,
        is_favorite: true,
        pet_type__key: appt.pet.type.key,
        pet_gender: appt.pet.gender,
        show_activities: this.showActivities,
        markdone_id: this.$route.query.markdone_id
      }
    },
    getAndSetDataFromQueryString(appt) {
      let query = { all: true }

      if (this.$route.query.search) {
        this.searchText = this.$route.query.search
        query["search"] = this.$route.query.search
      } else {
        this.searchText = ""
      }

      if (this.$route.query.is_favorite) {
        this.isFavorite = !!this.$route.query.is_favorite
        query["is_favorite"] = this.$route.query.is_favorite
      } else {
        this.isFavorite = false
      }

      if (this.$route.query.group) {
        this.selectedGroupId = parseInt(this.$route.query.group)
        query["group"] = this.$route.query.group
      } else {
        this.selectedGroupId = -1
      }

      if (this.$route.query.sub_group) {
        this.selectedSubGroupId = parseInt(this.$route.query.sub_group)
        query["sub_group"] = this.$route.query.sub_group
      } else {
        this.selectedSubGroupId = -1
      }

      if (appt.pet.type.key) {
        query["pet_type__key"] = appt.pet.type.key
      }

      if (appt.pet.gender !== null) {
        query["pet_gender"] = appt.pet.gender
      }

      if (this.$route.query.show_activities) {
        this.showActivities = !!this.$route.query.show_activities
        query["show_activities"] = this.showActivities
      }

      if (this.$route.query.markdone_id) {
        this.childId = this.$route.query.markdone_id
        query["markdone_id"] = this.childId
      }

      return query
    },
    createQueryString(data) {
      let query = { all: true }

      if (this.searchText !== "") {
        query["search"] = this.searchText
      }

      if (data.isFavorite) {
        this.isFavorite = data.isFavorite
        query["is_favorite"] = data.isFavorite
      } else {
        this.isFavorite = false
      }

      if (data.selectedGroupId !== -1) {
        this.selectedGroupId = data.selectedGroupId
        query["group"] = data.selectedGroupId
      } else {
        this.selectedGroupId = -1
      }

      if (data.selectedSubGroupId !== -1) {
        this.selectedSubGroupId = data.selectedSubGroupId
        query["sub_group"] = data.selectedSubGroupId
      } else {
        this.selectedSubGroupId = -1
      }

      // based on Appointment Data
      if (this.appt.pet.type.key) {
        query["pet_type__key"] = this.appt.pet.type.key
      }

      if (this.appt.pet.gender !== null) {
        query["pet_gender"] = this.appt.pet.gender
      }

      if (this.showActivities !== undefined) {
        query["show_activities"] = this.showActivities
      }

      return query
    },
    parseQueryToListActivities(query) {
      return _.omit(query, ["show_activities", "markdone_id"])
    },
    updateUrl(query) {
      this.$router.replace({ query })
    }
  },

  async created() {
    try {
      await this.$store.dispatch("ui/setIsFullScreenLoading", true)
      const appt = await this.$store.dispatch("appointment/getAppointment", {
        params: { id: this.$route.params.id }
      })
      await this.$store.dispatch("activity/listActivityGroups")

      let query = this.getDefaultQuery(appt)
      if (!_.isEmpty(this.$route.query)) {
        query = this.getAndSetDataFromQueryString(appt) // if querystring is not empty
      }
      this.updateUrl(query)
      await this.$store.dispatch("activity/listActivities", {
        query: this.parseQueryToListActivities(query)
      })
      await this.$store.dispatch("hospital/listVets")
      await this.$store.dispatch("ui/setIsFullScreenLoading", false)
    } catch (error) {
      await this.$store.dispatch("ui/showFetchErrorWithReloadMsg")
    }
  },
  watch: {
    appt(newValue) {
      if (newValue) {
        if (this.$route.query.markdone_id) {
          const foundMarkDone = newValue.timeline.find(
            item =>
              (item.type === "done" || item.type === "edit_markdone") &&
              item.detail.markdone.id ===
                parseInt(this.$route.query.markdone_id)
          )
          if (foundMarkDone) {
            this.apptData = foundMarkDone.detail.markdone
          }
        } else {
          this.apptData = newValue
        }
        this.apptData["date"] = new Date()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin: 0 auto;
  width: 620px;

  .left-col {
    float: left;
    display: none;
  }
  .right-col {
    float: right;
    width: 100%;
  }

  .pet-profile {
    margin: 0 50px;
  }

  &.show-activities {
    width: 100%;

    .left-col {
      display: block;
      width: 68%;
    }
    .right-col {
      display: block;
      width: 30%;
    }

    .pet-profile {
      margin: 0;
    }
  }
}

.total-topnav {
  display: inline-block;
  width: 150px;
  vertical-align: middle;

  .label {
    color: #666;
    margin-bottom: 5px;
  }
}
</style>

