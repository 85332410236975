<template>
  <div>
    <ContentBox>
      <div class="medical-note">
        <div class="title">Comment</div>
        <MarkdownEditor
          v-model="noteText"
          height="130px"
        ></MarkdownEditor>
        <div class="by-vet">
          <div class="text-normal">โดย</div>
          <v-select
            v-model="vetSelect"
            style="width: 40%;"
            label="full_name"
            :options="vetsItem"
            :clearSearchOnSelect="true"
            :pushTags="false"
            :filterable="true"
          />
          <div class="text-normal">Pin (4 point)</div>
          <el-input size="small" class="row" v-model="vetPin" style="width: 20%;" type="text" maxlength="4"></el-input>
          <el-button class="button-save" size="small" type="primary" @click="saveMedicalNote">Save</el-button>
        </div>
        <div class="title">Recently Comment</div>
        <div class="recently-comment" v-for="item in noteShow" :key="item.id">
          <hr class="line"/>
          <div class="text-normal">{{ item.created | toThaiDate }} {{ item.created | toHHMM }}{{ item.vet.full_name ? ' by ' + item.vet.full_name : '' }}</div>
          <div class="text-normal">
            <Viewer :initial-value="item.text"></Viewer>
          </div>
        </div>
      </div>
    </ContentBox>
  </div>
</template>

<script>
import { mapState } from "vuex"
import ContentBox from "@/components/container/ContentBox"
import MarkdownEditor from '@/components/medical/common/MarkdownEditor'
import { Viewer } from '@toast-ui/vue-editor'

export default {
  props: ['medId', 'vets'],
  components: {
    ContentBox,
    MarkdownEditor,
    Viewer
  },
  data() {
    return {
      vetsItem : this.vets,
      vetSelect: {},
      noteText: "",
      vetPin: "",
      noteShow: null
    }
  },
  computed: {
    ...mapState({
      note: state => (state.medical.note && state.medical.note.results)
    })
  },
  methods: {
    async saveMedicalNote() {
      try {
        let data = {}
        data['text'] = this.noteText
        data['vet'] = this.vetSelect.id
        data['vet_pin'] = this.vetPin
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        let newNote = await this.$store.dispatch("medical/createMedicalNote", {
          params: { id: this.medId },
          data
        })
        if (newNote.id) {
          this.vetSelect = {}
          this.noteText = ""
          this.vetPin = ""
        }
        await this.$store.dispatch("medical/getMedicalNote", {
          params: { id: this.medId }
        })
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (e) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        await this.$store.dispatch("ui/showFetchErrorMsg")
      }
    }
  },
  watch: {
    note: {
      handler() {
        this.noteShow = Object.assign({}, this.note)
      },
      deep: true
    }
  },
  async mounted() {
    try {
      this.vetSelect = {}
      this.noteText = ""
      this.vetPin = ""
      await this.$store.dispatch("ui/setIsFullScreenLoading", true)
      await this.$store.dispatch("medical/getMedicalNote", {
        params: { id: this.medId }
      })
      await this.$store.dispatch("ui/setIsFullScreenLoading", false)
    } catch (e) {
      await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      await this.$store.dispatch("ui/showFetchErrorMsg")
    }
    this.vetsItem.forEach(item => {
      item.name = item.first_name + " " + item.last_name
    });
  }
}
</script>

<style lang="scss" scoped>
  .title {
    font-family: Arial;
    font-size: 18px;
    font-weight: bold;
    color: #2198f1;
  }
  .text-normal {
    display: flex;
    align-self: center;
    margin: 0 15px;
    font-family: Arial;
    font-size: 14px;
    font-weight: normal;
  }
  .button-save {
    margin-left: 15px;
  }
  .medical-note {
    margin: 0 5%;
  }
  .by-vet {
    display: flex;
    margin: 15px 0 15px 30%;
  }
  .line {
    width: 100%;
    margin: 15px 0;
    border: solid 1px #b4b4b4;
  }
</style>
