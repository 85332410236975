import _ from 'lodash'
import CrossmatchInfo from '../blood-request-crossmatch-info/crossmatch-info'

export default class {
  constructor (obj) {
    if (obj != null) {
      this.id = obj.id == null ? null : obj.id
      this.singleCrossmatchList = obj.singleCrossmatchList == null ? [] : this.singleCrossmatchList
      this.multiCrossmatchList = obj.multiCrossmatchList == null ? [] : this.multiCrossmatchList
      this.requester = obj.requester == null ? '' : this.requester
    } else {
      this.id = null
      this.singleCrossmatchList = []
      this.multiCrossmatchList = []
      this.requester = ''
    }

    this.removeList = []
  }

  addSingleCrossmatchToList (bloodCrossmatchObj) {
    this.singleCrossmatchList.push(new CrossmatchInfo(bloodCrossmatchObj))
  }

  removeSingleCrossmatchFromList (bloodBagId) {
    let tmp = _.clone(this.singleCrossmatchList)
    let removedObj
    this.singleCrossmatchList.length = 0
    tmp.forEach((elem) => {
      if (elem.bloodBagId1 !== bloodBagId) {
        this.singleCrossmatchList.push(elem)
      } else {
        if (elem.id != null) {
          removedObj = elem
        }
      }
    })

    if (removedObj != null) {
      let existsInRemoveList = this.removeList.some((crossmatch) => {
        return crossmatch.bloodBagId1 === bloodBagId
      })

      if (!existsInRemoveList) {
        this.removeList.push(removedObj)
      }
    }
  }

  addMultiCrossmatchToList (bloodCrossmatchObj) {
    this.multiCrossmatchList.push(new CrossmatchInfo(bloodCrossmatchObj))
  }

  removeMultiCrossmatchToList (bloodBagId) {
    let tmp = _.clone(this.multiCrossmatchList)
    let removedObj
    this.multiCrossmatchList.length = 0
    tmp.forEach((elem) => {
      if (elem.bloodBagId1 !== bloodBagId && elem.bloodBagId2 !== bloodBagId) {
        this.multiCrossmatchList.push(elem)
      } else {
        if (elem.id != null) {
          removedObj = elem
        }
      }
    })

    if (removedObj != null) {
      let existsInRemoveList = this.removeList.some((crossmatch) => {
        return crossmatch.bloodBagId1 === bloodBagId || crossmatch.bloodBagId2
      })

      if (!existsInRemoveList) {
        this.removeList.push(removedObj)
      }
    }
  }

  getAllCrossmatchInfo(){
    return _.union(this.singleCrossmatchList, this.multiCrossmatchList);
  }

  toRequestJson () {
    let crossMatchArrayJson = []
    _.forEach(_.union(this.singleCrossmatchList, this.multiCrossmatchList), o => {
      crossMatchArrayJson.push(o.toRequestJson())
    })
    return crossMatchArrayJson
  }

  cloneFrom (obj) {
    this.id = obj.id
    this.singleCrossmatchList = [...obj.singleCrossmatchList.map(x => new CrossmatchInfo(x.toRequestJson()))]
    this.multiCrossmatchList = [...obj.multiCrossmatchList.map(x => new CrossmatchInfo(x.toRequestJson()))]
    this.requester = obj.requester
  }
}
