<template>
  <div :class="`form-block ${isLandingPage ? 'landing':''}`">
    <div class="header-block">
      <div class="title">
        Hospital
      </div>
      <div class="lead">
        Register
      </div>
    </div>
    <el-form ref="registerForm" :model="registerForm">
      <el-form-item>
        <el-input
          type="text"
          v-validate="'required'"
          name="hospitalName"
          :prefix-icon="`${isLandingPage ? 'far fa-user':''}`"
          :placeholder="`${isLandingPage ? 'Hospital Name':'ชื่อสถานพยาบาล'}`"
          v-model="registerForm.hospitalName"></el-input>
        <span class="error">{{ errors.first('hospitalName') }}</span>
      </el-form-item>
      <el-form-item>
        <el-input
          type="email"
          v-validate="'required|email'"
          name="email"
          :prefix-icon="`${isLandingPage ? 'far fa-user':''}`"
          placeholder="Email"
          v-model="registerForm.email"></el-input>
        <span class="error">{{ errors.first('email') }}</span>
        <span class="error">{{ errMessage }}</span>
      </el-form-item>

      <p class="email-text">* อีเมล์นี้จะใช้เป็น username ของคุณ</p>

      <el-form-item>
        <el-input
          type="password"
          v-validate="'required'"
          name="password"
          ref="password"
          :prefix-icon="`${isLandingPage ? 'fas fa-unlock-alt':''}`"
          placeholder="Password"
          v-model="registerForm.password"></el-input>
        <span class="error">{{ errors.first('password') }}</span>
      </el-form-item>
      <el-form-item>
        <el-input
          type="password"
          v-validate="'required|confirmed:password'"
          name="passwordConfirm"
          :prefix-icon="`${isLandingPage ? 'fas fa-unlock-alt':''}`"
          placeholder="Re-Password"
          v-model="registerForm.passwordConfirm"></el-input>
        <span class="error">{{ errors.first('passwordConfirm') }}</span>
      </el-form-item>

      <el-form-item v-if="!isNotVerify" class="login-optional">
          <div class="term-and-condition">
            <el-checkbox v-model="termChecked">ยอมรับ 
              <span>
                <el-link href="https://drive.google.com/file/u/3/d/1CB2gkIKR-74QXjfs4HaMZn6zbIX3Tl5l/view?usp=sharing" :underline="false" target="_blank" rel="norefferer">Term and Condition</el-link>
                </span>
            </el-checkbox>
          </div>
          <div></div>
        </el-form-item>

      <!-- <p class="terms-and-condition">
        คุณได้ยอมรับใน Terms and Condition<br> และ Privacy Policy แล้ว
      </p> -->
      <el-form-item class="form-submit text-center">
        <el-button
          :loading="submitButtonLoading"
          :disabled='toggleSignup'
          type="submit"
          class="btn btn-primary form-submit-btn btn-custom"
          @click="onSubmit">sign up</el-button>
      </el-form-item>
    </el-form>
    <div class="footer-block">
      <span>เป็นสมาชิกแล้ว? </span>
      <router-link :to="{ name: 'login' }">เข้าสู่ระบบ</router-link>
    </div>

    <modal class="verification-modal" v-bind:show="showModal" v-on:closeModal="showModal = false">
      <span class="title">Welcome to VRemind!</span>
      <span class="primary-blue">Please verify your account</span><br/>
      <span>by clicking the activate button in your email</span><br/><br/>
      <span class="primary-blue">กรุณายืนยันการสมัคร</span><br/>
      <span>ด้วยการกดปุ่มเพื่อยืนยันอีเมล์นี้ในอีเมล์ของคุณ</span><br/>
      <p class="foot-note">* This link will expire in 7 Days<br/>ลิงก์จะหมดอายุภายใน 7 วัน</p>
      <el-button class="btn btn-primary" type="text" @click="closeVerifyAccountModal">OK</el-button>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/alert/Modal";

export default {
  props: {
    isLandingPage: {
      type: Boolean,
      default: false
    } 
  },
  data() {
    return {
      registerForm: {
        hospitalName: '',
        email: '',
        password: '',
        passwordConfirm: '',
      },
      errMessage: '',
      showModal: false,
      termChecked: false,
      submitButtonLoading: false,
    }
  },
  computed: {
    toggleSignup: function(){
      return !this.termChecked;
    }
  },
  methods: {
    async onSubmit() {
      this.submitButtonLoading = true
      const valid = await this.$validator.validateAll()
      if (valid) {
        try {
          await this.$store.dispatch("ui/setIsFullScreenLoading", true)
          await this.$store.dispatch("hospital/register", {
            data: {
              name: this.registerForm.hospitalName,
              email: this.registerForm.email,
              password: this.registerForm.password
            }
          })
          this.showVerifyAccountModal()
           this.submitButtonLoading = false
          await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        } catch (err) {
          await this.$store.dispatch("ui/setIsFullScreenLoading", false)
           this.submitButtonLoading = false
          this.errMessage = err.email[0]
        }
      }
      this.submitButtonLoading = false
    },
    showVerifyAccountModal() {
      this.showModal = true
    },
    closeVerifyAccountModal() {
      this.showModal = false
      this.$router.push({ name: 'login' })
    }
  },
  components: {
    Modal
  }
}
</script>

<style lang="scss" scoped>
.form-block {
  @extend %block-container;
  @extend %form-style;
  .email-text {
    margin: 0 6px;
    color: $blue;
    font-weight: bold;
  }
}
.terms-and-condition {
  color: $blue;
  margin-bottom: 6px;
  text-align: center;
  line-height: 1.2;
}
.footer-block {
  text-align: center;
  span {
    color: $gray;
  }
  a {
    text-decoration: none;
    color: #000;
  }
}
button.btn-custom {
  width: 213px !important;
  font-size: 17px !important;
}
.verification-modal {
  text-align: center;
  font-size: 1.25rem;
  .title {
    display: block;
    font-size: 1.2em;
    margin-bottom: 15px;
    color: #000;
  }
  .primary-blue {
    color: $primary;
  }
  .foot-note {
    color: $red;
    font-size: 0.8rem;
  }
  .btn-primary {
    padding: 10px 70px;
  }
}

.landing {
  .email-text, .terms-and-condition {
    font-size: 10px;
  }

  .terms-and-condition {
    margin-top: 35px;
    margin-bottom: 10px;
  }

  .form-submit-btn {
    width: 213px;
    height: 38px;
    border-radius: 100px;
  }

  .form-submit {
    text-align: center;
  }

  .title {
    margin-bottom: 0px;
  }

  .el-form-item {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .footer-block {
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 20px;
  }
}
</style>

<style lang="scss" scoped>
.login-optional {
  .el-form-item__content {
    div {
      width: auto;
      display: inline-block;
      vertical-align: middle;

      .el-checkbox__label, a {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: 0.1px;
        text-align: center;
        color: #8e8e93;
        text-decoration: none;
      }
    }
    div:first-child {
      float: left;
    }
    div:last-child {
      float: right;
    }
  }
  .el-checkbox__inner {
    border-radius: 8px;
  }
}
</style>
