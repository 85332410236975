import * as vuexHelper from "../helpers/vuexHelper";

import { coreAPI } from "../../services/apis";
import * as endpoints from "../../services/endpoints";

const config = {
  default: {
    api: coreAPI
  },
  state: {
    all: null,
    overview: {},
    doctorsfee: [],
    doctorfee: null,
    doctorsfeeactivity: [],
    types: [],
    summaryoverview: {},
    summarylist: [],
    summary: null,
  },
  asyncActions: [
    {
      name: "getOverview",
      method: "get",
      setState: "overview",
      url: endpoints.incomeExpenseOverview,
    },
    {
      name: "listIncomeExpense",
      method: "get",
      url: endpoints.incomeExpense,
      setState: "all"
    },
    {
      name: "updateIncomeExpense",
      method: "patch",
      url: ({ id }) => `${endpoints.incomeExpense}${id}/`,
    },
    {
      name: "getIncomeExpense",
      method: "get",
      url: ({ id }) => `${endpoints.incomeExpense}${id}/`,
    },
    {
      name: "listIncomeExpenseTypes",
      method: "get",
      setState: "types",
      url: `${endpoints.incomeExpense}type/`,
    },
    {
      name: "createIncomeExpenseTypes",
      method: "post",
      url: `${endpoints.incomeExpense}type/`,
    },
    {
      name: "createIncomeGeneral",
      method: "post",
      url: `${endpoints.incomeExpense}income/`,
    },
    {
      name: "listIncomeExpenseTypeAll",
      method: "get",
      setState: "types",
      url: `${endpoints.incomeExpense}type/all/`,
    },
    {
      name: "createIncomeProduct",
      method: "post",
      url: `${endpoints.incomeExpense}product/income/`,
    },
    {
      name: "createExpenseGeneral",
      method: "post",
      url: `${endpoints.incomeExpense}expense/`,
    },
    {
      name: "createExpenseProduct",
      method: "post",
      url: `${endpoints.incomeExpense}product/expense/`,
    },
    {
      name: "deleteIncomeExpense",
      method: "delete",
      url: ({ id }) => `${endpoints.incomeExpense}${id}/`,
    },
    {
      name: 'upload',
      method: 'post',
      url: `${endpoints.incomeExpense}attachment/`
    },
    {
      name: "listDoctorsFee",
      method: "get",
      url: `${endpoints.incomeExpense}doctorsfee/`,
      setState: "doctorsfee"
    },
    {
      name: "getDoctorsFee",
      method: "get",
      setState: "doctorfee",
      url: ({ id }) => `${endpoints.incomeExpense}doctorsfee/${id}/`,
    },
    {
      name: "listDoctorsFeeActivity",
      method: "get",
      url: ({ id }) => `${endpoints.incomeExpense}doctorsfee/activity/${id}/`,
      setState: "doctorsfeeactivity"
    },
    {
      name: "overviewSummary",
      method: "get",
      url: `${endpoints.incomeExpense}summary/overview/`,
      setState: "summaryoverview"
    },
    {
      name: "listSummary",
      method: "get",
      url: `${endpoints.incomeExpense}summary/`,
      setState: "summarylist"
    },
    {
      name: "getSummary",
      method: "get",
      setState: "summary",
      url: `${endpoints.incomeExpense}summary/detail/`,
    },
    {
      name: "addIncome",
      method: "post",
      url: `${endpoints.incomeExpense}importexport/add_income/`,
    },
    {
      name: "addExpense",
      method: "post",
      url: `${endpoints.incomeExpense}importexport/add_expense/`,
    },
    {
      name: "getIncomeExpenseDetail",
      method: "get",
      url: ({ id }) => `${endpoints.incomeExpense}importexport/${id}/`,
    },
    {
      name: "updateIncomeExpenseDetail",
      method: "patch",
      url: ({ id }) => `${endpoints.incomeExpense}importexport/${id}/`,
    },
    {
      name: "voidIncomeExpense",
      method: "post",
      url: ({ id }) => `${endpoints.incomeExpense}importexport/${id}/void/`,
    },
  ]
};

export default vuexHelper.createStoreModule(config);
