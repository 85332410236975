
export default class {
  constructor (obj) {
    if (obj != null) {
      this.id = obj.id == null ? null : obj.id
      this.createdDate = obj.created == null ? null : obj.created
      this.modifiedDate = obj.last_updated == null ? null : obj.last_updated
      this.isApproved = obj.is_approved == null ? null : obj.is_approved
      this.approver = obj.approver == null ? '' : obj.approver
      this.approverName = obj.approver_name == null ? '' : obj.approver_name
      this.approvalNote = obj.note == null ? null : obj.note
      this.bloodRequestId = obj.blood_bag_request == null ? null : obj.blood_bag_request
    } else {
      this.id = null
      this.createdDate = null
      this.modifiedDate = null
      this.isApproved = null
      this.approver = ''
      this.approverName = ''
      this.approvalNote = null
      this.bloodRequestId = null
    }
  }

  toRequestJson () {
    return {
      id: this.id,
      created: this.createdDate,
      last_updated: this.modifiedDate,
      is_approved: this.isApproved,
      approver: this.approver,
      approver_name: this.approverName,
      note: this.approvalNote,
      blood_bag_request: this.bloodRequestId
    }
  }
}
