<script>
export default {
  inject: ["command"],

  props: {
    value: {
      type: undefined,
      default: undefined,
    },

    icon: {
      type: String,
      default: undefined,
    },

    type: {
      type: String,
      default: undefined,
    },
  },

  computed: {
    buttonClass() {
      switch (this.type) {
        case "primary":
          return "btn-primary";

        case "success":
          return this.$style.isSuccess;

        case "danger":
          return "btn-delete";

        case "coral":
          return "btn-coral";

        default:
          return "btn-gray";
      }
    },
  },

  methods: {
    handleClick() {
      this.command(this.value);
    },
  },

  render() {
    return (
      <button
        type="button"
        class={["btn", "btn-large", this.buttonClass, this.$style.root]}
        onClick={this.handleClick}
      >
        {this.icon && (
          <img
            src={this.icon}
            style={{
              height: "1em",
              marginRight: "0.5em",
              verticalAlign: "middle",
            }}
          />
        )}
        {this.$slots.default}
      </button>
    );
  },
};
</script>

<style lang="scss" module>
.root {
  display: block;
  width: 100%;
  margin: 0 !important;

  & + & {
    margin-top: 0.5rem !important;
  }
}

.isSuccess {
  color: #7ed321;
  box-shadow: inset 0 0 0 1px #7ed321;

  &:hover {
    color: white;
    background-color: #7ed321 !important;
  }
}
</style>
