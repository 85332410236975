<template>
  <el-dialog title="ADD Health Points" :visible.sync="visible" center @close="$emit('cancel')">
    <el-form :model="form" :rules="rules" ref="addPetPointForm" label-position="top">
      <el-form-item label="จำนวนแต้ม" :label-width="formLabelWidth" prop="point">
        <el-input-number 
        v-model="form.point" 
        :precision="0" 
        :step="1"
        :min="1"
        style="width: 50%;">
        </el-input-number>
      </el-form-item>
      <p class="text-hint">*แต้มสะสมสำหรับแลก reward ต่างๆของสถานพยาบาล</p>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('cancel')">Cancel</el-button>
      <el-button type="primary" @click="$emit('confirm')">Save</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
    props: {
      visible: Boolean,
      form: Object
    },
    data() {
      return {
        formLabelWidth: '100%',
        rules: {
          weight: [
            { required: true, },
          ],
        }
      };
    },
  };
</script>

<style lang="scss">
  .el-dialog__header .el-dialog__title {
    font-size: 20px;
    font-weight: bold;
    color: $light-blue;
  }
  .el-dialog--center .el-dialog__body {
    font-size: 16px;
    padding: 20px 80px;

    .el-form--label-top .el-form-item__label {
      padding-bottom: 0;
      font-weight: bold;
      font-size: 16px;
    }
  }
  
</style>

<style lang="scss" scoped>
.text-hint {
  color: #02b0e1;
  font-size: 12px;
}
</style>
