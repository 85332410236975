var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Subnavbar',[_c('template',{slot:"title"},[_vm._v("REPORT Visit Frequency And Expense Rate ")])],2),_c('div',{staticClass:"content-container"},[_c('div',{staticClass:"section-title"},[_vm._v("Visit Frequency And Expense Rate")]),(_vm.visitOverview)?_c('ExpenceVisitOverview',{attrs:{"chartData":_vm.getVisitOverviewData(),"options":_vm.getVisitOverviewOptions(),"customStyles":"height: 174px;position: relative;"}}):_vm._e()],1),_c('div',{staticClass:"content-container"},[_c('div',{staticClass:"date-filter-form"},[_c('div',{staticClass:"title"},[_vm._v("Date for")]),_c('div',{staticClass:"date-filter"},[_vm._v(" from   "),_c('el-date-picker',{staticStyle:{"width":"450px"},attrs:{"type":"daterange","editable":false,"clearable":false,"range-separator":"To","unlink-panels":"","start-placeholder":"Start date","end-placeholder":"End date"},model:{value:(_vm.datefilter),callback:function ($$v) {_vm.datefilter=$$v},expression:"datefilter"}})],1)]),_c('Panel',{attrs:{"enName":"PET","thName":"สัตว์เลี้ยง","customStyle":_vm.getPanelStyle()}},[_c('template',{slot:"viewFullLink"},[_c('p',{on:{"click":function($event){_vm.$router.push({ 
          name: 'expence-visit-report-detail', 
          params: { type: 'pet' },
          query: {start: _vm.datefilter[0].toString(), end: _vm.datefilter[1].toString()} })}}},[_vm._v("See more > ")])])],2),_c('el-row',{staticClass:"graph-container"},[_c('el-col',{staticClass:"graph",attrs:{"span":12}},[(_vm.petVisitFrequency)?_c('ExpenceVisitChart',{attrs:{"title":"Visit Frequency Rate","subtitle":"ความถี่ในการเข้ามารับบริการของสัตว์เลี้ยง (ตัว)","chartData":_vm.generateChartData('expence', _vm.petVisitFrequency),"options":_vm.generateChartOptions('pet'),"customStyles":{height: '210px', position: 'relative'}}}):_vm._e()],1),_c('el-col',{staticClass:"graph",attrs:{"span":12}},[(_vm.petExpenceRate)?_c('ExpenceVisitChart',{attrs:{"title":"Expense Rate","subtitle":"การใช้จ่ายของสัตว์เลี้ยง (ตัว)","chartData":_vm.generateChartData('frequency', _vm.petExpenceRate),"options":_vm.generateChartOptions('pet'),"customStyles":{height: '210px', position: 'relative'}}}):_vm._e()],1)],1),_c('Panel',{attrs:{"enName":"PET OWNER","thName":"เจ้าของสัตว์เลี้ยง","customStyle":_vm.getPanelStyle()}},[_c('template',{slot:"viewFullLink"},[_c('p',{on:{"click":function($event){_vm.$router.push({ 
          name: 'expence-visit-report-detail',
          params: { type: 'owner' },
          query: {start: _vm.datefilter[0].toString(), end: _vm.datefilter[1].toString()} })}}},[_vm._v("See more > ")])])],2),_c('el-row',{staticClass:"graph-container"},[_c('el-col',{staticClass:"graph",attrs:{"span":12}},[(_vm.ownerVisitFrequency)?_c('ExpenceVisitChart',{attrs:{"title":"Visit Frequency Rate","subtitle":"ความถี่ในการเข้ามารับบริการของเจ้าของสัตว์เลี้ยง (คน)","chartData":_vm.generateChartData('expence', _vm.ownerVisitFrequency),"options":_vm.generateChartOptions('owner', _vm.ownerVisitFrequency),"customStyles":{height: '210px', position: 'relative'}}}):_vm._e()],1),_c('el-col',{staticClass:"graph",attrs:{"span":12}},[(_vm.ownerExpenceRate)?_c('ExpenceVisitChart',{attrs:{"title":"Expense Rate","subtitle":"การใช้จ่ายของเจ้าของสัตว์เลี้ยง (คน)","chartData":_vm.generateChartData('frequency', _vm.ownerExpenceRate),"options":_vm.generateChartOptions('owner', _vm.ownerExpenceRate),"customStyles":{height: '210px', position: 'relative'}}}):_vm._e()],1)],1),_c('div',{staticStyle:{"margin-bottom":"120px"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }