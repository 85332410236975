<template>
  <div class="medical-detail-pdf" id="medical-detail-pdf">
    <img crossOrigin="Anonymous" id="hospital-logo" style="position: absolute; top: 12px; left: 20px; object-fit: contain;" width="50px" height="50px"/>
    <div class="title-content-upper" style="position: absolute; top: 24px; left: 86px;">ใบเสร็จรับเงิน</div>
    <div class="title-content-lower" style="position: absolute; top: 41px; left: 86px;">{{ hospital.name }}</div>
    <div v-if="med.finished" class="title-right" style="position: absolute; top: 27px; left: 439px;">
      <div>{{ getDay() }} {{ med.finished | toThaiDate }}</div>
      <div>เวลา {{ med.finished | toHHMM}}</div>
    </div>
    <div v-else class="title-right" style="position: absolute; top: 27px; left: 439px;">
      <div>{{ getDay() }} {{ med.arrived | toThaiDate }}</div>
      <div>เวลา {{ med.arrived | toHHMM}}</div>
    </div>
    <div class="title-address" style="position: absolute; top: 61px; left: 22px;">ที่อยู่: {{ getFullAddress() }}</div>
    <div class="title-contact" style="position: absolute; top: 62px; left: 320px;">{{ getTel() }}</div>
    <div class="title-line" style="position: absolute; top: 61px; left: 505px;">@Line: {{ hospital.line_id }}</div>
    <div style="position: absolute; top: 88px; left: 20px;" class="upper-line"/>
    <div class="content-title" style="position: absolute; top: 105px; left: 21px;">ชื่อสัตว์</div>
    <div class="content-title" style="position: absolute; top: 120px; left: 21px;">เจ้าของ</div>
    <div class="content-title" style="position: absolute; top: 135px; left: 21px;">กิจกรรม</div>
    <div class="content-detail" style="position: absolute; top: 105px; left: 105px;">{{ getPetDetail() }}</div>
    <div class="content-detail" style="position: absolute; top: 120px; left: 105px;">{{ getPetOwner() }}</div>
    <div class="content-activity">
      <div v-if="med && med.pos">
        <div style="position: absolute; top: 160px; left: 22px;">
          <div v-for="activity in med.pos.posactivity_set" :key="activity.id">
            {{ activity.amount }} {{ activity.activity.name }}
          </div>
        </div>
        <div style="position: absolute; top: 160px; left: 530px;">
          <div style="text-align: right;" v-for="activity in med.pos.posactivity_set" :key="activity.id">
            {{ getSumPrice(activity.unit_price, activity.amount) | currency }}
          </div>
        </div>
      </div>
      <div v-else>-</div>
    </div>
    <div class="content-final">
      <div v-if="med && med.pos">
        <div style="position: absolute; top: 340px; left: 400px;">
          <div>รวม</div>
          <div>ส่วนลด</div>
          <div>รวมทั้งหมด</div>
        </div>
        <div style="position: absolute; top: 340px; right: 50px;">
          <div style="text-align: right;">{{ totalPrice()| currency }}</div>
          <div style="text-align: right;">{{ totalDiscount() | currency }}</div>
          <div style="text-align: right;">{{ totalPriceWithDiscount() | currency }}</div>
        </div>
      </div>
    </div>
    <div class="power-by" style="position: absolute; top: 395px; left: 243px;">Power by : </div>
    <img src="@/assets/brand/v-remind-logo-text.png" class="pic" style="position: absolute; top: 395px; left: 290px; object-fit: contain;" width="63px" height="15px"/>
  </div>
</template>

<script>
// import jsPDF from "jspdf"
// import _ from "lodash"
import printJS from 'print-js'
import html2canvas from "html2canvas"

function convertImgToBase64URL(url, callback, outputFormat){
  var img = new Image();
  img.crossOrigin = 'Anonymous';
  img.onload = function(){
      var canvas = document.createElement('CANVAS'),
      ctx = canvas.getContext('2d'), dataURL;
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0);
      dataURL = canvas.toDataURL(outputFormat);
      callback(dataURL);
      canvas = null;
  };
  img.src = url;
}

export default {
  props: ["pet", "med", "hospital", "hn"],
  components: {},
  mounted() {
    this.logo()
    setTimeout(() => {
      html2canvas(document.getElementById('medical-detail-pdf'), { scale: 4 }).then(canvasimg => {
        var imgData = canvasimg.toDataURL("image/png")
        printJS(imgData, 'image')
      })
    }, 2000)
   },
  updated() {
  },
  methods: {
    logo() {
      if (this.hospital.logo.small) {
        convertImgToBase64URL(this.hospital.logo.small, function(base64Img){
            document.getElementById('hospital-logo').src = base64Img
        })
      }
    },
    getDay() {
      let dateUse = new Date()
      let thday = ["อาทิตย์","จันทร์", "อังคาร","พุธ","พฤหัส","ศุกร์","เสาร์"]
      return 'วัน' + thday[dateUse.getDay()] + 'ที่'
    },
    getFullAddress() {
      return this.hospital.address + ' ' + this.hospital.sub_district + ' ' + this.hospital.district + ' ' + this.hospital.province + ' ' + this.hospital.zip_code
    },
    getTel() {
      let phone = ''
      this.hospital.contact_phones.forEach(p => {
        phone += 'tel: ' + p + ' '
      })
      return phone
    },
    getPetDetail() {
      let detail = this.pet.name
      if (this.pet.type) {
        detail += ' ' + this.pet.type.name
      }
      if (this.pet.breed) {
        detail += ' ' + this.pet.breed.name
      }
      detail += ' ' + this.getPetAge(new Date(this.pet.birthday))
      if (this.hn) {
        detail += ' HN.' + this.hn
      }

      return detail
    },
    getPetAge(date) {
      if (date == null) {
        return '-'
      }
      var currentDate = new Date()
      var timeDiff = Math.abs(currentDate.getTime() - date.getTime())
      var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
      var diffMonth = parseInt(diffDays / 30 % 12)
      var diffYear = parseInt(diffDays / 30 / 12)
      return 'อายุ ' + (diffYear > 0 ? diffYear + ' ปี ' : '') + (diffMonth > 0 || diffYear <= 0 ? diffMonth + ' เดือน' : '')
    },
    getPetOwner() {
      if (this.pet.owners.length > 0) {
        return this.pet.owners[0].firstname + ' ' + this.pet.owners[0].lastname
      } else {
        return '-'
      }
    },
    getSumPrice(unit_price, amount) {
      return parseFloat(unit_price) ? parseFloat(unit_price) * amount : 0
    },
    totalPrice() {
      return this.med.pos.grand_total_price.before_discount;
    },
    totalDiscount() {
      return this.med.pos.grand_total_price.discount;
    },
    totalPriceWithDiscount() {
      return this.med.pos.grand_total_price.after_discount;
    }
  }
}
</script>

<style lang="scss" scoped>

@page {
  size: 595px 421px;
  margin: 0;
  padding: 0;
}

@media print {
  .medical-detail-pdf {
    size: auto;
  }
}

.medical-detail-pdf {
  background-color: #ffffff;
  width: 595px;
  height: 421px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.title-content-upper {
  width: 120px;
  height: 13px;
  font-family: Arial;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.title-content-lower {
  width: 135px;
  height: 16px;
  font-family: Arial;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.title-right {
  width: 160px;
  height: 26px;
  font-family: Thonburi;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000000;
}

.title-address {
  width: 300px;
  height: 12px;
  font-family: Avenir;
  font-size: 9px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.title-contact {
  width: 180px;
  height: 12px;
  font-family: Avenir;
  font-size: 9px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.title-line {
  width: 100px;
  height: 12px;
  font-family: Avenir;
  font-size: 9px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.upper-line {
  width: 575px;
  border: solid 1px #8e8e93;
}

.content-title {
  width: 40px;
  height: 13px;
  font-family: Arial;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.content-detail {
  width: 400px;
  height: 13px;
  font-family: Arial;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.content-activity {
  width: 420px;
  height: 100%;
  font-family: Thonburi;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.content-final {
  width: 150px;
  height: 40px;
  font-family: Arial;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #4a4a4a;
}

.power-by {
  width: 109px;
  height: 12px;
  font-family: Avenir;
  font-size: 9px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.pic {
  object-fit: contain;
}

</style>
