import Vue from "vue";

/**
 * Show stock cut dialog
 * @param {Object} propsData
 * @param {boolean} propsData.undo
 * @param {Object[]} propsData.items
 * @param {string} propsData.items[].name
 * @param {number} propsData.items[].value
 * @param {string} propsData.items[].unit
 */
export const showStockCutDialog = (propsData = {}, beforeClose) =>
  new Promise(async (resolve) => {
    const module = await import("./StockCutDialog");

    const Component = Vue.extend(module.default);

    const instance = new Component({
      propsData,

      destroyed() {
        document.body.removeChild(this.$el);
      },
    }).$mount();

    document.body.appendChild(instance.$el);

    instance.beforeClose = beforeClose;

    instance.$on("confirm", () => {
      resolve();
    });
  });
