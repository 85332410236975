<template>
  <div class="navbar">
    <el-row :gutter="20">
      <!-- <el-col :span="8"> -->
      <div class="left-col">
        <template v-if="profile">
          <a
            :class="{ 'toggle-button': true, active: showSidebar }"
            @click="showSidebar = !showSidebar"
          >
            <span class="icon" />
          </a>
        </template>
        <!-- <template v-else>
          <img class="logo" src="@/assets/brand/v-remind-logo.png" alt="VRemind Logo" />
        </template>-->
      </div>
      <router-link :to="{ name: (user_type == 'hospital_admin') ? 'hospitalHome' : 'petOwnerHome' }">
        <img
          v-if="user_type == 'hospital_admin'"
          src="@/assets/brand/v-remind-logo-text.png"
          alt="VRemind Logo"
          class="logo"
        />
        <img
          v-else
          src="@/assets/brand/itaam-logo.png"
          alt="VRemind Logo"
          class="logo"
        />
        
      </router-link>
      <div
        class="right-col"
        v-if="profile"
      >
        <template v-if="user_type == 'hospital_admin'">
          <el-popover
            placement="bottom"
            width="250"
            trigger="click"
          >
            <div class="message-container">
              <h3 class="title">Your messages</h3>
              <div
                @click="() => $router.push({ name: 'hospitalMessageDetail', params: { type: msg.type, id: msg.detail.id }})"
                :class="{ 'message': true, 'is_seen': !msg.is_seen }"
                v-for="msg in messages"
                :key="msg.id"
              >
                <el-row>
                  <el-col :span="8">
                    <div
                      class="thumbnail"
                      v-if="msg.detail.cover_img.thumbnail"
                    >
                      <img :src="msg.detail.cover_img.thumbnail" />
                    </div>
                  </el-col>
                  <el-col :span="16">
                    <div class="content">{{ msg.detail.content }}</div>
                  </el-col>
                </el-row>
                <div class="footer">
                  <el-row>
                    <el-col :span="12">
                      <div class="hospital">
                        <img
                          :src="msg.detail.sender.logo"
                          class="logo"
                        />
                        {{ msg.detail.sender.name }}
                      </div>
                    </el-col>
                    <el-col
                      :span="12"
                      style="text-align: right;"
                    >
                      {{ msg.date | toThaiDate }}
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
            <div
              class="notification"
              slot="reference"
            >
              <i class="el-icon-bell" />
              <span
                class="total"
                v-if="notificationTotal.not_seen_count > 0"
              >{{ notificationTotal.not_seen_count }}</span>
            </div>
          </el-popover>
        </template>
        <div class="avatar">
          <template v-if="profile">
            <img
              :src="profile.logo.medium"
              v-if="profile.logo && profile.logo.medium"
            />
            <img
              :src="profile.image.medium"
              v-if="profile.image && profile.image.medium"
            />
          </template>
        </div>
        <div class="profile">
          <template v-if="profile && profile.name">{{ profile.name }} 
              <span v-if="user_type == 'hospital_admin'" 
                class="hospital-current-user">{{ getHospitalCurrentUser() }}</span></template>
          <template v-if="profile && profile.firstname">{{ profile.firstname }} {{ profile.lastname }}</template>
        </div>
      </div>
      <!-- </el-col> -->
    </el-row>
    <div
      class="sidebar"
      v-show="showSidebar"
    >
      <div
        class="backdrop"
        @click="showSidebar = false"
      ></div>
      <div class="sidebar-content">
        <div class="header-menu">
          <a @click="navigateTo({ name: (user_type == 'hospital_admin') ? 'hospitalHome' : 'petOwnerHome' })">
            <i class="fa fa-home"></i> HOME
          </a>
        </div>
        <el-menu :default-openeds="openMenus">
          <template v-if="user_type == 'hospital_admin'">
            <el-submenu index="itaam-web">
              <template slot="title">
                <span>
                  <i class="fa fa-dog"></i>iTaam web
                </span>
              </template>
              <el-menu-item
                index="itaam-hospital-profile"
                @click="gotoiTaam('/hospital/me/')"
              >Hospital Profile</el-menu-item>
              <el-menu-item
                index="itaam-hospital-add-story"
                @click="gotoiTaam('/content/create')"
              >Add Story</el-menu-item>
            </el-submenu>
            <el-submenu index="appointment">
              <template slot="title">
                <span>
                  <i class="fa fa-calendar-check"></i>Appointment
                </span>
              </template>
              <el-menu-item
                index="appointment-list"
                @click="navigateTo({ name: 'appointments' })"
              >Appointment List</el-menu-item>
              <el-menu-item
                index="calendar"
                @click="navigateTo({ name: 'calendar' })"
              >Calendar</el-menu-item>
              <!-- <el-menu-item index="follow-up">Follow up &amp; Feedback</el-menu-item> -->
              <el-menu-item
                index="new-appointment"
                @click="navigateTo({ name: 'appointment-select-pet' })"
              >+ Add New Appointment</el-menu-item>
            </el-submenu>
            <el-submenu index="profile">
              <template slot="title">
                <span>
                  <i class="fa fa-paw"></i>Pet Profile
                </span>
              </template>
              <el-menu-item
                index="pet-profile-list"
                @click="navigateTo({ name: 'hospitalPets' })"
              >Pet Profile List</el-menu-item>
              <el-menu-item index="search-microchip" 
                v-if="currentPlan.hospital_feature.can_use_microchip_search"
                @click="navigateTo({ name: 'hospitalPetsSearchMicrochip' })">
                <template slot="title">
                  <span>Search From Microchip</span>
                </template>
              </el-menu-item>
              <el-menu-item
                index="new-pet"
                v-if="currentPlan.hospital_feature.can_create_owner_and_pet"
                @click="navigateTo({ name: 'hospitalPetsSearch' })"
              >+ Add New Pet</el-menu-item>
            </el-submenu>
            <el-submenu index="medical">
              <template slot="title">
                <span>
                  <i class="fa fa-archive"></i>OPD
                </span>
              </template>
              <el-menu-item
                index="medical-list"
                @click="navigateTo({ name: 'medicals' })"
              >OPD List</el-menu-item>
              <el-menu-item
                index="medical-deleted"
                v-if="currentPlan.opd_section.can_view_opd_delete_list"
                @click="navigateTo({ name: 'medical-deleted' })"
              >OPD Delete List</el-menu-item>
              <el-menu-item
                index="medical-add"
                v-if="currentPlan.opd_section.can_create_opd"
                @click="navigateTo({ name: 'medicals-select-pet' })"
              >Add OPD</el-menu-item>
            </el-submenu>
            <el-submenu index="ipd"
              v-if="currentPlan.ipd_section.can_use_ipd">
              <template slot="title">
                <span>
                  <i class="fa fa-archive"></i>IPD
                </span>
              </template>
              <el-menu-item
                index="ipd"
                @click="navigateTo({ name: 'ipd' })"
              >IPD List</el-menu-item>
              <el-menu-item
                index="ipd-deleted"
                v-if="currentPlan.ipd_section.can_view_ipd_delete_list"
                @click="navigateTo({ name: 'ipd-deleted' })"
              >IPD Delete List</el-menu-item>
            </el-submenu>
            <el-submenu index="pos"
              v-if="currentPlan.pos_section.can_use_pos">
              <template slot="title">
                <span>
                  <i class="fa fa-archive"></i>POS
                </span>
              </template>
              <el-menu-item
                index="pos"
                @click="navigateTo({ name: 'pos' })"
              >POS List</el-menu-item>
              <el-menu-item
                index="medical-add"
                @click="navigateTo({ name: 'pos-create' })"
              >Add POS</el-menu-item>
              <el-menu-item
                index="pos-deleted"
                @click="navigateTo({ name: 'pos-deleted' })"
              >POS Delete List</el-menu-item>
            </el-submenu>
            <el-submenu index="inventory" 
              v-if="currentPlan.stock_section.can_view_stock || currentPlan.stock_section.can_use_product_setting">
              <template slot="title">
                <span>
                  <i class="fa fa-archive"></i>Inventory
                </span>
              </template>
              <el-menu-item
                index="inventory-list"
                v-if="currentPlan.stock_section.can_view_stock"
                @click="navigateTo({ name: 'inventory-list' })"
              >Current Inventory</el-menu-item>
              <el-menu-item
                index="inventory-transaction"
                v-if="currentPlan.stock_section.can_view_inventory_transaction"
                @click="navigateTo({ name: 'inventory-transaction' })"
              >Inventory Transaction</el-menu-item>
              <!-- <el-menu-item
                index="inventory-used"
                v-if="currentPlan.stock_section.can_view_stock"
                @click="navigateTo({ name: 'inventory-used' })"
              >Product Used Summary</el-menu-item> -->
              <!-- <el-menu-item
                index="inventory-unused"
                v-if="currentPlan.stock_section.can_view_stock"
                @click="navigateTo({ name: 'inventory-unused' })"
              >Product Slow Moving</el-menu-item> -->
              <!-- <el-menu-item
                index="inventory-value"
                v-if="currentPlan.stock_section.can_view_stock"
                @click="navigateTo({ name: 'inventory-value' })"
              >Product Value</el-menu-item> -->
            </el-submenu>
            <el-submenu index="finance">
              <template slot="title">
                <span>
                  <i class="fa fa-coins"></i>Finance
                </span>
              </template>
              <el-menu-item
                index="income-expense-list"
                v-if="currentPlan.income_expense_section.can_view_income_expense_transaction"
                @click="navigateTo({ name: 'income-expense-list' })"
              >Income-Expense Transaction</el-menu-item>
              <el-menu-item
                index="df-list"
                v-if="currentPlan.income_expense_section.can_view_doctor_fee"
                @click="navigateTo({ name: 'df-list' })"
              >Doctor’s Fee (DF)</el-menu-item>
              <el-menu-item
                index="income-expense-summary"
                v-if="currentPlan.income_expense_section.can_view_income_expense_summary"
                @click="navigateTo({ name: 'income-expense-summary' })"
              >Income-Expense Summary</el-menu-item>
              <el-menu-item
                index="shift"
                v-if="currentPlan.income_expense_section.can_view_shift
                    || currentPlan.income_expense_section.can_open_close_shift"
                @click="navigateTo({ name: 'shift' })"
              >รอบเงินสดหน้าร้าน</el-menu-item>
              <el-menu-item
                index="income-expense-receipt-create"
                v-if="currentPlan.income_expense_section.can_view_receipt_create"
                @click="navigateTo({ name: 'income-expense-receipt-create' })"
              >Create Receipt</el-menu-item>
              <el-menu-item
                index="income-expense-receipt"
                v-if="currentPlan.income_expense_section.can_view_receipt_list"
                @click="navigateTo({ name: 'income-expense-receipt' })"
              >Receipt List</el-menu-item>
              <el-menu-item
                index="income-expense-overdue"
                v-if="currentPlan.income_expense_section.can_view_overdue_list"
                @click="navigateTo({ name: 'income-expense-overdue' })"
              >Overdue List</el-menu-item>
              <el-menu-item
                index="add-income"
                v-if="currentPlan.income_expense_section.can_add_income_expense"
                @click="navigateTo({ name: 'income-expense-income-add' })"
              >+ Add Income</el-menu-item>
              <el-menu-item
                index="add-expense"
                v-if="currentPlan.income_expense_section.can_add_income_expense"
                @click="navigateTo({ name: 'income-expense-expense-add' })"
              >+ Add Expense</el-menu-item>
            </el-submenu>
            <!-- <el-submenu index="bloodbank" 
              v-if="currentPlan.hospital_feature.can_use_smart_pet_blood_bank">
              <template slot="title">
                <span>
                  <i
                    class="fa fa-tint"
                    style="margin-left: 3px;"
                  ></i>
                  <span style="margin-left: 4px;">Bloodbank</span>
                </span>
              </template>
              <el-menu-item
                index="bb-request"
                @click="navigateTo({ name: 'request' })"
              >Blood Request</el-menu-item>
              <el-menu-item
                index="bb-request-hospital"
                class="request-hospital"
                @click="navigateTo({ name: 'requestHospital' })"
              >
                Blood Request<br>
                Between Hospital
              </el-menu-item>
              <el-menu-item
                index="bb-donate"
                @click="navigateTo({ name: 'donation' })"
              >Blood Donate</el-menu-item>
              <el-menu-item
                index="bb-inventory"
                @click="navigateTo({ name: 'inventory' })"
              >Blood Inventory</el-menu-item>
            </el-submenu> -->
            <el-submenu index="redeem-point"
              v-if="currentPlan.hospital_feature.can_use_point_redeem">
              <template slot="title">
                <span>
                  <img src="@/assets/icons/icon-gift.png" style="width: 18px; margin-right: 4px;" />
                  <span style="margin-left: 4px;">Redeem Point</span>
                </span>
              </template>
              <el-menu-item
                index="redeem-list"
                @click="navigateTo({ name: 'redeem-list' })"
              >Redeem List</el-menu-item>
              <el-menu-item
                index="redeem-select-pet"
                @click="navigateTo({ name: 'redeem-select-pet' })"
              >+ Add New Redeem</el-menu-item>
            </el-submenu>
            <el-menu-item index="broadcast" 
              v-if="currentPlan.broadcast_section.can_use_broadcast"
              @click="navigateTo({ name: 'hospitalBroadcast' })">
              <template slot="title">
                <span>
                  <i class="fa fa-broadcast-tower"></i>Broadcast
                </span>
              </template>
            </el-menu-item>
            <el-submenu
              index="reports"
              v-if="hasReportPermission">
              <template slot="title">
                <span>
                  <i class="fa fa-chart-bar"></i>Reports
                </span>
              </template>
              <el-menu-item
              index="report"
              v-if="currentPlan.report_section.can_use_report"
              @click="navigateTo({ name: 'hospitalReport' })"
               >Report</el-menu-item>
              <el-menu-item
                index="report-product-used"
                @click="navigateTo({ name: 'report-product-used' })"
                v-if="currentPlan.report_section.can_use_product_report"
              >Product Use</el-menu-item>
              <el-menu-item
                index="report-activity-used"
                @click="navigateTo({ name: 'report-activity-used' })"
                v-if="currentPlan.report_section.can_use_activity_report"
              >Activity Use</el-menu-item>
              <el-menu-item
                index="report-diagnosis-used"
                @click="navigateTo({ name: 'report-diagnosis-used' })"
                v-if="currentPlan.report_section.can_use_final_diagnosis_report"
              >Diagnosis Use</el-menu-item>
              <el-menu-item
                index="expence-visit-report"
                v-if="currentPlan.report_section.can_use_frequency_expense_report"
                @click="navigateTo({ name: 'expence-visit-report-home' })"
              >Frequency And Expense</el-menu-item>
              <el-menu-item
                index="report-expense-segmentation-pet"
                v-if="currentPlan.income_expense_section.can_view_pet_expense_segmentation"
                @click="navigateTo({ name: 'report-expense-segmentation-pet' })"
              >Pet Segmentation</el-menu-item>
              <el-menu-item
                index="report-expense-segmentation-pet-owner"
                v-if="currentPlan.income_expense_section.can_view_owner_expense_segmentation"
                @click="navigateTo({ name: 'report-expense-segmentation-pet-owner' })"
              >Pet Owner Segmentation</el-menu-item>
              <el-menu-item
                index="report-summary"
                v-if="currentPlan.report_section.can_use_summary_report"
                @click="navigateTo({ name: 'report-summary' })"
              >Report Summary</el-menu-item>
            </el-submenu>
            <el-submenu index="settings">
              <template slot="title">
                <span>
                  <i class="fa fa-wrench"></i>Settings
                </span>
              </template>
              <el-menu-item
                index="hospital-profile"
                @click="navigateTo({ name: 'hospitalProfile' })"
              >Hospital Profile</el-menu-item>
              <el-menu-item
                index="veterianarian"
                v-if="currentPlan.setting_section.can_use_vet_setting"
                @click="navigateTo({ name: 'veterinarians' })"
              >Veterinarian</el-menu-item>
              <el-menu-item
                index="staff"
                v-if="currentPlan.setting_section.can_use_staff_setting"
                @click="navigateTo({ name: 'staff' })"
              >Staff</el-menu-item>
              <el-menu-item
                index="contact"
                @click="navigateTo({ name: 'contact' })"
              >Contact Setting</el-menu-item>
              <el-menu-item
                index="activities-setting"
                v-if="currentPlan.setting_section.can_use_activity_setting"
                @click="navigateTo({ name: 'activities' })"
              >Activities Setting</el-menu-item>
              <el-menu-item
                index="reward-setting"
                v-if="currentPlan.setting_section.can_use_redeem_setting"
                @click="navigateTo({ name: 'rewardSetting' })"
              >Reward Setting</el-menu-item>
              <el-menu-item
                index="product-setting"
                v-if="currentPlan.setting_section.can_use_activity_setting"
                @click="navigateTo({ name: 'productSetting' })"
              >Product Setting</el-menu-item>
              <el-menu-item
                index="hospital-other-setting"
                v-if="currentPlan.setting_section.can_use_vat_setting"
                @click="navigateTo({ name: 'hospitalOtherSetting' })"
              >ตั้งค่าการใช้งาน</el-menu-item>
              <el-menu-item
                index="payment-method-setting"
                v-if="currentPlan.setting_section.can_use_payment_method_setting"
                @click="navigateTo({ name: 'paymentMethodSetting' })"
              >Payment Method Setting</el-menu-item>
            </el-submenu>

            <el-menu-item
              index="logout"
              @click="$emit('logout')"
            >
              <template slot="title">
                <span>
                  <i class="fa fa-power-off"></i>Logout
                </span>
              </template>
            </el-menu-item>
          </template>
          <template v-if="user_type == 'pet_owner'">
            <el-menu-item
              index="logout"
              @click="$emit('logout')"
            >
              <template slot="title">
                <span>
                  <i class="fa fa-power-off"></i>Logout
                </span>
              </template>
            </el-menu-item>
          </template>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    profile: {
      type: Object,
      default: () => ({ name: "" })
    }
  },
  data() {
    return {
      messages: [],
      notificationTotal: 0,
      showSidebar: false,
      openMenus: [] // "appointment", "profile", "settings"
    };
  },
  computed: {
    ...mapState({
      currentPlan: state => state.plan.currentPlan,
    }),
    user_type: function() {
      return localStorage.getItem("user_type");
    },
    hasReportPermission() {
      return this.currentPlan.report_section.can_use_report
            || this.currentPlan.report_section.can_use_product_report
            || this.currentPlan.report_section.can_use_activity_report
            || this.currentPlan.report_section.can_use_final_diagnosis_report
            || this.currentPlan.report_section.can_use_frequency_expense_report
            || this.currentPlan.income_expense_section.can_view_pet_expense_segmentation
            || this.currentPlan.income_expense_section.can_view_owner_expense_segmentation
            || this.currentPlan.report_section.can_use_summary_report
    }
  },
  methods: {
    navigateTo(options) {
      this.$router.push(options);
      this.showSidebar = false;
    },
    gotoiTaam(url) {
      window.open(process.env.VUE_APP_ITAAM_FRONTEND_ENDPOINT + url, '_blank');   
      this.showSidebar = false;
    },
    async fetchNotification() {
      this.notificationTotal = await this.$store.dispatch(
        "broadcast/getInboxCount"
      );
      const msgResponse = await this.$store.dispatch(
        "broadcast/getInboxMessages"
      );
      this.messages = msgResponse.results;
    },
    getHospitalCurrentUser() {
      if (localStorage.getItem("user_type") === "hospital_admin") {
        const name = (this.profile.current_account.first_name) ? 
          this.profile.current_account.first_name : this.profile.current_account.username
        const role = (this.profile.current_account.role) ? this.profile.current_account.role.name : ''

        if (role) return name + '(' + role + ')'
        return name
      }
    }
  },
  mounted() {
    if (localStorage.getItem("user_type") === "hospital_admin") {
      this.fetchNotification();
    }
  }
};
</script>

<style lang="scss" scoped>
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 55px;
  background: #fff;
  border-bottom: 1px solid #ddd;
  z-index: 99;

  .logo {
    height: 40px;
    margin-top: 6px;
    margin-bottom: 14px;
  }

  .mid-col {
    text-align: center;
  }

  .left-col {
    float: left;
    padding-left: 10px;
  }

  .right-col {
    text-align: right;
    line-height: 55px;
    padding-right: 10px;

    @include mobile {
      .notification,
      .avatar {
        display: none;
      }
    }
  }

  .logo {
    position: absolute;
    left: calc(50% - 81.75px);
    margin-top: 10px;
    height: 40px;
  }

  .avatar {
    cursor: pointer;
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ccc;
    vertical-align: middle;
    margin-right: 16px;

    > img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }

  .profile {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
    line-height: 1.4em;

    > i {
      cursor: pointer;
      font-size: 1.2em;
      line-height: 55px;
    }

    .hospital-current-user {
      font-size: 12px;
      line-height: 1em;
      color: #979797;
      display: block;
    }
  }

  .notification {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
    margin-bottom: 12px;
    line-height: 20px;

    > i {
      cursor: pointer;
      margin-top: 20px;
      color: #333;
      font-size: 1.5em;
      line-height: 1em;
    }

    .total {
      position: absolute;
      left: 12px;
      top: 14px;
      width: 16px;
      height: 16px;
      border-radius: 20px;
      background-color: red;
      color: #fff;
      display: inline-block;
      text-align: center;
      font-size: 11px;
      line-height: 16px;
    }
  }

  .toggle-button {
    cursor: pointer;
    display: inline-block;
    width: 55px;
    height: 55px;
    text-align: center;

    > .icon {
      display: inline-block;
      margin-top: 18px;
      width: 20px;
      height: 20px;
      background: url("../../assets/icons/icon-toggle-button.png") no-repeat;
      background-size: contain;
    }

    &:hover,
    &.active {
      background: #156f88;

      > .icon {
        background-image: url("../../assets/icons/icon-toggle-button-active.png");
      }
    }
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }
}

.message-container {
  margin: -10px -10px 0;
  height: 300px;
  overflow-y: scroll;

  .title {
    margin: 0;
    padding: 0 0 5px 0;
    font-size: 0.9em;
    text-align: center;
    border-bottom: 1px #ccc solid;
  }

  .message {
    cursor: pointer;
    font-size: 0.8em;
    padding: 10px 0 0 10px;
    border-bottom: 1px solid #ccc;

    &.is_seen {
      background: #f5f8fa;
    }

    .thumbnail {
      padding: 0 2px;
      img {
        width: 100%;
      }
    }

    .content {
      padding: 0 5px;
    }

    .footer {
      padding: 5px;
    }
  }
}
.sidebar .el-submenu .el-menu-item.request-hospital {
  height: 60px;
  line-height: 20px;
  padding-top: 10px;
}
</style>
