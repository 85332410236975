<template>
  <div class="pet-search-result">
    <div class="header">
      <span class="total">{{ requestCount | number }}</span> RESULT
    </div>
    <div class="content">
        
          <BloodRequestSearchItem class='request-item' v-for="req in requests.slice((currentPage-1)*10,currentPage*10)" :key="req.id" :request='req'></BloodRequestSearchItem>

      <div class="not-found" v-if="requests && requests.length === 0">ไม่พบข้อมูลที่ต้องการค้นหา</div>
    </div>
    <div class="footer" v-if="requests">
      <el-pagination
        background
        @current-change="(page) => $emit('current-change', page)"
        :current-page="currentPage"
        :page-size="10"
        layout="prev, pager, next, jumper"
        :total="requestCount"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import PetGenderIcon from "@/components/pet/PetGenderIcon"
import PetTypeIcon from "@/components/pet/PetTypeIcon"
import BloodRequestSearchItem from "@/components/bloodbank/blood-request/BloodRequestSearchItem"


export default {
  components: {
    PetGenderIcon,
    PetTypeIcon,
    BloodRequestSearchItem
  },
  props: ["requests", "currentPage"],
  data() {
    return {

    }
  },
  computed: {
    
    requestCount() {
      if (this.requests && this.requests.length) {
        return this.requests.length || 0
      }
      return 0
    }
  },
  methods: {
    
  }
}
</script>

<style lang="scss" scoped>
.pet-search-result {
  .header {
    margin-bottom: 5px;
  }
  .content {
    display: flex;
    flex-wrap: wrap;
  }

  .footer {
    padding: 20px;
    text-align: center;
  }
}

.request-item {
  width: 100%;
}

.total {
  color: $light-blue;
  font-weight: bold;
}

.not-found {
  display: flex;
  flex: 1;
  padding: 20px 0 0 0;
  text-align: center;
  justify-content: center;
}

.pagination {
  margin-top: 20px;
  text-align: right;
}
</style>
