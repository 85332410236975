<template>
  <el-dialog :visible="visible" width="500px" center @close="$emit('cancel')">
    <div class="dialog-content">
      <h1 class="title">{{ form.title === 'Remove' ? '- Remove' : '+ Add' }} from Inventory</h1>
      <div class="product-name">{{ product.trade_name }}</div>
      <div class="amount-content">
        <el-input-number class="input-value"
          placeholder="ปริมาณ"
          v-model="form.value"
          :min='0.01'
          :max='9999'
          :precision="2" 
          :step="0.01"
          :controls="false">
        </el-input-number>
        <div class="amount-remain">
          <div>จาก</div>
          <div>{{ remain }}</div>
          <div>{{ product.unit && product.unit.name }}</div>
        </div>
      </div>
      <div>สาเหตุ</div>
      <div v-if="form.title === 'Add'" class="radio-block">
        <el-radio class="radio-reason" :label="1" v-model="form.reason">เพิ่มสต็อก</el-radio>
        <el-radio class="radio-reason" :label="2" v-model="form.reason">ได้รับจากภายนอกมา</el-radio>
        <el-radio class="radio-reason" :label="3" v-model="form.reason">โอนย้ายมาจากสาขาอื่น</el-radio>
        <el-radio class="radio-reason" :label="4" v-model="form.reason">ปรับจำนวนคงเหลือ</el-radio>
        <el-radio class="radio-reason" :label="5" v-model="form.reason">
          <div v-if="form.reason !== 5">อื่นๆ</div>
          <el-input v-else size="small" type="text" v-model="form.note"></el-input>
        </el-radio>
      </div>
      <div v-if="form.title === 'Remove'" class="radio-block">
        <el-radio class="radio-reason" :label="1" v-model="form.reason">หมดอายุ</el-radio>
        <el-radio class="radio-reason" :label="2" v-model="form.reason">สูญหาย</el-radio>
        <el-radio class="radio-reason" :label="3" v-model="form.reason">ชำรุดเสียหาย ใช้งานไม่ได้</el-radio>
        <el-radio class="radio-reason" :label="4" v-model="form.reason">ปรับจำนวนคงเหลือ</el-radio>
        <el-radio class="radio-reason" :label="5" v-model="form.reason">
          <div v-if="form.reason !== 5">อื่นๆ</div>
          <el-input v-else size="small" type="text" v-model="form.note"></el-input>
        </el-radio>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('cancel')">Cancel</el-button>
      <el-button type="primary" @click="$emit('save')">Save</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: ["visible", "form", "stockId", "product", "remain"],
};
</script>

<style lang="scss" scoped>
.dialog-content {
  font-family: Thonburi;
  font-size: 16px;
  margin: -30px;
  .title {
    margin: 0 0 20px 0;
    padding: 0;
    font-size: 18px;
    text-align: center;
    color: #000;
  }
  .product-name {
    font-size: 16px;
    font-weight: bold;
    color: #02b0e1;
  }
  .amount-content {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: #000;
  }
  .amount-remain {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .input-value {
    width: 40%;
    margin: 10px 20px 10px 0;
  }
  .row {
    margin: 0 10px;
  }
  .radio-block {
    margin-bottom: 20px;
    font-family: Thonburi;
    font-size: 14px;
    border-radius: 4px;
    border: solid 1px #979797;
    display: flex;
    flex-direction: column;
  }
  .radio-reason {
    display: flex;
    align-items: center;
    margin: 10px;
  }
}
</style>

