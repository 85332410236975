export default (value) => {
  switch (value) {
    case "overdue":
      return {
        label: "Overdue",
        color: "#ff8800",
      };

    case "active":
      return {
        label: "Active",
        color: "#8fbe00",
      };

    case "finish":
      return {
        label: "Finish",
        color: "#324a5e",
      };

      case "deleted":
        return {
          label: "Deleted",
          color: "#f56c6c",
        };

    default:
      return null;
  }
};
