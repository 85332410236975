<template>
  <div class="pet-icon">
    <img
      v-if="gender === 0"
      src="@/assets/icons/icon-female-sign.png"
      class="icon"
      :style="{ width: iconWidth }"
    >
    <img
      v-if="gender === 1"
      src="@/assets/icons/icon-male-sign.png"
      class="icon"
      :style="{ width: iconWidth }"
    >
  </div>
</template>

<script>
export default {
  props: ["gender", "size"],
  computed: {
    iconWidth() {
      let width = this.size || 18
      return width + "px"
    }
  }
}
</script>

<style lang="scss" scoped>
.pet-icon {
  margin: 0 3px;
  display: inline-block;
  vertical-align: middle;

  .icon {
    opacity: 0.6;
  }
}
</style>


