<template>
    <div>
        <div class="title">{{ title }}</div>
        <div class="subtitle">{{ subtitle }}</div>
        <HorizontalBar 
            :chartData="chartData"
            :options="options"
            :responsive="true"
            :styles="customStyles"/>
    </div>
</template>

<script>
import HorizontalBar from './chart/HorizontalBar'

export default {
    components: {
        HorizontalBar
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        subtitle: {
            type: String,
            default: ''
        },
        chartData: {
            type: Object,
            default: null
        },
        options: {
            type: Object,
            default: null
        },
        customStyles: {
            type: Object,
            default: null
        }
    },
    mounted () {
    }
}
</script>

<style lang="scss" scoped>
.title {
    font-size: 18px;
    color: #000;
    font-weight: normal;
    font-family: "Roboto","Lucida Grande","DejaVu Sans","Bitstream Vera Sans",Verdana,Arial,sans-serif;
}
.subtitle {
    font-size: 14px;
    color: #4a4a4a;
    font-weight: normal;
    font-family: "Roboto","Lucida Grande","DejaVu Sans","Bitstream Vera Sans",Verdana,Arial,sans-serif;
}
</style>