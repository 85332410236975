<template>
  <div class="content">
    <div class="custom-row">
      <el-row style="margin-bottom: 10px;">
        <el-col :span="9">
          <label class="label">
            <img src="@/assets/icons/icon-clock.png" />วันที่นัด
            <span class="required-mark">*</span>
          </label>
        </el-col>
        <el-col :span="15">
          <el-date-picker
            type="date"
            placeholder="Pick a day"
            size="small"
            v-model="form.apptDate"
            :picker-options="pickerDateOptions"
            @change="handleChange"
          ></el-date-picker>
        </el-col>
      </el-row>
      <el-row style="margin-bottom: 10px;">
        <el-col :span="15" :offset="9">
          <el-time-select
            placeholder="Select time"
            size="small"
            :picker-options="{
              start: '00:00',
              step: '00:15',
              end: '24:45'
            }"
            :disabled="form.isNoTime"
            v-model="form.apptTime"
            format="HH:mm"
            @change="handleChange"
          ></el-time-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="15" :offset="9">
          <el-checkbox
            v-model="form.isNoTime"
            style="width: 100%;"
            size="small"
            label="ไม่ระบุเวลา"
            @change="handleChange"
            border
          ></el-checkbox>
        </el-col>
      </el-row>
    </div>
    <div class="custom-row" style="margin-bottom: 0;">
      <h3 class="label label-activity">
        <img src="@/assets/icons/icon-vaccine.png" />
        กิจกรรม ({{ activities.length }})
        <span class="required-mark">*</span>
      </h3>
    </div>
    <div class="custom-row">
      <div class="activitie-list">
        <transition-group name="fade" tag="div">
          <div class="activity-item" v-for="activity in activities" :key="activity.id">
            <h4 class="title">{{ activity.name }}</h4>
            <template v-if="activity.products && activity.products.length > 0">
              <div class="subtitle">{{ activity.products[0].trade_name || '-' }}</div>
            </template>
            <template v-else>
              <div class="subtitle">-</div>
            </template>
            <div class="price">{{ activity.price || 0}}฿</div>
            <div class="delete-btn" @click="$emit('delete', activity)">
              delete
              <i class="el-icon-close"></i>
            </div>
          </div>
        </transition-group>
        <div class="total">
          <el-row>
            <el-col :span="6">
              <label>Total</label>
            </el-col>
            <el-col class="sum" :span="18">{{ total }} ฿</el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="custom-row">
      <h3 class="label">
        <img src="@/assets/icons/icon-doctor.png" />สัตวแพทย์
      </h3>
      <el-select
        v-model="form.selectedVetId"
        placeholder="Select"
        size="small"
        @change="handleChange"
      >
        <el-option :key="-1" :value="-1" label="ไม่ระบุ"></el-option>
        <el-option
          v-for="vet in vets"
          :key="vet.id"
          :value="vet.id"
          :label="`${vet.first_name} ${vet.last_name}`"
        ></el-option>
      </el-select>
    </div>
    <div class="custom-row">
      <h3 class="label">
        <img src="@/assets/icons/icon-notepad.png" />บันทึกเพิ่มเติม
      </h3>
      <el-input
        v-model="form.note"
        type="textarea"
        :autosize="{ minRows: 2, maxRows: 4}"
        placeholder="Please input"
        @change="handleChange"
        size="small"
      ></el-input>
    </div>
    <div class="custom-row">
      <AppointmentReminder
        :owners="owners"
        :selectedOwnerId="selectedOwnerId"
        @change="handleChangeReminder"
      />
    </div>
    <div class="custom-row clearfix">
      <p class="remark">*สามารถอัพโหลดได้เฉพาะรูปภาพและไฟล์เอกสาร pdf เท่านั้น</p>

      <FileUploader
        style="float: left; width: 110px; height: 110px; margin: 4px;"
        :isUploading="isUploading"
        @onSelect="handleSelectFiles"
      />
      <FilePreviewer
        v-for="file in uploadedFiles"
        :key="file.id"
        style="float: left; width: 110px; height: 110px; margin: 4px;"
        :file="file"
        :showDeleteButton="true"
        @delete="handleDeleteUploadFile"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { combineDateTime, roundToQuarterHour } from "@/utils/datetime";
import FileUploader from "@/components/upload/FileUploader";
import FilePreviewer from "@/components/upload/FilePreviewer";
import AppointmentReminder from "@/components/appointment/AppointmentReminder";

export default {
  components: {
    FileUploader,
    FilePreviewer,
    AppointmentReminder
  },
  props: {
    activities: {
      type: Array
    },
    owners: {
      type: Array
    },
    vets: {
      type: Array
    },
    date: {
      type: Date
    },
    selectedVetId: {
      type: Number
    },
    selectedOwnerId: {
      type: String
    },
    note: {
      type: String
    }
  },
  data() {
    return {
      form: {
        apptDate: this.date,
        apptTime: roundToQuarterHour(this.date).format("HH:mm"),
        isNoTime: false,
        selectedVetId: this.selectedVetId,
        note: this.note,
        reminderForm: {}
      },
      pickerDateOptions: {
        disabledDate(time) {
          const date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24);
          return time.getTime() < date;
        }
      },
      isUploading: false,
      uploadedFiles: []
    };
  },
  computed: {
    total() {
      return this.activities.reduce((total, item) => {
        if (item.price) {
          return total + parseFloat(item.price);
        }
        return total;
      }, 0);
    }
  },
  methods: {
    handleChangeReminder(form) {
      this.reminderForm = form;
      this.handleChange();
    },
    handleChange() {
      let data = {
        vetId: this.form.selectedVetId,
        note: this.form.note,
        isNoTime: this.form.isNoTime,
        ...this.reminderForm
      };

      data["attachments"] = this.uploadedFiles.map(item => {
        return {
          attachment: item.id
        };
      });

      const timeToDate = moment(this.form.apptTime, "HH:mm").toDate();
      const dt = combineDateTime(this.form.apptDate, timeToDate);
      if (moment.isDate(dt)) {
        data["date"] = dt;
      } else {
        data["date"] = null;
      }

      this.$emit("change", data);
    },
    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
      }
    },
    async handleSelectFiles(files) {
      try {
        this.isUploading = true;
        for (let index = 0; index < files.length; index++) {
          await this.uploadFile(files[index]);
        }
        this.isUploading = false;
        this.$notify({
          message: `อัพโหลดไฟล์เรียบร้อยแล้ว`,
          type: "success"
        });
        this.handleChange();
      } catch (error) {
        this.isUploading = false;
      }
    },
    async uploadFile(file) {
      try {
        let formData = new FormData();
        formData.append("attachment", file);
        const response = await this.$store.dispatch("appointment/upload", {
          formData
        });
        this.uploadedFiles = [response, ...this.uploadedFiles];
      } catch (error) {
        this.$notify({
          message: "ไม่สามารถอัพโหลดไฟล์ " + file.name,
          type: "error"
        });
      }
    },
    handleDeleteUploadFile(file) {
      this.uploadedFiles = this.uploadedFiles.filter(
        item => item.id !== file.id
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  margin: -10px;
}
.custom-row {
  margin-bottom: 10px;
}
.el-select,
.el-input {
  width: 100%;
}

.label {
  margin: 0;
  padding: 5px 5px 5px 0;
  color: $light-blue;
  font-size: 14px;
  font-weight: bold;

  &.label-activity {
    padding: 5px 0 0 0;
  }

  > img {
    margin-right: 5px;
    height: 16px;
    vertical-align: middle;
  }
}

.apppointment-datetime {
  margin-bottom: 10px;

  .el-col {
    margin-bottom: 5px;
  }
}

.activitie-list {
  .activity-item {
    position: relative;
    padding: 5px 0;
    border-bottom: 1px solid #ccc;

    .title {
      padding: 0;
      margin: 5px 0 0 0;
      font-size: 14px;
    }
    .subtitle {
      padding: 0;
      margin: 0 0 5px 0;
      color: $gray;
      font-weight: normal;
    }

    .delete-btn {
      cursor: pointer;
      position: absolute;
      right: 0;
      bottom: 10px;
      color: $gray;

      &:hover {
        color: $red;
      }
    }
  }
}
.total {
  padding: 10px 0;
  .sum {
    text-align: right;
  }
}

.remark {
  padding: 0;
  margin-top: 5px;
  font-size: 0.9em;
  color: #333;
}
</style>

