<template>
<div class='container' style="width:100%;height: 45px;background-color:blueviolet;">

  <div class="row">

      <div class="col-9 row">
          <div class="flex" style="width: 100%;">
              <div class="flex-end" style="width: 100%; padding-right: 10px;">
                <b>{{label}} <span v-if="isRequired" style="color: red">*</span></b>
              </div>
          </div>
      </div>

      <div class="col-md-15 row" style="padding-top: 1px;">
          <el-radio-group v-model="vModel">
              <el-radio :disabled="disabled" v-for="(option, idx) in options" v-bind:key="idx" v-bind:label="option.value" :style="idx === 0 ? { } : { 'padding-left': spaceSize}">{{option.label}}</el-radio>
          </el-radio-group>
      </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'label-radio-group-pair',
  props: ['label', 'options', 'model', 'propName', 'spaceSize', 'isRequired', 'disabled'],
  data () {
    return {
      vModel: this.model
    }
  },
  watch: {
    vModel: function (val) {
      this.$emit('updated', { prop: this.propName, value: val })
    }
  }
}
</script>

<style lang='scss' scoped>
.container {
  font-size: 12px;
}
label.el-radio {
  margin-right: 5px;
}
</style>
