import MedicalRecordBloodRequest from '../../common/medical-record/medical-record-blood-request'
import LabExam from '../../common/lab-exam/lab-exam'
import ProductRequest from '../product-request-info/product-request-info'

export default class {
  constructor (obj) {
    if (obj != null) {
      this.id = obj.id == null ? null : obj.id
      this.bloodRequestId = obj.blood_request == null ? null : obj.blood_request
      this.isRecipientProfileSubmitted = obj.is_recipient_profile_submitted == null ? false : obj.is_recipient_profile_submitted
      this.isLabExamSubmitted = obj.is_lab_exam_submitted == null ? false : obj.is_lab_exam_submitted
      this.isProductRequestSubmitted = obj.is_product_request_submitted == null ? false : obj.is_product_request_submitted
      this.medicalRecord = obj.medicalRecord == null ? new MedicalRecordBloodRequest() : obj.medicalRecord
      this.labExam = obj.labExam == null ? new LabExam() : obj.labExam
      this.productRequest = obj.productRequest == null ? new ProductRequest() : obj.productRequest
    } else {
      this.id = null
      this.bloodRequestId = null
      this.isRecipientProfileSubmitted = false
      this.isLabExamSubmitted = false
      this.isProductRequestSubmitted = false
      this.medicalRecord = new MedicalRecordBloodRequest()
      this.labExam = new LabExam()
      this.productRequest = new ProductRequest()
    }
  }

  toRequestJson () {
    return {
      id: this.id,
      blood_request: this.bloodRequestId,
      is_recipient_profile_submitted: this.isRecipientProfileSubmitted,
      is_lab_exam_submitted: this.isLabExamSubmitted,
      is_product_request_submitted: this.isProductRequestSubmitted,
      medicalRecord: this.medicalRecord.toRequestJson(),
      labExam: this.labExam.toRequestJson(),
      productRequest: this.productRequest.toRequestJson()
    }
  }

  cloneFrom(obj) {
    this.id = obj.id
    this.bloodRequestId = obj.bloodRequestId
    this.isRecipientProfileSubmitted = obj.isRecipientProfileSubmitted
    this.isLabExamSubmitted = obj.isLabExamSubmitted
    this.isProductRequestSubmitted = obj.isProductRequestSubmitted
    this.medicalRecord = new MedicalRecordBloodRequest(obj.medicalRecord.toRequestJson())
    this.labExam = new LabExam(obj.labExam.toRequestJson())
    this.productRequest = new ProductRequest(obj.productRequest.toRequestJson())
  }
}
