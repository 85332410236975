let api;

if (!api) {
  import("./apis").then((apis) => {
    api = apis.coreAPI;
  });
}

/**
 * Get all transaction types
 */
const getTransactionTypes = () =>
  api.get("/stock/transaction/mixedtype-choices/");

/**
 * Get all transactions
 * @param {Object} params
 * @param {(Date|undefined)} params.start
 * @param {(Date|undefined)} params.end
 * @param {(string|undefined)} params.search
 * @param {(string|undefined)} params.action_type
 * @param {(number|undefined)} params.ref_content_type
 * @param {(number|undefined)} params.page
 */
const getTransactions = (params) => api.get("/stock/transaction/", { params });

export default {
  getTransactionTypes,
  getTransactions,
};
