<template>
  <div class="donation-step-1-exam" style='width: 100%'>

    <el-row>
      <slot class='headSlot' name="header" :currentProgress='this.myProgress' :currentText='this.myText'></slot>
    </el-row>

    <el-row>
      <el-row class="medical-record-card">
        <el-col>
          <img src="@/assets/bloodbank/file.png" height="20"/>
          <span>  Medical Record</span>
        </el-col>
      </el-row>
      <!-- Content -->
      <el-row>
        <el-card style="padding-left: 5px; padding-right: 5px;">
          <el-row :gutter='5'>
            <!-- Physical Exam -->
            <el-col :span='12' class='left pane'>
              <el-card style="width: 100%;">
                <h4>Physical Exam</h4>

                <el-row :gutter='5'>
                  <el-col :span='8'>
                    <top-label-input :disabled="donation.isCaseClosed()" :isRequired="true" v-validate="'required|decimal'" data-vv-value-path="vModel" data-vv-name="weight" :isValid="!errors.has('weight')" @updated="onTopLabelInputUpdated" prop-name="weight" label="Weight (kg.)" :model="donation.medicalRecord.weight"></top-label-input>
                  </el-col>
                  <el-col :span='8'>
                    <top-label-input :disabled="donation.isCaseClosed()" v-validate="'decimal'" data-vv-value-path="vModel" data-vv-name="temp" :isValid="!errors.has('temp')" @updated="onTopLabelInputUpdated" prop-name="temp" label="Temp (°C)" :model="donation.medicalRecord.temp"></top-label-input>
                  </el-col>
                  <el-col :span='8'>
                    <top-label-input :disabled="donation.isCaseClosed()" v-validate="'decimal'" data-vv-value-path="vModel" data-vv-name="hr" :isValid="!errors.has('hr')" @updated="onTopLabelInputUpdated" prop-name="hr"  label="HR (bmp)" :model="donation.medicalRecord.hr"></top-label-input>
                  </el-col>
                </el-row>

                <el-row :gutter='5'>
                  <el-col :span='8'>
                    <top-label-input :disabled="donation.isCaseClosed()" v-validate="'decimal'" data-vv-value-path="vModel" data-vv-name="rr" :isValid="!errors.has('rr')" @updated="onTopLabelInputUpdated" prop-name="rr" label="RR (tpm)" :model="donation.medicalRecord.rr"></top-label-input>
                  </el-col>
                  <el-col :span='8'>
                    <top-label-input :disabled="donation.isCaseClosed()" v-validate="'decimal'" data-vv-value-path="vModel" data-vv-name="crt" :isValid="!errors.has('crt')" @updated="onTopLabelInputUpdated" prop-name="crt" label="CRT (Sec)" :model="donation.medicalRecord.crt"></top-label-input>
                  </el-col>
                  <el-col :span='8'>
                    <top-label-input :disabled="donation.isCaseClosed()" data-vv-value-path="vModel" data-vv-name="bp" :isValid="!errors.has('bp')" @updated="onTopLabelInputUpdated" prop-name="bp" label="BP (mmHg)" :model="donation.medicalRecord.bp"></top-label-input>
                  </el-col>
                </el-row>

                <el-row style="padding: 5px;">
                  <el-col :span='8' style="padding-bottom: 5px;">
                    <label>Mucouse Membrane</label>
                  </el-col>

                  <el-col :span='16' style="padding: 5px;">
                    <el-row>
                      <el-radio-group v-model="donation.medicalRecord.mucouseMembrane" :disabled="donation.isCaseClosed()" size="small" circle gap='5'>
                        <el-radio-button :label='1' class="mucouse-btn" style="background: #fde6d2;"></el-radio-button>
                        <el-radio-button :label='2' class="mucouse-btn" style="background: #f8c4c4;"></el-radio-button>
                        <el-radio-button :label='3' class="mucouse-btn" style="background: #f16d5f;"></el-radio-button>
                        <el-radio-button :label='4' class="mucouse-btn" style="background: #c3423e;"></el-radio-button>
                        <el-radio-button :label='5' class="mucouse-btn" style="background: #7a4f66;"></el-radio-button>
                        <el-radio-button :label='6' class="mucouse-btn" style="background: #57302b;"></el-radio-button>
                        <el-radio-button :label='7' class="mucouse-btn" style="background: #2a2726;"></el-radio-button>
                      </el-radio-group>
                    </el-row>

                    <el-row class='mucouse-mini-labels'>
                      <el-col>Pale</el-col>
                      <el-col>Normal</el-col>
                      <el-col>Pink</el-col>
                      <el-col>Red</el-col>
                      <el-col>Purple</el-col>
                      <el-col>Brown</el-col>
                      <el-col>Black</el-col>
                    </el-row>
                  </el-col>
                </el-row>

                <el-row style="padding: 5px;">
                  <el-col :span='3' style="padding-top: 10px;">
                    <label>BSC Level</label>
                  </el-col>

                  <el-col :span='4' style="padding-top: 10px;">
                    <div v-if="donation.pet.type.toLowerCase() === 'dog'">
                      <img src="@/assets/bloodbank/dog-bsc-too-thin-1.png" v-if="donation.medicalRecord.bscLevel.toString() === '1' || donation.medicalRecord.bscLevel.toString() === '2'">
                      <img src="@/assets/bloodbank/dog-bsc-too-thin-2.png" v-if="donation.medicalRecord.bscLevel.toString() === '3' || donation.medicalRecord.bscLevel.toString() === '4'">
                      <img src="@/assets/bloodbank/dog-bsc-ideal.png" v-if="donation.medicalRecord.bscLevel.toString() === '5'">
                      <img src="@/assets/bloodbank/dog-bsc-overweight.png" v-if="donation.medicalRecord.bscLevel.toString() === '6' || donation.medicalRecord.bscLevel.toString() === '7'">
                      <img src="@/assets/bloodbank/dog-bsc-obese.png" v-if="donation.medicalRecord.bscLevel.toString() === '8' || donation.medicalRecord.bscLevel.toString() === '9'">
                    </div>

                    <div v-if="donation.pet.type.toLowerCase() === 'cat'">
                      <img src="@/assets/bloodbank/cat-bsc-too-thin-1.png" v-if="donation.medicalRecord.bscLevel.toString() === '1' || donation.medicalRecord.bscLevel.toString() === '2'">
                      <img src="@/assets/bloodbank/cat-bsc-too-thin-2.png" v-if="donation.medicalRecord.bscLevel.toString() === '3' || donation.medicalRecord.bscLevel.toString() === '4'">
                      <img src="@/assets/bloodbank/cat-bsc-ideal.png" v-if="donation.medicalRecord.bscLevel.toString() === '5'">
                      <img src="@/assets/bloodbank/cat-bsc-overweight.png" v-if="donation.medicalRecord.bscLevel.toString() === '6' || donation.medicalRecord.bscLevel.toString() === '7'">
                      <img src="@/assets/bloodbank/cat-bsc-obese.png" v-if="donation.medicalRecord.bscLevel.toString() === '8' || donation.medicalRecord.bscLevel.toString() === '9'">
                    </div>
                  </el-col>

                  <el-col :span='17' style="padding: 5px;">
                    <el-row style="height: 35px;">
                      <el-radio-group v-model="donation.medicalRecord.bscLevel" :disabled="donation.isCaseClosed()" size="mini">
                        <el-radio-button :label='1' class="bsc-btn"></el-radio-button>
                        <el-radio-button :label='2' class="bsc-btn"></el-radio-button>
                        <el-radio-button :label='3' class="bsc-btn"></el-radio-button>
                        <el-radio-button :label='4' class="bsc-btn"></el-radio-button>
                        <el-radio-button :label='5' class="bsc-btn"></el-radio-button>
                        <el-radio-button :label='6' class="bsc-btn"></el-radio-button>
                        <el-radio-button :label='7' class="bsc-btn"></el-radio-button>
                        <el-radio-button :label='8' class="bsc-btn"></el-radio-button>
                        <el-radio-button :label='9' class="bsc-btn"></el-radio-button>
                      </el-radio-group>
                    </el-row>

                    <el-row style="padding-bottom: 30px;">
                      <!-- div width is from width of each button multiply by number of coverage block -->
                      <div  style="display: inline-block; width: 110px;">
                        <label style="font-size: 10px;">Too Thin</label>
                      </div>
                      <div style="display: inline-block; width: 50px;">
                        <label style="font-size: 10px;">Ideal</label>
                      </div>
                      <div style="display: inline-block; width: 95px;">
                        <label style="font-size: 10px;">Overweight</label>
                      </div>
                      <div style="display: inline-block; width: 10px;">
                        <label style="font-size: 10px;">Obese</label>
                      </div>
                    </el-row>
                  </el-col>
                </el-row>

                <symptom-table :disabled="donation.isCaseClosed()" :symptoms="donation.medicalRecord.symptoms"></symptom-table>

              </el-card>

            </el-col>
            <!-- History -->
            <el-col :span='12' class='right pane'>
              <div class="row">
                <el-card style="width: 100%;">
                  <h4>History Taking</h4>

                  <div class="row">
                    <div class="col-md-8 flex-start">
                      <label>ประวัติการบริจาคเลือด</label>
                    </div>
                    <div class="col-md-16 flex-start">
                      <div class="row">
                        <div class="col-md-8 flex-start">
                          <label>ครั้งที่</label>
                        </div>
                        <div class="col-md-16 flex-start">
                        <el-input :disabled="donation.isCaseClosed()" v-model="donation.medicalRecord.numberOfDonation"></el-input>
                        </div>
                      </div>
                  </div>
                  </div>

                  <div class="row" style=" margin-top: 20px">
                    <div class="col-md-offset-8 col-md-16 flex-start">
                      <div class="row">
                        <div class="col-md-8 flex-start">
                          <label>ล่าสุดเมื่อ</label>
                        </div>
                        <div class="col-md-16 flex-start">
                          <el-date-picker :disabled="donation.isCaseClosed()" v-model="donation.medicalRecord.lastDonation" type="date" placeholder="Select date"></el-date-picker>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" style="margin-top:20px">
                    <div class="col-md-8 flex-start">
                      <label class="error-field-label"> ประวัติการทำวัคซีน <span>*</span> </label>
                    </div>

                    <div class="col-md-16 flex-start">
                      <el-radio :disabled="donation.isCaseClosed()" v-validate="'required'" v-model="donation.medicalRecord.isFullVaccineApplied" :label="true">ครบโปรแกรม</el-radio>
                      <el-radio :disabled="donation.isCaseClosed()" v-validate="'required'" v-model="donation.medicalRecord.isFullVaccineApplied" :label="false">ไม่ครบโปรแกรม</el-radio>
                    </div>
                  </div>

                  <div class="row"  style="margin-top:20px">
                    <div class="col-md-offset-8 col-md-16 flex-start">
                      <div class="row">
                        <div class="col-md-8 flex-start">
                          <label>ล่าสุดเมื่อ</label>
                        </div>
                        <div class="col-md-16 flex-start">
                          <el-date-picker :disabled="donation.isCaseClosed()" v-model="donation.medicalRecord.lastVaccine" type="date" placeholder="Select date"></el-date-picker>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" style="margin-top:20px">
                    <div class="col-md-8 flex-start">
                      <label class="error-field-label"> ประวัติการป้องกัน<br>เห็บหมัด <span>*</span> </label>
                    </div>

                    <div class="col-md-16 flex-start">
                      <el-radio :disabled="donation.isCaseClosed()" v-validate="'required'"  v-model="donation.medicalRecord.bugVaccineHistory" :label="vaccineRegular.MONTHLY">ประจำทุกเดือน</el-radio>
                      <el-radio :disabled="donation.isCaseClosed()" v-validate="'required'"  v-model="donation.medicalRecord.bugVaccineHistory" :label="vaccineRegular.SELDOM">ทำบ้าง</el-radio>
                      <el-radio :disabled="donation.isCaseClosed()" v-validate="'required'"  @input="donation.medicalRecord.lastBugResistance = donation.medicalRecord.bugVaccineHistory === vaccineRegular.NEVER ? '' : donation.medicalRecord.lastBugResistance" v-model="donation.medicalRecord.bugVaccineHistory" :label="vaccineRegular.NEVER">ไม่เคยทำ</el-radio>
                    </div>
                  </div>

                  <div class="row"  style="margin-top:20px">
                    <div class="col-md-offset-8 col-md-16 flex-start">
                      <div class="row">
                        <div class="col-md-8 flex-start">
                          <label>ล่าสุดเมื่อ</label>
                        </div>
                        <div class="col-md-16 flex-start">
                          <el-date-picker :disabled="donation.isCaseClosed()" @change="donation.medicalRecord.bugVaccineHistory = donation.medicalRecord.bugVaccineHistory === vaccineRegular.MONTHLY ? vaccineRegular.MONTHLY : vaccineRegular.SELDOM" v-model="donation.medicalRecord.lastBugResistance" type="date" placeholder="Select date"></el-date-picker>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" style="margin-top:20px">
                    <div class="col-md-8 flex-start">
                      <label>มีปัญหาโรคผิวหนังหรือไม่?</label>
                    </div>

                    <div class="col-md-16 flex-start">
                      <el-radio :disabled="donation.isCaseClosed()" v-model="donation.medicalRecord.hasSkinSymptom" :label="false">ไม่มี</el-radio>
                      <el-radio :disabled="donation.isCaseClosed()" v-model="donation.medicalRecord.hasSkinSymptom" :label="true">มี</el-radio>
                    </div>
                  </div>

                  <div class="row" style="margin-top:20px">
                    <div class="col-md-8 flex-start">
                      <label>ภายใน 1 เดือนที่ผ่านมามีการใช้ยาหรือไม่?</label>
                    </div>

                    <div class="col-md-16 flex-start">
                      <el-radio :disabled="donation.isCaseClosed()" v-model="donation.medicalRecord.hasPillTakenInOneMonth" :label="false">ไม่มี</el-radio>
                      <el-radio :disabled="donation.isCaseClosed()" v-model="donation.medicalRecord.hasPillTakenInOneMonth" :label="true">มี</el-radio>
                    </div>
                  </div>

                  <div class="row flex"  style="margin-top:20px">
                    <div class="col-md-8 flex-start">
                      <label>เคยได้รับเลือดมาก่อน</label>
                    </div>

                    <div class="col-md-16 flex-start">
                      <el-radio :disabled="donation.isCaseClosed()" v-model="donation.medicalRecord.usedToBloodTaken" :label="false">ไม่มี</el-radio>
                      <el-radio :disabled="donation.isCaseClosed()" v-model="donation.medicalRecord.usedToBloodTaken" :label="true">มี</el-radio>
                    </div>
                  </div>

                  <div class="row"  style="margin-top:20px">
                    <div class="col-md-8 flex-start">
                      <label>เคยผ่าตัดมาก่อน?</label>
                    </div>

                    <div class="col-md-16 flex-start">
                      <el-radio :disabled="donation.isCaseClosed()" v-model="donation.medicalRecord.usedToSurgery" :label="false">ไม่มี</el-radio>
                      <el-radio :disabled="donation.isCaseClosed()" v-model="donation.medicalRecord.usedToSurgery" :label="true">มี</el-radio>
                    </div>
                  </div>

                </el-card>
              </div>

              <div class="row" style="margin-top: 15px;">
                <el-card style="width: 100%;">
                  <h4 class="error-field-label">สรุปผลการตรวจ <span>*</span></h4>
                  <div clas="row">
                    <div class="col-md-24 col-sm-24 col-xs-24">
                      <confirmation-button :disabled="donation.isCaseClosed()" v-validate="'required'" data-vv-value-path="isActive" data-vv-name="testResult" :isActive="donation.medicalRecord.isPassed" v-model="donation.medicalRecord.isPassed"></confirmation-button>
                    </div>
                  </div>

                  <div class="row" style="margin-top: 10px;">
                    <div class="col-md-24 flex-start flex-middle">
                      <div class="col-md-2">
                        <label>หมายเหตุ</label>
                      </div>
                      <div class="col-md-22">
                        <el-input :readonly="donation.isCaseClosed()" type="textarea" :autosize="{ minRows: 3, maxRows: 3}" placeholder="บันทึกเพิ่มเติม ..." v-model="donation.medicalRecord.note"></el-input>
                      </div>
                    </div>
                  </div>
                </el-card>

              </div>
            </el-col>
          </el-row>
        </el-card>
      </el-row>
    </el-row>

  </div>
</template>

<script>
import TopLabelInputCtrl from '@/components/bloodbank/controls/top-label-input/TopLabelInput'
import SymptomTable from '@/components/bloodbank/controls/symptom-table/SymptomTable'
import VaccineRegular from '@/constants/bloodbank/vaccine-regular'
import ConfirmationButton from '@/components/bloodbank/controls/confirmation-button/ConfirmMationButton'
// import BloodDonationInfo from '@/models/data/blood-donate/blood-donate-info/blood-donate-info'

export default {
  components: {
    'top-label-input': TopLabelInputCtrl,
    'symptom-table': SymptomTable,
    'confirmation-button': ConfirmationButton
  },
  props: {
    donation: Object,
    mode : {
      default: 'view'
    }
  },
  data() {
    return {
      myProgress: 1,
      myText: 'Physical Exam',
      vaccineRegular: VaccineRegular
    }
  },
  created () {
    // Remove isPassed to choose again when saved
    // if(!this.donation.isCaseClosed())
    //   this.donation.medicalRecord.isPassed = null

  },
  methods: {
    onTopLabelInputUpdated(e) {
      this.donation.medicalRecord[e.prop] = e.value
    }
  }
}
</script>

<style lang="scss">
  .donation-step-1-exam {
    h4 {
      margin: 0;
    }

    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100% !important;
    }
  }

  .headSlot {
    min-height: 200px;
  }

  .medical-record-card {
    border-radius: 4px;
    min-height: 40px;
    text-align: center;
    color: white;
    background: #33495d;
  }

  .medical-record-card > .el-col {
    transform: translateY(+20%);
  }

  .mucouse-btn > span, .bsc-btn > span {
    height: 35px;
  }

  .mucouse-btn > span {
    background: inherit !important;
    color: transparent !important;
  }

  .mucouse-btn.is-active > span {
    background: inherit !important;
    color: transparent !important;
    border-bottom-color: #009688 !important;
    border-bottom-width: 3px !important;
    border-top-width: 3px !important;
  }

  .bsc-btn > span {
    width: 10px;
  }

  .bsc-btn-active, .bsc-btn:hover {
    background-color: rgb(97, 144, 233) !important;
    color: white;
  }

  .mucouse-mini-labels > .el-col {
    font-size: 10px;
    width: 37px;
    text-align: center;
  }
  /*}*/
</style>
