import BloodType from '@/constants/bloodbank/blood-product-type-enum'
import BloodStockType from '@/constants/bloodbank/pet-storage-stock-type'
import BloodBagStatus from '@/constants/bloodbank/blood-storage-bag-status'
import petTypeEnum from '@/constants/bloodbank/pet-type-enum.js'
import moment from 'moment'
import utils from '../../../../utils/bloodbank.js'
import _ from 'lodash'

export default class {
  constructor (obj) {
    if (obj) {
      this.id = obj.id ? obj.id : 0
      this.originalQuantity = obj.original_quantity ? Number(obj.original_quantity) : 0
      this.quantity = obj.collected_quantity ? Number(obj.collected_quantity) : 0
      this.donationId = obj.blood_donation ? obj.blood_donation : null
      this.petInfo = obj.petInfo ? obj.petInfo : null
      this.petType = obj.pet_type ? this.convertPetTypeToEnum(obj.pet_type) : obj.petInfo ? obj.petInfo.type : null
      this.bloodGroup = obj.blood_group ? obj.blood_group : null
      this.bloodType = obj.blood_type ? utils.convertBloodTypeServiceValueToEnum(obj.blood_type) : BloodType.FWB
      this.dateCollection = obj.collection_date ? obj.collection_date : null
      this.expiryDate = obj.expiry_date ? obj.expiry_date : null
      this.stock = obj.stock ? obj.stock : BloodStockType.PERSONAL
      this.status = this.computeBagStatus(obj)
      this.reservation = obj.reservation ? obj.reservation : null
      this.transformedFromBag = obj.blood_collection ? obj.blood_collection : null
      this.contact = obj.contact ? obj.contact : null
      this.spbbId = obj.spbb_id ? obj.spbb_id : null
      this.existingBloodBagId = obj.existing_bloodbag_id ? obj.existing_bloodbag_id : null
      this.hospitalName = obj.other_hospital_name ? obj.other_hospital_name : null
      this.hospitalId = obj.hospital ? obj.hospital : null
      this.givenToHospitalName = obj.given_to_hospital_name ? obj.given_to_hospital_name : ''
      this.givenByHospital = obj.given_by_hospital ? obj.given_by_hospital : null
      this.reasonOfRemoval = obj.remove_reason != null ? obj.remove_reason : null
      this.pcv = obj.PCV ? Number(obj.PCV) : null
      this.note = obj.note ? obj.note : ''
      this.spbb = obj.spbb_id == null ? null : obj.spbb_id
      this.aliasDonation = obj.alias_blood_donation_id != null ? obj.alias_blood_donation_id : null
    } else {
      this.id = 0
      this.originalQuantity = 0
      this.quantity = 0
      this.donationId = 0
      this.petInfo = null
      this.bloodGroup = null
      this.transformedFromBag = null
      this.bloodType = BloodType.FWB
      this.dateCollection = null
      this.expiryDate = null
      this.stock = BloodStockType.PERSONAL
      this.status = BloodBagStatus.AVAILABLE
      this.reservation = null
      this.transformedBag = []
      this.contact = null
      this.spbbId = null
      this.existingBloodBagId = null
      this.hospitalName = null
      this.hospitalId = null
      this.givenByHospital = null
      this.pcv = ''
      this.note = ''
      this.spbb = null
      this.aliasDonation = null
    }
  }
  setStatus (status) {
    this.status = status
  }

  computeBagStatus (obj) {
    let status = BloodBagStatus.AVAILABLE
    if (obj.remove_reason === 1) {
      status = BloodBagStatus.EXPIRE
    } else if (obj.remove_reason === 2) {
      status = BloodBagStatus.TRANSFER
    } else if (Number(obj.collected_quantity) <= 0 || obj.bag_status === 2) {
      status = BloodBagStatus.USED
    } else if (obj.status != null) {
      status = obj.status
    }

    return status
  }

  setReserve (reserveInfo) {
    this.reservation = reserveInfo
  }

  convertPetTypeToEnum (type) {
    switch (type) {
      case 1:
        return petTypeEnum.DOG

      case 2:
        return petTypeEnum.CAT

      default:
        return type
    }
  }

  getBloodTypeValue (val) {
    const bloodType = {
      FWB: 0,
      SWB: 1,
      PRP: 2,
      FFP: 3,
      FP: 4,
      PRBCS: 5
    }

    return bloodType[val]
  }

  getPetType () {
    return this.petType ? this.petType : this.petInfo ? this.petInfo.type ? this.petInfo.type : null : null
  }

  toRequestJson () {
    return {
      id: this.id,
      blood_group: this.bloodGroup,
      collection_date: _.isDate(new Date(this.dateCollection)) ? this.dateCollection : null,
      original_quantity: Number(this.originalQuantity),
      collected_quantity: Number(this.quantity),
      expiry_date: _.isDate(new Date(this.expiryDate)) && this.expiryDate != null ? moment(this.expiryDate).format('YYYY-MM-DD') : null,
      bag_type: this.bagType,
      bag_status: this.status,
      PCV: Number(this.pcv),
      blood_donation: this.donationId,
      blood_type: utils.convertBloodTypeEnumToServiceValue(this.bloodType),
      pet_type: this.petType === petTypeEnum.DOG ? 1 : 2,
      is_private: this.stock === BloodStockType.PERSONAL,
      existing_bloodbag_id: this.existingBloodBagId,
      // spbb_id: this.spbbId,
      other_hospital_name: this.hospitalName ? this.hospitalName : null,
      hospital: this.hospitalId ? Number.isInteger(this.hospitalId) ? this.hospitalId : null : null,
      given_to_hospital_name: this.givenToHospitalName,
      given_by_hospital: this.givenByHospital ? Number.isInteger(this.givenByHospital) ? this.givenByHospital : null : null,
      remove_reason: this.reasonOfRemoval === 0 ? null : this.reasonOfRemoval,
      blood_collection: this.transformedFromBag,
      note: this.note,
      spbb_id: this.spbb == null || this.spbb === '' ? null : this.spbb,
      alias_blood_donation_id: this.aliasDonation
    }
  }
}

