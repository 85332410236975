<template>
  <div>
    <div class="report_container header">
      <div class="number_panel header">
        <h1>Total</h1>
      </div>
      <div class="table_panel header">
        <div style="flex: 1;">
          <div class="col h header type"></div>
          <div class="col c header total">total</div>
          <div class="col c header male">male</div>
          <div class="col c header female">female</div>
          <div class="col c header others">others</div>
        </div>
        <div style="flex: 0.5;"></div>
      </div>
    </div>
    <div class="report_container">
      <div class="number_panel content">
        <p>{{ total }}</p>
      </div>
      <div class="table_panel content">
        <div v-for="(item, index) in bars" v-bind:key="index">
          <div style="flex: 1; display: flex;">
            <div class="col h type">{{index + 1}}. {{item.name}}</div>
            <div class="col c total">{{item.total}}</div>
            <div class="col c male">{{item.male}}</div>
            <div class="col c female">{{item.female}}</div>
            <div class="col c others">{{item.others}}</div>
          </div>
          <div style="flex: 0.5; flex-direction: column;">
            <LineSideBarTitle :bar="item.bar"/>
            <LineSideBarChart :bar="item.bar"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commaNumber from "comma-number";
import LineSideBarChart from './LineSideBarChart';
import LineSideBarTitle from './LineSideBarTitle';

export default {
  name: "PetNumberChart",
  props: ["pets"],
  components: {
    LineSideBarChart,
    LineSideBarTitle,
  },
  computed: {
    total() {
      return this.pets.number ? commaNumber(this.pets.number.total) : "-";
    },
    bars() {
      let maxNum = 0;
      let bars = [];
      if(this.pets.number){
        maxNum = this.pets.number.list.reduce((max, item) => item.total > max ? item.total : max, 0);
        this.pets.number.list.map((item) => {
          bars.push({
            name: item.name,
            total: item.total,
            male: item.male,
            female: item.female,
            others: item.others,
            bar: [
              {value: item.male/maxNum, unit: item.male, color: '#02b0e1'},
              {value: item.female/maxNum, unit: item.female, color: '#d0025b'},
              {value: item.others/maxNum, unit: item.others, color: '#D8D8D8'},
              {value: (item.total - item.female - item.male - item.others)/maxNum, color: 'rgba(0,0,0,0)'},
            ],
          });
        });
      }
      return bars;
    }
  },
};
</script>

<style lang="scss" scoped>
.report_container {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px 20px 20px;
}
.report_container.header {
  padding: 10px 20px 5px 20px;
}

.number_panel {
  flex: 0.45;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font-weight: normal;
    font-size: 24px;
    margin: 0px;
    text-align: center;
  }
}
.number_panel.content {
  text-align: center;
  border-right: 1px solid #979797;
  p {
    font-weight: normal;
    font-size: 36px;
  }
}

.table_panel {
  flex: 2.1;
  display: flex;
  flex-direction: column;
  padding: 0px 15px 0px 15px;
  div {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
  }
}
.table_panel.header {
  display: flex;
  flex-direction: row;
}

.col {
  padding: 3px 2px 3px 0px;
}
.col.h {
  flex: 2;
}
.col.c {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 5px;
}
.col.header {
  display: flex;
  justify-content: flex-end;
}
.col.type {
  color: #4a4a4a;
}
.col.male {
  color: #02b0e1;
}
.col.female {
  color: #d6005a;
}
.col.others {
  color: #626262;
}

.chart_panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 15px 10px 1px;
}
</style>
