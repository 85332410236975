import moment from 'moment'
import utils from '../../../../utils/bloodbank'
import store from '@/store/index'

export default class {
	constructor(obj) {
		if (obj) {
			this.id = obj.id ? obj.id : null
			this.action_timestamp = obj.action_timestamp
				? moment(obj.action_timestamp).format()
				: null
			this.blood_collection = obj.blood_collection ? obj.blood_collection : null

			// Id of type content_type
			this.object_id = obj.object_id ? obj.object_id : null

			// Enum
			this.action = obj.action === 0 || obj.action ? obj.action : null
			this.content_type = obj.content_type ? obj.content_type : null

			// Info
			this.blood_collection_info = obj.blood_collection
				? store.getters['bloodbank/getBloodBagById'](this.blood_collection)
				: null

			// ConvertedValue
			this.action_enum =
				this.action === 0 || this.action
					? utils.convertBloodCollectionHistoryActionValueToEnum(this.action)
					: null
		} else {
			this.id = null
			this.action_timestamp = moment().format()
			this.blood_collection = null
			this.object_id = null

			// Enum
			this.action = null
			this.content_type = null

			// Info
			this.blood_collection_info = null

			// ConvertedValue
			this.action_enum = null
		}
	}

	toRequestJson() {
		return {
			id: this.id,
			action_timestamp: moment(this.action_timestamp).format(),
			action: this.action,
			object_id: this.object_id,
			blood_collection: this.blood_collection,
			content_type: this.content_type
		}
	}
}
