<template>
  <div class="page">
    <Subnavbar>
      <template slot="title">Create Activity</template>
      <template slot="actions">
        <button
          class="btn btn-large btn-default"
          @click="$router.go(-1)"
        >Cancel</button>
        <button class="btn btn-large btn-primary" @click="handleSave">
            <i class="fa fa-save"></i>
              Save
        </button>
      </template>
    </Subnavbar>
    <ContentBox style="padding: 20px;">
      <div class="form">
        <el-row class="row">
          <el-col :span="6">
            <label>Cover Picture</label>
          </el-col>
          <el-col :span="18">
            <div class="remark">แนบรูป เป็น cover card</div>
            <FileUploader
                    v-if="!coverImageUrl"
                    style="width: 256px; height: 144px; margin: 4px;"
                    :multiple="false"
                    accept="image/*"
                    @onSelect="handleSelectCoverImage"
            />
            <ImageFilePreviewer
                    v-if="coverImageUrl"
                    style="width: 256px; height: 144px; margin: 4px;"
                    :url="coverImageUrl"
                    :showDeleteButton="true"
                    @delete="handleDeleteCoverImage"
            />
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="6">
            <label>Activity Name <span :style="{color: 'red'}">*</span></label>
          </el-col>
          <el-col :span="18">
            <el-input type="text" v-model="form.name"/>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="6">
            <label>Activity Group <span :style="{color: 'red'}">*</span></label>
          </el-col>
          <el-col :span="18">
           <el-select placeholder="Activity Group"
              clearable
              v-model="selectedGroupPosition"
              @change="handleClearSubGroupId"
              @clear="handleClearGroupPosition"
            >
              <el-option
                v-for="(group, index) in groups"
                :key="group.id"
                :value="index"
                :label="`${group.name}`"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="6">
            <label>Activity Sub Group <span :style="{color: 'red'}">*</span></label>
          </el-col>
          <el-col :span="18">
           <el-select placeholder="Activity Sub Group"
              clearable
              no-data-text="โปรดระบุ Activity Group"
              v-model="form.sub_group"
              @clear="handleClearSubGroupId"
            >
              <el-option
                v-for="sub_group in getSubGroupFromGroupSelected()"
                :key="sub_group.id"
                :value="sub_group.id"
                :label="`${sub_group.name}`"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="6">
            <label>Pet Type</label>
          </el-col>
          <el-col :span="18">
            <el-select
              multiple 
              placeholder="Pet Type"
              v-model="form.pet_type"
            >
             <el-option
                v-for="item in petTypes"
                :key="item.id"
                :value="item.id"
                :label="`${item.name}`"
              ></el-option>
            </el-select>
            <small class="remark" style="margin-left: 20px;">*สามารถเลือกได้มากกว่า 1 ชนิด</small>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="6">
            <label>ราคา</label>
          </el-col>
          <el-col :span="6">
            <el-input type="text" v-model="form.price"/>
          </el-col>
          <el-col :span="10">
            <label style="margin-left: 20px;">บาท</label>
          </el-col>
        </el-row>
        
        <label 
          v-if="currentPlan.setting_section.can_use_df_setting"
          :style="{marginLeft: '-4px', marginBottom: '8px'}">Doctor's fee (DF) ค่าบริการทางการแพทย์
          <span :style="{fontSize: '12px', color: '#5a5a5a'}">
            ค่า DF สำหรับคำนวนแยกสำหรับสัตวแพทย์แต่ละคนที่ใช้ Activity ตัวนี้
            <el-button
              :style="{marginLeft: '12px'}"
              type="primary"
              size="medium"
              @click="handleAssignDF">
              <i class="fa fa-plus"></i> เพิ่ม Vet : DF
            </el-button>
          </span>
        </label>
        <transition-group 
          name="fade"
          v-if="currentPlan.setting_section.can_use_df_setting">
        <div v-for="(df, index) in form.doctors_fee" :key="`${index}:${df.id}`" :style="{marginLeft: '60px'}">
          <el-row class="row" :gutter="4">
            <el-col :span="4">
              <label>Veterinary :</label>
            </el-col>
            <el-col :span="6">
              <el-select placeholder="Select Veterinary"
                filterable 
                clearable
                multiple
                v-model="form.doctors_fee[index].vets"
                @clear="() => form.doctors_fee[index].vets = []"
              >
                <el-option
                  v-for="item in staffs"
                  :key="item.id"
                  :value="item.id"
                  :label="`${item.first_name} ${item.last_name}`"
                ></el-option>
              </el-select>

            </el-col>
            <el-col :span="2" class="text-center">
              <label> DF: </label>
            </el-col>
            <el-col :span="5">
              <el-input-number 
                :min="0" 
                :controls="false" 
                :precision="2" 
                :step="0.1"
                style="width: auto"
                v-model="form.doctors_fee[index].price"/>
            </el-col>
            <el-col :span="2" class="text-center"><label>THB</label></el-col>
            <el-col :span="1">
               <label><i class="fa fa-times-circle" style="cursor: pointer;" @click="() => handleUnassignDF(index)"></i></label>
            </el-col>
          </el-row>
          
        </div>
        </transition-group>

        <el-row class="row" :style="{marginTop: '16px'}">
          <el-col :span="6">
            <label>Status</label>
          </el-col>
          <el-col :span="4"><label>Appointment</label></el-col>
          <el-col :span="10">
                <el-checkbox v-model="form.is_active" border>Active</el-checkbox>
                <el-checkbox v-model="form.is_favorite" border>Favorite </el-checkbox>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="6">
            <label>&nbsp;</label>
          </el-col>
          <el-col :span="4"><label>OPD</label></el-col>
          <el-col :span="10">
            <el-checkbox v-model="form.is_active_in_pos" border>Active</el-checkbox>
            <el-checkbox v-model="form.is_favorite_pos" border>Favorite </el-checkbox>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="6">
            <label>&nbsp;</label>
          </el-col>
          <el-col :span="4"><label>POS</label></el-col>
          <el-col :span="10">
            <el-checkbox v-model="form.is_active_in_standalonepos" border>Active</el-checkbox>
            <el-checkbox v-model="form.is_favorite_standalonepos" border>Favorite </el-checkbox>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="4">
            <label>ภาษีมูลค่าเพิ่ม</label>
          </el-col>
          <el-col :span="20">
            <el-radio-group v-model="form.is_vat">
              <el-radio :label="false">ไม่มี VAT</el-radio>
              <el-radio :label="true">VAT 7%</el-radio>
            </el-radio-group>
          </el-col>
        </el-row>
        <hr class="seperator" />
        <h1 class="title">จัดรายการ Product ที่ใช้ในกิจกรรม</h1>
        <transition-group name="fade">
        <div v-for="(p, index) in form.products" :key="`${index}:${p.id}`">
          <el-row class="row" type="flex" :gutter="4">
            <el-col :span="null">
              <label>Product Name:</label>
            </el-col>
            <el-col :span="null" :style="{ flex: '5' }">
              <el-select placeholder="Select Product"
                filterable 
                clearable
                v-model="form.products[index].product"
                @clear="() => form.products[index].product = null"
                @change="() => form.products[index].unit = getProductUnitTemporary(form.products[index].product)"
              >
                <el-option
                  v-for="item in products"
                  :key="item.id"
                  :value="item.id"
                  :label="`${item.trade_name}`"
                ></el-option>
              </el-select>
              <div v-if="index === 0">
                <small class="remark">*ชื่อ Product ที่แสดงใน card activity</small>
              </div>
            </el-col>
            <el-col :span="null" class="text-center">
              <label>จำนวน</label>
            </el-col>
            <el-col :span="null" :style="{ flex: '3' }">
              <el-input-number 
                :min="0" 
                :controls="false" 
                style="width: auto"
                v-model="form.products[index].amount"/>
            </el-col>
            <el-col :span="null" :style="{ flex: '3' }">
              <!-- <label>{{ getProductUnit(form.products[index].product) }}</label> -->
              <el-select placeholder="Select Unit"
                v-if="form.products[index].product"
                v-model="form.products[index].unit"
                disabled
              >
                <el-option
                  v-for="item in getProductUnits(form.products[index].product)"
                  :key="item.unit"
                  :value="item.unit"
                  :label="`${item.name}`"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :span="null" :style="{ flex: '1' }">
              <el-checkbox 
                v-model="form.products[index].visible_print"
                label="แสดงในใบเสร็จ"
                border />
            </el-col>
            <el-col :span="null">
               <label><i class="fa fa-times-circle" style="cursor: pointer;" @click="() => handleUnassignProduct(index)"></i></label>
            </el-col>
          </el-row>
        </div>
        </transition-group>
        <div style="padding: 20px 0;">
          <button class="btn btn-outline"  @click="handleAssignProduct"><i class="fa fa-plus"></i> เพิ่มรายการ</button>
          <button class="btn btn-outline" @click="$router.push({ name: 'productCreate' })" style="margin-left: 20px;"> Create New Product</button>
        </div>
      </div>
      
    </ContentBox>
  </div>
</template>

<script>
import { mapState } from "vuex"
import Subnavbar from "@/components/navigation/Subnavbar";
import ContentBox from "@/components/container/ContentBox";
import FileUploader from "@/components/upload/FileUploader"
import ImageFilePreviewer from "@/components/upload/ImageFilePreviewer"

// ไม่มี Vat ค่า เป้น null
// 
export default {
  components: {
    ContentBox,
    Subnavbar,
    FileUploader,
    ImageFilePreviewer
  },
  data() {
    return {
      isLoading: false,
      coverImageFile: null,
      coverImageUrl: null,
      attachmentFiles: [],
      activity: null,
      groups: [],
      subgroups: [],
      petTypes: [],
      selectedGroupPosition: null,
      products: [],
      form: {
        name: '',
        sub_group: '',
        price: 0,
        pet_type: [],
        products: [
          {
            product: null,
            unit: null,
            amount: 0.01,
            visible_print: true,
          }
        ],
        doctors_fee: [
          {
            vets: [],
            price: 0,
          }
        ],
        pet_gender: -1,
        note: [],
        is_active: true,
        is_favorite: false,
        position_in_favorite: 0,
        is_active_in_pos: true,
        is_favorite_pos: false,
        position_in_favorite_pos: 0,
        is_active_in_standalonepos: false,
        is_favorite_standalonepos: false,
        is_vat: false,
      }
    };
  },
  computed: {
     ...mapState({
      staffs: state => state.hospital.staffs,
      currentPlan: state => state.plan.currentPlan,
    })
  },
  methods: {
    getProductUnitTemporary(id) {
      const product = this.products.find(item => item.id === id);
      if (!product.unit) {
        return null
      } else {
        return product.unit.id
      }
    },
    getProductUnits (id) {
      if(this.products && this.products.length > 0) {
        const product = this.products.find(item => item.id === id);
        if (product && product.unit) {
          let results = [{unit: product.unit.id, name: product.unit.name}]
          return product.extra_units.reduce((arr, unit) => {
            arr.push({unit: unit.unit, name: unit.name});
            return arr;
          }, results);
        }
      } else {
        return []
      }
    },
    handleSelectCoverImage(files) {
      if (files.length > 0) {
        const self = this;
        const reader = new FileReader();
        this.coverImageFile = files[0]

        reader.onload = function (e) {
          self.coverImageUrl = e.target.result
        }
        reader.readAsDataURL(files[0])
      } else {
        this.coverImageFile = null
        this.coverImageUrl = null
      }
    },
    handleDeleteCoverImage() {
      this.coverImageUrl = null
    },
    handleSelectAttachment(files) {
      this.attachmentFiles = [...this.attachmentFiles, ...files]
    },
    handleDeleteAttachment(index) {
      this.attachmentFiles.splice(index, 1)
    },
    handleClearGroupPosition () {
      this.selectedGroupPosition = null
      this.form.sub_group = ''
    },
    handleClearSubGroupId () {
      this.form.sub_group = ''
    },
    getSubGroupFromGroupSelected() {
      if (this.selectedGroupPosition == null) return [];
      return this.groups[this.selectedGroupPosition].sub_group;
    },
    handleAssignProduct () {
      this.form.products.push({
        product: null,
        unit: null,
        amount: 0.01,
        visible_print: true,
      })
    },
    handleUnassignProduct (index) {
      this.form.products.splice(index, 1)
    },
    handleAssignDF () {
      this.form.doctors_fee.push({
        vets: [],
        price: 0
      })
    },
    handleUnassignDF (index) {
      this.form.doctors_fee.splice(index, 1)
    },
    verifySave() {
      if (!this.form.name) {
        this.$notify({
          message: 'โปรดใส่ชื่อ Activity',
          type: "warning"
        })
        return false;
      }

      if (!this.form.sub_group) {
        this.$notify({
          message: 'โปรดใส่ Group และ Sub Group',
          type: "warning"
        })
        return false;
      }

      if (this.form.doctors_fee.length > 1) {
        const dfs = this.form.doctors_fee.reduce(function(result, element) {
            result.push(element.vets);
            return result;
        }, []);

        const duplicateDFs = dfs.shift().reduce(function(result, element) {
            if (result.indexOf(element) === -1 && dfs.every(function(a) {
                return a.indexOf(element) !== -1;
            })) result.push(element);
            return result;
        }, []);

        if (duplicateDFs.length > 0) {
          this.$notify({
            message: 'ค่า DF ของหมอซ้ำ',
            type: "warning"
          })
          return false;
        }
      }

      const dfOverPrice = this.form.doctors_fee.filter(item => item.price > this.form.price);
      if (dfOverPrice.length > 0) {
        this.$notify({
          message: 'ค่า DF ไม่สามารถเกินราคา Activity ได้',
          type: "warning"
        })
        return false;
      }

      const invalidAmount = this.form.products.filter(item => item.amount < 0.01);
      if (invalidAmount.length > 0) {
        this.$notify({
          message: 'จำนวน Product ต้องมากกว่า 0.01',
          type: "warning"
        })
        return false;
      }

      return true;
    },

    async handleSave () {
      if (!this.verifySave()) return

      const data = {
        ...this.form,
        is_hidden: !this.form.is_active,
        is_hidden_pos: !this.form.is_active_in_pos,
        is_hidden_standalonepos: !this.form.is_active_in_standalonepos,
        products: this.form.products.filter(item => item.product !== null),
        doctors_fee: this.form.doctors_fee.filter(item => item.vets.length > 0),
      }

      if (this.form.is_active && !this.form.is_active_in_pos) {
        this.$notify({
          type: "warning",
          title: "กรุณาเปิด OPD status"
        })
        return
      }
      
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        this.activity = await this.$store.dispatch("activity/createActivity", {
          data
        });

        if (this.coverImageFile) {
          let formData = new FormData()
          formData.append("attachment", this.coverImageFile)
            await this.$store.dispatch("activity/uploadActivityV2", {
            params: { id: this.activity.id },
            formData
          });
        }

        this.$router.go(-1)
        this.$notify({
          type: "success",
          title: "บันทึกข้อมูลเรียบร้อยแล้ว"
        })
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorMsg", error.detail)
      }
    },
    async fetchData() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        this.groups = await this.$store.dispatch("activity/listActivityGroups", {
          query: { all: true }
        });
        this.products = await this.$store.dispatch("product/listRealImageProducts", {
          query: { all: true }
        });
        this.petTypes = await this.$store.dispatch("pet/listTypes", {
          query: { all: true }
        });
        await this.$store.dispatch("hospital/listStaff")
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg");
      }
    },
  },
  async created() {
    this.fetchData();
  }
};
</script>

<style lang="scss" scoped>
.page {
  .seperator {
    margin-top: 30px;
    margin-bottom: 20px;
    border-top: none;
    border-bottom: 1px solid #ccc;
  }
}

.form {
  padding: 0 50px;
  .row {
    margin-bottom: 10px;
  }

  .title {
    color: $light-blue;
    font-size: 1.2em;
  }

  label {
    display: inline-block;
    margin-right: 3px;
    line-height: 2.4em;
    
    &.multi-line {
      line-height: 1.2em;
      span {
        display: block;
      }
    }

    small {
      color: #999;
    }
  }

  .remark {
    font-size: 12px;
    color: #999;
  }

  .attachment-list {
    list-style-type: none;
    padding: 0;
    margin: 10px 0 0 0;

    > li {
      margin: 5px 0;
      padding: 0 10px;
      font-size: 14px;

      .name {
        display: inline-block;
        min-width: 150px;
        padding: 0 10px;
      }

      i {
        color: $light-blue;

        &.fa-times-circle {
          color: #ccc;
          cursor: pointer;

          &:hover {
            color: $red;
          }
        }
      }
    }
  }

  .el-radio {
    display: inline-block;
    margin-right: 20px;
  }

  .el-checkbox {
    line-height: 1em;
  }
}
</style>
