<template>
  <div class="form-block">
    <div class="header-block">
      <div class="title">
        Pet Owner
      </div>
      <div class="lead">
        Register
      </div>
    </div>
    <el-form ref="form" :model="form">
      <el-form-item>
        <el-input
          prefix-icon="far fa-user"
          type="tel"
          :disabled="true"
          name="telephone"
          placeholder="Telephone"
          v-model="form.telephone"></el-input>
        <span class="error">{{ errors.first('telephone') }}</span>
      </el-form-item>

      <el-form-item>
        <el-input
          type="text"
          v-validate="'required'"
          name="otp"
          ref="otp"
          placeholder="OTP"
          v-model="form.otp"></el-input>
        <span class="error">{{ errors.first('otp') }}</span>
      </el-form-item>

      <el-form-item class="text-center">
        <router-link :to="{ name: 'petOwnerRegister' }"
          class="btn btn-plain form-submit-btn">
          Cancel
        </router-link>
        <el-button
          :loading="submitButtonLoading"
          type="submit"
          class="btn btn-primary form-submit-btn"
          @click="onSubmit">Verify OTP</el-button>
        <span class="error">{{ errMessage }}</span>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        telephone: '',
        password: '',
        otp: '',
      },
      errMessage: '',
      submitButtonLoading: false,
    }
  },
  mounted() {
    if (this.$store.state.owner.profile != null)
      this.form.telephone = this.$store.state.owner.profile.phone
    if (this.$route.params.phone != null)
      this.form.telephone = this.$route.params.phone;
    if (this.$route.params.password != null)
      this.form.password = this.$route.params.password;
  },
  methods: {
    async onSubmit() {
      this.submitButtonLoading = true
      const valid = await this.$validator.validateAll()
      if (valid) {
        try {
          if (this.$route.query.verify_code == 'owner_create_password') {
            const result = await this.$store.dispatch('owner/ownerOTPVerify', {
              data: {
                phone: this.form.telephone,
                pin: this.form.otp,
                operation_name: 'owner_create_password'
              }
            })
            if (result.token != null) {
              this.$router.push({ name: 'invitePage', 
                params: {
                  phone: this.form.telephone,
                  token: result.token,
                },
                query: {
                  verify_code: 'owner_create_password',
                }
              })
            } else {
              if (["Code is invalid.", "Parameter Invalid."].indexOf(result.error) > -1) {
                this.errMessage = "Incorrect OTP pin";
              } else {
                this.$router.push({ name: 'petOwnerRegister' })
                this.$message('VRemind verification code expired. (รหัสหมดอายุแล้ว กรุณาลองใหม่อีกครั้ง)')
              }
            }
          } else {
            const result = await this.$store.dispatch('owner/ownerOTPVerify', {
              data: {
                phone: this.form.telephone,
                pin: this.form.otp,
                operation_name: 'owner_verify'
              }
            })
            if (result.message == 'Verification complete! Please Login to continue.') {    
              try {
                await this.$store.dispatch('auth/ownerLogin', {
                  data: {
                    telephone: this.form.telephone,
                    password: this.$route.params.password
                  }
                })
                this.$router.push({ name: 'petOwnerGeneralInfo' })
              } catch (e) {
                this.$message("เกิดข้อผิดพลาดที่ไม่รู้จัก กรุณาติดต่อผู้ดูแลระบบ")
              }
            } else {
              this.errMessage = 'Incorrect OTP pin'
            }
          } 
        } catch (e) {
          this.errMessage = e.pin[0]
        }
      }
      this.submitButtonLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.form-block {
  @extend %block-container;
  @extend %form-style;
  .btn-primary {
    width: 140px;
  }
}
</style>
