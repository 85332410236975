<template>
  <div class="appointment-table-container">
    <div class="header">
      <span class="total">{{ apptCount | number }}</span> RESULT
    </div>
    <table class="appointment-table">
      <thead>
        <tr>
          <th class="status">&nbsp;</th>
          <th class="currently-action">
            Currently Action
            <!-- <i class="el-icon-arrow-down"></i> -->
          </th>
          <th class="date-of-appointment">
            Date of Appointment
            <!-- <i class="el-icon-arrow-down"></i> -->
          </th>
          <th class="pet">
            Pet
            <!-- <i class="el-icon-arrow-down"></i> -->
          </th>
          <th class="owner">
            Owner
            <!-- <i class="el-icon-arrow-down"></i> -->
          </th>
          <th class="activities">
            Activities
            <!-- <i class="el-icon-arrow-down"></i> -->
          </th>
          <th class="action">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="appt in appts.results" :key="appt.id">
          <td>
            <div
              :class="{ tag: true, 'tag-vertical': true, [appt.status]: true}"
            >{{ appt.status | apptStatus }}</div>
          </td>
          <td>
            <div>{{ appt.last_updated | toThaiDate}}</div>
            <div class="subtitle">{{ appt.last_updated | toHHMM}}</div>
          </td>
          <td>
            <div>{{ appt.date_time | toThaiDate}}</div>
            <div class="subtitle">
              <template v-if="!appt.is_no_time">{{appt.date_time | toHHMM}}</template>
              <template v-else>ไม่ระบุเวลา</template>
            </div>
          </td>
          <td>
            <template v-if="appt.pet">
              <img
                v-if="appt.pet.rank <= 3"
                :src="getStatusIcon(appt.pet.rank)"
                alt="Status icon"
                :style='{ 
                  display: "inline-block",
                  height: "2em",
                  marginRight: "6px",
                  marginBottom: "12px"
                }'
              />
              <div
                v-bind:class="{ blankLeft: appt.pet.rank > 3 }"
                class="thumbnail">
                <img v-if="appt.pet.image && appt.pet.image.medium" :src="appt.pet.image.medium" />
              </div>
              <div class="info">
                <div class="name">
                  <router-link
                    :to="{ name:'hospitalPetProfile', params: { id: appt.pet.pet_hospital.id }}"
                  >{{ appt.pet.name }}</router-link>
                </div>
                <div class="subtitle">{{ appt.pet.type.name }}</div>
              </div>
            </template>
          </td>
          <td>
            <div class="thumbnail">
              <img
                v-if="appt.remind_to && appt.remind_to.image.medium"
                :src="appt.remind_to.image.medium"
              />
              <img
                v-if="appt.pet.owners.length > 0"
                :src="appt.pet.owners[0].image.medium"
              />
            </div>
            <div class="info">
              <div class="name">
                <router-link
                  v-if="appt.remind_to"
                  :to="{ name:'hospitalPetOwnerProfile', params: { id: appt.remind_to.id }}"
                >{{ `${appt.remind_to.firstname} ${appt.remind_to.lastname}` }}</router-link>
                <router-link
                  v-else-if="appt.pet.owners.length > 0"
                  :to="{ name:'hospitalPetOwnerProfile', params: { id: appt.pet.owners[0].id }}"
                >{{ `${appt.pet.owners[0].firstname} ${appt.pet.owners[0].lastname}` }}</router-link>
              </div>
              <div
                class="subtitle"
                v-if="appt.remind_to"
              >Tel. {{ appt.remind_to.reminder_phone}}</div>
              <div
                class="subtitle"
                v-if="!appt.remind_to && appt.pet.owners.length > 0"
              >Tel. {{ appt.pet.owners[0].reminder_phone}}</div>
            </div>
          </td>
          <td v-if="appt.activities">
            <el-tooltip placement="top" effect="dark">
              <div slot="content">{{ getActivityName(appt.activities) }}</div>
              <div>
                {{ appt.activities.length }}
                <template v-if="appt.activities.length > 1">Activities</template>
                <template v-else>Activity</template>
              </div>
            </el-tooltip>
          </td>
          <th>
            <el-button
              size="mini"
              @click="$router.push({ name: 'appointment-detail', params: { id: appt.id } })"
            >
              See Detail
              <i class="el-icon-arrow-right el-icon-right"></i>
            </el-button>
          </th>
        </tr>
      </tbody>
    </table>
    <div class="not-found" v-if="appts.length === 0">ไม่พบข้อมูลที่ต้องการค้นหา</div>
    <el-pagination
      class="pagination"
      background
      layout="prev, pager, next"
      :current-page="currentPage"
      :page-size="pageSize"
      :total="total"
      @current-change="(page) => $emit('current-change', page)"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    appts: {
      type: Object,
      required: true
    },
    pageSize: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  computed: {
    apptCount() {
      if (this.appts && this.appts.count) {
        return this.appts.count || 0;
      }
      return 0;
    }
  },

  methods: {
    getStatusIcon(value) {
      switch (value) {
        case 1:
          return require("@/assets/icons/medal-gold.svg");

        case 2:
          return require("@/assets/icons/medal-silver.svg");

        case 3:
          return require("@/assets/icons/medal-bronze.svg");
      }
    },

    getActivityName(items) {
      return items.map( item => item.name );
    }
  }
};
</script>

<style lang="scss" scoped>
.appointment-table-container {
  .header {
    margin-bottom: 5px;
  }
}
.appointment-table {
  width: 100%;
  border-collapse: collapse;

  a {
    color: #333;
    text-decoration: none;
    &:hover {
      color: #02b0e1;
    }
  }

  thead {
    tr {
      border: #e6eaee 1px solid;

      th {
        padding: 5px;
        font-weight: normal;
        color: #7f8fa4;
        background-color: #f5f8fa;
        text-align: left;
        font-size: 0.9em;

        &.status {
          width: 30px;
        }

        &.currently-action,
        &.date-of-appointment {
          width: 140px;
        }

        &.pet {
          width: 190px;
        }

        &.activities {
          width: 90px;
        }

        &.action {
          width: 100px;
        }
      }
    }
  }

  tbody {
    tr {
      height: 82px;

      td {
        position: relative;
        padding: 5px;
        font-size: 0.9em;

        .subtitle {
          color: #666;
        }

        .thumbnail {
          display: inline-block;
          width: 50px;
          height: 50px;
          overflow: hidden;
          background: #eee;
          border-radius: 5px;
          margin-right: 10px;

          > img {
            width: 50px;
            height: 50px;
            border-radius: 5px;
          }
        }

        .info {
          display: inline-block;
          width: calc(100% - 95px);
          vertical-align: top;
        }

        .name,
        .subtitle {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .blankLeft {
          margin-left: 34px;
        }
      }
    }
  }
}

.not-found {
  padding: 20px 0;
  text-align: center;
}

.pagination {
  margin-top: 20px;
  text-align: right;
}
</style>


