<template>
  <div class="owner-detail">
    <Subnavbar>
      <template slot="breadcrumb">
        <ul>
          <li>Pet Profile</li>
        </ul>
      </template>
      <template slot="title">PET PROFILE</template>
      <template slot="actions">
        <button
          class="btn btn-large btn1"
          v-if="currentPlan.direct_message_section.can_use_direct_message"
          @click="
            $router.push({
              name: 'hospitalDirectMessage',
              params: { id: pet.id },
            })
          "
        >
          <img src="@/assets/icons/icon-email.png" />
          Send Message
        </button>
        <button
          v-if="pet && pet.id"
          class="btn btn-large btn1"
          @click="
            $router.push({
              name: 'hospitalPetSearchOwnerPage',
              params: { id: pet.id },
            })
          "
        >
          <img
            src="@/assets/icons/icon-add-pet.png"
            style="width: 22px; height: 22px;"
          />
          + Owner
        </button>
        <el-dropdown class="btn3" @command="onFeatureClick">
          <el-button type="primary">
            <div><img src="@/assets/icons/icon-record.png" /></div>
            <div>Record<i class="el-icon-arrow-down el-icon--right"></i></div>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="medicalrecord"
              >OPD Record</el-dropdown-item
            >

            <el-dropdown-item
              v-if="isShowPetBloodGroupMenu"
              command="petBloodGroup"
            >
              Blood group Record
            </el-dropdown-item>
            <el-dropdown-item command="petWeight"
              >Weight Record</el-dropdown-item
            >
            <el-dropdown-item command="petMicrochip"
              >Microchip Record</el-dropdown-item
            >
            <el-dropdown-item command="redeem">Redeem Point</el-dropdown-item>
            <el-dropdown-item command="addPetPoint"
              >Add Health Point</el-dropdown-item
            >
            <el-dropdown-item
              v-if="currentPlan.hospital_feature.can_delete_pet"
              command="deletePet">
                Delete Pet
            </el-dropdown-item>

          </el-dropdown-menu>
        </el-dropdown>
        <button
          class="btn btn-large btn2"
          @click="
            $router.push({
              name: 'appointment-select-activities',
              params: { id: pet.id },
              query: { cancelToRouteName: 'hospitalPetProfile' },
            })
          "
        >
          <img
            src="@/assets/icons/icon-add-appt.png"
            style="width: 20px; height: 20px;"
          />
          Appointment
        </button>
      </template>
    </Subnavbar>

    <div class="el-row">
      <div class="el-col el-col-24 el-col-md-8">
        <PetDetail
          :pet="profile"
          :showProfile="isShowProfile"
          routeEditPet="hospitalPetProfileEdit"
        />
        <PetNote :pet="profile" />
        <PetDetailOwner :pet="profile" :showProfile="isShowProfile" />
        <div class="panel-block">
          <el-button
            class="action-btn"
            type="default"
            @click="isShowProfile = !isShowProfile"
            style="width: 100%; margin-top: 10px; margin-bottom: 15px;"
          >
            {{ isShowProfile ? "Hide" : "Show" }} Profile
          </el-button>
        </div>
      </div>
      <div
        class="el-col el-col-24 el-col-md-16 content-padding"
        style="height: 100%"
      >
        <el-tabs v-model="activeName">
          <el-tab-pane label="Appointment" name="appointment">
            <PetAppointmentTimeline
              v-if="pet && isAppointmentLoaded"
              :petHospitalId="pet.pet.id"
              type="active"
              viewer="hospital"
              viewIn="petPage"
            />

            <el-collapse class="appointment-history-collapse">
              <el-collapse-item name="1">
                <template slot="title">
                  <div class="appointment-history-collapse-title">
                    ประวัติการนัดหมาย
                  </div>
                </template>
                <PetAppointmentTimeline
                  v-if="pet && isAppointmentLoaded"
                  :petHospitalId="pet.pet.id"
                  viewer="hospital"
                  viewIn="petPage"
                />
              </el-collapse-item>
            </el-collapse>
          </el-tab-pane>
          <el-tab-pane label="Health Book" name="healthBook">
            <PetHealthBookTimeline
              v-if="pet && ishealthBookLoaded"
              viewer="hospital"
              :petId="pet.pet.id"
            />
          </el-tab-pane>
          <el-tab-pane label="Medical record" name="medicalrecord">
            <h2 class="hidden-sm-and-down">Master Problem</h2>
            <PetFinalDiagnosisTimeline
              v-if="pet && isMedicalRecordLoaded"
              title="Master Problem"
              :petId="pet.pet.id"
            />
            <h2>Medical record</h2>
            <PetMedicalRecordTimeline
              v-if="pet && isMedicalRecordLoaded"
              :petId="pet.pet.id"
            />
          </el-tab-pane>
          <el-tab-pane label="Insurance" name="insurance">
            <PetInsuranceTimeline
              v-if="pet && isInsuranceLoaded"
              viewer="hospital"
              :petID="pet.id"
            />
          </el-tab-pane>
          <el-tab-pane label="Message" name="message">
            <PetMessageTimeline
              v-if="pet && isMessageLoaded"
              :petHospitalId="pet.pet.id"
            />
          </el-tab-pane>
          <el-tab-pane
            label="Expense History"
            name="expense"
            v-if="currentPlan.income_expense_section.can_view_finance_history">
            <Expense>
              <ExpenseOverview
                slot="overview"
                :revenue="expenseOverview.revenue"
                :deposit="expenseOverview.deposit"
                :overdue="expenseOverview.overdue"
              />
              <ExpenseTabs active-name="summary">
                <ExpenseTab label="Summary" name="summary">
                  <ExpenseSummary :items="expenseSummaryList" />
                </ExpenseTab>
                <ExpenseTab label="History" name="history">
                  <ExpenseHistory
                    :items="expenseHistory.items"
                    :total="expenseHistory.total"
                    @pageChange="handleExpenseResultPageUpdate"
                    @view="handleExpenseResultView"
                  />
                </ExpenseTab>
              </ExpenseTabs>
            </Expense>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <PetWeightDialog
      v-if="isShowPetWeightDialog"
      :visible="isShowPetWeightDialog"
      :form="petWeightForm"
      @confirm="createPetWeight"
      @cancel="isShowPetWeightDialog = false"
    />

    <PetBloodGroupDialog
      v-if="isShowPetBloodGroupDialog"
      :visible="isShowPetBloodGroupDialog"
      :form="petBloodGroupForm"
      @confirm="createPetBloodGroup"
      @cancel="isShowPetBloodGroupDialog = false"
    />

    <PetMicrochipDialog
      v-if="isShowPetMicrochipDialog"
      :visible="isShowPetMicrochipDialog"
      ref="microchipStatusForm"
      @save="createPetMicrochip"
      @cancel="isShowPetMicrochipDialog = false"
    />

    <AddPetPointDialog
      v-if="isShowaddPetPointDialog"
      :visible="isShowaddPetPointDialog"
      :form="addPetPointForm"
      @confirm="addPetPoint"
      @cancel="isShowaddPetPointDialog = false"
    />

    <ConfirmDeleteDialog
      v-if="profile && currentHospital"
      :visible="isShowConfirmDeleteDialog"
      @cancel="isShowConfirmDeleteDialog = false"
      @confirm="handleDeletePet"
    >
      <template slot="header">
        <h1 class="title" :style="{ color: '#000' }">
          ยืนยันการลบ "{{ profile.name }}" <br />ออกจาก "{{
            currentHospital.name
          }}"
        </h1>
      </template>
      <template slot="content">
        <div :style="{ display: 'inline-block', textAlign: 'center' }">
          <p :style="{ fontSize: '14px', textAlign: 'left' }">
            เมื่อยืนยันการลบสัตว์เลี้ยงออกแล้ว จะไม่สามารถค้นหา หรือ บันทึก
            <br />ข้อมูลลงไปในสัตว์เลี้ยงตัวนี้ได้อีก
          </p>
        </div>
      </template>
    </ConfirmDeleteDialog>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import store from "@/store";
import Subnavbar from "@/components/navigation/Subnavbar";
import Avatar from "@/components/avatar/Avatar";
import PetDetail from "@/components/pet/PetDetail";
import PetDetailOwner from "@/components/pet/PetDetailOwner";
import PetNote from "@/components/pet/PetNote";
import PetMessageTimeline from "@/components/message/PetMessageTimeline";
import PetAppointmentTimeline from "@/components/appointment/PetAppointmentTimeline";
import PetMedicalRecordTimeline from "@/components/medical/timeline/PetMedicalRecordTimeline";
import PetHealthBookTimeline from "@/components/healthbook/timeline/PetHealthBookTimeline";
import PetInsuranceTimeline from "@/components/insurance/PetInsuranceTimeline";
import PetFinalDiagnosisTimeline from "@/components/medical/timeline/PetFinalDiagnosisTimeline";
import PetWeightDialog from "@/components/dialog/pet/PetWeightDialog";
import PetBloodGroupDialog from "@/components/dialog/pet/PetBloodGroupDialog";
import PetMicrochipDialog from "@/components/dialog/pet/MicrochipStatusModal";
import AddPetPointDialog from "@/components/dialog/pet/AddPetPointDialog";
import ConfirmDeleteDialog from "@/components/dialog/ConfirmDialog";
import {
  Expense,
  ExpenseOverview,
  ExpenseTabs,
  ExpenseTab,
  ExpenseSummary,
  ExpenseHistory,
} from "./components/expense";

const fetchExpenseHistory = async (id, query) => {
  try {
    store.dispatch("ui/setIsFullScreenLoading", true);

    const result = await store.dispatch('pet/getExpenseHistory', {
      params: { id: id },
      query: query,
    });

    store.dispatch("ui/setIsFullScreenLoading", false);

    const { results, count } = result;

    return {
      items: results.map((item) => ({
        date: new Date(item.datetime),
        type: item.type_name,
        revenue: item._amount,
        refId: item.receipt,
      })),
      total: count,
    };
  } catch (e) {
    store.dispatch("ui/showFetchErrorWithReloadMsg");
    throw e;
  }
};

const fetchExpenseOverview = async (id) => {
  try {
    store.dispatch("ui/setIsFullScreenLoading", true);

    const result = await store.dispatch('pet/getExpenseOverview', {
      params: { id: id },
    });

    store.dispatch("ui/setIsFullScreenLoading", false);

    return {
      revenue: +result.total_income,
      overdue: +result.overdue,
      deposit: +result.deposit,
    };
  } catch (e) {
    store.dispatch("ui/showFetchErrorWithReloadMsg");
    throw e;
  }
};

export default {
  components: {
    Avatar,
    Subnavbar,
    PetDetail,
    PetDetailOwner,
    PetNote,
    PetMessageTimeline,
    PetAppointmentTimeline,
    PetMedicalRecordTimeline,
    PetFinalDiagnosisTimeline,
    PetHealthBookTimeline,
    PetInsuranceTimeline,
    PetWeightDialog,
    PetBloodGroupDialog,
    PetMicrochipDialog,
    AddPetPointDialog,
    ConfirmDeleteDialog,
    Expense,
    ExpenseOverview,
    ExpenseTabs,
    ExpenseTab,
    ExpenseSummary,
    ExpenseHistory,
  },
  computed: {
    ...mapState({
      pet: (state) => state.pet.selected,
      currentHospital: (state) => state.hospital.profile,
      currentPlan: (state) => state.plan.currentPlan,
    }),
  },
  data() {
    return {
      profile: null,
      isShowProfile: true,
      activeName: "",
      isMessageLoaded: false,
      isAppointmentLoaded: false,
      isMedicalRecordLoaded: false,
      ishealthBookLoaded: false,
      isInsuranceLoaded: false,
      isShowPetWeightDialog: false,
      isShowPetBloodGroupDialog: false,
      isShowPetBloodGroupMenu: false,
      isShowPetMicrochipDialog: false,
      isShowaddPetPointDialog: false,
      isShowConfirmDeleteDialog: false,
      expenseOverview: {},
      expenseSummaryList: [],
      expenseHistory: {
        items: [],
        total: 0
      },
      petWeightForm: {
        date: moment(String(new Date())).format("YYYY-MM-DD"),
        weight: 0,
      },
      petBloodGroupForm: {
        date: moment(String(new Date())).format("YYYY-MM-DD"),
        blood_group_items: [],
        blood_group: "",
        note: "",
        file: "",
      },
      addPetPointForm: {
        point: 1,
      },
    };
  },
  methods: {
    async handleExpenseResultPageUpdate(page) {
      const result = await fetchExpenseHistory(this.profile.id, { page: page });
      this.expenseHistory.items = [
        ...this.expenseHistory.items,
        ...result.items
      ]
    },

    handleExpenseResultView(receiptID) {
      this.$router.push({
        name: "income-expense-receipt-detail",
        params: { id: receiptID },
      });
    },

    onFeatureClick(command) {
      switch (command) {
        case "donate":
          this.$router.push({
            name: "donationCreate",
            params: { id: this.pet.id },
          });
          break;
        case "request":
          this.$router.push({
            name: "requestCreate",
            params: { id: this.pet.id },
          });
          break;
        case "petWeight":
          this.isShowPetWeightDialog = true;
          break;
        case "petBloodGroup":
          this.isShowPetBloodGroupDialog = true;
          break;
        case "petMicrochip":
          this.isShowPetMicrochipDialog = true;
          break;
        case "addPetPoint":
          this.isShowaddPetPointDialog = true;
          break;
        case "deletePet":
          this.isShowConfirmDeleteDialog = true;
          break;
        case "medicalrecord":
          this.$router.push({
            name: "medicals-add-detail",
            params: { id: this.pet.id },
          });
          break;
        case "redeem":
          this.$router.push({
            name: "redeem-pet",
            params: { id: this.$route.params.id },
          });
      }
    },

    async createPetWeight() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        await this.$store.dispatch("pet/updatePetWeight", {
          params: { petHospitalId: id },
          data: {
            date: this.petWeightForm.date,
            weight: this.petWeightForm.weight,
          },
        });

        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        this.$notify({
          type: "success",
          title: "บันทึกข้อมูลเรียบร้อยแล้ว",
        });
        this.isShowPetWeightDialog = false;
        this.profile.weight = this.petWeightForm.weight;
        this.$router.go();
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorMsg");
      }
    },

    preparePetBloodGroupForm() {
      if (this.profile.type.key == "DOG") {
        this.isShowPetBloodGroupMenu = true;
        this.petBloodGroupForm.blood_group_items = [
          { label: "Unknown", value: "" },
          { label: "DEA 1.1 +", value: "DEA 1.1 +" },
          { label: "DEA 1.1 -", value: "DEA 1.1 -" },
        ];
      } else if (this.profile.type.key == "CAT") {
        this.isShowPetBloodGroupMenu = true;
        this.petBloodGroupForm.blood_group_items = [
          { label: "Unknown", value: "" },
          { label: "A", value: "A" },
          { label: "B", value: "B" },
          { label: "AB", value: "AB" },
        ];
      }
    },

    async createPetBloodGroup() {
      try {
        let formData = new FormData();
        formData.append("date", this.petBloodGroupForm.date);
        formData.append("blood_group", this.petBloodGroupForm.blood_group);
        formData.append("note", this.petBloodGroupForm.note);
        formData.append("file", this.petBloodGroupForm.file);

        const id = this.$route.params.id;
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        await this.$store.dispatch("pet/updatePetBloodGroup", {
          params: { petHospitalId: id },
          data: formData,
        });

        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        this.$notify({
          type: "success",
          title: "บันทึกข้อมูลเรียบร้อยแล้ว",
        });
        this.isShowPetBloodGroupDialog = false;
        this.profile.blood_group = this.petBloodGroupForm.blood_group;
        this.$router.go();
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorMsg");
      }
    },
    createPetMicrochip(data) {
      this.$store.dispatch("ui/setIsFullScreenLoading", true);

      let formData = new FormData();
      formData.append("microchip", data.microchip);
      formData.append("microchip_date", data.microchip_date);
      formData.append("microchip_note", data.microchip_note || "");

      if (data.file && data.file !== "") {
        formData.append("file", data.file);
      }

      this.$store
        .dispatch("pet/updatePetMicrochip", {
          params: { petHospitalId: this.pet.id },
          formData,
        })
        .then(() => {
          this.$store.dispatch("ui/setIsFullScreenLoading", false);
          this.isShowPetMicrochipDialog = false;
          this.$notify({
            type: "success",
            title: "บันทึกข้อมูลเรียบร้อยแล้ว",
          });
          this.profile.microchip = data.microchip;
          this.$refs.microchipStatusForm.clearFormData();
          this.$router.go();
        })
        .catch(() => {
          this.$store.dispatch("ui/setIsFullScreenLoading", false);
          this.$notify({
            type: "error",
            title: "ไม่สามารถบันทึกข้อมูลได้",
          });
        });
    },
    async addPetPoint() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        let result = await this.$store.dispatch("reward/addPetPoint", {
          data: {
            pet_hospital: id,
            point: this.addPetPointForm.point,
          },
        });

        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        this.$notify({
          type: "success",
          title: "บันทึกข้อมูลเรียบร้อยแล้ว",
        });
        this.isShowaddPetPointDialog = false;
        this.profile.point = result.point;
        this.$router.go();
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorMsg");
      }
    },
    async handleDeletePet() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        await this.$store.dispatch("pet/deletePetFromHospital", {
          params: { petHospitalId: id },
        });

        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        this.$notify({
          type: "success",
          title: "ลบสัตว์เลี้ยงเรียบร้อยแล้ว",
        });
        this.isShowConfirmDeleteDialog = false;
        this.$router.push({ name: "hospitalPets" });
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorMsg");
      }
    },
  },
  async created() {
    this.activeName = "appointment";
    const { tab } = this.$route.query;
    if (tab) this.activeName = tab;

    try {
      const id = this.$route.params.id;
      await this.$store.dispatch("ui/setIsFullScreenLoading", true);
      await this.$store.dispatch("pet/getPetProfile", {
        params: { petHospitalId: id },
      });

      if (this.$store.state.pet.selected) {
        const pet = this.$store.state.pet.selected.pet;

        if (pet !== undefined) {
          this.profile = {
            image: pet.image.large,
            name: pet.name,
            id: pet.id,
            rank: pet.rank,
            point: pet.point,
            birthdate: pet.birthday,
            gender: pet.gender,
            weight: pet.weight,
            sterilize: pet.sterilize,
            sterilize_date: pet.sterilize_date,
            blood_group: pet.blood_group,
            microchip: pet.microchip,
            insurance_number: pet.insurance_number,
            drug_allergy_detail: pet.drug_allergy_detail,
            owners: pet.owners,
            type: pet.type,
            breed: pet.breed,
            hospitals: pet.hospitals,
            hospital_note: pet.hospital_note,
            hn: this.$store.state.pet.selected.hn,
          };

          this.petWeightForm.weight = this.profile.weight;
          this.preparePetBloodGroupForm();

          const yearlyIncomes = await this.$store.dispatch("pet/getExpenseYearly", {
            params: { id: this.profile.id },
          });
          this.expenseSummaryList = yearlyIncomes.map((item) => ({
            year: item.year,
            revenue: item.amount_sum
          }));
          this.expenseOverview = await fetchExpenseOverview(this.profile.id);
          this.expenseHistory = await fetchExpenseHistory(this.profile.id, { page: 1 });

        } else {
          this.$notify({
            type: "error",
            title: "ไม่พบสัตว์เลี้ยงนี้ในโรงพยาบาลของท่าน",
          });
          this.$router.push({ name: "hospitalPets" });
        }
      }
      await this.$store.dispatch("ui/setIsFullScreenLoading", false);
    } catch (error) {
      await this.$store.dispatch("ui/showFetchErrorWithReloadMsg");
    }
  },
  watch: {
    activeName(newValue) {
      this.$router.replace({ query: { ...this.$route.query, tab: newValue } });
      if (newValue === "message") {
        this.isMessageLoaded = true;
      } else if (newValue === "appointment") {
        this.isAppointmentLoaded = true;
      } else if (newValue === "medicalrecord") {
        this.isMedicalRecordLoaded = true;
      } else if (newValue === "healthBook") {
        this.ishealthBookLoaded = true;
      } else if (newValue === "insurance") {
        this.isInsuranceLoaded = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-button {
  img {
    width: 20px;
    height: 20px;
    object-fit: contain;

    display: inline;
    vertical-align: middle;
  }

  &.side-button {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 15px;
  }

  &.action-btn {
    color: #156f88;
    border-radius: 5px;
    border: none;
    &:not(:hover) {
      background-color: #c2e5f0;
    }
  }
}

li {
  .feature {
    margin-top: -15px;
  }

  text-align: center;
}
.feature {
  margin-top: -6px;
  font-family: default;
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: center;
  color: #ff4f4a;
}

.detail {
  text-align: left;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0px;
  }

  .time {
    width: 269px;
    height: 30px;
    border-radius: 4px;
    color: white;
    background-color: #156f88;

    text-align: center;
    vertical-align: middle;
    line-height: 30px;

    span {
      &:first-child {
        float: left;
        margin-left: 8px;
      }
      &:last-child {
        float: right;
        margin-right: 8px;
      }
    }
  }

  .key {
    font-family: default;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    color: #8e8e93;
  }
  .value {
    font-family: default;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #030303;

    &.small {
      font-family: default;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.19;
      letter-spacing: normal;
      color: #354052;
    }

    &.big {
      font-family: default;
      font-size: 18px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
    }

    &.line {
      border-bottom: 1px solid #e6eaee;
    }

    .sub {
      font-size: 16px;
      font-weight: 0;
    }
  }
}
</style>

<style lang="scss">
.owner-detail {
  // .el-dropdown-menu {
  //   border: none !important;
  //   background-color: #324a5e !important;
  //   li {
  //     color: white !important;
  //     &:hover {
  //       background-color: #436480 !important;
  //     }
  //   }
  //   ul > div {
  //     display: none !important;
  //   }
  // }

  .content-padding {
    padding: 16px 12px 0px 46px;
  }
  // html {min-height:100%;}
  // body {height:100%}

  .el-tabs__item {
    font-family: default;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8e8e93;

    &.is-active {
      color: #000000;
    }
  }

  .el-tabs__active-bar {
    height: 3px;
  }

  .el-tabs__nav-wrap::after {
    background: none;
  }

  .el-card__body {
    padding: 0px;
  }

  .btn1 {
    border: solid 1px #979797 !important;
    background-color: #ffffff !important;
    vertical-align: middle;
    line-height: 24px;
    img {
      width: 24px;
      height: 24px;
      display: inline;
      vertical-align: middle;
    }
  }
  .btn2 {
    border: none;
    background-color: #c1e1e7 !important;
    vertical-align: middle;
    line-height: 24px;
    img {
      width: 24px;
      height: 24px;
      display: inline;
      vertical-align: middle;
    }
  }
  .btn3 {
    vertical-align: middle;

    img {
      width: 26px;
      height: 26px;
      object-fit: contain;
    }

    .el-button {
      // width: 160px;
      height: 50px;
      border: none;
      border-radius: 6px;
      background-color: #324a5e;

      margin-left: 10px;
      // padding-left: 10px;
      // padding-right: 10px;
      span {
        line-height: 25px;
      }

      div {
        display: inline;
        vertical-align: middle;

        &:first-child {
          float: left;
        }

        &:last-child {
          margin-left: 10px;
          float: right;
        }
      }
    }
  }

  .el-card {
    border: none;
    box-shadow: none;
  }
}
</style>

<style lang="scss">
.appointment-history-collapse {
  border-radius: 5px;
  background-color: rgba(2, 176, 225, 0.19);
  text-align: center;

  .el-collapse-item__header {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #156f88;
  }

  .appointment-history-collapse-title {
    width: 100%;
    text-align: center;
  }

  .el-collapse-item__wrap {
    background-color: #f0f2f5;
  }
}
</style>

<style lang="scss">
.el-tabs__nav-wrap.is-scrollable {
  padding: 0 32px;

  .el-tabs__nav-next,
  .el-tabs__nav-prev {
    font-size: 24px;
    line-height: 56px;
  }
}
</style>
