<script>
import ContentBox from "@/components/container/ContentBox";
import Tracker from "@/components/common/Tracker";
import DataLoading from "@/components/loading/DataLoading";

export default {
  props: {

    income: {
      type: Number,
      default: 0,
    },

    expense: {
      type: Number,
      default: 0,
    },

    deposit: {
      type: Number,
      default: 0,
    },

    overdue: {
      type: Number,
      default: 0,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  render() {
    const renderTracker = () => (
      <el-row type="flex" style={{ flexWrap: "wrap" }}>
        <el-col md={6} class={this.$style.trackerCol}>
          <Tracker
            label="รายรับ"
            value={this.income}
            unit="THB"
            format="0,0.00"
            class={this.$style.tracker}
          />
        </el-col>
        <el-col md={6} class={this.$style.trackerCol}>
          <Tracker
            label="รายจ่าย"
            value={this.expense}
            unit="THB"
            format="0,0.00"
            class={this.$style.tracker}
          />
        </el-col>
        <el-col md={6} class={this.$style.trackerCol}>
          <Tracker
            label="ยอดเงินมัดจำ"
            value={this.deposit}
            unit="THB"
            format="0,0.00"
            class={this.$style.tracker}
          />
        </el-col>
        <el-col md={6} class={this.$style.trackerCol}>
          <Tracker
            label="ยอดเงินค้างจ่าย"
            value={this.overdue}
            unit="THB"
            format="0,0.00"
            class={this.$style.tracker}
          />
        </el-col>
      </el-row>
    );

    return (
      <div style={{ position: "relative" }}>
        <h2 style={{ fontSize: "1.4rem", margin: "0em 0.3em" }}>TODAY</h2>
        <el-row>
          <el-col
            md={24}
            class={[this.$style.contentCol, this.$style.isTracker]}
          >
            {renderTracker()}
          </el-col>
        </el-row>
        {this.loading && (
          <DataLoading
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              backgroundColor: "rgba(0, 0, 0, 0.05)",
            }}
          />
        )}
      </div>
    );
  },
};
</script>

<style lang="scss" module>
.contentCol {
  & ~ & {
    margin-top: 1rem;

    @media (min-width: 992px) {
      margin-top: 0;
    }
  }

  &.isTracker {
    @media (min-width: 992px) {
      margin-left: auto;
    }
  }
}

.trackerCol {
  & ~ & {
    margin-top: 0.5rem;

    @media (min-width: 992px) {
      margin-top: 0;
    }
  }
}

.tracker {
  border-right: 1px solid #979797;
  padding: 0 0.5rem;
}
</style>
