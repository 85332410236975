<template>
  <el-dialog :visible="visible" width="500px" center @close="$emit('cancel')">
    <div class="dialog-content">
      <div class="product-name">{{ product.trade_name }}</div>
      <div class="content-data">
        <div>Date</div>
        <div class="detail">{{ item && item.datetime | toThaiDate }}</div>
      </div>
      <div class="content-data">
        <div>Time</div>
        <div class="detail">{{ item && item.datetime | toHHMM }}</div>
      </div>
      <div class="content-data">
        <div>Type</div>
        <div class="detail-type">{{ item && item.action_type_name }}</div>
      </div>
      <div class="content-data">
        <div>Amount</div>
        <div class="detail">{{ item && item.amount }}</div>
      </div>
      <div class="content-data">
        <div>สาเหตุ</div>
        <div class="detail">{{ item && item.note }}</div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="$emit('cancel')">OK</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: ["visible", "product", "item"]
};
</script>

<style lang="scss" scoped>
.dialog-content {
  font-family: Thonburi;
  display: flex;
  flex-direction: column;
  margin: -20px 20%;
  .product-name {
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    color: #02b0e1;
    margin: 10px 0;
  }
  .content-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    margin: 10px 0;
  }
  .detail, .detail-type {
    width: 55%;
    font-size: 12px;
    white-space: nowrap;
  }
  .detail-type {
    color: #000;
  }
}
</style>

