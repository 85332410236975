<template>
  <div class="pet-owner">
    <template v-if="showProfile && pet && pet.owners.length > 0">
    <div class="card-block">
        <div class="header">
          <span>Owner</span>
        </div>
        <div class="contain">
          <div v-for="owner in pet.owners" :key="owner.id" style="padding: 0px 8px; min-height: 140px">
            <div class="custom-row">
              <div class="split left" v-if="routeProfile!=''">
                <img class="profile large" :src="owner.image.large"
                style="margin-top: 18px;">
              </div>
              <div class="split left" v-else>
                <img class="profile large" :src="owner.image.large"
                style="margin-top: 18px; ">
              </div>
              <div class="split right" style="float: right">
                  <div class="detail">
                    <div class="value big">{{ `${owner.firstname} ${owner.lastname}` }}</div>
                  </div>
                  <div class="detail">
                    <div class="key">Tel</div>
                    <div class="value">{{ owner.reminder_phone }}</div>
                  </div>
                  <div class="detail">
                    <div class="key">Email</div>
                    <div class="value" style="letter-spacing: -0.5px;">{{ owner.email ? owner.email : '-' }}</div>
                  </div>
              </div>
            </div>
            <router-link :to="{ name: routeProfile, params: { id: owner.id }}" v-if="routeProfile!=''">
              <el-button type="text" style="width: 100%; text-align:center"><div class="profile-btn"><i class="el-icon-view"></i> View Profile</div></el-button>
            </router-link>
            <div v-else style="color: #fff">_</div>
            <div class="divider"></div>
          </div>
        </div>
    </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    pet: {
      type: Object
    },
    showProfile: {
      default: false,
      type: Boolean
    },
    routeProfile: {
      default: "hospitalPetOwnerProfile",
      type: String
    }
  },
}
</script>

<style lang="scss" scoped>
.divider {
  width: 100%;
  height: 1px;
  background-color: #e6eaee;
  margin-bottom: 6px;
}

.card-block {
  .header {
    padding: 12px;
    border-radius: 4px 4px 0px 0px;
    border: solid 1px #e6eaee;
    background-image: linear-gradient(to top, #f4f7fa, #ffffff);

    font-family: default;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #354052;
  }
  .contain {
    padding: 0px;
    border-radius: 0px 0px 4px 4px;
    border: solid 1px #e6eaee;
    background-color: #ffffff;
    .custom-row {
      padding: 12px 12px 0px 12px;
    }
  }
}

.detail {
  text-align: left;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0px;
  }

  .key {
    font-family: default;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    color: #8e8e93;
  }
  .value {
    font-family: default;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #030303;

    &.small {
      font-family: default;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.19;
      letter-spacing: normal;
      color: #354052;
    }

    &.big {
      font-family: default;
      font-size: 18px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
    }

    &.line {
      border-bottom: 1px solid #e6eaee;
    }

    .sub {
      font-size: 16px;
      font-weight: 0;
    }
  }
}

.profile-btn{
  font-family: default;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7f8fa4;

  text-decoration: none;

  padding: 0px;
}

img.profile {
  width: 128px;
  height: 128px;
  object-fit: cover;
  border-radius: 60px;

  position: relative;
  z-index: 1;

  &.large {
    width: 83px;
    height: 83px;
  }
}

.split {
  vertical-align: top;
  display: inline-block;
  top: 0;

  &.left {
    float: left;
    left: 0;
    width: 83px;
    // width: 95px;
  }

  &.right {
    right: 0;
    width: 180px;
  }

  .detail {
    margin-bottom: 6px;
  }
}
</style>
