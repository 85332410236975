import * as d3 from 'd3';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Chart from 'chart.js';
Chart.plugins.unregister(ChartDataLabels);


export function donutChartGenerator(idName, width, margin, data) {
  d3.select("#"+idName).select('svg').remove();

  const svg = d3.select("#"+idName)
    .append("svg")
      .attr("width", width)
      .attr("height", width)
    .append("g")
      .attr("transform", "translate(" + (width) / 2 + "," + width / 2 + ")");

  const radius = Math.min(width, width) / 2 - margin;

  const pie = d3.pie()
    .startAngle(0)
    .value(function(d) {return d.value.value; })
    .sort(null);
  const data_ready = pie(d3.entries(data));

  svg
    .selectAll('whatever')
    .data(data_ready)
    .enter()
    .append('path')
    .attr('d', d3.arc()
      .innerRadius(width * 0.3)
      .outerRadius(radius)
    )
    .attr('fill', function(d){ return(d.data.value.color) });
}

//------------------------------------------------------------------------------

export function barChartDecorationDataGenerator(data) {
  let dataGenerated = data;
  const maxNum = dataGenerated.data.reduce((max, item) => item > max ? item : max, dataGenerated.data[0]);
  dataGenerated.color = dataGenerated.data.map((item) => item === maxNum? '#02b0e1' : '#d8d8d8');
  dataGenerated.textColor = dataGenerated.data.map((item) => item === maxNum? '#02b0e1' : '#d8d8d8');
  return dataGenerated;
}

//------------------------------------------------------------------------------

export function barChartGenerator(chartId, dataGenerated, chartObject = null) {
  const ctx = document.getElementById(chartId)
  const dataSet = {
    data: dataGenerated.data,
    backgroundColor: dataGenerated.color
  }

  if (ctx != null) {
    if(chartObject) chartObject.destroy();

    chartObject = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: dataGenerated.label,
        datasets: [ dataSet ]
      },
      plugins: [ChartDataLabels],
      options: {
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'top',
            color: dataGenerated.textColor
          }
        },
        layout: {
          padding: {
            left: 50,
            right: 50,
            top: 50,
            bottom: 30
          }
        },
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
            },
            stacked: true
          }],
          xAxes: [{
            ticks: {
              fontColor: '#4a4a4a',
            },
            stacked: true,
            barPercentage: 0.4
          }]
        }
      }
    })
  }
  return chartObject;
}
