import Vue from "vue";
import ExportOPDDialog from "./ExportOPDDialog";


export const showExportOPDDialog = (propsData = {}) =>
  new Promise((resolve, reject) => {
    const Component = Vue.extend(ExportOPDDialog);

    const instance = new Component({
      propsData,

      destroyed() {
        document.body.removeChild(this.$el);
      },
    }).$mount();

    document.body.appendChild(instance.$el);

    instance.$on("action", (value) => {
      resolve(value);
    });

    instance.$on("close", () => {
      reject();
    });
  });
