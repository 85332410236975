<template>
  <div>
    <Subnavbar>
      <template slot="title">Doctor’s Fee (DF) Detail</template>
      <template slot="actions">
        <button
          class="btn btn-transparent btn-close"
          @click="$router.go(-1)"
        ><i class="fa fa-times"/></button>
      </template>
    </Subnavbar>
    <DoctorFeeDetailSearch
      :doctorfee="doctorfee"
      :startDate="searchForm.start"
      :endDate="searchForm.end"
      @search="handleSearch"
    />
    <ContentBox>
      <template v-if="items && items.results">
        <DoctorFeeDetailTable
          :items="items.results"
          :total="items.count"
          :current-page="currentPage"
          @current-change="pageChange"
          @seeDetail="handleSeeDetail"
        />
      </template>
    </ContentBox>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ContentBox from "@/components/container/ContentBox";
import Subnavbar from "@/components/navigation/Subnavbar";
import DoctorFeeDetailTable from "@/components/income-expense/DoctorFeeDetailTable";
import DoctorFeeDetailSearch from "@/components/income-expense/DoctorFeeDetailSearch";


export default {
  components: {
    ContentBox,
    Subnavbar,
    DoctorFeeDetailTable,
    DoctorFeeDetailSearch
  },
  data() {
    return {
      currentPage: 1,
      searchForm: {
        start: '',
        end: '',
      }
    };
  },
  computed: {
    ...mapState({
      doctorfee: state => state.incomeExpense.doctorfee,
      items: state => state.incomeExpense.doctorsfeeactivity
    })
  },
  methods: {
    handleSearch(form) {
      let query = {}
      this.currentPage = 1
      query['page'] = 1
      if (form.start && form.start !== '') query['start'] = form.start
      if (form.end && form.end !== '') query['end'] = form.end
  
      this.fetchDoctorFee(query)
      this.fetchDoctorsFeeActivity(query)
    },
    pageChange(page) {
      let query = this.getQueryString()
      query = { ...query, page }
      this.currentPage = page
      this.fetchDoctorsFeeActivity(query)
    },
    getQueryString() {
      let query = {}

      if (this.$route.query.page) {
        query["page"] = parseInt(this.$route.query.page)
        this.currentPage = parseInt(this.$route.query.page)
      } else {
        this.currentPage = 1
      }

      if (this.$route.query.start) {
        const start = new Date(parseInt(this.$route.query.start))
        query["start"] = start
        this.searchForm.start = start
      }

      if (this.$route.query.end) {
        const end = new Date(parseInt(this.$route.query.end))
        query["end"] = end
        this.searchForm.end = end
      }

      return query
    },
    async fetchDoctorFee (query) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        await this.$store.dispatch("incomeExpense/getDoctorsFee", {
          params: { id: this.$route.params.id },
          query: query
        })
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg")
      }
    },
    async fetchDoctorsFeeActivity (query) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        await this.$store.dispatch("incomeExpense/listDoctorsFeeActivity", {
          params: { id: this.$route.params.id },
          query: query
        })

        let urlQuery = { ...query }
        if (query.start) urlQuery['start'] = query.start.getTime()
        if (query.end) urlQuery['end'] = query.end.getTime()

        this.$router.replace({ query: urlQuery })
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg")
      }
    },
    handleSeeDetail(item) {
      this.$router.push({name: "income-expense-receipt-detail", params: { id: item.receipt_id } })
    },
  },
  async created() {
    this.fetchDoctorFee(this.getQueryString())
    this.fetchDoctorsFeeActivity(this.getQueryString());
  }
};
</script>