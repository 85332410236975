<template>
  <table class="inventory-table">
    <thead>
    <tr>
      <th class="status"></th>
      <th>Volumn (ml.)</th>
      <th>Type</th>
      <th>Pet Name</th>
      <th>Blood Bag ID</th>
      <th>PCV</th>
      <th>Blood Group</th>
      <th>Collect Date</th>
      <th>Expire Date</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr v-if="bloodBags" :key="index" v-for="(dataTable, index) in dataTable">
      <td v-if="dataTable.type === 'data'">
        <div
          class="tag tag-vertical"
          :style="`color: #fff; background-color: ${getInventoryStatusTagColor(dataTable.data.status)}`"
        >{{ getInventoryStatusTagLabel(dataTable.data.status) }}</div>
      </td>
      <td v-if="dataTable.type === 'sectionHeader'" colspan="10" style="background-color:#c7c7c7">
        {{dataTable.hospitalName}}
          <span v-if="dataTable.hospitalPhone">( ข้อมูลติดต่อ: {{dataTable.hospitalPhone}} )</span>
          <span v-if="!dataTable.hospitalPhone">( ข้อมูลติดต่อ: ไม่ระบุ )</span>

      </td>
      <td v-if="dataTable.type === 'data'">{{(Number(dataTable.data.quantity) * 10) / 10}} ml.</td>
      <td v-if="dataTable.type === 'data'">{{ dataTable.data.bloodType }}</td>
      <td v-if="dataTable.type === 'data'">{{ dataTable.data.petInfo && dataTable.data.petInfo.name ? dataTable.data.petInfo.name : '-'}}</td>
      <td v-if="dataTable.type === 'data'">{{ dataTable.data.id }}</td>
      <td v-if="dataTable.type === 'data'">{{ dataTable.data.pcv }}</td>
      <td v-if="dataTable.type === 'data'">{{ dataTable.data.bloodGroup == null ? 'ไม่ระบุ' : dataTable.data.bloodGroup }}</td>
      <td v-if="dataTable.type === 'data'">{{dataTable.data.dateCollection | toThaiDate}}</td>
      <td v-if="dataTable.type === 'data'">{{dataTable.data.expiryDate | toThaiDate}}</td>
      <td v-if="dataTable.type === 'data'">
        <el-button
          size="mini"
          type="primary"
          @click="onDetailClicked(dataTable.data.id)"
        >
          See Details
        </el-button>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
  import {mapState} from 'vuex'
  import bloodBagStatus from '../../../constants/bloodbank/blood-storage-bag-status'
  import utils from '../../../utils/bloodbank'
  import _ from 'lodash'

  export default {
    name: "BloodInventoryPublicTable",
    props: ['bloodBags'],
    data () {
      return {
        bloodBagStatus,
        hospitalList: [],
        dataTable: []
      }
    },
    methods: {
      onDetailClicked(value) {
        this.$emit('detail', value)
      },
      getInventoryStatusTagLabel: utils.getInventoryStatusTagLabel,
      getInventoryStatusTagColor: utils.getInventoryStatusTagColor,
    },
    computed: {
      ...mapState({
        hospitals: state => state.hospital.hospitals,
      }),
    },
    mounted: function() {
      this.dataTable.length = 0
      var hospitalFromBloodBagList = _.uniqBy(this.bloodBags, 'hospitalId');
      var hospitalInfoList = this.hospitals
      var hospitalList = []
      // Get hospitalInfo
      hospitalFromBloodBagList.forEach(hospital => {
        hospitalInfoList.forEach(hospitalInfo => {
          if(hospital.hospitalId === hospitalInfo.id) {
            hospitalList.push(hospitalInfo)
          }
        })
      })

      hospitalList.forEach(hospital => {
        this.dataTable.push(
          {
            type: 'sectionHeader',
            hospitalName: hospital.name,
            hospitalPhone:  hospital.phone
          }
        )
        this.bloodBags.forEach(bloodBag => {
          if(bloodBag.hospitalId === hospital.id) {
            this.dataTable.push(
              {
                type: 'data',
                data: bloodBag
              }
            )
          }
        })
      })
    },
    watch: {
      bloodBags: function (val) {
        this.dataTable.length = 0
        var hospitalFromBloodBagList = _.uniqBy(val, 'hospitalId');
        var hospitalInfoList = this.hospitals
        var hospitalList = []
        // Get hospitalInfo
        hospitalFromBloodBagList.forEach(hospital => {
          hospitalInfoList.forEach(hospitalInfo => {
            if(hospital.hospitalId === hospitalInfo.id){
              hospitalList.push(hospitalInfo)
            }
          })
        })

        hospitalList.forEach(hospital => {
          this.dataTable.push(
            {
              type: 'sectionHeader',
              hospitalName: hospital.name,
              hospitalPhone:  hospital.phone
            }
          )
          val.forEach(bloodBag => {
            if(bloodBag.hospitalId === hospital.id) {
              this.dataTable.push(
                {
                  type: 'data',
                  data: bloodBag
                }
              )
            }
          })
        })
        setTimeout(() => { this.$forceUpdate() })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .inventory-table {
    width: 100%;
    border-collapse: collapse;

    thead {
      tr {
        border: #e6eaee 1px solid;

        th {
          padding: 5px;
          font-weight: normal;
          color: #7f8fa4;
          background-color: #f5f8fa;
          font-size: 0.9em;

          &.status {
            width: 30px;
          }
        }
      }
    }

    tbody {
      tr {
        height: 82px;

        td {
          position: relative;
          padding: 5px;
          font-size: 0.9em;
          text-align: center;
        }
      }
    }
  }
</style>
