let api;

if (!api) {
  import("./apis").then((apis) => {
    api = apis.coreAPI;
  });
}

/**
 * Get pet profile
 * @param {number|string} id
 */
const get = (id) => api.get(`/pet/profiles/${id}/`);

export default { get };
