<script>
export default {
  render() {
    return (
      <section>
        <h1 style={{ marginTop: "0" }}>Finance</h1>
        {this.$slots.overview && <div>{this.$slots.overview}</div>}
        {this.$slots.default && (
          <div style={{ marginTop: "1em" }}>{this.$slots.default}</div>
        )}
      </section>
    );
  },
};
</script>
