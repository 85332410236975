import labExamTestKitOption from '@/constants/bloodbank/lab-exam-test-kit-option-enum'
import MorphologyType from '@/constants/bloodbank/morphology-type'
import store from '@/store/index'
export default class {
  constructor (obj) {
    if (obj != null) {
      this.id = obj.id == null ? null : obj.id
      this.HCT = obj.HCT == null ? '' : obj.HCT
      this.RBC = obj.RBC == null ? '' : obj.RBC
      this.NEU = obj.NEU == null ? '' : obj.NEU
      this.Mono = obj.Mono == null ? '' : obj.Mono
      this.Baso = obj.Baso == null ? '' : obj.Baso
      this.MPV = obj.MPV == null ? '' : obj.MPV
      this.MCHC = obj.MCHC == null ? '' : obj.MCHC
      this.RETIC = obj.RETIC == null ? '' : obj.RETIC
      this.MCH = obj.MCH == null ? '' : obj.MCH
      this.HgB = obj.HgB == null ? '' : obj.HgB
      this.WBC = obj.WBC == null ? '' : obj.WBC
      this.Lym = obj.Lym == null ? '' : obj.Lym
      this.Eos = obj.Eos == null ? '' : obj.Eos
      this.PLT = obj.PLT == null ? '' : obj.PLT
      this.PCT = obj.PCT == null ? '' : obj.PCT
      this.RDW = obj.RDW == null ? '' : obj.RDW
      this.MCV = obj.MCV == null ? '' : obj.MCV
      this.cbcMorphology = obj.cbcMorphology == null ? MorphologyType.MORPHOLOGY_TYPE_A : obj.cbcMorphology

      this.ALP = obj.ALP == null ? '' : obj.ALP
      this.AST = obj.AST == null ? '' : obj.AST
      this.GGT = obj.GGT == null ? '' : obj.GGT
      this.totalProtein = obj.total_Protein == null ? '' : obj.total_Protein
      this.totalBilirubin = obj.total_Bilirubin == null ? '' : obj.total_Bilirubin
      this.BUN = obj.BUN == null ? '' : obj.BUN
      this.Cholesterol = obj.Cholesterol == null ? '' : obj.Cholesterol
      this.Calcium = obj.Calcium == null ? '' : obj.Calcium
      this.TCO2_Bicarbonate = obj.TCO2_Bicarbonate == null ? '' : obj.TCO2_Bicarbonate
      this.Potessium = obj.Potessium == null ? '' : obj.Potessium
      this.Alb_Glob_Ratio = obj.Alb_Glob_Ratio == null ? '' : obj.Alb_Glob_Ratio
      this.NA_K_Ratio = obj.NA_K_Ratio == null ? '' : obj.NA_K_Ratio
      this.ALT = obj.ALT == null ? '' : obj.ALT
      this.CREATINE_KINASE = obj.CREATINE_KINASE == null ? '' : obj.CREATINE_KINASE
      this.Albumin = obj.Albumin == null ? '' : obj.Albumin
      this.Globulin = obj.Globulin == null ? '' : obj.Globulin
      this.Bilirubin_Conjugated = obj.Bilirubin_Conjugated == null ? '' : obj.Bilirubin_Conjugated
      this.CREATININE = obj.CREATININE == null ? '' : obj.CREATININE
      this.Glucose = obj.Glucose == null ? '' : obj.Glucose
      this.Phosphorus = obj.Phosphorus == null ? '' : obj.Phosphorus
      this.Chloride = obj.Chloride == null ? '' : obj.Chloride
      this.Sodium = obj.Sodium == null ? '' : obj.Sodium
      this.Bilirumin_Unconjugated = obj.Bilirumin_Unconjugated == null ? '' : obj.Bilirumin_Unconjugated

      this.ECanisEewingiiAb = obj.ECanisEewingiiAb == null ? labExamTestKitOption.UNCHECKED : this.convertTestKitToInfo(obj.ECanisEewingiiAb)
      this.LymeDisease = obj.LymeDisease == null ? labExamTestKitOption.UNCHECKED : this.convertTestKitToInfo(obj.LymeDisease)
      this.AphagocytophilumAplatyAb = obj.AphagocytophilumAplatyAb == null ? labExamTestKitOption.UNCHECKED : this.convertTestKitToInfo(obj.AphagocytophilumAplatyAb)
      this.HeartwormAg = obj.HeartwormAg == null ? labExamTestKitOption.UNCHECKED : this.convertTestKitToInfo(obj.HeartwormAg)
      this.GiardiaAg = obj.GiardiaAg == null ? labExamTestKitOption.UNCHECKED : this.convertTestKitToInfo(obj.GiardiaAg)
      this.LeptospiraAb = obj.LeptospiraAb == null ? labExamTestKitOption.UNCHECKED : this.convertTestKitToInfo(obj.LeptospiraAb)
      this.FIVAb = obj.FIVAb == null ? labExamTestKitOption.UNCHECKED : this.convertTestKitToInfo(obj.FIVAb)
      this.FeLVAg = obj.FeLVAg == null ? labExamTestKitOption.UNCHECKED : this.convertTestKitToInfo(obj.FeLVAg)
      this.FelineHeartwormAg = obj.FelineHeartwormAg == null ? labExamTestKitOption.UNCHECKED : this.convertTestKitToInfo(obj.FelineHeartwormAg)
      this.ParvovirusAg = obj.ParvovirusAg == null ? labExamTestKitOption.UNCHECKED : this.convertTestKitToInfo(obj.ParvovirusAg)
      this.PancreasSpecificLipase = obj.PancreasSpecificLipase == null ? labExamTestKitOption.UNCHECKED : this.convertTestKitToInfo(obj.PancreasSpecificLipase)
      this.NTproBNP = obj.NTproBNP == null ? labExamTestKitOption.UNCHECKED : this.convertTestKitToInfo(obj.NTproBNP)

      this.isPassed = obj.is_pass == null ? null : obj.is_pass
      this.note = obj.note == null ? '' : obj.note
      this.examiner = obj.examiner == null ? '' : obj.examiner
      this.medical_record_id = obj.object_id == null ? '' : obj.object_id
    } else {
      this.id = null
      this.HCT = ''
      this.RBC = ''
      this.NEU = ''
      this.Mono = ''
      this.Baso = ''
      this.MPV = ''
      this.MCHC = ''
      this.RETIC = ''
      this.MCH = ''
      this.HgB = ''
      this.WBC = ''
      this.Lym = ''
      this.Eos = ''
      this.PLT = ''
      this.PCT = ''
      this.RDW = ''
      this.MCV = ''
      this.cbcMorphology = MorphologyType.MORPHOLOGY_TYPE_A

      this.ALP = ''
      this.AST = ''
      this.GGT = ''
      this.totalProtein = ''
      this.totalBilirubin = ''
      this.BUN = ''
      this.Cholesterol = ''
      this.Calcium = ''
      this.TCO2_Bicarbonate = ''
      this.Potessium = ''
      this.Alb_Glob_Ratio = ''
      this.NA_K_Ratio = ''
      this.ALT = ''
      this.CREATINE_KINASE = ''
      this.Albumin = ''
      this.Globulin = ''
      this.Bilirubin_Conjugated = ''
      this.CREATININE = ''
      this.Glucose = ''
      this.Phosphorus = ''
      this.Chloride = ''
      this.Sodium = ''
      this.Bilirumin_Unconjugated = ''

      this.ECanisEewingiiAb = labExamTestKitOption.UNCHECKED
      this.LymeDisease = labExamTestKitOption.UNCHECKED
      this.AphagocytophilumAplatyAb = labExamTestKitOption.UNCHECKED
      this.HeartwormAg = labExamTestKitOption.UNCHECKED
      this.GiardiaAg = labExamTestKitOption.UNCHECKED
      this.LeptospiraAb = labExamTestKitOption.UNCHECKED
      this.FIVAb = labExamTestKitOption.UNCHECKED
      this.FeLVAg = labExamTestKitOption.UNCHECKED
      this.FelineHeartwormAg = labExamTestKitOption.UNCHECKED
      this.ParvovirusAg = labExamTestKitOption.UNCHECKED
      this.PancreasSpecificLipase = labExamTestKitOption.UNCHECKED
      this.NTproBNP = labExamTestKitOption.UNCHECKED

      this.isPassed = null
      this.note = ''
      this.examiner = ''
      this.medical_record_id = ''
    }
  }

  convertTestKitToInfo (args) {
    if (args === null) {
      return labExamTestKitOption.UNCHECKED
    } else if (args) {
      return labExamTestKitOption.POSITIVE
    } else if (!args) {
      return labExamTestKitOption.NEGATIVE
    } else {
      return labExamTestKitOption.UNCHECKED
    }
  }

  convertTestKitToJsonInfo (args) {
    if (args === labExamTestKitOption.UNCHECKED) {
      return null
    } else if (args === labExamTestKitOption.POSITIVE) {
      return true
    } else if (args === labExamTestKitOption.NEGATIVE) {
      return false
    }
  }

  toRequestJson () {
    return {
      id: this.id,
      HCT: isNaN(parseFloat(this.HCT)) || isNaN(this.HCT) ? null : this.HCT,
      RBC: isNaN(parseFloat(this.RBC)) || isNaN(this.RBC) ? null : this.RBC,
      NEU: isNaN(parseFloat(this.NEU)) || isNaN(this.NEU) ? null : this.NEU,
      Mono: isNaN(parseFloat(this.Mono)) || isNaN(this.Mono) ? null : this.Mono,
      Baso: isNaN(parseFloat(this.Baso)) || isNaN(this.Baso) ? null : this.Baso,
      MPV: isNaN(parseFloat(this.MPV)) || isNaN(this.MPV) ? null : this.MPV,
      MCHC: isNaN(parseFloat(this.MCHC)) || isNaN(this.MCHC) ? null : this.MCHC,
      RETIC: isNaN(parseFloat(this.RETIC)) || isNaN(this.RETIC) ? null : this.RETIC,
      MCH: isNaN(parseFloat(this.MCH)) || isNaN(this.MCH) ? null : this.MCH,
      HgB: isNaN(parseFloat(this.HgB)) || isNaN(this.HgB) ? null : this.HgB,
      WBC: isNaN(parseFloat(this.WBC)) || isNaN(this.WBC) ? null : this.WBC,
      Lym: isNaN(parseFloat(this.Lym)) || isNaN(this.Lym) ? null : this.Lym,
      Eos: isNaN(parseFloat(this.Eos)) || isNaN(this.Eos) ? null : this.Eos,
      PLT: isNaN(parseFloat(this.PLT)) || isNaN(this.PLT) ? null : this.PLT,
      PCT: isNaN(parseFloat(this.PCT)) || isNaN(this.PCT) ? null : this.PCT,
      RDW: isNaN(parseFloat(this.RDW)) || isNaN(this.RDW) ? null : this.RDW,
      MCV: isNaN(parseFloat(this.MCV)) || isNaN(this.MCV) ? null : this.MCV,
      Morphology: this.cbcMorphology,
      ALP: isNaN(parseFloat(this.ALP)) || isNaN(this.ALP) ? null : this.ALP,
      AST: isNaN(parseFloat(this.AST)) || isNaN(this.AST) ? null : this.AST,
      GGT: isNaN(parseFloat(this.GGT)) || isNaN(this.GGT) ? null : this.GGT,
      total_Protein: isNaN(parseFloat(this.totalProtein)) || isNaN(this.totalProtein) ? null : this.totalProtein,
      total_Bilirubin: isNaN(parseFloat(this.totalBilirubin)) || isNaN(this.totalBilirubin) ? null : this.totalBilirubin,
      BUN: isNaN(parseFloat(this.BUN)) || isNaN(this.BUN) ? null : this.BUN,
      Cholesterol: isNaN(parseFloat(this.Cholesterol)) || isNaN(this.Cholesterol) ? null : this.Cholesterol,
      Calcium: isNaN(parseFloat(this.Calcium)) || isNaN(this.Calcium) ? null : this.Calcium,
      TCO2_Bicarbonate: isNaN(parseFloat(this.TCO2_Bicarbonate)) || isNaN(this.TCO2_Bicarbonate) ? null : this.TCO2_Bicarbonate,
      Potessium: isNaN(parseFloat(this.Potessium)) || isNaN(this.Potessium) ? null : this.Potessium,
      Alb_Glob_Ratio: isNaN(parseFloat(this.Alb_Glob_Ratio)) || isNaN(this.Alb_Glob_Ratio) ? null : this.Alb_Glob_Ratio,
      NA_K_Ratio: isNaN(parseFloat(this.NA_K_Ratio)) || isNaN(this.NA_K_Ratio) ? null : this.NA_K_Ratio,
      ALT: isNaN(parseFloat(this.ALT)) || isNaN(this.ALT) ? null : this.ALT,
      CREATINE_KINASE: isNaN(parseFloat(this.CREATINE_KINASE)) || isNaN(this.CREATINE_KINASE) ? null : this.CREATINE_KINASE,
      Albumin: isNaN(parseFloat(this.Albumin)) || isNaN(this.Albumin) ? null : this.Albumin,
      Globulin: isNaN(parseFloat(this.Globulin)) || isNaN(this.Globulin) ? null : this.Globulin,
      Bilirubin_Conjugated: isNaN(parseFloat(this.Bilirubin_Conjugated)) || isNaN(this.Bilirubin_Conjugated) ? null : this.Bilirubin_Conjugated,
      CREATININE: isNaN(parseFloat(this.CREATININE)) || isNaN(this.CREATININE) ? null : this.CREATININE,
      Glucose: isNaN(parseFloat(this.Glucose)) || isNaN(this.Glucose) ? null : this.Glucose,
      Phosphorus: isNaN(parseFloat(this.Phosphorus)) || isNaN(this.Phosphorus) ? null : this.Phosphorus,
      Chloride: isNaN(parseFloat(this.Chloride)) || isNaN(this.Chloride) ? null : this.Chloride,
      Sodium: isNaN(parseFloat(this.Sodium)) || isNaN(this.Sodium) ? null : this.Sodium,
      Bilirumin_Unconjugated: isNaN(parseFloat(this.Bilirumin_Unconjugated)) || isNaN(this.Bilirumin_Unconjugated) ? null :  this.Bilirumin_Unconjugated,

      ECanisEewingiiAb: this.convertTestKitToJsonInfo(this.ECanisEewingiiAb),
      LymeDisease: this.convertTestKitToJsonInfo(this.LymeDisease),
      AphagocytophilumAplatyAb: this.convertTestKitToJsonInfo(this.AphagocytophilumAplatyAb),
      HeartwormAg: this.convertTestKitToJsonInfo(this.HeartwormAg),
      GiardiaAg: this.convertTestKitToJsonInfo(this.GiardiaAg),
      LeptospiraAb: this.convertTestKitToJsonInfo(this.LeptospiraAb),
      FIVAb: this.convertTestKitToJsonInfo(this.FIVAb),
      FeLVAg: this.convertTestKitToJsonInfo(this.FeLVAg),
      FelineHeartwormAg: this.convertTestKitToJsonInfo(this.FelineHeartwormAg),
      ParvovirusAg: this.convertTestKitToJsonInfo(this.ParvovirusAg),
      PancreasSpecificLipase: this.convertTestKitToJsonInfo(this.PancreasSpecificLipase),
      NTproBNP: this.convertTestKitToJsonInfo(this.NTproBNP),

      is_pass: this.isPassed,
      note: this.note,
      examiner: this.examiner,
      object_id: this.medical_record_id,
      hospital: store.state.hospital.profile.id
    }
  }
}
