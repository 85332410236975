<script>
export default {
  inject: { doCommand: "command" },

  props: {
    command: {
      type: undefined,
      default: undefined,
    },
  },

  methods: {
    handleClick() {
      this.doCommand(this.command);
    },
  },

  render() {
    return (
      <el-button
        type="info"
        style={{
          display: "block",
          width: "100%",
          height: "100%",
          padding: "0.5em",
        }}
        onClick={this.handleClick}
      >
        {this.$slots.icon && (
          <span style={{ marginRight: "0.5em", verticalAlign: "middle" }}>
            {this.$slots.icon}
          </span>
        )}
        {this.$slots.default}
      </el-button>
    );
  },
};
</script>
