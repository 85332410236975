<template>
  <div>
    <Subnavbar>
      <template slot="title">REPORT Visit Frequency And Expense Rate </template>
    </Subnavbar>

    <div class="content-container">
        <div class="section-title">Visit Frequency And Expense Rate</div>
        <ExpenceVisitOverview 
          v-if="visitOverview" 
          :chartData="getVisitOverviewData()" 
          :options="getVisitOverviewOptions()"
          customStyles="height: 174px;position: relative;" />
    </div>

    <div class="content-container">
      <div class="date-filter-form">
          <div class="title">Date for</div>
          <!-- <div class="date-filter">
              from&nbsp;&nbsp;
              <el-date-picker
                type="date"
                placeholder="Anyday"
                size="small"
                v-model="startDate"
                :clearable='false'
                style="margin-bottom: 5px; width: 200px;"
                format="dd/MM/yyyy"
              ></el-date-picker>
              &nbsp;&nbsp;to&nbsp;&nbsp;
              <el-date-picker
                type="date"
                placeholder="Anyday"
                size="small"
                v-model="endDate"
                :clearable='false'
                style="margin-bottom: 5px; width: 200px;"
                format="dd/MM/yyyy"
              ></el-date-picker>
        </div> -->
        <div class="date-filter">
          from&nbsp;&nbsp;
          <el-date-picker
            v-model="datefilter"
            type="daterange"
            :editable="false"
            :clearable="false"
            range-separator="To"
            unlink-panels
            start-placeholder="Start date"
            end-placeholder="End date"
            style="width: 450px;">
          </el-date-picker>
        </div>
      </div>

      <Panel enName="PET" thName="สัตว์เลี้ยง" :customStyle="getPanelStyle()">
        <template slot="viewFullLink">
          <p @click="$router.push({ 
            name: 'expence-visit-report-detail', 
            params: { type: 'pet' },
            query: {start: datefilter[0].toString(), end: datefilter[1].toString()} })">See more > </p>
        </template>
      </Panel>
      <el-row class="graph-container">
        <el-col class="graph" :span="12">
          <ExpenceVisitChart
            v-if="petVisitFrequency"
            title="Visit Frequency Rate"
            subtitle="ความถี่ในการเข้ามารับบริการของสัตว์เลี้ยง (ตัว)"
            :chartData="generateChartData('expence', petVisitFrequency)"
            :options="generateChartOptions('pet')"
            :customStyles="{height: '210px', position: 'relative'}" />
        </el-col>
        <el-col class="graph" :span="12">
          <ExpenceVisitChart
            v-if="petExpenceRate"
            title="Expense Rate"
            subtitle="การใช้จ่ายของสัตว์เลี้ยง (ตัว)"
            :chartData="generateChartData('frequency', petExpenceRate)"
            :options="generateChartOptions('pet')"
            :customStyles="{height: '210px', position: 'relative'}" />
        </el-col>
      </el-row>

      <Panel enName="PET OWNER" thName="เจ้าของสัตว์เลี้ยง" :customStyle="getPanelStyle()">
        <template slot="viewFullLink">
          <p @click="$router.push({ 
            name: 'expence-visit-report-detail',
            params: { type: 'owner' },
            query: {start: datefilter[0].toString(), end: datefilter[1].toString()} })">See more > </p>
        </template>
      </Panel>
      <el-row class="graph-container">
        <el-col class="graph" :span="12">
          <ExpenceVisitChart
            v-if="ownerVisitFrequency"
            title="Visit Frequency Rate"
            subtitle="ความถี่ในการเข้ามารับบริการของเจ้าของสัตว์เลี้ยง (คน)"
            :chartData="generateChartData('expence', ownerVisitFrequency)"
            :options="generateChartOptions('owner', ownerVisitFrequency)"
            :customStyles="{height: '210px', position: 'relative'}" />
        </el-col>
        <el-col class="graph" :span="12">
          <ExpenceVisitChart
            v-if="ownerExpenceRate"
            title="Expense Rate"
            subtitle="การใช้จ่ายของเจ้าของสัตว์เลี้ยง (คน)"
            :chartData="generateChartData('frequency', ownerExpenceRate)"
            :options="generateChartOptions('owner', ownerExpenceRate)"
            :customStyles="{height: '210px', position: 'relative'}" />
        </el-col>
      </el-row>

      <div style="margin-bottom: 120px;"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import Subnavbar from "@/components/navigation/Subnavbar"
import ContentBox from "@/components/container/ContentBox"
import Panel from "@/components/report/Panel"
import ExpenceVisitOverview from "@/components/report/ExpenceVisitOverview"
import ExpenceVisitChart from "@/components/report/ExpenceVisitChart"
import { startToday, endToday } from '@/utils/datetime'

export default {
  components: {
    ContentBox,
    Subnavbar,
    Panel,
    ExpenceVisitOverview,
    ExpenceVisitChart
  },
  data() {
    return {
      datefilter: [
        startToday(),
        endToday()
      ]
    }
  },
  computed: {
    ...mapState({
      visitOverview: state => state.report.visitoverview,
      petVisitFrequency: state => state.report.petvisitfrequency,
      petExpenceRate: state => state.report.petexpencerate,
      ownerVisitFrequency: state => state.report.ownervisitfrequency,
      ownerExpenceRate: state => state.report.ownerexpencerate
      
    }),
    
  },
  methods: {
    async fetchData() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        var query = {
          start: this.datefilter[0],
          end: this.datefilter[1],
        }

        await this.$store.dispatch("report/getExpenceVisitOverview", {
          query: {'is_last_18_month': true}
        })
        await this.$store.dispatch("report/getPetVisitFrequency", {
          query
        })
        await this.$store.dispatch("report/getPetExpenceRate", {
          query
        })
        await this.$store.dispatch("report/getOwnerVisitFrequency", {
          query
        })
        await this.$store.dispatch("report/getOwnerExpenceRate", {
          query
        })

        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        await this.$store.dispatch("ui/showFetchErrorMsg", error.detail)
      }

    },
    getPanelStyle() {
      return {
        'border-top': 'none',
        'margin': '0 -15px 0 -15px'
      }
    },
    getVisitOverviewData() {
      return {
        datasets: [{
            labels: ['Active', 'In-Active'],
            data: [this.visitOverview.pet_active_total, this.visitOverview.pet_inactive_total],
            backgroundColor: ['#33c9f3', '#d8d8d8'],
        }],
        labels: [
            'Active          ' + this.visitOverview.pet_active_total + ' ตัว',
            'In-Active      ' + this.visitOverview.pet_inactive_total + ' ตัว'
        ]
      }
    },
    getVisitOverviewOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 70,
        legend: {
          position: 'right',
          labels: {
            fontSize: 14,
            fontColor: '#000',
            usePointStyle: true,
          }
        },
        tooltips: {
          mode: 'label',
          callbacks: {
            label: function(tooltipItem, data) {
              var label = data.datasets[tooltipItem.datasetIndex].labels[tooltipItem.index];
              var dataset = data.datasets[tooltipItem.datasetIndex];
              
              var total = dataset.data.reduce(function(previousValue, currentValue) {
                return previousValue + currentValue;
              });
              
              var currentValue = dataset.data[tooltipItem.index];
              var percentage = Math.floor(((currentValue/total) * 100)+0.5);

              return label + ' ' + percentage + "%";
            }
          }
        }
      }
    },
    generateChartData(childKey, datas) {
      var color = (childKey == 'frequency') ? ['#204412', '#469c26', '#61db33', '#bfeaaf', '#d8d8d8'] : ['#0b4b5d', '#1686a4', '#33c9f3', '#a1e7fb', '#d8d8d8']
      var result = {
        labels: [],
        datasets: []
      };

      datas.forEach(function(data) {
        result['labels'].push(data.label);
        
        data[childKey].forEach(function(child, i) {
          if (result['datasets'][i] == null) {
            let space = (22 - child.label.length) > 0 ? 22 - child.label.length: 0;
            let label = child.label + '\xa0'.repeat(space);

            result['datasets'].push({
              // label: child.label,
              label: label,
              barPercentage: 0.5,
              data: [],
              backgroundColor: color[i]
            })
          }
          result['datasets'][i]['data'].push(child.total)
        })
      });

      return result;
    },
    generateChartOptions(primaryObjName) {
      var label_suffix = (primaryObjName == 'owner') ? 'คน' : 'ตัว';
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            ticks: {
              display: false
            },
            gridLines: {
              display: false
            }
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              display: false
            }
          }]
        },
        legend: {
          position: 'bottom',
          align: 'start',
          labels: {
            fontSize: 11,
            fontColor: '#4a4a4a',
            usePointStyle: true
          }
        },
        tooltips: {
          mode: 'label',
          titleFontSize: 10,
          bodyFontSize: 10,
          callbacks: {
            label: function (tooltipItem, data) {
                var label = data.datasets[tooltipItem.datasetIndex].label;
                var valor = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

                var total = 0;
                for (var i = 0; i < data.datasets.length; i++)
                    total += data.datasets[i].data[tooltipItem.index];

                if (tooltipItem.datasetIndex != data.datasets.length - 1) {
                    return label + '  ' + valor + ' ' + label_suffix;
                } else {
                    return [label + '  ' + valor + ' ' + label_suffix, 'รวมทั้งหมด  ' + total + ' ' + label_suffix];
                }
            }
          }
        }
      }
    }
  },
  async created() {
    if (this.$route.query.start && this.$route.query.end) {
      this.datefilter[0] = new Date(this.$route.query.start);
      this.datefilter[1] = new Date(this.$route.query.end);
    }
    this.fetchData();
  },
  watch: {
    'datefilter': function () {
      this.fetchData();
      
      this.$router.replace({ query: {} });
      let query = { start: this.datefilter[0].toString(), end: this.datefilter[1].toString() };
      this.$router.push({
        query: query 
      });
    }
  },
}
</script>

<style lang="scss" scoped>
.content-container {
  padding: 15px;
  margin-bottom: 4px;
  background-color: #fff;

  .section-title {
    font-size: 20px;
    color: #000;
    font-weight: normal;
    font-family: "Roboto","Lucida Grande","DejaVu Sans","Bitstream Vera Sans",Verdana,Arial,sans-serif;
    margin-bottom: 36px;
  }
}
.date-filter-form {
  margin-bottom: 16px;
  font-family: "Roboto","Lucida Grande","DejaVu Sans","Bitstream Vera Sans",Verdana,Arial,sans-serif;

  .title {
    font-size: 20px;
    margin-right: 34px;
    display: inline-block;
  }
  .date-filter {
    font-size: 14px;
    color: #354052;
    display: inline-block;
  }
}
.graph-container {
  margin-bottom: 30px;

  .graph {
    padding-right: 60px;
  }
}
</style>
