<template>
  <div class="broadcast-detail-page">
    <Subnavbar>
      <template slot="title">Broadcast Detail</template>
      <template slot="actions">
        <button
                @click="showConfirmDeleteDialog = true"
                class="btn btn-large btn-delete">
          <i class="fa fa-trash"></i>
          Delete
        </button>
         <button
            class="btn btn-transparent btn-close"
            @click="$router.replace({ name: 'hospitalBroadcast' })"
          >
            <i class="fa fa-times"/>
          </button>
      </template>
    </Subnavbar>

    <ContentBox v-if="detail">
      <el-row style="border-bottom: 1px solid #ccc; padding-bottom: 20px;">
        <el-col :span="4">
          <label>Date Sent</label>
        </el-col>
        <el-col :span="20">
          {{ detail.created | toThaiDate }} {{ detail.created | toHHMM }}
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4">
          &nbsp;
        </el-col>
        <el-col :span="20">
          <div class="cover-image-container">
            <template v-if="detail.cover_img">
              <img :src="detail.cover_img" class="cover-image">
            </template>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4">
          <label>Topic</label>
        </el-col>
        <el-col :span="20">
          <div class="text-box">
          {{detail.title}}
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4">
          <label>Content</label>
        </el-col>
        <el-col :span="20">
          <div class="text-box">
          {{detail.content}}
          </div>
        </el-col>
      </el-row>
      <el-row style="border-bottom: 1px solid #ccc; padding-bottom: 20px;">
        <el-col :span="4">
          <label>Attachment</label>
        </el-col>
        <el-col :span="20">
          <div class="clearfix">
            <AttachmentPreviewer
                v-for="(url, index) in detail.attachments_uri"
                :key="index"
                style="float: left; width: 110px; height: 110px; margin: 4px;"
                :url="url"
            />
          </div>
        </el-col>
      </el-row>
      <div class="audience-section">
        <h2 class="title">Audience</h2>
        <el-row>
          <el-col :span="24">
            <div class="subtitle"><label>Target</label></div>
          </el-col>
          <el-col :span="24">
            <div class="pet-target"><label>Pet Type</label>{{ detail.pet_type && detail.pet_type.name ? detail.pet_type.name : 'ไม่ระบุ'}}</div>
          </el-col>
          <el-col :span="24">
            <div class="pet-target"><label>Breed</label>{{ detail.pet_breed && detail.pet_breed.name ? detail.pet_breed.name : 'ไม่ระบุ' }}</div>
          </el-col>
          <el-col :span="24">
            <div class="pet-target"><label>Sex</label>{{ detail.pet_sex_id | petGenderTh }}</div>
          </el-col>
          <el-col :span="24">
            <div class="pet-target"><label>Age (min)</label>{{ detail.pet_age_min ? `${detail.pet_age_min} ปี` : 'ไม่ระบุ'}}</div>
          </el-col>
          <el-col :span="24">
            <div class="pet-target"><label>Age (max)</label>{{ detail.pet_age_max ? `${detail.pet_age_max} ปี` : 'ไม่ระบุ'}}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="subtitle"><label>จำนวนสัตว์เลี้ยงผู้รับข้อความ</label></div>
          </el-col>
          <el-col :span="24">
            <div>{{ detail.pet_count | number }} ตัว</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="subtitle"><label>จำนวนเจ้าของสัตว์เลี้ยงผู้รับข้อความ</label></div>
          </el-col>
          <el-col :span="24">
            <div>{{ detail.limit_owner_number | number }} คน</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
          <div class="subtitle"><label>เลือกช่องทางการส่ง</label></div>
          </el-col>
          <div v-if="detail.is_send_fb_messenger">Facebook</div>
          <div v-if="detail.is_send_line">Line</div>
          <div v-if="detail.is_send_email">Email</div>
          <div v-if="detail.is_send_webpush">Web push</div>
          <div v-if="detail.is_send_sms">SMS</div>
          <div v-else>-</div>
        </el-row>
      </div>
    </ContentBox>
    <ConfirmDialog
            :visible="showConfirmDeleteDialog"
            @cancel="showConfirmDeleteDialog = false"
            @confirm="deleteBroadCastDetail"
    >
      <template slot="header">
        <h1 class="title">ยืนยันการลบข้อความนี้</h1>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import { mapState } from "vuex"
import Subnavbar from "@/components/navigation/Subnavbar";
import ContentBox from "@/components/container/ContentBox";
import ConfirmDialog from "@/components/dialog/ConfirmDialog"
import AttachmentPreviewer from "@/components/upload/AttachmentPreviewer"

export default {
  components: {
    ContentBox,
    Subnavbar,
    ConfirmDialog,
    AttachmentPreviewer
  },
  data() {
    return {
      isLoading: false,
      showConfirmDeleteDialog: false
    };
  },
  computed: {
    ...mapState({
      detail: state => state.broadcast.detail
    })
  },
  methods: {
    async fetchBroadcastDetail () {
      try {
        const id = this.$route.params.id
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        await this.$store.dispatch("broadcast/getBroadcast", { 
          params: { id }
        })
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        await this.$store.dispatch("ui/showFetchErrorMsg")
      }
    },
    async deleteBroadCastDetail () {
      try {
        const id = this.$route.params.id
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        await this.$store.dispatch("broadcast/deleteBroadcast", {
          params: { id }
        })
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        this.$notify({
          message: "ลบข้อความเรียบร้อยแล้ว",
          type: "success"
        });
        this.$router.push({ name: 'hospitalBroadcast' })
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        await this.$store.dispatch("ui/showFetchErrorMsg")
      }
    }
  },
  mounted () {
    this.fetchBroadcastDetail()
  },
};
</script>

<style lang="scss" scoped>
  .broadcast-detail-page {
    .el-row {
      margin: 20px 0;
    }
    label {
      color: $light-blue;
    }

    .subtitle {
      font-size: 1em;
      margin-top: 10px;
    }

    .cover-image-container {
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      width: 256px;
      height: 144px;
      background-color: #f5f8fa;

      .cover-image {
        height: 144px;
      }
    }

    .text-box {
      padding: 5px 10px;
      background-color: #f5f8fa;
    }

    .pet-target {
      margin: 3px 0;
      > label {
        display: inline-block;
        width: 150px;
        color: #333;
      }
    }
    .audience-section {
      >.title {
        font-size: 1.3em;
        margin: 0;
        padding: 0;
        text-align: left;
      }
      >.row {
        margin: 0 0 15px 0;
      }
    }
  }
</style>
