<template>
  <div class="report_container">
    <div class="inner_report_container">
      <div class="chart_pane">
        <div>
          <h1 style="width: 100%; text-align: center; font-size: 24px;">Dog</h1>
          <div id="report_bloodbank_overview_dog_chart"/>
          <div class="inner_circle">
            <div class="inner_circle_top">
              Total
            </div>
            <div class="inner_circle_middle">
              {{data.dog.total}}
            </div>
            <div class="inner_circle_bottom">
              Units
            </div>
          </div>
        </div>
      </div>
      <div class="table_pane">
        <table class="table">
          <tr>
            <th class="table_header">
              <div class="colorBlock" :style="{backgroundColor: color.use}"></div>
              use
            </th>
            <td>{{data.dog.use}} units</td>
          </tr>
          <tr>
            <th class="table_header">
              <div class="colorBlock" :style="{backgroundColor: color.transfer}"></div>
              transfer
            </th>
            <td>{{data.dog.transfer}} units</td>
          </tr>
          <tr>
            <th class="table_header">
              <div class="colorBlock" :style="{backgroundColor: color.expired}"></div>
              Expired
            </th>
            <td>{{data.dog.expired}} units</td>
          </tr>
        </table>
      </div>
    </div>

    <div class="inner_report_container">
      <div class="chart_pane">
        <div>
          <h1 style="width: 100%; text-align: center; font-size: 24px;">Cat</h1>
          <div id="report_bloodbank_overview_cat_chart"/>
          <div class="inner_circle">
            <div class="inner_circle_top">
              Total
            </div>
            <div class="inner_circle_middle">
              {{data.cat.total}}
            </div>
            <div class="inner_circle_bottom">
              Units
            </div>
          </div>
        </div>
      </div>
      <div class="table_pane">
        <table class="table">
          <tr>
            <th class="table_header">
              <div class="colorBlock" :style="{backgroundColor: color.use}"></div>
              use
            </th>
            <td>{{data.cat.use}} units</td>
          </tr>
          <tr>
            <th class="table_header">
              <div class="colorBlock" :style="{backgroundColor: color.transfer}"></div>
              transfer
            </th>
            <td>{{data.cat.transfer}} units</td>
          </tr>
          <tr>
            <th class="table_header">
              <div class="colorBlock" :style="{backgroundColor: color.expired}"></div>
              Expired
            </th>
            <td>{{data.cat.expired}} units</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { donutChartGenerator } from '../../utils/report';

export default {
  name: "BloodbankOverviewSummaryChart",
  props: ['bloodbank'],
  data: function () {
    return {
      color: {
        use: "#d0025b",
        transfer: "#02b0e1",
        expired: "#9b9b9b",
      }
    }
  },
  computed: {
    data: function() {
      let data = {
        dog: {
          total: 0,
          use: 0,
          transfer: 0,
          expired: 0,
        },
        cat: {
          total: 0,
          use: 0,
          transfer: 0,
          expired: 0,
        },
      }
      if(this.bloodbank && this.bloodbank.overview ) {
        data.dog.total = this.bloodbank.overview.dog.total;
        data.dog.use = this.bloodbank.overview.dog.used;
        data.dog.transfer = this.bloodbank.overview.dog.transfer;
        data.dog.expired = this.bloodbank.overview.dog.expired;

        data.cat.total = this.bloodbank.overview.cat.total;
        data.cat.use = this.bloodbank.overview.cat.used;
        data.cat.transfer = this.bloodbank.overview.cat.transfer;
        data.cat.expired = this.bloodbank.overview.cat.expired;
      }
      return data;
    },
  },
  methods: {
    generateChart: function () {
      const dogData = [
        { value: this.data.dog.use, color: this.color.use },
        { value: this.data.dog.transfer, color: this.color.transfer },
        { value: this.data.dog.expired, color: this.color.expired },
      ]
      donutChartGenerator('report_bloodbank_overview_dog_chart', 180, 10, dogData);
      const catData = [
        { value: this.data.cat.use, color: this.color.use },
        { value: this.data.cat.transfer, color: this.color.transfer },
        { value: this.data.cat.expired, color: this.color.expired },
      ]
      donutChartGenerator('report_bloodbank_overview_cat_chart', 180, 10, catData);
    },
  },
  watch: {
    'bloodbank.overview': function () {
      this.generateChart();
    },
  },
  mounted() {
    this.generateChart()
  },
}
</script>

<style lang="scss" scoped>
.report_container {
  display: flex;
  flex-direction: row;
  width: auto;
  padding-top: 20px;
}

.inner_report_container {
  display: flex;
  flex-direction: row;
  flex: 0.5;
  justify-content: space-around;
}

.chart_pane {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 280px;
  padding-top: 10px;
}

.table_pane {
  flex: 1;
  display: flex;  
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 40px;
  padding-top: 40px;
}

.inner_circle {
  position: relative;
  text-align: center;
  top: -130px;
  .inner_circle_top {
    font-size: 14px;
  }
  .inner_circle_middle {
    font-size: 34px;
    color: #02b0e1;
    position: relative;
    top: -10px;
  }
  .inner_circle_bottom {
    font-size: 14px;
    position: relative;
    top: -20px;
  }
}

.table {
  width: 100%;
  th {
    text-align: left;
  }
  td {
    text-align: right;
  }
}

.table_header {
  display: flex;
  flex-direction: row;
}

.colorBlock {
  width: 20px;
  height: 20px;
  border-width: 1px;
  border-color: #979797;
  border-style: solid;
  margin-right: 10px;
}
</style>
