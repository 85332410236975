<script>
export default {
  props: {
    backgroundColor: {
      type: String,
      default: undefined,
    },

    color: {
      type: String,
      default: undefined,
    },
  },

  render() {
    return (
      <div
        style={{
          display: "inline-block",
          textAlign: "center",
          padding: "0.25em 0.5em",
          backgroundColor: this.backgroundColor,
          color: this.color,
        }}
      >
        {this.$slots.default}
      </div>
    );
  },
};
</script>
