<template>
  <ContentBox class="medical-search">
    <div class="title">Search</div>
    <div class="sub-title">• Date Visit</div>
    <el-row class="row">
      <el-col :md="12">
        <div class="date-group">
          <el-date-picker type="date" placeholder="Start Date" size="small" v-model="form.start" @change="startDateChange"></el-date-picker>
          <span>to</span>
          <el-date-picker type="date" placeholder="End Date" size="small" v-model="form.end" @change="endDateChange"></el-date-picker>
        </div>
      </el-col>
    </el-row>
    <el-row class="row">
      <el-col :md="10">
        <el-input placeholder="Pet Name, H.N., Owner, Medical Record ID , Tel" size="small"
          v-model="form.search"
          clearable
          @keyup.enter.native="onSearch"
          @clear="onClearSearch"
        >
          <el-button slot="append" icon="el-icon-search" @click="onSearch"></el-button>
        </el-input>
      </el-col>
      <span style="border-left: 1px solid #ccc; margin: 0 10px;"></span>
      <el-col :md="5">
        <el-select
          v-model="form.vetId"
          clearable
          placeholder="Veterinarian"
          size="small"
          @change="onSearch"
          @clear="onClearVetId"
        >
          <el-option
            v-for="vet in vets"
            :key="vet.id"
            :value="vet.id"
            :label="`${vet.type}: ${vet.first_name} ${vet.last_name}`"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :md="5">
        <el-select placeholder="Status"
          size="small"
          clearable
          v-model="form.status"
          @change="onSearch"
          @clear="onClearStatus" >
          <el-option :value="''" label="All"></el-option>
          <el-option :value="medicalStatus.IN_TREATMENT" label="In Treatment"></el-option>
          <el-option :value="medicalStatus.PAYMENT" label="Payment"></el-option>
          <el-option :value="medicalStatus.DONE" label="Done"></el-option>
        </el-select>
      </el-col>
      <el-col :md="2" v-if="form.status !== medicalStatus.DONE">
        <el-checkbox 
          v-model="form.isBadDebt"
          label="หนี้สูญ"
          border
          size="small"
          :false-label="undefined"
          :style="{ marginRight: '0' }"
          @change="onSearch"
        ></el-checkbox>
      </el-col>
    </el-row>
  </ContentBox>
</template>

<script>
import ContentBox from "@/components/container/ContentBox"
import { medicalStatus } from '../../constants'
// import { getMedicalIDFormUrl } from '../../utils'

export default {
  props: ['search', 'startDate', 'endDate', 'status',
  'vets', 'selectedVetId', 'isBadDebt'],
  components: {
    ContentBox
  },
  data() {
    return {
      medicalStatus: medicalStatus,
      form: {
        search: this.search,
        start: this.startDate,
        end: this.endDate,
        status: this.status,
        vetId: this.selectedVetId,
        isBadDebt: this.isBadDebt,
      }
    }
  },
  methods: {
    startDateChange () {
      this.$emit('search', this.form)
    },
    endDateChange (d) {
      if(d) {
        d.setHours(23,59,59,999)
        this.form.end = new Date(d)
      }
      this.$emit('search', this.form)
    },
    onSearch () {
      if (this.form.status === medicalStatus.DONE) {
        this.form.isBadDebt = undefined
      }
      
      this.$emit('search', this.form)
    },
    onClearSearch () {
      this.form.search = ''
      this.$emit('search', this.form)
    },
    onClearStatus () {
      this.form.status = ''
      this.$emit('search', this.form)
    },
    onClearVetId () {
      this.form.vetId = ''
      this.$emit('search', this.form)
    }
  },
  watch: {
    // 'form.search': function(value) {
    //   const result = getMedicalIDFormUrl(value)
    //   if(result !== '') {
    //     this.form.search = result
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped>
.medical-search {
  .title {
    margin: 0;
    padding: 0;
    font-size: 16px;
    margin-bottom: 5px;
  }
  .sub-title {
    margin: 0;
    padding: 0;
    font-size: 12px;
    margin-bottom: 5px;
  }

  .row {
    margin-bottom: 10px;
  }

  .date-group {
    > span {
      padding: 10px;
    }
  }
}
</style>


