import IncomeExpenseList from '@/pages/incomeExpense/IncomeExpenseList.vue'
import EditIncomeExpense from '@/pages/incomeExpense/EditIncomeExpense.vue'
import IncomeExpenseDetail from '@/pages/incomeExpense/IncomeExpenseDetail.vue'
import DoctorFeeList from '@/pages/incomeExpense/DoctorFeeList.vue'
import DoctorFeeDetail from '@/pages/incomeExpense/DoctorFeeDetail.vue'
const Summary = () => import('@/pages/incomeExpense/Summary')
const SummaryDetail = () => import('@/pages/incomeExpense/SummaryDetail')
const Overdue = () => import('@/pages/incomeExpense/Overdue')
const Receipt = () => import('@/pages/incomeExpense/Receipt')
const ReceiptDetail = () => import('@/pages/incomeExpense/ReceiptDetail')
const ReceiptCreate = () => import('@/pages/incomeExpense/ReceiptCreate')
const ReceiptPayment = () => import('@/pages/incomeExpense/ReceiptPayment')
const Shift = () => import('@/pages/incomeExpense/Shift')
const ShiftOpen = () => import('@/pages/incomeExpense/ShiftOpen')
const ShiftHistory = () => import('@/pages/incomeExpense/ShiftHistory')
const ShiftHistoryDetail = () => import('@/pages/incomeExpense/ShiftHistoryDetail')
const AddIncomeExpense = () => import('@/pages/incomeExpense/AddIncomeExpense')
const AddIncomeExpenseDetail = () => import('@/pages/incomeExpense/AddIncomeExpenseDetail')

const routes = [
  {
    path: '/income-expense',
    name: 'income-expense-list',
    component: IncomeExpenseList,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
			plan_permissions: ['income_expense_section.can_view_income_expense_transaction']
    }
  },
  {
    path: '/income-expense/summary',
    name: 'income-expense-summary',
    component: Summary,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
			plan_permissions: ['income_expense_section.can_view_income_expense_summary']
    }
  },
  {
    path: '/income-expense/summary/detail',
    name: 'income-expense-summary-detail',
    component: SummaryDetail,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
			plan_permissions: ['income_expense_section.can_view_income_expense_summary']
    }
  },
  {
    path: '/income-expense/overdue',
    name: 'income-expense-overdue',
    component: Overdue,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
			plan_permissions: ['income_expense_section.can_view_overdue_list']
    }
  },
  {
    path: '/income-expense/receipt',
    name: 'income-expense-receipt',
    component: Receipt,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
			plan_permissions: ['income_expense_section.can_view_receipt_list']
    }
  },
  {
    path: '/income-expense/receipt/create',
    name: 'income-expense-receipt-create',
    component: ReceiptCreate,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
			plan_permissions: ['income_expense_section.can_view_receipt_create']
    }
  },
  {
    path: '/income-expense/receipt/create/payment',
    name: 'income-expense-receipt-create-payment',
    component: ReceiptPayment,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
			// plan_permissions: ['income_expense_section.can_add_income_expense']
    }
  },
  {
    path: '/income-expense/receipt/:id',
    name: 'income-expense-receipt-detail',
    component: ReceiptDetail,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
			// plan_permissions: ['income_expense_section.can_add_income_expense']
    }
  },
  {
    path: '/income-expense/:id',
    name: 'income-expense-detail',
    component: IncomeExpenseDetail,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
			plan_permissions: ['income_expense_section.can_view_income_expense']
    }
  },
  {
    path: '/income-expense/:id/edit',
    name: 'income-expense-edit',
    component: EditIncomeExpense,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
			plan_permissions: ['income_expense_section.can_view_income_expense']
    }
  },
  {
    path: '/df',
    name: 'df-list',
    component: DoctorFeeList,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
			plan_permissions: ['income_expense_section.can_view_doctor_fee']
    }
  },
  {
    path: '/df/:id',
    name: 'df-detail',
    component: DoctorFeeDetail,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
			plan_permissions: ['income_expense_section.can_view_doctor_fee']
    }
  },
  {
    path: "shift",
    name: "shift",
    component: Shift,
    meta: {
      requiresAuth: true,
      permissions: ["hospital_admin"],
    },
    redirect: { name: "shift-open" },
    children: [
      {
        path: "open",
        name: "shift-open",
        component: ShiftOpen,
        meta: {
          requiresAuth: true,
          permissions: ["hospital_admin"],
          plan_permissions: ['income_expense_section.can_open_close_shift']
        },
      },
      {
        path: "history",
        name: "shift-history",
        component: ShiftHistory,
        meta: {
          requiresAuth: true,
          permissions: ["hospital_admin"],
          plan_permissions: ['income_expense_section.can_view_shift']
        },
      },
    ],
  },
  {
    path: 'shift/history/detail/:id',
    name: 'shift-history-detail',
    component: ShiftHistoryDetail,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['income_expense_section.can_view_shift']
    }
  },
  {
    path: '/income-expense/income/add',
    name: 'income-expense-income-add',
    component: AddIncomeExpense,
    meta: {
      requiresAuth: true,
      isIncome: true,
      isExpense: false,
      permissions: ['hospital_admin'],
			plan_permissions: ['income_expense_section.can_add_income_expense']
    }
  },
  {
    path: '/income-expense/expense/add',
    name: 'income-expense-expense-add',
    component: AddIncomeExpense,
    meta: {
      requiresAuth: true,
      isIncome: false,
      isExpense: true,
      permissions: ['hospital_admin'],
			plan_permissions: ['income_expense_section.can_add_income_expense']
    }
  },
  {
    path: '/income-expense/income/add/:id',
    name: 'income-expense-income-detail',
    component: AddIncomeExpenseDetail,
    meta: {
      requiresAuth: true,
      isIncome: true,
      isExpense: false,
      permissions: ['hospital_admin'],
			plan_permissions: ['income_expense_section.can_add_income_expense']
    }
  },
  {
    path: '/income-expense/expense/add/:id',
    name: 'income-expense-expense-detail',
    component: AddIncomeExpenseDetail,
    meta: {
      requiresAuth: true,
      isIncome: false,
      isExpense: true,
      permissions: ['hospital_admin'],
			plan_permissions: ['income_expense_section.can_add_income_expense']
    }
  }
]

export default routes
