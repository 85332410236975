<template>
  <div class="direct-message-page">
    <Subnavbar>
      <template slot="title">New Message <span>/ {{ pet.pet.name }}</span></template>
      <template slot="actions">
        <button
                class="btn btn-large btn-default"
                @click="$router.push({ name: 'hospitalPetProfile', params: { id: pet.id } })"
        >Cancel
        </button>
        <button
                @click="handleBeforeSend"
                class="btn btn-large btn-primary"
        >
          Send
        </button>
      </template>
    </Subnavbar>
    <ContentBox>
      <div class="form">
        <el-row class="row">
          <el-col :span="4"><label>To</label></el-col>
          <el-col :span="20">
            <span v-if="pet">{{ pet.pet.name }}'s owner</span>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="4"><label>Cover Picture</label></el-col>
          <el-col :span="20">
            <div class="remark">แนะนำสัดส่วนภาพที่ 16:9</div>
            <FileUploader
                    v-if="!coverImageUrl"
                    style="width: 256px; height: 144px; margin: 4px;"
                    :multiple="false"
                    accept="image/*"
                    @onSelect="handleSelectCoverImage"
            />
            <ImageFilePreviewer
                    v-if="coverImageUrl"
                    style="width: 256px; height: 144px; margin: 4px;"
                    :url="coverImageUrl"
                    :showDeleteButton="true"
                    @delete="handleDeleteCoverImage"
            />
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="4"><label>Topic</label> <span class="required-mark">*</span></el-col>
          <el-col :span="20">
            <el-input type="text" v-model="title"/>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="4"><label>Content</label> <span class="required-mark">*</span></el-col>
          <el-col :span="20">
            <el-input type="textarea" :rows="10" v-model="content"/>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="4"><label>Attachment</label></el-col>
          <el-col :span="20">
            <div class="remark">สามารถอัพโหลดได้เฉพาะรูปภาพและไฟล์เอกสาร PDF เท่านั้น</div>
            <FileUploader
                    type="attachment"
                    @onSelect="handleSelectAttachment"
            />
            <ul class="attachment-list">
              <li v-for="(file, index) in attachmentFiles" :key="index">
                <i class="fa fa-file"></i> <span class="name">{{ file.name }}</span> <i class="fa fa-times-circle"
                                                                                        @click="handleDeleteAttachment(index)"></i>
              </li>
            </ul>
          </el-col>
        </el-row>
      </div>
    </ContentBox>
    <ConfirmDialog
            :visible="showConfirmDialog"
            @cancel="showConfirmDialog = false"
            @confirm="handleSend"
    >
      <template slot="header">
        <h1 class="title">ยืนยันการส่งข้อความ</h1>
      </template>
      <template slot="content">
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
  import Subnavbar from "@/components/navigation/Subnavbar";
  import ContentBox from "@/components/container/ContentBox";
  import FileUploader from "@/components/upload/FileUploader"
  import ImageFilePreviewer from "@/components/upload/ImageFilePreviewer"
  import ConfirmDialog from "@/components/dialog/ConfirmDialog"

  export default {
    components: {
      ContentBox,
      Subnavbar,
      FileUploader,
      ImageFilePreviewer,
      ConfirmDialog
    },
    data() {
      return {
        pet: null,
        isLoading: false,
        showConfirmDialog: false,
        coverImageFile: null,
        coverImageUrl: null,
        attachmentFiles: [],
        title: "",
        content: ""
      };
    },
    methods: {
      handleSelectCoverImage(files) {
        if (files.length > 0) {
          const self = this;
          const reader = new FileReader();
          this.coverImageFile = files[0]

          reader.onload = function (e) {
            self.coverImageUrl = e.target.result
          }
          reader.readAsDataURL(files[0])
        } else {
          this.coverImageFile = null
          this.coverImageUrl = null
        }
      },
      handleDeleteCoverImage() {
        this.coverImageUrl = null
      },
      handleSelectAttachment(files) {
        this.attachmentFiles = [...this.attachmentFiles, ...files]
      },
      handleDeleteAttachment(index) {
        this.attachmentFiles.splice(index, 1)
      },
      handleBeforeSend() {
        if(this.title == "" || this.content == "")
        {
          this.$notify({
            message: 'กรุณากรอกข้อมูลให้ครบถ้วน',
            type: "warning"
          })
        } else {
          this.showConfirmDialog = true
        }
      },
      async handleSend() {
        let formData = new FormData()
        
        if (this.coverImageFile) formData.append("cover_img", this.coverImageFile)
        formData.append("pet_id", this.pet.pet.id)
        formData.append("title", this.title)
        formData.append("content", this.content)
        
        this.attachmentFiles.forEach((file, index) => {
          formData.append(`attachments[${index}]`, file)
        })

        try {
          await this.$store.dispatch("ui/setIsFullScreenLoading", true)
          await this.$store.dispatch("message/sendDirectMessage", {formData})
          await this.$store.dispatch("ui/setIsFullScreenLoading", false)
          this.showConfirmDialog = false
          this.$notify({
            message: "ส่งข้อความเรียบร้อยแล้ว",
            type: "success"
          });
          this.$router.push({name: 'hospitalPetProfile' , params: { id: this.pet.id }})
        } catch (error) {
          this.showConfirmDialog = false
          await this.$store.dispatch("ui/setIsFullScreenLoading", false)
          await this.$store.dispatch("ui/showFetchErrorMsg", error.detail)
        }
      }

    },
    async created() {
      try {
      const id = this.$route.params.id;
      await this.$store.dispatch("ui/setIsFullScreenLoading", true);
      this.pet = await this.$store.dispatch("pet/getPetProfile", {
        params: { petHospitalId: id }
      })
      await this.$store.dispatch("ui/setIsFullScreenLoading", false);
    } catch (error) {
      await this.$store.dispatch("ui/showFetchErrorWithReloadMsg");
    }
    }
  };
</script>

<style lang="scss" scoped>
  .direct-message-page {
    .seperator {
      margin-top: 30px;
      margin-bottom: 20px;
      border-top: none;
      border-bottom: 1px solid #ccc;
    }
  }

  .form {
    .row {
      margin-bottom: 10px;
    }

    label {
      color: $light-blue;
      margin-right: 3px;
    }

    .remark {
      font-size: 12px;
      color: #999;
    }

    .attachment-list {
      list-style-type: none;
      padding: 0;
      margin: 10px 0 0 0;

      > li {
        margin: 5px 0;
        padding: 0 10px;
        font-size: 14px;

        .name {
          display: inline-block;
          min-width: 150px;
          padding: 0 10px;
        }

        i {
          color: $light-blue;

          &.fa-times-circle {
            color: #ccc;
            cursor: pointer;

            &:hover {
              color: $red;
            }
          }
        }
      }
    }
  }
</style>
