<template>
  <div>
    <Subnavbar>
      <template slot="title">Report</template>
      <template slot="actions">
        <button 
          v-show="currentPlan.report_section.can_use_owner_report"
          class="btn btn-large btn-pet-owner-report"
          @click="changePage(1)">
          <i class="fa fa-user"></i>Pet Owner
        </button>
        <button 
          v-show="currentPlan.report_section.can_use_pet_report"
          class="btn btn-large btn-pet-report"
          @click="changePage(2)">
          <i class="fa fa-paw"></i>Pet
        </button>
        <button 
          v-show="this.currentPlan.report_section.can_use_owner_report 
            || this.currentPlan.report_section.can_use_pet_report"
          class="btn btn-large btn-bloodbank-report" 
          @click="changePage(3)">
          <i class="fa fa-tint"></i>BloodBank
        </button>
      </template>
    </Subnavbar>

    <ContentBox>
      <p class="header-text">{{title}}</p>
      <div v-if="currentPage === 1">
        <Panel enName="Gender" thName="เพศ">
          <template slot="viewFullLink">
            <!-- <p @click="viewFullPage('petOwner_gender')">View Full Report > </p> -->
          </template>
        </Panel>
        <PetOwnerGenderChart :petOwners='petOwners'/>

        <Panel enName="Age Range" thName="ช่วงอายุ">
          <template slot="viewFullLink">
            <!-- <p @click="viewFullPage('petOwner_ageRange')">View Full Table > </p> -->
          </template>
        </Panel>
        <PetOwnerAgeChart :petOwners='petOwners'/>

        <Panel enName="Number of Pet / Person" thName="จำนวนสัตว์เลี้ยงต่อคน">
          <template slot="viewFullLink">
            <!-- <p @click="viewFullPage('petOwner_numberOfPetPerPerson')">View Full Table > </p> -->
          </template>
        </Panel>
        <PetOwnerPetPerPersonChart :petOwners='petOwners'/>

        <Panel enName="Social Network Connect" thName="การเชื่อมต่อกับ Social Network">
          <template slot="viewFullLink">
            <!-- <p @click="viewFullPage('petOwner_SocialNetworkConnect')">View Full Table > </p> -->
          </template>
        </Panel>
        <PetOwnerSocialConnectChart :petOwners='petOwners'/>
      </div>

      <!-- ------------------ -->
      
      <div v-if="currentPage === 2">
        <Panel enName="Number of Pet" thName="จำนวนสัตว์ ในแต่ละชนิด">
          <template slot="viewFullLink">
            <!-- <p @click="viewFullPage('pet_number')">View Full Report > </p> -->
          </template>
        </Panel>
        <PetNumberChart :pets='pets'/>

        <Panel enName="Gender"  thName="เพศ">
          <template slot="dropDown">
            <el-select
              style="width: 200px;text-align:center;padding-left:10px;"
              popper-class="search-select"
              v-model="petType"
            >
              <el-option
                style="text-align:center;"
                v-for="(item, index) in petTypes"
                :key="index"
                :value="index"
                :label="item.name">
              </el-option>
            </el-select>
          </template>
          <template slot="viewFullLink">
            <!-- <p @click="viewFullPage('pet_number')">View Full Report > </p> -->
          </template>
        </Panel>
        <PetGenderChart :pets="pets" :petType="petType"/>

        <Panel enName="Age Range"  thName="ช่วงอายุ">
          <template slot="dropDown">
            <el-select
              style="width: 200px;text-align:center;padding-left:10px;"
              popper-class="search-select"
              v-model="petType"
            >
              <el-option
                style="text-align:center;"
                v-for="(item, index) in petTypes"
                :key="index"
                :value="index"
                :label="item.name">
              </el-option>
            </el-select>
          </template>
          <template slot="viewFullLink">
            <!-- <p @click="viewFullPage('pet_age')">View Full Report > </p> -->
          </template>
        </Panel>
        <PetAgeChart :pets="pets" :petType="petType"/>

        <Panel enName="Breed"  thName="พันธุ์">
          <template slot="dropDown">
            <el-select
              style="width: 200px;text-align:center;padding-left:10px;"
              popper-class="search-select"
              v-model="petType"
            >
              <el-option
                style="text-align:center;"
                v-for="(item, index) in petTypes"
                :key="index"
                :value="index"
                :label="item.name">
              </el-option>
            </el-select>
          </template>
          <template slot="viewFullLink">
            <!-- <p @click="viewFullPage('pet_age')">View Full Report > </p> -->
          </template>
        </Panel>
        <PetBreedChart :pets='pets' :petType="petType"/>
      </div>

      <!-- ------------------ -->

      <div v-if="currentPage === 3">
        <Panel enName="Blood Bags Overview"  thName="ภาพรวม">
          <template slot="datePicker">
            from
            <el-date-picker
              type="date"
              placeholder="Anyday"
              size="small"
              v-model="startDate"
              :clearable='false'
              style="margin-bottom: 5px; width: 150px;"
              format="dd/MM/yyyy"
              :picker-options="startDateOptions"
            ></el-date-picker>
            to
            <el-date-picker
              type="date"
              placeholder="Anyday"
              size="small"
              v-model="endDate"
              :clearable='false'
              style="margin-bottom: 5px; width: 150px;"
              format="dd/MM/yyyy"
              :picker-options="endDateOptions"
            ></el-date-picker>
          </template>
        </Panel>
        <BloodbankOverviewSummaryChart :bloodbank="bloodbank"/>
        <SubPanel enName="Blood Group" thName="หมู่เลือด">
          <template slot="dropDown">
            <el-select
              style="width: 200px;text-align:center;padding-left:10px;"
              popper-class="search-select"
              v-model="bloodbankPetType"
            >
              <el-option
                style="text-align:center;"
                v-for="(item, index) in bloodbankPetTypes"
                :key="index"
                :value="index"
                :label="item.name">
              </el-option>
            </el-select>
          </template>
          <template slot="viewFullLink">
            <!-- <p @click="viewFullPage('bloodbank_blood_group')">View Full Report > </p> -->
          </template>
        </SubPanel>
        <BloodbankOverviewBloodGroupChart :bloodbank="bloodbank" :petType="bloodbankPetType"/>
        <!-- <SubPanel enName="Product" thName="ผลิตภัณฑ์">
          <template slot="dropDown">
            <el-select
              style="width: 200px;text-align:center;padding-left:10px;"
              popper-class="search-select"
              v-model="bloodbankPetType"
            >
              <el-option
                style="text-align:center;"
                v-for="(item, index) in bloodbankPetTypes"
                :key="index"
                :value="index"
                :label="item.name">
              </el-option>
            </el-select>
          </template>
          <template slot="viewFullLink">
            <p @click="viewFullPage('bloodbank_product')">View Full Report > </p>
          </template>
        </SubPanel>
        <BloodbankOverviewProductChart :bloodbank="bloodbank" :petType="bloodbankPetType"/> -->

        <Panel enName="Donor Overview"  thName="สัตว์ที่บริจาคเลือด">
          <template slot="datePicker">
            from
            <el-date-picker
              type="date"
              placeholder="Anyday"
              size="small"
              v-model="startDate"
              :clearable='false'
              style="margin-bottom: 5px; width: 150px;"
              format="dd/MM/yyyy"
              :picker-options="startDateOptions"
            ></el-date-picker>
            to
            <el-date-picker
              type="date"
              placeholder="Anyday"
              size="small"
              v-model="endDate"
              :clearable='false'
              style="margin-bottom: 5px; width: 150px;"
              format="dd/MM/yyyy"
              :picker-options="endDateOptions"
            ></el-date-picker>
          </template>
        </Panel>
        <BloodbankDonerSummaryChart :bloodbank="bloodbank"/>
        <SubPanel enName="AgeRange" thName="ช่วงอายุ">
          <template slot="dropDown">
            <el-select
              style="width: 200px;text-align:center;padding-left:10px;"
              popper-class="search-select"
              v-model="bloodbankPetType"
            >
              <el-option
                style="text-align:center;"
                v-for="(item, index) in bloodbankPetTypes"
                :key="index"
                :value="index"
                :label="item.name">
              </el-option>
            </el-select>
          </template>
          <template slot="viewFullLink">
            <!-- <p @click="viewFullPage('bloodbank_ageRange')">View Full Report > </p> -->
          </template>
        </SubPanel>
        <BloodbankDonerAgeChart :bloodbank="bloodbank" :petType="bloodbankPetType"/>
        <SubPanel enName="Breed" thName="สายพันธุ์">
          <template slot="dropDown">
            <el-select
              style="width: 200px;text-align:center;padding-left:10px;"
              popper-class="search-select"
              v-model="bloodbankPetType"
            >
              <el-option
                style="text-align:center;"
                v-for="(item, index) in bloodbankPetTypes"
                :key="index"
                :value="index"
                :label="item.name">
              </el-option>
            </el-select>
          </template>
          <template slot="viewFullLink">
            <!-- <p @click="viewFullPage('bloodbank_breed')">View Full Report > </p> -->
          </template>
        </SubPanel>
        <BloodbankDonerBreedChart :bloodbank="bloodbank" :petType="bloodbankPetType"/>

        <Panel enName="Reason of Request"  thName="สาเหตุในการขอเลือด">
          <template slot="datePicker">
            from
            <el-date-picker
              type="date"
              placeholder="Anyday"
              size="small"
              v-model="startDate"
              :clearable='false'
              style="margin-bottom: 5px; width: 150px;"
              format="dd/MM/yyyy"
              :picker-options="startDateOptions"
            ></el-date-picker>
            to
            <el-date-picker
              type="date"
              placeholder="Anyday"
              size="small"
              v-model="endDate"
              :clearable='false'
              style="margin-bottom: 5px; width: 150px;"
              format="dd/MM/yyyy"
              :picker-options="endDateOptions"
            ></el-date-picker>
          </template>
        </Panel>
        <BloodbankRequestRankChart :bloodbank="bloodbank"/>
        <!-- <BloodbankRequestLinearChart :bloodbank="bloodbank"/> -->
      </div>
    </ContentBox>
  </div>
</template>

<script>
import { mapState } from "vuex"
import Subnavbar from "@/components/navigation/Subnavbar"
import ContentBox from "@/components/container/ContentBox"
import Panel from "@/components/report/Panel"
import PetOwnerGenderChart from "@/components/report/PetOwnerGenderChart"
import PetOwnerAgeChart from "@/components/report/PetOwnerAgeChart"
import PetOwnerPetPerPersonChart from "@/components/report/PetOwnerPetPerPersonChart"
import PetOwnerSocialConnectChart from "@/components/report/PetOwnerSocialConnectChart"
import PetNumberChart from "@/components/report/PetNumberChart"
import PetGenderChart from "@/components/report/PetGenderChart"
import PetAgeChart from "@/components/report/PetAgeChart"
import PetBreedChart from "@/components/report/PetBreedChart"
import BloodbankOverviewSummaryChart from "@/components/report/BloodbankOverviewSummaryChart"
import BloodbankOverviewBloodGroupChart from "@/components/report/BloodbankOverviewBloodGroupChart"
// import BloodbankOverviewProductChart from "@/components/report/BloodbankOverviewProductChart"
import BloodbankDonerSummaryChart from "@/components/report/BloodbankDonerSummaryChart"
import BloodbankDonerAgeChart from "@/components/report/BloodbankDonerAgeChart"
import BloodbankDonerBreedChart from "@/components/report/BloodbankDonerBreedChart"
import BloodbankRequestRankChart from "@/components/report/BloodbankRequestRankChart"
// import BloodbankRequestLinearChart from "@/components/report/BloodbankRequestLinearChart"
import SubPanel from "@/components/report/SubPanel"
import moment from "moment"

export default {
  components: {
    ContentBox,
    Subnavbar,
    Panel,
    PetOwnerGenderChart,
    PetOwnerAgeChart,
    PetOwnerPetPerPersonChart,
    PetOwnerSocialConnectChart,
    PetNumberChart,
    PetGenderChart,
    PetAgeChart,
    PetBreedChart,
    BloodbankOverviewSummaryChart,
    BloodbankOverviewBloodGroupChart,
    // BloodbankOverviewProductChart,
    BloodbankDonerSummaryChart,
    BloodbankDonerAgeChart,
    BloodbankDonerBreedChart,
    BloodbankRequestRankChart,
    // BloodbankRequestLinearChart,
    SubPanel,
  },
  data() {
    return {
      currentPage: 1,
      title: 'Pet Owner',
      petType: 0,
      bloodbankPetType: 0,
      startDate: moment(new Date()).startOf('day').subtract(12, 'months').toDate(),
      endDate: moment(new Date()).startOf('day').toDate(),
      viewPage: '',
      startDateOptions: {
        disabledDate: (date) =>  {
          return moment(date).diff(this.endDate) > 0;
        }
      },
      endDateOptions: {
        disabledDate: (date) =>   {
          return moment(date).diff(this.startDate) < 0;
        }
      },
    }
  },
  computed: {
    ...mapState({
      petOwners: state => state.report.petowners,
      pets: state => state.report.pets,
      bloodbank: state => state.report.bloodbank,
      currentPlan: state => state.plan.currentPlan,
    }),
    petTypes() {
      return this.pets ? this.pets.others : [];
    },
    bloodbankPetTypes() {
      return this.bloodbank ? this.bloodbank.others : [];
    },
  },
  methods: {
    changePage(page) {
      this.currentPage = page;
      if(page === 1) this.title = 'Pet Owner';
      else if(page === 2) this.title = 'Pet';
      else if(page === 3) this.title = 'BloodBank';
    },
    viewFullPage(page) {
      this.viewPage = page;
    },
    changeDateRange() {
      const startStr = moment(this.startDate).format('DDMMYYYY')
      const endStr = moment(this.endDate).format('DDMMYYYY')

      return this.$store.dispatch("report/getBloodbank", {
        params: { start: startStr, end: endStr }
      })
    },
    async waitChangeDateRange() {
      await this.$store.dispatch("ui/setIsFullScreenLoading", true)
      await this.changeDateRange()
      await this.$store.dispatch("ui/setIsFullScreenLoading", false)
    },
    initialCurrentPage() {
      if (this.currentPlan.report_section.can_use_owner_report)
        this.changePage(1)

      else if (!this.currentPlan.report_section.can_use_owner_report 
                  && this.currentPlan.report_section.can_use_pet_report)
        this.changePage(2)

      else this.changePage(3)
    }
  },
  async created() {
    try {
      await this.$store.dispatch("ui/setIsFullScreenLoading", true)
      if (this.currentPlan.report_section.can_use_owner_report) {
        await this.$store.dispatch("report/getPetowners")
      }
      if (this.currentPlan.report_section.can_use_pet_report) {
        await this.$store.dispatch("report/getPets")
      }
      await this.changeDateRange()
      this.initialCurrentPage();
      await this.$store.dispatch("ui/setIsFullScreenLoading", false)
    } catch (error) {
      await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      await this.$store.dispatch("ui/showFetchErrorMsg", error.detail)
    }
  },
  watch: {
    'startDate': function () {
      this.waitChangeDateRange();
    },
    'endDate': function () {
      this.waitChangeDateRange();
    },
  },
}
</script>

<style lang="scss" scoped>
.header-text {
  font-size: 24px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}

.btn-pet-owner-report {
  background-color: #d4ff78;
  img {
    height: 16px;
  }
  &:hover {
    background-color: lighten(#d4ff78, 5%);
  }
}

.btn-pet-report {
  background-color: #ffd57a;
  img {
    height: 16px;
  }
  &:hover {
    background-color: lighten(#ffd57a, 5%);
  }
}

.btn-bloodbank-report {
  background-color: #ff7878;
  img {
    height: 16px;
  }
  &:hover {
    background-color: lighten(#ff7878, 5%);
  }
}
</style>
