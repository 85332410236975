let api;

if (!api) {
  import("./apis").then((apis) => {
    api = apis.coreAPI;
  });
}

/**
 * Create IPD
 * @param {Object} data
 * @param {string} data.medical_record
 */
const create = (data) => api.post("/ipds/", data);

/**
 * Create an IPD deposit
 * @param {string} id
 * @param {Object} data
 * @param {Date} data.datetime
 * @param {string} data.owner
 * @param {number[]} data.attachments
 * @param {number} data.amount
 * @param {string} data.obj_id
 * @param {string} [data.obj_content_type__model=ipd]
 * @param {Object[]} data.payments
 * @param {number} data.payments[].payment_method
 * @param {string} data.payments[].ref
 * @param {number} data.payments[].amount
 * @param {number} data.payments[].fee_percentage
 */
const createDeposit = (id, data) =>
  api.post(`/receipt/deposit/`, {
    ...data,
    obj_id: id,
    obj_content_type__model: "ipd",
  });

/**
 * Delete an Deposit
 * @param {string} id
 */
 const delDeposit = (id) => api.delete(`/receipt/deposit/${id}/`);

/**
 * Create final receipt
 * @param {string} id
 * @param {Object} data
 * @param {Date} data.datetime
 * @param {string} data.owner
 * @param {number[]} data.attachments
 * @param {Object[]} data.payments
 * @param {number} data.payments[].payment_method
 * @param {string} data.payments[].ref
 * @param {number} data.payments[].amount
 * @param {number} data.payments[].fee_percentage
 */
const createFinalReceipt = (id, data) =>
  api.post(`/ipds/${id}/finalize/`, data);

/**
 * Create an IPD's medical record
 * @param {string} id
 * @param {Object} data
 * @param {string} data.medical_record
 */
const createMedicalRecord = (id, data) =>
  api.post(`/ipds/${id}/medicalrecords/add/`, data);

/**
 * Create an IPD note
 * @param {string} id
 * @param {Object} data
 * @param {string} data.text
 * @param {number} data.vet
 * @param {string} data.vet_pin
 */
const createNote = (id, data) => api.post(`/ipds/${id}/notes/`, data);

/**
 * Create an IPD receipt
 * @param {Object} data
 * @param {number[]} data.pos_set
 * @param {Date} data.datetime
 * @param {string} data.owner
 * @param {number[]} data.attachments
 * @param {Object[]} data.payments
 * @param {number} data.payments[].payment_method
 * @param {string} data.payments[].ref
 * @param {number} data.payments[].amount
 * @param {number} data.payments[].fee_percentage
 */
const createReceipt = (data) => api.post("/receipt/receipt/", data);

/**
 * Delete an IPD
 * @param {string} id
 * @param {Object} data
 * @param {(string|number)} data.vet_id
 */
const del = (id, data) => api.post(`/ipds/${id}/discard/`, data);

/**
 * Get an IPD detail
 * @param {string} id
 */
const get = (id) => api.get(`/ipds/${id}/`);

/**
 * Get IPDs
 * @param {(Object|undefined)} params
 * @param {(Date|undefined)} params.start
 * @param {(Date|undefined)} params.end
 * @param {(string|undefined)} params.search
 * @param {(''|'in_progress'|'done'|undefined)} params.progress_status
 * @param {(''|'exam'|'overdue'|'finished'|undefined)} params.overall_status
 * @param {(number|undefined)} params.page
 * @param {(number|undefined)} params.page_size
 */
const getAll = (params) => api.get("/ipds/", { params });
const getAllWithRepeatParams = (params) => api.get("/ipds/", params);

/**
 * Get discarded IPDs
 * @param {(Object|undefined)} params
 * @param {(Date|undefined)} params.start
 * @param {(Date|undefined)} params.end
 * @param {(string|undefined)} params.search
 * @param {(''|'in_progress'|'done'|undefined)} params.progress_status
 * @param {(''|'exam'|'overdue'|'finished'|undefined)} params.overall_status
 * @param {(number|undefined)} params.page
 * @param {(number|undefined)} params.page_size
 * @param {(string|number|undefined)} params.discardedmedicalrecord__vet__id
 */
const getDiscards = (params) => api.get("/ipds/discarded/", { params });

/**
 * Get an IPD deposit
 * @param {string} id
 */
const getDeposit = (id) => api.get(`/receipt/deposit/${id}/`);

/**
 * Get deposit's refund receipt
 * @param {string} id
 */
const getDepositRefundReceipt = (id) =>
  api.get(`/ipds/${id}/deposits/refunded-receipt/`);

/**
 * Get all IPD deposits
 * @param {string} id
 * @param {(Object|undefined)} params
 * @param {(number|undefined)} params.page
 */
const getDeposits = (id, params) =>
  api.get(`/ipds/${id}/deposits/`, { params });

/**
 * Get all IPD medical records
 * @param {string} id
 * @param {(Object|undefined)} params
 * @param {(boolean|undefined)} params.is_overdue
 * @param {(number|undefined)} params.page
 * @param {(boolean|undefined)} params.all
 */
const getMedicalRecords = (id, params) =>
  api.get(`/ipds/${id}/medicalrecords/`, { params });

/**
 * Get IPD notes
 * @param {string} id
 * @param {(Object|undefined)} params
 * @param {(number|undefined)} params.page
 * @param {(number|undefined)} params.page_size
 */
const getNotes = (id, params) => api.get(`/ipds/${id}/notes/`, { params });

/**
 * Get IPD overview
 * @param {(Object|undefined)} params
 * @param {(Date|undefined)} params.start
 * @param {(Date|undefined)} params.end
 */
const getOverview = (params) => api.get("/ipds/overview/", { params });

/**
 * Get pre-final detail
 * @param {string} id
 */
const getPreFinalDetail = (id) => api.get(`/ipds/${id}/pre-finalize/`);

/**
 * Get an IPD's paid receipt
 * @param {string} id
 */
const getReceipt = (id) => api.get(`/receipt/receipt/${id}/`);

/**
 * Get IPD's paid receipts
 * @param {string} id
 * @param {(Object|undefined)} params
 * @param {(number|undefined)} params.page
 */
const getReceipts = (id, params) =>
  api.get(`/ipds/${id}/receipts/`, {
    params: { ...params, ordering: "-created" },
  });

/**
 * @param {string} id
 */
const setPetBackHome = (id) =>
 api.post(`/ipds/${id}/backhome/`);

 /**
 * @param {string} id
 * @param {Object} data
 * @param {boolean} data.is_bad_debt
 */
const setBadDebt = (id, data) =>
  api.post(`/ipds/${id}/baddebt/`, data);

export default {
  create,
  createDeposit,
  delDeposit,
  createFinalReceipt,
  createMedicalRecord,
  createNote,
  createReceipt,
  del,
  get,
  getAll,
  getAllWithRepeatParams,
  getDiscards,
  getDeposit,
  getDepositRefundReceipt,
  getDeposits,
  getMedicalRecords,
  getNotes,
  getOverview,
  getPreFinalDetail,
  getReceipt,
  getReceipts,
  setPetBackHome,
  setBadDebt
};
