<script>
import tinycolor from "tinycolor2";

const isValidColor = (value) => tinycolor(value).isValid();

export default {
  props: {
    label: {
      type: String,
      default: "",
    },

    titleColor: {
      type: String,
      default: "",
      validator: isValidColor,
    },

    color: {
      type: String,
      default: "",
      validator: isValidColor,
    },
  },

  data() {
    return {
      options: [
        {
          label: "A4",
          value: "a4",
          icon: (
            <div
              class={this.$style.icon}
              style={{ width: "1em", height: "1.5em" }}
            />
          ),
        },
        {
          label: "A5",
          value: "a5",
          icon: (
            <div
              class={this.$style.icon}
              style={{ width: "1.25em", height: "1.5em" }}
            />
          ),
        },
        {
          label: "Slip",
          value: "slip",
          icon: (
            <div
              class={this.$style.icon}
              style={{ width: "0.5em", height: "2em" }}
            />
          ),
        },
      ],
    };
  },

  computed: {
    darkerColor() {
      return tinycolor(this.color)
        .darken(5)
        .toString();
    },

    lighterColor() {
      return tinycolor(this.color)
        .lighten(5)
        .toString();
    },

    textColor() {
      return tinycolor(this.color).isDark() ? "white" : "black";
    },

    textDarkerColor() {
      return tinycolor(this.darkerColor).isDark() ? "white" : "black";
    },

    textLighterColor() {
      return tinycolor(this.lighterColor).isDark() ? "white" : "black";
    },
  },

  render() {
    return (
      <div
        style={{
          "--color": this.color,
          "--color-darker": this.darkerColor,
          "--color-lighter": this.lighterColor,
          "--color-title": this.titleColor ? this.titleColor : this.color,
          "--color-text": this.textColor,
          "--color-text-darker": this.textDarkerColor,
          "--color-text-lighter": this.textLighterColor,
        }}
      >
        <div class={this.$style.lbl}>{this.label}</div>

        <el-button-group class={this.$style.buttonGroup}>
          {this.options.map((option, index) => (
            <el-button
              key={index}
              class={this.$style.button}
              onClick={() => {
                this.$emit("select", option.value);
              }}
            >
              {option.icon}
              {option.label}
            </el-button>
          ))}
        </el-button-group>
      </div>
    );
  },
};
</script>

<style lang="scss" module>
.lbl {
  color: var(--color-title);
  margin-bottom: 0.5em;
}

.buttonGroup {
  display: flex;
}

.button {
  display: block;
  width: 100%;
  background-color: var(--color);
  color: var(--color-text);
  border-color: var(--color-darker) !important;
  font-weight: bold;

  &:hover,
  &:focus {
    background-color: var(--color-lighter);
    color: var(--color-text-lighter);
  }

  &:active {
    background-color: var(--color-darker);
    color: var(--color-text-darker);
  }
}

.icon {
  display: inline-block;
  margin-right: 0.5em;
  border: 2px solid var(--color-text);
  vertical-align: middle;
  transition: ease-out all 125ms;

  .button:hover &,
  .button:focus & {
    border-color: var(--color-text-lighter);
  }

  .button:active & {
    border-color: var(--color-text-darker);
  }
}
</style>
