<template>
  <div>

    <!-- Sub Navbar -->
    <Subnavbar>
      <template slot="breadcrumb">
      </template>
      <template slot="title">BLOOD REQUEST BETWEEN HOSPITAL DETAIL</template>
      <template
        slot="actions"
        class="action-wrapper"
      >
        <button
          class="btn btn-large btn-plain hoverable"
          @click="backToDashboard"
          v-show="isMyInbox"
        >
          Cancel
        </button>
        <button
          class="btn btn-large btn-primary"
          @click="showVetConfirmModal"
          v-show="isMyInbox"
        >
          <i class="fa fa-check"></i>Submit
        </button>
      </template>
    </Subnavbar>

    <!-- Request -->
    <el-row :gutter="10">
      <el-col>
        <ContentBox v-if="requestDetail">
          <div class="row">
            <div
              class="topic head col-md-12 flex-end flex-middle"
              style="padding: 10px;"
            >
              <label>Request ID: </label>
            </div>

            <div
              class="detail head col-md-12 flex-start flex-middle"
              style="padding: 10px;"
            >
              <span>{{requestDetail.id}}</span>
            </div>
          </div>
          <div class="row">
            <div
              class="topic col-md-12 flex-end flex-middle"
              style="padding: 10px;"
            >
              <label>Request Date/Time </label>
            </div>

            <div
              class="detail col-md-12 flex-start flex-middle"
              style="padding: 10px;"
            >
              <span>{{ requestDetail.bloodRequestDate | toThaiDate }} {{ requestDetail.bloodRequestDate | toHHMM }}</span>
            </div>
          </div>

          <div class="row">
            <div
              class="topic col-md-12 flex-end flex-middle"
              style="padding: 10px;"
            >
              <label>From Hospital </label>
            </div>

            <div
              class="detail col-md-12 flex-start flex-middle"
              style="padding: 10px;"
            >
              <span>{{fromHospital}}</span>
            </div>
          </div>

          <div class="row">
            <div
              class="topic col-md-12 flex-end flex-middle"
              style="padding: 10px;"
            >
              <label>To Hospital </label>
            </div>

            <div
              class="detail col-md-12 flex-start flex-middle"
              style="padding: 10px;"
            >
              <span>{{toHospital}}</span>
            </div>
          </div>

          <div class="row">
            <div
              class="topic col-md-12 flex-end flex-middle"
              style="padding: 10px;"
            >
              <label>SPBB Blood Bag ID </label>
            </div>

            <div
              class="detail col-md-12 flex-start flex-middle"
              style="padding: 10px;"
            >
              <div class="row">
                <span>{{ requestDetail.bloodBagId || '-' }}</span>
              </div>
              <div
                class="row"
                v-if="requestDetail.bloodBagId"
              >
                <at-button
                  @click="viewBloodBagDetails"
                  type="primary"
                >SEE DETAILS</at-button>
              </div>
            </div>
          </div>

          <div class="row">
            <div
              class="topic col-md-12 flex-end flex-middle"
              style="padding: 10px;"
            >
              <label>Pet Type </label>
            </div>

            <div
              class="detail col-md-12 flex-start flex-middle"
              style="padding: 10px;"
            >
              <span>{{requestDetail.petType}}</span>
            </div>
          </div>

          <div class="row">
            <div
              class="topic col-md-12 flex-end flex-middle"
              style="padding: 10px;"
            >
              <label>Type </label>
            </div>

            <div
              class="detail col-md-12 flex-start flex-middle"
              style="padding: 10px;"
            >
              <span>{{requestDetail.bloodType}}</span>
            </div>
          </div>

          <div class="row">
            <div
              class="topic col-md-12 flex-end flex-middle"
              style="padding: 10px;"
            >
              <label>Amount </label>
            </div>

            <div
              class="detail col-md-12 flex-start flex-middle"
              style="padding: 10px;"
            >
              <span>{{requestDetail.amount}} ml.</span>
            </div>
          </div>

          <div class="row">
            <div
              class="topic col-md-12 flex-end flex-middle"
              style="padding: 10px;"
            >
              <label>Note </label>
            </div>

            <div
              class="detail col-md-12 flex-start flex-middle"
              style="padding: 10px;"
            >
              <span>{{requestDetail.note || '-'}} </span>
            </div>
          </div>
        </ContentBox>
      </el-col>
    </el-row>

    <!-- Result -->
    <el-row
      :gutter="10"
      v-if="isMyRequest"
    >
      <el-col>
        <ContentBox style="margin-top: 10px">
          <h2 class="title">Result</h2>

          <div class="row">
            <div
              class="topic col-md-12 flex-end flex-middle"
              style="padding: 10px;"
            >
              <label>Reply Date/Time: </label>
            </div>

            <div
              class="detail col-md-12 flex-start flex-middle"
              style="padding: 10px;"
            >
              <span v-if="approvalDetail">{{ approvalDetail.createdDate | toThaiDate }}</span>
              <span v-else>-</span>
            </div>
          </div>

          <div class="row">
            <div
              class="topic col-md-12 flex-end flex-middle"
              style="padding: 10px;"
            >
              <label>Answer: </label>
            </div>

            <div
              class="detail col-md-12 flex-start flex-middle"
              style="padding: 10px;"
            >
              <at-tag v-if="approvalDetail == null || approvalDetail.isApproved == null">{{statusEnum.NO_RESPOND}}</at-tag>
              <at-tag
                color="success"
                v-else-if="approvalDetail.isApproved === true"
              >{{statusEnum.ACCEPT}}</at-tag>
              <at-tag
                color="error"
                v-else-if="approvalDetail.isApproved === false"
              >{{statusEnum.REJECT}}</at-tag>
            </div>
          </div>

          <div class="row">
            <div
              class="topic col-md-12 flex-end flex-middle"
              style="padding: 10px;"
            >
              <label>Note: </label>
            </div>

            <div
              class="detail col-md-12 flex-start flex-middle"
              style="padding: 10px;"
            >
              <span>{{approvalDetail && approvalDetail.approvalNote || '-'}}</span>
            </div>
          </div>

          <div class="row">
            <div
              class="topic col-md-12 flex-end flex-middle"
              style="padding: 10px;"
            >
              <label>ผู้อนุมัติ: </label>
            </div>

            <div
              class="detail col-md-12 flex-start flex-middle"
              style="padding: 10px;"
            >
              <span>{{approvalDetail && approvalDetail.approverName || '-'}}</span>
            </div>
          </div>
        </ContentBox>
      </el-col>
    </el-row>

    <!-- Approving -->
    <el-row
      :gutter="10"
      v-if="isMyInbox"
    >
      <el-col>
        <ContentBox style="margin-top: 10px">
          <h2 class="title">My Action</h2>

          <el-row
            :gutter="10"
            type="flex"
            align="bottom"
          >
            <el-col
              :md="12"
              class="button-set"
            >
              <at-button
                class="flex-start"
                :type="acceptBtnStyle"
                v-on:click="onAcceptBtnClicked()"
                size="large"
                style=""
              >
                <i
                  class="icon icon-check"
                  style="font-size:40px;"
                ></i>
                <span style="font-size:40px;">Accept</span>
              </at-button>
              <at-button
                class="flex-start"
                :type="rejectBtnStyle"
                v-on:click="onRejectBtnClicked()"
                size="large"
                style="margin-left: 10px"
              >
                <i
                  class="icon icon-x"
                  style="font-size:40px;"
                ></i>
                <span style="font-size:40px;">Reject</span>
              </at-button>
            </el-col>

            <el-col
              :md="12"
              class="flex-start"
            >
              <div style="margin-bottom:10px;">หมายเหตุ</div>
              <el-input
                type="textarea"
                v-model="approvalDetail.approvalNote"
                :autosize="{ minRows: 3, maxRows: 3}"
                placeholder="บันทึกเพิ่มเติม ..."
              ></el-input>
            </el-col>
          </el-row>

          <el-row
            style="margin-top: 10px;"
            type="flex"
            align="bottom"
          >
            <el-col class="flex-start">
              <label>ผู้อนุมัติ: </label>
              <at-select
                v-validate="'required'"
                v-model="approvalDetail.approver"
                style="width:220px"
                placement="top"
              >
                <at-option
                  :key="idx"
                  :value="itm.id"
                  v-for="(itm, idx) in vetList"
                > {{ itm.title + ' ' + itm.first_name + ' ' + itm.last_name }} </at-option>
              </at-select>
            </el-col>
          </el-row>
        </ContentBox>
      </el-col>
    </el-row>

    <!-- Modal -->
    <VetConfirmModal
      v-bind:isShow="isConfirmModalShow"
      :vet="selectedVet"
      v-bind:onSubmit="submitBloodBagApproval"
      v-bind:onCancel="onCloseVetConfirmModal"
    ></VetConfirmModal>

  </div>
</template>

<script>
import ContentBox from "../../../components/container/ContentBox";
import Subnavbar from "../../../components/navigation/Subnavbar";
import { mapState } from "vuex";
import statusEnum from "../../../constants/bloodbank/blood-request-hospital-status-enum";
import BloodRequesHospitalTypeEnum from "../../../constants/bloodbank/blood-request-hospital-type-enum";
import VetConfirmModal from "@/components/bloodbank/controls/vet-confirm-modal/VetConfirmModal.vue";
import BloodRequestHospitalApproval from "../../../models/data/blood-request-hospital/blood-request-hospital-approval/blood-request-hospital-approval";
export default {
  name: "BloodRequestHospitalDetailPage",
  components: { Subnavbar, ContentBox, VetConfirmModal },
  data() {
    return {
      statusEnum: statusEnum,
      isAddBloodBagDialogShown: false,
      isConfirmModalShow: false,

      requestId: null,
      requestDetail: null,
      approvalDetail: null
    };
  },
  methods: {
    getRequestDetail: function() {
      if (this.requestId == null) {
        return null;
      }

      this.requestDetail = this.$store.getters[
        "bloodbank/getBloodHospitalRequestById"
      ](this.requestId);
    },
    getApprovalDetail: function() {
      if (this.requestId == null) {
        return null;
      }

      this.approvalDetail = this.$store.getters[
        "bloodbank/getClonedBloodApprovalByBloodRequestId"
      ](this.requestId);
    },
    getVetById: function(id) {
      return this.vetList.find(vet => vet.id === id);
    },
    onAcceptBtnClicked: function() {
      this.approvalDetail.isApproved = true;
    },
    onRejectBtnClicked: function() {
      this.approvalDetail.isApproved = false;
    },
    submitBloodBagApproval: function() {
      this.$validator.validateAll().then(result => {
        if (!result) {
          this.$Notify.error({
            title: "ข้อมูลผิดพลาด",
            message: "กรุณากรอกข้อมูลให้ถูกต้อง"
          });
          setTimeout(() => {
            this.isAddBloodBagDialogShown = true;
          }, 500);
          return;
        }

        this.$store
          .dispatch("bloodbank/createBloodHospitalRequestApproval", {
            data: this.approvalDetail.toRequestJson()
          })
          .then(result => {
            if (result) {
              this.$notify.success({
                title: "สำเร็จ",
                message: "ดำเนินการเรียบร้อย"
              });
              this.$store.commit(
                "bloodbank/NEW_BLOOD_HOSPITAL_REQUEST_APPROVAL",
                new BloodRequestHospitalApproval(result)
              );
            } else {
              this.$Notify.error({
                title: "ข้อมูลผิดพลาด",
                message: "กรุณากรอกข้อมูลให้ถูกต้อง"
              });
            }

            this.backToDashboard();
          });
      });
    },
    backToDashboard: function() {
      this.$router.push({ name: "requestHospital" });
    },
    viewBloodBagDetails: function() {
      this.$router.push({
        name: "inventoryDetail",
        params: { id: this.requestDetail.bloodBagId }
      });
    },
    showVetConfirmModal: function() {
      this.$validator.validateAll().then(result => {
        if (!result) {
          this.$Notify.error({
            title: "ข้อมูลผิดพลาด",
            message: "กรุณากรอกข้อมูลให้ถูกต้อง"
          });
          return;
        }
        this.isConfirmModalShow = true;
      });
    },
    onCloseVetConfirmModal: function() {
      this.isConfirmModalShow = false;
    }
  },
  computed: {
    ...mapState({
      vetList: state => state.hospital.vets,
      requestList: state => state.bloodbank.bloodHospitalRequests,
      requestApprovalList: state =>
        state.bloodbank.bloodHospitalRequestApprovals,
      hospitalInfo: state => state.hospital.profile,
      hospitals: state => state.hospital.hospitals
    }),
    isMyRequest: function() {
      return (
        this.requestDetail &&
        this.hospitalInfo.id == this.requestDetail.hospitalId
      );
    },
    isMyInbox: function() {
      return (
        this.requestDetail &&
        this.hospitalInfo.id != this.requestDetail.hospitalId
      );
    },
    detailType: function() {
      return this.requestDetail &&
        this.hospitalInfo.id == this.requestDetail.hospitalId
        ? BloodRequesHospitalTypeEnum.MY_REQUEST
        : BloodRequesHospitalTypeEnum.MY_INBOX;
    },
    fromHospital: function() {
      return (
        this.requestDetail &&
        this.requestDetail.hospitalId &&
        this.$store.getters["hospital/getHospitalById"](
          this.requestDetail.hospitalId
        ).name
      );
    },
    toHospital: function() {
      return (
        this.requestDetail &&
        this.requestDetail.targetHospitalId &&
        this.$store.getters["hospital/getHospitalById"](
          this.requestDetail.targetHospitalId
        ).name
      );
    },
    approverName: function() {
      let approver =
        this.approvalDetail && this.getVetById(this.approvalDetail.approver);
      return (
        approver &&
        approver.title + " " + approver.first_name + " " + approver.last_name
      );
    },
    acceptBtnStyle: function() {
      return this.approvalDetail && this.approvalDetail.isApproved === true
        ? "primary"
        : "";
    },
    rejectBtnStyle: function() {
      return this.approvalDetail && this.approvalDetail.isApproved === false
        ? "primary"
        : "";
    },
    selectedVet: function() {
      let vet = this.approvalDetail && this.approvalDetail.approver;
      return this.getVetById(vet);
    }
  },
  async mounted() {
    await this.$store.dispatch("ui/setIsFullScreenLoading", true);

    // Set request ID
    this.requestId = Number(this.$route.params.id);

    try {
      // // List&Fill Donations
      // if (this.$store.state.bloodbank.donations.length < 1) {
      //   await this.$store.dispatch("bloodbank/listAndFillBloodDonations")
      // }
      // // List&Fill Requests
      // if (this.$store.state.bloodbank.requests.length < 1) {
      //   await this.$store.dispatch("bloodbank/listAndFillBloodRequests")
      // }
      // List Vets
      if (this.$store.state.hospital.vets.length < 1) {
        await this.$store.dispatch("hospital/listVets");
      }
      // List Hospitals
      if (this.hospitals.length < 1) {
        await this.$store.dispatch("hospital/listHospitals");
      }
      // // List Blood Bags
      // if (this.bloodBags.length < 1) {
      //   await this.$store.dispatch("bloodbank/listAndFillBloodCollectInfo")
      // }

      // List Requests
      if (this.requestApprovalList.length < 1) {
        await this.$store.dispatch(
          "bloodbank/listBloodHospitalRequestApprovals"
        );
      }
      if (this.requestList.length < 1) {
        await this.$store.dispatch("bloodbank/listBloodHospitalRequests");
      }
    } catch (e) {
      await this.$store.dispatch("ui/showFetchErrorMsg");
    }

    // set detail
    this.getRequestDetail();
    this.getApprovalDetail();

    // set default vet
    if (
      this.vetList[0] &&
      !(typeof this.approvalDetail.approver === "number")
    ) {
      this.approvalDetail.approver = this.vetList[0].id;
    }

    await this.$store.dispatch("ui/setIsFullScreenLoading", false);
  }
};
</script>

<style lang="scss" scoped>
.title {
  margin: 0;
  padding: 0;
  font-size: 16px;
  margin-bottom: 5px;
}

.topic {
  color: #6a6a6a;
  font-size: 18px;
  font-weight: 600;

  &.head {
    font-size: 34px;
  }
}

.detail {
  font-size: 18px;
  /*font-weight: 600;*/

  &.head {
    font-size: 34px;
  }
}
</style>
