<template>
  <div
    class="request-step-6"
    style="width: 100%"
  >
    <el-row>
      <slot
        class="headSlot"
        name="header"
        :currentProgress="this.myProgress"
        :currentText="this.myText"
        :currentVetType="myVetType"
      ></slot>
    </el-row>

    <el-row>
      <el-row class="medical-record-card">
        <el-col>
          <img
            src="@/assets/bloodbank/file.png"
            height="20"
          />
          <span>ยืนยันการรับถุงเลือด</span>
        </el-col>
      </el-row>
    </el-row>

    <!-- Content -->
    <el-card>
      <div class="row">
        <div class="col-md-24 col-xs-24 col-sm-24">
          <span
            v-if="!hasSelectedBag"
            style="font-weight: bold; font-size: 20px; color: red;"
          >กรุณากลับไปเลือกถุงเลือดอย่างน้อย 1 ถุง</span>
          <blood-confirmation
            v-bind:key="index"
            v-for="(bloodBag,index) in request.reserveBlood.bloodBagList"
            v-if="bloodBag.isSelected"
            v-bind:bloodBagId="bloodBag.crossmatchInfo.bloodBagId1 != null ? bloodBag.crossmatchInfo.bloodBagId1 : bloodBag.crossmatchInfo.blood_collection_1"
            v-bind:isMajorPassed="bloodBag.crossmatchInfo.isMajor"
            v-bind:isMinorPassed="bloodBag.crossmatchInfo.isMinor"
            v-bind:pcvValue="getBloodBagInfo(bloodBag.crossmatchInfo.bloodBagId1) ? getBloodBagInfo(bloodBag.crossmatchInfo.bloodBagId1).pcv : '-'"
            v-bind:bloodType="getBloodBagInfo(bloodBag.crossmatchInfo.bloodBagId1) ? getBloodBagInfo(bloodBag.crossmatchInfo.bloodBagId1).bloodType : '-'"
            v-bind:quantity="bloodBag.quantity"
            v-bind:remainingQuantity="getBloodBagInfo(bloodBag.crossmatchInfo.bloodBagId1) ? getBloodBagInfo(bloodBag.crossmatchInfo.bloodBagId1).quantity: '-'"
          ></blood-confirmation>
          <!-- <div class="flex-start">
                <span style="color:red">* คำแนะนำเพิ่มเติม</span><br>
                <span>ควรสังเกตอาการน้ำท่วมปอดเป็นพิเศษ ในกรณีที่มีอาการไอแห้ง</span>
          </div>-->
          <hr style="border:solid 1px" />
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import BloodConfirmation from "@/components/bloodbank/blood-request/BloodRequestConfirmBloodBag";

export default {
  components: {
    "blood-confirmation": BloodConfirmation
  },
  props: {
    request: Object,
    mode: {
      default: "view"
    }
  },

  data() {
    return {
      myProgress: 4,
      myText: "Blood Request Confirmation",
      myVetType: "ผู้รับถุงเลือด"
    };
  },
  computed: {
    ...mapState({
      diagnosis: state => state.bloodbank.diagnosis,
      profile: state => state.hospital.profile
    }),
    ...mapGetters({
      getBloodBagById: "bloodbank/getBloodBagById",
      getOwnedBloodBagList: "bloodbank/getOwnedBloodBagList"
    }),
    hasSelectedBag: function() {
      return this.request.reserveBlood.bloodBagList.some(bag => bag.isSelected);
    }
  },

  methods: {
    getBloodBagInfo: function(id) {
      return this.getBloodBagById(id);
    },
    async beforeChangeStep() {
      let isValidationPassed = true;
      if (this.request.receiveBlood.approver === "") {
        isValidationPassed = false;
      }

      let bagToValidateQuantity = [];
      this.request.reserveBlood.bloodBagList.map(item => {
        if (item.isSelected) {
          bagToValidateQuantity.push({
            id: item.crossmatchInfo.bloodBagId1,
            quantity: item.quantity
          });
        }
      });

      await this.$confirm(
        "คุณยืนยันการรับถุงเลือด ใช่หรือไม่ ? คุณจะไม่สามารถกลับมาแก้ไขข้อมูลใดๆ กับการขอเบิกเลือดครั้งนี้ได้อีก ยกเว้น ผลจากการรับเลือด และถุงเลือดที่เบิกจะถูกตัดออกจากระบบ",
        "คำเตือน"
      )
        .then(() => {
          this.$store
            .dispatch(
              "bloodbank/validateBloodBagQuantity",
              bagToValidateQuantity
            )
            .then(() => {
              if (!isValidationPassed) {
                throw "กรุณากรอกข้อมูลให้ถูกต้อง";
              } else {
                // Pass !
              }
            })
            .catch(err => {
              if (err) {
                err.map(invalidBag => {
                  throw `ปริมาตรถุงเลือด ID: ${
                    invalidBag.id
                  } ไม่เพียงพอ เนื่องจากปริมาตรคงเหลือ ${
                    invalidBag.remaining
                  } ml.`;
                });
              }
            });
        })
        .catch(() => {
          throw "ยกเลิก";
        });
    }
  }
};
</script>

<style lang="scss">
.request-step-6 {
  .header-container {
    width: calc(100% + 5px);
    height: 50px;
    border-radius: 6px;
    background-color: #5c9ba3;
    text-align: center;
  }

  .profile-row-container {
    height: 55px;
  }

  .el-input-group__append {
    padding: 0px !important;
  }

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100% !important;
  }
}
</style>
