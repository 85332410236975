import BloodDonationPage from '@/pages/bloodbank/blood-donate/BloodDonationPage.vue'
import BloodDonationProfilePage from '@/pages/bloodbank/blood-donate/BloodDonationProfilePage.vue'
import BloodBankPetSelectPage from '@/pages/bloodbank/common/BloodBankPetSelectPage.vue'
import BloodDonationTimelinePage from '@/pages/bloodbank/blood-donate/BloodDonationTimelinePage'
import BloodDonationStepExam from '@/pages/bloodbank/blood-donate/BloodDonationStep1Exam'
import BloodDonationStepLab from '@/pages/bloodbank/blood-donate/BloodDonationStep2Lab'
import BloodDonationStepCare from '@/pages/bloodbank/blood-donate/BloodDonationStep3Care'
import BloodDonationStepCollect from '@/pages/bloodbank/blood-donate/BloodDonationStep4Collect'

import BloodRequestPage from '@/pages/bloodbank/blood-request/BloodRequestPage.vue'
import BloodRequestProfilePage from '@/pages/bloodbank/blood-request/BloodRequestProfilePage.vue'
import BloodRequestTimeline from '@/pages/bloodbank/blood-request/BloodRequestTimeline'
import BloodRequestStepExam from '@/pages/bloodbank/blood-request/BloodRequestStep1Exam'
import BloodRequestStepLab from '@/pages/bloodbank/blood-request/BloodRequestStep2Lab'
import BloodRequestStepRequest from '@/pages/bloodbank/blood-request/BloodRequestStep3Request'
import BloodRequestStepCross from '@/pages/bloodbank/blood-request/BloodRequestStep4Cross'
import BloodRequestStepReserve from '@/pages/bloodbank/blood-request/BloodRequestStep5Reserve'
import BloodRequestStepConfirm from '@/pages/bloodbank/blood-request/BloodRequestStep6Confirm'
import BloodRequestStepResult from '@/pages/bloodbank/blood-request/BloodRequestStep7Result'

import BloodInventoryPage from '@/pages/bloodbank/blood-storage/BloodInventoryPage'
import BloodInventoryDetailPage from '@/pages/bloodbank/blood-storage/BloodInventoryDetailPage'
import BloodRequestHospitalPage from '@/pages/bloodbank/blood-request-hospital/BloodRequestHospitalPage'
import BloodRequestHospitalDetailPage from '@/pages/bloodbank/blood-request-hospital/BloodRequestHospitalDetailPage'
import BloodRequestHospitalNewPage from '@/pages/bloodbank/blood-request-hospital/BloodRequestHospitalNewPage'


const routes = [
	// DONATE
	{
		path: 'bloodbank/donation',
		name: 'donation',
		component: BloodDonationPage,
		meta: {
			requiresAuth: true,
			permissions: ['hospital_admin']
		}
	},
	// DONATE PROFILE
	{
		path: 'bloodbank/donation/profile/:id/',
		name: 'donationProfile',
		component: BloodDonationProfilePage,
		redirect: { name: 'donationTimeline' },
		meta: {
			requiresAuth: true,
			permissions: ['hospital_admin'],
			plan_permissions: ['hospital_feature.can_use_smart_pet_blood_bank']
		},
		children: [
			{
				path: 'timeline',
				name: 'donationTimeline',
				component: BloodDonationTimelinePage,
				meta: {
					requiresAuth: true,
					permissions: ['hospital_admin'],
					plan_permissions: ['hospital_feature.can_use_smart_pet_blood_bank']
				}
			},
			{
				path: 'physicalExam',
				name: 'donationExam',
				component: BloodDonationStepExam,
				meta: {
					requiresAuth: true,
					permissions: ['hospital_admin'],
					plan_permissions: ['hospital_feature.can_use_smart_pet_blood_bank']
				}
			},
			{
				path: 'lab',
				name: 'donationLab',
				component: BloodDonationStepLab,
				meta: {
					requiresAuth: true,
					permissions: ['hospital_admin'],
					plan_permissions: ['hospital_feature.can_use_smart_pet_blood_bank']
				}
			},
			{
				path: 'donorCare',
				name: 'donationCare',
				component: BloodDonationStepCare,
				meta: {
					requiresAuth: true,
					permissions: ['hospital_admin'],
					plan_permissions: ['hospital_feature.can_use_smart_pet_blood_bank']
				}
			},
			{
				path: 'bloodCollect',
				name: 'donationCollect',
				component: BloodDonationStepCollect,
				meta: {
					requiresAuth: true,
					permissions: ['hospital_admin'],
					plan_permissions: ['hospital_feature.can_use_smart_pet_blood_bank']
				}
			}
		]
	},
	// DONATE CREATE
	{
		path: 'bloodbank/donation/create/',
		name: 'donationCreateSelect',
		component: BloodBankPetSelectPage,
		props: { forPage: 'donation' },
		meta: {
			requiresAuth: true,
			permissions: ['hospital_admin'],
			plan_permissions: ['hospital_feature.can_use_smart_pet_blood_bank']
		}
	},
	{
		path: 'bloodbank/donation/create/:id/',
		name: 'donationCreate',
		component: BloodDonationProfilePage,
		redirect: { name: 'donationCreateExam' },
		props: { mode: 'create' },
		meta: {
			requiresAuth: true,
			permissions: ['hospital_admin'],
			plan_permissions: ['hospital_feature.can_use_smart_pet_blood_bank']
		},
		children: [
			{
				path: 'physicalExam',
				name: 'donationCreateExam',
				component: BloodDonationStepExam,
				meta: {
					requiresAuth: true,
					permissions: ['hospital_admin'],
					plan_permissions: ['hospital_feature.can_use_smart_pet_blood_bank']
				}
			}
		]
	},
	// REQUEST
	{
		path: 'bloodbank/request',
		name: 'request',
		component: BloodRequestPage,
		meta: {
			requiresAuth: true,
			permissions: ['hospital_admin'],
			plan_permissions: ['hospital_feature.can_use_smart_pet_blood_bank']
		}
	},
	// REQUEST PROFILE
	{
		path: 'bloodbank/Request/profile/:id/',
		name: 'RequestProfile',
		component: BloodRequestProfilePage,
		redirect: { name: 'RequestTimeline' },
		meta: {
			requiresAuth: true,
			permissions: ['hospital_admin'],
			plan_permissions: ['hospital_feature.can_use_smart_pet_blood_bank']
		},
		children: [
			{
				path: 'timeline',
				name: 'requestTimeline',
				component: BloodRequestTimeline,
				meta: {
					requiresAuth: true,
					permissions: ['hospital_admin'],
					plan_permissions: ['hospital_feature.can_use_smart_pet_blood_bank']
				}
			},
			{
				path: 'physicalExam',
				name: 'requestExam',
				component: BloodRequestStepExam,
				meta: {
					requiresAuth: true,
					permissions: ['hospital_admin'],
					plan_permissions: ['hospital_feature.can_use_smart_pet_blood_bank']
				}
			},
			{
				path: 'lab',
				name: 'requestLab',
				component: BloodRequestStepLab,
				meta: {
					requiresAuth: true,
					permissions: ['hospital_admin'],
					plan_permissions: ['hospital_feature.can_use_smart_pet_blood_bank']
				}
			},
			{
				path: 'request',
				name: 'requestRequest',
				component: BloodRequestStepRequest,
				meta: {
					requiresAuth: true,
					permissions: ['hospital_admin'],
					plan_permissions: ['hospital_feature.can_use_smart_pet_blood_bank']
				}
			},
			{
				path: 'crossmatch',
				name: 'requestCross',
				component: BloodRequestStepCross,
				meta: {
					requiresAuth: true,
					permissions: ['hospital_admin'],
					plan_permissions: ['hospital_feature.can_use_smart_pet_blood_bank']
				}
			},
			{
				path: 'reserve',
				name: 'requestReserve',
				component: BloodRequestStepReserve,
				meta: {
					requiresAuth: true,
					permissions: ['hospital_admin'],
					plan_permissions: ['hospital_feature.can_use_smart_pet_blood_bank']
				}
			},
			{
				path: 'confirm',
				name: 'requestConfirm',
				component: BloodRequestStepConfirm,
				meta: {
					requiresAuth: true,
					permissions: ['hospital_admin'],
					plan_permissions: ['hospital_feature.can_use_smart_pet_blood_bank']
				}
			},
			{
				path: 'result',
				name: 'requestResult',
				component: BloodRequestStepResult,
				meta: {
					requiresAuth: true,
					permissions: ['hospital_admin'],
					plan_permissions: ['hospital_feature.can_use_smart_pet_blood_bank']
				}
			}
		]
	},
	// REQUEST CREATE
	{
		path: 'bloodbank/request/create/',
		name: 'requestCreateSelect',
		component: BloodBankPetSelectPage,
		props: { forPage: 'request' },
		meta: {
			requiresAuth: true,
			permissions: ['hospital_admin'],
			plan_permissions: ['hospital_feature.can_use_smart_pet_blood_bank']
		}
	},
	{
		path: 'bloodbank/request/create/:id/',
		name: 'requestCreate',
		component: BloodRequestProfilePage,
		redirect: { name: 'requestCreateExam' },
		props: { mode: 'create' },
		meta: {
			requiresAuth: true,
			permissions: ['hospital_admin'],
			plan_permissions: ['hospital_feature.can_use_smart_pet_blood_bank']
		},
		children: [
			{
				path: 'physicalExam',
				name: 'requestCreateExam',
				component: BloodRequestStepExam,
				meta: {
					requiresAuth: true,
					permissions: ['hospital_admin'],
					plan_permissions: ['hospital_feature.can_use_smart_pet_blood_bank']
				}
			},
			{
				path: 'lab',
				name: 'requestCreateLab',
				component: BloodRequestStepLab,
				meta: {
					requiresAuth: true,
					permissions: ['hospital_admin'],
					plan_permissions: ['hospital_feature.can_use_smart_pet_blood_bank']
				}
			},
			{
				path: 'request',
				name: 'requestCreateRequest',
				component: BloodRequestStepRequest,
				meta: {
					requiresAuth: true,
					permissions: ['hospital_admin'],
					plan_permissions: ['hospital_feature.can_use_smart_pet_blood_bank']
				}
			}
		]
	},


	// REQUEST Between HOSPITAL
	{
		path: 'bloodbank/request-hospital',
		name: 'requestHospital',
		component: BloodRequestHospitalPage,
		meta: {
			requiresAuth: true,
			permissions: ['hospital_admin'],
			plan_permissions: ['hospital_feature.can_use_smart_pet_blood_bank']
		}
	},
	{
		path: 'bloodbank/request-hospital/form',
		name: 'requestHospitalForm',
		component: BloodRequestHospitalNewPage,
		meta: {
			requiresAuth: true,
			permissions: ['hospital_admin'],
			plan_permissions: ['hospital_feature.can_use_smart_pet_blood_bank']
		}
	},
	{
		path: 'bloodbank/request-hospital/:id/',
		name: 'requestHospitalDetail',
		component: BloodRequestHospitalDetailPage,
		meta: {
			requiresAuth: true,
			permissions: ['hospital_admin'],
			plan_permissions: ['hospital_feature.can_use_smart_pet_blood_bank']
		},
	},

	// BLOOD INVENTORY
	{
		path: 'bloodbank/inventory',
		name: 'inventory',
		component: BloodInventoryPage,
		meta: {
			requiresAuth: true,
			permissions: ['hospital_admin'],
			plan_permissions: ['hospital_feature.can_use_smart_pet_blood_bank']
		}
	},
  {
    path: 'bloodbank/inventory/:id/',
    name: 'inventoryDetail',
    component: BloodInventoryDetailPage,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
	  plan_permissions: ['hospital_feature.can_use_smart_pet_blood_bank']
    },
  },
]

export default routes
