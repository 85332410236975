<template>
  <div>
    <div class="search-section">
      <el-input
        placeholder="ค้นหาชื่อ กิจกรรม, ผลิตภัณฑ์, บริษัท"
        v-model="searchValue"
        class="search-input"
        clearable
        @keyup.enter.native="search"
        @clear="clearSearch"
      >
        <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
      </el-input>
    </div>
    <div class="activity-breadcrumb" v-show="!isSearchMode">
      <template v-if="activities">
        <template v-if="$route.query.is_favorite">Favorite</template>
        <template v-else>{{ groupName }}</template>
        ({{ activities.length }}
        <template v-if=" activities.length > 1">Activities</template>
        <template v-else>Activity</template>)
      </template>
      <template v-else>
        <i class="el-icon-loading"/>
      </template>
    </div>
    <div class="activity-groups-section" v-show="!isSearchMode">
      <ActivityGroups
        :groups="activityGroups"
        :selectedGroupId="selectedGroupId"
        :selectedSubGroupId="selectedSubGroupId"
        :isFavorite="isFavorite"
        @click="changeGroupd"
      />
    </div>

    <ContentBox>
      <div class="activity-list" v-if="activities">
        <div v-for="activity in activities" :key="activity.id" class="activity-item-wrapper">
          <ActivitySelectableItem :activity="activity" @click="selectActivity" :isSelected="isActivitySelected(activity.id)"/>
        </div>
      </div>
      <div class="not-found" v-if="activities && activities.length === 0">ไม่พบข้อมูลที่ต้องการค้นหา</div>
    </ContentBox>
  </div>
</template>

<script>
import ContentBox from "@/components/container/ContentBox"
import ActivityGroups from "@/components/activity/ActivityGroups"
import ActivitySelectableItem from "@/components/activity/ActivitySelectableItem"

export default {
  props: [
    "activities", 
    "activityGroups", 
    "isFavorite", 
    "selectedGroupId", 
    "selectedSubGroupId",
    "selectedActivities",
    "searchText"
  ],
  components: {
    ContentBox,
    ActivityGroups,
    ActivitySelectableItem
  },
  data() {
    return {
      searchValue: this.searchText,
    }
  },
  computed: {
    isSearchMode() {
      return !!this.$route.query.search
    },
    groupName() {
      const group = this.activityGroups.find(
        group => group.id === this.selectedGroupId
      )
      let groupName = group ? group.name : ""
      if (group && group.sub_group) {
        const supGroup = group.sub_group.find(
          group => group.id === this.selectedSubGroupId
        )
        groupName += supGroup ? ` > ${supGroup.name}` : ""
      }
      return groupName
    }
  },
  methods: {
    isActivitySelected(id) {
      return !!this.selectedActivities.find(item => item.id === id)
    },
    search() {
      this.$emit('search', this.searchValue)
    },
    clearSearch() {
      this.$emit('clearSearch')
    },
    changeGroupd(data) {
      this.$emit('changeGroup', data)
    },
    selectActivity(activity) {
      let selectedActivities = this.selectedActivities
      if (this.isActivitySelected(activity.id)) {
        selectedActivities = this.deleteActivity(activity)
      } else {
        selectedActivities.push(activity)
      }
      this.$emit('updateSelected', selectedActivities)
    },
    deleteActivity(activity) {
      return this.selectedActivities.filter(
        item => item.id !== activity.id
      )
    },
  },
  watch: {
    async searchText(newValue, oldValue) {
      if(newValue !== oldValue) {
        this.searchValue = newValue
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.search-section {
  margin-bottom: 8px;
}
.activity-breadcrumb {
  padding: 2px 0;
  margin: 0;
  height: 20px;
  font-size: 16px;
  line-height: 1em;
  font-weight: normal;
  color: #333;
}
.not-found {
  text-align: center;
}
.activity-item-wrapper {
  display: inline-block;
  width: calc(20% - 10px);
  padding: 5px;
}
</style>

