<template>
  <el-card>
    <h1 class='header'>{{(request && (mode === 'view')) ? `Request ${request.id}` : 'Recipient Profile'}}</h1>
    <el-row v-if='request'>
      <el-col :span='3'>
        <img :src='request.petInfo.imageUrl' />
      </el-col>
      <el-col :span='7'>
        <el-row>{{ request.petInfo.type }} | {{ request.petInfo.breed.name }} </el-row>
        <el-row>Name: {{ request.petInfo.name }} </el-row>
        <el-row>H.N. {{ request.petInfo.hospitalNumber }} </el-row>
        <el-row>เจ้าของ: {{ request.petInfo.owners.length > 0 ? request.petInfo.owners[0].firstname : '' }} {{ request.petInfo.owners.length > 0 ? request.petInfo.owners[0].lastname : '' }} </el-row>
        <el-row>เบอร์: {{ request.petInfo.owners.length > 0 ? request.petInfo.owners[0].phone : '' }} </el-row>
      </el-col>
      <el-col :span='7'>
        <el-row>Request :</el-row>
        <el-row><b>{{ request.recipientProfileInfo.productRequest.selectedBloodProduct}} {{ request.recipientProfileInfo.productRequest.quantity}} ml.</b></el-row>
        <el-row>น้ำหนัก : {{ weight }} kg.</el-row>
        <el-row>PCV : {{ pcv }}</el-row>
        <el-row>สาเหตุ : {{ getDiagnosis() ? getDiagnosis() : '-' }}</el-row>
        <el-row>อาการ : {{ getTechnicalSign() ? getTechnicalSign() : '-'}}</el-row>
      </el-col>
      <el-col :span='7'>
        <el-row>วันที่ใช้เลือด:</el-row>
        <el-row><b>{{ getReserveDate() }}</b></el-row>
        <el-row>วันที่ขอเลือด: {{ getFormattedDate(request.createDate) }} </el-row>
        <el-row>ผู้ขอ: {{ getRequesterName(request.recipientProfileInfo.productRequest.requester) ? getRequesterName(request.recipientProfileInfo.productRequest.requester) : '-' }} </el-row>
        <el-row>แผนก: -</el-row>
      </el-col>
      <el-button type='primary' size='mini' @click="$router.push({ name: 'hospitalPetProfile', params: { id: request.petInfo.systemId }})">Pet detail</el-button>
    </el-row>
  </el-card>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex';

export default {
  props: {
    request: Object,
    mode : {
      default: 'view'
    }
  },
  computed: {
    ...mapState({
      requests: state => state.bloodbank.requests,
      diagnosisList: state => state.bloodbank.diagnosis,
      vets: state => state.hospital.vets
    }),
    pcv: function () {
        if (this.request.isRecipientProfileSubmitted) {
            return `${Number(this.request.recipientProfileInfo.labExam.HCT) } -> ${ this.request.recipientProfileInfo.productRequest.targetPcv}`
        } else if (this.request.recipientProfileInfo.isLabExamSubmitted) {
            return Number(this.request.recipientProfileInfo.labExam.HCT)
        } else {
            return '-'
        }
      },
      weight: function () {
          if (this.request.recipientProfileInfo.isRecipientProfileSubmitted) {
              return this.request.recipientProfileInfo.medicalRecord.weight
          } else {
              return '-'
          }
      }
  },
  methods: {
    getDiagnosis() {
        
        var diagnosisSelected = this.request.recipientProfileInfo.medicalRecord.diagnosis
        var diagnosisText = ''
        this.diagnosisList.forEach(diagnosis => {
            if(diagnosis.diagnosis_type === 3 ) {
                diagnosisSelected.forEach(item => {
                    if(item === diagnosis.id){
                        diagnosisText += ' ' + diagnosis.tag
                    }
                })
            }
        })
        return diagnosisText
    },

    getTechnicalSign() {
        var diagnosisSelected = this.request.recipientProfileInfo.medicalRecord.diagnosis
        var technicalSign = ''
        this.diagnosisList.forEach(diagnosis => {
            if(diagnosis.diagnosis_type === 1 ) {
                diagnosisSelected.forEach(item => {
                    if(item === diagnosis.id){
                        technicalSign += ' ' + diagnosis.tag
                    }
                })
            }
        })
        return technicalSign
    },

    getReserveDate() {
        if(this.request.recipientProfileInfo.medicalRecord.isImmediatelyRequired){
            return 'เร็วที่สุด'
        } else {
            return this.getFormattedDate(this.request.recipientProfileInfo.medicalRecord.reserveDate)
        }
    },

    getRequesterName(requesterId){
        var requesterName = ''
        this.vets.forEach(employee => 
        { 
            if(employee.id === requesterId) { 
                requesterName = employee.title + ' ' + employee.first_name + ' ' + employee.last_name
                return 
            }
        })
        return requesterName
    },

    getFormattedDate: function (date) {
      if (date == null) {
        return '-'
      }

      let latestDonationDate = new Date(date)
      let day
      let i = latestDonationDate.getDate()
      let j = i % 10
      let k = i % 100

      day = i + 'th'

      if (j === 1 && k !== 11) {
        day = i + 'st'
      }
      if (j === 2 && k !== 12) {
        day = i + 'nd'
      }
      if (j === 3 && k !== 13) {
        day = i + 'rd'
      }

      let currentDate = day + ' ' + moment(latestDonationDate).format('MMM, YY')
      return currentDate
    },

    getPetAge: function (date) {
      var currentDate = new Date()
      var timeDiff = Math.abs(currentDate.getTime() - date.getTime())
      var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
      var diffMonth = parseInt(diffDays / 30 % 12)
      var diffYear = parseInt(diffDays / 30 / 12)
      return (diffYear > 0 ? diffYear + ' ปี' : '') + (diffMonth > 0 || diffYear <= 0 ? diffMonth + ' เดือน' : '')
    }
  }

}
</script>

<style scoped>
  .el-card {
    width: 100%;
  }

  .header {
    text-align: center;
  }
</style>
