<template>
  <div class="file-previewer clearfix">
    <div class="file-previewer-card">
      <div v-if="fileExt !== 'pdf'" class="thumbnail" @click="handlePictureCardPreview(file)">
        <img :src="file.attachment">
        <div class="overlay">
          <i class="fa fa-search-plus"></i>
        </div>
      </div>

      <div v-if="fileExt === 'pdf'" class="thumbnail" @click="handelDownload(file)">
        <i class="fa fa-file-pdf"></i>
        <div class="overlay">
          <i class="fa fa-download"></i>
        </div>
      </div>
    </div>
    <i v-if="showDeleteButton" class="fa fa-times-circle" @click="$emit('delete', file)"></i>

    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    file: {
      type: Object
    },
    showDeleteButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false
    }
  },
  computed: {
    fileExt() {
      return this.file.attachment 
        ? this.file.attachment.split(/[#?]/)[0].split('.').pop().trim()
        : undefined;
    },
  },
  methods: {
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.attachment
      this.dialogVisible = true
    },
    handelDownload(file) {
      var link = document.createElement("a")
      link.target = "_blank"
      link.href = file.attachment
      link.download = "file.pdf"
      link.dispatchEvent(new MouseEvent("click"))
    }
  }
}
</script>


<style lang="scss" scoped>
.file-previewer {
  position: relative;
}
.file-previewer-card {
  position: relative;
  display: inline-block;
  overflow: hidden;
  border: 1px solid #d9d9d9;
  width: 100%;
  height: 100%;
}

.thumbnail {
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100%;

  img {
    height: 100%;
  }
}
.thumbnail .overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
  transition: opacity 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;

  &:hover {
    opacity: 1;
  }

  > i {
    color: #fff;
    font-size: 2.4em;
    opacity: 0.8;
  }
}

.fa-file-pdf {
  font-size: 3em;
  color: #e12025;
}
.fa-times-circle {
  cursor: pointer;
  position: absolute;
  right: -3px;
  top: -3px;
  z-index: 5;
}
</style>

