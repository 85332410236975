import RewardSettingList from '@/pages/reward/RewardSettingList.vue'

const routes = [
  {
    path: '/reward-setting',
    name: 'rewardSetting',
    component: RewardSettingList,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['setting_section.can_use_redeem_setting']
    }
  }
]

export default routes