<script>
import moment from "moment";

export default {
  data() {
    return {
      visible: false,
      month: '',
    };
  },

  mounted() {
    this.visible = true;
  },

  methods: {
    emit() {
      if (!this.month) return

      const startOfMonth = moment(this.month).startOf('month');
      const endOfMonth = moment(this.month).endOf('month');

      this.$emit("action", {'start': new Date(startOfMonth), 'end': new Date(endOfMonth)});

      this.visible = false;
    },

    handleClose() {
      this.$emit("close");

      this.visible = false;
    },

    handleClosed() {
      this.$destroy();
    },
  },

  render() {
    const buttons = (() => {
      const cancelButton = (
        <el-button
          style={{ width: "130px" }}
          onClick={this.handleClose}>
          Cancel
        </el-button>
      );

      const confirmButton = (
        <el-button
          type="primary"
          style={{ width: "130px" }}
          onClick={() => {
            this.emit();
          }}
        >
          Export
        </el-button>
      );

      return [cancelButton, confirmButton]
    })();

    return (
      <el-dialog
        title="Export OPD"
        center
        width="50%"
        visible={this.visible}
        onClose={this.handleClose}
        onClosed={this.handleClosed}
      >
        <el-row type="flex" gutter={20} align="middle" justify="center">
          <el-col span={null}>
            <div 
              style={{ color: "#02b0e1", marginBottom: "8px" }}>
              เลือกเดือน
            </div>
          </el-col>
          <el-col span={null}>
            <el-date-picker
              v-model={this.month}
              type="month"
              placeholder="เลือกเดือน">
            </el-date-picker>
          </el-col>
        </el-row>
        <p style={{ fontSize: "12px", textAlign: "center" }}>
          เลือกเดือนที่ต้องการ export OPD โดยจะ export ตั้งแต่วันแรกจนถึงวันสุดท้ายของเดือนนั้นๆ
        </p>

        <el-row
          slot="footer"
          type="flex"
          gutter={16}
          justify="center"
          style={{ flexWrap: "wrap" }}
        >
          {buttons.map((vnode) => (
            <el-col span={null}>{vnode}</el-col>
          ))}
        </el-row>
      </el-dialog>
    );
  },
};
</script>
