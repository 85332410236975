var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pet-medical-record-timeline"},[(_vm.timeline_items.length > 0)?_c('el-timeline',[_c('el-timeline-item'),_vm._l((_vm.timeline_items),function(item,index){return _c('el-timeline-item',{key:index,class:`${item.type}`},[(item.type === 'medicalrecord_medicalrecord')?_c('MedicalRecordItem',{attrs:{"date":item.date,"item":item.detail},on:{"click":_vm.navigateToDetail}}):(item.type === 'medicalrecord_ipd')?_c('IPDItem',{attrs:{"id":item.detail.id,"arrived":item.detail.arrived
                      ? new Date(item.detail.arrived)
                      : undefined,"finished":item.detail.finished
                      ? new Date(item.detail.finished)
                      : undefined,"status":item.detail.overall_status === 'finished' 
                    || item.detail.overall_status === 'overdue'
                      ? item.detail.overall_status === 'finished'
                            ? 'finish' : 'overdue'
                      : 'active',"deposit":item.detail.deposit,"paid":item.detail.paid,"overdue":item.detail.unpaid,"vets":item.detail.vets},on:{"view":_vm.navigateToIPDDetail}}):(item.type === 'pet_petbloodgroup')?_c('PetBloodGroupItem',{attrs:{"date":item.date,"item":item.detail}}):(item.type === 'pet_petmicrochip')?_c('PetMicrochipItem',{attrs:{"date":item.date,"item":item.detail}}):(item.type === 'pet_petweight')?_c('PetWeightItem',{attrs:{"date":item.date,"item":item.detail}}):(item.type === 'bloodbank_bloodrequest')?_c('PetBloodRequestItem',{attrs:{"date":item.date,"item":item.detail}}):(item.type === 'bloodbank_blooddonation')?_c('PetBloodDonationItem',{attrs:{"date":item.date,"item":item.detail}}):_vm._e()],1)}),_c('el-timeline-item')],2):_c('div',{staticClass:"no-item"},[_vm._v("No timeline")]),(_vm.currentPage < _vm.totalPages)?_c('div',{staticClass:"load-more-container"},[_c('button',{staticClass:"btn btn-small btn-primary",on:{"click":_vm.loadMore}},[_vm._v("Load more...")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }