<template>
  <div class="header-activity-done" v-if="discardable || (currentStep === 4 && pos.receipt || pos.is_updated_stock || !canEditData)">
    <div class="left-activity">
      <ContentBox class="header-done" style="margin-top: 10px;">
        <div class="title-done">Activity</div>
        <template v-if="currentStep === 4 || pos.is_updated_stock || discardable || !canEditData">
          <hr class="line" />
          <el-row class="actors">
            <el-col :span="12">
              <div class="actor text-left">สัตวแพทย์ <span class="detail">: {{ actors.vet }}</span></div>
              <div class="actor text-left">บันทึกล่าสุด<span class="detail">: {{ actors.editor }}</span></div>
              <div class="actor text-left" 
                v-if="discardable && actors.deleted_by">
                Deleted by<span class="detail">: {{ actors.deleted_by }}</span>
              </div>
            </el-col>
            <el-col :span="12" class="text-right">
              <div class="actor text-right">ผู้รับชำระ<span class="detail">: {{ actors.payee }}</span></div>
              <div class="actor text-right">Reciept ID
                <span class="detail" v-if="pos.receipt">: {{ pos.receipt.receipt_number }}</span>
                <span class="detail" v-else>-</span>
              </div>
            </el-col>
          </el-row>
          <hr class="line" />
        </template>
        <div
          class="content-done"
          v-for="activity in selectedActivities"
          :key="activity.id"
        >
          <div class="amount-done">
            <div class="header-text">{{ activity.amount }}</div>
            <div>X</div>
          </div>
          <div class="name-done">
            <div class="text-normal">{{ activity.name }}</div>
            <ActivityInfo
              :amount="activity.amount"
              :price-per-unit="+activity.unit_price"
              :discount="+activity.discount"
              :vat="activity.activity.is_has_vat"
              :products="
                (activity.posactivityproduct_set)
                  ? activity.posactivityproduct_set.map((item) => ({
                      name: item.product.trade_name,
                      amount: item.amount,
                    }))
                  : []
              "
              :style="{ fontSize: '0.75rem', color: '#02b0e1' }"
            />
            <!-- <template v-if="activity.products && activity.products.length > 0">
              <div class="sub-text">{{ activity.products[0].trade_name || "-" }}</div>
            </template> -->
          </div>
          <div class="total-done text-normal">
            {{ (activity.total || 0) | currency }}฿
          </div>
        </div>
        <template v-if="currentStep === 4">
          <hr class="line" />
          <div class="lower-done text-gray">
            <div>รวมรายการที่ยกเว้นภาษี</div>
            {{ pos.grand_total_price_no_vat_only | currency }}฿
          </div>
          <div class="lower-done text-gray">
            <div>ราคาสุทธิสินค้าบริการที่เสียภาษี</div>
            {{ pos.grand_total_price_exclude_vat | currency }}฿
          </div>
          <div class="lower-done text-gray">
            <div>ภาษีมูลค่าเพิ่ม 7%</div>
            {{ pos.total_vat | currency }}฿
          </div>
          <div class="lower-done">
            <div>จำนวนเงินรวมทั้งสิ้น (Grand Total)</div>
            {{ grandTotal | currency }}฿
          </div>
        </template>
        <!-- <div class="lower-done text-gray">
          <div>รวมเป็นเงิน (Subtotal)</div>
          {{ (total || 0) | currency }}฿
        </div>
        <div class="lower-done text-gray">
          <div>ส่วนลด (Special Discount)</div>
          <div v-if="pos.receipt">{{ (pos.receipt.discount || 0) | currency }}฿</div>
          <div v-else>{{ 0 | currency }}฿</div>
        </div>
        <div class="lower-done">
          <div>จำนวนเงินหลังหักส่วนลด (After Discount)</div>
          {{ (totalWithDiscount || 0) | currency }}฿
        </div> -->
        <!-- <div
          v-if="pos && pos.receipt && pos.receipt.payments && !isIPD()"
          style="margin-top: 50px"
        >
          <h1 class="title">ช่องทางรับชำระ</h1>
          <hr />
          <ol style="padding-left: 15px;">
            <li v-for="p in pos.receipt.payments" :key="p.id">
              <div class="lower-done" style="margin-top: 20px;">
                <div>{{ getPaymentDetail(p.payment_method) }}</div>
                {{ (p.incl_fee || 0) | currency }}฿
              </div>
              <el-row v-if="p.payment_method.bank_account_id">
                <el-col :span="14" class="text-small">จำนวน</el-col>
                <el-col :span="10" class="text-small"
                  >{{ (p.amount || 0) | currency }}฿</el-col
                >
              </el-row>
              <el-row
                v-if="
                  p.payment_method.fee_percentage &&
                    parseFloat(p.payment_method.fee_percentage) > 0
                "
              >
                <el-col :span="14" class="text-small"
                  >ค่าธรรมเนียม {{ p.fee_percentage }}%</el-col
                >
                <el-col :span="10" class="text-small"
                  >{{ (p.incl_fee - p.amount) | currency }}฿</el-col
                >
              </el-row>
              <el-row v-if="p.payment_method.bank_account_id">
                <el-col :span="24" class="text-small"
                  >รหัสอ้างอิง {{ p.ref }}</el-col
                >
              </el-row>
            </li>
          </ol>
          <hr />
          <div v-if="pos && pos.receipt && pos.receipt.payments">
            <div class="lower-done text-gray">
              <div>รวมเงินที่รับชำระ</div>
              {{ (pos.receipt.total_paid || 0) | currency }}฿
            </div>
            <hr />
            <div class="lower-done text-gray">
              <div>จำนวนเงินหลังหักส่วนลด</div>
              {{ (totalWithDiscount || 0) | currency }}฿
            </div>
            <div class="lower-done text-gray">
              <div>รวมค่าธรรมเนียม</div>
              {{ (pos.receipt.fee || 0) | currency }}฿
            </div>
            <hr />
            <div class="lower-done">
              <div style="font-size: 18px; font-weight: bold;">
                รวมที่ต้องชำระทั้งสิ้น
              </div>
              {{
                (totalWithDiscount + parseFloat(pos.receipt.fee) || 0)
                  | currency
              }}฿
            </div>
            <hr style="margin-bottom: 0;" />
            <hr style="margin-top: 2px;" />
            <div class="lower-done text-gray">
              <div>เงินทอน</div>
              {{ (pos.receipt.total_change || 0) | currency }}฿
            </div>
          </div>
          <div
            v-if="pos.receipt.attachments && pos.receipt.attachments.length > 0"
            style="margin-top: 30px"
          >
            <h1 class="title">หลักฐาน/สลิป</h1>
            <hr />
            <FilePreviewer
              v-for="file in pos.receipt.attachments"
              :key="file.id"
              style="float: left; width: 110px; height: 110px; margin: 4px;"
              :file="file"
              :showDeleteButton="false"
            />
          </div>
        </div> -->
      </ContentBox>
    </div>
    <div class="right-activity" style="margin-left: 20px; padding-top: 10px;">
      <template v-if="med">
        <!-- <div
          v-if="!isIPD() && med.overall_status === 'finished' && (currentPlan.print_section.can_use_no_tax_print || currentPlan.print_section.can_use_have_tax_print)"
          :style="{ marginBottom: '1em' }"
        >
          <PrintCommand
            v-if="currentPlan.print_section.can_use_no_tax_print"
            header="ใบเสร็จรับเงิน (No Tax)"
            class="print-command"
            @command="handleCommand"
          >
            <PrintCommandButton command="a4/taxless">
              <A4Icon slot="icon" :style="{ marginLeft: '0.5em' }" />A4
            </PrintCommandButton>
            <PrintCommandButton command="a5/taxless">
              <A5Icon slot="icon" />A5
            </PrintCommandButton>
            <PrintCommandButton command="slip/taxless">
              <SlipIcon slot="icon" />Slip
            </PrintCommandButton>
          </PrintCommand>
          <PrintCommand
            v-if="currentPlan.print_section.can_use_have_tax_print"
            header="ใบเสร็จรับเงิน (Tax)"
            class="print-command"
            @command="handleCommand"
          >
            <PrintCommandButton command="a4">
              <A4Icon slot="icon" :style="{ marginLeft: '0.5em' }" />A4
            </PrintCommandButton>
            <PrintCommandButton command="a5">
              <A5Icon slot="icon" />A5
            </PrintCommandButton>
            <PrintCommandButton command="slip">
              <SlipIcon slot="icon" />Slip
            </PrintCommandButton>
          </PrintCommand>
        </div> -->
        <ReceiptCommandGroup @command="handleCommand">
          <ReceiptCommand
            v-if="!discardable && isShowPaymentButton() && currentPlan.opd_section.can_use_opd_payment_step"
            :icon="cashIcon"
            value="payment"
            type="coral"
            >Payment</ReceiptCommand
          >
          <ReceiptCommand
            :icon="pillBottleIcon"
            value="drug_label"
            type="primary"
            v-show="currentPlan.print_section.can_use_drug_label_print"
            >View Drug Label</ReceiptCommand
          >
          <!-- <ReceiptCommand
            v-if="!discardable && !isIPD() && progress_status === 'done'"
            value="void"
            type="danger"
            v-show="currentPlan.receipt_section.can_void_receipt"
            >Void Receipt</ReceiptCommand
          > -->
          <ReceiptCommand 
            v-if="!isShowPaymentButton() && this.pos.receipt" 
            value="receipt" 
            type="success">See Receipt
          </ReceiptCommand>
        </ReceiptCommandGroup>

        <PrintCommand
          v-if="isShowPaymentButton()"
          header="ใบแจ้งค่าใช้จ่าย"
          class="print-command"
          :style="{ marginTop: '1em' }"
          @command="handleInvoiceCommand"
        >
          <PrintCommandButton command="a4">
            <A4Icon slot="icon" />A4
          </PrintCommandButton>
          <PrintCommandButton command="a5">
            <A5Icon slot="icon" />A5
          </PrintCommandButton>
          <PrintCommandButton command="slip">
            <SlipIcon slot="icon" />Slip
          </PrintCommandButton>
        </PrintCommand>

        <PrintCommand
          v-if="isShowPaymentButton()"
          header="ใบแจ้งค่าใช้จ่าย"
          class="print-command"
          :style="{ marginTop: '1em' }"
          @command="handleInvoiceCommand"
        >
          <PrintCommandButton command="a4/group">
            <A4Icon slot="icon" />A4
          </PrintCommandButton>
          <PrintCommandButton command="a5/group">
            <A5Icon slot="icon" />A5
          </PrintCommandButton>
          <PrintCommandButton command="slip/group">
            <SlipIcon slot="icon" />Slip
          </PrintCommandButton>
        </PrintCommand>

        <el-button
          v-if="currentStep !== 4 && !discardable && pos.is_updated_stock"
          v-show="currentPlan.stock_section.can_stock_out_with_unpaid"
          type="danger"
          plain
          :style="{ display: 'block', width: '100%', marginTop: '1em' }"
          @click="handleUndoStockCutButtonClick"
        >
          ยกเลิกตัดสินค้าออกจาก Stock
        </el-button>
        <div 
          v-if="isShowPaymentButton() && !pos.is_updated_stock && currentPlan.stock_section.can_stock_out_with_unpaid"
          :style="{ marginTop: '1em' }">
          <el-button
            size="large"
            :style="{ display: 'block', width: '100%' }"
            @click="handleStockCutButtonClick"
          >
            ตัดสินค้าออกจาก Stock
          </el-button>
          <p :style="{ fontSize: '0.75em', margin: '0.5em 0 0' }">
            * ใช้ในกรณีที่ต้องการตัดสินค้าออกจากคลังโดยที่ยังไม่ชำระเงิน กรณีกลับมาชำระเงินในรายการที่ตัดสินค้าแล้ว จะไม่ตัดซ้ำอีก
          </p>
        </div>
        <!-- <button
          @click="printMedicalNoTax"
          class="btn btn-gray btn-large"
          style="display: block; width: 100%; margin-bottom: 0.5rem"
        >
          <img
            src="@/assets/icons/icon-printer.png"
            style="height: 16px; vertical-align: top; margin-right: 5px;"
          />Print Receipt no tax
        </button>
        <button
          @click="printMedical"
          class="btn btn-gray btn-large"
          style="display: block; width: 100%; margin-bottom: 0.5rem"
        >
          <img
            src="@/assets/icons/icon-printer.png"
            style="height: 16px; vertical-align: top; margin-right: 5px;"
          />Print Receipt A4
        </button>
        <button
          @click="printMedicalCompact"
          class="btn btn-gray btn-large"
          style="display: block; width: 100%; margin-bottom: 0.5rem"
        >
          <img
            src="@/assets/icons/icon-printer.png"
            style="height: 16px; vertical-align: top; margin-right: 5px;"
          />Print Receipt Slip
        </button>
        <button
          @click="toDrugLabelPage"
          v-show="currentPlan.print_section.can_use_drug_label_print"
          class="btn btn-primary btn-large"
          style="display: block; width: 100%;"
        >
          <img
            src="@/assets/icons/icon-pill-bottle.svg"
            style="height: 16px; vertical-align: top; margin-right: 5px;"
          />View Drug Label
        </button> -->
      </template>
    </div>
  </div>
  <div class="header-activity" v-else>
    <div class="left-activity">
      <div class="search-section">
        <el-row :gutter="8" style="display: block;">
          <el-col :span="4">
            <div class="activity-groups-section">
              <ActivityGroups
                v-if="!searchText"
                :groups="activityGroups"
                :selectedGroupId="selectedGroupId"
                :selectedSubGroupId="selectedSubGroupId"
                :isFavorite="isFavorite"
                @click="selectGroup"
              />
            </div>
          </el-col>

          <el-col :span="20">
            <el-input
              placeholder="ค้นหาชื่อ กิจกรรม, ผลิตภัณฑ์, บริษัท"
              v-model="searchText"
              class="search-input"
              clearable
              @keyup.enter.native="search"
              @clear="clearSearch"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="search"
              ></el-button>
            </el-input>

            <div class="activity-breadcrumb">
              <template v-if="showActivities">
                <template>{{ groupName }}</template>
                ({{ activities ? activities.count : 0 }}
                <template v-if="activities && activities.count > 1">Activities</template>
                <template v-else>Activity</template>)
              </template>
              <template v-else>
                <i class="el-icon-loading" />
              </template>
            </div>
            <div :style="{ backgroundColor: '#fff' }">
              <div class="activity-list" v-if="showActivities">
                <div
                  v-if="isFavorite && !searchText"
                  @click="$router.push({ name: 'activity-setting' })"
                  class="add-activity"
                >
                  <div class="thumbnail">
                    <div class="add-select-button">
                      <img
                        src="@/assets/icons/icon-add.png"
                        style="max-width: 100%; max-height: 100%;"
                        alt
                      />
                    </div>
                  </div>
                  create activity
                </div>
                <div
                  v-for="activity in showActivities"
                  :key="activity.id"
                  class="activity-item-wrapper"
                >
                  <ActivitySelectableItem
                    :activity="activity"
                    :isSelected="isActivitySelected(activity.id)"
                    @click="selectActivity"
                  />
                </div>
              </div>
              <div
                class="not-found"
                v-if="showActivities && showActivities.length === 0"
              >
                ไม่พบข้อมูลที่ต้องการค้นหา
              </div>
              <el-pagination
                v-if="activities"
                background
                class="text-center"
                :page-size="50"
                @current-change="handleActivityPageChanged"
                :current-page.sync="activityPage"
                layout="prev, pager, next"
                :total="activities.count">
              </el-pagination>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="right-activity">
      <ContentBox>
        <template slot="header">Detail</template>
        <h3 class="label">
          <img src="@/assets/icons/icon-vaccine.png" />
          กิจกรรม ({{ selectedActivities.length }})
          <span class="required-mark">*</span>
        </h3>
        <div class="activitie-list">
          <transition-group name="fade" tag="div">
            <div
              class="activity-item"
              v-for="activity in selectedActivities"
              :key="activity.id"
            >
              <h4 class="title">{{ activity.name }}</h4>
              <div class="delete-btn" 
                @click="deleteActivity(activity)">
                <i class="el-icon-close"></i>
              </div>
              <template
                v-if="activity.products && activity.products.length > 0"
              >
                <div class="subtitle">
                  {{ activity.products[0].trade_name || "-" }}
                </div>
              </template>
              <div :style="{ display: 'flex' }">
                <el-checkbox
                  style="display: inline-block; margin: 0;"
                  @change="toggleTx(activity)"
                  :checked="activity.is_tx"
                  size="small"
                  label="Tx/ใช้ใน รพ.ส."
                  border
                ></el-checkbox>
                <el-checkbox
                  v-show="activity.products.length > 0"
                  style="display: inline-block; margin: 0 0 0 5px;"
                  @change="toggleRx(activity)"
                  :checked="activity.is_rx"
                  size="small"
                  label="Rx/จ่ายกลับบ้าน"
                  border
                ></el-checkbox>
              </div>
              <template
                v-if="activity.products && activity.products.length > 0"
              >
                <div class="title">Lot No.</div>
                <el-input
                  style="width: 50%;"
                  v-model="activity.lot_no"
                  size="small"
                ></el-input>
              </template>
              <div class="add-amount">
                <el-input
                  style="width: 40%;"
                  v-model="activity.price"
                  :disabled="!canEditPriceAndStockAmount"
                  size="small"
                ></el-input>
                <div class="multiply">X</div>
                <el-input-number
                  size="mini"
                  :min="1"
                  v-model="activity.amount"
                ></el-input-number>
                <!-- <div class="card-amount">
                  <span class="mark" @click="decrease(activity)" style="margin-left: 10px;">-</span>
                  <span style="color: #ccc">|</span>
                  {{activity.amount}}
                  <span style="color: #ccc">|</span>
                  <span class="mark" @click="increase(activity)" style="margin-right: 10px;">+</span>
                </div>-->
              </div>
              <div :style="{ marginBottom: '1rem' }">
                <div class="title">ส่วนลด</div>
                <el-input-number
                  size="mini"
                  :min="0"
                  v-model="activity.discount"
                  :style="{ width: '100%' }"
                />
              </div>
              <div class="price">{{ (activity.total || 0) | currency }}฿</div>

              <!-- display products -->
              <div
                v-if="activity.products.length"
                style="margin-top: 10px; font-size: .8em;color: #999;"
              >
                <el-row style="margin-bottom: 5px; font-size: 1.1em;">
                  <el-col :span="20"
                    >Set Include {{ activity.products.length }}
                    {{
                      activity.products.length > 1 ? " Items" : "Item"
                    }}</el-col
                  >
                  <el-col :span="4" style="text-align: right;">
                    <a
                      style="text-decoration: underline; cursor: pointer"
                      @click="toggleActivityProduct(activity)"
                      >{{ activity.showProducts ? "Hide" : "Show" }}
                    </a>
                  </el-col>
                </el-row>
                <template v-if="activity.showProducts">
                  <div
                    v-for="(product, index) in activity.products"
                    :key="product.id"
                  >
                    <el-row
                      type="flex"
                      align="top"
                      :style="{ flexWrap: 'wrap', marginBottom: '8px', alignItems: 'unset', fontSize: '11px' }"
                    >
                      <el-col :span="null" :style="{flex: '4', color: '#02b0e1'}">{{ product.trade_name }}</el-col>
                      <el-col :span="null" :style="{flex: '2'}">
                        <el-row :style="{ display: 'block' }">
                          <el-input-number
                            :style="{width: '100%', height: '30px'}"
                            size="mini"
                            :precision="2"
                            :controls="false"
                            :disabled="!canEditPriceAndStockAmount"
                            v-model="activity.activityProducts[index].amount">
                          </el-input-number>
                          <el-col
                            :span="24" 
                            :style="{flex: '1', textAlign: 'right'}">
                            {{ product.unit && product.unit.name }}
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col
                        v-if="canEditPriceAndStockAmount"
                        :span="null"
                        :style="{ textAlign: 'right', padding: '0 4px' }">
                        <button
                          :style="{ padding: 0 }"
                          class="btn btn-transparent btn-close"
                          @click="deleteActivityProduct(activity, product)"
                        ><i class="el-icon-close"/></button>
                      </el-col>
                    </el-row>
                    <hr v-if="index < activity.products.length-1"
                      :style='{ 
                        borderTop: "1px solid rgba(180,188,204,0.35)",
                        borderBottom: "none",
                        marginTop: 0
                      }' />
                  </div>
                </template>
              </div>
            </div>
          </transition-group>
          <div class="total">
            <el-row>
              <el-col :span="6">
                <label>รวม</label>
              </el-col>
              <el-col class="sum" :span="18">{{ total | currency }} ฿</el-col>
            </el-row>
          </div>
          <div style="padding: 20px 0;" 
            v-if="med && pos 
                  && (isShowSendToCashierButton() || isShowPaymentButton())">
            <button
              v-if="pos && med.overall_status === 'exam'"
              @click="showCashierDialog = true"
              class="btn btn-new-pet"
              style="width: 100%; margin-bottom: 0.5rem"
            >
              <img
                src="@/assets/icons/icon-cashier.png"
                style="height: 16px; vertical-align: top; margin-right: 5px;"
              />
              Send to Cashier
            </button>
            <button
              v-if="pos && pos.posactivity_set.length > 0 && med.id"
              class="btn btn-primary"
              style="width: 100%;"
              v-show="currentPlan.print_section.can_use_drug_label_print"
              @click="toDrugLabelPage"
            >
              <img
                src="@/assets/icons/icon-pill-bottle.svg"
                style="height: 16px; vertical-align: top; margin-right: 5px;"
              />
              View Drug Label
            </button>

            <button
              v-if="isShowPaymentButton() && currentPlan.opd_section.can_use_opd_payment_step"
              @click="beforePayment(false)"
              class="btn btn-coral"
              style="width: 100%; margin-top: 0.5em;"
            >
              <img
                src="@/assets/icons/icon-cash.png"
                style="height: 16px; vertical-align: middle; margin-right: 5px;"
              />
              Payment
            </button>

            <PrintCommand
              v-if="isShowPaymentButton()"
              header="ใบแจ้งค่าใช้จ่าย"
              class="print-command"
              :style="{ marginTop: '1em' }"
              @command="handleInvoiceCommand"
            >
              <PrintCommandButton command="a4">
                <A4Icon slot="icon" />A4
              </PrintCommandButton>
              <PrintCommandButton command="a5">
                <A5Icon slot="icon" />A5
              </PrintCommandButton>
              <PrintCommandButton command="slip">
                <SlipIcon slot="icon" />Slip
              </PrintCommandButton>
            </PrintCommand>

            <PrintCommand
              v-if="isShowPaymentButton()"
              header="ใบแจ้งค่าใช้จ่ายแบบย่อ"
              class="print-command"
              :style="{ marginTop: '1em' }"
              @command="handleInvoiceCommand"
            >
              <PrintCommandButton command="a4/group">
                <A4Icon slot="icon" />A4
              </PrintCommandButton>
              <PrintCommandButton command="a5/group">
                <A5Icon slot="icon" />A5
              </PrintCommandButton>
              <PrintCommandButton command="slip/group">
                <SlipIcon slot="icon" />Slip
              </PrintCommandButton>
            </PrintCommand>

            <div 
              v-if="isShowPaymentButton() && currentPlan.stock_section.can_stock_out_with_unpaid"
              :style="{ marginTop: '1em' }">
              <el-button
                :style="{ display: 'block', width: '100%' }"
                @click="handleStockCutButtonClick"
              >
                ตัดสินค้าออกจาก Stock
              </el-button>
              <p :style="{ fontSize: '0.75em', margin: '0.5em 0 0' }">* ใช้ในกรณีที่ต้องการตัดสินค้าออกจากคลังโดยที่ยังไม่ชำระเงิน กรณีกลับมาชำระเงินในรายการที่ตัดสินค้าแล้ว จะไม่ตัดซ้ำอีก</p>
            </div>
          </div>
        </div>
      </ContentBox>
    </div>
    <CreateActivity
      :groups="activityGroups"
      :visible="showCreateActivity"
      @confirm="finishCreateActivity()"
      @cancel="showCreateActivity = false"
    />
    <ConfirmDialog
      :visible="showCashierDialog"
      @cancel="showCashierDialog = false"
    >
      <template slot="header">
        <h1 class="title">ส่งไปชำระเงิน</h1>
      </template>
      <template slot="content">
        <div class="dialog-form">
          <div style="text-align: left;">
            <h1 style="font-size: 1.4em; text-align: center;">
              ยอดเงินที่ต้องชำระ ฿ {{ totalWithDiscount | currency }}
            </h1>
            <!-- <el-row>
              <el-col :span="24">
                <label style="display: block;">Select Veterinary</label>
                <el-select
                  style="width: 100%"
                  v-model="selectedVetId"
                  placeholder="Select"
                  size="small"
                >
                  <el-option
                    v-for="vet in vets"
                    :key="vet.id"
                    :value="vet.id"
                    :label="`${vet.first_name} ${vet.last_name}`"
                  ></el-option>
                </el-select>
              </el-col>
            </el-row> -->
            <div style="margin-top: 20px;" v-if="!isIPD()">
              *เมื่อยืนยันการชำระเงินแล้ว จะเปลี่ยนสถานะเป็นจบเคส
              ไม่สามารถส่งเข้า Admit เป็นสัตว์ป่วยในได้
            </div>
          </div>
        </div>
      </template>
      <template slot="footer">
        <el-button
          type="primary"
          style="width: 200px"
          @click="() => handleCashierConfirm(false)"
          >Confirm</el-button
        >
        <el-button
          v-if="!discardable && !isIPD()"
          type="primary"
          style="width: 200px"
          @click="() => handleCashierConfirm(true)"
          >Confirm &amp; Payment</el-button
        >
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import _ from "lodash"
import { PosService, ReceiptService } from "@/services";
import { showStockCutDialog } from "@/components/dialog/stock-cut";
import { showStockNotEnoughDialog } from '@/components/dialog/stock-not-enough';
import ContentBox from "@/components/container/ContentBox";
import ActivityGroups from "@/components/medical/activity/ActivityGroups";
import ActivitySelectableItem from "@/components/medical/activity/ActivitySelectableItem";
import CreateActivity from "@/components/medical/CreateActivity";
import ConfirmDialog from "@/components/dialog/ConfirmDialog";
import FilePreviewer from "@/components/upload/FilePreviewer";
import ActivityInfo from "@/components/receipt/ActivityInfo";
import {
  ReceiptCommandGroup,
  ReceiptCommand,
} from "@/components/receipt-command";
import {
  PrintCommand,
  PrintCommandButton,
  A4Icon,
  A5Icon,
  SlipIcon,
} from "@/components/print-command";

export default {
  inject: ["voidReceipt"],
  props: [
    "pet", 
    "pos", 
    "progress_status", 
    "currentStep", 
    "ipd", 
    "ipdStatus", 
    "actors", 
    "discardable",
    "canEditData",
    "canEditPriceAndStockAmount",
  ],
  components: {
    ContentBox,
    ActivityGroups,
    ActivitySelectableItem,
    CreateActivity,
    ConfirmDialog,
    FilePreviewer,
    ActivityInfo,
    ReceiptCommandGroup,
    ReceiptCommand,
    PrintCommand,
    PrintCommandButton,
    A4Icon,
    A5Icon,
    SlipIcon,
  },
  data() {
    return {
      // selectedVetId: null,
      query: {},
      showCashierDialog: false,
      showActivities: [],
      activityPage: 1,
      isRestored: false,
      searchText: "",
      isFavorite: true,
      selectedGroupId: -1,
      selectedSubGroupId: -1,
      selectedActivities: [],
      showCreateActivity: false,
    };
  },
  watch: {
    selectedActivities: {
      handler() {
        this.selectedActivities.forEach((activity) => {
          activity.showProducts =
            activity.showProducts === null ? false : activity.showProducts;
          activity.is_tx = activity.is_tx === null ? false : activity.is_tx;
          activity.is_rx = activity.is_rx === null ? false : activity.is_rx;
          activity.total = parseFloat(activity.price)
            ? parseFloat(activity.price) * activity.amount -
              (activity.discount || 0)
            : 0;
        });
        this.pos.posactivity_set = JSON.parse(
          JSON.stringify(this.selectedActivities)
        );
      },
      deep: true,
    },
    activities: {
      handler() {
        const result = this.activities ? this.activities.results : [];
        this.showActivities = JSON.parse(JSON.stringify(result));
      },
      deep: true,
    },
    async progress_status() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        if (this.progress_status !== "done") {
          this.query = this.getDefaultQuery();
          await this.$store.dispatch("activity/listActivities", { query });
          await this.$store.dispatch("activity/listActivityGroups", {
            query: {
              is_only_in_activity: true,
              feature: "medical"
            }
          });
        }
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (e) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      }
    },
  },
  computed: {
    ...mapState({
      activityGroups: (state) => state.activity.groups,
      activities: (state) => state.activity.activities,
      backupActivities: (state) => state.medical.backupActivities,
      med: (state) => state.medical.selected,
      vets: (state) => state.hospital.vets,
      currentPlan: state => state.plan.currentPlan,
    }),
    groupName() {
      const group = this.activityGroups.find(
        (group) => group.id === this.selectedGroupId
      );
      let groupName = group ? group.name : "";
      if (group && group.sub_group) {
        const supGroup = group.sub_group.find(
          (group) => group.id === this.selectedSubGroupId
        );
        groupName += supGroup ? ` > ${supGroup.name}` : "";
      }
      return groupName;
    },
    total() {
      let total = this.selectedActivities.reduce((total, item) => {
        if (item.price && item.amount) {
          return (
            total + parseFloat(item.price) * item.amount - (item.discount || 0)
          );
        }
        return total;
      }, 0);
      return total;
    },
    totalWithDiscount() {
      return (
        this.total -
        (this.pos.receipt &&
          (parseFloat(this.pos.receipt.discount)
            ? parseFloat(this.pos.receipt.discount)
            : this.pos.receipt.discount))
      );
    },
    grandTotal() {
      if (this.pos) {
        return (
          +this.pos.grand_total_price_no_vat_only +
          +this.pos.grand_total_price_exclude_vat +
          +this.pos.total_vat
        );
      }

      return 0;
    },
    printIcon() {
      return require("@/assets/icons/icon-printer.png");
    },
    pillBottleIcon() {
      return require("@/assets/icons/icon-pill-bottle.svg");
    },
    cashIcon() {
      return require("@/assets/icons/icon-cash.png");
    }
  },
  methods: {
    isIPD() {
      return this.ipd
    },

    isShowPaymentButton() {
      return this.pos && !this.isIPD()
              && (this.med.overall_status === 'cashier' 
                  || (this.med.overall_status === 'finished' 
                        && this.pos.is_paid == false));
    },

    isShowSendToCashierButton() {
      if (this.isIPD()) 
        return this.currentPlan.ipd_section.can_use_ipd_cashier_step
              && this.ipdStatus !== "finish";
      
      return this.currentPlan.opd_section.can_use_opd_cashier_step
    },

    async beforePayment(dontSave) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        if (!dontSave) {
          await this.$store.dispatch("medical/updateMedical", {
            params: { id: this.$route.params.id },
            data: {
              pethospital: this.med.pethospital,
              vet_id: this.med.vet_id,
              pos: this.clonePOS(),
            },
          });

          this.$notify({
            type: "success",
            title: "บันทึกข้อมูลเรียบร้อยแล้ว",
          });
        }

        this.showCashierDialog = false;
        // this.selectedVetId = null;
        this.$router.push({ name: "payment", params: { id: this.med.id } });

        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorMsg", error);
      }
    },
    getPaymentDetail(paymentMethod) {
      if (paymentMethod.payment_method_type) {
        let in_bracket = "";

        if (paymentMethod.bank) in_bracket += paymentMethod.bank.name;
        if (paymentMethod.account_name)
          in_bracket += " " + paymentMethod.account_name;
        if (paymentMethod.bank_account_id)
          in_bracket += " " + paymentMethod.bank_account_id;
        if (in_bracket.trim()) in_bracket = `(${in_bracket.trim()})`;

        return `${paymentMethod.payment_method_type.name} ${in_bracket}`.trim();
      }
      return paymentMethod.description;
    },
    toggleTx(activity) {
      const index = this.selectedActivities.findIndex(
        (item) => item.id === activity.id
      );
      if (index !== -1) {
        const copyActivities = [...this.selectedActivities];
        copyActivities[index].is_tx = !copyActivities[index].is_tx;
        this.selectedActivities = copyActivities;
      }
    },
    toggleRx(activity) {
      const index = this.selectedActivities.findIndex(
        (item) => item.id === activity.id
      );
      if (index !== -1) {
        const copyActivities = [...this.selectedActivities];
        copyActivities[index].is_rx = !copyActivities[index].is_rx;
        this.selectedActivities = copyActivities;
      }
    },
    toggleActivityProduct(activity) {
      const index = this.selectedActivities.findIndex(
        (item) => item.id === activity.id
      );
      if (index !== -1) {
        const copyActivities = [...this.selectedActivities];
        copyActivities[index].showProducts = !copyActivities[index]
          .showProducts;
        this.selectedActivities = copyActivities;
      }
    },
    printMedicalNoTax() {
      let routeData = this.$router.resolve({
        name: "medicals-detail-pdf-v1",
        params: { id: this.med.id },
      });
      window.open(routeData.href, "_blank");
    },
    printMedical() {
      let routeData = this.$router.resolve({
        name: "medicals-detail-pdf",
        params: { id: this.med.id },
      });
      window.open(routeData.href, "_blank");
    },
    printMedicalCompact() {
      const route = this.$router.resolve({
        name: "medicals-detail-pdf-compact",
        params: { id: this.med.id },
      });

      window.open(route.href, "_blank");
    },
    clonePOS() {
      let clonePOS = JSON.parse(JSON.stringify(this.pos));
      clonePOS.posactivity_set = clonePOS.posactivity_set.map((a) => {
        return {
          activity: a.id,
          lot_no: a.lot_no,
          unit_price: a.price,
          amount: a.amount,
          discount: a.discount,
          is_tx: a.is_tx || false,
          is_rx: a.is_rx || false,
          posactivityproduct_set: a.activityProducts
        };
      });
      if (!clonePOS.discount) {
        clonePOS.discount = 0;
      }
      return clonePOS;
    },
    async handleCashierConfirm(gotoPayment) {
      try {
          await this.$store.dispatch("ui/setIsFullScreenLoading", true);
          await this.$store.dispatch("medical/updateMedical", {
            params: { id: this.med.id },
            data: {
              pethospital: this.med.pethospital,
              // vet_id: this.selectedVetId,
              is_send_to_cashier: true,
              pos: this.clonePOS(),
            },
          });

          this.$notify({
            type: "success",
            title: "บันทึกข้อมูลเรียบร้อยแล้ว",
          });

          this.showCashierDialog = false;
          this.selectedVetId = null;
          if (gotoPayment) {
            this.$router.push({ name: "payment", params: { id: this.med.id } });
          } else {
            if (this.isIPD()) {
              this.$router.go(-1);
            } else {
              this.$router.go();
            }
          }
          await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
          await this.$store.dispatch("ui/setIsFullScreenLoading", false);
          await this.$store.dispatch("ui/showFetchErrorMsg", error);
      }
    },
    createActivity() {
      this.showCreateActivity = true;
    },
    finishCreateActivity() {
      this.showCreateActivity = false;
      this.$router.go();
    },
    search() {
      if (this.searchText.trim() !== "") {
        this.query = {
          ...this.getDefaultQuery(),
          search: this.searchText.trim(),
        };
        delete this.query.is_favorite;
        this.fetchActivity(this.query);
      }

      if (this.searchText.trim() === "") {
        this.clearSearch();
      }
    },
    clearSearch() {
      this.query = this.getDefaultQuery();
      this.activityPage = 1;
      this.searchText = "";
      this.isFavorite = true;
      this.selectedGroupId = -1;
      this.selectedSubGroupId = -1;
      this.fetchActivity(this.query);
    },
    async handleActivityPageChanged(page) {
      const query = {
        ...this.query,
        page: this.activityPage
      };
      await this.fetchActivity(query);
    },
    async fetchActivity(query) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        await this.$store.dispatch("activity/listActivities", { 
          query: {
            ...query,
            page_size: 50,
          }
        });
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorMsg");
      }
    },
    async selectGroup(data) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        this.activityPage = 1;
        this.query = this.setQueryString(data);
        await this.fetchActivity(this.query);
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorMsg");
      }
    },
    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
      }
    },
    increase(activity) {
      activity.amount = activity.amount + 1;
    },
    decrease(activity) {
      if (activity.amount === 1) {
        this.deleteActivity(activity);
      } else {
        activity.amount = activity.amount - 1;
      }
    },
    selectActivity(activity) {
      if (this.isActivitySelected(activity.id)) {
        this.deleteActivity(activity);
      } else {
        let a = JSON.parse(JSON.stringify(activity));
        a.amount = 1;
        a.lot_no = "";
        a.total = 0;
        a.discount = 0;
        a.activityProducts = (activity.products)
                          ? activity.products.map((item) => ({
                              product: item.id,
                              amount: item.amount,
                            }))
                          : [];
        this.selectedActivities.push(a);
      }
    },
    deleteActivity(activity) {
      this.selectedActivities = this.selectedActivities.filter(
        (item) => item.id !== activity.id
      );
    },

    deleteActivityProduct(activity, product) {
      activity.products = activity.products.filter((item) => item.id !== product.id);
      activity.activityProducts = activity.products.map((item) => {
        return { product: item.id, amount: item.amount };
      });
    },

    isActivitySelected(id) {
      return !!this.selectedActivities.find((item) => item.id === id);
    },
    getDefaultQuery() {
      return {
        // all: true,
        page: 1,
        is_favorite: true,
        listing_type: "pos",
        pet_type__key: this.pet.type.key,
        pet_gender: this.pet.gender,
      };
    },
    setQueryString(data) {
      let query = {
        page: this.activityPage,
        listing_type: "pos",
      };

      if (this.searchText !== "") {
        query["search"] = this.searchText;
      }

      if (data.isFavorite) {
        this.isFavorite = data.isFavorite;
        query["is_favorite"] = data.isFavorite;
      } else {
        this.isFavorite = false;
        query["is_favorite"] = undefined;
      }

      if (data.selectedGroupId !== -1) {
        this.selectedGroupId = data.selectedGroupId;
        query["group"] = data.selectedGroupId;
      } else {
        this.selectedGroupId = -1;
        query["group"] = undefined;
      }

      if (data.selectedSubGroupId !== -1) {
        this.selectedSubGroupId = data.selectedSubGroupId;
        query["sub_group"] = data.selectedSubGroupId;
      } else {
        this.selectedSubGroupId = -1;
        query["sub_group"] = undefined;
      }

      if (this.pet.type.key) {
        query["pet_type__key"] = this.pet.type.key;
      }

      if (this.pet.gender !== null) {
        query["pet_gender"] = this.pet.gender;
      }

      return query;
    },

    toDrugLabelPage() {
      this.$router.push({
        name: "medicals-drug-label",
        params: {id: this.med.id},
      });
    },

    handleCommand(value) {
      const goToPrintPage = (name) => {
        const route = this.$router.resolve({ name });

        window.open(route.href);
      };

      switch (value) {
        case "a4":
          return goToPrintPage("medicals-print-a4");

        case "a5":
          return goToPrintPage("medicals-print-a5");

        case "slip":
          return goToPrintPage("medicals-print-slip");

        case "a4/taxless":
          return goToPrintPage("medicals-print-a4-taxless");

        case "a5/taxless":
          return goToPrintPage("medicals-print-a5-taxless");

        case "slip/taxless":
          return goToPrintPage("medicals-print-slip-taxless");

        case "drug_label":
          return this.$router.push({ name: "medicals-drug-label" });

        case "void":
          return this.voidReceipt();

        case "payment":
          return this.beforePayment(true);

        case "receipt":
          return this.$router.push({ name: "income-expense-receipt-detail", params: { id: this.pos.receipt.id } })
      }
    },

    getStockCutItems() {
      return [
        {
          items: this.pos.posactivity_set
            .map((item) =>
              item.posactivityproduct_set.map((product) => ({
                id: product.id,
                name: product.product.trade_name,
                value: product.amount * item.amount,
                unit: product.product.unit ? product.product.unit.name : null,
              }))
            )
            .reduce((prev, curr) => {
              prev.push(...curr);
              return prev;
            }, [])
            .reduce((prev, curr) => {
              const index = prev.findIndex((item) => item.id === curr.id);

              if (index !== -1) {
                prev[index].value += curr.value;
              } else {
                prev.push(curr);
              }

              return prev;
            }, []),
        },
      ];
    },

    async handleStockCutButtonClick() {
      await showStockCutDialog(
        {
          items: this.getStockCutItems(),
        },
        async (setLoading, done) => {
          setLoading(true);

          try {
            const {
              data: { status_code, message },
            } = await PosService.cutStock({
              pos_set: [this.pos.id],
            });
            
            if (status_code == 460) {
              await showStockNotEnoughDialog({
                items: message
              });
            } else {
              this.$router.go();
            }
          } catch (e) {
            if (e) this.$notify.error(e.detail);
          }

          done();
          setLoading(false);
        }
      )
    },

    async handleUndoStockCutButtonClick() {
      await showStockCutDialog(
        {
          undo: true,
          items: this.getStockCutItems(),
        },
        async (setLoading, done) => {
          setLoading(true);

          try {
            await PosService.uncutStock({
              pos_set: [this.pos.id],
            });

            this.$router.go();
          } catch (e) {
            this.$notify.error(e.detail);
          }

          done();
          setLoading(false);
        }
      )
    },

    async handleInvoiceCommand(value) {
      const goToPrintPage = (name, params) => {
        const route = this.$router.resolve({
          name,
          params: { ...this.$route.params, ...params },
        });

        window.open(route.href);
      };

      try {
        this.$store.dispatch("ui/setIsFullScreenLoading", true);

        const {
          data: { result },
        } = await ReceiptService.createInvoice({
          pos_set: [this.pos.id],
          datetime: new Date(),
          owner: this.pet.owners[0].id,
        });

        switch (value) {
          case "a4":
          case "a5":
            return goToPrintPage("medicals-expense-print", {
              invoiceId: result.id,
              size: value,
            });

          case "slip":
            return goToPrintPage("medicals-expense-print-slip", {
              invoiceId: result.id,
            });
          case "a4/group":
          case "a5/group":
            return goToPrintPage("medicals-expense-group-print", {
              invoiceId: result.id,
              size: value.split('/')[0],
            });

          case "slip/group":
            return goToPrintPage("medicals-expense-group-print-slip", {
              invoiceId: result.id,
            });
        }
      } catch (e) {
        this.$notify.error("เกิดข้อผิดพลาดในการสร้างใบแจ้งค่าใช้จ่าย");
      } finally {
        this.$store.dispatch("ui/setIsFullScreenLoading", false);
      }
    },
  },
  async created() {
    try {
      await this.$store.dispatch("ui/setIsFullScreenLoading", true);
      if (this.progress_status !== "done") {
        this.query = this.getDefaultQuery();
        await this.$store.dispatch("hospital/listVets");
        
        if (!this.isIPD()) {
          await this.$store.dispatch("medical/getMedical", {
            params: { id: this.$route.params.id },
          });
        }
      
        await this.fetchActivity(this.query);
        await this.$store.dispatch("activity/listActivityGroups", {
          query: {
            is_only_in_activity: true,
            feature: "medical"
          }
        });
      }
      await this.$store.dispatch("ui/setIsFullScreenLoading", false);
    } catch (error) {
      await this.$store.dispatch("ui/showFetchErrorWithReloadMsg");
    }
  },
  async beforeDestroy() {
    this.$store.commit(
      "medical/SET_BACKUP_ACTIVITIES",
      this.selectedActivities
    );
  },
  async mounted() {
    if (this.pos.last_updated) {
      this.selectedActivities = JSON.parse(
        JSON.stringify(this.pos.posactivity_set)
      );
    } else if (this.backupActivities) {
      this.selectedActivities = this.backupActivities;
    }
  },
};
</script>

<style lang="scss" scoped>
.disable-ele {
  pointer-events: none;
}
.header-activity {
  width: 100%;
  display: flex;
}
.left-activity {
  margin-top: 10px;
  width: 72%;
}
.right-activity {
  margin-top: 10px;
  width: 28%;
}
.search-section {
  margin-bottom: 10px;
}
.activity-breadcrumb {
  padding: 2px 0;
  margin: 4px 0;
  height: 20px;
  font-size: 16px;
  line-height: 1em;
  font-weight: normal;
  color: #333;
}
.not-found {
  text-align: center;
}
.activity-item-wrapper {
  display: inline-block;
  // width: calc(20% - 10px);
  padding: 4px;
  vertical-align: top;
}
.add-activity {
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin: 4px;
  vertical-align: top;
  border: 1px solid #ccc;
  width: 105px;
  height: 168px;
  overflow: hidden;
  text-align: center;
  font-size: 13px;
}
.thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 60px;
  margin-bottom: 6px;
  width: 100%;
}
.add-select-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.label {
  display: flex;
  padding: 5px 5px 5px 0;
  color: $light-blue;
  font-size: 14px;
  font-weight: bold;
  .required-mark {
    margin-left: 5px;
    align-self: center;
  }

  > img {
    margin-right: 5px;
    height: 16px;
    vertical-align: middle;
  }
}
.actors {
  font-size: 12px;

  .detail {
    color: #5a5a5a;
    margin-left: 10px;
  }

  .actor {
    display: block;
    color: #8e8e93;
    line-height: 1.6rem;
  }
}
.activitie-list {
  .activity-item {
    position: relative;
    padding: 5px 0;
    border-bottom: 1px solid #ccc;

    .title {
      margin: 5px 0 0 0;
      max-width: 85%;
      font-size: 14px;
    }
    .subtitle {
      margin: 0 0 5px 0;
      color: $gray;
      font-weight: normal;
    }
    .add-amount {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 5px;
      margin-bottom: 5px;

      .multiply {
        align-self: center;
        font-size: 14px;
      }
      .card-amount {
        border-radius: 4px;
        font-size: 20px;
        border: 1px solid #ccc;
        display: flex;
        justify-content: space-between;
        width: 100px;
      }
      .mark {
        cursor: pointer;
      }
    }
    .price {
      text-align: right;
    }
    .delete-btn {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
      margin-top: 3%;
      color: $gray;

      &:hover {
        color: $red;
      }
    }
  }
}
.discount {
  margin: 10px 0;
}
.total {
  padding: 10px 0;
  .sum {
    text-align: right;
  }
}

.header-activity-done {
  width: 100%;
  display: flex;
  justify-content: center;
}
.header-done {
  display: flex;
  flex-direction: column;
}
.title-done {
  margin: 10px 0;
  font-family: Arial;
  font-size: 18px;
  font-weight: bold;
}
.content-done {
  display: flex;
  justify-content: space-between;
}
.amount-done {
  margin: 10px 0;
  width: 10%;
  display: flex;
  justify-content: space-between;
}
.name-done {
  margin: 10px 0;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.header-text {
  font-family: Arial;
  font-size: 18px;
  font-weight: bold;
}
.text-normal {
  font-family: Arial;
  font-size: 16px;
  font-weight: normal;
}
.sub-text {
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  color: #156f88;
}
.total-done {
  margin: 10px 0;
  display: flex;
  justify-content: flex-end;
}
.line {
  width: 100%;
  margin: 15px 0;
  border: solid 1px #b4b4b4;
}
.lower-done {
  margin-top: 10px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.text-small {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #030202;
}
.text-gray {
  color: #354052;
}

.print-command {
  ::v-deep {
    .el-row {
      align-items: initial;
      width: auto;
    }
  }
}
</style>
