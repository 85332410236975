import moment from "moment";

export const toThaiDate = date => {
  if (date == null) {
    return '-'
  }

  var d = new Date(date);

  // var thday = new Array ("อาทิตย์","จันทร์", "อังคาร","พุธ","พฤหัส","ศุกร์","เสาร์");
  var thmonth = new Array(
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม"
  );

  return (
    d.getDate() + " " + thmonth[d.getMonth()] + " " + (d.getFullYear() + 543)
  );
};


export const toHHMM = date => {
  var d = moment(date);
  // return d.getHours() + ":" + d.getMinutes()
  return d.format('HH:mm')
};
