<template>
  <div class="hopital-pet-owner-register">
    <Subnavbar>
      <template slot="breadcrumb">
        <ul>
          <li>Owner Profile</li>
          <li>Add new pet</li>
        </ul>
      </template>
      <template slot="title">
        ADD NEW PET TO
        <span v-if="owner">{{ `${owner.firstname} ${owner.lastname}` }}</span>
      </template>
      <template slot="actions">
        <button class="btn btn-large btn-outline" @click="$router.back()">Cancel</button>
        <button class="btn btn-large btn-primary" style="width: 100px" @click="showConfirmDialog=true">Save</button>
      </template>
    </Subnavbar>

    <ContentBox>
      <h2 class="title">Create Pet Profile</h2>
      <div class="upload-section">
        <div class="image-container">
          <img v-if="profileImageUrl" class="full-logo" :src="profileImageUrl" alt="" style="border-radius: 60px;">
          <img v-else src="@/assets/icons/icon-camera-plus.png"> 
          <input type="file" id="file" ref="file" @change="handleFileUpload"/>
        </div>
        <span>Pet Picture *</span>
      </div>
      <div class="form-center">
        <el-row>
          <el-col class="col" :span="12">
            <h3 class="subtitle">1. General Info</h3>
            <div class="form-wrapper">
              <PetGeneralInfoForm :form="form" />
            </div>
          </el-col>
          <el-col class="col" :span="12">
            <h3 class="subtitle">2. Health Details</h3>
            <div class="form-wrapper" style="height: 518px;">
              <PetHealthDetailForm :form="form"/>
            </div>
          </el-col>
        </el-row>
      </div>
    </ContentBox>
    <ConfirmDialog
      :visible="showConfirmDialog"
      @cancel="showConfirmDialog = false"
      @confirm="onConfirmSave"
    >
      <template slot="header">
        <h1 class="title">ยืนยันการสร้าง Pet Profile?</h1>
      </template>
      <template slot="content">
        
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import { mapState } from "vuex"
import ContentBox from "@/components/container/ContentBox"
import Subnavbar from "@/components/navigation/Subnavbar"
import ConfirmDialog from "@/components/dialog/ConfirmDialog"
import PetGeneralInfoForm from '@/components/pet/PetGeneralInfoForm'
import PetHealthDetailForm from '@/components/pet/PetHealthDetailForm'

export default {
  data() {
    return {
      form: {
        image: null,
        image_url: null,
        name: null,
        type: null,
        type_key: null,
        type_select: null,
        breed: null,
        gender: null,
        birthday: null,
        age_year: null,
        age_month: null,

        hn: "",
        sterilize: null,
        sterilize_date: null,
        microchip: "",
        // microchip_date: "",
        microchips: [""],
        blood_group: "",
        insurance_number: "",
        insurance_numbers: [""],
        drug_allergy_detail: "",
        type_key_default: null,
      },
      profileImageUrl: null,
      showConfirmDialog: false
    }
  },
  components: {
    ContentBox,
    Subnavbar,
    ConfirmDialog,
    PetGeneralInfoForm,
    PetHealthDetailForm,
  },
  computed: {
    ...mapState({
      owner: state => state.owner.selected,
      profile: state => state.hospital.profile,
    })
  },
  methods: {
    handleFileUpload() {
      if (this.$refs.file.files[0]) {
        this.profileImageUrl =  window.URL.createObjectURL(this.$refs.file.files[0])
      }
    },
    validate() {
      let message = "";
      switch(true) {
        case this.form.name == "": message = "กรุณากรอกชื่อสัตว์เลี้ยง"; break;
        case this.form.type == null: message = "กรุณาระบุชนิดสัตว์เลี้ยง"; break;
        case this.form.breed == null: message = "กรุณาระบุพันธุ์สัตว์เลี้ยง"; break;
        case this.form.gender == null: message = "กรุณาระบุเพศสัตว์เลี้ยง"; break;
        case this.form.birthday == null: message = "กรุณาระบุวันเกิดสัตว์เลี้ยง"; break;
        // case this.form.hn == "": message = "กรุณาระบุหมายเลข HN"; break;
        // case this.form.age_year == null || this.form.age_month == null: message = "กรุณาระบุอายุสัตว์เลี้ยง"; break;
        case this.form.age_year == null: this.form.age_year = 0; break;
        case this.form.age_month == null: this.form.age_month = 0; break;
      }

      if (message != "") {
        this.$notify({
          message: message,
          type: "warning"
        })
        return false;
      }
      return true;
    },
    async onConfirmSave () {
      this.showConfirmDialog = false;
      if (this.validate()) {
        try {
          await this.$store.dispatch("ui/setIsFullScreenLoading", true)
          const pet = await this.$store.dispatch("pet/createPetProfile", {
            data: {
              pet: this.form,
              hn: this.form.hn,
            }
          });
          await this.$store.dispatch("pet/addToOwner", {
            params: { petId: pet.pet.id },
            data: {
              owner: this.owner.id,
            }
          });
          if (this.$refs.file.files[0]) {
            var formData = new FormData()
            // formData.append('partial', true)
            formData.append('pet.image', this.$refs.file.files[0])
            await this.$store.dispatch("pet/updatePetProfile", {
              params: { petHospitalId: pet.id },
              formData: formData,
            })
          }
          await this.$store.dispatch("ui/setIsFullScreenLoading", false)
          this.$notify({
            message: 'สร้าง Pet Profile เรียบร้อยแล้ว',
            type: "success"
          })
          this.$router.push({ name: 'hospitalPetOwnerProfile', params: { id: this.owner.id } })
        } catch (error) {
          this.showConfirmDialog = false
          await this.$store.dispatch("ui/setIsFullScreenLoading", false)
          await this.$store.dispatch("ui/showFetchErrorMsg")
        }
      }
    }
  },
  async created() {
    try {
      const id = this.$route.params.id
      await this.$store.dispatch("ui/setIsFullScreenLoading", true)
      await this.$store.dispatch("owner/getOwnerProfileById", {
        params: { id: id }
      })

      await this.$store.dispatch("ui/setIsFullScreenLoading", false)
    } catch (error) {
      await this.$store.dispatch("ui/showFetchErrorWithReloadMsg")
    }
  }
}
</script>

<style lang="scss">
.hopital-pet-owner-register {
  .form-center {
    width: 66.66%;
    margin: 0 auto;
  }
}
</style>
