<template>
  <div class="message-item" @click="$emit('click', msg.type, msg.detail.id)">
    <div class="message-status">
      <span v-if="msg.is_recent" class="new">New!</span>
      <span v-if="msg.is_seen" class="read">Read</span>
    </div>
    <div class="date">{{ msg.date | toThaiDate }}</div>
    <div class="body">
      <div class="thumbnail" v-if="msg.detail.cover_img">
        <img :src="msg.detail.cover_img" />
      </div>
      <div class="content">
        <h2 class="title">{{ msg.detail.title }}</h2>

        <h4 class="subitle">
          <div class="hospital">
            <img :src="msg.detail.sender.logo" v-if="msg.detail.sender.logo" class="logo" />
            {{ msg.detail.sender.name }}
          </div>
        </h4>
        <div class="detail" v-html="msg.detail.content"></div>
        <div class="attachments" v-if="msg.detail.attachments">
          <img src="@/assets/icons/icon-paperclip.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["msg"]
};
</script>

<style lang="scss" scoped>
.message-item {
  cursor: pointer;
  position: relative;

  .message-status {
    position: absolute;
    top: 0;
    right: 0;

    .new {
      color: $red;
    }
    .read {
      color: #999;
    }
  }
  .body {
    margin: 4px 0;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #eee;
  }

  .content {
    position: relative;

    a {
      cursor: pointer;
      color: $light-blue;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .thumbnail img {
    width: 100%;
  }

  .title {
    margin: 0;
    padding: 0;
    text-align: left;
    color: $light-blue;
  }

  .subitle {
    margin: 0;
    padding: 10px 0;
    text-align: left;
    font-size: 0.8em;
    color: #999;

    .hospital {
      color: #999;
      .logo {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        vertical-align: middle;
        border: 1px solid #ccc;
        margin-right: 10px;
      }
    }
  }

  .detail {
    margin: 5px 0;
  }

  .attachments {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
</style>