<template>
  <div class="pet-search-page">
    <Subnavbar>
      <template slot="breadcrumb">
        <ul>
          <li>Add New Pet To Owner</li>
        </ul>
      </template>
      <template slot="title">
        ADD NEW OWNER TO
        <span v-if="pet">{{ `${pet.pet.name}` }}</span>
      </template>
      <template slot="actions">
        <button
          class="btn btn-large btn-register-new-pet"
          @click="$router.push({ name: 'hospitalPetAddNewOwner', params: { id: $route.params.id } })"
        >
          <i class="fa fa-plus"></i> Register New Owner
        </button>
      </template>
    </Subnavbar>

    <ContentBox>
      <div class="search-section">
        <h2 class="title">ค้นหาเจ้าของจากเบอร์โทรศัพท์</h2>
        <div class="search-input-wrapper">
          <el-input
            :placeholder="'ค้นหาจาก เบอร์โทรศัพท์'"
            v-model="searchText"
            class="search-input"
            clearable
            @keyup.enter.native="() => search(1)"
            @clear="clearSearch"
          >
            <el-button slot="append" icon="el-icon-search" @click="() => search(1)"></el-button>
          </el-input>

          <small class="search-remark">* พิมพ์ให้ถูกต้องทุกตัวอักษร ถึงจะแสดงผลการค้นหา</small>
        </div>
      </div>
      <div class="section">
        <PetOwnerSearchResult
          :owners="ownerResults"
          :current-page="ownerCurrentPage"
          @current-change="ownerPageChange"
          :isAddToPet="true"
          @addToPetClick="handelAddToPetClick"
        />
      </div>
      <div
        class="section"
        v-show="searchText !== '' && (ownerResults && ownerResults.count === 0)"
        style="padding: 5px; text-align: center;"
      >ไม่พบข้อมูลที่ต้องการค้นหา</div>
      <div
        class="section"
        v-show="ownerResults === null || (ownerResults && ownerResults.count === 0)"
        style="padding: 10px; text-align: center;"
      >
        <button
          class="btn btn-large btn-register-new-pet"
          v-show="currentPlan.hospital_feature.can_create_owner_and_pet"
          @click="$router.push({ name: 'hospitalPetAddNewOwner', params: { id: $route.params.id } })"
        >
          <i class="fa fa-plus"></i> New Pet Owner
        </button>
      </div>
    </ContentBox>

    <ConfirmDialog
      :visible="showAddOwnerDialog"
      @cancel="showAddOwnerDialog = false"
      @confirm="handleConfirmAddOwnerToPet"
    >
      <template slot="header">
        <h1 class="title">
          Add this pet owner to
          <span class="petname-text" v-if="pet">{{ pet.pet.name }}</span> ?
        </h1>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ContentBox from "@/components/container/ContentBox";
import Subnavbar from "@/components/navigation/Subnavbar";
import PetOwnerSearchResult from "@/components/pet/PetOwnerSearchResult";
import ConfirmDialog from "@/components/dialog/ConfirmDialog";
import PetGenderIcon from "@/components/pet/PetGenderIcon";
import PetTypeIcon from "@/components/pet/PetTypeIcon";

export default {
  components: {
    ContentBox,
    Subnavbar,
    PetOwnerSearchResult,
    ConfirmDialog,
    PetGenderIcon,
    PetTypeIcon
  },
  data() {
    return {
      searchText: "",
      ownerResults: null,
      ownerCurrentPage: 1,
      selectedOwnerId: null,
      showAddOwnerDialog: false
    };
  },
  computed: {
    ...mapState({
      pet: state => state.pet.selected,
      currentPlan: state => state.plan.currentPlan
    })
  },
  methods: {
    handelAddToPetClick(ownerId) {
      this.selectedOwnerId = ownerId;
      this.showAddOwnerDialog = true;
    },
    async handleConfirmAddOwnerToPet() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);

        await this.$store.dispatch("pet/addPetToOwner", {
          params: { petId: this.pet.pet.id },
          data: {
            owner: this.selectedOwnerId
          }
        });
        this.showAddOwnerDialog = false;
        this.selectedOwnerId = null;
        this.$notify({
          message: `เพิ่ม pet owner เรียบร้อยแล้ว`,
          type: "success"
        });
        this.$router.push({
          name: "hospitalPetProfile",
          params: { id: this.pet.id }
        });
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg");
      }
    },
    async search(ownerCurrentPage) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        this.ownerResults = await this.$store.dispatch("pet/searchNotOwners", {
          params: { id: this.pet.pet.id },
          query: { search: this.searchText }
        });
        this.$router.replace({
          query: {
            search: this.searchText,
            ownerCurrentPage: ownerCurrentPage
          }
        });
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg");
      }
    },
    clearSearch() {
      this.searchText = "";
      this.ownerResults = null;
      this.ownerCurrentPage = 1;
      this.$router.replace({
        search: "",
        ownerCurrentPage: 1
      });
    },
    ownerPageChange(page) {
      this.ownerCurrentPage = page;
      this.search(page);
    },
    getQueryStringFormUrl() {
      if (this.$route.query.search) {
        this.searchText = this.$route.query.search;
      }

      if (this.$route.query.ownerCurrentPage) {
        this.ownerCurrentPage = parseInt(this.$route.query.ownerCurrentPage);
      }

      this.search(this.ownerCurrentPage);
    }
  },
  async created() {
    try {
      const id = this.$route.params.id;
      await this.$store.dispatch("ui/setIsFullScreenLoading", true);
      await this.$store.dispatch("pet/getPetProfile", {
        params: { petHospitalId: id }
      });
      this.getQueryStringFormUrl();
      await this.$store.dispatch("ui/setIsFullScreenLoading", false);
    } catch (error) {
      await this.$store.dispatch("ui/showFetchErrorWithReloadMsg");
    }
  }
};
</script>

<style lang="scss">
.pet-search-page {
  .section {
    margin: 20px 0;
  }
  .tabs {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    border-bottom: 0;
    &::-webkit-scrollbar {
      display: none;
    }

    .tab {
      margin-right: 5px;
      width: auto;
      white-space: nowrap;
    }

    .el-button {
      border: none;
      background-color: #ccc;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: 0;
    }

    .active {
      .el-button {
        background-color: #fff;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .search-section {
    .title {
      margin: 5px 0;
      padding: 0;
      font-size: 18px;
      text-align: center;
    }

    .search-choices {
      margin: 5px auto;
      text-align: center;
      width: 500px;

      input[type="text"] {
        text-align: center !important;
        font-weight: bold !important;
      }
    }
    .search-input-wrapper {
      margin: 0 auto;
      width: 500px;
    }

    .search-remark {
      display: block;
      margin-top: 5px;
      font-size: 0.9em;
      text-align: center;
      color: $light-blue;
    }
  }

  .petname-text {
    color: $light-blue;
  }
}
</style>
