<template>
  <div class="appointment-timeline" v-if="appt">
    <div class="header">
      <h4 class="title">Timeline</h4>
    </div>
    <div class="content">
      <ul class="timeline-list">
        <li v-for="(item, index) in appt.timeline" :key="index">
          <ApptTimelineItem :item="item" :appt="appt"/>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ApptTimelineItem from "./ApptTimelineItem"

export default {
  props: ["appt"],
  components: {
    ApptTimelineItem
  }
}
</script>

<style lang="scss" scoped>
.timeline-list {
  margin: 0 0 15px 0;
  padding: 0;
  list-style-type: none;
  > li {
    padding: 5px 0;
    margin: 0;
  }
}
</style>

