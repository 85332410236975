<template>
  <div class="medical-detail-pdf">
    <div v-if="pet">
      <MedicalDetailPDF :med="med" :pet="pet.pet" :hospital="hospitalInfo" :hn="hn" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import MedicalDetailPDF from "@/components/medical/MedicalDetailPDF"

export default {
  components: {
    MedicalDetailPDF
  },
  data() {
    return {
      'hn': ''
    }
  },
  computed: {
    ...mapState({
      pet: state => state.pet.selected,
      med: state => state.medical.selected,
      hospitalInfo: state => state.hospital.profile
    })
  },
  methods: {
    async fetchData(id) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        await this.$store.dispatch("medical/getMedical", {
          params: { id }
        })
        await this.$store.dispatch("hospital/getProfile")
        let response = await this.$store.dispatch("pet/getPetProfile", {
          params: { petHospitalId: this.med.pethospital }
        })
        this.hn = response.hn;
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg")
      }
    }
  },
  async created() {
    const id = this.$route.params.id
    this.fetchData(id)
  },
  watch: {
    "$route.params.id"(newId) {
      this.fetchData(newId) // if id changes, will refectch data
    }
  }
}
</script>

<style lang="scss" scoped>
.medical-detail-pdf {
  margin: 0 auto;
  width: 620px;
}
</style>
