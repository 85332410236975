var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('table',{staticClass:"inventory-product-table"},[_vm._m(0),_c('tbody',_vm._l((_vm.items),function(item){return _c('tr',{key:item.id},[_c('td',[_c('div',{staticClass:"date-text"},[_vm._v(_vm._s(_vm._f("toThaiDate")(item.datetime)))]),_c('div',{staticClass:"time-text"},[_vm._v(_vm._s(_vm._f("toHHMM")(item.datetime)))])]),_c('td',{staticClass:"type"},[_c('div',{staticClass:"ellipsis"},[_vm._v(_vm._s(_vm.getItemTypeName(item)))])]),_c('td',[_c('div',{style:({ fontSize: '0.857em' })},[(['OPD', 'IPD', 'POS'].includes(_vm.getType(item)))?[_c('div',[_vm._v(_vm._s(item.ref_id))]),(item.receipt )?_c('div',{style:({ fontWeight: 'bold' })},[_vm._v(" "+_vm._s(item.receipt.receipt_number)+" ")]):_vm._e()]:(
                [
                  'Add',
                  'Remove',
                  'Income',
                  'Expense',
                  'Revert',
                  'Initial Stock',
                ].includes(_vm.getType(item))
              )?[_vm._v("Product")]:_vm._e()],2)]),_c('td',{staticClass:"amount-add"},[(item.amount > 0)?_c('div',{staticClass:"ellipsis"},[_vm._v(_vm._s(item.amount))]):_vm._e()]),_c('td',{staticClass:"amount-remove"},[(item.amount < 0)?_c('div',{staticClass:"ellipsis"},[_vm._v(_vm._s(item.amount))]):_vm._e()]),_c('td',{staticClass:"current-stock"},[(item.remaining_amount < 0)?_c('div',{staticClass:"text-red"},[_vm._v(_vm._s(item.remaining_amount))]):_c('div',[_vm._v(_vm._s(item.remaining_amount))])]),_c('td',{staticClass:"action"},[(item.is_ref_404)?_c('div',{staticClass:"text-red"},[_vm._v("Deleted")]):_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.$emit('select-product', item)}}},[_vm._v(" See Detail "),_c('i',{staticClass:"el-icon-arrow-right el-icon-right"})])],1)])}),0)]),(_vm.items)?_c('div',{staticClass:"footer"},[_c('el-pagination',{staticClass:"pagination",attrs:{"background":"","layout":"prev, pager, next","current-page":_vm.currentPage,"page-size":_vm.pageSize,"total":_vm.total},on:{"current-change":(page) => _vm.$emit('current-change', page)}})],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"date"},[_vm._v("Date")]),_c('th',{staticClass:"type"},[_vm._v("Type")]),_c('th',{staticClass:"detail"},[_vm._v("Detail")]),_c('th',{staticClass:"amount-add"},[_vm._v("Add")]),_c('th',{staticClass:"amount-remove"},[_vm._v("Remove")]),_c('th',{staticClass:"current-stock"},[_vm._v("Current Stock")]),_c('th',{staticClass:"action"},[_vm._v(" ")])])])
}]

export { render, staticRenderFns }