<template>
  <div>
    <Subnavbar>
      <template slot="title">
        Drug Label
      </template>
      <template slot="actions">
        <div style="display: flex;">
          <button
            class="btn btn-large btn-default"
            @click="$router.go(-1)"
          >Cancel</button>
          <button class="btn btn-large btn-primary" @click="saveDrugLabel">
            <i class="fa fa-save"></i>Save
          </button>
        </div>
      </template>
    </Subnavbar>
    <el-row :gutter="20">
      <el-col :md="24">
        {{ med && med.id ? 'Medical Record ID : ' + med.id : ''}}
        <ContentBox>
          <template slot="header" class="pet-profile-header">Pet Profile</template>
          <div class="pet-profile-content">
            <PetProfile v-if="pet" class="medical-pet-profile" :pet="pet.pet" :owner="pet.pet.owners[0]"/>
            <div class="rectangle">
              <span class="health-point">Health Point: <span class="pet-point">{{pet && pet.pet.point}}</span> <img src="@/assets/images/common/favorite-true.png" style="width: 15px; height: 15px;"></span>
            </div>
          </div>
        </ContentBox>
      </el-col>
    </el-row>
    <el-row style="display: flex; justify-content: flex-start; flex-wrap: wrap; width: auto;" v-if="pet">
      <el-col style="display: flex; width: 47%; margin: 1%;" v-for="(drug, index) in medicalDrugList" :key="drug.id">
        <ContentBox>
          <div class="drug-label">
            <div class="row header-content" style="margin-top: 10px;">
              <div class="number" style="margin-right: 10px;">{{ (index + 1) }}.</div>
              <div class="total-amount">
                <div>Total Amount {{ drug.product && drug.product.unit ? `(${drug.product.unit.name})` : ''}}</div>
                <div style="margin-left: 5px;">{{ drug.amount }}</div>
              </div>
            </div>
            <div class="row number"></div>
            <div class="row title">Product Name (Trade Name)</div>
            <div class="row value-field">{{ drug.product.trade_name }}</div>
            <div class="row title">Generic Name, Concentration</div>
            <div class="row value-field">{{ drug.product.generic_name || '-' }}</div>
            <div class="row many-content" style="margin-top: 10px;">
              <div class="title-head" style="width: 40%; margin-right: 10px;">วิธีการใช้</div>
              <div class="title-head" style="width: 55%">ตำแหน่ง</div>
              <div style="width: 40%; margin-right: 10px;">
                <el-select
                  placeholder="Select Medical Use"
                  v-model="drug.medication_use"
                >
                  <el-option
                    v-for="item in medicationUse"
                    :key="item.id"
                    :value="item.id"
                    :label="`${item.name}`"
                  ></el-option>
                </el-select>
              </div>
              <div style="width: 55%">
                <el-input type="text" v-model="drug.location" />
              </div>
            </div>
            <div class="row many-content" style="margin-top: 10px;">
              <div class="title-head" style="width: 100%">จำนวนที่ให้ต่อครั้ง</div>
              <div style="width: 25%; margin-right: 10px;"><el-input type="text" v-model="drug.dose" /></div>
              <div style="width: 25%; margin-right: 20px;">
                <el-select
                  placeholder="Select Unit"
                  v-model="drug.unit"
                >
                  <el-option
                    v-for="item in medicationUnits"
                    :key="item.id"
                    :value="item.id"
                    :label="`${item.name}`"
                  ></el-option>
                </el-select>
              </div>
              <!-- <template v-if="drug.taken_condition">
                <div style="width: 10%">วันละ</div>
                <div style="width: 20%; margin-right: 10px;">
                  <el-input type="text" v-model="drug.taken_times_per_day" />
                </div>
                <div style="width: 5%"> ครั้ง</div>
              </template> -->
            </div>
            <div class="row many-content" style="margin-top: 10px;">
              <div class="title-head" style="width: 100%">When</div>
              <div style="width: 30%; margin-right: 20px;">
                <input type="radio"
                  v-model="drug.taken_condition"
                  @change="changeCondition(drug)"
                  :value="1"
                  @click="cancelRadio(drug, 1)"/> ก่อนอาหาร
              </div>
              <div style="width: 65%;">
                <el-input
                  type="text"
                  :disabled="drug.taken_condition !== 1"
                  placeholder="อธิบายเพิ่มเติม เช่น 30 นาที"
                  v-model="drug.taken_condition_suffix_before"
                />
              </div>
              <div style="width: 30%; margin-right: 20px;">
                <input type="radio"
                  v-model="drug.taken_condition"
                  @change="changeCondition(drug)"
                  :value="2"
                  @click="cancelRadio(drug, 2)"/> หลังอาหาร
              </div>
              <div style="width: 65%;">
                <el-input
                  type="text"
                  :disabled="drug.taken_condition !== 2"
                  placeholder="อธิบายเพิ่มเติม เช่น 30 นาที"
                  v-model="drug.taken_condition_suffix_after"
                />
              </div>
              <div style="width: 30%; margin-right: 20px;">
                <input type="radio"
                  v-model="drug.taken_condition"
                  @change="changeCondition(drug)"
                  :value="3"
                  @click="cancelRadio(drug, 3)"/> พร้อมอาหาร
              </div>
              <div style="width: 65%;">
                <el-input
                  type="text"
                  :disabled="drug.taken_condition !== 3"
                  placeholder="อธิบายเพิ่มเติม เช่น 30 นาที"
                  v-model="drug.taken_condition_suffix_current"
                />
              </div>
            </div>
            <div class="row many-content" style="margin-top: 10px;">
              <div style="width: 100%;">
                <el-checkbox-group :disabled="drug.taken_condition === null" v-model="drug.taken_frequency">
                  <el-checkbox class="check-box" :label="1" border>เช้า</el-checkbox>
                  <el-checkbox class="check-box" :label="2" border>กลางวัน</el-checkbox>
                  <el-checkbox class="check-box" :label="3" border>เย็น</el-checkbox>
                  <el-checkbox class="check-box" :label="4" border>ก่อนนอน</el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
            <div class="row many-content" style="margin-top: 10px;">
              <div style="width: 20%; margin-right: 20px;">
                <input type="radio"
                  @change="changeCondition(drug)"
                  v-model="drug.taken_condition"
                  :value="null"/> อื่นๆ
              </div>
              <div style="width: 15%;">
                <el-checkbox
                  :disabled="drug.taken_condition !== null"
                  @change="frequencyCheck(drug)"
                  v-model="drug.taken_hour_frequency_checked">
                </el-checkbox>
              </div>
              <div style="width: 10%">ทุกๆ</div>
              <div style="width: 20%; margin-right: 10px;">
                <el-input
                  type="text"
                  v-model="drug.taken_hour_frequency"
                  :disabled="!drug.taken_hour_frequency_checked" />
              </div>
              <div style="width: 5%"> ชั่วโมง</div>
            </div>
            <div class="row many-content">
              <div style="width: 20%; margin-right: 20px;"></div>
              <div style="width: 15%;">
                <el-checkbox
                  :disabled="drug.taken_condition !== null"
                  v-model="drug.taken_frequency_5">
                </el-checkbox>
              </div>
              <div style="width: 30%">เมื่อมีอาการ</div>
            </div>
            <div class="row many-content" style="margin-top: 10px;">
              <div style="width: 20%;"></div>
              <div style="width: 30%; margin-right: 20px;">
                <el-checkbox v-model="drug.is_external_use">ห้ามรับประทาน</el-checkbox>
              </div>
              <div style="width: 30%;">
                <el-checkbox v-model="drug.is_keep_cool">เก็บในตู้เย็น</el-checkbox>
              </div>
            </div>
            <div class="row many-content">
              <div style="width: 20%;"></div>
              <div style="width: 30%; margin-right: 20px;">
                <el-checkbox v-model="drug.is_danger">ยาอันตราย</el-checkbox>
              </div>
              <div style="width: 30%;">
                <el-checkbox v-model="drug.is_need_shake">เขย่าก่อนใช้</el-checkbox>
              </div>
            </div>
            <div class="row many-content" style="margin-top: 10px;">
              <div style="width: 20%; margin-right: 20px;"><label>วัตถุประสงค์</label></div>
              <div style="width: 65%;">
                <el-input
                  type="text"
                  placeholder="ยาต้านการแข็งตัวของเลือด"
                  v-model="drug.medication_properties"
                />
              </div>
            </div>
            <div class="row many-content">
              <div style="width: 20%; margin-right: 20px;"><label>เพิ่มเติม</label></div>
              <div style="width: 65%;">
                <el-input
                  type="text"
                  v-model="drug.note"
                />
              </div>
            </div>
          </div>
        </ContentBox>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// import _ from "lodash"
import { mapState } from "vuex"
import ContentBox from "@/components/container/ContentBox"
import Subnavbar from "@/components/navigation/Subnavbar"
import PetProfile from "@/components/pet/PetProfile"

export default {
  components: {
    ContentBox,
    Subnavbar,
    PetProfile
  },
  data() {
    return {
      medicalDrugList: [],
      medicationUse: [],
      medicationUnits: []
    }
  },
  watch: {

  },
  computed: {
    ...mapState({
      pet: state => state.pet.selected,
      med: state => state.medical.selected
    }),

    isIpd() {
      return this.$route.meta.ipd;
    },

    id() {
      if (this.isIpd) return this.$route.params.opdId
      return this.$route.params.id
    }
  },
  methods: {
    frequencyCheck(drug) {
      if (!drug.taken_hour_frequency_checked) {
        drug.taken_hour_frequency = ''
      }
    },
    changeCondition(drug) {
      if (drug.taken_condition) {
        drug.taken_hour_frequency_checked = false
        drug.taken_frequency_5 = false
        drug.taken_hour_frequency = ''
      } else {
        drug.taken_condition_suffix_before = ''
        drug.taken_condition_suffix_after = ''
        drug.taken_condition_suffix_current = ''
        drug.taken_frequency = []
      }
    },
    cancelRadio(drug, value) {
      if (drug.taken_condition === value) {
        drug.taken_condition = null
      }
    },
    async saveDrugLabel() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        for (let i=0 ; i<this.medicalDrugList.length ; i++) {
          let data = {}
          data['medication_use'] = this.medicalDrugList[i].medication_use
          data['location'] = this.medicalDrugList[i].location
          data['dose'] = this.medicalDrugList[i].dose
          data['taken_times_per_day'] = this.medicalDrugList[i].taken_times_per_day
          data['taken_condition'] = this.medicalDrugList[i].taken_condition
          data['taken_condition_suffix'] = this.medicalDrugList[i].taken_condition === 1 ? this.medicalDrugList[i].taken_condition_suffix_before : (this.medicalDrugList[i].taken_condition === 2 ? this.medicalDrugList[i].taken_condition_suffix_after : this.medicalDrugList[i].taken_condition === 3 ? this.medicalDrugList[i].taken_condition_suffix_current : '' )
          data['taken_frequency'] = this.medicalDrugList[i].taken_frequency_5 ? this.medicalDrugList[i].taken_frequency.concat(5) : this.medicalDrugList[i].taken_frequency
          data['taken_hour_frequency'] = this.medicalDrugList[i].taken_hour_frequency
          data['is_external_use'] = this.medicalDrugList[i].is_external_use
          data['is_keep_cool'] = this.medicalDrugList[i].is_keep_cool
          data['is_danger'] = this.medicalDrugList[i].is_danger
          data['is_need_shake'] = this.medicalDrugList[i].is_need_shake
          data['medication_properties'] = this.medicalDrugList[i].medication_properties
          data['note'] = this.medicalDrugList[i].note
          data['unit'] = this.medicalDrugList[i].unit
          data['product'] = this.medicalDrugList[i].product.id

          await this.$store.dispatch("medical/updateMedicalLabelList", {
            params: { id: this.med.id, drug_id: this.medicalDrugList[i].id },
            data
          });
        }
        this.$router.go(-1)
        this.$notify({
          type: "success",
          title: "บันทึกข้อมูลเรียบร้อยแล้ว"
        });
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        this.$notify({
          message: "เกิดข้อผิดพลาดในการบันทึกข้อมูล",
          type: "error"
        });
      }

    }
  },
  async mounted() {

  },
  async created() {
    try {
      await this.$store.dispatch("ui/setIsFullScreenLoading", true)
      this.medicationUse = await this.$store.dispatch(
        "product/listMedicationUse",
        {
          query: { all: true }
        }
      )
      this.medicationUnits = await this.$store.dispatch("product/listUnits", {
        query: { show_in: "medication", all: true }
      })
      await this.$store.dispatch("medical/getMedical", {
        params: { id: this.id }
      })
      const labelList = await this.$store.dispatch("medical/getMedicalLabelList", {
        params: { id: this.med.id }
      })
      for (let i=0 ; i<labelList.length ; i++) {
        let dataList = {}
        dataList.amount = labelList[i].amount
        dataList.dose = labelList[i].dose
        dataList.id = labelList[i].id
        dataList.is_danger = labelList[i].is_danger
        dataList.is_external_use = labelList[i].is_external_use
        dataList.is_keep_cool = labelList[i].is_keep_cool
        dataList.is_need_shake = labelList[i].is_need_shake
        dataList.is_specific_location = labelList[i].is_specific_location
        dataList.location = labelList[i].location
        dataList.medicalrecord = JSON.parse(JSON.stringify(labelList[i].medicalrecord))
        dataList.medication_caution = labelList[i].medication_caution
        dataList.medication_properties = labelList[i].medication_properties
        dataList.medication_use = labelList[i].medication_use
        dataList.note = labelList[i].note
        dataList.posactivityproduct = JSON.parse(JSON.stringify(labelList[i].posactivityproduct))
        dataList.product = JSON.parse(JSON.stringify(labelList[i].product))
        dataList.taken_condition = labelList[i].taken_condition
        dataList.taken_condition_suffix = labelList[i].taken_condition_suffix
        dataList.taken_frequency = labelList[i].taken_frequency
        dataList.taken_hour_frequency = labelList[i].taken_hour_frequency
        dataList.taken_times_per_day = labelList[i].taken_times_per_day
        dataList.unit = labelList[i].unit
        dataList['taken_condition_suffix_before'] = ''
        dataList['taken_condition_suffix_after'] = ''
        dataList['taken_condition_suffix_current'] = ''
        dataList['taken_frequency_5'] = false
        dataList['taken_hour_frequency_checked'] = false

        if (dataList.taken_condition === 1) {
          dataList.taken_condition_suffix_before = JSON.parse(JSON.stringify(dataList.taken_condition_suffix))
        } else if (dataList.taken_condition === 2) {
          dataList.taken_condition_suffix_after = JSON.parse(JSON.stringify(dataList.taken_condition_suffix))
        } else if (dataList.taken_condition === 3) {
          dataList.taken_condition_suffix_current = JSON.parse(JSON.stringify(dataList.taken_condition_suffix))
        }
        if (dataList.taken_frequency.indexOf(5) !== -1) {
          dataList.taken_frequency.splice(dataList.taken_frequency.indexOf(5), 1)
          dataList.taken_frequency_5 = true
        }
        if (dataList.taken_hour_frequency) {
          dataList.taken_hour_frequency_checked = true
        }
        this.medicalDrugList.push(dataList)
      }
      await this.$store.dispatch("pet/getPetProfile", {
        params: { petHospitalId: this.med.pethospital }
      })
      await this.$store.dispatch("ui/setIsFullScreenLoading", false)
    } catch (error) {
      await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      await this.$store.dispatch("ui/showFetchErrorWithReloadMsg")
    }
  }
}
</script>

<style lang="scss" scoped>
.medical-pet-profile {
  height: 100%;
  display: flex;
  justify-content: center;
}
.pet-profile-header {
  border-radius: 4px;
  border: solid 1px #e6eaee;
}
.pet-profile-content {
  display: flex;
  flex-direction: column;
  margin-left: 5%;
}
.el-row {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  .el-col-8 {
    width: 10%;
  }
  .el-col-16 {
    width: 35%;
  }
}
.margin-row {
  margin: 10px 0;
}
.rectangle {
  margin-left: -10%;
  display: flex;
  align-items: center;
  align-self: center;
  width: 30%;
  height: 45px;
  border-radius: 5px;
  background-color: #f5f8fa;
}
.health-point {
  margin-left: 15%;
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  color: #7f8fa4;
}
.pet-point {
  margin: 0 20px;
  font-family: Arial;
  font-size: 18px;
  font-weight: Bold;
  color: #d0025b;
}
.message-box {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.drug-label {
  font-family: Thonburi;
  font-size: 14px;
  font-weight: normal;
  margin: 10px;
  display: flex;
  flex-direction: column;
}
.row {
  margin: 10px 0;
}
.column {
  margin: 0 10px;
}
.number {
  font-family: Thonburi;
  font-size: 18px;
  font-weight: bold;
}
.title {
  font-family: Arial;
  font-size: 14px;
  font-weight: bold;
  color: #5a5a5a;
}
.value-field {
  font-family: Thonburi;
  font-size: 14px;
  font-weight: bold;
}
.many-content {
  display: flex;
  align-items: center;
}
.header-content {
  display: flex;
  justify-content: space-between;
}
.total-amount {
  font-family: Thonburi;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.title-head {
  font-family: Thonburi;
  font-size: 14px;
  font-weight: bold;
  color: #5a5a5a;
}
.check-box {
  margin-right: 5px;
}
</style>
