<template>
  <Subnavbar>
    <template slot="title">
      Appointment Detail
      <span v-if="id">/ {{ id }}</span>
      <div class="tag-wrapper">
        <span
          class="date"
          v-if="appt && appt.status === 'request_postpone' && appt.request_postpone"
        >
          {{ appt.request_postpone.postpone_request_datetime | toThaiDate }}
          {{ appt.request_postpone.postpone_request_datetime | toHHMM }}
        </span>
        <span
          v-if="appt"
          :class="{ tag: true, 'small': true, [appt.status]: true}"
        >{{ appt.status | apptStatus }}</span>
      </div>
    </template>
    <template slot="actions">
      <button
        class="btn btn-large btn-status-cancel"
        :disabled="!apptState.isCancel"
        @click="$emit('actions', 'cancel')"
      >
        <i class="fa fa-trash-alt"/>Terminate
      </button>
      <button
        :class="{ 'btn': true, 'btn-large': true, 'btn-status-postpone': true, 'pressed': isPostponeActive}"
        :disabled="!apptState.isPostpone"
        @click="$emit('actions', 'postpone')"
      >
        <i class="fa fa-history"/>Postpone
      </button>
      <button
        class="btn btn-large btn-status-confirm"
        :disabled="!apptState.isConfirm"
        @click="$emit('actions', 'confirm')"
      >
        <i class="fa fa-thumbs-up"/>Confirm
      </button>
      <button
        class="btn btn-large btn-status-done"
        :disabled="!apptState.isMarkDone"
        @click="$emit('actions', 'done')"
      >
        Mark done
        <i class="fa fa-angle-right"/>
      </button>
      <el-dropdown trigger="click" @command="handlePrintCommand">
        <button class="btn btn-large btn-status-print">
          <i class="fa fa-print"/>Print
        </button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="a5">A5</el-dropdown-item>
          <el-dropdown-item command="slip">Slip</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <button
        class="btn btn-transparent btn-close"
        @click="$router.replace({ name: 'appointments' })"
      >
        <i class="fa fa-times"/>
      </button>
    </template>
  </Subnavbar>
</template>

<script>
import Subnavbar from "@/components/navigation/Subnavbar"

export default {
  props: ["appt", "apptState", "isPostponeActive", "id"],
  components: {
    Subnavbar
  },

  methods: {
    handlePrintCommand(value) {
      this.$emit("actions", `print/${value}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.tag-wrapper {
  position: relative;
  display: inline-block;

  .date {
    position: absolute;
    top: -20px;
    right: 6px;
    font-size: 12px;
    color: #666;
    width: 200px;
    text-align: right;
  }
}
.btn-close {
  padding: 10px;
}


.subnavbar .left-col {

  .title {
    font-size: 15px !important;

    .tag {
      font-size: 11px !important;
    }

    @media (min-width: 1200px) {
      font-size: 20px !important;

       .tag {
        font-size: 12px !important;
      }
    }
  }
}

</style>
