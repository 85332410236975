<template>
  <div class="no-gutter">
    <div class="row">
      <div class="col-md-1 flex">
        <div class="flex-center flex-middle">
          <i v-if="isSelected" class="icon icon-check" style="color: green;font-size:30px"></i>
        </div>
      </div>

      <div class="col-md-3">
        <div class="row">
          <label>เลขที่ถุงเลือด</label>
        </div>
        <div class="row">
          <h3>{{bloodBagId}}</h3>
        </div>
      </div>

      <div class="col-md-2">
        <div class="row">
          <label>Type</label>
        </div>
        <div class="row">
          <h3>{{bloodType}}</h3>
        </div>
      </div>

      <div class="col-md-2">
        <div class="row">
          <label>PCV</label>
        </div>
        <div class="row">
          <h3>{{pcvValue}} %</h3>
        </div>
      </div>

      <div class="col-md-3">
        <div class="row">
          <label>วันหมดอายุ</label>
        </div>
        <div class="row">
          <h3>{{expiry}}</h3>
        </div>
      </div>

      <div class="col-md-4">
        <div class="row">
          <label>คะแนนความเป็นไปได้</label>
        </div>
        <div class="row">
          <h3>{{possibility}} - %</h3>
        </div>
      </div>

      <div class="col-md-4">
        <div class="row">
          <label>ปริมาตรที่แนะนำ</label>
        </div>
        <div class="row">
          <h3>{{suggestedQuantity}} - ml.</h3>
        </div>
      </div>

      <div class="col-md-3">
        <div class="row">
          <label>ปริมาตรคงเหลือ</label>
        </div>

        <div class="row">
          <h3>{{remainingQuantity}} ml.</h3>
        </div>
      </div>

      <div class="col-md-2 flex">
        <div class="row container flex-middle">
          <div class="flex-middle" style="height: 75%;">
            <at-button
              :disabled="disabled"
              v-if="isInStock && isSelected"
              v-on:click="onCancel"
            >ยกเลิก</at-button>
            <at-button
              :disabled="disabled"
              v-if="isInStock && !isSelected"
              v-on:click="onSelect"
            >เลือกถุงเลือด</at-button>
            <at-button
              :disabled="disabled"
              v-if="!isInStock"
              v-on:click="onRemoveClicked"
              type="error"
            >Remove</at-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "blood-suggest-row",
  data() {
    return {
      isSelected: false
    };
  },
  props: [
    "bloodBagId",
    "bloodType",
    "pcvValue",
    "expiry",
    "possibility",
    "suggestedQuantity",
    "remainingQuantity",
    "isInStock",
    "disabled"
  ],
  methods: {
    onSelect: function(e) {
      this.isSelected = true;
      this.$emit("bloodbagSelected", {
        event: e,
        selectedBloodBagId: this.bloodBagId
      });
    },

    onCancel: function(e) {
      this.isSelected = false;
      this.$emit("bloodbagCancel", {
        event: e,
        selectedBloodBagId: this.bloodBagId
      });
    },

    onRemoveClicked: function(e) {
      this.$emit("remove", { event: e, selectedBloodBagId: this.bloodBagId });
    }
  }
};
</script>

<style>
</style>
