import InventoryDetailPage from '@/pages/inventory/InventoryDetailPage.vue'
import InventoryProductPage from '@/pages/inventory/InventoryProductPage.vue'
const InventoryTransaction = () => import('@/pages/inventory/InventoryTransaction')
const InventoryUsed = () => import('@/pages/inventory/InventoryUsed')
const InventoryUnused = () => import('@/pages/inventory/InventoryUnused')
const InventoryValue = () => import('@/pages/inventory/InventoryValue')
const CurrentInventory = () => import('@/pages/inventory/CurrentInventory')
const AddRemoveInventory = () => import("@/pages/inventory/AddRemoveInventory")
const AddRemoveInventoryNoPayment = () => import("@/pages/inventory/AddRemoveInventoryNoPayment")
const AddRemoveInventoryWithPayment = () => import("@/pages/inventory/AddRemoveInventoryWithPayment")
const AddRemoveInventoryDetail = () => import("@/pages/inventory/AddRemoveInventoryDetail")
const StockCard = () => import('@/pages/inventory/StockCard')
const StockCardCurrentInventory = () => import('@/pages/inventory/StockCardCurrentInventory')
const StockCardInventoryTransaction = () => import('@/pages/inventory/StockCardInventoryTransaction')


const routes = [
  {
    path: '/inventories',
    name: 'inventory-list',
    component: CurrentInventory,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['stock_section.can_view_stock']
    }
  },
  {
    path: '/inventories/addremove',
    name: 'inventory-addremove',
    component: AddRemoveInventory,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['stock_section.can_add_stock']
    },
    redirect: { name: "inventory-add-with-payment" },
    children: [
      {
        path: "/inventories/add/withpayment",
        name: 'inventory-add-with-payment',
        component: AddRemoveInventoryWithPayment,
        meta: {
          isAdd: true,
          requiresAuth: true,
          permissions: ["hospital_admin"],
        },
      },
      {
        path: "/inventories/remove/withpayment",
        name: 'inventory-remove-with-payment',
        component: AddRemoveInventoryWithPayment,
        meta: {
          isAdd: false,
          requiresAuth: true,
          permissions: ["hospital_admin"],
        },
      },
      {
        path: "/inventories/add/nopayment",
        name: 'inventory-add-no-payment',
        component: AddRemoveInventoryNoPayment,
        meta: {
          isAdd: true,
          requiresAuth: true,
          permissions: ["hospital_admin"],
        },
      },
      {
        path: "/inventories/remove/nopayment",
        name: 'inventory-remove-no-payment',
        component: AddRemoveInventoryNoPayment,
        meta: {
          isAdd: false,
          requiresAuth: true,
          permissions: ["hospital_admin"],
        },
      },
    ]
  },
  {
    path: "/inventories/add/detail/:id",
    name: 'inventory-add-detail',
    component: AddRemoveInventoryDetail,
    meta: {
      isAdd: true,
      requiresAuth: true,
      permissions: ["hospital_admin"],
    },
  },
  {
    path: "/inventories/remove/detail/:id",
    name: 'inventory-remove-detail',
    component: AddRemoveInventoryDetail,
    meta: {
      isAdd: false,
      requiresAuth: true,
      permissions: ["hospital_admin"],
    },
  },
  {
    path: "/stockcard/:stock/:product",
    name: "stockcard",
    component: StockCard,
    meta: {
      requiresAuth: true,
      permissions: ["hospital_admin"],
    },
    redirect: { name: "stockcard-current-inventory" },
    children: [
      {
        path: "current/inventory",
        name: "stockcard-current-inventory",
        component: StockCardCurrentInventory,
        meta: {
          requiresAuth: true,
          permissions: ["hospital_admin"],
          plan_permissions: ['stock_section.can_view_stock']
        },
      },
      {
        path: "inventory/transactions",
        name: "stockcard-inventory-transaction",
        component: StockCardInventoryTransaction,
        meta: {
          requiresAuth: true,
          permissions: ["hospital_admin"],
          plan_permissions: ['stock_section.can_view_stock']
        },
      },
    ],
  },
  {
    path: '/inventories/select/:id',
    name: 'inventory-detail',
    component: InventoryDetailPage,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['stock_section.can_view_stock']
    }
  },
  {
    path: '/inventories/product/:stock_id/:product_id',
    name: 'inventory-product',
    component: InventoryProductPage,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['stock_section.can_view_stock']
    }
  },
  {
    path: '/inventories/transaction',
    name: 'inventory-transaction',
    component: InventoryTransaction,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['stock_section.can_view_inventory_transaction']
    }
  },
  {
    path: '/inventories/used',
    name: 'inventory-used',
    component: InventoryUsed,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['stock_section.can_view_stock']
    }
  },
  {
    path: '/inventories/unused',
    name: 'inventory-unused',
    component: InventoryUnused,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['stock_section.can_view_stock']
    }
  },
  {
    path: '/inventories/value',
    name: 'inventory-value',
    component: InventoryValue,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['stock_section.can_view_stock']
    }
  }
]

export default routes
