export default class {
  constructor (obj) {
    if (obj != null) {
      this.id = obj.id == null ? null : obj.id
      this.approver = obj.receiver == null ? '' : obj.receiver
      this.bloodRequestId = obj.blood_request == null ? '' : obj.blood_request
      this.modifiedDate = obj.last_updated == null ? null : obj.last_updated
    } else {
      this.id = null
      this.approver = ''
      this.bloodRequestId = null
      this.modifiedDate = null
    }
  }

  toRequestJson () {
    return {
      id: this.id,
      receiver: this.approver,
      blood_request: this.bloodRequestId
    }
  }
}
