<template>
  <el-dialog :visible="visible" width="500px" center @close="$emit('cancel')">
    <div class="dialog-content" v-if="type === 'create'">
      <h1 class="title">Save your edit?</h1>
      <!-- <div>
        <div class="row">Select Veterinarian</div>
        <div class="row">
          <v-select
            v-model="finishData.vet_id"
            style="width: 100%;"
            label="full_name"
            :options="vetsItem"
            :clearSearchOnSelect="true"
            :pushTags="false"
            :filterable="true"
          />
        </div>
      </div> -->
    </div>
    <p v-if="noticeText" style="font-size: 12px; padding-right:8px;">{{ noticeText }}</p>
    <div class="dialog-content" v-if="type === 'delete'">
      <h1 class="title">Confirm Delete?</h1>
      <!-- <div>
        <div class="row">Select Veterinarian</div>
        <div class="row">
          <v-select
            v-model="finishData.vet_id"
            style="width: 100%;"
            label="full_name"
            :options="vetsItem"
            :clearSearchOnSelect="true"
            :pushTags="false"
            :filterable="true"
          />
        </div>
      </div> -->
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('cancel')">Cancel</el-button>
      <el-button v-if="type === 'create'" type="primary" @click="$emit('confirm')">Confirm</el-button>
      <el-button v-if="type === 'delete'" type="primary" @click="$emit('delete')">Confirm</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: ['visible', 'finishData', 'type', 'noticeText'],
  data() {
    return {
      vetsItem : this.vets
    }
  },
};
</script>

<style lang="scss" scoped>
.dialog-content {
  font-size: 16px;
  .title {
    margin: -15px 0 10px 0;
    padding: 0;
    font-size: 20px;
    text-align: center;
    color: $light-blue;
  }

  .row {
    margin-bottom: 5px;
  }

  label {
    display: inline-block;
    vertical-align: top;
    color: #333;
    width: 200px;
    font-weight: bold;
  }

  ol {
    display: inline-block;
    margin: 0 0 10px 20px;
    padding: 0;
    > li {
      margin-bottom: 3px;
    }
  }
}
</style>

