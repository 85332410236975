<template>
  <div class="form-block">
    <div class="header-block">
      <div class="title">
        Create Pet Owner Profile
      </div>
      <div class="lead">
        General Info<br/>
        (1/4)
      </div>
    </div>
    <div class="upload-image">
      <div class="image-container">
        <img v-if="petOwnerProfilePicture" class="full-logo" :src="petOwnerProfilePicture" alt="">
        <img v-else src="@/assets/icons/icon-camera-plus.png"> 
        <input type="file" id="file" ref="file" @change="handleFileUpload"/>
      </div>
      <span>Profile Picture *</span>
    </div>
    <el-form ref="form" :model="form">

      <el-form-item>
        <span class="form-label">ชื่อ <span class="red">*</span></span>
        <el-input
          type="text"
          v-validate="'required'"
          name="name"
          v-model="form.name"></el-input>
        <span class="error">{{ errors.first('name') }}</span>
      </el-form-item>

      <el-form-item>
        <span class="form-label">นามสกุล <span class="red">*</span></span>
        <el-input
          type="text"
          v-validate="'required'"
          name="lastName"
          v-model="form.lastName"></el-input>
        <span class="error">{{ errors.first('lastName') }}</span>
      </el-form-item>

      <el-form-item>
        <span class="form-label">เพศ <span class="red">*</span></span>
        <div class="gender-select">
          <el-radio v-model="form.gender" label="1" border ref="radio1">ชาย</el-radio>
          <el-radio v-model="form.gender" label="0" border ref="radio2">หญิง</el-radio>
        </div>
      </el-form-item>

      <el-form-item>
        <span class="form-label">วันเกิด</span>
        <el-date-picker
          v-model="form.birthday"
          type="date"
          format="yyyy/MM/dd"
          :picker-options="pickerOptions"
          placeholder="กรุณาเลือกวันเกิด">
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <span class="form-label">เบอร์ติดต่อ</span>
        <el-input
          type="text"
          v-validate="'required'"
          name="phone"
          v-model="form.phone"
          :disabled="true"></el-input>
      </el-form-item>

      <el-form-item>
        <span class="form-label">หมายเลขบัตรประชาชน</span>
        <el-input
          type="tel"
          name="id_card_number"
          placeholder="ใช้สำหรับการออกใบกำกับภาษี"
          v-model="form.id_card_number"></el-input>
        <span class="error">{{ errors.first('email') }}</span>
      </el-form-item>

      <el-form-item>
        <span class="form-label">อีเมล์</span>
        <el-input
          type="email"
          name="email"
          placeholder="Email"
          v-model="form.email"></el-input>
        <span class="error">{{ errors.first('email') }}</span>
      </el-form-item>

      <el-form-item class="text-center">

        <el-button
          :loading="submitButtonLoading"
          type="button"
          class="btn btn-primary form-submit-btn"
          @click="onSubmit">Next ></el-button>
        <span v-for="(error, key) of errMessage"
          v-bind:key="key"
          class="error">{{ key }}: {{ error[0] }}</span>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import moment from "moment"

export default {
  data() {
    return {
      submitButtonLoading: false,
      username: '',
      petOwnerProfilePicture: null,
      form: {},
      errMessage: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      }
    }
  },
  async mounted() {
    await this.$store.dispatch('ui/setIsFullScreenLoading', true)
    await this.$store.dispatch('owner/getOwnerProfile')
    this.petOwnerProfilePicture = this.$store.state.owner.profile.image.large
    this.form = {
      name: this.$store.state.owner.profile.firstname,
      lastName: this.$store.state.owner.profile.lastname,
      gender: this.$store.state.owner.profile.gender.toString(),
      birthday: this.$store.state.owner.profile.birthdate,
      phone: this.$store.state.owner.profile.phone,
      id_card_number: this.$store.state.owner.profile.id_card_number,
      email: this.$store.state.owner.profile.email
    }

    await this.$store.dispatch('ui/setIsFullScreenLoading', false)
  },
  methods: {
    async onSubmit() {
      this.submitButtonLoading = true
        var form = new FormData()
      try {
   
        if (this.$refs.file.files[0]) {
           form.append('image',this.$refs.file.files[0])
         }

         if (this.form.birthday !== undefined && this.form.birthday !== null) {
          form.append('birthdate',moment(this.form.birthday).format('YYYY-MM-DD'))
         } else {
           form.append('birthdate', '')
         }
        form.append('firstname',this.form.name)
        form.append('lastname',this.form.lastName)
        form.append('gender',parseInt(this.form.gender))
        form.append('id_card_number',this.form.id_card_number)
        form.append('email',this.form.email)

        await this.$store.dispatch('owner/updateProfile', {
          formData: form
        })
        this.$message('Profile Saved')
        this.$router.push({ name: 'petOwnerMapLocation' })
      } catch (err) {
        this.errMessage = err
      }
      this.submitButtonLoading = false
    },

    async handleFileUpload() {
      if (this.$refs.file.files[0]) {
        this.petOwnerProfilePicture  =  window.URL.createObjectURL(this.$refs.file.files[0])        
      }
    }
  },
  watch: {
    "form.phone" (val) {
      this.form.phone = val.replace(/[^0-9]/g, '')
    }
  }
}
</script>

<style lang="scss" scoped>
.form-block {
  @extend %block-container;
  @extend %form-style;
  .form-submit-btn {
    width: 160px;
  }
  .gender-select {
    text-align: center;
    .el-radio {
      padding: 12px 25px 0 20px;
      width: 140px;
    }
  }
  .upload-image {
    text-align: center;
    .image-container {
      position: relative;
       height: 120px;
      width: 120px;
      border-radius: 60px;
      background-color: #d8d8d8;
      display: block;
      margin: 8px auto;
     img {
        display: block;
        width: 60px;
        height: 60px;
        padding: 30px;
        object-fit: contain;
        &.full-logo {
          width: 120px;
          height: 120px;
          padding: 0px;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      input {
        height: 120px;
        width: 120px;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
      }
    }
  }
}
</style>
