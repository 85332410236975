<template>
  <div class="pet-note">
    <template v-if="pet">
      <div class="card-block">
          <div class="header">
            <span>{{ label }} Note</span>
          </div>
          <div class="contain">
            <div class="custom-row">
              <div class="detail">
                <div class="value" v-if="pet.hospital_note">{{ pet.hospital_note }}</div>
                <div class="value" v-else>
                  <div class="no-item">ยังไม่มีการบันทึก</div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </template>
    <el-button class="side-button" type="primary" @click="addPetNote"><img src="@/assets/icons/icon-edit.svg"> Add {{ label }} Note</el-button>
  </div>
</template>

<script>
export default {
  props: {
    pet: {
      type: Object
    },
    label: {
      default: 'Pet',
      type: String
    }
  },
  methods: {
    addPetNote() {
      this.$prompt('กรุณากรอกบันทึกช่วยจำ', `${this.label} Note`, {
        confirmButtonText: 'บันทึก',
        cancelButtonText: 'ยกเลิก',
        inputValue: this.pet.hospital_note,
        // inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        // inputErrorMessage: 'Invalid Email'
      }).then(({ value }) => {
        this.updatePetNote(value);
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: 'Input canceled'
        // });
      });
    },
    async updatePetNote(value) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)

        if (this.label == 'Pet') {
          await this.$store.dispatch("pet/updatePetProfile", {
            params: { petHospitalId: this.$route.params.id },
            data: { pet: { hospital_note: value }}
          })
        } else if (this.label == 'Owner') {
          await this.$store.dispatch("owner/updateProfileById", {
            params: { id: this.$route.params.id },
            data: { hospital_note: value }
          })
        }

        this.pet.hospital_note = value;
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg")
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.divider {
  width: 100%;
  height: 1px;
  background-color: #e6eaee;
  margin-bottom: 6px;
}

.card-block {
  .header {
    padding: 12px;
    border-radius: 4px 4px 0px 0px;
    border: solid 1px #e6eaee;
    background-image: linear-gradient(to top, #f4f7fa, #ffffff);

    font-family: default;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #354052;
  }
  .contain {
    min-height: 112px;
    padding: 0px;
    border-radius: 0px 0px 4px 4px;
    border: solid 1px #e6eaee;
    background-color: #ffffff;
    .custom-row {
      padding: 12px 12px 12px 12px;
    }
  }
}

.detail {
  text-align: left;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0px;
  }

  .key {
    font-family: default;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    color: #8e8e93;
  }
  .value {
    font-family: default;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #030303;

    &.small {
      font-family: default;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.19;
      letter-spacing: normal;
      color: #354052;
    }

    &.big {
      font-family: default;
      font-size: 18px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
    }

    &.line {
      border-bottom: 1px solid #e6eaee;
    }

    .sub {
      font-size: 16px;
      font-weight: 0;
    }
  }
}

.profile-btn{
  font-family: default;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7f8fa4;

  text-decoration: none;

  padding: 0px;
}

img.profile {
  width: 128px;
  height: 128px;
  object-fit: cover;
  border-radius: 60px;

  position: relative;
  z-index: 1;

  &.large {
    width: 83px;
    height: 83px;
  }
}

.split {
  vertical-align: top;
  display: inline-block;
  top: 0;

  &.left {
    left: 0;
    width: 95px;
  }

  &.right {
    right: 0;
  }

  .detail {
    margin-bottom: 6px;
  }
}

.el-button {
  &.side-button {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 15px;

    vertical-align: middle;

    img {
      display: inline;
      vertical-align: middle;

      width: 20px;
      height: 20px
    }
  }
}

.no-item {
  font-family: default;
  font-size: 18px;
  font-style: normal;
  // height: 112px;
  color: #8e8e93 !important;

  width: 100%;
  text-align: center;
  vertical-align: middle;
  line-height: 84px;
}
</style>
