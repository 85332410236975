<template>
  <div>

    <!-- Sub Navbar -->
    <Subnavbar>
      <template slot="breadcrumb">
      </template>
      <template slot="title">REQUEST PUBLIC STOCK BLOOD BAG</template>
      <template
        slot="actions"
        class="action-wrapper"
      >
        <button
          class="btn btn-large btn-plain hoverable"
          @click="backToDashboard"
        >
          Cancel
        </button>
        <button
          class="btn btn-large btn-primary"
          @click="submitBloodBagRequest"
        >
          <i class="fa fa-external-link-alt"></i>Send Request
        </button>
      </template>
    </Subnavbar>

    <!-- Request -->
    <el-row
      :gutter="10"
      v-if="bloodRequestForm"
    >
      <el-col>
        <ContentBox>
          <div class="row">
            <div
              class="col-md-8 flex-end flex-middle"
              style="padding: 5px;"
            >
              <label>Request Date/Time <span style="color: red;">*</span> </label>
            </div>

            <div
              class="col-md-8 flex-start flex-middle"
              style="padding: 5px;"
            >
              <el-date-picker
                size="mini"
                v-model="bloodRequestForm.bloodRequestDate"
                type="datetime"
                placeholder="โปรดระบุวันที่ต้องการใช้เลือด"
              ></el-date-picker>
            </div>
          </div>

          <div class="row">
            <div
              class="col-md-8 flex-end flex-middle"
              style="padding: 5px;"
            >
              <label>To Hospital <span style="color: red;">*</span> </label>
            </div>

            <div
              class="col-md-8 flex-start flex-middle"
              style="padding: 5px;"
            >
              <at-select
                v-validate="'required'"
                v-model="bloodRequestForm.targetHospitalId"
                placeholder="โปรดระบุโรงพยาบาล"
                @on-change="updateBloodBagDropDown"
                style="width:220px"
              >
                <at-option
                  disabled
                  value=""
                >
                  <at-input
                    v-model="hospitalSearchText"
                    placeholder="ค้นหาโรงพยาบาล"
                    prepend-button
                  >
                    <template slot="prepend">
                      <i class="icon icon-search"></i>
                    </template>
                  </at-input>
                </at-option>
                <at-option
                  v-for="(hospital, idx) in nonSelfHospitals"
                  :key="idx"
                  v-bind:value="hospital.id"
                >{{hospital.name}}</at-option>
              </at-select>
            </div>
          </div>

          <div class="row">
            <div
              class="col-md-8 flex-end flex-middle"
              style="padding: 5px;"
            >
              <label>Pet Type <span style="color: red;">*</span> </label>
            </div>

            <div
              class="col-md-8 flex-start flex-middle"
              style="padding: 5px; padding-top: 0px;"
            >
              <at-radio-group
                v-model="bloodRequestForm.petType"
                @radio-group-change="updateBloodBagDropDown"
              >
                <at-radio :label="petTypeEnum.DOG">Dog</at-radio>
                <at-radio
                  :label="petTypeEnum.CAT"
                  style="margin-left: 15px"
                >Cat</at-radio>
              </at-radio-group>
            </div>
          </div>

          <div class="row">
            <div
              class="col-md-8 flex-end flex-middle"
              style="padding: 5px;"
            >
              <label>Blood Product <span style="color: red;">*</span> </label>
            </div>

            <div
              class="col-md-8 flex-start flex-middle"
              style="padding: 5px;"
            >
              <at-select
                v-validate="'required'"
                v-model="bloodRequestForm.bloodType"
                @on-change="updateBloodBagDropDown"
                style="width:220px"
              >
                <at-option :value="bloodType.FWB">Fresh Whole Blood (FWB)</at-option>
                <at-option :value="bloodType.SWB">Stored Whole Blood (SWB)</at-option>
                <at-option :value="bloodType.PRBCS">Packed RBCs RBCs (pRBCs)</at-option>
                <at-option :value="bloodType.PRP">Platelet Rich Plasma (PRP)</at-option>
                <at-option :value="bloodType.FFP">Fresh Frozen Plasma (FFP)</at-option>
                <at-option :value="bloodType.FP">Fresh Plasma (FP)</at-option>
              </at-select>
            </div>
          </div>

          <div class="row">
            <div
              class="col-md-8 flex-end flex-middle"
              style="padding: 5px;"
            >
              <label>SPBB Blood Bag ID <span style="color: red;">*</span> </label>
            </div>

            <div
              class="col-md-8 flex-start flex-middle"
              style="padding: 5px;"
            >
              <at-select
                ref="bloodBagSelect"
                v-validate="'required'"
                v-model="bloodRequestForm.bloodBagId"
                placeholder="โปรดระบุ ID ถุงเลือด"
                @on-change="onChangeBloodBag"
                style="width:220px"
              >
                <at-option
                  v-for="(bloodBag, idx) in filteredPublicBloodBagList"
                  :key="idx"
                  v-bind:value="bloodBag.id"
                >{{bloodBag.id}}</at-option>
              </at-select>
            </div>
          </div>

          <div class="row">
            <div
              class="col-md-8 flex-end flex-middle"
              style="padding: 5px;"
            >
              <label>Volume <span style="color: red;">*</span> </label>
            </div>

            <div
              class="col-md-8 flex-start flex-middle"
              style="padding: 5px;"
            >
              <at-input
                ref="requestAmount"
                v-validate="'required'"
                v-model="bloodRequestForm.amount"
                placeholder="โปรดระบุปริมาตร"
                size="small"
                style="width: 220px; display: inline-block;"
              >{{bloodRequestForm.amount}}</at-input>
              <span>ml.</span>
            </div>
          </div>

          <div class="row">
            <div
              class="col-md-8 flex-end flex-middle"
              style="padding: 5px;"
            >
              <label>Note </label>
            </div>

            <div
              class="col-md-8 flex-start flex-middle"
              style="padding: 5px;"
            >
              <el-input
                type="textarea"
                v-model="bloodRequestForm.note"
                :autosize="{ minRows: 3, maxRows: 3}"
                placeholder="บันทึกเพิ่มเติม ..."
              ></el-input>
            </div>
          </div>
        </ContentBox>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import ContentBox from "../../../components/container/ContentBox";
import Subnavbar from "../../../components/navigation/Subnavbar";
import { mapState } from "vuex";
import bloodType from "../../../constants/bloodbank/blood-product-type-enum";
import bloodBagStatus from "../../../constants/bloodbank/blood-storage-bag-status";
import petTypeEnum from "../../../constants/bloodbank/pet-type-enum";
import BloodRequestHospitalDetail from "../../../models/data/blood-request-hospital/blood-request-hospital-detail/blood-request-hospital-detail";
export default {
  name: "BloodRequestHospitalNewPage",
  components: { Subnavbar, ContentBox },
  data() {
    return {
      bloodType,
      petTypeEnum,
      bloodBagStatus,

      avaliableBloodBagList: [],
      filteredPublicBloodBagList: [],

      bloodRequestForm: null,
      hospitalSearchText: ""
    };
  },
  methods: {
    submitBloodBagRequest: function() {
      this.$validator.validateAll().then(result => {
        if (!result) {
          this.$Notify.error({
            title: "ข้อมูลผิดพลาด",
            message: "กรุณากรอกข้อมูลให้ถูกต้อง"
          });
          return;
        }
        this.bloodRequestForm.hospitalId = this.hospitalInfo.id;
        this.$store
          .dispatch("bloodbank/createBloodHospitalRequest", {
            data: this.bloodRequestForm.toRequestJson()
          })
          .then(result => {
            if (result) {
              this.$store.commit(
                "bloodbank/NEW_BLOOD_HOSPITAL_REQUEST",
                new BloodRequestHospitalDetail(result)
              );

              this.$notify.success({
                title: "สำเร็จ",
                message: "ดำเนินการเรียบร้อย"
              });
              this.$router.replace({
                name: "requestHospitalDetail",
                params: { id: result.id }
              });
            } else {
              this.$Notify.error({
                title: "ข้อมูลผิดพลาด",
                message: "กรุณากรอกข้อมูลให้ถูกต้อง"
              });
            }
          });
      });
    },
    onChangeBloodBag: function() {
      let bloodBagId = this.bloodRequestForm.bloodBagId;
      let bloodBag = this.$store.getters["bloodbank/getBloodBagById"](
        bloodBagId
      );
      if (bloodBag) {
        this.bloodRequestForm.amount = bloodBag.quantity;
        this.$refs.requestAmount.setCurrentValue(bloodBag.quantity);
      }
      this.$refs.bloodBagSelect.updateOptions();
    },
    filterAvaliableBloodBag: function(bag) {
      let isValid = false;
      if (bag.donationId) {
        let donationInfo = this.$store.getters["bloodbank/getDonationById"](
          bag.donationId
        );
        isValid = !donationInfo || donationInfo.isBloodCollectSubmitted;
      } else {
        isValid = true;
      }
      return (
        bag.status !== this.bloodBagStatus.DISBURSE &&
        bag.status !== this.bloodBagStatus.EXPIRE &&
        bag.quantity > 0 &&
        isValid &&
        bag.reasonOfRemoval == null
      );
    },
    updateBloodBagDropDown: function() {
      this.bloodRequestForm.bloodBagId = "";
      this.bloodRequestForm.amount = "";
      this.filteredPublicBloodBagList = this.avaliableBloodBagList
        .filter(
          item => item.hospitalId === this.bloodRequestForm.targetHospitalId
        )
        .filter(item => item.petType === this.bloodRequestForm.petType)
        .filter(item => item.bloodType === this.bloodRequestForm.bloodType);
    },
    backToDashboard: function() {
      this.$router.push({ name: "requestHospital" });
    }
  },
  computed: {
    ...mapState({
      bloodBags: state => state.bloodbank.bloodBags,
      requestList: state => state.bloodbank.bloodHospitalRequests,
      requestApprovalList: state =>
        state.bloodbank.bloodHospitalRequestApprovals,
      hospitalInfo: state => state.hospital.profile,
      hospitals: state => state.hospital.hospitals
    }),
    nonSelfHospitals: function() {
      if (!this.hospitalInfo) {
        return [];
      }

      return this.hospitals
        .filter(item => item.id != this.hospitalInfo.id)
        .filter(item => item.name.includes(this.hospitalSearchText));
    }
  },
  async mounted() {
    await this.$store.dispatch("ui/setIsFullScreenLoading", true);

    try {
      // List&Fill Donations
      if (this.$store.state.bloodbank.donations.length < 1) {
        await this.$store.dispatch("bloodbank/listAndFillBloodDonations");
      }
      // List&Fill Requests
      if (this.$store.state.bloodbank.requests.length < 1) {
        await this.$store.dispatch("bloodbank/listAndFillBloodRequests");
      }
      // List Vets
      if (this.$store.state.hospital.vets.length < 1) {
        await this.$store.dispatch("hospital/listVets");
      }
      // List Hospitals
      if (this.hospitals.length < 1) {
        await this.$store.dispatch("hospital/listHospitals");
      }
      // List Blood Bags
      if (this.bloodBags.length < 1) {
        await this.$store.dispatch("bloodbank/listAndFillBloodCollectInfo");
      }

      // List Requests
      if (this.requestApprovalList.length < 1) {
        await this.$store.dispatch(
          "bloodbank/listBloodHospitalRequestApprovals"
        );
      }
      if (this.requestList.length < 1) {
        await this.$store.dispatch("bloodbank/listBloodHospitalRequests");
      }
    } catch (e) {
      await this.$store.dispatch("ui/showFetchErrorMsg");
    }

    this.avaliableBloodBagList = this.$store.getters[
      "bloodbank/getPublicBloodBagList"
    ]().filter(item => this.filterAvaliableBloodBag(item));

    this.bloodRequestForm = new BloodRequestHospitalDetail();

    await this.$store.dispatch("ui/setIsFullScreenLoading", false);
  }
};
</script>

<style scoped>
</style>
