<template>
  <el-container>
    <VetConfirmModal
      v-bind:isShow="isConfirmModalShow"
      :vet="selectedVet"
      v-bind:onSubmit="onNextClicked"
      v-bind:onCancel="() => isConfirmModalShow = false"
    ></VetConfirmModal>

    <router-view
      v-if="request"
      ref="stepForm"
      :mode="mode"
      :request="request"
      @save="validateForm()"
    >
      <template
        slot="header"
        slot-scope="{ currentProgress, currentText, currentVetType }"
      >
        <!-- Sub Navbar -->
        <Subnavbar>
          <template slot="breadcrumb">
            <ul>
              <li>Request Profile</li>
            </ul>
          </template>
          <template slot="title">{{ titleText || currentText }}</template>
          <template
            slot="actions"
            class="action-wrapper"
          >
            <!-- <el-button
              type="text"
              disabled
            >วันที่ {{ getCurrentDate }} เวลา {{ getCurrentTime }}</el-button> -->
            <el-button
              type="text"
              v-if="profile.id === request.hospitalId && !/(Timeline|Exam|Lab)/.test($route.name)"
            >
              <label class="btn-large error-field-label">{{currentVetType}} *</label>
              <el-select
                :disabled="request.isCaseClosed()&&$route.name!=='requestResult'"
                v-model="selectedVetID"
                size="large"
                style=" width: 300px;"
                placeholder="กรุณาเลือก"
              >
                <el-option
                  :key="idx"
                  :label="itm.title + ' ' + itm.first_name + ' ' + itm.last_name"
                  :value="itm.id"
                  v-for="(itm, idx) in vets"
                ></el-option>
              </el-select>
            </el-button>
            <span v-if="!$route.name.includes('Timeline')">
              <el-button
                v-if="(!request.isBloodBagReceived||$route.name==='requestResult')&&!request.isTicketRejected"
                class="btn-large"
                type="primary"
                @click="validateForm()"
              >
                <i class="fa fa-save"></i>Save
              </el-button>
              <span v-else>
                <el-button
                  plain
                  class="btn-large"
                  icon="fa fa-list"
                  @click="changePage('requestTimeline')"
                >Timeline</el-button>
                <el-button
                  class="btn-large"
                  type="primary"
                  icon="fa fa-chevron-left"
                  v-if="getCurrentPage>0"
                  @click="changePage('previous')"
                >Back</el-button>
                <el-button
                  class="btn-large"
                  type="primary"
                  :disabled="!canGoNext"
                  @click="changePage('next')"
                >
                  Next
                  <i class="fa fa-chevron-right"></i>
                </el-button>
              </span>
            </span>
            <span v-else>
              <el-button
                plain
                class="btn-large"
                icon="fa fa-chevron-left"
                @click="$router.push({ name: 'request'})"
              >Back</el-button>
              <el-button
                plain
                class="btn-large btn-danger"
                icon="fa fa-trash"
                v-if="!request.isBloodBagReceived&&!request.isTicketRejected"
                @click="rejectTicket()"
              >Cancel Request</el-button>
            </span>
          </template>
        </Subnavbar>

        <!-- Progress Step -->
        <el-steps
          v-if="request && currentProgress > 0"
          :active="currentProgress"
        >
          <el-step
            v-for="p in (getCurrentPage>=3 ? request.getProgressList():request.getSubProgressList())"
            :key="p.title"
            :title="p.title"
          ></el-step>
        </el-steps>

        <el-row>
          <BloodRequestProfileHeader
            :mode="mode"
            :request="request"
          ></BloodRequestProfileHeader>
        </el-row>
      </template>
    </router-view>
  </el-container>
</template>


<script>
import { mapState, mapGetters } from "vuex";
import moment from "moment";
import Subnavbar from "@/components/navigation/Subnavbar";
import BloodRequestProfileHeader from "@/components/bloodbank/blood-request/BloodRequestProfileHeader";
import PetInfo from "@/models/data/common/pets-info/pet-info/pet-info";
import BloodRequestInfo from "@/models/data/blood-request/blood-request-info/blood-request-info";
import VetConfirmModal from "@/components/bloodbank/controls/vet-confirm-modal/VetConfirmModal.vue";
import ContentTypeEnums from "@/constants/bloodbank/content-type.js";
import BloodCollectionHistoryActionEnums from "@/constants/bloodbank/blood-collect-history-action.js";
import BloodBagHistoryInfo from "@/models/data/blood-storage/blood-bag-history-info/blood-bag-history-info";
import _ from "lodash";

export default {
  components: {
    Subnavbar,
    BloodRequestProfileHeader,
    VetConfirmModal
  },
  props: {
    mode: {
      default: "view"
    }
  },
  data() {
    return {
      request: null,
      isConfirmModalShow: false,
      pageList: [
        "requestExam",
        "requestLab",
        "requestRequest",
        "requestCross",
        "requestReserve",
        "requestConfirm",
        "requestResult"
      ],
      pageListCreate: [
        "requestCreateExam",
        "requestCreateLab",
        "requestCreateRequest"
      ],
      contentTypeEnums: ContentTypeEnums,
      bloodCollectionHistoryActionEnums: BloodCollectionHistoryActionEnums
    };
  },
  computed: {
    ...mapState({
      requests: state => state.bloodbank.requests,
      vets: state => state.hospital.vets,
      profile: state => state.hospital.profile,
      selectedPet: state => state.pet.selected,
      diagnosisList: state => state.bloodbank.diagnosis
    }),
    ...mapGetters({
      getBloodBagById: "bloodbank/getBloodBagById"
    }),
    titleText() {
      return this.mode === "view"
        ? `Request Profile / ID: ${this.request.id} ${
            this.$route.name.includes("Timeline") ? "/ " + this.statusText : ""
          }`
        : "";
    },
    statusText() {
      if (this.request.isFeedbackSubmitted) {
        return "Done";
      } else if (this.request.isTicketRejected) {
        return "Cancelled";
      } else if (this.request.isBloodBagReceived) {
        return "รับเลือดแล้ว";
      } else if (this.request.isWithdrawBloodBagReceived) {
        return "เบิกถุงเลือด";
      } else if (this.request.isCrossmatchSubmitted) {
        return "Crossmatch";
      } else if (
        this.request.recipientProfileInfo.isLabExamSubmitted ||
        this.request.recipientProfileInfo.isProductRequestSubmitted ||
        this.request.recipientProfileInfo.isRecipientProfileSubmitted
      ) {
        return "ยื่นคำขอถุงเลือด";
      }
    },
    // getCurrentDate: function() {
    //   var currentDate = moment().format("DD-MM-YYYY");
    //   return currentDate;
    // },
    // getCurrentTime: function() {
    //   var currentTime = moment().format("HH:mm");
    //   return currentTime;
    // },
    canGoNext() {
      let can = false;
      if (this.getCurrentPage < this.pageList.length) {
        if (this.request.isTicketRejected) {
          const stepCheck = [
            this.request.isCrossmatchSubmitted,
            this.request.isWithdrawBloodBagReceived,
            this.request.isBloodBagReceived,
            this.request.isFeedbackSubmitted
          ];
          if (this.getCurrentPage < 2 || stepCheck[this.getCurrentPage - 2]) {
            can = true;
          }
        } else {
          can = true;
        }
      }
      return can;
    },
    selectedVetID: {
      get() {
        if (!this.request) return null;
        switch (this.$route.name) {
          case "requestExam":
          case "requestLab":
          case "requestCreateRequest":
          case "requestRequest":
            return this.request.recipientProfileInfo.productRequest.requester;
          case "requestCross":
            return this.request.crossmatchExaminer;
          case "requestReserve":
            return this.request.reserveBlood.approverId;
          case "requestConfirm":
            return this.request.receiveBlood.approver;
          case "requestResult":
            return this.request.feedback.approver;
        }
      },
      set(val) {
        if (!this.request) return;
        switch (this.$route.name) {
          case "requestExam":
          case "requestLab":
          case "requestCreateRequest":
          case "requestRequest":
            this.request.recipientProfileInfo.productRequest.requester = val;
            break;
          case "requestCross":
            this.request.crossmatchExaminer = val;
            break;
          case "requestReserve":
            this.request.reserveBlood.approverId = val;
            break;
          case "requestConfirm":
            this.request.receiveBlood.approver = val;
            break;
          case "requestResult":
            this.request.feedback.approver = val;
            break;
        }
      }
    },
    selectedVet() {
      return this.vets.find(v => v.id === this.selectedVetID);
    },
    getCurrentPage() {
      if (this.$route.name.includes("Create"))
        return this.pageListCreate.indexOf(this.$route.name);
      else return this.pageList.indexOf(this.$route.name);
    }
  },
  methods: {
    changePage(name) {
      if (name === "next") {
        if (this.$route.name.includes("Create")) {
          this.$router.push({
            name: this.pageListCreate[this.getCurrentPage + 1]
          });
        } else {
          this.$router.push({ name: this.pageList[this.getCurrentPage + 1] });
        }
      } else if (name === "previous")
        this.$router.push({ name: this.pageList[this.getCurrentPage - 1] });
      else this.$router.push({ name: name });
    },
    validateForm: async function() {
      switch (this.$route.name) {
        case "requestCreateExam":
        case "requestCreateLab":
        case "requestExam":
        case "requestLab":
          if (!(await this.$refs.stepForm.$validator.validateAll())) {
            this.$notify({
              message: "ข้อมูลผิดพลาด กรุณากรอกข้อมูลให้ถูกต้อง",
              type: "error"
            });
            return;
          }
          this.changePage("next");
          break;
        case "requestCreateRequest":
        case "requestRequest":
          this.showVetConfirmModal();
          break;
        case "requestCross":
          this.showVetConfirmModal();
          break;

        case "requestReserve":
          this.showVetConfirmModal();
          break;

        case "requestConfirm":
          this.showVetConfirmModal();
          break;

        case "requestResult":
          this.showVetConfirmModal();
          break;
      }
    },
    onNextClicked: async function() {
      let result = await this.$refs.stepForm.$validator.validateAll();
      if (!result || !this.hasApproverSelected()) {
        this.$notify({
          message: "ข้อมูลผิดพลาด กรุณากรอกข้อมูลให้ถูกต้อง",
          type: "error"
        });
        return;
      }

      try {
        var crossmatchJustSubmitted;
        var reserveJustSubmitted;
        switch (this.$route.name) {
          case "requestRequest":
          case "requestCreateRequest":
            this.request.isRecipientProfileSubmitted = true;
            this.request.recipientProfileInfo.isRecipientProfileSubmitted = true;
            this.request.recipientProfileInfo.isLabExamSubmitted = true;
            this.request.recipientProfileInfo.isProductRequestSubmitted = true;
            break;

          case "requestCross":
            await this.$refs.stepForm.beforeChangeStep();
            crossmatchJustSubmitted = !this.request.isCrossmatchSubmitted;
            this.request.isCrossmatchSubmitted = true;
            break;

          case "requestReserve": {
            await this.$refs.stepForm.beforeChangeStep();
            reserveJustSubmitted = !this.request.isWithdrawBloodBagReceived;
            this.request.isWithdrawBloodBagReceived = true;
            break;
          }
          case "requestConfirm": {
            await this.$refs.stepForm.beforeChangeStep();
            this.request.isBloodBagReceived = true;
            // Update TargetBloodbag quantity
            this.request.reserveBlood.bloodBagList.forEach(reservation => {
              if (reservation.isSelected && reservation.isDirty) {
                let bloodBag = this.getBloodBagById(
                  reservation.crossmatchInfo.bloodBagId1
                );
                bloodBag.status = 2; // Contaminate
                bloodBag.quantity -= reservation.quantity;

                this.$store
                  .dispatch("bloodbank/updateFullBloodCollectInfo", {
                    id: bloodBag.id,
                    data: bloodBag.toRequestJson(),
                    isDataReady: true
                  })
                  .then(bc => {
                    let newBloodCollectionHistoryInfo = new BloodBagHistoryInfo();
                    // Set Info Here
                    newBloodCollectionHistoryInfo.blood_collection =
                      reservation.crossmatchInfo.bloodBagId1;
                    newBloodCollectionHistoryInfo.action = this.bloodCollectionHistoryActionEnums.Received;
                    newBloodCollectionHistoryInfo.content_type = this.contentTypeEnums.BloodRequest;
                    newBloodCollectionHistoryInfo.object_id = this.request.id;

                    this.$store.dispatch(
                      "bloodbank/createBloodCollectionHistory",
                      { data: newBloodCollectionHistoryInfo.toRequestJson() }
                    );
                  });
              }
            });
            break;
          }
          case "requestResult":
            await this.$store.dispatch("ui/setIsFullScreenLoading", true);
            await this.$refs.stepForm.beforeChangeStep();
            this.request.isFeedbackSubmitted = true;
            break;
        }

        await this.createOrUpdateRequest();
        if (crossmatchJustSubmitted) {
          console.log("Create History Crossmatch");
          // Loop here
          _.forEach(
            _.union(
              this.request.crossmatch.singleCrossmatchList,
              this.request.crossmatch.multiCrossmatchList
            ),
            crossmatchItem => {
              let newBloodCollectionHistoryInfo = new BloodBagHistoryInfo();
              // Set Info Here
              newBloodCollectionHistoryInfo.blood_collection =
                crossmatchItem.bloodBagId1;
              newBloodCollectionHistoryInfo.action = this.bloodCollectionHistoryActionEnums.Crossmatch;
              newBloodCollectionHistoryInfo.content_type = this.contentTypeEnums.Crossmatch;
              newBloodCollectionHistoryInfo.object_id = crossmatchItem.id;

              this.$store.dispatch("bloodbank/createBloodCollectionHistory", {
                data: newBloodCollectionHistoryInfo.toRequestJson()
              });

              // 3 ways
              if (crossmatchItem.bloodBagId2) {
                let newBloodCollectionHistoryInfo = new BloodBagHistoryInfo();
                // Set Info Here
                newBloodCollectionHistoryInfo.blood_collection =
                  crossmatchItem.bloodBagId2;
                newBloodCollectionHistoryInfo.action = this.bloodCollectionHistoryActionEnums.Crossmatch;
                newBloodCollectionHistoryInfo.content_type = this.contentTypeEnums.Crossmatch;
                newBloodCollectionHistoryInfo.object_id = crossmatchItem.id;

                this.$store.dispatch("bloodbank/createBloodCollectionHistory", {
                  data: newBloodCollectionHistoryInfo.toRequestJson()
                });
              }
            }
          );
        } else if (reserveJustSubmitted) {
          console.log("Create History Reserve");
          // Loop here
          _.forEach(this.request.reserveBlood.bloodBagList, reserveBlood => {
            if (reserveBlood.isDirty && reserveBlood.isSelected) {
              let newBloodCollectionHistoryInfo = new BloodBagHistoryInfo();
              // Set Info Here
              newBloodCollectionHistoryInfo.blood_collection =
                reserveBlood.crossmatchInfo.bloodBagId1;
              newBloodCollectionHistoryInfo.action = this.bloodCollectionHistoryActionEnums.Reserve;
              newBloodCollectionHistoryInfo.content_type = this.contentTypeEnums.Reserve;
              newBloodCollectionHistoryInfo.object_id = this.request.reserveBlood.id;

              this.$store.dispatch("bloodbank/createBloodCollectionHistory", {
                data: newBloodCollectionHistoryInfo.toRequestJson()
              });

              if (reserveBlood.crossmatchInfo.bloodBagId2) {
                let newBloodCollectionHistoryInfo = new BloodBagHistoryInfo();
                // Set Info Here
                newBloodCollectionHistoryInfo.blood_collection =
                  reserveBlood.crossmatchInfo.bloodBagId2;
                newBloodCollectionHistoryInfo.action = this.bloodCollectionHistoryActionEnums.Reserve;
                newBloodCollectionHistoryInfo.content_type = this.contentTypeEnums.Reserve;
                newBloodCollectionHistoryInfo.object_id = this.request.reserveBlood.id;

                this.$store.dispatch("bloodbank/createBloodCollectionHistory", {
                  data: newBloodCollectionHistoryInfo.toRequestJson()
                });
              }
            }
          });
        }

        this.changePage("request");
      } catch (e) {
        this.$Notify.error({
          title: "ข้อมูลผิดพลาด",
          message: e
        });
      }
    },
    async createOrUpdateRequest() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        let result = null;
        if (this.mode === "create") {
          result = await this.$store.dispatch(
            "bloodbank/createFullRequest",
            this.request
          );
        } else if (this.mode === "view") {
          result = await this.$store.dispatch("bloodbank/updateFullRequest", {
            id: this.request.bloodRequestId,
            data: this.request
          });
        }
        // eslint-disable-next-line no-console
        console.log(result);
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorMsg");
      }
    },
    hasApproverSelected() {
      switch (this.$route.name) {
        case "requestExam":
        case "requestCreateExam":
          return (
            this.request.medicalRecord.examiner != null &&
            this.request.medicalRecord.examiner !== ""
          );

        case "requestLab":
          return (
            this.request.labExam.examiner != null &&
            this.request.labExam.examiner !== ""
          );

        case "requestCare":
          return (
            this.request.donorCareInfo.approver != null &&
            this.request.donorCareInfo.approver !== ""
          );

        case "requestCollect":
          return (
            this.request.bloodCollectInfo.collector != null &&
            this.request.bloodCollectInfo.collector !== ""
          );

        default:
          return true;
      }
    },
    showVetConfirmModal() {
      if (!this.selectedVet) {
        this.$notify({
          message: "ข้อมูลผิดพลาด กรุณาเลือกผู้ตรวจ",
          type: "error"
        });
        return;
      }
      this.isConfirmModalShow = true;
    },
    async rejectTicket() {
      this.$confirm(
        "คุณยืนยันที่จะยกเลิกขอเบิกเลือดนี้ใช่ไหม ?",
        "คำเตือน"
      ).then(() => {
        this.$store
          .dispatch("bloodbank/cancelRequest", {
            id: this.request.id,
            data: this.request
          })
          .then(() => this.$router.push({ name: "request" }));
      });
    }
  },
  async mounted() {
    try {
      await this.$store.dispatch("ui/setIsFullScreenLoading", true);

      // List&Fill Requests
      if (this.requests.length < 1) {
        await this.$store.dispatch("bloodbank/listAndFillBloodRequests");
      }

      // List&Fill Donations
      if (this.$store.state.bloodbank.donations.length < 1) {
        await this.$store.dispatch("bloodbank/listAndFillBloodDonations");
      }

      // List Vets
      if (this.vets.length < 1) {
        await this.$store.dispatch("hospital/listVets");
      }

      // List diagnosis
      if (this.diagnosisList.length < 1)
        await this.$store.dispatch("bloodbank/listDiagnosis");

      // List bloodbags
      if (this.$store.state.bloodbank.bloodBags.length < 1) {
        await this.$store.dispatch("bloodbank/listAndFillBloodCollectInfo");
      }

      // Pre-set
      this.request = new BloodRequestInfo();

      // If in create mode, fetch the selected pet and set dummy request
      if (this.mode === "create") {
        await this.$store.dispatch("pet/getPetProfile", {
          params: { petHospitalId: this.$route.params.id }
        });
        this.request.petInfo = new PetInfo().setPropsFromPetObject(
          this.selectedPet
        );
      }
      // If in view mode, clone current request for edit purpose
      else if (this.mode === "view") {
        let src = this.requests.find(
          d => d.id === parseInt(this.$route.params.id)
        );
        // Commit once for id
        this.$store.commit("bloodbank/SET_SELECTED_REQUEST", src);
        this.request.cloneFrom(src);
      }

      // Post-set
      let storeRequest = new BloodRequestInfo();
      storeRequest.cloneFrom(this.request);
      this.$store.commit("bloodbank/SET_SELECTED_REQUEST", storeRequest);

      await this.$store.dispatch("ui/setIsFullScreenLoading", false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      await this.$store.dispatch("ui/showFetchErrorMsg");
    }
  }
};
</script>

<style lang="scss" scoped>
.subnavbar .left-col {

  .title {
    font-size: 15px !important;

    @media (min-width: 1200px) {
      font-size: 20px !important;
    }
  }
}
</style>
