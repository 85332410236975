import HospitalProfilePage from '@/pages/hospital/ProfilePage.vue' 
import ReportPage from '@/pages/hospital/ReportPage.vue' 
import BroadcastPage from '@/pages/broadcast/BroadcastPage.vue'
import BroadcastCreatePage from '@/pages/broadcast/BroadcastCreatePage.vue'
import BroadcastDetailPage from '@/pages/broadcast/BroadcastDetailPage.vue'
import HospitalMessageDetail from '@/pages/hospital/HospitalMessageDetail.vue'
import HospitalDirectMessage from '@/pages/hospital/HospitalDirectMessage.vue'
import HospitalPetOwnerProfile from '@/pages/hospital/HospitalPetOwnerProfile.vue'
import HospitalPetOwnerProfileEdit from '@/pages/hospital/HospitalPetOwnerProfileEdit.vue'
import HospitalPetOwnerAddNewPet from '@/pages/hospital/HospitalPetOwnerAddNewPet.vue'
import HospitalPetProfileEdit from '@/pages/hospital/HospitalPetProfileEdit.vue'
import HospitalPetOwnerRegister from '@/pages/hospital/HospitalPetOwnerRegister.vue'
import HospitalPetListPage from '@/pages/hospital/HospitalPetListPage.vue'
import HospitalPetProfilePage from '@/pages/hospital/HospitalPetProfilePage.vue'
import HospitalPetSearchPage from '@/pages/hospital/HospitalPetSearchPage.vue'
import HospitalPetSearchMicrochipPage from '@/pages/hospital/HospitalPetSearchMicrochipPage.vue'
import HospitalPetRegister from '@/pages/hospital/HospitalPetRegister.vue'
import HospitalPetAddNewOwner from '@/pages/hospital/HospitalPetAddNewOwner.vue'
import HospitalPetSearchOwnerPage from '@/pages/hospital/HospitalPetSearchOwnerPage.vue'
import HospitalOtherSetting from '@/pages/hospital/OtherSetting.vue'
import PageError403 from '@/pages/auth/PageError403.vue'

const routes = [
  {
    path: '/hospital/403',
    name: 'hospitalError403',
    component: PageError403,
    meta: {
      requiresAuth: false,
      permissions: ['hospital_admin']
    }
  },
  {
    path: '/hospital/profile',
    name: 'hospitalProfile',
    component: HospitalProfilePage,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin']
    }
  },
  {
    path: '/hospital/report',
    name: 'hospitalReport',
    component: ReportPage,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin']
    }
  },
  {
    path: '/hospital/broadcast',
    name: 'hospitalBroadcast',
    component: BroadcastPage,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
			plan_permissions: ['broadcast_section.can_use_broadcast']
    }
  },
  {
    path: '/hospital/broadcast/create',
    name: 'hospitalBroadcastCreate',
    component: BroadcastCreatePage,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
			plan_permissions: ['broadcast_section.can_use_broadcast']
    }
  },
  {
    path: '/hospital/broadcast/:id',
    name: 'hospitalBroadcastDetail',
    component: BroadcastDetailPage,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
			plan_permissions: ['broadcast_section.can_use_broadcast']
    }
  },
  {
    path: '/hospital/message/:type/:id',
    name: 'hospitalMessageDetail',
    component: HospitalMessageDetail,
    meta: {
      requiresAuth: false,
      permissions: []
    }
  },
  {
    path: '/hospital/direct-message/:id',
    name: 'hospitalDirectMessage',
    component: HospitalDirectMessage,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
			plan_permissions: ['direct_message_section.can_use_direct_message']
    }
  },
  {
    path: '/hospital/owner/register',
    name: 'hospitalPetOwnerRegister',
    component: HospitalPetOwnerRegister,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
			plan_permissions: ['hospital_feature.can_create_owner_and_pet']
    }
  },
  {
    path: '/hospital/owner/:id',
    name: 'hospitalPetOwnerProfile',
    component: HospitalPetOwnerProfile,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin']
    }
  },
  {
    path: '/hospital/owner/:id/edit',
    name: 'HospitalPetOwnerProfileEdit',
    component: HospitalPetOwnerProfileEdit,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin']
    }
  },
  {
    path: '/hospital/owner/:id/pet/register',
    name: 'hospitalPetOwnerAddNewPet',
    component: HospitalPetOwnerAddNewPet,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
			plan_permissions: ['hospital_feature.can_create_owner_and_pet']
    }
  },
  {
    path: '/hospital/pet/:id/edit',
    name: 'hospitalPetProfileEdit',
    component: HospitalPetProfileEdit,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin']
    }
  },
  {
    path: '/hospital/pet/:id/searchOwner',
    name: 'hospitalPetSearchOwnerPage',
    component: HospitalPetSearchOwnerPage,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin']
    }
  },
  {
    path: '/hospital/pet/:id/addNewOwner',
    name: 'hospitalPetAddNewOwner',
    component: HospitalPetAddNewOwner,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
			plan_permissions: ['hospital_feature.can_create_owner_and_pet']
    }
  },
  {
    path: '/hospital/pets',
    name: 'hospitalPets',
    component: HospitalPetListPage,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin']
    }
  },
  {
    path: '/hospital/pet/register',
    name: 'hospitalPetRegister',
    component: HospitalPetRegister,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin']
    }
  },
  {
    path: '/hospital/pet/:id',
    name: 'hospitalPetProfile',
    component: HospitalPetProfilePage,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin']
    }
  },
  {
    path: '/hospital/pets/search',
    name: 'hospitalPetsSearch',
    component: HospitalPetSearchPage,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin']
    }
  },
  {
    path: '/hospital/search-microchip',
    name: 'hospitalPetsSearchMicrochip',
    component: HospitalPetSearchMicrochipPage,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
			plan_permissions: ['hospital_feature.can_use_microchip_search']
    }
  },
  {
    path: '/hospital/other/setting',
    name: 'hospitalOtherSetting',
    component: HospitalOtherSetting,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
			plan_permissions: ['setting_section.can_use_vat_setting']
    }
  }
]

export default routes
