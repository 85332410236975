<template>
  <div class="error-403-page">
    <Subnavbar>
      <template slot="title">สิทธิ์การเข้าใช้งานระบบ</template>
      <template slot="actions">
        <button class="btn btn-large btn-primary" @click="$router.push({ name: 'hospitalHome' })">
          <i class="fa fa-home"></i>
          กลับไปหน้าแรก
        </button>
      </template>
    </Subnavbar>

    <ContentBox style="padding: 20px 0;">
      <div class="icon"><i class="fa fa-exclamation-circle"></i></div>
      <h1 class="title">{{ errorMessage || 'ไม่มีสิทธ์เข้าใช้งานระบบในส่วนนี้'}}</h1>
    </ContentBox>
  </div>
</template>

<script>
  import $ from 'jquery'
  import { mapState } from "vuex"
  import Subnavbar from "@/components/navigation/Subnavbar";
  import ContentBox from "@/components/container/ContentBox";
  import BroadcastSearch from "@/components/broadcast/BroadcastSearch";
  import BroadcastTable from "@/components/broadcast/BroadcastTable";

  export default {
    components: {
      ContentBox,
      Subnavbar,
      BroadcastSearch,
      BroadcastTable
    },
    computed: {
      ...mapState({
        errorMessage: state => state.auth.errorMessage
      })
    },
    async mounted() {
        $(document).ready(function () {
          $('.el-message--error').css('display', 'none');
        });
    }
  };
</script>

<style lang="scss" scoped>
  .error-403-page {
    .icon {
      text-align: center;
      font-size: 2.6em;
      color: #F5B712;
    }
    .title {
      text-align: center;
    }
  }
</style>