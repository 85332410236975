let api;

if (!api) {
  import("./apis").then((apis) => {
    api = apis.coreAPI;
  });
}

/**
 * Cut stock
 * @param {Object} data
 * @param {number[]} data.pos_set
 */
const cutStock = (data) => api.post("/pos/update-stock/", data);

/**
 * Uncut stock
 * @param {Object} data
 * @param {number[]} data.pos_set
 */
const uncutStock = (data) => api.post("/pos/revert-update-stock/", data);

/**
 * Get discarded POSes
 * @param {Object} params
 * @param {(Date|undefined)} params.start
 * @param {(Date|undefined)} params.end
 * @param {(string|undefined)} params.search
 * @param {(string|number|undefined)} params.discardedstandalonepos__vet__id\
 */
const getDiscards = (params) =>
  api.get("/pos/standalone/discarded/", { params });

/**
 * Get an unpaid POS
 * @param {number} id
 */
const getUnpayment = (id) => api.get(`/pos/unpaid/${id}/`);

/**
 * Get unpaid POSes
 * @param {Object} params
 * @param {(Date|undefined)} params.start
 * @param {(Date|undefined)} params.end
 * @param {(string|undefined)} params.search
 * @param {(string|undefined)} params.ordering
 */
const getUnpayments = (params) => api.get("/pos/unpaid/", { params });


 const getPaidList = (params) => api.get("/pos/paid/", { params });

/**
 * Get all POSes
 * @param {Object} params
 * @param {(Date|undefined)} params.start
 * @param {(Date|undefined)} params.end
 * @param {(string|undefined)} params.search
 * @param {(string|undefined)} params.status
 * @param {(number|undefined)} params.page
 */
const getAll = (params) => api.get("/pos/standalone/", { params });

/**
 * Get unpaid overview
 * @param {Object} params
 * @param {(Date|undefined)} params.start
 * @param {(Date|undefined)} params.end
 */
const getUnpaymentOverview = (params) =>
  api.get("/pos/unpaid/overview/", { params });

export default {
  cutStock,
  uncutStock,
  getDiscards,
  getUnpayment,
  getUnpayments,
  getPaidList,
  getAll,
  getUnpaymentOverview,
};
