import ProductSettingList from '@/pages/product/ProductSettingList.vue'
import ProductCreate from '@/pages/product/ProductCreate.vue'
import ProductEdit from '@/pages/product/ProductEdit.vue'

const routes = [
  {
    path: '/product-setting',
    name: 'productSetting',
    component: ProductSettingList,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['setting_section.can_use_activity_setting']
    }
  },
  {
    path: '/product-setting/create',
    name: 'productCreate',
    component: ProductCreate,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['setting_section.can_use_activity_setting']
    }
  },
  {
    path: '/product-setting/:id/',
    name: 'productEdit',
    component: ProductEdit,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['setting_section.can_use_activity_setting']
    }
  },
]

export default routes