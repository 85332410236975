<template>
  <el-card class="request-timeline">
    <el-row>
      <slot
        name="header"
        :currentProgress='this.myProgress'
        :currentText='this.myText'
      ></slot>
    </el-row>
    <el-divider></el-divider>
    <div class="block">
      <el-timeline v-if='request'>
        <el-timeline-item
          v-for='(p,i) in request.getProgressList()'
          :key='p.title'
          size='large'
          :timestamp='p.status.toUpperCase()'
          placement="top"
          :icon="p.status === 'finish' ? 'el-icon-check': 'el-icon-time'"
          :color="p.status === 'finish' ? '#0bbd87': '#ff9e24'"
        >
          <el-card>
            <el-col :span='3'>
              <img :src="imgLoader(p.img)" />
            </el-col>
            <el-col :span='19'>
              <h1>{{p.title}}</h1>
            </el-col>
            <el-col :span='2'>
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                @click='btnAction(i)'
                :disabled="isStepDisabled(i)"
              ></el-button>
            </el-col>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>

  </el-card>
</template>

<script>
export default {
  props: ["request"],
  data() {
    return {
      myProgress: 0,
      myText: "Request Timeline"
    };
  },
  computed: {},
  methods: {
    isStepDisabled(i) {
      return (
        i > 0 &&
        (this.request.getProgressList()[i - 1].status !== "finish" ||
          (this.request.isTicketRejected &&
            this.request.getProgressList()[i].status !== "finish"))
      );
    },
    imgLoader(imgPath) {
      // eslint-disable-next-line no-undef
      return require("@/assets/bloodbank/" + imgPath);
    },
    btnAction(i) {
      switch (i) {
        case 0:
          this.$router.push({ name: "requestExam" });
          break;
        case 1:
          this.$router.push({ name: "requestCross" });
          break;
        case 2:
          this.$router.push({ name: "requestReserve" });
          break;
        case 3:
          this.$router.push({ name: "requestConfirm" });
          break;
        case 4:
          this.$router.push({ name: "requestResult" });
          break;
      }
    }
  }
  // async mounted() {
  //   this.request.isRecipientProfileSubmitted = true
  //   this.request.recipientProfileInfo.isRecipientProfileSubmitted = true
  //   this.request.recipientProfileInfo.isLabExamSubmitted = true
  //   this.request.recipientProfileInfo.isProductRequestSubmitted = true
  //   let result = await this.$store.dispatch("bloodbank/updateFullRequest",{
  //     id: this.request.bloodRequestId,
  //     data: this.request
  //   })
  //   // eslint-disable-next-line no-console
  //   console.log(result)
  // }
};
</script>

<style scoped>
.el-card {
  width: 100%;
}
img {
  transform: translateY(-10%);
}
</style>

<style lang="scss">
.request-timeline {
  h1 {
    margin: 0;
  }
}
</style>
