import * as vuexHelper from "../helpers/vuexHelper";

import { coreAPI } from "../../services/apis";
import * as endpoints from "../../services/endpoints";

const config = {
  default: {
    api: coreAPI
  },
  state: {
    all: null,
    shift: null,
    transactions: null
  },
  asyncActions: [
    {
      name: "listShift",
      method: "get",
      url: `${endpoints.Shift}`,
      setState: "all"
    },
    { 
      name: "getShift",
      method: "get",
      url: ({ id }) => `${endpoints.Shift}${id}/`,
      setState: "shift"
    },
    { 
      name: "getCurrentShift",
      method: "get",
      url: `${endpoints.Shift}current/`,
      setState: "shift"
    },
    { 
      name: "getShiftRecently",
      method: "get",
      url: `${endpoints.Shift}recent/`,
      setState: "shift"
    },
    {
      name: "listShiftTransaction",
      method: "get",
      url: ({ id }) => `${endpoints.Shift}${id}/detail/`,
      setState: "transactions"
    },
    { 
      name: "startShift",
      method: "post",
      url: `${endpoints.Shift}`,
      setState: "shift"
    },
    { 
      name: "increaseDecreaseShiftAmount",
      method: "post",
      url: `${endpoints.Shift}current/detail/`,
      setState: "shift"
    },
    { 
      name: "closeShift",
      method: "post",
      url: `${endpoints.Shift}end/`,
      setState: "shift"
    },
  ]
};

export default vuexHelper.createStoreModule(config);
