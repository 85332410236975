export const apptStatus = value => {
  switch (value) {
    case "no_respond":
      return "No respond"
    case "no_show":
      return "No show"
    case "request_postpone":
      return "Request Postpone"
    case "postpone":
      return "Postpone"
    case "cancel":
      return "Cancel"
    case "confirm":
      return "Confirm"
    case "done":
      return "Done"
    case "in_progress":
      return "In Progress"
    default:
      return ""
  }
};
