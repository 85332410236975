import * as vuexHelper from "../helpers/vuexHelper";

import { coreAPI } from "../../services/apis";
import * as endpoints from "../../services/endpoints";

const config = {
  default: {
    api: coreAPI
  },
  state: {
    all: null,
    types: null,
    selected: null,
    contacts: [],
    contact: null,
    activities: []
  },
  asyncActions: [
    {
      name: "listProducts",
      method: "get",
      url: endpoints.productsMinimal,
      setState: "all"
    },
    {
      name: "listRealImageProducts",
      method: "get",
      url: endpoints.productsMinimalRealImage,
      setState: "all"
    },
    {
      name: "listProductsInventory",
      method: "get",
      url: `${endpoints.products}?ordering=stock,trade_name`
    },
    {
      name: "getProductTypes",
      method: "get",
      url: `${endpoints.productType}?all=TRUE`,
      onSuccess: (result, payload, { commit }) => {
        commit('SET_TYPES', result)
      }
    },
    {
      name: "createProduct",
      method: "post",
      url: endpoints.products,
    },
    {
      name: "getProduct",
      method: "get",
      url: ({ id }) => `${endpoints.products}${id}/`,
      setState: "selected"
    },
    {
      name: "updateProduct",
      method: "patch",
      url: ({ id }) => `${endpoints.products}${id}/`
    },
    {
      name: "deleteProduct",
      method: "delete",
      url: ({ id }) => `${endpoints.products}${id}/`
    },
    {
      name: "getProductActivities",
      method: "get",
      url: ({ id }) => `${endpoints.products}${id}/activities/`,
      setState: "activities"
    },
    {
      name: "getProductOverview",
      method: "get",
      url: ({ id }) => `${endpoints.products}${id}/overview/`,
    },
    {
      name: "listTypes",
      method: "get",
      url: endpoints.productType,
    },
    {
      name: "listUnits",
      method: "get",
      url: endpoints.productUnit,
    },
    {
      name: "listMedicationUse",
      method: "get",
      url: endpoints.productMedicationUse,
    },
    {
      name: "listSummary",
      method: "get",
      url: `${endpoints.productSummary}`,
      setState: "all"
    },
    {
      name: "detailSummary",
      method: "get",
      url: ({ id }) => `${endpoints.productSummary}${id}/`
    },
    {
      name: "chartSummary",
      method: "get",
      url: ({ id, year }) => `${endpoints.productSummary}${id}/yearly/${year}/`
    },
    {
      name: "listContact",
      method: "get",
      url: endpoints.productContact,
      setState: 'contacts',
      before(payload, { commit }) {
        commit('SET_CONTACTS', [])
      }
    },
    {
      name: "createContact",
      method: "post",
      url: endpoints.productContact,
    },
    {
      name: "updateContact",
      method: "patch",
      url: ({ id }) => `${endpoints.productContact}${id}/`,
    },
    {
      name: "deleteContact",
      method: "delete",
      url: ({ id }) => `${endpoints.productContact}${id}/`,
    },
    {
      name: "getContact",
      method: "get",
      url: ({ id }) => `${endpoints.productContact}${id}/`,
      setState: "contact",
      before(payload, { commit }) {
        commit('SET_CONTACT', null)
      }
    },
  ]
};

export default vuexHelper.createStoreModule(config);
