<template>
  <div class="request-step-5" style="width: 100%">
    <el-row>
      <slot
        class="headSlot"
        name="header"
        :currentProgress="this.myProgress"
        :currentText="this.myText"
        :currentVetType="myVetType"
      ></slot>
    </el-row>

    <el-row>
      <el-row class="medical-record-card">
        <el-col>
          <img src="@/assets/bloodbank/file.png" height="20" />
          <span>จอง หรือ เบิกถุงเลือดเพื่อใช้งาน</span>
        </el-col>
      </el-row>
    </el-row>

    <!-- Content -->
    <el-card>
      <div class="row">
        <div class="col-md-12" style="text-align:left">
          <h3>ถุงเลือดที่ผ่านจากการ Crossmatch แล้ว</h3>
        </div>
        <div class="col-md-4">
          <h3>ปริมาตรที่เบิก (ml)</h3>
        </div>
        <div class="col-md-4">
          <h3>ปริมาตรจากสูตร*</h3>
        </div>
        <div class="col-md-4">
          <h3>ปริมาตรที่ AI แนะนำ</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-24">
          <blood-reserve-bag
            style="width: 100%"
            v-bind:key="index"
            v-for="(bloodBag,index) in this.reserveList"
            v-bind:quantityFromEquation="!isValidForAiCalculate(bloodBag.crossmatchInfo.bloodBagId1) ? '-' : quantityEquation <= 0 ? '-' : quantityEquation"
            v-bind:quantityFromAi="!isValidForAiCalculate(bloodBag.crossmatchInfo.bloodBagId1) ? '-' : quantityAi <= 0 ? '-' : quantityAi"
            :reserveBagInfo="bloodBag"
            :disabled="request.isCaseClosed()"
          ></blood-reserve-bag>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label class="flex-end" style="float:right">*คำนวณจากความต้องการ PCV ของตัวป่วย</label>
        </div>
      </div>
      <hr style="border:solid 1px; margin-top: 0;" />
      <div class="row">
        <div class="col-md-24">
          <div class="row col-md-24">
            <div class="col-md-2">
              <label>ใช้เลือด</label>
            </div>

            <div class="col-md-22 flex-start">
              <el-radio
                :disabled="request.isCaseClosed()"
                v-model="request.reserveBlood.isImmediatelyUse"
                :label="true"
              >ทันที</el-radio>
            </div>

            <div class="col-md-2"></div>

            <div class="col-md-22 flex-start">
              <el-radio
                :disabled="request.isCaseClosed()"
                v-model="request.reserveBlood.isImmediatelyUse"
                :label="false"
              >จองใช้เมื่อ</el-radio>
            </div>

            <div class="col-md-2"></div>

            <div class="col-md-6 flex-start" style="margin-top:10px">
              <el-date-picker
                :disabled="request.isCaseClosed()"
                v-if="!request.reserveBlood.isImmediatelyUse"
                v-validate="{ rules: { required: !request.reserveBlood.isImmediatelyUse} }"
                v-model="request.reserveBlood.usedDate"
                type="datetime"
                placeholder="Select date"
                :picker-options="{disabledDate:disablePreviousDate}"
              ></el-date-picker>
            </div>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import BloodReserveBag from "@/components/bloodbank/blood-request/BloodRequestReserveBag";

import BloodReserveItemInfo from "@/models/data/blood-request/acquire-blood-bag-item-info/acquire-blood-bag-item-info";
import _ from "lodash";

export default {
  name: "blood-request-profile",
  components: {
    "blood-reserve-bag": BloodReserveBag
  },
  props: {
    request: Object,
    mode: {
      default: "view"
    }
  },

  data() {
    return {
      myProgress: 3,
      myText: "Blood Request Reserve",
      myVetType: "ผู้จ่ายเลือด",
      bloodBagList: this.request.crossmatch.singleCrossmatchList,
      bloodBagList2: this.request.crossmatch.multiCrossmatchList,
      reserveList: this.request.reserveBlood.bloodBagList,
      quantityAi: "-",
      quantityEquation: "-",
      calculatableQuantity: ["FWB", "SWB", "PRBCS"]
    };
  },
  computed: {
    ...mapState({
      diagnosis: state => state.bloodbank.diagnosis,
      profile: state => state.hospital.profile
    }),
    ...mapGetters({
      getBloodBagById: "bloodbank/getBloodBagById",
      getOwnedBloodBagList: "bloodbank/getOwnedBloodBagList"
    })
  },

  created: function() {
    this.reserveList =
      this.reserveList == null ? new BloodReserveItemInfo() : this.reserveList;
    let diffList = [];
    this.bloodBagList.forEach(crossmatchInfo => {
      let item = this.reserveList.find(
        item => crossmatchInfo.bloodBagId1 === item.crossmatchInfo.bloodBagId1
      );

      if (item == null) {
        diffList.push(crossmatchInfo);
      }
    });

    // eslint-disable-next-line no-console
    console.log("DiffList: ", diffList);

    diffList.forEach(crossmatchInfo => {
      let reserveItem = new BloodReserveItemInfo();
      reserveItem.crossmatchId = crossmatchInfo.id;
      reserveItem.crossmatchInfo = crossmatchInfo;
      this.request.reserveBlood.bloodBagList.push(reserveItem);
    });

    const weight = Number(
      this.request.recipientProfileInfo.medicalRecord.weight
    );
    const pcv = Number(this.request.recipientProfileInfo.labExam.HCT);
    const targetPCV = Number(
      this.request.recipientProfileInfo.productRequest.targetPcv
    );

    if (weight > 0 && pcv > 0 && targetPCV > 0) {
      this.$store
        .dispatch("bloodbank/postQuantityPredict", {
          data: {
            Weight: weight,
            PCV_patient: pcv,
            PCV_target: targetPCV
          }
        })
        .then(data => {
          this.quantityEquation = _.round(data.Formula);
          this.quantityAi = _.round(data.ML_Vol);
        });
    }
  },

  mounted: function() {
    this.request.reserveBlood.isImmediatelyUse = this.request.recipientProfileInfo.medicalRecord.isImmediatelyRequired;
    if (!this.request.reserveBlood.isImmediatelyUse) {
      this.request.reserveBlood.usedDate = this.request.recipientProfileInfo.medicalRecord.reserveDate;
    }
  },
  methods: {
    isValidForAiCalculate: function(id) {
      const bloodBag = this.getBloodBagInfo(id);

      if (bloodBag) {
        const bloodType = bloodBag.bloodType;
        return this.calculatableQuantity.indexOf(bloodType.toUpperCase()) >= 0;
      }

      return false;
    },
    getBloodBagInfo: function(id) {
      return this.getBloodBagById(id);
    },

    disablePreviousDate(time) {
      return time.getTime() < Date.now() - 8.64e7;
    },

    async beforeChangeStep() {
      let bagToValidateQuantity = [];

      let isReserveRowValid = true;
      this.request.reserveBlood.bloodBagList.map(reserveRow => {
        if (reserveRow.isSelected && !reserveRow.quantity) {
          isReserveRowValid = false;
        } else if (
          reserveRow.isSelected &&
          !isNaN(reserveRow.quantity) &&
          Number(reserveRow.quantity) >= 0
        ) {
          bagToValidateQuantity.push({
            id: reserveRow.crossmatchInfo.bloodBagId1,
            quantity: reserveRow.quantity
          });
        }
      });

      this.$store
        .dispatch("bloodbank/validateBloodBagQuantity", bagToValidateQuantity)
        .then(() => {
          if (
            this.request.reserveBlood.approverId === "" ||
            !isReserveRowValid
          ) {
            throw "กรุณากรอกข้อมูลให้ถูกต้อง";
          } else {
            // Pass !
          }
        })
        .catch(err => {
          if (err) {
            err.map(invalidBag => {
              throw `ปริมาตรถุงเลือด ID: ${invalidBag.id} ไม่เพียงพอ เนื่องจากปริมาตรคงเหลือ ${invalidBag.remaining} ml.`;
            });
          }
        });
    }
  }
};
</script>

<style lang="scss">
  .request-step-5 {
    h2, h3 {
      margin: 0;
    }

    .header-container {
      width: calc(100% + 5px);
      height: 50px;
      border-radius: 6px;
      background-color: #5c9ba3;
      text-align: center;
    }

    .profile-row-container {
      height: 55px;
    }

    .el-input-group__append {
      padding: 0px !important;
    }

    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100% !important;
    }
  }
</style>
