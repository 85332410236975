<template>
  <div>
    <Subnavbar>
      <template slot="title">Income-Expense Transaction</template>
      <template slot="actions">
        <button 
          v-show="currentPlan.income_expense_section.can_add_income_expense"
          class="btn btn-large btn-green"
          @click="$router.push({ name: 'income-expense-income-add' })">
          <i class="fa fa-plus-circle"></i>Add Income
        </button>
        <button
          v-show="currentPlan.income_expense_section.can_add_income_expense"
          class="btn btn-large btn-red" 
          @click="$router.push({ name: 'income-expense-expense-add' })">
          <i class="fa fa-plus-circle"></i>Add Expense
        </button>
      </template>
    </Subnavbar>

    <IncomeExpenseOverview 
      v-if="overview"
      :isLoading="isOverviewLoading"
      :overview="overview"
      :currentDate="overviewDate"
      @changePrevMonth="changePrevMonth"
      @changeNextMonth="changeNextMonth"
    />

    <ContentBox >
      <IncomeExpenseSearch
        :search="searchForm.search"
        :startDate="searchForm.start"
        :endDate="searchForm.end"
        :types="types"
        :contacts="contacts"
        :selectedTypeId="searchForm.selectedTypeId"
        :contact="searchForm.contact"
        @search="handleSearch"
      />
      
      <el-row v-if="summary" style="padding: 20px 0; font-weight: bold;">
        <el-col style="color: #999;" :span="3">Income</el-col>
        <el-col :span="4">{{summary.income | number }} THB</el-col>
        <el-col  style="color: #999;" :span="3">Expense</el-col>
        <el-col style="color: #d0021b;" :span="4">{{summary.expense | number }} THB</el-col>
      </el-row>
      <div v-else style="margin-bottom: 20px;"></div>

      <template v-if="listData && listData.results">
        <IncomeExpenseTable
          :items="listData"
          :total="listData.count"
          :current-page="currentPage"
          @current-change="pageChange"
        />
      </template>
    </ContentBox>

  </div>
</template>

<script>
import { mapState } from "vuex"
import ContentBox from "@/components/container/ContentBox"
import Subnavbar from "@/components/navigation/Subnavbar"
import IncomeExpenseOverview from "@/components/income-expense/IncomeExpenseOverview"
import IncomeExpenseSearch from "@/components/income-expense/IncomeExpenseSearch"
import IncomeExpenseTable from "@/components/income-expense/IncomeExpenseTable"

export default {
  components: {
    ContentBox,
    Subnavbar,
    IncomeExpenseOverview,
    IncomeExpenseSearch,
    IncomeExpenseTable,
  },
  data() {
    return {
      currentPage: 1,
      isOverviewLoading: false,
      overviewDate: new Date(),
      types: null,
      contacts: null,
      summary: null,
      overview: null,
      searchForm: {
        start: '',
        end: '',
        selectedTypeId: '',
        contact: '',
      }
    }
  },
  computed: {
    ...mapState({
      listData: state => state.incomeExpense.all,
      vets: state => state.hospital.vets,
      currentPlan: state => state.plan.currentPlan,
    })
  },
  methods: {
    handleSearch(form) {
      let query = {}
      this.currentPage = 1
      query['page'] = 1
      if (form.start && form.start !== '') query['start'] = new Date(form.start)
      if (form.end && form.end !== '') query['end'] = new Date(form.end)
      if (form.typeId !== '') query['transaction_type__id'] = form.typeId
      if (form.contact !== '') query['contact'] = form.contact

      this.fetchData(query)
    },
    pageChange(page) {
      let query = this.getQueryString()
      query = { ...query, page }
      this.currentPage = page
      this.fetchData(query)
    },
    changePrevMonth() {
      const t = this.overviewDate.setMonth(this.overviewDate.getMonth() - 1)
      this.overviewDate = new Date(t)
      this.fetchOverview()
    },
    changeNextMonth() {
      const t = this.overviewDate.setMonth(this.overviewDate.getMonth() + 1)
      this.overviewDate = new Date(t)
      this.fetchOverview()
    },
    async fetchOverview () {
      const y = this.overviewDate.getFullYear()
      const m = this.overviewDate.getMonth()
      const firstDay = new Date(y, m, 1)
      const lastDay = new Date(y, m + 1, 0,23,59,59,999)      

      try {
        this.isOverviewLoading = true
        this.overview = await this.$store.dispatch("incomeExpense/getOverview", { query: { 
          start: firstDay,
          end: lastDay
        }})
        this.isOverviewLoading = false
      } catch (error) {
        this.isOverviewLoading = false
         this.$notify({
          message: `พบปัญหาการโหลดข้อมูล overview`,
          type: "error"
        })
      }
    },
    getQueryString() {
      let query = {}

      if (this.$route.query.page) {
        query["page"] = parseInt(this.$route.query.page)
        this.currentPage = parseInt(this.$route.query.page)
      } else {
        this.currentPage = 1
      }

      if (this.$route.query.start) {
        const start = new Date(parseInt(this.$route.query.start))
        query["start"] = start
        this.searchForm.start = start
      }

      if (this.$route.query.end) {
        const end = new Date(parseInt(this.$route.query.end))
        query["end"] = end
        this.searchForm.end = end
      }

      if (this.$route.query["transaction_type__id"]) {
        query["transaction_type__id"] = this.$route.query["transaction_type__id"]
        this.searchForm.selectedTypeId = this.$route.query["transaction_type__id"]
      }

      if (this.$route.query["contact"]) {
        query["contact"] = this.$route.query["contact"]
        this.searchForm.contact = this.$route.query["contact"]
      }

      return query
    },
    async fetchTypes () {
       try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        this.types = await this.$store.dispatch("incomeExpense/listIncomeExpenseTypeAll", {
          query: {
            all: true
          }
        })
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg")
      }
    },

    async fetchContacts () {
       try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        const results = await this.$store.dispatch("product/listContact", {
          query: { all: true }
        })
        this.contacts = results.map((item) => ({
          id: item.id,
          name: `${item.prefix || ""} ${item.firstname || ""} ${item.lastname || ""}`.trim()
        }));
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg")
      }
    },

    async fetchData (query) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        await this.$store.dispatch("incomeExpense/listIncomeExpense", {
          query: {
            ...query
          }
        })

        if (this.currentPlan.income_expense_section.can_view_income_expense_transaction_filter_summary) {
          this.summary = await this.$store.dispatch("incomeExpense/getOverview", { query });
        }

        let urlQuery = { ...query }
        if (query.start) urlQuery['start'] = query.start.getTime()
        if (query.end) urlQuery['end'] = query.end.getTime()
        if (query["transaction_type__id"]) urlQuery['transaction_type__id'] = query["transaction_type__id"]
        if (query["contact"]) urlQuery['contact'] = query["contact"]

        this.$router.replace({ query: urlQuery })
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg")
      }
    }
  },
  async created() {
    if (this.currentPlan.income_expense_section.can_view_finance_overview) {
      await this.fetchOverview();
    }
    await this.fetchTypes()
    await this.fetchContacts()
    await this.fetchData(this.getQueryString())
  }
}
</script>

<style lang="scss" scoped>
.box {
  margin-bottom: 15px;
}
.box-data-table {
  padding: 20px;
}
</style>
