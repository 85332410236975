<template>
  <el-dialog :visible="visible" width="600px" center @close="$emit('cancel')">
    <div slot="header">
      <span>บันทึกการฝังไมโครชิฟ</span>
    </div>
    <div class="content">
      <h3 class="title">
        <img src="/images/icons/check-select.png" class="check-icon">
        ได้รับการฟังไมโครชิฟแล้ว
      </h3>
      <div class="form-group">
        <label>วันที่</label>
        <el-date-picker type="date" v-model="form.microchip_date" placeholder="Select Date"></el-date-picker>
      </div>
      <div class="form-group">
        <label>เลขที่ไมโครชิฟ</label>
        <el-input v-model.trim="form.microchip"/>
      </div>
      <div class="form-group">
        <label>บันทึกเพิ่มเติม</label>
        <el-input type="textarea" v-model="form.microchip_note"></el-input>
      </div>
      <div class="form-group">
        <input type="file" accept="image/*" ref="file" v-on:change="handleFileUpload()">
      </div>
    </div>
    <div slot="footer">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-button type="outline" style="width: 100%;" @click="$emit('cancel')">ยกเลิก</el-button>
        </el-col>
        <el-col :span="12">
          <el-button type="primary" :disabled="!isFormValid" @click="save" style="width: 100%;">บันทึก</el-button>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
import moment from "moment";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        microchip: "",
        microchip_date: new Date(),
        microchip_note: ""
      },
      file: ""
    };
  },
  computed: {
    isFormValid() {
      return !!(
        this.form.microchip !== "" &&
        this.form.microchip_date !== "" &&
        moment(this.form.microchip_date).isValid()
      );
    }
  },
  methods: {
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    save() {
      const updatedData = {
        ...this.form,
        microchip_date: moment(this.form.microchip_date).format("YYYY-MM-DD"),
        file: this.file
      };
      this.$emit("save", updatedData);
    },
    clearFormData() {
      this.form.microchip = "";
      this.form.microchip_date = new Date();
      this.form.microchip_note = "";
      this.file = "";
    }
  }
};
</script>


<style lang="scss" scoped>
.title {
  text-align: center;
  margin-bottom: 10px;
}
.check-icon {
  width: 20px;
  height: 15px;
  margin-right: 10px;
}
.form-group {
  margin: 10px 0;

  label {
    display: block;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
  }
}
</style>
