import { make } from "vuex-pathify";
import axios from "axios";
import _ from "lodash";

/**
 * $store.dispatch('namespace/functionName', { params: {}, query: {}, data: {}, formData: {})
 *
 * params: will pass values to url's params. ex. params: { id: 1} => /product/:id => /product/1
 * query: will send querystring with url. ex. query: { search: abc } => /products?search=abc
 * data: send json data
 * formData: send form data
 */

export function requestFn(
  api,
  method,
  url,
  payload = { params: {}, query: {}, data: {}, formData: {} }
) {
  const { params, query, formData } = payload;

  let data = payload.data;
  let urlFn;
  if (typeof url === "function") {
    urlFn = params => url(params);
  } else {
    urlFn = () => url;
  }

  let requestConfig = {};
  if (query) {
    requestConfig["params"] = query;
  }

  if (formData) {
    requestConfig["headers"] = { "Content-Type": "multipart/form-data" };
    data = formData;
  }

  if (["post", "put", "patch"].indexOf(method) > -1) {
    return api[method](urlFn(params), data, requestConfig);
  } else {
    return api[method](urlFn(params), requestConfig);
  }
}

export function createActionFn(action, defaultValues) {
  const { name, method, url } = action;
  let api = axios;

  if (defaultValues.api) {
    api = defaultValues.api;
  }

  if (!name) {
    throw new Error(`name key is Required.`);
  }
  if (!method) {
    throw new Error(`method key is Required`);
  }

  if (!url) {
    throw new Error(`url key is Required.`);
  }

  return {
    [action.name]: async ({ commit, dispatch }, payload) => {
      if (action.before) {
        action.before(payload, { commit, dispatch });
      }

      return requestFn(api, method, url, payload).then(
        response => {
          let result = response.data;
          if (response.data.result) {
            result = response.data.result;
          }

          if (action.setState) {
            commit(`SET_${_.toUpper(action.setState)}`, result);
          }

          if (action.onSuccess) {
            action.onSuccess(result, payload, { commit, dispatch });
          }

          return Promise.resolve(result);
        },
        error => {
          if (action.onError) {
            action.onError(error, payload, { commit, dispatch });
          }
          return Promise.reject(error);
        }
      );
    }
  };
}

export function createStoreModule(config) {
  let defaultValues = {};
  let state = {};
  let getters = {};
  let actions = {};
  let mutations = {};

  if (config.default) {
    defaultValues = config.default;
  }

  if (config.state) {
    state = { ...config.state };
    actions = make.actions(state);
    mutations = make.mutations(state);
  }

  if (config.getters) {
    getters = Object.assign(getters, config.getters);
  }

  if (config.actions) {
    actions = Object.assign(actions, config.actions);
  }

  if (config.asyncActions) {
    config.asyncActions.forEach(asyncActions => {
      actions = Object.assign(actions, createActionFn(asyncActions, defaultValues));
    });
  }

  if (config.mutations) {
    mutations = Object.assign(mutations, config.mutations);
  }

  return {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
}
