<template>
  <div class="form-block">
    <div class="header-block">
      <div class="title">
        Create Hospital Profile
      </div>
      <div class="lead">
        General Info<br/>
        (1/4)
      </div>
    </div>
    <div class="upload-image">
      <div class="image-container">
        <img v-if="hospitalLogo" class="full-logo" :src="hospitalLogo" alt="">
        <img v-else src="@/assets/icons/icon-camera-plus.png">
        <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
      </div>
      <span>1. Hospital Logo</span>
    </div>
    <el-form ref="form" :model="form">

      <el-form-item>
        <span class="form-label">Username</span>
        <el-input
          type="text"
          name="username"
          v-model="username"
          :disabled="true"></el-input>
        <span class="error">{{ errors.first('username') }}</span>
      </el-form-item>

      <el-form-item>
        <span class="form-label">2. ชื่อสถานพยาบาล <span class="red">*</span></span>
        <el-input
          type="text"
          v-validate="'required'"
          name="hospitalName"
          placeholder="ชื่อสถานพยาบาล"
          v-model="form.name"></el-input>
        <span class="error">{{ errors.first('hospitalName') }}</span>
      </el-form-item>

      <el-form-item>
        <span class="form-label">3. อีเมล์ของสถานพยาบาลสำหรับลูกค้า</span>
        <el-input v-for="email in form.contactEmail"
          v-bind:key="email.key"
          type="email"
          name="contactEmail"
          placeholder="Email"
          v-model="email.value"></el-input>
        <span class="input-action" @click="addEmail">+ เพิ่มอีเมล์</span>
      </el-form-item>

      <el-form-item>
        <span class="form-label">4. เบอร์ติดต่อสถานพยาบาลสำหรับลูกค้า <span class="red">*</span></span>
        <el-input v-for="phone in form.contactPhone"
          v-bind:key="phone.key"
          type="tel"
          v-validate="'required|numeric'"
          name="phone"
          placeholder="เช่น 028294565"
          v-model="phone.value"></el-input>
        <span class="input-action" @click="addPhone">+ เพิ่มเบอร์โทร</span>
      </el-form-item>

      <el-form-item>
        <span class="form-label">5. เวลาทำการ</span>
        <el-input
          type="text"
          name="openTime"
          placeholder="เช่น เปิดทุกวันตลอด 24 ชั่วโมง"
          v-model="form.open_time"></el-input>
        <span class="error">{{ errors.first('open_time') }}</span>
      </el-form-item>

      <el-form-item>
        <span class="form-label">6. Website (optional)</span>
        <el-input
          type="text"
          name="website"
          placeholder="เช่น https://www.myhospital.com"
          v-model="form.website"></el-input>
        <span class="error">{{ errors.first('website') }}</span>
      </el-form-item>

      <el-form-item class="text-center">

        <el-button
          :loading="submitButtonLoading"
          type="button"
          class="btn btn-primary form-submit-btn"
          @click="onSubmit">Next ></el-button>
        <span v-for="(error, key) of errMessage"
          v-bind:key="key"
          class="error">{{ key }}: {{ error[0] }}</span>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      submitButtonLoading: false,
      username: '',
      hospitalLogo: '',
      form: {},
      errMessage: []
    }
  },
  async mounted() {
    await this.$store.dispatch('ui/setIsFullScreenLoading', true)
    await this.$store.dispatch('hospital/getProfile')
    this.username = this.$store.state.auth.user.username
    this.hospitalLogo = this.$store.state.hospital.profile.logo.large
    var contactEmail = this.$store.state.hospital.profile.contact_emails.map(email => ({
      key: this.generateRandomKey(),
      value: email
    }))
    var contactPhone = this.$store.state.hospital.profile.contact_phones.map(phone => ({
      key: this.generateRandomKey(),
      value: phone
    }))
    this.form = {
      name: this.$store.state.hospital.profile.name,
      contactEmail: contactEmail,
      contactPhone: contactPhone,
      open_time: this.$store.state.hospital.profile.open_time,
      website: this.$store.state.hospital.profile.website,
    }
    await this.$store.dispatch('ui/setIsFullScreenLoading', false)
  },
  methods: {
    async onSubmit() {
      this.submitButtonLoading = true
      var contactEmail = this.form.contactEmail.map(email => (email.value)).filter(Boolean)
      var contactPhone = this.form.contactPhone.map(phone => (phone.value)).filter(Boolean)
      try {
        await this.$store.dispatch('hospital/updateProfile', {
          data: {
            name: this.form.name,
            contact_emails: contactEmail,
            contact_phones: contactPhone,
            open_time: this.form.open_time,
            website: this.form.website
          }
        })
        this.$message('Hospital Profile Saved')
        this.$router.push({ name: 'profileMapLocation' })
      } catch (err) {
        this.errMessage = err
      }
      this.submitButtonLoading = false
    },
    addEmail() {
      this.form.contactEmail.push({key: this.generateRandomKey(), value: ''})
    },
    addPhone() {
      this.form.contactPhone.push({key: this.generateRandomKey(), value: ''})
    },
    async handleFileUpload() {
      var form = new FormData()
      form.append('logo', this.$refs.file.files[0])
      try {
        await this.$store.dispatch('hospital/updateProfile', {
          formData: form
        })
        this.hospitalLogo = this.$store.state.hospital.profile.logo.large
      } catch (err) {
        this.errMessage = err
        this.hospitalLogo = ''
      }
    },
    generateRandomKey() {
      return Math.random().toString(36).substring(7)
    }
  }
}
</script>

<style lang="scss" scoped>
.form-block {
  @extend %block-container;
  @extend %form-style;
  .form-submit-btn {
    width: 160px;
  }
  .upload-image {
    text-align: center;
    .image-container {
      position: relative;
      height: 120px;
      width: 120px;
      border-radius: 60px;
      background-color: #d8d8d8;
      display: block;
      margin: 8px auto;
      img {
        display: block;
        width: 70px;
        height: 70px;
        padding: 25px;
        object-fit: contain;
        &.full-logo {
          width: 120px;
          height: 120px;
          padding: 0px;
          object-fit: cover;
        }
      }
      input {
        height: 120px;
        width: 120px;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
      }
    }
  }
}
</style>
