<template>
  <ContentBox style="padding: 5px;">
    <div class="form">
      <el-row>
        <el-col :span="24">
          <h1 class="title">{{ title }}</h1>
        </el-col>
      </el-row>
      <el-row class="row">
        <el-col :span="4">
          <label>Date/time :</label>
        </el-col>
        <el-col :span="20">
          <el-date-picker
            type="date"
            placeholder="Pick a day"
            v-model="form.d"
            @change="handleChange"
            :disabled="isEdit"
            style="margin-bottom: 5px"
          ></el-date-picker>
          <el-time-select
            style="margin-left: 10px;"
            placeholder="Select time"
            v-model="form.t"
            :picker-options="{
                  start: '00:00',
                  step: '00:15',
                  end: '24:45'
                }"
            format="HH:mm"
            :disabled="isEdit"
            @change="handleChange"
          ></el-time-select>
        </el-col>
      </el-row>
      <el-row class="row">
        <el-col :span="4">
          <label>Type :</label>
        </el-col>
        <el-col :span="20">
          <el-select
            placeholder="Select Type"
            clearable
            v-model="form.transaction_type"
            @change="handleChange"
            @clear="handleClearTransactionType"
            :disabled="isEdit"
          >
            <el-option
              v-for="type in types"
              :key="type.id"
              :value="type.id"
              :label="`${type.name}`"
            ></el-option>
          </el-select>
          <template v-if="!isEdit">
           <label style="margin: 0 10px;"><strong>OR</strong></label>
           <el-button class="btn btn-outline" @click="showAddTypeDialog = true">Create Type</el-button>
          </template>
        </el-col>
      </el-row>
      <el-row class="row">
        <el-col :span="4">
          <label>Detail :</label>
        </el-col>
        <el-col :span="20">
          <el-input :disabled="isRevertType" type="text" v-model="form.description" @change="handleChange"  />
        </el-col>
      </el-row>
      <el-row class="row">
        <el-col :span="4">
          <label>Price :</label>
        </el-col>
        <el-col :span="6">
          <el-input-number
            :controls="false"
            :precision="2"
            :disabled="isEdit" 
            v-model="form.unit_price" 
            @change="handleChange"  />
        </el-col>
        <el-col :span="10">
          <label style="margin-left: 20px;">บาท</label>
        </el-col>
      </el-row>
      <el-row class="row">
        <el-col :span="4">
          <label>Discount :</label>
        </el-col>
        <el-col :span="6">
          <el-input-number
            :controls="false"
            :precision="2"
            :disabled="isEdit"
            v-model="form.discount" 
            @change="handleChange"  />
        </el-col>
        <el-col :span="10">
          <label style="margin-left: 20px;">บาท</label>
        </el-col>
      </el-row>
      <el-row class="row">
        <el-col :span="4">
          <label>Total :</label>
        </el-col>
        <el-col :span="6">
          <label>{{ form.unit_price - form.discount | currency}}</label>
        </el-col>
        <el-col :span="10">
          <label style="margin-left: 20px;">บาท</label>
        </el-col>
      </el-row>
      <el-row class="row">
        <el-col :span="4">
          <label>Note :</label>
        </el-col>
        <el-col :span="20">
          <el-input
            v-model="form.note"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            @change="handleChange"
            size="small"
          ></el-input>
        </el-col>
      </el-row>
      <el-row class="row">
        <el-col :span="4">
          <label>Attachment :</label>
        </el-col>
        <el-col :span="20">
          <div
            style="margin-top: 8px; color: #666;"
          >สามารถอัพโหลดได้เฉพาะรูปภาพและไฟล์ เอกสาร PDF เท่านั้น</div>
          <div class="section clearfix">
            <FileUploader
              style="float: left; width: 110px; height: 110px; margin: 4px;"
              :isUploading="isUploading"
              @onSelect="handleSelectFiles"
            />
            <FilePreviewer
              v-for="file in this.form.attachments"
              :key="file.id"
              style="float: left; width: 110px; height: 110px; margin: 4px;"
              :file="file"
              :showDeleteButton="true"
              @delete="handleDeleteUploadFile"
            />
          </div>
        </el-col>
      </el-row>
    </div>
    <el-dialog
      :visible="showAddTypeDialog"
      width="500px"
      center
      @close="showAddTypeDialog = false"
    >
      <div class="dialog-content">
        <div class="header">
          <h2 class="title">Create type</h2>
        </div>
        <div class="content">
          <el-input type="text" v-model="typeName" />
        </div>
        <span class="footer">
          <el-button style="width: 100px;" class="btn btn-outline" @click="showAddTypeDialog = false">Cancel</el-button>
          <el-button style="width: 100px;" type="primary" @click="handleCreateType">OK</el-button>
        </span>
      </div>
    </el-dialog>
  </ContentBox>
  
</template>

<script>
import { toHHMM } from "@/filters/datetime"
import ContentBox from "@/components/container/ContentBox";
import FileUploader from "@/components/upload/FileUploader"
import FilePreviewer from "@/components/upload/FilePreviewer"

export default {
  props: ["isExpense" ,"isEdit", "formData", "types", "isRevertType"],
  components: {
    ContentBox,
    FileUploader,
    FilePreviewer
  },
  data() {
    return {
      showAddTypeDialog: false,
      isUploading: false,
      typeName: '',
      form: {
        d: this.formData.datetime,
        t: toHHMM(this.formData.datetime),
        transaction_type: this.formData.transaction_type,
        description: this.formData.description,
        unit_price: this.formData.unit_price,
        discount: this.formData.discount,
        note: this.formData.note,
        attachments: this.formData.attachments || []
      }
    };
  },
  computed: {
    title () {
      if (this.isExpense) {
        return 'General Expense'
      } else {
        return 'General Income'
      }
    }
  },
  methods: {
    handleClearTransactionType() {
      this.transaction_type = null;
    },
    handleChange() {
      this.$emit("change", this.form);
    },
    async handleSelectFiles(files) {
      try {
        this.isUploading = true
        for (let index = 0; index < files.length; index++) {
          await this.uploadFile(files[index])
        }
        this.isUploading = false
        this.$notify({
          message: `อัพโหลดไฟล์เรียบร้อยแล้ว`,
          type: "success"
        })
        this.handleChange()
      } catch (error) {
        this.isUploading = false
      }
    },
    async uploadFile(file) {
      try {
        let formData = new FormData()
        formData.append("attachment", file)
        const response = await this.$store.dispatch("incomeExpense/upload", {
          formData
        })
        this.form.attachments = [...this.form.attachments, response]
      } catch (error) {
        this.$notify({
          message: "ไม่สามารถอัพโหลดไฟล์ " + file.name,
          type: "error"
        })
      }
    },
    handleDeleteUploadFile(file) {
      this.form.attachments = this.form.attachments.filter(
        item => item.id !== file.id
      )
      this.handleChange()
    },
    async handleCreateType () {
      if (this.typeName.trim() === '') {
        this.$notify({
          message: `กรุณากรอก type`,
          type: "warning"
        })
        return
      }
       try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        await this.$store.dispatch("incomeExpense/createIncomeExpenseTypes", {
          data: {
            name: this.typeName
          }
        });
        this.$emit("createType");
        this.typeName = '';
        this.showAddTypeDialog = false;
        this.$notify({
          type: "success",
          title: "สร้าง type เรียบร้อยแล้ว"
        })
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorMsg", error)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  padding: 0 50px;
  .row {
    margin-bottom: 10px;
  }

  .title {
    color: $light-blue;
    font-size: 1.2em;
  }

  label {
    display: inline-block;
    margin-right: 3px;
    line-height: 2.4em;

    &.multi-line {
      line-height: 1.2em;
      span {
        display: block;
      }
    }

    small {
      color: #999;
    }
  }

  .remark {
    font-size: 12px;
    color: #999;
  }

  .attachment-list {
    list-style-type: none;
    padding: 0;
    margin: 10px 0 0 0;

    > li {
      margin: 5px 0;
      padding: 0 10px;
      font-size: 14px;

      .name {
        display: inline-block;
        min-width: 150px;
        padding: 0 10px;
      }

      i {
        color: $light-blue;

        &.fa-times-circle {
          color: #ccc;
          cursor: pointer;

          &:hover {
            color: $red;
          }
        }
      }
    }
  }

  .el-radio {
    display: inline-block;
    margin-right: 20px;
  }

  .el-checkbox {
    line-height: 1em;
  }

  .el-input-number {
    width: 100%;
  }
}

.dialog-content {
  .header {
    margin-top: -30px;
    padding-bottom: 10px;
    text-align: center;
  }

  .title {
    margin: -15px 0 10px 0;
    padding: 0;
    font-size: 20px;
    text-align: center;
    color: $light-blue;
  }

  .footer {
    display: block;
    padding: 10px 0 0 0;
    text-align: center;
  }
}
</style>


