<template>
  <ContentBox>
    <div :style="{ marginBottom: '0.5rem' }">{{ total | number }} RESULT</div>

    <el-table
      :data="items"
      :header-cell-class-name="$style.cell"
      :cell-class-name="$style.cell"
      :class="$style.table"
      :header-row-style="{ backgroundColor: '#f5f8fa' }"
      :row-style="{ height: '4rem' }"
      :style="{ marginBottom: '0.5rem' }"
    >
      <el-table-column prop="status" width="23" :class-name="$style.tagCol">
        <template v-slot="{ row }">
          <div
            v-if="getStatus(row.status)"
            :style="{ backgroundColor: getStatus(row.status).color }"
          >
            {{ getStatus(row.status).label }}
          </div>
        </template>
      </el-table-column>

      <el-table-column label="Date Visit" prop="date" min-width="130">
        <template v-slot="{ row }">
          <div>
            {{ row.date | toThaiDate }}
          </div>
          <div :style="{ color: '#666' }">
            {{ row.date | toHHMM }}
          </div>
        </template>
      </el-table-column>

      <el-table-column label="Consumer" prop="consumer" min-width="150">
        <template v-slot="{ row }">
          {{ row.consumer || "-" }}
        </template>
      </el-table-column>

      <el-table-column label="Revenue" prop="revenue" min-width="100">
        <template v-slot="{ row }">
          {{ row.revenue | currency }}
        </template>
      </el-table-column>

      <el-table-column prop="id" width="115">
        <template v-slot="{ row }">
          <el-button
            v-if="row.status === 'paid'"
            size="mini"
            :style="{ width: '100%' }"
            @click="view(row.id)"
          >
            See Detail <i class="el-icon-right"></i>
          </el-button>
          <el-button
            v-else
            type="primary"
            size="mini"
            icon="el-icon-plus"
            :style="{ width: '100%' }"
            @click="view(row.id)"
          >
            Record <i class="el-icon-right"></i>
          </el-button>
        </template>
      </el-table-column>
      <img
        slot="empty"
        src="@/assets/images/no-result/medical-record-no-result.png"
        alt="Not found"
        :style="{ display: 'block', margin: '0 auto', maxWidth: '300px' }"
      />
    </el-table>
    <el-pagination
      :current-page="page"
      :total="total"
      :page-size="pageSize"
      background
      layout="->, prev, pager, next"
      @current-change="handleCurrentChange"
    />
  </ContentBox>
</template>

<script>
import ContentBox from "@/components/container/ContentBox";

export default {
  components: { ContentBox },

  props: {
    items: {
      type: Array,
      default: () => [],
    },

    page: {
      type: Number,
      default: 1,
    },

    total: {
      type: Number,
      default: 0,
    },

    pageSize: {
      type: Number,
      default: 10,
    },
  },

  methods: {
    view(id) {
      this.$emit("view", id);
    },

    getStatus(value) {
      switch (value) {
        case "unpaid":
          return {
            label: "In Action",
            color: "#90d5e8",
          };

        case "paid":
          return {
            label: "Finish",
            color: "#7c2496",
          };

        default:
          return null;
      }
    },

    handleCurrentChange(page) {
      this.$emit("page-change", page);
    },
  },
};
</script>

<style lang="scss" module>
.table {
  color: black;

  :global(.el-table__empty-text) {
    margin: 1.5rem 0;
    width: 100%;
  }

  th {
    padding: 0.25rem 0;
    background-color: #f5f8fa;
  }
}

.cell {
  :global(.cell) {
    word-break: normal;
    padding: 0 0.25rem !important;
    line-height: 1.5;
  }
}

.tagCol {
  overflow: hidden;
  font-size: 0.75em;
  position: relative;

  :global(.cell) {
    padding: 0 !important;
    width: 8rem;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
    line-height: 23px;
    text-align: center;
    font-weight: bold;
    color: white;
  }
}
</style>
