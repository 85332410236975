<template>
  <el-dialog :visible="visible" width="500px" center @close="$emit('cancel')">
    <div class="dialog-content text-center">
      <h1 class="title">ยืนยันการลบข้อมูลผลิตภัณฑ์</h1>
      <div class="title" style="text-align: left;">คุณต้องการลบ 
        <span class="product-name">{{ product.trade_name }}</span> ออกจากคลังสินค้าหรือไม่</div>
      
      <template v-if="activities.length > 0">
        <div class="normal-text text-left" style="margin-bottom: 8px;">คุณมี {{ getActivityCountText() }} ที่ใช้งานผลิตภัณฑ์นี้อยู่ หากคุณยืนยัน ผลิตภัณฑ์จะหายไปจาก Activity เหล่านี้ด้วย</div>
        <div class="normal-text text-left"
          v-for="(activity, key) in activities" :key="key">
          - {{ activity.name }}
        </div>
      </template>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('cancel')">cancel</el-button>
      <el-button type="primary" @click="$emit('delete', product)">confirm</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: ["visible", "product", "activities"],
  methods: {
    getActivityCountText() {
      if (this.activities.length > 1) {
        return this.activities.length + " Activities"
      } else {
        return this.activities.length + " Activity"
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-content {
  font-family: Thonburi;
  font-size: 16px;
  margin: -30px;
  .title {
    margin: 0 0 20px 0;
    padding: 0;
    font-size: 18px;
    text-align: center;
    color: #000;
  }
  .normal-text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #5a5a5a;
  }
  .product-name {
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #d0021b;
  }
}
.dialog-footer {
  display: inherit;
  margin-top: 15px;
}
</style>

