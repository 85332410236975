import axios from 'axios'

const fixDate = function (dt) {
        let dbdate = new Date(dt)
        dbdate.setHours(dbdate.getHours() - dbdate.getTimezoneOffset()/60);
        return dbdate
    }

const fullname = function (title,first_name,last_name) {
        let fullname = ""

        if (title) { if (title.length > 0) fullname = title }
        if (first_name) {  if (first_name.length > 0) {
            if (fullname.length > 0) fullname += ' '
            fullname += first_name
        } }
        if (last_name) { if (last_name.length > 0) {
            if (fullname.length > 0) fullname += ' '
            fullname += last_name
        } }

        return fullname
    }


export default {
    async getipd( context , payload) {
        //--- Call api from monitor server ---
        const api = axios.create({
            baseURL: process.env.VUE_APP_API_ENDPOINT2
        })
        //--- params for api call ---
        const params = `ipd=${payload.ipd}`

        //--- { config: config } is from axios ---
        const { status: status,
            data:data
        } = await api.get('/ipddetail/' + params )

        if (status != 200) throw new Error('การติดต่อกับระบบขัดข้อง ' + status)

        //--- commit to mutation ---

        if (data.ipddetail.length > 0) {
            context.commit('setIpd', data.ipddetail[0])
        } else {
            throw new Error('ไม่พบข้อมูล ipd ' + payload.ipd)
        }
    },
    async getipdmedicalrecord( context , payload) {
        //--- Call api from monitor server ---
        const api = axios.create({
            baseURL: process.env.VUE_APP_API_ENDPOINT2
        })
        //--- params for api call ---
        const params = `ipd=${payload.ipd}`

        //--- { config: config } is from axios ---
        const { status: status,
            data:data
        } = await api.get('/ipdrecords/' + params )

        if (status != 200) throw new Error('การติดต่อกับระบบขัดข้อง ' + status)

        console.log(data.ipdrecord)

        let records = data.ipdrecord.map((item) => ({
            id: item.medical_record_id,
            date: fixDate(item.arrived),
            receiptStatus: item.payment_status,
            stockStatus: item.is_updated_stock == 0 ? "stock_not_update" : "stock_out" ,
            isDeleted: false,
            cc:  item.cc,
            ht:  item.ht,
            tx:  item.tx,
            rx:  item.rx,
            response:  item.response,
            note: item.notes,
            plan:  item.treatment_plan,
            clientEducation:  item.client_edu,
            dx: item.diagonosis_detail,
            finalDx:  item.final_diagnosis,
            by:  fullname(item.title,item.first_name,item.last_name),
            problems:  item.problems,
            foodTypes:  [],
            diffDxs: item.diff_diagonosis,
            overallStatus: item.overall_status,
            pe:  "",
            images:  [],
            activities: item.activity.map((item) => ({
              name: item.activity_name,
              amount: parseFloat(item.amount),
              value: parseFloat(item.unit_price),
              discount: parseFloat(item.discount),
              hasVat: item.vat_percentage !== null,
              vatPercentage: parseFloat(item.vat_percentage),
              products: item.product.map((item) => ({
                name: item.product_name,
                amount: parseFloat(item.amount),
              })), }))
        }))

        //console.log(records)
        //--- commit to mutation ---
        if (records.length > 0) {
            context.commit('setIpdMedicalRecords', records)
        } else {
            throw new Error('ไม่พบข้อมูล Medical records ' + payload.ipd)
        }
    },
    async getipdpayment( context , payload) {
        //--- Call api from monitor server ---
        const api = axios.create({
            baseURL: process.env.VUE_APP_API_ENDPOINT2
        })
        //--- params for api call ---
        const params = `ipd=${payload.ipd}`

        //--- { config: config } is from axios ---
        const { status: status,
                data:data
        } = await api.get('/ipdpayment/' + params )

        if (status != 200) throw new Error('การติดต่อกับระบบขัดข้อง ' + status)

        const payments = data.ipdpayment
        const deposits = data.ipddeposit

        let deposit = 0
        let overdue = 0
        let paid = 0

        payments.forEach(el  => {
              if (el.payment_status == "for_payment") {
                  overdue = parseFloat(el.amount)
              }

              if (el.payment_status == "paid") {
                  paid = parseFloat(el.amount)
              }
        })

        deposits.forEach(el  => {
            deposit = parseFloat(el.amount)
        })

        context.commit('setPaymentStatus', {
              deposit: deposit,
              paid: paid,
              overdue: overdue,
        })
    },


    async getbranch( context ) {
        const api = axios.create({
          baseURL: process.env.VUE_APP_API_ENDPOINT2
        })

        //--- { config: config } is from axios ---
        const { status: status,
            data:data
        } = await api.get('/branch/')

        if (status != 200) throw new Error('การติดต่อกับระบบขัดข้อง ' + status)

        console.log(data)
        console.log(data.branches)

        context.commit('setBranch', data.branches)
    },
    async getgraph( context , payload ) {

        const api = axios.create({
            baseURL: process.env.VUE_APP_API_ENDPOINT2
        })

        const year = payload.year? payload.year : ''
        const branch = payload.branch? payload.branch : ''

        const { status: status,
            data:data } = await api.get(`/buyingraph/branch=${branch}&year=${year}`)

        if (status != 200) throw new Error('การติดต่อกับระบบขัดข้อง ' + status)

        console.log(data)
        console.log(data.datas)

        context.commit('setGraph', data.datas)
    },
    async gettable( context , payload ) {

        const api = axios.create({
            baseURL: process.env.VUE_APP_API_ENDPOINT2
        })

        if (payload.from) {
            payload.from = new Date(payload.from.getTime() - payload.from.getTimezoneOffset()*60*1000);
        }

        if (payload.to) {
            payload.to = new Date(payload.to.getTime() - payload.to.getTimezoneOffset()*60*1000);
            payload.to.setUTCHours(23, 59, 59);
        }

        const branch = payload.branch? payload.branch : ''
        const product = payload.product? payload.product : ''
        const from = payload.from? payload.from.toISOString().split('.')[0] : ''
        const to = payload.to? payload.to.toISOString().split('.')[0] : ''

        const params = `/buyinbpd/branch=${branch}&product=${product}&from=${from}&to=${to}`
        console.log(params )

        const { status: status,
            data:data } = await api.get(params)

        if (status != 200) throw new Error('การติดต่อกับระบบขัดข้อง ' + status)

        console.log(data)
        console.log(data.datas)

        context.commit('setTable', data.datas)
    },
    async getdetailtable( context , payload ) {

        const api = axios.create({
            baseURL: process.env.VUE_APP_API_ENDPOINT2
        })

        if (payload.from) {
            payload.from = new Date(payload.from.getTime() - payload.from.getTimezoneOffset()*60*1000);
        }

        if (payload.to) {
            payload.to = new Date(payload.to.getTime() - payload.to.getTimezoneOffset()*60*1000);
            payload.to.setUTCHours(23, 59, 59);
        }

        const branch = payload.branch? payload.branch : ''
        const product = payload.product? payload.product : ''
        const from = payload.from? payload.from.toISOString().split('.')[0] : ''
        const to = payload.to? payload.to.toISOString().split('.')[0] : ''

        const page = payload.page? payload.page : '1'
        const pagesize = payload.pagesize? payload.pagesize : '100'

        const params = `/buyinbpddetail/branch=${branch}&product=${product}&from=${from}&to=${to}&page=${page}&pagesize=${pagesize}`
        console.log(params )

        const { status: status,
            data:data } = await api.get(params)

        if (status != 200) throw new Error('การติดต่อกับระบบขัดข้อง ' + status)

        console.log(data)
        console.log(data.datas)

        context.commit('setDetailTable', data.datas)
    },

}