<template>
  <div id="app">
    <router-view/>
    <FullScreenLoading v-if="isFullScreenLoading"></FullScreenLoading>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import FullScreenLoading from "@/components/loading/FullScreenLoading";

export default {
  components: {
    FullScreenLoading
  },
  computed: {
    ...mapState({
      isFullScreenLoading: state => state.ui.isFullScreenLoading,
      plan: state => state.plan.currentPlan,
    })
  },
  methods: {

  },
  created() {
    this.$store.dispatch("auth/autoLogin");
    const user_type = localStorage.getItem("user_type");
    if (user_type && user_type === 'hospital_admin') {
      this.$store.dispatch("plan/getCurrentPlan");
    }
  }
}
</script>

<style lang="scss">
#app {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
