import * as vuexHelper from "../helpers/vuexHelper";
import { coreAPI } from "../../services/apis";
import * as endpoints from "../../services/endpoints";

const config = {
  default: {
    api: coreAPI
  },
  state: {
   
  },
  asyncActions: [
    {
      name: "getArticleDetail",
      method: "get",
      url: ({ id }) => `${endpoints.article}/${id}/`,
    },
  ]
};

export default vuexHelper.createStoreModule(config);
