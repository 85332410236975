import moment from 'moment'

export const combineDateTime = (dateValue, timeValue) => {
  if (dateValue && timeValue) {
    if (moment.isDate(dateValue) && moment.isDate(timeValue)) {
      const d = new Date(dateValue);
      const t = new Date(timeValue);
      // No secounds
      return new Date(d.getFullYear(), d.getMonth(), d.getDate(), t.getHours(), t.getMinutes())
    } else {
      return 'Date format is not correct'
    }
  } else {
    return 'Values are not correct'
  }
}

export const roundToQuarterHour = (dateValue) =>{
  if (moment.isDate(dateValue)) {
    const remainder = 15 - (dateValue.getMinutes() % 15);
    return moment(dateValue).add(remainder, "minutes")
  } else {
    throw Error("Date value is not correct")
  }
}

export const startToday = () => {
  var start = new Date();
  start.setHours(0,0,0,0)
  return new Date(start)
}

export const endToday = () => {
  var end = new Date();
  end.setHours(23,59,59,999)
  return new Date(end)
}

export const startTodayWithNoTime = () => {
  var date = new Date();
  return moment(date).format('YYYY-MM-DD');
}

export const endTodayWithNoTime = () => {
  var date = new Date();
  return moment(date).format('YYYY-MM-DD');
}

export const getThaiMonth = (index) => {
  var thmonth = new Array(
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม"
  )
  return thmonth[index]
}

export const toNextDay = (date) => {
  if (!date) return undefined;

  const newDate = new Date(date)
  newDate.setDate(newDate.getDate() + 1)
  return newDate;
}


export const onlyDate = (date) => {
  if (!date) return undefined;

  return moment(date).format('YYYY-MM-DD');
}

