<template>
    <el-card>
        <div class="contain">
            <div class="medical-record-item">
                <div class="date">Date: {{ date | toThaiDate }}</div>

                <div class="content" v-if="item.cc">
                    <div class="title">Chief Complaint : CC</div>
                    <div class="detail line-clamp">{{ item.cc }}</div>
                </div>

                <div class="content" v-if="item.pe">
                    <div class="title">Phycical examination : PE</div>
                    <div class="detail line-clamp">{{ item.pe }}</div>
                </div>

                <div class="content" v-if="item.dx">
                    <div class="title">Diagnosis : Dx</div>
                    <div class="detail line-clamp">{{ item.dx }}</div>
                </div>

                <div class="content" v-if="item.final_diagnosis">
                    <div class="title">Final Diagnosis</div>
                    <div class="detail line-clamp">{{ item.final_diagnosis }}</div>
                </div>

                <div class="content" v-if="item.tx">
                    <div class="title">Tretment  : Tx</div>
                    <div class="detail line-clamp" v-if="item.tx">{{ item.tx }}</div>
                </div>

                <div class="content" v-if="item.note">
                    <div class="title">บันทึกเพิ่มเติม</div>
                    <div class="detail line-clamp" v-if="item.note">{{ item.note }}</div>
                </div>

                
                <div class="content activity-record" v-if="item.pos">
                    <div class="title">Activity record</div>
                    <div class="detail">
                        <el-row class="activity_record"
                            v-for="(pos_activity, index) in item.pos.posactivity_set"
                            :key="index" style="margin-bottom: 12px;">
                        <el-col :span="3">{{ pos_activity.amount }} x</el-col>
                        <el-col :span="17">
                            <el-row>
                                <el-col :span="24">{{ pos_activity.activity.name }}</el-col>
                            </el-row>
                            <el-row v-if="pos_activity.activity.products[0]">
                                <el-col class="text-blue" :span="24">{{ pos_activity.activity.products[0].trade_name }}</el-col>
                            </el-row>
                        </el-col>
                        <el-col class="text-right" :span="4">{{ pos_activity.unit_price * pos_activity.amount | currency }} &#x0E3F;</el-col>
                        </el-row>
                        
                        <hr>

                        <el-row
                            v-if="item.pos.grand_total_price"
                            style="margin-bottom: 12px;">
                            <el-col :span="3">รวม</el-col>
                            <el-col class="text-right" :span="21">{{ item.pos.grand_total_price.before_discount | currency }} &#x0E3F;</el-col>
                        </el-row>
                        <el-row
                            v-if="item.pos.grand_total_price"
                            style="margin-bottom: 12px;">
                            <el-col :span="3">ส่วนลด</el-col>
                            <el-col class="text-right" :span="21">{{ item.pos.grand_total_price.discount | currency }} &#x0E3F;</el-col>
                        </el-row>
                        <el-row
                            v-if="item.pos.grand_total_price"
                            style="margin-bottom: 12px;">
                            <el-col :span="3"><strong>Total</strong></el-col>
                            <el-col class="text-right" :span="21">{{ item.pos.grand_total_price.after_discount | currency }} &#x0E3F;</el-col>
                        </el-row>
                        <el-row>
                            <el-col class="text-gray" :span="24">
                                <img src='@/assets/icons/icon-vet.png'
                                    style="width: 17px; height: 20px; margin-right: 8px;">สัตวแพทย์</el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24" v-if="item.vet">{{ item.vet.full_name }}</el-col>
                            <el-col :span="24" v-else>ไม่ระบุ</el-col>
                        </el-row>
                    </div>
                </div>

            </div>
        </div>
        <el-button class="action-btn b1"
            @click="$emit('click', item.id)"
            >ดูรายละเอียด</el-button>
    </el-card>
</template>

<script>
export default {
  props: ["date", "item"]
};
</script>

<style lang="scss" scoped>
    .medical-record-item {
        .date {
            font-size: 18px;
            font-weight: bold;
        }
        .content {
            .title {
                font-size: 16px;
                font-weight: bold;
                color: #02b0e1;
                padding: 0 12px;
            }
            .detail {
                font-size: 14px;
                color: #070b10;
                padding: 0 12px;
                font-family: "Roboto","Lucida Grande","DejaVu Sans","Bitstream Vera Sans",Verdana,Arial,sans-serif;
            }
        }
        .activity-record {
            .detail {
                padding-left: 25px;
            }
        }
        .text-blue {
            color: #156f88;
        }
        .text-gray {
            color: #9b9b9b;
        }
        .line-clamp {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;  
            overflow: hidden;
        }
    }
</style>

<style lang="scss" scoped>
.el-button {
  img {
    width: 20px;
    height: 20px;
    object-fit: contain;

    display: inline;
    vertical-align: middle;
  }

  &.side-button {
    width: 100%;
    // margin-top: 5px;
    margin-bottom: 15px;
  }
}

.el-button {
  &.action-btn {
    color: #156f88;
    border-radius: 5px;
    border: none;
    &:not(:hover) {
      background-color: #c2e5f0;
    }

    &.b1 {
      border-radius: 0px;
      width: 100%;
    }
  }
}

.contain {
  padding: 12px;
}
</style>