var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"no-gutter"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-1 flex"},[_c('div',{staticClass:"flex-center flex-middle"},[(_vm.isSelected)?_c('i',{staticClass:"icon icon-check",staticStyle:{"color":"green","font-size":"30px"}}):_vm._e()])]),_c('div',{staticClass:"col-md-3"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('h3',[_vm._v(_vm._s(_vm.bloodBagId))])])]),_c('div',{staticClass:"col-md-2"},[_vm._m(1),_c('div',{staticClass:"row"},[_c('h3',[_vm._v(_vm._s(_vm.bloodType))])])]),_c('div',{staticClass:"col-md-2"},[_vm._m(2),_c('div',{staticClass:"row"},[_c('h3',[_vm._v(_vm._s(_vm.pcvValue)+" %")])])]),_c('div',{staticClass:"col-md-3"},[_vm._m(3),_c('div',{staticClass:"row"},[_c('h3',[_vm._v(_vm._s(_vm.expiry))])])]),_c('div',{staticClass:"col-md-4"},[_vm._m(4),_c('div',{staticClass:"row"},[_c('h3',[_vm._v(_vm._s(_vm.possibility)+" - %")])])]),_c('div',{staticClass:"col-md-4"},[_vm._m(5),_c('div',{staticClass:"row"},[_c('h3',[_vm._v(_vm._s(_vm.suggestedQuantity)+" - ml.")])])]),_c('div',{staticClass:"col-md-3"},[_vm._m(6),_c('div',{staticClass:"row"},[_c('h3',[_vm._v(_vm._s(_vm.remainingQuantity)+" ml.")])])]),_c('div',{staticClass:"col-md-2 flex"},[_c('div',{staticClass:"row container flex-middle"},[_c('div',{staticClass:"flex-middle",staticStyle:{"height":"75%"}},[(_vm.isInStock && _vm.isSelected)?_c('at-button',{attrs:{"disabled":_vm.disabled},on:{"click":_vm.onCancel}},[_vm._v("ยกเลิก")]):_vm._e(),(_vm.isInStock && !_vm.isSelected)?_c('at-button',{attrs:{"disabled":_vm.disabled},on:{"click":_vm.onSelect}},[_vm._v("เลือกถุงเลือด")]):_vm._e(),(!_vm.isInStock)?_c('at-button',{attrs:{"disabled":_vm.disabled,"type":"error"},on:{"click":_vm.onRemoveClicked}},[_vm._v("Remove")]):_vm._e()],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('label',[_vm._v("เลขที่ถุงเลือด")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('label',[_vm._v("Type")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('label',[_vm._v("PCV")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('label',[_vm._v("วันหมดอายุ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('label',[_vm._v("คะแนนความเป็นไปได้")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('label',[_vm._v("ปริมาตรที่แนะนำ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('label',[_vm._v("ปริมาตรคงเหลือ")])])
}]

export { render, staticRenderFns }