
import petTypeEnum from '@/constants/bloodbank/pet-type-enum'
import BloodType from '@/constants/bloodbank/blood-product-type-enum'
import bloodProductTypeEnum from '@/constants/bloodbank/blood-product-type-enum'
import utils from '../../../../utils/bloodbank.js'
import store from '@/store/index'

export default class {
  constructor (obj) {
    if (obj != null) {
      this.id = obj.id == null ? null : obj.id
      this.createdDate = obj.created == null ? null : obj.created
      this.modifiedDate = obj.last_updated == null ? null : obj.last_updated
      this.bloodRequestDate = obj.blood_request_date == null ? null : obj.blood_request_date
      this.bloodType = obj.blood_type ? utils.convertBloodTypeServiceValueToEnum(obj.blood_type) : BloodType.FWB
      this.amount = obj.amount == null ? null : obj.amount
      this.note = obj.note == null ? null : obj.note
      this.targetHospitalId = obj.target_hospital == null ? '' : obj.target_hospital
      this.bloodBagId = obj.blood_collection
      this.petType = obj.pet_type == null ? null : this.convertPetTypeToEnum(obj.pet_type)
      this.hospitalId = obj.hospital == null ? null : obj.hospital
    } else {
      this.id = null
      this.createdDate = null
      this.modifiedDate = null
      this.bloodRequestDate = null
      this.bloodType = bloodProductTypeEnum.FWB
      this.amount = null
      this.note = null
      this.targetHospitalId = ''
      this.bloodBagId = ''
      this.petType = petTypeEnum.DOG
      this.hospitalId = store.state.hospital.profile.id
    }
  }

  convertPetTypeToEnum (type) {
    switch (type) {
      case 1:
        return petTypeEnum.DOG

      case 2:
        return petTypeEnum.CAT

      default:
        return type
    }
  }

  toRequestJson () {
    return {
      id: this.id,
      created: this.createdDate,
      last_updated: this.modifiedDate,
      blood_request_date: this.bloodRequestDate,
      blood_type: utils.convertBloodTypeEnumToServiceValue(this.bloodType),
      amount: this.amount,
      note: this.note,
      target_hospital: this.targetHospitalId,
      blood_collection: this.bloodBagId,
      pet_type: this.petType === petTypeEnum.DOG ? 1 : 2,
      hospital: this.hospitalId ? Number.isInteger(this.hospitalId) ? this.hospitalId : null : null
    }
  }
}
