<template>
  <div>
    <ContentBox class="content-detail">
      <div class="title">Chief Complaint: CC</div>
      <Viewer v-if="(progress_status === 'done' || !canEditData) && POData.problem_oriented.cc" :initialValue="POData.problem_oriented.cc"></Viewer>
      <div v-else-if="(progress_status === 'done' || !canEditData) && !POData.problem_oriented.cc" class="view-mode">
        -
      </div>
      <MarkdownEditor v-else
        height="150px"
        v-model="POData.problem_oriented.cc"
      ></MarkdownEditor>
      <div class="title">History Taking: HT</div>
      <Viewer v-if="(progress_status === 'done' || !canEditData) && POData.problem_oriented.ht" :initialValue="POData.problem_oriented.ht"></Viewer>
      <div v-else-if="(progress_status === 'done' || !canEditData) && !POData.problem_oriented.ht" class="view-mode">
        -
      </div>
      <MarkdownEditor v-else
        height="150px"
        v-model="POData.problem_oriented.ht"
      ></MarkdownEditor>
      <div class="title">Problem List</div>
      <div v-if="(progress_status === 'done' || !canEditData)" class="view-mode">
        <div v-for="item in POData.problem_oriented.problems" :key="item.name">
          {{item.name}}
        </div>
      </div>
      <div class="select-with-button" v-else>
        <el-select
            v-if="problem"
            v-model="POData.problem_oriented.problems"
            multiple
            :filterable="true"
            remote
            clearable
            :remote-method="asyncFindProblem"
            name="selectProblems"
            value-key="id"
            placeholder="Problem"
            style="width: 100%;">
            <ul class="el-select-dropdown__list"
              v-infinite-scroll="asyncFindProblem"
              :infinite-scroll-disabled="tagOptions.problem.loading || tagOptions.problem.nomore"
              infinite-scroll-immediate="false"
              style="height: 200px; overflow-y: scroll;">
              <el-option
                v-for="item in problem"
                :key="item.id"
                :label="item.name"
                :value="item">
              </el-option>
              <p class="el-select-dropdown__empty" v-if="tagOptions.problem.loading">loading...</p>
            </ul>
        </el-select>
        <div @click="openModal('problem')" class="select-with-button-content">
          <span>เพิ่ม Problem List</span>
          <div class="add-select-button"><img src="@/assets/icons/icon-add.png" style="max-width: 100%; max-height: 100%;" alt=""></div>
        </div>
      </div>
      <div class="title">Type of food</div>
      <div v-if="(progress_status === 'done' || !canEditData)" class="view-mode">
        <div v-for="item in POData.problem_oriented.food_types" :key="item.name">
          {{item.name}}
        </div>
      </div>
      <div class="select-with-button" style="width: 50%;" v-else>
        <el-select
            v-if="foodtype"
            v-model="POData.problem_oriented.food_types"
            multiple
            :filterable="true"
            remote
            clearable
            :remote-method="asyncFindFoodtype"
            name="selectFoodTypes"
            value-key="id"
            placeholder="Type of food">
            <ul class="el-select-dropdown__list"
              v-infinite-scroll="asyncFindFoodtype"
              :infinite-scroll-disabled="tagOptions.foodtype.loading || tagOptions.foodtype.nomore"
              infinite-scroll-immediate="false"
              style="height: 200px; overflow-y: scroll;">
              <el-option
                v-for="item in foodtype"
                :key="item.id"
                :label="item.name"
                :value="item">
              </el-option>
              <p class="el-select-dropdown__empty" v-if="tagOptions.foodtype.loading">loading...</p>
            </ul>
          </el-select>
        <div @click="openModal('foodtype')" class="select-with-button-content">
          <span>เพิ่ม Type of food</span>
          <div class="add-select-button"><img src="@/assets/icons/icon-add.png" style="max-width: 100%; max-height: 100%;" alt=""></div>
        </div>
      </div>
      <div class="title">แนบรูป, PDF</div>
      <input
        class="camera-bg"
        type="file"
        ref="filePO"
        @change="handleFileUpload"
        :hidden="(progress_status === 'done' || !canEditData)"
      />
      <div class="image-card-container">
        <el-card
          :body-style="{ padding: '5px' }"
          v-for="(file, index) in POData.problem_oriented.attachments"
          :key="index"
          class="image-card"
        >
          <div class="file-previewer clearfix">
            <div class="file-previewer-card">
              <div v-if="file.attachmentPreview && fileExt(file.attachmentPreview) === 'pdf'"
                class="thumbnail pdf"
                @click="handelDownload(file.attachmentPreview)"
              >
                <i class="fa fa-file-pdf"></i>
                <div class="overlay">
                  <i class="fa fa-download"></i>
                </div>
              </div>

              <div v-else class="thumbnail" @click="openModalPic(index)">
                <img :src="file.attachmentPreview" class="image image-size"/>
                <div class="overlay">
                  <i class="fa fa-search-plus"></i>
                </div>
              </div>

              <i v-if="progress_status !== 'done'" class="fa fa-times-circle" @click="showConfirmDeleteImage(index)"></i>
            </div>
          </div>

        </el-card>
      </div>
    </ContentBox>
    <div class="expand-box">
      Physical Exam
    </div>
    <ContentBox class="content-detail">
      <div class="title">Physical Examination : PE</div>
      <Viewer v-if="(progress_status === 'done' || !canEditData) && POData.physicalexam.note" :initialValue="POData.physicalexam.note"></Viewer>
      <div v-else-if="(progress_status === 'done' || !canEditData) && !POData.physicalexam.note" class="view-mode">
        -
      </div>
      <MarkdownEditor v-else
        height="150px"
        v-model="POData.physicalexam.note"
      ></MarkdownEditor>
      <div class="card-box">
        <div class="card" v-for="item in PEcard" :key="item.text">
          <label>
            {{ item.text }}
            <small 
              v-if="(progress_status != 'done' && canEditData) 
                      && item.type === 'number'"
              style="color: #d0021b; display: block;"
            >เฉพาะตัวเลข</small>
            <small v-else style="display: block; margin-bottom: 14px"></small>
          </label>
          <div v-if="(progress_status === 'done' || !canEditData)">
            <div v-if="POData.physicalexam[item.value] && item.type === 'number'" class="card-input-display">{{ POData.physicalexam[item.value] | currency }}</div>
            <div v-else class="card-input-display">{{ POData.physicalexam[item.value] }}</div>
          </div>
          <div v-else>
            <el-input-number 
              v-if="item.type === 'number'" 
              :controls="false" 
              :precision="3" 
              :step="0.1" 
              placeholder='0.000' 
              class="card-input" 
              v-model="POData.physicalexam[item.value]" 
              @input="item.callback"
              size="small">
            </el-input-number>
            <el-input 
              v-else 
              class="card-input" 
              v-model="POData.physicalexam[item.value]"
              size="small">
            </el-input>
          </div>
        </div>
      </div>
      <div style="display: flex; justify-content: space-between;">
        <div>
          <div class="title">Mucous Membrane</div>
          <div class="radio-box" style="width: 50%;">
            <div class="custom-radios" :class="(progress_status === 'done' || !canEditData) && 'disable-ele'" v-for="item in MMradio" :key="item.value">
              <div>
                <input type="radio" :id="item.value" name="color" :value="item.value" v-model="POData.physicalexam.membrane" @click="checkCancel('color', item.value)">
                <label :for="item.value">
                  <span>
                    <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/242518/check-icn.svg" alt="Checked Icon" />
                  </span>
                </label>
              </div>
              <label style="margin-left: -15px;">{{ item.text }}</label>
            </div>
          </div>
        </div>
        <div>
          <div class="title">BSC Level</div>
          <div class="radio-box" style="width: 100%;">
            <div class="bsc-pic">
              <span v-if="pet.type.id === 1 && (POData.physicalexam.BSCLevel === 1 || POData.physicalexam.BSCLevel === 2)">
                <img src="@/assets/medical/dog-bsc-too-thin-1.png"/>
              </span>
              <span v-if="pet.type.id === 1 && (POData.physicalexam.BSCLevel === 3 || POData.physicalexam.BSCLevel === 4)">
                <img src="@/assets/medical/dog-bsc-too-thin-2.png"/>
              </span>
              <span v-if="pet.type.id === 1 && POData.physicalexam.BSCLevel === 5">
                <img src="@/assets/medical/dog-bsc-ideal.png"/>
              </span>
              <span v-if="pet.type.id === 1 && (POData.physicalexam.BSCLevel === 6 || POData.physicalexam.BSCLevel === 7)">
                <img src="@/assets/medical/dog-bsc-overweight.png"/>
              </span>
              <span v-if="pet.type.id === 1 && (POData.physicalexam.BSCLevel === 8 || POData.physicalexam.BSCLevel === 9)">
                <img src="@/assets/medical/dog-bsc-obese.png"/>
              </span>
              <span v-if="pet.type.id === 2 && (POData.physicalexam.BSCLevel === 1 || POData.physicalexam.BSCLevel === 2)">
                <img src="@/assets/medical/cat-bsc-too-thin-1.png"/>
              </span>
              <span v-if="pet.type.id === 2 && (POData.physicalexam.BSCLevel === 3 || POData.physicalexam.BSCLevel === 4)">
                <img src="@/assets/medical/cat-bsc-too-thin-2.png"/>
              </span>
              <span v-if="pet.type.id === 2 && POData.physicalexam.BSCLevel === 5">
                <img src="@/assets/medical/cat-bsc-ideal.png"/>
              </span>
              <span v-if="pet.type.id === 2 && (POData.physicalexam.BSCLevel === 6 || POData.physicalexam.BSCLevel === 7)">
                <img src="@/assets/medical/cat-bsc-overweight.png"/>
              </span>
              <span v-if="pet.type.id === 2 && (POData.physicalexam.BSCLevel === 8 || POData.physicalexam.BSCLevel === 9)">
                <img src="@/assets/medical/cat-bsc-obese.png"/>
              </span>
            </div>
            <div class="bsc-radios" :class="(progress_status === 'done' || !canEditData) && 'disable-ele'" v-for="item in BSCradio" :key="item.value">
              <div>
                <input type="radio" :id="'bsc-' + item.value" v-model="POData.physicalexam.BSCLevel" name="bsc" :value="item.value" @click="checkCancel('bsc', item.value)">
                <label :for="'bsc-' + item.value">
                  <span>
                    <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/242518/check-icn.svg" alt="Checked Icon" />
                  </span>
                </label>
              </div>
              <label>{{ item.value }}</label>
              <label class="mini-text">{{ item.value == 1 && 'Too Thin' || item.value == 5 && 'Ideal' || item.value == 7 && 'Obesity' || item.value == 9 && 'Overweight' || '' }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="checklist-box" style="width: 100%;">
        <div class="checklist-content" style="width: 80%; margin-left: 10%;">
          <div class="expand-box">Examination</div>
          <ContentBox class="content-detail symptom">
            <symptom-table :disabled="(progress_status === 'done' || !canEditData)" :symptoms={symptomCheckingList}></symptom-table>
          </ContentBox>
        </div>
      </div>
    </ContentBox>
    <div class="expand-box">
      Diagnosis
    </div>
    <ContentBox class="content-detail">
      <div class="title">Diagnosis</div>
      <Viewer v-if="(progress_status === 'done' || !canEditData) && POData.problem_oriented.diagnosis_detail" :initialValue="POData.problem_oriented.diagnosis_detail"></Viewer>
      <div v-else-if="(progress_status === 'done' || !canEditData) && !POData.problem_oriented.diagnosis_detail" class="view-mode">
        -
      </div>
      <MarkdownEditor v-else
        height="150px"
        v-model="POData.problem_oriented.diagnosis_detail"
      ></MarkdownEditor>
      <div class="title">Differential Diagnosis <span>(เลือกได้มากกว่า 1)</span></div>
      <div v-if="(progress_status === 'done' || !canEditData)" class="view-mode">
        <div v-for="item in POData.problem_oriented.diff_diagnosises" :key="item.name">
          {{item.name}}
        </div>
      </div>
      <div class="select-with-button" v-else>
        <el-select
            v-if="diagnosis"
            v-model="POData.problem_oriented.diff_diagnosises"
            multiple
            :filterable="true"
            remote
            clearable
            :remote-method="asyncFindDiagnosis"
            name="selectDiagnosis"
            value-key="id"
            placeholder="เลือก Differential Diagnosis"
            style="width: 100%;">
            <ul class="el-select-dropdown__list"
              v-infinite-scroll="asyncFindDiagnosis"
              :infinite-scroll-disabled="tagOptions.diagnosis.loading || tagOptions.diagnosis.nomore"
              infinite-scroll-immediate="false"
              style="height: 200px; overflow-y: scroll;">
              <el-option
                v-for="item in diagnosis"
                :key="item.id"
                :label="item.name"
                :value="item">
              </el-option>
              <p class="el-select-dropdown__empty" v-if="tagOptions.diagnosis.loading">loading...</p>
            </ul>
          </el-select>
        <div @click="openModal('diagnosis')" class="select-with-button-content">
          <span>เพิ่ม Differential Diagnosis</span>
          <div class="add-select-button"><img src="@/assets/icons/icon-add.png" style="max-width: 100%; max-height: 100%;" alt=""></div>
        </div>
      </div>
      <div class="title">Final Diagnosis <span><span style="color: #2198f1;">= Master Problem </span> (เลือกได้เพียง 1 ชื่อเท่านั้น) </span></div>
      <div v-if="(progress_status === 'done' || !canEditData)" class="view-mode">
        {{POData.problem_oriented.final_diagnosis ? POData.problem_oriented.final_diagnosis.name : ''}}
      </div>
      <div class="infinite-list-wrapper" v-else>
        <el-select class="el-select-dropdown__list"
            v-if="diagnosis"
            v-model="POData.problem_oriented.final_diagnosis"
            :filterable="true"
            remote
            clearable
            :remote-method="asyncFindDiagnosis"
            name="selectMasterProblem"
            value-key="id"
            placeholder="Final Diagnosis"
            style="width: 50%;">
            <ul class="el-select-dropdown__list"
              v-infinite-scroll="asyncFindDiagnosis"
              :infinite-scroll-disabled="tagOptions.diagnosis.loading || tagOptions.diagnosis.nomore"
              infinite-scroll-immediate="false"
              style="height: 200px; overflow-y: scroll;">
              <el-option
                v-for="item in diagnosis"

                :key="item.id"
                :label="item.name"
                :value="item">
              </el-option>
              <p class="el-select-dropdown__empty" v-if="tagOptions.diagnosis.loading">loading...</p>
            </ul>
        </el-select>
      </div>
    </ContentBox>
    <div class="expand-box">
      Treatment & Plan
    </div>
    <ContentBox class="content-detail">
      <div v-for="item in TandP" :key="item.name">
        <div class="title">
          {{ item.text }}
          <span
            v-if="item.hint"
            :style="{ fontSize: '12px', color: '#4a4a4a', marginLeft: '8px' }"
          >{{ item.hint }}</span>
        </div>
        <Viewer v-if="(progress_status === 'done' || !canEditData) && POData.problem_oriented[item.name]" :initialValue="POData.problem_oriented[item.name]"></Viewer>
        <div v-else-if="(progress_status === 'done' || !canEditData) && !POData.problem_oriented[item.name]" class="view-mode">
          -
        </div>
        <MarkdownEditor v-else
          height="150px"
          v-model="POData.problem_oriented[item.name]"
        ></MarkdownEditor>
      </div>
    </ContentBox>
    <div class="expand-box">
      การตอบสนองต่อการรักษาของสัตว์ป่วย
    </div>
    <ContentBox class="content-detail">
      <div class="radio-box" style="width: 100%;">
        <div class="conclude-radios" :class="(progress_status === 'done' || !canEditData) && 'disable-ele'" v-for="item in concludeRatio" :key="item.value">
          <div>
            <input v-model="POData.problem_oriented.response_score" type="radio" :id="'conclude-' + item.value" name="conclude" :value="item.value" @click="checkCancel('conclude', item.value)">
            <label :for="'conclude-' + item.value">
              <span>
                {{ item.text }}
              </span>
            </label>
          </div>
        </div>
      </div>
      <Viewer v-if="(progress_status === 'done' || !canEditData)" :initialValue="POData.problem_oriented.response"></Viewer>
      <MarkdownEditor v-else
        height="150px"
        v-model="POData.problem_oriented.response"
      ></MarkdownEditor>
    </ContentBox>
    <ConfirmDialog
            :visible="showConfirmDeleteDialog"
            @cancel="showConfirmDeleteDialog = false"
            @confirm="deleteImage"
    >
      <template slot="header">
        <h1 class="title">ยืนยันการลบรูปภาพนี้</h1>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>

import { mapState } from "vuex"
import ContentBox from "@/components/container/ContentBox"
import SymptomTable from '@/components/medical/common/SymptomTable'
import MarkdownEditor from '@/components/medical/common/MarkdownEditor'
import { Viewer } from '@toast-ui/vue-editor'
import ConfirmDialog from "@/components/dialog/ConfirmDialog"
import { getParamFromUrlString } from "@/utils/index"
import { Loading } from 'element-ui';

export default {
  props: [
    'pet', 
    'modalData', 
    'POData', 
    'progress_status', 
    'modalPic',
    "canEditData",
    'ipd'
  ],
  components: {
    ContentBox,
    'symptom-table': SymptomTable,
    MarkdownEditor,
    Viewer,
    ConfirmDialog
  },
  data() {
    return {
      isRestored: false,
      tagOptions: {
        problem: {
          loading: false,
          search: '',
          page: 1,
          nomore: false
        },
        foodtype: {
          loading: false,
          search: '',
          page: 1,
          nomore: false
        },
        diagnosis: {
          loading: false,
          search: '',
          page: 1,
          nomore: false
        }
      },
      showConfirmDeleteDialog: false,
      deleteImg: {
        index: null
      },
      PEcard: [
        { text: "Weight (kg.)", value: 'weight', type: 'number', callback: undefined },
        { text: "Temp (°F)", value: 'tempF', type: 'number', callback: this.fahrenheitChange  },
        { text: "Temp (°C)", value: 'temp', type: 'number', callback: this.celsiusChange },
        { text: "HR (bmp)", value: 'HR', type: 'text', callback: undefined   },
        { text: "RR (tpm)", value: 'RR', type: 'text', callback: undefined   },
        { text: "CRT (Sec)", value: 'CRT', type: 'text', callback: undefined   },
        { text: "BP (mmHg)", value: 'BP', type: 'text', callback: undefined   }
      ],
      MMradio: [
        { value: "pale", text: "Pale" },
        { value: "normal", text: "Normal" },
        { value: "pink", text: "Pink" },
        { value: "red", text: "Red" },
        { value: "purple", text: "Purple" },
        { value: "brown", text: "Brown" },
        { value: "black", text: "Black" }
      ],
      BSCradio: [
        { value: 1 },
        { value: 2 },
        { value: 3 },
        { value: 4 },
        { value: 5 },
        { value: 6 },
        { value: 7 },
        { value: 8 },
        { value: 9 }
      ],
      TandP: [
        { name: 'tx', text: "Treatment: Tx" },
        { name: 'rx', text: "Recipere: Rx" },
        { name: 'treatment_plan', text: "Plan: แผนการรักษา" },
        { name: 'client_edu', text: "Client Education: คำแนะนำให้เจ้าของสัตว์" },
        { name: 'notes', text: "ประเมินค่าใช้จ่าย", hint: this.isIPD() ? "*จะแสดงในใบแจ้งค่าใช้จ่าย ใน IPD ถ้าเลือกหลายๆ รายการเพื่อออกใบแจ้งค่าใช้จ่าย ข้อความจะรวมของทุกรายการแสดงไปพร้อมกัน" : "*จะแสดงในใบแจ้งค่าใช้จ่าย" }
      ],
      concludeRatio: [
        { value: 1, text: "แย่ลง (-1)" },
        { value: 2, text: "ไม่เปลี่ยนแปลง (0)" },
        { value: 3, text: "อาการดีขึ้น (+1)" },
        { value: 4, text: "หายดี (+2)" }
      ],
      symptomCheckingList: [
        {
          "organName": "eye",
          "organType": "couple",
          "isNormal": this.POData.physicalexam.is_eye_normal ? "normal" : "abnormal",
          "note": this.POData.physicalexam.eye_note,
          "symptomDetail": [
            {
              "symptomName": "ophthalmitis",
              "status": {
                "left": this.POData.physicalexam.is_ophthalmitis === 1 || this.POData.physicalexam.is_ophthalmitis === 3,
                "right": this.POData.physicalexam.is_ophthalmitis === 2 || this.POData.physicalexam.is_ophthalmitis === 3
              }
            },
            {
              "symptomName": "corneal opacity",
              "status": {
                "left": this.POData.physicalexam.is_corneal_opacity === 1 || this.POData.physicalexam.is_corneal_opacity === 3,
                "right": this.POData.physicalexam.is_corneal_opacity === 2 || this.POData.physicalexam.is_corneal_opacity === 3
              }
            },
            {
              "symptomName": "ulcerate",
              "status": {
                "left": this.POData.physicalexam.is_ulcerate === 1 || this.POData.physicalexam.is_ulcerate === 3,
                "right": this.POData.physicalexam.is_ulcerate === 2 || this.POData.physicalexam.is_ulcerate === 3
              }
            },
            {
              "symptomName": "ocular hypertension",
              "status": {
                "left": this.POData.physicalexam.is_hypertension === 1 || this.POData.physicalexam.is_hypertension === 3,
                "right": this.POData.physicalexam.is_hypertension === 2 || this.POData.physicalexam.is_hypertension === 3
              }
            }
          ]
        },
        {
          "organName": "ear",
          "organType": "couple",
          "isNormal": this.POData.physicalexam.is_ear_normal ? "normal" : "abnormal",
          "note": this.POData.physicalexam.ear_note,
          "symptomDetail": [
            {
              "symptomName": "inaudible",
              "status": {
                "left": this.POData.physicalexam.is_inaudible === 1 || this.POData.physicalexam.is_inaudible === 3,
                "right": this.POData.physicalexam.is_inaudible === 2 || this.POData.physicalexam.is_inaudible === 3
              }
            },
            {
              "symptomName": "earwax",
              "status": {
                "left": this.POData.physicalexam.is_earwax === 1 || this.POData.physicalexam.is_earwax === 3,
                "right": this.POData.physicalexam.is_earwax === 2 || this.POData.physicalexam.is_earwax === 3
              }
            },
            {
              "symptomName": "smell",
              "status": {
                "left": this.POData.physicalexam.is_ear_smelly === 1 || this.POData.physicalexam.is_ear_smelly === 3,
                "right": this.POData.physicalexam.is_ear_smelly === 2 || this.POData.physicalexam.is_ear_smelly === 3
              }
            },
            {
              "symptomName": "otitis",
              "status": {
                "left": this.POData.physicalexam.is_otitis === 1 || this.POData.physicalexam.is_otitis === 3,
                "right": this.POData.physicalexam.is_otitis === 2 || this.POData.physicalexam.is_otitis === 3
              }
            },
            {
              "symptomName": "mite",
              "status": {
                "left": this.POData.physicalexam.is_mite === 1 || this.POData.physicalexam.is_mite === 3,
                "right": this.POData.physicalexam.is_mite === 2 || this.POData.physicalexam.is_mite === 3
              }
            }
          ]
        },
        {
          "organName": "skin and hair",
          "organType": "single",
          "isNormal": this.POData.physicalexam.is_skin_and_hair_normal ? "normal" : "abnormal",
          "note": this.POData.physicalexam.skin_and_hair_note,
          "symptomDetail": [
            {
              "symptomName": "excoriation",
              "status": {
                "single": this.POData.physicalexam.is_excoriation
              }
            },
            {
              "symptomName": "oily skin",
              "status": {
                "single": this.POData.physicalexam.is_oily_skin
              }
            },
            {
              "symptomName": "hair lose",
              "status": {
                "single": this.POData.physicalexam.is_hair_lose
              }
            },
            {
              "symptomName": "rash",
              "status": {
                "single": this.POData.physicalexam.is_rash
              }
            },
            {
              "symptomName": "tick",
              "status": {
                "single": this.POData.physicalexam.is_tick
              }
            }
          ]
        },
        {
          "organName": "alimentary system",
          "organType": "single",
          "isNormal": this.POData.physicalexam.is_alimetary_system_normal ? "normal" : "abnormal",
          "note": this.POData.physicalexam.alimetary_system_note,
          "symptomDetail": [
            {
              "symptomName": "diarrhea",
              "status": {
                "single": this.POData.physicalexam.is_diarrhea
              }
            },
            {
              "symptomName": "vomit",
              "status": {
                "single": this.POData.physicalexam.is_vomit
              }
            },
            {
              "symptomName": "constipation",
              "status": {
                "single": this.POData.physicalexam.is_constipation
              }
            }
          ]
        },
        {
          "organName": "heart",
          "organType": "single",
          "isNormal": this.POData.physicalexam.is_heart_normal ? "normal" : "abnormal",
          "note": this.POData.physicalexam.heart_note,
          "symptomDetail": [
            {
              "symptomName": "disturbance sound",
              "status": {
                "single": this.POData.physicalexam.is_disturbance_sound
              }
            },
            {
              "symptomName": "arrhythmia",
              "status": {
                "single": this.POData.physicalexam.is_arrhythmia
              }
            },
            {
              "symptomName": "enlarged heart",
              "status": {
                "single": this.POData.physicalexam.is_enlarged_heart
              }
            }
          ]
        },
        {
          "organName": "respiratory system",
          "organType": "single",
          "isNormal": this.POData.physicalexam.is_respiratory_system_normal ? "normal" : "abnormal",
          "note": this.POData.physicalexam.respiratory_system_note,
          "symptomDetail": [
            {
              "symptomName": "gasping",
              "status": {
                "single": this.POData.physicalexam.is_gasping
              }
            },
            {
              "symptomName": "cough",
              "status": {
                "single": this.POData.physicalexam.is_cough
              }
            },
            {
              "symptomName": "snivel",
              "status": {
                "single": this.POData.physicalexam.is_snivel
              }
            }
          ]
        },
        {
          "organName": "mouth and teeth and gill",
          "organType": "single",
          "isNormal": this.POData.physicalexam.is_mouth_teeth_gill_normal ? "normal" : "abnormal",
          "note": this.POData.physicalexam.mouth_teeth_gill_note,
          "symptomDetail": [
            {
              "symptomName": "smell",
              "status": {
                "single": this.POData.physicalexam.is_mouth_teeth_gill_smelly
              }
            },
            {
              "symptomName": "gingivitis",
              "status": {
                "single": this.POData.physicalexam.is_gingivitis
              }
            },
            {
              "symptomName": "detal calculas",
              "status": {
                "single": this.POData.physicalexam.is_dental_calculus
              }
            },
            {
              "symptomName": "decayed tooth",
              "status": {
                "single": this.POData.physicalexam.is_decayed_tooth
              }
            }
          ]
        },
        {
          "organName": "urinate system and reproduce system",
          "organType": "single",
          "isNormal": this.POData.physicalexam.is_urinate_and_reproduce_system_normal ? "normal" : "abnormal",
          "note": this.POData.physicalexam.urinate_and_reproduce_system_note,
          "symptomDetail": [
            {
              "symptomName": "guarded",
              "status": {
                "single": this.POData.physicalexam.is_guarded
              }
            },
            {
              "symptomName": "tumor",
              "status": {
                "single": this.POData.physicalexam.is_tumor
              }
            },
            {
              "symptomName": "frown",
              "status": {
                "single": this.POData.physicalexam.is_frown
              }
            }
          ]
        },
        {
          "organName": "bone and muscle",
          "organType": "single",
          "isNormal": this.POData.physicalexam.is_bone_and_muscle_normal ? "normal" : "abnormal",
          "note": this.POData.physicalexam.bone_and_muscle_note,
          "symptomDetail": [
            {
              "symptomName": "limp",
              "status": {
                "single": this.POData.physicalexam.is_limp
              }
            },
            {
              "symptomName": "out of shape",
              "status": {
                "single": this.POData.physicalexam.is_out_of_shape
              }
            },
            {
              "symptomName": "deteriorate",
              "status": {
                "single": this.POData.physicalexam.is_deteriorate
              }
            }
          ]
        },
        {
          "organName": "lymph node",
          "organType": "single",
          "isNormal": this.POData.physicalexam.is_lymph_node_normal ? "normal" : "abnormal",
          "note": this.POData.physicalexam.lymph_node_note,
          "symptomDetail": [
            {
              "symptomName": "intumesce",
              "status": {
                "single": this.POData.physicalexam.is_intumesce
              }
            },
            {
              "symptomName": "pain",
              "status": {
                "single": this.POData.physicalexam.is_pain
              }
            },
            {
              "symptomName": "inflame",
              "status": {
                "single": this.POData.physicalexam.is_inflame
              }
            }
          ]
        },
        {
          "organName": "nervous system",
          "organType": "single",
          "isNormal": this.POData.physicalexam.is_nervous_system_normal ? "normal" : "abnormal",
          "note": this.POData.physicalexam.nervous_system_note,
          "symptomDetail": [
            {
              "symptomName": "convulse",
              "status": {
                "single": this.POData.physicalexam.is_convulse
              }
            },
            {
              "symptomName": "weak",
              "status": {
                "single": this.POData.physicalexam.is_weak
              }
            },
            {
              "symptomName": "paralysis",
              "status": {
                "single": this.POData.physicalexam.is_paralysis
              }
            }
          ]
        }
      ]
    }
  },
  computed: {
    ...mapState({
      problem: state => state.medical.problem || [],
      foodtype: state => state.medical.foodtype || [],
      diagnosis: state => state.medical.diagnosis || []
    })
  },
  watch: {
    "POData.problem_oriented": {
      handler() {
        if (this.POData.problem_oriented.last_updated) {
          this.restoreModel()
        }
      }
    },
    symptomCheckingList: {
      handler() {
        this.POData.physicalexam.is_eye_normal = this.symptomCheckingList[0].isNormal === "normal"
        this.POData.physicalexam.is_ophthalmitis = 0 + (1 && this.symptomCheckingList[0].symptomDetail[0].status.left) + (2 && this.symptomCheckingList[0].symptomDetail[0].status.right)
        this.POData.physicalexam.is_corneal_opacity = 0 + (1 && this.symptomCheckingList[0].symptomDetail[1].status.left) + (2 && this.symptomCheckingList[0].symptomDetail[1].status.right)
        this.POData.physicalexam.is_ulcerate = 0 + (1 && this.symptomCheckingList[0].symptomDetail[2].status.left) + (2 && this.symptomCheckingList[0].symptomDetail[2].status.right)
        this.POData.physicalexam.is_hypertension = 0 + (1 && this.symptomCheckingList[0].symptomDetail[3].status.left) + (2 && this.symptomCheckingList[0].symptomDetail[3].status.right)
        this.POData.physicalexam.eye_note = this.symptomCheckingList[0].note
        this.POData.physicalexam.is_ear_normal = this.symptomCheckingList[1].isNormal === "normal"
        this.POData.physicalexam.is_inaudible = 0 + (1 && this.symptomCheckingList[1].symptomDetail[0].status.left) + (2 && this.symptomCheckingList[1].symptomDetail[0].status.right)
        this.POData.physicalexam.is_earwax = 0 + (1 && this.symptomCheckingList[1].symptomDetail[1].status.left) + (2 && this.symptomCheckingList[1].symptomDetail[1].status.right)
        this.POData.physicalexam.is_ear_smelly = 0 + (1 && this.symptomCheckingList[1].symptomDetail[2].status.left) + (2 && this.symptomCheckingList[1].symptomDetail[2].status.right)
        this.POData.physicalexam.is_otitis = 0 + (1 && this.symptomCheckingList[1].symptomDetail[3].status.left) + (2 && this.symptomCheckingList[1].symptomDetail[3].status.right)
        this.POData.physicalexam.is_mite = 0 + (1 && this.symptomCheckingList[1].symptomDetail[4].status.left) + (2 && this.symptomCheckingList[1].symptomDetail[4].status.right)
        this.POData.physicalexam.ear_note = this.symptomCheckingList[1].note
        this.POData.physicalexam.is_mouth_teeth_gill_normal = this.symptomCheckingList[6].isNormal === "normal"
        this.POData.physicalexam.is_mouth_teeth_gill_smelly = this.symptomCheckingList[6].symptomDetail[0].status.single
        this.POData.physicalexam.is_gingivitis = this.symptomCheckingList[6].symptomDetail[1].status.single
        this.POData.physicalexam.is_dental_calculus = this.symptomCheckingList[6].symptomDetail[2].status.single
        this.POData.physicalexam.is_decayed_tooth = this.symptomCheckingList[6].symptomDetail[3].status.single
        this.POData.physicalexam.mouth_teeth_gill_note = this.symptomCheckingList[6].note
        this.POData.physicalexam.is_skin_and_hair_normal = this.symptomCheckingList[2].isNormal === "normal"
        this.POData.physicalexam.is_excoriation = this.symptomCheckingList[2].symptomDetail[0].status.single
        this.POData.physicalexam.is_oily_skin = this.symptomCheckingList[2].symptomDetail[1].status.single
        this.POData.physicalexam.is_hair_lose = this.symptomCheckingList[2].symptomDetail[2].status.single
        this.POData.physicalexam.is_rash = this.symptomCheckingList[2].symptomDetail[3].status.single
        this.POData.physicalexam.is_tick = this.symptomCheckingList[2].symptomDetail[4].status.single
        this.POData.physicalexam.skin_and_hair_note = this.symptomCheckingList[2].note
        this.POData.physicalexam.is_urinate_and_reproduce_system_normal = this.symptomCheckingList[7].isNormal === "normal"
        this.POData.physicalexam.is_guarded = this.symptomCheckingList[7].symptomDetail[0].status.single
        this.POData.physicalexam.is_tumor = this.symptomCheckingList[7].symptomDetail[1].status.single
        this.POData.physicalexam.is_frown = this.symptomCheckingList[7].symptomDetail[2].status.single
        this.POData.physicalexam.urinate_and_reproduce_system_note = this.symptomCheckingList[7].note
        this.POData.physicalexam.is_alimetary_system_normal = this.symptomCheckingList[3].isNormal === "normal"
        this.POData.physicalexam.is_diarrhea = this.symptomCheckingList[3].symptomDetail[0].status.single
        this.POData.physicalexam.is_vomit = this.symptomCheckingList[3].symptomDetail[1].status.single
        this.POData.physicalexam.is_constipation = this.symptomCheckingList[3].symptomDetail[2].status.single
        this.POData.physicalexam.alimetary_system_note = this.symptomCheckingList[3].note
        this.POData.physicalexam.is_respiratory_system_normal = this.symptomCheckingList[5].isNormal === "normal"
        this.POData.physicalexam.is_gasping = this.symptomCheckingList[5].symptomDetail[0].status.single
        this.POData.physicalexam.is_cough = this.symptomCheckingList[5].symptomDetail[1].status.single
        this.POData.physicalexam.is_snivel = this.symptomCheckingList[5].symptomDetail[2].status.single
        this.POData.physicalexam.respiratory_system_note = this.symptomCheckingList[5].note
        this.POData.physicalexam.is_bone_and_muscle_normal = this.symptomCheckingList[8].isNormal === "normal"
        this.POData.physicalexam.is_limp = this.symptomCheckingList[8].symptomDetail[0].status.single
        this.POData.physicalexam.is_out_of_shape = this.symptomCheckingList[8].symptomDetail[1].status.single
        this.POData.physicalexam.is_deteriorate = this.symptomCheckingList[8].symptomDetail[2].status.single
        this.POData.physicalexam.bone_and_muscle_note = this.symptomCheckingList[8].note
        this.POData.physicalexam.is_heart_normal = this.symptomCheckingList[4].isNormal === "normal"
        this.POData.physicalexam.is_disturbance_sound = this.symptomCheckingList[4].symptomDetail[0].status.single
        this.POData.physicalexam.is_arrhythmia = this.symptomCheckingList[4].symptomDetail[1].status.single
        this.POData.physicalexam.is_enlarged_heart = this.symptomCheckingList[4].symptomDetail[2].status.single
        this.POData.physicalexam.heart_note = this.symptomCheckingList[4].note
        this.POData.physicalexam.is_lymph_node_normal = this.symptomCheckingList[9].isNormal === "normal"
        this.POData.physicalexam.is_intumesce = this.symptomCheckingList[9].symptomDetail[0].status.single
        this.POData.physicalexam.is_pain = this.symptomCheckingList[9].symptomDetail[1].status.single
        this.POData.physicalexam.is_inflame = this.symptomCheckingList[9].symptomDetail[2].status.single
        this.POData.physicalexam.lymph_node_note = this.symptomCheckingList[9].note
        this.POData.physicalexam.is_nervous_system_normal = this.symptomCheckingList[10].isNormal === "normal"
        this.POData.physicalexam.is_convulse = this.symptomCheckingList[10].symptomDetail[0].status.single
        this.POData.physicalexam.is_weak = this.symptomCheckingList[10].symptomDetail[1].status.single
        this.POData.physicalexam.is_paralysis = this.symptomCheckingList[10].symptomDetail[2].status.single
        this.POData.physicalexam.nervous_system_note = this.symptomCheckingList[10].note
      },
      deep: true
    }
  },
  methods: {
    isIPD() {
      return this.ipd
    },

    celsiusChange(value) {
      if (!value) return;
      this.POData.physicalexam.tempF = parseFloat(this.convertCToF(value)).toFixed(2);
    },

    fahrenheitChange(value) {
      if (!value) return;
      this.POData.physicalexam.temp = parseFloat(this.convertFToC(value)).toFixed(2);
    },

    convertCToF(celsius) {
      return celsius * 9/5 + 32
    },

    convertFToC(fahrenheit) {
      return (fahrenheit - 32) * 5/9;
    },

    async deleteImage() {
      if (this.POData.problem_oriented.last_updated) {
        await this.$store.commit("medical/deletePOAttachment", {
          index: this.deleteImg.index
        });
      } else {
        this.POData.problem_oriented.attachments.splice(this.deleteImg.index, 1)
      }

      this.deleteImg.index = null
      this.showConfirmDeleteDialog = false
    },
    showConfirmDeleteImage(index) {
      this.deleteImg.index = index
      this.showConfirmDeleteDialog = true
    },
    openModalPic(index) {
      this.modalPic.img = JSON.parse(JSON.stringify(this.POData.problem_oriented.attachments[index]))
      this.modalPic.index = index
      this.modalPic.allImgs = JSON.parse(JSON.stringify(this.POData.problem_oriented.attachments))
      this.modalPic.noTag = true
      this.modalPic.showModal = true
    },
    checkCancel(type, value) {
      if (type === 'color' && value === this.POData.physicalexam.membrane) {
        this.POData.physicalexam.membrane = null
      } else if (type === 'bsc' && value === this.POData.physicalexam.BSCLevel) {
        this.POData.physicalexam.BSCLevel = null
      } else if (type === 'conclude' && value === this.POData.problem_oriented.response_score) {
        this.POData.problem_oriented.response_score = ""
      }
    },
    handleFileUpload() {
      let refFile = this.$refs.filePO
      const loading = Loading.service({ fullscreen: true });
      Array.from(refFile.files).forEach(async file => {
        let response = await this.uploadFile(file)
        let model = {
          id: response.id,
          attachment: response.attachment,
          attachmentPreview: response.attachment
        }
        if (this.POData.problem_oriented.last_updated) {
          this.$store.commit("medical/addPOAttachment", model)
        } else {
          this.POData.problem_oriented.attachments.unshift(model)
        }
        loading.close();
      })
    },
    openModal(type) {
      if (type === 'foodtype') {
        this.modalData.modalType = 0
        this.modalData.modalTitle = 'Create Type of food'
      } else if (type === 'diagnosis') {
        this.modalData.modalType = 1
        this.modalData.modalTitle = 'Create Diagnosis'
      } else {
        this.modalData.modalType = 2
        this.modalData.modalTitle = 'Create Problem List'
      }
      this.modalData.showCreateButton = true
      this.modalData.showModal = true
    },
    fileExt(filename) {
      return typeof filename === 'string' || filename instanceof String
        ? filename.split(/[#?]/)[0].split('.').pop().trim()
        : undefined;
    },
    handelDownload(file) {
      var link = document.createElement("a")
      link.target = "_blank"
      link.href = file
      link.download = "file.pdf"
      link.dispatchEvent(new MouseEvent("click"))
    },
    async uploadFile(file) {
      try {
        let formData = new FormData()
        formData.append("attachment", file)
        const response = await this.$store.dispatch("medical/attachment", {
          formData
        })
        this.$notify({
          message: `อัพโหลดไฟล์เรียบร้อยแล้ว`,
          type: "success"
        })
        return response
      } catch (error) {
        this.$notify({
          message: "ไม่สามารถอัพโหลดไฟล์ " + file.name,
          type: "error"
        })
        return null
      }
    },
    async asyncFindProblem (query=null) {
      if (query != null && query !== this.tagOptions.problem.search) {
        this.tagOptions.problem.search = query;
        this.tagOptions.problem.page = 1;
        this.tagOptions.problem.nomore = false;
        this.$store.commit("medical/SET_PROBLEM", []);
        this.restoreSelect();
      }

      if (this.tagOptions.problem.page === null) {
        this.tagOptions.problem.nomore = true;
        return;
      }

      this.tagOptions.problem.loading = true;
      let response = await this.$store.dispatch("medical/getProblem", {
        query: {
          page: this.tagOptions.problem.page,
          search: this.tagOptions.problem.search
        }
      })
      this.tagOptions.problem.page = getParamFromUrlString(response.next, 'page', null);
      this.tagOptions.problem.loading = false;
    },
    async asyncFindFoodtype (query=null) {
      if (query != null && query !== this.tagOptions.foodtype.search) {
        this.tagOptions.foodtype.search = query;
        this.tagOptions.foodtype.page = 1;
        this.tagOptions.foodtype.nomore = false;
        this.$store.commit("medical/SET_FOODTYPE", []);
        this.restoreSelect();
      }

      if (this.tagOptions.foodtype.page === null) {
        this.tagOptions.foodtype.nomore = true;
        return;
      }

      this.tagOptions.foodtype.loading = true
      let response = await this.$store.dispatch("medical/getFoodtype", {
        query: {
          page: this.tagOptions.foodtype.page,
          search: this.tagOptions.foodtype.search
        }
      })
      this.tagOptions.foodtype.page = getParamFromUrlString(response.next, 'page', null);
      this.tagOptions.foodtype.loading = false;
    },
    async asyncFindDiagnosis (query=null) {
      if (query != null && query !== this.tagOptions.diagnosis.search) {
        this.tagOptions.diagnosis.search = query;
        this.tagOptions.diagnosis.page = 1;
        this.tagOptions.diagnosis.nomore = false;
        this.$store.commit("medical/SET_DIAGNOSIS", []);
        this.restoreSelect();
      }

      if (this.tagOptions.diagnosis.page === null) {
        this.tagOptions.diagnosis.nomore = true;
        return;
      }

      this.tagOptions.diagnosis.loading = true
      let response = await this.$store.dispatch("medical/getDiagnosis", {
        query: {
          page: this.tagOptions.diagnosis.page,
          search: this.tagOptions.diagnosis.search
        }
      })
      this.tagOptions.diagnosis.page = getParamFromUrlString(response.next, 'page', null);
      this.tagOptions.diagnosis.loading = false;
    },
    async restoreModel() {
      if (this.POData.problem_oriented.attachments)
        this.POData.problem_oriented.attachments.forEach(attch => {
          attch.attachmentPreview = attch.attachment
        });

      this.POData.physicalexam.weight = this.POData.physicalexam.weight && parseFloat(this.POData.physicalexam.weight) 
        ? parseFloat(this.POData.physicalexam.weight).toFixed(3) 
        : undefined;

      this.POData.physicalexam.temp = this.POData.physicalexam.temp && parseFloat(this.POData.physicalexam.temp) 
        ? parseFloat(this.POData.physicalexam.temp).toFixed(3) 
        : this.POData.physicalexam.temp;

      this.POData.physicalexam.tempF = this.POData.physicalexam.temp && parseFloat(this.POData.physicalexam.temp) 
        ? parseFloat(this.convertCToF(this.POData.physicalexam.temp)).toFixed(3) 
        : undefined;

      this.isRestored = true
    },
    restoreSelect() {

      if (this.POData.problem_oriented.problems)
        this.POData.problem_oriented.problems.forEach(obj => {
          this.$store.commit('medical/addProblem', obj);
        });
      
      if (this.POData.problem_oriented.food_types)
        this.POData.problem_oriented.food_types.forEach(obj => {
          this.$store.commit('medical/addFoodtype', obj);
        });

      if (this.POData.problem_oriented.diff_diagnosises)
        this.POData.problem_oriented.diff_diagnosises.forEach(obj => {
          this.$store.commit('medical/addDiagnosis', obj);
        });

      if (this.POData.problem_oriented.final_diagnosis)
        this.$store.commit("medical/addDiagnosis", this.POData.problem_oriented.final_diagnosis);
    }
  },

  // async updated() {
  //   console.log('updated')
  //   this.POData.physicalexam.tempF = this.POData.physicalexam.temp && parseFloat(this.POData.physicalexam.temp) 
  //       ? parseFloat(this.convertCToF(this.POData.physicalexam.temp)).toFixed(2) 
  //       : undefined;
  //   console.log(this.POData.physicalexam.temp);
  //   console.log(this.POData.physicalexam.tempF);
  // },

  async created() {
    this.POData.physicalexam.tempF = undefined;
    if (this.POData.problem_oriented.last_updated) {
      this.restoreModel()
    }
  },

  async mounted() {
		this.$nextTick(() => {

			this.restoreSelect();

      this.asyncFindProblem();
      this.asyncFindFoodtype();
      this.asyncFindDiagnosis();

		});
  }
}
</script>

<style lang="scss" scoped>
  $pale: #fde6d2;
  $normal: #f8c4c4;
  $pink: #f16d5f;
  $red: #c3423e;
  $purple: #7a4f66;
  $brown: #57302b;
  $black: #2a2726;
  $bsc: #437d99;
  $checkedSts: #e6eaee;

  .disable-ele {
    pointer-events: none;
  }
  .view-mode {
    min-height: 60px;
    margin-left: 10px;
    color: #4a4a4a;
  }
  .expand-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 33px;
    border-radius: 4px;
    border: solid 1px #e6eaee;
    background-image: linear-gradient(to top, #f4f7fa, #ffffff);
    font-family: Arial;
    font-size: 16px;
    font-weight: bold;
    color: #4a4a4a;
  }

  .content-detail {
    height: 100%;
    margin: 0;
    font-family: Arial;
    &.symptom {
      border: 2px solid #FFFFFF;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
    }
  }

  .title {
    font-family: "Kanit", sans-serif;
    font-size: 18px;
    font-weight: bold;
    color: #2198f1;
    span {
      font-family: "Kanit", sans-serif;
      font-size: 12px;
      color: #9b9b9b;
    }
  }

  .mini-text {
    margin-top: 5px;
    font-family: Arial;
    font-size: 8px;
    color: #4a4a4a;
  }
  .select-with-button {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    width:100%;
  }
  .select-with-button-content {
    margin: 10px 0;
    display: flex;
    justify-content: flex-end;
    font-family: Thonburi;
    font-size: 14px;
    color: #4a90e2;
  }
  .add-select-button {
    align-self: center;
    margin-left: 10px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }
  .camera-bg {
    width: 72px;
    height: 60px;
    border-radius: 5px;
    background-image: url('../../assets/icons/icon-camera-plus.png');
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .card-box, .radio-box, .checklist-box {
    margin: 4% 0;
    display: flex;
    height: 100%;
  }
  .card-box {
    justify-content: space-between;
  }
  .card {
    display: flex;
    flex-direction: column;
    font-family: Thonburi;
    font-size: 12px;
    font-weight: bold;
    border: none;
  }
  .card-input {
    margin: 10px 0;
    width: 125px;
  }
  .card-input-display {
    text-align: center;
    margin: 10px 0;
    width: 125px;
    min-height: 32px;
    line-height: 2rem;
    border-radius: 4px;
    border: 1px solid #EBEEF5;
  }
  .custom-radios, .bsc-radios, .bsc-pic {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    font-family: Thonburi;
    font-size: 12px;
    font-weight: bold;
    span {
      margin: 0 10px;
      width: 100px;
      height: 100px;
    }
    div {
      display: inline-block;
    }
    input[type="radio"] {
      display: none;
      + label {
        color: #333;
        font-family: Arial, sans-serif;
        font-size: 14px;
        span {
          display: inline-block;
          width: 40px;
          height: 40px;
          margin: -1px 15px 0 0;
          vertical-align: middle;
          cursor: pointer;
          border-radius: 50%;
          border: 2px solid #FFFFFF;
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
          background-repeat: no-repeat;
          background-position: center;
          text-align: center;
          line-height: 44px;
          img {
            opacity: 0;
            transition: all .3s ease;
          }
        }
      }
      &#pale + label span {
        background-color: $pale;
      }
      &#normal + label span {
        background-color: $normal;
      }
      &#pink + label span {
        background-color: $pink;
      }
      &#red + label span {
        background-color: $red;
      }
      &#purple + label span {
        background-color: $purple;
      }
      &#brown + label span {
        background-color: $brown;
      }
      &#black + label span {
        background-color: $black;
      }
      &:checked + label span img {
        opacity: 1;
      }
    }
  }
  .bsc-radios {
    input[type="radio"] {
      + label {
        span {
          margin: 0 1px;
          border-radius: 5px;
          background-color: $bsc;
        }
      }
    }
  }
  .checklist-content {
    display: flex;
    flex-direction: column;
  }
  .conclude-radios {
    div {
      display: inline-block;
    }
    input[type="radio"] {
      display: none;
      + label {
        color: #333;
        font-family: Arial, sans-serif;
        font-size: 14px;
        span {
          display: inline-block;
          width: 120px;
          height: 40px;
          margin: 0 10px;
          vertical-align: middle;
          cursor: pointer;
          border-radius: 5px;
          border: 2px solid $checkedSts;
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
          background-repeat: no-repeat;
          background-position: center;
          text-align: center;
          line-height: 44px;
          img {
            opacity: 0;
            transition: all .3s ease;
          }
        }
      }
      &:checked + label span {
          background-color: #e6eaee;
      }
    }
  }
  .image-card-container {
    width: 100%;
    height: 100%;
    margin-top: 8px;
    overflow: scroll;
    white-space: nowrap;
  }
  .image {
    width: 100%;
    display: block;
  }
  .image-card {
    width: 100%;
    max-width: 300px;
    max-height: 450px;
    overflow: scroll;
    display: inline-block;
  }
  .image-size {
    width: 100%;
    height: auto;
    max-width: 300px;
  }
</style>

<style lang="scss" scoped>
.file-previewer {
  position: relative;
}
.file-previewer-card {
  position: relative;
  display: inline-block;
  overflow: hidden;
  border: 1px solid #d9d9d9;
  width: 100%;
  height: 100%;
}

.thumbnail {
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100%;

  img {
    height: 100%;
  }
}
.pdf {
  height: 160px;
}
.thumbnail .overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
  transition: opacity 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;

  &:hover {
    opacity: 1;
  }

  > i {
    color: #fff;
    font-size: 2.4em;
    opacity: 0.8;
  }
}

.fa-file-pdf {
  font-size: 3em;
  color: #e12025;
}
.fa-times-circle {
  cursor: pointer;
  position: absolute;
  right: 2px;
  top: 2px;
  z-index: 5;
}
</style>


