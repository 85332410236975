<template>
  <div>
    <Subnavbar>
      <template slot="title">Staff</template>
      <template slot="actions">
        <button
          class="btn btn-large btn-delete"
          v-if="isEdit && staff && !staff.is_first_user"
          @click="confirmDeleteVisible = true"
        >Delete</button>
        <button
          class="btn btn-large btn-default"
          @click="$router.push({ name: 'staff' })"
        >Cancel</button>
        <button
          class="btn btn-large btn-primary"
          @click="verifySaveDetail"
        >
        {{ isEdit? "Save" : "Create" }}
        </button>
      </template>
    </Subnavbar>

    <ContentBox class="content-mini" v-if="form">
      <div class="title">{{ isEdit ? "Edit" : "Create" }} Account</div>
      <el-form ref="form" :model="form">
        <div class="subtitle">Prefix username (สร้างที่ Profile hospital)</div>
        <strong v-if="hospital && hospital.staff_username_prefix">
          {{ hospital.staff_username_prefix}}
        </strong>
        <p v-else style="color: red;">โปรดสร้าง Prefix ก่อนสร้าง Staff</p>
        <div class="subtitle" style="margin-top: 10px;">Username</div>
        <div v-if="staff && staff.is_first_user">
          <strong>{{ staff.username }}</strong>
        </div>
        <div v-else style="margin-bottom: 10px;">
          <el-input class="input" v-model="form.username" placeholder="username"></el-input>
        </div>

        <p class="password-hint text-red" v-if="staff && !staff.is_from_plan_active">
          Staff นี้ไม่สามารถลงชื่อเข้าใช้งานได้ เพราะเกินจำนวนที่ระบุไว้ในแพลนของคุณ</p>

        <div class="subtitle">Password</div>
        <div v-if="!isEdit">
          <el-input class="input" v-model="form.password" placeholder="password"></el-input>
        </div>
        <div v-else>
          <p class="password-hint">Raw passwords are not stored, so there is no way to see this 
            user's password, but you can change the password using</p>
            <el-button 
              type="primary" 
              size="mini" 
              style="float: right;"
              @click="changePasswordVisible = true">Change password</el-button>
        </div>
      </el-form>
    </ContentBox>

    <ContentBox class="content-mini" v-if="form">
      <div class="title">{{ isEdit ? "Edit" : "Create" }} Staff</div>
      <el-form ref="form" :model="form">
        <div class="subtitle">คำนำหน้า</div>
        <el-input class="input" v-model="form.title" placeholder="กรอกคำนำหน้า"></el-input>
        <div class="subtitle">ชื่อ</div>
        <el-input class="input" v-model="form.first_name" placeholder="กรอกชื่อ"></el-input>
        <div class="subtitle">นามสกุล</div>
        <el-input class="input" v-model="form.last_name" placeholder="กรอกนามสกุล"></el-input>
        <div class="subtitle">เลขที่ใบประกอบวิชาชีพ</div>
        <el-input class="input" 
          v-model="form.license_number" 
          placeholder="กรอกเลขที่ใบประกอบวิชาชีพ"
        ></el-input>
        <div class="subtitle">ที่อยู่</div>
        <el-input
            class="input textarea"
            v-model="form.address"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="กรอกที่อยู่"
            size="small"
        ></el-input>
        <div class="subtitle">เบอร์โทร</div>
        <el-input class="input" v-model="form.phone" placeholder="กรอกเบอร์โทรศัพท์"></el-input>
        <div class="subtitle">Email</div>
        <el-input class="input" v-model="form.email" autocomplete="new-password" placeholder="กรอกอีเมลล์"></el-input>
        <div class="subtitle">Role</div>
        <p v-if="staff && staff.is_first_user">{{ staff.role.name }}</p>
        <el-radio-group
          v-else
          v-model="form.role"
          class="staff-role-select">
          <el-radio v-for="role in roles"
            :key="role.code"
            :label="role.code"
            border 
            fill
            size="medium">{{ role.name }}</el-radio>
        </el-radio-group>
        <div class="subtitle">Status</div>
        <div>
            <el-radio 
            v-model="form.is_active" 
            :label="true"
            border 
            >
            Active
            </el-radio>
            <el-radio 
            v-model="form.is_active" 
            :label="false"
            border
            >
            Inactive
            </el-radio>
        </div>
      </el-form>
    </ContentBox>

    <ConfirmDialog
      :visible="showConfirmDialog"
      @cancel="showConfirmDialog = false"
      @confirm="confirmSaveDetail"
    >
      <template slot="header">
        <h1 class="title">ยืนยันการบันทึก?</h1>
      </template>
    </ConfirmDialog>

    <el-dialog title="Delete Staff" 
      :visible="confirmDeleteVisible" 
      width="500px" 
      center append-to-body 
      @close="confirmDeleteVisible = false">
        <div class="text-center">ยืนยันการลบพนักงาน</div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="confirmDeleteVisible = false">cancel</el-button>
            <el-button type="primary" @click="deleteStaff">confirm</el-button>
        </span>
    </el-dialog>

    <el-dialog title="Change Password" 
      :visible="changePasswordVisible" width="500px" 
      center append-to-body 
      @close="changePasswordVisible = false">
        <div style="margin-bottom: 20px" v-if="staff">Enter a new password for the user {{ staff.username }}</div>
        <el-form>
          <el-form-item>
            <el-input
              type="password"
              v-validate="'required'"
              name="password"
              ref="password"
              placeholder="Password"
              v-model="changePasswordForm.password"></el-input>
            <span class="error">{{ errors.first('password') }}</span>
          </el-form-item>
          <el-form-item>
            <el-input
              type="password"
              v-validate="'required|confirmed:password'"
              name="passwordConfirm"
              placeholder="Re-Password"
              v-model="changePasswordForm.passwordConfirm"></el-input>
            <span class="error">{{ errors.first('passwordConfirm') }}</span>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="changePasswordVisible = false">cancel</el-button>
            <el-button type="primary" @click="changeStaffPassword">confirm</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Subnavbar from "@/components/navigation/Subnavbar";
import ContentBox from "@/components/container/ContentBox";
import ConfirmDialog from "@/components/dialog/ConfirmDialog"

export default {
  data() {
    return {
      form: {
        role: "",
        username: "",
        password: "",
        title: "",
        first_name: "",
        last_name: "",
        license_number: "",
        address: "",
        phone: "",
        email: "",
        is_active: false
      },
      changePasswordForm: {
        password: '',
        passwordConfirm: ''
      },
      isEdit: false,
      showConfirmDialog: false,
      confirmDeleteVisible: false,
      changePasswordVisible: false
    }
  },
  components: {
    Subnavbar,
    ContentBox,
    ConfirmDialog,
  },
  computed: {
    ...mapState({
      staff: state => state.hospital.staff,
      roles: state => state.hospital.staffroles,
      hospital: state => state.hospital.profile
    }),
  },
  methods: {
    validEmail: function (email) {
      // eslint-disable-next-line
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    verifySaveDetail() {
      if (!this.hospital.staff_username_prefix) {
        this.$notify({
          message: "กรุณาเพิ่ม prefix สำหรับโรงพยาบาลได้ที่ Hospital Profile",
          type: "warning"
        })
        return;
      }
      if (this.form.username == "") {
        this.$notify({
          message: "กรุณากรอก username",
          type: "warning"
        })
        return;
      }
      if (this.isEdit == false && this.form.password == "") {
        this.$notify({
          message: "กรุณากรอก password",
          type: "warning"
        })
        return;
      }
      if (this.form.title == "") {
        this.$notify({
          message: "กรุณากรอกคำนำหน้าชื่อ",
          type: "warning"
        })
        return;
      }
      if (this.form.first_name == "") {
        this.$notify({
          message: "กรุณากรอกชื่อ",
          type: "warning"
        })
        return;
      }
      if (this.form.last_name == "") {
        this.$notify({
          message: "กรุณากรอกนามสกุล",
          type: "warning"
        })
        return;
      }
      // if (this.form.license_number == "") {
      //   this.$notify({
      //     message: "กรุณากรอกเลขที่ใบประกอบวิชาชีพ",
      //     type: "warning"
      //   })
      //   return;
      // }
      // if (this.form.address == "") {
      //   this.$notify({
      //     message: "กรุณากรอกที่อยู่",
      //     type: "warning"
      //   })
      //   return;
      // }
      if (this.form.phone == "") {
        this.$notify({
          message: "กรุณากรอกเบอร์โทรศัพท์",
          type: "warning"
        })
        return;
      }
      if (this.form.email == "") {
        this.$notify({
          message: "กรุณากรอกอีเมลล์",
          type: "warning"
        })
        return;
      }
      if (!this.validEmail(this.form.email)) {
        this.$notify({
          message: "รูปแบบอีเมลล์ไม่ถูกต้อง",
          type: "warning"
        })
        return;
      }
      // if (this.form.pin == "" && !this.isEdit) {
      //   this.$notify({
      //     message: "กรุณากรอก PIN สำหรับใช้ใน Bloodbank",
      //     type: "warning"
      //   })
      //   return;
      // }

      this.showConfirmDialog = true;
    },
    confirmSaveDetail() {
      this.isEdit? this.updateStaff() : this.createStaff();
    },
    async updateStaff() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)

        let data =  {
          role: this.form.role,
          username: this.form.username,
          title: this.form.title,
          first_name: this.form.first_name,
          last_name: this.form.last_name,
          license_number: this.form.license_number,
          address: this.form.address,
          phone: this.form.phone,
          email: this.form.email,
          is_active: this.form.is_active,
        };

        await this.$store.dispatch("hospital/updateStaff", {
          params: { id: this.$route.params.id },
          data: data,
        })
        this.$router.push({
          name: "staff"
        })
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        let message = ''
        if (error.username) {
          message = error.username[0]
        }
        await this.$store.dispatch("ui/showFetchErrorMsg", message)
      }
    },
    async changeStaffPassword() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)

        let data =  {
          password: this.changePasswordForm.password,
        };

        await this.$store.dispatch("hospital/updateStaff", {
          params: { id: this.$route.params.id },
          data: data,
        })
        this.changePasswordVisible = false;
        this.$notify({
          message: "เปลี่ยนรหัสผ่านเรียบร้อย",
          type: "success"
        })
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        let message = ''
        if (error.password) {
          message = error.password
        }
        await this.$store.dispatch("ui/showFetchErrorMsg", message)
      }
    },
    async createStaff() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        await this.$store.dispatch("hospital/createStaff", {
          data: {
            role: this.form.role,
            username: this.form.username,
            password: this.form.password,
            title: this.form.title,
            first_name: this.form.first_name,
            last_name: this.form.last_name,
            license_number: this.form.license_number,
            address: this.form.address,
            phone: this.form.phone,
            email: this.form.email,
            is_hidden: this.form.is_hidden,
            is_active: this.form.is_active,
          }
        })
        this.$router.push({
          name: "staff"
        })
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        console.log(error)
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        let message = ''
        if (error.username) {
          message = error.username
        } else {
          message = error.detail
        }
        this.showConfirmDialog = false
        await this.$store.dispatch("ui/showFetchErrorMsg", message)
      }
    },
    async deleteStaff() {
      this.confirmDeleteVisible = false;
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        await this.$store.dispatch("hospital/deleteStaff", {
          params: { id: this.staff.id },
        });
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        this.$notify({
          type: "success",
          title: "ลบพนักงานเรียบร้อยแล้ว"
        })
        this.$router.go(-1);
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorMsg");
      }
    },
  },
  async created() {
    if (this.$route.params.id != undefined) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        await this.$store.dispatch("hospital/listStaffRole")
        await this.$store.dispatch("hospital/getStaff", {
          params: { id: this.$route.params.id }
        });
        this.isEdit = true;
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg");
      }
    } else {
      try {
        this.$store.commit("hospital/SET_STAFF", null);
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        await this.$store.dispatch("hospital/listStaffRole")
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg");
      }
    }
  },
  watch: {
    staff(newValue) {
      if (newValue) {
        this.form.role = newValue.role.code
        this.form.username = newValue.username
        this.form.password = newValue.password
        this.form.title = newValue.title;
        this.form.first_name = newValue.first_name;
        this.form.last_name = newValue.last_name;
        this.form.license_number = newValue.license_number;
        this.form.address = newValue.address;
        this.form.phone = newValue.phone;
        this.form.email = newValue.email;
        this.form.is_active = newValue.is_active;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.content-mini {
  font-family: "Kanit", sans-serif;
  font-size: 14px;
  display: block;
  width: 290px;
  margin: 0 auto;
  margin-bottom: 15px;
  background: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);

  padding: 20px;
  padding-top: 0px;
}

.title {
  width: 100%;
  height: 22px;
  font-family: "Kanit", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
  margin-top: 9px;
  margin-bottom: 17px;
}

.subtitle {
  width: 100%;
  height: 18px;
  font-family: "Kanit", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
  margin-top: 0px;
  margin-bottom: 7px;
}

.input {
  display: block;
  width: 260px;
  height: 40px;
  border-radius: 5px;
  // border: solid 1px #e5e5e5;
  margin-bottom: 20px;

  &.textarea { 
    height: auto;
    clear: both;
    // resize: none;
  }
}

.button {
  width: 113px;
  height: 40px;
  border-radius: 5px;
  border: solid 1px #e5e5e5;
  display: inline-block;

  font-family: "Kanit", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: -0.3px;

  vertical-align: middle;

  img {
    width: 19px;
    height: 19px;
    object-fit: contain;
    margin-left: -8px;
    margin-right: 20px;

    display: inline;
  }

  &:last-child{
    float: right;
  }
}

.el-radio:last-child{
  float: right;
}

.required-mark {
  margin-top: -15px;
  margin-bottom: 10px;
  font-size: .9em;
}

.staff-role-select {
  .el-radio {
    margin-left: 0 !important;
    width: 100%;
  }
  margin-bottom: 20px;
}

.password-hint {
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.27px;
  color: #8e8e93;
}

.text-red {
  color: #d0021b;
}
</style>
