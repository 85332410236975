<template>
    <div class="contain">
        <el-collapse class="hidden-md-and-up" v-model="activeName">
            <el-collapse-item class="collapse-item" :title="title" name="1">
               <div class="pet-blood-timeline" v-if="timeline_items.length > 0">
                    <ul class="infinite-list" style="overflow:auto"
                        v-infinite-scroll="fetchPetBloodTimeline"
                        infinite-scroll-disabled="disabled">

                        <li v-for="(item, index) in timeline_items" :key="index" class="infinite-list-item">
                            <el-card class="box-card">
                                <el-row>
                                    <el-col class="date" :md="6" :sm="12">{{ item.date | toThaiDate }}</el-col>

                                    <el-col class="detail" :md="18" :sm="12"
                                        v-if="item.type == 'pet_petbloodgroup'">
                                        <strong>Blood Group : </strong>{{ item.detail.blood_group }}
                                    </el-col>
                                    <el-col class="detail" :md="18" :sm="12"
                                        v-if="item.type == 'bloodbank_bloodrequest'">
                                        <el-row>
                                            <el-col :span="24"><strong>Blood Request : </strong>Volume {{ new Intl.NumberFormat().format(item.detail.bloodrequestprofile.product_request[0].blood_quantity) }} ml</el-col>
                                            <el-col :span="24">Blood Type : {{ item.detail.bloodrequestprofile.product_request[0].blood_type_name }}</el-col>
                                        </el-row>
                                    </el-col>
                                    <el-col class="detail" :md="18" :sm="12"
                                        v-if="item.type == 'bloodbank_blooddonation'">
                                        <strong>Blood Donation : </strong>ID {{ item.detail.id }}&nbsp;&nbsp;{{ new Intl.NumberFormat().format(item.detail.quantity) }}ml
                                    </el-col>

                                </el-row>
                            </el-card>
                        </li>
                        <li v-if="loading" class="infinite-list-item"><el-card class="box-card text-center">Loading...</el-card></li>
                    </ul>
                </div>
                <div class="no-item" v-else>ยังไม่มีรายการ</div>
            </el-collapse-item>
        </el-collapse>

        <div class="pet-blood-timeline hidden-sm-and-down" v-if="timeline_items.length > 0">
            <el-card class="box-card list-header">
                <el-row>
                    <el-col :span="6"><strong>วันที่</strong></el-col>
                    <el-col :span="18"><strong>รายการ</strong></el-col>
                </el-row>
            </el-card>
            <ul class="infinite-list" style="overflow:auto"
                v-infinite-scroll="fetchPetBloodTimeline"
                infinite-scroll-disabled="disabled">

                <li v-for="(item, index) in timeline_items" :key="index" class="infinite-list-item">
                    <el-card class="box-card">
                        <el-row>
                            <el-col class="date" :md="6" :sm="12">{{ item.date | toThaiDate }}</el-col>

                            <el-col class="detail" :md="18" :sm="12"
                                v-if="item.type == 'pet_petbloodgroup'">
                                <strong>Blood Group : </strong>{{ item.detail.blood_group }}
                            </el-col>
                            <el-col class="detail" :md="18" :sm="12"
                                v-if="item.type == 'bloodbank_bloodrequest'">
                                <el-row>
                                    <el-col :span="24" style="margin-bottom: 4px;"><strong>Blood Request : </strong>Volume {{ new Intl.NumberFormat().format(item.detail.bloodrequestprofile.product_request[0].blood_quantity) }} ml</el-col>
                                    <el-col :span="24">Blood Type : {{ item.detail.bloodrequestprofile.product_request[0].blood_type_name }}</el-col>
                                </el-row>
                            </el-col>
                            <el-col class="detail" :md="18" :sm="12"
                                v-if="item.type == 'bloodbank_blooddonation'">
                                <strong>Blood Donation : </strong>ID {{ item.detail.id }}&nbsp;&nbsp;{{ new Intl.NumberFormat().format(item.detail.quantity) }}ml
                            </el-col>
                        </el-row>
                    </el-card>
                </li>
                <li v-if="loading" class="infinite-list-item"><el-card class="box-card text-center">Loading...</el-card></li>
            </ul>
        </div>
        <div class="no-item hidden-sm-and-down" v-else>ยังไม่มีรายการ</div>
    </div>
</template>

<script>
  export default {
    // viewer: ['owner']
    props: ["title", "petId", "viewer"],
    data() {
        return {
            id: this.petId,
            timeline_items: [],
            loading: false,
            currentPage: 1,
            totalPages: 1,
            activeName: '1' // for expand collapsing
        };
    },
    computed: {
      noMore () {
            return this.totalPages === this.currentPage;
      },
      disabled () {
            return this.loading || this.noMore
      }
    },
    methods: {
        async fetchPetBloodTimeline() {
            try {
                this.loading = true;
                var response;
                let query = {};
                if (this.currentPage < this.totalPages) {
                    this.currentPage += 1;
                }
                query["page"] = this.currentPage;

                if (this.viewer === 'owner') {
                    response = await this.$store.dispatch("owner/listPetTimelineBlood", {
                        query, params: { petId: this.id }
                    });
                } else if (this.viewer === 'public') {
                    response = await this.$store.dispatch("pet/listPublicTimelineHospitalBlood", {
                        query, params: { code: this.id }
                    });
                }

                if (this.currentPage > 1) {
                    this.timeline_items = [...this.timeline_items, ...response.results];
                } else {
                    this.timeline_items = response.results;
                }

                this.totalPages = Math.ceil(response.count / 10);
                this.loading = false;
            } catch (error) {
                this.loading = false;
                await this.$store.dispatch("ui/showFetchErrorMsg", error.detail);
            }
        }
    },
    async mounted() {
        this.fetchPetBloodTimeline();
    }
  }
</script>

<style lang="scss">
    .el-collapse-item__header {
        background-color: inherit;
        font-size: 24px;
        font-weight: bold;

        .el-collapse-item__arrow {
            font-size: 16px;
            color: #7f8fa4;
        }
    }
    .el-collapse-item__wrap {
        background-color: inherit;
    }
</style>
<style lang="scss" scoped>
    .list-header {
        border-radius: 4px;
        border: solid 1px #e6eaee;
        background-image: linear-gradient(to top, #f4f7fa, #fff);
    }
    .pet-blood-timeline {
        .infinite-list {
            width: 100%;
            height: auto;
            max-height: 200px;
            
            .infinite-list-item {
                display: flex;
                margin-bottom: 2px;
                color: #7dbcfc;
                font-family: "Roboto","Lucida Grande","DejaVu Sans","Bitstream Vera Sans",Verdana,Arial,sans-serif;

                .date {
                    @media (max-width: 767px) {
                        color: #5a5a5a;
                        margin-bottom: 8px;
                    }
                }
            }
        }
    }
    .box-card {
        width: 100%;
        padding: 8px 40px;

        @media (max-width: 767px) {
            padding: 8px 20px;
        }
    }
    ul {
        display: block;
        list-style-type: none;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 0;
    }
    .contain {
        font-size: 14px;
    }
    .no-item {
        font-family: default;
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        text-align: left;
        color: #8e8e93;
        margin: 8px 18px;
    }
</style>