<script>
import numeral from "numeral";

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },

    total: {
      type: Number,
      default: 0,
    }
  },

  computed: {
    currentPage() {
      return Math.ceil(this.items.length / 10);
    },

    totalPage() {
      return Math.ceil(this.total / 10);
    }
  },

  methods: {
    toCurrency(value) {
      return numeral(value).format('0,0.00');
    },

    view(data) {
      this.$emit("view", data);
    },

    handleCurrentChange() {
      this.$emit("pageChange", this.currentPage+1);
    },
  },

  render() {
    return (
      <div>
        <el-table data={this.items}>
          <el-table-column
            label="Date"
            prop="date"
            scopedSlots={{
              default: ({ row }) =>
                row.date.toLocaleString("th-TH", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                }),
            }}
          />
          <el-table-column
            label="Type"
            prop="type"
          />
          <el-table-column
            label="Revenue"
            prop="revenue"
            align="right"
            scopedSlots={{
              default: ({ row }) =>
                this.toCurrency(row.revenue),
            }}
          />
          <el-table-column
            prop="refId"
            width="110"
            scopedSlots={{
              default: ({ row }) => (
                <el-button 
                  size="mini"
                  onClick={() => {
                    this.view(row.refId);
                  }}>
                  See Detail
                </el-button>
              ),
            }}
          />
        </el-table>
        {(this.currentPage < this.totalPage) && (
          <el-row style={{ backgroundColor: "#fff", padding: "10px" }}>
            <el-col span={4} offset={10}>
              <el-button 
                plain
                type="primary"
                size="mini"
                onClick={() => {
                  this.handleCurrentChange();
                }}>
                  Load more..
              </el-button>
            </el-col>
          </el-row>
        )}
      </div>
    );
  },
};
</script>
