<template>
  <div :style="[containerStyle, customStyle]">
    <template v-if="src">
      <img :src="src" :style="style">
    </template>
  </div>
</template>

<script>
export default {
  props: {
    customStyle: {
      type: Object
    },
    size: {
      type: Number,
      default: 70
    },
    src: {
      type: String
    },
    rounded: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    containerStyle() {
      return {
        display: "inline-block",
        verticalAlign: "middle",
        width: `${this.size}px`,
        height: `${this.size}px`,
        borderRadius: this.rounded ? "50%" : 0,
        backgroundColor: "#eee"
      }
    },
    style() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
        border: "1px solid #eee",
        borderRadius: this.rounded ? "50%" : 0
      }
    }
  }
}
</script>
