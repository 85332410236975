<template>
  <div class="report_container">
    <div class="inner_report_container">
      <div class="chart_pane">
        <div>
          <h1 style="width: 100%; text-align: center; font-size: 24px;">Dog</h1>
          <div id="report_bloodbank_doner_dog_chart"/>
          <div class="inner_circle">
            <div class="inner_circle_top">
              Total
            </div>
            <div class="inner_circle_middle">
              {{data.dog.total}}
            </div>
          </div>
        </div>
      </div>
      <div class="table_pane">
        <table class="table">
          <tr v-for="(item, index) in data.dog.bloodgroup" :key="index">
            <th class="table_header">
              <div class="colorBlock" :style="{backgroundColor: color[index]}"></div>
              {{item.name}}
            </th>
            <td style="font-size: 14px; color: #9b9b9b;">{{item.number}}</td>
            <td style="color: #626262; padding-left: 10px; font-size: 14px;">{{item.percent}}%</td>
          </tr>
        </table>
      </div>
    </div>

    <div class="inner_report_container">
      <div class="chart_pane">
        <div>
          <h1 style="width: 100%; text-align: center; font-size: 24px;">Cat</h1>
          <div id="report_bloodbank_doner_cat_chart"/>
          <div class="inner_circle">
            <div class="inner_circle_top">
              Total
            </div>
            <div class="inner_circle_middle">
              {{data.cat.total}}
            </div>
          </div>
        </div>
      </div>
      <div class="table_pane">
        <table class="table">
          <tr v-for="(item, index) in data.cat.bloodgroup"  :key="index">
            <th class="table_header">
              <div class="colorBlock" :style="{backgroundColor: color[index]}"></div>
              {{item.name}}
            </th>
            <td style="font-size: 14px; color: #9b9b9b;">{{item.number}}</td>
            <td style="color: #626262; padding-left: 10px; font-size: 14px;">{{item.percent}}%</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { donutChartGenerator } from '../../utils/report';

export default {
  name: "BloodbankDonerSummaryChart",
  props: ['bloodbank'],
  data: function () {
    return {
      color: ['#1283a2', '#02b0e1', '#6bd6f4', '#cff2fb', '226b80']
    }
  },
  computed: {
    data: function() {
      let data = {
        dog: {
          total: 0,
          bloodgroup: []
        },
        cat: {
          total: 0,
          bloodgroup: []
        },
      }
      if(this.bloodbank && this.bloodbank.others ) {
        const dogTotal = this.bloodbank.others[0].data.doner
          .reduce((sum, item) => item.number + sum, 0);
        data.dog.total = dogTotal
        data.dog.bloodgroup = this.bloodbank.others[0].data.doner
          .map(item => ({name: item.name, number: item.number, percent: Math.round(item.number/dogTotal*100)}))
        
        const catTotal  = this.bloodbank.others[1].data.doner
          .reduce((sum, item) => item.number + sum, 0);
        data.cat.total = catTotal
        data.cat.bloodgroup = this.bloodbank.others[1].data.doner
          .map(item => ({name: item.name, number: item.number, percent: Math.round(item.number/catTotal*100)}))
      }
      return data;
    },
  },
  methods: {
    generateChart: function () {
      const dogData = this.data.dog.bloodgroup.map((item, index) => ({value: item.number, color: this.color[index]}))
      donutChartGenerator('report_bloodbank_doner_dog_chart', 180, 10, dogData);
      const catData = this.data.cat.bloodgroup.map((item, index) => ({value: item.number, color: this.color[index]}))
      donutChartGenerator('report_bloodbank_doner_cat_chart', 180, 10, catData);
    },
  },
  watch: {
    'bloodbank.others': function () {
      this.generateChart();
    },
  },
  mounted() {
    this.generateChart()
  },
}
</script>

<style lang="scss" scoped>
.report_container {
  display: flex;
  flex-direction: row;
  width: auto;
  padding-top: 20px;
}

.inner_report_container {
  display: flex;
  flex-direction: row;
  flex: 0.5;
  justify-content: space-around;
}

.chart_pane {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 280px;
  padding-top: 10px;
}

.table_pane {
  flex: 1;
  display: flex;  
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 40px;
  padding-top: 40px;
}

.inner_circle {
  position: relative;
  text-align: center;
  top: -130px;
  .inner_circle_top {
    font-size: 14px;
  }
  .inner_circle_middle {
    font-size: 34px;
    color: #02b0e1;
    position: relative;
    top: -10px;
  }
  .inner_circle_bottom {
    font-size: 14px;
    position: relative;
    top: -20px;
  }
}

.table {
  width: 100%;
  th {
    text-align: left;
  }
  td {
    text-align: right;
  }
}

.table_header {
  display: flex;
  flex-direction: row;
}

.colorBlock {
  width: 20px;
  height: 20px;
  border-width: 1px;
  border-color: #979797;
  border-style: solid;
  margin-right: 10px;
}
</style>
