<template>
  <div>
    <Subnavbar>
      <template slot="title">REPORT Visit Frequency And Expense Rate </template>
      <template slot="actions">
         <button
            class="btn btn-large btn-default"
            @click="$router.go(-1)"
          >Close</button>
      </template>
    </Subnavbar>

    <div class="content-container">
      <div class="title"><strong>{{ typeTitle }}</strong> Frequency and Expense</div>
      <hr style="margin: 24px 0; border: solid 1px #c3c3c3;">
      <div class="date-filter-form">
        <div class="title">Date for</div>
        <div class="date-filter">
          from&nbsp;&nbsp;
          <el-date-picker
            v-model="datefilter"
            type="daterange"
            :editable="false"
            :clearable="false"
            range-separator="To"
            unlink-panels
            start-placeholder="Start date"
            end-placeholder="End date"
            style="width: 320px;">
          </el-date-picker>
        </div>
        <div class="title-overview">
          <div class="data-section">
            <label v-if="type == 'pet'">Pet Active</label>
            <label v-else>Pet Owner Active</label>
            <div class="values">
              <div class="top">{{ overview.total_active | number }}</div>
              <div class="bottom">/{{ overview.total_insystem | number }}</div>
            </div>
          </div>
          <div class="data-section">
            <label>Total Revenue</label>
            <div class="values">
              <div class="top">{{ overview.total_revenue | currency }}</div>
              <div class="bottom">THB</div>
            </div>
          </div>
        </div>
      </div>

      <hr style="margin: 24px 0;">

      <el-row class="graph-container">
        <el-col class="graph" :span="12">
          <ExpenceVisitChart
            v-if="visitFrequency"
            title="Visit Frequency Rate"
            :subtitle="getExpenceFrequencyChartSubtitle('frequency')"
            :chartData="generateChartData('expence', visitFrequency)"
            :options="generateChartOptions(type)"
            :customStyles="{height: '210px', position: 'relative'}" />
        </el-col>
        <el-col class="graph" :span="12">
          <ExpenceVisitChart
            v-if="expenceRate"
            title="Expense Rate"
            :subtitle="getExpenceFrequencyChartSubtitle('expence')"
            :chartData="generateChartData('frequency', expenceRate)"
            :options="generateChartOptions(type)"
            :customStyles="{height: '210px', position: 'relative'}" />
        </el-col>
      </el-row>

      <el-row class="expence-frequency-choices">
        <el-col :span="3" class="title">Data for</el-col>

        <el-col :span="21">
          <el-row :gutter="150">

            <el-col :span="12">
              <el-row class="title">Expense</el-row>
              <el-row>
                <el-select v-model="filter.expence" placeholder="Select" v-if="expenceLabels.length > 0">
                  <el-option
                    v-for="(item, index) in expenceLabels"
                    :key="index"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </el-row>
            </el-col>

            <el-col :span="12">
              <el-row class="title">Frequency</el-row>
              <el-row>
                <el-select v-model="filter.frequency" placeholder="Select" v-if="frequencyLabels.length > 0">
                  <el-option
                    v-for="(item, index) in frequencyLabels"
                    :key="index"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </el-row>
            </el-col>

          </el-row>
        </el-col>
      </el-row>

      <el-row :gutter="8" class="expecne-frequency-overview">
        <el-col :span="6">
          <div class="item">
            <el-row v-if="type == 'pet'" class="title">Result Pet</el-row>
            <el-row v-else class="title">Result Pet owner</el-row>
            <el-row class="detail">{{ expecneFrequencyOverview.active | number }}</el-row>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="item">
            <el-row v-if="type == 'pet'" class="title">% from total pet active</el-row>
            <el-row v-else class="title">% from total pet owner active</el-row>
            <el-row class="detail">{{ expecneFrequencyOverview.active_percentage | number }}%</el-row>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="item">
            <el-row class="title">Gain Revenue</el-row>
            <el-row class="detail">{{ expecneFrequencyOverview.revenue | currency }}</el-row>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="item">
            <el-row class="title">% from period Revenue</el-row>
            <el-row class="detail">{{ expecneFrequencyOverview.revenue_percentage | number }}%</el-row>
          </div>
        </el-col>
      </el-row>

      <Panel enName="Age Range" thName="ช่วงอายุ" :customStyle="getPanelStyle()"></Panel>
      <BarChart
        v-if="ages"
        :chartData="getAgeChartData()"
        :options="getAgeChartOptions()"
        :style="{height: '240px', width: '840px', position: 'relative'}"
        style="padding: 18px 64px;" />

      <Panel v-if="type == 'pet' && expenceRate" enName="Breed" thName="พันธุ์" :customStyle="getPanelStyle()"></Panel>
      <HorizontalBarChart
        v-if="type == 'pet' && petBreeds"
        :chartData="getBreedChartData()"
        :options="getBreedChartOptions()"
        :style="{height: '240px', width: '840px', position: 'relative'}"
        style="padding: 18px 64px;" />

      <template v-if="type == 'pet'">
        <Panel enName="Pet  List" :customStyle="getPanelStyle()"></Panel>
        <PetTable 
          :results="visitExpenceObjects.results" 
          :total="visitExpenceObjects.count"
          :current-page="visitExpenceObjectsCurrentPage"
          @current-change="visitExpenceObjectsPageChange"
          style="margin: 18px 0;" />
      </template>
      <template v-else>
        <Panel enName="Pet owner  List" :customStyle="getPanelStyle()"></Panel>
        <OwnerTable 
          :results="visitExpenceObjects.results" 
          :total="visitExpenceObjects.count"
          :current-page="visitExpenceObjectsCurrentPage"
          @current-change="visitExpenceObjectsPageChange"
          style="margin: 18px 0;" />
      </template>

      <div style="margin-bottom: 120px;"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import Subnavbar from "@/components/navigation/Subnavbar"
import ContentBox from "@/components/container/ContentBox"
import Panel from "@/components/report/Panel"
import ExpenceVisitOverview from "@/components/report/ExpenceVisitOverview"
import ExpenceVisitChart from "@/components/report/ExpenceVisitChart"
import BarChart from "@/components/report/chart/Bar"
import HorizontalBarChart from "@/components/report/chart/HorizontalBar"
import PetTable from "@/components/report/PetTable"
import OwnerTable from "@/components/report/OwnerTable"
import { startToday, endToday } from '@/utils/datetime'

export default {
  components: {
    ContentBox,
    Subnavbar,
    Panel,
    ExpenceVisitOverview,
    ExpenceVisitChart,
    BarChart,
    HorizontalBarChart,
    PetTable,
    OwnerTable
  },
  data() {
    return {
      datefilter: [
        startToday(),
        endToday()
      ],
      type: '',
      typeTitle: '',
      typeLabel: '',
      visitFrequency: null,
      expenceRate: null,
      expenceLabels: [],
      frequencyLabels: [],
      filter: {
        frequency: null,
        expence: null
      },
      overview: {
        total_active: 0,
        total_insystem: 0,
        total_revenue: 0
      },
      expecneFrequencyOverview: {},
      ages: [],
      petBreeds: [],
      visitExpenceObjects: [],
      visitExpenceObjectsCurrentPage: 1
    }
  },
  computed: {
    ...mapState({
      visitOverview: state => state.report.visitoverview,
    }),
    
  },
  methods: {
    splitLabel(label) {
      var temps = []
      var start = null;
      var end = null;

      if (label.includes(' - ')) {
        temps = label.split(' - ');
        start = temps[0]
        end = temps[1].split(' ')[0];

      } else if (label.includes('> ')) {
        temps = label.split(' ')
        start = temps[1];

      } else if (label == 'ไม่มาเลย') {
        end = 0;

      } else {
        temps = label.split(' ');
        end = temps[0];
      }

      if (start) start = parseFloat(start.replace(/,/g, ''));
      if (end) end = parseFloat(end.replace(/,/g, ''));
      return [start, end]
    },
    getVisitExpenceQueryParams() {
      var _frequency = this.splitLabel(this.filter.frequency);
      var _expence = this.splitLabel(this.filter.expence);

      return {
        start: this.datefilter[0],
        end: this.datefilter[1],
        frequency_start: _frequency[0],
        frequency_end: _frequency[1],
        expence_start: _expence[0],
        expence_end: _expence[1],
      }
    },
    async fetchData() {
      if (this.$route.path.indexOf("/pet") !== -1) {
        this.fetchPetData();
      } else {
        this.fetchOwnerData();
      }
    },
    async fetchPetData() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        var query = {
          start: this.datefilter[0],
          end: this.datefilter[1],
        }

        await this.$store.dispatch("report/getExpenceVisitOverview", {
          query: { ...query, revenue_type: "pet" }
        })
        this.visitFrequency = await this.$store.dispatch("report/getPetVisitFrequency", {
          query
        })
        this.expenceRate = await this.$store.dispatch("report/getPetExpenceRate", {
          query
        })

        this.type = 'pet';
        this.typeTitle = 'PET';
        this.typeLabel = 'ตัว';
        this.overview.total_active = this.visitOverview.pet_active_total;
        this.overview.total_insystem = this.visitOverview.pet_active_total + this.visitOverview.pet_inactive_total;
        this.overview.total_revenue = this.visitOverview.total_revenue;
        this.generateFilterData();
        this.fetchVisitExpenceData();

        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        await this.$store.dispatch("ui/showFetchErrorMsg", error.detail)
      }
    },
    async fetchOwnerData() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        var query = {
          start: this.datefilter[0],
          end: this.datefilter[1],
        }

        await this.$store.dispatch("report/getExpenceVisitOverview", {
          query: { ...query, revenue_type: "owner" }
        })
        this.visitFrequency = await this.$store.dispatch("report/getOwnerVisitFrequency", {
          query
        })
        this.expenceRate = await this.$store.dispatch("report/getOwnerExpenceRate", {
          query
        })

        this.type = 'owner';
        this.typeTitle = 'PET OWNER';
        this.typeLabel = 'คน';
        this.overview.total_active = this.visitOverview.owner_active_total;
        this.overview.total_insystem = this.visitOverview.owner_active_total + this.visitOverview.owner_inactive_total;
        this.overview.total_revenue = this.visitOverview.total_revenue;
        this.generateFilterData();
        this.fetchVisitExpenceData();

        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        await this.$store.dispatch("ui/showFetchErrorMsg", error.detail)
      }
    },
    async prepareFilterVisitExpence() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        this.fetchVisitExpenceData();
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        await this.$store.dispatch("ui/showFetchErrorMsg", error.detail)
      }
    },
    async fetchVisitExpenceData() {
      var query = this.getVisitExpenceQueryParams();

      if (this.$route.path.indexOf("/pet") !== -1) {
        this.expecneFrequencyOverview = await this.$store.dispatch("report/getPetExpenceVisitSummary", {
          query
        })
        this.ages = await this.$store.dispatch("report/getPetExpenceVisitAge", {
          query
        })
        this.petBreeds = await this.$store.dispatch("report/getPetExpenceVisitBreed", {
          query
        })
        this.visitExpenceObjects = await this.$store.dispatch("report/getPetExpenceVisitPets", {
          query
        })
        this.visitExpenceObjectsCurrentPage = 1;
      } else {
        this.expecneFrequencyOverview = await this.$store.dispatch("report/getOwnerExpenceVisitSummary", {
          query
        })
        this.ages = await this.$store.dispatch("report/getOwnerExpenceVisitAge", {
          query
        })
        this.visitExpenceObjects = await this.$store.dispatch("report/getOwnerExpenceVisitOwners", {
          query
        })
        this.visitExpenceObjectsCurrentPage = 1;
      }
    },
    generateFilterData() {
      this.expenceLabels = [];
      this.frequencyLabels = [];

      this.expenceRate.forEach(function(data) {
        this.expenceLabels.push(data.label);
      }.bind(this));
      this.visitFrequency.forEach(function(data) {
        this.frequencyLabels.push(data.label);
      }.bind(this));
      this.filter.expence = this.expenceLabels[0];
      this.filter.frequency = this.frequencyLabels[0];
    },
    async visitExpenceObjectsPageChange(page) {
      this.currentPage = page;
      var query = this.getVisitExpenceQueryParams();
      query['page'] = this.currentPage;

      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        if (this.$route.path.indexOf("/pet") !== -1) {
          this.visitExpenceObjects = await this.$store.dispatch("report/getPetExpenceVisitPets", {
            query
          })
        } else {
          this.visitExpenceObjects = await this.$store.dispatch("report/getOwnerExpenceVisitOwners", {
            query
          })
        }
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        await this.$store.dispatch("ui/showFetchErrorMsg", error.detail)
      }
    },
    getPanelStyle() {
      return {
        'border-top': 'none',
        'margin': '0 -15px 0 -15px'
      }
    },
    getExpenceFrequencyChartSubtitle(chartKeyType) {
      if (chartKeyType == 'frequency') {
        return this.type == 'pet' ? 'ความถี่ในการเข้ามารับบริการของสัตว์เลี้ยง (ตัว)' : 'ความถี่ในการเข้ามารับบริการเจ้าของสัตว์ (คน)'
      } else {
        return this.type == 'pet' ? 'การใช้จ่ายของสัตว์เลี้ยง (ตัว)' : 'การใช้จ่ายของเจ้าของสัตว์ (คน)';
      }
    },
    getAgeChartData() {
      // let labels = ['ไม่ระบุ', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22'];
      // if (this.type == 'owner') {
      //   labels = ['ไม่ระบุ', '0-5', '6-10', '11-15', '16-20', '21-25', '26-30', '31-35', '36-40', '40-45', '46-50', '51-55', '56-60', '61-65', '66-70', '71-75', '76-80', '81-85', '86-90', '91-95', '96-100'];
      // }
      let labels = ['ไม่ระบุ'];
      let datas = [0];
      let size = this.type == 'pet' ? 23 : 20;
      
      for (var i=1; i<=size; i++) {

        if (this.type == 'pet') {
          labels.push(String(i-1));
        } else if (this.type == 'owner') {
          if (i == 1) {
            labels.push(i*5-5 + '-' + i*5);
          } else {
            labels.push(i*5-4 + '-' + i*5);
          }
        }

        datas.push(0);
      }

      var chartData = {
        labels: labels,
        datasets: [{
          barThickness: 9,
          maxBarThickness: 9,
          minBarLength: 0,
          hoverBackgroundColor: '#33c9f3',
          backgroundColor: '#a1e7fb',
          data: datas,
        }]
      }

      if (this.type == 'pet') {
        this.ages.forEach(function(age) {
          if (age.age == 'ไม่ระบุ') {
            chartData.datasets[0].data[0] = age.total;
          } else {
            chartData.datasets[0].data[age.age + 1] = age.total;
          }
        });
      } else {
          this.ages.forEach(function(age) {
          if (age.age == 'ไม่ระบุ') {
            chartData.datasets[0].data[0] = age.total;
          } else if (age.age == 0) {
            chartData.datasets[0].data[1] += age.total;
          } else {
            var index = Math.floor(age.age / 5);
            if (age.age % 5 !== 0) index++;

            chartData.datasets[0].data[index] += age.total;
          }
        });
      }
      return chartData;
    },
    getAgeChartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              beginAtZero: true
            },
            scaleLabel: {
              display: true,
              labelString: 'อายุ(ปี)'
            }
          }],
          yAxes: [{
            gridLines: {
              display: true
            },
            ticks: {
              display: true,
              maxTicksLimit: 5,
              beginAtZero: true
            },
            scaleLabel: {
              display: true,
              labelString: 'จำนวน(' + this.typeLabel + ')',
            }
          }]
        },
      }
    },
    getBreedChartData() {
      var chartData = {
        labels: [],
        datasets: [{
          barThickness: 16,
          maxBarThickness: 16,
          minBarLength: 2,
          backgroundColor: '#d8d8d8',
          hoverBackgroundColor: '#33c9f3',
          data: [],
        }]
      }

      this.petBreeds.forEach(function(breed) {
        chartData.labels.push(breed.breed)
        chartData.datasets[0].data.push(breed.total)
      });

      return chartData;
    },
    getBreedChartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              display: false
            },
          }],
          yAxes: [{
            gridLines: {
              display: false
            },
          }]
        },
      }
    },
    generateChartData(childKey, datas) {
      var color = (childKey == 'frequency') ? ['#204412', '#469c26', '#61db33', '#bfeaaf', '#d8d8d8'] : ['#0b4b5d', '#1686a4', '#33c9f3', '#a1e7fb', '#d8d8d8']
      var result = {
        labels: [],
        datasets: []
      };

      datas.forEach(function(data) {
        result['labels'].push(data.label);
        
        data[childKey].forEach(function(child, i) {
          if (result['datasets'][i] == null) {
            let space = (22 - child.label.length) > 0 ? 22 - child.label.length: 0;
            let label = child.label + '\xa0'.repeat(space);

            result['datasets'].push({
              // label: child.label,
              label: label,
              barPercentage: 0.5,
              data: [],
              backgroundColor: color[i]
            })
          }
          result['datasets'][i]['data'].push(child.total)
        })
      });

      return result;
    },
    generateChartOptions(primaryObjName) {
      var label_suffix = (primaryObjName == 'owner') ? 'คน' : 'ตัว';
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            ticks: {
              display: false
            },
            gridLines: {
              display: false
            }
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              display: false
            }
          }]
        },
        legend: {
          position: 'bottom',
          align: 'start',
          labels: {
            fontSize: 11,
            fontColor: '#4a4a4a',
            usePointStyle: true
          }
        },
        tooltips: {
          mode: 'label',
          titleFontSize: 10,
          bodyFontSize: 10,
          callbacks: {
            label: function (tooltipItem, data) {
                var label = data.datasets[tooltipItem.datasetIndex].label;
                var valor = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

                var total = 0;
                for (var i = 0; i < data.datasets.length; i++)
                    total += data.datasets[i].data[tooltipItem.index];

                if (tooltipItem.datasetIndex != data.datasets.length - 1) {
                    return label + '  ' + valor + ' ' + label_suffix;
                } else {
                    return [label + '  ' + valor + ' ' + label_suffix, 'รวมทั้งหมด  ' + total + ' ' + label_suffix];
                }
            }
          }
        }
      }
    }
  },
  async created() {
    if (this.$route.query.start && this.$route.query.end) {
      this.datefilter[0] = new Date(this.$route.query.start);
      this.datefilter[1] = new Date(this.$route.query.end);
    }
    this.fetchData();
  },
  watch: {
    'datefilter': function () {
      this.fetchData();

      this.$router.replace({ query: {} });
      let query = { start: this.datefilter[0].toString(), end: this.datefilter[1].toString() };
      this.$router.push({
        query: query 
      });
    },
    'filter.frequency': function() {
      this.prepareFilterVisitExpence();
    },
    'filter.expence': function() {
      this.prepareFilterVisitExpence();
    }
  },
}
</script>

<style lang="scss" scoped>
.content-container {
  padding: 15px;
  margin-bottom: 4px;
  background-color: #fff;
  font-family: "Roboto","Lucida Grande","DejaVu Sans","Bitstream Vera Sans",Verdana,Arial,sans-serif;

  .title {
    font-size: 24px;
    color: #626262;
    font-weight: normal;
    font-family: "Roboto","Lucida Grande","DejaVu Sans","Bitstream Vera Sans",Verdana,Arial,sans-serif;

    strong {
      color: #000;
    }
  }

  .expence-frequency-choices {
    color: #000;
    background-color: #f7f7f7;
    margin: 0 -15px 0 -15px;
    padding: 8px 16px 24px 24px;
    
    .title {
      font-size: 20px;
    }

    .el-select {
      width: 100%;
      height: 40px;
      border-radius: 5px;
    }
  }

  .expecne-frequency-overview {
    padding: 16px 0;

    .item {
      padding: 8px;
      border-radius: 8px;
      border: solid 1px #979797;
      background-color: #fbfbfb;

      .title {
        text-align: left;
        font-size: 14px;
        font-weight: bold;
        margin: 0;
        color: #4a4a4a;
      }
      
      .detail {
        text-align: center;
        font-size: 36px;
        font-weight: bold;
        color: #4a4a4a;
      }
    }
  }
}
.date-filter-form {
  margin-bottom: 16px;
  font-family: "Roboto","Lucida Grande","DejaVu Sans","Bitstream Vera Sans",Verdana,Arial,sans-serif;

  .title {
    font-size: 20px;
    margin-right: 34px;
    display: inline-block;
  }
  .date-filter {
    font-size: 14px;
    color: #354052;
    display: inline-block;
    margin-right: 18px;
  }

  .title-overview {
    display: inline-block;
    vertical-align: top;

    .data-section {
      border-left: 1px solid #ccc;
      padding: 0 10px 10px;
      display: inline-block;

      label {
        display: inline-block;
        width: 40%;
        font-size: 16px;
        line-height: 1.2em;
        vertical-align: top;
      }

      .values {
        display: inline-block;
        min-width: 80px;
        width: 60%;
        text-align: right;

        .top {
          font-size: 20px;
          line-height: 1em;
          font-weight: bold;
        }
        .bottom {
          font-size: 12px;
          line-height: 2em;
        }
      }
    }
  }
}

.graph-container {
  margin-bottom: 30px;

  .graph {
    padding-right: 60px;
  }
}

</style>
