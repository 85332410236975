import Payment from '@/pages/receipt/Payment.vue'
import PaymentMethodSetting from '@/pages/receipt/PaymentMethodSetting.vue'

const routes = [
  {
    path: '/payment/:id',
    name: 'payment',
    component: Payment,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin']
    }
  },
  {
    path: '/payment/method/setting',
    name: 'paymentMethodSetting',
    component: PaymentMethodSetting,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['setting_section.can_use_payment_method_setting']
    }
  },
]

export default routes