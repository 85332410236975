export default {
    setIpd(state, payload) {
        state.Ipd = payload
    },
    setIpdMedicalRecords(state, payload) {
        state.MedicalRecords = payload
    },
    setPaymentStatus(state, payload) {
        state.PaymentStatus = payload
    },

    setBranch(state, payload) {
        state.Branches = payload
    },
    setGraph(state, payload) {
        state.Graphs = payload
    },
    setTable(state, payload) {
        state.Tables = payload
    },
    setDetailTable(state, payload) {
        state.DetailTables = payload
    },
}