<template>
  <ContentBox class="doctorfee-search">
    <el-row class="custom-row">
      <el-col :md="3">
        <h2 class="title">ค่า DF ในช่วง</h2>
      </el-col>
      <el-col :md="12">
        <div class="date-group" :gutter="20">
          <el-date-picker type="date" placeholder="Start Date" size="small" v-model="form.start" @change="startDateChange"></el-date-picker>
          <span>to</span>
          <el-date-picker type="date" placeholder="End Date" size="small" v-model="form.end" @change="endDateChange"></el-date-picker>
        </div>
      </el-col>
    </el-row>
  </ContentBox>
</template>

<script>
import ContentBox from "@/components/container/ContentBox"

export default {
  props: ['startDate', 'endDate',],
  components: {
    ContentBox
  },
  data() {
    return {
      form: {
        start: this.startDate,
        end: this.endDate,
      }
    }
  },
  methods: {
    startDateChange () {
      this.$emit('search', this.form)
    },
    endDateChange (d) {
      if(d) {
        d.setHours(23,59,59,999)
        this.form.end = new Date(d)
      }
      this.$emit('search', this.form)
    },
  },
}
</script>

<style lang="scss" scoped>
.doctorfee-search {
  .title {
    margin: 0;
    padding: 0;
    font-size: 16px;
    margin-bottom: 5px;
  }

  .custom-row {
    margin-bottom: 10px;
  }

  .date-group {
    > span {
      padding: 10px;
    }
  }
}
</style>


