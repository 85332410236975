<template>
  <div class="pet-health-detail-form">
    <div :class="standalone?`form-block standalone`:`form-block`">
      <div class="header-block" v-if="standalone">
        <div class="title">{{ $route.params.id ? 'Edit' : 'Create' }} Pet Profile</div>
        <div class="lead">
          Health Details
          <br />(2/2)
        </div>
      </div>

      <el-form ref="form" :model="form">
        <el-form-item v-if="!standalone">
          <span class="form-label">HN.</span>
          <el-input placeholder="หมายเลข HN" v-model="form.hn"></el-input>
        </el-form-item>
        <el-form-item>
          <span class="form-label">ประวัติการทำหมัน</span>
          <div class="sterilize">
            <el-button v-on:click="onSetSterilize" :type="(form.sterilize) ? 'primary' : ''">
              <img class="check-select" src="@/assets/icons/icon-check-select.png" />
              ทำหมันแล้ว
            </el-button>
            <el-date-picker
              v-on:change="onSetSterilizeDate"
              v-model="form.sterilize_date"
              type="date"
              format="yyyy/MM/dd"
              value-format="yyyy-MM-dd"
              placeholder="เมื่อวันที่"
            ></el-date-picker>
          </div>
        </el-form-item>

        <el-form-item>
          <span class="form-label">รหัสไมโครชิฟ</span>
          <el-input placeholder="รหัสไมโครชิพ" v-model="form.microchip"></el-input>
          <!-- <span v-for="(item, index) in form.microchips" :key="index">
            <el-input :placeholder="(index != 0) ? `รหัสไมโครชิพ ${index+1}` : `รหัสไมโครชิพ`" v-model="form.microchips[index]">
              <el-button v-if="index != 0" class="btn-remove" slot="suffix"
                @click="removeMicrochip(index)">
                <img src="@/assets/icons/icon-delete-copy.png">
              </el-button>
            </el-input>
          </span>
          <span class="btn-add" @click="addMicrochip">
            <span >เพิ่มรหัสไมโครชิพ</span>
            <img src="@/assets/icons/icon-add.png">
          </span>-->
        </el-form-item>

        <el-form-item v-if="bloodGroups">
          <span class="form-label">กรุ๊ปเลือด</span>
          <el-select v-model="form.blood_group" placeholder="กรุณาเลือก" style="width: 100%;">
            <el-option
              v-for="(item, index) in bloodGroups"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <span class="form-label">ประวัติการแพ้ยา</span>
          <el-input
            type="textarea"
            :rows="5"
            placeholder="ประวัติการแพ้ยา"
            v-model="form.drug_allergy_detail"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <span class="form-label">เลขที่กรมธรรม์ประกันภัย</span>
          <el-input placeholder="เลขที่กรมธรรม์ประกันภัย" v-model="form.insurance_number"></el-input>
          <!-- <span v-for="(item, index) in form.insurance_numbers" :key="index">
            <el-input :placeholder="(index != 0) ? `เลขที่กรมธรรม์ประกันภัย ${index+1}` : `เลขที่กรมธรรม์ประกันภัย`" v-model="form.insurance_numbers[index]">
              <el-button v-if="index != 0" class="btn-remove" slot="suffix"
                @click="removeInsuranceNumber(index)">
                <img src="@/assets/icons/icon-delete-copy.png">
              </el-button>
            </el-input>
          </span>
          <span class="btn-add" @click="addInsuranceNumber">
            <span >เพิ่มเลขที่กรมธรรม์ประกันภัย</span>
            <img src="@/assets/icons/icon-add.png">
          </span>-->
        </el-form-item>

        <slot name="actions"></slot>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    form: {
      default: null,
      type: Object
    },
    standalone: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    ...mapState({
      types: state => state.pet.types,
      otherTypes: state =>
        state.pet.types.filter(function(type) {
          return ["DOG", "CAT"].indexOf(type.key) == -1;
        }),
      breeds: state => state.pet.breeds
    }),
    bloodGroups() {
      if (this.form.type_key == "DOG") {
        return [
          { label: "Unknown", value: "" },
          { label: "DEA 1.1 +", value: "DEA 1.1 +" },
          { label: "DEA 1.1 -", value: "DEA 1.1 -" }
        ];
      } else if (this.form.type_key == "CAT") {
        return [
          { label: "Unknown", value: "" },
          { label: "A", value: "A" },
          { label: "B", value: "B" },
          { label: "AB", value: "AB" }
        ];
      }
      return null;
    }
  },
  methods: {
    removeInsuranceNumber(i) {
      this.form.insurance_numbers.splice(i, 1);
    },
    removeMicrochip(i) {
      this.form.microchips.splice(i, 1);
    },
    addMicrochip() {
      this.form.microchips.push("");
    },
    addInsuranceNumber() {
      this.form.insurance_numbers.push("");
    },
    onSetSterilizeDate() {
      this.form.sterilize = this.form.sterilize_date != null;
    },
    onSetSterilize() {
      this.form.sterilize = !this.form.sterilize;
      this.form.sterilize_date = null;
    },
    async setType(type) {
      if (["DOG", "CAT"].indexOf(type) > -1) this.form.type_select = null;

      this.types.forEach(t => {
        if (t.key == type) this.form.type = t.id;
      });

      this.form.type_key = type;
      this.form.breed = null;

      await this.$store.dispatch("pet/listBreeds", {
        query: {
          pet_type__key: type
        }
      });
    },
    onSetType() {
      if (this.form.type_select != null) this.setType(this.form.type_select);
    }
  },
  watch: {
    "form.type_key"(newValue, oldValue) {
      if (newValue !== oldValue && oldValue !== null) {
        this.form.blood_group = "";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.form-block {
  &.standalone {
    @extend %block-container;
  }
  @extend %form-style;
  .form-submit-btn {
    width: 160px;
  }
  .el-button {
    max-height: 40px;
  }
  .sterilize {
    .el-button {
      width: 116px;
      padding: 12px;
    }
    .el-date-editor {
      float: right;
      margin-top: 0px;
      width: calc(100% - 116px - 10px);
    }
  }
  .check-select {
    vertical-align: middle;
    display: inline;
    margin-top: -3px;
    width: 19px;
    // height: 19px;
    // object-fit: ;
  }
  .btn-add {
    float: right;
    width: auto;
    height: 30px;
    // margin-top: 6px;

    span {
      font-family: Default;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.3px;
      text-align: right;
      color: #4a90e2;

      margin-right: 11px;
    }

    img {
      width: 15px;
      height: 15px;
      object-fit: contain;

      vertical-align: middle;
    }

    &:hover {
      color: #4180c7;
      cursor: pointer;
    }
  }
  .btn-remove {
    background: none;
    padding: 8px;
    border: none;
    // width: 30px;
    height: 100%;
    img {
      width: 15px;
      height: 15px;
    }
  }
  .el-input:not(first-child) {
    margin-top: 4px;
  }
}
</style>
