import ActivitySettingList from '@/pages/activity/ActivitySettingList.vue'
import ActivityCreate from '@/pages/activity/ActivityCreate.vue'
import ActivityEdit from '@/pages/activity/ActivityEdit.vue'
const ActivitySetting = () => import("@/pages/activity/ActivitySetting");
const ActivitySettingProduct = () => import("@/pages/activity/ActivitySettingProduct");
const ActivitySettingService = () => import("@/pages/activity/ActivitySettingService");
const ActivitySelectDelete = () => import("@/pages/activity/ActivitySelectDelete");
const ActivitySelectSort = () => import("@/pages/activity/ActivitySelectSort");

const routes = [
  {
    path: '/activities',
    name: 'activities',
    component: ActivitySettingList,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['setting_section.can_use_activity_setting']
    }
  },
  {
    path: '/activities/setting',
    name: 'activity-setting',
    component: ActivitySetting,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['setting_section.can_use_activity_setting']
    },
    redirect: { name: "activity-setting-product-create" },
    children: [
      {
        path: "product/create",
        name: "activity-setting-product-create",
        component: ActivitySettingProduct,
        meta: {
          canSwitch: true,
          isCreate: true,
          requiresAuth: true,
          permissions: ["hospital_admin"],
        },
      },
      {
        path: "service/create",
        name: "activity-setting-service-create",
        component: ActivitySettingService,
        meta: {
          canSwitch: true,
          isCreate: true,
          requiresAuth: true,
          permissions: ["hospital_admin"],
        },
      },
      {
        path: 'product/:id/edit',
        name: 'activity-setting-product-update',
        component: ActivitySettingProduct,
        meta: {
          canSwitch: false,
          isCreate: false,
          requiresAuth: true,
          permissions: ['hospital_admin'],
          plan_permissions: ['setting_section.can_use_activity_setting']
        }
      },
      {
        path: 'service/:id/edit',
        name: 'activity-setting-service-update',
        component: ActivitySettingService,
        meta: {
          canSwitch: false,
          isCreate: false,
          requiresAuth: true,
          permissions: ['hospital_admin'],
          plan_permissions: ['setting_section.can_use_activity_setting']
        }
      },
      {
        path: '/products/settings/create',
        name: 'proudct-setting-product-create',
        component: ActivitySettingProduct,
        meta: {
          canSwitch: false,
          isCreate: true,
          isFromProduct: true,
          requiresAuth: true,
          permissions: ['hospital_admin'],
          plan_permissions: ['setting_section.can_use_activity_setting']
        }
      },
      {
        path: '/products/settings/:id/edit',
        name: 'proudct-setting-product-update',
        component: ActivitySettingProduct,
        meta: {
          canSwitch: false,
          isCreate: false,
          isFromProduct: true,
          requiresAuth: true,
          permissions: ['hospital_admin'],
          plan_permissions: ['setting_section.can_use_activity_setting']
        }
      },
    ]
  },
  {
    path: '/activities/setting/delete/select',
    name: 'activity-setting-delete-select',
    component: ActivitySelectDelete,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['setting_section.can_use_activity_setting']
    }
  },
  {
    path: '/activities/setting/sort/select',
    name: 'activity-setting-sort-select',
    component: ActivitySelectSort,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['setting_section.can_use_activity_setting']
    }
  },
  {
    path: '/activities/:id/',
    name: 'ActivityEdit',
    component: ActivityEdit,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin']
    }
  },
]

export default routes