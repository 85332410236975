var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-table',{class:_vm.$style.table,style:({ marginBottom: '0.5rem' }),attrs:{"data":_vm.items,"header-cell-class-name":_vm.$style.cell,"cell-class-name":_vm.$style.cell,"header-row-style":{ backgroundColor: '#f5f8fa' },"row-style":{ height: '4rem' }}},[_c('el-table-column',{attrs:{"prop":"status","width":"23","class-name":_vm.$style.tagCol},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(_vm.getStatus(row.status))?_c('div',{style:({ backgroundColor: _vm.getStatus(row.status).color })},[_vm._v(" "+_vm._s(_vm.getStatus(row.status).label)+" ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"Date Admit","prop":"date","min-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('div',{style:({ fontSize: '0.8em' })},[_vm._v(" "+_vm._s(_vm._f("toThaiDate")(row.date))+" ")]),_c('div',{style:({ color: '#666' })},[_vm._v(" "+_vm._s(_vm._f("toHHMM")(row.date))+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Pet","prop":"pet","min-width":"130"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('el-row',{attrs:{"type":"flex","gutter":8,"align":"middle"}},[_c('el-col',{attrs:{"span":null}},[_c('img',{style:({
                display: 'block',
                width: '40px',
                height: '40px',
                objectFit: 'cover',
                borderRadius: '0.25rem',
              }),attrs:{"src":row.petImage,"alt":"Pet image"}})]),_c('el-col',{style:({ flex: '1' }),attrs:{"span":null}},[_c('router-link',{style:({ color: '#000', textDecoration: 'none' }),attrs:{"to":{ name:'hospitalPetProfile', params: { id: row.petID }}}},[_vm._v(_vm._s(row.petName))]),_c('div',{style:({ color: '#666' })},[_vm._v(_vm._s(row.petType))])],1)],1)]}}])}),_c('el-table-column',{attrs:{"label":"Overdue","prop":"amount","min-width":"80"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm._f("currency")(row.amount))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"id","width":"115"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('el-button',{style:({ width: '100%' }),attrs:{"size":"mini"},on:{"click":function($event){return _vm.view(row.id)}}},[_vm._v(" See Detail "),_c('i',{staticClass:"el-icon-right"})])]}}])}),_c('img',{style:({ display: 'block', margin: '0 auto', maxWidth: '300px' }),attrs:{"slot":"empty","src":require("@/assets/images/no-result/medical-record-no-result.png"),"alt":"Not found"},slot:"empty"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }