import { MessageBox } from 'element-ui';
import { saveAs } from 'file-saver';

export function getAppointmentIDFormUrl(value) {
  if (value && value !== '') {
    const reg = /^(?:http(s)?:\/\/)(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]\/(public\/)?appointment\/AP(\w+)+(\/)?$/
    const isValid = reg.test(value)
    if (isValid) {
      const arrUrl = value.split('/').filter(v => v != '')
      return arrUrl[arrUrl.length-1]
    }
  }
  return ''
}


export function getParamFromUrlString(urlString, key, defaultValue='') {
  try {
    var url = new URL(urlString);
    return url.searchParams.get(key);
  } catch (e) {
    return defaultValue;
  }
}

export function currentUserType() {
  return localStorage.getItem('user_type');
}

export const download = (data, filename, headers) => {
  if (headers['content-type'] == 'application/json') {
    const blob = new Blob([data], {type: headers['content-type']});
    const fileReader = new FileReader();
    fileReader.onload = ((event) => {
      const fr = event.target;
      const message = JSON.parse(fr.result).message.detail;

      MessageBox.alert(message, {
        type: 'warning',
        center: true
      });
    })
    fileReader.readAsText(blob);

  } else {
    saveAs(data, filename, { type: headers['content-type'] })
  }
};
