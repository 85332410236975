<template>
  <div :style="[containerStyle, customStyle]">
    <template v-if="src">
      <img :src="src" :style="style">
    </template>
    <template v-if="ownerSrc">
      <div :style="owenerStyle">
        <Avatar :src="ownerSrc" :size="size/3"/>
      </div>
    </template>
  </div>
</template>

<script>
import Avatar from "@/components/avatar/Avatar"

export default {
  props: {
    customStyle: {
      type: Object
    },
    size: {
      type: Number,
      default: 70
    },
    src: {
      type: String
    },
    rounded: {
      type: Boolean,
      default: true
    },
    ownerSrc: {
      type: String
    }
  },
  components: {
    Avatar
  },
  computed: {
    containerStyle() {
      return {
        position: "relative",
        display: "inline-block",
        verticalAlign: "middle",
        width: `${this.size}px`,
        height: `${this.size}px`,
        borderRadius: this.rounded ? "50%" : 0,
        backgroundColor: "#eee"
      }
    },
    style() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
        border: "1px solid #eee",
        borderRadius: this.rounded ? "50%" : 0
      }
    },
    owenerStyle() {
      const pos = this.size / 6 - this.size / 6
      return {
        position: "absolute",
        right: `-${pos}px`,
        bottom: `-${pos}px`
      }
    }
  }
}
</script>

