<template>
  <transition name="fade">
    <div class="modal-container" v-if="show" @click="clickModal($event)">
      <div class="modal">
        <i class="el-icon-close" style="cursor: pointer;" @click="closeModal"></i>
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: ['show'],
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
    clickModal(e) {
      if (e.target.className === 'modal-container') {
        this.$emit('closeModal')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-container {
  position: fixed;
  z-index: 1;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
}
.modal {
  position: fixed;
  overflow: scroll;
  max-height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: $white;
  border-radius: 5px;
  padding: 0;
  width: 80%;
  max-width: 800px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
