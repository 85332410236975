<template>
  <div class="pet-owner-address" v-if="addresses">
    <div class="form-block">
      <div class="card-block" v-for="(address,index) in addresses" :key="index">
        <div class="header">
          <span>Address {{ index > 0 ? index + 1 : '' }}</span>
        </div>
        <div class="contain">
          <div class="row">
            <div class="detail">
              <div class="key">ที่อยู่ / Address</div>
              <div class="value small">{{ fullAddress(address) }}</div>
            </div>
          </div>
          <LocationPicker
            :apiKey="googleApiKey"
            :initialLat="address.latitude"
            :initialLng="address.longitude"
            :lock="true"
            v-if="loaded"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { googleApiKey } from '@/constants'
import LocationPicker from '@/components/LocationPicker'

export default {
  components: {
    LocationPicker,
  },
  props: {
    addresses: {
      types: Array,
      default: null,
    },
  },
  data() {
    return {
      loaded: false,
      googleApiKey: googleApiKey,
    }
  },
  methods: {
    fullAddress(address) {
      const address1 = address.address1 != null && address.address1 != "" ? ` ${address.address1}`:``;
      const address2 = address.address2 != null && address.address2 != "" ? ` ${address.address2}`:``;
      const subdistrict = address.subdistrict != null && address.subdistrict != "" ? ` ${address.subdistrict.name_th}`:``;
      const district = address.district != null && address.district != "" ? ` ${address.district.name_th}`:``;
      const province = address.province != null && address.province != "" ? ` ${address.province.name_th}`:``;
      const zipcode = address.zipcode != null && address.zipcode != "" ? ` ${address.zipcode}`:``;
      return address1 + address2 + subdistrict + district + province + zipcode;
    },
  },
  async mounted() {
    // if (this.$store.state.owner.profile.addresses.length) {
    //   var address = this.$store.state.owner.profile.addresses[0]
    //   this.location = {
    //     lat: address.latitude || 0,
    //     lng: address.longitude || 0
    //   }
    // } else {
    //   this.location = {
    //     lat: 0,
    //     lng: 0,
    //   }
    // }
    this.loaded = true;
  }
}
</script>

<style lang="scss" scoped>
.form-block {
  @extend %block-container;
  @extend %form-style;

  background: none;
  box-shadow: none;
  padding: 0;
  margin: 0 auto;

  max-width: 400px;
}
.location-picker-container {
  // margin: -20px;
  // margin-top: 0px;
  width: 100%;
  height: 200px;
}

.detail {
  text-align: left;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0px;
  }

  .key {
    font-family: default;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    color: #8e8e93;
  }
  .value {
    font-family: default;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #030303;

    &.small {
      font-family: default;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.19;
      letter-spacing: normal;
      color: #354052;
    }

    &.line {
      border-bottom: 1px solid #e6eaee;
    }

    .sub {
      color: #8e8e93;
    }
  }
}

.card-block {
  .header {
    padding: 12px;
    border-radius: 4px 4px 0px 0px;
    border: solid 1px #e6eaee;
    background-image: linear-gradient(to top, #f4f7fa, #ffffff);

    font-family: default;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #354052;
  }
  .contain {
    padding: 0px;
    border-radius: 0px 0px 4px 4px;
    border: solid 1px #e6eaee;
    background-color: #ffffff;
    .row {
      padding: 12px;
    }
  }
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.no-item {
  font-family: default;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.7px;
  text-align: center;
  color: #8e8e93;
  margin: 72px 0px;
}

.pet-owner-address {
  margin-bottom: 10px;
}
</style>