<template>
    <el-card>
        <div class="contain">
            <el-row>
                <el-col class="text-center" :span="4">
                    <img src="@/assets/images/timeline/bloodbag.png"
                        style="width: 31px; height: 59px;" />

                </el-col>
                <el-col class="text-left" :span="14">
                    <el-row>
                        <el-col :span="24"><strong>Donation ID: {{ item.id }}</strong></el-col>
                    </el-row>
                    <el-row>
                        <el-col class="title" :span="24">
                            <strong v-if="item.quantity">{{ new Intl.NumberFormat().format(item.quantity) }} ml</strong>
                            <strong v-else>0 ml</strong>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col class="text-right" :span="6">
                    <el-row>
                        <el-col class="date" :span="24"><strong>{{ date | toThaiDate }}</strong></el-col>
                    </el-row>
                    <el-row>
                        <el-col class="normal-font gray status" :span="24">{{ item.status }}</el-col>
                    </el-row>
                </el-col>
            </el-row>
        </div>
    </el-card>
</template>

<script>
export default {
  props: ["date", "item"],
  methods: {
      getDiagnosises(diagnosises) {
          var results = [];
          diagnosises.forEach(diagnosis => results.push(diagnosis.tag));
          return results.join(', ');
      }
  }
};
</script>

<style lang="scss" scoped>
.contain {
  padding: 12px;
}
.title {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
}
.date {
    color: #156f88;
    font-size: 16px;
    margin-bottom: 8px;
}
.status {
    font-size: 12px;
}
.gray {
    color: #7f8fa4;
}
.normal-font {
    font-family: "Roboto","Lucida Grande","DejaVu Sans","Bitstream Vera Sans",Verdana,Arial,sans-serif;
}
</style>