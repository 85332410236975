export default class {
  constructor (obj) {
    if (obj != null) {
      this.id = obj.id == null ? null : obj.id
      this.crossmatchId = obj.crossmatch == null ? null : obj.crossmatch
      this.crossmatchInfo = obj.crossmatchInfo == null ? null : obj.crossmatchInfo
      this.quantity = obj.reserve_quantity == null ? '' : obj.reserve_quantity
      this.isSelected = obj.is_selected == null ? false : obj.is_selected
      this.isDirty = true
    } else {
      this.id = null
      this.crossmatchId = null
      this.crossmatchInfo = null
      this.quantity = ''
      this.isSelected = false
      this.isDirty = true
    }
  }

  toRequestJson () {
    return {
      id: this.id,
      crossmatch: this.crossmatchId,
      reserve_quantity: Number(this.quantity),
      is_selected: this.isSelected
    }
  }

  cloneFrom(obj) {
    this.id = obj.id 
    this.crossmatchId = obj.crossmatchId 
    this.crossmatchInfo = { ...obj.crossmatchInfo }
    this.quantity = obj.quantity 
    this.isSelected = obj.isSelected 
    this.isDirty = obj.isDirty 
    return this
  }
}
