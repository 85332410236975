<template>
  <el-dialog :visible="visible" width="600px" center v-on="$listeners" @close="$emit('cancel')">
    <div class="header" v-if="hasHeaderSlot">
      <slot name="header"></slot>
    </div>
    <div class="content" v-if="hasContentSlot">
      <slot name="content"></slot>
    </div>
    <span class="footer" v-if="!hasFooterSlot">
      <el-button @click="$emit('cancel')">Cancel</el-button>
      <el-button type="primary" @click="$emit('confirm')">Confirm</el-button>
    </span>
    <span class="footer" :class="{'footer-wider': footerWider}" v-if="hasFooterSlot">
      <slot name="footer"></slot>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: ["visible", "footerWider"],
  computed: {
    hasHeaderSlot() {
      return !!this.$slots.header
    },
    hasContentSlot() {
      return !!this.$slots.content
    },
    hasFooterSlot() {
      return !!this.$slots.footer
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  margin-top: -40px;
  margin-bottom: 20px;
  text-align: center;
}
.content {
  font-size: 16px;
  text-align: center;
}
.footer {
  margin-top: 30px;
  display: flex;
  justify-content: center;

  &.footer-wider {
    margin-left: -60px;
    margin-right: -60px;
  }
}
</style>
