<template>
  <div class="container no-gutter">
    <div class="row" v-if="reserveBagInfo.crossmatchInfo.bloodBagId1">
      <div class="col-md-8 col-xs-8 col-sm-8">
        <div class="row">
          <div class="col-md-4 col-xs-4 col-sm-4 flex">
            <div class="container row flex-middle flex-center">
              <el-checkbox :disabled="disabled" v-model="reserveBagInfo.isSelected"></el-checkbox>
            </div>
          </div>

          <div class="col-md-10 col-xs-10 col-sm-10 flex">
            <div class="container row flex-middle flex-start">
              <h3>{{reserveBagInfo.crossmatchInfo.bloodBagId1}}</h3>
            </div>
          </div>

          <div class="col-md-10 col-xs-10 col-sm-10 flex">
            <div class="container row flex-middle flex-center">
              <h3>{{getBloodBagInfo(reserveBagInfo.crossmatchInfo.bloodBagId1).petInfo == null ? '-' : getBloodBagInfo(reserveBagInfo.crossmatchInfo.bloodBagId1).petInfo.name}}</h3>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 col-xs-4 col-sm-4 flex"></div>

          <div class="col-md-12 col-xs-12 col-sm-12 flex">
            <label>{{getBloodBagInfo(reserveBagInfo.crossmatchInfo.bloodBagId1).bloodType == null ? '-' : getBloodBagInfo(reserveBagInfo.crossmatchInfo.bloodBagId1).bloodType}} {{getBloodBagInfo(reserveBagInfo.crossmatchInfo.bloodBagId1).quantity == null ? '-' : getBloodBagInfo(reserveBagInfo.crossmatchInfo.bloodBagId1).quantity}} ml.</label>
            <label
              style="margin-left:10px;"
            >PCV = {{getBloodBagInfo(reserveBagInfo.crossmatchInfo.bloodBagId1).pcv == null ? '-' : Number(getBloodBagInfo(reserveBagInfo.crossmatchInfo.bloodBagId1).pcv)}} %</label>
          </div>

          <div class="col-md-8 col-xs-8 col-sm-8 flex">
            <div class="container row flex-bottom flex-start"></div>
          </div>
        </div>
      </div>

      <div class="col-md-8 col-xs-8 col-sm-8">
        <div class="row">
          <div class="col-md-12 col-xs-12 col-sm-12">
            <div class="row" style="height: 27px;">
              <div class="container row flex-middle flex-start" style="margin-left: 10px;">
                <label>
                  <i
                    class="icon icon-check"
                    style="color: #68b70b;"
                    v-if="reserveBagInfo.crossmatchInfo.isMajor"
                  ></i>
                  <i
                    class="icon icon-x"
                    style="color: #d0021b;"
                    v-if="!reserveBagInfo.crossmatchInfo.isMajor"
                  ></i>
                  <span style="margin-right: 15px;">Major</span>
                  <i
                    class="icon icon-check"
                    style="color: #68b70b;"
                    v-if="reserveBagInfo.crossmatchInfo.isMinor"
                  ></i>
                  <i
                    class="icon icon-x"
                    style="color: #d0021b;"
                    v-if="!reserveBagInfo.crossmatchInfo.isMinor"
                  ></i>
                  <span>Minor</span>
                </label>
              </div>
            </div>

            <div class="row">
              <div class="container row flex-bottom flex-start"></div>
            </div>
          </div>

          <div class="col-md-12 col-xs-12 col-sm-12 flex">
            <div class="container row flex-middle flex-start">
              <el-input
                :disabled="disabled || !reserveBagInfo.isSelected"
                v-model="reserveBagInfo.quantity"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                size="small"
              ></el-input>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-8 col-xs-8 col-sm-8">
        <div class="row" style="height: 100%;">
          <div class="col-md-12 col-xs-12 col-sm-12 flex">
            <div class="container row flex-center">
              <h3>{{ this.quantityFromEquation }} ml.</h3>
            </div>
          </div>

          <div class="col-md-12 col-xs-12 col-sm-12 flex">
            <div class="container row flex-center">
              <h3>{{ this.quantityFromAi }} ml.</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "blood-request-reserve-bag",
  props: [
    "pcvValue", // From X-match
    "bloodType", // From X-match
    "quantityFromEquation", // Display as dash(-) for this point
    "quantityFromAi", // Display as dash(-) for this point
    "reserveBagInfo",
    "disabled"
  ],
  computed: {
    ...mapGetters({
      getBloodBagById: "bloodbank/getBloodBagById"
    })
  },
  methods: {
    getBloodBagInfo: function(id) {
      return this.getBloodBagById(id);
    }
  }
};
</script>

<style>
</style>
