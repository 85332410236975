<template>
  <div>
    <Subnavbar>
      <template slot="breadcrumb">
      </template>
      <template slot="title">Pet Profile List</template>
      <template slot="actions">
        <button
          class="btn btn-large btn-appointment"
          @click="$router.push({ name: 'appointment-select-pet' })"
        >
          <i class="fa fa-plus-circle"></i>Appointment
        </button>
        <button 
          class="btn btn-large btn-new-pet" 
          v-show="currentPlan.hospital_feature.can_create_owner_and_pet"
          @click="$router.push({ name: 'hospitalPetsSearch' })">
          <i class="fa fa-plus-circle"></i>New Pet
        </button>
        <button class="btn btn-large btn-calendar"  @click="$router.push({ name: 'calendar' })">
          <i class="fa fa-calendar-alt"></i>Calendar
        </button>
        <button class="btn btn-large btn-pet-profile" @click="$router.push({ name: 'hospitalPets' })">
          <i class="fa fa-list-alt"></i>Pet Profile
        </button>
      </template>
    </Subnavbar>
    <ContentBox v-if="overview" class="overview">
      <h2 class="title">Overview</h2>
      <div class="inner">
        <div class="column new-pet">
          <div class="label">
            New Pet
            <br>
            <small>This month</small>
          </div>
          <div class="value">{{ overview.new_pet | number}}</div>
        </div>
        <div class="column">
          <div class="label">Total Pet</div>
          <div class="value">{{ overview.total_pet | number}}</div>
        </div>
        <div class="column">
          <div class="label">
            <div>
              <i class="fa fa-mars"/> Male
            </div>
            <small>Pet</small>
          </div>
          <div class="value">
            {{ overview.pet_male | number}}
            <br>
            <small>{{ overview.pet_male_percentage }}</small>
          </div>
        </div>
        <div class="column">
          <div class="label">
            <div>
              <i class="fa fa-venus"/>
              Female
            </div>
            <small>Pet</small>
          </div>
          <div class="value">
            {{ overview.pet_female | number}}
            <br>
            <small>{{ overview.pet_female_percentage }}</small>
          </div>
        </div>
        <div class="column">
          <div class="label">Total Owner</div>
          <div class="value">{{ overview.total_owner | number}}</div>
        </div>
      </div>
    </ContentBox>
    <ContentBox>
      <el-row class="custom-row" :gutter="20">
        <el-col :md="12">
          <el-input
            placeholder="ชื่อสัตว์, เบอร์โทร, ชื่อเจ้าของ, อีเมล์, HN, Microchips, Pet ID, Appointment ID"
            v-model="searchText"
            class="search-input"
            size="small"
            clearable
            @keyup.enter.native="handleSearchClick"
            @clear="clearSearch"
          >
            <el-button slot="append" icon="el-icon-search" @click="handleSearchClick"></el-button>
          </el-input>
        </el-col>
        <el-col :md="4">
          <el-select
            clearable
            placeholder="Pet Type"
            v-model="selectedPetType"
            size="small"
            @change="handleSearchClick"
            @clear="selectedBreadId=''"
          >
            <el-option
              v-for="item in pettypeList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :md="4">
          <el-select
            clearable
            placeholder="Breed"
            :disabled="selectedPetType === ''"
            v-model="selectedBreadId"
            size="small"
            @change="handleSearchClick"
            @clear="selectedBreadId=''"
          >
            <el-option
              v-for="breed in breeds"
              :key="breed.id"
              :value="breed.id"
              :label="breed.name"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :md="4">
          <el-select
            clearable
            placeholder="Sex / Sterilize"
            v-model="selectedSterilize"
            size="small"
            @change="handleSearchClick"
            @clear="selectedSterilize=''"
          >
            <el-option value="male" label="ผู้ / ไม่หมัน"></el-option>
            <el-option value="female" label="เมีย / ไม่หมัน"></el-option>
            <el-option value="male_sterilize" label="ผู้ / หมัน"></el-option>
            <el-option value="female_sterilize" label="เมีย / หมัน"></el-option>
          </el-select>
        </el-col>
      </el-row>
    </ContentBox>

    <ContentBox>
      <PetListSearchResult
        :pets="petResults"
        :can-export="currentPlan.export_section.can_export_pet"
        :current-page="currentPage"
        @current-change="pageChange"
        @export-data="exportData"
      />
    </ContentBox>
  </div>
</template>

<script>
import _ from "lodash"
import { mapState } from "vuex"
import { ExportService } from "@/services"
import { download } from "@/utils"
import ContentBox from "@/components/container/ContentBox"
import Subnavbar from "@/components/navigation/Subnavbar"
import PetListSearchResult from "@/components/pet/PetListSearchResult"

export default {
  components: {
    ContentBox,
    Subnavbar,
    PetListSearchResult
  },
  data() {
    return {
      searchText: "",
      currentPage: 1,
      selectedPetType: "",
      selectedBreadId: "",
      selectedSterilize: "",
      pettypeList: [],
    }
  },
  computed: {
    ...mapState({
      overview: state => state.pet.overview,
      petResults: state => state.pet.all,
      breeds: state => state.pet.breeds,
      currentPlan: state => state.plan.currentPlan
    })
  },
  methods: {
    async fetchPetTypeData() {
      const result = await this.$store.dispatch('pet/listTypes');

      return result.map((item) => ({
        value: item.key,
        label: item.name
      }));
    },

    handleSearchClick() {
      this.search(1)
    },

    getQuery() {
      let query = {}
      if (this.searchText !== "") {
        query.search = this.searchText
      }

      if (this.selectedPetType !== "") {
        query["pet__type__key"] = this.selectedPetType
      }

      if (this.selectedBreadId !== "") {
        query["pet__breed"] = this.selectedBreadId
      }
      
      if (this.selectedSterilize !== "") {
        switch (this.selectedSterilize) {
          case "male":
            query["pet__gender"] = 1
            query["pet__sterilize"] = false
            break
          case "female":
            query["pet__gender"] = 0
            query["pet__sterilize"] = false
            break
          case "male_sterilize":
            query["pet__gender"] = 1
            query["pet__sterilize"] = true
            break
          case "female_sterilize":
            query["pet__gender"] = 0
            query["pet__sterilize"] = true
            break
        }
      }
      return query
    },

    async search(currentPage) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)

        let query = { ...{page: currentPage}, ...this.getQuery() }

        await this.$store.dispatch("pet/getOverview")
        await this.$store.dispatch("pet/getOverview")

        if (this.selectedPetType !== "") {
          await this.$store.dispatch("pet/listBreeds", {
            query: {
              pet_type__key: this.selectedPetType
            }
          })
        } else {
          this.$store.commit("pet/SET_BREEDS", null)
        }

        await this.$store.dispatch("pet/listPetProfiles", { query })

        let urlQuery = _.omit(query, "pet__gender", "pet__sterilize")
        if (this.selectedSterilize !== "") {
          urlQuery.sterilize = this.selectedSterilize
        }
        this.$router.replace({ query: urlQuery })
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        console.log(error)
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        await this.$store.dispatch("ui/showFetchErrorMsg")
      }
    },
    clearSearch() {
      this.$store.commit("pet/SET_ALL", null)
      this.$store.commit("pet/SET_BREEDS", null)
      this.searchText = ""
      this.currentPage = 1
      this.$router.replace({
        search: "",
        currentPage: 1
      })
      this.search(1)
    },
    pageChange(page) {
      this.currentPage = page
      this.search(page)
    },

    async exportData() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)

        const { data, headers } = await ExportService.exportPets(this.getQuery());
        download(data, "pets.xlsx", headers)

        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        await this.$store.dispatch("ui/showFetchErrorMsg")
      }
    },

    getQueryStringFormUrl() {
      if (this.$route.query.search) {
        this.searchText = this.$route.query.search
      }

      if (this.$route.query.page) {
        this.currentPage = parseInt(this.$route.query.page)
      }

      if (
        this.$route.query.pet__type__key &&
        this.$route.query.pet__type__key !== ""
      ) {
        this.selectedPetType = this.$route.query.pet__type__key
      }

      if (this.$route.query.pet__breed && this.$route.query.pet__breed !== "") {
        this.selectedBreadId = parseInt(this.$route.query.pet__breed)
      }

      if (this.$route.query.sterilize && this.$route.query.sterilize !== "") {
        this.selectedSterilize = this.$route.query.sterilize
      }

      this.search(this.currentPage)
    }
  },
  async mounted() {
    this.$store.commit("pet/SET_ALL", null)
    this.getQueryStringFormUrl()
    this.pettypeList = await this.fetchPetTypeData();
  }
}
</script>

<style lang="scss" scoped>
.overview {
  .title {
    padding: 0;
    margin: 0 0 5px 10px;
  }

  .label {
    display: flex;
    flex: 2;
    flex-direction: column;
    color: #666;

    small {
      margin-top: 4px;
      font-size: 0.7em;
    }
  }

  .value {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    font-size: 1.2em;
    font-weight: bold;
    line-height: 1em;

    small {
      margin-top: 4px;
      font-size: 0.6em;
    }
  }

  .inner {
    display: flex;
    flex-direction: row;

    .column {
      display: flex;
      flex-direction: row;
      flex: 1;
      height: 35px;
      padding: 5px 10px;
      border-left: 1px solid #ccc;

      &.new-pet {
        .value {
          color: $light-blue;
        }
      }

      &:first-child {
        border-left: none;
      }
    }
  }
}
</style>
