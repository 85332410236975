<template>
  <div class="report_container">
    <div class="table_pane">
      <table class="table">
        <tr>
          <th class="table_header">
            <div class="colorBlock" :style="{backgroundColor: color.maleSterilize}"></div>
            Male / Sterilize
          </th>
          <td>{{number.maleSterilize}}</td>
        </tr>
        <tr>
          <th class="table_header">
            <div class="colorBlock" :style="{backgroundColor: color.maleNonSterilize}"></div>
            Male / Non Sterilize
          </th>
          <td>{{number.maleNonSterilize}}</td>
        </tr>
        <tr>
          <th class="table_header">
            <div class="colorBlock" :style="{backgroundColor: color.femaleSterilize}"></div>
            Female / Sterilize
          </th>
          <td>{{number.femaleSterilize}}</td>
        </tr>
        <tr>
          <th class="table_header">
            <div class="colorBlock" :style="{backgroundColor: color.femaleNonSterilize}"></div>
            Female / Non Sterilize
          </th>
          <td>{{number.femaleNonSterilize}}</td>
        </tr>
      </table>
    </div>
    <div class="chart_pane">
      <div>
        <div id="report_pet_gender_chart"/>
        <div class="inner_circle">
          <div class="inner_circle_top">
            Total Pet
          </div>
          <div class="inner_circle_bottom">
            {{number.total}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { donutChartGenerator } from '../../utils/report';

export default {
  name: "PetGenderChart",
  props: ['pets', 'petType'],
  data: function () {
    return {
      color: {
        maleSterilize: "#02b0e1",
        maleNonSterilize: "#006b8a",
        femaleSterilize: "#d0025b",
        femaleNonSterilize: "#8c003d",
      }
    }
  },
  computed: {
    number: function() {
      let number = {
        total: 0,
        maleSterilize: 0,
        maleNonSterilize: 0,
        femaleSterilize: 0,
        femaleNonSterilize: 0,
      }
      if(this.pets.others && this.pets.others[this.petType] && this.pets.others[this.petType].gender) {
        number.total = this.pets.others[this.petType].gender.total;
        number.maleSterilize = this.pets.others[this.petType].gender.maleSterilize;
        number.maleNonSterilize = this.pets.others[this.petType].gender.maleNonSterilize;
        number.femaleSterilize = this.pets.others[this.petType].gender.femaleSterilize;
        number.femaleNonSterilize = this.pets.others[this.petType].gender.femaleNonSterilize;
      }
      return number;
    },
  },
  methods: {
    generateChart: function () {
      const data = [
        { value: this.number.maleSterilize, color: this.color.maleSterilize },
        { value: this.number.maleNonSterilize, color: this.color.maleNonSterilize },
        { value: this.number.femaleSterilize, color: this.color.femaleSterilize },
        { value: this.number.femaleNonSterilize, color: this.color.femaleNonSterilize },
      ]
      donutChartGenerator('report_pet_gender_chart', 190, 10, data);
    },
  },
  watch: {
    'pets.others': function () {
      this.generateChart();
    },
    'petType': function () {
      this.generateChart();
    },
  },
  mounted() {
    this.generateChart()
  },
}
</script>

<style lang="scss" scoped>
.report_container {
  display: flex;
  flex-direction: row;
  width: auto;
  justify-content: space-around;
  padding-top: 20px;
}

.chart_pane {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 220px;
  padding-top: 10px;
}

.table_pane {
  flex: 1;
  display: flex;  
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 40px;
}

.inner_circle {
  position: relative;
  text-align: center;
  top: -130px;
  .inner_circle_top {
    font-size: 14px;
  }
  .inner_circle_bottom {
    font-size: 34px;
    color: #02b0e1;
    position: relative;
    top: -10px;
  }
}
.table {
  width: 60%;
  th {
    text-align: left;
  }
  td {
    text-align: right;
  }
}
.table_header {
  display: flex;
  flex-direction: row;
}
.colorBlock {
  width: 20px;
  height: 20px;
  border-width: 1px;
  border-color: #979797;
  border-style: solid;
  margin-right: 10px;
}
</style>
