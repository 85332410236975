<template>
  <div>
    <Subnavbar>
      <template slot="title">
        Print Drug Label
      </template>
      <template slot="actions">
        <div>
          <button
            class="btn btn-large btn-delete"
            @click="$router.go(-1)"
            >
            <i class="el-icon-close"></i>Close
          </button>
        </div>
      </template>
    </Subnavbar>
    <div v-if="medicalDrugLabel">
      <div id="medical-drug-label-pdf" class="medical-drug-label-pdf">
        <div v-for="(list) in medicalDrugLabel.medicalDrugLabel" :key="list.id">
          <DrugLabelPrint 
            :medicationUse="medicalDrugLabel.medicationUse" 
            :medicationUnits="medicalDrugLabel.medicationUnits" 
            :drug="list" 
            :pet="medicalDrugLabel.pet.pet" 
            :hospital="medicalDrugLabel.hospitalInfo" 
            :hn="medicalDrugLabel.hn" 
            :nameFlag="medicalDrugLabel.nameFlag"
            :productFlag="!medicalDrugLabel.productFlag"
          ></DrugLabelPrint>
        </div>
      </div>
      <!-- <el-button class="cancel-button" @click="$router.go(-1)">Cancel</el-button> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import DrugLabelPrint from "@/components/medical/DrugLabelShow"
import Subnavbar from "@/components/navigation/Subnavbar"
import printJS from 'print-js'
import html2canvas from "html2canvas"

export default {
  components: {
    Subnavbar,
    DrugLabelPrint
  },
  data() {
    return {
      medicalDrugLabel: null,
      labelImages: []
    }
  },
  computed: {
    ...mapGetters({
      getMedicalDrugLabel: "medical/getMedicalDrugLabel"
    }),

    isIpd() {
      return this.$route.meta.ipd;
    },

    id() {
      if (this.isIpd) return this.$route.params.opdId
      return this.$route.params.id
    }
  },
  methods: {
    async fetchData(id) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        this.medicalDrugLabel = await this.getMedicalDrugLabel(id)
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg")
      }
    },
    async generateImages() {
      try {
        const lables = document.querySelector('#medical-drug-label-pdf').childNodes
        for (let i = 0; i < lables.length; i++) {
          let labelDiv = lables[i].firstChild;
          await html2canvas(labelDiv, { scale: 20 }).then(canvasimg => {
            this.labelImages.push(canvasimg.toDataURL("image/png"))
          })
        }
      } catch (error) {
        return error
      }
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    showPrintDialog() {
      const style = '@media print {html,body{height:100%;width:100%;margin:0;padding:0;} } @media print {@page { size: 70mm 50mm; } img { width: 100%; break-before: always; break-after: always;}}'
      printJS({
        printable: this.labelImages,
        type: 'image',
        header: null,
        style: style
      })
    }
  },
  async created() {
    const id = this.id

    try {
      await this.fetchData(id)
      await this.$store.dispatch("ui/setIsFullScreenLoading", true)
      await this.sleep(1000)
      await this.generateImages()
      await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      this.showPrintDialog()
    } catch (error) {
      return error
    }
  },
  watch: {
    async id(newId) {
      await this.fetchData(newId) // if id changes, will refectch data
    }
  }
}
</script>

<style lang="scss" scoped>
@media print {
  html, body {
    height:auto;
    width:100%;
    margin:0;
    padding:0;
    display:inline-block;
  }
  @page {
    size: 70mm 50mm;
    height:100%;
    width:100%;
    margin: 0;
  }
  img {
    width:100%;
    height:100%;
    display:block;
    transform:scale(.6,.6);
    -ms-transform:scale(.6,.6);
    -webkit-transform:scale(.6,.6);
  }
}
.medical-drug-label-pdf {
  display: flex;
  flex-direction: column;
}
.cancel-button {
  margin: 10px 15%;
}
</style>
