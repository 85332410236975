<template>
  <div class="file-previewer clearfix">
    <div class="file-previewer-card">
      <div class="thumbnail" @click="handlePictureCardPreview()">
        <img :src="imageUrl" />
        <div class="overlay">
          <i class="fa fa-search-plus"></i>
        </div>
      </div>
    </div>
    <i v-if="showDeleteButton" class="fa fa-times-circle" @click="handleDelete"></i>

    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img width="100%" :src="imageUrl" alt />
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String
    },
    showDeleteButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      imageUrl: this.url,
      dialogVisible: false
    };
  },
  methods: {
    handlePictureCardPreview() {
      this.dialogVisible = true;
    },
    handleDelete() {
      this.imageUrl = null;
      this.$emit("delete");
    }
  },
  watch: {
    url (newValue) {
      this.imageUrl = newValue
    }
  }
};
</script>


<style lang="scss" scoped>
.file-previewer {
  position: relative;
}
.file-previewer-card {
  position: relative;
  display: inline-block;
  overflow: hidden;
  border: 1px solid #d9d9d9;
  width: 100%;
  height: 100%;
}

.thumbnail {
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100%;

  img {
    height: 100%;
  }
}
.thumbnail .overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
  transition: opacity 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;

  &:hover {
    opacity: 1;
  }

  > i {
    color: #fff;
    font-size: 2.4em;
    opacity: 0.8;
  }
}

.fa-file-pdf {
  font-size: 3em;
  color: #e12025;
}
.fa-times-circle {
  cursor: pointer;
  position: absolute;
  right: -3px;
  top: -3px;
  z-index: 5;
}
</style>

