<script>
import numeral from "numeral";

export default {
  props: {
    amount: {
      type: Number,
      default: 0,
    },

    pricePerUnit: {
      type: Number,
      default: 0,
    },

    discount: {
      type: Number,
      default: 0,
    },

    vat: {
      type: Boolean,
      default: false,
    },

    products: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    totalPrice() {
      return this.amount * this.pricePerUnit
    },

    vatText() {
      return this.vat ? 'VAT 7%' : 'No VAT'
    }
  },

  methods: {
    toFormattedNumber(value) {
      return numeral(value).format('0,0.00')
    }
  },

  render() {
    return (
      <div>
        <p style={{ margin: "0.25rem 0" }}>
          ราคาต่อหน่วย: {this.toFormattedNumber(this.pricePerUnit)} บาท, รวมราคา:{" "}
          {this.toFormattedNumber(this.totalPrice)} บาท, ส่วนลด:{" "}
          {this.toFormattedNumber(this.discount)} บาท, {this.vatText}
        </p>
        <ul style={{ margin: "0.25rem 0" }}>
          {this.products.map((item) => (
            <li>
              {item.name} x{item.amount}
            </li>
          ))}
        </ul>
      </div>
    );
  },
};
</script>
