import SymptomChecking from './symptom-checking'
import SymptomDetail from './symptom-detail'

// enum
import OrganEnum from '@/constants/bloodbank/organ-enum'
import OrganTypeEnum from '@/constants/bloodbank/organ-type-enum'
import EyeSymptomEnum from '@/constants/bloodbank/organ-symptom-type-enum/eye-symptom-type-enum'
import SkinAndHairSymptomEnum from '@/constants/bloodbank/organ-symptom-type-enum/skin-and-hair-symptom-type-enum'
import AlimetarySystemSymptomEnum from '@/constants/bloodbank/organ-symptom-type-enum/alimetary-system-symptom-type-enum'
import EarSymptomEnum from '@/constants/bloodbank/organ-symptom-type-enum/ear-symptom-enum'
import HeartSymptomEnum from '@/constants/bloodbank/organ-symptom-type-enum/heart-symptom-enum'
import RespiratorySystemSymptomEnum from '@/constants/bloodbank/organ-symptom-type-enum/respiratory-system-symptom-enum'
import MouthTeethGillSymptomEnum from '@/constants/bloodbank/organ-symptom-type-enum/mouth-teeth-gill-symptom-enum'
import UrinateSystemAndReproduceSystemSymptomEnum from '@/constants/bloodbank/organ-symptom-type-enum/urinate-system-and-reproduce-system-symptom-enum'
import BoneAndMuscleSymptomEnum from '@/constants/bloodbank/organ-symptom-type-enum/bone-and-muscle-symptom-enum'
import LymphNodeSymptomEnum from '@/constants/bloodbank/organ-symptom-type-enum/lymph-node-symptom-enum'
import NervousSystemSymptomEnum from '@/constants/bloodbank/organ-symptom-type-enum/nervous-system-symptom-enum'
import WeightSymptomEnum from '@/constants/bloodbank/organ-symptom-type-enum/weight-symptom-type-enum'

let _eyeSymptom = [
	EyeSymptomEnum.OPHTHALMITIS,
	EyeSymptomEnum.CORNEAL_OPACITY,
	EyeSymptomEnum.ULCERATE,
	EyeSymptomEnum.OCULAR_HYPERTENSION
]

let _skinAndHairSysmptom = [
	SkinAndHairSymptomEnum.EXCORIATION,
	SkinAndHairSymptomEnum.OILY_SKIN,
	SkinAndHairSymptomEnum.HAIR_LOSE,
	SkinAndHairSymptomEnum.RASH,
	SkinAndHairSymptomEnum.TICK
]

let _alimetarySystemSymptom = [
	AlimetarySystemSymptomEnum.DIARRHEA,
	AlimetarySystemSymptomEnum.VOMIT,
	AlimetarySystemSymptomEnum.CONSTIPATION
]

let _earSymptom = [
	EarSymptomEnum.INAUDIBLE,
	EarSymptomEnum.EARWAX,
	EarSymptomEnum.SMELL,
	EarSymptomEnum.OTITIS,
	EarSymptomEnum.MITE
]

let _heartSymptom = [
	HeartSymptomEnum.DISTURBANCE_SOUND,
	HeartSymptomEnum.ARRHYTHMIA,
	HeartSymptomEnum.ENLARGED_HEART
]

let _respiratorySystemSymptom = [
	RespiratorySystemSymptomEnum.GASPING,
	RespiratorySystemSymptomEnum.COUGH,
	RespiratorySystemSymptomEnum.SNIVEL
]

let _mouthTeethGillSymptom = [
	MouthTeethGillSymptomEnum.SMELL,
	MouthTeethGillSymptomEnum.GINGIVITIS,
	MouthTeethGillSymptomEnum.DENTAL_CALCULUS,
	MouthTeethGillSymptomEnum.DECAYED_TOOTH
]

let _urinateSystemAndReproduceSystemSymptom = [
	UrinateSystemAndReproduceSystemSymptomEnum.GUARDED,
	UrinateSystemAndReproduceSystemSymptomEnum.TUMOR,
	UrinateSystemAndReproduceSystemSymptomEnum.FROWN
]

let _boneAndMuscleSymptom = [
	BoneAndMuscleSymptomEnum.LIMP,
	BoneAndMuscleSymptomEnum.OUT_OF_SHAPE,
	BoneAndMuscleSymptomEnum.DETERIORATE
]

let _lymphNodeSymptom = [
	LymphNodeSymptomEnum.INTUMESCE,
	LymphNodeSymptomEnum.PAIN,
	LymphNodeSymptomEnum.INFLAME
]

let _nervousSystemSymptom = [
	NervousSystemSymptomEnum.CONVULSE,
	NervousSystemSymptomEnum.WEAK,
	NervousSystemSymptomEnum.PARALYSIS
]

// let _weightSymptom = [
//   WeightSymptomEnum.OBESE,
//   WeightSymptomEnum.SKINNY
// ]let _weightSymptom = [
//   WeightSymptomEnum.OBESE,
//   WeightSymptomEnum.SKINNY
// ]

let _organList = {
	couple: {
		organType: OrganTypeEnum.COUPLE,
		organList: [OrganEnum.EYE, OrganEnum.EAR]
	},
	single: {
		organType: OrganTypeEnum.SINGLE,
		organList: [
			OrganEnum.SKIN_AND_HAIR,
			OrganEnum.ALIMETARY_SYSTEM,
			OrganEnum.HEART,
			OrganEnum.RESPIRATORY_SYSTEM,
			OrganEnum.MOUTH_TEETH_GILL,
			OrganEnum.URINATE_SYSTEM_AND_REPRODUCE_SYSTEM,
			OrganEnum.BONE_AND_MUSCLE,
			OrganEnum.LYMPH_NODE,
			OrganEnum.NERVOUS_SYSTEM
		]
	}
}

let _symptomList = [
	{
		organName: OrganEnum.EYE,
		symptomList: _eyeSymptom
	},
	{
		organName: OrganEnum.SKIN_AND_HAIR,
		symptomList: _skinAndHairSysmptom
	},
	{
		organName: OrganEnum.ALIMETARY_SYSTEM,
		symptomList: _alimetarySystemSymptom
	},
	{
		organName: OrganEnum.EAR,
		symptomList: _earSymptom
	},
	{
		organName: OrganEnum.HEART,
		symptomList: _heartSymptom
	},
	{
		organName: OrganEnum.RESPIRATORY_SYSTEM,
		symptomList: _respiratorySystemSymptom
	},
	{
		organName: OrganEnum.MOUTH_TEETH_GILL,
		symptomList: _mouthTeethGillSymptom
	},
	{
		organName: OrganEnum.URINATE_SYSTEM_AND_REPRODUCE_SYSTEM,
		symptomList: _urinateSystemAndReproduceSystemSymptom
	},
	{
		organName: OrganEnum.BONE_AND_MUSCLE,
		symptomList: _boneAndMuscleSymptom
	},
	{
		organName: OrganEnum.LYMPH_NODE,
		symptomList: _lymphNodeSymptom
	},
	{
		organName: OrganEnum.NERVOUS_SYSTEM,
		symptomList: _nervousSystemSymptom
	}
]
export default class {
	constructor(obj) {
		this.symptomCheckingList = this.addSymptomCheckingList()
		if (obj != null) {
			this.convertMedicalRecordFromServiceToSymptomCheckingList(obj)
		}
	}

	addSymptomCheckingList() {
		var sysmptomCheckingList = []
		for (var key in _organList) {
			if (_organList.hasOwnProperty(key)) {
				for (var i = 0; i < _organList[key].organList.length; i++) {
					sysmptomCheckingList.push(
						this.createObjectForSymptomChecking(
							_organList[key].organList[i],
							_organList[key].organType
						)
					)
				}
			}
		}
		return sysmptomCheckingList
	}

	createObjectForSymptomChecking(organName, organType) {
		var objPattern = null
		var objPatternSymptomDetail = null
		if (organType === OrganTypeEnum.COUPLE) {
			objPattern = {
				organName: organName,
				organType: OrganTypeEnum.COUPLE,
				isNormal: 'normal',
				symptomDetail: [],
				note: null
			}
		} else {
			objPattern = {
				organName: organName,
				organType: OrganTypeEnum.SINGLE,
				isNormal: 'normal',
				symptomDetail: [],
				note: null
			}
		}
		for (var i = 0; i < _symptomList.length; i++) {
			if (_symptomList[i].organName === objPattern.organName) {
				var symptomList = _symptomList[i].symptomList
				for (var j = 0; j < symptomList.length; j++) {
					if (organType === OrganTypeEnum.COUPLE) {
						objPatternSymptomDetail = {
							symptomName: symptomList[j],
							status: { left: false, right: false }
						}
					} else {
						objPatternSymptomDetail = {
							symptomName: symptomList[j],
							status: { single: false }
						}
					}
					objPattern.symptomDetail.push(
						new SymptomDetail(objPatternSymptomDetail)
					)
				}
				break
			}
		}

		return new SymptomChecking(objPattern)
	}

	convertMedicalRecordFromServiceToSymptomCheckingList(medicalRecord) {
		this.symptomCheckingList.forEach(item => {
			if (item.organName === OrganEnum.EYE) {
				this.updateEyeSymptom(item, medicalRecord)
			} else if (item.organName === OrganEnum.EAR) {
				this.updateEarSymptom(item, medicalRecord)
			} else if (item.organName === OrganEnum.MOUTH_TEETH_GILL) {
				this.updateMouthTeethGillSymptom(item, medicalRecord)
			} else if (item.organName === OrganEnum.SKIN_AND_HAIR) {
				this.updateSkinSymptom(item, medicalRecord)
			} else if (item.organName === OrganEnum.ALIMETARY_SYSTEM) {
				this.updateAlimetarySymptom(item, medicalRecord)
			} else if (
				item.organName === OrganEnum.URINATE_SYSTEM_AND_REPRODUCE_SYSTEM
			) {
				this.updateUrinateSymptom(item, medicalRecord)
			} else if (item.organName === OrganEnum.RESPIRATORY_SYSTEM) {
				this.updateRespiratorySymptom(item, medicalRecord)
			} else if (item.organName === OrganEnum.BONE_AND_MUSCLE) {
				this.updateBoneSymptom(item, medicalRecord)
			} else if (item.organName === OrganEnum.HEART) {
				this.updateHeartSymptom(item, medicalRecord)
			} else if (item.organName === OrganEnum.LYMPH_NODE) {
				this.updateLymphSymptom(item, medicalRecord)
			} else if (item.organName === OrganEnum.NERVOUS_SYSTEM) {
				this.updateNervousSymptom(item, medicalRecord)
			} else if (item.organName === OrganEnum.WEIGHT) {
				this.updateWeightSymptom(item, medicalRecord)
			}
		})
	}

	updateEyeSymptom(eyeSymptom, medicalRecord) {
		if (
			medicalRecord.is_ophthalmitis !== 0 ||
			medicalRecord.is_corneal_opacity !== 0 ||
			medicalRecord.is_ulcerate !== 0 ||
			medicalRecord.is_hypertension !== 0
		) {
			eyeSymptom.isNormal = 'abnormal'
		} else {
			eyeSymptom.isNormal = 'normal'
		}
		eyeSymptom.symptomDetail.forEach(symptom => {
			let value = null
			if (symptom.symptomName === EyeSymptomEnum.OPHTHALMITIS) {
				value = medicalRecord.is_ophthalmitis
			} else if (symptom.symptomName === EyeSymptomEnum.CORNEAL_OPACITY) {
				value = medicalRecord.is_corneal_opacity
			} else if (symptom.symptomName === EyeSymptomEnum.ULCERATE) {
				value = medicalRecord.is_ulcerate
			} else if (symptom.symptomName === EyeSymptomEnum.OCULAR_HYPERTENSION) {
				value = medicalRecord.is_hypertension
			}
			switch (value) {
				case 0:
					symptom.status.left = false
					symptom.status.right = false
					break
				case 1:
					symptom.status.left = true
					symptom.status.right = false
					break
				case 2:
					symptom.status.left = false
					symptom.status.right = true
					break
				case 3:
					symptom.status.left = true
					symptom.status.right = true
					break
			}
		})
		eyeSymptom.note = medicalRecord.eye_note
	}

	updateEarSymptom(earSymptom, medicalRecord) {
		if (
			medicalRecord.is_inaudible !== 0 ||
			medicalRecord.is_earwax !== 0 ||
			medicalRecord.is_ear_smelly !== 0 ||
			medicalRecord.is_otitis !== 0 ||
			medicalRecord.is_mite !== 0
		) {
			earSymptom.isNormal = 'abnormal'
		} else {
			earSymptom.isNormal = 'normal'
		}

		earSymptom.symptomDetail.forEach(symptom => {
			let value = null
			if (symptom.symptomName === EarSymptomEnum.INAUDIBLE) {
				value = medicalRecord.is_inaudible
			} else if (symptom.symptomName === EarSymptomEnum.EARWAX) {
				value = medicalRecord.is_earwax
			} else if (symptom.symptomName === EarSymptomEnum.SMELL) {
				value = medicalRecord.is_ear_smelly
			} else if (symptom.symptomName === EarSymptomEnum.OTITIS) {
				value = medicalRecord.is_otitis
			} else if (symptom.symptomName === EarSymptomEnum.MITE) {
				value = medicalRecord.is_mite
			}
			switch (value) {
				case 0:
					symptom.status.left = false
					symptom.status.right = false
					break
				case 1:
					symptom.status.left = true
					symptom.status.right = false
					break
				case 2:
					symptom.status.left = false
					symptom.status.right = true
					break
				case 3:
					symptom.status.left = true
					symptom.status.right = true
					break
			}
		})
		earSymptom.note = medicalRecord.ear_note
	}

	updateMouthTeethGillSymptom(mouthSymptom, medicalRecord) {
		if (
			medicalRecord.is_mouth_teeth_gill_smelly ||
			medicalRecord.is_gingivitis ||
			medicalRecord.is_dental_calculas ||
			medicalRecord.is_decayed_tooth
		) {
			mouthSymptom.isNormal = 'abnormal'
		} else {
			mouthSymptom.isNormal = 'normal'
		}
		mouthSymptom.symptomDetail.forEach(symptom => {
			if (symptom.symptomName === MouthTeethGillSymptomEnum.SMELL) {
				symptom.status.single = medicalRecord.is_mouth_teeth_gill_smelly
			} else if (symptom.symptomName === MouthTeethGillSymptomEnum.GINGIVITIS) {
				symptom.status.single = medicalRecord.is_gingivitis
			} else if (
				symptom.symptomName === MouthTeethGillSymptomEnum.DENTAL_CALCULUS
			) {
				symptom.status.single = medicalRecord.is_dental_calculus
			} else if (
				symptom.symptomName === MouthTeethGillSymptomEnum.DECAYED_TOOTH
			) {
				symptom.status.single = medicalRecord.is_decayed_tooth
			}
		})
		mouthSymptom.note = medicalRecord.mouth_teeth_gill_note
	}

	updateSkinSymptom(skinSymptom, medicalRecord) {
		if (
			medicalRecord.is_excoriation ||
			medicalRecord.is_oily_skin ||
			medicalRecord.is_hair_lose ||
			medicalRecord.is_rash ||
			medicalRecord.is_tick
		) {
			skinSymptom.isNormal = 'abnormal'
		} else {
			skinSymptom.isNormal = 'normal'
		}
		skinSymptom.symptomDetail.forEach(symptom => {
			if (symptom.symptomName === SkinAndHairSymptomEnum.EXCORIATION) {
				symptom.status.single = medicalRecord.is_excoriation
			} else if (symptom.symptomName === SkinAndHairSymptomEnum.OILY_SKIN) {
				symptom.status.single = medicalRecord.is_oily_skin
			} else if (symptom.symptomName === SkinAndHairSymptomEnum.HAIR_LOSE) {
				symptom.status.single = medicalRecord.is_hair_lose
			} else if (symptom.symptomName === SkinAndHairSymptomEnum.RASH) {
				symptom.status.single = medicalRecord.is_rash
			} else if (symptom.symptomName === SkinAndHairSymptomEnum.TICK) {
				symptom.status.single = medicalRecord.is_tick
			}
		})
		skinSymptom.note = medicalRecord.skin_and_hair_note
	}

	updateAlimetarySymptom(alimetarySymptom, medicalRecord) {
		if (
			medicalRecord.is_diarrhea ||
			medicalRecord.is_vomit ||
			medicalRecord.is_constipation
		) {
			alimetarySymptom.isNormal = 'abnormal'
		} else {
			alimetarySymptom.isNormal = 'normal'
		}
		alimetarySymptom.symptomDetail.forEach(symptom => {
			if (symptom.symptomName === AlimetarySystemSymptomEnum.DIARRHEA) {
				symptom.status.single = medicalRecord.is_diarrhea
			} else if (symptom.symptomName === AlimetarySystemSymptomEnum.VOMIT) {
				symptom.status.single = medicalRecord.is_vomit
			} else if (
				symptom.symptomName === AlimetarySystemSymptomEnum.CONSTIPATION
			) {
				symptom.status.single = medicalRecord.is_constipation
			}
		})
		alimetarySymptom.note = medicalRecord.alimetary_system_note
	}

	updateUrinateSymptom(urinateSymptom, medicalRecord) {
		if (
			medicalRecord.is_guarded ||
			medicalRecord.is_tumor ||
			medicalRecord.is_frown
		) {
			urinateSymptom.isNormal = 'abnormal'
		} else {
			urinateSymptom.isNormal = 'normal'
		}
		urinateSymptom.symptomDetail.forEach(symptom => {
			if (
				symptom.symptomName ===
				UrinateSystemAndReproduceSystemSymptomEnum.GUARDED
			) {
				symptom.status.single = medicalRecord.is_guarded
			} else if (
				symptom.symptomName === UrinateSystemAndReproduceSystemSymptomEnum.TUMOR
			) {
				symptom.status.single = medicalRecord.is_tumor
			} else if (
				symptom.symptomName === UrinateSystemAndReproduceSystemSymptomEnum.FROWN
			) {
				symptom.status.single = medicalRecord.is_frown
			}
		})
		urinateSymptom.note = medicalRecord.urinate_and_reproduce_system_note
	}

	updateRespiratorySymptom(respiratorySymptom, medicalRecord) {
		if (
			medicalRecord.is_gasping ||
			medicalRecord.is_cough ||
			medicalRecord.is_snivel
		) {
			respiratorySymptom.isNormal = 'abnormal'
		} else {
			respiratorySymptom.isNormal = 'normal'
		}
		respiratorySymptom.symptomDetail.forEach(symptom => {
			if (symptom.symptomName === RespiratorySystemSymptomEnum.GASPING) {
				symptom.status.single = medicalRecord.is_gasping
			} else if (symptom.symptomName === RespiratorySystemSymptomEnum.COUGH) {
				symptom.status.single = medicalRecord.is_cough
			} else if (symptom.symptomName === RespiratorySystemSymptomEnum.SNIVEL) {
				symptom.status.single = medicalRecord.is_snivel
			}
		})
		respiratorySymptom.note = medicalRecord.respiratory_system_note
	}

	updateBoneSymptom(boneSymptom, medicalRecord) {
		if (
			medicalRecord.is_limp ||
			medicalRecord.is_out_of_shape ||
			medicalRecord.is_deteriorate
		) {
			boneSymptom.isNormal = 'abnormal'
		} else {
			boneSymptom.isNormal = 'normal'
		}
		boneSymptom.symptomDetail.forEach(symptom => {
			if (symptom.symptomName === BoneAndMuscleSymptomEnum.LIMP) {
				symptom.status.single = medicalRecord.is_limp
			} else if (
				symptom.symptomName === BoneAndMuscleSymptomEnum.OUT_OF_SHAPE
			) {
				symptom.status.single = medicalRecord.is_out_of_shape
			} else if (symptom.symptomName === BoneAndMuscleSymptomEnum.DETERIORATE) {
				symptom.status.single = medicalRecord.is_deteriorate
			}
		})
		boneSymptom.note = medicalRecord.bone_and_muscle_note
	}

	updateHeartSymptom(heartSymptom, medicalRecord) {
		if (
			medicalRecord.is_disturbance_sound ||
			medicalRecord.is_arrhythmia ||
			medicalRecord.is_enlarged_heart
		) {
			heartSymptom.isNormal = 'abnormal'
		} else {
			heartSymptom.isNormal = 'normal'
		}
		heartSymptom.symptomDetail.forEach(symptom => {
			if (symptom.symptomName === HeartSymptomEnum.DISTURBANCE_SOUND) {
				symptom.status.single = medicalRecord.is_disturbance_sound
			} else if (symptom.symptomName === HeartSymptomEnum.ARRHYTHMIA) {
				symptom.status.single = medicalRecord.is_arrhythmia
			} else if (symptom.symptomName === HeartSymptomEnum.ENLARGED_HEART) {
				symptom.status.single = medicalRecord.is_enlarged_heart
			}
		})
		heartSymptom.note = medicalRecord.heart_note
	}

	updateLymphSymptom(lymphSymptom, medicalRecord) {
		if (
			medicalRecord.is_intumesce ||
			medicalRecord.is_pain ||
			medicalRecord.is_inflame
		) {
			lymphSymptom.isNormal = 'abnormal'
		} else {
			lymphSymptom.isNormal = 'normal'
		}
		lymphSymptom.symptomDetail.forEach(symptom => {
			if (symptom.symptomName === LymphNodeSymptomEnum.INTUMESCE) {
				symptom.status.single = medicalRecord.is_intumesce
			} else if (symptom.symptomName === LymphNodeSymptomEnum.PAIN) {
				symptom.status.single = medicalRecord.is_pain
			} else if (symptom.symptomName === LymphNodeSymptomEnum.INFLAME) {
				symptom.status.single = medicalRecord.is_inflame
			}
		})
		lymphSymptom.note = medicalRecord.lymph_node_note
	}

	updateNervousSymptom(nervousSymptom, medicalRecord) {
		if (
			medicalRecord.is_convulse ||
			medicalRecord.is_weak ||
			medicalRecord.is_paralysis
		) {
			nervousSymptom.isNormal = 'abnormal'
		} else {
			nervousSymptom.isNormal = 'normal'
		}
		nervousSymptom.symptomDetail.forEach(symptom => {
			if (symptom.symptomName === NervousSystemSymptomEnum.CONVULSE) {
				symptom.status.single = medicalRecord.is_convulse
			} else if (symptom.symptomName === NervousSystemSymptomEnum.WEAK) {
				symptom.status.single = medicalRecord.is_weak
			} else if (symptom.symptomName === NervousSystemSymptomEnum.PARALYSIS) {
				symptom.status.single = medicalRecord.is_paralysis
			}
		})
		nervousSymptom.note = medicalRecord.nervous_system_note
	}

	updateWeightSymptom(weightSymptom, medicalRecord) {
		if (medicalRecord.is_weightNormal) {
			weightSymptom.isNormal = 'normal'
		} else {
			weightSymptom.isNormal = 'abnormal'
		}
		weightSymptom.symptomDetail.forEach(symptom => {
			if (symptom.symptomName === WeightSymptomEnum.OBESE) {
				symptom.status.single = medicalRecord.is_obese
			} else if (symptom.symptomName === WeightSymptomEnum.SKINNY) {
				symptom.status.single = medicalRecord.is_skinny
			}
		})
		weightSymptom.note = medicalRecord.weight_note
	}

	cloneFrom(obj) {
		// alert('Clone sym')
		this.symptomCheckingList = JSON.parse(
			JSON.stringify(obj.symptomCheckingList)
		)
	}
}
