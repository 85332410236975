<template>
  <div class="form-block">
    <div class="header-block">
      <div class="title">
        Forget Your Password?
      </div>
      <div class="lead">
        กรุณากรอกเบอร์โทรศัพท์มือถือ ที่ใช้เป็น<br>Username ของคุณ
      </div>
    </div>
    <el-form ref="form" :model="form">
      <el-form-item>
        <el-input
          class="pet-owner-register"
          prefix-icon="far fa-user"
          type="tel"
          v-validate="'required'"
          name="telephone"
          placeholder="Telephone"
          v-model="form.telephone"></el-input>
        <span class="error">{{ errors.first('telephone') }}</span>
      </el-form-item>

      <el-form-item class="text-center">
        <el-button v-if="!isNotVerify"
          :loading="submitButtonLoading"
          type="submit"
          class="btn btn-primary form-submit-btn btn-custom"
          @click="onSubmit">Done</el-button>
        <span class="error">{{ errMessage }}</span>
        <el-button v-if="isNotVerify"
          :loading="submitButtonLoading"
          type="submit"
          class="btn btn-primary form-submit-btn btn-custom"
          @click="onGetOTP">Get OTP</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        telephone: '',
      },
      isNotVerify: false,
      errMessage: '',
      submitButtonLoading: false,
    }
  },
  methods: {
    async onGetOTP() {
      await this.$store.dispatch('owner/createPasswordOTP', {
        data: {
          phone: this.form.telephone,
        }
      })
      this.$message('VRemind verification code sent. รหัสจะหมดอายุใน 5 นาที)')
      this.$router.push({ name: 'petOwnerForgetPasswordOTP', 
        params: {
          phone: this.form.telephone
        },
        query: {
          verify_code: 'owner_create_password'
        }
      })
    },
    async onSubmit() {
      this.submitButtonLoading = true
      const valid = await this.$validator.validateAll()
      if (valid) {
        try {
          const result = await this.$store.dispatch('owner/ownerForgetPassword', {
            data: {
              phone: this.form.telephone,
            }
          });
          
          if (result.status == "error") {
            this.errMessage = 'ไม่พบเบอร์นี้ในระบบกรุณาลงทะเบียน'
          } else {
            this.$message('VRemind verification code sent. (รหัสจะหมดอายุใน 5 นาที)')
            this.$router.push({ name: 'petOwnerForgetPasswordOTP' })
          }
        } catch (e) {
          if (e.constructor === Array)
            if (e[0] == "owner with this phone not verify yet.") {
              this.errMessage = "Owner with this phone not verify yet.";
              this.isNotVerify = true;
            } else
              this.errMessage = e[0];

          if (e.constructor == Object)
          if (e.phone.constructor === Array)
            if (e.phone[0] == "Ensure this field has no more than 16 characters.")
              this.errMessage = "หมายเลขโทรศัพท์มือถือไม่ควรเกิน 16 ตัวอักษร"
            else
              this.errMessage = e.phone[0];
          // if (e[0])
          // this.errMessage = 'หมายเลขโทรศัพท์มือถือไม่ควรเกิน 15 ตัวอักษร'
        }
      }
      this.submitButtonLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.form-block {
  @extend %block-container;
  @extend %form-style;
  height: 470px;

  .title {
    height: 20px;
    font-size: 18px !important;
    font-weight: bold;
    text-align: center;
    color: #4a4a4a;
  }

  .lead {
    margin-top: 24px;
    height: 36px;
    font-size: 14px !important;
    font-weight: bold;
    letter-spacing: -0.6px;
    text-align: center;
    color: #4a4a4a;
  }

  .el-button {
    margin-top: 10px;
  }
}
.footer-block {
  text-align: center;
  span {
    color: $gray;
  }
  a {
    text-decoration: none;
    color: #000;
  }
}
</style>

<style lang="scss" scoped>
button.btn-custom {
  width: 213px !important;
  font-size: 17px !important;
}
</style>
