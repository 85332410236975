<template>
  <div class="pet-icon">
    <img
      v-if="type === 'DOG'"
      src="@/assets/icons/icon-dog.png"
      class="icon"
      :style="{ width: iconWidth }"
    >
    <img
      v-else-if="type === 'CAT'"
      src="@/assets/icons/icon-cat.png"
      class="icon"
      :style="{ width: iconWidth }"
    >
    <div v-else>{{ type }}</div>
  </div>
</template>

<script>
export default {
  props: ["type", "size"],
  computed: {
    iconWidth() {
      let width = this.size || 24
      return width + "px"
    }
  }
}
</script>

<style lang="scss" scoped>
.pet-icon {
  margin: 0 3px;
  display: inline-block;
  vertical-align: middle;
}
</style>


