<template>
  <div class="broadcast-table-wrapper">
    <div>
      <h3 class="title">Result {{ total | number}}</h3>
    </div>
    <table class="broadcast-table">
      <thead>
        <tr>
          <th class="id">Broadcast ID</th>
          <th class="datetime">Date time</th>
          <th class="topic">Topic</th>
          <th class="action"></th>
        </tr>
      </thead>
      <tbody v-if="!isLoading">
        <tr v-for="item in items" :key="item.id">
          <td class="id">{{ item.id }}</td>
          <td class="datetime">
            <div class="date">{{ item.created | toThaiDate }}</div>
            <div class="time">{{ item.created | toHHMM }}</div>
          </td>
          <td class="topic">{{ item.title }}</td>
          <td class="action">
            <button
              class="btn btn-tiny btn-default"
              @click="$router.push({ name: 'hospitalBroadcastDetail', params: { id: item.id }})"
            >SEE DETAIL</button>
          </td>
        </tr>
      </tbody>
    </table>
    <template v-if="!isLoading">
      <div class="not-found-message" v-if="items.length === 0">ไม่พบข้อมูลที่ต้องการค้นหา</div>
      <div class="pagination-wrapper" v-if="items.length > 0">
        <el-pagination
          class="pagination"
          background
          layout="prev, pager, next"
          :current-page="currentPage"
          :page-size="pageSize"
          :total="total"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </template>
    <template v-if="isLoading">
      <DataLoading />
    </template>
  </div>
</template>

<script>
import DataLoading from "@/components/loading/DataLoading";

export default {
  components: {
    DataLoading
  },
  props: ["items", "currentPage", "pageSize", "total", "isLoading"],
  methods: {
    handlePageChange(page) {
      this.$emit("page-change", page);
    }
  }
};
</script>

<style lang="scss" scoped>
.broadcast-table-wrapper {
  .title {
    margin-left: 5px;
  }
  .not-found-message {
    margin-bottom: 10px;
    text-align: center;
  }
  .pagination-wrapper {
    text-align: right;
  }
}
.broadcast-table {
  margin-bottom: 10px;
  width: 100%;
  border-collapse: collapse;

  th {
    padding: 10px 5px;
    font-weight: normal;
    color: #7f8fa4;
    background-color: #f5f8fa;
    font-size: 0.9em;
  }

  td {
    padding: 10px 5px;
    vertical-align: top;
    font-weight: normal;
  }

  tbody > tr {
    border-bottom: 1px solid #eee;
  }

  .id {
    width: 15%;
    text-align: center;
  }
  .datetime {
    width: 15%;
    > .date {
      font-size: 14px;
    }
    > .time {
      font-size: 12px;
      color: #999;
    }
  }
  .topic {
    width: 55%;
  }
  .action {
    text-align: right;
    vertical-align: middle;
    width: 15%;
  }
}
</style>


