<template>
  <div>
    <Subnavbar>
      <template slot="title">Payment Method Setting</template>
      <template slot="actions">
        <button class="btn btn-large btn-primary" @click="handleCreateOrEdit(null)">
          <i class="fa fa-plus-circle"></i>Create Payment Method
        </button>
      </template>
    </Subnavbar>
    <ContentBox>
      <template v-if="items && items.results">
        <PaymentMethodTable
          :items="items.results"
          :total="items.count"
          :current-page="currentPage"
          @current-change="pageChange"
          @selectToEdit="handleCreateOrEdit"
        />
      </template>
    </ContentBox>

    <PaymentMethodCreateDialog
        v-if="isShowCreatePaymentMethodDialog"
        :visible="isShowCreatePaymentMethodDialog"
        :dialogType="getPaymentMethodDialogType()"
        :banks="paymentbanks"
        :paymentTypes="paymenttypes"
        :editItemData="editItemData"
        @create="createPaymentMethod"
        @edit="editPaymentMethod"
        @delete="deletePaymentMethod"
        @cancel="isShowCreatePaymentMethodDialog = false"
      />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ContentBox from "@/components/container/ContentBox";
import Subnavbar from "@/components/navigation/Subnavbar";
import PaymentMethodTable from "@/components/receipt/PaymentMethodTable";
import PaymentMethodCreateDialog from "@/components/receipt/PaymentMethodCreateDialog";


export default {
  components: {
    ContentBox,
    Subnavbar,
    PaymentMethodTable,
    PaymentMethodCreateDialog,
  },
  data() {
    return {
      currentPage: 1,
      isShowCreatePaymentMethodDialog: false,
      editItemData: null,
    };
  },
  computed: {
    ...mapState({
      paymentbanks: state => state.receipt.paymentbanks,
      paymenttypes: state => state.receipt.paymenttypes,
      items: state => state.receipt.paymentmethods
    })
  },
  methods: {
    handleCreateOrEdit(data) {
      this.editItemData = data;
      this.isShowCreatePaymentMethodDialog = true;
    },
    getPaymentMethodDialogType() {
      return this.editItemData == null ? 'create' : 'edit';
    },
    async createPaymentMethod(data) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        await this.$store.dispatch("receipt/createPaymentMethod", {
          data
        });
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        this.$notify({
          message: "สร้าง Payment method เรียบร้อยแล้ว",
          type: "success"
        });
        this.$router.go();
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        await this.$store.dispatch("ui/showFetchErrorMsg")
      }
    },
    async editPaymentMethod(data) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        await this.$store.dispatch("receipt/updatePaymentMethod", {
          params: { id: this.editItemData.id },
          data: data
        });
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        this.$notify({
          message: "แก้ไข Payment method เรียบร้อยแล้ว",
          type: "success"
        });
        this.$router.go();
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        await this.$store.dispatch("ui/showFetchErrorMsg")
      }
    },
    async deletePaymentMethod(paymentMethod) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        await this.$store.dispatch("receipt/deletePaymentMethod", {
          params: { id: paymentMethod.id },
        });
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        this.$notify({
          type: "success",
          title: "ลบวิธีการชำระเงินเรียบร้อยแล้ว"
        })
        this.isShowCreatePaymentMethodDialog = false;
        this.$router.go();
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorMsg");
      }
    },
    getQueryString() {
      let query = {};

      if (this.$route.query.page) {
        query["page"] = parseInt(this.$route.query.page);
        this.currentPage = parseInt(this.$route.query.page);
      } else {
        this.currentPage = 1;
      }

      return query;
    },
    pageChange(page) {
      let query = this.getQueryString();
      query = { ...query, page };
      this.currentPage = page;
      this.fetchData(query);
    },
    async fetchData(query) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        await this.$store.dispatch("receipt/getPaymentMethodList", {
          query,
        });
        if (this.paymentbanks.length == 0) {
          await this.$store.dispatch("receipt/getPaymentMethodBankList", {
            query: {'all': true},
          });
        }
        if (this.paymenttypes.length == 0) {
          await this.$store.dispatch("receipt/getPaymentMethodTypeList", {
            query: {'all': true},
          });
        }
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg");
      }
    }
  },
  async created() {
    this.fetchData(this.getQueryString());
  }
};
</script>