import { render, staticRenderFns } from "./PetTypeIcon.vue?vue&type=template&id=7a7e0920&scoped=true&"
import script from "./PetTypeIcon.vue?vue&type=script&lang=js&"
export * from "./PetTypeIcon.vue?vue&type=script&lang=js&"
import style0 from "./PetTypeIcon.vue?vue&type=style&index=0&id=7a7e0920&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a7e0920",
  null
  
)

export default component.exports