<template>
  <div>
    <table class="payment-method-table">
      <thead>
        <tr>
          <th class="no">No.</th>
          <th class="payment-type">Payment Type</th>
          <th class="bank-name">Bank</th>
          <th class="bank-id">Bank ID</th>
          <th class="account-name">Account Name</th>
          <th class="fee">Fee</th>
          <th class="action"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td class="no">{{ (10 * (currentPage - 1)) + index + 1 }}</td>
          <td class="payment-type">
            <div v-if="item.payment_method_type">{{ item.payment_method_type.name }}</div>
            <!-- <div v-else>-</div> -->
          </td>
          <td class="bank-name">
            <div class="ellipsis" v-if="item.bank">{{ item.bank.name }}</div>
            <!-- <div v-else>-</div> -->
          </td>
          <td class="bank-id">
            <div v-if="item.bank_account_id">{{ item.bank_account_id }}</div>
            <!-- <div v-else>-</div> -->
          </td>
          <td class="account-name">
            <div v-if="item.account_name">{{ item.account_name }}</div>
            <!-- <div v-else>-</div> -->
          </td>
          <td class="fee">
            <div v-if="item.has_fee">{{ item.fee_percentage }}%</div>
            <!-- <div v-else>-</div> -->
          </td>
          <td class="action">
            <el-button
              v-if="item.is_editable"
              size="mini"
              @click="$emit('selectToEdit', item)"
            >
              Edit
              <i class="el-icon-arrow-right el-icon-right"></i>
            </el-button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="not-found" v-if="items.length === 0">ไม่พบข้อมูลที่ต้องการค้นหา</div>
    <el-pagination
      class="pagination"
      background
      layout="prev, pager, next"
      :current-page="currentPage"
      :page-size="pageSize"
      :total="total"
      @current-change="(page) => $emit('current-change', page)"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    pageSize: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.el-button {
  width: 91px;
  height: 35px;
  border-radius: 5px;
  background-color: #02b0e1;
  color: white;

  font-family: "Kanit";
}

.payment-method-table {
  width: 100%;
  border-collapse: collapse;
  font-family: "Kanit", sans-serif;

  thead {
    tr {
      height: 54px;
      border-radius: 4px;
      border: #e6eaee 1px solid;

      th {
        padding: 5px;
        font-weight: normal;
        color: rgba(0, 0, 0, 0.48);
        background-color: #f5f8fa;
        text-align: center;
        font-size: 14px;
        font-family: "Kanit", sans-serif;

        &.no {
          width: 64px;
        }

        &.action {
          width: 100px;
        }
      }
    }
  }

  tbody {
    tr {
      height: 56px;
      border-bottom: 1px #eee solid;
      font-family: "Kanit", sans-serif;

      td {
        position: relative;
        padding: 5px;
        text-align: center;
        font-size: 14px;
        font-family: "Kanit", sans-serif;

        .ellipsis {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &.name > .ellipsis {
          text-align: left;
          width: 200px;
        }

        &.detail {
          > .ellipsis {
            text-align: left;
            width: 400px;
          }
        }
      }
    }
  }
}

.not-found {
  text-align: center;
  padding: 20px 0;
}

.pagination {
  margin-top: 20px;
  text-align: right;
}
</style>


