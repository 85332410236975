const Contact = () => import('@/pages/contact/Contact')
const ContactDetail = () => import('@/pages/contact/ContactDetail')

const routes = [
  {
    path: '/contacts',
    name: 'contact',
    component: Contact,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      // plan_permissions: ['setting_section.can_use_staff_setting']
    }
  },
  {
    path: '/contact/add',
    name: 'contact-add',
    component: ContactDetail,
    meta: {
      isAdd: true,
      requiresAuth: true,
      permissions: ['hospital_admin'],
      // plan_permissions: ['setting_section.can_use_staff_setting']
    }
  },
  {
    path: '/contact/:id',
    name: 'contact-detail',
    component: ContactDetail,
    meta: {
      isAdd: false,
      requiresAuth: true,
      permissions: ['hospital_admin'],
      // plan_permissions: ['setting_section.can_use_staff_setting']
    }
  }
]

export default routes