import { render, staticRenderFns } from "./BloodRequestPage.vue?vue&type=template&id=48efb596&"
import script from "./BloodRequestPage.vue?vue&type=script&lang=js&"
export * from "./BloodRequestPage.vue?vue&type=script&lang=js&"
import style0 from "./BloodRequestPage.vue?vue&type=style&index=0&id=48efb596&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports