<template>
    <el-row>
        <el-row :class="`first ${addClassIOS}`">
            <div class="logo">
            <img src="@/assets/images/landing/logo-v-remind.png"
                srcset="@/assets/images/landing/logo-v-remind@2x.png 2x, 
                        @/assets/images/landing/logo-v-remind@3x.png 3x"
                class="Logo-VRemind">
            </div>
            <div class="button">
                <!-- <router-link :to="{ name: 'plan-and-price'}" class="plan-link">Plan & Price</router-link> -->
                <el-dropdown @command="onLogin" placement="bottom">
                    <el-button type="primary">
                        เข้าสู่ระบบ <i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="hospital">โรงพยาบาลสัตว์</el-dropdown-item>
                        <el-dropdown-item command="user">เจ้าของสัตว์เลี้ยง</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown @command="onRegister" placement="bottom">
                    <el-button class="bth-secondary" type="primary">
                        ลงทะเบียน <i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="hospital">โรงพยาบาลสัตว์</el-dropdown-item>
                        <el-dropdown-item command="user">เจ้าของสัตว์เลี้ยง</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                
            </div>
            <div class="topic">
                New Solution for manage<br>‘Pet Appointment’
            </div>
            <div class="topic sub-topic">
                <b>ระบบจัดการนัดหมาย</b> สำหรับ <b>สถานพยาบาลสัตว์</b><br>
                ที่ช่วย เพิ่มการกลับมาของลูกค้า และ <b>“เพิ่มรายได้”</b> ให้<br>
                กับธุรกิจของคุณ
            </div>
        </el-row>
        <el-row class="second">
            <div class="box">
                <div class="feature">
                    <div class="title">
                        START YOUR FREE TRIAL TODAY
                    </div>
                    <div class="subtitle">
                        ลงทะเบียนเป็นผู้ทดสอบระบบ “ฟรี” 
                    </div>
                    <div class="checklist">
                        <img src="@/assets/icons/icon-check.png">
                        ทำนัดได้ไม่จำกัด
                    </div>
                    <div class="checklist">
                        <img src="@/assets/icons/icon-check.png">
                        เพิ่มเจ้าของสัตว์เลี้ยง และ สัตว์เลี้ยง ได้ไม่จำกัด
                    </div>
                    <div class="checklist">
                        <img src="@/assets/icons/icon-check.png">
                        แจ้งเตือนแบบอัตโนมัติ ผ่านหลายช่องทาง
                    </div>
                    <div class="checklist">
                        <img src="@/assets/icons/icon-check.png">
                        ระบบให้เจ้าของสัตว์ ยืนยัน และ แจ้งเลื่อนนัดหมาย ด้วยตัวเองได้
                    </div>
                    <div class="checklist">
                        <img src="@/assets/icons/icon-check.png">
                        แสดงตารางนัดหมาย ที่เห็นสถานะของแต่ละนัดได้อย่างชัดเจน
                    </div>
                </div>
                <Register :isLandingPage="true" class="register-form"></Register>
            </div>
            <el-col :span="20" :offset="2" class="content">
                <div class="warpper">
                    <div class="head-1">
                        ลืมวิธีการทำนัดหมายแบบเดิมๆไปได้เลย!
                    </div>
                    <div class="head-2">
                        ทำนัดหมายแบบเดิม
                    </div>
                    <div class="box-2">
                        <img src="@/assets/images/landing/info-1.png"
                            srcset="@/assets/images/landing/info-1@2x.png,
                                    @/assets/images/landing/info-1@3x.png">
                    </div>
                    <div class="head-3">
                        <span class="style-1">ทำนัดหมายด้วย VRemind</span><br>
                        ลดเวลาที่ใช้จัดการการนัดหมายลง 
                        <span class="style-2">“5 เท่า”</span> 
                        <div class="tablet"></div>เมื่อเทียบกับแบบเดิมด้วยระบบ 
                        <span class="style-3">Automatic</span> 
                        ของเรา<br>
                        “ทำงานน้อยลง ได้ผลมากขึ้น”
                    </div>
                    <div class="box-2">
                        <img src="@/assets/images/landing/info-2.png"
                            srcset="@/assets/images/landing/info-2@2x.png,
                                    @/assets/images/landing/info-2@3x.png">
                    </div>
                </div>
            </el-col>
        </el-row>
        <el-row class="third">
            <el-col :span="20" :offset="2" class="content">
                <div class="warpper">
                    <el-carousel :interval="5000" arrow="always">
                        <!-- <el-carousel-item v-for="item in 5" :key="item">
                            <div class="caption">{{ `พี่ก้องจะเป็นคนคิดคำอธิบายส่วนนี้ ${item}` }}</div>
                            <img src="@/assets/images/landing/carousal-1.png">
                        </el-carousel-item> -->
                        <el-carousel-item>
                            <div class="caption"> </div>
                            <img src="@/assets/images/landing/slide-1.png">
                        </el-carousel-item>
                        <el-carousel-item>
                            <div class="caption"> </div>
                            <img src="@/assets/images/landing/slide-2.png">
                        </el-carousel-item>
                        <el-carousel-item>
                            <div class="caption"> </div>
                            <img src="@/assets/images/landing/slide-3.png">
                        </el-carousel-item>
                        <el-carousel-item>
                            <div class="caption"> </div>
                            <img src="@/assets/images/landing/slide-4.png">
                        </el-carousel-item>
                        <el-carousel-item>
                            <div class="caption"> </div>
                            <img src="@/assets/images/landing/slide-5.png">
                        </el-carousel-item>
                    </el-carousel>

                    <div class="head-1">
                        Our Feature
                    </div>

                    <div class="feature">
                        <div class="item">
                            <div>
                                <img src="@/assets/images/landing/icon-1.svg" 
                                    style="width: 177px; height: 120px;">
                            </div>
                            <div>
                                • ทำนัดหมาย ที่เตรียมมาสำหรับ<br>สถานพยาบาลสัตว์โดยเฉพาะ
                            </div>
                        </div>
                        <div class="item">
                            <div>
                                <img src="@/assets/images/landing/icon-2.svg" 
                                    style="width:73px; height:98px;">
                            </div>
                            <div>
                                • พิมพ์ใบนัด ในรูปแบบ<br>Appointment Slip 
                            </div>
                        </div>
                        <div class="item">
                            <div>
                                <img src="@/assets/images/landing/icon-3.svg" 
                                    style="width:183px; height:124px;">
                            </div>
                            <div>
                                • แจ้งเตือนอัตโนมัติ<br>ผ่านช่องทางหลากหลาย
                            </div>
                        </div>
                        <div class="item">
                            <div>
                                <img src="@/assets/images/landing/icon-4.svg" 
                                    style="width:159px; height:110px;">
                            </div>
                            <div>
                                • มีระบบติดตามสถานะการนัดหมาย<br>เพื่อเพิ่มประสิทธิภาพในการจัดการ
                            </div>
                        </div>
                        <div class="item">
                            <div>
                                <img src="@/assets/images/landing/icon-5.svg" 
                                    style="width:211px; height:97px;">
                            </div>
                            <div>
                                • มีระบบช่วยให้เจ้าของสัตว์<br>ยืนยันนัด และ เลื่อนนัด
                            </div>
                        </div>
                        <div class="item">
                            <div>
                                <img src="@/assets/images/landing/icon-6.svg" 
                                    style="width:80px; height:80px;">
                            </div>
                            <div>
                                • เก็บ และ วิเคราะห์ข้อมูลลูกค้า<br>เพื่อช่วยในการตลาดต่อยอด
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
        <el-row class="four">
            <el-col :span="20" :offset="2" class="content">
                <div class="warpper">
                    <div class="row">
                        <div class="col">
                            สถานพยาบาลสัตว์สนใจให้ VRemind<br>
                            ช่วยดูแลลูกค้า สมัครเลย!<br>
                            <span class="style-1">
                                (ลงทะเบียนเป็นผู้ทดสอบระบบ “ฟรี” )
                            </span>
                        </div>
                        <div class="col">
                            <el-button @click="onRegister('hospital')">ลงทะเบียน เพื่อเป็นผู้ทดสอบระบบ</el-button>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
        <el-row class="five">
            <el-col :span="20" :offset="2" class="content">
                <div class="warpper">
                    <div class="row">
                        <div class="col">
                            <div>ติดต่อเรา</div>
                            <img class="logo-vremind" 
                                src="@/assets/images/landing/logo-v-remind.svg"
                                style="width: 62px; height: 59px;">
                        </div>
                        <div class="col">
                            <div>(+66) 0848475744</div>
                            <div><a class="email-link" href="mailto:support@vremind.co">support@vremind.co</a></div>
                            <div>
                                <div class="social">
                                    <a href="https://www.facebook.com/VRemindU/" target="_blank">
                                    <img class="logo-social" 
                                        src="@/assets/images/landing/logo-facebook.svg"
                                        style="width: 24px; height: 24px;"></a>
                                    <a href="http://nav.cx/f0rayna" target="_blank">
                                    <img class="logo-social"
                                        src="@/assets/images/landing/logo-line.png"
                                        style="width: 24px; height: 24px;"></a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="vetlist">
                                <div>power by</div>
                                <img class="logo-vetlist" 
                                    src="@/assets/images/landing/logo-vetlist.png"
                                    style="width: 49px; height: 56px;">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <el-link
                            href="https://drive.google.com/file/u/3/d/1CB2gkIKR-74QXjfs4HaMZn6zbIX3Tl5l/view?usp=sharing"
                            :underline="false"
                            style="color: #fff;"
                            target="_blank"
                            rel="norefferer">
                            Term and Condition
                        </el-link>
                    </div>
                    <div class="row">
                        <span class="copyright">copyright @2019 vremind.co</span>
                    </div>
                </div>
            </el-col>
        </el-row>
    </el-row>
</template>

<script>
import Register from "@/pages/hospitalRegister/Register"

export default {
    data() {
        return {
            
        }
    },
    components: {
        Register
    },
    computed: {
        addClassIOS: function() {
            return navigator.userAgent.match(/(iPod|iPhone|iPad)/) ? 'ios' : '';
        }
    },
    methods: {
        onRegister(command) {
            command == "hospital" ? 
            this.$router.push({name: 'register'}) : 
            this.$router.push({name: 'petOwnerRegister'});
        },
        onLogin(command) {
            command == "hospital" ? 
            this.$router.push({name: 'login'}) : 
            this.$router.push({name: 'petOwnerLogin'});
        },
    },
}
</script>

<style lang="scss" scoped>
.button i {
    float: right;
}
.plan-link {
    display: inline-block;
    margin-right: 24px;
    color: #fff;
    text-decoration: none;
    font-size: 1.3em;
}
.head-1 {
    clear: both;
    // height: 66px;
    font-family: kanit;
    font-size: 42px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0px;
    text-align: center;
    color: #40c0cb;
    margin-top: 51px;
    margin-bottom: 33px;
    clear: both;

    @include tablet {
        font-size: 36px;
        margin-bottom: 33px;
    }

    @include mobile {
        font-size: 30px;
        margin-bottom: 33px;
        padding-left: 24px;
        padding-right: 24px;
    }

    @include desktop {
        font-size: 42px;
        margin-bottom: 33px;
        padding-left: 24px;
        padding-right: 24px;
    }
}

.head-2 {
    height: 57px;
    font-family: kanit;
    font-size: 36px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0px;
    text-align: center;
    color: #0f4553;
    margin-bottom: 36px;

    @include tablet {
        font-size: 32px;
        margin-bottom: 24px;
    }

    @include mobile {
        font-size: 26px;
        margin-bottom: 0px;
    }
}

.head-3 {
    // height: 188px;
    font-family: kanit;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0px;
    text-align: center;
    color: #0f4553;

    margin-top: 27px;
    margin-bottom: 63px;

    .style-1 {
        font-size: 36px;
        @include mobile {
            font-size: 30px;
            margin-bottom: 320px;
        }
    }

    .style-2 {
        clear: both;
        color: #ff1e21;
    }

    .style-3 {
        clear: both;
        color: #00b7e5;
    }

    @include mobile {
        font-size: 20px;
        margin-bottom: 48px;
    }

    @include tablet {
        // font-size: 22px;
    }
}

div .tablet {
    display: none;

    @include tablet {
        display: block;
    }

    @include mobile {
        display: block;
    }
}

.content {
    width: 95%;
    margin: 0 2.5%;
    margin-top: 0px;

    @include tablet {
        width: 95%;
        margin: 0 2.5%;
    }

    @include mobile {
        width: 95%;
        margin: 0 2.5%;
    }

    & .warpper {
        max-width: 1200px;
        display: block;
        margin: 0 auto;
    }
}

.first {
    background: url('~@/assets/images/landing/bg.jpg') no-repeat center center fixed; 

    @include tablet {
        background: url('~@/assets/images/landing/bg.jpg') center center;   
    }

    @include mobile {
        background: url('~@/assets/images/landing/bg.jpg') center center;
    }

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    padding-bottom: 180px;

    &.ios {
        background-attachment: initial !important;
        background-size: cover !important;
    }

    .Logo-VRemind {
        margin-top: 20px;
        margin-left: 31px;
        width: 168px;
        height: 159px;
        object-fit: contain;

        @include mobile {
            margin-top: 20px;
            margin-left: 0px;
        }
    }
    
    .logo {
        display: inline-block;

        @include mobile {
            display: block;
            text-align: center;
        }
    }

    .topic {
        margin-top: 87px;
        width: 100%;
        clear: both;

        // height: auto;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
        font-family: kanit;
        font-size: 72px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.11;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;

        @include mobile {
            font-size: 28px;
        }

        @include tablet {
            font-size: 48px;
        }
    }

    .sub-topic { 
        margin-top: 20px;
        // margin-bottom: 143px;
        width: 100%;
        clear: both;
        // height: 228px;
        // height: auto;
        font-family: kanit;
        font-size: 36px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;

        @include mobile {
            font-size: 14px;
        }

        @include tablet {
            font-size: 24px;
        }
    }

    .button {
        float: right;
        margin-top: 23px;
        margin-right: 31px;
        
        @include mobile {
            float: none;
            display: block;
            text-align: center;
             margin-right: 0px;
        }

        .el-dropdown {
            vertical-align: middle;
        }

        .el-button {
            vertical-align: middle;
            // float: right;
            width: 195px;
            height: 50px;
            border-radius: 5px;
            border: solid 3px #ffffff;
            background: none;
            color: #fff;

            font-family: kanit;
            font-size: 16px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            // line-height: normal;
            letter-spacing: 0px;

            // @include td {
                &.bth-secondary {
                    // float: right;
                    margin-left: 18px;
                    background-color: #ccf6ff;
                    border: none;
                    color: #0f4553;
                    @include mobile {
                        float: none;
                    }
                }
            // }
            span, i {
                vertical-align: middle;
            }
            
            @include mobile {
                width: 155px;
                height: 50px;
            }
        }
    } 
    
}

.second {
    box-sizing: content-box;
    height: auto;
    background-image: linear-gradient(to bottom, rgba(241, 241, 241, 0), #e7e7e7);
    padding-bottom: 104px;

    @include mobile {
        padding-bottom: 54px;
    }

    .box {
        box-sizing: border-box;
        width: auto;
        max-width: 1120px;
        height: auto;
        clear: both;

        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        background-color: #40c0cb;

        margin: -100px auto;
        margin-bottom: 0px;
        padding: 21px 20px 32px 67px;

        @include tablet {
            display: block;
            width: auto;
            padding: 21px 20px 32px 40px;
            margin: 0;
        }

        @include mobile {
            display: block;
            width: auto;
            padding: 20px;
            margin: 0;
        }

        .feature {
            display: inline-block;
            height: auto;

            .title {
                // height: 57px;
                font-family: kanit;
                font-size: 36px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #ffffff;

                @include mobile {
                    font-size: 20px;
                    height: auto;
                }
            }

            .subtitle {
                height: 37px;
                font-family: kanit;
                font-size: 24px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #ffffff;
                margin-bottom: 33px;

                @include mobile {
                    font-size: 18px;
                    height: auto;
                }
            }

            .checklist {
                margin-left: 35px;
                height: 31px;
                font-family: kanit;
                font-size: 20px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #ffffff;
                margin-bottom: 36px;

                @include mobile {
                    font-size: 16px;
                    height: auto;
                }

                img {
                    width: 24px;
                    height: 17px;
                    margin-right: 10px;
                }
            }
        }

        .register-form {
            float: right;
            width: 300px;
            margin: 0;
            clear: both;

            @include tablet {
                float: none;
                height: auto;
                margin: 0 auto;
            }

            @include mobile {
                float: none;
                height: auto;
                margin: 0 auto;
            }
        }
    }

    .box-2 {
        display: block;
        padding: 33px;
        // padding: 16px 17px 16px 33px;
        // padding-left: 33px;
        // padding-right: 33px;
        background-color: #ffffff;
        clear: both;

        img {
            width: 100%;
        }

        // &:last-child {
        //     padding: 34px 109px 33px 109px;
        // }
        
    }
}

.third {
    height: auto;
    padding-top: 60px;

    margin: 0 auto;

    .head-1 {
        margin-top: 23px;
    }

    .feature {
        clear: both;
        display: block;
        width: auto;
        text-align: center;
        // background: red;

        .item {
            vertical-align: middle;
            // clear: left;
            box-sizing: border-box;
            // float: left;
            display: inline-block;
            width: 400px;
            height: 260px;
            padding-left: 50px;
            padding-right: 50px;
            text-align: center;

            margin-top: 0px;

            font-family: kanit;
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0px;
            text-align: center;
            color: #4a4a4a;

            @include tablet {
                font-size: 18px;
                width: 49%;
            }

            @include mobile {
                font-size: 18px;
            }

            @include lg {
                width: 32%;
                padding-left: 0px;
                padding-right: 0px;
            }

            div:first-child {
                display: block;
                // float: left;
                height: 120px;
                margin-bottom: 12px;
                position: relative;
                
            }

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                
                transform: translate(-50%, -50%);
                // margin: auto 0;
            }
        }
    }

    button {
        background: red;
    }

    img {
        display: block;
        // width: 100%;
        height: 100%;
        
        margin: 0 auto;
        clear: both;
    }
}

.four {
    background-color: #40c0cb;
    padding-top: 80px;
    padding-bottom: 77px;

    .row {
        text-align: center;

        .col {
            display: inline-block;
            height: 103px;
            vertical-align: middle;

            &:first-child {
                margin-right: 34px;
                width: 361px;
                // width: 460px;
                // height: 138px;
                font-family: kanit;
                font-size: 24px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: 0px;
                text-align: right;
                color: 0f4553;

                .style-1 {
                    font-size: 20px;

                    @include tablet {
                        font-size: 16px;
                    }
                }

                @media (min-width: 0px) and (max-width: 840px) {
                    margin: 0;
                    text-align: center;
                    height: auto;
                    // margin-bottom: 16px;
                }

                @include tablet {
                    font-size: 20px;
                    width: 330px;
                }
            }

            &:last-child {
                height: 93px;
                .el-button {
                    width: 385px;
                    height: 93px;
                    border-radius: 11px;
                    box-shadow: 0 2px 4px 0 #0f4553;
                    background-color: #ffc52d;
                    
                    font-family: kanit;
                    font-size: 24px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: 0px;
                    text-align: center;
                    color: #0f4553;

                    border: none;

                    &:hover {
                        background-color: #e6b028;
                    }
                }
            }  
        }
    }
}

.five {
    height: auto;
    background-color: #0f4553;

    font-family: kanit;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0px;
    text-align: center;
    color: #ffffff;

    padding-top: 25px;

    .col {
        width: auto;
        float: left;
        height: auto;

        

        &:nth-child(1) {
            margin-right: 20px;
            img { margin-top: 7px; }
        }

        &:nth-child(2) {
            text-align: left;
            font-size: 13px;

            img {
                margin-right: 12px;
            }

            & div {
                margin-bottom: 0px;
                
                &:nth-child(1) {
                    font-size: 13px;
                    margin-bottom: 6px;
                }

                &:nth-child(2) {
                    font-size: 14px;
                    margin-bottom: 15px;
                }
            }

            .social {
                margin-bottom: 0px !important;
            }
        }

        &:nth-child(3) {
            float: right;

            .vetlist {
                display: inline-block;
                vertical-align: middle;

                div {
                    text-align: center;
                    margin-bottom: 14px;
                    font-size: 10px;
                    vertical-align: middle;
                }

                img {
                    vertical-align: middle;
                }
            }
        }
    }

    .row {
        display: block;
        clear: both;

        &:last-child {
            padding-top: 16px;
            padding-bottom: 20px;
        }
    }

    .copyright {
        font-size: 10px;
        clear: both;
    }

}
</style>

<style lang="scss">
.third {

    .el-carousel__container {
        width: auto;
        height: 500px;

        @include tablet {
            height: 400px;
        }

        @include mobile {
            height: 280px;
        }

        @include lg {
            height: 500px;
        }

        @include xl {
            height: 600px;
        }

        img {
            width: auto;
            height: 90%;

             @include tablet {
                height: 80%;
            }

            @include mobile {
                height: 65%;
            }
        }
    }

    .el-carousel__button {
        width: 10px;
        height: 10px;
        background-color: #cac9ca;
        border-radius: 64px; 

        @include tablet {
            width: 8px;
            height: 8px;
        }

        @include mobile {
            width: 6px;
            height: 6px;
        }
    }

    .el-carousel__indicator {
        padding-left: 8px;
        padding-right: 8px;
    }

    .el-carousel__arrow {
        width: 60px;
        height: 100px;
        color: transparent;
        border-radius: 0;
        // color: red !important;

        @include tablet {
            width: 40px;
            height: 75px;
        }

        @include mobile {
            width: 20px;
            height: 40px;
        }
    }

    .el-carousel__arrow--left {
        background: url("~@/assets/images/landing/arrow-left.svg");
        background-size: 100% 100%;
    }

    .el-carousel__arrow--right {
        background: url("~@/assets/images/landing/arrow-right.svg");
        background-size: 100% 100%;
    }

    .el-carousel__item {
        .caption {
            text-align: center;

            margin-top: 14px;
            margin-bottom: 16px;
        }
    }
}
.email-link {
    color: #fff;
    text-decoration: none;
}
</style>
