import ExpenceVisitReportHome from '@/pages/report/ExpenceVisitReportHome.vue'
import ExpenceVisitReportDetail from '@/pages/report/ExpenceVisitReportDetail.vue'
const ReportExpenseSegmentationPet = () => import('@/pages/report/ReportExpenseSegmentationPet')
const ReportExpenseSegmentationPetOwner = () => import('@/pages/report/ReportExpenseSegmentationPetOwner')
const ProductUsed = () => import('@/pages/report/ProductUsed')
const ProductUsedDetail = () => import('@/pages/report/ProductUsedDetail')
const ActivityUsed = () => import('@/pages/report/ActivityUsed')
const ActivityUsedDetail = () => import('@/pages/report/ActivityUsedDetail')
const DiagnosisUsed = () => import('@/pages/report/DiagnosisUsed')
const DiagnosisUsedDetail = () => import('@/pages/report/DiagnosisUsedDetail')
const Summary = () => import('@/pages/report/Summary')
const MonthlySummary = () => import('@/pages/report/MonthlySummary')
const YearlySummary = () => import('@/pages/report/YearlySummary')

const routes = [
  {
    path: '/report/expence-visit',
    name: 'expence-visit-report-home',
    component: ExpenceVisitReportHome,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['report_section.can_use_frequency_expense_report']
    }
  },
  {
    path: '/report/expence-visit/:type/detail',
    name: 'expence-visit-report-detail',
    component: ExpenceVisitReportDetail,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['report_section.can_use_frequency_expense_report']
    }
  },
  {
    path: '/report/expense-segmentation/pet',
    name: 'report-expense-segmentation-pet',
    component: ReportExpenseSegmentationPet,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['income_expense_section.can_view_pet_expense_segmentation']
    }
  },
  {
    path: '/report/expense-segmentation/pet-owner',
    name: 'report-expense-segmentation-pet-owner',
    component: ReportExpenseSegmentationPetOwner,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['income_expense_section.can_view_owner_expense_segmentation']
    }
  },
  {
    path: '/report/product/used',
    name: 'report-product-used',
    component: ProductUsed,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['report_section.can_use_product_report']
    }
  },
  {
    path: '/report/product/used/:id/detail',
    name: 'report-product-used-detail',
    component: ProductUsedDetail,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['report_section.can_use_product_report']
    }
  },
  {
    path: '/report/activity/used',
    name: 'report-activity-used',
    component: ActivityUsed,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['report_section.can_use_activity_report']
    }
  },
  {
    path: '/report/activity/used/:id/detail',
    name: 'report-activity-used-detail',
    component: ActivityUsedDetail,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['report_section.can_use_activity_report']
    }
  },
  {
    path: '/report/diagnosis/used',
    name: 'report-diagnosis-used',
    component: DiagnosisUsed,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['report_section.can_use_final_diagnosis_report']
    }
  },
  {
    path: '/report/diagnosis/used/:id/detail',
    name: 'report-diagnosis-used-detail',
    component: DiagnosisUsedDetail,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      // plan_permissions: ['report_section.can_use_final_diagnosis_report']
    }
  },
  {
    path: "/report/summary",
    name: "report-summary",
    component: Summary,
    meta: {
      requiresAuth: true,
      permissions: ["hospital_admin"],
      plan_permissions: ['report_section.can_use_summary_report']
    },
    redirect: { name: "report-summary-monthly" },
    children: [
      {
        path: "monthly",
        name: "report-summary-monthly",
        component: MonthlySummary,
        meta: {
          requiresAuth: true,
          permissions: ["hospital_admin"],
          plan_permissions: ['report_section.can_use_summary_report']
        },
      },
      {
        path: "yearly",
        name: "report-summary-yearly",
        component: YearlySummary,
        meta: {
          requiresAuth: true,
          permissions: ["hospital_admin"],
          plan_permissions: ['report_section.can_use_summary_report']
        },
      },
    ],
  },
]

export default routes
