<template>
  <div class="broadcast-audience-filter" v-if="audiences">
    <h2 class="title">Audience</h2>
    <div class="subtitle"><label>1. Filter Target</label></div>
    <div class="remark">
      <span style="display: inline-block; width: 600px;">เลือกคุณสมบัติของผู้รับข้อมูลที่ต้องการ <span
              class="required-mark">* ถ้าไม่เลือกจะเป็นการส่งทั้งหมด</span></span>
      <span>เลือกช่วงอายุสัตว์เลี้ยงที่ต้องการ</span>
    </div>
    <div class="filter-options">
      <el-select
              clearable
              placeholder="Pet Type"
              size="small"
              v-model="form.selectedPetType"
              @change="handleSearchBreeds"
              @clear="form.selectedPetType=''"
      >
        <el-option value="1" label="Dog/สุนัข"></el-option>
        <el-option value="2" label="Cat/แมว"></el-option>
      </el-select>
      <el-select
              clearable
              placeholder="Breed"
              size="small"
              :disabled="form.selectedPetType === ''"
              v-model="form.selectedBreadId"
              @change="handleGetTotalPetsAndOwners"
              @clear="form.selectedBreadId=''"
      >
        <el-option
                v-for="breed in breeds"
                :key="breed.id"
                :value="breed.id"
                :label="breed.name"
        ></el-option>
      </el-select>
      <el-select
              clearable
              placeholder="Sex"
              size="small"
              @change="handleGetTotalPetsAndOwners"
              @clear="form.selectedSexId=''"
              v-model="form.selectedSexId"
      >
        <el-option value="1" label="Male/เพศผู้"></el-option>
        <el-option value="0" label="Female/เพศเมีย"></el-option>
      </el-select>
      <label style="color: #333; margin: 0 10px;">อายุ</label>
      <el-input-number size="small" v-model="form.ageMin" @change="handleGetTotalPetsAndOwners" :min="0" :max="form.ageMax || 999"></el-input-number>
      <el-input-number size="small" v-model="form.ageMax" @change="handleGetTotalPetsAndOwners" :min="form.ageMin || 0" :max="999"></el-input-number>
    </div>
      <div class="total">
      <span>Total Result: <span class="number">{{audiences.pet_count | number}}</span> </span>
      <span style="margin-left: 50px;">Total Owner: <span
              class="number">{{audiences.owner_count | number}}</span></span>
    </div>
    <div class="subtitle"><label>2. Select Number</label> <span class="required-mark">*</span></div>
    <div class="remark">
      <span>เลือกจำนวนเจ้าของสัตว์เลี้ยงผู้รับข้อความ</span>
    </div>
    <div style="margin: 10px 0;">
      จำนวน
      <el-input-number
              size="small"
              v-model="form.limitOwnerNumber"
              @change="handleChangeLimitOwnerNumber"
              @blur="!form.limitOwnerNumber? form.limitOwnerNumber = 0 : null"
              :min="0" :max="audiences.owner_count"
      ></el-input-number>
      คน
      <span style="margin-left: 10px; font-size: 12px; color: #02b0e1;">*ไม่สามารถใส่จำนวนเกิน Total Owner ได้ </span>
    </div>
    <div class="subtitle"><label>3. Select Channel</label></div>
    <div class="remark">
      <span>เลือกช่องทางการส่ง</span>
    </div>
    <div class="credit-container">
      <div class="clearfix credit-header">
        <div style="float: left;">Your Credit</div>
        <div style="float: right;">{{ audiences.credit_left | number }}</div>
      </div>
      <div class="credit-body">
        <div class="channel" style="padding: 10px 0 10px 10px;">
          <el-checkbox disabled="disabled" v-model="form.isSystem" label="VRemind"></el-checkbox>
          <span class="credit" style="color: #999; margin-left: -1px;">( 0 Credit )</span>
        </div>
        <div class="channel">
          <el-checkbox :disabled="!audiences.credit_use_enough"  v-model="form.isSocial" label="Social" @change="handleSubmitValues" border></el-checkbox>
          <span class="credit">( {{ audiences.credit_use | number }} {{ audiences.credit_use > 1 ? 'Credits' : 'Credit'}} )</span>
          <span class="required-mark" v-if="!audiences.credit_use_enough">*คุณมี Credit ไม่พอ!</span>
        </div>
        <div class="channel">
          <el-checkbox :disabled="!audiences.credit_use_with_sms_enough" v-model="form.isSms" label="SMS" @change="handleSubmitValues" border></el-checkbox>
          <span class="credit">( {{ audiences.credit_use_with_sms | number }} {{ audiences.credit_use_with_sms > 1 ? 'Credits' : 'Credit'}} )</span>
          <span class="required-mark" v-if="!audiences.credit_use_with_sms_enough">*คุณมี Credit ไม่พอ!</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState} from "vuex"

  export default {
    props: [
      "selectedPetType",
      "selectedBreadId",
      "selectedSexId",
      "ageMin",
      "ageMax",
      "limitOwnerNumber",
      "isSocial",
      "isSms"
    ],
    data() {
      return {
        form: {
          selectedPetType: this.selectedPetType,
          selectedBreadId: this.selectedBreadId,
          selectedSexId: this.selectedSexId,
          ageMin: this.ageMin,
          ageMax: this.ageMax,
          limitOwnerNumber: this.limitOwnerNumber,
          isSystem: true,
          isSocial: this.isSocial,
          isSms: this.isSms,
          useCredits: 0
        }
      }
    },
    computed: {
      ...mapState({
        audiences: state => state.broadcast.audiences,
        breeds: state => state.pet.breeds
      }),
    },
    methods: {
      calculateUseCredits () {
        let useCredits = 0;
        if (this.form.isSocial && this.audiences.credit_use_enough) {
          useCredits += this.audiences.credit_use
        }
        if (this.form.isSms && this.audiences.credit_use_with_sms_enough) {
          useCredits += this.audiences.credit_use_with_sms
        }
        this.form.useCredits = useCredits
      },
      handleSubmitValues() {
        this.calculateUseCredits()
        this.$nextTick(() => {
          this.$emit('change', this.form)
        })
      },
      handleChangeLimitOwnerNumber () {
        this.form.isSms = false
        this.form.isSocial = false
        this.calculateUseCredits()
        this.fetchAudiences()
      },
      handleGetTotalPetsAndOwners() {
        this.form.limitOwnerNumber = 0
        this.form.isSms = false
        this.form.isSocial = false
        this.calculateUseCredits()
        this.fetchAudiences()
      },
      async handleSearchBreeds() {
        this.form.limitOwnerNumber = 0
        if (this.form.selectedPetType !== "") {
          this.form.selectedBreadId = ""
          await this.$store.dispatch("pet/listBreeds", {
            query: {pet_type: this.form.selectedPetType}
          })
        } else {
          this.$store.commit("pet/SET_BREEDS", null)
        }
        this.fetchAudiences()
      },
      getQuery() {
        let query = {}
        if (this.form.selectedPetType && this.form.selectedPetType !== "") query["pet_type_id"] = this.form.selectedPetType
        if (this.form.selectedBreadId && this.form.selectedBreadId !== "") query["pet_breed_id"] = this.form.selectedBreadId
        if (this.form.selectedSexId && this.form.selectedSexId !== "") query["pet_sex_id"] = this.form.selectedSexId
        if (this.form.ageMin && this.form.ageMin !== "") query["pet_age_min"] = this.form.ageMin
        if (this.form.ageMax && this.form.ageMax !== "") query["pet_age_max"] = this.form.ageMax
        if (this.form.limitOwnerNumber && this.form.limitOwnerNumber !== "") {
          query["limit_owner_number"] = this.form.limitOwnerNumber
        } else {
          query["limit_owner_number"] = 0
        }

        return query
      },
      async fetchAudiences() {
        try {
          this.$emit('isLoading', true)
          await this.$store.dispatch("ui/setIsFullScreenLoading", true)
          await this.$store.dispatch("broadcast/getAudiences", {
            query: this.getQuery()
          })
          this.$emit('change', this.form)
          this.$emit('isLoading', false)
          await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        } catch (error) {
          this.$emit('isLoading', false)
          await this.$store.dispatch("ui/setIsFullScreenLoading", false)
          await this.$store.dispatch("ui/showFetchErrorMsg")
        }
      }
    },
    async mounted() {
      await this.fetchAudiences()
    },
    watch: {
      limitOwnerNumber (newValue) {
        this.form.limitOwnerNumber = newValue
      }
    }
  }
</script>

<style lang="scss" scoped>
  .broadcast-audience-filter {
    position: relative;
    padding-bottom: 30px;

    .title {
      font-size: 18px;
      margin-bottom: 4px;
    }

    .subtitle {
      margin-top: 15px;
    }

    label {
      color: $light-blue;
      margin-right: 3px;
    }

    .filter-options {
      padding: 10px 0 5px 0;
    }

    .el-select,
    .el-input-number {
      margin-right: 5px;
    }

    .el-checkbox {
      width: 100px;
    }

    .remark {
      font-size: 12px;
      color: #999;

      .required-mark {
        font-size: 12px;
      }
    }

    .total .number {
      font-size: 18px;
      margin-left: 15px;
    }

    .credit-container {
      width: 400px;
    }

    .credit-header {
      padding: 10px 0;
      border-bottom: 1px solid #ccc;
    }

    .credit-body {
      .channel {
        margin: 10px 0;
      }

      .credit {
        margin-left: 10px;
        color: $light-blue;
        font-size: 12px;
      }

      .required-mark {
        margin-left: 10px;
        font-size: 12px;
      }
    }
  }
</style>


