<template>
  <div class="pet-search-result">
    <div class="header">
      <span class="total">{{ petCount | number }}</span> RESULT
    </div>
    <div class="content">
      <table class="pet-table">
        <thead>
          <tr>
            <th class="hn">H.N.</th>
            <th class="name">Pet Name</th>
            <th class="type">Type</th>
            <th class="breed">Breed</th>
            <th class="owner">Owner</th>
            <th class="tel-email">Tel./Email</th>
            <th class="action"></th>
          </tr>
        </thead>
        <tbody v-if="pets && pets.results">
          <tr v-for="pet in pets.results" :key="pet.id">
            <td>{{ pet.hn }}</td>
            <td>
              <img
                v-if="pet.pet.rank && pet.pet.rank <= 3"
                :src="getStatusIcon(pet.pet.rank)"
                alt="Status icon"
                :style='{ 
                  display: "inline-block",
                  height: "2em",
                  marginRight: "6px",
                  verticalAlign: "middle"
                }'
              />
              <div
                v-bind:class="{ blankLeft: pet.pet.rank && pet.pet.rank > 3 }"
                class="thumbnail">
                <img v-if="pet.pet.image && pet.pet.image.medium" :src="pet.pet.image.medium">
              </div>
              <div class="pet-info">
                <router-link :to="{ name:'hospitalPetProfile', params: { id: pet.id }}">
                {{ pet.pet.name }}
                </router-link>
              </div>
            </td>
            <td>
              <PetTypeIcon 
                :type="pet.pet.type.key" />
              <PetGenderIcon :gender="pet.pet.gender"/>
            </td>
            <td>
              <template v-if="pet.pet && pet.pet.breed">
                <div>{{ pet.pet.breed.name }}</div>
              </template>
            </td>
            <td>
              <template v-if="pet.pet && pet.pet.owners && pet.pet.owners.length > 0">
                <router-link :to="{ name:'hospitalPetOwnerProfile', params: { id: pet.pet.owners[0].id }}">
                <div>{{ `${pet.pet.owners[0].firstname} ${pet.pet.owners[0].lastname}` }}</div>
                </router-link>
              </template>
              <template v-else>
                <div class="text-center">-</div>
              </template>
            </td>
            <td>
              <template v-if="pet.pet && pet.pet.owners && pet.pet.owners.length > 0">
                <div v-if="pet.pet.owners[0].phone">Tel. {{ pet.pet.owners[0].phone}}</div>
                <div class="subtitle" v-if="pet.pet.owners[0].email">{{ pet.pet.owners[0].email}}</div>
              </template>
              <template v-else>
                <div class="text-center">-</div>
              </template>
            </td>
            <td>
              <button
                class="btn btn-tiny btn-primary"
                @click="$router.push({ name: 'hospitalPetProfile', params: { id: pet.id }})"
              >
                SEE DETAIL
                <i class="el-icon-arrow-right el-icon-right"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="not-found" v-if="pets && pets.results.length === 0">ไม่พบข้อมูลที่ต้องการค้นหา</div>
    </div>
    <div class="footer" v-if="pets">
      <el-row type="flex" justify="end">
        <el-col :md="4" class="text-left">
          <button v-if="canExport" class="btn btn-small btn-status-cancel"
            @click="() => $emit('export-data')">
            Export Data
          </button>
        </el-col>
        <el-col :md="20" class="text-right">
          <el-pagination
            background
            @current-change="(page) => $emit('current-change', page)"
            :current-page="currentPage"
            :page-size="10"
            layout="prev, pager, next, jumper"
            :total="petCount"
          ></el-pagination>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import PetGenderIcon from "@/components/pet/PetGenderIcon"
import PetTypeIcon from "@/components/pet/PetTypeIcon"

export default {
  components: {
    PetGenderIcon,
    PetTypeIcon
  },
  props: ["pets", "currentPage", "canExport"],
  data() {
    return {}
  },
  computed: {
    petCount() {
      if (this.pets && this.pets.count) {
        return this.pets.count || 0
      }
      return 0
    }
  },

  methods: {
    getStatusIcon(value) {
      switch (value) {
        case 1:
          return require("@/assets/icons/medal-gold.svg");

        case 2:
          return require("@/assets/icons/medal-silver.svg");

        case 3:
          return require("@/assets/icons/medal-bronze.svg");
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.pet-search-result {
  .header {
    margin-bottom: 5px;
  }
  .content {
    display: flex;
    flex-wrap: wrap;
  }

  .footer {
    padding: 20px;
    text-align: center;
  }
}

.pet-table {
  width: 100%;
  border-collapse: collapse;

  a {
    color: #333;
    text-decoration: none;
    &:hover {
      color: #02b0e1;
    }
  }

  thead {
    tr {
      border: #e6eaee 1px solid;

      th {
        padding: 10px 5px;
        font-weight: normal;
        color: #7f8fa4;
        background-color: #f5f8fa;
        font-size: 0.9em;

        &.hn {
          width: 50px;
        }

        &.name {
          width: 190px;
        }

        &.type {
          width: 100px;
        }

        &.action {
          width: 120px;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        position: relative;
        padding: 10px 5px;
        font-size: 0.9em;
        border-bottom: 1px solid #eee;

        .subtitle {
          color: #666;
        }

        .thumbnail {
          display: inline-block;
          width: 50px;
          height: 50px;
          overflow: hidden;
          background: #eee;
          border-radius: 5px;
          margin-right: 10px;
          vertical-align: middle;

          > img {
            width: 50px;
            height: 50px;
            border-radius: 5px;
          }
        }

        .pet-info {
          display: inline-block;
          width: calc(100% - 95px);
          vertical-align: middle;
        }

        .gender-icon {
          margin: 0 5px;
          height: 20px;
          vertical-align: middle;
          opacity: 0.5;
        }

        .blankLeft {
          margin-left: 34px;
        }
      }
    }
  }
}

.total {
  color: $light-blue;
  font-weight: bold;
}

.not-found {
  display: flex;
  flex: 1;
  padding: 20px 0 0 0;
  text-align: center;
  justify-content: center;
}

.pagination {
  margin-top: 20px;
  text-align: right;
}
</style>
