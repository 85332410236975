<template>
    <div class="social-button" v-if="profile">
        <div>
            <img v-if="profile.is_bind_messenger" src="@/assets/icons/icon-messenger.png" class="social-icon">
            <img v-if="profile.is_bind_line" src="@/assets/icons/icon-line.png" class="social-icon">
            <img v-if="profile.is_bind_onesignal" src="@/assets/icons/icon-onesignal.png" class="social-icon">
        </div>
        <template v-if="!isHospital && !isRegisterPage">
            <div class="social-info" v-if="!(profile.is_bind_messenger && profile.is_bind_line && profile.is_bind_onesignal)">
                * กรุณาเชื่อมต่อโปรแกรมเพื่อรับการแจ้งเตือน
            </div>
            <div class="social-btn" v-if="!profile.is_bind_messenger">
                <el-button class="messenger" type="primary" @click="Messenger">Connect with Messenger</el-button>
            </div>
            <div class="social-btn" v-if="!profile.is_bind_line">
                <el-button class="line" type="success" @click="Line">Connect with Line</el-button>
            </div>
            <!-- <div class="social-btn" v-if="!profile.is_bind_onesignal">
                <el-button class="webpush" type="danger">Website Notification</el-button>

            </div> -->
            <div class="social-btn">
                <div class='onesignal-customlink-container social-btn'></div>
            </div>
        </template>

        <template v-else-if="!isHospital">
            <el-form>
            <el-form-item v-if="!profile.is_bind_messenger">
                <span class="form-label">Messenger</span>
                <el-button class="connect-btn" type="primary" @click="Messenger">Connect with Messenger</el-button>
            </el-form-item>

            <el-form-item v-if="!profile.is_bind_line">
                <span class="form-label">Line</span>
                <el-button class="connect-btn" type="success" @click="Line">Connect with Line</el-button>
            </el-form-item>

            <el-form-item v-if="!profile.is_bind_onesignal">
                <span class="form-label">One Signal</span>
                <div class='onesignal-customlink-container social-btn'></div>
            </el-form-item>
            </el-form>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        profile: {
            type: Object,
            default: null,
        },
        isHospital: {
            default: false,
            type: Boolean
        },
        isRegisterPage: {
            default: false,
            type: Boolean
        },
    },
    methods: {
        Line() {
            const redirect_uri = `https://${window.location.hostname}/pet-owner/me/line`;
            const state = Math.random().toString(36).substring(2, 15);
            window.location = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1568451363&redirect_uri=${redirect_uri}&state=${state}&scope=openid%20profile&bot_prompt=aggressive`;
        },
        Messenger() {
            window.location = `https://www.messenger.com/t/1807293722714658`;
        },
    },
    async mounted() {
        var OneSignal = window.OneSignal || [];
        OneSignal.push(function() {
            // eslint-disable-next-line
            OneSignal.on('subscriptionChange', function (isSubscribed) {
                OneSignal.push(function() {
                    OneSignal.getUserId(function(userId) {
                        const redirect_uri = `https://${window.location.hostname}/pet-owner/me/onesignal?userId=${userId}`;
                        window.location = redirect_uri;
                    });
                });
            });
        });
    }
}
</script>


<style lang="scss" scoped>
.social-icon {
  display: inline;
  width: 40px;
  height: 40px;
  object-fit: contain;
  &:not(:last-child) {
      margin-right: 3px;
  }
  margin-bottom: 8px;
}

.social-info {
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;

  margin-bottom: 7px
}

.social-button {
    max-width: 400px;
}
button {
    width: 100%
}
</style>

<style lang="scss">
.social-button {
    .social-btn {
        .el-button {
            height: 50px;
            border-radius: 5px;
            border: solid 2px #e7e7e7;
            margin-bottom: 8px;
        }
        .messenger {
            background-color: #0384ff;
            &:hover {
                background-color: #0399ff;
            }
        }
        .line {
            background-color: #02bb26;
            &:hover {
                background-color: #02ce2a;
            }
        }
        .webpush {
            background-color: #e54b4d;
            &:hover {
                background-color: #e75c5e;
            }
        }
    }

    div.el-form-item {
        margin-bottom: 8px;
    }

    div.el-form-item__content {
        line-height: normal;
        text-align: left;
    }
}
</style>
