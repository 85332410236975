<template>
  <div>
    <Subnavbar>
      <template slot="title">Doctor’s Fee (DF)</template>
      <template slot="actions">
    
      </template>
    </Subnavbar>
    <DoctorFeeSearch
      :startDate="searchForm.start"
      :endDate="searchForm.end"
      @search="handleSearch"
    />
    <ContentBox>
      <template v-if="items && items.results">
        <DoctorFeeTable
          :items="items.results"
          :total="items.count"
          :current-page="currentPage"
          @current-change="pageChange"
          @seeDetail="handleSeeDetail"
        />
      </template>
    </ContentBox>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { startToday, endToday } from '@/utils/datetime'
import ContentBox from "@/components/container/ContentBox";
import Subnavbar from "@/components/navigation/Subnavbar";
import DoctorFeeTable from "@/components/income-expense/DoctorFeeTable";
import DoctorFeeSearch from "@/components/income-expense/DoctorFeeSearch";


export default {
  components: {
    ContentBox,
    Subnavbar,
    DoctorFeeTable,
    DoctorFeeSearch
  },
  data() {
    return {
      currentPage: 1,
      searchForm: {
        start: startToday(),
        end: endToday(),
      }
    };
  },
  computed: {
    ...mapState({
      items: state => state.incomeExpense.doctorsfee
    })
  },
  methods: {
    handleSearch(form) {
      let query = {}
      this.currentPage = 1
      query['page'] = 1
      if (form.start && form.start !== '') query['start'] = form.start
      if (form.end && form.end !== '') query['end'] = form.end
  
      this.fetchDoctorsFee(query)
    },
    pageChange(page) {
      let query = this.getQueryString()
      query = { ...query, page }
      this.currentPage = page
      this.fetchAppointments(query)
    },
    getQueryString() {
      let query = {}

      if (this.$route.query.page) {
        query["page"] = parseInt(this.$route.query.page)
        this.currentPage = parseInt(this.$route.query.page)
      } else {
        this.currentPage = 1
      }

      if (this.$route.query.start) {
        const start = new Date(parseInt(this.$route.query.start))
        query["start"] = start
        this.searchForm.start = start
      }

      if (this.$route.query.end) {
        const end = new Date(parseInt(this.$route.query.end))
        query["end"] = end
        this.searchForm.end = end
      }

      return query
    },
    async fetchDoctorsFee (query) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        await this.$store.dispatch("incomeExpense/listDoctorsFee", {
          query
        })

        let urlQuery = { ...query }
        if (query.start) urlQuery['start'] = query.start.getTime()
        if (query.end) urlQuery['end'] = query.end.getTime()

        this.$router.replace({ query: urlQuery })
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg")
      }
    },
    handleSeeDetail(item) {
      this.$router.push({
        name: 'df-detail', 
        params: { id: item.vet.id }, 
        query: {start: this.$route.query.start, end: this.$route.query.end} 
      })
    },
  },
  async created() {
    this.handleSearch(this.searchForm);
  }
};
</script>