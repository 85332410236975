<template>
  <div>

    <!-- Sub Navbar -->
    <Subnavbar>
      <template slot="breadcrumb">
      </template>
      <template slot="title">BLOOD INVENTORY</template>
      <template slot="actions" class="action-wrapper">
        <button class="btn btn-large btn-add-storage" @click="isAddBloodBagDialogShown = true"
                v-if="stock !== bloodStockType.HISTORY">
          <i class="fa fa-plus"></i>Add
        </button>
        <button class="btn btn-large btn-remove-storage" @click="isRemoveBloodBagDialogShown = true"
                v-if="stock !== bloodStockType.HISTORY">
          <i class="fa fa-minus"></i>Remove
        </button>
      </template>
    </Subnavbar>

    <!-- Navigation -->
    <el-row :gutter="10">
      <el-col>
        <ContentBox class="blood-navigation">
          <h2 class="title">Navigation</h2>
          <el-row>
            <el-col :md="8">
              <el-radio-group v-model="computedStock" size="small">
                <el-radio-button label="In Our Stock"></el-radio-button>
                <el-radio-button label="Public Stock"></el-radio-button>
                <el-radio-button label="History"></el-radio-button>
              </el-radio-group>
            </el-col>

            <el-col :md="6">
              <el-select
                placeholder="Pet Type"
                v-model="petType"
                size="small"
                :clearable="false"
              >
                <el-option :value="petTypeEnum.CAT" label="CAT"></el-option>
                <el-option :value="petTypeEnum.DOG" label="DOG"></el-option>
              </el-select>
            </el-col>

            <el-col :md="8">
<!--              <el-input placeholder="Blood Bag ID" size="small" v-model="bloodBagSearchText" clearable>-->
<!--              </el-input>-->
              <el-input
                placeholder="Blood Bag ID"
                v-model="bloodBagSearchText"
                class="search-input"
                size="small"
                clearable
                @keyup.enter.native="search"
                @clear="search"
              >
                <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
              </el-input>
            </el-col>
          </el-row>
        </ContentBox>
      </el-col>
    </el-row>

    <!-- Dashboard -->
    <el-row :gutter="10" v-show="stock !== bloodStockType.HISTORY">
      <el-col :md="12">
        <!-- Chart -->
        <ContentBox style="height: 100%">
          <BloodInventoryChart :summary-table-data="summaryTableData"></BloodInventoryChart>
        </ContentBox>
      </el-col>

      <el-col :md="12">
        <!-- Summary Table -->
        <ContentBox style="height: 100%">
          <BloodInventorySummaryTable :summary-table-data="summaryTableData"></BloodInventorySummaryTable>
        </ContentBox>
      </el-col>
    </el-row>

    <!-- Tabs -->
    <el-row :gutter="10">
      <el-col>
        <ContentBox style="height: 100%; margin-top: 10px">
          <div class="table-container">
            <div class="table-container-header">
              <span class="total">{{ count | number }}</span> RESULT
            </div>

            <div class="table-detail">
              <BloodInventoryPersonalTable v-if="stock === bloodStockType.PERSONAL" :blood-bags="computedSearchedPaginatedBloodBags" @detail="showDetail">
              </BloodInventoryPersonalTable>

              <BloodInventoryPublicTable v-if="stock === bloodStockType.PUBLIC" :blood-bags="computedSearchedPaginatedBloodBags" @detail="showDetail">
              </BloodInventoryPublicTable>

              <BloodInventoryHistoryTable v-if="stock === bloodStockType.HISTORY" :blood-bags="computedSearchedPaginatedBloodBags" @detail="showDetail">
              </BloodInventoryHistoryTable>
            </div>

            <div class="not-found" v-if="count === 0">ไม่พบข้อมูลที่ต้องการค้นหา</div>

            <el-pagination
              class="pagination"
              background
              layout="prev, pager, next"
              :current-page="currentPage"
              :page-size="pageSize"
              :total="count"
              @current-change="changePage"
            ></el-pagination>
          </div>
        </ContentBox>
      </el-col>
    </el-row>

    <!-- Modals -->
    <at-modal v-model="isAddBloodBagDialogShown" :showClose="false" :maskClosable="false" :show-footer="false">
      <div slot="header">
        <el-row type="flex" align="middle">
          <el-col :span="12">
            Add Blood Bag
          </el-col>

          <el-col :span="12" class="text-right">
            <el-button class="btn btn-large btn-plain hoverable" @click="resetAddBloodBagObject(); isAddBloodBagDialogShown = false;">Cancel</el-button>
            <el-button class="btn-large" type="primary" @click="addBloodBag" icon="fa fa-save">Save</el-button>
          </el-col>
        </el-row>
      </div>

      <div class="at-container">
        <!-- <div class="row">
          <div class="col-md-10 flex-end flex-middle" style="padding: 5px;">
            <label>From:</label>
          </div>

          <div class="col-md-14 flex-start flex-middle" style="padding: 5px;">
            <at-select disabled v-model="addBloodBagObj.givenByHospital" style="width:220px">
              <at-option v-for="(hospital, idx) in hospitals" :key="idx" v-bind:value="hospital.id">{{hospital.name}}</at-option>
              <at-option :value="'OTHER'">อื่น ๆ</at-option>
            </at-select>
          </div>
        </div> -->
        <div class="row">
          <div class="col-md-10 flex-end flex-middle" style="padding: 7px; padding-right: 5px;">
            <label>SPBB BloodBag ID (optional):</label>
          </div>

          <div class="col-md-14 flex-start flex-middle">
            <!-- <at-input v-model="addBloodBagObj.spbb_bloodbag_id" size="small" style="width: 220px;"></at-input> -->
            <!-- <at-select v-model="addBloodBagObj.spbb_id" style="width: 220px;" @on-change="onSPBBSelected" clearable :disabled="addSpbbSelectableBags.length <= 0">
              <at-option v-for="(bag,idx) in addSpbbSelectableBags" :key="idx" :value="bag.id">{{ bag.id }} [ {{ bag.bloodType }} - {{ bag.quantity }} ml. ]</at-option>
            </at-select> -->
            <div class="row" style="padding-left: 5px;">
              <at-input v-model="addBloodBagObj.spbb_id" style="width: 105px;"></at-input>
              <at-button type="primary" icon="icon-search" style="margin-left: 10px;" @click="onSPBBSelected"></at-button>
              <at-button type="error" icon="icon-x" style="margin-left: 10px;" @click="resetAddBloodBagObject"></at-button>
            </div>
          </div>
        </div>

        <div class="row" v-if="addBloodBagObj.given_by_hospital === 'OTHER'">
          <div class="col-md-10 flex-end flex-middle" style="padding: 5px;">
            <label>From Hospital Name: <span style="color: red;">*</span> </label>
          </div>

          <div class="col-md-14 flex-start flex-middle" style="padding: 5px;">
            <at-input v-validate="'required'" v-model="addBloodBagObj.other_hospital_name" placeholder="โปรดระบุชื่อโรงพยาบาล ..." size="small" style="width: 220px;"></at-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-10 flex-end flex-middle" style="padding: 5px;">
            <label>Donor:</label>
          </div>

          <div class="col-md-14 flex-start flex-middle" style="padding: 5px; padding-top: 0px;">
            <at-radio-group v-model="addBloodBagObj.pet_type" @radio-group-change="onAddBloodBagPetTypeChange">
              <at-radio label="DOG">Dog</at-radio>
              <at-radio label="CAT" style="margin-left: 15px">Cat</at-radio>
            </at-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-md-10 flex-end flex-middle" style="padding: 7px; padding-right: 5px;">
            <label>Blood Group: <span style="color: red;">*</span> </label>
          </div>

          <div  class="col-md-14 flex-start flex-middle" style="padding: 5px;">
            <at-select v-model="addBloodBagObj.blood_group"  v-validate="'required'" style="width:220px">
              <at-option value="unknown">ไม่ระบุ</at-option>
              <at-option v-show="(addBloodBagObj.pet_type == petTypeEnum.DOG && dogBloodGroups.indexOf(bloodGroup) >= 0) ||
                   (addBloodBagObj.pet_type == petTypeEnum.CAT && catBloodGroups.indexOf(bloodGroup) >= 0)"
                         v-for="(bloodGroup, idx) in allBloodGroups" :key="idx" v-bind:value="bloodGroup">{{bloodGroup}}</at-option>
            </at-select>
          </div>
        </div>

        <div class="row">
          <div class="col-md-10 flex-end flex-middle" style="padding: 7px; padding-right: 5px;">
            <label>Type:</label>
          </div>

          <div class="col-md-14 flex-start flex-middle" style="padding: 5px;">
            <at-select v-model="addBloodBagObj.blood_type" style="width:220px">
              <at-option :value="bloodType.FWB">Fresh Whole Blood (FWB)</at-option>
              <at-option :value="bloodType.SWB">Stored Whole Blood (SWB)</at-option>
              <at-option :value="bloodType.PRBCS">Packed RBCs RBCs (pRBCs)</at-option>
              <at-option :value="bloodType.PRP">Platelet Rich Plasma (PRP)</at-option>
              <at-option :value="bloodType.FFP">Fresh Frozen Plasma (FFP)</at-option>
              <at-option :value="bloodType.FP">Fresh Plasma (FP)</at-option>
            </at-select>
          </div>
        </div>

        <div class="row">
          <div class="col-md-10 flex-end flex-middle" style="padding: 7px; padding-right: 5px;">
            <label>PCV : <span style="color: red;">*</span> </label>
          </div>

          <div class="col-md-14 flex-start flex-middle" style="padding: 5px;">
            <at-input v-model="addBloodBagObj.PCV"  v-validate="'required'" style="width:220px"></at-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-10 flex-end flex-middle" style="padding: 7px; padding-right: 5px;">
            <label>Amount: <span style="color: red;">*</span></label>
          </div>

          <div class="col-md-14 flex-start flex-middle" style="padding: 5px;">
            <div class="row" style="margin-left: 0px;">
              <at-input v-validate="'required|decimal'" v-model="addBloodBagObj.original_quantity" size="small" style="width: 220px;"></at-input>
              <label style="padding: 3px;">ml.</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-10 flex-end flex-middle" style="padding: 5px; padding-top: 7px;">
            <label>Collect Date/Time:</label>
          </div>

          <div class="col-md-14 flex-start flex-middle" style="padding: 5px;">
            <el-date-picker size="mini" v-model="addBloodBagObj.collection_date" type="datetime" placeholder="Select date"></el-date-picker>
          </div>
        </div>

        <div class="row">
          <div class="col-md-10 flex-end flex-middle" style="padding: 5px; padding-top: 7px;">
            <label>Expiry Date: <span style="color: red;">*</span></label>
          </div>

          <div class="col-md-14 flex-start flex-middle" style="padding: 5px;">
            <el-date-picker size="mini" v-validate="'required'" v-model="addBloodBagObj.expiry_date" type="date" placeholder="Select date"></el-date-picker>
          </div>
        </div>

        <!-- <div class="row">
          <div class="col-md-10 flex-end flex-middle" style="padding: 7px; padding-right: 5px;">
            <label>SPBB BloodBag ID (optional):</label>
          </div>

          <div class="col-md-14 flex-start flex-middle" style="padding: 5px; display:">
            <at-input v-model="addBloodBagObj.spbb_id" size="small" style="width: 220px;"></at-input>
          </div>
        </div> -->

        <div class="row">
          <div class="col-md-10 flex-end flex-middle" style="padding: 7px; padding-right: 5px;">
            <label>Other BloodBag ID (optional):</label>
          </div>

          <div class="col-md-14 flex-start flex-middle" style="padding: 5px;">
            <at-input v-model="addBloodBagObj.existing_bloodbag_id" size="small" style="width: 220px;"></at-input>
          </div>
        </div>
      </div>
    </at-modal>

    <at-modal v-model="isRemoveBloodBagDialogShown" :showClose="false" :maskClosable="false" :show-footer="false">
      <div slot="header">
        <el-row type="flex" align="middle">
          <el-col :span="12">
            Remove Blood Bag
          </el-col>

          <el-col :span="12" class="text-right">
            <el-button class="btn btn-large btn-plain hoverable" @click="isRemoveBloodBagDialogShown = false">Cancel</el-button>
            <el-button class="btn-large" icon="fa fa-save" @click="removeBloodBag" type="primary">Save</el-button>
          </el-col>
        </el-row>
      </div>

      <div class="at-container">
        <div class="row">
          <div class="col-md-10 flex-end flex-middle" style="padding: 5px; padding-top: 7px;">
            <label>Blood Bag ID:</label>
          </div>

          <div class="col-md-14 flex-start flex-middle" style="padding: 5px;">
            <at-select v-model="removeBloodBagObj.bloodBagId" style="width:220px">
              <at-option v-for="bloodBag in sortedIdBloodBags" :key="bloodBag.id" v-bind:value="bloodBag.id">{{bloodBag.id}}</at-option>
            </at-select>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class="col-md-10 flex-end flex-middle" style="padding: 5px;">
            <label>Reason of Removal:</label>
          </div>

          <div class="col-md-14 flex-start flex-middle">
            <div class="row no-glutter" style="padding: 5px;">
              <at-radio v-model="removeBloodBagObj.reasonOfRemoval" label="EXPIRE">หมดอายุ</at-radio>
            </div>
            <div class="row no-glutter" style="padding: 5px;">
              <at-radio v-model="removeBloodBagObj.reasonOfRemoval" label="GIVE_AWAY" style="margin-top: 3px;">ให้โรงพยาบาล</at-radio>

              <div v-if="removeBloodBagObj.reasonOfRemoval === removalBloodBagReasonEnum.GIVE_AWAY" class="row no-glutter" style="padding: 5px;">
                <at-select v-model="removeBloodBagObj.hospital" style="width:220px">
                  <at-option v-for="(hospital, idx) in otherHospitals" :key="idx" v-bind:value="hospital.id"  >{{hospital.name}}</at-option>
                  <at-option value="OTHER">อื่น ๆ</at-option>
                </at-select>
              </div>
              <at-input v-if="removeBloodBagObj.reasonOfRemoval === removalBloodBagReasonEnum.GIVE_AWAY && removeBloodBagObj.hospital === 'OTHER'" placeholder="ชื่อโรงพยาบาล" v-model="removeBloodBagObj.givenHospitalName" size="small"></at-input>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-10 flex-end flex-middle" style="padding: 5px; padding-top: 7px;">
            <label>หมายเหตุ:</label>
          </div>

          <div class="col-md-14 flex-start flex-middle" style="padding: 5px;">
            <!--<at-textarea v-if="isRemoveBloodBagDialogShown" v-model="removeBloodBagObj.note" max-rows="1"></at-textarea>-->
            <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 3}" placeholder="บันทึกเพิ่มเติม ..." v-model="removeBloodBagObj.note"></el-input>
          </div>
        </div>
      </div>
    </at-modal>
  </div>
</template>

<script>
  import {mapState, mapGetters} from 'vuex'
  import Subnavbar from '../../../components/navigation/Subnavbar'
  import ContentBox from '../../../components/container/ContentBox'
  import Widget from '../../../components/bloodbank/Widget'
  import BloodInventoryChart from '../../../components/bloodbank/blood-storage/BloodInventoryChart'
  import bloodType from '../../../constants/bloodbank/blood-product-type-enum'
  import bloodBagStatus from '../../../constants/bloodbank/blood-storage-bag-status'
  import BloodInventorySummaryTable from '../../../components/bloodbank/blood-storage/BloodInventorySummaryTable'
  import bloodStockType from '../../../constants/bloodbank/pet-storage-stock-type'
  import petTypeEnum from '../../../constants/bloodbank/pet-type-enum'
  import BloodInventoryPersonalTable from '../../../components/bloodbank/blood-storage/BloodInventoryPersonalTable'
  import BloodInventoryPublicTable from '../../../components/bloodbank/blood-storage/BloodInventoryPublicTable'
  import BloodInventoryHistoryTable from '../../../components/bloodbank/blood-storage/BloodInventoryHistoryTable'
  import removalBloodBagReasonEnum from '../../../constants/bloodbank/removal-blood-bag-reason'
  import utils from '../../../utils/bloodbank'
  import BloodBag from '../../../models/data/blood-storage/blood-bag/blood-bag'
  import BloodBagHistoryInfo from '../../../models/data/blood-storage/blood-bag-history-info/blood-bag-history-info'
  import bloodCollectionHistoryActionEnums from '../../../constants/bloodbank/blood-collect-history-action.js'
  import contentTypeEnums from '../../../constants/bloodbank/content-type'

  export default {
    name: "BloodInventoryPage",
    components: {
      BloodInventoryHistoryTable,
      BloodInventoryPublicTable,
      BloodInventoryPersonalTable,
      BloodInventorySummaryTable,
      BloodInventoryChart,
      Widget,
      Subnavbar,
      ContentBox
    },
    data () {
      return {
        bloodStockType,
        petTypeEnum,
        bloodType,
        removalBloodBagReasonEnum,
        bloodCollectionHistoryActionEnums,
        contentTypeEnums,

        stock: bloodStockType.PERSONAL,
        petType: petTypeEnum.DOG,
        bloodBagSearchText: null,
        currentPage: 1,
        pageSize: 10,

        summaryTableData: {
          FWB: {
            all: 0,
            allQuantity: 0,
            reserve: 0,
            reserveQuantity: 0,
            available: 0,
            availableQuantity: 0
          },
          SWB: {
            all: 0,
            allQuantity: 0,
            reserve: 0,
            reserveQuantity: 0,
            available: 0,
            availableQuantity: 0
          },
          PRBCS: {
            all: 0,
            allQuantity: 0,
            reserve: 0,
            reserveQuantity: 0,
            available: 0,
            availableQuantity: 0
          },
          PRP: {
            all: 0,
            allQuantity: 0,
            reserve: 0,
            reserveQuantity: 0,
            available: 0,
            availableQuantity: 0
          },
          FFP: {
            all: 0,
            allQuantity: 0,
            reserve: 0,
            reserveQuantity: 0,
            available: 0,
            availableQuantity: 0
          },
          FP: {
            all: 0,
            allQuantity: 0,
            reserve: 0,
            reserveQuantity: 0,
            available: 0,
            availableQuantity: 0
          }
        },

        isAddBloodBagDialogShown: false,
        addBloodBagObj: {
          hospital: null,
          other_hospital_name: '',
          pet_type: petTypeEnum.DOG,
          blood_type: bloodType.FWB,
          original_quantity: '',
          collected_quantity: '',
          collection_date: new Date(),
          expiry_date: '',
          stock: bloodStockType.PERSONAL,
          spbb_bloodbag_id: '',
          other_bloodbag_id: '',
          PCV: '',
          blood_group: '',
          given_by_hospital: 'OTHER'
        },
        isRemoveBloodBagDialogShown: false,
        removeBloodBagObj: {
          bloodBagId: '',
          reasonOfRemoval: removalBloodBagReasonEnum.EXPIRE,
          hospital: 'OTHER',
          givenHospitalName: '',
          note: ''
        },
        dogBloodGroups: ['DEA 1.1 +', 'DEA 1.1 -'],
        catBloodGroups: ['A', 'B', 'AB'],
        allBloodGroups: ['DEA 1.1 +', 'DEA 1.1 -', 'A', 'B', 'AB'],
        isSPBBInprogress: false,
      }
    },
    methods: {
      search() {
        this.changePage(1)
      },
      changePage(page) {
        this.currentPage = page
        this.updateQueryString()
      },
      updateQueryString() {
        const query = {
          stock: this.stock,
          pet_type: this.petType
        }
        if (this.currentPage && this.currentPage > 1) {
          query.page = this.currentPage
        }
        if (this.bloodBagSearchText && this.bloodBagSearchText !== '') {
          query.search = this.bloodBagSearchText
        }

        this.$router.replace({query})
      },
      loadQueryString() {
        if (this.$route.query.page) {
          this.currentPage = Number(this.$route.query.page)
        }
        if (this.$route.query.search) {
          this.bloodBagSearchText = this.$route.query.search
        }
        if (this.$route.query.stock) {
          this.stock = this.$route.query.stock
        }
        if (this.$route.query.pet_type) {
          this.petType = this.$route.query.pet_type
        }
      },

      loadSummaryTableData() {
        const bloodBagList = this.computedBloodBags

        var numberOfAvailableFWB = 0
        var numberOfAvailableSWB = 0
        var numberOfAvailablePRBCS = 0
        var numberOfAvailablePRP = 0
        var numberOfAvailableFFP = 0
        var numberOfAvailableFP = 0

        var numberOfReserveFWB = 0
        var numberOfReserveSWB = 0
        var numberOfReservePRBCS = 0
        var numberOfReservePRP = 0
        var numberOfReserveFFP = 0
        var numberOfReserveFP = 0

        var quantityOfAvailableFWB = 0
        var quantityOfAvailableSWB = 0
        var quantityOfAvailablePRBCS = 0
        var quantityOfAvailablePRP = 0
        var quantityOfAvailableFFP = 0
        var quantityOfAvailableFP = 0

        var quantityOfReserveFWB = 0
        var quantityOfReserveSWB = 0
        var quantityOfReservePRBCS = 0
        var quantityOfReservePRP = 0
        var quantityOfReserveFFP = 0
        var quantityOfReserveFP = 0

        if (bloodBagList) {
          bloodBagList.forEach(function (bag) {
            switch (bag.bloodType) {
              case bloodType.FWB:
                if (bag.status === bloodBagStatus.AVAILABLE) {
                  numberOfAvailableFWB++
                  quantityOfAvailableFWB += Number(bag.quantity)
                } else if (bag.status === bloodBagStatus.RESERVE) {
                  numberOfReserveFWB++
                  quantityOfReserveFWB += Number(bag.quantity)
                }
                break
              case bloodType.SWB:
                if (bag.status === bloodBagStatus.AVAILABLE) {
                  numberOfAvailableSWB++
                  quantityOfAvailableSWB += Number(bag.quantity)
                } else if (bag.status === bloodBagStatus.RESERVE) {
                  numberOfReserveSWB++
                  quantityOfReserveSWB += Number(bag.quantity)
                }
                break
              case bloodType.PRBCS:
                if (bag.status === bloodBagStatus.AVAILABLE) {
                  numberOfAvailablePRBCS++
                  quantityOfAvailablePRBCS += Number(bag.quantity)
                } else if (bag.status === bloodBagStatus.RESERVE) {
                  numberOfReservePRBCS++
                  quantityOfReservePRBCS += Number(bag.quantity)
                }
                break
              case bloodType.PRP:
                if (bag.status === bloodBagStatus.AVAILABLE) {
                  numberOfAvailablePRP++
                  quantityOfAvailablePRP += Number(bag.quantity)
                } else if (bag.status === bloodBagStatus.RESERVE) {
                  numberOfReservePRP++
                  quantityOfReservePRP += Number(bag.quantity)
                }
                break
              case bloodType.FFP:
                if (bag.status === bloodBagStatus.AVAILABLE) {
                  numberOfAvailableFFP++
                  quantityOfAvailableFFP += Number(bag.quantity)
                } else if (bag.status === bloodBagStatus.RESERVE) {
                  numberOfReserveFFP++
                  quantityOfReserveFFP += Number(bag.quantity)
                }
                break
              case bloodType.FP:
                if (bag.status === bloodBagStatus.AVAILABLE) {
                  numberOfAvailableFP++
                  quantityOfAvailableFP += Number(bag.quantity)
                } else if (bag.status === bloodBagStatus.RESERVE) {
                  numberOfReserveFP++
                  quantityOfReserveFP += Number(bag.quantity)
                }
                break
              default:
                break
            }
          })
        }

        const summaryTableData = {
          FWB: {},
          SWB: {},
          PRBCS: {},
          PRP: {},
          FFP: {},
          FP: {}
        }

        summaryTableData.FWB.all = numberOfAvailableFWB + numberOfReserveFWB
        summaryTableData.SWB.all = numberOfAvailableSWB + numberOfReserveSWB
        summaryTableData.PRBCS.all = numberOfAvailablePRBCS + numberOfReservePRBCS
        summaryTableData.PRP.all = numberOfAvailablePRP + numberOfReservePRP
        summaryTableData.FFP.all = numberOfAvailableFFP + numberOfReserveFFP
        summaryTableData.FP.all = numberOfAvailableFP + numberOfReserveFP

        summaryTableData.FWB.reserve = numberOfReserveFWB
        summaryTableData.SWB.reserve = numberOfReserveSWB
        summaryTableData.PRBCS.reserve = numberOfReservePRBCS
        summaryTableData.PRP.reserve = numberOfReservePRP
        summaryTableData.FFP.reserve = numberOfReserveFFP
        summaryTableData.FP.reserve = numberOfReserveFP

        summaryTableData.FWB.available = numberOfAvailableFWB
        summaryTableData.SWB.available = numberOfAvailableSWB
        summaryTableData.PRBCS.available = numberOfAvailablePRBCS
        summaryTableData.PRP.available = numberOfAvailablePRP
        summaryTableData.FFP.available = numberOfAvailableFFP
        summaryTableData.FP.available = numberOfAvailableFP

        summaryTableData.FWB.allQuantity = Number(quantityOfAvailableFWB) + Number(quantityOfReserveFWB)
        summaryTableData.SWB.allQuantity = Number(quantityOfAvailableSWB) + Number(quantityOfReserveSWB)
        summaryTableData.PRBCS.allQuantity = Number(quantityOfAvailablePRBCS) + Number(quantityOfReservePRBCS)
        summaryTableData.PRP.allQuantity = Number(quantityOfAvailablePRP) + Number(quantityOfReservePRP)
        summaryTableData.FFP.allQuantity = Number(quantityOfAvailableFFP) + Number(quantityOfReserveFFP)
        summaryTableData.FP.allQuantity = Number(quantityOfAvailableFP) + Number(quantityOfReserveFP)

        summaryTableData.FWB.reserveQuantity = quantityOfReserveFWB
        summaryTableData.SWB.reserveQuantity = quantityOfReserveSWB
        summaryTableData.PRBCS.reserveQuantity = quantityOfReservePRBCS
        summaryTableData.PRP.reserveQuantity = quantityOfReservePRP
        summaryTableData.FFP.reserveQuantity = quantityOfReserveFFP
        summaryTableData.FP.reserveQuantity = quantityOfReserveFP

        summaryTableData.FWB.availableQuantity = quantityOfAvailableFWB
        summaryTableData.SWB.availableQuantity = quantityOfAvailableSWB
        summaryTableData.PRBCS.availableQuantity = quantityOfAvailablePRBCS
        summaryTableData.PRP.availableQuantity = quantityOfAvailablePRP
        summaryTableData.FFP.availableQuantity = quantityOfAvailableFFP
        summaryTableData.FP.availableQuantity = quantityOfAvailableFP

        this.summaryTableData = summaryTableData
      },
      showDetail(bloodBagId) {
        this.$router.push({name: 'inventoryDetail', params: {id: bloodBagId}})
      },

      onSPBBSelected: function () {
        if (this.addBloodBagObj.spbb_id == null || this.addBloodBagObj.spbb_id === '' || Number(this.addBloodBagObj.spbb_id) === 0) {
          return
        }
        this.addBloodBagObj.spbb_id = Number(this.addBloodBagObj.spbb_id)
        this.isSPBBInprogress = true
        const bloodbag = this.$store.getters['bloodbank/getBloodBagById'](this.addBloodBagObj.spbb_id)
        const hospital = this.$store.getters['hospital/getHospitalById'](bloodbag.hospitalId)
        this.addBloodBagObj.hospital = this.profile.id
        this.addBloodBagObj.other_hospital_name = hospital.name
        this.addBloodBagObj.pet_type = bloodbag.petType
        this.addBloodBagObj.blood_type = bloodbag.bloodType
        this.addBloodBagObj.original_quantity = bloodbag.quantity
        this.addBloodBagObj.collected_quantity = bloodbag.quantity
        this.addBloodBagObj.collection_date = bloodbag.dateCollection
        this.addBloodBagObj.expiry_date = bloodbag.expiryDate
        this.addBloodBagObj.stock = bloodStockType.PERSONAL
        this.addBloodBagObj.spbb_id = this.addBloodBagObj.spbb_id
        this.addBloodBagObj.other_bloodbag_id = ''
        this.addBloodBagObj.PCV = bloodbag.pcv
        this.addBloodBagObj.given_by_hospital = 'OTHER'
        this.addBloodBagObj.alias_blood_donation_id = bloodbag.donationId > 0 ? bloodbag.donationId :
          bloodbag.alias_blood_donation_id != null ? bloodbag.alias_blood_donation_id : null

        setTimeout(() => {
          this.isSPBBInprogress = false
          this.addBloodBagObj.blood_group = bloodbag.bloodGroup == null ? 'unknown' : bloodbag.bloodGroup
          this.$forceUpdate()
        })
      },
      clearSPBB: function () {
        if (!this.isSPBBInprogress) {
          this.addBloodBagObj.spbb_id = ''
        }
      },
      resetAddBloodBagObject: function () {
        this.addBloodBagObj.hospital = this.profile.id
        this.addBloodBagObj.other_hospital_name = ''
        this.addBloodBagObj.pet_type = petTypeEnum.DOG
        this.addBloodBagObj.blood_type = bloodType.FWB
        this.addBloodBagObj.original_quantity = ''
        this.addBloodBagObj.collected_quantity = ''
        this.addBloodBagObj.collection_date = new Date()
        this.addBloodBagObj.expiry_date = ''
        this.addBloodBagObj.stock = bloodStockType.PERSONAL
        this.addBloodBagObj.spbb_bloodbag_id = ''
        this.addBloodBagObj.other_bloodbag_id = ''
        this.addBloodBagObj.PCV = ''
        this.addBloodBagObj.given_by_hospital = 'OTHER'
        this.addBloodBagObj.blood_group = undefined
        this.addBloodBagObj.alias_blood_donation_id = null
        this.addBloodBagObj.spbb_id = ''
        this.clearSPBB()
      },
      addBloodBag: function () {
        this.$validator.validateAll().then((result) => {
          if (!result) {
            this.$Notify.error({ title: 'ข้อมูลผิดพลาด', message: 'กรุณากรอกข้อมูลให้ถูกต้อง' })
            setTimeout(() => {
              this.isAddBloodBagDialogShown = true
            }, 500)
            return
          }
          this.addBloodBagObj.blood_type = utils.convertBloodTypeEnumToServiceValue(this.addBloodBagObj.blood_type)
          this.addBloodBagObj.given_by_hospital = this.addBloodBagObj.given_by_hospital === 'OTHER' ? null : this.addBloodBagObj.given_by_hospital
          this.addBloodBagObj.collected_quantity = this.addBloodBagObj.original_quantity

          const bloodBag = new BloodBag(this.addBloodBagObj)
          this.$store.dispatch('bloodbank/createBloodCollectInfo', {data: bloodBag.toRequestJson()}).then(async (result) => {
            if (result) {
              let newBloodCollectionHistoryInfo = new BloodBagHistoryInfo()
              // Set Info Here
              newBloodCollectionHistoryInfo.blood_collection = result.id
              newBloodCollectionHistoryInfo.action = this.bloodCollectionHistoryActionEnums.Collect
              newBloodCollectionHistoryInfo.content_type = this.contentTypeEnums.BloodCollection
              newBloodCollectionHistoryInfo.object_id = result.id

              // bloodCollectionHistoryInstance.addBloodBagHistoryInfo(newBloodCollectionHistoryInfo)
              await this.$store.dispatch('bloodbank/createBloodCollectionHistory', {data: newBloodCollectionHistoryInfo.toRequestJson()})
              this.resetAddBloodBagObject()

              await this.$store.dispatch("bloodbank/listAndFillBloodCollectInfo")
              await this.$store.dispatch('bloodbank/listBloodCollectionHistory')
              this.loadSummaryTableData()
            }
          })
        })
        this.isAddBloodBagDialogShown = false // Close modal
      },
      removeBloodBag: function () {
        var removeBloodBag = this.$store.getters['bloodbank/getClonedBloodBagById'](this.removeBloodBagObj.bloodBagId)
        if (this.removeBloodBagObj.reasonOfRemoval) {
          removeBloodBag.reasonOfRemoval = this.removeBloodBagObj.reasonOfRemoval
          if (this.removeBloodBagObj.reasonOfRemoval === removalBloodBagReasonEnum.GIVE_AWAY) {
            if (this.removeBloodBagObj.hospital !== 'OTHER') {
              removeBloodBag.given_by_hospital = this.profile.id
              removeBloodBag.givenToHospitalName = this.$store.getters['hospital/getHospitalById'](this.removeBloodBagObj.hospital).name
            } else {
              removeBloodBag.givenToHospitalName = this.removeBloodBagObj.givenHospitalName
            }
          }

          removeBloodBag.note = this.removeBloodBagObj.note

          let newBloodCollectionHistoryInfo = new BloodBagHistoryInfo()
          // Set Info Here
          newBloodCollectionHistoryInfo.blood_collection = this.removeBloodBagObj.bloodBagId
          newBloodCollectionHistoryInfo.action = this.removeBloodBagObj.reasonOfRemoval === removalBloodBagReasonEnum.GIVE_AWAY
            ? this.bloodCollectionHistoryActionEnums.Remove_Move
            : this.bloodCollectionHistoryActionEnums.Remove_Expire
          newBloodCollectionHistoryInfo.content_type = this.contentTypeEnums.BloodCollection
          newBloodCollectionHistoryInfo.object_id = this.removeBloodBagObj.bloodBagId

          // bloodCollectionHistoryInstance.addBloodBagHistoryInfo(newBloodCollectionHistoryInfo)
          this.$store.dispatch('bloodbank/createBloodCollectionHistory', {data: newBloodCollectionHistoryInfo.toRequestJson()})

          this.$store.dispatch('bloodbank/updateFullBloodCollectInfo', {
            id: removeBloodBag.id,
            data: removeBloodBag
          }).then(() => {
            this.$store.dispatch("bloodbank/listAndFillBloodCollectInfo").then(() => {
              this.$store.dispatch('bloodbank/listBloodCollectionHistory').then(() => {
                this.loadSummaryTableData()
              })
            })
          })
        }
        this.isRemoveBloodBagDialogShown = false // Close modal
      },
      onAddBloodBagPetTypeChange: function () {
        this.addBloodBagObj.blood_group = undefined
      }
    },
    computed: {
      ...mapState({
        profile: state => state.hospital.profile,
        hospitals: state => state.hospital.hospitals.filter(function (hospital) {
          if (hospital == state.hospital.profile.id || hospital.is_bloodbank_publish) {
            return hospital
          }
        }),
      }),
      ...mapGetters({
        getOwnedBloodBagList: 'bloodbank/getOwnedBloodBagList',
        getPublicBloodBagList: 'bloodbank/getPublicBloodBagList',
      }),
      otherHospitals() {
        if (!this.hospitals || !this.profile) {
          return []
        }
        return this.hospitals.filter(x => x.id !== this.profile.id)
      },

      computedStock: {
        get: function () {
          if (this.stock === bloodStockType.HISTORY) {
            return 'History'
          }
          return this.stock === bloodStockType.PERSONAL ? 'In Our Stock' : 'Public Stock'
        },
        set: function (value) {
          if (value === 'History') {
            this.stock = bloodStockType.HISTORY
            return
          }
          this.stock = (value === 'In Our Stock' ? bloodStockType.PERSONAL : bloodStockType.PUBLIC)
          this.changePage(1)
        }
      },

      bloodBags() {
        return this.profile ?
          this.getOwnedBloodBagList(this.profile.id)
            .sort((a,b) => a.id < b.id)
          : []
      },

      publicBloodBags() {
        var hospitals = this.hospitals
        return this.profile ? this.getPublicBloodBagList(this.profile.id)
            // .sort((a,b) => a.id < b.id)
            .filter(function (bag) {
              var hospital = hospitals.find(hospital => {
                return hospital.id === bag.hospitalId
              })

              if (hospital && hospital.is_bloodbank_publish) {
                return bag
              }
            })
            .filter(x => x.hospitalId)
            .sort((a, b) => a.hospitalId - b.hospitalId)
          : []
      },

      computedPersonalBloodBags: function () {
        if (this.bloodBags.length > 0) {
          let bloodBags = this.bloodBags.filter(function (bag) {
            let isValid = false
            if (bag.donationId) {
              let donationInfo = this.$store.getters['bloodbank/getDonationById'](bag.donationId)
              isValid = (!donationInfo || donationInfo.isBloodCollectSubmitted)
            } else {
              isValid = true
            }
            return bag.stock === bloodStockType.PERSONAL && bag.status !== bloodBagStatus.DISBURSE && bag.quantity > 0 && isValid && bag.reasonOfRemoval == null && bag.status !== bloodBagStatus.USED
          }.bind(this))
          return bloodBags
        }
      },

      computedPersonalBloodBagsFilteredByPetType: function () {
        if (this.bloodBags.length > 0) {
          let petType = this.petType
          let bloodBags = this.bloodBags.filter(function (bag) {
            let isValid = false
            if (bag.donationId) {
              let donationInfo = this.$store.getters['bloodbank/getDonationById'](bag.donationId)
              isValid = (!donationInfo || donationInfo.isBloodCollectSubmitted)
            } else {
              isValid = true
            }
            return (bag.getPetType() === petType) && bag.stock === bloodStockType.PERSONAL && bag.status !== bloodBagStatus.DISBURSE && bag.status !== bloodBagStatus.EXPIRE && bag.quantity > 0 && isValid && bag.reasonOfRemoval == null && bag.status !== bloodBagStatus.DISBURSE && bag.status !== bloodBagStatus.USED
          }.bind(this))
          return bloodBags
        }
      },

      computedPublicBloodBags: function () {
        if (this.publicBloodBags.length > 0) {
          let bloodBags = this.publicBloodBags.filter(function (bag) {
            let isValid = false
            if (bag.donationId) {
              let donationInfo = this.$store.getters['bloodbank/getDonationById'](bag.donationId)
              isValid = (!donationInfo || donationInfo.isBloodCollectSubmitted)
            } else {
              isValid = true
            }
            return bag.hospitalId !== this.profile.id && bag.status !== bloodBagStatus.DISBURSE && bag.quantity > 0 && isValid && bag.status !== bloodBagStatus.EXPIRE && bag.reasonOfRemoval == null && bag.status !== bloodBagStatus.USED
          }.bind(this))
          return bloodBags
        } else {
          return []
        }
      },

      computedPublicBloodBagsFilteredByPetType: function () {
        if (this.publicBloodBags.length > 0) {
          let petType = this.petType
          let bloodBags = this.publicBloodBags.filter(function (bag) {
            let isValid = false
            if (bag.donationId) {
              let donationInfo = this.$store.getters['bloodbank/getDonationById'](bag.donationId)
              isValid = (!donationInfo || donationInfo.isBloodCollectSubmitted)
            } else {
              isValid = true
            }
            return (bag.getPetType() === petType) && bag.hospitalId !== this.profile.id && bag.status !== bloodBagStatus.DISBURSE && bag.status !== bloodBagStatus.EXPIRE && bag.quantity > 0 && isValid && bag.reasonOfRemoval == null && bag.status !== bloodBagStatus.USED
          }.bind(this))
          return bloodBags
        }
      },

      computedHistoryBloodBags: function () {
        if (this.bloodBags.length > 0) {
          var bloodBags = this.bloodBags.filter(function (bag) {
            let isValid = false
            if (bag.donationId) {
              let donationInfo = this.$store.getters['bloodbank/getDonationById'](bag.donationId)
              isValid = (!donationInfo || donationInfo.isBloodCollectSubmitted)
            } else {
              isValid = true
            }
            return (bag.reasonOfRemoval != null || bag.quantity <= 0) && isValid || bag.status === bloodBagStatus.EXPIRE
          }.bind(this))
          return bloodBags
        }
      },

      computedHistoryBloodBagsFilteredByPetType: function () {
        if (this.bloodBags.length > 0) {
          var petType = this.petType
          var bloodBags = this.bloodBags.filter(function (bag) {
            let isValid = false
            if (bag.donationId) {
              let donationInfo = this.$store.getters['bloodbank/getDonationById'](bag.donationId)
              isValid = (!donationInfo || donationInfo.isBloodCollectSubmitted)
            } else {
              isValid = true
            }
            return (bag.getPetType() === petType) && bag.status !== bloodBagStatus.AVAILABLE && ((bag.reasonOfRemoval != null || bag.quantity <= 0 || bag.status === bloodBagStatus.USED) && isValid || bag.status === bloodBagStatus.EXPIRE)
          }.bind(this))
          return bloodBags
        }
      },

      computedBloodBags: function () {
        if (this.petType == null) {  // ALL
          if (this.stock === bloodStockType.HISTORY) {
            return this.computedHistoryBloodBags
          }
          return this.stock === bloodStockType.PERSONAL ? this.computedPersonalBloodBags : this.computedPublicBloodBags
        }

        // CAT or DOG
        if (this.stock === bloodStockType.HISTORY) {
          return this.computedHistoryBloodBagsFilteredByPetType
        }
        return this.stock === bloodStockType.PERSONAL ?
          this.computedPersonalBloodBagsFilteredByPetType : this.computedPublicBloodBagsFilteredByPetType
      },
      computedSearchedBloodBags: function () {
        return this.computedBloodBags ?
          this.computedBloodBags.filter(bag => this.$route.query.search == null || this.$route.query.search === '' || bag.id.toString().indexOf(this.$route.query.search) >= 0)
          : []
      },
      computedSearchedPaginatedBloodBags: function () {
        return this.computedSearchedBloodBags ?
          this.computedSearchedBloodBags
            .slice((this.currentPage - 1) * 10, this.currentPage * 10)
          : []
      },

      count: function () {
        return this.computedSearchedBloodBags ? this.computedSearchedBloodBags.length : 0
      },

      sortedIdBloodBags: {
        get: function () {
          let bags = this.computedPersonalBloodBags

          if (bags != null) {
            return bags.sort(function (a, b) { return a.id - b.id })
          } else {
            return [ ]
          }
        }
      },
    },
    async mounted() {
      await this.$store.dispatch("ui/setIsFullScreenLoading", true);
      this.loadQueryString()

      try {
        // List&Fill Donations
        if (this.$store.state.bloodbank.donations.length < 1) {
          await this.$store.dispatch("bloodbank/listAndFillBloodDonations")
        }
        // List&Fill Requests
        if (this.$store.state.bloodbank.requests.length < 1) {
          await this.$store.dispatch("bloodbank/listAndFillBloodRequests")
        }
        // List Vets
        if (this.$store.state.hospital.vets.length < 1) {
          await this.$store.dispatch("hospital/listVets")
        }
        // List Hospitals
        if (this.hospitals.length < 1) {
          await this.$store.dispatch('hospital/listHospitals')
        }
        // List Blood Bags
        if (this.bloodBags.length < 1) {
          await this.$store.dispatch("bloodbank/listAndFillBloodCollectInfo")
        }
      } catch (e) {
        await this.$store.dispatch("ui/showFetchErrorMsg");
      }
      await this.$store.dispatch("ui/setIsFullScreenLoading", false);

      this.loadSummaryTableData()
      this.updateQueryString()

      // set hospital in add obj
      this.addBloodBagObj.hospital = this.profile.id
    },
    watch: {
      petType: function () {
        this.loadSummaryTableData()
        this.updateQueryString()
      },
      stock: function () {
        this.loadSummaryTableData()
        this.updateQueryString()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .blood-navigation {
    .title {
      margin: 0;
      padding: 0;
      font-size: 16px;
      margin-bottom: 5px;
    }

    .row {
      margin-bottom: 10px;
    }

    .date-group {
      > span {
        padding: 10px;
      }
    }
  }

  .table-container {
    .table-container-header {
      margin-bottom: 5px;
    }

    .table-detail {
      overflow-x: auto;
    }
  }

  .not-found {
    display: flex;
    flex: 1;
    padding: 20px 0 0 0;
    text-align: center;
    justify-content: center;
  }

  .pagination {
    margin-top: 20px;
    text-align: right;
  }
</style>
