import petTypeEnum from '@/constants/bloodbank/pet-type-enum'
import bloodGroup from '@/constants/bloodbank/blood-type'
import bloodType from '@/constants/bloodbank/blood-product-type-enum'
import bloodStockType from '@/constants/bloodbank/pet-storage-stock-type'
import bloodBagStatus from '@/constants/bloodbank/blood-storage-bag-status'
import BloodBag from '../blood-storage/blood-bag/blood-bag'

export default class {
  constructor () {
    this.bloodSuggestList = []
  }

  getBloodBagById () {
    // var bloogbag = null

    // for (var itr = 0; itr < this.bloodSuggestList.length; itr++) {
    //   if (this.bloodSuggestList[itr].id === id) {
    //     bloogbag = this.bloodSuggestList[itr]
    //     break
    //   }
    // }
    // return bloodbag
    return new BloodBag(1039571029,
      345,
      2819274718, { name: 'จ๊ะเอ๋', type: petTypeEnum.CAT, id: 382193718 },
      bloodGroup.DEA1_PLUS, bloodType.FWB,
      new Date(),
      new Date(),
      bloodStockType.PUBLIC,
      bloodBagStatus.AVAILABLE, { id: 28191930, requester: { id: 123456, name: 'น.สพ.ธนพัฒน์ สุขวิสุทธิ์' }, reserveDate: new Date(2018, 2, 12) }, [
        new BloodBag(1039571030, 345, 2819274718, { name: 'จ๊ะเอ๋', type: petTypeEnum.CAT, id: 382193718 }, bloodGroup.DEA1_PLUS, bloodType.FWB, new Date(2018, 2, 14), new Date(), bloodStockType.PUBLIC, bloodBagStatus.AVAILABLE),
        new BloodBag(1039571031, 345, 2819274718, { name: 'จ๊ะเอ๋', type: petTypeEnum.CAT, id: 382193718 }, bloodGroup.DEA1_PLUS, bloodType.FWB, new Date(2018, 2, 13), new Date(), bloodStockType.PUBLIC, bloodBagStatus.AVAILABLE)
      ],
      'Suan-Pak Clinic, tel 02-333-9999'
    )
  }

  clearBloodSuggestList () {
    this.bloodSuggestList.length = 0
  }
}
