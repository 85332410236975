<template>
  <div
    class="request-step-2-exam"
    style="width: 100%"
  >
    <el-row>
      <slot
        class="headSlot"
        name="header"
        :currentProgress="this.myProgress"
        :currentText="this.myText"
        :currentVetType="myVetType"
      ></slot>
    </el-row>

    <el-row>
      <el-row class="medical-record-card">
        <el-col>
          <img
            src="@/assets/bloodbank/file.png"
            height="20"
          />
          <span>Lab Exam</span>
        </el-col>
      </el-row>
      <el-row>
        <div style="margin-top: 10px; background: #ffffff; font-size: 16px;">
          <el-card style="padding-top: 15px; padding-left: 10px; padding-right: 10px;">
            <el-card
              :no-hover="true"
              style="width: 100%;"
            >
              <h4 style="text-align: center">Complete Blood Count (CBC)</h4>

              <div
                class="row"
                style="padding-left: 15px; padding-right: 15px;"
              >
                <div class="container no-gutter">
                  <div
                    class="row"
                    style="background: #f2f2f2;"
                  >
                    <div class="col-md-12 col-xs-24 row">
                      <div class="col-md-12 col-xs-12 flex">
                        <div
                          class="row flex-middle flex-center"
                          style="height: 36px; width: 100%;"
                        >
                          <h6>Test</h6>
                        </div>
                      </div>

                      <div class="col-md-12 col-xs-12 flex">
                        <div
                          class="row flex-middle flex-center"
                          style="height: 36px; width: 100%;"
                        >
                          <h6>Result</h6>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 col-xs-hidden row">
                      <div class="col-md-12 flex">
                        <div
                          class="row flex-middle flex-center"
                          style="height: 36px; width: 100%;"
                        >
                          <h6>Test</h6>
                        </div>
                      </div>

                      <div class="col-md-12 flex">
                        <div
                          class="row flex-middle flex-center"
                          style="height: 36px; width: 100%;"
                        >
                          <h6>Result</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="col-md-12 col-xs-24 padding-slider-lab"
                      v-bind:key="index"
                      v-for="(chem, index) in chem"
                    >
                      <slider-lab
                        :pet-type='request.petInfo.type'
                        :disabled="request.isCaseClosed()"
                        :is-required="chem.isRequired"
                        v-validate="chem.isRequired ? 'required|decimal' : 'decimal'"
                        data-vv-value-path="value"
                        :data-vv-name="chem.prop"
                        :isValid="!errors.has(chem.prop)"
                        @updated="onSliderValueUpdated"
                        :input-value="chem.value"
                        :prop-name="chem.prop"
                        :prefix="chem.prefix"
                        :suffix="chem.suffix"
                        :min="chem.min[request.petInfo.id === 0 ? request.bloodCollectInfo.petType.toLowerCase() : request.petInfo.type.toLowerCase()]"
                        :max="chem.max[request.petInfo.id === 0 ? request.bloodCollectInfo.petType.toLowerCase() : request.petInfo.type.toLowerCase()]"
                      ></slider-lab>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="row">
                    <div class="col-md-12 col-xs-24 row">
                      <div class="col-md-6 col-xs-6">
                        <label>Morphology</label>
                      </div>

                      <div class="col-md-18 col-xs-18">
                        <at-select v-model="request.recipientProfileInfo.labExam.cbcMorphology" filterable size="large" style="width: 100%;">
                          <at-option :value="morphologyType.MORPHOLOGY_TYPE_A">Morphology Item No. 1</at-option>
                          <at-option :value="morphologyType.MORPHOLOGY_TYPE_B">Morphology Item No. 2</at-option>
                        </at-select>
                      </div>
                    </div>
              </div>-->
            </el-card>

            <div
              class="row"
              style="margin-top: 15px;"
            >
              <el-card
                :no-hover="true"
                style="width: 100%;"
              >
                <h4 style="text-align: center">Blood Chemistry</h4>

                <div
                  class="row"
                  style="padding-left: 15px; padding-right: 15px;"
                >
                  <div class="container no-gutter">
                    <div
                      class="row"
                      style="background: #f2f2f2;"
                    >
                      <div class="col-md-12 col-xs-24 row">
                        <div class="col-md-12 col-xs-12 flex">
                          <div
                            class="row flex-middle flex-center"
                            style="height: 36px; width: 100%;"
                          >
                            <h6>Test</h6>
                          </div>
                        </div>

                        <div class="col-md-12 col-xs-12 flex">
                          <div
                            class="row flex-middle flex-center"
                            style="height: 36px; width: 100%;"
                          >
                            <h6>Result</h6>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12 col-xs-hidden row">
                        <div class="col-md-12 flex">
                          <div
                            class="row flex-middle flex-center"
                            style="height: 36px; width: 100%;"
                          >
                            <h6>Test</h6>
                          </div>
                        </div>

                        <div class="col-md-12 flex">
                          <div
                            class="row flex-middle flex-center"
                            style="height: 36px; width: 100%;"
                          >
                            <h6>Result</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div
                    class="col-md-12 col-xs-24 padding-slider-lab"
                    v-bind:key="index"
                    v-for="(chem, index) in chem2"
                  >
                    <slider-lab
                      :pet-type='request.petInfo.type'
                      :disabled="request.isCaseClosed()"
                      v-validate="chem.isRequired ? 'require|decimal' : 'decimal'"
                      data-vv-value-path="value"
                      :data-vv-name="chem.prop"
                      :isValid="!errors.has(chem.prop)"
                      @updated="onSliderValueUpdated"
                      :prop-name="chem.prop"
                      :input-value="chem.value"
                      :prefix="chem.prefix"
                      :suffix="chem.suffix"
                      :min="chem.min[request.petInfo.id === 0 ? request.bloodCollectInfo.petType.toLowerCase() : request.petInfo.type.toLowerCase()]"
                      :max="chem.max[request.petInfo.id === 0 ? request.bloodCollectInfo.petType.toLowerCase() : request.petInfo.type.toLowerCase()]"
                    ></slider-lab>
                  </div>
                </div>
              </el-card>
            </div>

            <div
              class="row"
              style="margin-top: 15px;"
            >
              <el-card
                :no-hover="true"
                style="width: 100%;"
              >
                <h4 style="text-align: center">Test Kit</h4>
                <div class="row flex-start">
                  <label-radio-group-pair
                    :disabled="request.isCaseClosed()"
                    class="col-md-12 col-xs-24 flex-start"
                    v-if="request.petInfo.type.toLowerCase() === 'dog' || (request.bloodCollectInfo != null && request.bloodCollectInfo.petType != null && request.bloodCollectInfo.petType.toLowerCase() === 'dog')"
                    spaceSize="5px"
                    label="E.Canis/ E. ewingii Ab"
                    v-bind:model="request.recipientProfileInfo.labExam.ECanisEewingiiAb"
                    prop-name="ECanisEewingiiAb"
                    @updated="onRadioUpdated"
                    v-bind:options="radioGroupOption"
                  ></label-radio-group-pair>

                  <label-radio-group-pair
                    :disabled="request.isCaseClosed()"
                    class="col-md-12 col-xs-24 flex-start"
                    v-if="request.petInfo.type.toLowerCase() === 'dog' || (request.bloodCollectInfo != null && request.bloodCollectInfo.petType != null && request.bloodCollectInfo.petType.toLowerCase() === 'dog')"
                    spaceSize="5px"
                    label="Lyme Disease"
                    v-bind:model="request.recipientProfileInfo.labExam.LymeDisease"
                    prop-name="LymeDisease"
                    @updated="onRadioUpdated"
                    v-bind:options="radioGroupOption"
                  ></label-radio-group-pair>

                  <label-radio-group-pair
                    :disabled="request.isCaseClosed()"
                    class="col-md-12 col-xs-24 flex-start"
                    v-if="request.petInfo.type.toLowerCase() === 'dog' || (request.bloodCollectInfo != null && request.bloodCollectInfo.petType != null && request.bloodCollectInfo.petType.toLowerCase() === 'dog')"
                    spaceSize="5px"
                    label="A.phagocytophilum/a. platys Ab"
                    v-bind:model="request.recipientProfileInfo.labExam.AphagocytophilumAplatyAb"
                    prop-name="AphagocytophilumAplatyAb"
                    @updated="onRadioUpdated"
                    v-bind:options="radioGroupOption"
                  ></label-radio-group-pair>

                  <label-radio-group-pair
                    :disabled="request.isCaseClosed()"
                    class="col-md-12 col-xs-24 flex-start"
                    v-if="request.petInfo.type.toLowerCase() === 'dog' || (request.bloodCollectInfo != null && request.bloodCollectInfo.petType != null && request.bloodCollectInfo.petType.toLowerCase() === 'dog')"
                    spaceSize="5px"
                    label="Heartworm Ag"
                    v-bind:model="request.recipientProfileInfo.labExam.HeartwormAg"
                    prop-name="HeartwormAg"
                    @updated="onRadioUpdated"
                    v-bind:options="radioGroupOption"
                  ></label-radio-group-pair>

                  <label-radio-group-pair
                    :disabled="request.isCaseClosed()"
                    class="col-md-12 col-xs-24 flex-start"
                    spaceSize="5px"
                    label="Giardia Ag"
                    v-bind:model="request.recipientProfileInfo.labExam.GiardiaAg"
                    prop-name="GiardiaAg"
                    @updated="onRadioUpdated"
                    v-bind:options="radioGroupOption"
                  ></label-radio-group-pair>

                  <label-radio-group-pair
                    :disabled="request.isCaseClosed()"
                    class="col-md-12 col-xs-24 flex-start"
                    v-if="request.petInfo.type.toLowerCase() === 'dog' || (request.bloodCollectInfo != null && request.bloodCollectInfo.petType != null && request.bloodCollectInfo.petType.toLowerCase() === 'dog')"
                    spaceSize="5px"
                    label="Leptospira Ab"
                    v-bind:model="request.recipientProfileInfo.labExam.LeptospiraAb"
                    prop-name="LeptospiraAb"
                    @updated="onRadioUpdated"
                    v-bind:options="radioGroupOption"
                  ></label-radio-group-pair>

                  <label-radio-group-pair
                    :disabled="request.isCaseClosed()"
                    class="col-md-12 col-xs-24 flex-start"
                    v-if="request.petInfo.type.toLowerCase() === 'cat' || (request.bloodCollectInfo != null && request.bloodCollectInfo.petType != null && request.bloodCollectInfo.petType.toLowerCase() === 'cat')"
                    spaceSize="5px"
                    label="FIV Ab"
                    v-bind:model="request.recipientProfileInfo.labExam.FIVAb"
                    prop-name="FIVAb"
                    @updated="onRadioUpdated"
                    v-bind:options="radioGroupOption"
                  ></label-radio-group-pair>

                  <label-radio-group-pair
                    :disabled="request.isCaseClosed()"
                    class="col-md-12 col-xs-24 flex-start"
                    v-if="request.petInfo.type.toLowerCase() === 'cat' || (request.bloodCollectInfo != null && request.bloodCollectInfo.petType != null && request.bloodCollectInfo.petType.toLowerCase() === 'cat')"
                    spaceSize="5px"
                    label="FeLV Ag"
                    v-bind:model="request.recipientProfileInfo.labExam.FeLVAg"
                    prop-name="FeLVAg"
                    @updated="onRadioUpdated"
                    v-bind:options="radioGroupOption"
                  ></label-radio-group-pair>

                  <label-radio-group-pair
                    :disabled="request.isCaseClosed()"
                    class="col-md-12 col-xs-24 flex-start"
                    v-if="request.petInfo.type.toLowerCase() === 'cat' || (request.bloodCollectInfo != null && request.bloodCollectInfo.petType != null && request.bloodCollectInfo.petType.toLowerCase() === 'cat')"
                    spaceSize="5px"
                    label="Feline heartworm Ag"
                    v-bind:model="request.recipientProfileInfo.labExam.FelineHeartwormAg"
                    prop-name="FelineHeartwormAg"
                    @updated="onRadioUpdated"
                    v-bind:options="radioGroupOption"
                  ></label-radio-group-pair>

                  <label-radio-group-pair
                    :disabled="request.isCaseClosed()"
                    class="col-md-12 col-xs-24 flex-start"
                    v-if="request.petInfo.type.toLowerCase() === 'dog' || (request.bloodCollectInfo != null && request.bloodCollectInfo.petType != null && request.bloodCollectInfo.petType.toLowerCase() === 'dog')"
                    spaceSize="5px"
                    label="Parvovirus Ag"
                    v-bind:model="request.recipientProfileInfo.labExam.ParvovirusAg"
                    prop-name="ParvovirusAg"
                    @updated="onRadioUpdated"
                    v-bind:options="radioGroupOption"
                  ></label-radio-group-pair>

                  <label-radio-group-pair
                    :disabled="request.isCaseClosed()"
                    class="col-md-12 col-xs-24 flex-start"
                    spaceSize="5px"
                    label="Pancreas-specific lipase"
                    v-bind:model="request.recipientProfileInfo.labExam.PancreasSpecificLipase"
                    prop-name="PancreasSpecificLipase"
                    @updated="onRadioUpdated"
                    v-bind:options="radioGroupOption"
                  ></label-radio-group-pair>

                  <label-radio-group-pair
                    :disabled="request.isCaseClosed()"
                    class="col-md-12 col-xs-24 flex-start"
                    v-if="request.petInfo.type.toLowerCase() === 'cat' || (request.bloodCollectInfo != null && request.bloodCollectInfo.petType != null && request.bloodCollectInfo.petType.toLowerCase() === 'cat')"
                    spaceSize="5px"
                    label="NTproBNP"
                    v-bind:model="request.recipientProfileInfo.labExam.NTproBNP"
                    prop-name="NTproBNP"
                    @updated="onRadioUpdated"
                    v-bind:options="radioGroupOption"
                  ></label-radio-group-pair>
                </div>
              </el-card>
            </div>
          </el-card>
        </div>
      </el-row>
    </el-row>
  </div>
</template>

<script>
import MorphologyType from "@/constants/bloodbank/morphology-type";
import OptionEnum from "@/constants/bloodbank/lab-exam-test-kit-option-enum";
import LabelRadioPair from "@/components/bloodbank/controls/label-radio-group-pair/LabelRadioGroupPair";
import SliderLab from "@/components/bloodbank/controls/slider/lab-slider";
import ConfirmationButton from "@/components/bloodbank/controls/confirmation-button/ConfirmMationButton";
import { Validator } from "vee-validate";

export default {
  components: {
    "label-radio-group-pair": LabelRadioPair,
    "slider-lab": SliderLab,
    "confirmation-button": ConfirmationButton
  },
  props: {
    request: Object,
    mode: {
      default: "view"
    }
  },
  data() {
    return {
      myProgress: 2,
      myText: "Lab",
      myVetType: "ผู้ขอ",
      NT: OptionEnum.UNCHECKED,
      recommends: [
        "อายุอยู่ระหว่าง 1-7 ปี",
        "น้ำหนักมากกว่า 20 kg.",
        "ป้องกันวัคซีนภายใน 1 ปี",
        "ไม่กินยาในเวลา 1 เดือน"
      ],
      radioGroupOption: [
        {
          value: OptionEnum.UNCHECKED,
          label: "ไม่ได้ตรวจ"
        },
        {
          value: OptionEnum.POSITIVE,
          label: "Positive"
        },
        {
          value: OptionEnum.NEGATIVE,
          label: "Negative"
        }
      ],
      morphologyType: MorphologyType,
      note: "",
      chem: [
        {
          prefix: "PCV",
          suffix: "%",
          min: {
            dog: 39,
            cat: 28.2
          },
          max: {
            dog: 56,
            cat: 52.7
          },
          value: this.LabData["HCT"],
          prop: "HCT",
          isRequired: true
        },
        {
          prefix: "MPV",
          suffix: "fL",
          min: {
            dog: 7,
            cat: 5
          },
          max: {
            dog: 12.9,
            cat: 11.8
          },
          value: this.LabData["MPV"],
          prop: "MPV"
        },
        {
          prefix: "HgB",
          suffix: "g/L",
          min: {
            dog: 110,
            cat: 103
          },
          max: {
            dog: 190,
            cat: 162
          },
          value: this.LabData["HgB"],
          prop: "HgB"
        },
        {
          prefix: "PCT",
          suffix: "%",
          min: {
            dog: 0.14,
            cat: 0
          },
          max: {
            dog: 0.46,
            cat: 0.79
          },
          value: this.LabData["PCT"],
          prop: "PCT"
        },
        {
          prefix: "RBC",
          suffix: "10<sup>6</sup>/μL",
          min: {
            dog: 5.5,
            cat: 7.12
          },
          max: {
            dog: 8.5,
            cat: 11.46
          },
          value: this.LabData["RBC"],
          prop: "RBC"
        },
        {
          prefix: "WBC",
          suffix: "10<sup>3</sup>/μL",
          min: {
            dog: 6,
            cat: 3.9
          },
          max: {
            dog: 17,
            cat: 19
          },
          value: this.LabData["WBC"],
          prop: "WBC"
        },
        {
          prefix: "Reticulocyte",
          suffix: "10<sup>3</sup>/μL",
          min: {
            dog: 10,
            cat: 3
          },
          max: {
            dog: 110,
            cat: 50
          },
          value: this.LabData["RETIC"],
          prop: "RETIC"
        },
        {
          prefix: "NEU",
          suffix: "10<sup>3</sup>/μL",
          min: {
            dog: 3,
            cat: 2.62
          },
          max: {
            dog: 11.4,
            cat: 15.17
          },
          value: this.LabData["NEU"],
          prop: "NEU"
        },
        {
          prefix: "MCV",
          suffix: "fL",
          min: {
            dog: 62,
            cat: 39
          },
          max: {
            dog: 72,
            cat: 56
          },
          value: this.LabData["MCV"],
          prop: "MCV"
        },
        {
          prefix: "Lym",
          suffix: "10<sup>3</sup>/μL",
          min: {
            dog: 0.8,
            cat: 0.85
          },
          max: {
            dog: 5.1,
            cat: 5.88
          },
          value: this.LabData["Lym"],
          prop: "Lym"
        },
        {
          prefix: "MCH",
          suffix: "pg",
          min: {
            dog: 20,
            cat: 12.6
          },
          max: {
            dog: 25,
            cat: 16.5
          },
          value: this.LabData["MCH"],
          prop: "MCH"
        },
        {
          prefix: "Mono",
          suffix: "10<sup>3</sup>/μL",
          min: {
            dog: 0.15,
            cat: 0.04
          },
          max: {
            dog: 1.35,
            cat: 0.53
          },
          value: this.LabData["Mono"],
          prop: "Mono"
        },
        {
          prefix: "MCHC",
          suffix: "g/L",
          min: {
            dog: 300,
            cat: 285
          },
          max: {
            dog: 380,
            cat: 378
          },
          value: this.LabData["MCHC"],
          prop: "MCHC"
        },
        {
          prefix: "EOS",
          suffix: "10<sup>3</sup>/μL",
          min: {
            dog: 0.1,
            cat: 0.09
          },
          max: {
            dog: 0.75,
            cat: 2.18
          },
          value: this.LabData["Eos"],
          prop: "Eos"
        },
        {
          prefix: "RDW",
          suffix: "%",
          min: {
            dog: 11,
            cat: 14
          },
          max: {
            dog: 15.5,
            cat: 18
          },
          value: this.LabData["RDW"],
          prop: "RDW"
        },
        {
          prefix: "Baso",
          suffix: "10<sup>3</sup>/μL",
          min: {
            dog: 0,
            cat: 0
          },
          max: {
            dog: 10,
            cat: 0.1
          },
          value: this.LabData["Baso"],
          prop: "Baso"
        },
        {
          prefix: "PLT",
          suffix: "10<sup>3</sup>/μL",
          min: {
            dog: 117,
            cat: 155
          },
          max: {
            dog: 460,
            cat: 641
          },
          value: this.LabData["PLT"],
          prop: "PLT"
        },
      ],
      chem2: [
        {
          prefix: "ALP",
          suffix: "U/L",
          min: {
            dog: 5,
            cat: 12
          },
          max: {
            dog: 160,
            cat: 59
          },
          value: this.LabData["ALP"],
          prop: "ALP"
        },
        {
          prefix: "CREATININE",
          suffix: "mg/dL",
          min: {
            dog: 0.5,
            cat: 0.9
          },
          max: {
            dog: 1.5,
            cat: 2.5
          },
          value: this.LabData["CREATININE"],
          prop: "CREATININE"
        },
        {
          prefix: "ALT",
          suffix: "U/L",
          min: {
            dog: 6,
            cat: 18
          },
          max: {
            dog: 70,
            cat: 121
          },
          value: this.LabData["ALT"],
          prop: "ALT"
        },
        {
          prefix: "Cholesterol",
          suffix: "mg/dL",
          min: {
            dog: 131,
            cat: 91
          },
          max: {
            dog: 345,
            cat: 305
          },
          value: this.LabData["Cholesterol"],
          prop: "Cholesterol"
        },
        {
          prefix: "AST",
          suffix: "U/L",
          min: {
            dog: 16,
            cat: 16
          },
          max: {
            dog: 55,
            cat: 67
          },
          value: this.LabData["AST"],
          prop: "AST"
        },
        {
          prefix: "Glucose",
          suffix: "mg/dL",
          min: {
            dog: 63,
            cat: 72
          },
          max: {
            dog: 114,
            cat: 175
          },
          value: this.LabData["Glucose"],
          prop: "Glucose"
        },
        {
          prefix: "CREATINE KINASE",
          suffix: "U/L",
          min: {
            dog: 10,
            cat: 64
          },
          max: {
            dog: 200,
            cat: 440
          },
          value: this.LabData["CREATINE_KINASE"],
          prop: "CREATINE_KINASE"
        },
        {
          prefix: "Calcium",
          suffix: "mg/dL",
          min: {
            dog: 8.4,
            cat: 8
          },
          max: {
            dog: 11.8,
            cat: 12
          },
          value: this.LabData["Calcium"],
          prop: "Calcium"
        },
        {
          prefix: "GGT",
          suffix: "U/L",
          min: {
            dog: 0,
            cat: 0
          },
          max: {
            dog: 13,
            cat: 6
          },
          value: this.LabData["GGT"],
          prop: "GGT"
        },
        {
          prefix: "Phosphorus",
          suffix: "mg/dL",
          min: {
            dog: 2.5,
            cat: 2.9
          },
          max: {
            dog: 6.1,
            cat: 6.3
          },
          value: this.LabData["Phosphorus"],
          prop: "Phosphorus"
        },
        {
          prefix: "Albumin",
          suffix: "g/dL",
          min: {
            dog: 2.7,
            cat: 3.9
          },
          max: {
            dog: 3.2,
            cat: 3.9
          },
          value: this.LabData["Albumin"],
          prop: "Albumin"
        },
        {
          prefix: "TCO2 (Bicarbonate)",
          suffix: "mmol/L",
          min: {
            dog: 13,
            cat: 12
          },
          max: {
            dog: 27,
            cat: 22
          },
          value: this.LabData["TCO2_Bicarbonate"],
          prop: "TCO2_Bicarbonate"
        },
        {
          prefix: "Total Protein",
          suffix: "g/dL",
          min: {
            dog: 5.5,
            cat: 6.3
          },
          max: {
            dog: 7.5,
            cat: 8.8
          },
          value: this.LabData["totalProtein"],
          prop: "totalProtein"
        },
        {
          prefix: "Chloride",
          suffix: "mmol/L",
          min: {
            dog: 108,
            cat: 114
          },
          max: {
            dog: 119,
            cat: 126
          },
          value: this.LabData["Chloride"],
          prop: "Chloride"
        },
        {
          prefix: "Globulin",
          suffix: "g/dL",
          min: {
            dog: 2.4,
            cat: 3.0
          },
          max: {
            dog: 4.0,
            cat: 5.9
          },
          value: this.LabData["Globulin"],
          prop: "Globulin"
        },
        {
          prefix: "Potassium",
          suffix: "mmol/L",
          min: {
            dog: 4.0,
            cat: 3.7
          },
          max: {
            dog: 5.4,
            cat: 5.2
          },
          value: this.LabData["Potassium"],
          prop: "Potassium"
        },
        {
          prefix: "Total Bilirubin",
          suffix: "mg/dL",
          min: {
            dog: 0.0,
            cat: 0.0
          },
          max: {
            dog: 0.3,
            cat: 0.3
          },
          value: this.LabData["totalBilirubin"],
          prop: "totalBilirubin"
        },
        {
          prefix: "Sodium",
          suffix: "mmol/L",
          min: {
            dog: 142,
            cat: 147
          },
          max: {
            dog: 152,
            cat: 157
          },
          value: this.LabData["Sodium"],
          prop: "Sodium"
        },
        {
          prefix: "Bilirubin-Conjugated",
          suffix: "mg/dL",
          min: {
            dog: 0,
            cat: 0
          },
          max: {
            dog: 0.1,
            cat: 0.2
          },
          value: this.LabData["Bilirubin_Conjugated"],
          prop: "Bilirubin_Conjugated"
        },
        {
          prefix: "Alb/Glob Ratio",
          suffix: "",
          min: {
            dog: 0.7,
            cat: 0.5
          },
          max: {
            dog: 1.5,
            cat: 1.2
          },
          value: this.LabData["Alb_Glob_Ratio"],
          prop: "Alb_Glob_Ratio"
        },
        {
          prefix: "Bilirubin-Unconjugated",
          suffix: "mg/dL",
          min: {
            dog: 0.0,
            cat: 0.0
          },
          max: {
            dog: 0.2,
            cat: 0.2
          },
          value: this.LabData["Bilirubin_Unconjugated"],
          prop: "Bilirubin_Unconjugated"
        },
        {
          prefix: "MCNA/K Ratio",
          suffix: "",
          min: {
            dog: 28,
            cat: 29
          },
          max: {
            dog: 37,
            cat: 42
          },
          value: this.LabData["NA_K_Ratio"],
          prop: "NA_K_Ratio"
        },
        {
          prefix: "BUN",
          suffix: "mg/dL",
          min: {
            dog: 9,
            cat: 16
          },
          max: {
            dog: 31,
            cat: 37
          },
          value: this.LabData["BUN"],
          prop: "BUN"
        },
      ]
    };
  },
  methods: {
    onSliderValueUpdated: function(arg) {
      // eslint-disable-next-line no-console
      console.log(arg);
      this.request.recipientProfileInfo.labExam[arg.prop] = arg.value;
    },

    onRadioUpdated: function(arg) {
      // eslint-disable-next-line no-console
      console.log(arg);
      this.request.recipientProfileInfo.labExam[arg.prop] = arg.value;
    }
  },
  created: function() {
    Validator.extend("unassignedChecked", {
      getMessage: "Field must not be unassigned.",
      validate: function(value) {
        return value !== OptionEnum.UNCHECKED;
      }
    });
    this.$store.dispatch("bloodbank/listLabExamMeta", {
      params: { pet_type: this.request.petInfo.type }
    });
  }
};
</script>

<style lang="scss">
.request-step-2-exam {
  h4,
  h6 {
    margin: 0;
  }

  .medical-record-card {
    border-radius: 4px;
    min-height: 40px;
    text-align: center;
    color: white;
    background: #33495d;
  }

  .medical-record-card > .el-col {
    transform: translateY(+20%);
  }

  .header-container {
    width: calc(100% + 5px);
    height: 50px;
    border-radius: 6px;
    background-color: #5c9ba3;
    text-align: center;
  }

  .padding-slider-lab {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100% !important;
  }
}
</style>
