<template>
  <div class="layout-container">
    <PlainNavbar>
      <img 
        slot="logo"
        src="@/assets/brand/v-remind-logo-text.png"
        alt="VRemind Logo"
        style="height: 40px;margin: 4px 0 0;">
    </PlainNavbar>

    <div class="form-block">
      <div class="header-block">
        <div class="title">
          Hospital
        </div>
        <div class="lead">
          Log in
        </div>
      </div>
      <el-form ref="form" :model="form">
        <el-form-item>
          <el-input
            type="email"
            v-validate="'required'"
            name="email"
            placeholder="Email / Staff username"
            v-model="form.username"></el-input>
          <span class="error">{{ errors.first('email') }}</span>
        </el-form-item>

        <el-form-item>
          <el-input
            type="password"
            v-validate="'required'"
            name="password"
            ref="password"
            placeholder="Password"
            v-model="form.password"></el-input>
          <span class="error">{{ errors.first('password') }}</span>
        </el-form-item>
        <div class="term-and-condition">
          <el-checkbox v-model="termChecked">ยอมรับ 
            <span>
              <el-link href="https://drive.google.com/file/u/3/d/1CB2gkIKR-74QXjfs4HaMZn6zbIX3Tl5l/view?usp=sharing" :underline="false" target="_blank" rel="norefferer">Term and Condition</el-link>
              </span>
          </el-checkbox>
        </div>
        <div class="forget-password">
          <router-link
            :to="{ name: 'hospitalForgetPassword' }">forget password?</router-link>
        </div>

        <el-form-item style="text-align:center">
          <el-button
            :loading="submitButtonLoading"
            type="submit"
            class="btn btn-primary form-submit-btn"
            :disabled='toggleSignin'
            @click="signin"
          >sign in</el-button>
          <span class="error">{{ errMessage }}</span>
        </el-form-item>
      </el-form>
      <div class="footer-block">
        <span>ไม่ได้เป็นสมาชิก?</span>
        <router-link :to="{ name: 'register' }">สมัครสมาชิกที่นี่</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import PlainNavbar from '@/components/navigation/PlainNavbar'

export default {
  data() {
    return {
      form: {
        username: "",
        password: ""
      },
      errMessage: "",
      submitButtonLoading: false,
      termChecked: true,
    };
  },
  computed: {
    toggleSignin: function(){
      return !this.termChecked;
    }
  },
  methods: {
    async signin() {
      this.submitButtonLoading = true;
      try {
        const valid = await this.$validator.validateAll();
        if (valid) {
          await this.$store.dispatch("ui/setIsFullScreenLoading", true);
          await this.$store.dispatch("auth/login", {
            data: {
              username: this.form.username,
              password: this.form.password
            }
          });
          await this.$store.dispatch("plan/getCurrentPlan");
          await this.$store.dispatch("ui/setIsFullScreenLoading", false);
          var redirectTo = this.$route.query.redirect;
          if (redirectTo) {
            this.$router.push({ path: redirectTo });
          } else {
            this.$router.push({ name: 'hospitalHome' });
          }
        }
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        this.errMessage = "กรุณากรอก username หรือ password ให้ถูกต้อง";
      }
      this.submitButtonLoading = false;
    }
  },
  components: {
    PlainNavbar
  },
};
</script>

<style lang="scss" scoped>
.layout-container {
  background-color: $gray-background;
  min-height: 500px;
  padding-bottom: 40px;
}
.is-danger {
  border: 1px solid red;
}
.form-block {
  @extend %block-container;
  @extend %form-style;
  .forget-password {
    text-align: right;
    padding: 0 6px;
    display: inline-block;
    float: right;
    a {
      text-decoration: none;
      color: #8e8e93;
    }
  }
  .term-and-condition {
    text-align: left;
    padding: 0 6px;
    display: inline-block;
  }
}
.footer-block {
  text-align: center;
  span {
    color: $gray;
  }
  a {
    text-decoration: none;
    color: #000;
  }
}
</style>
