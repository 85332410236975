import { render, staticRenderFns } from "./DoctorFeeDetailTable.vue?vue&type=template&id=ef79a542&scoped=true&"
import script from "./DoctorFeeDetailTable.vue?vue&type=script&lang=js&"
export * from "./DoctorFeeDetailTable.vue?vue&type=script&lang=js&"
import style0 from "./DoctorFeeDetailTable.vue?vue&type=style&index=0&id=ef79a542&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef79a542",
  null
  
)

export default component.exports