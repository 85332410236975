import VeterinarianListPage from '@/pages/veterinarian/VeterinarianListPage.vue'
import VeterinarianDetail from '@/pages/veterinarian/VeterinarianDetail.vue'

const routes = [
  {
    path: '/veterinarians',
    name: 'veterinarians',
    component: VeterinarianListPage,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['setting_section.can_use_vet_setting']
    }
  },
  {
    path: '/veterinarians/add',
    name: 'veterinarians-add',
    component: VeterinarianDetail,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['setting_section.can_use_vet_setting']
    }
  },
  {
    path: '/veterinarians/:id',
    name: 'veterinarians-detail',
    component: VeterinarianDetail,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin'],
      plan_permissions: ['setting_section.can_use_vet_setting']
    }
  }
]

export default routes