<template>
  <div class="form-block">
    <div class="header-block">
      <div class="title">
        Create Pet Owner Profile
      </div>
      <div class="lead">
        Social & Notification<br/>
        (4/4)
      </div>
    </div>

    <el-form ref="form">

      <!-- <el-form-item>
        <span class="form-label">Line</span>
        <el-button class="connect-btn" type="success">Connect with Line</el-button>
      </el-form-item>

      <el-form-item>
        <span class="form-label">Messenger</span>
        <el-button class="connect-btn" type="primary">Connect with Messenger</el-button>
      </el-form-item>

      <el-form-item>
        <span class="form-label">One Signel</span>
        <el-button class="connect-btn" type="danger">Website Notification</el-button>
      </el-form-item> -->

      <SocialButton :profile="$store.state.owner.profile" :isRegisterPage="true"  style="max-width: 320px; margin: 0 auto; text-align: center"/>

      <el-form-item class="text-center">
        <router-link
          :to="{ name: 'petOwnerAddress' }"
          class="btn btn-plain form-submit-btn">
          &lt; Back
        </router-link>
        <router-link
          :to="{ name: 'petOwnerHome' }"
          class="btn btn-primary form-submit-btn">
          Next &gt;
        </router-link>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import SocialButton from "@/components/common/SocialButton"

export default {
  components: {
    SocialButton,
  },
  data() {
    return {
    }
  },
  async mounted() {
    await this.$store.dispatch('ui/setIsFullScreenLoading', true)
    await this.$store.dispatch('hospital/getProfile')
    await this.$store.dispatch('ui/setIsFullScreenLoading', false)
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.form-block {
  @extend %block-container;
  @extend %form-style;
  .el-form-item {
    width: 80%;
    margin: 4px 10%;
    button {
      margin: 2px 0;
      width: 100%;
    }
  }
  .text-center {
    margin-top: 10px;
  }
}
</style>
