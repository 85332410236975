import * as vuexHelper from "../helpers/vuexHelper";
import { coreAPI } from "../../services/apis";
// import * as endpoints from "../../services/endpoints";

const config = {
  default: {
    api: coreAPI,
  },
  state: {
    items: [],
  },
  getters: {
    items: (state) => state.items,
  },
  mutations: {
    SET_ITEMS: (state, value) => {
      state.items = value;
    },
  },
  actions: {
    clear: ({ commit }) => {
      commit("SET_ITEMS", []);
    },
  },
  asyncActions: [],
};

export default vuexHelper.createStoreModule(config);
