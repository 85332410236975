<template>
  <div class="chart_panel_title">
    <div class="bar">
      <div class="block"
        v-for="(item, index) in bar" v-bind:key="index"
        v-bind:style="{ color: item.color, flex: item.value ? item.value : 0}"
      >
        <div v-if="item.value !== 0" class="text">{{item.unit}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['bar']
};
</script>

<style lang="scss" scoped>
.chart_panel_title {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-left: 1px solid #979797;
  margin-left: 5px;
  padding: 0px;
}
.bar {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 1px;
  padding: 0px;

}
.block {
  display: flex;
}
.text {
  position: relative;
  text-align: center;
  width: 100%;
  top: -5px;
  font-size: 12px;
}
</style>
