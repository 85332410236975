<template>
  <div class="pet-owner-address-form">
      <div class="form-row">
        <label class="form-label">ที่อยู่ <span class="required-mark">*</span></label>
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 10}"
          name="address"
          placeholder="บ้านเลขที่, อาคาร, ถนน"
          v-model="form.address1"
          @change="onChange"
          required
          ></el-input>
        <span class="error">{{ errors.first('address') }}</span>
      </div>

      <div class="form-row">
        <label class="form-label">แขวง/ตำบล <span class="required-mark">*</span></label>
        <v-select
          :options="subDistrictArray"
          @search="searchSubDistict"
          @input="onSubDistrictChange"
          v-model="selectSubDistrict"
          :placeholder="'พิมพ์เพื่อค้นหาอย่างน้อย 3 ตัวอักษร'"
        >
          <span slot="no-options">
            ไม่พบตำบล/แขวง
          </span>
        </v-select>
      </div>

      <div class="form-row">
        <label class="form-label">เขต/อำเภอ</label>
        <el-input
          type="text"
          name="district"
          :disabled="true"
          v-model="form.districtText"
          @change="onChange"
          ></el-input>
      </div>

      <div class="form-row">
        <label class="form-label">จังหวัด</label>
        <el-input
          type="text"
          name="province"
          :disabled="true"
          v-model="form.provinceText"
          @change="onChange"
          ></el-input>
      </div>

      <div class="form-row">
        <label class="form-label">รหัสไปรษณีย์</label>
        <el-input
          type="tel"
          name="zipCode"
          v-model="form.zipcode"
          @change="onChange"
          ></el-input>
      </div>

  </div>
</template>

<script>
import _ from 'lodash'

export default {
  props: ['address1', 'address2', 
  'subdistrict', 'subdistrictText',
  'district', 'districtText',
  'province', 'provinceText', 
  'zipcode'],
  data() {
    return {
      form: {
        address1: this.address1,
        address2: this.address2,
        subdistrict: this.subdistrict || '',
        subdistrictText: this.subdistrictText || '',
        district: this.subdistrict || '',
        districtText: this.districtText || '',
        province: this.province || '',
        provinceText: this.provinceText || '',
        zipcode: this.zipcode
      },
      selectSubDistrict: null,
      subDistrictArray: [],
    }
  },
  methods: {
    async onChange() {
      this.$emit('change', this.form)
    },
    onSubDistrictChange(data) {
      if (data && data.code) {
        const { code } = data
        this.form.subdistrict = code.subdistrict.id
        this.form.subdistrictText = code.subdistrict.name_th
        this.form.district = code.district.id
        this.form.districtText = code.district.name_th
        this.form.province = code.province.id
        this.form.provinceText = code.province.name_th
        this.form.zipcode = code.subdistrict.zip_code
        this.onChange()
      } else {
        this.subDistrictArray = []
        this.form.subdistrict = ''
        this.form.subdistrictText = ''
        this.form.districtText = ''
        this.form.district = ''
        this.form.districtText = ''
        this.form.province = ''
        this.form.provinceText = ''
        this.form.zipcode = ''
        this.onChange()
      }
      
    },
    async searchSubDistict(search, loading) {
      if (!search || search.length < 2) {
        return
      }
      this.search(search, loading, this)
    },
    search:_.debounce(async (search, loading, vm) => {
      try {
        loading(true)
        var result = await vm.$store.dispatch('location/getSubdistrict', {
          query: {
            'name_th__contains': search
          }
        })
        vm.subDistrictArray = result.map(item => ({
            label: item.subdistrict.name_th + " - " + item.district.name_th,
            code: item
          }))
        loading(false)
      } catch (error) {
        loading(false)
      }
    }, 350)
  },
  watch: {
    address1 (val) {
      this.form.address1 = val
    },
    address2 (val) {
      this.form.address2 = val
    },
    subdistrict (val) {
      this.form.subdistrict = val
    },
    subdistrictText (val) {
      this.form.subdistrictText = val
      this.selectSubDistrict = val
    },
    district (val) {
      this.form.district = val
    },
    districtText (val) {
      this.form.districtText = val
    },
    province (val) {
      this.form.province = val
    },
    provinceText (val) {
      this.form.provinceText = val
    },
    zipcode (val) {
      this.form.zipcode = val
    }
  }
}
</script>

<style lang="scss" scoped>
.pet-owner-address-form {
 .form-row {
   margin-bottom: 10px;
 }
 .form-label {
   display: block;
 }
 .dropdown-select {
    position: absolute;
    background-color: white;
    z-index: 1;
    padding: 4px 12px;
    border: 1px solid $gray;
    border-radius: 4px;
    .sub-district-select {
      cursor: pointer;
    }
  }
}
</style>

