import SymptomCheckingList from '../medical-record/symptom-checking/symptom-checking-list'
import OrganEnum from '@/constants/bloodbank/organ-enum'

import SymptomEyeEnum from '@/constants/bloodbank/organ-symptom-type-enum/eye-symptom-type-enum'
import SymptomEarEnum from '@/constants/bloodbank/organ-symptom-type-enum/ear-symptom-enum'
import SymptomMouthEnum from '@/constants/bloodbank/organ-symptom-type-enum/mouth-teeth-gill-symptom-enum'
import SymptomSkinEnum from '@/constants/bloodbank/organ-symptom-type-enum/skin-and-hair-symptom-type-enum'
import SymptomAlimetaryEnum from '@/constants/bloodbank/organ-symptom-type-enum/alimetary-system-symptom-type-enum'
import SymptomUrinateEnum from '@/constants/bloodbank/organ-symptom-type-enum/urinate-system-and-reproduce-system-symptom-enum'
import SymptomRespiratoryEnum from '@/constants/bloodbank/organ-symptom-type-enum/respiratory-system-symptom-enum'
import SymptomBoneEnum from '@/constants/bloodbank/organ-symptom-type-enum/bone-and-muscle-symptom-enum'
import SymptomHeartEnum from '@/constants/bloodbank/organ-symptom-type-enum/heart-symptom-enum'
import SymptomLymphEnum from '@/constants/bloodbank/organ-symptom-type-enum/lymph-node-symptom-enum'
import SymptomNervousEnum from '@/constants/bloodbank/organ-symptom-type-enum/nervous-system-symptom-enum'
import SymptomWeightEnum from '@/constants/bloodbank/organ-symptom-type-enum/weight-symptom-type-enum'
// import LabExam from '../../common/lab-exam/lab-exam'

import _ from 'lodash'
import store from '@/store/index'

export default class {
	constructor(obj) {
		if (obj != null) {
			this.id = obj.id == null ? null : obj.id
			this.symptoms = new SymptomCheckingList(obj)
			this.weight = obj.weight == null ? '' : Number(obj.weight)
			this.temp = obj.temp == null ? '' : Number(obj.temp)
			this.hr = obj.HR == null ? '' : Number(obj.HR)
			this.rr = obj.RR == null ? '' : Number(obj.RR)
			this.crt = obj.CRT == null ? '' : Number(obj.CRT)
			this.bp = obj.BP == null ? '' : obj.BP
			this.mucouseMembrane = obj.membrane == null ? '' : obj.membrane
			this.bscLevel = obj.BSCLevel == null ? '' : obj.BSCLevel
			this.note = obj.note == null ? '' : obj.note
			this.labExamId = obj.lab_exam == null ? '' : obj.lab_exam
			this.examiner = obj.examiner == null ? null : obj.examiner
		} else {
			this.id = null
			this.symptoms = new SymptomCheckingList()
			this.weight = ''
			this.temp = ''
			this.hr = ''
			this.rr = ''
			this.crt = ''
			this.bp = ''
			this.mucouseMembrane = ''
			this.bscLevel = ''
			this.note = ''
			this.labExamId = null
			this.examiner = ''
		}
	}

	toRequestJson() {
		var requestJsonObj = {
			id: Number(this.id),
			// sysmtoms = this.symptoms.toRequestJson(),
			weight:
				isNaN(parseFloat(this.weight)) || isNaN(this.weight)
					? null
					: Number(this.weight),
			temp:
				isNaN(parseFloat(this.temp)) || isNaN(this.temp)
					? null
					: Number(this.temp),
			HR: isNaN(parseFloat(this.hr)) || isNaN(this.hr) ? null : Number(this.hr),
			RR: isNaN(parseFloat(this.rr)) || isNaN(this.rr) ? null : Number(this.rr),
			CRT:
				isNaN(parseFloat(this.crt)) || isNaN(this.crt)
					? null
					: Number(this.crt),
			BP: this.bp,
			membrane:
				isNaN(parseFloat(this.mucouseMembrane)) || isNaN(this.mucouseMembrane)
					? null
					: Number(this.mucouseMembrane),
			BSCLevel:
				isNaN(parseFloat(this.bscLevel)) || isNaN(this.bscLevel)
					? null
					: Number(this.bscLevel),
			note: this.note,
			examiner: this.examiner,
			lab_exam: this.labExamId,
			hospital: store.state.hospital.profile.id
		}
		var symptomRequestJson = this.symptomCheckingListToRequest()

		return _.merge({}, requestJsonObj, symptomRequestJson)
	}

	symptomCheckingListToRequest() {
		var requestObj = {}
		this.symptoms.symptomCheckingList.forEach(item => {
			if (item.organName === OrganEnum.EYE) {
				this.symptomEyeToRequest(requestObj, item)
			} else if (item.organName === OrganEnum.EAR) {
				this.symptomEarToRequest(requestObj, item)
			} else if (item.organName === OrganEnum.MOUTH_TEETH_GILL) {
				this.symptomMouthTeethGillToRequest(requestObj, item)
			} else if (item.organName === OrganEnum.SKIN_AND_HAIR) {
				this.symptomSkinToRequest(requestObj, item)
			} else if (item.organName === OrganEnum.ALIMETARY_SYSTEM) {
				this.symptomAlimetaryToRequest(requestObj, item)
			} else if (
				item.organName === OrganEnum.URINATE_SYSTEM_AND_REPRODUCE_SYSTEM
			) {
				this.symptomUrinateToRequest(requestObj, item)
			} else if (item.organName === OrganEnum.RESPIRATORY_SYSTEM) {
				this.symptomRespiratoryToRequest(requestObj, item)
			} else if (item.organName === OrganEnum.BONE_AND_MUSCLE) {
				this.symptomBoneToRequest(requestObj, item)
			} else if (item.organName === OrganEnum.HEART) {
				this.symptomHeartToRequest(requestObj, item)
			} else if (item.organName === OrganEnum.LYMPH_NODE) {
				this.symptomLymphToRequest(requestObj, item)
			} else if (item.organName === OrganEnum.NERVOUS_SYSTEM) {
				this.symptomNervousToRequest(requestObj, item)
			} else if (item.organName === OrganEnum.WEIGHT) {
				this.symptomWeightToRequest(requestObj, item)
			}
		})
		return requestObj
	}

	symptomEyeToRequest(requestObj, eyeSymptom) {
		if (eyeSymptom.isNormal === 'abnormal') {
			requestObj.is_eye_normal = false
		} else {
			requestObj.is_eye_normal = true
		}
		requestObj.eye_note = eyeSymptom.note

		eyeSymptom.symptomDetail.forEach(symptom => {
			let value = null
			if (symptom.status.left === false && symptom.status.right === false) {
				value = 0
			} else if (
				symptom.status.left === true &&
				symptom.status.right === false
			) {
				value = 1
			} else if (
				symptom.status.left === false &&
				symptom.status.right === true
			) {
				value = 2
			} else if (
				symptom.status.left === true &&
				symptom.status.right === true
			) {
				value = 3
			}

			if (symptom.symptomName === SymptomEyeEnum.OPHTHALMITIS) {
				requestObj.is_ophthalmitis = value
			} else if (symptom.symptomName === SymptomEyeEnum.CORNEAL_OPACITY) {
				requestObj.is_corneal_opacity = value
			} else if (symptom.symptomName === SymptomEyeEnum.ULCERATE) {
				requestObj.is_ulcerate = value
			} else if (symptom.symptomName === SymptomEyeEnum.OCULAR_HYPERTENSION) {
				requestObj.is_hypertension = value
			}
		})
	}

	symptomEarToRequest(requestObj, earSymptom) {
		if (earSymptom.isNormal === 'abnormal') {
			requestObj.is_ear_normal = false
		} else {
			requestObj.is_ear_normal = true
		}
		requestObj.ear_note = earSymptom.note

		earSymptom.symptomDetail.forEach(symptom => {
			let value = null
			if (symptom.status.left === false && symptom.status.right === false) {
				value = 0
			} else if (
				symptom.status.left === true &&
				symptom.status.right === false
			) {
				value = 1
			} else if (
				symptom.status.left === false &&
				symptom.status.right === true
			) {
				value = 2
			} else if (
				symptom.status.left === true &&
				symptom.status.right === true
			) {
				value = 3
			}

			if (symptom.symptomName === SymptomEarEnum.INAUDIBLE) {
				requestObj.is_inaudible = value
			} else if (symptom.symptomName === SymptomEarEnum.EARWAX) {
				requestObj.is_earwax = value
			} else if (symptom.symptomName === SymptomEarEnum.SMELL) {
				requestObj.is_ear_smelly = value
			} else if (symptom.symptomName === SymptomEarEnum.OTITIS) {
				requestObj.is_otitis = value
			} else if (symptom.symptomName === SymptomEarEnum.MITE) {
				requestObj.is_mite = value
			}
		})
	}

	symptomMouthTeethGillToRequest(requestObj, mouthSymptom) {
		if (mouthSymptom.isNormal === 'abnormal') {
			requestObj.is_mouth_teeth_gill_normal = false
		} else {
			requestObj.is_mouth_teeth_gill_normal = true
		}
		requestObj.mouth_teeth_gill_note = mouthSymptom.note

		mouthSymptom.symptomDetail.forEach(symptom => {
			if (symptom.symptomName === SymptomMouthEnum.SMELL) {
				requestObj.is_mouth_teeth_gill_smelly = symptom.status.single
			} else if (symptom.symptomName === SymptomMouthEnum.GINGIVITIS) {
				requestObj.is_gingivitis = symptom.status.single
			} else if (symptom.symptomName === SymptomMouthEnum.DENTAL_CALCULUS) {
				requestObj.is_dental_calculus = symptom.status.single
			} else if (symptom.symptomName === SymptomMouthEnum.DECAYED_TOOTH) {
				requestObj.is_decayed_tooth = symptom.status.single
			}
		})
	}

	symptomSkinToRequest(requestObj, skinSymptom) {
		if (skinSymptom.isNormal === 'abnormal') {
			requestObj.is_skin_and_hair_normal = false
		} else {
			requestObj.is_skin_and_hair_normal = true
		}
		requestObj.skin_and_hair_note = skinSymptom.note

		skinSymptom.symptomDetail.forEach(symptom => {
			if (symptom.symptomName === SymptomSkinEnum.EXCORIATION) {
				requestObj.is_excoriation = symptom.status.single
			} else if (symptom.symptomName === SymptomSkinEnum.OILY_SKIN) {
				requestObj.is_oily_skin = symptom.status.single
			} else if (symptom.symptomName === SymptomSkinEnum.HAIR_LOSE) {
				requestObj.is_hair_lose = symptom.status.single
			} else if (symptom.symptomName === SymptomSkinEnum.RASH) {
				requestObj.is_rash = symptom.status.single
			} else if (symptom.symptomName === SymptomSkinEnum.TICK) {
				requestObj.is_tick = symptom.status.single
			}
		})
	}

	symptomAlimetaryToRequest(requestObj, alimetarySymptom) {
		if (alimetarySymptom.isNormal === 'abnormal') {
			requestObj.is_alimetary_system_normal = false
		} else {
			requestObj.is_alimetary_system_normal = true
		}
		requestObj.alimetary_system_note = alimetarySymptom.note

		alimetarySymptom.symptomDetail.forEach(symptom => {
			if (symptom.symptomName === SymptomAlimetaryEnum.DIARRHEA) {
				requestObj.is_diarrhea = symptom.status.single
			} else if (symptom.symptomName === SymptomAlimetaryEnum.VOMIT) {
				requestObj.is_vomit = symptom.status.single
			} else if (symptom.symptomName === SymptomAlimetaryEnum.CONSTIPATION) {
				requestObj.is_constipation = symptom.status.single
			}
		})
	}

	symptomUrinateToRequest(requestObj, urinateSymptom) {
		if (urinateSymptom.isNormal === 'abnormal') {
			requestObj.urinate_and_reproduce_system_note = false
		} else {
			requestObj.urinate_and_reproduce_system_note = true
		}
		requestObj.urinate_and_reproduce_system_note = urinateSymptom.note

		urinateSymptom.symptomDetail.forEach(symptom => {
			if (symptom.symptomName === SymptomUrinateEnum.GUARDED) {
				requestObj.is_guarded = symptom.status.single
			} else if (symptom.symptomName === SymptomUrinateEnum.TUMOR) {
				requestObj.is_tumor = symptom.status.single
			} else if (symptom.symptomName === SymptomUrinateEnum.FROWN) {
				requestObj.is_frown = symptom.status.single
			}
		})
	}

	symptomRespiratoryToRequest(requestObj, respiratorySymptom) {
		if (respiratorySymptom.isNormal === 'abnormal') {
			requestObj.is_respiratory_system_normal = false
		} else {
			requestObj.is_respiratory_system_normal = true
		}
		requestObj.respiratory_system_note = respiratorySymptom.note

		respiratorySymptom.symptomDetail.forEach(symptom => {
			if (symptom.symptomName === SymptomRespiratoryEnum.GASPING) {
				requestObj.is_gasping = symptom.status.single
			} else if (symptom.symptomName === SymptomRespiratoryEnum.COUGH) {
				requestObj.is_cough = symptom.status.single
			} else if (symptom.symptomName === SymptomRespiratoryEnum.SNIVEL) {
				requestObj.is_snivel = symptom.status.single
			}
		})
	}

	symptomBoneToRequest(requestObj, boneSymptom) {
		if (boneSymptom.isNormal === 'abnormal') {
			requestObj.is_bone_and_muscle_normal = false
		} else {
			requestObj.is_bone_and_muscle_normal = true
		}
		requestObj.bone_and_muscle_note = boneSymptom.note

		boneSymptom.symptomDetail.forEach(symptom => {
			if (symptom.symptomName === SymptomBoneEnum.LIMP) {
				requestObj.is_limp = symptom.status.single
			} else if (symptom.symptomName === SymptomBoneEnum.OUT_OF_SHAPE) {
				requestObj.is_out_of_shape = symptom.status.single
			} else if (symptom.symptomName === SymptomBoneEnum.DETERIORATE) {
				requestObj.is_deteriorate = symptom.status.single
			}
		})
	}

	symptomHeartToRequest(requestObj, heartSymptom) {
		if (heartSymptom.isNormal === 'abnormal') {
			requestObj.is_heart_normal = false
		} else {
			requestObj.is_heart_normal = true
		}
		requestObj.heart_note = heartSymptom.note

		heartSymptom.symptomDetail.forEach(symptom => {
			if (symptom.symptomName === SymptomHeartEnum.DISTURBANCE_SOUND) {
				requestObj.is_disturbance_sound = symptom.status.single
			} else if (symptom.symptomName === SymptomHeartEnum.ARRHYTHMIA) {
				requestObj.is_arrhythmia = symptom.status.single
			} else if (symptom.symptomName === SymptomHeartEnum.ENLARGED_HEART) {
				requestObj.is_enlarged_heart = symptom.status.single
			}
		})
	}

	symptomLymphToRequest(requestObj, lymphSymptom) {
		if (lymphSymptom.isNormal === 'abnormal') {
			requestObj.is_lymph_node_normal = false
		} else {
			requestObj.is_lymph_node_normal = true
		}
		requestObj.lymph_node_note = lymphSymptom.note

		lymphSymptom.symptomDetail.forEach(symptom => {
			if (symptom.symptomName === SymptomLymphEnum.INTUMESCE) {
				requestObj.is_intumesce = symptom.status.single
			} else if (symptom.symptomName === SymptomLymphEnum.PAIN) {
				requestObj.is_pain = symptom.status.single
			} else if (symptom.symptomName === SymptomLymphEnum.INFLAME) {
				requestObj.is_inflame = symptom.status.single
			}
		})
	}

	symptomNervousToRequest(requestObj, nervousSymptom) {
		if (nervousSymptom.isNormal === 'abnormal') {
			requestObj.is_nervous_system_normal = false
		} else {
			requestObj.is_nervous_system_normal = true
		}
		requestObj.nervous_system_note = nervousSymptom.note

		nervousSymptom.symptomDetail.forEach(symptom => {
			if (symptom.symptomName === SymptomNervousEnum.CONVULSE) {
				requestObj.is_convulse = symptom.status.single
			} else if (symptom.symptomName === SymptomNervousEnum.WEAK) {
				requestObj.is_weak = symptom.status.single
			} else if (symptom.symptomName === SymptomNervousEnum.PARALYSIS) {
				requestObj.is_paralysis = symptom.status.single
			}
		})
	}

	symptomWeightToRequest(requestObj, weightSymptom) {
		if (weightSymptom.isNormal === 'abnormal') {
			requestObj.is_weight_normal = false
		} else {
			requestObj.is_weight_normal = true
		}
		requestObj.weight_note = weightSymptom.note

		weightSymptom.symptomDetail.forEach(symptom => {
			if (symptom.symptomName === SymptomWeightEnum.OBESE) {
				requestObj.is_obese = symptom.status.single
			} else if (symptom.symptomName === SymptomWeightEnum.SKINNY) {
				requestObj.is_skinny = symptom.status.single
			}
		})
	}

	cloneFrom(obj) {
		this.id = obj.id
		this.symptoms.cloneFrom(obj.symptoms)
		this.weight = obj.weight
		this.temp = obj.temp
		this.hr = obj.hr
		this.rr = obj.rr
		this.crt = obj.crt
		this.bp = obj.bp
		this.mucouseMembrane = obj.mucouseMembrane
		this.bscLevel = obj.bscLevel
		this.note = obj.note
		this.labExamId = obj.labExamId
		this.examiner = obj.examiner
	}
}
