let api;

if (!api) {
  import("./apis").then((apis) => {
    api = apis.coreAPI;
  });
}

/**
 * Get an OPD
 * @param {string} id
 */
const get = (id) => api.get(`/medicalrecord/${id}/`);

/**
 * Get OPDs
 * @param {Object} params
 * @param {(string|undefined)} params.search
 * @param {(string|undefined)} params.ordering
 * @param {(Date|undefined)} params.start
 * @param {(Date|undefined)} params.end
 * @param {(string|number|undefined)} params.vet_id
 */
const getAll = (params) => api.get("/medicalrecord/", { params });

/**
 * Get discarded OPDs
 * @param {Object} params
 * @param {(string|undefined)} params.search
 * @param {(string|undefined)} params.ordering
 * @param {(Date|undefined)} params.start
 * @param {(Date|undefined)} params.end
 * @param {(string|number|undefined)} params.vet_id
 * @param {(string|number|undefined)} params.discardedmedicalrecord__vet__id
 */
const getDiscards = (params) =>
  api.get("/medicalrecord/discarded/", { params });

/**
 * Get OPD overview
 * @param {Object} params
 * @param {(Date|undefined)} params.start
 * @param {(Date|undefined)} params.end
 */
const getOverview = (params) => api.get("/medicalrecord/overview/", { params });

export default { get, getAll, getDiscards, getOverview };
