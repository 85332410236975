import * as vuexHelper from "../helpers/vuexHelper";
import { coreAPI } from "../../services/apis";
import * as endpoints from "../../services/endpoints";

const config = {
  default: {
    api: coreAPI
  },
  state: {
   
  },
  asyncActions: [
    {
      name: "getDirectMessage",
      method: "get",
      url: ({ id }) => `${endpoints.message}/${id}/`,
    },
    {
      name: "getPublicDirectMessage",
      method: "get",
      url: ({ id }) => `${endpoints.message}/public/${id}/`,
    },
    {
      name: "sendDirectMessage",
      method: "post",
      url: `${endpoints.message}/`,
    },
  ]
};

export default vuexHelper.createStoreModule(config);
