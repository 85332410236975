<template>
  <el-dialog :visible="visible" width="600px" center @close="$emit('cancel')">
    <div class="dialog-content">
      <div class="title">Create New Activity + Product</div>
      <hr class="line"/>
      <div class="position-textbox">
        <div class="text">Activity Name <span class="required-mark">*</span> </div>
        <el-input size="small" v-model="name" type="text"></el-input>
      </div>
      <div class="position-textbox">
        <div class="text">Price ฿ <span class="required-mark">*</span><span class="blue-text"> ราคาของบริการ</span></div>
        <el-input size="small" v-model="price" type="text"></el-input>
      </div>
      <div class="position-textbox">
        <div class="text">Activity Group <span class="required-mark">*</span></div>
        <el-select
          size="small"
          v-model="group"
          placeholder="Select"
        >
          <el-option
            v-for="group in groups"
            :key="group.id"
            :label="group.name"
            :value="group.id"
          ></el-option>
        </el-select>
      </div>
      <div class="position-textbox">
        <div class="text">Pet Type <span class="required-mark">*</span><span class="blue-text"> สามารถเลือกสัตว์ได้มากกว่า 1 ชนิด</span></div>
        <v-select
          v-model="pet_type"
          multiple
          label="name"
          :options="types"
          :pushTags="false"
          :filterable="true"
        />
      </div>
      <div class="position-textbox">
        <div class="text">Product name (trade name)<span class="blue-text"> ถ้ามี! เพื่อการนับจำนวนการใช้ ผลิตภัณฑ์</span></div>
        <el-input size="small" v-model="trade_name" type="text"></el-input>
      </div>
      <input
        class="camera-bg"
        type="file"
        ref="fileCreateActivity"
        @change="handleFileUpload"
      />
      <div>{{ file ? file.name : '' }}</div>
      <!-- <div class="image-card-container">
        <el-card
          :body-style="{ padding: '5px' }"
          v-for="(img,index) in POData.problem_oriented.attachments"
          :key="index"
          class="image-card"
        >
          <img :src="img.attachmentPreview" class="image image-size" />
        </el-card>
      </div> -->
      <div class="button-save">
        <el-button size="small" type="primary" @click="createActivity">Confirm</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex"

export default {
  props: ['visible', 'groups'],
  data() {
    return {
      isLoading: false,
      name: '',
      price: '0.00',
      group: 1,
      pet_type: [],
      trade_name: '',
      file: null
    }
  },
  computed: {
    ...mapState({
      types: state => state.pet.types
    })
  },
  methods: {
    clearData() {
      this.name = ''
      this.price = '0.00'
      this.group = 1
      this.pet_type = []
      this.trade_name = ''
      this.file = null
    },
    handleFileUpload() {
      let refFile = this.$refs.fileCreateActivity
      Array.from(refFile.files).forEach(async file => {
        this.file = file
      })
    },
    async createActivity() {
      if (!this.name) {
        this.$notify({
          message: "กรุณาใส่ Activity Name",
          type: "warning"
        })
        return
      } else if (!parseFloat(this.price) || parseFloat(this.price) == 0) {
        this.$notify({
          message: "ราคาของบริการไม่ถูกต้อง",
          type: "warning"
        })
        return
      }

      let data = {}
      data['name'] = this.name
      data['group'] = this.group
      data['price'] = parseFloat(this.price)
      data['pet_gender'] = -1
      data['note'] = []
      let products = []
      if (this.trade_name) {
        products.push({ trade_name: this.trade_name })
      }
      data['products'] = products
      let pet_types = []
      if (this.pet_type.length > 0) {
        pet_types = this.pet_type.map(p => p.id)
      }
      data['pet_type'] = pet_types

      try {
        let resMed = true
        let resultMed = {}
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        resultMed = await this.$store.dispatch("activity/createActivityLegacy", {
          data
        }).catch(async(err) => {
          resMed = false
          this.$notify({
            message: JSON.stringify(err),
            type: "error"
          })
        })

        if (resMed) {
          if (this.file) {
            let formData = new FormData()
            formData.append("attachment", this.file)
            // const response = await this.$store.dispatch("activity/uploadActivity", {
            await this.$store.dispatch("activity/uploadActivity", {
              params: { id: resultMed.id },
              formData
            })
          }
          this.$notify({
            message: "บันทึกเรียบร้อยแล้ว",
            type: "success"
          })
          setTimeout(() => {
            this.$emit('confirm')
          }, 1500)
        }
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (e) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        await this.$store.dispatch("ui/showFetchErrorMsg")
      }
    }
  },
  watch: {

  },
  async mounted() {
    this.clearData()
    await this.$store.dispatch('ui/setIsFullScreenLoading', true);
    await this.$store.dispatch('pet/listTypes');
    await this.$store.dispatch('ui/setIsFullScreenLoading', false)
  }
};
</script>

<style lang="scss" scoped>
.dialog-content {
  font-size: 16px;
  .title {
    margin: -15px 0 10px 0;
    padding: 0;
    font-size: 20px;
    text-align: center;
    color: $light-blue;
  }
  .position-textbox {
    margin: 10px 0;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .text {
    margin-right: 15px;
    .blue-text {
      font-size: 12px;
      color: $blue;
    }
  }
  .button-save {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.camera-bg {
  width: 50px;
  height: 40px;
  border-radius: 5px;
  background-image: url('../../assets/icons/icon-camera-plus.png');
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.image-card-container {
  width: 100%;
  height: 100%;
  overflow: scroll;
  white-space: nowrap;
}
.image {
  width: 100%;
  display: block;
}
.image-card {
  width: 100%;
  max-width: 200px;
  display: inline-block;
}
.image-size {
  width: 100%;
  height: 200px;
  max-width: 200px;
}
.line {
  width: 100%;
  margin: 15px 0;
  border: solid 1px #b4b4b4;
}
</style>

