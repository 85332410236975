const Pos = () => import("@/pages/pos/Pos");
const PosCreate = () => import("@/pages/pos/PosCreate");
const PosDeleted = () => import("@/pages/pos/PosDeleted");
const PosCustomerSelect = () => import("@/pages/pos/PosCustomerSelect");
const PosPayment = () => import("@/pages/pos/PosPayment");

const routes = [
  {
    path: "/pos",
    name: "pos",
    component: Pos,
    meta: {
      requiresAuth: true,
      permissions: ["hospital_admin"],
      plan_permissions: ["pos_section.can_use_pos"],
    },
  },
  {
    path: "/pos/create",
    name: "pos-create",
    component: PosCreate,
    meta: {
      requiresAuth: true,
      permissions: ["hospital_admin"],
      plan_permissions: ["pos_section.can_use_pos"],
    },
  },
  {
    path: "/pos/customer/select",
    name: "pos-customer-select",
    component: PosCustomerSelect,
    meta: {
      requiresAuth: true,
      permissions: ["hospital_admin"],
      plan_permissions: ["pos_section.can_use_pos"],
    },
  },
  {
    path: "/pos/deleted",
    name: "pos-deleted",
    component: PosDeleted,
    meta: {
      requiresAuth: true,
      permissions: ["hospital_admin"],
      plan_permissions: ["pos_section.can_use_pos"],
    },
  },
  {
    path: "/pos/:id",
    name: "pos-detail",
    component: PosCreate,
    meta: {
      requiresAuth: true,
      permissions: ["hospital_admin"],
      detail: true,
      plan_permissions: ["pos_section.can_use_pos"],
    },
  },
  {
    path: "/pos/:id/customer/select",
    name: "pos-detail-customer-select",
    component: PosCustomerSelect,
    meta: {
      requiresAuth: true,
      permissions: ["hospital_admin"],
      detail: true,
      plan_permissions: ["pos_section.can_use_pos"],
    },
  },
  {
    path: "/pos/:id/payment",
    name: "pos-payment",
    component: PosPayment,
    meta: {
      requiresAuth: true,
      permissions: ["hospital_admin"],
      plan_permissions: ["pos_section.can_use_pos_payment"],
    },
  },
];

export default routes;
