<template>
  <div class="broadcast-page">
    <Subnavbar>
      <template slot="title">Broadcast</template>
      <template slot="actions">
        <button class="btn btn-large btn-broadcast" @click="$router.push({ name: 'hospitalBroadcastCreate' })">
          <i class="fa fa-plus"></i>
          New Broadcast
        </button>
      </template>
    </Subnavbar>

    <ContentBox>
      <BroadcastSearch
        :startDate="startDate"
        :endDate="endDate"
        :search="search"
        @search="handleSearch"
      />
    </ContentBox>

    <ContentBox>
      <BroadcastTable
        :items="list.results"
        :current-page="currentPage"
        :page-size="list.limit"
        :total="list.total"
        :isLoading="isLoading"
        @page-change="handlePageChange"
       />
    </ContentBox>
  </div>
</template>

<script>
import { mapState } from "vuex"
import Subnavbar from "@/components/navigation/Subnavbar";
import ContentBox from "@/components/container/ContentBox";
import BroadcastSearch from "@/components/broadcast/BroadcastSearch";
import BroadcastTable from "@/components/broadcast/BroadcastTable";

export default {
  components: {
    ContentBox,
    Subnavbar,
    BroadcastSearch,
    BroadcastTable
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      search: "",
      currentPage: 1,
      isLoading: false
    };
  },
  computed: {
    ...mapState({
      list: state => state.broadcast.list
    })
  },
  methods: {
    handleSearch (criteria) {
      this.currentPage = 1
      this.startDate = criteria.startDate
      this.endDate = criteria.endDate
      this.search = criteria.search 
      this.fetchBroadcast()
    },
    handlePageChange (page) {
      this.currentPage = page
      this.fetchBroadcast()
    },
    getQueryFromUrl () {
      const { search, startDate, endDate, page } = this.$route.query
      this.startDate = startDate || null
      this.endDate = endDate || null
      this.search = search || ""
      this.currentPage = page ? parseInt(page) : 1
    },
    createQury () {
      let apiQuery = {}
      let urlQuery = {}

      apiQuery["page"] =  this.currentPage
      urlQuery["page"] = this.currentPage
      
      if (this.startDate) {
        apiQuery["start"] = new Date(parseInt(this.startDate))
        urlQuery["startDate"] = this.startDate
      }

      if (this.endDate) {
        apiQuery["end"] = new Date(parseInt(this.endDate))
        urlQuery["endDate"] = this.endDate
      }

      if (this.search !== "") {
        apiQuery["search"] = this.search
        urlQuery["search"] = this.search
      }

      this.$router.replace({ query: urlQuery })
      return apiQuery
    },
    async fetchBroadcast () {
      try {
        this.isLoading = true;
        await this.$store.dispatch("broadcast/listBroadcast", { 
          query: this.createQury()
        })
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        await this.$store.dispatch("ui/showFetchErrorMsg", error.detail)
      }
    },
  },
  mounted () {
    this.getQueryFromUrl()
    this.fetchBroadcast()
  },
};
</script>
