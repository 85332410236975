<template>
  <table class="request-table">
    <thead>
    <tr>
      <th class="status"></th>
      <th>Date Request</th>
      <th>{{ inbox ? 'From' : 'To' }} Hospital</th>
      <th>Pet Type</th>
      <th>Blood Type</th>
      <th>Amount</th>
      <th>Blood Group</th>
      <th>Ref. Request ID</th>
      <th>Details</th>
    </tr>
    </thead>
    <tbody v-if="requestList && requestList.length > 0">
    <tr :key="index" v-for="(request, index) in requestList">
      <td>
        <div
          class="tag tag-vertical"
          :style="`color: white; background-color: ${request.status.color};`"
        >
          {{ request.status.text }}
        </div>
      </td>
      <td>{{ request.requestDate | toThaiDate }}</td>
      <td>{{ request.hospitalName }}</td>
      <td>{{ request.petType }}</td>
      <td>{{ request.bloodType }}</td>
      <td>{{ request.amount }}</td>
      <td>{{ request.bloodGroup }}</td>
      <td>{{ request.id }}</td>
      <td>
        <el-button
          size="mini"
          type="primary"
          @click="onDetailClicked(request.id)"
        >
          See Details
        </el-button>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
  export default {
    name: "BloodRequestHospitalTable",
    props: ['requestList', 'inbox'],
    methods: {
      onDetailClicked(value) {
        this.$emit('detail', value)
      },
    }
  }
</script>

<style lang="scss" scoped>
  .request-table {
    width: 100%;
    border-collapse: collapse;

    thead {
      tr {
        border: #e6eaee 1px solid;

        th {
          padding: 5px;
          font-weight: normal;
          color: #7f8fa4;
          background-color: #f5f8fa;
          font-size: 0.9em;

          &.status {
            width: 30px;
          }
        }
      }
    }

    tbody {
      tr {
        height: 82px;

        td {
          position: relative;
          padding: 5px;
          font-size: 0.9em;
          text-align: center;
        }
      }
    }
  }
</style>
