<template>
  <div class="panel-body" v-bind:style="customStyle">
    <div class="panel-title">
      <div class="title-group">
        <div>
          <span class="panel-en-title">{{enName}}</span>
          <span v-if="thName" class="panel-th-title"> / {{thName}}</span>
        </div>
        <slot name="dropDown"></slot>
      </div>
      <div>
        <span class="panel-fullview" v-bind:class="{ 'has-fullview-link': hasFullviewLink() }">
          <slot name="viewFullLink"></slot>
        </span>
        <div>
          <slot name="datePicker"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    enName: {
      type: String
    },
    thName: {
      type: String
    },
    customStyle: {
      type: Object
    }
  },
  methods: {
    hasFullviewLink() {
      return !!this.$slots.viewFullLink;
    }
  }
}
</script>

<style lang="scss" scoped>
  .panel-body {
    padding: 5px 15px 5px 15px;
    background-color: #f7f7f7;
    border-top: solid 3px #c9c9c9;
  }
  .panel-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .title-group {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .panel-en-title {
    color: #000000;
    font-size: 24px;
  }
  .panel-th-title {
    color: #4a4a4a;
    font-size: 16px;
  }
  .panel-fullview {
    color: #4a4a4a;
    font-size: 12px;
    cursor: pointer;
    display: none;

    &.has-fullview-link {
      display: inline;
    }
  }
  .error {
    text-align: right;
    font-size: 14px;
    color:crimson;
  }
</style>
