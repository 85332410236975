<template>
  <div class="timeline-item">
    <span class="bullet-icon"/>
    <div class="content">
      <span class="date-time">{{ item.datetime | toThaiDate }} {{ item.datetime | toHHMM }}</span>
      <template v-if="item.type === 'cancel'">
        <span class="tag small cancel">Cancel</span>
        <template>&nbsp;by {{ item.detail.by }}</template>
      </template>
      <template v-if="item.type === 'confirm'">
        <span class="tag small confirm">Confirm</span>
        <template v-if="item.detail && item.detail.by">&nbsp;by {{ item.detail.by }}</template>
      </template>
      <template v-if="item.type === 'request_postpone'">
        <span class="tag small request_postpone">Request Postpone</span>
        <template v-if="item.detail && item.detail.by">&nbsp;by {{ item.detail.by }}</template>
        <div
          class="date-time-box"
        >{{ item.detail.postpone_request_datetime | toThaiDate }} {{ item.detail.postpone_request_datetime | toHHMM }}</div>
      </template>
      <template v-if="item.type === 'postpone'">
        <span class="tag small postpone">Postpone</span>
        <template>&nbsp;by {{ item.detail.by }}</template>
        <div
          class="detail"
        >นัดนี้ได้ทำการเลื่อนนัดไปวันที่ {{ item.detail.postpone_request_datetime | toThaiDate }} {{ item.detail.postpone_request_datetime | toHHMM }}</div>
        <button
          class="btn btn-small btn-bluesky"
          @click="$router.push({ name: 'appointment-detail', params: { id: item.detail.to_appointment } })"
        >ดูรายละเอียดนัด</button>
      </template>
      <template v-if="item.type === 'done' || item.type === 'edit_markdone'">
        <strong v-if="item.type === 'edit_markdone'">** Updated ** &nbsp;</strong>
        <span>Marked done</span>
        <span class="tag small done">done</span>
        <template>&nbsp;by {{ item.detail.by }}</template>
        <div class="detail">
          <button
            class="btn btn-small btn-bluesky"
            @click="$router.push({
              name: 'medicals-edit-detail',
              params: { id: item.detail.markdone.medical_record, type: medicalType.TYPE_DONE_APPOINTMENT}}
            )"
          >ดูรายละเอียด</button>
        </div>
        <!-- <span
          v-if="!canEditMarkdone(item.detail.markdone.id)"
          @click="showMarkdone = !showMarkdone"
        >
          <i class="icon-toggle fa fa-eye" v-show="!showMarkdone"></i>
          <i class="icon-toggle fa fa-eye-slash" v-show="showMarkdone"></i>
        </span> -->
        <!-- <div
          class="markdone-container"
          v-if="showMarkdone || canEditMarkdone(item.detail.markdone.id)"
        >
          <ContentBox>
            <ApptFullDetail
              :appt="item.detail.markdone"
              :isMarkDone="true"
              :parentId="appt.id"
              :canEdit="canEditMarkdone(item.detail.markdone.id)"
            />
          </ContentBox>
        </div> -->
      </template>
    </div>
  </div>
</template>

<script>
import ApptFullDetail from "./ApptFullDetail"
import ContentBox from "../container/ContentBox"
import { appointmentStatus } from "../../constants"
import medicalType from '@/constants/medical/medical-type.js'

export default {
  props: ["item", "appt"],
  components: {
    ApptFullDetail,
    ContentBox
  },
  data() {
    return {
      showMarkdone: false,
      medicalType: medicalType
    }
  },
  methods: {
    canEditMarkdone(id) {
      if (this.appt && this.appt.timeline) {
        let markdone = []
        this.appt.timeline.forEach(item => {
          if (
            item.type === appointmentStatus.DONE ||
            item.type === appointmentStatus.EDIT_MARKDONE
          ) {
            markdone.push(item.detail.markdone.id)
          }
        })
        if (markdone.length === 1) {
          return true
        } else {
          const index = markdone.findIndex(item => item === id)
          return index === markdone.length - 1
        }
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.bullet-icon {
  display: inline-block;
  margin-top: 5px;
  margin-right: 10px;
  width: 15px;
  height: 15px;
  vertical-align: top;
  border-radius: 50%;
  background: #02b0e1;
}
.content {
  display: inline-block;
  width: calc(100% - 30px);
}
.date-time {
  display: inline-block;
  width: 180px;
}
.detail {
  margin: 5px;
}
.date-time-box {
  display: block;
  width: 160px;
  margin: 5px 5px 5px 185px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  background-color: #fff;
  font-size: 14px;
}
.markdone-container {
  margin-left: -20px;
  margin-top: 20px;
}
.icon-toggle {
  cursor: pointer;
  color: #666;
  margin-left: 10px;
}
</style>

