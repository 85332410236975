<template>
    <div class="pet-health-book-group">
        <h2>{{ title }}</h2>
        <div v-if="items && items.length > 0">
            <div class="health-book-item" v-for="(item, index) in items" :key="index">
                <PetHealthBookItem
                    :title="item.activity.name"
                    :date="item.date"
                    :product="getProductName(item)"
                    :lot="item.lot_no"
                    :vet="item.vet.full_name"
                    :hospital="item.hospital.name" />
            </div>
        </div>
        <div class="no-item" v-else>No Record</div>
    </div>
</template>

<script>
import PetHealthBookItem from "./PetHealthBookItem";

export default {
  components: {
    PetHealthBookItem
  },
  props: ["title", "items"],
  methods: {
    getProductName(healthBookItem) {
        if (healthBookItem.activity.products.length > 0) {
          return healthBookItem.activity.products[0].trade_name;
        } else {
          return '-'
        }
    }
  }
}
</script>

<style lang="scss" scoped>
.no-item {
  font-family: default;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.7px;
  text-align: left;
  color: #8e8e93;
}
</style>